import { useField } from 'formik';
import React from 'react';
import { DateTime } from 'luxon';
import { FieldFormGroupProps } from '@shared/components/fields/field_form_group';
import { FormikFieldFormGroup } from '@shared/components/fields/formik/formik_field_form_group';

function formatDateTimeForInput(datetime: DateTime) {
  return datetime.startOf('minute').toISO({ includeOffset: false, suppressSeconds: true, suppressMilliseconds: true });
}

export const FormikNativeDateTimeFormGroup: React.FC<FieldFormGroupProps & { name: string; zone?: string }> = ({
  help,
  label,
  name,
  id,
  zone,
}) => {
  const [{ value }, _, { setValue }] = useField<DateTime>(name);

  return (
    <FormikFieldFormGroup name={name} help={help} label={label} id={id}>
      <input
        id={id}
        name={name}
        type="datetime-local"
        className="form-control"
        value={value && formatDateTimeForInput(zone ? value.setZone(zone, { keepLocalTime: true }) : value)}
        onChange={(event) => setValue(DateTime.fromISO(event.target.value, { zone: zone ?? 'local' }))}
      />
    </FormikFieldFormGroup>
  );
};

import * as React from 'react';
import { client } from '@admin/libraries/apollo';
import { AppointmentStateEnum, DropOff__Appointment, useAdminDropOffAppointmentQuery } from '@admin/schema';

import { DateTime } from 'luxon';

const INBOUND_MAX_OFFSET_DAYS = 21;
const OUTBOUND_MAX_OFFSET_DAYS = 7;

const formatTime = (time: string): string => DateTime.fromISO(time).toISODate();

export const AppointmentTime: React.FC<{
  orderID: string;
  orderType: string;
  scheduled: Date;
  onDayChange: (value: string) => void;
}> = ({ orderID, orderType, scheduled, onDayChange }) => {
  const { data } = useAdminDropOffAppointmentQuery({
    client,
    variables: { orderID },
    fetchPolicy: 'network-only',
    onCompleted: ({ dropOffAppointment }) => {
      setAppointment(dropOffAppointment);
    },
  });

  const [appointment, setAppointment] = React.useState<Pick<DropOff__Appointment, 'expectedAt'> | undefined | null>(
    data?.dropOffAppointment,
  );

  const disableBefore =
    orderType === 'pickup'
      ? DateTime.fromJSDate(scheduled).minus({ days: INBOUND_MAX_OFFSET_DAYS }).toISODate()
      : DateTime.fromJSDate(scheduled).toISODate();
  const disableAfter =
    orderType === 'pickup'
      ? DateTime.fromJSDate(scheduled).toISODate()
      : DateTime.fromJSDate(scheduled).plus({ days: OUTBOUND_MAX_OFFSET_DAYS }).toISODate();

  if (!appointment) {
    return null;
  }
  const appointmentDate = formatTime(appointment.expectedAt);

  return (
    <div className="form-group flex">
      <label htmlFor="appointment_time_picker" className="control-label col-sm-1">
        Customer Arrival
      </label>
      <div className="col-sm-11 flex center">
        <div id="appointment_time_picker">
          {scheduled ? (
            <div className="form-inline">
              <input
                type="date"
                className="form-control"
                disabled={data?.dropOffAppointment?.state !== AppointmentStateEnum.Pending}
                onChange={(event) => {
                  setAppointment({ expectedAt: event.target.value });
                  onDayChange(event.target.value);
                }}
                value={appointmentDate}
                min={disableBefore}
                max={disableAfter}
              />
            </div>
          ) : (
            'Must specify time of day for order scheduled to select appointment time.'
          )}
        </div>
      </div>
    </div>
  );
};

/* eslint-disable no-param-reassign */
(function () {
  const app = angular.module('app');

  app.component('itemsExplorer', {
    templateUrl: 'partials/components/items_explorer.html',
    controller: [
      'ItemsExplorer',
      'PaginationService',
      '$location',
      'DebounceService',
      'FiltersService',
      'ErrorService',
      'ClLocation',
      function (
        ItemsExplorer,
        PaginationService,
        $location,
        DebounceService,
        FiltersService,
        ErrorService,
        ClLocation,
      ) {
        const self = this;
        const debounce = DebounceService.initialize();

        const FILTERS = [
          'pickup_date_from',
          'pickup_date_till',
          'category_filter_type',
          'dimension_filter_type',
          'dimension_min_cuft',
          'dimension_max_cuft',
          'dimension_max_cuft',
          'in_group',
          'height_filter_type',
          'width_filter_type',
          'length_filter_type',
          'min_height',
          'max_height',
          'min_width',
          'max_width',
          'min_length',
          'max_length',
          'include_undefined_height',
          'include_undefined_width',
          'include_undefined_length',
        ];

        self.$onInit = function () {
          self.items = [];
          self.initialFilters = FiltersService.fromQueryParameters({
            pickup_date_from: 'date',
            pickup_date_till: 'date',
            in_group: 'string',
            dimension_min_cuft: 'number',
            dimension_max_cuft: 'number',
            warehouse_ids: 'array',
            mover_ids: 'array',
            category_ids: 'array',
            sizing_selections: 'array',
            item_states: 'array',
            incidents: 'array',
            part_category_ids: 'array',
            material_category_ids: 'array',
            pallet_type_ids: 'array',
            account_ids: 'array',
            min_height: 'number',
            max_height: 'number',
            min_width: 'number',
            max_width: 'number',
            min_length: 'number',
            max_length: 'number',
          });

          self.pagination = PaginationService.initialize({ page: $location.search().page });
        };

        self.onSearchClick = function () {
          self.search();
        };

        self.onFilterChange = function (filters) {
          self.filters = _.cloneDeep(filters);
          self.pagination.reset();
        };

        self.transformFiltersForServer = function (filters) {
          if (filters.incidents) {
            // Unwrap incidents object out of array:
            filters.incidents = filters.incidents[0];
          }
          if (filters.sizing_selections && filters.sizing_selections.length) {
            // Unwrap sizing_selections out of array and only pass the values to the server:
            filters.sizing_selections = _.flatten(Object.values(filters.sizing_selections[0]));
          } else {
            filters.sizing_selections = undefined;
          }
        };

        function formatItemFilters(filters) {
          const { selections } = filters;
          const params = _.pick(filters, FILTERS);
          _.merge(params, selections);
          params.page = self.pagination.page;
          return params;
        }

        self.search = function () {
          self.searching = true;
          const filters = formatItemFilters(self.filters);
          ClLocation.setSearch(filters);
          self.transformFiltersForServer(filters);
          debounce.execute(ItemsExplorer.index(filters)).then(
            (data) => {
              self.pagination.prepare(data.pagination);
              self.items = data.results;
              delete self.searching;
            },
            (error) => {
              ErrorService.handle(error);
              delete self.searching;
            },
          );
        };
      },
    ],
  });
})();

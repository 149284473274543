import * as React from 'react';

import { Panel } from '@shared/components/bootstrap';

import { Stats } from './stats';

interface IFiltersProps {
  mode: 'week' | 'dates';
  query?: string;
  from?: string;
  till?: string;
  week?: string;
  onMode(mode: 'week' | 'dates'): void;
  onQuery(query?: string): void;
  onFrom(date?: string): void;
  onTill(date?: string): void;
  onWeek(date?: string): void;
  onCSV(): void;
}

export const Filters = ({
  mode,
  query,
  from,
  till,
  week,
  onMode,
  onQuery,
  onFrom,
  onTill,
  onWeek,
  onCSV,
}: IFiltersProps) => (
  <Panel>
    <Panel.Body>
      <div className="form">
        <div className="form-group">
          <input
            type="search"
            value={query || ''}
            onChange={(event) => onQuery(event.target.value || undefined)}
            className="form-control"
            placeholder="Search by employee name"
          />
        </div>
      </div>
      <div className="form-inline">
        <label className="mar-hor">
          <input type="radio" checked={mode === 'week'} onChange={() => onMode('week')} />
          <strong className="mar-hor">Week: </strong>
          <div className="form-group">
            <input
              type="week"
              className="form-control"
              disabled={mode !== 'week'}
              value={week || ''}
              onChange={(event) => onWeek(event.target.value || undefined)}
            />
          </div>
        </label>
        <label className="mar-hor">
          <input type="radio" checked={mode === 'dates'} onChange={() => onMode('dates')} />
          <strong className="mar-hor">Dates: </strong>
          <input
            type="date"
            className="form-control"
            disabled={mode !== 'dates'}
            value={from || ''}
            onChange={(event) => onFrom(event.target.value || undefined)}
          />
          <span> - </span>
          <input
            type="date"
            className="form-control"
            disabled={mode !== 'dates'}
            value={till || ''}
            onChange={(event) => onTill(event.target.value || undefined)}
          />
        </label>
      </div>
    </Panel.Body>
    <Panel.Footer>
      <div className="clearfix">
        <div className="pull-right">
          <Stats />
          <button className="btn btn-default" onClick={onCSV}>
            Export CSV
          </button>
        </div>
      </div>
    </Panel.Footer>
  </Panel>
);

import React from 'react';
import { client } from '@admin/libraries/apollo';

import styled from '@emotion/styled';

import { useEstimationItemsQuery } from '@admin/schema';

const StyledTable = styled.div`
  max-width: 800px;
`;

type CategoryInformation = {
  defaultCuft?: number;
  name: string;
  category?: string;
};

export const Table: React.FC<{
  orderID: string;
  algorithmID: string | null;
  disassemblyFlow: boolean;
}> = ({ orderID, algorithmID, disassemblyFlow }) => {
  const { data } = useEstimationItemsQuery({
    client,
    variables: {
      orderID: orderID,
      algorithmID: algorithmID,
    },
  });

  const countByCategory: Map<string, number> = new Map();
  const disassemblyCountByCategory: Map<string, number> = new Map();
  const categoryInformationByID: Map<string, CategoryInformation> = new Map();

  let totalCuft = 0;
  let itemCount = 0;
  let disassemblyCount = 0;

  data?.estimationItems.forEach((item) => {
    if (!categoryInformationByID.has(item.category.id)) {
      categoryInformationByID.set(item.category.id, {
        defaultCuft: item.category.defaultCuft ?? undefined,
        name: item.category.name,
        category: item.category.parent?.name ?? undefined,
      });
    }

    countByCategory.set(item.category.id, (countByCategory.get(item.category.id) ?? 0) + item.quantity);
    disassemblyCountByCategory.set(
      item.category.id,
      (disassemblyCountByCategory.get(item.category.id) ?? 0) + (item.disassemblyQuantity ?? 0),
    );

    totalCuft += item.quantity * (item.category.defaultCuft || 0);
    itemCount += item.quantity;
    disassemblyCount += item.disassemblyQuantity ?? 0;
  });

  return (
    <>
      <h3 className="text-thin">Item Selection</h3>
      <StyledTable>
        <div className="table-responsive">
          <table className="table table-striped">
            <thead>
              <tr key="header">
                <th className="text-left">Name</th>
                <th className="text-left">Category</th>
                <th className="text-left">Estimated Cuft</th>
                <th className="text-left">Quantity</th>
                {disassemblyFlow && <th className="text-left">Disassembly Quantity</th>}
              </tr>
            </thead>
            <tbody>
              {Array.from(categoryInformationByID.keys()).map((categoryID) => {
                const quantity = countByCategory.get(categoryID)!;
                const disassemblyQuantity = disassemblyCountByCategory.get(categoryID)!;
                const category = categoryInformationByID.get(categoryID)!;
                return (
                  <tr key={`item-${categoryID}`}>
                    <th className="text-left">{category.name}</th>
                    <th className="text-left">{category.category || '-'}</th>
                    <th className="text-left">{category.defaultCuft?.toFixed(2)}</th>
                    <th className="text-left">{quantity}</th>
                    {disassemblyFlow && <th className="text-left">{disassemblyQuantity}</th>}
                  </tr>
                );
              })}
              <tr key="total">
                <th className="text-left" colSpan={2}>
                  <strong>Total</strong>
                </th>
                <th className="text-left">
                  <strong>{totalCuft.toFixed(2)}</strong>
                </th>
                <th className="text-left">
                  <strong>{itemCount}</strong>
                </th>
                {disassemblyFlow && (
                  <th className="text-left">
                    <strong>{disassemblyCount}</strong>
                  </th>
                )}
              </tr>
            </tbody>
          </table>
        </div>
      </StyledTable>
    </>
  );
};

import { InputFormGroup } from '@admin/components/fields';
import { Box } from '@clutter/clean';
import React, { useState } from 'react';

import { client } from '@admin/libraries/apollo';
import { Status, useAccountBanQuery, useAccountBanUpdateMutation } from '@admin/schema';
import { Alert, Button, Modal } from '@shared/components/bootstrap';

interface IAccountBanUpdateProps {
  accountID: string;
  onBanUpdated(banned: boolean): void;
}

const BAN_WARNING = `Are you sure you want to disable this account? This account will be unable to book any new
appointments through the funnel or Account Portal. Agents will still be able book appointments on behalf
of this customer through Teal.`;

const UNBAN_WARNING = `Are you sure you want to enable this account? 
This account will be able to book new appointments through the funnel and Account Portal.`;

const verb = (banned: boolean) => (banned ? 'Enable' : 'Disable');
const warning = (banned: boolean) => (banned ? UNBAN_WARNING : BAN_WARNING);

const BanUpdateModal: React.FC<
  IAccountBanUpdateProps & { banned: boolean; banReason: string | undefined; onClose(): void }
> = ({ accountID, banned, banReason, onBanUpdated, onClose }) => {
  const [error, setError] = useState<string | undefined>();
  const [reason, setReason] = useState<string | undefined>();

  const [ban, { loading }] = useAccountBanUpdateMutation({ client });

  const onConfirm = async () => {
    if (!banned && !reason) {
      setError('Please enter a reason to disable this account');
      return;
    }

    const response = await ban({ variables: { accountID, input: { banned: !banned, reason } } });
    if (response && response.data?.ban) {
      if (response.data.ban.error) {
        setError(`We were unable to disable this account. ${response.data.ban.error}`);
      } else if (response.data.ban.status === Status.Ok) {
        onBanUpdated(banned);
        onClose();
      }
    }
  };

  return (
    <Modal onClose={onClose}>
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>
            {verb(banned)} Account {accountID}?
          </Modal.Title>
          <Modal.Close close={onClose} />
        </Modal.Header>
        <Modal.Body>
          {error && (
            <Alert style="danger">
              <strong>Error: </strong>
              {error}
            </Alert>
          )}
          <Box>{warning(banned)}</Box>
          <Box margin="24px 0 0 0">
            {banned && <>Current disable reason: {banReason}</>}
            {!banned && (
              <InputFormGroup
                id="ban_reason"
                label="Disable reason:"
                type="text"
                value={reason ?? ''}
                onChange={(event) => setReason(event.target.value)}
              />
            )}
          </Box>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onClose} disabled={loading} kind="default">
            Cancel
          </Button>
          <Button onClick={onConfirm} disabled={loading} loading={loading} loadingLabel="Loading..." kind="danger">
            {verb(banned)}
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};

export const BanUpdate: React.FC<IAccountBanUpdateProps> = ({ accountID, onBanUpdated }) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const { data } = useAccountBanQuery({ client, variables: { accountID } });

  if (!data) {
    return null;
  }

  return (
    <>
      <Button kind="danger" onClick={() => setShowModal(true)}>
        {verb(data.account.bannedFromBooking)} Account
      </Button>
      {showModal && (
        <BanUpdateModal
          accountID={accountID}
          banned={data.account.bannedFromBooking}
          banReason={data.account.banReason ?? undefined}
          onBanUpdated={onBanUpdated}
          onClose={() => setShowModal(false)}
        />
      )}
    </>
  );
};

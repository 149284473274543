import React from 'react';

import { MovingQuoteFragment } from '@admin/schema';

import { Time } from '@admin/components/helpers/time';

const formatMoverDisplay = (moverIndex: number): string => `${moverIndex + 1} mover${moverIndex > 0 ? 's' : ''}`;
const formatActiveDurationRowClass = (
  moverSelection: MovingQuoteFragment['moverSelection'],
  moverIndex: number,
): string => (moverIndex + 1 === moverSelection ? 'active' : '');

export const QuoteDurations: React.FC<{
  quote: MovingQuoteFragment;
}> = ({ quote: { moverSelection, packDurations, unpackDurations, orderDurations, dispatchDurations } }) => (
  <>
    <div className="col-sm-2">
      <table className="table">
        <caption>Pack Durations</caption>
        <tbody>
          {packDurations.map((packDuration, index) => (
            <tr key={index} className={formatActiveDurationRowClass(moverSelection, index)}>
              <td className="text-left">{formatMoverDisplay(index)}</td>
              <td className="text-right">
                <strong>
                  <Time value={packDuration} />
                </strong>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    <div className="col-sm-2">
      <table className="table">
        <caption>Unpack Durations</caption>
        <tbody>
          {unpackDurations.map((unpackDuration, index) => (
            <tr key={index} className={formatActiveDurationRowClass(moverSelection, index)}>
              <td className="text-left">{formatMoverDisplay(index)}</td>
              <td className="text-right">
                <strong>
                  <Time value={unpackDuration} />
                </strong>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    <div className="col-sm-2">
      <table className="table">
        <caption>Order Durations</caption>
        <tbody>
          {orderDurations?.map((orderDuration, index) => (
            <tr key={index} className={formatActiveDurationRowClass(moverSelection, index)}>
              <td className="text-left">{formatMoverDisplay(index)}</td>
              <td className="text-right">
                <strong>
                  <Time value={orderDuration} />
                </strong>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    <div className="col-sm-2">
      <table className="table">
        <caption>Total Dispatch Durations</caption>
        <tbody>
          {dispatchDurations?.map((dispatchDuration, index) => (
            <tr key={index} className={formatActiveDurationRowClass(moverSelection, index)}>
              <td className="text-left">{formatMoverDisplay(index)}</td>
              <td className="text-right">
                <strong>
                  <Time value={dispatchDuration} />
                </strong>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </>
);

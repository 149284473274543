import { SelectFormGroup } from '@admin/components/fields';
import * as React from 'react';

import { useBillingReBillReasonsQuery } from '@admin/schema';
import { client } from '@admin/libraries/apollo';

export const ReasonSelector: React.FC<{
  invoiceID: string;
  reasonID?: string;
  onSelect(reasonID?: string): void;
}> = ({ invoiceID, reasonID, onSelect }) => {
  const { data, loading } = useBillingReBillReasonsQuery({ client, variables: { invoiceID } });
  const reasons = data?.reasons ?? [];

  return (
    <SelectFormGroup
      id="re_bill_reason"
      label="Reason:"
      value={reasonID || ''}
      onChange={(event) => onSelect(event.target.value || undefined)}
    >
      <option value="" disabled>
        {loading ? ' - Loading - ' : '- Reason -'}
      </option>
      {!loading &&
        reasons.map((reason) => (
          <option key={reason.id} value={reason.id}>
            {reason.name}
          </option>
        ))}
    </SelectFormGroup>
  );
};

import React from 'react';
import { Formik, FormikErrors, Form as FormikForm } from 'formik';

import {
  Status,
  OrderSubtypeEnum,
  OrderTypeEnum,
  useOrderLeadTimeUpsertMutation,
  OrderLeadTimeInput,
} from '@admin/schema';

import { client } from '@admin/libraries/apollo';
import { FormikInputFormGroup } from '@shared/components/fields/formik/formik_input_form_group';
import { Alert, Button, Panel, Modal } from '@shared/components/bootstrap';
import { ORDER_SUBTYPE_TO_NAME } from '@admin/constants/order_subtype';
import { DateTime } from 'luxon';
import { ORDER_TYPE_TO_NAME } from '@admin/constants/order_type';
import { FormikSelectFormGroup } from '@shared/components/fields/formik/formik_select_form_group';
import { FormikRadioFormGroup } from '../fields/formik/formik_radio_form_group';
import {
  FormikTimePickerFormGroup,
  formatDateTimeForTimePickerInput,
} from '../fields/formik/formik_time_picker_form_group';

export type OrderLeadTimeEditingType = {
  id: string;
  ignoreNonOperatingDays: boolean;
  orderSubtype: OrderSubtypeEnum;
  orderType: OrderTypeEnum;
  processingDayCutoffTime: string;
  processingDaysRequired: number;
};

export type OrderLeadTimeExistingKindsType = {
  orderSubtype: OrderSubtypeEnum;
  orderType: OrderTypeEnum;
};

export const UpsertModal: React.FC<{
  orderLeadTime?: OrderLeadTimeEditingType;
  existingOrderLeadTimeKinds: OrderLeadTimeExistingKindsType[];
  warehouseID: string;
  closeModal(): void;
}> = ({ orderLeadTime, warehouseID, existingOrderLeadTimeKinds, closeModal }) => {
  const editing = !!orderLeadTime;

  const [save, { loading, data }] = useOrderLeadTimeUpsertMutation({
    client,
    onCompleted: ({ result }) => {
      if (result?.status === Status.Ok) closeModal();
    },
  });

  const validateInput = ({
    orderType,
    orderSubtype,
    processingDaysRequired,
  }: OrderLeadTimeInput): FormikErrors<OrderLeadTimeInput> => {
    const formErrors: FormikErrors<OrderLeadTimeInput> = {};

    if (!orderType) {
      formErrors.orderType = 'An order type is required';
    }

    if (!orderSubtype) {
      formErrors.orderSubtype = 'An order subtype is required';
    }

    if (
      !editing &&
      existingOrderLeadTimeKinds.find((kind) => kind.orderType === orderType && kind.orderSubtype === orderSubtype)
    ) {
      formErrors.orderSubtype =
        'An order lead time already exists for this type/subtype combination. Edit the existing one if you need to make changes.';
    }

    if (!Number.isInteger(processingDaysRequired)) {
      formErrors.processingDaysRequired = 'A valid number of days is required';
    }

    return formErrors;
  };

  const onSubmit = (input: OrderLeadTimeInput) => {
    save({
      variables: {
        id: orderLeadTime?.id,
        input,
      },
    });
  };

  const orderTypeOptions = [OrderTypeEnum.Pickup, OrderTypeEnum.Return, OrderTypeEnum.Move];
  const pickupSubtypeOptions = [OrderSubtypeEnum.Onboarding, OrderSubtypeEnum.Subsequent];
  const returnSubtypeOptions = [OrderSubtypeEnum.Subsequent, OrderSubtypeEnum.Final];
  const moveSubtypeOptions = [OrderSubtypeEnum.None, OrderSubtypeEnum.Packing, OrderSubtypeEnum.Unpacking];

  return (
    <Modal onClose={closeModal}>
      <Formik<OrderLeadTimeInput>
        initialValues={{
          ignoreNonOperatingDays: orderLeadTime?.ignoreNonOperatingDays ?? true,
          orderSubtype: orderLeadTime?.orderSubtype ?? OrderSubtypeEnum.None,
          orderType: orderLeadTime?.orderType ?? OrderTypeEnum.Move,
          processingDayCutoffTime: formatDateTimeForTimePickerInput(
            DateTime.fromISO(
              orderLeadTime?.processingDayCutoffTime ??
                DateTime.utc().set({ hour: 11, minute: 0, second: 0, millisecond: 0 }).toISO(),
              { zone: 'UTC' },
            ),
          ),
          processingDaysRequired: orderLeadTime?.processingDaysRequired ?? 2,
          warehouseId: warehouseID,
        }}
        validate={validateInput}
        onSubmit={onSubmit}
      >
        {({ values }) => {
          function orderSubtypeOptions() {
            if (values.orderType === OrderTypeEnum.Pickup) {
              return pickupSubtypeOptions;
            } else if (values.orderType === OrderTypeEnum.Return) {
              return returnSubtypeOptions;
            } else {
              return moveSubtypeOptions;
            }
          }
          return (
            <FormikForm>
              <Panel>
                <Panel.Body>
                  {data?.result?.error && <Alert style="danger">{data.result.error}</Alert>}
                  <FormikSelectFormGroup label="Order Type" name="orderType" disabled={editing}>
                    <option value=""> - Select - </option>
                    {orderTypeOptions.map((orderType) => (
                      <option key={orderType} value={orderType}>
                        {ORDER_TYPE_TO_NAME[orderType]}
                      </option>
                    ))}
                  </FormikSelectFormGroup>
                  <FormikSelectFormGroup label="Order Subtype" name="orderSubtype" disabled={editing}>
                    <option value=""> - Select - </option>
                    {orderSubtypeOptions().map((orderSubtype) => (
                      <option key={orderSubtype} value={orderSubtype}>
                        {ORDER_SUBTYPE_TO_NAME[orderSubtype]}
                      </option>
                    ))}
                  </FormikSelectFormGroup>
                  <FormikInputFormGroup
                    label="Processing Days Required"
                    name="processingDaysRequired"
                    type="number"
                    min="0"
                    step="1"
                  />
                  <FormikTimePickerFormGroup label="Processing Day Cutoff Time" name="processingDayCutoffTime" />
                  <FormikRadioFormGroup
                    name="ignoreNonOperatingDays"
                    options={[
                      {
                        value: true,
                        label: 'Operating Days',
                      },
                      {
                        value: false,
                        label: 'Calendar Days',
                      },
                    ]}
                  />
                </Panel.Body>
                <Panel.Footer align="right">
                  <Button kind="primary" onClick={closeModal}>
                    Cancel
                  </Button>
                  <Button loading={loading} kind="primary" type="submit">
                    Save
                  </Button>
                </Panel.Footer>
              </Panel>
            </FormikForm>
          );
        }}
      </Formik>
    </Modal>
  );
};

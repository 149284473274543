import { Roles } from '@admin/components/helpers/roles';
import { UserRole } from '@admin/types';
import React, { useState } from 'react';

import { client } from '@admin/libraries/apollo';
import { Status, useSuspendAccountMutation } from '@admin/schema';
import { Alert, Button, Modal } from '@shared/components/bootstrap';

interface ISuspendAccountProps {
  accountID: string;
  onSuspended(): void;
}

const SuspendModal: React.FC<ISuspendAccountProps & { onClose(): void }> = ({ accountID, onSuspended, onClose }) => {
  const [error, setError] = useState<string | undefined>();

  const [suspend, { loading }] = useSuspendAccountMutation({ client });

  const onConfirm = async () => {
    const response = await suspend({ variables: { accountID } });
    if (response && response.data?.suspend) {
      if (response.data.suspend.error) {
        setError(`We were unable to suspend this account. ${response.data.suspend.error}`);
      } else if (response.data.suspend.status === Status.Ok) {
        onSuspended();
        onClose();
      }
    }
  };

  return (
    <Modal onClose={onClose}>
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>Suspend Account {accountID}?</Modal.Title>
          <Modal.Close close={onClose} />
        </Modal.Header>
        <Modal.Body>
          {error && (
            <Alert style="danger">
              <strong>Error: </strong>
              {error}
            </Alert>
          )}
          <div>
            Are you sure you want to suspend this account? This account will only become active again when all of their
            invoices have been paid up or forgiven.
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onClose} disabled={loading} kind="default">
            Cancel
          </Button>
          <Button onClick={onConfirm} disabled={loading} loading={loading} loadingLabel="Loading..." kind="danger">
            Suspend
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};

export const SuspendButton: React.FC<ISuspendAccountProps & { suspendable: boolean }> = ({
  accountID,
  suspendable,
  onSuspended,
}) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  return (
    <>
      {suspendable && (
        <Roles show={[UserRole.Collections, UserRole.Admin]}>
          <Button kind="danger" onClick={() => setShowModal(true)}>
            Suspend Account
          </Button>
          {showModal && (
            <SuspendModal accountID={accountID} onSuspended={onSuspended} onClose={() => setShowModal(false)} />
          )}
        </Roles>
      )}
    </>
  );
};

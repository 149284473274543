import * as React from 'react';

import { SaveButton } from './save_button';

import { IOutboundDispatch } from './types/outbound_dispatch';

interface IVehicleAssignmentProps {
  outboundDispatches?: IOutboundDispatch[];
  serialCode?: string | undefined;
  warehouseId?: string;
  selectedDispatchId?: number;
  submitEnabled: boolean;
  collapseEnabled: boolean;
  handleSelectedDispatch(selectedDispatchId: number, serialCode: string): void;
  handleVehicleAssignment(
    serialCode: string | undefined,
    dispatchId: number | undefined,
    warehouseId: string | undefined,
    oldDispatchId: number | undefined,
    notes: string | undefined,
  ): void;
}

interface IVehicleAssignmentState {
  submitEnabled?: boolean | undefined;
  selectedDispatchId?: string | undefined;
}

class VehicleAssignment extends React.Component<IVehicleAssignmentProps, IVehicleAssignmentState> {
  constructor(props: IVehicleAssignmentProps) {
    super(props);

    this.state = {
      selectedDispatchId: undefined,
    };
  }

  public render() {
    if (!this.props.serialCode) {
      return null;
    }
    const {
      outboundDispatches,
      serialCode,
      warehouseId,
      submitEnabled,
      handleVehicleAssignment,
      selectedDispatchId,
      collapseEnabled,
    } = this.props;

    const assignedDispatch = outboundDispatches && outboundDispatches.find((d) => d.vehicleCodes.includes(serialCode));

    if (outboundDispatches) {
      return (
        <div className="vehicle-assignment">
          <select
            className={`form-control vehicle-assignment__select ${
              assignedDispatch ? 'vehicle-assignment__select--bold' : ''
            }`}
            value={
              selectedDispatchId !== undefined ? selectedDispatchId : assignedDispatch && assignedDispatch.dispatchId
            }
            onChange={(e) => {
              this.props.handleSelectedDispatch(Number(e.currentTarget.value), serialCode);
            }}
          >
            <option value="default">Select Dispatch</option>
            {assignedDispatch && <option value={0}>Remove Dispatch</option>}
            {outboundDispatches.map((d: any) => (
              <option key={d.dispatchId} value={d.dispatchId}>
                {d.dispatchId}
              </option>
            ))}
          </select>
          {!collapseEnabled && (
            <SaveButton
              serialCode={serialCode}
              selectedDispatchId={selectedDispatchId}
              warehouseId={warehouseId}
              outboundDispatches={outboundDispatches}
              disabled={!submitEnabled}
              handleVehicleAssignment={handleVehicleAssignment}
            />
          )}
        </div>
      );
    } else {
      return null;
    }
  }
}

export { VehicleAssignment };

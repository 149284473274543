const app = angular.module('app');

app.controller('LeadsFormController', [
  '$scope',
  '$rootScope',
  'AddressGeocodeService',
  'Lead',
  'LeadObjectionService',
  'ProductAvailabilityService',
  'Phone',
  function ($scope, $rootScope, AddressGeocodeService, Lead, LeadObjectionService, ProductAvailabilityService, Phone) {
    $scope.origLead = {};
    $scope.disablePhoneInput = false;

    $scope.mapControl = {};
    $scope.notes = null;
    $scope.closeCRMActive = window.CONFIG.feature_flag.close_crm_sync_2024_03;

    $scope.isFieldUnsaved = function (field) {
      return $scope.lead[field] !== $scope.origLead[field];
    };

    $scope.callConsentToggle = async () => {
      $scope.callConsentToggleDisabled = true;
      const toggledCommercialCall = !$scope.lead.phone_commercial_call;
      await Phone.update({
        id: $scope.lead.phone_id,
        commercial_call: toggledCommercialCall,
        transactional_call: toggledCommercialCall,
      }).$promise;
      $scope.lead.phone_commercial_call = toggledCommercialCall;
      $scope.lead.phone_transactional_call = toggledCommercialCall;
      $scope.callConsentToggleDisabled = false;
    };

    $scope.smsConsentToggle = async () => {
      $scope.smsConsentToggleDisabled = true;
      const toggledCommercialSms = !$scope.lead.phone_commercial_sms;
      await Phone.update({
        id: $scope.lead.phone_id,
        commercial_sms: toggledCommercialSms,
        transactional_sms: toggledCommercialSms,
      }).$promise;
      $rootScope.$broadcast('messages_controller.loadPreferences', () => {
        $scope.lead.phone_commercial_sms = toggledCommercialSms;
        $scope.smsConsentToggleDisabled = false;
      });
    };

    $rootScope.$on('lead:checkZip', (event, data) => {
      $scope.checkZip(data);
    });

    $scope.checkZip = function (zip) {
      $scope.afterZipSelectionCallback({ zip });
      AddressGeocodeService.geocode({ zip }).then((geocoded) => {
        $scope.coords = geocoded;
        $rootScope.$broadcast('zip:validated', { zip, geocode: geocoded });
      });
    };

    $scope.onZipChange = () => {
      if ($scope.lead.zip.length === 5) {
        $scope.checkZip($scope.lead.zip);
      }
    };

    const loadExistingLead = function () {
      Lead.get({ id: $scope.lead.id }, (response) => {
        // Prevents resetting objection_reason if the agent sets it before the lead fully loads
        const currentObjectionReason = $scope.lead.objection_reason;

        $scope.lead = response;
        $scope.lead.originalObjection = response.objection_reason;
        $scope.lead.objection_reason = currentObjectionReason || null;

        if (response.zip) {
          $scope.checkZip(response.zip);
        }

        if ($scope.lead.phone) {
          $scope.disablePhoneInput = true;
        }

        if ($scope.afterLoadCallback !== undefined) {
          $scope.afterLoadCallback({ lead: $scope.lead });
        }
        angular.copy($scope.lead, $scope.origLead);
      });
    };

    this.$onInit = function () {
      if ($scope.lead.id !== undefined) {
        loadExistingLead();
      }
    };

    $scope.onDiscountSelected = (discount) => {
      const couponId = _.get(discount, 'coupon_id');
      $scope.discount = discount;
      $scope.coupon = _.find($scope.coupons, (coupon) => coupon.id === couponId);
    };

    $scope.$watch('lead.objection_reason', (newValue, oldValue) => {
      LeadObjectionService.handleObjectionChange($scope.lead, newValue, oldValue);

      if (!_.isUndefined($scope.lead.state)) {
        $rootScope.$broadcast('lead:statusChanged', {
          objection_reason: newValue,
          followup_scheduled_at: $scope.lead.followup_scheduled_at,
          state: $scope.lead.state,
        });
      }
    });

    $scope.isChannelPartnerLead = ProductAvailabilityService.isChannelPartnerLead;

    $scope.onSelect = (source) => {
      $scope.lead.reported_source = source;
    };
  },
]);

import { DateTime } from 'luxon';
import React, { useState } from 'react';

import { useBuildMessageMutation, useDispatchCommunicationsQuery } from '@admin/schema';

import { client } from '@admin/libraries/apollo';
import { TimeZoneContext } from '../context';
import { CommunicationsPanel } from './communications_panel';

const INACTIVE_DISPATCH = 'The dispatch is not active';
const DISPATCH_WARNING =
  'Reminder: This is for M&Ps. You can send messages to customers on the order and account pages.';

interface IMessageInput {
  body: string;
  recipientType: string;
  recipientId: string;
  phoneId: string;
}

const isToday = (someDate: DateTime) => {
  const today = DateTime.local();
  return someDate.day === today.day && someDate.month === today.month && someDate.year === today.year;
};

export const DispatchCommunications: React.FC<{
  recipientType: string;
  recipientID: string;
}> = ({ recipientType, recipientID }) => {
  const [page, setPage] = useState<number>(1);
  const { data, refetch } = useDispatchCommunicationsQuery({
    client,
    variables: { dispatchID: recipientID, page },
  });
  const [createMessage, { loading: messageLoading }] = useBuildMessageMutation({ client });

  const refetchPage = (newPage: number) => {
    setPage(newPage);
  };

  if (!data || !data.dispatch) {
    return null;
  }

  const onCreate = async (body: string, phoneNumber: string) => {
    if (messageLoading || !body) {
      return;
    }
    const recipient = data.dispatch.callables.find((callable) => callable.number === phoneNumber);
    const messageInput: IMessageInput = {
      body,
      recipientType,
      recipientId: recipient!.assignmentId!,
      phoneId: recipient!.phoneId!,
    };
    const variables = {
      input: messageInput,
    };
    await createMessage({ variables });
  };

  const disabled =
    !isToday(DateTime.fromISO(data.dispatch.arrival).setZone(data.dispatch.region!.tz)) || !data.dispatch.callables;

  const placeholder = disabled ? INACTIVE_DISPATCH : 'What would you like to say?';

  return (
    <TimeZoneContext.Provider value={data.dispatch.region!.tz}>
      <CommunicationsPanel
        callables={data.dispatch.callables}
        paginatedCommunications={data.dispatch.paginatedCommunications}
        disabled={disabled}
        warning={DISPATCH_WARNING}
        placeholder={placeholder}
        onCreate={onCreate}
        refetchPage={refetchPage}
        refetch={refetch}
      />
    </TimeZoneContext.Provider>
  );
};

import { DateTime } from 'luxon';
import * as React from 'react';
import { useState } from 'react';

import { client } from '@admin/libraries/apollo';
import {
  CustomerTicketFragment,
  CustomerTicket__Priority,
  Status,
  useModifyCustomerTicketMutation,
} from '@admin/schema';

import { Categories } from '@admin/components/customer_tickets/categories';
import { TicketGroupPicker } from '@admin/components/customer_tickets/ticket_group_picker';
import { DateTimePicker } from '@admin/components/helpers/date_time_picker';
import { LabelledAttribute } from '@admin/components/helpers/labelled_attribute';
import styled from '@emotion/styled';
import { Button, Text } from '@shared/components/bootstrap';
import { Timestamp } from '@shared/components/helpers';
import { FEATURE_FLAG } from '@shared/config/feature_flag';

import { PriorityPicker } from './priority_picker';

const EditForm = styled.form`
  .labelled-attribute {
    margin: 5px 0;
  }
`;

const ButtonContainer = styled.div`
  text-align: right;

  button {
    margin-left: 4px;
  }
`;

const AttributeContainer = styled.div`
  max-width: 500px;
`;

export const Edit: React.FC<{
  customerTicket: CustomerTicketFragment;
  customerTicketableLink: string;
  finishEditing(): void;
}> = ({ customerTicket, customerTicketableLink, finishEditing }) => {
  const {
    id,
    customerTicketable,
    customerTicketableType,
    createdAt,
    creator,
    state,
    assignee,
    actionRequiredAt: ar,
  } = customerTicket;

  const [category, setCategory] = useState<string>(customerTicket.category);
  const [subCategory, setSubCategory] = useState<string | undefined>(customerTicket.subCategory ?? undefined);
  const [description, setDescription] = useState<string | undefined>(customerTicket.description ?? undefined);
  const [groupID, setGroupID] = useState<string | undefined>(customerTicket.group?.id);
  const [assigneeID, setAssigneeID] = useState<string | undefined>(assignee ? String(assignee.id) : undefined);
  const [priority, setPriority] = useState<CustomerTicket__Priority | undefined>(customerTicket.priority ?? undefined);
  const [actionRequiredAt, setActionRequiredAt] = useState<DateTime | undefined>(ar ? DateTime.fromISO(ar) : undefined);
  const [formError, setFormError] = useState<string | undefined>();
  const [allowIncomingCalls, setAllowIncomingCalls] = useState<boolean>(customerTicket.allowIncomingCalls);

  const [modifyCustomerTicket, { loading }] = useModifyCustomerTicketMutation({ client });

  const onSave = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (!actionRequiredAt) {
      return;
    }
    const response = await modifyCustomerTicket({
      variables: {
        input: {
          id,
          category,
          subCategory,
          description: description ? description.trim() : undefined,
          groupId: groupID,
          assigneeId: assigneeID,
          actionRequiredAt: actionRequiredAt ? actionRequiredAt.toJSON() : undefined,
          priority,
          allowIncomingCalls: allowIncomingCalls,
        },
      },
    });
    if (response && response.data) {
      if (response.data.modifyCustomerTicket.status === Status.Unprocessable) {
        const errorMessage = response.data.modifyCustomerTicket.error;
        setFormError(`There was a problem updating this ticket: ${errorMessage}`);
        return;
      }
      setFormError(undefined);
      finishEditing();
    }
  };

  const onTicketGroupChange = (newGroupID: string, newAssigneeID: string) => {
    setGroupID(newGroupID);
    setAssigneeID(newAssigneeID);
  };

  const onCategoryChange = (newCategory: string, newSubCategory: string) => {
    setCategory(newCategory);
    setSubCategory(newSubCategory);
  };

  return (
    <EditForm onSubmit={onSave}>
      <a href={customerTicketableLink} target="_blank">
        <LabelledAttribute label={customerTicketableType} attribute={customerTicketable.shortDisplay} />
      </a>
      <LabelledAttribute
        label="Opened on"
        attribute={
          <>
            <Timestamp value={createdAt} format={Timestamp.Format.DateTime} />{' '}
            <span>(by {creator ? creator.name : 'Customer'})</span>
          </>
        }
      />
      <LabelledAttribute
        label="Group / Assignee"
        attribute={
          <div className="row">
            <TicketGroupPicker
              className="col-md-3"
              selectedGroupID={groupID}
              selectedAssigneeID={assigneeID}
              onChange={onTicketGroupChange}
            />
          </div>
        }
      />
      <LabelledAttribute
        label="Priority"
        attribute={
          <div className="row">
            <PriorityPicker className="col-md-3" priority={priority} onChange={setPriority} />
          </div>
        }
      />
      <LabelledAttribute
        label="Action Required At"
        attribute={
          <AttributeContainer>
            <DateTimePicker dt={actionRequiredAt} onChange={setActionRequiredAt} />
          </AttributeContainer>
        }
      />
      <LabelledAttribute label="State" attribute={state} />
      <LabelledAttribute
        label="Category"
        attribute={
          <div className="row">
            <Categories
              className="col-md-3"
              selectedCategory={category}
              selectedSubCategory={subCategory}
              onChange={onCategoryChange}
            />
          </div>
        }
      />
      <LabelledAttribute
        label="Description"
        attribute={
          <textarea className="form-control" value={description} onChange={(e) => setDescription(e.target.value)} />
        }
      />
      {FEATURE_FLAG.new_ivr_flow_2022_06 && (
        <div className="checkbox">
          <label>
            <input
              type="checkbox"
              checked={allowIncomingCalls}
              onChange={(event) => {
                setAllowIncomingCalls(event.target.checked);
              }}
            />
            Allow Incoming Calls
          </label>
        </div>
      )}
      {formError && (
        <Text tag="p" style="danger" alignment="right">
          <strong>{formError}</strong>
        </Text>
      )}
      <ButtonContainer>
        <Button kind="warning" type="submit" disabled={loading}>
          Save
        </Button>
        <Button kind="danger" onClick={finishEditing} disabled={loading}>
          Cancel
        </Button>
      </ButtonContainer>
    </EditForm>
  );
};

import * as React from 'react';

import { Panel } from '@admin/components/helpers/panel';
import { InteractableType } from '@admin/types/customer_interaction';
import { Table } from '@shared/components/bootstrap';

import { CustomerInteractionFragment, LeadInteractionFragment } from '@admin/schema';
import { PhoneCallTableData } from './phone_call_table_data';

export const Interactions: React.FC<{ interactions: Array<CustomerInteractionFragment | LeadInteractionFragment> }> = ({
  interactions,
}) => (
  <Panel>
    <Panel.Header>
      <Panel.Title>Interactions</Panel.Title>
    </Panel.Header>
    <Panel.Body>
      <Table striped responsive>
        <thead>
          <tr>
            <th className="col-sm-2">Date</th>
            <th className="col-sm-2">Agent</th>
            <th className="col-sm-2">Interaction Type</th>
            <th className="col-sm-6">Call Notes</th>
            <th className="col-zero" />
          </tr>
        </thead>
        <tbody>
          {interactions.map((interaction) => (
            <tr key={interaction.id}>
              {interaction.interactableType === InteractableType.PHONE_CALL && (
                <PhoneCallTableData phoneCall={interaction.interactable} />
              )}
            </tr>
          ))}
        </tbody>
      </Table>
    </Panel.Body>
  </Panel>
);

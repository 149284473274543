import React from 'react';

import { Button, ListGroup } from '@shared/components/bootstrap';
import { Currency } from '@shared/components/helpers';

import { IssueSelection } from './issue_selection';
import { ItemSelections } from './item_selections';
import { PropertySelection } from './property_selection';
import { IClaimStepProps } from '../steps';
import { calculateTotalApproved, calculateTotalClaimed, calculateTotalCompensated } from '../totals';
import { IssueSelectionDescription } from '../issue_selection_description';

export const Summary: React.FC<IClaimStepProps> = ({ claim, editable = false, onNext }) => {
  const { account, itemSelections, propertySelection, issueSelection } = claim;

  if (!itemSelections.length && !propertySelection && !issueSelection) {
    return null;
  }

  const totalClaimed = calculateTotalClaimed(itemSelections, propertySelection);
  const totalApproved = calculateTotalApproved(itemSelections, propertySelection);
  const totalCompensated = calculateTotalCompensated(itemSelections, propertySelection);

  return (
    <ListGroup id="selections-summary" className="list-group-flush">
      {issueSelection && <IssueSelection issueSelection={issueSelection} />}
      {!!itemSelections.length && <ItemSelections itemSelections={itemSelections} accountID={account.id} />}
      {propertySelection && <PropertySelection propertySelection={propertySelection} />}
      {(propertySelection || !!itemSelections.length) && (
        <ListGroup.Item>
          <div className="row">
            <div className="col-sm-1 col-sm-offset-7">
              <strong>
                <Currency value={totalClaimed} />
              </strong>
            </div>
            <div className="col-sm-1 col-sm-offset-2">
              <strong>
                <Currency value={totalApproved} />
              </strong>
            </div>
            <div className="col-sm-1">
              <strong>
                <Currency value={totalCompensated} />
              </strong>
            </div>
          </div>
        </ListGroup.Item>
      )}
      {issueSelection && <IssueSelectionDescription issueSelection={issueSelection} />}
      {editable && (
        <div className="mar-top">
          <Button kind="primary" className="pull-right" onClick={() => onNext(claim)}>
            Start
          </Button>
        </div>
      )}
    </ListGroup>
  );
};

import * as React from 'react';

enum Source {
  Search = 'search',
  FriendsFamily = 'friends_family',
  Facebook = 'facebook',
  Walmart = 'walmart',
  Instagram = 'instagram',
  Groupon = 'groupon',
  ClutterTruck = 'clutter_truck',
  Yelp = 'yelp',
  Mail = 'mail',
  Press = 'press',
  TvStreaming = 'tv_streaming',
  Other = 'other',
}

interface ISource {
  value: Source;
  displayValue: string;
}

const sources: ISource[] = [
  { value: Source.Search, displayValue: 'Online search (Google, Yahoo)' },
  { value: Source.FriendsFamily, displayValue: 'Friend/Family' },
  { value: Source.Facebook, displayValue: 'Facebook' },
  { value: Source.Walmart, displayValue: 'Walmart' },
  { value: Source.TvStreaming, displayValue: 'TV / Streaming Video' },
  { value: Source.ClutterTruck, displayValue: 'Saw Clutter truck' },
  { value: Source.Yelp, displayValue: 'Yelp' },
  { value: Source.Mail, displayValue: 'Mail' },
  { value: Source.Instagram, displayValue: 'Instagram' },
  { value: Source.Groupon, displayValue: 'Groupon' },
  { value: Source.Press, displayValue: 'Press' },
  { value: Source.Other, displayValue: 'Other' },
];

export const HowDidYouHearAboutUs: React.FC<{
  source?: string;
  onSelect(source: Source): void;
}> = ({ source, onSelect }) => (
  <select
    className="form-control"
    value={source || ''}
    onChange={(event) => onSelect((event.target.value as Source) || undefined)}
  >
    <option value="">How did you hear about us?</option>
    {sources.map((s) => (
      <option key={s.value} value={s.value}>
        {s.displayValue}
      </option>
    ))}
  </select>
);

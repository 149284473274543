(function () {
  const app = angular.module('app');

  app.component('itemBrick', {
    templateUrl: 'partials/components/item_brick.html',
    bindings: {
      item: '=',
      sizeable: '=?',
    },
  });
})();

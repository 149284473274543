import axios from 'axios';

export enum Status {
  NotApplicable = 'not_applicable',
  Retrieved = 'retrieved',
  Retrievable = 'retrievable',
  NoEmail = 'no_email',
  Canceled = 'canceled',
  TooSoon = 'too_soon',
  TooLate = 'too_late',
}

export class GiftCardResource {
  public static async status(accountId: number): Promise<{ text: string; status: Status }> {
    return (await axios.get(`/accounts/${accountId}/onboarding_incentive_amazon_gift_card_status.json`)).data;
  }

  public static issue(accountId: number) {
    return axios.post(`/accounts/${accountId}/onboarding_incentive_amazon_gift_card_override.json`);
  }
}

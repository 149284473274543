import styled from '@emotion/styled';
import { capitalize } from 'lodash';
import { DateTime } from 'luxon';
import * as React from 'react';

import { AnchorButton as Link, Breadcrumb, Table, Text } from '@shared/components/bootstrap';
import { Currency } from '@shared/components/helpers';

import { Panel } from '@admin/components/helpers/panel';
import { Spinner } from '@admin/components/spinner';

import { Size } from '@admin/components/self_storage/classifications/size';

import { HistoriesPanel } from '@admin/components/histories/panel';
import { NotesPanel } from '@admin/components/notes/panel';
import { TicketsPanel } from '@admin/components/tickets/panel';

import {
  Maybe,
  History__Subject,
  NoteNotableEnum,
  Ticket__TicketableEnum,
  SelfStorage__UnitFragment,
  SelfStorage__RentalFragment,
  useSelfStorageUnitWithRentalsQuery,
} from '@admin/schema';
import { client } from '@admin/libraries/apollo';

import {
  accountURL,
  editSelfStorageUnitURL as editUnitURL,
  listSelfStorageUnitsURL as unitsURL,
} from '@admin/config/routes';

const Attribute = styled.div`
  display: flex;
  margin: -10px;
`;

const Name = styled.div`
  font-weight: bold;
  padding: 10px;
  width: 160px;
`;

const Data = styled.div`
  font-weight: normal;
  padding: 10px;
`;

const Breadcrumbs: React.FC<{ unit?: SelfStorage__UnitFragment }> = ({ unit }) => (
  <div className="page-header">
    <Breadcrumb>
      <Breadcrumb.Item>
        <a href={unitsURL()}>Units</a>
      </Breadcrumb.Item>
      <Breadcrumb.Item>{unit?.facility.name || '-'}</Breadcrumb.Item>
      <Breadcrumb.Item>#{unit?.name || '-'}</Breadcrumb.Item>
    </Breadcrumb>
  </div>
);

const Attributes: React.FC<{ unit: SelfStorage__UnitFragment }> = ({ unit }) => (
  <>
    <Attribute>
      <Name>Facility:</Name>
      <Data>{unit.facility.name}</Data>
    </Attribute>
    <Attribute>
      <Name>Name:</Name>
      <Data>{unit.name}</Data>
    </Attribute>
    <Attribute>
      <Name>Status:</Name>
      <Data>{capitalize(unit.status)}</Data>
    </Attribute>
    <Attribute>
      <Name>Category:</Name>
      <Data>{capitalize(unit.classification.category)}</Data>
    </Attribute>
    <Attribute>
      <Name>Size:</Name>
      <Data>
        <Size classification={unit.classification} />
      </Data>
    </Attribute>
    <Attribute>
      <Name>Location:</Name>
      <Data>{unit.classification.indoors ? 'Indoors' : 'Outdoors'}</Data>
    </Attribute>
    <Attribute>
      <Name>Floor:</Name>
      <Data>{unit.floor}</Data>
    </Attribute>
    <Attribute>
      <Name>Rentable:</Name>
      <Data>{unit.rentable ? 'Yes' : 'No'}</Data>
    </Attribute>
    <Attribute>
      <Name>Designations:</Name>
      <Data>{unit.designations.length > 0 ? unit.designations.join(', ') : 'None'}</Data>
    </Attribute>
    <Attribute>
      <Name>Climate Controlled:</Name>
      <Data>{unit.classification?.climate ? 'Yes' : 'No'}</Data>
    </Attribute>
    <Attribute>
      <Name>Obstructed:</Name>
      <Data>{unit.classification.obstructed ? 'Yes' : 'No'}</Data>
    </Attribute>
    <Attribute>
      <Name>Electricity:</Name>
      <Data>{unit.classification.electricity ? 'Yes' : 'No'}</Data>
    </Attribute>
    <Attribute>
      <Name>Standard Rate:</Name>
      <Data>{unit.price?.rate ? <Currency value={unit.price.rate} /> : 'None'}</Data>
    </Attribute>
    <Attribute>
      <Name>Web Rate:</Name>
      <Data>{unit.price?.pushRate ? <Currency value={unit.price.pushRate} /> : 'None'}</Data>
    </Attribute>
    <Attribute>
      <Name>Promotion:</Name>
      <Data>{unit.promotion?.name || 'None'}</Data>
    </Attribute>
  </>
);

const Rentals: React.FC<{ rentals?: Maybe<SelfStorage__RentalFragment[]> }> = ({ rentals }) => (
  <>
    <Table>
      <thead>
        <tr>
          <th className="text-left">ID</th>
          <th className="text-left">Account</th>
          <th className="text-left">Move-In Date</th>
          <th className="text-left">Move-Out Date</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {rentals?.map((rental) => (
          <tr key={rental.id}>
            <td className="text-left">{rental.id}</td>
            <td className="text-left">{rental.subscription.account.customer.name}</td>
            <td className="text-left">
              {rental.activatedAt && DateTime.fromISO(rental.activatedAt).toLocaleString(DateTime.DATETIME_MED)}
            </td>
            <td className="text-left">
              {rental.canceledAt && DateTime.fromISO(rental.canceledAt).toLocaleString(DateTime.DATETIME_MED)}
            </td>
            <td className="text-right">
              <Link kind="default" href={accountURL(rental.subscription.account)}>
                Account
              </Link>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
    {!rentals && <Spinner />}
  </>
);

export const Detail: React.FC<{ id: string }> = ({ id }) => {
  const { data } = useSelfStorageUnitWithRentalsQuery({ client, variables: { id } });

  const unit = data?.unit;

  return (
    <>
      <Breadcrumbs unit={unit} />

      <Panel>
        <Panel.Header>
          <Panel.Title>Unit Details</Panel.Title>
        </Panel.Header>
        <Panel.Body>{unit ? <Attributes unit={unit} /> : <Spinner />}</Panel.Body>
        <Panel.Footer>
          {unit && !unit.facility.partner?.isThirdParty && (
            <Text alignment="right" tag="div">
              <Link kind="primary" href={editUnitURL({ id })}>
                Edit
              </Link>
            </Text>
          )}
        </Panel.Footer>
      </Panel>

      <Panel>
        <Panel.Header>
          <Panel.Title>Tenant History</Panel.Title>
        </Panel.Header>
        <Panel.Body>
          <Rentals rentals={unit?.rentals} />
        </Panel.Body>
      </Panel>

      <NotesPanel id={id} type={NoteNotableEnum.SelfStorageUnit} />
      <TicketsPanel ticketable={{ id, type: Ticket__TicketableEnum.SelfStorageUnit }} />
      <HistoriesPanel id={id} type={History__Subject.SelfStorageUnit} />
    </>
  );
};

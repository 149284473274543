import styled from '@emotion/styled';
import * as React from 'react';

import { SelfStorage__PriceFragment } from '@admin/schema';

import { Currency } from '@shared/components/helpers';

const List = styled.div`
  margin: -4px;
  display: flex;
  justify-content: center;
`;

const Entry = styled.div`
  padding: 4px;
  flex-grow: 0;
  flex-shrink: 0;
`;

const Amount = styled.div`
  font-size: 16px;
  font-weight: 600;
`;

const Hint = styled.div`
  font-size: 10px;
`;

const Muted = styled.div`
  opacity: 0.5;
  text-decoration: line-through;
`;

const PROMO_KEY = 'pushRate';
const DEFAULT_KEY = 'rate';
const PROMO_HINT = 'web only';
const DEFAULT_HINT = 'in store';

export const Summary: React.FC<{
  price: SelfStorage__PriceFragment;
}> = ({ price }) =>
  price[PROMO_KEY] > 0 && price[PROMO_KEY] < price[DEFAULT_KEY] ? (
    <List>
      <Entry>
        <Hint>{PROMO_HINT}</Hint>
        <Amount>
          <Currency value={price[PROMO_KEY]} />
        </Amount>
      </Entry>
      <Entry>
        <Hint>{DEFAULT_HINT}</Hint>
        <Muted>
          <Amount>
            <Currency value={price[DEFAULT_KEY]} />
          </Amount>
        </Muted>
      </Entry>
    </List>
  ) : (
    <Currency value={price[DEFAULT_KEY]} />
  );

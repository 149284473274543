import * as React from 'react';

import styled from '@emotion/styled';

import { Panel } from '@admin/components/helpers/panel';
import { MessageHistory } from '@admin/components/messages/message_history';
import { MessageSender } from '@admin/components/messages/message_sender';
import { Pagination } from '@admin/components/pagination';
import { DispatchCommunicationsQuery } from '@admin/schema';
import { Formatter as PhoneFormatter } from '@admin/components/phone/formatter';
import { PhoneCallHistory } from './phone_call_history';

const Body = styled.div`
  padding: 1.5rem 2.5rem;
`;

const HistoryShading = styled.div`
  &:nth-child(even) {
    background: rgb(247, 248, 250);
  }
`;

const PhoneText = styled.div`
  font-weight: 600;
  font-size: 16px;
  padding: 5px 0px;
  display: inline-block;
`;

const Refresh = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const RefreshIcon = styled.i`
  :hover,
  :focus {
    color: gray;
  }
`;

const PanelTitle = styled(Panel.Title)`
  display: flex;
  justify-content: space-between;
`;

export const CommunicationsPanel: React.FC<{
  callables: DispatchCommunicationsQuery['dispatch']['callables'];
  paginatedCommunications: DispatchCommunicationsQuery['dispatch']['paginatedCommunications'];
  disabled: boolean;
  warning: string;
  placeholder?: string;
  refetchPage(page: number): void;
  onCreate(body: string, phoneID: string): Promise<void>;
  refetch(): void;
}> = ({ callables, paginatedCommunications, disabled, placeholder, warning, refetchPage, onCreate, refetch }) => {
  const lead = callables.find((callable) => callable.role === 'lead');
  const communications = paginatedCommunications.results;
  return (
    <Panel>
      <Panel.Header>
        <PanelTitle>
          <div>Communications with M&amp;P's</div>
          <Refresh>
            <button className="btn btn-refresh pull-right" onClick={() => refetch()}>
              <RefreshIcon className="fa fa-2x fa-refresh" />
            </button>
          </Refresh>
        </PanelTitle>
      </Panel.Header>
      <Body>
        {lead && (
          <>
            <PhoneText>M&P Number: </PhoneText> <PhoneFormatter number={lead.number} />
          </>
        )}
        <MessageSender
          callables={callables}
          disabled={disabled}
          placeholder={placeholder}
          warning={warning}
          refetch={refetch}
          onCreate={onCreate}
        />
        <Body>
          {communications.map((communication) => (
            <HistoryShading key={communication.id}>
              {communication.__typename === 'Message' && <MessageHistory message={communication} />}
              {communication.__typename === 'PhoneCall' && <PhoneCallHistory phoneCall={communication} />}
            </HistoryShading>
          ))}
          <Pagination pagination={paginatedCommunications.pagination} onPage={refetchPage} />
        </Body>
      </Body>
    </Panel>
  );
};

(function () {
  angular.module('app').factory('Unavailability', [
    '$resource',
    'SerializerService',
    'DeserializerService',
    'TransformBuilder',
    function ($resource, SerializerService, DeserializerService, TransformBuilder) {
      const MAPPINGS = {
        start_date: DeserializerService.types.DATE,
        until_date: DeserializerService.types.DATE,
      };

      return $resource(
        '/unavailabilities/:id.json',
        {
          id: '@id',
        },
        {
          query: {
            method: 'GET',
            isArray: true,
            transformResponse: TransformBuilder.transformResponse(DeserializerService.collection(MAPPINGS)),
          },
          update: {
            method: 'PATCH',
            transformResponse: TransformBuilder.transformResponse(DeserializerService.instance(MAPPINGS)),
            transformRequest: TransformBuilder.transformRequest(SerializerService.transform(MAPPINGS)),
          },
          create: {
            method: 'POST',
            transformResponse: TransformBuilder.transformResponse(DeserializerService.instance(MAPPINGS)),
            transformRequest: TransformBuilder.transformRequest(SerializerService.transform(MAPPINGS)),
          },
        },
      );
    },
  ]);
})();

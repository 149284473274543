import * as React from 'react';

import { useSelfStorageFacilitiesQuery } from '@admin/schema';

import { client } from '@admin/libraries/apollo';

export const SingleSelector: React.FC<{
  name: string;
  selectedID?: string;
  onSelect(selectedID?: string): void;
}> = ({ name, selectedID, onSelect }) => {
  const { data, loading } = useSelfStorageFacilitiesQuery({ client });
  const facilities = data?.facilities ?? [];

  return (
    <select
      name={name}
      className="form-control"
      disabled={loading}
      value={selectedID || ''}
      onChange={(event) => onSelect(event.target.value || undefined)}
    >
      <option disabled={!!selectedID} value="">
        - Facility -
      </option>
      {facilities.map((facility) => (
        <option key={facility.id} value={facility.id}>
          {facility.name}
        </option>
      ))}
    </select>
  );
};

import React from 'react';
import styled from '@emotion/styled';
import { Logistics__AddressFragment } from '@admin/schema';

const BiggerText = styled.div`
  font-size: 16px;
`;

export const AddressInfo: React.FC<{ kind: string; address: Logistics__AddressFragment }> = ({ kind, address }) => (
  <>
    <h3>{kind} Address</h3>
    <BiggerText>
      <div>{address.businessName}</div>
      <div>
        {address.street} {address.aptsuite && `, ${address.aptsuite}`}
      </div>
      <div>
        {address.city}, {address.state} {address.zip}
      </div>
      <div>{address.country}</div>
    </BiggerText>
  </>
);

(function () {
  const app = angular.module('app');

  app.controller('OrderRedirectController', [
    '$http',
    '$state',
    '$stateParams',
    function ($http, $state, $stateParams) {
      const id = $stateParams.id;
      $http({
        method: 'GET',
        url: '/orders/' + id + '.json',
      }).then(function (response) {
        $state.go('viewOrder', { account_id: response.data.account_id, id: response.data.id });
      });
    },
  ]);
})();

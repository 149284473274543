import React from 'react';
import { Button, Modal, Text } from '@shared/components/bootstrap';
import { InsufficientSkuInventoryQuery } from '@admin/schema';
import { logisticsDetailedLoadURL } from '@admin/config/routes';
import styled from '@emotion/styled';
import { COLORS } from '@root/colors';
import { Spacer } from '@shared/components/helpers';

const Link = styled.a`
  color: ${COLORS.blueLight};
`;

type InsufficientSKUInventoryObjects = InsufficientSkuInventoryQuery['insufficientSkuInventory'];

export const SkuInventoryConfirmationModal: React.FC<{
  warehouseName: string;
  insufficientInventoryObjects: InsufficientSKUInventoryObjects;
  onCancel(): void;
  onConfirm(): void;
}> = ({ warehouseName, insufficientInventoryObjects, onCancel, onConfirm }) => (
  <Modal onClose={onCancel}>
    <Modal.Content>
      <Modal.Header>
        <Modal.Title>Insufficient Inventory</Modal.Title>
        <Modal.Close close={onCancel} />
      </Modal.Header>
      <Modal.Body>
        <Text>
          Based on the current inventory and upcoming outbound loads, there may not be enough inventory in stock to ship
          the following SKUs from {warehouseName}:
        </Text>
        <Spacer height="8px" />
        <table className="table table-striped">
          <thead>
            <tr>
              <th className="col-md-1">SKU</th>
              <th className="col-md-1">Items in Storage</th>
              <th className="col-md-1">Existing Outbound Loads</th>
            </tr>
          </thead>
          <tbody>
            {insufficientInventoryObjects.map((insufficientInventoryObject, index) => (
              <Entry insufficientInventoryObject={insufficientInventoryObject} key={index} />
            ))}
          </tbody>
        </table>
      </Modal.Body>
      <Modal.Footer>
        {
          <div>
            <Button kind="secondary" onClick={onCancel}>
              Cancel
            </Button>
            <Button kind="warning" onClick={onConfirm}>
              Continue
            </Button>
          </div>
        }
      </Modal.Footer>
    </Modal.Content>
  </Modal>
);

interface IEntryProps {
  insufficientInventoryObject: InsufficientSKUInventoryObjects[number];
}

const Entry: React.FC<IEntryProps> = ({ insufficientInventoryObject }) => {
  const { id, value, storedCount, upcomingLoadsNeedingSku } = insufficientInventoryObject;
  return (
    <tr>
      <td className="col-md-1">{value}</td>
      <td className="col-md-1">{storedCount}</td>
      <td className="col-md-1">
        <table>
          {upcomingLoadsNeedingSku.map((load) => (
            <tr key={load.id}>
              <td>
                <Link href={logisticsDetailedLoadURL({ id: load.id })}>{load.number}</Link>
                <Text>
                  {' '}
                  -{' '}
                  {load.fulfillmentExpectations
                    .filter((expectation) => expectation.sku.id === id)
                    .reduce((sum, expectation) => sum + expectation.quantity, 0)}{' '}
                  Items
                </Text>
              </td>
            </tr>
          ))}
        </table>
      </td>
    </tr>
  );
};

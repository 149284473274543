import React from 'react';

import { LandingLocationRouteQuery, useLandingLocationRouteQuery } from '@admin/schema';

import { client } from '@admin/libraries/apollo';

import { Breadcrumb, AnchorButton as Link } from '@shared/components/bootstrap';
import { Panel } from '@admin/components/helpers/panel';
import { PageHeader } from '@admin/components/helpers/page_header';
import { Spinner } from '@admin/components/spinner';

import { List as ContentPanel } from '@admin/components/landing/location/content/list';

const Content: React.FC<{
  route: LandingLocationRouteQuery['route'];
}> = ({ route }) => (
  <>
    <Panel>
      <Panel.Body>
        <div>
          Slug: <strong>{route.slug}</strong>
        </div>
        <div>
          Origin: <strong>{route.origin.name}</strong>
        </div>
        <div>
          Destination: <strong>{route.destination.name}</strong>
        </div>
        <div>
          Featured: <strong>{route.featured ? '✅' : '❌'}</strong>
        </div>
      </Panel.Body>
      <Panel.Footer align="right">
        <Link kind="primary" href={`/landing/location/routes/${route.id}/edit`}>
          Edit
        </Link>
      </Panel.Footer>
    </Panel>
    <ContentPanel location={route} />
  </>
);

export const Details: React.FC<{
  id: string;
}> = ({ id }) => {
  const { data } = useLandingLocationRouteQuery({
    client,
    fetchPolicy: 'cache-and-network',
    variables: { id },
  });
  const route = data?.route;

  return (
    <>
      <PageHeader>
        <Breadcrumb>
          <Breadcrumb.Item>Landing</Breadcrumb.Item>
          <Breadcrumb.Item>Location</Breadcrumb.Item>
          <Breadcrumb.Item>
            <a href="/landing/location/routes">Routes</a>
          </Breadcrumb.Item>
          {id && <Breadcrumb.Item active>{route?.id ?? '-'}</Breadcrumb.Item>}
        </Breadcrumb>
      </PageHeader>
      {route ? <Content route={route} /> : <Spinner />}
    </>
  );
};

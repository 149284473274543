import angular from 'angular';
import template from '@admin/angular/templates/self_storage_shuttle_form.html';

const app = angular.module('app');

app.component('selfStorageShuttleForm', {
  template,
  bindings: {
    account: '<account',
    order: '<order',
  },
  controller: [
    'SelfStorageShuttle',
    'SELF_STORAGE_SHUTTLE_ATTRIBUTES',
    function (SelfStorageShuttle, SELF_STORAGE_SHUTTLE_ATTRIBUTES) {
      this.attributes = SELF_STORAGE_SHUTTLE_ATTRIBUTES;
      this.resource = SelfStorageShuttle;
    },
  ],
});

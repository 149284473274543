import { capitalize } from 'lodash';
import React from 'react';

import { Thumbnails } from '@admin/components/helpers/thumbnails';
import { Claim__ItemSelectionFragment } from '@admin/schema';
import { Currency } from '@shared/components/helpers';
import { ListGroup } from '@shared/components/bootstrap';

import { ItemLink } from '../item_link';
import { AssessmentSummary } from '../assessment_summary';

const ItemSelection: React.FC<{
  itemSelection: Claim__ItemSelectionFragment;
  accountID: string;
}> = ({ itemSelection, accountID }) => {
  const { declaration, item, kind, name, photos, productURL, receipts, resolution, assessment } = itemSelection;
  return (
    <div className="row">
      <div className="col-sm-2">
        <ItemLink accountID={accountID} itemID={item?.id} itemName={name ?? undefined} />
      </div>
      <div className="col-sm-1">{capitalize(kind)}</div>
      <div className="col-sm-1">
        <Thumbnails attachments={photos.concat(receipts)} condensed />
      </div>
      <div className="col-sm-3">
        <a className="btn-link text-overflow" href={productURL ?? undefined} target="_blank">
          {productURL}
        </a>
      </div>
      <div className="col-sm-1">
        <Currency value={Number(declaration || 0)} />
      </div>
      <div className="col-sm-2">
        <AssessmentSummary
          assessment={assessment ?? undefined}
          kind={resolution?.kind}
          reason={resolution?.reason}
          showWeight
        />
      </div>
      <div className="col-sm-1">{!!assessment && <Currency value={Number(assessment?.approvedAmount || 0)} />}</div>
      <div className="col-sm-1">{!!resolution && <Currency value={Number(resolution?.amount || 0)} />}</div>
    </div>
  );
};

export const ItemSelections: React.FC<{
  itemSelections: Claim__ItemSelectionFragment[];
  accountID: string;
}> = ({ itemSelections, accountID }) => (
  <>
    <ListGroup.Item>
      <div className="row">
        <div className="col-sm-2">
          <strong>Item</strong>
        </div>
        <div className="col-sm-1">
          <strong>Type</strong>
        </div>
        <div className="col-sm-1">
          <strong>Images</strong>
        </div>
        <div className="col-sm-3">
          <strong>URL</strong>
        </div>
        <div className="col-sm-1">
          <strong>Declared</strong>
        </div>
        <div className="col-sm-2">
          <strong>Classifications</strong>
        </div>
        <div className="col-sm-1">
          <strong>Approved</strong>
        </div>
        <div className="col-sm-1">
          <strong>Offered</strong>
        </div>
      </div>
    </ListGroup.Item>
    {itemSelections.map((selection) => (
      <ListGroup.Item key={selection.id}>
        <ItemSelection itemSelection={selection} accountID={accountID} />
      </ListGroup.Item>
    ))}
  </>
);

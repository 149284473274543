import React from 'react';
import axios from 'axios';

import { Button } from '@shared/components/bootstrap';
import {
  useConfirmation,
  Dialog as ConfirmationDialog,
  Response as ConfirmationResponse,
} from '@admin/components/confirmation';
import { useQA } from '@admin/hooks/use_qa';

export const DeleteButton: React.FC<{
  id: string;
}> = ({ id }) => {
  const qa = useQA();
  const { confirmation, confirm } = useConfirmation();
  if (!qa) return null;

  const onClick = async () => {
    const confirmed = await confirm({
      title: 'Are you sure you want to delete this account?',
      description: 'NOTE: some accounts may not be removable with this button and must be deleted manually.',
    });
    if (confirmed !== ConfirmationResponse.Confirm) return;

    axios.delete(`/accounts/${id}.json`).then(() => {
      location.assign('/accounts');
    });
  };

  return (
    <>
      {confirmation && <ConfirmationDialog confirmation={confirmation} />}

      <Button kind="danger" onClick={onClick} type="button">
        Delete
      </Button>
    </>
  );
};

import * as React from 'react';

import { NotesPanel } from '@admin/components/notes/panel';
import { TicketsPanel } from '@admin/components/tickets/panel';
import { Spinner } from '@admin/components/spinner';
import { ROLES } from '@admin/config/roles';
import { client } from '@admin/libraries/apollo';
import { Claim__Status, NoteNotableEnum, Ticket__TicketableEnum, useClaimQuery } from '@admin/schema';
import { UserRole } from '@admin/types';

import { Attributes } from './attributes';
import { OfferHistory } from './offer_history';
import { Specifications } from './specifications';

const EDITABLE_STATES = [Claim__Status.InReview, Claim__Status.Approved];

export const Details: React.FC<{ id: string }> = ({ id }) => {
  const { data, loading, error } = useClaimQuery({ client, variables: { id } });

  if (error) {
    return <div>There was an error loading this claim: {error.message}</div>;
  } else if (loading) {
    return <Spinner />;
  } else if (!data) {
    return <div>Claim not found</div>;
  }

  const { claim } = data;

  const editable = EDITABLE_STATES.includes(claim.status) && ROLES.includes(UserRole.Claims);

  return (
    <>
      <Attributes editable={editable} claim={claim} />
      <Specifications claim={claim} editable={editable} />
      <OfferHistory offers={claim.offers} />
      <TicketsPanel ticketable={{ id: claim.id, type: Ticket__TicketableEnum.Claim }} />
      <NotesPanel type={NoteNotableEnum.Claim} id={claim.id} />
    </>
  );
};

import * as React from 'react';

import { DateTime } from 'luxon';

import { TooltipPlacement } from '@shared/components/bootstrap/tooltip';

import { IOrderEvent } from '@admin/types';

import { COLORS } from '@root/colors';
import { Events } from './events';
import { getColorforEvent, KNOWN_EVENT_NAMES } from './utils';

const eventNameMap: { [key: string]: string } = {
  clockin: 'Clock In',
  navigated: 'Drive',
  entered: 'Park',
  parked: 'Prep',
  signed_approval: 'Order',
  exited_to_facility: 'Drive to Facility',
  facility_arrival: 'Truck Swap',
  ended: 'Depart',
  exited: 'Return to Depot',
  depot_arrival: 'Clock Out',
  canceled: 'Return to Depot',
};

export const BreakEventStream: React.FC<{
  events: IOrderEvent[];
  shade: number;
  placement?: TooltipPlacement;
  next?: DateTime;
}> = ({ events, shade, placement, next }) => {
  const knownEvents = events.filter(({ isBreakEvent }) => isBreakEvent);
  const isTerminalEvent = (eventName: string) => eventName.includes('break_end_');
  const getName = (_: string) => 'Break';

  const getColor = (_: string) => (shade > 0.5 ? COLORS.grayLight : COLORS.grayLightest);

  return (
    <Events
      events={knownEvents}
      isTerminalEvent={isTerminalEvent}
      getName={getName}
      getColor={getColor}
      placement={placement}
      final={next}
    />
  );
};

export const OrderEventStream: React.FC<{
  events: IOrderEvent[];
  shade: number;
  placement?: TooltipPlacement;
  next?: DateTime;
  isTerminalOrderEvent: (eventName: string) => boolean;
}> = ({ events, shade, placement, next, isTerminalOrderEvent }) => {
  const knownEvents = events.filter(({ eventName }) => KNOWN_EVENT_NAMES.includes(eventName));
  const getName = (eventName: string) => eventNameMap[eventName];

  const getColor = (eventName: string) => {
    const name = getName(eventName);
    return getColorforEvent(name)(shade);
  };

  return (
    <Events
      events={knownEvents}
      isTerminalEvent={isTerminalOrderEvent}
      getName={getName}
      getColor={getColor}
      placement={placement}
      final={next}
    />
  );
};

export const EventStream: React.FC<{
  events: IOrderEvent[];
  shade: number;
  placement?: TooltipPlacement;
  next?: DateTime;
  isTerminalOrderEvent: (eventName: string) => boolean;
}> = ({ events, shade, placement, next, isTerminalOrderEvent }) => (
  <div style={{ zIndex: 1, position: 'relative' }}>
    <OrderEventStream
      events={events}
      shade={shade}
      placement={placement}
      next={next}
      isTerminalOrderEvent={isTerminalOrderEvent}
    />
    <BreakEventStream events={events} shade={shade} placement={placement} next={next} />
  </div>
);

import * as React from 'react';
import { useState } from 'react';
import { Panel as Container } from '@admin/components/helpers/panel';
import { Widget } from './widget';

const DEFAULT_COLLAPSED = false;

export const TicketMessagesPanel: React.FC<{
  id: string;
}> = ({ id }) => {
  const [collapsed, setCollapsed] = useState<boolean>(DEFAULT_COLLAPSED);

  return (
    <Container>
      <Container.Header>
        <Container.Collapse collapsed={collapsed} onChange={setCollapsed} />
        <Container.Title>Ticket Messages</Container.Title>
      </Container.Header>
      {!collapsed && (
        <Container.Body>
          <Widget id={id} />
        </Container.Body>
      )}
    </Container>
  );
};

(function () {
  const app = angular.module('app');

  app.controller('EditCustomersFormController', [
    'Account',
    'AccountService',
    'ErrorService',
    'ngDialog',
    function (Account, AccountService, ErrorService, ngDialog) {
      const self = this;
      self.addCustomer = addCustomer;
      self.addPhone = addPhone;
      self.canSave = canSave;
      self.deletePhone = deletePhone;
      self.isEmptyCustomer = AccountService.isEmptyCustomer;
      self.$onInit = onInit;
      self.phoneTypes = ['unknown', 'cell', 'home', 'work'];
      self.shouldShowAddPhone = shouldShowAddPhone;
      self.shouldShowDeletePhone = shouldShowDeletePhone;
      self.togglePrimaryEmail = togglePrimaryEmail;
      self.togglePrimaryPhone = togglePrimaryPhone;
      self.update = update;
      self.consentFields = ['commercial_sms', 'transactional_sms', 'commercial_call', 'transactional_call'];
      self.consentFieldChecked = consentFieldChecked;
      self.consentFieldDisabled = consentFieldDisabled;
      self.consentInfoClick = consentInfoClick;

      function consentFieldChecked(phone, consentField) {
        const value = phone[consentField];
        if (consentField === 'transactional_sms' && !value) {
          phone.commercial_sms = false;
        } else if (consentField === 'transactional_call' && !value) {
          phone.commercial_call = false;
        } else if (consentField === 'commercial_sms' && value) {
          phone.transactional_sms = true;
        } else if (consentField === 'commercial_call' && value) {
          phone.transactional_call = true;
        }
      }

      function consentFieldDisabled(phone, consentField) {
        if (consentField === 'commercial_sms') {
          return !phone.transactional_sms;
        } else if (consentField === 'commercial_call') {
          return !phone.transactional_call;
        } else {
          return false;
        }
      }

      function consentInfoClick(consentField) {
        ngDialog.open({ template: `partials/customers/${consentField}_info_modal.html` });
      }

      function addCustomer() {
        self.customers.push(AccountService.getEmptyCustomer(self.account.id));
      }

      function addPhone(customer) {
        customer.phones = AccountService.addPhoneToList(customer.phones);
      }

      // Prevent the user from saving if no primary customer has been chosen.
      function canSave() {
        if (!self.customers) {
          return false;
        }

        const primaryCustomer = self.customers.filter(function (customer) {
          return customer.phones.some(function (phone) {
            return phone.primary;
          });
        });
        return primaryCustomer.length;
      }

      function deletePhone(customer, phone) {
        customer.phones = AccountService.deletePhoneFromView(phone, customer.phones);
      }

      function getNumberOfVisiblePhones(phones) {
        return AccountService.getNonDeletedPhones(phones).length;
      }

      function onInit() {
        self.account.$promise.then(function (account) {
          self.customers = AccountService.getCustomersToEdit(account.customers);
        });
      }

      function save(data) {
        Account.updateCustomers({ id: self.account.id }, { customers: data }).$promise.then(
          self.successHandler,
          function (error) {
            self.errorHandler({ error: error });
          },
        );
      }

      function shouldShowAddPhone(customer, phone) {
        const visiblePhones = AccountService.getNonDeletedPhones(customer.phones);
        return phone === visiblePhones[visiblePhones.length - 1];
      }

      // Prevent the "delete" button from showing up if there is only one phone
      // visible for the customer AND it is a new phone without any number
      // entered yet. In this case, hitting "delete" wouldn't do anything anyway.
      function shouldShowDeletePhone(customer, phone) {
        if (phone.number || phone.id || getNumberOfVisiblePhones(customer.phones) > 1) {
          return true;
        } else {
          return false;
        }
      }

      function togglePrimaryEmail(customer) {
        if (customer.primary_email) {
          customer.primary_email = false;
        } else {
          self.customers = AccountService.setPrimaryEmail(self.customers, customer.tempId);
        }
      }

      function togglePrimaryPhone(phone) {
        if (phone.primary) {
          phone.primary = false;
        } else {
          self.customers = AccountService.setPrimaryPhone(self.customers, phone.tempId);
        }
      }

      function update() {
        const data = AccountService.getCustomersToSave(self.customers);
        const report = AccountService.validateCustomers(data);
        if (report.isValid) {
          save(data);
        } else {
          ErrorService.refreshBanner({ message: report.messages });
        }
      }
    },
  ]);
})();

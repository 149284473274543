/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import angular from 'angular';

const app = angular.module('app');

app.service('EstimatedReturnMoverCountService', [
  'EstimatedReturnMoverCount',
  function EstimatedReturnMoverCountService(EstimatedReturnMoverCount) {
    this.process = (itemIds, context = {}) => {
      const params = {};
      params.item_ids = itemIds;
      params.address_id = context?.address?.id;

      EstimatedReturnMoverCount.get(params).$promise.then(({ estimated_mover_count }) => {
        context.movers = estimated_mover_count;
      });
    };
  },
]);

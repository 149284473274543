import { useField } from 'formik';
import * as React from 'react';

import { HTMLFormControl, HTMLFormControlProps } from '../html_form_control';

type FormikHTMLFormControlProps = Omit<HTMLFormControlProps, 'value' | 'onChange'> & { name: string };

export const FormikHTMLFormControl: React.FC<FormikHTMLFormControlProps> = ({ name, ...props }) => {
  const [{ value }, _, { setValue }] = useField(name);
  return <HTMLFormControl {...props} value={value} onChange={setValue} />;
};

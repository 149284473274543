import angular from 'angular';

const app = angular.module('app');

app.factory('MessageFollowup', [
  '$resource',
  ($resource) =>
    $resource('/messages/:message_id/followups.json', {
      message_id: '@message_id',
    }),
]);

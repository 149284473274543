(function () {
  angular.module('app').factory('DispatchSerializer', [
    function () {
      return function (data) {
        const parameters = angular.copy(data);

        if (parameters.region) {
          parameters.region_id = parameters.region.id;
          delete parameters.region;
        }

        if (parameters.warehouse) {
          parameters.warehouse_id = parameters.warehouse.id;
          delete parameters.warehouse;
        }

        if (parameters.depot) {
          parameters.depot_id = parameters.depot.id;
          delete parameters.depot;
        }

        if (parameters.orders) {
          parameters.order_ids = _.map(parameters.orders, function (order) {
            return order.id;
          });

          delete parameters.orders;
        }

        if (parameters.vehicles) {
          parameters.vehicle_ids = _.map(parameters.vehicles, function (vehicle) {
            return vehicle.id;
          });

          delete parameters.vehicles;
        }

        if (parameters.assignments) {
          parameters.assignments_attributes = _.map(parameters.assignments, function (assignment) {
            return { id: assignment.id, role: assignment.role, state: assignment.state, user_id: assignment.user.id };
          });

          delete parameters.assignments;
        }

        return parameters;
      };
    },
  ]);
})();

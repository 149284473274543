/* eslint-disable no-param-reassign */

import angular from 'angular';

const app = angular.module('app');

app.service('LeadSavingService', [
  '$rootScope',
  'FollowupService',
  'LeadObjectionService',
  function ($rootScope, FollowupService, LeadObjectionService) {
    const updateLead = (lead, successCallback, errorCallback) => {
      if (lead.phone && lead.phone.substring(0, 1) !== '+') {
        lead.phone = `+1${lead.phone}`;
      }

      lead.$update(() => {
        // why not call the notes callback directly here?
        successCallback(lead);

        /*
        What happens if noteSaved fails? The state won't transition and the user
        might click Save Lead again (same if triggerWarming fails). This can be avoided
        if we don't use events and instead use a Service that returns a promise.

          I'd think that if the lead saves but the note or triggerWarming fails,
          we'd want to transition to the leads index anyway and notify the user of the error.
          */
      }, errorCallback);
    };

    const createLead = (lead, successCallback, errorCallback) => {
      lead.$create(
        (response) => {
          lead.id = Number(response.id);
          if (successCallback !== undefined) {
            successCallback(lead);
          }
        },
        (error) => {
          if (errorCallback !== undefined) {
            errorCallback(error);
          }
          $rootScope.$broadcast('message:group:display', error.data);
        },
      );
    };

    const setDefaultObjections = function setDefaultObjections(lead) {
      if (!lead.objection_reason) {
        lead.objection_reason = lead.originalObjection;
      }
      if (!lead.self_storage_objection_reason) {
        lead.self_storage_objection_reason = lead.originalSelfStorageObjection;
      }
    };

    this.save = function (lead, followupSelections, successCallback, errorCallback) {
      if (!(lead.phone || lead.email)) {
        errorCallback({ message: "Can't save a lead without a phone or email specified" });
        return;
      }

      if (LeadObjectionService.isFollowupScheduledObjection(lead.objection_reason)) {
        lead.followup_scheduled_at = FollowupService.getFollowupTime(followupSelections);
        if (followupSelections.timeZone) {
          lead.dst_flag = followupSelections.timeZone.dst_flag;
          lead.time_zone_offset = followupSelections.timeZone.time_zone_offset;
        }
      }

      setDefaultObjections(lead);

      if (lead.id) {
        updateLead(lead, successCallback, errorCallback);
      } else {
        createLead(lead, successCallback, errorCallback);
      }
    };
  },
]);

import angular from 'angular';
import { throttle } from 'lodash';

const DRAG_THROTTLE_PERIOD = 400; // ms
const app = angular.module('app');

app.directive('drag', [
  '$parse',
  function directive($parse) {
    return {
      restrict: 'A',
      scope: {
        drag: '=',
      },
      link: function link(scope, element, attrs) {
        function handle(name, event) {
          const raw = attrs[name];
          if (raw) {
            const fn = $parse(raw);
            scope.$apply(() => {
              fn(scope.$parent, { $event: event });
            });
          }
        }

        function dragstart(event) {
          handle('dragstart', event);
        }

        function dragmove(event) {
          handle('dragmove', event);
        }

        function dragend(event) {
          handle('dragend', event);
        }

        function configure(enabled) {
          element.attr('draggable', enabled);
          const method = enabled ? 'bind' : 'unbind';
          element[method]('dragstart', dragstart);
          element[method]('dragend', dragend);
          element[method]('drag', throttle(dragmove, DRAG_THROTTLE_PERIOD));
        }

        scope.$watch('drag', configure);
      },
    };
  },
]);

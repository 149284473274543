(function () {
  angular.module('app').factory('Pricing', [
    '$resource',
    function ($resource) {
      return $resource('/plans/:plan_id/pricings.json', {
        plan_id: '@plan_id',
      });
    },
  ]);
})();

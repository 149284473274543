import { SelfStorage__RentInput } from '@admin/schema';
import { useState } from 'react';

import { ISigning } from './signing';
import { Status } from './status';

interface IPromise {
  status: Status;
  signatureIDs?: string[];
}

export const useSign = () => {
  const [signing, setSigning] = useState<ISigning | undefined>();

  const sign = (input: SelfStorage__RentInput) =>
    new Promise<IPromise>((callback) => {
      setSigning({
        input,
        onCancel: () => {
          setSigning(undefined);
          callback({ status: Status.Cancel });
        },
        onSubmit: (signatureIDs?: string[]) => {
          setSigning(undefined);
          callback({ status: Status.Submit, signatureIDs });
        },
      });
    });

  return { sign, signing };
};

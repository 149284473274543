import { SelfStorage__PromotionFragment, SelfStorage__Promotion__Kind } from '@admin/schema';

export function monthlySavings(amount: number, promotion: SelfStorage__PromotionFragment) {
  if (promotion.delay) {
    return 0;
  }
  switch (promotion.kind) {
    case SelfStorage__Promotion__Kind.Amount:
      return promotion.value;
    case SelfStorage__Promotion__Kind.Override:
      return amount - promotion.value;
    case SelfStorage__Promotion__Kind.Percent:
      return (amount * promotion.value) / 100.0;
  }
}

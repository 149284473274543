import React, { useEffect } from 'react';

import { Thumbnails } from '@admin/components/helpers/thumbnails';
import { Claim__PropertySelectionFragment } from '@admin/schema';
import { ListGroup } from '@shared/components/bootstrap';
import { Currency } from '@shared/components/helpers';

import { IResolutionEdits } from './helpers';
import { Selection } from './selection';
import { compensated } from '../helpers';

export const PropertySelection: React.FC<{
  selection: Claim__PropertySelectionFragment;
  edit: IResolutionEdits;
  setEdit(edit: IResolutionEdits): void;
}> = ({ selection, edit, setEdit }) => {
  const { id, name, photos, quotes, owner, resolution, assessment } = selection;

  useEffect(() => {
    if (resolution?.amount) {
      return;
    }
    const amount = compensated(selection) ? assessment?.approvedAmount : 0;
    setEdit({ ...edit, [id]: amount ?? undefined });
  }, []);

  return (
    <>
      <ListGroup.Item>
        <div className="row">
          <div className="col-sm-2">
            <strong>Property</strong>
          </div>
          <div className="col-sm-1">
            <strong>Type</strong>
          </div>
          <div className="col-sm-1">
            <strong>Images</strong>
          </div>
          <div className="col-sm-2">
            <strong>Owner</strong>
          </div>
          <div className="col-sm-1">
            <strong>Declared</strong>
          </div>
          <div className="col-sm-2">
            <strong>Classifications</strong>
          </div>
          <div className="col-sm-1">
            <strong>Approved</strong>
          </div>
          <div className="col-sm-2">
            <strong>Offered</strong>
          </div>
        </div>
      </ListGroup.Item>
      <ListGroup.Item key={id}>
        <div className="row">
          <div className="col-sm-2">{name}</div>
          <div className="col-sm-1">Property Damage</div>
          <div className="col-sm-1">
            <Thumbnails attachments={photos.concat(quotes)} condensed />
          </div>
          <div className="col-sm-2">
            {!owner ? (
              'Customer'
            ) : (
              <>
                <div>{owner.name}</div>
                <div>{owner.email}</div>
                <div>{owner.phone}</div>
              </>
            )}
          </div>
          <div className="col-sm-1">
            <Currency value={Number(selection.declaration || 0)} />
          </div>
          <Selection selection={selection} edits={edit} setEdits={setEdit} />
        </div>
      </ListGroup.Item>
    </>
  );
};

import angular from 'angular';

const app = angular.module('app');

app.run([
  '$rootScope',
  ($rootScope) => {
    Object.assign($rootScope, {
      DOUBLE_L_CURLY_BRACE: '{{',
      DOUBLE_R_CURLY_BRACE: '}}',
    });
  },
]);

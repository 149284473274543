import * as React from 'react';

import { formatPhoneForHuman } from '@admin/utils/format_phone_for_human';

export const usePhoneFormatter = (number: string) => {
  const formatted = formatPhoneForHuman(number);
  const international = !!number && number.charAt(0) === '+' && number.charAt(1) !== '1';

  return {
    formatted,
    international,
  };
};

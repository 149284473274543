(function () {
  angular.module('app').controller('UserCallGroupManagerController', [
    '$scope',
    'UserCallGroup',
    'users',
    'callGroups',
    'userCallGroups',
    function ($scope, UserCallGroup, users, callGroups, userCallGroups) {
      $scope.ucgKey = function (userId, callGroupId) {
        return userId + ':' + callGroupId;
      };

      $scope.users = users;
      $scope.callGroups = callGroups;
      $scope.userCallGroups = {};

      angular.forEach(userCallGroups, function (item) {
        const key = $scope.ucgKey(item.user_id, item.call_group_id);
        $scope.userCallGroups[key] = item;
      });

      $scope.toggle = function (user, callGroup) {
        const key = $scope.ucgKey(user.id, callGroup.id);
        let join = $scope.userCallGroups[key];
        if (join) {
          join.$delete(function () {
            $scope.userCallGroups[key] = null;
          });
        } else {
          join = new UserCallGroup({
            user_id: user.id,
            call_group_id: callGroup.id,
          });
          join.$save(function () {
            $scope.userCallGroups[key] = join;
          });
        }
      };
    },
  ]);
})();

import * as React from 'react';

import { client } from '@admin/libraries/apollo';
import { useDispatcherDropMoverDispatchQuery } from '@admin/schema';

import { ErrorIcon, Status } from '@admin/components/helpers/error_icon';
import { TooltipPlacement } from '@shared/components/bootstrap/tooltip';

type Error = {
  message: string;
  status: Status;
};

type MoverErrorIconProps = {
  errors: Error[];
  dispatchID: string;
};

export const MoverErrorIcon: React.FC<MoverErrorIconProps> = ({ errors, dispatchID }) => {
  const { data, loading } = useDispatcherDropMoverDispatchQuery({
    client,
    variables: { dispatchID },
    fetchPolicy: 'network-only',
  });

  if (!data || loading) {
    const worstStatus = errors.reduce(
      (currentWorstStatus, { status }) => Math.max(status, currentWorstStatus),
      Status.Good,
    );

    return (
      <ErrorIcon
        errors={errors.map(({ message }) => message)}
        icon="users"
        status={worstStatus}
        placement={TooltipPlacement.Bottom}
      />
    );
  }

  const {
    dispatch: { canDropMover },
  } = data;

  const dropMoverCountError = {
    message: 'Drop at least 1 mover to make a more preferable dispatch.',
    status: Status.Bad,
  };

  const newErrors = canDropMover ? [...errors, dropMoverCountError] : errors;

  const worstStatus = newErrors.reduce(
    (currentWorstStatus, { status }) => Math.max(status, currentWorstStatus),
    Status.Good,
  );

  return (
    <ErrorIcon
      errors={newErrors.map(({ message }) => message)}
      icon="users"
      status={worstStatus}
      placement={TooltipPlacement.Bottom}
    />
  );
};

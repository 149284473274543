import * as React from 'react';

import { client } from '@admin/libraries/apollo';
import { useOrderEstimatedCuftQuery } from '@admin/schema';

export const EstimatedCUFT: React.FC<{
  orderID: number;
}> = ({ orderID }) => {
  const { data } = useOrderEstimatedCuftQuery({
    client,
    variables: { orderID: String(orderID) },
  });
  if (!data?.order) return null;
  return <>{data.order.estimatedCuft.toFixed(2)}</>;
};

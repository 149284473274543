import styled from '@emotion/styled';
import React, { useState } from 'react';

import { Panel } from '@admin/components/helpers/panel';
import { Spinner } from '@admin/components/spinner';
import { Alert, AnchorButton as Link, Breadcrumb, Button } from '@shared/components/bootstrap';
import { Currency, Spacer } from '@shared/components/helpers';
import { accountURL } from '@admin/config/routes';
import {
  Status,
  SelfStorage__Status as SelfStorage__Rental__Status,
  useModifySelfStorageBillingMutation,
  useAccountWithSelfStorageRentalsAndBillingDetailsQuery,
  AccountWithSelfStorageRentalsAndBillingDetailsDocument,
  SelfStorageCurrentFacilityFeesDocument,
} from '@admin/schema';
import { client } from '@admin/libraries/apollo';
import { Facility } from './components/facility';
import { Totals } from './components/helpers';

import { buildEditInputs, buildFacilityRentalTuples, buildTotals } from './helpers';

import { IElectricityEdits, IFeeEdits, IPriceEdits, IPromotionEdits, IProtectionEdits } from './types';

const Summary = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 5px;
`;

const Buttons = styled.div`
  display: flex;
`;

export const Edit: React.FC<{ accountID: string; onSave(): void }> = ({ accountID, onSave }) => {
  const [save, { loading, data: editBilling }] = useModifySelfStorageBillingMutation({
    client,
    refetchQueries: [
      { query: AccountWithSelfStorageRentalsAndBillingDetailsDocument, variables: { accountID } },
      { query: SelfStorageCurrentFacilityFeesDocument, variables: { accountID } },
    ],
  });
  const { data } = useAccountWithSelfStorageRentalsAndBillingDetailsQuery({ client, variables: { accountID } });
  const account = data?.account;
  const rentals = account?.rentals?.filter((rental) => rental.status === SelfStorage__Rental__Status.Active);
  const facilities = rentals?.length ? buildFacilityRentalTuples(rentals) : undefined;
  const [priceEdits, setPriceEdits] = useState<IPriceEdits>({});
  const [protectionEdits, setProtectionEdits] = useState<IProtectionEdits>({});
  const [promotionEdits, setPromotionEdits] = useState<IPromotionEdits>({});
  const [electricityEdits, setElectricityEdits] = useState<IElectricityEdits>({});
  const [feeEdits, setFeeEdits] = useState<IFeeEdits>({});

  const totals = buildTotals(facilities, priceEdits, protectionEdits, promotionEdits, electricityEdits, feeEdits);

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const inputs = buildEditInputs(rentals!, priceEdits, protectionEdits, promotionEdits, electricityEdits, feeEdits);
    const response = await save({
      variables: {
        accountID: account!.id,
        inputs,
      },
    });

    if (response && response.data?.result?.status === Status.Ok) {
      onSave();
    }
  };

  return (
    <Panel>
      <Panel.Header>
        <Panel.Title>
          <Breadcrumb>
            <Breadcrumb.Item>
              <a href="/accounts">Accounts</a>
            </Breadcrumb.Item>
            {account && (
              <>
                <Breadcrumb.Item>
                  <a href={accountURL(account)}>
                    #{account.number} ({account.customer.name})
                  </a>
                </Breadcrumb.Item>
                <Breadcrumb.Item active>Edit Self-Storage Billing</Breadcrumb.Item>
              </>
            )}
          </Breadcrumb>
        </Panel.Title>
      </Panel.Header>
      <Panel.Body>
        {!rentals && <Spinner />}
        {rentals && !rentals.length && <Alert style="info">This account does not have any self-storage rentals.</Alert>}
        <form id="editBilling" onSubmit={onSubmit}>
          {totals &&
            account &&
            facilities?.map((facilityRentalTuple, idx) => {
              const facility = facilityRentalTuple[0];
              return (
                <Facility
                  key={facility.id}
                  accountID={account.id}
                  facility={facility}
                  rentals={facilityRentalTuple[1]}
                  totals={totals?.facilities[facility.id]}
                  priceEdits={priceEdits}
                  setPriceEdits={setPriceEdits}
                  protectionEdits={protectionEdits}
                  setProtectionEdits={setProtectionEdits}
                  promotionEdits={promotionEdits}
                  setPromotionEdits={setPromotionEdits}
                  electricityEdits={electricityEdits}
                  setElectricityEdits={setElectricityEdits}
                  feeEdits={feeEdits[facility.id] || new Map()}
                  setFeeEdits={(edits) => setFeeEdits({ ...feeEdits, [facility.id]: edits })}
                  isLastFacility={idx === facilities.length - 1}
                />
              );
            })}
          {editBilling?.result?.error && <Alert style="danger">{editBilling?.result?.error}</Alert>}
        </form>
      </Panel.Body>
      <Panel.Footer>
        <Summary>
          {account && (
            <Buttons>
              <Button
                type="submit"
                form="editBilling"
                kind="primary"
                loading={loading}
                disabled={typeof totals?.oldTotal !== 'number'}
              >
                Save Billing Adjustments
              </Button>
              <Spacer width="8px" />
              <Link disabled={loading} kind="default" href={accountURL(account)}>
                Cancel
              </Link>
            </Buttons>
          )}
          {totals && (
            <Totals>
              <tbody>
                {typeof totals.oldTotal === 'number' && (
                  <tr>
                    <td>Original Total:</td>
                    <td>
                      <Currency value={totals.oldTotal} />
                    </td>
                  </tr>
                )}
                <tr>
                  <td>Total:</td>
                  <td>
                    <Currency value={totals.total} />
                  </td>
                </tr>
              </tbody>
            </Totals>
          )}
        </Summary>
      </Panel.Footer>
    </Panel>
  );
};

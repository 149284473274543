import styled from '@emotion/styled';
import { faDollarSign } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { useState } from 'react';

import { Alert, Button, InputGroup, FormGroup, Modal } from '@shared/components/bootstrap';

import { CurrencyFormControl, NestedFormControl } from '@admin/components/fields';

import { Currency } from '@shared/components/helpers';

import { CREDIT_REASONS } from '@admin/constants/credit_reasons';

import { Status, useBillingChargeRefundMutation } from '@admin/schema';
import { client } from '@admin/libraries/apollo';

import { dispatchDocumentEvent } from '@shared/hooks';

const Title = styled.div`
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  text-align: center;
`;

const Description = styled.div`
  font-size: 18px;
  font-weight: 200;
  line-height: 24px;
  text-align: center;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -8px;
`;

const Action = styled.div`
  padding: 8px;
`;

type Reason = {
  category: string;
  name: string;
};

// NOTE: required for legacy form / component interactions.
const broadcast = () => {
  dispatchDocumentEvent('broadcast', {
    name: 'message:display',
    data: {
      type: 'info',
      body: 'Charge Succesfully Refunded!',
    },
  });
  dispatchDocumentEvent('broadcast', {
    name: 'inlineform:saved',
    data: {
      form: 'chargesNew',
    },
  });
};

export const Dialog: React.FC<{
  refundable: {
    id: string;
    receivable_id?: string;
    amount: number | string;
  };
  onClose(): void;
}> = ({ refundable, onClose }) => {
  const [execute, { loading, data }] = useBillingChargeRefundMutation({ client });
  const [amount, setAmount] = useState<number | undefined>(Number(refundable.amount));
  const [notes, setNotes] = useState<string | undefined>();
  const [reason, setReason] = useState<Reason | undefined>();

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    event.stopPropagation();
    if (loading || !amount || !notes || !reason) {
      return;
    }

    const status = (
      await execute({
        variables: {
          id: refundable.receivable_id ?? refundable.id,
          input: {
            amount,
            notes: `${reason.category} - ${reason.name} - ${notes}`,
          },
        },
      })
    )?.data?.result.status;
    if (status === Status.Ok) {
      onClose();
      broadcast();
    }
  };

  return (
    <Modal onClose={() => !loading && onClose()}>
      <form onSubmit={onSubmit}>
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>
              <Title>Refund Invoice: #{refundable.id}</Title>
              <Description>
                Total Paid: <Currency value={Number(refundable.amount)} />
              </Description>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {data?.result?.error && <Alert style="danger">{data.result.error}</Alert>}
            <FormGroup>
              <InputGroup>
                <InputGroup.Addon>
                  <FontAwesomeIcon icon={faDollarSign} />
                </InputGroup.Addon>
                <CurrencyFormControl placeholder="0.00" name="refund[amount]" amount={amount} onChange={setAmount} />
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <NestedFormControl
                prompt="- Select -"
                name="refund[reason]"
                selection={reason}
                onSelect={setReason}
                options={CREDIT_REASONS}
              />
            </FormGroup>
            <FormGroup>
              <input
                name="refund[notes]"
                placeholder="Notes"
                value={notes || ''}
                onChange={(event) => setNotes(event.target.value || undefined)}
                type="text"
                className="form-control"
              />
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <Actions>
              <Action>
                <Button type="button" kind="default" size="lg" onClick={onClose} disabled={loading}>
                  Cancel
                </Button>
              </Action>
              <Action>
                <Button
                  type="submit"
                  kind="primary"
                  size="lg"
                  disabled={!amount || !notes || !reason}
                  loading={loading}
                >
                  Refund
                </Button>
              </Action>
            </Actions>
          </Modal.Footer>
        </Modal.Content>
      </form>
    </Modal>
  );
};

(function () {
  const app = angular.module('app');

  app.directive('resourceStateLabel', () => ({
    restrict: 'E',
    transclude: true,
    scope: {
      state: '=',
      resource: '@',
    },
    controller: [
      '$scope',
      function ($scope) {
        const mappings = {
          incident() {
            switch ($scope.state) {
              case 'open':
                return 'label-warning';
              case 'resolved':
                return 'label-success';
              default:
                return 'label-default';
            }
          },
          account() {
            switch ($scope.state) {
              case 'pending':
                return 'label-primary';
              case 'active':
                return 'label-success';
              case 'canceled':
                return 'label-warning';
              case 'suspended':
                return 'label-danger';
              default:
                return 'label-default';
            }
          },
          user() {
            switch ($scope.state) {
              case 'cool_down':
                return 'label-primary';
              case 'available':
                return 'label-success';
              case 'alerting':
                return 'label-warning';
              case 'on_call':
                return 'label-danger';
              default:
                return 'label-default';
            }
          },
          lead() {
            switch ($scope.state) {
              case 'won':
                return 'label-primary';
              case 'action_required':
                return 'label-success';
              case 'open':
                return 'label-warning';
              case 'closed':
                return 'label-danger';
              default:
                return 'label-default';
            }
          },
          invoice() {
            switch ($scope.state) {
              case 'refunded':
                return 'label-primary';
              case 'paid':
                return 'label-success';
              case 'failed':
                return 'label-warning';
              case 'final attempt failed':
                return 'label-danger';
              case 'not attempted':
                return 'label-info';
              default:
                return 'label-default';
            }
          },
          grade() {
            switch ($scope.state) {
              case 'good':
                return 'label-success';
              case 'acceptable':
                return 'label-warning';
              case 'fail':
                return 'label-danger';
              default:
                return 'label-default';
            }
          },
          selfStorageReservation() {
            switch ($scope.state) {
              case 'canceled':
                return 'label-default';
              case 'lost':
                return 'label-danger';
              case 'requested':
                return 'label-warning';
              case 'won':
                return 'label-success';
              default:
                return 'label-info';
            }
          },
          misc() {
            switch ($scope.state) {
              case 'lead':
                return 'label-primary';
              case 'accepted':
                return 'label-success';
              case 'rejected':
                return 'label-warning';
              case 'canceled':
                return 'label-danger';
              case 'completed':
                return 'label-info';
              default:
                return 'label-default';
            }
          },
        };

        $scope.styling = function () {
          return (mappings[$scope.resource] || mappings.misc)();
        };
      },
    ],
    template:
      '<span class="label {{styling()}}"> <span>{{state | titleize}}</span> <span ng-transclude></span> </span>',
  }));
})();

import React, { useState } from 'react';

import { Panel } from '@admin/components/helpers/panel';
import { Claim__OfferFragment } from '@admin/schema';
import { Table } from '@shared/components/bootstrap';
import { Currency, Timestamp } from '@shared/components/helpers';

const DEFAULT_COLLAPSED = false;

export const OfferHistory: React.FC<{
  offers: Claim__OfferFragment[];
}> = ({ offers }) => {
  const [collapsed, setCollapsed] = useState<boolean>(DEFAULT_COLLAPSED);

  return (
    <Panel>
      <Panel.Header>
        <Panel.Collapse collapsed={collapsed} onChange={setCollapsed} />
        <Panel.Title>Offer History</Panel.Title>
      </Panel.Header>
      {!collapsed && (
        <Panel.Body>
          <Table striped responsive>
            <thead>
              <tr>
                <th className="col-sm-2">Reviewer</th>
                <th className="col-sm-2">Created At</th>
                <th className="col-sm-2">Total Amount Offered</th>
                <th className="col-sm-6">Details</th>
              </tr>
            </thead>
            <tbody>
              {offers &&
                offers.map((offer, key) => (
                  <tr key={key}>
                    <td className="col-sm-2">{offer.user.name}</td>
                    <td className="col-sm-2">
                      <Timestamp value={offer.createdAt} format={Timestamp.Format.DateTime} />
                    </td>
                    <td className="col-sm-2">
                      <Currency value={offer.totalAmount} />
                    </td>
                    <td className="col-sm-6 text-left">
                      <p className="pre-wrap">{offer.details}</p>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Panel.Body>
      )}
    </Panel>
  );
};

(function () {
  const app = angular.module('app');

  app.component('itemGroupableGridComponent', {
    templateUrl: 'partials/items/groupable.html',
    bindings: {
      groupedItems: '=',
      selections: '=?',
    },
  });
})();

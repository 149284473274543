import angular from 'angular';

export const MONTHS = [
  'January',
  'Febuary',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
const app = angular.module('app');

app.constant('Months', {
  NAMES: MONTHS,
});

import React from 'react';
import { DateTime } from 'luxon';

import { AvailabilityJobAvailabilityQuery, Availability__BlockReasonEnum } from '@admin/schema';

import { Table } from '@shared/components/bootstrap';

import { Option } from '../../indeterminate_checkbox_form_group';
import { KIND_TO_DISPLAY_MAPPING } from '../utils';
import { formatReasonAsString } from '../../util';

export const Entries: React.FC<{
  availabilities: AvailabilityJobAvailabilityQuery['paginated']['results'];
  selectedOptions: Option[];
  setSelectedOptions: React.Dispatch<React.SetStateAction<Option[]>>;
}> = ({ availabilities, selectedOptions, setSelectedOptions }) => (
  <Table striped responsive>
    <thead>
      <tr>
        <th className="text-left" />
        <th className="text-left">Date</th>
        <th className="text-left">Region</th>
        <th className="text-left">Reason</th>
        <th className="text-left">Kind</th>
        <th className="text-left">Maximum Mover Minutes</th>
        <th className="text-left">Booked Mover Minutes</th>
        <th className="text-left">Added By</th>
      </tr>
    </thead>
    <tbody>
      {availabilities.map(
        ({ __typename, id, date, region, displayKind, maximumMoverMinutes, bookedMoverMinutes, creator, reason }) => (
          <tr key={id}>
            <td>
              <input
                data-test-id={`toDelete${id}`}
                checked={!!selectedOptions.find((option) => option.id === id && option.__typename === __typename)}
                type="checkbox"
                onChange={(event) => {
                  const checked = event.target.checked;
                  setSelectedOptions((current) => {
                    if (checked) {
                      return Array.from(new Set([...current, { __typename: __typename!, id }]));
                    } else {
                      return current.filter((option) => !(option.id === id && option.__typename === __typename));
                    }
                  });
                }}
                disabled={reason === Availability__BlockReasonEnum.WeeklySchedule}
              />
            </td>
            <td className="text-left">{DateTime.fromISO(date).toLocaleString()}</td>
            <td className="text-left">{region.name}</td>
            <td className="text-left">{reason && formatReasonAsString(reason)}</td>
            <td className="text-left">{KIND_TO_DISPLAY_MAPPING[displayKind]}</td>
            <td className="text-left">{maximumMoverMinutes}</td>
            <td className="text-left">{bookedMoverMinutes}</td>
            <td className="text-left">{creator.name}</td>
          </tr>
        ),
      )}
    </tbody>
  </Table>
);

import * as React from 'react';
import { Table } from '@shared/components/bootstrap';

import { DispatchVehicleSealImagesQuery } from '@admin/schema';

export const InfoTable: React.FC<{ dispatch: DispatchVehicleSealImagesQuery['dispatch'] }> = ({ dispatch }) => (
  <>
    <Table responsive>
      <thead>
        <tr className="vehicle-info__row">
          <th className="col-sm-2 text-left">Assigned Vehicle</th>
          <th className="col-sm-2 text-left">Preferred Vehicles</th>
          <th className="col-sm-2 text-left">Allowed Vehicle Types</th>
          <th className="col-sm-2 text-left">Min Vehicles Allowed</th>
          <th className="col-sm-2 text-left">Max Vehicles Allowed</th>
          <th className="col-sm-2 text-left">Min Total Truck CUFT</th>
        </tr>
      </thead>
      <tbody>
        <tr className="vehicle-info__row">
          <td className="col-sm-2 text-left">
            {dispatch.dispatchesVehicles?.map((v) => v.vehicle.serialCode).join(', ')}
          </td>
          <td className="col-sm-2 text-left">
            {dispatch.vehiclePreferences?.map((vp) => `${vp.quantity} ${vp.vehicleType.name} truck`).join(', ')}
          </td>
          <td className="col-sm-2 text-left">
            {dispatch.vehicleRecipe?.allowedVehicleTypes?.map((vt) => vt.name).join(', ')}
          </td>
          <td className="col-sm-2 text-left">{dispatch.vehicleRecipe?.minQuantity}</td>
          <td className="col-sm-2 text-left">{dispatch.vehicleRecipe?.maxQuantity}</td>
          <td className="col-sm-2 text-left">{dispatch.vehicleRecipe?.minimumCuft}</td>
        </tr>
      </tbody>
    </Table>
    {dispatch.vehicleRecipe?.notes && (
      <div>
        <strong>Vehicle Request Notes: </strong>
        {dispatch.vehicleRecipe?.notes}
      </div>
    )}
  </>
);

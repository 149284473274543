import React from 'react';
import { Duration } from 'luxon';

import { COLORS } from '@clutter/clean';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputGroup } from '@shared/components/bootstrap';

// Workaround to allow global className styling from bootstrap as well as
// emotion styling changes
const InputGroupAddonStyle = styled.div`
  font-size: 12px;
  background-color: ${COLORS.grayBackground};
`;

export const DurationInput: React.FC<{
  duration: number;
  unit: 'seconds' | 'minutes';
  onChange(duration: number): void;
}> = ({ duration, unit, onChange }) => {
  const durationObject = Duration.fromObject({
    hours: 0,
    minutes: unit === 'minutes' ? duration : 0,
    seconds: unit === 'seconds' ? duration : 0,
  }).normalize();

  return (
    <InputGroup>
      <InputGroup.Addon>
        <FontAwesomeIcon icon="stopwatch" />
      </InputGroup.Addon>
      <input
        id="duration-hours"
        type="number"
        className="form-control"
        value={durationObject.hours.toString()}
        onChange={(event) => {
          const newDuration = durationObject.set({ hours: parseInt(event.target.value) || 0 });
          onChange(newDuration.as(unit));
        }}
      />
      <InputGroupAddonStyle className="input-group-addon">hrs</InputGroupAddonStyle>
      <input
        id="duration-minutes"
        type="number"
        className="form-control"
        value={durationObject.minutes.toString()}
        onChange={(event) => {
          const newDuration = durationObject.set({ minutes: parseInt(event.target.value) || 0 });
          onChange(newDuration.as(unit));
        }}
      />
      <InputGroupAddonStyle className="input-group-addon">mins</InputGroupAddonStyle>
    </InputGroup>
  );
};

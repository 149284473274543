import angular from 'angular';

const app = angular.module('app');

app.directive('clickoutside', () => ({
  restrict: 'A',
  scope: {
    onClickOutside: '&',
  },
  link(scope, element, attrs) {
    window.addEventListener(
      'click',
      (event) => {
        const clickedEl = event.target;
        let isDescendant = element.find(clickedEl).length > 0;

        const exceptions = attrs.exceptions ? attrs.exceptions.split(',') : [];
        if (exceptions.length > 0 && !isDescendant) {
          for (let i = 0; i < exceptions.length; i++) {
            const exceptionEl = angular.element(exceptions[i]);
            isDescendant = exceptionEl.find(clickedEl).length > 0;
            if (isDescendant) break;
          }
        }

        if (!isDescendant) {
          scope.onClickOutside();
        }
      },
      {},
      true,
    );
  },
}));

import * as React from 'react';

import styled from '@emotion/styled';

import { COLORS } from '@root/colors';
import { getColorforEvent } from './utils';

import { ExpectedStartTimeCaret } from './expected_start_time';

const SEGMENTS = ['Clock In/Out', 'Drive', 'Park', 'Prep', 'Order', 'Depart', 'Break'];

const SegmentBand = styled.div<{ color: string; backgroundColor: string }>`
  color: ${({ color }) => color};
  background-color: ${({ backgroundColor }) => backgroundColor};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.15rem 0;
`;

const Grid = styled.div<{ length: number; percent: number }>`
  display: grid;
  grid-template-columns: ${({ length, percent }) => `repeat(${length}, ${percent}%)`};
  border: 1px solid ${COLORS.grayDarkest};
`;

const Content = styled.div`
  width: 80%;
`;

const Segment: React.FC<{ title: string; backgroundColor: string; color?: string }> = ({
  title,
  color = COLORS.white,
  backgroundColor,
}) => (
  <SegmentBand backgroundColor={backgroundColor} color={color}>
    {title}
  </SegmentBand>
);

export const Legend: React.FC = () => {
  const length = SEGMENTS.length;
  const percent = 100 / length;

  return (
    <div className="order-events-legend">
      <p>
        <strong>Legend:</strong>
      </p>
      <Content>
        <p>
          <strong>*</strong> - Simultaneous Return/Pickup
        </p>
        <p>
          <ExpectedStartTimeCaret /> - Expected Start Time
        </p>
        <Grid length={length} percent={percent}>
          {SEGMENTS.map((segment) => (
            <Segment
              key={`Predicted ${segment}`}
              title={`Predicted ${segment}`}
              backgroundColor={getColorforEvent(segment)(0.5)}
              color={segment === 'Break' ? COLORS.grayDarkest : COLORS.white}
            />
          ))}
          {SEGMENTS.map((segment) => (
            <Segment
              key={`Actual ${segment}`}
              title={`Actual ${segment}`}
              backgroundColor={getColorforEvent(segment)(1)}
            />
          ))}
        </Grid>
      </Content>
    </div>
  );
};

import * as React from 'react';

import { phonecallURL } from '@admin/config/routes';
import { AnchorButton } from '@shared/components/bootstrap';
import { Timestamp } from '@shared/components/helpers';
import { PhoneCallFragment } from '@admin/schema';

export const PhoneCallTableData: React.FC<{
  phoneCall: PhoneCallFragment;
}> = ({ phoneCall }) => {
  const latestConnectedUser = phoneCall.latestConnectedUser ? phoneCall.latestConnectedUser.name : '-';
  const interactionType = `${phoneCall.direction} Phone Call`;
  const callNotes = phoneCall.note && phoneCall.note.body;
  return (
    <>
      <td className="col-sm-2">
        <Timestamp value={phoneCall.createdAt} format={Timestamp.Format.DateTime} />
      </td>
      <td className="col-sm-2">{latestConnectedUser}</td>
      <td className="col-sm-2 text-capitalize">{interactionType}</td>
      <td className="col-sm-6">{callNotes}</td>
      <td className="col-zero">
        <AnchorButton kind="primary" href={phonecallURL({ id: phoneCall.id })}>
          View
        </AnchorButton>
      </td>
    </>
  );
};

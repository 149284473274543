import angular from 'angular';

angular.module('app').factory('Lead', [
  'LeadSerializer',
  '$resource',
  'TransformBuilder',
  (LeadSerializer, $resource, TransformBuilder) =>
    $resource(
      '/leads/:id.json',
      { id: '@id' },
      {
        index: {
          method: 'GET',
          isArray: true,
        },
        show: {
          method: 'GET',
          isArray: false,
        },
        create: {
          method: 'POST',
          transformRequest: TransformBuilder.transformRequest(LeadSerializer),
        },
        update: {
          method: 'PUT',
          transformRequest: TransformBuilder.transformRequest(LeadSerializer),
        },
        destroy: {
          method: 'DELETE',
        },
        self_storage_objection_list: {
          url: '/leads/self_storage_objection_reasons.json',
          method: 'GET',
          isArray: true,
        },
        stats: {
          method: 'GET',
          url: '/leads/stats.json',
        },
        on_call: {
          method: 'GET',
          url: '/leads/on_call.json',
        },
        checkout_url: {
          method: 'GET',
          url: 'leads/:id/checkout_link.json',
        },
      },
    ),
]);

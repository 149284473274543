import { keyBy } from 'lodash';

import {
  SelfStorage__RentalWithBillingFragment,
  SelfStorage__FacilityFragment,
  SelfStorage__FeeFragment,
  SelfStorage__Fee__Kind,
} from '@admin/schema';

import {
  IBillingInput,
  IElectricityEdits,
  IFacilityTotals,
  IFeeEdits,
  IPriceEdits,
  IPromotionEdits,
  IProtectionEdits,
  ITotals,
} from './types';

type FacilityRentalTuple = [
  Pick<SelfStorage__FacilityFragment, 'id' | 'name'>,
  SelfStorage__RentalWithBillingFragment[],
];

export function buildFacilityRentalTuples(rentals: SelfStorage__RentalWithBillingFragment[]) {
  const facilities: FacilityRentalTuple[] = [];
  rentals?.forEach((rental) => {
    const existingFacilityIndex = facilities.findIndex((facility) => facility[0].id === rental.unit.facility.id);
    if (existingFacilityIndex !== -1) {
      facilities[existingFacilityIndex][1].push(rental);
    } else {
      facilities.push([rental.unit.facility, [rental]]);
    }
  });

  return facilities;
}

export function buildTotals(
  facilities: FacilityRentalTuple[] | undefined,
  priceEdits: IPriceEdits,
  protectionEdits: IProtectionEdits,
  promotionEdits: IPromotionEdits,
  electricityEdits: IElectricityEdits,
  feeEdits: IFeeEdits,
) {
  if (!facilities) {
    return;
  }

  const totals: ITotals = {
    total: 0,
    facilities: {},
  };
  let oldTotal = 0;
  let hasEdits = false;

  facilities.forEach((facility) => {
    const facilityTotals: IFacilityTotals = {
      total: 0,
      rentals: {},
    };
    let oldFacilityTotal = 0;
    let facilityHasEdits = false;
    const fees: SelfStorage__FeeFragment[] = [];

    facility[1].forEach((rental) => {
      const priceEditNumber = Number(priceEdits[rental.id]);
      const price = (!isNaN(priceEditNumber) && priceEditNumber) || rental.unitRate;
      const protection = protectionEdits.hasOwnProperty(rental.id) ? protectionEdits[rental.id] : rental.protection;
      const exitingElectricityFee = rental.fees.find((fee) => fee.kind === SelfStorage__Fee__Kind.Electricity);
      const electricityFee = electricityEdits.hasOwnProperty(rental.id)
        ? electricityEdits[rental.id]
        : exitingElectricityFee;

      facilityTotals.total += price + (protection?.price || 0) + (electricityFee?.price || 0);
      oldFacilityTotal += rental.unitRate + (rental.protection?.price || 0) + (exitingElectricityFee?.price || 0);

      const rentalTotal = price + (protection?.price || 0) + (electricityFee?.price || 0);
      facilityTotals.rentals[rental.id] = { total: rentalTotal, price };

      fees.push(...rental.fees.filter((fee) => fee.recurring && fee.kind !== SelfStorage__Fee__Kind.Electricity));

      if (
        rental.unitRate !== price ||
        protectionEdits.hasOwnProperty(rental.id) ||
        promotionEdits[rental.id] ||
        electricityEdits.hasOwnProperty(rental.id)
      ) {
        facilityHasEdits = true;
        hasEdits = true;
      }
    });

    const facilityFeeEdits = feeEdits[facility[0].id] || new Map();
    fees.forEach((fee) => {
      oldFacilityTotal += fee.price;
      facilityTotals.total += facilityFeeEdits.get(fee.kind) === undefined ? fee.price : 0;
    });

    Array.from(facilityFeeEdits.values()).forEach((fee) => {
      facilityTotals.total += fee?.price || 0;
      facilityHasEdits = true;
      hasEdits = true;
    });

    if (facilityHasEdits) {
      facilityTotals.oldTotal = oldFacilityTotal;
    }

    totals.total += facilityTotals.total;
    oldTotal += oldFacilityTotal;
    totals.facilities[facility[0].id] = facilityTotals;
  });

  if (hasEdits) {
    totals.oldTotal = oldTotal;
  }

  return totals;
}

export function buildEditInputs(
  rentals: SelfStorage__RentalWithBillingFragment[],
  priceEdits: IPriceEdits,
  protectionEdits: IProtectionEdits,
  promotionEdits: IPromotionEdits,
  electricityEdits: IElectricityEdits,
  feeEdits: IFeeEdits,
) {
  const rentalMap = keyBy(rentals, 'id');
  const inputs: { [rentalID: string]: IBillingInput } = {};
  const updateOrInitInput = (rentalID: string, changes: Partial<IBillingInput>) => {
    if (!inputs[rentalID]) {
      inputs[rentalID] = { rentalID };
    }

    inputs[rentalID] = { ...inputs[rentalID], ...changes };
  };

  Object.keys(priceEdits).forEach((rentalID) => {
    const castedPriceEdit = Number(priceEdits[rentalID]);
    if (!isNaN(castedPriceEdit) && castedPriceEdit !== rentalMap[rentalID].rate) {
      updateOrInitInput(rentalID, { rate: castedPriceEdit });
    }
  });

  Object.keys(protectionEdits).forEach((rentalID) => {
    const protection = protectionEdits[rentalID];
    updateOrInitInput(rentalID, { protectionID: protection?.id ?? '' });
  });

  Object.keys(promotionEdits).forEach((rentalID) => {
    updateOrInitInput(rentalID, { removePromotion: true });
  });

  Object.keys(electricityEdits).forEach((rentalID) => {
    const fee = electricityEdits[rentalID];
    const rental = rentalMap[rentalID];
    const newFees = fee
      ? rental.fees.concat(fee)
      : rental.fees.filter((f) => f.kind !== SelfStorage__Fee__Kind.Electricity);
    updateOrInitInput(rentalID, { feeIDs: newFees.map((f) => f.id) });
  });

  Object.keys(feeEdits).forEach((facilityID) => {
    const kinds = Array.from(feeEdits[facilityID].keys());

    for (const kind of kinds) {
      const fee = feeEdits[facilityID].get(kind);
      const rental = rentals.find((r) =>
        fee ? r.unit.facility.id === facilityID : !!r.fees.find((f) => f.kind === kind),
      );

      if (!rental) {
        throw new Error('No rental');
      }

      const feeIDs = inputs[rental.id]?.feeIDs || rental.fees.filter((f) => !kinds.includes(f.kind)).map((f) => f.id);

      if (!fee) {
        const feeID = rental.fees.find((f) => f.kind === kind)!.id;
        updateOrInitInput(rental.id, { feeIDs: feeIDs.filter((id) => id !== feeID) });
      } else {
        updateOrInitInput(rental.id, { feeIDs: feeIDs.concat(fee.id) });
      }
    }
  });

  return Object.values(inputs);
}

import * as React from 'react';

import { Rooms } from './rooms';

export type MoveSizes = 'EVERYTHING' | 'HALF' | 'FEW';

export type StorageUnitSizes = 'locker' | '5x5' | '5x10' | '10x10' | '10x15' | '10x20' | '10x25' | '10x30';
export type SmallMoveUnitTypes = '1_item' | '10_items' | 'dorm_room';
export const SMALL_MOVE_UNIT_TYPES = ['1_item', '10_items', 'dorm_room'];

const MOVE_SIZE_TO_NAME: Record<MoveSizes, string> = {
  EVERYTHING: 'Everything',
  HALF: 'About half',
  FEW: 'Only a few items',
};

const UNIT_SIZE_TO_NAME: Record<StorageUnitSizes, string> = {
  locker: 'Locker',
  '5x5': '5′ x 5′',
  '5x10': '5′ x 10′',
  '10x10': '10′ x 10′',
  '10x15': '10′ x 15′',
  '10x20': '10′ x 20′',
  '10x25': '10′ x 25′',
  '10x30': '10′ x 30′',
};

const SMALL_MOVE_UNIT_TYPES_TO_NAME: Record<SmallMoveUnitTypes, string> = {
  '1_item': '1 Item',
  '10_items': '10 Items',
  dorm_room: 'Dorm Room',
};

type StorageUnitSize = {
  field: StorageUnitSizes;
  label: string;
};
const STORAGE_UNIT_SIZES: readonly StorageUnitSize[] = [
  { label: 'Locker', field: 'locker' },
  { label: "5' x 5'", field: '5x5' },
  { label: "5' x 10'", field: '5x10' },
  { label: "10' x 10'", field: '10x10' },
  { label: "10' x 15'", field: '10x15' },
  { label: "10' x 20'", field: '10x20' },
  { label: "10' x 25'", field: '10x25' },
  { label: "10' x 30'", field: '10x30' },
] as const;

export const STORAGE_UNIT_FIELDS = STORAGE_UNIT_SIZES.map(({ field }) => field);

export const SelectionDetailsSummary: React.FC<{
  movingRooms: { [field: string]: boolean | number };
  moveHasSquareFootage: boolean;
  moveHasMoveSize: boolean;
  moveHasStorageUnit: boolean;
  movingSquareFootage: number;
  moveSize?: MoveSizes;
  smallMoveSelection?: SmallMoveUnitTypes;
  storageUnitSize?: StorageUnitSizes;
}> = ({
  movingRooms,
  moveHasSquareFootage,
  moveHasMoveSize,
  moveHasStorageUnit,
  movingSquareFootage,
  moveSize,
  smallMoveSelection,
  storageUnitSize,
}) => {
  if (moveHasSquareFootage || moveHasMoveSize || smallMoveSelection) {
    return (
      <>
        <h3 className="text-thin">Selection Details</h3>
        {smallMoveSelection && <p>{SMALL_MOVE_UNIT_TYPES_TO_NAME[smallMoveSelection]}</p>}
        {moveHasSquareFootage && <p>Square Footage: {movingSquareFootage}</p>}
        {moveHasMoveSize && <p>Move Size: {moveSize ? MOVE_SIZE_TO_NAME[moveSize] : '-'}</p>}
        {moveHasStorageUnit && <p>Unit Size: {storageUnitSize ? UNIT_SIZE_TO_NAME[storageUnitSize] : '-'}</p>}
      </>
    );
  }

  return (
    <>
      <h3 className="text-thin">Number of Rooms</h3>
      <Rooms movingRooms={movingRooms} />
    </>
  );
};

import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { COLORS } from '@root/colors';

const Container = styled.div`
  display: flex;
  margin: 4px 0 0;
  flex-wrap: wrap;
  gap: 8px;
`;

const badge = css`
  color: ${COLORS.grayDarker};
  padding: 4px 8px;
  border-radius: 8px;
  background-color: ${COLORS.grayLighter};
`;
export const Upload = styled.span(badge);
export const Download = styled.a(badge);

export const Files = Object.assign(Container, {
  Upload,
  Download,
});

(() => {
  const app = angular.module('app');

  app.service('InputValidationService', [
    '$http',
    function ($http) {
      const self = this;

      const validate = (params) =>
        $http.get('/validation.json', {
          params: params,
        });

      self.validatePhoneNumber = (phoneNumber) => validate({ phone_number: phoneNumber });

      self.validateEmail = (email) => validate({ email: email });

      self.validate = (fields) => {
        if (fields.email === '') {
          fields.email = null;
        }
        return validate({ phone_number: fields.phone_number, email: fields.email });
      };
    },
  ]);
})();

(function () {
  angular.module('app').factory('Incident', [
    '$resource',
    function ($resource) {
      return $resource(
        '/:parent_type/:parent_id/incidents/:id.json',
        {
          id: '@id',
          parent_type: '@parent_type',
          parent_id: '@parent_id',
        },
        {
          create: {
            method: 'POST',
          },
          update: {
            method: 'PATCH',
          },
        },
      );
    },
  ]);
})();

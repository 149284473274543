import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { useDrag, useDrop } from 'react-dnd';
import type { XYCoord } from 'dnd-core';
import { COLORS, UnstyledButton } from '@clutter/clean';

import {
  Dispatch__ItemMetricsFragment,
  FacilityFragment,
  Maybe,
  NightTransport__DispatchFragment,
  VehicleFragment,
} from '@admin/schema';
import { faExclamationCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Alert } from '@shared/components/bootstrap';
import { Box } from '@clutter/clean';

import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { faSave } from '@fortawesome/free-regular-svg-icons';
import { join } from 'lodash';
import { dispatchURL } from '@admin/config/routes';
import { DateTime } from 'luxon';
import { DriveFormGroup } from './action/drive_form_group';
import { Context } from './context';
import { FacilityFormGroup } from './facility_form_group';
import { Mode } from './filters';
import {
  FacilityType,
  NightTransportAction,
  NightTransportDirection,
  NightTransportRequiredDrive,
  NightTransportTask,
} from './types';
import { VehicleFormGroup } from './vehicle_form_group';

const BORDER_COLORS = {
  [NightTransportDirection.Inbound]: '#DAA520',
  [NightTransportDirection.Outbound]: '#008000',
  [NightTransportDirection.Adhoc]: '#337AB7',
};

const FILL_COLORS = {
  [NightTransportDirection.Inbound]: '#fffcf1',
  [NightTransportDirection.Outbound]: '#f3fff7',
  [NightTransportDirection.Adhoc]: '#f2f9ff',
};

const TaskItem = styled.div<{ direction: NightTransportDirection; dragging: boolean }>`
  padding: 12px 12px 0;
  background-color: ${({ direction }) => FILL_COLORS[direction]};
  border-color: ${({ direction }) => BORDER_COLORS[direction]};
  border-width: 1.8px;
  border-style: solid;
  opacity: ${({ dragging }) => (dragging ? 0.5 : 1.0)};
`;

const ActionForm = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 12px;
`;

const DeleteButton = styled(UnstyledButton)<{ canDelete: boolean }>`
  display: flex;
  color: ${({ canDelete }) => (canDelete ? COLORS.toucan : COLORS.grayBorder)};
`;

const DeleteTask: React.FC<{ taskUUID: string }> = ({ taskUUID }) => {
  const { mode, onDeleteTask } = useContext(Context);
  const editing = mode === Mode.Editing;

  return (
    <DeleteButton disabled={!editing} canDelete={editing} onClick={() => onDeleteTask(taskUUID)}>
      <FontAwesomeIcon icon={faTimes} />
    </DeleteButton>
  );
};

const VehiclePreferences: React.FC<{ preferences: NightTransport__DispatchFragment['vehiclePreferences'] }> = ({
  preferences,
}) => {
  const value = join(
    preferences.map(({ quantity, vehicleType: { name } }) => `${quantity} ${name} truck${quantity > 1 ? 's' : ''}`),
    ', ',
  );
  return <p>Vehicle Preferences: {value} </p>;
};

const ItemMetrics: React.FC<Dispatch__ItemMetricsFragment & { estimate?: boolean }> = ({ cuft, count, estimate }) => (
  <p>
    {estimate ? 'Estimated' : 'Actual'}: {Math.round(cuft)} cuft, {count === null ? 'Unknown' : count} item count
  </p>
);

const DispatchDetails: React.FC<{
  dispatch: NightTransport__DispatchFragment;
  direction: NightTransportDirection;
}> = ({ dispatch, direction }) => {
  const DirectionItemMetrics: React.FC = () => {
    if (direction === NightTransportDirection.Inbound) {
      return <ItemMetrics {...dispatch.estimatedInboundItemMetrics} estimate />;
    } else if (direction === NightTransportDirection.Outbound) {
      return <ItemMetrics {...dispatch.outboundItemMetrics} />;
    }
    return null;
  };

  return (
    <>
      <p>
        Dispatch:{' '}
        <a
          style={{ color: '#0964DE', textDecoration: 'underline' }}
          href={dispatchURL({ id: dispatch.id })}
          onClick={(e) => e.stopPropagation()}
          target="_blank"
        >
          {dispatch.id}
        </a>
        , {DateTime.fromISO(dispatch.arrival).setZone(dispatch.region.tz).toLocaleString(DateTime.DATE_SHORT)}
      </p>
      {Boolean(dispatch?.vehiclePreferences.length) && <VehiclePreferences preferences={dispatch.vehiclePreferences} />}
      <DirectionItemMetrics />
    </>
  );
};

export const taskDirection = (rootRequiredDriveTask: Maybe<NightTransportRequiredDrive>, task: NightTransportTask) => {
  if (rootRequiredDriveTask) {
    const { origin, destination } = rootRequiredDriveTask;
    return actionDirection(origin ?? null, destination);
  } else if (task.action.dispatch) {
    const { origin, destination } = task.action;
    return actionDirection(origin ?? null, destination ?? null);
  } else {
    return NightTransportDirection.Adhoc;
  }
};

const actionDirection = (origin: Maybe<FacilityFragment>, destination: Maybe<FacilityFragment>) => {
  if (origin?.__typename === 'Depot') {
    return NightTransportDirection.Inbound;
  } else if (destination?.__typename === 'Depot') {
    return NightTransportDirection.Outbound;
  } else {
    return NightTransportDirection.Adhoc;
  }
};

const actionType = (typename?: string) => {
  if (typename === 'NightTransport__Carpool') {
    return 'Carpool';
  } else if (typename === 'NightTransport__Resupply') {
    return 'Resupply';
  } else {
    return 'Drive';
  }
};

export const getRequiredDriveTask = (
  requiredDriveTasks: Record<string, NightTransportRequiredDrive>,
  requiredDriveTaskUUID?: Maybe<string>,
): Maybe<NightTransportRequiredDrive> => {
  if (!requiredDriveTaskUUID) {
    return null;
  }

  return requiredDriveTasks[requiredDriveTaskUUID];
};

const possibleOriginFacilityTypes = (requiredDriveTask: Maybe<NightTransportRequiredDrive>): FacilityType[] => {
  if (!requiredDriveTask) {
    return [FacilityType.Warehouse, FacilityType.Depot];
  }

  return requiredDriveTask.destination.__typename === 'Depot' ? [FacilityType.Warehouse] : [FacilityType.Depot];
};

const isEqualFacility = (a: FacilityFragment, b: FacilityFragment) => a.__typename === b.__typename && a.id === b.id;

const congruentDriveAndRequiredDrive = (
  origin: Maybe<FacilityFragment>,
  destination: Maybe<FacilityFragment>,
  requiredDriveTask: NightTransportRequiredDrive | null,
) => {
  if (!requiredDriveTask) {
    return true;
  }

  const inboundRequiredDriveTask = requiredDriveTask.destination.__typename === 'Warehouse';
  const outboundRequiredDriveTask = requiredDriveTask.destination.__typename === 'Depot';

  return (
    (inboundRequiredDriveTask &&
      destination &&
      isEqualFacility(requiredDriveTask.destination, destination) &&
      origin &&
      isEqualFacility(requiredDriveTask.origin!, origin)) ||
    (outboundRequiredDriveTask &&
      destination &&
      isEqualFacility(requiredDriveTask.destination!, destination) &&
      (origin?.__typename === requiredDriveTask.origin?.__typename || origin?.__typename === 'Warehouse'))
  );
};

export const getRoot = (task: NightTransportTask, tasks: NightTransportTask[]): NightTransportTask => {
  if (!task.action.predecessorUUID) {
    return task;
  }
  const predecessor = tasks.find((elem) => elem.action.uuid === task.action.predecessorUUID)!;
  return getRoot(predecessor, tasks);
};

const getLast = (task: NightTransportTask, tasks: NightTransportTask[]): NightTransportTask => {
  const successor = tasks.find((elem) => elem.action.predecessorUUID === task.action.uuid);

  if (!successor) {
    return task;
  }
  return getLast(successor, tasks);
};

export const Task: React.FC<{ task: NightTransportTask; index: number }> = ({ task, index }) => {
  const { onChangeTask, onMoveTask, onSwapTasks, tasks, mode, requiredDriveTasks, loading, errors } =
    useContext(Context);
  const [expanded, setExpanded] = useState<boolean>(false);
  const { action, editing } = task;

  const predecessor = tasks.find(({ action: taskAction }) => taskAction.uuid === task.action.predecessorUUID);

  const isCarpool = action.__typename === 'NightTransport__Carpool';
  const isDrive = action.__typename === 'NightTransport__Drive';
  const isResupply = action.__typename === 'NightTransport__Resupply';
  const isExtensionDrive = isDrive && !!predecessor;
  const isOptionalDrive = isDrive && !action.predecessorUUID && !action.dispatch;
  const type = actionType(action.__typename);

  const rootTask = getRoot(task, tasks);
  const lastTask = getLast(task, tasks);
  const drive =
    isDrive || !action.driveUUID
      ? action
      : tasks.find(({ action: taskAction }) => taskAction.uuid === action.driveUUID)?.action;

  const rootRequiredDriveTask = getRequiredDriveTask(requiredDriveTasks, rootTask.requiredDriveTaskUUID);
  const dispatch = rootRequiredDriveTask?.dispatch || action.dispatch;
  const vehicle =
    (rootRequiredDriveTask?.vehicleSuggestion.__typename === 'Vehicle' && rootRequiredDriveTask.vehicleSuggestion) ||
    (isResupply ? action.vehicle : drive?.vehicle);

  const requiresChanges = (() => {
    if (isResupply) {
      return !(
        !!drive?.destination &&
        !!rootRequiredDriveTask?.destination &&
        isEqualFacility(drive.destination, rootRequiredDriveTask.destination)
      );
    } else {
      return !congruentDriveAndRequiredDrive(
        rootTask.action.origin ?? null,
        lastTask.action.destination ?? null,
        rootRequiredDriveTask,
      );
    }
  })();

  const direction = taskDirection(rootRequiredDriveTask, task);
  const taskType = (() => {
    if (isExtensionDrive) return `Extension Drive for Task #${predecessor.id}`;
    else if (isOptionalDrive) return 'Optional Drive';
    else if (isCarpool) return 'Carpool Task';

    return `${NightTransportDirection[direction]} ${type}`;
  })();

  const canToggleResupply = direction === NightTransportDirection.Outbound && !action.predecessorUUID && dispatch;

  const ref = useRef<HTMLDivElement>(null);

  const onChangeAction = (change: NightTransportAction) => {
    onChangeTask({ ...task, action: change });
  };

  const onEdit = () => {
    onChangeTask({ ...task, editing: true });
  };

  const onSave = () => {
    onChangeTask({ ...task, editing: false });
  };

  const onChangeDrive = (driveUUID: string) => {
    onChangeAction({ ...action, driveUUID });
  };

  const onChangeOrigin = (origin: FacilityFragment) => {
    onChangeAction({ ...action, origin });
  };

  const onChangeDestination = (destination: FacilityFragment) => {
    onChangeAction({ ...action, destination });
    const successor = tasks.find((elem) => elem.action.predecessorUUID === task.action.uuid);

    if (successor) {
      onChangeTask({ ...successor, action: { ...successor.action, origin: destination } });
    }
  };

  const onChangeVehicle = (selectedVehicle: VehicleFragment) => {
    onChangeAction({ ...action, vehicle: selectedVehicle });
  };

  const onConvertAction = (resupply: boolean) => {
    onChangeAction({ ...action, __typename: resupply ? 'NightTransport__Resupply' : 'NightTransport__Drive' });
  };

  const [_, drop] = useDrop<NightTransportTask & { index: number }, void, {}>({
    accept: 'Task',
    hover(item: NightTransportTask & { index: number }, monitor) {
      if (!ref.current) {
        return;
      }
      const sameRoute = item.routeUUID === task.routeUUID;
      const dragIndex = sameRoute ? item.index : index;
      const hoverIndex = index;

      if (sameRoute && dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      const clientOffset = monitor.getClientOffset();

      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      const hoverPosition = hoverIndex + 1;
      if (sameRoute) {
        const dragPosition = dragIndex + 1;
        onSwapTasks(item, dragPosition, task, hoverPosition);
      } else {
        onMoveTask(item, hoverPosition, task.routeUUID);
        item.routeUUID = task.routeUUID;
      }
      item.position = hoverPosition;
      item.index = hoverIndex;
    },
  });

  const [{ dragging }, drag] = useDrag(
    () => ({
      type: 'Task',
      item: { ...task, index },
      canDrag: mode === Mode.Editing && !loading,
      isDragging(monitor) {
        return monitor.getItem().uuid === task.uuid;
      },
      collect: (monitor) => ({
        dragging: monitor.isDragging(),
      }),
    }),
    [mode, task, loading],
  );

  drag(drop(ref));

  const DriveCopy = () => {
    if (!drive || isResupply) return null;
    else if (drive.origin && drive.destination)
      return (
        <p>
          {drive.origin.name} to {drive.destination.name}
        </p>
      );
    else if (!drive.origin && drive.destination) return <p>Please set origin location.</p>;

    return null;
  };
  const ExpandedDetails = () => <div>{dispatch && <DispatchDetails dispatch={dispatch} direction={direction} />}</div>;

  if (mode === Mode.Viewing || (mode === Mode.Editing && !editing)) {
    return (
      <TaskItem onClick={() => setExpanded(!expanded)} ref={ref} direction={direction} dragging={dragging}>
        <Box.Flex justifyContent="space-between">
          <Box.FlexItem>
            <Box.Flex gap="4px">
              {requiresChanges && <FontAwesomeIcon icon={faExclamationCircle} size="lg" color="#D26053" />}
              <p>
                Task #{task.id}, {taskType}
                {vehicle && `, ${vehicle.name}`}
              </p>
            </Box.Flex>
            {isResupply && drive?.destination && <p>Resupply at {drive.destination.name}</p>}
            <DriveCopy />
          </Box.FlexItem>
          {mode === Mode.Editing && (
            <Box.FlexItem>
              <UnstyledButton
                onClick={(e) => {
                  e.stopPropagation();
                  onEdit();
                }}
              >
                <FontAwesomeIcon icon={faPencil} size="sm" color="#000000" />
              </UnstyledButton>
            </Box.FlexItem>
          )}
        </Box.Flex>
        {expanded && <ExpandedDetails />}
      </TaskItem>
    );
  }

  return (
    <TaskItem ref={ref} direction={direction} dragging={dragging}>
      {errors[task.uuid] && <Alert style="danger">{errors[task.uuid]}</Alert>}
      <Box.Flex justifyContent="space-between">
        <Box.Flex flexDirection="row" gap="4px">
          {requiresChanges && <FontAwesomeIcon icon={faExclamationCircle} size="lg" color={'#D26053'} />}
          <p>
            Task #{task.id}, {taskType}
            {vehicle && `, ${vehicle.name}`}
          </p>
        </Box.Flex>
        <Box.FlexItem flexShrink="0">
          <UnstyledButton
            onClick={(e) => {
              e.stopPropagation();
              onSave();
            }}
          >
            <FontAwesomeIcon icon={faSave} size="sm" color="#000000" />
          </UnstyledButton>
          {(!rootRequiredDriveTask || action.predecessorUUID) && <DeleteTask taskUUID={task.uuid} />}
        </Box.FlexItem>
      </Box.Flex>
      {canToggleResupply && (
        <div className="checkbox-inline">
          <label>
            <input
              type="checkbox"
              disabled={mode !== Mode.Editing || loading}
              checked={isResupply}
              onChange={(event) => onConvertAction(event.target.checked)}
            />
            Resupply
          </label>
        </div>
      )}
      {isCarpool && task.action.driveUUID && (
        <div>Route Task: #{tasks.find(({ action: taskAction }) => taskAction.uuid === task.action.driveUUID)!.id}</div>
      )}

      <ActionForm>
        {isResupply && (
          <DriveFormGroup
            name={`action_${action.id}_drive_task_id`}
            label="Task #"
            currentDriveUUID={action.driveUUID}
            outboundOnly
            onChangeAction={onChangeDrive}
          />
        )}
        {isExtensionDrive && <p>Origin: {predecessor.action.destination?.name}</p>}
        {!isResupply && !isExtensionDrive && (
          <>
            <FacilityFormGroup
              name={`action_${action.id}_origin_id`}
              label="Origin"
              disabled={isCarpool}
              currentFacility={drive?.origin}
              onChangeFacility={onChangeOrigin}
              facilityTypes={possibleOriginFacilityTypes(
                getRequiredDriveTask(requiredDriveTasks, task.requiredDriveTaskUUID),
              )}
            />
          </>
        )}
        {!isResupply && (
          <FacilityFormGroup
            name={`action_${action.id}_destination_id`}
            label="Dest."
            disabled={isCarpool}
            currentFacility={drive?.destination}
            onChangeFacility={onChangeDestination}
          />
        )}
        {isOptionalDrive && (
          <VehicleFormGroup
            name={`action_${action.id}_vehicle_id`}
            label="Vehicle"
            currentVehicle={vehicle || undefined}
            onChangeVehicle={onChangeVehicle}
          />
        )}
      </ActionForm>
    </TaskItem>
  );
};

import styled from '@emotion/styled';
import React from 'react';

import { client } from '@admin/libraries/apollo';
import { ConfigurationAuditReviewQuery, useAuditItemGroupQuery } from '@admin/schema';
import { Spinner } from '@admin/components/spinner';
import { Spacer } from '@shared/components/helpers';
import { ItemGroupInfoPanel } from './item_group_info_panel';
import { SubResourcePanel } from './sub_resource_panel';

type AuditType = ConfigurationAuditReviewQuery['audit'];

const Scrollable = styled.div`
  max-height: 80vh;
  overflow: hidden;
  overflow-y: scroll;
`;

export const DisassemblyReview: React.FC<{
  audit: AuditType;
}> = ({ audit }) => {
  const variables = audit ? { id: audit.resourceID } : undefined;
  const { data } = useAuditItemGroupQuery({ client, variables, skip: !audit });

  if (audit == null) {
    return <></>;
  }
  const group = data?.itemGroup;

  return (
    <div>
      {!group && (
        <>
          <Spinner />
          <Spacer height="8px" />
        </>
      )}
      {group && (
        <Scrollable>
          <ItemGroupInfoPanel itemGroup={group} />
          {group.items.length > 0 && (
            <div className="ops-audit-item-resource-panel">
              {[...group.items]
                .sort((a, b) => parseInt(a.id) - parseInt(b.id))
                .map((subResource) => (
                  <SubResourcePanel
                    key={subResource.id}
                    resource={subResource}
                    groupCategory={group.category.name}
                    subAudits={audit.children}
                  />
                ))}
            </div>
          )}
          {group.partialItems.length > 0 && (
            <div className="ops-audit-partial-item-resource-panel">
              {[...group.partialItems]
                .sort((a, b) => parseInt(a.id) - parseInt(b.id))
                .map((subResource) => (
                  <SubResourcePanel
                    key={subResource.id}
                    resource={subResource}
                    groupCategory={group.category.name}
                    subAudits={audit.children}
                  />
                ))}
            </div>
          )}
        </Scrollable>
      )}
    </div>
  );
};

(function () {
  const app = angular.module('app');

  app.directive('enforceHeightLimit', [
    '$timeout',
    function ($timeout) {
      const directive = {
        restrict: 'A',
        scope: {
          heightLimit: '<',
        },
        link: linkFunc,
        controller: ['$timeout', EnforceHeightLimitController],
        controllerAs: 'vm',
        bindToController: true,
      };

      return directive;

      function EnforceHeightLimitController($timeout) {
        this.$onDestroy = () => {
          if (self.heightCheckPromise) {
            $timeout.cancel(self.heightCheckPromise);
          }
        };
      }

      function linkFunc(scope, el) {
        styleElement();

        function styleElement() {
          const elHeight = el.height();
          if (!elHeight) {
            scope.vm.heightCheckPromise = $timeout(styleElement, 100);
          } else if (elHeight > scope.vm.heightLimit) {
            el.addClass('over-limit');
            const targetHeight = scope.vm.heightLimit - el.find('.show-if-over-limit').outerHeight(true);
            el.find('.truncate-if-over-limit').height(targetHeight);
          }
        }
      }
    },
  ]);
})();

import React from 'react';
import { connect } from 'react-redux';

export const withRouterState = (Component) =>
  connect()(
    class extends React.Component {
      state = {};

      componentDidMount() {
        const $state = this.props.dispatch((dispatch, getState, { $state }) => $state);
        this.setState({ $state });
      }

      render() {
        return <Component $state={this.state.$state} {...this.props} />;
      }
    },
  );

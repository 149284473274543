import React from 'react';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useQA } from '@admin/hooks/use_qa';

export const QaQrCodeLink: React.FC<{
  value: string;
}> = ({ value }) => {
  const qa = useQA();
  if (!qa) return null;

  return (
    <>
      {value && (
        <a className="btn-link" href={'/qaQrCodes?value=' + value} target="_blank">
          <FontAwesomeIcon icon={faLink} rotation={90} />
        </a>
      )}
    </>
  );
};

(function () {
  angular.module('app').factory('Move', [
    '$resource',
    'TransformBuilder',
    'OrderSerializer',
    'OrderDeserializer',
    function ($resource, TransformBuilder, serializer, deserializer) {
      return $resource(
        '/accounts/:account_id/moves/:id.json',
        {
          id: '@id',
          account_id: '@account_id',
        },
        {
          get: {
            method: 'GET',
            transformResponse: TransformBuilder.transformResponse(deserializer),
          },
          update: {
            method: 'PATCH',
            transformRequest: TransformBuilder.transformRequest(serializer),
            transformResponse: TransformBuilder.transformResponse(deserializer),
          },
        },
      );
    },
  ]);
})();

(function () {
  const app = angular.module('app');

  app.constant('SelfStorageReservationConstants', {
    STATES: [
      { name: 'Pending', id: 'pending' },
      { name: 'Won', id: 'won' },
      { name: 'Lost', id: 'lost' },
    ],
  });
})();

import { RateAdjustment__Upload__Product } from '@admin/schema';
import { last } from 'lodash';
import * as React from 'react';

import { Text } from '@shared/components/bootstrap';
import { ISelection } from '@shared/components/files';

import { FileUploader } from './file_uploader';

export const Selector: React.FC<{ uploads: ISelection[]; product: RateAdjustment__Upload__Product }> = ({
  uploads,
  product,
}) => {
  const lowerProduct = product.toLowerCase();
  const fileName = `${lowerProduct}_rate_adjustments.csv`;
  return (
    <>
      <Text tag="p">
        You can update existing {lowerProduct.replace(/_/g, '-')} rate adjustments or create new ones by uploading a
        spreadsheet based on this template: (
        <a href={`/files/rate_adjustments/${fileName}`} className="btn-link" target="_blank" download>
          {fileName}
        </a>
        )
      </Text>
      <FileUploader upload={last(uploads)} />
    </>
  );
};

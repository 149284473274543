(function () {
  angular.module('app').filter('list', function () {
    return function (collection, key, separator) {
      if (!collection || !key) {
        return '';
      }

      separator = separator || ', ';

      return _.map(collection, function (element) {
        return element[key];
      }).join(separator);
    };
  });
})();

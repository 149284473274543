(function () {
  const app = angular.module('app');

  app.controller('ItemCategoryManagementController', [
    '$scope',
    '$location',
    'ItemCategory',
    'FiltersService',
    'ErrorService',
    function ($scope, $location, ItemCategory, FiltersService, ErrorService) {
      $scope.filters = $location.search();
      $scope.categories = ItemCategory.query();
      $scope.mpVisibleOptions = [
        { label: 'M&P Visible', value: true },
        { label: 'M&P Hidden', value: false },
      ];

      $scope.filter = function (filters) {
        filters = FiltersService.cleanup(filters);
        $location.search(filters);

        $scope.rowspan = function (category) {
          let rowspan = 0;
          if (category.sizing_metric) {
            rowspan += $scope.categoryEnumerationKeys(category, false).length;
          }
          if (category) {
            rowspan++;
          }
          return rowspan;
        };

        $scope.categories.$promise.then(function () {
          const query = $scope.filters ? $scope.filters.query : null;

          if (query && query.length) {
            const regex = new RegExp(query, 'gi');
            $scope.results = _.filter($scope.categories, function (category) {
              return category.name.match(regex);
            });
          } else {
            $scope.results = $scope.categories;
          }
        });
      };

      $scope.categoryEnumerationKeys = function (category, first) {
        const results = [];
        if (category.sizing_metric) {
          if (category.sizing_metric.enumerations.length > 0) {
            category.sizing_metric.enumerations.forEach(function (enumeration) {
              results.push(enumeration);
            });
          } else {
            const range = category.sizing_metric.range.split(',');
            for (let i = parseInt(range[0]); i <= parseInt(range[1]); i++) {
              results.push(i);
            }
          }
        } else {
          results.push('default');
        }

        if (first) {
          return [results[0]];
        } else {
          results.splice(0, 1);
          return results;
        }
      };

      $scope.save = function (category) {
        const method = category.id ? 'update' : 'create';
        $scope.saving = {
          id: category.id,
          move_and_pack_visible: category.move_and_pack_visible,
          category_defaults_attributes: {
            defaults: _.map(category.defaults, function (value, key) {
              return {
                key: key,
                value: value,
              };
            }),
          },
        };

        ItemCategory[method]($scope.saving).$promise.then(
          function () {
            delete $scope.saving;
            $scope.cancel();
          },
          function (error) {
            delete $scope.saving;
            ErrorService.handle(error);
          },
        );
      };

      $scope.manage = function (category) {
        $scope.selection = category;
      };

      $scope.cancel = function () {
        $scope.selection = null;
      };

      $scope.filter($scope.filters);
    },
  ]);
})();

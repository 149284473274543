import * as React from 'react';

import { Timestamp } from '@admin/components/timestamp';

const ISO_DATE_TIME_REGEXP = /(\d{4})-(\d{2})-(\d{2})T(\d{2})\:(\d{2})/;
const SEPARATOR = ', ';

export const Autoformat: React.FC<{
  value: string | number | boolean | string[] | number[] | boolean[] | null;
  timezone?: string;
}> = ({ value, timezone }) => {
  if (value === null) {
    return null;
  } else if (value instanceof Array) {
    return <>{value.join(SEPARATOR)}</>;
  } else if (typeof value === 'string' && value.match(ISO_DATE_TIME_REGEXP)) {
    return <Timestamp value={value} timezone={timezone} />;
  } else if (typeof value === 'boolean' && value) {
    return <>ON</>;
  } else if (typeof value === 'boolean' && !value) {
    return <>OFF</>;
  } else {
    return <>{value}</>;
  }
};

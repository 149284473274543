(function () {
  const DATETIME = /(\d{4})-(\d{2})-(\d{2})T(\d{2})\:(\d{2})/;
  const SEPARATOR = ', ';

  angular.module('app').filter('autoformat', [
    '$filter',
    function ($filter) {
      return function (value, timezone) {
        if (value && value instanceof Array) {
          return value.join(SEPARATOR);
        }
        if (value && value.match && value.match(DATETIME)) {
          const date = new Date(value);
          if (timezone) {
            return $filter('datetz')(date, timezone, 'medium');
          } else {
            return $filter('date')(date, 'medium');
          }
        } else if (value === true || value === 'true') {
          return 'ON';
        } else if (value === false || value === 'false') {
          return 'OFF';
        } else {
          return value;
        }
      };
    },
  ]);
})();

(function () {
  const app = angular.module('app');

  app.component('materialCategorySelect', {
    templateUrl: 'partials/components/material_category_select.html',
    bindings: {
      item: '<',
      selection: '=',
      filters: '=',
    },
    controller: [
      'MaterialsService',
      function (MaterialsService) {
        const self = this;
        self.categories = MaterialsService.categories;
      },
    ],
  });
})();

import axios from 'axios';
import * as React from 'react';
import { useEffect, useState } from 'react';

import { ITags } from '@admin/types/tags';
import LabelGroup from '../label_group';

export const Tags: React.FC<{
  accountID: number;
}> = ({ accountID }) => {
  const [accountTags, setAccountTags] = useState<ITags | undefined>(undefined);
  useEffect(() => {
    const request = axios.get<ITags>(`/accounts/${accountID}/tags.json`);
    request.then(({ data }) => {
      setAccountTags(data);
    });
  }, [accountID]);

  if (!accountTags || !accountTags.tags.length) {
    return null;
  }

  return <LabelGroup className="label-account-tag" labels={accountTags.tags} />;
};

import React from 'react';
import { Formik, FormikErrors, Form as FormikForm } from 'formik';

import { Status, TrainingUpload__Create__Input, useTrainingUploadCreateMutation } from '@admin/schema';

import { client } from '@admin/libraries/apollo';
import { FormikInputFormGroup } from '@shared/components/fields/formik/formik_input_form_group';
import { Alert, Button, Panel, Modal } from '@shared/components/bootstrap';
import { FormikMultiselectFormGroup } from '../fields/formik/formik_multiselect_form_group';
import { FormikFileFormGroup } from '../fields/formik/formik_file_form_group';
import { FormikRadioFormGroup } from '../fields/formik/formik_radio_form_group';

type FormValues = TrainingUpload__Create__Input & { teamSelectionOption: string };

export const UploadModal: React.FC<{
  teamOptions: Array<{ id: string; name: string }>;
  closeModal(refetch: boolean): void;
}> = ({ teamOptions, closeModal }) => {
  const [save, { loading, data }] = useTrainingUploadCreateMutation({
    client,
    onCompleted: ({ result }) => {
      if (result?.status === Status.Ok) closeModal(true);
    },
  });

  const onSubmit = (input: FormValues) => {
    let teamIDs: string[] = [];
    switch (input.teamSelectionOption) {
      case 'all':
        teamIDs = teamOptions
          .filter((teamOption) => teamOption.name.includes('Field') || teamOption.name.includes('Warehouse'))
          .map((teamOption) => teamOption.id);
        break;

      case 'field':
        teamIDs = teamOptions
          .filter((teamOption) => teamOption.name.includes('Field'))
          .map((teamOption) => teamOption.id);
        break;
      case 'warehouse':
        teamIDs = teamOptions
          .filter((teamOption) => teamOption.name.includes('Warehouse'))
          .map((teamOption) => teamOption.id);
        break;
      case 'custom':
        teamIDs = input.teamIDs;
        break;
    }
    save({
      variables: {
        input: {
          fileSignedID: input.fileSignedID,
          name: input.name,
          teamIDs: teamIDs,
        },
      },
    });
  };

  const validateInput = ({
    fileSignedID,
    name,
    teamIDs,
    teamSelectionOption,
  }: FormValues): FormikErrors<TrainingUpload__Create__Input> => {
    const formErrors: FormikErrors<TrainingUpload__Create__Input> = {};

    if (fileSignedID.length === 0) {
      formErrors.fileSignedID = 'Please select a file';
    }

    if (name.length === 0) {
      formErrors.name = 'Please enter a name';
    }

    if (teamIDs.length === 0 && teamSelectionOption === 'custom') {
      formErrors.teamIDs = 'Please select at least one team';
    }

    return formErrors;
  };

  return (
    <Modal onClose={() => closeModal(false)}>
      <Formik<FormValues>
        initialValues={{
          fileSignedID: '',
          name: '',
          teamIDs: [],
          teamSelectionOption: 'all',
        }}
        onSubmit={onSubmit}
        validate={validateInput}
      >
        {({ values }) => (
          <FormikForm>
            <Panel>
              <Panel.Body>
                {data?.result?.error && <Alert style="danger">{data.result.error}</Alert>}
                <FormikInputFormGroup label="Name" name="name" />
                <FormikRadioFormGroup
                  label="Teams"
                  id="teamSelectionOption"
                  name="teamSelectionOption"
                  options={[
                    {
                      value: 'all',
                      label: 'Field & Warehouse',
                    },
                    {
                      value: 'field',
                      label: 'Field Only',
                    },
                    {
                      value: 'warehouse',
                      label: 'Warehouse Only',
                    },
                    {
                      value: 'custom',
                      label: 'Custom',
                    },
                  ]}
                />
                {values.teamSelectionOption === 'custom' && (
                  <FormikMultiselectFormGroup
                    label="Teams"
                    name="teamIDs"
                    entries={teamOptions.map((option) => ({ id: option.id, name: option.name }))}
                  />
                )}
                <FormikFileFormGroup id="fileSignedID" name="fileSignedID" label="PDF File" accept="application/pdf" />
              </Panel.Body>
              <Panel.Footer align="right">
                <Button kind="primary" onClick={() => closeModal(false)}>
                  Cancel
                </Button>
                <Button loading={loading} kind="primary" type="submit">
                  Upload
                </Button>
              </Panel.Footer>
            </Panel>
          </FormikForm>
        )}
      </Formik>
    </Modal>
  );
};

(function () {
  const app = angular.module('app');

  app.directive('recompile', [
    '$parse',
    function ($parse) {
      return {
        transclude: true,
        link: function (scope, $el, attrs, ctrls, transclude) {
          let _elements;
          let _scope;

          function compile() {
            transclude(scope.$new(false, scope), function (element, $scope) {
              _elements = element;
              _scope = $scope;
              $el.append(element);
            });
          }

          function recompile() {
            if (_elements) {
              _elements.remove();
              _elements = null;
              $el.empty();
            }

            if (_scope) {
              _scope.$destroy();
            }

            compile();
          }

          scope.$watch(
            attrs.recompile,
            function (_new, _old) {
              if (_new !== _old) {
                recompile();
              }
            },
            typeof $parse(attrs.recompile)(scope) === 'object',
          );

          compile();
        },
      };
    },
  ]);
})();

import React from 'react';
import { Button } from '@shared/components/bootstrap';

export const Toggle: React.FC<{
  className?: string;
  included: boolean;
  onToggle(included: boolean): void;
}> = ({ className, included, onToggle }) => (
  <div className={className}>
    <Button kind={included ? 'success' : 'default'} active={included} onClick={() => onToggle(true)}>
      Include
    </Button>
    <Button
      className="mar-lft"
      kind={!included ? 'danger' : 'default'}
      active={!included}
      onClick={() => onToggle(false)}
    >
      Exclude
    </Button>
  </div>
);

import React, { useState } from 'react';
import { client } from '@admin/libraries/apollo';
import { Button, HelpBlock } from '@shared/components/bootstrap';
import { OrderStateEnum } from '@portal/schema';
import { OrderPolicyDocument, Status, useModifyAuctionMutation } from '@admin/schema';

export const SelfStorageAuction: React.FC<{
  orderID: number | string;
  state: string;
}> = ({ orderID, state }) => {
  const [error, setError] = useState<string | null | undefined>();
  const [modifyAuctionMutation] = useModifyAuctionMutation({
    client,
    awaitRefetchQueries: true,
    refetchQueries: [{ query: OrderPolicyDocument, variables: { orderID } }],
  });
  const auctionable = !(
    state === OrderStateEnum.Completed ||
    state === OrderStateEnum.Returned ||
    state === OrderStateEnum.Canceled
  );
  const markedForAuction = state === OrderStateEnum.Enroute;

  const handle = async () => {
    const result = await modifyAuctionMutation({
      variables: {
        id: orderID as string,
      },
    });

    if (result.data?.modifyAuction.status === Status.Ok) {
      setError(undefined);
      window.location.reload();
    } else if (
      result.data?.modifyAuction.status === Status.Unprocessable ||
      result.data?.modifyAuction.status === Status.Unauthorized
    ) {
      setError(result.data.modifyAuction.error);
    } else {
      setError('Sorry, something went wrong. Please try again or contact Tech Support.');
    }
  };

  return (
    <>
      <Button kind="default" disabled={!auctionable} onClick={handle}>
        {!markedForAuction && auctionable ? 'Mark Auctioned' : 'Unmark Auctioned'}
      </Button>
      {error && <HelpBlock>{error}</HelpBlock>}
    </>
  );
};

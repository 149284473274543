import React, { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { MultiselectFormControl } from '@admin/components/fields';
import { Spacer } from '@shared/components/helpers';
import styled from '@emotion/styled';
import { Button } from '@shared/components/bootstrap';
import { OpsAudit__QuestionInput } from '@admin/schema';
import { Field } from './field';
import { AnswerChoiceField } from './answer_choice_field';
import { transformStringArrayToBooleanArray, transformBooleanArrayToStringArray } from '../util';

export const StyledRow = styled.div`
  display: flex;
  align-items: center;
`;

const StyledButton = styled(Button)`
  margin: 0px 0px 5px;
`;

const OPTIONS = [
  'DNE',
  'appliance',
  'appliance_has_incl_part',
  'appliance_no_incl_part',
  'appliances',
  'bed_frame',
  'bed_frame_has_incl_part',
  'bed_frame_no_incl_part',
  'box',
  'box_has_incl_part',
  'box_no_incl_part',
  'clutter-packed',
  'customer-packed',
  'fragile',
  'fragile_box',
  'fragile_box_has_incl_part',
  'fragile_box_no_incl_part',
  'fragile_has_incl_part',
  'fragile_no_incl_part',
  'has_barcode',
  'mattress',
  'mattress_has_incl_part',
  'mattress_no_incl_part',
  'miscellaneous',
  'miscellaneous_has_incl_part',
  'miscellaneous_no_incl_part',
  'no_barcode',
  'no_packing_has_incl_part',
  'no_packing_no_incl_part',
  'pallet',
  'rug',
  'seating',
  'seating_has_incl_part',
  'seating_no_incl_part',
  'storage',
  'storage_furniture',
  'storage_has_incl_part',
  'storage_no_incl_part',
  'table',
  'table_has_incl_part',
  'table_no_incl_part',
  'television',
  'television_has_incl_part',
  'television_no_incl_part',
  'undirected',
].map((option) => ({ id: option, name: option }));

export const QuestionField: React.FC<{
  question: OpsAudit__QuestionInput;
  questionIndex: number;
}> = ({ question, questionIndex }) => {
  const { setValue, register } = useFormContext();
  const [groups, setGroups] = useState<string[]>(question.groups || []);
  const [answerChoices, setAnswerChoices] = useState<string[]>(question.answerChoices);
  const [defectAnswers, setDefectAnswers] = useState<boolean[]>(
    transformStringArrayToBooleanArray(question.answerChoices, question.defectAnswers || []),
  );
  const [failedAnswers, setFailedAnswers] = useState<boolean[]>(
    transformStringArrayToBooleanArray(question.answerChoices, question.failedAnswers || []),
  );
  const [deletedAt, setDeletedAt] = useState<string>(question.deletedAt || '');

  useEffect(() => {
    register({ name: `questions[${questionIndex}].groups` });
    register({ name: `questions[${questionIndex}].answerChoices` });
    register({ name: `questions[${questionIndex}].defectAnswers` });
    register({ name: `questions[${questionIndex}].failedAnswers` });
    register({ name: `questions[${questionIndex}].deletedAt` });
  }, [register]);

  const onAddAnswerChoice = () => {
    const newAnswerChoices = [...answerChoices, ''];
    const newDefectAnswers = [...defectAnswers, false];
    const newFailedAnswers = [...failedAnswers, false];
    setAnswerChoices(newAnswerChoices);
    setDefectAnswers(newDefectAnswers);
    setFailedAnswers(newFailedAnswers);
    setValue(`questions[${questionIndex}].answerChoices`, newAnswerChoices);
    setValue(
      `questions[${questionIndex}].defectAnswers`,
      transformBooleanArrayToStringArray(newAnswerChoices, newDefectAnswers),
    );
    setValue(
      `questions[${questionIndex}].failedAnswers`,
      transformBooleanArrayToStringArray(newAnswerChoices, newFailedAnswers),
    );
  };

  const onDelete = () => {
    const date = new Date();
    setDeletedAt(date.toISOString());
    setValue(`questions[${questionIndex}].deletedAt`, date);
  };

  const onModifyGroups = (options: string[]) => {
    setGroups(options);
    setValue(`questions[${questionIndex}].groups`, options);
  };

  return (
    <>
      <div>
        <hr />
        <Field id={`questions[${questionIndex}].prompt`} label="Prompt" required>
          <input
            id={`questions[${questionIndex}].prompt`}
            name={`questions[${questionIndex}].prompt`}
            type="string"
            className="form-control"
            ref={register({ required: true })}
          />
        </Field>
        <Field id={`questions[${questionIndex}].slug`} label="Slug" required>
          <input
            id={`questions[${questionIndex}].slug`}
            name={`questions[${questionIndex}].slug`}
            type="string"
            className="form-control"
            ref={register({ required: true })}
          />
        </Field>
        <Field id={`questions[${questionIndex}].failedMessage`} label="Failed Message">
          <input
            id={`questions[${questionIndex}].failedMessage`}
            name={`questions[${questionIndex}].failedMessage`}
            type="string"
            className="form-control"
            ref={register({ required: false })}
          />
        </Field>
        <Field id={`questions[${questionIndex}].answerChoicesLabel`} label="Answer Choices" required />
        {answerChoices.map((answer, answerIndex) => (
          <AnswerChoiceField
            questionIndex={questionIndex}
            answer={answer}
            answerIndex={answerIndex}
            answerChoices={answerChoices}
            setAnswerChoices={setAnswerChoices}
            defectAnswers={defectAnswers}
            setDefectAnswers={setDefectAnswers}
            failedAnswers={failedAnswers}
            setFailedAnswers={setFailedAnswers}
            key={`questions[${questionIndex}].${answerIndex}`}
          />
        ))}
        <StyledRow className="row pull-right">
          <StyledButton kind="primary" type="button" onClick={onAddAnswerChoice}>
            Add
          </StyledButton>
        </StyledRow>
        <Spacer height="20px" />
        <Field id={`questions[${questionIndex}].groups`} label="Groups">
          <MultiselectFormControl options={OPTIONS} selectedIDs={groups} onChange={onModifyGroups} />
        </Field>
        <StyledRow className="row">
          <div className="col-md-11">
            <Field id={`questions[${questionIndex}].deletedAt`} label="Soft Deleted At">
              <input
                value={deletedAt ? deletedAt.substring(0, 16) : ''}
                className="form-control"
                type="datetime-local"
                readOnly
              />
            </Field>
          </div>
          <div className="col-md-1">
            <Button kind="danger" onClick={onDelete} disabled={deletedAt.length !== 0}>
              Delete
            </Button>
          </div>
        </StyledRow>
        <Field id={`questions[${questionIndex}].id`} label="">
          <input
            id={`questions[${questionIndex}].id`}
            name={`questions[${questionIndex}].id`}
            type="hidden"
            className="form-control"
            ref={register}
          />
        </Field>
      </div>
    </>
  );
};

import { intersection } from 'lodash';
import { ROLES } from '@admin/config/roles';
import React from 'react';

import { Alert } from '@shared/components/bootstrap';
import { ACCOUNT_CANCEL_REASON_OPTIONS } from '@shared/config/account_cancel_reasons';

const allowedReasons = (existingReason?: string) =>
  ACCOUNT_CANCEL_REASON_OPTIONS.filter(
    (reason) =>
      reason.roles.length === 0 ||
      reason.value.toLowerCase() === existingReason ||
      intersection(ROLES, reason.roles).length > 0,
  );

export const AccountCancelReasonSelector: React.FC<{
  reason?: string;
  setReason(reason: string): void;
}> = ({ reason: existingReason, setReason }) => (
  <>
    <Alert style="info">
      <strong>Warning:</strong> Selecting all items will cancel this account.
    </Alert>
    <select onChange={(e) => setReason(e.currentTarget.value)} value={existingReason || ''}>
      <option value="">- Select a Reason for Cancellation -</option>
      {allowedReasons(existingReason).map((reason) => (
        <option key={reason.value} value={reason.value.toLowerCase()}>
          {reason.label}
        </option>
      ))}
    </select>
  </>
);

import {
  Dialog as ConfirmationDialog,
  Response as ConfirmationResponse,
  useConfirmation,
} from '@admin/components/confirmation';
import { InputFormGroup } from '@admin/components/fields';
import { client } from '@admin/libraries/apollo';
import {
  Status,
  useMovingLongDistanceTransportWeightUpsertDetailsQuery,
  useMovingLongDistanceTransportWeightUpsertMutation,
} from '@admin/schema';
import { Alert, Button } from '@shared/components/bootstrap';
import { Panel } from '@admin/components/helpers/panel';
import React, { useEffect, useState } from 'react';

export const TransportWeightUpsertForm: React.FC<{
  orderID: string;
}> = ({ orderID }) => {
  const [collapsed, setCollapsed] = useState(false);
  const [emptyTruckWeightInPounds, setEmptyTruckWeightInPounds] = useState<number | undefined>();
  const [loadedTruckWeightInPounds, setLoadedTruckWeightInPounds] = useState<number | undefined>();
  const [error, setError] = useState<string | undefined>();
  const { confirmation, confirm } = useConfirmation();

  const cargoWeightInPounds =
    loadedTruckWeightInPounds && emptyTruckWeightInPounds && loadedTruckWeightInPounds - emptyTruckWeightInPounds;

  const { data } = useMovingLongDistanceTransportWeightUpsertDetailsQuery({
    client,
    variables: { orderID },
  });

  const [upsert, { loading, data: upsertResponse }] = useMovingLongDistanceTransportWeightUpsertMutation({
    client,
  });

  useEffect(() => {
    if (data && data.order.movingOperation) {
      const movingOperation = data.order.movingOperation;
      const emptyTruckWeight =
        movingOperation.emptyTruckWeightInPounds || movingOperation.assumedEmptyTruckWeightInPounds || undefined;
      setEmptyTruckWeightInPounds(emptyTruckWeight);

      const loadedTruckWeight = movingOperation.loadedTruckWeightInPounds ?? undefined;
      setLoadedTruckWeightInPounds(loadedTruckWeight);
    }
  }, [data]);

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    event.stopPropagation();

    if (!emptyTruckWeightInPounds || emptyTruckWeightInPounds <= 0) {
      setError('Please set an empty truck weight greater than 0');
      return;
    }

    if (!loadedTruckWeightInPounds || loadedTruckWeightInPounds <= 0) {
      setError('Please set a loaded truck weight greater than 0');
      return;
    }

    if (cargoWeightInPounds && cargoWeightInPounds <= 0) {
      setError('Please set a cargo weight greater than 0');
      return;
    }

    setError(undefined);

    const confirmed = await confirm({
      title: 'Submit Weight?',
      description:
        'This is a long-distance move and the customer will be charged according to this weight, unless the move is billed using a flat-rate.',
    });
    if (confirmed !== ConfirmationResponse.Confirm) return;

    const response = await upsert({
      variables: { input: { orderID, emptyTruckWeightInPounds, loadedTruckWeightInPounds } },
    });

    if (response.data?.result?.error) {
      setError(response.data.result.error.errorMessage);
    }
  };

  if (!data?.order?.movingOperation?.longDistanceTransportationRate) {
    return null;
  }

  return (
    <div id="weight-upsert-panel">
      <form onSubmit={onSubmit}>
        <Panel>
          <Panel.Header>
            <Panel.Collapse collapsed={collapsed} onChange={setCollapsed} />
            <Panel.Title>Truck & Cargo Weight</Panel.Title>
          </Panel.Header>
          {!collapsed && (
            <>
              <Panel.Body>
                {upsertResponse?.result?.status === Status.Ok && (
                  <Alert style="success">Your changes have been saved</Alert>
                )}
                {error && <Alert style="danger">{error}</Alert>}
                <InputFormGroup
                  type="number"
                  label="Total Empty Truck Weight (lbs)"
                  id="empty-truck-weight"
                  help={data.order.movingOperation.emptyTruckWeightInPoundsSuggestion}
                  value={emptyTruckWeightInPounds}
                  onChange={(event) => setEmptyTruckWeightInPounds(event.target.valueAsNumber)}
                />
                <InputFormGroup
                  type="number"
                  label="Total Loaded Truck Weight (lbs)"
                  id="loaded-truck-weight"
                  help="If multiple trucks were used for the appointment, enter the sum of the loaded truck weights."
                  value={loadedTruckWeightInPounds}
                  onChange={(event) => setLoadedTruckWeightInPounds(event.target.valueAsNumber)}
                />
                <InputFormGroup
                  type="number"
                  label="Total Cargo Weight (lbs)"
                  id="cargo-weight"
                  help="The cargo weight is calculated based on the other entered weights."
                  value={cargoWeightInPounds}
                  disabled={true}
                />
              </Panel.Body>
              <Panel.Footer align="right">
                <>
                  <Button type="submit" kind="primary" disabled={loading}>
                    Submit Weight
                  </Button>
                </>
              </Panel.Footer>
            </>
          )}
        </Panel>
      </form>
      {confirmation && <ConfirmationDialog confirmation={confirmation} />}
    </div>
  );
};

import React from 'react';

import { Account } from '@admin/schema';

import { Label } from '@admin/components/helpers/label';

import { ACCOUNT_STATE_TO_KIND, ACCOUNT_STATE_TO_NAME } from '@admin/constants/account_state';

export const State: React.FC<{ account: Pick<Account, 'state'> }> = ({ account: { state } }) => (
  <Label kind={ACCOUNT_STATE_TO_KIND[state]}>{ACCOUNT_STATE_TO_NAME[state]}</Label>
);

import * as React from 'react';
import Select from 'react-select';

interface Option<T> {
  label: string;
  value: T;
}

export const SingleSelectFormControl = <T extends string | number>({
  id,
  disabled,
  placeholder,
  options,
  value: propsValue,
  onChange,
}: {
  id?: string;
  disabled?: boolean;
  placeholder?: string;
  options: Array<Option<T>>;
  value?: T;
  onChange(value: T | undefined): void;
}) => {
  const value = options.find((option) => option.value === propsValue);

  return (
    <Select
      id={id}
      classNamePrefix="Select"
      options={options}
      value={value}
      onChange={(option) => onChange(option === null ? undefined : option.value)}
      placeholder={placeholder}
      isDisabled={disabled}
    />
  );
};

import React from 'react';

import { User__Role } from '@admin/schema';

import { Label } from '@admin/components/helpers/label';

import { USER_ROLE_TO_NAME } from '@admin/constants/user_role';

export const Role: React.FC<{ role: User__Role }> = ({ role }) => (
  <Label kind="default">{USER_ROLE_TO_NAME[role]}</Label>
);

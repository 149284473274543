(function () {
  const app = angular.module('app');

  app.service('PollingService', [
    '$q',
    '$interval',
    function ($q, $interval) {
      this.poll = function (resource, params, endPollFunction, errorFunction) {
        const deferred = $q.defer();

        const interval = $interval(pollingFunction, 1000);

        function pollingFunction() {
          resource.get(params).$promise.then(function (response) {
            if (endPollFunction(response)) {
              $interval.cancel(interval);
              deferred.resolve(response);
            }
            if (errorFunction(response)) {
              $interval.cancel(interval);
              deferred.reject(response);
            }
          });
        }

        return deferred.promise;
      };
    },
  ]);
})();

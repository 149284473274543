import * as React from 'react';
import { useNonExistentPartsItemGroupQuery, NonExistentPartsItemGroupQuery } from '@admin/schema';

import { client } from '@admin/libraries/apollo';

type PartialItem = NonExistentPartsItemGroupQuery['itemGroup']['partialItems'][number];

export const NonexistentParts: React.FC<{
  itemGroupID: string;
}> = ({ itemGroupID }) => {
  const partialItems = useNonExistentPartsItemGroupQuery({ client, variables: { id: itemGroupID } }).data?.itemGroup
    .partialItems;
  const probableCategory = (resource: PartialItem) =>
    resource.category?.name || resource.partCategory?.name || resource.materialCategory?.name;

  return <>{`${partialItems && partialItems.map((partialItem) => probableCategory(partialItem)).join(', ')}`}</>;
};

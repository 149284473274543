(function () {
  angular.module('app').factory('OrderDeserializer', [
    function () {
      return function (data) {
        data.scheduled = new Date(data.scheduled);
        data.cancel_account = data.subtype === 'final';
        if (data.pickup_deadline) {
          data.pickup_deadline = new Date(data.pickup_deadline);
        }
        return data;
      };
    },
  ]);
})();

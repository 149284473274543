import React from 'react';
import { Breadcrumb } from '@shared/components/bootstrap';
import { Coupon__Input, useCouponCreateMutation } from '@admin/schema';
import { client } from '@admin/libraries/apollo';
import { CouponForm } from './form';

export const New: React.FC<{ onSave(): void }> = ({ onSave }) => {
  const [execute, { loading }] = useCouponCreateMutation({ client });

  return (
    <>
      <div className="page-header">
        <Breadcrumb>
          <Breadcrumb.Item>
            <a href="/marketing/coupons">Coupons</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>New</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <CouponForm
        onSave={onSave}
        loading={loading}
        save={(input: Coupon__Input) => execute({ variables: { input } })}
      />
    </>
  );
};

(function () {
  const app = angular.module('app');

  app.directive('viewLocation', function () {
    return {
      restrict: 'E',
      scope: {
        location: '=',
        showWarehouse: '=',
      },
      template:
        '<a ui-sref="viewLocation({ id: location.id })" class="text-primary">#{{location.barcode.value}}<span ng-show="showWarehouse"> ({{location.located.object.name}})<span></a>',
    };
  });
})();

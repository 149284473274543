(function () {
  angular.module('app').factory('Team', [
    '$resource',
    function ($resource) {
      return $resource(
        '/teams.json',
        {},
        {
          query: {
            method: 'GET',
            isArray: true,
            cache: true,
          },
        },
      );
    },
  ]);
})();

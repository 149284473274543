import React, { useState } from 'react';

import { client } from '@admin/libraries/apollo';

import { useRegionsQuery } from '@admin/schema';

import { Panel } from '@admin/components/helpers/panel';
import { regionURL } from '@admin/config/routes';
import { Table } from '@shared/components/bootstrap';
import { UpsertModal } from './upsert_modal';

export const Dashboard: React.FC<{}> = () => {
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);

  const { data } = useRegionsQuery({ client });

  if (!data) return null;

  const regions = data.regions;

  return (
    <Panel>
      <Panel.Header>
        <Panel.Title>Regions</Panel.Title>
      </Panel.Header>
      <Panel.Body>
        <Table striped responsive>
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Timezone</th>
              <th>Parent</th>
              <th className="col-zero text-right">
                <a className="btn btn-mint btn-labeled fa fa-plus" onClick={() => setShowCreateModal(true)}>
                  Add
                </a>
              </th>
            </tr>
          </thead>
          <tbody>
            {regions.map((region) => (
              <tr key={region.id}>
                <td>{region.id}</td>
                <td>{region.active ? region.name : `${region.name} (Inactive)`}</td>
                <td>{region.tz}</td>
                <td>{region.parent && region.parent.name}</td>
                <td className="col-zero text-right">
                  <a className="btn btn-primary" href={regionURL({ id: region.id })}>
                    View Details
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Panel.Body>
      {showCreateModal && (
        <UpsertModal
          warehouseOptions={data.warehouses}
          onClose={(newRegionID) => {
            if (newRegionID) {
              window.location.href = regionURL({ id: newRegionID });
            } else {
              setShowCreateModal(false);
            }
          }}
        />
      )}
    </Panel>
  );
};

import {
  Dialog as ConfirmationDialog,
  Response as ConfirmationResponse,
  useConfirmation,
} from '@admin/components/confirmation';
import { CurrencyFormControl, DurationInput, FieldFormGroup } from '@admin/components/fields';
import { Time } from '@admin/components/helpers/time';
import { client } from '@admin/libraries/apollo';
import {
  Moving__WalkthroughInput,
  PackageKindEnum,
  useMovingWalkthroughDeleteMutation,
  useMovingWalkthroughDetailsQuery,
  useMovingWalkthroughUpdateMutation,
} from '@admin/schema';
import styled from '@emotion/styled';
import { Alert, Button, InputGroup } from '@shared/components/bootstrap';
import { Panel } from '@admin/components/helpers/panel';
import { Currency } from '@shared/components/helpers';
import { parseGQLErrorUnion } from '@shared/utils/gql_errors';
import React, { useEffect, useState } from 'react';

const MarginButton = styled(Button)`
  margin-left: 4px;
`;

const validate = ({ flatRateAmount, walkthroughEstimatedDuration }: Moving__WalkthroughInput): string | undefined => {
  let errors = '';

  if (flatRateAmount && flatRateAmount <= 0) {
    errors += 'Flat-rate amount must be greater than 0. ';
  }
  if (!walkthroughEstimatedDuration) {
    errors += 'Estimated duration must be set. ';
  } else if (walkthroughEstimatedDuration <= 0) {
    errors += 'Estimated duration must be greater than 0.';
  }

  return errors || undefined;
};

export const WalkthroughForm: React.FC<{
  orderID: string;
}> = ({ orderID }) => {
  const [collapsed, setCollapsed] = useState(false);
  const [flatRateAmount, setFlatRateAmount] = useState<number | undefined>();
  const [duration, setDuration] = useState<number | undefined>();
  const [validationError, setValidationError] = useState<string | undefined>();
  const [savedFlatRateAmount, setSavedFlatRateAmount] = useState<number | undefined>();
  const [lastChange, setLastChange] = useState<'delete' | 'update' | undefined>();
  const { confirmation, confirm } = useConfirmation();

  const { data } = useMovingWalkthroughDetailsQuery({
    client,
    variables: { orderID },
  });

  const [updateWalkthrough, { loading: updateLoading, data: updateResponse }] = useMovingWalkthroughUpdateMutation({
    client,
  });
  const [deleteWalkthrough, { loading: deleteLoading, data: deleteResponse }] = useMovingWalkthroughDeleteMutation({
    client,
  });

  useEffect(() => {
    if (data) {
      const accountPackages = data.order?.accountPackages ?? [];

      const currentFlatRateAmount = accountPackages.find(
        (accountPackage) => accountPackage.kind === PackageKindEnum.FlatRateService,
      )?.amount;

      setFlatRateAmount(currentFlatRateAmount);
      setSavedFlatRateAmount(currentFlatRateAmount);

      setDuration(data.order?.estimatedDuration ?? undefined);
    }
  }, [data]);

  const mutationLoading = updateLoading || deleteLoading;

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    event.stopPropagation();

    if (mutationLoading) {
      return;
    }

    setLastChange('update');
    const submitError = validate({ walkthroughEstimatedDuration: duration, flatRateAmount });
    if (submitError) {
      setValidationError(submitError);
      return;
    } else {
      setValidationError(undefined);
    }

    const durationCallout = <>The estimated duration of the order is {<Time value={duration!} />}.</>;
    const confirmed = await confirm({
      title: 'Submit Virtual Walkthrough?',
      description: (
        <>
          {flatRateAmount ? (
            <>
              Submitting a virtual walkthrough with a flat-rate amount will guarantee that the customer pays exactly{' '}
              <Currency value={flatRateAmount} /> for labor. This customer will not be charged their normal hourly rate.{' '}
              {durationCallout}
            </>
          ) : (
            <>{durationCallout} Note: this customer will still be charged their normal hourly rate.</>
          )}
        </>
      ),
    });
    if (confirmed !== ConfirmationResponse.Confirm) return;

    updateWalkthrough({
      variables: { orderID, input: { walkthroughEstimatedDuration: duration, flatRateAmount } },
    });
  };

  const onDelete = async (event: React.FormEvent) => {
    event.preventDefault();
    event.stopPropagation();

    setLastChange('delete');

    const confirmed = await confirm({
      title: 'Remove Flat Rate and Virtual Walkthrough?',
      description: 'This customer will be charged their normal hourly rate',
    });
    if (confirmed !== ConfirmationResponse.Confirm) return;

    deleteWalkthrough({ variables: { orderID } });
  };

  const [updatedOrder, updateError] = parseGQLErrorUnion(updateResponse?.result.order);
  const [deletedOrder, deleteError] = parseGQLErrorUnion(deleteResponse?.result.order);

  return (
    <div id="walkthrough-form-panel">
      <form onSubmit={onSubmit}>
        <fieldset disabled={!data || mutationLoading}>
          <Panel>
            <Panel.Header>
              <Panel.Collapse collapsed={collapsed} onChange={setCollapsed} />
              <Panel.Title>Virtual Walkthrough Details</Panel.Title>
            </Panel.Header>
            {!collapsed && (
              <>
                <Panel.Body>
                  {lastChange === 'update' && (
                    <>
                      {updateError && <Alert style="danger">{updateError.errorMessage}</Alert>}
                      {updatedOrder && <Alert style="success">Your changes have been saved</Alert>}
                    </>
                  )}
                  {lastChange === 'delete' && (
                    <>
                      {deleteError && <Alert style="danger">{deleteError.errorMessage}</Alert>}
                      {deletedOrder && <Alert style="success">The flat rate has been removed</Alert>}
                    </>
                  )}
                  {validationError && <Alert style="danger">{validationError}</Alert>}
                  <FieldFormGroup label="Flat-Rate Amount" id="flat-rate-amount">
                    <InputGroup>
                      <InputGroup.Addon>$</InputGroup.Addon>
                      <CurrencyFormControl id="flat-rate-amount" onChange={setFlatRateAmount} amount={flatRateAmount} />
                    </InputGroup>
                  </FieldFormGroup>
                  <FieldFormGroup label="Estimated Duration" id="estimated-duration">
                    <DurationInput duration={duration ?? 0} onChange={setDuration} unit="seconds" />
                  </FieldFormGroup>
                </Panel.Body>
                <Panel.Footer align="right">
                  {data?.permissions?.submitVirtualWalkthrough && (
                    <>
                      <MarginButton type="submit" kind="primary" disabled={mutationLoading}>
                        Submit Virtual Walkthrough
                      </MarginButton>
                      {savedFlatRateAmount && (
                        <MarginButton kind="danger" disabled={mutationLoading} onClick={onDelete}>
                          Remove Flat Rate
                        </MarginButton>
                      )}
                    </>
                  )}
                </Panel.Footer>
              </>
            )}
          </Panel>
        </fieldset>
      </form>
      {confirmation && <ConfirmationDialog confirmation={confirmation} />}
    </div>
  );
};

import React, { useState } from 'react';

import { Button, Panel, Table, Col, Row } from '@shared/components/bootstrap';

import { client } from '@admin/libraries/apollo';

import { usePaginatedLeadsQuery, Lead, Lead__Status } from '@admin/schema';

import { useDebounce } from '@shared/hooks';

import { InputFormGroup, SelectFormGroup } from '@admin/components/fields';
import { Spinner } from '@admin/components/spinner';
import { Pagination } from '@admin/components/pagination';

import { CreateLeadButton } from './create_leads';
import { titleize } from '../helpers/titleize';

const DEFAULT_PAGE = 1;

const formatStatus = (status: Lead__Status) => titleize(status.split('_').join(' '));

const QueryFormGroup: React.FC<{
  value?: string;
  onChange(_?: string): void;
}> = ({ value, onChange }) => (
  <InputFormGroup
    id="filter_query"
    name="filter[query]"
    label="Search:"
    type="text"
    value={value ?? ''}
    onChange={(event) => onChange(event.target.value || undefined)}
    placeholder="Search by name, email, phone, zip, or Close.io lead ID"
  />
);

const StatusFormGroup: React.FC<{
  value?: string;
  onSelect(_?: Lead__Status): void;
}> = ({ value, onSelect }) => (
  <SelectFormGroup
    id="filter_status"
    name="filter[status]"
    value={value}
    onChange={(event) => onSelect(event.target.value as Lead__Status)}
    label="Status:"
  >
    <option value=""> - All - </option>
    {Object.values(Lead__Status).map((statusOption, key) => (
      <option value={statusOption} key={key}>
        {formatStatus(statusOption)}
      </option>
    ))}
  </SelectFormGroup>
);

export const List: React.FC<{
  onSelect(lead: Pick<Lead, 'id'>): void;
}> = ({ onSelect }) => {
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [query, setQuery] = useState<string | undefined>();
  const [status, setStatus] = useState<Lead__Status | undefined>(Lead__Status.Qualified);

  const { data, loading, refetch } = usePaginatedLeadsQuery({
    client,
    variables: {
      page,
      filters: {
        status: status || undefined,
        query: useDebounce(query),
      },
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  return (
    <>
      <Panel>
        <Panel.Body>
          <Row>
            <Col xs={4}>
              <StatusFormGroup value={status} onSelect={setStatus} />
            </Col>
            <Col xs={8}>
              <QueryFormGroup value={query} onChange={setQuery} />
            </Col>
          </Row>
        </Panel.Body>
        <Panel.Footer align="right">
          <CreateLeadButton onSave={onSelect} />
          <Button kind="default" onClick={() => refetch()} loading={loading}>
            Refresh Leads
          </Button>
        </Panel.Footer>
      </Panel>

      <Panel>
        <Panel.Body>
          <Table responsive striped>
            <thead>
              <tr>
                <th className="col-zero">ID</th>
                <th className="col-sm-4">Name</th>
                <th className="col-sm-3">Email</th>
                <th className="col-sm-2">Status</th>
                <th className="col-sm-3">Close Lead ID</th>
                <th className="col-zero" />
              </tr>
            </thead>
            <tbody>
              {data?.paginated.results.map((lead) => (
                <tr key={lead.id}>
                  <td>{lead.id}</td>
                  <td>{lead.name ?? '-'}</td>
                  <td>{lead.email ?? '-'}</td>
                  <td>{formatStatus(lead.status)}</td>
                  <td>{lead.closeLeadID ?? '-'}</td>
                  <td>
                    <Button onClick={() => onSelect(lead)} kind="primary">
                      View
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {!data && <Spinner />}
          {data && <Pagination onPage={setPage} pagination={data.paginated.pagination} />}
        </Panel.Body>
      </Panel>
    </>
  );
};

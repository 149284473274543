(function () {
  angular.module('app').factory('Affiliate', [
    '$resource',
    'TransformBuilder',
    'AffiliateDeserializer',
    function ($resource, TransformBuilder, AffiliateDeserializer) {
      return $resource(
        '/marketing/affiliates/:id.json',
        {
          id: '@id',
        },
        {
          index: {
            method: 'GET',
            isArray: true,
          },
          show: {
            method: 'GET',
            transformResponse: TransformBuilder.transformResponse(AffiliateDeserializer),
          },
          create: {
            method: 'POST',
            transformResponse: TransformBuilder.transformResponse(AffiliateDeserializer),
          },
          update: {
            method: 'PUT',
            transformResponse: TransformBuilder.transformResponse(AffiliateDeserializer),
          },
          delete: {
            method: 'DELETE',
          },
          kinds: {
            method: 'GET',
            isArray: true,
            url: '/marketing/affiliates/kinds.json',
          },
        },
      );
    },
  ]);
})();

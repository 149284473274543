// http://codepen.io/rpdasilva/pen/DpbFf?editors=0010

import { formatPhoneForHuman } from '@admin/utils/format_phone_for_human';

(function () {
  const app = angular.module('app');

  app.directive('inputTelephoneFilter', [
    '$browser',
    function ($browser) {
      return {
        restrict: 'A',
        require: 'ngModel',
        link: function (scope, element, attrs, ngModelController) {
          const listener = function () {
            const value = element.val().replace(/[^0-9]/g, '');
            element.val(formatPhoneForHuman(value));
          };

          ngModelController.$parsers.push(function (viewValue) {
            return viewValue.replace(/[^0-9]/g, '').slice(0, 10);
          });

          ngModelController.$render = function () {
            element.val(formatPhoneForHuman(ngModelController.$viewValue));
          };

          element.bind('change', listener);
          element.bind('keydown', function (event) {
            const key = event.keyCode;
            const CMD = 91;
            const C = 67;
            const LEFTARROW = 37;
            const DOWNARROW = 40;
            const SHIFT = 16;
            const ALT = 18;

            const numberPress =
              (event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105);
            if (numberPress && element.val() && element.val().replace(/[^0-9]/g, '').length >= 10) {
              event.preventDefault();
            }

            if (key === CMD || (SHIFT <= key && key <= ALT) || (LEFTARROW <= key && key <= DOWNARROW) || key === C) {
              return;
            }

            $browser.defer(listener);
          });

          element.bind('paste cut', function () {
            $browser.defer(listener);
          });
        },
      };
    },
  ]);
})();

import React from 'react';
import PropTypes from 'prop-types';
import { DirectionsRenderer } from 'react-google-maps';
import { lowerCase } from 'lodash';
import { isOrderHoveredOrSelected, isDispatchHoveredOrSelected } from '../utils';
import { layoutEventSystem } from '../propTypes';

const DashboardDirections = ({ directions, dispatch, fillColor, strokeColor, gMaps, ...eventSystem }) =>
  directions.map((direction) => {
    let isActive;
    if (lowerCase(direction.type) === 'order') {
      isActive = isOrderHoveredOrSelected(eventSystem, direction.id);
    } else {
      isActive = isDispatchHoveredOrSelected(eventSystem, direction.id);
    }
    return (
      <DirectionsRenderer
        key={`${dispatch.id}:${direction.key}`}
        directions={direction}
        options={{
          markerOptions: {
            visible: false,
          },
          preserveViewport: true,
          polylineOptions: {
            strokeColor,
            strokeWeight: 3,
            strokeOpacity: isActive ? 0.9 : 0.7,
            icons: [
              {
                icon: {
                  path: gMaps.SymbolPath.FORWARD_CLOSED_ARROW,
                  fillColor,
                  scale: 3,
                  fillOpacity: 1,
                },
                repeat: '7%',
              },
            ],
          },
        }}
      />
    );
  });

DashboardDirections.propTypes = {
  directions: PropTypes.array,
  dispatch: PropTypes.object,
  fillColor: PropTypes.string,
  strokeColor: PropTypes.string,
  gMaps: PropTypes.object,
  ...layoutEventSystem,
};

export default DashboardDirections;

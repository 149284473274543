import React, { useState } from 'react';

import { client } from '@admin/libraries/apollo';

import { Button } from '@shared/components/bootstrap';

import { currentUser } from '@admin/config/current_user';

import {
  PalletRequestKindEnum,
  useBuildPalletRequestMutation,
  useDeletePalletRequestMutation,
  useInProgressManualPalletRequestQuery,
} from '@admin/schema';
import { SelectWithOther } from '../helpers/select_with_other';

export const PalletRequestButton: React.FC<{
  palletID: string;
}> = ({ palletID }) => {
  const [reason, setReason] = useState('');
  const requestorID = currentUser.id.toString();
  const [submitPalletRequest, { loading: creatingPalletRequest }] = useBuildPalletRequestMutation({
    client,
    variables: {
      input: {
        palletID,
        requestorID,
        kind: PalletRequestKindEnum.Outbound,
        manuallyRequested: true,
        manualRequestReason: reason,
      },
    },
  });
  const {
    data,
    loading: fetchingPalletRequest,
    refetch,
  } = useInProgressManualPalletRequestQuery({
    client,
    variables: { palletID },
  });
  const palletRequestID = data?.inProgressManualPalletRequest?.id;

  const [deletePalletRequest, { loading: deletingPalletRequest }] = useDeletePalletRequestMutation({ client });

  const requestPallet = async () => {
    const result = await submitPalletRequest();
    if (!result || !result.data) {
      return;
    }

    setReason('');
    refetch();
  };

  const cancelPalletRequest = async (id: string) => {
    const result = await deletePalletRequest({
      variables: {
        id,
      },
    });
    if (!result || !result.data) {
      return;
    }

    refetch();
  };

  const REQUEST_REASONS = ['Transfer', 'Missing Item Investigation', 'Pallet Rework'];

  return (
    <div className="text-right">
      {palletRequestID === undefined && (
        <>
          <SelectWithOther
            options={REQUEST_REASONS}
            option={reason}
            onSelect={setReason}
            placeholder="Select a Request Reason"
          />
          <Button
            kind="primary"
            disabled={creatingPalletRequest || fetchingPalletRequest || !reason}
            onClick={() => requestPallet()}
          >
            Request Pallet to Dock
          </Button>
        </>
      )}
      {palletRequestID && (
        <Button
          kind="danger"
          disabled={deletingPalletRequest || fetchingPalletRequest}
          onClick={() => cancelPalletRequest(palletRequestID)}
        >
          Cancel Request for Pallet to Dock
        </Button>
      )}
    </div>
  );
};

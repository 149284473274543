import { round } from 'lodash';
import * as React from 'react';
import { useEffect, useState } from 'react';

const PRECISION = 2;

export const CurrencyFormControl: React.FC<
  Omit<React.InputHTMLAttributes<HTMLInputElement>, 'amount' | 'onChange'> & {
    amount?: number;
    onChange(amount?: number): void;
  }
> = ({ amount, onChange, ...props }) => {
  const [value, setValue] = useState<number | string | undefined>(amount);
  useEffect(() => {
    // This effect allows this component to be controlled by the amount prop while also handling rounding onBlur
    setValue(amount);
  }, [amount]);

  return (
    <input
      {...props}
      className="form-control"
      type="number"
      placeholder="0.00"
      value={value ?? ''}
      onChange={(event) => {
        setValue(event.target.value);
      }}
      onBlur={(event) => {
        // We handle rounding onBlur so that the user can see their value entered as-is before
        // it gets rounded onBlur
        const raw = !event.target.value.length ? undefined : Number(event.target.value);
        const val = typeof raw === 'number' && !Number.isNaN(raw) ? round(raw, PRECISION) : undefined;
        onChange(val);
        setValue(val);
      }}
    />
  );
};

(function () {
  angular.module('app').service('NotificationService', [
    '$window',
    function ($window) {
      const self = this;
      const Notification = $window.Notification;

      self.request = function () {
        return Notification.requestPermission();
      };

      self.setup = function (callback) {
        if (!Notification) {
          return;
        }
        switch (Notification.permission) {
          case 'default': {
            const request = self.request();
            if (request) {
              return self.request().then(function (permission) {
                if (callback) {
                  callback(permission);
                }
              });
            } else if (callback) {
              callback(Notification.permission);
            }
            break;
          }
          default:
            if (callback) {
              callback(Notification.permission);
            }
        }
      };

      self.notify = function (title, options, callback) {
        if (!Notification) {
          return;
        }
        if (!options) {
          options = {};
        }

        const notification = new Notification(title, options);
        notification.onclick = function () {
          window.focus();
          if (callback) {
            callback();
          }
        };

        return notification;
      };
    },
  ]);
})();

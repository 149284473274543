import * as React from 'react';
import { forwardRef } from 'react';

import { FieldFormGroupProps } from '../field_form_group';

import { InputFormControlType, InputFormControlProps } from '../input_form_control';
import { FormikFieldFormGroup } from './formik_field_form_group';
import { FormikInputFormControl } from './formik_input_form_control';

type InputFormGroupType = InputFormControlType;
type InputFormGroupProps = InputFormControlProps & FieldFormGroupProps & { name: string };

export const FormikInputFormGroup = forwardRef<InputFormGroupType, InputFormGroupProps>(
  ({ help, label, children, ...input }, ref) => (
    <FormikFieldFormGroup name={input.name} help={help} label={label} id={input.id}>
      <FormikInputFormControl ref={ref} {...input} />
    </FormikFieldFormGroup>
  ),
);

import { FormikAddressFinderFormGroup } from '@admin/components/fields/formik/formik_address_finder_form_group';
import { FormikInputFormGroup } from '@shared/components/fields/formik/formik_input_form_group';
import { FormikPhoneFormGroup } from '@admin/components/fields/formik/formik_phone_form_group';
import { FormikTextAreaFormGroup } from '@admin/components/fields/formik/formik_text_area_form_group';
import { client } from '@admin/libraries/apollo';
import { Maybe, TaskOrder__TaskFragment, useAccountAddressUpsertMutation } from '@admin/schema';
import { Button, Modal, Text } from '@shared/components/bootstrap';
import { Form, Formik, FormikHelpers } from 'formik';
import React, { useState } from 'react';

export type ClientTask = {
  id: string | undefined;
  instructions?: string | null;
  contact?: {
    name?: Maybe<string>;
    phone: string;
  } | null;
  address?: {
    id: string;
    street: string;
    city: string;
    state: string;
    zip: string;
  };
};

type FormValues = {
  contactName?: string;
  contactPhone?: string;
  address?: TaskOrder__TaskFragment['address'];
  instructions?: string;
};

export const TaskModal = ({
  task,
  onEdit,
  accountID,
  onClose,
}: {
  onClose(): void;
  onEdit(task: ClientTask): void;
  orderID: string;
  task: ClientTask;
  accountID: string;
}) => {
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState(false);
  const [upsertAddress, { error: mutationError }] = useAccountAddressUpsertMutation({ client });

  const createAddress = async (input: Exclude<ClientTask['address'], undefined>) => {
    const { data, errors } = await upsertAddress({
      variables: { accountID, input: { ...input, details: { buildingType: 'Other' } } },
    });
    if (data?.accountAddressUpsert.address) {
      return data.accountAddressUpsert.address;
    } else {
      throw errors?.[0] || new Error(data?.accountAddressUpsert.error ?? 'Could not create new address');
    }
  };

  const onSave = async (values: FormValues, helpers: FormikHelpers<FormValues>) => {
    let valid = true;
    const { contactName, contactPhone, address, instructions } = values;

    if (contactName && !contactPhone) {
      helpers.setFieldError('contactPhone', 'Adding a contact requires a phone');
      valid = false;
    }

    if (!address) {
      helpers.setFieldError('address', 'Address is required');
      valid = false;
    }

    if (!valid || !address) return;

    try {
      setLoading(true);
      let resolvedAddress = address;
      if (!resolvedAddress.id) {
        resolvedAddress = await createAddress(address);
      }
      await onEdit({
        id: task.id,
        address: resolvedAddress,
        instructions,
        contact: contactPhone ? { name: contactName?.trim() ?? undefined, phone: contactPhone } : null,
      });
      onClose();
    } catch (e) {
      setLoading(false);
      if (e instanceof Error) setError(e);
      else throw e;
    }
  };

  return (
    <Modal onClose={onClose}>
      <Formik<FormValues>
        initialValues={{
          contactName: task?.contact?.name ?? undefined,
          contactPhone: task?.contact?.phone ?? undefined,
          instructions: task?.instructions ?? undefined,
          address: task.address,
        }}
        onSubmit={onSave}
      >
        <Form>
          <Modal.Content>
            <Modal.Header>
              <Modal.Title>Edit Task</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <FormikAddressFinderFormGroup label="Address" name="address" accountID={accountID} />
              <FormikTextAreaFormGroup label="Instructions" name="instructions" />
              <FormikInputFormGroup label="Contact Name" name="contactName" />
              <FormikPhoneFormGroup label="Contact Phone" name="contactPhone" />
            </Modal.Body>
            <Modal.Footer>
              <Text style="danger">{error?.message ?? mutationError?.message} </Text>
              <Button kind="danger" onClick={onClose}>
                Cancel
              </Button>
              <Button kind="primary" loading={loading} type="submit">
                Save
              </Button>
            </Modal.Footer>
          </Modal.Content>
        </Form>
      </Formik>
    </Modal>
  );
};

import * as React from 'react';
import Select from 'react-select';

interface ISelectable<T> {
  id: T;
  name: string;
}

interface IOption<T> {
  label: string;
  value: T;
}

export const MultiselectFormControl = <T extends string | number>({
  disabled,
  placeholder,
  options: entries,
  selectedIDs,
  onChange,
}: {
  disabled?: boolean;
  placeholder?: string;
  options: Array<ISelectable<T>>;
  selectedIDs: T[];
  onChange(selectedIDs: T[]): void;
}) => {
  const selected = new Set<T>(selectedIDs);
  const options: Array<IOption<T>> = entries.map((entry) => ({ value: entry.id, label: entry.name }));
  const value = options.filter((option) => selected.has(option.value));

  return (
    <Select
      isMulti
      classNamePrefix="Select"
      options={options}
      value={value}
      onChange={(selections: any) => {
        onChange((selections as Array<IOption<T>>).map((option) => option.value));
      }}
      placeholder={placeholder}
      isDisabled={disabled}
    />
  );
};

import * as React from 'react';

import { SelfStorage__ProtectionFragment } from '@admin/schema';

import { Currency } from '@shared/components/helpers';

import { Selector } from './selector';

export const Rows: React.FC<{
  facilityID?: string;
  selection?: SelfStorage__ProtectionFragment;
  onChange(selection?: SelfStorage__ProtectionFragment): void;
}> = ({ facilityID, selection, onChange }) =>
  facilityID ? (
    <tr>
      <td className="text-left">
        <strong>Protection Plan</strong>
      </td>
      <td>
        <Selector facilityID={facilityID} selection={selection} onSelect={(protection) => onChange(protection)} />
      </td>
      <td className="text-right">
        <Currency value={selection ? selection.price : 0} />
      </td>
    </tr>
  ) : null;

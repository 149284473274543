import angular from 'angular';

const app = angular.module('app');

app.component('deleteLeadButton', {
  templateUrl: 'partials/components/delete_lead_button.html',
  bindings: {
    lead: '<',
  },
  controller: [
    '$window',
    'ErrorService',
    function DeleteLeadButtonController($window, ErrorService) {
      this.deleteLead = () =>
        this.lead.$destroy(() => {
          $window.location.assign('/leads');
        }, ErrorService.handle);
    },
  ],
});

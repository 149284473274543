import styled from '@emotion/styled';
import { COLORS } from '@root/colors';
import React from 'react';

import { TooltipPlacement } from '@shared/components/bootstrap/tooltip';

import { EventStream } from './event_stream';
import { GanttRow } from './types';

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 5px;
  position: relative;
  height: 80px;
  box-sizing: border-box;
  border-bottom: 1px solid gray;

  &:first-child {
    border-top: 1px solid gray;
  }

  &:last-child {
    border-bottom: none;
  }
`;

const Description = styled.span`
  position: relative;
  left: 5px;
  top: 10px;
  padding: 7px;
  background: ${COLORS.grayLightest};
  border-radius: 10px;
  box-sizing: border-box;
`;

export const Row: React.FC<{
  row: GanttRow;
}> = ({ row }) => (
  <Container>
    <div>
      <Description>{row.title}</Description>
    </div>
    <EventStream placement={TooltipPlacement.Bottom} events={row.events} />
  </Container>
);

import * as React from 'react';

import { ControlLabel, FormGroup, HelpBlock } from '@shared/components/bootstrap';

export type FieldFormGroupProps = {
  id?: string;
  has?: 'error' | 'warning' | 'success';
  help?: string | React.ReactNode;
  label?: React.ReactNode;
  className?: string;
};

export const FieldFormGroup: React.FC<FieldFormGroupProps> = ({ id, has, help, label, className, children }) => (
  <FormGroup has={has} className={className}>
    {label && <ControlLabel htmlFor={id}>{label}</ControlLabel>}
    {children}
    {help && <HelpBlock>{help}</HelpBlock>}
  </FormGroup>
);

import template from '@admin/angular/templates/incidents_panel.html';

import angular from 'angular';

const app = angular.module('app');

app.component('incidentsPanel', {
  template,
  bindings: {
    kind: '@', // i.e. "item"
    parentType: '@',
    parentId: '<',
    resource: '<',
    lightbox: '<',
    timezone: '<?',
  },
  controller: [
    'Incident',
    '$filter',
    'ngDialog',
    'HistoryModalService',
    'UserService',
    function (Incident, $filter, ngDialog, HistoryModalService, UserService) {
      this.refresh = () => {
        this.incidents = Incident.query({
          parent_type: this.parentType,
          parent_id: this.parentId,
        });
      };

      const presentIncidentsDialogController = (incident) => {
        Object.assign(incident, {
          parent_type: this.parentType,
          parent_id: this.parentId,
        });

        ngDialog
          .openConfirm({
            plain: true,
            template: `
            <incidents-dialog
              incident="ngDialogData.incident"
              resource="ngDialogData.resource"
              on-save="confirm"
              on-cancel="closeThisDialog"
            >
            </incidents-dialog>
          `,
            data: {
              incident,
              resource: this.resource,
            },
          })
          .then(this.refresh);
      };

      this.new = function () {
        const incident = new Incident();
        presentIncidentsDialogController(incident);
      };

      this.edit = function (selection) {
        const incident = angular.copy(selection);
        presentIncidentsDialogController(incident);
      };

      this.history = function (selection) {
        HistoryModalService.present(selection.id, 'INCIDENT', selection.user.tz);
      };

      this.hasReportIncidentRole = UserService.hasRole('admin') || UserService.hasRole('claims');

      this.$onInit = () => {
        this.readOnly = this.kind === 'pallet';
        this.refresh();
      };

      this.formattedDateTime = (dateTime) =>
        $filter('datetz')(
          new Date(dateTime),
          this.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone,
          'MMM d, y, h:mm:ss a',
        );
    },
  ],
});

const app = angular.module('app');

const INCIDENT_OPTIONS = [
  { label: 'damaged', value: 'damaged' },
  { label: 'duplicate', value: 'duplicate' },
  { label: 'lost', value: 'lost' },
  { label: 'auction', value: 'auction' },
  { label: 'missing', value: 'missing' },
  { label: 'inaccessible', value: 'inaccessible' },
];

const MAX_DIMENSION = 500;
const MIN_DIMENSION = 0;

const parseBase10 = (value) => parseInt(value, 10);

const displaySizingSelection = (value, unit) => {
  let str = value;
  if (unit) {
    str = `${str}-${unit}`;
  }
  return str;
};

const sizingEnumerations = (sizingMetric) => {
  const results = [];

  if (sizingMetric.range) {
    const range = sizingMetric.range.split(',');
    for (let i = parseBase10(range[0]); i <= parseBase10(range[1]); i += 1) {
      results.push({
        name: displaySizingSelection(i, sizingMetric.unit),
        value: i,
      });
    }
  } else {
    sizingMetric.enumerations.forEach((i) => {
      results.push({
        name: displaySizingSelection(i, sizingMetric.unit),
        value: i,
      });
    });
  }

  return results;
};

app.component('itemsSearchFilter', {
  templateUrl: 'partials/components/items_search_filter.html',
  bindings: {
    initialFilters: '<',
    searchDisabled: '<?',
    onSearchClick: '&',
    onFilterChange: '&',
  },
  controller: [
    'Setup',
    'Account',
    'ItemState',
    '$q',
    '$filter',
    function (Setup, Account, ItemState, $q, $filter) {
      const FILTERS = [
        'pickup_date_from',
        'pickup_date_till',
        'category_filter_type',
        'dimension_filter_type',
        'dimension_min_cuft',
        'dimension_max_cuft',
        'in_group',
        'height_filter_type',
        'width_filter_type',
        'length_filter_type',
        'min_height',
        'max_height',
        'min_width',
        'max_width',
        'min_length',
        'max_length',
        'include_undefined_height',
        'include_undefined_width',
        'include_undefined_length',
      ];

      const self = this;

      self.$onInit = function init() {
        const selections = _.pick(self.initialFilters, [
          'category_ids',
          'sizing_selections',
          'incidents',
          'mover_ids',
          'warehouse_ids',
          'item_states',
          'part_category_ids',
          'material_category_ids',
          'pallet_type_ids',
          'account_ids',
        ]);
        self.filters = {
          category_filter_type: 'any',
          dimension_filter_type: 'any',
          height_filter_type: 'any',
          width_filter_type: 'any',
          length_filter_type: 'any',
          selections,
        };

        self.height = { min: MIN_DIMENSION, max: MAX_DIMENSION };
        self.width = { min: MIN_DIMENSION, max: MAX_DIMENSION };
        self.length = { min: MIN_DIMENSION, max: MAX_DIMENSION };
        self.max_dimension = MAX_DIMENSION;
        self.min_dimension = MIN_DIMENSION;

        self.groupOptions = [
          { name: 'In a Group', value: 'true' },
          { name: 'Not in a Group', value: 'false' },
        ];

        self.incidentOptions = [
          {
            label: 'Open',
            value: 'open',
            children: INCIDENT_OPTIONS,
          },
          {
            label: 'Closed',
            value: 'closed',
            children: INCIDENT_OPTIONS,
          },
        ];

        $q.all([
          Setup.get().$promise,
          ItemState.index().$promise,
          Account.index({ lost_and_found: true }).$promise,
        ]).then((data) => {
          self.users = data[0].users;
          self.itemCategories = data[0].item_categories;
          self.materialCategories = self.selectableMaterialCategories(data[0].material_categories);
          self.idToCategoryMapping = self.itemCategories.reduce((acc, category) => {
            acc[category.id] = category;
            return acc;
          }, {});
          self.warehouses = data[0].warehouses;
          self.itemStates = data[1].map((state) => ({ name: state }));
          self.partCategories = data[0].part_categories;
          self.palletTypes = data[0].pallet_types;
          self.accounts = data[2].results;
          self.accounts.forEach((account) => (account.name = account.customers[0].name));

          if (self.initialFilters) {
            Object.assign(self.filters, _.pick(self.initialFilters, FILTERS));
            self.loadSelections(self.initialFilters);
          }
        });
      };

      self.selectableMaterialCategories = function (materialCategories) {
        const filtered = _.filter(materialCategories, (materialCategory) =>
          ['box', 'customer_provided_box', 'customer_box'].includes(materialCategory.kind),
        );

        const titleize = $filter('titleize');
        filtered.forEach((category) => (category.displayName = `${category.name} (${titleize(category.kind)})`));
        return filtered;
      };

      self.resetSizingMetrics = function () {
        self.initialFilters.sizing_selections = [];
        self.filters.selections.sizing_selections = [];
      };

      self.loadSelections = function (initialFilters) {
        if (initialFilters.category_ids) {
          self.updateSizingMetricOptions();
        }
        if (initialFilters.min_height || initialFilters.max_height) {
          self.height = {
            min: Math.max(Math.abs(initialFilters.min_height) || MIN_DIMENSION, MIN_DIMENSION),
            max: Math.min(Math.abs(initialFilters.max_height) || MAX_DIMENSION, MAX_DIMENSION),
          };
          self.onHeightChange(self.height);
        }
        if (initialFilters.min_width || initialFilters.max_width) {
          self.width = {
            min: Math.max(Math.abs(initialFilters.min_width) || MIN_DIMENSION, MIN_DIMENSION),
            max: Math.min(Math.abs(initialFilters.max_width) || MAX_DIMENSION, MAX_DIMENSION),
          };
          self.onWidthChange(self.width);
        }
        if (initialFilters.min_length || initialFilters.max_length) {
          self.length = {
            min: Math.max(Math.abs(initialFilters.min_length) || MIN_DIMENSION, MIN_DIMENSION),
            max: Math.min(Math.abs(initialFilters.max_length) || MAX_DIMENSION, MAX_DIMENSION),
          };
          self.onLengthChange(self.length);
        }
        self.filterize();
        self.toggleCategorySelect();
      };

      self.onMultiselectChange = function (field, selectedOptions) {
        self.filters.selections[field] = selectedOptions;
        self.filterize();
      };

      self.filterize = function () {
        self.onFilterChange({ filters: self.filters });
      };

      self.toggleCategorySelect = function (filter_type) {
        if (filter_type) {
          self.filters.category_filter_type = filter_type;
        }
        const $itemCategoryEls = angular.element(document).find('.category-multiselect .multiSelect button[id]');
        if (self.filters.category_filter_type === 'custom_item') {
          $itemCategoryEls.removeAttr('disabled');
        } else {
          $itemCategoryEls.attr('disabled', '');
        }
        self.filterize();
      };

      self.onHeightChange = (range) => {
        self.height = range;
        self.filters.min_height = range.min;
        self.filters.max_height = range.max;
        self.filterize();
      };

      self.onWidthChange = (range) => {
        self.width = range;
        self.filters.min_width = range.min;
        self.filters.max_width = range.max;
        self.filterize();
      };

      self.onLengthChange = (range) => {
        self.length = range;
        self.filters.min_length = range.min;
        self.filters.max_length = range.max;
        self.filterize();
      };

      self.onHeightFilterTypeChange = (filterType) => {
        self.filters.height_filter_type = filterType;
        self.filters.min_height = self.height.min;
        self.filters.max_height = self.height.max;
        self.filterize();
      };

      self.onIncludeUndefinedHeightChanged = (includeUndefined) => {
        self.filters.include_undefined_height = includeUndefined;
        self.filterize();
      };

      self.onWidthFilterTypeChange = (filterType) => {
        self.filters.width_filter_type = filterType;
        self.filters.min_width = self.width.min;
        self.filters.max_width = self.width.max;
        self.filterize();
      };

      self.onIncludeUndefinedWidthChanged = (includeUndefined) => {
        self.filters.include_undefined_width = includeUndefined;
        self.filterize();
      };

      self.onLengthFilterTypeChange = (filterType) => {
        self.filters.length_filter_type = filterType;
        self.filters.min_length = self.length.min;
        self.filters.max_length = self.length.max;
        self.filterize();
      };

      self.onIncludeUndefinedLengthChanged = (includeUndefined) => {
        self.filters.include_undefined_length = includeUndefined;
        self.filterize();
      };

      self.search = function () {
        self.onSearchClick();
      };

      self.updateSizingMetricOptions = function () {
        self.sizingMetricOptions = [];
        const processedSizingMetricIds = [];

        self.filters.selections.category_ids.forEach((categoryId) => {
          const category = self.idToCategoryMapping[categoryId];
          const sizingMetric = category.sizing_metric;
          if (sizingMetric) {
            if (!processedSizingMetricIds.includes(sizingMetric.id)) {
              processedSizingMetricIds.push(sizingMetric.id);
              const options = sizingEnumerations(sizingMetric);
              const parent = { name: category.name, value: category.name, children: options };
              self.sizingMetricOptions = self.sizingMetricOptions.concat(parent);
            }
          }
        });
      };
    },
  ],
});

import React from 'react';
import { flatten, map, filter, lowerCase, uniqBy, sortBy } from 'lodash';
import { getOrderStartTime } from '../../../models/get_order_time_estimates';

export const getValidOrdersForDispatch = (dispatch) =>
  sortBy(
    filter(dispatch.orders, (order) => lowerCase(order.state) !== 'canceled'),
    getOrderStartTime,
  );

export const getDedupedValidOrdersForDispatch = (dispatch) => {
  const orders = getValidOrdersForDispatch(dispatch);
  const uniqOrders = uniqBy(orders, 'address.id');
  uniqOrders.forEach((o) => {
    o.sibling_orders = orders.filter(
      (relatedOrder) => relatedOrder.address.id === o.address.id && relatedOrder.id !== o.id,
    );
  });
  return uniqOrders;
};

export const getValidOrders = (dispatches) => flatten(map(dispatches, getValidOrdersForDispatch));

export const withProps = (staticProps) => (Component) => (props) => <Component {...staticProps} {...props} />;

export const isOrderHoveredOrSelected = (eventSystem, id) =>
  id === eventSystem.activeOrderId || id === eventSystem.hoveredOrderId;

export const isDispatchHoveredOrSelected = (eventSystem, id) =>
  id === eventSystem.activeDispatchId || id === eventSystem.hoveredDispatchId;

export const isCheckinHoveredOrSelected = (eventSystem, id) =>
  id === eventSystem.activeCheckinId || id === eventSystem.hoveredCheckinId;

export const isOrderHovered = (eventSystem, id) => id === eventSystem.hoveredOrderId;

export const isDispatchHovered = (eventSystem, id) => id === eventSystem.hoveredDispatchId;

export const isCheckinHovered = (eventSystem, id) => id === eventSystem.hoveredCheckinId;

export const isOrderSelected = (eventSystem, id) => id === eventSystem.activeOrderId;

export const isDispatchSelected = (eventSystem, id) => id === eventSystem.activeDispatchId;

export const isCheckinSelected = (eventSystem, id) => id === eventSystem.activeCheckinId;

import styled from '@emotion/styled';
import { range } from 'lodash';
import { capitalize } from 'lodash';
import * as React from 'react';

import { MultiselectFormControl, NumberFormControl, SelectFormControl } from '@admin/components/fields';

import {
  Maybe,
  SelfStorage__Unit__FiltersInput,
  SelfStorage__Unit__Filters__ClassificationInput,
  SelfStorage__Unit__Filters__KindInput,
  SelfStorage__Unit__Filters__RangeInput,
  SelfStorage__Unit__Status,
  useSelfStorageFacilitiesQuery,
} from '@admin/schema';
import { client } from '@admin/libraries/apollo';

const InputGroupList = styled.div`
  display: flex;
  margin: -4px;
`;

const InputGroupItem = styled.div`
  padding: 4px;
  flex-grow: 1;
  flex-shrink: 1;
`;

const InputGroupSeparator = styled.div`
  padding: 4px;
  flex-grow: 0;
  flex-shrink: 0;
  line-height 34px;
`;

enum SelfStorageKindName {
  Locker = 'Locker',
  Parking = 'Parking',
  SelfStorage = 'Self-Storage',
}

export enum UnitFilterOptions {
  Search,
  Facility,
  Price,
  Cuft,
  Dimensions,
  Floor,
  Climate,
  Obstructed,
  Indoors,
  Status,
  Kind,
}

const DEFAULT_FILTER_OPTIONS = {
  [UnitFilterOptions.Search]: 3,
  [UnitFilterOptions.Facility]: 2,
  [UnitFilterOptions.Price]: 2,
  [UnitFilterOptions.Cuft]: 2,
  [UnitFilterOptions.Dimensions]: 3,
  [UnitFilterOptions.Floor]: 2,
  [UnitFilterOptions.Climate]: 2,
  [UnitFilterOptions.Obstructed]: 2,
  [UnitFilterOptions.Indoors]: 2,
  [UnitFilterOptions.Status]: 2,
  [UnitFilterOptions.Kind]: 2,
};

export const SELF_STORAGE_KIND_NAMES = [
  SelfStorageKindName.Locker,
  SelfStorageKindName.Parking,
  SelfStorageKindName.SelfStorage,
];

const KIND_OPTIONS = SELF_STORAGE_KIND_NAMES.map((kind) => ({ id: kind, name: capitalize(kind) }));
const STATUS_OPTIONS = [
  SelfStorage__Unit__Status.Available,
  SelfStorage__Unit__Status.Unavailable,
  SelfStorage__Unit__Status.Rented,
  SelfStorage__Unit__Status.Held,
  SelfStorage__Unit__Status.Designated,
].map((status) => ({ id: status, name: capitalize(status) }));

const FLOOR_OPTIONS = range(13).map((floor) => ({ id: floor, name: String(floor) }));

interface IFormControlProps<T> {
  placeholder?: string;
  value?: Maybe<T>;
  onChange(value?: Maybe<T>): void;
}

const convertSTB = (truthy: string, falsey: string, value?: string): Maybe<boolean> | undefined => {
  switch (value) {
    case truthy:
      return true;
    case falsey:
      return false;
    default:
      return undefined;
  }
};

const convertBTS = (truthy: string, falsey: string, value?: Maybe<boolean>): string | undefined => {
  switch (value) {
    case true:
      return truthy;
    case false:
      return falsey;
    default:
      return undefined;
  }
};

const LOCATION_OPTIONS = ['indoors', 'outdoors'];

const LocationFormControl: React.FC<IFormControlProps<boolean>> = ({ value, onChange }) => (
  <SelectFormControl
    options={LOCATION_OPTIONS}
    name={capitalize}
    value={convertBTS('indoors', 'outdoors', value)}
    onChange={(option) => onChange(convertSTB('indoors', 'outdoors', option))}
    prompt=" - All -"
  />
);

const BOOLEAN_OPTIONS = ['yes', 'no'];

const BooleanFormControl: React.FC<IFormControlProps<boolean>> = ({ value, onChange }) => (
  <SelectFormControl
    options={BOOLEAN_OPTIONS}
    name={capitalize}
    value={convertBTS('yes', 'no', value)}
    onChange={(option) => onChange(convertSTB('yes', 'no', option))}
    prompt=" - All -"
  />
);

const BaseFormGroup: React.FC<{
  name: string;
}> = ({ name, children }) => (
  <div className="form-group">
    <label className="control-label">{name}:</label>
    {children}
  </div>
);

const SearchFormGroup: React.FC<{
  search?: Maybe<string>;
  onSearch(search?: Maybe<string>): void;
}> = ({ search, onSearch }) => (
  <BaseFormGroup name="Search">
    <input
      className="form-control"
      type="search"
      placeholder="Search"
      value={search || ''}
      onChange={(event) => onSearch(event.target.value || undefined)}
    />
  </BaseFormGroup>
);

export const FloorFormGroup: React.FC<{
  floors?: Maybe<number[]>;
  onChange(floors: Maybe<number[]>): void;
}> = ({ floors, onChange }) => (
  <BaseFormGroup name="Floor">
    <MultiselectFormControl
      placeholder=" - Floor - "
      options={FLOOR_OPTIONS}
      selectedIDs={floors || []}
      onChange={onChange}
    />
  </BaseFormGroup>
);

export const ClimateFormGroup: React.FC<{
  climate?: Maybe<boolean>;
  onChange(climate?: Maybe<boolean>): void;
}> = ({ climate, onChange }) => (
  <BaseFormGroup name="Climate Control">
    <BooleanFormControl value={climate} onChange={onChange} />
  </BaseFormGroup>
);

export const IndoorsFormGroup: React.FC<{
  indoors?: Maybe<boolean>;
  onChange(indoors?: Maybe<boolean>): void;
}> = ({ indoors, onChange }) => (
  <BaseFormGroup name="Location">
    <LocationFormControl value={indoors} onChange={onChange} />
  </BaseFormGroup>
);

export const ObstructedFormGroup: React.FC<{
  obstructed?: Maybe<boolean>;
  onChange(obstructed?: Maybe<boolean>): void;
}> = ({ obstructed, onChange }) => (
  <BaseFormGroup name="Obstructed">
    <BooleanFormControl value={obstructed} onChange={onChange} />
  </BaseFormGroup>
);

const StatusFormGroup: React.FC<{
  status?: Maybe<SelfStorage__Unit__Status[]>;
  onChange(status: Maybe<SelfStorage__Unit__Status[]>): void;
}> = ({ status, onChange }) => (
  <BaseFormGroup name="Status">
    <MultiselectFormControl
      placeholder=" - Status - "
      options={STATUS_OPTIONS}
      selectedIDs={status || []}
      onChange={onChange}
    />
  </BaseFormGroup>
);

export const KindFormGroup: React.FC<{
  kind?: Maybe<SelfStorage__Unit__Filters__KindInput>;
  onChange(kind: Maybe<SelfStorage__Unit__Filters__KindInput>): void;
}> = ({ kind, onChange }) => (
  <BaseFormGroup name="Unit Type">
    <MultiselectFormControl
      placeholder=" - Unit Type - "
      options={KIND_OPTIONS}
      selectedIDs={kind?.name ?? []}
      onChange={(name: SelfStorageKindName[]) => onChange({ name })}
    />
  </BaseFormGroup>
);

const FacilityFormGroup: React.FC<{
  facilityIDs?: Maybe<string[]>;
  onChange(facilityID: Maybe<string[]>): void;
}> = ({ facilityIDs, onChange }) => {
  const { data } = useSelfStorageFacilitiesQuery({ client });
  const facilities = data?.facilities ?? [];

  return (
    <BaseFormGroup name="Facility">
      <MultiselectFormControl
        placeholder=" - Facility - "
        options={facilities}
        selectedIDs={facilityIDs || []}
        onChange={onChange}
      />
    </BaseFormGroup>
  );
};

export const PriceFormGroup: React.FC<{
  price?: Maybe<SelfStorage__Unit__Filters__RangeInput>;
  onChange(price: Maybe<SelfStorage__Unit__Filters__RangeInput>): void;
}> = ({ price, onChange }) => (
  <BaseFormGroup name="Price">
    <InputGroupList>
      <InputGroupItem>
        <NumberFormControl
          value={price?.min ?? undefined}
          placeholder="Min"
          onChange={(min) => onChange({ ...price, min })}
        />
      </InputGroupItem>
      <InputGroupSeparator>-</InputGroupSeparator>
      <InputGroupItem>
        <NumberFormControl
          value={price?.max ?? undefined}
          placeholder="Max"
          onChange={(max) => onChange({ ...price, max })}
        />
      </InputGroupItem>
    </InputGroupList>
  </BaseFormGroup>
);

export const CUFTFormGroup: React.FC<{
  cuft?: Maybe<SelfStorage__Unit__Filters__RangeInput>;
  onChange(cuft: SelfStorage__Unit__Filters__RangeInput): void;
}> = ({ cuft, onChange }) => (
  <BaseFormGroup name="CUFT">
    <InputGroupList>
      <InputGroupItem>
        <NumberFormControl
          placeholder="Min"
          value={cuft?.min || undefined}
          onChange={(min) => onChange({ ...cuft, min })}
        />
      </InputGroupItem>
      <InputGroupSeparator>-</InputGroupSeparator>
      <InputGroupItem>
        <NumberFormControl
          placeholder="Max"
          value={cuft?.max || undefined}
          onChange={(max) => onChange({ ...cuft, max })}
        />
      </InputGroupItem>
    </InputGroupList>
  </BaseFormGroup>
);

export const DimensionsFormGroup: React.FC<{
  classification?: Maybe<SelfStorage__Unit__Filters__ClassificationInput>;
  onChange(classification: SelfStorage__Unit__Filters__ClassificationInput): void;
}> = ({ classification, onChange }) => (
  <BaseFormGroup name="Dimensions">
    <InputGroupList>
      <InputGroupItem>
        <NumberFormControl
          placeholder="W (feet)"
          value={classification?.width ?? undefined}
          onChange={(width) => onChange({ ...classification, width })}
        />
      </InputGroupItem>
      <InputGroupSeparator>×</InputGroupSeparator>
      <InputGroupItem>
        <NumberFormControl
          placeholder="L (feet)"
          value={classification?.length ?? undefined}
          onChange={(length) => onChange({ ...classification, length })}
        />
      </InputGroupItem>
      <InputGroupSeparator>×</InputGroupSeparator>
      <InputGroupItem>
        <NumberFormControl
          placeholder="H (feet)"
          value={classification?.height ?? undefined}
          onChange={(height) => onChange({ ...classification, height })}
        />
      </InputGroupItem>
    </InputGroupList>
  </BaseFormGroup>
);

export const Filters: React.FC<{
  filters: SelfStorage__Unit__FiltersInput;
  filterOptions?: { [key in UnitFilterOptions]?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 };
  onFilter(filters: SelfStorage__Unit__FiltersInput): void;
}> = ({ filters, filterOptions = DEFAULT_FILTER_OPTIONS, onFilter }) => (
  <>
    <div className="row">
      {filterOptions[UnitFilterOptions.Search] && (
        <div className={`col-md-${filterOptions[UnitFilterOptions.Search]}`}>
          <SearchFormGroup search={filters.search} onSearch={(search) => onFilter({ ...filters, search })} />
        </div>
      )}
      {filterOptions[UnitFilterOptions.Facility] && (
        <div className={`col-md-${filterOptions[UnitFilterOptions.Facility]}`}>
          <FacilityFormGroup
            facilityIDs={filters.facilityIDs}
            onChange={(facilityIDs) => onFilter({ ...filters, facilityIDs })}
          />
        </div>
      )}
      {filterOptions[UnitFilterOptions.Price] && (
        <div className={`col-md-${filterOptions[UnitFilterOptions.Price]}`}>
          <PriceFormGroup price={filters.price} onChange={(price) => onFilter({ ...filters, price })} />
        </div>
      )}
      {filterOptions[UnitFilterOptions.Cuft] && (
        <div className={`col-md-${filterOptions[UnitFilterOptions.Cuft]}`}>
          <CUFTFormGroup
            cuft={filters.classification?.cuft}
            onChange={(cuft) => onFilter({ ...filters, classification: { ...filters.classification, cuft } })}
          />
        </div>
      )}
      {filterOptions[UnitFilterOptions.Dimensions] && (
        <div className={`col-md-${filterOptions[UnitFilterOptions.Dimensions]}`}>
          <DimensionsFormGroup
            classification={filters.classification}
            onChange={(classification) => onFilter({ ...filters, classification })}
          />
        </div>
      )}
    </div>
    <div className="row">
      {filterOptions[UnitFilterOptions.Floor] && (
        <div className={`col-md-${filterOptions[UnitFilterOptions.Floor]}`}>
          <FloorFormGroup floors={filters.floors} onChange={(floors) => onFilter({ ...filters, floors })} />
        </div>
      )}
      {filterOptions[UnitFilterOptions.Climate] && (
        <div className={`col-md-${filterOptions[UnitFilterOptions.Climate]}`}>
          <ClimateFormGroup
            climate={filters.classification?.climate}
            onChange={(climate) => onFilter({ ...filters, classification: { ...filters.classification, climate } })}
          />
        </div>
      )}
      {filterOptions[UnitFilterOptions.Obstructed] && (
        <div className={`col-md-${filterOptions[UnitFilterOptions.Obstructed]}`}>
          <ObstructedFormGroup
            obstructed={filters.classification?.obstructed}
            onChange={(obstructed) =>
              onFilter({ ...filters, classification: { ...filters.classification, obstructed } })
            }
          />
        </div>
      )}
      {filterOptions[UnitFilterOptions.Indoors] && (
        <div className={`col-md-${filterOptions[UnitFilterOptions.Indoors]}`}>
          <IndoorsFormGroup
            indoors={filters.classification?.indoors}
            onChange={(indoors) => onFilter({ ...filters, classification: { ...filters.classification, indoors } })}
          />
        </div>
      )}
      {filterOptions[UnitFilterOptions.Status] && (
        <div className={`col-md-${filterOptions[UnitFilterOptions.Status]}`}>
          <StatusFormGroup status={filters.status} onChange={(status) => onFilter({ ...filters, status })} />
        </div>
      )}
      {filterOptions[UnitFilterOptions.Kind] && (
        <div className={`col-md-${filterOptions[UnitFilterOptions.Kind]}`}>
          <KindFormGroup
            kind={filters.classification?.kind}
            onChange={(kind) => onFilter({ ...filters, classification: { ...filters.classification, kind } })}
          />
        </div>
      )}
    </div>
  </>
);

import * as React from 'react';

import { LabelledAttribute } from '@admin/components/helpers/labelled_attribute';
import { Panel } from '@admin/components/helpers/panel';
import { ROLES } from '@admin/config/roles';
import { UserRole } from '@admin/types';
import { DetailedClaimFragment, Claim__Status } from '@admin/schema';
import { Timestamp } from '@shared/components/helpers';

import { CloseClaimButton } from './details/close_claim_button';
import { Owner } from './details/owner';
import { ReopenClaimButton } from './details/reopen_claim_button';

const timestamp = (time?: string) => (time ? <Timestamp value={time} format={Timestamp.Format.Date} /> : 'N/A');

const CAN_REOPEN_STATES = [Claim__Status.Canceled, Claim__Status.Denied];

export const Attributes: React.FC<{
  editable: boolean;
  claim: DetailedClaimFragment;
}> = ({ editable, claim }) => {
  const {
    id,
    number: claimNumber,
    account,
    openedAt,
    offeredAt,
    closedAt,
    owner,
    settlementAgreement,
    expiredReason,
    status,
  } = claim;

  const shownStatus = status === Claim__Status.InReview ? 'in review' : status.toLowerCase();
  const displayableStatus = expiredReason ? `${shownStatus} - (${expiredReason})` : shownStatus;

  const canReopen = CAN_REOPEN_STATES.includes(claim.status) && ROLES.includes(UserRole.Claims);

  return (
    <Panel>
      <Panel.Header>
        <Panel.Title>{claimNumber}</Panel.Title>
      </Panel.Header>
      <Panel.Body>
        <a href={`/accounts/${account.id}`}>
          <LabelledAttribute label="Account" attribute={account.customer.name} />
        </a>
        <LabelledAttribute label="Status" attribute={displayableStatus} />
        <LabelledAttribute label="Opened" attribute={timestamp(openedAt ?? undefined)} />
        <LabelledAttribute label="Offered" attribute={timestamp(offeredAt ?? undefined)} />
        <LabelledAttribute label="Closed" attribute={timestamp(closedAt ?? undefined)} />
        <LabelledAttribute label="Owner" attribute={<Owner claimID={id} editable={editable} owner={owner} />} />
        {account.latestProtectionSubscription && (
          <LabelledAttribute
            label="Protection Plan"
            attribute={
              <span>
                {account.latestProtectionSubscription.quantity} × {account.latestProtectionSubscription.name}
              </span>
            }
          />
        )}
        {settlementAgreement && (
          <div className="mar-top">
            <a href={settlementAgreement.imgixURL} target="_blank" style={{ textDecoration: 'underline' }}>
              View Settlement Agreement
            </a>
          </div>
        )}
      </Panel.Body>
      <Panel.Footer>
        {canReopen && <ReopenClaimButton claimID={id} />}
        {editable && <CloseClaimButton claimID={id} />}
      </Panel.Footer>
    </Panel>
  );
};

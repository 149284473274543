import * as React from 'react';
import { useState } from 'react';
import { isEqual } from 'lodash';

import { palletTypesURL } from '@admin/config/routes';
import {
  ModifyPalletTypeMutation,
  PalletTypeInput,
  useModifyPalletTypeMutation,
  usePalletTypeQuery,
} from '@admin/schema';
import { client } from '@admin/libraries/apollo';
import { PalletTypeForm } from './pallet_type_form';

export const EditPalletType: React.FC<{ id: string }> = ({ id }) => {
  const { data } = usePalletTypeQuery({ client, variables: { id } });
  const [palletType, setPalletType] = useState<PalletTypeInput | undefined>(undefined);
  const [savedPalletType, setSavedPalletType] = useState<PalletTypeInput | undefined>(undefined);
  const [results, setResults] = useState<ModifyPalletTypeMutation | undefined | null>(undefined);
  const [execute, { loading: loadingMutation }] = useModifyPalletTypeMutation({ client });

  if (!data || !data.palletType) {
    return null;
  }

  const onSave = async (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    if (loadingMutation || !palletType) {
      return;
    }
    const input = palletType;
    const variables = { input, id };
    const response = await execute({ variables });
    if (!response) {
      return;
    }
    setResults(response.data);
    if (response && response.data && response.data.modifyPalletType.error === null) {
      window.location.href = palletTypesURL();
    }
  };

  if (data && data.palletType && palletType === undefined) {
    setSavedPalletType({
      name: data.palletType.name,
      capacity: data.palletType.capacity || undefined,
      active: data.palletType.active,
      legacySize: data.palletType.legacySize,
      itemCategoryIDs: data.palletType.itemCategories!.map((itemCategory) => itemCategory.id),
      materialCategoryIDs: data.palletType.materialCategories!.map((materialCategory) => materialCategory.id),
      partCategoryIDs: data.palletType.partCategories!.map((partCategory) => partCategory.id),
      description: data.palletType.description,
      position: data.palletType.position,
    });
    setPalletType(savedPalletType);
  }

  return (
    <div className="panel">
      <div className="panel-heading">
        <h3 className="panel-title">Edit Pallet Type</h3>
      </div>
      {results && results.modifyPalletType.error && (
        <div className="alert alert-danger">{results.modifyPalletType.error}</div>
      )}
      {palletType && (
        <form
          onSubmit={async (event) => {
            event.preventDefault();
            event.stopPropagation();
          }}
        >
          <div className="panel-body">
            <PalletTypeForm palletType={palletType} onChange={setPalletType} edit={true} />
          </div>
          <div className="panel-footer text-right">
            <button
              type="button"
              className="btn btn-primary"
              disabled={loadingMutation || isEqual(savedPalletType, palletType)}
              onClick={onSave}
            >
              Save
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

import { DateTime, Interval } from 'luxon';

const getIntervalInMinutes = (started: string | DateTime, ended: string | DateTime) =>
  Interval.fromDateTimes(
    started instanceof DateTime ? started : DateTime.fromISO(started),
    ended instanceof DateTime ? ended : DateTime.fromISO(ended),
  ).length('minutes');

export const calculateDuration = (
  started: string | DateTime,
  ended: string | DateTime,
  workBreaks: Array<{ started?: string | DateTime | null; ended?: string | DateTime | null }>,
) => {
  let duration = 0;
  if (started && ended) {
    const shiftLength = getIntervalInMinutes(started, ended);
    const breakLength =
      workBreaks
        .filter((wb) => wb.started && wb.ended)
        .reduce((minutes, wb) => minutes + getIntervalInMinutes(wb.started as string, wb.ended as string), 0) || 0;
    duration = shiftLength > breakLength ? shiftLength - breakLength : 0;
  }
  return Math.ceil(duration);
};

import * as React from 'react';

import axios from 'axios';

import { Alert } from '@shared/components/bootstrap';

interface IFileUploaderState {
  disabled: boolean;
  file?: File;
  error?: string;
  success: boolean;
}

export class FileUploader extends React.Component<{}, IFileUploaderState> {
  constructor(props: any) {
    super(props);
    this.state = {
      disabled: true,
      success: false,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  public render() {
    return (
      <div>
        {this.state.success && <Alert style="info">Notes Uploaded successfully.</Alert>}
        {this.state.error && <Alert style="danger">{this.state.error}</Alert>}
        <input type="file" onChange={(e) => this.handleChange(e.currentTarget.files!)} />
        <button
          className={`btn btn-primary pull-right`}
          disabled={this.state.disabled}
          onClick={() => this.handleClick()}
        >
          Upload
        </button>
      </div>
    );
  }

  private handleChange(selectorFiles: FileList) {
    this.setState({
      disabled: false,
      file: selectorFiles[0],
      success: false,
      error: undefined,
    });
  }

  private handleClick() {
    const url = '/notes_uploader.json';
    const formData = new FormData();
    formData.append('file', this.state.file!);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    axios
      .post(url, formData, config)
      .then(() => {
        this.setState({ success: true });
      })
      .catch((error) => {
        this.setState({ error: error.response.data.message });
      });
  }
}

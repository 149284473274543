import * as React from 'react';

import { ErrorIcon, Status } from '@admin/components/helpers/error_icon';
import { TooltipPlacement } from '@shared/components/bootstrap/tooltip';
import { client } from '@admin/libraries/apollo';

import { useDispatcherDispatchQuery } from '@admin/schema';

const LOADING = <ErrorIcon icon="history" flip="horizontal" status={Status.Off} placement={TooltipPlacement.Bottom} />;

export const SimulationErrorIcon: React.FC<{ dispatchID: string }> = ({ dispatchID }) => {
  const { data, loading } = useDispatcherDispatchQuery({
    client,
    variables: { dispatchID },
    fetchPolicy: 'no-cache',
  });
  if (!data || loading || !data.dispatch.simulation) {
    return LOADING;
  }

  const {
    dispatch: { simulation },
  } = data;

  const errorMessages = simulation.errors.map((error) => error.message);
  const iconStatus =
    simulation.errors.length === 0
      ? Status.Good
      : simulation.errors.some((error) => error.severe)
      ? Status.Bad
      : Status.Fair;

  return (
    <ErrorIcon
      errors={errorMessages}
      icon="history"
      flip="horizontal"
      status={iconStatus}
      placement={TooltipPlacement.Bottom}
    />
  );
};

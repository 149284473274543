import { NoDetailAddressInput } from '@admin/schema';
import { IAddress } from '@shared/types';
import * as React from 'react';
import { useState } from 'react';

import { AptSuiteFormGroup } from './apt_suite_form_group';
import { AutocompleteFormGroup } from './autocomplete_form_group';
import { CityFormGroup } from './city_form_group';
import { CountryFormGroup } from './country_form_group';
import { LatitudeFormGroup } from './latitude_form_group';
import { LongitudeFormGroup } from './longitude_form_group';
import { StateFormGroup } from './state_form_group';
import { StreetFormGroup } from './street_form_group';
import { ZipFormGroup } from './zip_form_group';

const AUTOCOMPLETE_LABEL = 'Search for your street address and select it from the list';

export const AddressForm: React.FC<{
  address: IAddress | NoDetailAddressInput;
  disabled?: boolean;
  onChange(address: NoDetailAddressInput): void;
}> = ({ onChange, address, disabled }) => {
  const [query, setQuery] = useState<string | undefined>();
  const { aptsuite, street, city, state, country, zip, latitude, longitude } = address;

  return (
    <>
      <AutocompleteFormGroup
        label={AUTOCOMPLETE_LABEL}
        disabled={disabled || !!address.street}
        query={query}
        onQuery={setQuery}
        onChange={(place) => {
          setQuery(undefined);
          onChange({
            ...address,
            ...place,
          });
        }}
      />

      <StreetFormGroup
        disabled={disabled}
        street={street ?? ''}
        onChange={(value) => onChange({ ...address, street: value })}
      />
      <AptSuiteFormGroup
        disabled={disabled}
        value={aptsuite ?? ''}
        onChange={(event) => onChange({ ...address, aptsuite: event.target.value })}
      />
      <CityFormGroup
        disabled={disabled}
        value={city ?? ''}
        onChange={(event) => onChange({ ...address, city: event.target.value })}
      />
      <StateFormGroup
        disabled={disabled}
        value={state ?? ''}
        onChange={(event) => onChange({ ...address, state: event.target.value })}
      />
      <CountryFormGroup
        disabled={disabled}
        value={country ?? ''}
        onChange={(event) => onChange({ ...address, country: event.target.value })}
      />
      <ZipFormGroup
        disabled={disabled}
        value={zip ?? ''}
        onChange={(event) => onChange({ ...address, zip: event.target.value })}
      />

      <div className="row">
        <div className="col-sm-6">
          <LatitudeFormGroup
            disabled={disabled}
            latitude={latitude ?? undefined}
            onChange={(value) => onChange({ ...address, latitude: value })}
          />
        </div>
        <div className="col-sm-6">
          <LongitudeFormGroup
            disabled={disabled}
            longitude={longitude ?? undefined}
            onChange={(value) => onChange({ ...address, longitude: value })}
          />
        </div>
      </div>
    </>
  );
};

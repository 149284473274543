/* eslint no-param-reassign: ["error", { "props": true,
  "ignorePropertyModificationsFor": ["$scope"] }] */

import angular from 'angular';

// TODO: remove IIFE
// We should make this change in a separate PR because the diff will be hard to read.
(function () {
  const app = angular.module('app');

  app.controller('CheckoutsNewController', [
    '$scope',
    '$state',
    '$stateParams',
    'ErrorService',
    '$rootScope',
    'Lead',
    'LeadSavingService',
    'ScrollService',
    'LeadObjectionService',
    'InputValidationService',
    'PostalCodeClassifier',
    'FollowupService',
    function (
      $scope,
      $state,
      $stateParams,
      ErrorService,
      $rootScope,
      Lead,
      LeadSavingService,
      ScrollService,
      LeadObjectionService,
      InputValidationService,
      PostalCodeClassifier,
      FollowupService,
    ) {
      /*
        Refactoring thoughts:
          - Rename nv and ov to newValue and oldValue to make it more clear
          - It's easier to test.
          - It's more performant
            - ($watch adds one more thing that the $digest cycle has to check for)
          - Discussion here:
            http://stackoverflow.com/questions/21442183/is-it-an-antipattern-to-use-angulars-watch-in-a-controller
          */
      const saveButtonText = 'Save';
      const saveAndRescheduleButtonText = 'Save & Reschedule';
      const validationSuccessIndicators = {};

      $scope.lead = new Lead();
      $scope.lead.id = $stateParams.id;
      $scope.lead.source = $stateParams.source;
      $scope.errors = {};
      $scope.followupSelections = {};
      $scope.followupErrors = {};
      $scope.existingLeadHasPhone = false;

      $scope.updateFollowupSelections = (updates) => {
        $scope.followupSelections = FollowupService.updateSelections($scope.followupSelections, updates);
        $scope.followupErrors = FollowupService.clearErrors($scope.followupErrors, updates);
      };

      // showFullForm - determine whether the checkout form is visible or just the lead form
      // withOrderForm - this is an option on the Lead Directive that will include the
      //                 Start Order button
      //  presumably the Lead Directive could be used elsewhere without the order form
      $scope.formFlags = {
        showFullForm: false,
        withOrderForm: true,
        leadButtonText: saveButtonText,
      };

      $scope.afterLeadSave = (lead) => {
        $state.go('editLead', { id: lead.id }, { reload: true });
      };

      const allValidationsSuccessful = () =>
        Object.keys(validationSuccessIndicators).every((key) => validationSuccessIndicators[key]);

      const broadcastButtonEnable = (enableAction, buttonId) => {
        $rootScope.$broadcast(`checkout_action:${enableAction}`, buttonId);
      };

      const phoneOrEmailAdded = () => $scope.lead.email || $scope.lead.phone;

      const enableSaveLeadButton = () => {
        broadcastButtonEnable('enable', 'save-lead');
      };

      const disableSaveLeadButton = () => {
        broadcastButtonEnable('disable', 'save-lead');
      };

      const enableOtherButtons = function () {
        if (allValidationsSuccessful() && phoneOrEmailAdded()) {
          enableSaveLeadButton();
        }
      };

      $scope.afterLeadSaveError = function (error) {
        ErrorService.handle(error);
        enableOtherButtons();
        $scope.lead.phone_call_sid = null;
      };

      const isLegacyLeadWithInvalidPhone = (invalidFields) =>
        $scope.existingLeadHasPhone && !invalidFields.includes('email');

      const validateBeforeSubmit = (validCallback) => {
        if (LeadObjectionService.isFollowupScheduledObjection($scope.lead.objection_reason)) {
          $scope.followupErrors = FollowupService.addErrors($scope.followupSelections);

          if (FollowupService.hasError($scope.followupErrors)) {
            ScrollService.scrollTo($(FollowupService.constants.FOLLOWUP_FIELDS_SELECTOR));
            return;
          }
        }

        const validationParams = {
          email: $scope.lead.email || null,
          phone_number: $scope.lead.phone || null,
        };
        InputValidationService.validate(validationParams).then((response) => {
          const results = response.data;
          if (results.valid) {
            validCallback();
          } else if (results.invalid_fields && results.invalid_fields.length > 0) {
            if (isLegacyLeadWithInvalidPhone(results.invalid_fields)) {
              validCallback();
            } else {
              ScrollService.scrollTo(`validatable-${_.kebabCase(response.data.invalid_fields[0])}-input`);
            }
          }
        });
      };

      $scope.validateBeforeSavingLead = () => {
        validateBeforeSubmit(() => {
          $scope.saveLead();
        });
      };

      $scope.saveLead = (successCallback = $scope.afterLeadSave) => {
        LeadSavingService.save($scope.lead, $scope.followupSelections, successCallback, $scope.afterLeadSaveError);
      };

      $scope.afterLeadLoad = async function (lead) {
        if (lead === undefined || lead.id === undefined) {
          return;
        }
        $scope.existingLeadHasPhone = !!lead.phone;
        $scope.lead = lead;

        if (LeadObjectionService.isFollowupScheduledObjection($scope.lead.objection_reason)) {
          $scope.formFlags.leadButtonText = saveAndRescheduleButtonText;
        } else {
          $scope.formFlags.leadButtonText = saveButtonText;
        }
      };

      $scope.$watch('lead.objection_reason', (nv) => {
        if (LeadObjectionService.isFollowupScheduledObjection(nv)) {
          $scope.formFlags.leadButtonText = saveAndRescheduleButtonText;
        } else {
          $scope.formFlags.leadButtonText = saveButtonText;
        }

        if (!_.isUndefined($scope.lead.state)) {
          $rootScope.$broadcast('lead:statusChanged', {
            objection_reason: nv,
            followup_scheduled_at: $scope.lead.followup_scheduled_at,
            state: $scope.lead.state,
          });
        }
      });

      $scope.getClassifier = (zip) => {
        $scope.serviceAreaClassifier = zip ? PostalCodeClassifier.get({ label: zip }) : null;
      };

      this.$onInit = ScrollService.scroll;

      const updateValidationSuccessIndicators = (data) => {
        Object.assign(validationSuccessIndicators, data);
      };

      $scope.$on('validation:failed', (_, data) => {
        updateValidationSuccessIndicators(data);
        disableSaveLeadButton();
      });

      $scope.$on('validation:successful', (_, data) => {
        updateValidationSuccessIndicators(data);
        enableOtherButtons();
      });

      $scope.$on('acceptIncomingLeadCall', (_, data) => {
        const callback = () => {
          if (data.lead_id) {
            $state.go('editLead', { id: data.lead_id });
          }
        };

        LeadSavingService.save($scope.lead, $scope.followupSelections, callback, callback);
      });
    },
  ]);
})();

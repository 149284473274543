import * as React from 'react';
import { useState } from 'react';

import { Panel } from '@admin/components/helpers/panel';
import { Pagination } from '@admin/components/pagination';
import { Spinner } from '@admin/components/spinner';

import { MultiSelector as PartnersSelector } from '@admin/components/self_storage/partners/multi_selector';
import { Selector as RegionsSelector } from '@admin/components/regions/selector';

import { usePaginatedSelfStorageFacilitiesQuery } from '@admin/schema';
import { client } from '@admin/libraries/apollo';
import { Table } from './table';

enum Status {
  Active = 'active',
  Inactive = 'inactive',
}

export const List: React.FC = () => {
  const [status, setStatus] = useState<Status>();
  const [search, setSearch] = useState<string | undefined>();
  const [partnerIDs, setPartnerIDs] = useState<string[]>([]);
  const [regionIDs, setRegionIDs] = useState<string[]>([]);
  const [page, setPage] = useState<number | undefined>();

  const { data, loading } = usePaginatedSelfStorageFacilitiesQuery({
    client,
    variables: {
      page,
      filters: {
        search,
        status,
        partnerIDs,
        regionIDs,
      },
    },
  });
  const paginated = data && data.paginated;

  return (
    <>
      <Panel>
        <Panel.Body>
          <div className="row">
            <div className="col-md-3">
              <div className="form-group">
                <label className="control-label" htmlFor="search">
                  Search:
                </label>
                <input
                  id="search"
                  className="form-control"
                  type="search"
                  placeholder="Search"
                  value={search || ''}
                  onChange={(event) => {
                    setSearch(event.target.value || undefined);
                    setPage(undefined);
                  }}
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label className="control-label">Partner:</label>
                <PartnersSelector
                  selectedIDs={partnerIDs}
                  onChange={(ids) => {
                    setPartnerIDs(ids);
                    setPage(undefined);
                  }}
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label className="control-label">Region:</label>
                <RegionsSelector
                  selectedIDs={regionIDs}
                  onChange={(ids) => {
                    setRegionIDs(ids);
                    setPage(undefined);
                  }}
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label className="control-label" htmlFor="state">
                  Status:
                </label>
                <select
                  id="state"
                  className="form-control"
                  value={status || ''}
                  onChange={(event) => {
                    setStatus((event.target.value || undefined) as Status);
                    setPage(undefined);
                  }}
                >
                  <option value=""> - All - </option>
                  <option value={Status.Active}>Active</option>
                  <option value={Status.Inactive}>Inactive</option>
                </select>
              </div>
            </div>
          </div>
        </Panel.Body>
      </Panel>
      <Panel>
        <Panel.Body>
          {loading && <Spinner />}
          {!loading && paginated && (
            <>
              <Table facilities={paginated.results} />
              <Pagination pagination={paginated.pagination} onPage={setPage} />
            </>
          )}
        </Panel.Body>
      </Panel>
    </>
  );
};

import { Titleize } from '@admin/components/helpers/titleize';

import { RateAdjustment__Draft, RateAdjustment__Upload__Product } from '@admin/schema';

import { Table } from '@shared/components/bootstrap';
import { Timestamp } from '@shared/components/helpers';
import React from 'react';

export const Drafts: React.FC<{
  drafts: RateAdjustment__Draft[];
  product: RateAdjustment__Upload__Product;
}> = ({ drafts, product }) => (
  <Table>
    <thead>
      <tr>
        {product === RateAdjustment__Upload__Product.SelfStorage && <th>Rental ID</th>}
        <th>Account ID</th>
        <th>New Price</th>
        <th>Effective Month/Year</th>
        <th>Exclude From Emails</th>
        <th>Best Offer</th>
        <th>Label</th>
      </tr>
    </thead>
    <tbody>
      {drafts.map((draft, index) => (
        <tr key={index}>
          {product === RateAdjustment__Upload__Product.SelfStorage && <td>{draft.rentalID}</td>}
          <td>{draft.accountID}</td>
          <td>{draft.newPrice}</td>
          <td>
            <Timestamp
              value={draft.effectiveMonthYear}
              format={Timestamp.Format.Custom}
              options={{
                month: 'short',
                year: 'numeric',
                timeZone: 'UTC',
              }}
            />
          </td>
          <td>
            <Titleize text={String(draft.excludeFromEmails)} />
          </td>
          <td>{draft.bestOffer}</td>
          <td>{draft?.label}</td>
        </tr>
      ))}
    </tbody>
  </Table>
);

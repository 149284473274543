import React, { useState } from 'react';

import { Button, ModalSize } from '@shared/components/bootstrap';

import { Order, Status, useOrderCancelMutation, useOrderCurrentCancellationFeeQuery } from '@admin/schema';

import {
  Dialog as ConfirmationDialog,
  Response as ConfirmationResponse,
  useConfirmation,
} from '@admin/components/confirmation';
import { ConfirmationModal } from '@admin/components/orders/actions/cancel/confirmation_modal';
import { client } from '@admin/libraries/apollo';

const CANCELATION_FEE_TITLE = 'Do you want to charge the cancellation fee?';

export const Cancel: React.FC<{
  order: Pick<Order, 'id' | 'simultaneous' | 'type' | 'subtype' | 'itemsCount'>;
}> = ({ order }) => {
  const { confirmation: cancelFeeConfirmation, confirm } = useConfirmation();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [reasonID, setReasonID] = useState<string | undefined>();

  const { data, loading } = useOrderCurrentCancellationFeeQuery({
    client,
    variables: { orderID: order.id },
    skip: !showModal,
  });
  const [cancelOrder, { loading: canceling }] = useOrderCancelMutation({ client });

  const onConfirm = async () => {
    let chargeFee = false;
    if (data?.fee) {
      const feeConfirmation = await confirm({
        title: CANCELATION_FEE_TITLE,
        description: data.fee,
        size: ModalSize.Large,
      });
      if (feeConfirmation === ConfirmationResponse.Close) {
        return;
      }
      chargeFee = feeConfirmation === ConfirmationResponse.Confirm;
    }
    const response = await cancelOrder({
      variables: {
        input: { orderID: order.id, chargeCancelationFee: chargeFee, cancelReasonID: reasonID },
      },
    });
    if (response.data?.orderCancel?.status === Status.Unprocessable) {
      await confirm({
        title: 'There was an error canceling this order',
        description: response.data.orderCancel.error || '',
        showConfirm: false,
        cancel: 'OK',
        size: ModalSize.Large,
      });
    }
  };

  if (loading) {
    return null;
  }

  return (
    <>
      <Button kind="danger" onClick={() => setShowModal(true)} disabled={canceling}>
        Cancel
      </Button>
      {showModal && (
        <ConfirmationModal
          order={order}
          reasonID={reasonID}
          setReasonID={setReasonID}
          onClose={() => setShowModal(false)}
          onConfirm={onConfirm}
        />
      )}
      {cancelFeeConfirmation && <ConfirmationDialog confirmation={cancelFeeConfirmation} />}
    </>
  );
};

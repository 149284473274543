import React from 'react';

import { Button, Modal } from '@shared/components/bootstrap';
import { Pluralize, Spacer } from '@shared/components/helpers';

import { PaginatedSelfStorageUnitsDocument, useDestroySelfStoragePromotionBatchMutation } from '@admin/schema';
import { client } from '@admin/libraries/apollo';
import { Footer, ModalWrapper, Title } from './add_promotion_modal';

export const RemovePromotionModal: React.FC<{
  count?: number;
  IDs: string[];
  show: boolean;
  /** onClose is called when the modal is hidden or the cancel button is clicked */
  onClose: () => void;
  /** onSave is called when the remove mutation is successfully executed */
  onSave: () => void;
}> = ({ count, IDs, show, onClose, onSave }) => {
  const [removePromotions, { loading }] = useDestroySelfStoragePromotionBatchMutation({
    client,
    onCompleted: onSave,
    refetchQueries: [{ query: PaginatedSelfStorageUnitsDocument }],
  });

  const handleRemovePromotions = () =>
    removePromotions({
      variables: {
        input: { IDs },
      },
    });

  if (!show) {
    return null;
  }

  return (
    <Modal onClose={onClose}>
      <Modal.Content>
        <ModalWrapper>
          <Modal.Header>
            <Title>
              Remove Promotion
              {typeof count === 'number' && (
                <>
                  {' '}
                  from <Pluralize count={count} singular="Rentable Unit" plural="Rentable Units" />
                </>
              )}
              ?
            </Title>
          </Modal.Header>
          <Modal.Body>
            <Spacer height="16px" />
            <Footer>
              <Button kind="danger" size="lg" onClick={onClose} disabled={loading}>
                Cancel
              </Button>
              <Spacer width="12px" />
              <Button kind="primary" size="lg" onClick={handleRemovePromotions} disabled={loading}>
                Confirm
              </Button>
            </Footer>
            <Spacer height="8px" />
          </Modal.Body>
        </ModalWrapper>
      </Modal.Content>
    </Modal>
  );
};

(function () {
  const app = angular.module('app');

  app.directive('inputDateTimeWithTimeZone', [
    'TZDateTimeService',
    function (TZDateTimeService) {
      return {
        restrict: 'A',

        scope: {
          tz: '=tz',
          min: '=min',
          max: '=max',
          value: '=value',
          onChangeCallback: '&',
          disabled: '=disabled',
        },

        template:
          '<div class="form-inline">' +
          '<input ng-disabled="disabled" type="date" class="form-control" ng-model="local.date" ng-change="change(local)" ' +
          'ng-model-options="{timezone: \'UTC\'}" min="{{min}}" max="{{max}}" />' +
          '<span> - </span>' +
          '<input ng-disabled="disabled" type="time" class="form-control" ng-model="local.time" ng-change="change(local)" ' +
          'ng-model-options="{timezone: \'UTC\'}" min="{{min}}" max="{{max}}" />' +
          '</div>',

        link: function (scope) {
          const reset = function resetTime() {
            scope.local = scope.local || {};
            const date = scope.local.date || new Date();
            const tz = scope.tz || moment.tz.guess();
            scope.local.date = TZDateTimeService.utcForValueWithTZ(date, tz);
            scope.local.time = null;
          };

          reset();

          scope.change = function () {
            scope.value = TZDateTimeService.localForDateTimeWithTZ(scope.local.date, scope.local.time, scope.tz);
            if (scope.onChangeCallback !== undefined) {
              scope.onChangeCallback({ newValue: scope.value });
            }
          };

          scope.$watch('tz', function () {
            if (scope.local.date && scope.local.time && scope.tz) {
              scope.value = TZDateTimeService.localForDateTimeWithTZ(scope.local.date, scope.local.time, scope.tz);
            } else {
              scope.updateDateTime();
            }
          });

          scope.$watch('value', function () {
            scope.updateDateTime();
          });

          scope.updateDateTime = () => {
            if (scope.value && scope.tz) {
              scope.local.date = TZDateTimeService.utcForValueWithTZ(scope.value, scope.tz);
              scope.local.time = TZDateTimeService.utcForValueWithTZ(scope.value, scope.tz);
            } else {
              reset();
            }
          };
        },
      };
    },
  ]);
})();

import cn from 'classnames';
import React from 'react';

import { Address, Maybe } from '@admin/schema';

type NullablePartial<T> = {
  [K in keyof T]: T[K] | null;
};

export const Preview: React.FC<{
  mode?: 'inline'; // controls formatting
  address: Pick<
    NullablePartial<Address>,
    'street' | 'aptsuite' | 'neighborhood' | 'city' | 'state' | 'zip' | 'businessName'
  > & {
    business_name?: Maybe<string>;
  };
}> = ({ mode, address }) => {
  const { street, aptsuite, neighborhood, city, state, zip } = address;
  // NOTE: support for REST / GQL formatting
  const businessName = address.businessName ?? address.business_name;
  const locality = neighborhood ? `${city} (${neighborhood})` : city;

  return (
    <div className={cn('address', mode && `address-${mode}`)}>
      <div>
        {street} {aptsuite}
      </div>{' '}
      {businessName && <div>{businessName}</div>}{' '}
      <div>
        {locality}, {state} {zip}
      </div>
    </div>
  );
};

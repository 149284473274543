(function () {
  const app = angular.module('app');

  app.controller('AvailabilityDialogController', [
    '$scope',
    'users',
    'unavailabilities',
    'date',
    'time',
    function ($scope, users, unavailabilities, date, time) {
      $scope.date = date;
      $scope.time = time;
      $scope.users = users;
      $scope.unavailabilities = unavailabilities;

      const ids = _.map(unavailabilities, function (unavailability) {
        return unavailability.user_id;
      });

      $scope.available = _.filter($scope.users, function (user) {
        return !_.includes(ids, user.id);
      });

      $scope.unavailable = _.filter($scope.users, function (user) {
        return _.includes(ids, user.id);
      });
    },
  ]);
})();

(function () {
  angular.module('app').factory('Logger', [
    '$resource',
    function ($resource) {
      return $resource(
        '/logger/:id.json',
        {
          id: '@id',
        },
        {
          info: {
            url: '/logger/info.json',
            method: 'POST',
          },
          warn: {
            url: '/logger/warn.json',
            method: 'POST',
          },
        },
      );
    },
  ]);
})();

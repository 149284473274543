(function () {
  angular.module('app').factory('MessageObjection', [
    '$resource',
    function ($resource) {
      return $resource('/messages/:message_id/objections.json', {
        message_id: '@message_id',
      });
    },
  ]);
})();

import { isFunction, omitBy, omit, uniq, isArray } from 'lodash';

export const value =
  (actionTypes, defaultValue = null) =>
  (state = defaultValue, action) => {
    switch (action.type) {
      case actionTypes.SET:
        return isFunction(action.data) ? action.data(state) : action.data;
      case actionTypes.CLEAR:
        return defaultValue;
      default:
        return state;
    }
  };

export const object =
  (actionTypes, defaultValue = {}) =>
  (state = defaultValue, action) => {
    switch (action.type) {
      case actionTypes.SET:
        return { ...state, ...(isFunction(action.data) ? action.data(state) : action.data) };
      case actionTypes.FILTER:
        return isArray(action.data) ? omitBy(state, action.data) : omit(state, action);
      case actionTypes.REPLACE:
        return isFunction(action.data) ? action.data(state) : action.data;
      case actionTypes.CLEAR:
        return defaultValue;
      default:
        return state;
    }
  };

export const array =
  (actionTypes, defaultValue = []) =>
  (state = defaultValue, action) => {
    let result = [];
    switch (action.type) {
      case actionTypes.SET_AT:
        result = [...state.slice(0, action.index), action.data, ...state.slice(action.index + 1)];
        break;
      case actionTypes.INSERT_AT:
        result = [...state.slice(0, action.index), action.data, ...state.slice(action.index)];
        break;
      case actionTypes.REMOVE_AT:
        result = [...state.slice(0, action.index), ...state.slice(action.index + 1)];
        break;
      case actionTypes.SLICE:
        result = state.slice(action.start, action.end);
      case actionTypes.PUSH:
      case actionTypes.ADD:
        result = [...state, action.data];
        break;
      case actionTypes.CONCAT:
        result = [...state, ...action.data];
        break;
      case actionTypes.UNSHIFT:
        result = [action.data, ...state];
        break;
      case actionTypes.SHIFT:
        result = state.slice(1);
        break;
      case actionTypes.POP:
        result = state.slice(0, -1);
        break;
      case actionTypes.FILTER:
        result = state.filter(action.data);
        break;
      case actionTypes.MAP:
        result = state.map(action.data);
        break;
      case actionTypes.REPLACE:
      case actionTypes.SET:
        result = isFunction(action.data) ? action.data(state) : action.data;
        break;
      case actionTypes.CLEAR:
        return defaultValue;
      default:
        return state;
    }
    if (action.unique && result) {
      return uniq(result);
    }
    return result;
  };

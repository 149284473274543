(function () {
  const app = angular.module('app');
  app.factory('Page', () => {
    let title = 'Clutter';
    return {
      title: () => title,
      reset: (attributes) => {
        title = attributes.title;
      },
    };
  });
})();

import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Field } from './field';

export const ReferenceFields: React.FC = () => {
  const { errors, register } = useFormContext<{
    releaseNumber: string;
    reference1: string;
    reference2: string;
    reference3: string;
    reference4: string;
    reference5: string;
  }>();

  return (
    <>
      <Field error={errors.releaseNumber} id="release_number" label="Release Number">
        <input id="release_number" type="text" className="form-control" name="releaseNumber" ref={register} />
      </Field>
      <Field error={errors.reference1} id="reference_1" label="Reference 1">
        <input id="reference_1" type="text" className="form-control" name="reference1" ref={register} />
      </Field>
      <Field error={errors.reference2} id="reference_2" label="Reference 2">
        <input id="reference_2" type="text" className="form-control" name="reference2" ref={register} />
      </Field>
      <Field error={errors.reference3} id="reference_3" label="Reference 3">
        <input id="reference_3" type="text" className="form-control" name="reference3" ref={register} />
      </Field>
      <Field error={errors.reference4} id="reference_4" label="Reference 4">
        <input id="reference_4" type="text" className="form-control" name="reference4" ref={register} />
      </Field>
      <Field error={errors.reference5} id="reference_5" label="Reference 5">
        <input id="reference_5" type="text" className="form-control" name="reference5" ref={register} />
      </Field>
    </>
  );
};

import axios from 'axios';

import { IDiscount } from '@admin/types/discount';

interface IDiscountSearch {
  query: string;
  referrers: boolean;
  vouchers: boolean;
  coupons: boolean;
}

export class DiscountsResource {
  public static async search({ query, referrers, vouchers, coupons }: IDiscountSearch): Promise<IDiscount[]> {
    return (await axios.get('/discounts/search.json', { params: { query, referrers, vouchers, coupons } })).data;
  }
}

(function () {
  angular.module('app').factory('Payments', [
    '$resource',
    function ($resource) {
      return $resource(
        '/accounts/:account_id/payments.json',
        {
          account_id: '@account_id',
        },
        {
          index: {
            method: 'GET',
            isArray: false,
          },
        },
      );
    },
  ]);
})();

import styled from '@emotion/styled';
import cn from 'classnames';
import React, { useContext } from 'react';

import { ISelection, Selector, Uploader } from '@shared/components/files';
import { Spacer } from '@shared/components/helpers';

import { Context } from './context';

const FlexContainer = styled.div`
  height: 40px;
  display: flex;
  align-items: start;
`;

const TruncatedText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const UploadBar = styled(TruncatedText)`
  min-width: 144px;
`;

export const FileUploader: React.FC<{
  upload?: ISelection;
}> = ({ upload }) => {
  const { file, loading } = useContext(Context);

  return (
    <FlexContainer>
      <label className={cn('btn', 'btn-xs', 'btn-rounded', 'btn-default', { disabled: loading })}>
        <Selector multiple={false} accept=".csv" disabled={loading} />
        Choose File
      </label>
      <Spacer width="6px" />
      {!upload && <TruncatedText>{file?.name}</TruncatedText>}
      <UploadBar>{upload && <Uploader key={upload.uuid} {...upload} />}</UploadBar>
    </FlexContainer>
  );
};

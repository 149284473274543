import angular from 'angular';

const app = angular.module('app');

app.component('invoiceSummary', {
  templateUrl: 'partials/components/invoice_summary.html',
  bindings: {
    invoice: '<',
  },
});

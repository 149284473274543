import * as React from 'react';

import { HistoryResourceSubscribeFragment } from '@admin/schema';

import { Timestamp } from '@admin/components/timestamp';
import { Currency } from '@shared/components/helpers/currency';

export const Subscribe: React.FC<{
  resource: HistoryResourceSubscribeFragment;
  timestamp: string;
  timezone?: string;
}> = ({ resource, timestamp, timezone }) => (
  <tr>
    <td className="col-nowrap col-md-2 text-left" colSpan={2}>
      <Timestamp value={timestamp} timezone={timezone} />
    </td>

    <td className="col-nowrap col-md-2 text-left" colSpan={2}>
      <strong>{resource.user.name}</strong> <span>adjusted</span>
    </td>

    <td className="col-md-8 text-left" colSpan={8}>
      <p>
        <span>{resource.adjustments.length} subscriptions</span> <span>for</span>{' '}
        <strong>
          <a href={`/accounts/${resource.order.accountID}/orders/${resource.order.id}`}>
            Order #{resource.order.number}
          </a>
        </strong>
      </p>
      <ul>
        {resource.adjustments.map((adjustment, index) => (
          <li key={index}>
            <strong>
              {adjustment.kind === 'upgrade' && <>Add</>}
              {adjustment.kind === 'downgrade' && <>Remove</>}
              {adjustment.kind === 'price_match' && <>Price Match</>}
            </strong>{' '}
            <strong>{adjustment.subscription.quantity}</strong> <span>×</span>{' '}
            <strong>{adjustment.subscription.name}</strong>{' '}
            <span>
              (<Currency value={adjustment.subscription.pricing.amount * adjustment.subscription.quantity} />)
            </span>
          </li>
        ))}
      </ul>
    </td>
  </tr>
);

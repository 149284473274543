import styled from '@emotion/styled';
import cn from 'classnames';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { DecimalInput } from '@shared/components/bootstrap';
import { Spacer } from '@shared/components/helpers';

import { ValidationIcon } from './field';

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const FormGroup = styled.div`
  margin-bottom: 0px;
`;

const Error = styled.div`
  font-size: 12px;
  color: #a94442;
  margin: 4px 0 2px 0;
`;

export const PriceOverrideRateField: React.FC = () => {
  const { register, formState, errors } = useFormContext<{
    priceOverride: {
      rate?: string;
    };
  }>();

  const submitted = formState.isSubmitted;
  const error = errors.priceOverride?.rate?.message;

  return (
    <div>
      <InputWrapper>
        <span>$</span>
        <Spacer width="8px" />
        <FormGroup
          className={cn('form-group', {
            'has-success': submitted && !error,
            'has-error': submitted && !!error,
            'has-feedback': submitted,
          })}
        >
          <DecimalInput
            id="price-override-rate"
            className="form-control text-right"
            name="priceOverride.rate"
            step="any"
            ref={register({
              required: 'Please provide a price.',
              min: { value: 0, message: 'Price must be greater than zero.' },
            })}
          />
          {submitted && <ValidationIcon icon={error ? faTimes : faCheck} />}
        </FormGroup>
      </InputWrapper>
      {error && <Error>{error}</Error>}
    </div>
  );
};

import * as React from 'react';

import { Currency } from '@shared/components/helpers';

export const FeesTotal: React.FC<{
  totalAmount: number;
}> = ({ totalAmount }) => (
  <div className="fees">
    <div className="form-group">
      <div className="onboarding-fees">
        <div className="col-sm-7 control-label">
          <strong>Fees Total:</strong>
        </div>
        <div className="col-sm-5 form-total">
          <strong className="text-success">
            <Currency value={totalAmount} />
          </strong>
        </div>
      </div>
    </div>
  </div>
);

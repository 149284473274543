(function () {
  angular.module('app').controller('DispatcherInstructionsController', [
    '$scope',
    'dispatch',
    function ($scope, dispatch) {
      $scope.dispatch = dispatch;
      $scope.form = {
        mover_notes: dispatch.mover_notes,
        warehouse_notes: dispatch.warehouse_notes,
        load_out: dispatch.load_out,
        addons: dispatch.addons ? dispatch.addons : [],
      };

      $scope.save = function () {
        $scope.dispatch.mover_notes = $scope.form.mover_notes;
        $scope.dispatch.warehouse_notes = $scope.form.warehouse_notes;
        $scope.dispatch.load_out = $scope.form.load_out;
        $scope.dispatch.addons = $scope.form.addons;
        $scope.closeThisDialog();
      };
    },
  ]);
})();

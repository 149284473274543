(function () {
  angular.module('app').filter('pluralize', function () {
    return function (number, singular, plural) {
      // appropriated from humanize library: https://github.com/HubSpot/humanize/blob/master/src/humanize.js#L268
      if (typeof number === 'undefined' || typeof singular === 'undefined') {
        return null;
      }

      plural = plural || singular + 's';
      return parseInt(number, 10) === 1 ? singular : plural;
    };
  });
})();

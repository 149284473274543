(function () {
  angular.module('app').factory('MessageThread', [
    '$resource',
    function ($resource) {
      return $resource(
        '/message_threads/:id.json',
        {
          id: '@id',
        },
        {
          index: {
            method: 'GET',
            isArray: true,
          },
          resolve: {
            method: 'PUT',
            url: '/message_threads/resolve.json',
          },
        },
      );
    },
  ]);
})();

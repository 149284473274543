import { DateTime } from 'luxon';
import React from 'react';

import { Availability__RescheduleOfferFragment } from '@admin/schema';
import { Table } from '@shared/components/bootstrap';

import { formattedDiscount } from '@shared/utils/coupon';

import styled from '@emotion/styled';
import { COLORS, toggleStyleValue } from '@clutter/clean';
import { displayableText } from '../util';

const formatDate = (date: string) => DateTime.fromISO(date).toLocaleString(DateTime.DATE_SHORT);
const formatDateTime = (datetime: string) => DateTime.fromISO(datetime).toLocaleString(DateTime.DATETIME_SHORT);

const Row = styled.tr<{ expired: boolean }>`
  color: ${toggleStyleValue('expired', COLORS.hippo, COLORS.panther)};
`;

const Entry: React.FC<{
  offer: Availability__RescheduleOfferFragment;
  selectedOptions: string[];
  onChange(id: string, checked: boolean): void;
}> = ({ offer, selectedOptions, onChange }) => {
  const coupon = offer.rescheduleOfferItems[0]?.coupon;

  return (
    <Row expired={offer.expired}>
      <td>
        {!offer.expired && (
          <input
            data-test-id={`toDelete${offer.id}`}
            checked={selectedOptions.includes(offer.id)}
            type="checkbox"
            onChange={(event) => onChange(offer.id, event.target.checked)}
          />
        )}
      </td>
      <td className="text-left">{offer.region.name}</td>
      <td className="text-left">{displayableText(offer.orderClassification)}</td>
      <td className="text-left">{formatDate(offer.originDate)}</td>
      <td className="text-left">{formatDateTime(offer.startAt)}</td>
      <td className="text-left">{formatDateTime(offer.expireAt)}</td>
      <td className="text-left">{offer.targetDates.map((date) => formatDate(date)).join(', ')}</td>
      <td className="text-left">{coupon && formattedDiscount(coupon)}</td>
      <td className="text-left">{offer.orderMinimumMoverMinutes}</td>
      <td className="text-left">{offer.originTargetAvailabilityThreshold}%</td>
      <td className="text-left">{offer.creator.name}</td>
    </Row>
  );
};

export const Entries: React.FC<{
  availabilityRescheduleOffers: Availability__RescheduleOfferFragment[];
  selectedOptions: string[];
  setSelectedOptions: React.Dispatch<React.SetStateAction<string[]>>;
}> = ({ availabilityRescheduleOffers, selectedOptions, setSelectedOptions }) => {
  const onChange = (id: string, checked: boolean) => {
    setSelectedOptions((current) => {
      if (checked) {
        return Array.from(new Set([...current, id]));
      } else {
        return current.filter((option) => option !== id);
      }
    });
  };

  return (
    <Table striped responsive>
      <thead>
        <tr>
          <th className="text-left" />
          <th className="text-left">Region</th>
          <th className="text-left">Order Classification</th>
          <th className="text-left">Origin Date</th>
          <th className="text-left">Start At</th>
          <th className="text-left">Expire At</th>
          <th className="text-left">Target Dates</th>
          <th className="text-left">Offer Value</th>
          <th className="text-left">Min Mover Minutes</th>
          <th className="text-left">Target Availability</th>
          <th className="text-left">Added By</th>
        </tr>
      </thead>
      <tbody>
        {availabilityRescheduleOffers.map((offer) => (
          <Entry key={offer.id} offer={offer} selectedOptions={selectedOptions} onChange={onChange} />
        ))}
      </tbody>
    </Table>
  );
};

(function () {
  const app = angular.module('app');

  app.service('PartsService', [
    'PartCategory',
    function (PartCategory) {
      this.categories = PartCategory.query();
      this.categories.$promise.then(function (categories) {
        _.each(categories, function (category) {
          category.parent = category.parent_id
            ? _.find(categories, function (parent) {
                return parent.id === category.parent_id;
              })
            : null;
        });
      });

      this.find = function (id) {
        return this.categories.find(function (category) {
          return category.id === id;
        });
      };
    },
  ]);
})();

import * as React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';

import { SelfStorage__UnitGroupFragment, SelfStorage__KindFragment } from '@admin/schema';

import { FilterGroup, FilterOption } from './filter';

const SELF_STORAGE_KIND_NAME = 'Self-Storage';

enum SelfStorageKindName {
  Locker = 'Locker',
  Parking = 'Parking',
  SelfStorage = 'Self-Storage',
}

interface ISize {
  name: string;
  kind: SelfStorage__KindFragment;
  length?: number;
  width?: number;
  height?: number;
}

export type Size = Set<ISize>;

export const filter = (groups: SelfStorage__UnitGroupFragment[], option: Size) => {
  if (!option.size) {
    return groups;
  }
  const sizes = Array.from(option);
  return groups.filter(({ classification }) =>
    sizes.some(
      (size) =>
        classification.kind.name === size.kind.name &&
        (!size.length || classification.length === size.length) &&
        (!size.width || classification.width === size.width) &&
        (!size.height || classification.height === size.height),
    ),
  );
};

const cuft = ({ classification: { length, width, height } }: SelfStorage__UnitGroupFragment) => length * width * height;

const priority = ({
  classification: {
    kind: { name },
  },
}: SelfStorage__UnitGroupFragment) => {
  switch (name) {
    case SelfStorageKindName.Locker:
      return 1;
    case SelfStorageKindName.SelfStorage:
      return 2;
    case SelfStorageKindName.Parking:
      return 3;
    default:
      return 0;
  }
};

const options = (groups: readonly SelfStorage__UnitGroupFragment[]) => {
  const names = new Set<string>();
  return [...groups]
    .sort((a: SelfStorage__UnitGroupFragment, b: SelfStorage__UnitGroupFragment) => {
      if (a.classification.kind.name === b.classification.kind.name) {
        return cuft(a) - cuft(b);
      } else {
        return priority(a) - priority(b);
      }
    })
    .map(({ classification: { length, width, height, kind } }) =>
      kind.name === SELF_STORAGE_KIND_NAME
        ? {
            name: `${width}′ × ${length}′ × ${height}′`,
            kind,
            width,
            length,
            height,
          }
        : { name: kind.name, kind },
    )
    .filter((option) => {
      const exists = names.has(option.name);
      names.add(option.name);
      return !exists;
    });
};

export const Sizing: React.FC<{
  groups: SelfStorage__UnitGroupFragment[];
  selection: Size;
  onSelect(selection: Size): void;
}> = ({ groups, selection, onSelect }) => {
  const [sizes, setSizes] = useState(() => options(groups));
  useEffect(() => setSizes(options(groups)), groups);

  return (
    <FilterGroup>
      {sizes.map((option, index) => {
        const selected = selection.has(option);
        return (
          <FilterOption
            key={index}
            onSelect={() => {
              const copy = new Set(selection);
              copy[selected ? 'delete' : 'add'](option);
              onSelect(copy);
            }}
            selected={selected}
            children={option.name}
          />
        );
      })}
    </FilterGroup>
  );
};

(function () {
  angular.module('app').factory('Stripe', [
    '$resource',
    function ($resource) {
      return $resource(
        '/accounts/:account_id/stripes.json',
        {
          account_id: '@account_id',
        },
        {},
      );
    },
  ]);
})();

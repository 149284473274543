import styled from '@emotion/styled';
import * as React from 'react';
import { COLORS } from '@root/colors';

import { Roles } from '@admin/components/helpers/roles';
import { useActiveAuctionOrderQuery } from 'admin/schema';
import { client } from '@admin/libraries/apollo';
import { UserRole } from '@admin/types';
import { Timestamp } from '@shared/components/helpers';

const Banner = styled.div`
  background: ${COLORS.yellow};
  margin-bottom: 10px;
  display: block;
  font-size: 16px;
  padding: 10px;
`;

export const AuctionAccountBanner: React.FC<{
  accountID: string;
}> = ({ accountID }) => {
  const auctionOrder = useActiveAuctionOrderQuery({ client, variables: { accountID } }).data?.activeAuctionOrder;

  return (
    <>
      {auctionOrder && (
        <Banner>
          Auction Account for Auction on <Timestamp value={auctionOrder.scheduled} format={Timestamp.Format.DateTime} />
          <Roles show={[UserRole.L2Agent, UserRole.Care, UserRole.BillingAdmin]}>
            , please transfer to AR if available. Read all account notes before speaking to the customer.
          </Roles>
        </Banner>
      )}
    </>
  );
};

import React, { useEffect, useState } from 'react';
import { client } from '@admin/libraries/apollo';
import {
  Estimation__AlgorithmName,
  useEstimationAlgorithmsQuery,
  useEstimationAlgorithmForOrderQuery,
} from '@admin/schema';
import { Box, Select } from '@clutter/clean';
import styled from '@emotion/styled';

import { Panel } from '@admin/components/helpers/panel';

import { Table as ItemsTable } from './items/table';
import { Table as RoomsTable } from './rooms/table';
import { OrderPanel } from '../order/panel';
import { Uploads as EstimationUploads } from '../order/uploads';

const StyledSelect = styled.div`
  max-width: 200px;
`;

const InventoryContainer = styled(Box.FlexItem)`
  min-width: 700px;
`;

const UploadsContainer = styled(Box.FlexItem)`
  min-width: 400px;
  width: 40%;
  margin: 10px 30px;
  text-align: center;
`;

const DEFAULT_COLLAPSED = false;
const DEFAULT_PANEL_ALGORITHM = null;

export const EstimationFlowInsightPanel: React.FC<{
  orderID: string;
  moving: boolean;
}> = ({ orderID, moving }) => {
  const [collapsed, setCollapsed] = useState<boolean>(DEFAULT_COLLAPSED);
  const [algorithmID, setAlgorithmID] = useState<string | null>('');
  const [orderAlgorithmID, setOrderAlgorithmID] = useState<string>('');
  const [dropdownOptions, setDropdownOptions] = useState<Array<{ value: string | null; label: string }>>([]);

  const { data } = useEstimationAlgorithmsQuery({ client });
  useEstimationAlgorithmForOrderQuery({
    client,
    variables: { orderID },
    onCompleted: (estimationAlgorithm) => {
      setAlgorithmID(estimationAlgorithm.estimationAlgorithmForOrder?.id ?? '');
      setOrderAlgorithmID(estimationAlgorithm.estimationAlgorithmForOrder?.id ?? '');
    },
  });

  useEffect(() => {
    if (!data || !data.estimationAlgorithms.length) {
      return;
    }

    const options = [
      ...data.estimationAlgorithms
        .filter((algorithm) => algorithm.name !== Estimation__AlgorithmName.MovingDisassemblyFlow || moving)
        .map((algorithm) => ({
          value: algorithm.id,
          label: algorithm.name.toLowerCase(),
        })),
      { value: null, label: 'default' },
    ];
    setDropdownOptions(options);
  }, [data, moving]);

  const disassemblyFlowAlgorithmID = data?.estimationAlgorithms.find(
    (algorithm) => algorithm.name === Estimation__AlgorithmName.MovingDisassemblyFlow,
  )?.id;
  const disassemblyFlow = disassemblyFlowAlgorithmID === algorithmID;
  const v1AlgorithmID = data?.estimationAlgorithms.find(
    (algorithm) => algorithm.name === Estimation__AlgorithmName.V1,
  )?.id;
  const virtualWalkthroughID = data?.estimationAlgorithms.find(
    (algorithm) => algorithm.name === Estimation__AlgorithmName.VirtualWalkthrough,
  )?.id;

  return (
    <Panel>
      <Panel.Header>
        <Panel.Collapse collapsed={collapsed} onChange={setCollapsed} />
        <Panel.Title>Estimation Flow Insight</Panel.Title>
      </Panel.Header>
      <Panel.Body>
        {!collapsed && (
          <Box.Flex flexDirection="row" flexWrap="wrap" alignItems="flex-start">
            <InventoryContainer order={[2, 2, 1]}>
              <StyledSelect>
                <Select
                  name="algorithm"
                  placeholder="-Flow Version-"
                  value={algorithmID ?? ''}
                  options={dropdownOptions}
                  onChange={(event: any) => setAlgorithmID(event)}
                />
              </StyledSelect>
              <hr />
              {[virtualWalkthroughID, v1AlgorithmID, DEFAULT_PANEL_ALGORITHM].includes(algorithmID) ? (
                <OrderPanel
                  orderID={orderID}
                  algorithmID={orderAlgorithmID}
                  isVirtualWalkthrough={algorithmID === virtualWalkthroughID}
                />
              ) : (
                <>
                  {algorithmID !== '' && (
                    <ItemsTable orderID={orderID} algorithmID={algorithmID} disassemblyFlow={disassemblyFlow} />
                  )}
                  <hr />
                  {algorithmID !== '' && <RoomsTable orderID={orderID} algorithmID={algorithmID} />}
                </>
              )}
            </InventoryContainer>
            <UploadsContainer order={[1, 1, 2]}>
              <h3 className="text-thin">Customer Uploads</h3>
              <EstimationUploads orderID={orderID} />
            </UploadsContainer>
          </Box.Flex>
        )}
      </Panel.Body>
    </Panel>
  );
};

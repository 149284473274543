(function () {
  angular.module('app').filter('prependHash', [
    '$filter',
    function ($filter) {
      return function (number) {
        if (number) {
          return $filter('prepend')(number, '#');
        }
      };
    },
  ]);
})();

import { formatPhoneForHuman } from '@admin/utils/format_phone_for_human';

(function () {
  const app = angular.module('app');

  app.controller('MessageMetadataController', [
    '$filter',
    function ($filter) {
      const self = this;
      self.getRecipient = getRecipient;
      self.getSender = getSender;

      function formatPhoneAndTealUser(number) {
        return `${number == 'iterable' ? 'Iterable' : formatPhoneForHuman(number)} (${self.message.user.name})`;
      }

      function getSender() {
        return isInbound(self.message)
          ? $filter('telephoneDetail')(self.message.phone)
          : formatPhoneAndTealUser(self.message.from);
      }

      function getRecipient() {
        return isInbound(self.message)
          ? formatPhoneAndTealUser(self.message.to)
          : $filter('telephoneDetail')(self.message.phone);
      }

      function isInbound(message) {
        return message.kind === 'inbound';
      }
    },
  ]);
})();

(function () {
  const app = angular.module('app');

  app.component('partCategorySelect', {
    templateUrl: 'partials/components/part_category_select.html',
    bindings: {
      item: '<',
      selection: '=',
    },
    controller: [
      'PartsService',
      function (PartsService) {
        const self = this;
        self.categories = PartsService.categories;
      },
    ],
  });
})();

import React from 'react';

import { AuditItemQuery } from '@admin/schema';

type ItemType = AuditItemQuery['item'];

export const ItemDetails: React.FC<{
  item: ItemType;
}> = ({ item }) => (
  <>
    {item && (
      <>
        {' - '}
        <span className="label label-default">
          <CategoryFormatter item={item} fallback={'No Category'} />
        </span>
        {' - '}
        <span className="label label-info">
          <DimensionsFormatter item={item} fallback={'No Category'} />
        </span>
        {' - '}
        <span className={'label ' + (item.dimensionsOverwritten ? 'label-dark' : 'label-default')}>
          {item.dimensionsOverwritten ? 'Dim Overwritten' : 'Default Dim'}
        </span>
      </>
    )}
  </>
);

const CategoryFormatter: React.FC<{
  item: ItemType;
  fallback?: string;
}> = ({ item, fallback }) => {
  if (!item) {
    return fallback ? <>{fallback}</> : null;
  }

  if (!!item.packaging && !item.category) {
    if (item.materialCategory?.name) {
      return <>{item.materialCategory.name}</>;
    } else if (item.partCategory?.name) {
      return <>{item.partCategory.name}</>;
    }
  }

  if (!item.category) {
    return fallback ? <>{fallback}</> : null;
  }

  if (item.customCategoryName) {
    return <>{item.customCategoryName} (Custom)</>;
  }
  if (!item.sizingSelection) {
    return <>{item.category.name}</>;
  }

  let summary = item.sizingSelection;
  if (item.category.sizingMetric && item.category.sizingMetric.unit) {
    summary += `-${item.category.sizingMetric.unit}`;
  }

  return (
    <>
      {item.category.name} ({summary})
    </>
  );
};

const DimensionsFormatter: React.FC<{
  item: ItemType;
  fallback?: string;
}> = ({ item, fallback = null }) => {
  if (!item?.length || !item?.width || !item?.height) {
    return fallback ? <>{fallback}</> : null;
  }
  return (
    <>
      {item.length}" × {item.width}" × {item.height}" ({((item.length * item.width * item.height) / 1728).toFixed(2)}{' '}
      cuft)
    </>
  );
};

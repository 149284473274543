import cn from 'classnames';
import React, { useState } from 'react';

import { Panel } from '@admin/components/helpers/panel';
import { DetailedClaimFragment } from '@admin/schema';
import { Nav } from '@shared/components/bootstrap';

import { ClaimSteps, Steps, stepEnabled } from './details/steps';

const TAB_NAMES = {
  [ClaimSteps.Summary]: 'Summary',
  [ClaimSteps.Verify]: 'Verify',
  [ClaimSteps.Assess]: 'Assess',
  [ClaimSteps.Offer]: 'Offer',
};

const TABS = [ClaimSteps.Summary, ClaimSteps.Verify, ClaimSteps.Assess, ClaimSteps.Offer];

interface ITabProps {
  claim: DetailedClaimFragment;
  selectedTab?: ClaimSteps;
  setTab(mode: ClaimSteps): void;
}

const TabItem: React.FC<{ tab: ClaimSteps } & ITabProps> = ({ tab, claim, selectedTab, setTab }) => {
  const enabled = stepEnabled(tab, claim);
  return (
    <Nav.Item key={tab} className={cn({ active: tab === selectedTab, disabled: !enabled })}>
      <Nav.Link onClick={() => enabled && setTab(tab)}>{TAB_NAMES[tab]}</Nav.Link>
    </Nav.Item>
  );
};

const Tabs: React.FC<ITabProps> = (props) => (
  <Nav format={Nav.Format.Tabs}>
    {TABS.map((tab) => (
      <TabItem key={tab} tab={tab} {...props} />
    ))}
  </Nav>
);

export const Specifications: React.FC<{
  claim: DetailedClaimFragment;
  editable: boolean;
}> = ({ claim, editable }) => {
  const [step, setStep] = useState<ClaimSteps>(ClaimSteps.Summary);

  return (
    <Panel>
      <Panel.Header>
        <Panel.Title>Specifications</Panel.Title>
      </Panel.Header>
      <Panel.Body>
        {editable && <Tabs claim={claim} selectedTab={step} setTab={setStep} />}
        <Steps claim={claim} editable={editable} step={step} onStepChange={setStep} />
      </Panel.Body>
    </Panel>
  );
};

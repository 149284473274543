import { currency } from '@shared/utils/currency';
import * as React from 'react';

import { Maybe, SelfStorage__ProtectionFragment, useSelfStorageProtectionsQuery } from '@admin/schema';
import { client } from '@admin/libraries/apollo';

const ProtectionOption: React.FC<SelfStorage__ProtectionFragment & { legacy?: boolean }> = ({
  id,
  name,
  price,
  legacy,
}) => (
  <option value={id}>
    {name} ({currency(price)}/mo) {legacy && ' - Legacy'}
  </option>
);

export const Selector: React.FC<{
  facilityID: string;
  currentProtection?: Maybe<SelfStorage__ProtectionFragment>;
  selection?: Maybe<SelfStorage__ProtectionFragment>;
  onSelect(selection?: SelfStorage__ProtectionFragment): void;
}> = ({ facilityID, currentProtection, selection, onSelect }) => {
  const { data, loading } = useSelfStorageProtectionsQuery({ client, variables: { facilityID } });
  const protections = data?.protections ?? [];
  const onChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedID = event.target.value || undefined;
    if (selectedID === currentProtection?.id) {
      onSelect(currentProtection ?? undefined);
    } else {
      onSelect(protections.find(({ id }) => id === selectedID));
    }
  };

  if (!protections.length) {
    return null;
  }

  return (
    <select className="form-control" disabled={loading} value={selection?.id} onChange={onChange}>
      <option value="">{loading ? ' - Loading - ' : ' - Protection - '}</option>
      {currentProtection && !protections.find((protection) => protection.id === currentProtection.id) && (
        <ProtectionOption legacy key={currentProtection.id} {...currentProtection} />
      )}
      {protections.map((protection) => (
        <ProtectionOption key={protection.id} {...protection} />
      ))}
    </select>
  );
};

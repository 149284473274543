import { DateTime } from 'luxon';
import * as React from 'react';
import { FC, useState } from 'react';

import { Alert, AnchorButton as Link, Breadcrumb, Button, Panel } from '@shared/components/bootstrap';

import { Availability } from '@shared/components/availability/types';
import { AvailabilityCalendar } from '@shared/components/availability/availability_calendar';
import { AvailabilityTimeslot } from '@shared/components/availability/availability_timeslot';
import { AvailabilityContainer } from '@shared/components/availability/availability_container';

import { PageHeader } from '@admin/components/helpers/page_header';

import { accountsURL, accountURL, orderURL } from '@admin/config/routes';

import { client } from '@admin/libraries/apollo';
import {
  Account,
  AvailabilitiesInputKind,
  Order,
  Region,
  Status,
  useAvailabilitiesQuery,
  useOrderRescheduleMutation,
  useOrderWithSchedulingQuery,
} from '@admin/schema';
import styled from '@emotion/styled';

const Fields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const OrderRescheduleAvailability: FC<{
  order: Pick<Order, 'id' | 'scheduled'> & {
    account: Pick<Account, 'id'>;
    region: Pick<Region, 'id' | 'tz'>;
  };
}> = ({ order }) => {
  const [date, setDate] = useState<DateTime | undefined>();
  const [selection, setSelection] = useState<Availability | undefined>();
  const scheduled = DateTime.fromISO(order.scheduled, { zone: order.region.tz });
  const [period, setPeriod] = React.useState<DateTime>(() => scheduled);
  const from = period.startOf('month');
  const till = period.endOf('month');

  const [reschedule, { loading: saving, data: saved }] = useOrderRescheduleMutation({ client });
  const error = saved?.result?.error;

  const { data, loading } = useAvailabilitiesQuery({
    client,
    variables: {
      accountID: order.account.id,
      input: {
        orderID: order.id,
        from: from.toISODate(),
        till: till.toISODate(),
        kind: AvailabilitiesInputKind.Editing,
      },
    },
  });

  const availabilities = data?.availabilities;

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    event.stopPropagation();
    if (!selection) return;

    const response = await reschedule({
      variables: {
        input: {
          orderID: order.id,
          scheduled: selection.datetime,
          duration: selection.duration!,
          laborRateID: selection.laborRate?.id,
          perMoverHourAdjustmentAmount: selection.perMoverHourAdjustmentAmount,
        },
      },
    });

    if (response?.data?.result?.status === Status.Ok) window.location.href = orderURL(order);
  };

  return (
    <form onSubmit={onSubmit}>
      {error && <Alert style="danger">{error}</Alert>}
      <Panel>
        <Panel.Body>
          <AvailabilityContainer
            availabilities={availabilities}
            loading={loading}
            zone={order.region.tz}
            from={from}
            till={till}
            period={period}
            date={date}
            selection={selection}
            onDate={setDate}
            onSelect={setSelection}
            onNavigate={setPeriod}
            onReset={() => {
              setDate(undefined);
              setSelection(undefined);
            }}
          >
            <Fields>
              <AvailabilityCalendar />
              <AvailabilityTimeslot />
            </Fields>
          </AvailabilityContainer>
        </Panel.Body>
        <Panel.Footer align="right">
          <Link kind="default" href={orderURL(order)} disabled={saving}>
            Back
          </Link>
          <Button type="submit" kind="primary" disabled={!selection} loading={saving}>
            Reschedule
          </Button>
        </Panel.Footer>
      </Panel>
    </form>
  );
};

export const OrderReschedule: FC<{
  orderID: string;
}> = ({ orderID }) => {
  const { data } = useOrderWithSchedulingQuery({ client, variables: { id: orderID } });
  if (!data) return null;

  const order = data.order;
  const account = order.account;

  return (
    <>
      <PageHeader>
        <Breadcrumb>
          <Breadcrumb.Item>
            <a href={accountsURL()}>Accounts</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <a href={accountURL(account)}>
              {account.number} ({account.customer.name})
            </a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <a href={orderURL(order)}>#{order.number}</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Reschedule</Breadcrumb.Item>
        </Breadcrumb>
      </PageHeader>

      <OrderRescheduleAvailability order={order} />
    </>
  );
};

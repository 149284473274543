import React, { useState } from 'react';

import { Status, useCancelPaymentPlanOfferMutation } from '@admin/schema';
import { Button, Modal, Text } from '@shared/components/bootstrap';
import { client } from '@admin/libraries/apollo';

export const CancelButton: React.FC<{
  offerID: string;
  disabled?: boolean;
}> = ({ offerID, disabled = false }) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const [cancelOffer, { loading }] = useCancelPaymentPlanOfferMutation({ client });

  const onClose = () => setShowModal(false);

  const onCancelOffer = async () => {
    const response = await cancelOffer({ variables: { offerID } });
    if (response && response.data) {
      if (response.data.cancelOffer.status === Status.Unprocessable) {
        setError(`There was a problem canceling the payment plan offer: ${response.data.cancelOffer.error}`);
      } else if (response.data.cancelOffer.status === Status.Ok) {
        setError(undefined);
        onClose();
      }
    }
  };

  return (
    <>
      <Button kind="danger" type="button" disabled={disabled} onClick={() => setShowModal(true)}>
        Cancel Offer
      </Button>
      {showModal && (
        <Modal onClose={onClose}>
          <Modal.Content>
            <Modal.Header>
              <Modal.Title>Cancel Offer</Modal.Title>
              <Modal.Close close={onClose} />
            </Modal.Header>
            <Modal.Body className="text-center">
              <div className="pad-btm">
                <p>Are you sure you would like to cancel this Payment Plan offer?</p>
              </div>
              <div className="mar-top">
                {error && (
                  <Text tag="p" style="danger">
                    {error}
                  </Text>
                )}
                <Button kind="primary" disabled={loading} onClick={onClose}>
                  No, keep offer open
                </Button>
                <Button kind="danger" className="mar-lft" loading={loading} onClick={onCancelOffer}>
                  Yes, cancel offer
                </Button>
              </div>
            </Modal.Body>
          </Modal.Content>
        </Modal>
      )}
    </>
  );
};

import React, { useState } from 'react';
import { Box } from '@clutter/clean';
import { AnchorButton, Button } from '@shared/components/bootstrap';
import { ConfirmationModal } from '@shared/components/modals/confirmation_modal';
import { IndeterminateCheckboxFormGroup, Option } from './indeterminate_checkbox_form_group';

type AvailabilityControlsProps<T extends string | Option> = {
  createHref: string;
  weeklyScheduleHref?: string;
  loading: boolean;
  options: T[];
  selectedOptions: T[];
  create?: boolean;
  destroy?: boolean;
  onDestroy(): void;
  setSelectedOptions(options: T[]): void;
};

export const AvailabilityControls = <T extends string | Option>({
  createHref,
  weeklyScheduleHref = undefined,
  loading,
  options,
  selectedOptions,
  create = false,
  destroy = false,
  onDestroy,
  setSelectedOptions,
}: AvailabilityControlsProps<T>) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  return (
    <>
      <Box.Flex width="100%" justifyContent="space-between">
        <Box.FlexItem>
          <IndeterminateCheckboxFormGroup
            id="selectAll"
            label="Select All"
            className="checkbox-inline"
            options={options}
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
          />
        </Box.FlexItem>
        <Button.Group>
          <Button
            kind="danger"
            loading={loading}
            disabled={!destroy || selectedOptions.length === 0}
            onClick={() => setShowConfirmationModal(true)}
          >
            Delete
          </Button>
          {weeklyScheduleHref && (
            <AnchorButton kind="primary" href={weeklyScheduleHref} loading={loading} disabled={!create}>
              {'New Weekly Schedule'}
            </AnchorButton>
          )}
          <AnchorButton kind="primary" href={createHref} loading={loading} disabled={!create}>
            {weeklyScheduleHref ? 'New One-Off' : 'New'}
          </AnchorButton>
        </Button.Group>
      </Box.Flex>
      {showConfirmationModal && (
        <ConfirmationModal
          title={`Are you sure you want to delete the selected ${selectedOptions.length} ${
            selectedOptions.length !== 1 ? 'availabilities' : 'availability'
          }?`}
          acceptButton={`Yes, delete ${selectedOptions.length !== 1 ? 'these availabilities' : 'this availability'}`}
          declineButton={`No, do not delete ${
            selectedOptions.length !== 1 ? 'these availabilities' : 'this availability'
          }`}
          onConfirm={() => {
            onDestroy();
            setShowConfirmationModal(false);
          }}
          onClose={() => setShowConfirmationModal(false)}
        />
      )}
    </>
  );
};

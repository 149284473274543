(function () {
  const app = angular.module('app');

  app.directive('showForRole', [
    'UserService',
    function (UserService) {
      return {
        restrict: 'A',
        link: function (scope, element, attributes) {
          const roles = attributes.showForRole.split(' ');
          const matched = _.intersection(roles, UserService.roles).length > 0;
          element[matched ? 'removeClass' : 'addClass']('ng-hide');
        },
      };
    },
  ]);
})();

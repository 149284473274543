import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { useDrop } from 'react-dnd';

import { Pluralize } from '@shared/components/helpers';

import { Context } from './context';
import { OptionalAction } from './optional_action';
import { Task } from './task';
import { NightTransportTask } from './types';

const TaskList = styled.div`
  gap: 5px;
  padding: 0 15px 15px;
  display: flex;
  flex-direction: column;
`;

const UnassignedTasksText = styled.div`
  font-weight: bold;
  padding: 15px;
  font-size: 18px;
`;

export const UnassignedTasks: React.FC<{
  tasks: readonly NightTransportTask[];
}> = ({ tasks }) => {
  const { onChangeTask } = useContext(Context);
  const [_, drop] = useDrop(() => ({
    accept: 'Task',
    drop: (task: NightTransportTask) => {
      onChangeTask({ ...task, routeUUID: undefined });
    },
  }));

  return (
    <>
      <UnassignedTasksText>
        <Pluralize count={tasks.length} singular="Unassigned Task" plural="Unassigned Tasks" />
      </UnassignedTasksText>
      <TaskList ref={drop}>
        {[...tasks]
          .sort(({ position: positionA }, { position: positionB }) => positionA - positionB)
          .map((task, index) => (
            <Task key={task.uuid} task={task} index={index} />
          ))}
        <OptionalAction />
      </TaskList>
    </>
  );
};

import { faStar as faRegularStar } from '@fortawesome/free-regular-svg-icons';
import { faStar as faSolidStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import { Landing__Location__Review } from '@admin/schema';

export const Rating: React.FC<{
  review: Pick<Landing__Location__Review, 'rating'>;
}> = ({ review: { rating } }) => (
  <>
    {Array.from({ length: 5 }, (_, i) => (
      <span key={i}>
        <FontAwesomeIcon icon={i < rating ? faSolidStar : faRegularStar} />
      </span>
    ))}
  </>
);

(function () {
  const app = angular.module('app');

  app.service('ItemGroupAttributesService', [
    function () {
      this.convert = function (resource) {
        return {
          id: resource.id,
          hero_image_id: resource.hero_image ? resource.hero_image.id : null,
          category_id: resource.category.id,
          sizing_selection: resource.sizing_selection,
          custom_category_name: resource.custom_category_name,
          account_id: resource.account_id,
          kind: resource.kind,
          length: resource.length,
          width: resource.width,
          height: resource.height,
          num_movers: resource.num_movers,
          dimensions_overwritten: resource.dimensions_overwritten,
          item_ids: resource.items.map(function (item) {
            return item.id;
          }),
          items_attributes: resource.items.map(function (item) {
            return {
              id: item.id,
              packaging: item.packaging ? item.packaging.type : null,
              material_category_id: item.packaging ? item.packaging.material_category_id : null,
              part_category_id: item.packaging ? item.packaging.part_category_id : null,
            };
          }),
        };
      };
    },
  ]);
})();

import { DateTimePicker } from '@admin/components/helpers/date_time_picker';
import { LoadTypeEnum, Logistics__RequestedAppointmentWindowEnum, OptionsQuery } from '@admin/schema';
import { Box } from '@clutter/clean';
import styled from '@emotion/styled';
import { Spacer } from '@shared/components/helpers';
import { Logistics__PalletHeightPreferenceEnum } from '@admin/schema';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Field, Info } from './field';

type Warehouse = OptionsQuery['warehouses'][number];

const DATE_FORMAT = 'yyyy-MM-dd';

export const HelperText = styled.span`
  font-size: 12px;
`;

export const BasicInfoFields: React.FC<{ editMode: boolean; warehouses: Warehouse[]; state?: string }> = ({
  editMode,
  warehouses,
  state,
}) => {
  const { errors, register, setValue, watch, unregister } = useFormContext<{
    type: string;
    number?: string;
    bolNumber?: string;
    warehouseID: string;
    appointmentDate: string;
    specialInstructions?: string;
    handlingMethod: string;
    requestedAppointmentDate: string;
    requestedAppointmentWindow?: Logistics__RequestedAppointmentWindowEnum;
    targetPalletHeight?: number;
    palletHeightPreference?: Logistics__PalletHeightPreferenceEnum;
  }>();

  const getWarehouseTimeZone = () =>
    warehouses.find((warehouse) => String(warehouse.id) === watch('warehouseID'))?.tz || 'America/Los_Angeles';

  const [timeZone, setTimeZone] = useState<string>(getWarehouseTimeZone());

  useEffect(() => {
    register({ name: 'appointmentDate' }, { required: 'please select an appointment date' });
    return () => {
      unregister('appointmentDate');
    };
  }, [register]);

  useEffect(() => {
    setTimeZone(getWarehouseTimeZone());
    setValue('appointmentDate', DateTime.fromISO(watch('appointmentDate')).setZone(timeZone).toString() || '');
  }, [watch('warehouseID')]);

  return (
    <>
      <input id="id" type="hidden" name="id" ref={register} disabled={true} />
      <Field error={errors.type} id="type" label="Load Type" required={true}>
        <select
          className="form-control"
          name="type"
          id="type"
          ref={register({ required: 'please select a load type' })}
          disabled={editMode}
        >
          <option value={LoadTypeEnum.Inbound}>Inbound</option>
          <option value={LoadTypeEnum.Outbound}>Outbound</option>
        </select>
      </Field>
      <Field error={errors.number} id="number" label="Load Number" required={false}>
        <input
          id="number"
          type="text"
          className="form-control"
          name="number"
          defaultValue={undefined}
          placeholder="Will be autogenerated if left blank"
          ref={register}
          disabled={editMode}
        />
      </Field>
      <Field error={errors.bolNumber} id="bol_number" label="Bill of Lading Number" required={false}>
        <input id="bol_number" type="text" className="form-control" name="bolNumber" ref={register} />
      </Field>
      <Field error={errors.warehouseID} id="warehouse_id" label="Warehouse" required={true}>
        <select
          className="form-control"
          name="warehouseID"
          id="warehouse_id"
          defaultValue={watch('warehouseID')}
          ref={register()}
        >
          <option key={0} value={0}>
            None Selected
          </option>
          {warehouses
            .filter((warehouse) => warehouse.active)
            .sort((a, b) => (a.name < b.name ? -1 : 1))
            .map((warehouse) => (
              <option key={warehouse.id} value={warehouse.id}>
                {warehouse.name}
              </option>
            ))}
        </select>
      </Field>
      <Spacer height="4rem" />
      <Box.Flex flexDirection="row" alignItems="top" justifyContent="space-between">
        <Box width="49%">
          <Field
            error={errors.requestedAppointmentDate}
            id="requested_appointment_date"
            label="Requested Appointment Date"
          >
            {' '}
            <input
              id="requested_appointment_date"
              type="date"
              className="form-control"
              name="requestedAppointmentDate"
              min={editMode ? undefined : DateTime.local().setZone(timeZone).toFormat(DATE_FORMAT)}
              disabled={editMode && state !== 'draft'}
              ref={register()}
            />
          </Field>
        </Box>
        <Box width="49%">
          <Field
            error={errors.requestedAppointmentWindow}
            id="requested_appointment_window"
            label="Requested Appointment Window"
          >
            {' '}
            <select
              className="form-control"
              name="requestedAppointmentWindow"
              id="requested_appointment_window"
              disabled={editMode && state !== 'draft'}
              ref={register()}
            >
              <option value={''}>Pick an appointment window</option>
              <option value={Logistics__RequestedAppointmentWindowEnum.Morning}>Morning</option>
              <option value={Logistics__RequestedAppointmentWindowEnum.Afternoon}>Afternoon</option>
              <option value={Logistics__RequestedAppointmentWindowEnum.NoPreference}>No Preference</option>
            </select>
          </Field>
        </Box>
      </Box.Flex>
      <HelperText>
        Use this to keep track of the customer's requested appointment date & window. The warehouse will{' '}
        <strong>not</strong> see this.
      </HelperText>
      <Spacer height="2rem" />
      <Field error={errors.appointmentDate} id="appointment_date" label="Appointment Date and Time">
        {' '}
        <Info>In Warehouse's Local Time Zone</Info>
        {watch('warehouseID') && (
          <DateTimePicker
            dt={DateTime.fromISO(watch('appointmentDate')).setZone(timeZone)}
            onChange={(newDateTime) => {
              setValue('appointmentDate', newDateTime?.toString() || '');
            }}
            allowPastDate={editMode}
            required={false}
          />
        )}
      </Field>
      <HelperText>
        Use this once the appointment date & time are <strong>confirmed</strong>. The warehouse will see this.
      </HelperText>
      <Spacer height="4rem" />
      <Field error={errors.handlingMethod} id="handling_method" label="Handling Method" required={true}>
        <select
          className="form-control"
          name="handlingMethod"
          id="handling_method"
          defaultValue="floor_loaded"
          ref={register({ required: 'please select a handling method' })}
        >
          <option value="floor_loaded">Floor Loaded</option>
          <option value="palletized">Palletized</option>
        </select>
      </Field>
      <Field
        error={errors.targetPalletHeight}
        id="target_pallet_height"
        label="Target Pallet Height (inches)"
        required={false}
      >
        <input
          id="target_pallet_height"
          type="number"
          className="form-control"
          name="targetPalletHeight"
          defaultValue={undefined}
          min="0"
          max="100"
          step="any"
          onWheel={(e) => e.currentTarget.blur()}
          ref={register({ min: 1, max: 100 })}
          onChange={(e) => {
            if (!e.target.value) {
              setValue('palletHeightPreference', undefined);
            }
          }}
        />
      </Field>
      <Field
        error={errors.palletHeightPreference}
        id="pallet_height_preference"
        label="Pallet Height Preference"
        required={!!watch('targetPalletHeight')}
      >
        <select
          className="form-control"
          name="palletHeightPreference"
          id="pallet_height_preference"
          disabled={!watch('targetPalletHeight')}
          ref={register}
        >
          <option value="">Choose a preference if Target Pallet Height is filled out</option>
          <option value={Logistics__PalletHeightPreferenceEnum.DoNotExceed}>Do Not Exceed</option>
          <option value={Logistics__PalletHeightPreferenceEnum.CloseAsPossible}>Close as Possible</option>
        </select>
      </Field>
      <Field error={errors.specialInstructions} id="special_instructions" label="Special Instructions" required={false}>
        <textarea id="special_instructions" className="form-control" name="specialInstructions" ref={register} />
      </Field>
    </>
  );
};

import { RateAdjustment__Scheduling } from '@admin/schema';
import { Currency } from '@shared/components/helpers';
import { DateTime } from 'luxon';
import React from 'react';

export type SummaryScheduling = Pick<RateAdjustment__Scheduling, 'effectiveAt' | 'newPrice' | 'percentageWithSign'>;

export const Summary: React.FC<{ scheduling: SummaryScheduling }> = ({
  scheduling: { effectiveAt, newPrice, percentageWithSign },
}) => (
  <>
    {DateTime.fromISO(effectiveAt).toUTC().toLocaleString(DateTime.DATE_FULL)} to <Currency value={newPrice} /> (
    {percentageWithSign})
  </>
);

import angular from 'angular';
import _ from 'lodash';
import moment from 'moment';

/* eslint-disable import/extensions, import/no-unresolved */
import { availabilities, pricingPerItemFee } from '@admin/utils';
/* eslint-enable import/extensions, import/no-unresolved */

const ISO_DATE_FORMAT = 'YYYY-MM-DD';

const NY_INTRASTATE_LAUNCH_DATE = '2021-02-05';

angular.module('app').component('availability', {
  templateUrl: 'partials/components/availability.html',
  bindings: {
    regionId: '<',
    predecessorId: '<',
    orderType: '<',
    orderSubtype: '<',
    orderId: '<',
    order: '<',
    accountId: '<',
    address: '<',
    items: '<',
    inventory: '<',
    subscriptions: '<',
    timezone: '<',
    targetDate: '<',
    selectedEvent: '@',
    activeEvent: '@',
    checkout: '<',
    zip: '<',
    onScheduleEventClick: '&',
    onScheduleEventClear: '&',
  },
  controller: [
    '$scope',
    '$interval',
    function ($scope, $interval) {
      this.dates = [];

      const clearSelection = () => {
        if (this.onScheduleEventClear) {
          this.onScheduleEventClear();
        }
      };

      const isDateInDates = (d1) => this.dates.some((d2) => moment(d1).isSame(d2, 'day'));
      const setAvailabilityEvents = (entries, isNYIntraStateMove) => {
        this.events = entries
          .filter((entry) => isDateInDates(new Date(entry.datetime)))
          .filter(
            (entry) =>
              !isNYIntraStateMove ||
              moment
                .tz(NY_INTRASTATE_LAUNCH_DATE, this.timezone)
                .isBefore(moment.tz(new Date(entry.datetime), this.timezone), 'day'),
          )
          .map((entry) => ({
            id: entry.datetime.toISO(),
            available: entry.available,
            from_time: entry.datetime.toJSDate(),
            till_time: entry.datetime.plus(entry.duration).toJSDate(),
            duration: entry.duration,
            appointmentFee: entry.appointmentFee,
            laborRate: entry.laborRate,
            perMoverHourAdjustmentAmount: entry.perMoverHourAdjustmentAmount,
            laborBillingFormat: entry.laborBillingFormat,
          }));
      };

      const loadResources = async () => {
        if (this.loading) {
          return;
        }
        const from = _.min(this.dates);
        const till = _.max(this.dates);
        if (!from || !till) {
          return;
        }

        this.loading = true;
        const { data, error } = await availabilities({
          accountID: this.accountId,
          orderID: this.orderId,
          predecessorID: this.predecessorId,
          regionID: this.regionId,
          type: this.orderType,
          subtype: this.orderSubtype,
          address: { ...this.address, zip: this.zip },
          subscriptions: this.subscriptions,
          items: this.items,
          inventory: this.inventory,
          from: moment(from).format(ISO_DATE_FORMAT),
          till: moment(till).format(ISO_DATE_FORMAT),
        });

        const { data: perItemFee, error: perItemFeeError } = await pricingPerItemFee({
          regionID: this.regionId,
          orderType: this.orderType,
          orderSubtype: this.orderSubtype,
        });
        this.loading = false;

        if (error || perItemFeeError) {
          this.error = [error, perItemFeeError].filter(Boolean).join(' ');
        } else {
          const isNYIntraStateMove =
            this.order.type === 'moving-move' &&
            this.order.moving_operation.destination_address.state === 'NY' &&
            this.order.moving_operation.origin_address.state === 'NY';
          setAvailabilityEvents(data, isNYIntraStateMove);
          this.perItemFee = perItemFee;
        }

        $scope.$digest();
      };

      const loadResourcesDebounced = _.debounce(loadResources, 700, {
        trailing: true,
        leading: true,
      });

      const checkChanges = () => {
        delete this.error;
        loadResourcesDebounced();
      };

      const refreshAvailabilities = $interval(() => {
        checkChanges();
      }, 15000);

      this.$onDestroy = () => {
        $interval.cancel(refreshAvailabilities);
      };

      this.getFooterMessage = () => {
        if (this.selectedEvent) {
          const selectedEvent = moment.tz(this.selectedEvent, this.timezone);
          return `You're booking ${selectedEvent.format('h:mm a [on] ddd, MMM D')}`;
        }
        return '';
      };

      this.refresh = () => {
        checkChanges();
      };

      this.onCheck = () => {
        this.dates = this.selectedDays;
        checkChanges();
      };

      this.onDaySelected = (dates) => {
        this.selectedDays = dates;
      };

      this.$onInit = () => {
        this.timezone = this.timezone || 'UTC';
        this.dates = [];
        this.selectedDays = [];
        checkChanges();
      };

      this.$onChanges = ({ regionId = {} }) => {
        if (!_.invoke(regionId, 'isFirstChange') && regionId.currentValue !== regionId.previousValue) {
          clearSelection();
        }
        checkChanges();
      };

      this.scheduleEventClicked = ({ id, duration, laborRate, perMoverHourAdjustmentAmount }) => {
        if (!id) return;

        if (this.onScheduleEventClick) {
          this.onScheduleEventClick({
            $id: id,
            $calenderQuoteId: this.calenderQuoteId,
            $duration: duration,
            $laborRate: laborRate,
            $perMoverHourAdjustmentAmount: perMoverHourAdjustmentAmount,
          });
        }
      };
    },
  ],
});

import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCar, faExclamationCircle, faHome, faLevelUpAlt, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { InputFormGroup, SelectFormGroup } from '@admin/components/fields';
import { Address__BuildingRestrictionsAnswerEnum } from '@admin/schema';
import { Address } from '@admin/types/address';

const BUILDING_TYPES = [
  'House',
  'Apartment',
  'Townhouse',
  'Studio',
  'Storage Facility / Warehouse',
  'Dorm / Campus Apartment',
  'Commercial',
  'Donation Center',
  'Other',
];

const OPENING_HOURS = [
  { name: '6 am', value: 6 },
  { name: '7 am', value: 7 },
  { name: '8 am', value: 8 },
  { name: '9 am', value: 9 },
  { name: '10 am', value: 10 },
  { name: '11 am', value: 11 },
];

const CLOSING_HOURS = [
  { name: '6 am', value: 6 },
  { name: '7 am', value: 7 },
  { name: '8 am', value: 8 },
  { name: '9 am', value: 9 },
  { name: '10 am', value: 10 },
  { name: '11 am', value: 11 },
  { name: '12 pm', value: 12 },
  { name: '1 pm', value: 13 },
  { name: '2 pm', value: 14 },
  { name: '3 pm', value: 15 },
  { name: '4 pm', value: 16 },
  { name: '5 pm', value: 17 },
  { name: '6 pm', value: 18 },
  { name: '7 pm', value: 19 },
];

const Fields: React.FC<{ fields: Address; onChange(address: Address): void }> = ({ fields, onChange }) => (
  <fieldset className="address-details-form form-horizontal">
    <div className="form-group">
      <label className="col-sm-1 control-label">
        <FontAwesomeIcon icon={faHome} />
      </label>
      <div className="col-sm-11">
        <SelectFormGroup
          value={fields.building_type ?? ''}
          onChange={(event) => onChange({ ...fields, building_type: event.target.value || null })}
          required={false}
          name="address_detail[building_type]"
        >
          <option value="">- Select Building Type -</option>
          {BUILDING_TYPES.map((value) => (
            <option key={value} value={value}>
              {value}
            </option>
          ))}
        </SelectFormGroup>
      </div>
    </div>
    {fields.building_type === 'House' && (
      <div>
        <div className="form-group">
          <label className="col-sm-1 control-label">
            <FontAwesomeIcon icon={faLevelUpAlt} />
          </label>
          <div className="col-sm-5">
            <div className="row">
              <div className="col-sm-8">
                <InputFormGroup
                  type="number"
                  placeholder="Number of stories"
                  min="0"
                  maxLength={3}
                  value={fields.stories ?? ''}
                  onChange={(event) => onChange({ ...fields, stories: Number(event.target.value) || null })}
                />
              </div>
              <label className="col-sm-4 control-label label-right">{fields.stories === 1 ? 'story' : 'stories'}</label>
            </div>
          </div>
          <div className="col-sm-5">
            <div className="row">
              <div className="col-sm-4">
                <InputFormGroup
                  type="number"
                  min="0"
                  maxLength={2}
                  value={fields.front_door_steps ?? ''}
                  onChange={(event) => onChange({ ...fields, front_door_steps: Number(event.target.value) || null })}
                />
              </div>
              <label className="col-sm-8 control-label label-right">steps in front</label>
            </div>
          </div>
        </div>
      </div>
    )}
    {fields.building_type !== 'House' && (
      <div>
        <div className="form-group">
          <label className="col-sm-1 control-label">
            <FontAwesomeIcon icon={faLevelUpAlt} />
          </label>
          <div className="col-sm-3">
            <div className="row">
              <div className="col-sm-8">
                <InputFormGroup
                  type="number"
                  min="0"
                  className="form-control"
                  maxLength={3}
                  value={fields.floor ?? ''}
                  onChange={(event) => onChange({ ...fields, floor: Number(event.target.value) || null })}
                />
              </div>
              <label className="col-sm-4 control-label label-right">
                {fields.floor && fields.floor > 1 ? 'floors' : 'floor'}
              </label>
            </div>
          </div>
          <div className="col-sm-5">
            <div className="row">
              <div className="col-sm-8">
                <InputFormGroup
                  type="number"
                  min="0"
                  className="form-control"
                  maxLength={3}
                  placeholder="Number of stories"
                  value={fields.stories ?? ''}
                  onChange={(event) => onChange({ ...fields, stories: Number(event.target.value) || null })}
                />
              </div>
              <label className="col-sm-4 control-label label-right">{fields.stories === 1 ? 'story' : 'stories'}</label>
            </div>
          </div>
        </div>
        <div className="form-group">
          <div className="checkbox col-sm-2 col-sm-offset-1 text-left">
            <label>
              <input
                type="checkbox"
                checked={!!fields.stairs}
                onChange={(event) => onChange({ ...fields, stairs: event.target.checked || false })}
              />
              Stairs
            </label>
          </div>
          <div className="checkbox col-sm-2 text-left">
            <label>
              <input
                type="checkbox"
                checked={!!fields.elevator || !!fields.service_elevator}
                onChange={(event) => onChange({ ...fields, elevator: event.target.checked || false })}
                disabled={!!fields.service_elevator}
              />
              Elevator
            </label>
          </div>
          <div className="checkbox col-sm-3 text-left">
            <label>
              <input
                type="checkbox"
                checked={!!fields.service_elevator}
                onChange={(event) =>
                  onChange({
                    ...fields,
                    elevator: event.target.checked || false,
                    service_elevator: event.target.checked || false,
                  })
                }
              />
              Service Elevator
            </label>
          </div>
        </div>
      </div>
    )}

    <div className="form-group">
      <label className="col-sm-1 control-label">
        <FontAwesomeIcon icon={faCar} />
      </label>
      <div className="col-sm-11">
        <div className="row">
          <div className="checkbox col-sm-2 text-left">
            <label>
              <input
                type="checkbox"
                checked={!!fields.parking_street}
                onChange={(event) => {
                  if (event.target.checked === false) {
                    onChange({
                      ...fields,
                      parking_street: false,
                      parking_permit: false,
                      parking_in_front: false,
                      parking_on_specific_street: null,
                    });
                  } else {
                    onChange({ ...fields, parking_street: true });
                  }
                }}
              />
              Street
            </label>
          </div>
          <div className="checkbox col-sm-2 text-left">
            <label>
              <input
                type="checkbox"
                checked={!!fields.parking_driveway}
                onChange={(event) => onChange({ ...fields, parking_driveway: event.target.checked || false })}
              />
              Driveway
            </label>
          </div>
          <div className="checkbox col-sm-1 text-left">
            <label>
              <input
                type="checkbox"
                checked={!!fields.parking_lot}
                onChange={(event) => onChange({ ...fields, parking_lot: event.target.checked || false })}
              />
              Lot
            </label>
          </div>
          <div className="checkbox col-sm-4 text-left">
            <label>
              <input
                type="checkbox"
                checked={!!fields.parking_alley}
                onChange={(event) => onChange({ ...fields, parking_alley: event.target.checked || false })}
              />
              Alley/Behind Building
            </label>
          </div>
          {fields.parking_street && (
            <div className="checkbox col-sm-3 text-left">
              <label>
                <input
                  type="checkbox"
                  checked={!!fields.parking_permit}
                  onChange={(event) => onChange({ ...fields, parking_permit: event.target.checked || false })}
                />
                Permits
              </label>
            </div>
          )}
        </div>
        {fields.parking_street && (
          <div className="row" ng-if="$ctrl.fields.parking_street">
            <div className="radio col-sm-4 text-left">
              <label>
                <input
                  type="radio"
                  checked={fields.parking_in_front === true}
                  onChange={(event) =>
                    onChange({
                      ...fields,
                      parking_in_front: event.target.checked || false,
                      parking_on_specific_street: null,
                    })
                  }
                />
                In front of building
              </label>
            </div>
            <div className="radio col-sm-3 text-left">
              <label>
                <input
                  type="radio"
                  checked={fields.parking_in_front === false}
                  onChange={() => onChange({ ...fields, parking_in_front: false })}
                />
                On Street:
              </label>
            </div>
            <div className="col-sm-5 text-left">
              <InputFormGroup
                type="text"
                placeholder="street name..."
                value={fields.parking_on_specific_street ?? ''}
                disabled={fields.parking_in_front !== false}
                required={fields.parking_in_front === false}
                onChange={(event) => onChange({ ...fields, parking_on_specific_street: event.target.value || null })}
              />
            </div>
          </div>
        )}

        <div className="row">
          <div className="col-sm-12 text-left">
            <InputFormGroup
              type="text"
              placeholder="Additional Parking Instructions"
              value={fields.parking_instructions ?? ''}
              onChange={(event) => onChange({ ...fields, parking_instructions: event.target.value || null })}
            />
          </div>
        </div>
      </div>
    </div>

    <div className="form-group">
      <label className="col-sm-1 control-label">
        <FontAwesomeIcon icon={faExclamationCircle} />
      </label>
      <div className="col-sm-11">
        <div className="row">
          <div className="checkbox col-sm-6 text-left">
            <label>
              <input
                type="checkbox"
                checked={!!fields.paperwork}
                onChange={(event) => {
                  onChange({ ...fields, paperwork: event.target.checked || false });
                }}
              />
              Paperwork / COI
            </label>
          </div>
          <div className="checkbox col-sm-6 text-left">
            <label>
              <input
                type="checkbox"
                checked={!!fields.steep}
                onChange={(event) => onChange({ ...fields, steep: event.target.checked || false })}
              />
              Steep Driveway or Road
            </label>
          </div>
          <div className="checkbox col-sm-6 text-left">
            <label>
              <input
                type="checkbox"
                checked={!!fields.gated}
                onChange={(event) => onChange({ ...fields, gated: event.target.checked || false })}
              />
              Gated Community
            </label>
          </div>
          <div className="checkbox col-sm-6 text-left">
            <label>
              <input
                type="checkbox"
                checked={!!fields.service_entrance}
                onChange={(event) => onChange({ ...fields, service_entrance: event.target.checked || false })}
              />
              Service Entrance
            </label>
          </div>
          <div className="checkbox col-sm-2 text-left">
            <label>
              <input
                type="checkbox"
                checked={!!fields.code}
                onChange={(event) => onChange({ ...fields, code: event.target.checked || false })}
              />
              Code
            </label>
          </div>
          <div className="col-sm-4 text-left">
            <InputFormGroup
              type="text"
              placeholder="(optional)"
              value={fields.code_value ?? ''}
              disabled={!fields.code}
              onChange={(event) => onChange({ ...fields, code_value: event.target.value || null })}
            />
          </div>
          <div className="checkbox col-sm-6 text-left">
            <label>
              <input
                type="checkbox"
                checked={!!fields.loading_dock}
                onChange={(event) => onChange({ ...fields, loading_dock: event.target.checked || false })}
              />
              Loading Dock
            </label>
          </div>
          <div className="checkbox col-sm-6 text-left">
            <label>
              <input
                type="checkbox"
                checked={!!fields.long_carry}
                onChange={(event) => onChange({ ...fields, long_carry: event.target.checked || false })}
              />
              More than a 5 minute walk?
            </label>
          </div>
        </div>
      </div>
    </div>

    <div className="form-group">
      <div className="col-sm-11 col-sm-offset-1">
        <div className="row">
          <div className="checkbox col-sm-6 text-left">
            <label>
              <input
                type="checkbox"
                checked={fields.building_restrictions_answer === Address__BuildingRestrictionsAnswerEnum.Yes}
                onChange={(event) => {
                  if (event.target.checked) {
                    onChange({ ...fields, building_restrictions_answer: Address__BuildingRestrictionsAnswerEnum.Yes });
                  } else {
                    onChange({
                      ...fields,
                      building_restrictions_answer: Address__BuildingRestrictionsAnswerEnum.No,
                      building_opening_hour: null,
                      building_closing_hour: null,
                    });
                  }
                }}
              />
              Building Restrictions
            </label>
          </div>
        </div>
        {fields.building_restrictions_answer === Address__BuildingRestrictionsAnswerEnum.Yes && (
          <div className="row">
            <div className="col-sm-5 label-left">
              <label className="dropdown-fill">
                Earliest possible start:
                <SelectFormGroup
                  value={fields.building_opening_hour ?? ''}
                  onChange={(event) =>
                    onChange({ ...fields, building_opening_hour: Number(event.target.value) || null })
                  }
                >
                  <option value="">- Opening Hour -</option>
                  {OPENING_HOURS.map((entry) => (
                    <option key={entry.value} value={entry.value}>
                      {entry.name}
                    </option>
                  ))}
                </SelectFormGroup>
              </label>
            </div>
            <div className="col-sm-5 label-left">
              <label className="dropdown-fill">
                Latest possible start:
                <SelectFormGroup
                  value={fields.building_closing_hour ?? ''}
                  onChange={(event) =>
                    onChange({ ...fields, building_closing_hour: Number(event.target.value) || null })
                  }
                >
                  <option value="">- Closing Hour -</option>
                  {CLOSING_HOURS.map((entry) => (
                    <option key={entry.value} value={entry.value}>
                      {entry.name}
                    </option>
                  ))}
                </SelectFormGroup>
              </label>
            </div>
          </div>
        )}
      </div>
    </div>

    <div className="form-group">
      <label className="col-sm-1 control-label">
        <FontAwesomeIcon icon={faQuestionCircle} />
      </label>
      <div className="col-sm-11">
        <InputFormGroup
          type="text"
          placeholder="Address Notes"
          value={fields.additional_notes ?? ''}
          onChange={(event) => onChange({ ...fields, additional_notes: event.target.value || null })}
        />
      </div>
    </div>
  </fieldset>
);

export { Fields };

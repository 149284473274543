import angular from 'angular';

const app = angular.module('app');

app.factory('ScrollService', [
  '$anchorScroll',
  '$stateParams',
  '$timeout',
  function ($anchorScroll, $stateParams, $timeout) {
    const service = {
      scroll: scroll,
      scrollTo: scrollTo,
    };

    return service;

    function scroll() {
      const $navbar = $('#navbar');
      $anchorScroll.yOffset = $navbar;

      if ($stateParams.scroll) {
        $timeout(() => {
          $anchorScroll($stateParams.scroll);
        }, 2000);
      }
    }

    function scrollTo(element) {
      $anchorScroll(element);
    }
  },
]);

(function () {
  angular.module('app').factory('AffiliateInvoice', [
    '$resource',
    'TransformBuilder',
    'MonthService',
    function ($resource, TransformBuilder, MonthService) {
      return $resource(
        '/marketing/affiliate_invoices/:id.json',
        {
          id: '@id',
        },
        {
          update: {
            method: 'PUT',
          },
          query: {
            method: 'GET',
            isArray: true,
            transformResponse: TransformBuilder.transformResponse(MonthService.indexbyOneToMonthDeserializer),
          },
        },
      );
    },
  ]);
})();

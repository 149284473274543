import { useField } from 'formik';
import * as React from 'react';
import { forwardRef } from 'react';

import { FieldFormGroupProps } from '@shared/components/fields/field_form_group';

import { TextAreaFormControlType, TextAreaFormControlProps, TextAreaFormControl } from '../text_area_form_control';

type TextAreaFormGroupType = TextAreaFormControlType;
type TextAreaFormGroupProps = TextAreaFormControlProps & FieldFormGroupProps & { name: string };

export const FormikTextAreaFormControl = forwardRef<TextAreaFormGroupType, TextAreaFormGroupProps>(
  ({ help, label, children, ...textarea }, ref) => {
    const [field] = useField(textarea.name);
    return <TextAreaFormControl ref={ref} {...field} {...textarea} />;
  },
);

(function () {
  const app = angular.module('app');

  app.factory('Shift', [
    '$resource',
    function ($resource) {
      return $resource(
        '/shifts/:date.json',
        { date: '@date' },
        {
          query: {
            method: 'GET',
            url: '/shifts.json',
            isArray: true,
          },
          create: {
            method: 'POST',
            url: '/shifts.json',
          },
          update: {
            method: 'PATCH',
          },
          destroy: {
            method: 'DELETE',
            url: '/shifts/:id.json',
            params: { id: '@id' },
          },
        },
      );
    },
  ]);
})();

import { debounce } from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { InputFormGroup, InputFormGroupProps } from '@admin/components/fields';

const DEFAULT_DELAY = 200; // ms

export const DebouncedInputFormGroup: React.FC<
  InputFormGroupProps & {
    delay?: number;
    initial?: string | undefined;
    onDebounce(query: string | undefined): void;
  }
> = ({ delay = DEFAULT_DELAY, initial, onDebounce, ...props }) => {
  const [value, setValue] = useState<string | undefined>(initial);
  const onDebounceRef = useRef(onDebounce);

  useEffect(() => {
    onDebounceRef.current = onDebounce;
  }, [onDebounce]);

  const onValue = useMemo(
    () =>
      debounce((query?: string | undefined) => {
        onDebounceRef.current(query);
      }, delay),
    [delay],
  );

  return (
    <InputFormGroup
      {...props}
      value={value ?? ''}
      onChange={(event) => {
        const query = event.target.value || undefined;
        setValue(query);
        onValue(query);
      }}
    />
  );
};

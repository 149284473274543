import React, { useEffect } from 'react';

import { Thumbnails } from '@admin/components/helpers/thumbnails';
import { Claim__IssueSelectionFragment, Claim__ResolutionKind } from '@admin/schema';
import { ListGroup } from '@shared/components/bootstrap';
import { Currency } from '@shared/components/helpers';

import { IResolutionEdits, selectionAmount } from './helpers';
import { compensated, denied } from '../helpers';
import { DISPLAYABLE_ISSUE } from '../contants';
import { ResolutionSummary } from '../resolution_summary';
import { validAmount } from './selection';

export const IssueSelection: React.FC<{
  selection: Claim__IssueSelectionFragment;
  edit: IResolutionEdits;
  setEdit(edit: IResolutionEdits): void;
}> = ({ selection, edit, setEdit }) => {
  const { id, compensationRequested, declaration, kind, photos, resolution } = selection;

  useEffect(() => {
    if (resolution?.amount) {
      return;
    }
    const amount = compensated(selection) ? declaration : 0;
    setEdit({ ...edit, [id]: amount ?? undefined });
  }, []);

  const amount = selectionAmount(edit, selection);

  const updateAmount = (amountString?: string) => {
    const parsedAmount = Number(amountString);
    const newAmount = !!amountString && validAmount(parsedAmount) ? parsedAmount : undefined;
    setEdit({ ...edit, [id]: newAmount });
  };

  return (
    <>
      <ListGroup.Item>
        <div className="row">
          <div className="col-sm-2">
            <strong>Issue Type</strong>
          </div>
          <div className="col-sm-2">
            <strong>Images</strong>
          </div>
          <div className="col-sm-2">
            <strong>Requesting Compensation</strong>
          </div>
          <div className="col-sm-1">
            <strong>Declared</strong>
          </div>
          <div className="col-sm-2">
            <strong>Classifications</strong>
          </div>
          <div className="col-sm-1">
            <strong>Approved</strong>
          </div>
          <div className="col-sm-2">
            <strong>Offered</strong>
          </div>
        </div>
      </ListGroup.Item>
      <ListGroup.Item>
        <div className="row">
          <div className="col-sm-2">{kind && DISPLAYABLE_ISSUE[kind]}</div>
          <div className="col-sm-2">
            <Thumbnails attachments={photos} condensed />
          </div>
          <div className="col-sm-2">{compensationRequested ? 'Yes' : 'No'}</div>
          <div className="col-sm-1">
            <Currency value={Number(declaration || 0)} />
          </div>
          <div className="col-sm-2">{!!resolution && <ResolutionSummary resolution={resolution} />}</div>
          <div className="col-sm-1">
            {!!resolution && (
              <Currency value={Number(resolution.kind === Claim__ResolutionKind.Compensated ? declaration : 0)} />
            )}
          </div>

          <div className="col-sm-2">
            {!!resolution && (
              <div className="input-group">
                <span className="input-group-addon">$</span>
                <input
                  name="amount"
                  disabled={denied(selection)}
                  value={amount ?? ''}
                  className="form-control"
                  type="number"
                  onChange={(event) => updateAmount(event.currentTarget.value)}
                />
              </div>
            )}
          </div>
        </div>
      </ListGroup.Item>
    </>
  );
};

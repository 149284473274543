import angular from 'angular';

const app = angular.module('app');

app.service('MapService', [
  'uiGmapGoogleMapApi',
  'uiGmapIsReady',
  '$q',
  function MapService(uiGmapGoogleMapApi, uiGmapIsReady, $q) {
    return {
      ready: () =>
        $q.all({
          mapInst: () => uiGmapIsReady.promise(1),
          googleMaps: uiGmapGoogleMapApi,
        }),
    };
  },
]);

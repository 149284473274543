import Hashcode from 'hashcode';

const app = angular.module('app');

app.component('abVariantLabel', {
  templateUrl: 'partials/components/ab_variant_label.html',
  bindings: {
    text: '<',
  },
  controller: function () {
    this.getLabelClasses = () => {
      const availableClasses = ['label-success', 'label-warning', 'label-mint', 'label-purple'];

      const selectedClasses = ['badge', 'badge-pill'];

      if (this.text && this.text === 'default') {
        selectedClasses.push('badge-primary');
      } else {
        const ix = Math.abs(Hashcode.hashCode().value(this.text)) % availableClasses.length;
        selectedClasses.push(availableClasses[ix]);
      }

      return selectedClasses;
    };
  },
});

import { GanttEvent, GanttLegend, GanttRow } from '@admin/components/helpers/gantt_chart/types';
import { DateTime } from 'luxon';
import {
  NightTransport__RouteTimelineFragment as RouteTimeline,
  NightTransport__RouteTimeline_TaskEventFragment as TaskEvent,
  NightTransport__RouteTimeline__TaskEvent__CategoryEnum as CategoryEnum,
} from '@admin/schema';

const eventNameMap: { [key: string]: string } = {
  [CategoryEnum.Clockin]: 'Clock In',
  [CategoryEnum.Drive]: 'Drive',
  [CategoryEnum.Resupply]: 'Re-Supply',
  [CategoryEnum.Carpool]: 'Carpool',
  [CategoryEnum.Deadtime]: 'Deadtime',
  [CategoryEnum.Break]: 'Break',
  [CategoryEnum.Clockout]: 'Clock Out',
};

const eventColorMap: { [key: string]: string } = {
  [CategoryEnum.Clockin]: '#CFBB93',
  [CategoryEnum.Drive]: '#9BCDBC',
  [CategoryEnum.Resupply]: '#E699C3',
  [CategoryEnum.Carpool]: '#E3B088',
  [CategoryEnum.Deadtime]: '#F7E19C',
  [CategoryEnum.Break]: '#D0CECE',
  [CategoryEnum.Clockout]: '#CFBB93',
};

const formatEvents = (events: TaskEvent[], tz: string): GanttEvent[] => {
  let prevLocation = '';
  return events.map((event) => {
    const details = [];
    if (event.dispatchID) {
      details.push(`Dispatch #${event.dispatchID}`);
    }
    if (event.vehicleName) {
      details.push(event.vehicleName);
    }
    const location = event.origin.name;
    let subtitle = '';
    if (event.category !== CategoryEnum.Clockin && location !== prevLocation) {
      subtitle = location;
      prevLocation = location;
    }

    return {
      name: eventNameMap[event.category],
      startTimestamp: DateTime.fromISO(event.startTimestamp).setZone(tz),
      endTimestamp: DateTime.fromISO(event.endTimestamp).setZone(tz),
      color: eventColorMap[event.category],
      details: details.join(', '),
      subtitle: subtitle,
    };
  });
};

export const formatRoutesForGantt = (routes: RouteTimeline[], tz: string): GanttRow[] =>
  routes.map((route) => ({
    title: route.driver.name,
    events: formatEvents(route.taskEvents, tz),
  }));

const EVENTS = [
  CategoryEnum.Clockin,
  CategoryEnum.Drive,
  CategoryEnum.Resupply,
  CategoryEnum.Carpool,
  CategoryEnum.Deadtime,
  CategoryEnum.Break,
  CategoryEnum.Clockout,
];

export const createLegendMapping = (): GanttLegend[] =>
  EVENTS.map((event) => ({
    name: eventNameMap[event],
    backgroundColor: eventColorMap[event],
  }));

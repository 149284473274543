import angular from 'angular';

angular.module('app').factory('EstimatedReturnMoverCount', [
  '$resource',
  ($resource) =>
    $resource(
      '/return_movers_estimations.json',
      {},
      {
        get: {
          method: 'POST',
          paramSerializer: 'NestedParamsSerializer',
        },
      },
    ),
]);

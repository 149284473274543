(() => {
  const app = angular.module('app');
  const TEMPLATE = `
    <div class="dialog">
      <div class="dialog-title">History</div>
      <react-component
        name="HistoriesTable"
        props="{
          id: ngDialogData.id,
          type: ngDialogData.type,
          timezone: ngDialogData.tz
        }"
      />
    </div>
  `;

  app.service('HistoryModalService', [
    'ngDialog',
    function (ngDialog) {
      this.present = (id, type, tz) => {
        ngDialog.open({
          template: TEMPLATE,
          plain: true,
          data: {
            id: id,
            type: type,
            tz: tz,
          },
        });
      };
    },
  ]);
})();

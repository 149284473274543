import { useState } from 'react';

const AUTOMATE_QA_META_SELECTOR = 'meta[name="automate-qa"]';

export const useQA = () => {
  const [qa] = useState(() => {
    const tag = document.querySelector(AUTOMATE_QA_META_SELECTOR);
    const content = tag?.getAttribute('content');

    return content === 'true';
  });

  return qa;
};

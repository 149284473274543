import { Timestamp } from '@admin/components/timestamp';
import { Dispatch__TaskKindEnum, DispatchVehicleSealImagesQuery } from '@admin/schema';
import { Lightbox } from '@shared/components/helpers/lightbox';
import React, { useState } from 'react';

interface IVehicleImage {
  claimer: string;
  vehicle: string;
  type: string;
  source: string;
  sizedImage: string;
  dateTime: string;
}

export const VehicleImages: React.FC<{ dispatch: DispatchVehicleSealImagesQuery['dispatch'] }> = ({ dispatch }) => {
  const [selection, setSelection] = useState<string | undefined>(undefined);
  const tz = dispatch.region.tz;

  const taskImages: IVehicleImage[] = dispatch.tasks
    .filter((t) => t.image && t.vehicle)
    .map(
      (t): IVehicleImage => ({
        claimer: t.user.name,
        vehicle: t.vehicle!.serialCode,
        type: t.kind === Dispatch__TaskKindEnum.Inbound ? 'Inbound Unseal Photo' : 'Outbound Claim Photo',
        source: t.image!.source,
        sizedImage: t.image!.size,
        dateTime: t.image!.createdAt,
      }),
    );

  const sealImages: IVehicleImage[] = dispatch.dispatchesVehicles
    .filter((dv) => dv.sealImage && dv.sealAt)
    .map(
      (dv): IVehicleImage => ({
        claimer: dv.user!.name,
        vehicle: dv.vehicle.serialCode,
        type: 'Outbound Seal Photo',
        source: dv.sealImage!.source,
        sizedImage: dv.sealImage!.size,
        dateTime: dv.sealAt!,
      }),
    );

  const images = taskImages
    .concat(sealImages)
    .sort((a, b): number => new Date(a.dateTime).getTime() - new Date(b.dateTime).getTime());

  return (
    <div className="grid">
      <div className="row">
        {images.map((image) => (
          <div key={image.source} className="entry col-xs-6 col-sm-4 col-md-3 col-lg-2">
            <figure className="brick-square">
              <a
                href="#"
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  setSelection(image.source);
                }}
              >
                <img className="location-image" src={image.sizedImage} />
              </a>
            </figure>
            <ul className="list-unstyled">
              <li>{image.type}</li>
              <li>Vehicle: {image.vehicle}</li>
              <li>Claimed By: {image.claimer}</li>
              <li>
                <Timestamp value={image.dateTime} timezone={tz} />
              </li>
            </ul>
          </div>
        ))}
        {selection && <Lightbox imageURL={selection} onClose={() => setSelection(undefined)} />}
      </div>
    </div>
  );
};

import React, { useState } from 'react';

import { Button, Modal, Text } from '@shared/components/bootstrap';
import { client } from '@admin/libraries/apollo';
import { Status, useReopenClaimMutation } from '@admin/schema';

export const ReopenClaimButton: React.FC<{
  claimID: string;
}> = ({ claimID }) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);

  const [reopenClaim, { loading }] = useReopenClaimMutation({ client });

  const closeModal = () => setShowModal(false);

  const onReopenClaim = async () => {
    const response = await reopenClaim({ variables: { claimID } });
    if (response.data?.reopenClaim.status === Status.Unprocessable) {
      setError(response.data.reopenClaim.error ?? undefined);
    }
  };

  return (
    <>
      <div className="text-right">
        <Button kind="warning" onClick={() => setShowModal(true)}>
          Reopen Claim
        </Button>
      </div>
      {showModal && (
        <Modal onClose={closeModal}>
          <Modal.Content>
            <Modal.Header>
              <Modal.Title>Re-open Claim</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-group form-inline">
                <p>Are you sure you would like to re-open this claim?</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              {error && (
                <Text tag="p" style="danger">
                  {error}
                </Text>
              )}
              <Button kind="default" onClick={closeModal}>
                Cancel
              </Button>{' '}
              <Button kind="info" loading={loading} onClick={onReopenClaim}>
                Re-open Claim
              </Button>
            </Modal.Footer>
          </Modal.Content>
        </Modal>
      )}
    </>
  );
};

/** @jsx jsx */

import * as React from 'react';

import { Download } from '@admin/components/audio/download';
import { Listen } from '@admin/components/audio/listen';
import { phonecallURL } from '@admin/config/routes';
import { jsx } from '@emotion/react';
import styled from '@emotion/styled';
import { Timestamp } from '@shared/components/helpers';
import { Duration } from 'luxon';
import { DetailedPhoneCallFragment } from '@admin/schema';

const PhoneCallContainer = styled.div`
  border-top: 2px #ddd solid;
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
  align-items: center;
  padding: 10px 5px;
`;

const ButtonContainer = styled.div`
  display: inline-block;
  margin: 0 10px;
`;

const ListenWithMargin = styled(Listen)`
  margin-right: 50px;
`;

const Label = styled.div`
  display: flex;
  font-weight: bold;
`;

const Info = styled.div`
  padding-left: 3px;
  font-weight: normal;
`;

export const PhoneCallHistory: React.FC<{ phoneCall: DetailedPhoneCallFragment }> = ({ phoneCall }) => {
  const lastConversation = phoneCall.conversations?.[phoneCall.conversations.length - 1]?.recording?.playbackURL || '';
  return (
    <PhoneCallContainer>
      <Timestamp value={phoneCall.createdAt} format={Timestamp.Format.DateTime} />
      <div>
        <Label>
          ID:{' '}
          <Info>
            <p>
              <a className="underline" href={phonecallURL({ id: phoneCall.id })}>
                {phoneCall.id}
              </a>
            </p>
          </Info>
        </Label>
        <Label>
          Agent: <Info>{phoneCall.latestConnectedUser?.name}</Info>
        </Label>
        <Label>
          Status: <Info>{phoneCall.status}</Info>
        </Label>
        <Label>
          Direction: <Info>{phoneCall.direction}</Info>
        </Label>
        <Label>
          Length: <Info>{Duration.fromObject({ seconds: phoneCall.duration }).toFormat('hh:mm:ss')}</Info>
        </Label>
      </div>
      <div>
        <Label>
          Call Reason: <Info>{phoneCall.touchTaxons?.[0]?.parentName}</Info>
        </Label>
        <Label>
          Call Subreason: <Info>{phoneCall.touchTaxons?.[0]?.name}</Info>
        </Label>
        <Label>
          Call Notes: <Info>{phoneCall.notes[0]?.body}</Info>
        </Label>
      </div>
      <ButtonContainer>
        {phoneCall.conversations && (
          <ListenWithMargin
            agent={phoneCall.latestConnectedUser?.name}
            src={lastConversation}
            pageID={phoneCall.id}
            date={phoneCall.startAt ?? undefined}
          />
        )}
        {phoneCall.conversations && <Download src={lastConversation} />}
      </ButtonContainer>
    </PhoneCallContainer>
  );
};

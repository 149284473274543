import angular from 'angular';
import { get } from 'lodash';

angular.module('app').constant('DispatcherConstants', {
  DEFAULT_ESTIMATED_DURATION: 3600,
  ESTIMATED_DURATION_UNITS: 'seconds',
  DISPATCHER_OFFSET_UNITS: 'seconds',
  TRAVEL_WINDOW_DURATION: 60,
  TRAVEL_WINDOW_UNITS: 'minutes',
  DEFAULT_TIMES_FROM: 6, // 6:00 AM
  DEFAULT_TIMES_TILL: 20, // 8:00 PM
  DISPATCH_OFFSET_INTERVAL: 1800, // 30 minutes
  ARRIVAL_WINDOW: get(window, 'clutter.appointment.arrival_window'),
  DEFAULT_WINDOW_SLOT_DURATION: 1800, // 30 minutes
  ARRIVAL_VALID_OFFSET: 30,
  BREAK_OFFSET: 270, // 4.5 hours
  BREAK_OFFSET_UNITS: 'm',
  BREAK_LENGTH: 30,
  BREAK_LENGTH_UNIT: 'm',
});

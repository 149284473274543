import styled from '@emotion/styled';
import React, { useState } from 'react';
import { startCase } from 'lodash';
import { DateTime } from 'luxon';

import { Panel } from '@admin/components/helpers/panel';
import { Timestamp } from '@admin/components/timestamp';
import { Text } from '@shared/components/bootstrap';
import { ILightboxCarouselInput, LightboxWithCarousel } from '@shared/components/helpers/lightbox';
import { AuditItemGroupQuery, OpsAudit__AuditFragment, OpsAudit__ImageFragment } from '@admin/schema';
import { accountItemURL } from '@admin/config/routes';

type ItemType = AuditItemGroupQuery['itemGroup']['items'][number];
type PartialItemType = AuditItemGroupQuery['itemGroup']['partialItems'][number];

const Image = styled.img`
  margin: 10px 0 10px 0;
`;

const Scrollable = styled.div`
  max-height: 80vh;
  overflow: hidden;
  overflow-y: scroll;
`;

export const SubResourcePanel: React.FC<{
  resource: ItemType | PartialItemType | undefined;
  groupCategory: string;
  subAudits: OpsAudit__AuditFragment[];
}> = ({ resource, groupCategory, subAudits }) => {
  const [selection, setSelection] = useState<number | undefined>(undefined);

  if (resource === undefined) {
    return null;
  }

  const auditImages = subAudits.find(
    (sa) => resource.__typename === sa.resourceType && sa.resourceID === resource.id,
  )?.images;
  const probableCategory = resource.category?.name || resource.partCategory?.name || resource.materialCategory?.name;
  const imageSizeArray: ILightboxCarouselInput[] | undefined = auditImages?.map((image) => ({ src: image.source }));

  return (
    <Panel>
      <Panel.Header>
        <Panel.Title>{`${startCase(resource.__typename)} - ${resource.id}`}</Panel.Title>
      </Panel.Header>
      <Panel.Body>
        <div className="row">
          <div className="col-md-9">
            <ul>
              {resource.__typename === 'Item' && (
                <li>
                  <strong>Barcode: </strong>
                  <a href={accountItemURL(resource.accountID, resource.id)} className="btn-link" target="_blank">
                    #{(resource as ItemType).barcode.value}
                  </a>
                </li>
              )}
              <li>
                <strong>Category: </strong>
                <span>
                  {probableCategory}{' '}
                  {(resource as PartialItemType).state
                    ? `does not exist on ${groupCategory}`
                    : `disassembled from ${groupCategory}`}
                </span>
              </li>
              <li>
                <strong>Included Parts: </strong>
                <span>{resource.includedParts.map((part) => part.category.name).join(', ')}</span>
              </li>
            </ul>
            <Scrollable>
              {auditImages &&
                auditImages
                  .sort(
                    (a: OpsAudit__ImageFragment, b: OpsAudit__ImageFragment) =>
                      DateTime.fromISO(a.createdAt).diff(DateTime.fromISO(b.createdAt)).milliseconds,
                  )
                  .map((image, index) => (
                    <div key={image.id} className="col-lg-4">
                      <Text tag="p" alignment="center">
                        {image.name ? `${image.name} - ` : null}Taken at:{' '}
                        <Timestamp value={image.createdAt} format="medium" />
                      </Text>
                      <figure>
                        <a
                          href="#"
                          onClick={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            setSelection(index);
                          }}
                        >
                          <Image className="brick-image" src={image.size} />
                        </a>
                      </figure>
                    </div>
                  ))}
              {selection != null && imageSizeArray && (
                <LightboxWithCarousel
                  imageInputs={imageSizeArray}
                  defaultActiveIndex={selection}
                  onClose={() => setSelection(undefined)}
                />
              )}
            </Scrollable>
          </div>
        </div>
      </Panel.Body>
    </Panel>
  );
};

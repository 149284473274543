import styled from '@emotion/styled';
import { InputFormGroup } from '@admin/components/fields/input_form_group';

export const InlineInputFormGroup = styled(InputFormGroup)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;

  & > label {
    margin-bottom: 0;
  }
`;

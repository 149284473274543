import { CancelButton } from '@admin/components/rate_adjustment/schedulings/cancel';
import React from 'react';

import {
  AccountRateAdjustmentSchedulingFragment as RateAdjustment,
  RateAdjustment__Scheduling__CancelReason,
  RateAdjustment__Scheduling__State as AdjustmentState,
  useAccountRateAgreementSummaryQuery,
} from '@admin/schema';

import { client } from '@admin/libraries/apollo';
import { Spinner } from '@admin/components/spinner';
import { Summary as RateAdjustmentSummary } from '@admin/components/rate_adjustment/schedulings/summary';

const RateAdjustmentList: React.FC<{
  rateAdjustments: RateAdjustment[];
  onCancel(): void;
}> = ({ rateAdjustments, onCancel }) => (
  <>
    {rateAdjustments.map((rateAdjustment, index) => (
      <span key={index}>
        {index ? '; ' : ''}
        <RateAdjustmentSummary scheduling={rateAdjustment} />{' '}
        {rateAdjustment.state === AdjustmentState.Pending && (
          <CancelButton scheduling={rateAdjustment} onCancel={onCancel} />
        )}
      </span>
    ))}
  </>
);

export const AccountRateAdjustments: React.FC<{
  accountID: string;
}> = ({ accountID }) => {
  const { data, loading, refetch } = useAccountRateAgreementSummaryQuery({ client, variables: { accountID } });

  if (loading) {
    return <Spinner />;
  }

  if (!data) {
    return null;
  }

  const rateAdjustments = data.account.rateAdjustments;

  const upcoming: RateAdjustment[] = [];
  const active: RateAdjustment[] = [];
  const waived: RateAdjustment[] = [];
  const reversed: RateAdjustment[] = [];

  rateAdjustments.forEach((adjustment) => {
    if (adjustment.state === AdjustmentState.Pending && adjustment.notified) {
      upcoming.push(adjustment);
    } else if (adjustment.state === AdjustmentState.Active) {
      active.push(adjustment);
    } else if (
      adjustment.state === AdjustmentState.Canceled &&
      adjustment.cancelReason === RateAdjustment__Scheduling__CancelReason.Waived
    ) {
      waived.push(adjustment);
    } else if (adjustment.state === AdjustmentState.Reversed) {
      reversed.push(adjustment);
    }
  });

  if (!upcoming.length && !active.length && !waived.length && !reversed.length) {
    return null;
  }

  return (
    <div>
      <strong>Rate Adjustments:</strong>
      <ul>
        {upcoming.length > 0 && (
          <li>
            Next: <RateAdjustmentList rateAdjustments={upcoming} onCancel={refetch} />
          </li>
        )}
        {active.length > 0 && (
          <li>
            Past: <RateAdjustmentList rateAdjustments={active} onCancel={refetch} />
          </li>
        )}
        {waived.length > 0 && (
          <li>
            Waived: <RateAdjustmentList rateAdjustments={waived} onCancel={refetch} />
          </li>
        )}
        {reversed.length > 0 && (
          <li>
            Reversed: <RateAdjustmentList rateAdjustments={reversed} onCancel={refetch} />
          </li>
        )}
      </ul>
    </div>
  );
};

import React from 'react';

import { SelectWithOther } from '@admin/components/helpers/select_with_other';
import { Claim__ResolutionKind } from '@admin/schema';

import { Toggle } from './toggle';
import { ClaimResolutionEdits, ClaimSelection } from '../types';

export const Resolution: React.FC<{
  disabled: boolean;
  declineReasons: string[];
  selection: ClaimSelection;
  resolutionEdits: ClaimResolutionEdits;
  onResolve(resolution: ClaimResolutionEdits): void;
}> = ({ disabled, selection, resolutionEdits, declineReasons, onResolve }) => {
  const { resolution } = selection;
  const resolutionKind = resolutionEdits[selection.id]?.kind ?? resolution?.kind;
  const resolutionReason = resolutionEdits[selection.id]?.reason ?? resolution?.reason ?? undefined;

  const onKindToggle = (kind: Claim__ResolutionKind) => {
    const reason = kind === Claim__ResolutionKind.Compensated ? undefined : resolutionReason;
    const newResolution = { ...resolutionEdits[selection.id], kind, reason };
    onResolve({ ...resolutionEdits, [selection.id]: newResolution });
  };

  const onReasonSelect = (reason: string) => {
    const newResolution = { ...resolutionEdits[selection.id], reason };
    onResolve({ ...resolutionEdits, [selection.id]: newResolution });
  };

  return (
    <div className="row">
      <div className="col-sm-4">
        <Toggle kind={resolutionKind} onClick={onKindToggle} />
      </div>
      <div className="col-sm-8">
        {resolutionKind === Claim__ResolutionKind.Denied && (
          <SelectWithOther
            options={declineReasons}
            disabled={disabled}
            onSelect={onReasonSelect}
            option={resolutionReason ?? undefined}
            placeholder="Select a reason"
          />
        )}
      </div>
    </div>
  );
};

import * as React from 'react';

import { Panel as Container } from '@admin/components/helpers/panel';
import { Titleize } from '@admin/components/helpers/titleize';
import { Spinner } from '@admin/components/spinner';
import { editAccountPricingURL } from '@admin/config/routes';
import { client } from '@admin/libraries/apollo';
import {
  Admin__PaymentPlan__SubscriptionFragment as PaymentPlanSubscription,
  SubscriptionFragment,
  useAccountSubscriptionsQuery,
  usePricingPolicyQuery,
} from '@admin/schema';
import { AnchorButton as Link, Table as BSTable, Text } from '@shared/components/bootstrap';
import { Currency, Timestamp } from '@shared/components/helpers';

const StorageSubscriptionRow: React.FC<{ subscription: SubscriptionFragment }> = ({ subscription }) => (
  <tr data-stripe-id={subscription.stripeID}>
    <td className="text-left">
      <strong>{subscription.quantity}</strong> × <strong>{subscription.name}</strong> (
      <Currency
        value={subscription.pricing.amount * subscription.quantity}
        currency={subscription.pricing.currency}
      />{' '}
      per month)
    </td>
    <td className="text-right">
      <span className="label label-default">
        <Titleize text={subscription.state} />
      </span>
    </td>
  </tr>
);

const PaymentPlanSubscriptionRow: React.FC<{ subscription: PaymentPlanSubscription }> = ({ subscription }) => {
  const { offer, amount, term, activatedAt, status } = subscription;

  return (
    <tr>
      <td className="text-left">
        <strong>Payment plan #{offer.id}</strong> (<Currency value={amount} /> per month for {term} months
        {activatedAt && (
          <>
            {' '}
            starting <Timestamp value={activatedAt ?? ''} format={Timestamp.Format.DateTime} />
          </>
        )}
        )
      </td>
      <td className="text-right">
        <span className="label label-default">
          <Titleize text={status ?? ''} />
        </span>
      </td>
    </tr>
  );
};

const Table: React.FC<{
  subscriptions: SubscriptionFragment[];
  paymentPlanSubscriptions: PaymentPlanSubscription[];
}> = ({ subscriptions, paymentPlanSubscriptions }) => (
  <BSTable striped responsive>
    <tbody>
      {subscriptions.map((subscription) => (
        <StorageSubscriptionRow key={subscription.id} subscription={subscription} />
      ))}
      {paymentPlanSubscriptions.map((subscription) => (
        <PaymentPlanSubscriptionRow key={subscription.id} subscription={subscription} />
      ))}
    </tbody>
  </BSTable>
);

export const SubscriptionsPanel: React.FC<{
  accountID: string;
}> = ({ accountID }) => {
  const { data } = useAccountSubscriptionsQuery({ variables: { accountID }, client });
  const { data: policy } = usePricingPolicyQuery({ client });
  const account = data && data.account;
  const permissions = policy?.permissions;

  if (!account) {
    return <Spinner />;
  }

  const { currentPaymentPlanSubscriptions, subscriptions } = account;

  return (
    <Container>
      <Container.Header>
        <Container.Title>Subscriptions</Container.Title>
      </Container.Header>
      <Container.Body>
        {subscriptions && (
          <Table subscriptions={subscriptions} paymentPlanSubscriptions={currentPaymentPlanSubscriptions} />
        )}
      </Container.Body>
      <Container.Footer>
        <Text alignment="right" tag="div">
          <Link kind="primary" href={editAccountPricingURL({ accountID })} disabled={!permissions?.modify}>
            Edit Pricing
          </Link>
        </Text>
      </Container.Footer>
    </Container>
  );
};

(function () {
  angular.module('app').service('CssHelperService', [
    function () {
      this.positiveOrNegativeIndicatorObject = function (oldValue, newValue) {
        const positiveHighlight = newValue < oldValue;
        const negativeHighlight = newValue > oldValue;
        return { negative: negativeHighlight, positive: positiveHighlight };
      };
    },
  ]);
})();

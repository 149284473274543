import React, { useState } from 'react';

import { client } from '@admin/libraries/apollo';
import {
  Claim__ResolutionFragment,
  Claim__ResolutionKind,
  Claim__VerifyItemSelectionInput,
  Maybe,
  Status,
  useVerifyClaimMutation,
} from '@admin/schema';
import { Button, ListGroup, Text } from '@shared/components/bootstrap';

import { IssueSelection } from './issue_selection';
import { ItemSelections } from './item_selections';
import { PropertySelection } from './property_selection';
import { IssueSelectionDescription } from '../issue_selection_description';
import { IClaimStepProps } from '../steps';
import {
  ClaimIssueKindEdits,
  ClaimItemEdits,
  ClaimKindEdits,
  ClaimResolutionEdit,
  ClaimResolutionEdits,
} from '../types';

const validResolution = (edits: ClaimResolutionEdit, savedResolution?: Maybe<Claim__ResolutionFragment>) => {
  const kind = edits?.kind || savedResolution?.kind;
  const reason = edits?.reason || savedResolution?.reason;
  return kind === Claim__ResolutionKind.Compensated || (kind === Claim__ResolutionKind.Denied && !!reason);
};

const buildIssueSelectionInput = (
  selectionID: string,
  issueKindEdits: ClaimIssueKindEdits,
  resolutionEdits: ClaimResolutionEdits,
) => ({
  id: selectionID,
  kind: issueKindEdits[selectionID],
  resolutionKind: resolutionEdits[selectionID]?.kind,
  resolutionReason: resolutionEdits[selectionID]?.reason,
});

export const buildItemSelectionInputs = (
  itemEdits: ClaimItemEdits,
  kindEdits: ClaimKindEdits,
  resolutionEdits: ClaimResolutionEdits,
) => {
  const inputs: { [selectionID: string]: Claim__VerifyItemSelectionInput } = {};
  const updateOrInitInput = (selectionID: string, changes: Partial<Claim__VerifyItemSelectionInput>) => {
    if (!inputs[selectionID]) {
      inputs[selectionID] = { id: selectionID };
    }

    inputs[selectionID] = { ...inputs[selectionID], ...changes };
  };

  Object.keys(itemEdits).forEach((selectionID) => {
    updateOrInitInput(selectionID, { itemID: itemEdits[selectionID] });
  });

  Object.keys(kindEdits).forEach((selectionID) => {
    updateOrInitInput(selectionID, { kind: kindEdits[selectionID] });
  });

  Object.keys(resolutionEdits).forEach((selectionID) => {
    updateOrInitInput(selectionID, {
      resolutionKind: resolutionEdits[selectionID]?.kind,
      resolutionReason: resolutionEdits[selectionID]?.reason,
    });
  });

  return Object.values(inputs);
};

const buildPropertySelectionInput = (selectionID: string, resolutionEdits: ClaimResolutionEdits) => ({
  id: selectionID,
  resolutionKind: resolutionEdits[selectionID]?.kind,
  resolutionReason: resolutionEdits[selectionID]?.reason,
});

export const Verify: React.FC<IClaimStepProps> = ({ claim, onNext }) => {
  const { issueSelection, itemSelections, propertySelection } = claim;

  const [itemEdits, setItemEdits] = useState<ClaimItemEdits>({});
  const [kindEdits, setKindEdits] = useState<ClaimKindEdits>({});
  const [issueKindEdits, setIssueKindEdits] = useState<ClaimIssueKindEdits>({});
  const [issueResolutionEdits, setIssueResolutionEdits] = useState<ClaimResolutionEdits>({});
  const [itemResolutionEdits, setItemResolutionEdits] = useState<ClaimResolutionEdits>({});
  const [propertyResolutionEdits, setPropertyResolutionEdits] = useState<ClaimResolutionEdits>({});
  const [error, setError] = useState<string | undefined>();

  const [verify, { loading }] = useVerifyClaimMutation({ client });

  const nextStepEnabled = () => {
    for (const selection of itemSelections) {
      const valid = validResolution(itemResolutionEdits[selection.id], selection?.resolution);
      if (!valid) {
        return false;
      }
    }
    return (
      !propertySelection ||
      validResolution(propertyResolutionEdits[propertySelection.id], propertySelection?.resolution)
    );
  };

  const onVerify = async () => {
    const itemSelectionInputs = buildItemSelectionInputs(itemEdits, kindEdits, itemResolutionEdits);
    const propertySelectionInput =
      propertySelection && propertyResolutionEdits[propertySelection.id]
        ? buildPropertySelectionInput(propertySelection.id, propertyResolutionEdits)
        : undefined;

    const issueSelectionInput =
      issueSelection && (issueKindEdits[issueSelection.id] || issueResolutionEdits[issueSelection.id])
        ? buildIssueSelectionInput(issueSelection.id, issueKindEdits, issueResolutionEdits)
        : undefined;

    if (!issueSelectionInput && !itemSelectionInputs.length && !propertySelectionInput) {
      setError(undefined);
      onNext(claim);
      return;
    }

    const response = await verify({
      variables: {
        input: {
          claimID: claim.id,
          issueSelection: issueSelectionInput,
          itemSelections: itemSelectionInputs,
          propertySelection: propertySelectionInput,
        },
      },
    });

    if (response.data) {
      if (response.data.verifyClaim.status === Status.Unprocessable) {
        setError(`Unable to verify claim: ${response.data.verifyClaim.error}`);
      } else if (response.data.verifyClaim.claim) {
        setError(undefined);
        onNext(response.data.verifyClaim.claim!);
      }
    }
  };

  return (
    <>
      <ListGroup id="selections-verify" className="list-group-flush">
        {issueSelection && (
          <IssueSelection
            selection={issueSelection}
            issueKindEdits={issueKindEdits}
            resolutionEdits={issueResolutionEdits}
            setIssueKindEdits={setIssueKindEdits}
            setResolutionEdits={setIssueResolutionEdits}
          />
        )}
        {!!itemSelections.length && (
          <ItemSelections
            itemSelections={itemSelections}
            itemEdits={itemEdits}
            kindEdits={kindEdits}
            resolutionEdits={itemResolutionEdits}
            setItemEdits={setItemEdits}
            setKindEdits={setKindEdits}
            setResolutionEdits={setItemResolutionEdits}
          />
        )}
        {propertySelection && (
          <PropertySelection
            propertySelection={propertySelection}
            resolutionEdits={propertyResolutionEdits}
            setResolutionEdits={setPropertyResolutionEdits}
          />
        )}
        {issueSelection && <IssueSelectionDescription issueSelection={issueSelection} />}
      </ListGroup>
      {error && (
        <div className="mar-top text-right">
          <Text style="danger">{error}</Text>
        </div>
      )}
      <div className="mar-top">
        <Button
          kind="primary"
          className="pull-right"
          disabled={!nextStepEnabled()}
          loading={loading}
          onClick={onVerify}
        >
          Save & Continue
        </Button>
      </div>
    </>
  );
};

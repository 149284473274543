import * as React from 'react';
import styled from '@emotion/styled';

import { COLORS } from '@root/colors';

import { GanttLegend } from './types';

const SegmentBand = styled.div<{ color: string; backgroundColor: string }>`
  color: ${({ color }) => color};
  background-color: ${({ backgroundColor }) => backgroundColor};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.15rem 0;
`;

const Grid = styled.div<{ length: number; percent: number }>`
  display: grid;
  grid-template-columns: ${({ length, percent }) => `repeat(${length}, ${percent}%)`};
  border: 1px solid ${COLORS.grayDarkest};
`;

const Content = styled.div`
  width: 80%;
`;

const Segment: React.FC<{ title: string; backgroundColor: string; color?: string }> = ({
  title,
  color = COLORS.white,
  backgroundColor,
}) => (
  <SegmentBand backgroundColor={backgroundColor} color={color}>
    {title}
  </SegmentBand>
);

export const Legend: React.FC<{ eventMapping: GanttLegend[] }> = ({ eventMapping }) => {
  const length = eventMapping.length;
  const percent = 100 / length;

  return (
    <Content>
      <Grid length={length} percent={percent}>
        {eventMapping.map((event) => (
          <Segment key={event.name} title={event.name} backgroundColor={event.backgroundColor} color={event.color} />
        ))}
      </Grid>
    </Content>
  );
};

import * as React from 'react';
import { useContext, useEffect } from 'react';

import { PricingGroupPricingEntryFragment } from '@admin/schema';

import { columnify } from '@admin/utils/columnify';
import { filteredPricingGroupEntries } from '@admin/utils/subscribe';

import { PricingSetContext } from './pricing_set_context';

const CURRENCY_LOCALE = 'en';
const CURRENCY_OPTIONS = {
  style: 'currency',
  currency: 'USD',
};

const name = (entry: PricingGroupPricingEntryFragment) =>
  columnify([
    entry.pricing.plan.name,
    `(${entry.pricing.plan.cuft})`,
    entry.pricing.amount.toLocaleString(CURRENCY_LOCALE, CURRENCY_OPTIONS),
  ]);

export const StorageSelect: React.FC<{
  storage?: PricingGroupPricingEntryFragment;
  rateGroupId?: string;
  onSelect(storage?: PricingGroupPricingEntryFragment): void;
}> = ({ storage, rateGroupId, onSelect }) => {
  const { storagePricingGroupEntries } = useContext(PricingSetContext);
  const entries = filteredPricingGroupEntries(storagePricingGroupEntries, rateGroupId);

  const selection = entries.find((entry) => !!storage && entry.pricing.plan.id === storage.pricing.plan.id);
  const value = selection ? selection.pricing.plan.id : undefined;

  useEffect(() => {
    if (selection !== storage) {
      onSelect(selection);
    }
  }, [selection]);

  const onChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    onSelect(entries.find((entry) => entry.pricing.plan.id === event.target.value));
  };

  return (
    <select id="storage-select" disabled={!entries.length} className="form-control" value={value} onChange={onChange}>
      <option disabled={!!selection}> - Storage Plan - </option>
      {entries.map((entry) => (
        <option key={entry.id} value={entry.pricing.plan.id}>
          {name(entry)}
        </option>
      ))}
    </select>
  );
};

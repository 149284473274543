(function () {
  angular.module('app').filter('notePreview', function () {
    return function (input) {
      if (input) {
        const matches = input.match(/(.+)/g);
        let output = matches[0];

        if (matches.length > 1) {
          output += '\n' + matches[1];
        }

        if (matches.length > 2) {
          output += '…';
        }

        return output;
      }
    };
  });
})();

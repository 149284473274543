(function () {
  angular.module('app').service('PhoneService', [
    'Phone',
    function (Phone) {
      this.plausibleNumber = function (phoneNumber) {
        const resource = Phone.plausible({ number: phoneNumber });

        const plausibleNumber = resource.$promise.then(function (data) {
          return data.plausible_number;
        });

        return plausibleNumber;
      };
    },
  ]);
})();

import * as React from 'react';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { COLORS } from '@root/colors';

const Warning = styled.strong`
  color: ${COLORS.red};
`;

export const TeachInWarning: React.FC<{}> = () => (
  <Warning>
    <FontAwesomeIcon icon="exclamation-triangle" /> Requires teach-in
  </Warning>
);

import * as React from 'react';

import { DiscountsResource } from '@admin/resources/discounts';
import { IDiscount } from '@admin/types/discount';
import Autosuggest from 'react-autosuggest';

const getSuggestionValue = (suggestion: IDiscount) => suggestion.name;

const renderSuggestion = (suggestion: IDiscount) => (
  <div>
    <h5>
      {suggestion.name}
      {suggestion.region && ` - ${suggestion.region.name}`}
    </h5>
    <h6>{suggestion.promo_type}</h6>
    {suggestion.promo_code && <h6>Promo Code: {suggestion.promo_code}</h6>}
  </div>
);

interface IDiscountSelector {
  referrers?: boolean;
  coupons?: boolean;
  vouchers?: boolean;
  initDiscount?: IDiscount;
  placeholder?: string;
  onDiscountSelected: (discount: IDiscount | null) => void;
}

const DiscountSelector: React.FC<IDiscountSelector> = ({
  onDiscountSelected,
  referrers = true,
  vouchers = true,
  coupons = true,
  placeholder = 'Type promo code or referrer name',
  initDiscount,
}) => {
  const [suggestions, setSuggestions] = React.useState<IDiscount[]>([]);
  const [noResults, setNoResults] = React.useState<boolean>(false);
  const [selectedSuggestion, setSelectedSuggestion] = React.useState<IDiscount | null>(null);
  const [currValue, setValue] = React.useState<string>(initDiscount ? initDiscount.name : '');

  const loadSuggestions = async (query: string) => {
    if (query.length > 2) {
      const newSuggestions = await DiscountsResource.search({ query, referrers, vouchers, coupons });
      setNoResults(newSuggestions.length === 0);
      setSuggestions(newSuggestions);
    } else {
      setNoResults(false);
      setSuggestions([]);
    }
  };

  const onSuggestionsFetchRequested = ({ value }: { value: string }) => loadSuggestions(value);

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const onChange = (_: React.FormEvent<HTMLSelectElement>, { newValue }: { newValue: string }) => {
    setValue(newValue);
    onDiscountSelected(null);
    setSelectedSuggestion(null);
  };

  const onSuggestionSelected = (_: React.FormEvent<HTMLSelectElement>, { suggestion }: { suggestion: IDiscount }) => {
    onDiscountSelected(suggestion);
    setSelectedSuggestion(suggestion);
  };

  const inputProps = {
    placeholder,
    value: currValue,
    onChange,
  };

  return (
    <div className={selectedSuggestion ? 'suggestion-selected' : ''}>
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        onSuggestionSelected={onSuggestionSelected}
        inputProps={inputProps}
      />
      {noResults && <div> No results </div>}
    </div>
  );
};

export { DiscountSelector };

import React from 'react';

import { useField } from 'formik';
import { PhoneFormControl } from '@admin/components/fields';
import { FormikFieldFormGroup } from '@shared/components/fields/formik/formik_field_form_group';
import { FieldFormGroupProps } from '@shared/components/fields/field_form_group';

type InputFormGroupProps = FieldFormGroupProps & { name: string };

export const FormikPhoneFormGroup: React.FC<InputFormGroupProps> = ({ name, ...props }) => {
  const [{ value }, _1, helpers] = useField(name);

  return (
    <FormikFieldFormGroup name={name} {...props}>
      <div>
        <PhoneFormControl
          id={props.id}
          name={name}
          value={value}
          onChange={(newValue) => {
            helpers.setValue(newValue);
          }}
        />
      </div>
    </FormikFieldFormGroup>
  );
};

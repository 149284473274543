import React, { FC } from 'react';
import { DateTime } from 'luxon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faExclamation, faSpinner } from '@fortawesome/free-solid-svg-icons';

import { client } from '@admin/libraries/apollo';

import {
  SendGrid__Suppression__Bounce,
  SendGrid__Suppression__Block,
  useSendGridStatusQuery,
  useSendGridSuppressionResetMutation,
} from '@admin/schema';

import { Alert, Button, Modal, ModalSize, Table, Text, Tooltip } from '@shared/components/bootstrap';
import { Box } from '@clutter/clean';

const SendGridSuppressionResetButton: FC<{
  disabled?: boolean;
  email: string;
  onReset(): void;
}> = ({ disabled, email, onReset }) => {
  const [reset, { loading, data }] = useSendGridSuppressionResetMutation({ client });
  const error = data?.result.error;
  const submitted = !!data;

  const onClick = async () => {
    if (data && !error) return;
    await reset({ variables: { email } });
    onReset();
  };

  const tooltip = submitted
    ? error ?? `The request to reset SendGrid bounces and blocks has been processed.`
    : undefined;

  return (
    <Tooltip title={tooltip}>
      <Button kind="danger" type="button" disabled={disabled || submitted} loading={loading} onClick={onClick}>
        {submitted && <FontAwesomeIcon icon={error ? faExclamation : faCheck} />} Reset SendGrid Bounces and Blocks
      </Button>
    </Tooltip>
  );
};

const SendGridSuppressionBlocksTable: FC<{
  blocks?: Array<Pick<SendGrid__Suppression__Block, 'created' | 'reason'>> | null;
  email: string;
}> = ({ blocks, email }) => (
  <>
    <Table>
      <caption>SendGrid Blocks</caption>
      <thead>
        <tr>
          <th className="text-left">When</th>
          <th className="text-left">Reason</th>
        </tr>
      </thead>
      <tbody>
        {blocks?.map((bounce, key) => (
          <tr key={key}>
            <td className="text-left">{DateTime.fromISO(bounce.created).toLocaleString(DateTime.DATETIME_MED)}</td>
            <td className="text-left">{bounce.reason}</td>
          </tr>
        ))}
      </tbody>
    </Table>
    {blocks?.length === 0 && <Alert style="info">No blocks found for '{email}'</Alert>}
  </>
);

const SendGridSuppressionBouncesTable: FC<{
  bounces?: Array<Pick<SendGrid__Suppression__Bounce, 'created' | 'reason'>> | null;
  email: string;
}> = ({ bounces, email }) => (
  <>
    <Table striped>
      <caption>SendGrid Bounces</caption>
      <thead>
        <tr>
          <th className="text-left">When</th>
          <th className="text-left">Reason</th>
        </tr>
      </thead>
      <tbody>
        {bounces?.map((bounce, key) => (
          <tr key={key}>
            <td className="text-left">{DateTime.fromISO(bounce.created).toLocaleString(DateTime.DATETIME_MED)}</td>
            <td className="text-left">{bounce.reason}</td>
          </tr>
        ))}
      </tbody>
    </Table>
    {bounces?.length === 0 && <Alert style="info">No bounces found for '{email}'</Alert>}
  </>
);

export const SendGridStatusModal: FC<{
  email: string;
  onClose(): void;
}> = ({ email, onClose }) => {
  const { data, loading, refetch } = useSendGridStatusQuery({ client, variables: { email } });
  const bounces = data?.bounces;
  const blocks = data?.blocks;

  return (
    <Modal onClose={onClose} size={ModalSize.Large}>
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>Status for '{email}'</Modal.Title>
          <Modal.Close close={onClose} />
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <Text alignment="center" tag="div">
              <FontAwesomeIcon icon={faSpinner} spin />
            </Text>
          ) : (
            <>
              <SendGridSuppressionBouncesTable bounces={bounces} email={email} />
              <SendGridSuppressionBlocksTable blocks={blocks} email={email} />
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Box.Flex gap="4px" justifyContent="end">
            <SendGridSuppressionResetButton email={email} onReset={refetch} disabled={loading} />
            <Button loading={loading} kind="primary" type="button" onClick={() => refetch()}>
              Refresh
            </Button>
          </Box.Flex>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};

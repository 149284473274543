(function () {
  const app = angular.module('app');

  const PRECISION_ALTER_CONSTANT = 10;

  app.directive('duration', [
    function () {
      return {
        restrict: 'E',
        scope: {
          from: '=',
          till: '=',
          value: '=',
          units: '=',
          format: '=',
          precision: '=',
        },
        template: '{{ duration() }}',
        controller: [
          '$scope',
          function ($scope) {
            $scope.duration = function () {
              let duration = null;
              if ($scope.value && $scope.units) {
                duration = moment.duration($scope.value, $scope.units);
              } else {
                duration = moment.duration(moment($scope.till) - moment($scope.from));
              }

              const prec = PRECISION_ALTER_CONSTANT ** ($scope.precision === undefined ? null : $scope.precision);

              switch ($scope.format) {
                case 'humanize':
                  return duration.humanize();
                case 'seconds':
                  return Math.round(duration.as('seconds') * prec) / prec + ' secs';
                case 'minutes':
                  return Math.round(duration.as('minutes') * prec) / prec + ' mins';
                case 'hours':
                  return Math.round(duration.as('hours') * prec) / prec + ' hours';
                default: {
                  let hours = String(Math.floor(duration.asHours()));
                  let minutes = String(duration.minutes());
                  while (hours.length < 2) {
                    hours = '0' + hours;
                  }
                  while (minutes.length < 2) {
                    minutes = '0' + minutes;
                  }
                  return hours + ':' + minutes;
                }
              }
            };
          },
        ],
      };
    },
  ]);
})();

import * as React from 'react';

import {
  GoogleMapsPlacesAutocompleteInput,
  IGoogleMapsPlace,
} from '@shared/components/helpers/google_maps_places_autocomplete_input';

const ALLOWED_GOOGLE_MAPS_PLACE_TYPES = ['street_address', 'premise', 'subpremise'];

const UNALLOWED_GOOGLE_MAPS_PLACE_TYPE_MESSAGE = 'Please select a street address.';
const INCOMPLETE_GOOGLE_MAPS_PLACE_MESSAGE = 'You must select a valid address from the dropdown.';

interface IPlace {
  street: string;
  city: string;
  state: string;
  country: string;
  zip: string;
  latitude: number;
  longitude: number;
}

export const AutocompleteFormControl: React.FC<{
  id?: string;
  disabled?: boolean;
  placeholder?: string;
  name?: string;
  query?: string;
  zip?: string;
  onQuery(query?: string): void;
  onChange(place: IPlace): void;
  onError(error?: string): void;
}> = ({ disabled, placeholder, name, id, query, onQuery, onChange, onError }) => {
  const onAutocomplete = (place: IGoogleMapsPlace) => {
    if (!place.types.some((type) => ALLOWED_GOOGLE_MAPS_PLACE_TYPES.some((allowed) => allowed === type))) {
      onError(UNALLOWED_GOOGLE_MAPS_PLACE_TYPE_MESSAGE);
      return;
    }
    if (!place.street || !place.city || !place.state || !place.country || !place.zip) {
      onError(INCOMPLETE_GOOGLE_MAPS_PLACE_MESSAGE);
      return;
    }

    onChange({
      street: place.street,
      city: place.city,
      state: place.state,
      country: place.country,
      zip: place.zip,
      latitude: place.latitude,
      longitude: place.longitude,
    });
  };

  return (
    <GoogleMapsPlacesAutocompleteInput
      id={id}
      value={query || ''}
      onChange={(event) => onQuery(event.target.value || undefined)}
      disabled={disabled}
      type="text"
      placeholder={placeholder}
      name={name}
      className="form-control"
      onAutocomplete={onAutocomplete}
    />
  );
};

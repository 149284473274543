import * as React from 'react';
import { useState } from 'react';

import { useDocumentEventListener } from '@shared/hooks';

import { History__SubjectInput, usePaginatedHistoryListQuery } from '@admin/schema';

import { Pagination } from '@admin/components/pagination';
import { Spinner } from '@admin/components/spinner';
import { client } from '@admin/libraries/apollo';
import { Row } from './table/row';

export const Table: React.FC<
  History__SubjectInput & {
    timezone?: string;
  }
> = ({ id, type, timezone }) => {
  const [page, setPage] = useState<number | undefined>();
  const variables = { subject: { id, type }, page };
  const { data, refetch } = usePaginatedHistoryListQuery({
    client,
    variables,
    fetchPolicy: 'no-cache',
  });
  const paginated = data?.paginated;
  const histories = paginated?.results;
  const pagination = paginated?.pagination;

  useDocumentEventListener('history:reload', (resource: { id: string; type: string }) => {
    if (resource.type === type && resource.id === id) {
      refetch();
    }
  });

  if (!histories) {
    return <Spinner />;
  }

  if (!histories.length) {
    return <p className="text-center">No history has been recorded yet.</p>;
  }

  return (
    <>
      <div className="table-responsive">
        <table className="table table-striped">
          <tbody>
            {histories.map((history, index) => (
              <Row key={index} history={history} timezone={timezone} />
            ))}
          </tbody>
        </table>
      </div>
      {pagination && <Pagination pagination={pagination} onPage={setPage} />}
    </>
  );
};

import template from '@admin/angular/templates/items_count.html';

(() => {
  const app = angular.module('app');

  app.component('itemsCount', {
    template,
    bindings: {
      items: '<',
    },
  });
})();

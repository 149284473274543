import { IInvoiceActionProps, IInvoiceActionFormProps } from '@admin/components/billing/invoices/types';
import { Roles } from '@admin/components/helpers/roles';
import { UserRole } from '@admin/types';
import { Currency } from '@shared/components/helpers';
import React, { useState } from 'react';

import { Button, Modal, Text } from '@shared/components/bootstrap';

import { InvoiceMarkAsPaidForm } from './mark_as_paid/form';

const MarkAsPaidModal: React.FC<IInvoiceActionFormProps> = (props) => (
  <Modal centered onClose={props.onCancel}>
    <Modal.Content>
      <Modal.Header>
        <Modal.Title>Make Other Payment On Invoice #{props.invoiceID}</Modal.Title>
        <Text tag="p">
          Amount Due: <Currency value={props.amountOwed} />
        </Text>
        <Modal.Close close={props.onCancel} />
      </Modal.Header>
      <Modal.Body>
        <InvoiceMarkAsPaidForm {...props} />
      </Modal.Body>
    </Modal.Content>
  </Modal>
);

export const MarkAsPaid: React.FC<IInvoiceActionProps> = ({ invoiceID, accountID, amountOwed, onSave }) => {
  const [showModal, setShowModal] = useState<boolean>(false);

  return (
    <Roles show={[UserRole.Collections, UserRole.SelfStorageAssociate]}>
      <Button kind="danger" onClick={() => setShowModal(true)}>
        Other Payment
      </Button>
      {showModal && (
        <MarkAsPaidModal
          onCancel={() => setShowModal(false)}
          onSave={(attemptID) => {
            setShowModal(false);
            onSave(attemptID, 'Invoice successfully paid offline');
          }}
          invoiceID={String(invoiceID)}
          accountID={String(accountID)}
          amountOwed={Number(amountOwed)}
        />
      )}
    </Roles>
  );
};

import angular from 'angular';

const REASONS = ['Return of Lost Item', 'Return of Damaged Item', 'QoS Pickup', 'Other'];

const template = `
  <label for="labor-exception" class="control-label col-sm-2">
    Waive Labor?
  </label>
  <div class="col-sm-1 col-auto">
    <input
      type="checkbox"
      id="labor-exception"
      ng-model="$ctrl.checked"
      ng-change="$ctrl.onCheck({ value: $ctrl.checked })"
    />
  </div>
  <div class="col-sm-9">
    <select
      class="form-control"
      ng-disabled="!$ctrl.checked"
      ng-options="reason for reason in $ctrl.REASONS"
      ng-model="$ctrl.reason"
      ng-change="$ctrl.onSelect({ value: $ctrl.reason })"
    >
      <option value="">-- Reason --</option>
    </select>
  </div>
`;

// ILaborException = { reason?: string }
angular.module('app').component('laborExceptionInput', {
  template,
  bindings: {
    laborException: '<',
    onCheck: '&',
    onSelect: '&',
  },
  controller: function ctrl() {
    this.REASONS = REASONS;
    this.$onChanges = () => {
      if (this.laborException) {
        this.checked = true;
        this.reason = this.laborException.reason;
      } else {
        this.checked = null;
        this.reason = null;
      }
    };
  },
});

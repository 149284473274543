(function () {
  const app = angular.module('app');

  app.constant('FacilityConstants', {
    ACTIVE_OPTIONS: [
      { name: 'Active', id: true },
      { name: 'Inactive', id: false },
    ],
  });
})();

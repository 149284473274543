export const truck =
  'M5.9200 0.0000H2.7200c-0.2651 0.0000 -0.4800 0.2149 -0.4800 0.4800v0.4800h-0.4412a0.4800 0.4800 0 0 0 -0.3394 0.1406l-0.9988 0.9988A0.4800 0.4800 0 0 0 0.3200 2.4388V3.5200h-0.0800c-0.1326 0.0000 -0.2400 0.1074 -0.2400 0.2400v0.1600c0.0000 0.1326 0.1074 0.2400 0.2400 0.2400h0.4000c0.0000 0.5302 0.4298 0.9600 0.9600 0.9600s0.9600 -0.4298 0.9600 -0.9600h1.2800c0.0000 0.5302 0.4298 0.9600 0.9600 0.9600s0.9600 -0.4298 0.9600 -0.9600h0.4000c0.1326 0.0000 0.2400 -0.1074 0.2400 -0.2400V0.4800c0.0000 -0.2651 -0.2149 -0.4800 -0.4800 -0.4800zM1.6000 4.6400c-0.2647 0.0000 -0.4800 -0.2153 -0.4800 -0.4800s0.2153 -0.4800 0.4800 -0.4800s0.4800 0.2153 0.4800 0.4800s-0.2153 0.4800 -0.4800 0.4800zm0.6400 -2.0800H0.8000v-0.1212L1.7988 1.4400H2.2400v1.1200zm2.5600 2.0800c-0.2647 0.0000 -0.4800 -0.2153 -0.4800 -0.4800s0.2153 -0.4800 0.4800 -0.4800s0.4800 0.2153 0.4800 0.4800s-0.2153 0.4800 -0.4800 0.4800z';
export const checkCircle =
  'M5.0400 2.5600c0.0000 1.3697 -1.1103 2.4800 -2.4800 2.4800S0.0800 3.9297 0.0800 2.5600S1.1903 0.0800 2.5600 0.0800s2.4800 1.1103 2.4800 2.4800zM2.2731 3.8731l1.8400 -1.8400c0.0625 -0.0625 0.0625 -0.1638 0.0000 -0.2263l-0.2263 -0.2263c-0.0625 -0.0625 -0.1638 -0.0625 -0.2263 0.0000L2.1600 3.0812l-0.7006 -0.7006c-0.0625 -0.0625 -0.1638 -0.0625 -0.2263 0.0000l-0.2263 0.2263c-0.0625 0.0625 -0.0625 0.1638 0.0000 0.2263l1.0400 1.0400c0.0625 0.0625 0.1638 0.0625 0.2263 0.0000z';
export const person =
  'M0.9600 0.0000c0.3535 0.0000 0.6400 0.2865 0.6400 0.6400s-0.2865 0.6400 -0.6400 0.6400s-0.6400 -0.2865 -0.6400 -0.6400S0.6065 0.0000 0.9600 0.0000m0.4800 1.4400h-0.1136c-0.2271 0.1044 -0.4959 0.1089 -0.7328 0.0000H0.4800c-0.2651 0.0000 -0.4800 0.2149 -0.4800 0.4800v1.3600c0.0000 0.1326 0.1074 0.2400 0.2400 0.2400h0.1600v1.3600c0.0000 0.1326 0.1074 0.2400 0.2400 0.2400h0.6400c0.1326 0.0000 0.2400 -0.1074 0.2400 -0.2400V3.5200h0.1600c0.1326 0.0000 0.2400 -0.1074 0.2400 -0.2400V1.9200c0.0000 -0.2651 -0.2149 -0.4800 -0.4800 -0.4800z';
export const house =
  'M4.8800 3.1270V4.5600c0.0000 0.1330 -0.1070 0.2400 -0.2400 0.2400H3.4800c-0.0660 0.0000 -0.1200 -0.0540 -0.1200 -0.1200V3.5600c0.0000 -0.0660 -0.0540 -0.1200 -0.1200 -0.1200h-0.7200c-0.0660 0.0000 -0.1200 0.0540 -0.1200 0.1200v1.1200c0.0000 0.0660 -0.0540 0.1200 -0.1200 0.1200H1.1200c-0.1330 0.0000 -0.2400 -0.1070 -0.2400 -0.2400V3.1270c0.0000 -0.0360 0.0160 -0.0700 0.0440 -0.0930l1.8800 -1.5480c0.0440 -0.0360 0.1080 -0.0360 0.1530 0.0000l1.8800 1.5480c0.0270 0.0230 0.0430 0.0570 0.0430 0.0930zm0.8360 -0.6090L4.8800 1.8290V0.4440c0.0000 -0.0660 -0.0540 -0.1200 -0.1200 -0.1200h-0.5600c-0.0660 0.0000 -0.1200 0.0540 -0.1200 0.1200V1.1700l-0.8950 -0.7370c-0.1770 -0.1460 -0.4330 -0.1460 -0.6100 0.0000L0.0440 2.5180c-0.0510 0.0420 -0.0580 0.1180 -0.0160 0.1690l0.2550 0.3100c0.0420 0.0510 0.1180 0.0580 0.1690 0.0160l2.3520 -1.9370c0.0440 -0.0360 0.1080 -0.0360 0.1530 0.0000l2.3520 1.9370c0.0510 0.0420 0.1270 0.0350 0.1690 -0.0160l0.2550 -0.3100c0.0420 -0.0520 0.0340 -0.1270 -0.0170 -0.1690z';
export const box =
  'M4.8800 1.2800H0.2400c-0.1326 0.0000 -0.2400 -0.1074 -0.2400 -0.2400V0.5600c0.0000 -0.1326 0.1074 -0.2400 0.2400 -0.2400h4.6400c0.1326 0.0000 0.2400 0.1074 0.2400 0.2400v0.4800c0.0000 0.1326 -0.1074 0.2400 -0.2400 0.2400zm-0.0800 3.2800V1.8400c0.0000 -0.1326 -0.1074 -0.2400 -0.2400 -0.2400H0.5600c-0.1326 0.0000 -0.2400 0.1074 -0.2400 0.2400v2.7200c0.0000 0.1326 0.1074 0.2400 0.2400 0.2400h4.0000c0.1326 0.0000 0.2400 -0.1074 0.2400 -0.2400zM3.0800 2.5600H2.0400c-0.0663 0.0000 -0.1200 -0.0537 -0.1200 -0.1200v-0.0800c0.0000 -0.0663 0.0537 -0.1200 0.1200 -0.1200h1.0400c0.0663 0.0000 0.1200 0.0537 0.1200 0.1200v0.0800c0.0000 0.0663 -0.0537 0.1200 -0.1200 0.1200z';
export const building =
  'M4.3600 4.8000h-0.2000V0.2400c0.0000 -0.1326 -0.1074 -0.2400 -0.2400 -0.2400H0.5600C0.4274 0.0000 0.3200 0.1074 0.3200 0.2400v4.5600H0.1200c-0.0663 0.0000 -0.1200 0.0537 -0.1200 0.1200v0.2000h4.4800v-0.2000c0.0000 -0.0663 -0.0537 -0.1200 -0.1200 -0.1200zM1.2800 0.7600c0.0000 -0.0663 0.0537 -0.1200 0.1200 -0.1200h0.4000c0.0663 0.0000 0.1200 0.0537 0.1200 0.1200v0.4000c0.0000 0.0663 -0.0537 0.1200 -0.1200 0.1200h-0.4000c-0.0663 0.0000 -0.1200 -0.0537 -0.1200 -0.1200V0.7600zm0.0000 0.9600c0.0000 -0.0663 0.0537 -0.1200 0.1200 -0.1200h0.4000c0.0663 0.0000 0.1200 0.0537 0.1200 0.1200v0.4000c0.0000 0.0663 -0.0537 0.1200 -0.1200 0.1200h-0.4000c-0.0663 0.0000 -0.1200 -0.0537 -0.1200 -0.1200v-0.4000zm0.5200 1.4800h-0.4000c-0.0663 0.0000 -0.1200 -0.0537 -0.1200 -0.1200v-0.4000c0.0000 -0.0663 0.0537 -0.1200 0.1200 -0.1200h0.4000c0.0663 0.0000 0.1200 0.0537 0.1200 0.1200v0.4000c0.0000 0.0663 -0.0537 0.1200 -0.1200 0.1200zm0.7600 1.6000h-0.6400v-0.8400c0.0000 -0.0663 0.0537 -0.1200 0.1200 -0.1200h0.4000c0.0663 0.0000 0.1200 0.0537 0.1200 0.1200v0.8400zm0.6400 -1.7200c0.0000 0.0663 -0.0537 0.1200 -0.1200 0.1200h-0.4000c-0.0663 0.0000 -0.1200 -0.0537 -0.1200 -0.1200v-0.4000c0.0000 -0.0663 0.0537 -0.1200 0.1200 -0.1200h0.4000c0.0663 0.0000 0.1200 0.0537 0.1200 0.1200v0.4000zm0.0000 -0.9600c0.0000 0.0663 -0.0537 0.1200 -0.1200 0.1200h-0.4000c-0.0663 0.0000 -0.1200 -0.0537 -0.1200 -0.1200v-0.4000c0.0000 -0.0663 0.0537 -0.1200 0.1200 -0.1200h0.4000c0.0663 0.0000 0.1200 0.0537 0.1200 0.1200v0.4000zm0.0000 -0.9600c0.0000 0.0663 -0.0537 0.1200 -0.1200 0.1200h-0.4000c-0.0663 0.0000 -0.1200 -0.0537 -0.1200 -0.1200V0.7600c0.0000 -0.0663 0.0537 -0.1200 0.1200 -0.1200h0.4000c0.0663 0.0000 0.1200 0.0537 0.1200 0.1200v0.4000z';
export const markerOutline =
  'M1.9200 0.0000C0.8596 0.0000 0.0000 0.8596 0.0000 1.9200c0.0000 0.7741 0.2697 0.9903 1.7227 3.0967c0.0953 0.1377 0.2993 0.1377 0.3947 0.0000C3.5703 2.9103 3.8400 2.6941 3.8400 1.9200C3.8400 0.8596 2.9804 0.0000 1.9200 0.0000zm0.0000 4.7393C0.5271 2.7249 0.3200 2.5649 0.3200 1.9200c0.0000 -0.4274 0.1664 -0.8292 0.4686 -1.1314S1.4926 0.3200 1.9200 0.3200s0.8292 0.1664 1.1314 0.4686S3.5200 1.4926 3.5200 1.9200c0.0000 0.6449 -0.2069 0.8047 -1.6000 2.8193z';

import styled from '@emotion/styled';
import * as React from 'react';
import { useRef, useState } from 'react';

import { useKey } from '@shared/hooks';

import { COLORS } from '@root/colors';

const Container = styled.div`
  position: relative;
`;

const Menu = styled.div`
  background: ${COLORS.white};
  box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.2);
  position: absolute;
  z-index: 1000;
  min-width: 160px;
  max-width: 320px;
`;

const SELECT_KEY = 'Enter';
const CANCEL_KEY = 'Escape';
const PREV_KEY = 'ArrowUp';
const NEXT_KEY = 'ArrowDown';

const Item = styled.button<{ highlight: boolean }>`
  display: block;
  border: 0;
  padding: 0;
  padding: 8px;
  width: 100%;
  text-align: inherit;

  color: ${({ highlight }) => (highlight ? COLORS.white : COLORS.black)};
  background: ${({ highlight }) => (highlight ? COLORS.blue : COLORS.white)};
`;

interface IProps<T> {
  selection?: T;
  search?: string;
  placeholder?: string;
  options?: T[];
  option: React.FC<{ option: T }>;
  onSearch(search?: string): void;
  onSelect(option: T): void;
}

export const AsyncFormControl = <T extends {}>({
  search,
  placeholder,
  options,
  onSearch,
  onSelect,
  option: Option,
}: IProps<T>) => {
  const menu = useRef<HTMLDivElement>(null);
  const input = useRef<HTMLInputElement>(null);
  const [highlight, setHighlight] = useState(0);
  const [focused, setFocused] = useState(false);

  const onStep = (step: number) => {
    if (focused && options?.length) {
      setHighlight((highlight + options.length + step) % options.length);
    }
  };

  useKey(NEXT_KEY, 'keydown', () => onStep(+1));
  useKey(PREV_KEY, 'keydown', () => onStep(-1));

  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);

  useKey(CANCEL_KEY, 'keydown', () => {
    if (focused) {
      input.current?.blur();
    }
  });

  useKey(SELECT_KEY, 'keydown', () => {
    if (focused) {
      input.current?.blur();
      const selected = options && options[highlight];
      if (selected) {
        onSelect(selected);
      }
    }
  });

  return (
    <Container>
      <input
        ref={input}
        type="text"
        className="form-control"
        placeholder={placeholder}
        value={search || ''}
        onChange={(event) => onSearch(event.target.value || undefined)}
        onFocus={onFocus}
        onBlur={(event) => {
          const target = event.relatedTarget as Node;
          // NOTE: do not dismiss the menu if the selecting something in the menu.
          if (!target || !menu.current?.contains(target)) {
            onBlur();
          }
        }}
      />
      {focused && options && (
        <Menu ref={menu}>
          {options.map((option, index) => (
            <Item
              key={index}
              highlight={index === highlight}
              onMouseOver={() => setHighlight(index)}
              onClick={() => {
                onSelect(option);
                onBlur();
              }}
            >
              <Option option={option} />
            </Item>
          ))}
        </Menu>
      )}
    </Container>
  );
};

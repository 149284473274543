import * as React from 'react';
import { useEffect, useState } from 'react';
import { FormContext, useForm, useFormContext } from 'react-hook-form';

import { AutocompleteFormControl } from '@admin/components/address/autocomplete_form_control';
import { AptSuiteFormGroup } from '@admin/components/address/apt_suite_form_group';
import { CityFormGroup } from '@admin/components/address/city_form_group';
import { StateFormGroup } from '@admin/components/address/state_form_group';
import { ZipFormGroup } from '@admin/components/address/zip_form_group';
import { FieldFormGroup, InputFormGroup, SelectFormGroup } from '@admin/components/fields';
import { Panel } from '@admin/components/helpers/panel';
import { Alert, Button, Text } from '@shared/components/bootstrap';

import { useAdapter, useElement, useTokenize, Element } from '@shared/components/stripe';

import { Billing__SourceFragment, useAccountAddressesQuery, useBillingSourceBuildMutation } from '@admin/schema';
import { client } from '@admin/libraries/apollo';

import { STRIPE_API_KEY } from '@admin/config/stripe';
import { ZIP_REGEX } from '@shared/config/zip';

const STRIPE_CARD_ELEMENT_OPTIONS: IStripeElementOptions = {
  hidePostalCode: true,
  style: {
    base: {
      color: '#555555',
      fontSize: '12px',
      fontFamily: 'inherit',
    },
  },
};

const DEFAULT_COUNTRY = 'US';

type AddressData = {
  aptsuite: string;
  street: string;
  city: string;
  state: string;
  zip: string;
};

type FormData = {
  name: string;
  addressID?: string;
  address?: AddressData;
};

const Address: React.FC = () => {
  const [autocomplete, setAutocomplete] = useState<{
    query?: string;
    error?: string;
  }>({});

  const { errors, register, unregister, setValue } = useFormContext<{ address: AddressData }>();

  useEffect(() => {
    register(
      { name: 'address.street' },
      {
        required: 'Please provide a street',
        validate: () => autocomplete.error,
      },
    );
    return () => {
      unregister('address.street');
    };
  }, [register, unregister, autocomplete.error]);

  return (
    <>
      <div className="row">
        <div className="col-md-9">
          <FieldFormGroup
            label="Street"
            id="address_street"
            has={errors?.address?.street ? 'error' : undefined}
            help={errors?.address?.street?.message}
          >
            <AutocompleteFormControl
              id="address_street"
              onChange={(place) => {
                setAutocomplete({ query: place.street });
                setValue('address.street', place.street, true);
                setValue('address.city', place.city, true);
                setValue('address.state', place.state, true);
                setValue('address.zip', place.zip, true);
              }}
              query={autocomplete.query}
              onQuery={(query) => {
                setValue('address.street', query, true);
                setAutocomplete({ ...autocomplete, query });
              }}
              onError={(error) => {
                setAutocomplete({ ...autocomplete, error });
              }}
            />
          </FieldFormGroup>
        </div>

        <div className="col-md-3">
          <AptSuiteFormGroup
            name="address.aptsuite"
            ref={register}
            has={errors?.address?.aptsuite ? 'error' : undefined}
            help={errors?.address?.aptsuite?.message}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <CityFormGroup
            name="address.city"
            ref={register({ required: 'Please provide a city' })}
            has={errors?.address?.city ? 'error' : undefined}
            help={errors?.address?.city?.message}
          />
        </div>

        <div className="col-md-3">
          <StateFormGroup
            name="address.state"
            ref={register({ required: 'Please provide a state' })}
            has={errors?.address?.state ? 'error' : undefined}
            help={errors?.address?.state?.message}
          />
        </div>

        <div className="col-md-3">
          <ZipFormGroup
            name="address.zip"
            ref={register({
              required: 'Please provide a ZIP',
              pattern: {
                value: ZIP_REGEX,
                message: 'Please provide a valid ZIP',
              },
            })}
            has={errors.address?.zip ? 'error' : undefined}
            help={errors.address?.zip?.message}
          />
        </div>
      </div>
    </>
  );
};

export const BillingSourceForm: React.FC<{
  accountID: string;
  onClose(): void;
  onSave(source: Billing__SourceFragment): void;
}> = ({ accountID, onClose, onSave }) => {
  const form = useForm<FormData>();

  const [save, { loading, data }] = useBillingSourceBuildMutation({ client });
  const addresses = useAccountAddressesQuery({ client, variables: { accountID } })?.data?.account.addresses;
  const adapter = useAdapter(STRIPE_API_KEY);
  const element = useElement(adapter, 'card', STRIPE_CARD_ELEMENT_OPTIONS);
  const tokenizer = useTokenize(adapter, element, { allow: 'prepaid' });

  const onSubmit = async (formData: FormData) => {
    if (loading || tokenizer.tokenizing) {
      return;
    }

    const address = formData.address ?? addresses!.find(({ id }) => id === formData.addressID);
    const token = await tokenizer.tokenize({
      name: formData.name,
      address_line1: address?.street ?? undefined,
      address_line2: address?.aptsuite ?? undefined,
      address_city: address?.city,
      address_state: address?.state,
      address_zip: address?.zip,
      address_country: address ? DEFAULT_COUNTRY : undefined,
    });

    if (!token) {
      return;
    }

    const response = await save({
      variables: {
        accountID,
        token: token.id,
      },
    });

    const source = response.data?.result.source;
    if (source) {
      onSave(source);
    }
  };

  return (
    <FormContext {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Panel>
          <Panel.Header>
            <Panel.Title>Add Billing Source</Panel.Title>
          </Panel.Header>
          <Panel.Body>
            {tokenizer.error && <Alert style="danger">{tokenizer.error}</Alert>}
            {data?.result.error && <Alert style="danger">{data?.result.error}</Alert>}

            <div className="row">
              <div className="col-md-6">
                <InputFormGroup
                  id="name"
                  name="name"
                  type="text"
                  label="Name on Card"
                  ref={form.register({ required: 'Please provide a "Name on Card".' })}
                  has={form.errors?.name ? 'error' : undefined}
                  help={form.errors?.name?.message}
                />

                <FieldFormGroup label="Payment Information">
                  <Element element={element} />
                </FieldFormGroup>
              </div>

              <div className="col-md-6">
                <SelectFormGroup id="address_id" label="Address" name="addressID" ref={form.register}>
                  <option value="">Enter new billing address</option>
                  {addresses?.map((address) => (
                    <option key={address.id} value={address.id}>
                      {address.street} {address.aptsuite}, {address.city}, {address.state}, {address.zip}
                    </option>
                  ))}
                </SelectFormGroup>

                {!form.watch('addressID') && <Address />}
              </div>
            </div>
          </Panel.Body>
          <Panel.Footer>
            <Text alignment="right" tag="div">
              <Button kind="danger" onClick={onClose} disabled={loading}>
                Cancel
              </Button>{' '}
              <Button type="submit" kind="primary" loading={loading}>
                Save
              </Button>
            </Text>
          </Panel.Footer>
        </Panel>
      </form>
    </FormContext>
  );
};

import React from 'react';
import { useFormContext } from 'react-hook-form';

import { SelfStorage__Policy__Kind } from '@admin/schema';
import { Field } from './field';

import { FormData } from './form/types';

type FieldsData = Pick<FormData, 'policy'>;

const OPTIONS = [
  {
    name: 'Auto Insurance',
    value: SelfStorage__Policy__Kind.AutoInsurance,
  },
  {
    name: 'Commercial',
    value: SelfStorage__Policy__Kind.Commercial,
  },
  {
    name: 'General Liability',
    value: SelfStorage__Policy__Kind.GeneralLiability,
  },
  {
    name: 'Homeowner',
    value: SelfStorage__Policy__Kind.Homeowner,
  },
  {
    name: 'Renter',
    value: SelfStorage__Policy__Kind.Renter,
  },
  {
    name: 'Umbrella Coverage',
    value: SelfStorage__Policy__Kind.UmbrellaCoverage,
  },
];

const AMOUNT_REGEX = /^\d+(\.\d{1,2})?$/;

export const Policy: React.FC = () => {
  const { register } = useFormContext<FieldsData>();

  return (
    <>
      <Field required id="policy-company" name="policy.company" label="Company">
        <input
          type="text"
          id="policy-company"
          name="policy.company"
          className="form-control"
          ref={register({ required: 'Please provide a company' })}
        />
      </Field>
      <Field required id="policy-number" name="policy.number" label="Policy #">
        <input
          type="text"
          id="policy-number"
          name="policy.number"
          className="form-control"
          ref={register({ required: 'Please provide a policy #' })}
        />
      </Field>
      <Field required id="policy-kind" name="policy.kind" label="Type">
        <select
          id="policy-kind"
          name="policy.kind"
          className="form-control"
          ref={register({ required: 'Please provide a type' })}
        >
          <option value=""> - Type - </option>
          {OPTIONS.map((option, key) => (
            <option key={key} value={option.value}>
              {option.name}
            </option>
          ))}
        </select>
      </Field>
      <Field required id="policy-amount" name="policy.amount" label="Coverage Amount">
        <input
          type="number"
          step="any"
          id="policy-amount"
          name="policy.amount"
          className="form-control"
          ref={register({
            required: 'Please provide a coverage amount',
            pattern: {
              value: AMOUNT_REGEX,
              message: 'Please check the formatting of the coverage amount (i.e. "9.99")',
            },
          })}
        />
      </Field>
      <div className="row">
        <div className="col-md-6">
          <Field required id="policy-from" name="policy.from" label="Coverage Start Date">
            <input
              type="date"
              id="policy-from"
              name="policy.from"
              className="form-control"
              ref={register({ required: 'Please provide a coverage start date' })}
            />
          </Field>
        </div>
        <div className="col-md-6">
          <Field required id="policy-till" name="policy.till" label="Coverage End Date">
            <input
              type="date"
              id="policy-till"
              name="policy.till"
              className="form-control"
              ref={register({ required: 'Please provide a coverage end date' })}
            />
          </Field>
        </div>
      </div>
    </>
  );
};

import { DateTime } from 'luxon';
import React from 'react';

import { Availability__LaborAdjustmentFragment } from '@admin/schema';
import { Table } from '@shared/components/bootstrap';

import { displayableText } from '../util';

export const Entries: React.FC<{
  availabilityLaborAdjustments: Availability__LaborAdjustmentFragment[];
  selectedOptions: string[];
  setSelectedOptions: React.Dispatch<React.SetStateAction<string[]>>;
}> = ({ availabilityLaborAdjustments, selectedOptions, setSelectedOptions }) => {
  const onChange = (id: string, checked: boolean) => {
    setSelectedOptions((current) => {
      if (checked) {
        return Array.from(new Set([...current, id]));
      } else {
        return current.filter((option) => option !== id);
      }
    });
  };

  return (
    <Table striped responsive>
      <thead>
        <tr>
          <th className="text-left" />
          <th className="text-left">Date</th>
          <th className="text-left">Region</th>
          <th className="text-left">Order Classification</th>
          <th className="text-left">Low Threshold %</th>
          <th className="text-left">High Threshold %</th>
          <th className="text-left">$/per mover hour adjustment</th>
          <th className="text-left">Added By</th>
        </tr>
      </thead>
      <tbody>
        {availabilityLaborAdjustments.map((entry) => (
          <tr key={entry.id}>
            <td>
              <input
                data-test-id={`toDelete${entry.id}`}
                checked={selectedOptions.includes(entry.id)}
                type="checkbox"
                onChange={(event) => onChange(entry.id, event.target.checked)}
              />
            </td>
            <td className="text-left">{DateTime.fromISO(entry.date).toLocaleString()}</td>
            <td className="text-left">{entry.region.name}</td>
            <td className="text-left">{displayableText(entry.orderClassification)}</td>
            <td className="text-left">{entry.lowThresholdPercentage}</td>
            <td className="text-left">{entry.highThresholdPercentage}</td>
            <td className="text-left">{entry.perMoverHourAdjustmentAmount}</td>
            <td className="text-left">{entry.creator.name}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

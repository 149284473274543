import { capitalize } from 'lodash';
import { DateTime } from 'luxon';
import * as React from 'react';

import { client } from '@admin/libraries/apollo';

import { useOrdersSelectorQuery, OrderTypeEnum } from '@admin/schema';

const formatDateTime = (datetime: string) => DateTime.fromISO(datetime).toLocaleString(DateTime.DATETIME_MED);

export const Selector: React.FC<{
  name: string;
  accountID: string;
  selectedID?: string;
  orderType?: OrderTypeEnum;
  onSelect(selectedID?: string): void;
}> = ({ name, accountID, selectedID, orderType, onSelect }) => {
  const { data, loading } = useOrdersSelectorQuery({ client, variables: { accountID } });
  const orders = data?.orders ?? [];
  const filteredOrders = orderType ? orders.filter((order) => order.type === orderType) : orders;

  return (
    <select
      name={name}
      className="form-control"
      disabled={loading}
      value={selectedID || ''}
      onChange={(event) => onSelect(event.target.value || undefined)}
    >
      <option disabled={!!selectedID} value="">
        - Order -
      </option>
      {filteredOrders.map((order) => (
        <option key={order.id} value={order.id}>
          #{order.id} - {formatDateTime(order.scheduled)} - {capitalize(order.type)} - {capitalize(order.state)}
        </option>
      ))}
    </select>
  );
};

import * as React from 'react';
import { useState } from 'react';

import { FieldFormGroup } from '@admin/components/fields';

import { AutocompleteFormControl } from './autocomplete_form_control';

interface IPlace {
  street: string;
  city: string;
  state: string;
  country: string;
  zip: string;
  latitude: number;
  longitude: number;
}

export const AutocompleteFormGroup: React.FC<{
  label?: string;
  disabled?: boolean;
  query?: string;
  onQuery(query?: string): void;
  onChange(place: IPlace): void;
}> = ({ disabled, label, query, onQuery, onChange }) => {
  const [error, setError] = useState<string | undefined>();
  const id = 'address-autocomplete';

  return (
    <FieldFormGroup has={error !== undefined ? 'error' : undefined} label={label} id={id} help={error}>
      <AutocompleteFormControl
        id={id}
        query={query}
        onQuery={onQuery}
        disabled={disabled}
        onChange={onChange}
        onError={setError}
      />
    </FieldFormGroup>
  );
};

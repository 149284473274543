import { pick } from 'lodash';
import React, { useEffect, useState } from 'react';

import { Panel } from '@admin/components/helpers/panel';
import { Pagination } from '@admin/components/pagination';
import { Spinner } from '@admin/components/spinner';
import { client } from '@admin/libraries/apollo';
import { usePaginatedClaimsQuery } from '@admin/schema';
import { Table } from '@shared/components/bootstrap';
import { Claim__FiltersInput } from '@admin/schema';
import { Timestamp } from '@shared/components/helpers';
import { useDebounce } from '@shared/hooks';

import { Filters } from './filters';

const formatDate = (time?: string) => (time ? <Timestamp value={time} format={Timestamp.Format.Date} /> : 'N/A');

export const Dashboard: React.FC<
  Claim__FiltersInput & {
    page?: number;
    onFilter(filters: Claim__FiltersInput & { page?: number }): void;
  }
> = ({ onFilter, ...defaults }) => {
  const [filters, setFilters] = useState<Claim__FiltersInput>(
    pick(defaults, ['status', 'ownerID', 'hasDamagedItem', 'hasDamagedProperty', 'hasLostItem', 'hasIssue', 'query']),
  );
  const [page, setPage] = useState<number | undefined>(defaults.page);

  const { data, loading, error } = usePaginatedClaimsQuery({
    client,
    variables: {
      page,
      filters: {
        status: filters.status,
        ownerID: filters.ownerID,
        hasIssue: filters.hasIssue,
        hasDamagedItem: filters.hasDamagedItem,
        hasDamagedProperty: filters.hasDamagedProperty,
        hasLostItem: filters.hasLostItem,
        query: useDebounce(filters.query),
      },
    },
  });

  useEffect(() => {
    onFilter({
      page,
      ...filters,
    });
  }, [filters, page]);

  const updateFilters = (filtered: Claim__FiltersInput) => {
    const newFilters = { ...filters, ...filtered };
    setFilters(newFilters);
    onFilter(newFilters);
  };

  const claims = data && data.paginatedClaims;

  if (error) {
    return <div>There was an error loading: {error.message}</div>;
  }

  return (
    <>
      <Filters filters={filters} onFilter={updateFilters} />
      {loading ? (
        <Spinner />
      ) : (
        <Panel>
          <Panel.Header>
            <Panel.Title>Claims</Panel.Title>
          </Panel.Header>
          <Panel.Body>
            <Table striped responsive>
              <thead>
                <tr>
                  <th>Number</th>
                  <th>Opened On</th>
                  <th>Customer Name</th>
                  <th>Kind</th>
                  <th>Owner</th>
                  <th>Status</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {claims &&
                  claims.results.map((claim) => (
                    <tr key={claim.number}>
                      <td>{claim.number}</td>
                      <td>{formatDate(claim.openedAt ?? undefined)}</td>
                      <td>{claim.account.customer.name}</td>
                      <td>{claim.kind}</td>
                      <td>{claim.owner ? claim.owner.name : 'Unassigned'}</td>
                      <td>{claim.status.toLowerCase()}</td>
                      <td>
                        <a className="btn btn-primary" href={`/claims/${claim.id}`}>
                          View
                        </a>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
            {claims && <Pagination onPage={setPage} pagination={claims.pagination} />}
          </Panel.Body>
        </Panel>
      )}
    </>
  );
};

(function () {
  const app = angular.module('app');

  app.controller('CalendarsShowController', [
    '$scope',
    'Calendar',
    'Region',
    '$location',
    'FiltersService',
    function ($scope, Calendar, Region, $location, FiltersService) {
      $scope.regions = Region.query();
      $scope.calendar = Calendar.get();
      $scope.filters = $location.search();

      $scope.showGroup = function (group) {
        return _.find(group.sections, function (section) {
          return section.total > 0 && (!$scope.filters.region_id || $scope.filters.region_id === section.region.id);
        });
      };

      $scope.filter = function (filters) {
        if (!filters) {
          filters = $scope.filters;
        }
        filters = FiltersService.cleanup(filters);
        $location.search(filters);
      };
    },
  ]);
})();

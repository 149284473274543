import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';

type Result<T> = {
  error?: Error;
  response?: AxiosResponse<T>;
};

/**
 * Usages:
 *
 * const [request] = useState(() => axios.get<User[]>('/users.json'));
 * const { loading, result } = useAxiosRequest(request);
 *
 * const [request, setRequest] = useState<Promise<AxiosResponse<User>> | undefined>();
 * useEffect(() => {
 *   setRequest(axios.get(`/users/${id}.json`));
 * }, [id])
 * const { loading, result } = useAxiosRequest(request);
 *
 * @param request must be stable
 */
export const useAxiosRequest = <T>(request?: Promise<AxiosResponse<T>>) => {
  const [result, setResult] = useState<Result<T> | undefined>();
  const [loading, setLoading] = useState<boolean | undefined>();

  useEffect(() => {
    setResult(undefined);
    setLoading(!!request);

    if (!request) return;

    let active = true;

    request.then((response) => {
      if (!active) return;
      setLoading(false);
      setResult({ response });
    });

    request.catch((error) => {
      if (!active) return;
      setLoading(false);
      setResult({ error });
    });

    return () => {
      active = false;
    };
  }, [request]);

  return { ...result, loading };
};

import React, { useState } from 'react';

import { client } from '@admin/libraries/apollo';
import { Hrs__ProfileDefault, useHrsProfileDefaultsQuery } from '@admin/schema';

import { Panel } from '@admin/components/helpers/panel';
import { Table, AnchorButton as Link } from '@shared/components/bootstrap';
import { Spinner } from '@admin/components/spinner';
import { UpsertModal } from './upsert_modal';

export const HRSProfileDefaultsList: React.FC = () => {
  const [showUpsertModal, setShowUpsertModal] = useState<boolean>(false);
  const [editingProfileDefault, setEditingProfileDefault] = useState<Hrs__ProfileDefault | undefined>(undefined);

  const { data, loading, refetch } = useHrsProfileDefaultsQuery({
    client,
  });

  return (
    <>
      <Panel>
        <Panel.Body>
          <Table responsive striped>
            <thead>
              <tr>
                <th className="col-sm-2">Job Code & Title</th>
                <th className="col-sm-2">Location</th>
                <th className="col-sm-2">Org Level 1</th>
                <th className="col-sm-2">Org Level 2</th>
                <th className="col-sm-2">Org Level 3</th>
                <th className="col-sm-2">Org Level 4</th>
                <th className="col-sm-2">Team</th>
                <th className="col-sm-1 text-right">
                  <a
                    className="btn btn-mint btn-labeled fa fa-plus"
                    onClick={() => {
                      setEditingProfileDefault(undefined);
                      setShowUpsertModal(true);
                    }}
                  >
                    Add
                  </a>
                </th>
              </tr>
            </thead>

            <tbody>
              {data?.hrsProfileDefaults.map((profileDefault) => (
                <tr key={profileDefault.id}>
                  <td className="col-sm-2">{profileDefault.businessTitle}</td>
                  <td className="col-sm-2">{profileDefault.location?.code ?? 'Any'}</td>
                  <td className="col-sm-2">{profileDefault.orgLevel1}</td>
                  <td className="col-sm-2">{profileDefault.orgLevel2}</td>
                  <td className="col-sm-2">{profileDefault.orgLevel3}</td>
                  <td className="col-sm-2">{profileDefault.orgLevel4}</td>
                  <td className="col-sm-2">{profileDefault.team?.name}</td>
                  <td className="col-sm-1 text-right">
                    <Link
                      kind="primary"
                      onClick={() => {
                        setEditingProfileDefault(profileDefault);
                        setShowUpsertModal(true);
                      }}
                    >
                      Edit
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {loading && <Spinner />}
        </Panel.Body>
      </Panel>
      {showUpsertModal && (
        <UpsertModal
          currentBusinessTitles={data?.hrsBusinessTitles ?? []}
          locationOptions={data?.hrsLocations ?? []}
          profileDefault={editingProfileDefault}
          teamOptions={data?.teams ?? []}
          closeModal={() => {
            setEditingProfileDefault(undefined);
            setShowUpsertModal(false);
            refetch();
          }}
        />
      )}
    </>
  );
};

import * as React from 'react';

import { Label } from '@admin/components/helpers/label';
import { usePhoneFormatter } from '@admin/hooks/use_phone_formatter';

export const Formatter: React.FC<{
  number: string | { number: string };
}> = ({ number }) => {
  const { formatted, international } = usePhoneFormatter(typeof number === 'string' ? number : number.number);

  return (
    <>
      <span>{formatted ?? number}</span>
      {international && (
        <>
          {' '}
          <Label kind="warning">International</Label>
        </>
      )}
    </>
  );
};

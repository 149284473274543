import { Titleize } from '@admin/components/helpers/titleize';
import { FormattedServiceType } from '@admin/components/orders/formatted_service_type';
import { Pagination } from '@admin/components/pagination';
import { Spinner } from '@admin/components/spinner';
import { client } from '@admin/libraries/apollo';
import { PaginatedOrdersQuery, OrderSubject, usePaginatedOrdersQuery } from '@admin/schema';
import { AnchorButton as Link, Table } from '@shared/components/bootstrap';
import { DateTime } from 'luxon';
import React, { useState } from 'react';

import { Scheduled } from './list/scheduled';
import { State } from './list/state';

export type PaginatedOrderResult = PaginatedOrdersQuery['paginated']['results'][0];

const Row: React.FC<{ order: PaginatedOrderResult }> = ({ order }) => {
  const tz = order.region.tz;
  const bookedAt = DateTime.fromISO(order.bookedAt).setZone(tz);

  return (
    <tr>
      <td className="text-left col-md-1 hidden-xs">#{order.number}</td>
      <td className="text-left col-md-2 col-xs-4">
        {order.moving && 'Moving'}
        {order.moving && order.storage && ' & '}
        {(order.storage || !order.moving) && <Titleize text={order.type} />}
        {' - '}
        {<Titleize text={order.subtype} />}
        {order.maintenance ? ' - Maintenance' : ''}
      </td>
      <td className="text-left col-md-1 col-xs-1">
        <FormattedServiceType order={order} />
      </td>
      <td className="text-left col-md-3 col-xs-4">
        <Scheduled order={order} />
      </td>
      <td className="text-left col-md-1 col-xs-1">
        <State order={order} />
      </td>
      <td className="text-left col-md-2 hidden-xs">{order.user?.name || '-'}</td>
      <td className="text-left col-md-3 hidden-xs">{bookedAt.toLocaleString(DateTime.DATETIME_FULL)}</td>
      <td className="text-right col-zero col-xs-3">
        <Link kind="primary" href={`/accounts/${order.accountID}/orders/${order.id}`}>
          Details
        </Link>
      </td>
    </tr>
  );
};

export const List: React.FC<{ id: string | number; type: OrderSubject }> = ({ id, type }) => {
  const [page, setPage] = useState<number | undefined>();
  const { data, loading } = usePaginatedOrdersQuery({ client, variables: { subject: { id: String(id), type }, page } });
  const paginated = data?.paginated;

  return (
    <>
      <Table responsive striped>
        <thead>
          <tr>
            <th className="text-left col-md-1 hidden-xs">Number</th>
            <th className="text-left col-md-2 col-xs-4">Kind</th>
            <th className="text-left col-md-1 col-xs-1">Service Type</th>
            <th className="text-left col-md-3 col-xs-4">Scheduled</th>
            <th className="text-left col-md-1 col-xs-1">State</th>
            <th className="text-left col-md-2 hidden-xs">Booked by</th>
            <th className="text-left col-md-3 hidden-xs">Booked at</th>
            <th className="text-right col-zero col-xs-3" />
          </tr>
        </thead>

        <tbody>
          {paginated?.results.map((order) => (
            <Row key={order.id} order={order} />
          ))}
        </tbody>
      </Table>
      {loading && <Spinner />}
      {paginated && <Pagination pagination={paginated.pagination} onPage={setPage} />}
    </>
  );
};

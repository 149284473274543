(function () {
  angular.module('app').factory('Warehouse', [
    '$resource',
    function ($resource) {
      return $resource(
        '/warehouses/:id.json',
        {
          id: '@id',
        },
        {
          query: {
            method: 'GET',
            isArray: true,
            cache: true,
          },
        },
      );
    },
  ]);
})();

import { CopyURL } from '@admin/components/helpers/copy_url';
import { BookedByToggle } from '@admin/components/orders/show/booked_by_toggle';
import { FormattedServiceType } from '@admin/components/orders/formatted_service_type';
import { LaborPolicy } from '@admin/components/orders/labor_policy';
import { MultiDayContext } from '@admin/components/orders/multi_day_context';
import { OrderTags } from '@admin/components/orders/order_tags';
import { State } from '@admin/components/orders/state';
import { WaitlistRequests } from '@admin/components/orders/waitlist_requests';
import { Formatter } from '@admin/components/phone/formatter';
import { Summary } from '@admin/components/subscriptions/summary';
import { dispatchURL, mapURL, orderURL } from '@admin/config/routes';
import { Customer, Order, OrderStateEnum, OrderSubtypeEnum, OrderTypeEnum, useOrderShowQuery } from '@admin/schema';
import { capitalize } from 'lodash';
import { DateTime } from 'luxon';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMap } from '@fortawesome/free-solid-svg-icons';
import { client } from '@admin/libraries/apollo';
import { titleize } from '@admin/components/helpers/titleize';
import { SkeletonLoader } from '@clutter/clean';
import { CouponToggle } from '@admin/components/orders/show/coupon_toggle';

function formatDate(date: string, format = DateTime.DATETIME_MED) {
  return DateTime.fromISO(date).toLocaleString(format);
}

type CustomerFragment = Pick<Customer, 'id' | 'phone' | 'name'>;

const CustomerNames: React.FC<{ customers: CustomerFragment[]; primaryCustomer: CustomerFragment }> = ({
  customers,
  primaryCustomer,
}) => (
  <>
    {[primaryCustomer.name]
      .concat(
        customers
          .filter((c) => c.id !== primaryCustomer.id)
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((c) => c.name),
      )
      .join(', ')}
  </>
);

const orderKindDescription = (order: Pick<Order, 'moving' | 'storage' | 'type' | 'subtype' | 'maintenance'>) => {
  let description = '';
  if (order.moving) description += 'Moving';
  if (order.moving && order.storage) description += ' & Pickup';
  if (
    !order.moving &&
    order.storage &&
    order.type !== OrderTypeEnum.SelfStorageShuttle &&
    order.type !== OrderTypeEnum.RetailDelivery &&
    order.type !== OrderTypeEnum.Disposal
  )
    description += titleize(order.type);
  if (order.type === OrderTypeEnum.TaskOrder) description += 'Task Order';
  if (order.type === OrderTypeEnum.Disposal) description += 'Disposal';
  if (order.type === OrderTypeEnum.SelfStorageShuttle) description += 'Self-Storage Shuttle';
  if (order.type === OrderTypeEnum.RetailDelivery) description += 'Delivery';
  if (order.subtype !== OrderSubtypeEnum.None) description += ' - ' + titleize(order.subtype.toLocaleLowerCase());
  if (order.maintenance) description += ' - Maintenance';

  return description;
};

export const OrderSummaryFields: React.FC<{
  orderID: string;
}> = ({ orderID }) => {
  const { data: { order } = {}, error } = useOrderShowQuery({ client, variables: { id: orderID! }, skip: !orderID });

  if (error) throw error;
  if (!order) return <SkeletonLoader height="320px" />;

  const account = order.account;

  const scheduledDateTime = DateTime.fromISO(order.scheduled).setZone(order.region.tz);
  const date = scheduledDateTime.toFormat('MMM d, y');

  return (
    <>
      <p className="position-relative">
        <strong>ID:</strong> {order.id}
        <CopyURL />
        {order.dispatch?.id && (
          <a
            href={mapURL({ orderID: order.id, accountID: account.id })}
            className="position-absolute"
            style={{ marginLeft: '42px;' }}
            target="_blank"
          >
            <FontAwesomeIcon icon={faMap} />
          </a>
        )}
      </p>
      <p>
        <strong>Kind:</strong> {orderKindDescription(order)} <OrderTags id={order.id} />
      </p>
      <p>
        <strong>Service Type:</strong> <FormattedServiceType order={order} />
      </p>
      <p>
        <strong>Labor:</strong>
        {order.id && (
          <>
            {' '}
            <LaborPolicy orderId={order.id} />
          </>
        )}
      </p>
      {!order.parent && !order.predecessor?.id && (
        <p>
          <strong>Coupon:</strong> <CouponToggle order={order} />
        </p>
      )}
      {account.currentStorageSubscription && (
        <p>
          <strong>Current Plan:</strong>
          <Summary subscriptions={[account.currentStorageSubscription]} showCost />
        </p>
      )}
      <p>
        <strong>{order.dropOffAppointment ? 'Internal Transfer: ' : 'Scheduled: '}</strong>
        {order.state === OrderStateEnum.Pending ? (
          'N/A'
        ) : (
          <>
            {date}, {order.formattedArrivalWindow}
          </>
        )}
      </p>
      {order.dropOffAppointment && (
        <p>
          <strong>Customer Arrival:</strong>
          <span>{DateTime.fromISO(order.dropOffAppointment.expectedAt).toLocaleString(DateTime.DATETIME_MED)}</span>
        </p>
      )}
      <p>
        <WaitlistRequests orderID={order.id} />
      </p>
      <p>
        <strong>State:</strong> <State state={order.state} />
        {order.state === OrderStateEnum.Canceled && order.cancelReason && ' - ' + order.cancelReason.kind}
      </p>
      <p>
        <strong>Movers:</strong> {order.movers}
        {order.dispatch && (
          <>
            {` - `}
            <a href={dispatchURL({ id: order.dispatch.id })}>
              {order.dispatch.assignments
                .map((assignment) => `${assignment.user.name} (${assignment.role})`)
                .join(', ')}
            </a>
          </>
        )}
      </p>
      {order.flaggedAt && (
        <p>
          <strong>Flagged:</strong>
          {formatDate(order.flaggedAt)}
        </p>
      )}
      {order.review && (
        <p>
          <strong>Rating:</strong> <span className="label label-default">{capitalize(order.review.rating)}</span>{' '}
          <span>
            on {formatDate(order.review.updatedAt || order.review.createdAt)}{' '}
            {order.review.comments && ` - "${order.review.comments}"`}
          </span>
        </p>
      )}
      {order.eta && (
        <p>
          <strong>ETA:</strong> <span>{formatDate(order.eta)}</span>
        </p>
      )}
      {order.shipmentValuationDeclaration && (
        <p>
          <strong>Shipment Valuation:</strong> <span>{order.shipmentValuationDeclaration.description}</span>
        </p>
      )}
      {order.movingOperation && (
        <p>
          <strong>Travel Time Multiplier:</strong>{' '}
          <span>{order.movingOperation.quote?.movingConfiguration?.travelRateMultiplier}</span>
        </p>
      )}
      {order.parent && order.type === OrderTypeEnum.Restock && (
        <p>
          <strong>Restock of Order: </strong>
          <a href={orderURL({ id: order.parent.id, account: { id: account.id } })} className="underlined_link">
            {order.parent.number}
          </a>
        </p>
      )}
      {order.parent && order.type !== OrderTypeEnum.Restock && (
        <p>
          <strong>Attached to Parent Order: </strong>
          <a href={orderURL({ id: order.parent.id, account: { id: account.id } })} className="underlined_link">
            #{order.parent.number}
          </a>
        </p>
      )}
      {order.children.map((child) => (
        <p key={child.id}>
          <strong>Attached to Child Order: </strong>
          <a href={orderURL({ id: child.id, account: { id: account.id } })} className="underlined_link">
            #{child.number}
          </a>
        </p>
      ))}
      <MultiDayContext
        orderID={order.id}
        goToOrder={({ id, accountID }) => (window.location.pathname = orderURL({ id, account: { id: accountID } }))}
      />
      <p>
        <strong>Customer(s):</strong> <CustomerNames customers={account.customers} primaryCustomer={account.customer} />
      </p>
      <p>
        <strong>Phone:</strong>
        {account.customer.phone ? <Formatter number={account.customer.phone} /> : 'N/A'}
      </p>
      <BookedByToggle order={order} />
    </>
  );
};

import { intersection } from 'lodash';
import * as React from 'react';

import { ROLES } from '@admin/config/roles';
import { UserRole } from '@admin/types';

export const Roles: React.FC<{
  show?: UserRole[];
  hide?: UserRole[];
}> = ({ show, hide, children }) => {
  if (show !== undefined && intersection(ROLES, show).length === 0) {
    return null;
  }
  if (hide !== undefined && intersection(ROLES, hide).length !== 0) {
    return null;
  }
  return <>{children}</>;
};

(function () {
  const app = angular.module('app');

  app.component('itemPackagingFields', {
    templateUrl: 'partials/components/item_packaging_fields.html',
    bindings: {
      item: '=',
    },
    controller: [
      function () {
        const self = this;
        self.reset = function () {
          self.item.material_category_id = null;
          self.item.part_category_id = null;
        };
      },
    ],
  });
})();

import { DateTime } from 'luxon';
import React from 'react';

import { usePredecessorOrderSelectorQuery, Order, OrderTypeEnum, OrderSubtypeEnum } from '@admin/schema';

import { client } from '@admin/libraries/apollo';

const FROM_ISO_OPTIONS = { setZone: true };

const OrderOption: React.FC<{
  order: Pick<Order, 'id' | 'scheduled' | 'type' | 'subtype' | 'number'>;
}> = ({ order }) => {
  const { id, number } = order;
  const scheduled = DateTime.fromISO(order.scheduled, FROM_ISO_OPTIONS);
  const type = order.type.toLowerCase();
  const subtype = order.subtype.toLowerCase();

  const disabled =
    order.type !== OrderTypeEnum.Move &&
    (order.type !== OrderTypeEnum.Pickup || order.subtype !== OrderSubtypeEnum.Onboarding);

  return (
    <option disabled={disabled} value={id}>
      {scheduled.toLocaleString(DateTime.DATETIME_FULL)} - {type} - {subtype} - {number}
      {disabled && ' - only moves or onboardings may be selected'}
    </option>
  );
};

export const PredecessorOrderSelector: React.FC<{
  disabled?: boolean;
  accountID: number | string;
  orderID: number | string;
  predecessorID: number | string;
  onSelectPredecessorID(predecessorID?: number | string): void;
}> = ({ disabled, accountID, orderID, predecessorID, onSelectPredecessorID }) => {
  const { data } = usePredecessorOrderSelectorQuery({ client, variables: { accountID: String(accountID) } });

  const onChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (orderID === predecessorID) return;
    onSelectPredecessorID(event.target.value ? event.target.value : undefined);
  };

  const orders = data?.orders.filter((order) => order.id !== String(orderID));

  return (
    <select className="form-control" onChange={onChange} value={predecessorID ?? ''} disabled={disabled}>
      <option value=""> -- Parent Order -- </option>
      {orders?.map((order) => (
        <OrderOption key={order.id} order={order} />
      ))}
    </select>
  );
};

import axios from 'axios';
import * as React from 'react';

import { ticketURL } from '@admin/config/routes';
import { ITicketable } from '@admin/types';
import { Modal } from '@shared/components/bootstrap';
import { Area, BusinessUnit, TechSupportForm } from './form';

interface ICreateTicketProps {
  ticketable: ITicketable;
}

interface ICreateTicketState {
  show: boolean;
  priority?: number;
  description?: string;
  explanation?: string;
  notify: boolean;
  error?: Error;
  loading: boolean;
  ticketId?: number;
  businessUnit?: BusinessUnit;
  area?: Area;
}

class CreateTicket extends React.Component<ICreateTicketProps, ICreateTicketState> {
  public state: ICreateTicketState = {
    show: false,
    priority: undefined,
    description: undefined,
    notify: true,
    loading: false,
  };

  public render() {
    const { ticketable } = this.props;
    const { ticketId } = this.state;
    const { close, show, hide, onSubmit, form, success, formButtons } = this;
    return (
      <>
        <button type="button" className="btn btn-warning" onClick={show}>
          Tech Support <i className="fa fa-wrench" />
        </button>
        {this.state.show && (
          <Modal onClose={hide}>
            <Modal.Content>
              <form onSubmit={onSubmit}>
                <Modal.Header>
                  <Modal.Title id="contained-modal-title-lg">
                    Tech Support Request for {ticketable.displayName}
                  </Modal.Title>
                  <Modal.Close close={hide} />
                </Modal.Header>
                <Modal.Body>
                  {!ticketId && form()}
                  {ticketId && success(ticketId)}
                </Modal.Body>
                <Modal.Footer>
                  {!ticketId && formButtons()}
                  {ticketId && close()}
                </Modal.Footer>
              </form>
            </Modal.Content>
          </Modal>
        )}
      </>
    );
  }

  private form = (): React.ReactNode => {
    const { onPriority, onDescription, onNotify, onExplanation, onBusinessUnit, onArea } = this;
    const { error } = this.state;
    return (
      <TechSupportForm
        onPriority={onPriority}
        onDescription={onDescription}
        onNotify={onNotify}
        onExplanation={onExplanation}
        {...this.state}
        error={error}
        onBusinessUnit={onBusinessUnit}
        onArea={onArea}
      />
    );
  };

  private success = (id: number): React.ReactNode => (
    <div className="text-center">
      <h5>Your request has been submitted.</h5>
      <a className="btn btn-success" href={ticketURL({ id })}>
        View Ticket
      </a>
    </div>
  );

  private formButtons = (): React.ReactNode => {
    const { hide } = this;
    const { loading } = this.state;
    return (
      <>
        <button type="button" className="btn btn-danger" onClick={hide}>
          Cancel
        </button>
        <button disabled={loading} type="submit" className="btn btn-primary">
          Submit
        </button>
      </>
    );
  };

  private close = (): React.ReactNode => {
    const { hide } = this;
    return (
      <button type="button" className="btn btn-primary" onClick={hide}>
        Close
      </button>
    );
  };

  private onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    event.stopPropagation();
    const { id, type, feature } = this.props.ticketable;
    const { priority, description, explanation, notify, businessUnit, area } = this.state;
    const explainedDescription = ['Request Description:', description, 'Reason for Request:', explanation].join('\n\n');
    try {
      this.setState({ loading: true });
      const response = await axios.post('/tech_support/tickets.json', {
        ticketable_id: id,
        ticketable_type: type,
        priority,
        feature,
        description: explainedDescription,
        notify,
        business_unit: businessUnit,
        area,
      });
      this.setState({ ticketId: response.data.id });
    } catch (error) {
      this.setState({ error: error as Error, loading: false });
    }
  };

  private onPriority = (value: number) => {
    this.setState({ priority: value });
  };

  private onDescription = (value: string) => {
    this.setState({ description: value });
  };

  private onNotify = (value: boolean) => {
    this.setState({ notify: value });
  };

  private onExplanation = (value: string) => {
    this.setState({ explanation: value });
  };

  private onBusinessUnit = (value: BusinessUnit) => {
    this.setState({ businessUnit: value });
  };

  private onArea = (value: Area) => {
    this.setState({ area: value });
  };

  private show = () => {
    this.setState({ show: true });
  };

  private hide = () => {
    this.setState({ show: false });
  };
}

export { CreateTicket };

import { faDollarSign } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';

import { CurrencyFormControl, FieldFormGroup } from '@admin/components/fields';
import { InputGroup } from '@shared/components/bootstrap';

export const AmountFormGroup: React.FC<{
  value?: number;
  disabled?: boolean;
  onChange(value?: number): void;
}> = (props) => (
  <FieldFormGroup id="offline_payment_amount" label="Amount:">
    <InputGroup>
      <InputGroup.Addon>
        <FontAwesomeIcon icon={faDollarSign} />
      </InputGroup.Addon>
      <CurrencyFormControl id="offline_payment_amount" {...props} />
    </InputGroup>
  </FieldFormGroup>
);

import * as React from 'react';

export const PACKING_VALUES = ['All of my items', 'Some of my items', 'Everything is already packed'] as const;
export type PackingValue = typeof PACKING_VALUES[number];

export interface IPackingDetailsProps {
  packingValue: PackingValue;
  packingSuppliesRequired: boolean;
}

export const PackingDetailsSummary: React.FC<IPackingDetailsProps> = ({ packingValue, packingSuppliesRequired }) => (
  <>
    <p>"What do you need help packing?" {packingValue}</p>
    <p>"Packing Supplies Required?": {packingSuppliesRequired ? 'Yes' : 'No'}</p>
  </>
);

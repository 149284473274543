import React, { useState } from 'react';

import { AddressInput, useAccountAddressUpsertMutation, useTaskOrderCreateMutation } from '@admin/schema';
import { client } from '@admin/libraries/apollo';
import { Duration } from 'luxon';
import { Dialog } from '@admin/components/helpers/dialog';
import { DetailForm, Values } from '@admin/components/orders/task_orders/components/detail_form';
import { ClientAddress } from '@admin/components/fields/address_finder_form_control';
import { editAccountTaskOrderURL } from '@admin/config/routes';
import { useHistory } from 'react-router-dom';

export const CreatePanel: React.FC<{
  accountID: string;
}> = ({ accountID }) => {
  const [create, { loading }] = useTaskOrderCreateMutation({ client });
  const [upsertAddress, { error: mutationError }] = useAccountAddressUpsertMutation({ client });
  const [error, setError] = useState<Error | null>(null);
  const history = useHistory();

  const createAddress = async (input: Omit<AddressInput, 'details'>) => {
    const { data, errors } = await upsertAddress({
      variables: { accountID, input: { ...input, details: { buildingType: 'Other' } } },
    });
    if (data?.accountAddressUpsert.address) {
      return data.accountAddressUpsert.address;
    } else {
      throw errors?.[0] || new Error(data?.accountAddressUpsert.error ?? 'Could not create new address');
    }
  };

  const onSubmit = async (values: Values) => {
    const resolvedAddress: ClientAddress = values.address?.id ? values.address : await createAddress(values.address!);
    const { data, errors } = await create({
      variables: {
        input: {
          accountID,
          addressID: resolvedAddress.id!,
          movers: parseInt(values.movers!),
          estimatedDuration: Duration.fromDurationLike({ seconds: values.estimatedDuration! }).toISO(),
          regionID: values.regionID!,
          scheduled: values.scheduled!.toISO(),
        },
      },
    });

    if (errors || data?.result?.error || !data?.result?.order) {
      setError(errors?.[0] || new Error(data?.result?.error || 'An unknown error occurred'));
    } else {
      history.push(editAccountTaskOrderURL({ accountID, orderID: data.result.order.id! }));
    }
  };

  const resolvedError = error || mutationError;

  return (
    <>
      <DetailForm
        initialValues={{
          scheduled: undefined,
          address: undefined,
          movers: undefined,
          estimatedDuration: undefined,
          regionID: undefined,
        }}
        onSubmit={onSubmit}
        loading={loading}
        addressInputConfig={{ accountID }}
      />
      {resolvedError && <Dialog title="Error" description={resolvedError.message} onClose={() => setError(null)} />}
    </>
  );
};

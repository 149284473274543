import cn from 'classnames';
import styled from '@emotion/styled';
import React, { useState } from 'react';

import { client } from '@admin/libraries/apollo';
import {
  Maybe,
  PlanKindEnum,
  PlanTypeEnum,
  Pricing,
  SubscriptionFragment,
  useAccountStorageLadderQuery,
} from '@admin/schema';
import { Modal, Table } from '@shared/components/bootstrap';
import { Currency } from '@shared/components/helpers';

const PricingTable: React.FC<{
  isCustom: boolean;
  selectedSubscription: Maybe<SubscriptionFragment>;
  storageLadderPricings?: Maybe<Pricing[]>;
}> = ({ isCustom, selectedSubscription, storageLadderPricings }) => {
  let selectedCuft = 0;
  let selectedRate = 0;
  if (selectedSubscription) {
    selectedCuft = selectedSubscription.customDimension?.cuft ?? selectedSubscription.pricing.plan.cuft;
    selectedRate = (isCustom ? selectedSubscription.quantity : selectedSubscription.pricing.amount) / selectedCuft;
  }

  const customPrice = (pricing: Pricing) => Math.round(selectedRate * pricing.plan.cuft);

  const dimensionalPlans = storageLadderPricings?.filter((pricing) => pricing.plan.kind !== PlanKindEnum.Custom);

  if (!dimensionalPlans) {
    return <p>No attached storage ladder or pricing set.</p>;
  }

  return (
    <Table striped>
      <thead>
        <tr>
          <th className="text-left col-sm-2">Plan</th>
          <th className="text-left col-sm-2">Price</th>
        </tr>
      </thead>
      <tbody>
        {dimensionalPlans.map((pricing, i) => (
          <tr key={i} className={cn({ info: selectedSubscription ? pricing.plan.cuft === selectedCuft : false })}>
            <td className="text-left col-sm-2">{pricing.plan.name}</td>
            <td className="text-left col-sm-2">
              <Currency value={isCustom ? customPrice(pricing) : pricing.amount} currency={pricing.currency} />
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

const PricingModal: React.FC<{
  accountID: string;
  onClose(): void;
}> = ({ accountID, onClose }) => {
  const { data } = useAccountStorageLadderQuery({ variables: { accountID }, client });

  if (!data) {
    return null;
  }

  const {
    account: { subscriptions, storageLadderPricings },
  } = data;
  const storageSubscriptions = subscriptions.filter(
    (subscription) => subscription.pricing.plan.type === PlanTypeEnum.StoragePlan,
  );
  const selectedSubscription = storageSubscriptions[0];
  const isCustom = selectedSubscription ? selectedSubscription.pricing.plan.kind === PlanKindEnum.Custom : false;

  let title = 'Pricing';
  if (isCustom) {
    title += ' (Custom)';
  }

  return (
    <Modal onClose={onClose}>
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PricingTable
            isCustom={isCustom}
            selectedSubscription={selectedSubscription}
            storageLadderPricings={storageLadderPricings}
          />
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};

const TextLink = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;

export const StorageLadderModal: React.FC<{ accountID: string }> = ({ accountID }) => {
  const [showModal, setShowModal] = useState<boolean>(false);

  return (
    <>
      <TextLink onClick={() => setShowModal(true)}>See other plans / pricing</TextLink>
      {showModal && <PricingModal accountID={accountID} onClose={() => setShowModal(false)} />}
    </>
  );
};

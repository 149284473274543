import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import React from 'react';

export const RangeSlider: React.FC<{
  maxValue: number;
  minValue: number;
  value: { max: number; min: number };
  onChange(value: number | { max: number; min: number }): void;
  filterType: string;
  filterTypeChange(value: string): void;
  includeUndefined: boolean;
  includeUndefinedChanged(value: boolean): void;
}> = ({
  maxValue,
  minValue,
  value,
  onChange,
  filterType,
  filterTypeChange,
  includeUndefined,
  includeUndefinedChanged,
}) => (
  <>
    <div>
      <label>
        <input
          className="form-radio-input"
          type="radio"
          value="any"
          checked={filterType === 'any'}
          onChange={(ev: React.ChangeEvent<HTMLInputElement>) => filterTypeChange(ev.target.value)}
        />{' '}
        Any
      </label>
      <label>
        <input
          className="form-radio-input"
          type="radio"
          value="range"
          checked={filterType === 'range'}
          onChange={(ev: React.ChangeEvent<HTMLInputElement>) => filterTypeChange(ev.target.value)}
        />{' '}
        Range
      </label>
      <label>
        <input
          className="form-radio-input"
          type="checkbox"
          checked={includeUndefined}
          onChange={(ev: React.ChangeEvent<HTMLInputElement>) => includeUndefinedChanged(ev.target.checked)}
        />{' '}
        Include Undefined
      </label>
    </div>
    <div className="range-slider">
      <InputRange
        maxValue={maxValue}
        minValue={minValue}
        value={value}
        onChange={onChange}
        disabled={filterType === 'any'}
      />
    </div>
  </>
);

import React, { useState } from 'react';

import { Dialog } from './dialog';

const DEFAULT_TITLE = 'Whoops';

export const useErrors = () => {
  const [error, setError] = useState<Error | string>();

  return {
    error,
    display: setError,
    clear: () => setError(undefined),
  };
};

export const Errors: React.FC<{
  error?: Error | string;
  clear(): void;
}> = ({ error, clear }) => {
  if (!error) return null;
  const title = typeof error === 'string' ? DEFAULT_TITLE : error.name;
  const description = typeof error === 'string' ? error : error.message;

  return (
    <Dialog
      title={title}
      description={description}
      onClose={() => {
        clear();
      }}
    />
  );
};

(function () {
  const app = angular.module('app');

  app.service('MaterialsService', [
    'MaterialCategory',
    function (MaterialCategory) {
      this.categories = MaterialCategory.query();

      this.find = function (id) {
        return this.categories.find(function (category) {
          return category.id === id;
        });
      };
    },
  ]);
})();

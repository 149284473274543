import * as React from 'react';
import { useState } from 'react';

import { Button } from '@shared/components/bootstrap';

import { Roles } from '@admin/components/helpers/roles';

import { UserRole } from '@admin/types';

import { Dialog } from './dialog';

const REQUIRED_ROLES = [UserRole.BillingAdmin, UserRole.Care];

export const Action: React.FC<{
  refundable: {
    id: string;
    receivable_id?: string;
    amount: number | string;
  };
}> = ({ refundable }) => {
  const [visible, setVisible] = useState(false);
  const onToggle = () => setVisible(!visible);

  return (
    <Roles show={REQUIRED_ROLES}>
      {visible && <Dialog onClose={onToggle} refundable={refundable} />}
      <Button kind="primary" onClick={onToggle}>
        Refund
      </Button>
    </Roles>
  );
};

import { Panel } from '@admin/components/helpers/panel';
import { client } from '@admin/libraries/apollo';
import React, { useState } from 'react';
import { OrderEventsQuery, useOrderEventsQuery } from '@admin/schema';
import { Timestamp } from '@admin/components/timestamp';
import { Lightbox } from '@shared/components/helpers';
import styled from '@emotion/styled';
import { TimeZoneContext } from '../dispatch/context';

const DOCUMENT_EVENT_NAMES = ['bol_start_signature', 'bol_end_signature', 'estimated_costs_signature'];

const ImageContainer = styled.div`
  border: 1px solid;

  &:hover {
    cursor: pointer;
  }
`;

const Image = styled.img`
  margin: 0;
  padding: 0;
  border: none;
  display: block;
  width: 100%;
  height: auto;
`;

export const DocumentImagesPanel: React.FC<{ orderID: string }> = ({ orderID }) => {
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const { data, loading } = useOrderEventsQuery({ client, variables: { orderID } });

  if (loading || !data) {
    return null;
  }

  const timezone = data.order.region!.tz;

  const documentImages = data.order.orderEvents.filter((event) => DOCUMENT_EVENT_NAMES.includes(event.eventName));
  if (documentImages.length === 0) {
    return null;
  }

  return (
    <TimeZoneContext.Provider value={timezone}>
      <Panel>
        <Panel.Header>
          <Panel.Collapse collapsed={collapsed} onChange={setCollapsed} />
          <Panel.Title>Document Images</Panel.Title>
        </Panel.Header>
        {collapsed || (
          <Panel.Body>
            <DocumentImages events={documentImages} timezone={timezone} />
          </Panel.Body>
        )}
      </Panel>
    </TimeZoneContext.Provider>
  );
};

interface IImageSelection {
  imageSrc: string;
  caption: string;
}

const DocumentImages: React.FC<{ events: OrderEventsQuery['order']['orderEvents']; timezone: string }> = ({
  events,
  timezone,
}) => {
  const [selection, setSelection] = useState<IImageSelection | undefined>();
  return (
    <div>
      {events
        .filter((orderEvent) => orderEvent.image)
        .map((orderEvent) => (
          <div key={orderEvent.image!.id} className="col-xs-6 col-md-4 col-lg-2">
            <span>
              {orderEvent.eventName} at <Timestamp value={orderEvent.timestamp} format="medium" timezone={timezone} />
            </span>

            <ImageContainer
              onClick={() => {
                setSelection({
                  imageSrc: orderEvent.image!.source,
                  caption: orderEvent.eventName,
                });
              }}
            >
              <Image src={orderEvent.image!.size} />
            </ImageContainer>
          </div>
        ))}
      {selection && (
        <Lightbox imageURL={selection.imageSrc} caption={selection.caption} onClose={() => setSelection(undefined)} />
      )}
    </div>
  );
};

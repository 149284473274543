import * as React from 'react';

import { Currency } from '@shared/components/helpers';

import { PlanKindEnum, PricingGroupPricingEntryFragment } from '@admin/schema';

export const StorageTotal: React.FC<{
  storage?: PricingGroupPricingEntryFragment;
  quantity?: number;
}> = ({ storage, quantity }) => {
  if (!storage) {
    return <>-</>;
  }
  const custom = storage.pricing.plan.kind === PlanKindEnum.Custom;
  const amount = storage.pricing.amount;
  return <Currency value={custom ? (quantity || 0) * amount : amount} />;
};

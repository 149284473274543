import { DateTime } from 'luxon';
import { createContext } from 'react';

import { Availability } from './types';

export const AvailabilityContext = createContext<{
  loading?: boolean;
  availabilities: Availability[];
  date?: DateTime;
  selection?: Availability;
  zone: string;
  from: DateTime;
  till: DateTime;
  onDate(date: DateTime): void;
  onSelect(_: Availability): void;
  onPrev(): void;
  onNext(): void;
}>({
  availabilities: [],
  date: undefined,
  selection: undefined,
  from: DateTime.now(),
  till: DateTime.now(),
  zone: '',
  onDate: () => {
    /* noop */
  },
  onSelect: () => {
    /* noop */
  },
  onPrev: () => {
    /* noop */
  },
  onNext: () => {
    /* noop */
  },
});

import { User__State } from '@admin/schema';

export const USER_STATES = [
  User__State.Alerting,
  User__State.Available,
  User__State.CoolDown,
  User__State.Offline,
  User__State.OnCall,
];

export const USER_STATE_TO_NAME: Record<User__State, string> = {
  [User__State.Alerting]: 'Alerting',
  [User__State.Available]: 'Available',
  [User__State.CoolDown]: 'Cool Down',
  [User__State.Offline]: 'Offline',
  [User__State.OnCall]: 'On Call',
};

export const USER_STATE_TO_KIND: Record<User__State, 'warning' | 'success' | 'primary' | 'default' | 'danger'> = {
  [User__State.Alerting]: 'warning',
  [User__State.Available]: 'success',
  [User__State.CoolDown]: 'primary',
  [User__State.Offline]: 'default',
  [User__State.OnCall]: 'danger',
};

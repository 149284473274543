import { Label } from '@admin/components/helpers/label';
import styled from '@emotion/styled';
import { faFileContract } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { client } from '@admin/libraries/apollo';
import { DateTime } from 'luxon';
import * as React from 'react';

import { COLORS } from '@root/colors';

import { Spinner } from '@admin/components/spinner';
import { Table } from '@shared/components/bootstrap';
import { Currency } from '@shared/components/helpers';

import { Status } from '@admin/components/self_storage/rentals/status';

import {
  SelfStorage__Status as SelfStorage__Rental__Status,
  useSelfStorageRentalPolicyQuery,
  useSelfStorageRentalQuery,
} from '@admin/schema';
import { Edit as EditSource } from './default_source/edit';

const Rate: React.FC<{ canceled: boolean }> = ({ canceled, children }) =>
  canceled ? <del children={children} /> : <span children={children} />;

const Signatures = styled.div`
  display: flex;
  margin: -4px;
`;

const Signature = styled.a`
  color: ${COLORS.blue};
  padding: 4px 8px;
  margin: 4px;
  border-width: thin;
  border-style: solid;
  border-radius: 4px;
  border-color: ${COLORS.blue};
`;

export const Details: React.FC<{
  rentalID: string;
}> = ({ rentalID }) => {
  const { data, loading } = useSelfStorageRentalQuery({
    client,
    fetchPolicy: 'cache-and-network',
    variables: { id: rentalID },
  });
  const { data: policy } = useSelfStorageRentalPolicyQuery({ client });

  const items = data?.rental?.subscriptionItems;
  const promotion = data?.rental?.promotion;
  const signatures = data?.rental?.signatures;
  const subscription = data?.rental?.subscription;
  const account = subscription?.account;
  const rateAdjustmentSchedulings = data?.rental?.rateAdjustmentSchedulings;
  const permissions = policy?.permissions;

  if (loading) {
    return <Spinner />;
  }

  const total = items
    ?.filter(({ status }) => status !== SelfStorage__Rental__Status.Canceled)
    ?.reduce((memo, { rate }) => memo + rate, 0);

  return (
    <>
      <Table striped>
        <thead>
          <tr>
            <th className="text-left col-zero">ID</th>
            <th className="text-left col-md-3">Status</th>
            <th className="text-left col-md-3">Summary</th>
            <th className="text-left col-md-3">Activated</th>
            <th className="text-left col-md-3">Canceled</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {rateAdjustmentSchedulings &&
            rateAdjustmentSchedulings.map(({ id, selfStorageRental, effectiveAt, newPrice }) => (
              <tr key={`scheduling-${id}`}>
                <td className="text-left col-zero">{id}</td>
                <td className="text-left col-md-3">
                  <Label kind="info">Scheduled</Label>
                </td>
                <td className="text-left col-md-3">Unit {selfStorageRental!.unit.name}</td>
                <td className="text-left col-md-3">
                  {DateTime.fromISO(effectiveAt).toUTC().toLocaleString(DateTime.DATE_MED)}{' '}
                  <Label kind="info">Rate Adjustment</Label>
                </td>
                <td className="text-left col-md-3" />
                <td className="text-right col-zero">
                  <Currency value={newPrice} />
                  /month
                </td>
              </tr>
            ))}
          {items &&
            items.map((item) => (
              <tr key={`item-${item.id}`}>
                <td className="text-left col-zero">{item.id}</td>
                <td className="text-left col-md-3">
                  <Status status={item.status} />
                </td>
                <td className="text-left col-md-3">{item.summary}</td>
                <td className="text-left col-md-3">
                  {item.activatedAt && DateTime.fromISO(item.activatedAt).toLocaleString(DateTime.DATETIME_MED)}
                </td>
                <td className="text-left col-md-3">
                  {item.canceledAt && DateTime.fromISO(item.canceledAt).toLocaleString(DateTime.DATETIME_MED)}
                </td>
                <td className="text-right col-zero">
                  <Rate canceled={item.status === SelfStorage__Rental__Status.Canceled}>
                    <Currency value={item.rate} />
                    /month
                  </Rate>
                </td>
              </tr>
            ))}
        </tbody>
        <tfoot>
          {promotion && (
            <tr>
              <th colSpan={6} className="text-right">
                {promotion.name}
              </th>
            </tr>
          )}
          <tr>
            <th colSpan={6} className="text-right">
              Total:{' '}
              {total ? (
                <>
                  <Currency value={total} />
                  /month
                </>
              ) : (
                <>-</>
              )}
            </th>
          </tr>
        </tfoot>
      </Table>
      <Signatures>
        {signatures?.map((signature) => (
          <Signature key={signature.id} href={signature.contract?.url ?? '#'} target="_blank">
            <FontAwesomeIcon icon={faFileContract} /> {signature.document.name}
          </Signature>
        ))}
      </Signatures>
      {account && permissions?.modify && <EditSource accountID={account.id} subscriptionID={subscription!.id} />}
    </>
  );
};

import * as React from 'react';

import {
  ItemCategory,
  MaterialCategory,
  PartCategory,
  useItemCategoriesQuery,
  useMaterialCategoriesQuery,
  usePalletTypesQuery,
  usePartCategoriesQuery,
} from '@admin/schema';

import { Panel } from '@admin/components/helpers/panel';
import { createPalletTypeURL, editPalletTypeURL } from '@admin/config/routes';
import { Table } from '@shared/components/bootstrap';
import { client } from '@admin/libraries/apollo';

export const PalletTypes: React.FC = () => {
  const { data } = usePalletTypesQuery({ client });
  const { data: itemCategoryData } = useItemCategoriesQuery({ client });
  const { data: materialCategoryData } = useMaterialCategoriesQuery({ client });
  const { data: partCategoryData } = usePartCategoriesQuery({ client });
  if (
    !data ||
    !data.palletTypes ||
    !itemCategoryData ||
    !itemCategoryData.categories ||
    !materialCategoryData ||
    !materialCategoryData.categories ||
    !partCategoryData ||
    !partCategoryData.categories
  ) {
    return null;
  }

  return (
    <Panel>
      <Panel.Header>
        <Panel.Title>Pallet Types</Panel.Title>
      </Panel.Header>
      <Panel.Body>
        <Table striped responsive>
          <thead>
            <tr>
              <th style={{ width: '2.5%' }}>ID</th>
              <th style={{ width: '2.5%' }}>Position</th>
              <th style={{ width: '5%' }}>Name</th>
              <th style={{ width: '5%' }}>Capacity</th>
              <th style={{ width: '30%' }}>Allowed Item Categories</th>
              <th style={{ width: '15%' }}>Allowed Part Categories</th>
              <th style={{ width: '15%' }}>Allowed Material Categories</th>
              <th style={{ width: '5%' }}>Legacy Type</th>
              <th style={{ width: '5%' }}>iOS Creatable</th>
              <th style={{ width: '10%' }}>Description</th>
              <th style={{ width: '5%' }}>
                <a className="btn btn-mint btn-labeled fa fa-plus" href={createPalletTypeURL()}>
                  Add
                </a>
              </th>
            </tr>
          </thead>
          <tbody>
            {data.palletTypes.map((type) => (
              <tr key={type.id}>
                <td>{type.id}</td>
                <td>{type.position}</td>
                <td>{type.name}</td>
                <td>{type.capacity}</td>
                <td>{formatCategories(type.itemCategories, itemCategoryData?.categories?.length)}</td>
                <td>{formatCategories(type.partCategories, partCategoryData?.categories?.length)}</td>
                <td>{formatCategories(type.materialCategories, materialCategoryData?.categories?.length)}</td>
                <td>{type.legacySize.toLowerCase()}</td>
                <td>{type.active.toString()}</td>
                <td>{type.description}</td>
                <td>
                  <a className="btn btn-primary" href={editPalletTypeURL({ id: type.id })}>
                    Edit
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Panel.Body>
    </Panel>
  );
};

function formatCategories(
  categories:
    | Array<Pick<ItemCategory, 'name'>>
    | Array<Pick<PartCategory, 'name'>>
    | Array<Pick<MaterialCategory, 'name'>>
    | undefined
    | null,
  totalCategoryCount: number | undefined | null,
) {
  if (!categories) return null;

  if (categories.length === totalCategoryCount) {
    return 'All';
  } else if (categories.length === 0) {
    return 'None';
  }
  return categories.map((category) => category.name).join(', ');
}

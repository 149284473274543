import styled from '@emotion/styled';
import React from 'react';
import { TD } from './helpers';

const PriceInput = styled.input`
  display: inline;
  width: auto;
  max-width: calc(100% - 10px);
`;

export const PriceCell: React.FC<{
  value: number | string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  highlight: boolean;
}> = ({ value, onChange, highlight }) => (
  <TD className="text-left" highlight={highlight}>
    $ <PriceInput type="number" min="0" step="any" className="form-control" value={value} onChange={onChange} />
  </TD>
);

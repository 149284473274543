import React from 'react';

import { LandingLocationContentQuery, Landing__Location__Service, useLandingLocationContentQuery } from '@admin/schema';

import { client } from '@admin/libraries/apollo';

import { Breadcrumb, AnchorButton as Link, Table } from '@shared/components/bootstrap';
import { Fit, Picture } from '@shared/components/helpers/picture';
import { Panel } from '@admin/components/helpers/panel';
import { PageHeader } from '@admin/components/helpers/page_header';
import { Spinner } from '@admin/components/spinner';

import { Rating as ReviewRating } from '@admin/components/landing/location/reviews/rating';

const SERVICE_TO_TEXT: Record<Landing__Location__Service, string> = {
  [Landing__Location__Service.Moving]: 'Moving',
  [Landing__Location__Service.SelfStorage]: 'Self-Storage',
  [Landing__Location__Service.SmartStorage]: 'Smart-Storage',
};

const Content: React.FC<{
  content: LandingLocationContentQuery['content'];
}> = ({ content }) => (
  <>
    <Panel>
      <Panel.Header>
        <Panel.Title>Content</Panel.Title>
      </Panel.Header>
      <Panel.Body>
        {content.heroImage && (
          <Picture source={content.heroImage.imgixURL} height={320} width={960} fit={Fit.Crop} background="FFF" />
        )}
        <div>
          Service: <strong>{SERVICE_TO_TEXT[content.service]}</strong>
        </div>

        <div>
          Title: <strong>{content.title}</strong>
        </div>

        <div>
          Description: <strong>{content.description}</strong>
        </div>

        <div className="well" dangerouslySetInnerHTML={{ __html: content.blurb }} />
      </Panel.Body>
      <Panel.Footer align="right">
        <Link kind="primary" href={`/landing/location/contents/${content.id}/edit`}>
          Edit
        </Link>
      </Panel.Footer>
    </Panel>

    <Panel>
      <Panel.Header>
        <Panel.Title>Reviews</Panel.Title>
      </Panel.Header>
      <Panel.Body>
        <Table striped>
          <thead>
            <tr>
              <th className="text-left">Author</th>
              <th className="text-left">Message</th>
              <th className="text-left">Rating</th>
            </tr>
          </thead>
          <tbody>
            {content.reviews.map((review) => (
              <tr key={review.id}>
                <td className="text-left">{review.author}</td>
                <td className="text-left">{review.message}</td>
                <td className="text-left">
                  <ReviewRating review={review} />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Panel.Body>
    </Panel>
  </>
);

export const Details: React.FC<{
  id: string;
  onSave(): void;
}> = ({ id }) => {
  const { data } = useLandingLocationContentQuery({
    client,
    fetchPolicy: 'cache-and-network',
    variables: { id: id },
  });
  const content = data?.content;

  return (
    <>
      <PageHeader>
        <Breadcrumb>
          <Breadcrumb.Item>Landing</Breadcrumb.Item>
          <Breadcrumb.Item>Location</Breadcrumb.Item>
          <Breadcrumb.Item>Content</Breadcrumb.Item>
          <Breadcrumb.Item active>{id}</Breadcrumb.Item>
        </Breadcrumb>
      </PageHeader>

      {content ? <Content content={content} /> : <Spinner />}
    </>
  );
};

import React from 'react';
import { Breadcrumb } from '@shared/components/bootstrap';
import { HistoriesPanel } from '@admin/components/histories/panel';
import { Spinner } from '@admin/components/spinner';
import { History__Subject, Coupon__Input, useCouponQuery, useCouponUpdateMutation } from '@admin/schema';
import { client } from '@admin/libraries/apollo';
import { CouponForm } from './form';

export const Edit: React.FC<{ id: string; onSave(): void }> = ({ id, onSave }) => {
  const { data, loading } = useCouponQuery({ client, variables: { id } });
  const [execute, { loading: saving }] = useCouponUpdateMutation({ client });
  return (
    <>
      <div className="page-header">
        <Breadcrumb>
          <Breadcrumb.Item>
            <a href="/marketing/coupons">Coupons</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Edit</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      {loading && <Spinner />}
      {data && (
        <CouponForm
          coupon={data.coupon}
          save={(input: Coupon__Input) => execute({ variables: { input, id: data.coupon.id } })}
          onSave={onSave}
          loading={saving}
        />
      )}
      <HistoriesPanel id={id} type={History__Subject.Coupon}></HistoriesPanel>
    </>
  );
};

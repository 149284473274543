(function () {
  const app = angular.module('app');

  app.directive('files', [
    '$parse',
    function ($parse) {
      return {
        restrict: 'A',
        link: function (scope, element, attrs) {
          scope.selected = function (file, reader) {
            const fn = $parse(attrs.picked);
            scope.$apply(function () {
              fn(scope, { $file: file, $reader: reader });
            });
          };

          element.bind('change', function (event) {
            const files = event.target.files;
            _.each(files, function (file) {
              const reader = new FileReader();
              reader.onload = function ({ target }) {
                scope.selected(file, target);
              };
              reader.readAsDataURL(file);
            });
          });
        },
      };
    },
  ]);
})();

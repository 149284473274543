(function () {
  const app = angular.module('app');

  app.controller('TransfersShowController', [
    '$stateParams',
    '$scope',
    '$http',
    'Transfer',
    'ConfirmationService',
    'ErrorService',
    function ($stateParams, $scope, $http, Transfer, ConfirmationService, ErrorService) {
      $scope.id = $stateParams.id;
      $scope.transfer = Transfer.get({ id: $stateParams.id });

      $scope.cancel = function () {
        ConfirmationService.confirm({
          title: 'Are you sure you want to cancel this transfer?',
          description: 'Canceling a transfer may generate a "restock" transfer if the transfer is "enroute".',
          acceptButton: 'Yes, Cancel This Transfer',
          declineButton: 'No, Nevermind',
        }).then(function () {
          $http.patch('/transfers/' + $scope.id + '/cancel.json').then(function () {
            $scope.transfer.state = 'canceled';
            $scope.transfer.cancelable = !$scope.transfer.cancelable;
          }, ErrorService.handle);
        });
      };
    },
  ]);

  app.controller('TransfersEditController', [
    '$stateParams',
    '$state',
    '$scope',
    '$http',
    'Transfer',
    'Warehouse',
    'ErrorService',
    function ($stateParams, $state, $scope, $http, Transfer, Warehouse, ErrorService) {
      $scope.id = $stateParams.id;
      $scope.warehouses = Warehouse.query();
      $scope.transfer = Transfer.get({ id: $stateParams.id });
      $scope.transfer.$promise.then(function () {
        _.each($scope.transfer.pallets, function (pallet) {
          pallet.selected = !!pallet;
        });
      });

      $scope.save = function () {
        $http({
          method: 'PATCH',
          url: '/transfers/' + $scope.id + '.json',
          data: {
            origin_id: $scope.transfer.origin ? $scope.transfer.origin.id : null,
            destination_id: $scope.transfer.destination ? $scope.transfer.destination.id : null,
            pallet_ids:
              _.map(
                _.filter($scope.transfer.pallets, function (pallet) {
                  return !!pallet.selected;
                }),
                'id',
              ) || [],
          },
        }).then(function () {
          $state.go('viewTransfer', { id: $scope.id });
        }, ErrorService.handle);
      };
    },
  ]);

  app.controller('TransfersIndexController', [
    '$scope',
    '$location',
    'Transfer',
    'Warehouse',
    'PaginationService',
    'DebounceService',
    function ($scope, $location, Transfer, Warehouse, PaginationService, DebounceService) {
      const debounce = DebounceService.initialize();
      $scope.filters = $location.search();
      $scope.warehouses = Warehouse.query();
      $scope.pagination = PaginationService.initialize({ page: $location.search().page });

      $scope.filter = function (filters) {
        if (!filters) {
          filters = $scope.filters || {};
        } else {
          $scope.pagination.reset();
        }

        filters.page = $scope.pagination.page;
        $location.search(filters);

        $scope.transfers = null;
        debounce.execute(Transfer.get(filters)).then(function (data) {
          $scope.pagination.prepare(data.pagination);
          $scope.transfers = data.results;
        });
      };

      $scope.filter();
    },
  ]);
})();

import * as React from 'react';
import { Query } from '@apollo/client/react/components';

import { Text } from '@shared/components/bootstrap';
import { ConfigWithSetup } from '@shared/components/inventory_capture';
import { Summary } from '@shared/components/inventory_capture/summary';
import { client } from '@admin/libraries/apollo';

import { buildOrderInventory } from '@portal/utils';
import { OrderTypeEnum } from '@admin/schema';
import { IInventoryConfig, IOrder } from '@shared/types';
import * as QUERY from './inventory/query.gql';

interface IQueryData extends IInventoryConfig {
  order: IOrder;
}

interface IQueryVariables {
  orderID: string;
}

export const InventorySummary: React.FC<{
  orderID: string;
}> = ({ orderID }) => (
  <Query<IQueryData, IQueryVariables>
    client={client}
    query={QUERY}
    variables={{ orderID: String(orderID) }}
    fetchPolicy="no-cache"
  >
    {({ data }) => {
      if (!data || !data.order) {
        return null;
      }
      const { order, ...config } = data;

      return (
        <div id="inventory_summary">
          {order.services.map(
            (service, key) =>
              service.type === OrderTypeEnum.Pickup &&
              service.id === orderID && (
                <ConfigWithSetup key={key} {...config} inventory={buildOrderInventory(service, config)}>
                  <Summary />
                  <Text tag="p">
                    <strong>Estimated:</strong> {service.estimatedCuft.toFixed(2)} ft<sup>3</sup>
                  </Text>
                </ConfigWithSetup>
              ),
          )}
        </div>
      );
    }}
  </Query>
);

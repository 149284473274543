import { SelfStorage__ReservationFragment } from '@admin/schema';
import { Breadcrumb } from '@shared/components/bootstrap';
import React from 'react';

export const LeadBreadcrumbs: React.FC<{
  reservation: SelfStorage__ReservationFragment;
}> = ({ reservation }) => (
  <Breadcrumb>
    <Breadcrumb.Item>
      <a href="/leads">Leads</a>
    </Breadcrumb.Item>
    <Breadcrumb.Item>
      <a href={`/leads/${reservation.lead.id}/edit`}>{`#${reservation.lead.id} (${reservation.name})`}</a>
    </Breadcrumb.Item>
    <Breadcrumb.Item active>Self-Storage Reservation</Breadcrumb.Item>
  </Breadcrumb>
);

import * as React from 'react';
import { useState } from 'react';

import { BuildPalletTypeMutation, PalletTypeInput, useBuildPalletTypeMutation } from '@admin/schema';
import { palletTypesURL } from '@admin/config/routes';
import { client } from '@admin/libraries/apollo';
import { PalletTypeForm } from './pallet_type_form';

const DEFAULT_PALLET_TYPE_INPUT: PalletTypeInput = {
  name: '',
  capacity: undefined,
  active: true,
  legacySize: '' as any,
  itemCategoryIDs: [],
  partCategoryIDs: [],
  materialCategoryIDs: [],
  description: '',
  position: 0,
};

export const CreatePalletType: React.FC = () => {
  const [palletType, setPalletType] = useState<PalletTypeInput>(DEFAULT_PALLET_TYPE_INPUT);
  const [results, setResults] = useState<BuildPalletTypeMutation | undefined | null>(undefined);
  const [execute, { loading }] = useBuildPalletTypeMutation({ client });
  const onSave = async (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    if (loading) {
      return;
    }
    const variables = { input: palletType };
    const response = await execute({ variables });
    if (!response) {
      return;
    }
    setResults(response.data);
    if (response && response.data && response.data.buildPalletType.error === null) {
      setPalletType(DEFAULT_PALLET_TYPE_INPUT);
      window.location.href = palletTypesURL();
    }
  };

  return (
    <div className="panel">
      <div className="panel-heading">
        <h3 className="panel-title">Add Pallet Type</h3>
      </div>
      {results && results.buildPalletType.error && (
        <div className="alert alert-danger">{results.buildPalletType.error}</div>
      )}
      <form
        onSubmit={async (event) => {
          event.preventDefault();
          event.stopPropagation();
        }}
      >
        <div className="panel-body">
          <PalletTypeForm palletType={palletType} onChange={setPalletType} edit={false} />
        </div>
        <div className="panel-footer text-right">
          <button type="button" className="btn btn-primary" disabled={loading} onClick={onSave}>
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

import * as React from 'react';

import { SelfStorage__ClassificationFragment } from '@admin/schema';

import { Size } from './size';

export const Summary: React.FC<{
  classification: SelfStorage__ClassificationFragment;
}> = ({ classification }) => (
  <div>
    <Size classification={classification} /> {classification.kind.name}
  </div>
);

(function () {
  angular.module('app').factory('Usage', [
    '$resource',
    function ($resource) {
      return $resource('/accounts/:account_id/usage.json', {
        account_id: '@account_id',
      });
    },
  ]);
})();

import React, { useState } from 'react';

import { Order, Region, useTaskOrderUpdateMutation } from '@admin/schema';
import { client } from '@admin/libraries/apollo';
import { DateTime, Duration } from 'luxon';
import { ConfirmationModal } from '@shared/components/modals/confirmation_modal';
import { orderURL } from '@admin/config/routes';
import { Dialog } from '@admin/components/helpers/dialog';
import { DetailForm, Values } from '@admin/components/orders/task_orders/components/detail_form';

export const EditPanel: React.FC<{
  order: Pick<Order, 'id' | 'movers' | 'scheduled' | 'estimatedDuration'> & { region: Pick<Region, 'id' | 'tz'> };
}> = ({ order }) => {
  const [update, { loading }] = useTaskOrderUpdateMutation({ client });
  const [state, setState] = useState<Error | 'confirm' | null>(null);

  const { movers, scheduled, region, estimatedDuration } = order;

  const onSubmit = async (values: Values) => {
    const { data, errors } = await update({
      variables: {
        input: {
          moverCount: parseInt(values.movers!),
          estimatedDuration: Duration.fromDurationLike({ seconds: values.estimatedDuration! }).toISO(),
          orderID: order.id,
          regionID: values.regionID!,
          scheduledTime: values.scheduled!.toISO(),
          skipRescheduleFee: true,
          skipAvailabilityCheck: true,
        },
      },
    });

    if (errors || data?.result?.error) {
      setState(errors?.[0] || new Error(data?.result?.error || 'An unknown error occurred'));
      return;
    }
    setState('confirm');
  };

  return (
    <>
      <DetailForm
        initialValues={{
          scheduled: DateTime.fromISO(scheduled),
          regionID: region.id,
          movers: movers.toFixed(0),
          estimatedDuration: estimatedDuration ?? undefined,
        }}
        onSubmit={onSubmit}
        loading={loading}
      />
      {state instanceof Error && <Dialog title="Error" description={state.message} onClose={() => setState(null)} />}
      {state === 'confirm' && (
        <ConfirmationModal
          title="Success"
          onClose={() => setState(null)}
          onConfirm={() => (window.location.pathname = orderURL({ id: order.id }))}
          acceptButton="View Order"
          declineButton="Edit"
        >
          The order has been updated.
        </ConfirmationModal>
      )}
    </>
  );
};

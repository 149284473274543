import angular from 'angular';

const app = angular.module('app');

function isChannelPartnerLead(lead) {
  return !!lead && !!lead.channel && lead.channel !== 'clutter';
}

function isFullServiceAvailable(classifier) {
  return !!classifier && classifier.full_service_eligible;
}

const service = {
  isFullServiceAvailable,
  isChannelPartnerLead,
};

app.factory('ProductAvailabilityService', () => service);

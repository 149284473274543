import * as React from 'react';
import { DateTime } from 'luxon';

import { InputFormGroup } from '@admin/components/fields';

export const DueDateFormGroup: React.FC<{
  value?: string;
  onChange(value?: string): void;
}> = ({ value, onChange }) => (
  <InputFormGroup
    id="offline_payment_due_date"
    label="New Due Date:"
    type="date"
    value={value ?? ''}
    min={DateTime.local().plus({ days: 1 }).toISODate()}
    onChange={(event) => onChange(event.target.value || undefined)}
  />
);

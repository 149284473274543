import moment from 'moment-timezone';
import * as React from 'react';

const MAPPINGS = {
  medium: 'MMM D, YYYY h:mm:ss A z',
  small: 'h:mm:ss A z',
};

export const Timestamp: React.FC<{
  value: string;
  format?: 'medium' | 'small';
  timezone?: string;
}> = ({ value, format = 'medium', timezone }) => (
  <>
    {moment(value)
      .tz(timezone || Intl.DateTimeFormat().resolvedOptions().timeZone)
      .format(MAPPINGS[format])}
  </>
);

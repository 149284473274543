(function () {
  angular.module('app').filter('sectioned', [
    function () {
      return function (resources, group) {
        const groupings = _.groupBy(resources, group);
        const sections = _.keys(groupings).sort();

        return _.map(sections, function (section) {
          return {
            section: section,
            resources: groupings[section],
          };
        });
      };
    },
  ]);
})();

import { Spinner } from '@admin/components/spinner';
import { useSelfStorageSubscriptionQuery } from '@admin/schema';
import styled from '@emotion/styled';
import { client } from '@admin/libraries/apollo';
import React, { useState } from 'react';
import { Button, Modal, Text } from '@shared/components/bootstrap';

import { Form } from './form';

const RentalsWrapper = styled.div`
  padding-bottom: 8px;
`;

const EditDefaultSourceModal: React.FC<{
  accountID: string;
  subscriptionID: string;
  onCancel(): void;
  onSave(): void;
}> = (props) => {
  const { data, loading } = useSelfStorageSubscriptionQuery({ client, variables: { id: props.subscriptionID } });
  const subscription = data?.subscription;

  return (
    <Modal centered onClose={props.onCancel}>
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>Edit Payment Method</Modal.Title>
          <Modal.Close close={props.onCancel} />
        </Modal.Header>
        <Modal.Body>
          {loading && <Spinner />}
          {subscription && (
            <>
              <RentalsWrapper>
                Changing the payment method for:{' '}
                {subscription.rentals.map((rental) => (
                  <Text tag="div" key={rental.id}>
                    {rental.name}
                  </Text>
                ))}
              </RentalsWrapper>
              <Form subscription={subscription} {...props} />
            </>
          )}
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};

export const Edit: React.FC<{
  accountID: string;
  subscriptionID: string;
}> = (props) => {
  const [showModal, setShowModal] = useState<boolean>(false);

  return (
    <div className="text-right">
      <Button kind="danger" onClick={() => setShowModal(true)}>
        Edit Payment Method
      </Button>
      {showModal && (
        <EditDefaultSourceModal
          onCancel={() => setShowModal(false)}
          onSave={() => {
            setShowModal(false);
          }}
          {...props}
        />
      )}
    </div>
  );
};

import template from '@admin/angular/templates/dispatcher_estimation_form.html';

(() => {
  const app = angular.module('app');

  const getUtcOffsetStr = (tz) => {
    const offset = (moment().tz(tz).utcOffset() / 60) * 100;
    let offsetString = offset < 0 ? '-' : '+';
    if (Math.abs(offset) < 1000) {
      offsetString += '0';
    }
    offsetString += Math.abs(offset);
    return offsetString;
  };

  app.component('dispatcherEstimationForm', {
    template,
    bindings: {
      order: '<',
      onSave: '&',
      mode: '<',
    },
    controller: [
      'ErrorService',
      'OrderEstimatedDuration',
      function (ErrorService, OrderEstimatedDuration) {
        this.$onInit = () => {
          OrderEstimatedDuration.get({ id: this.order.id }).$promise.then((data) => {
            this.order_estimated_duration = data.estimated_duration;
            this.predicted_duration_certainty = data.predicted_duration_certainty;
          });
          this.form = this.formParams(this.order);
          this.utc_offset = getUtcOffsetStr(this.order.region.tz);
          this.start_time = new Date(new Date(this.order.scheduled).getTime() + this.order.dispatch_offset * 1000);
          this.min_start_time = new Date(this.order.scheduled);
          this.max_start_time = new Date(
            new Date(this.order.scheduled).getTime() + this.order.sla_window_size * 30 * 60 * 1000,
          );

          this.min_start_time_formatted = moment(this.min_start_time).format('HH:mm');
          this.max_start_time_formatted = moment(this.max_start_time).format('HH:mm');
        };

        this.formParams = (order) => ({
          estimated_duration: order.estimated_duration,
          estimated_duration_override_reason: order.estimated_duration_override_reason,
          estimated_duration_overwritten_at: order.estimated_duration_overwritten_at,
          estimated_duration_hour: Math.floor(order.estimated_duration / 3600),
          estimated_duration_minute: Math.floor(order.estimated_duration / 60) % 60,
          estimated_duration_override: Boolean(order.estimated_duration_overwritten_at),
        });

        this.updateEstimatedDuration = () => {
          this.restrictInputsToValidRange();
          const durationInSeconds =
            this.form.estimated_duration_hour * 60 * 60 + this.form.estimated_duration_minute * 60;
          Object.assign(this.form, { estimated_duration: durationInSeconds });
        };

        this.restrictInputsToValidRange = () => {
          if (this.form.estimated_duration_hour < 0) {
            this.form.estimated_duration_hour = 0;
          }
          if (this.form.estimated_duration_minute < 0) {
            this.form.estimated_duration_minute = 0;
          }
          if (this.form.estimated_duration_minute > 59) {
            this.form.estimated_duration_minute = 59;
          }
        };

        this.reset = () => {
          this.save({
            estimated_duration: this.order_estimated_duration,
            estimated_duration_override: false,
            estimated_duration_override_reason: null,
          });
        };

        this.updateDispatchOffset = () => {
          this.order.dispatch_offset = (this.start_time.getTime() - new Date(this.order.scheduled).getTime()) / 1000;
        };

        this.update = () => {
          this.save({
            estimated_duration: this.form.estimated_duration,
            estimated_duration_override: true,
            estimated_duration_override_reason: this.form.estimated_duration_override_reason,
          });
        };

        this.onReasonChange = (value) => {
          this.form.estimated_duration_override_reason = value;
        };

        this.onCuftOverride = (estimatedDuration) => {
          const values = {
            estimated_duration: estimatedDuration,
            estimated_duration_override_reason: this.order.estimated_duration_override_reason,
            estimated_duration_overwritten_at: this.order.estimated_duration_overwritten_at,
            estimated_duration_hour: Math.floor(estimatedDuration / 3600),
            estimated_duration_minute: Math.floor(estimatedDuration / 60) % 60,
          };
          _.assign(this.form, values);
          _.assign(this.order, values);
        };

        this.save = (params) => {
          if (this.saving) {
            return;
          }
          this.saving = this.order;

          OrderEstimatedDuration.update({ id: this.order.id }, params)
            .$promise.then((order) => {
              _.assign(this.order, this.formParams(order));
              this.onSave();
            })
            .catch(ErrorService.handle)
            .finally(() => {
              delete this.saving;
            });
        };
      },
    ],
  });
})();

import * as React from 'react';
import { useState } from 'react';

import { useModifyRegionMutation, useRegionEditQuery, RegionEditDocument } from '@admin/schema';

import { PageHeader } from '@admin/components/helpers/page_header';
import { Panel } from '@admin/components/helpers/panel';
import { regionURL } from '@admin/config/routes';

import { client } from '@admin/libraries/apollo';
import { Breadcrumbs } from './breadcrumbs';
import { ZipInput } from './edit_zip_input';

const getZipDifferenceCount = (zipSet1: Set<string>, zipSet2: Set<string>) => {
  const difference = new Set(Array.from(zipSet1).filter((zip) => !zipSet2.has(zip)));
  return difference.size;
};

const parseZips = (zipText: string) => zipText.split(/\s+/).filter((word) => word !== '');

export const Edit: React.FC<{ id: string }> = ({ id }) => {
  const [inServiceAreaZipText, setInServiceAreaZipText] = useState<string>('');
  const [originalInServiceAreaZipSet, setOriginalInServiceAreaZipSet] = useState<Set<string>>(new Set());
  const [storageExtendedServiceAreaZipText, setStorageExtendedServiceAreaZipText] = useState<string>('');
  const [storageOriginalExtendedServiceAreaZipSet, setStorageOriginalExtendedServiceAreaZipSet] = useState<Set<string>>(
    new Set(),
  );
  const [movingExtendedServiceAreaZipText, setMovingExtendedServiceAreaZipText] = useState<string>('');
  const [movingOriginalExtendedServiceAreaZipSet, setMovingOriginalExtendedServiceAreaZipSet] = useState<Set<string>>(
    new Set(),
  );
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const { data, loading: querying } = useRegionEditQuery({
    client,
    variables: { regionID: id },
    onCompleted: ({ region }) => {
      setOriginalInServiceAreaZipSet(new Set(region.zipCodes));
      setInServiceAreaZipText(region.zipCodes.join('\n'));
      setStorageOriginalExtendedServiceAreaZipSet(new Set(region.storageExtendedServiceAreaPostalCodes));
      setStorageExtendedServiceAreaZipText(region.storageExtendedServiceAreaPostalCodes.join('\n'));
      setMovingOriginalExtendedServiceAreaZipSet(new Set(region.movingExtendedServiceAreaPostalCodes));
      setMovingExtendedServiceAreaZipText(region.movingExtendedServiceAreaPostalCodes.join('\n'));
    },
  });

  const [executeMutation, { loading: loadingMutation }] = useModifyRegionMutation({
    client,
    refetchQueries: [
      {
        query: RegionEditDocument,
        variables: { regionID: id },
      },
    ],
  });

  if (querying || !data?.region) {
    return null;
  }

  const { region } = data;

  const inServiceAreaZipCodesSet = new Set(parseZips(inServiceAreaZipText));
  const addedInServiceZipsCount = getZipDifferenceCount(inServiceAreaZipCodesSet, originalInServiceAreaZipSet);
  const removedInServiceZipsCount = getZipDifferenceCount(originalInServiceAreaZipSet, inServiceAreaZipCodesSet);

  const storageExtendedServiceAreaZipCodesSet = new Set(parseZips(storageExtendedServiceAreaZipText));
  const storageAddedExtendedServiceZipsCount = getZipDifferenceCount(
    storageExtendedServiceAreaZipCodesSet,
    storageOriginalExtendedServiceAreaZipSet,
  );
  const storageRemovedExtendedServiceZipsCount = getZipDifferenceCount(
    storageOriginalExtendedServiceAreaZipSet,
    storageExtendedServiceAreaZipCodesSet,
  );

  const movingExtendedServiceAreaZipCodesSet = new Set(parseZips(movingExtendedServiceAreaZipText));
  const movingAddedExtendedServiceZipsCount = getZipDifferenceCount(
    movingExtendedServiceAreaZipCodesSet,
    movingOriginalExtendedServiceAreaZipSet,
  );
  const movingRemovedExtendedServiceZipsCount = getZipDifferenceCount(
    movingOriginalExtendedServiceAreaZipSet,
    movingExtendedServiceAreaZipCodesSet,
  );

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const response = await executeMutation({
      variables: {
        id,
        input: {
          postalCodeLabels: Array.from(inServiceAreaZipCodesSet),
          storageExtendedServiceAreaPostalCodeLabels: Array.from(storageExtendedServiceAreaZipCodesSet),
          movingExtendedServiceAreaPostalCodeLabels: Array.from(movingExtendedServiceAreaZipCodesSet),
        },
      },
    });
    if (!response || !response.data) {
      return;
    }
    if (response.data.modifyRegion.error) {
      setErrorMessage(response.data.modifyRegion.error);
      return;
    }
    window.location.href = regionURL({ id });
  };

  return (
    <>
      <PageHeader>
        <Breadcrumbs name={region.name} regionId={region.id} edit />
      </PageHeader>
      <Panel>
        <Panel.Header>
          <Panel.Title>{region.name}</Panel.Title>
        </Panel.Header>
        <Panel.Body>
          {errorMessage && (
            <div className="alert alert-danger" role="alert">
              {errorMessage}
            </div>
          )}
          <form id="regionedit" onSubmit={(event) => onSubmit(event)}>
            <div className="panel-body">
              <div className="row">
                <div className="col-sm-4">
                  <ZipInput
                    label="In Service Area Zips"
                    value={inServiceAreaZipText}
                    addedZipsCount={addedInServiceZipsCount}
                    removedZipsCount={removedInServiceZipsCount}
                    disabled={loadingMutation}
                    onChange={(e) => setInServiceAreaZipText(e.target.value)}
                  />
                </div>
                <div className="col-sm-4">
                  <ZipInput
                    label="Storage Extended Service Area Zips"
                    value={storageExtendedServiceAreaZipText}
                    addedZipsCount={storageAddedExtendedServiceZipsCount}
                    removedZipsCount={storageRemovedExtendedServiceZipsCount}
                    disabled={loadingMutation}
                    onChange={(e) => setStorageExtendedServiceAreaZipText(e.target.value)}
                  />
                </div>
                <div className="col-sm-4">
                  <ZipInput
                    label="Moving Extended Service Area Zips"
                    value={movingExtendedServiceAreaZipText}
                    addedZipsCount={movingAddedExtendedServiceZipsCount}
                    removedZipsCount={movingRemovedExtendedServiceZipsCount}
                    disabled={loadingMutation}
                    onChange={(e) => setMovingExtendedServiceAreaZipText(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </form>
        </Panel.Body>
        <Panel.Footer>
          <div className="text-right">
            <input
              type="submit"
              form="regionedit"
              className="btn btn-primary"
              value="Save"
              disabled={loadingMutation}
            />
          </div>
        </Panel.Footer>
      </Panel>
    </>
  );
};

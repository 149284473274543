import { capitalize } from 'lodash';

import { SelfStorage__ReservationFragment } from '@admin/schema';

export function getFeaturesForReservation(reservation: SelfStorage__ReservationFragment) {
  if (!reservation.classification) {
    return [];
  }

  return [
    `${reservation.classification.indoors ? 'Indoors' : 'Outdoors'}${
      reservation.classification.firstFloor ? ' (Ground Floor)' : ''
    }`,
    reservation.classification.climate && 'Climate Controlled',
    reservation.classification.obstructed && 'Obstructed',
    reservation.classification.kind.subtype && `${capitalize(reservation.classification.kind.subtype)} Parking`,
  ].filter(Boolean);
}

import React, { useState } from 'react';

import { useField } from 'formik';
import { FormikFieldFormGroup } from '@shared/components/fields/formik/formik_field_form_group';
import { FieldFormGroupProps } from '@shared/components/fields/field_form_group';
import { FileFormControl } from '../file_form_control';

type InputFormGroupProps = FieldFormGroupProps & { name: string; accept: string };

export const FormikFileFormGroup: React.FC<InputFormGroupProps> = ({ accept, name, ...props }) => {
  const [upload, setUpload] = useState<File | undefined>();
  const [_, _1, helpers] = useField(name);

  return (
    <FormikFieldFormGroup name={name} {...props}>
      <div>
        <FileFormControl
          accept={accept}
          onUpload={(file, signedID) => {
            setUpload(file);
            helpers.setValue(signedID);
          }}
          label={upload?.name}
        />
      </div>
    </FormikFieldFormGroup>
  );
};

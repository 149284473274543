import * as React from 'react';
import { useState } from 'react';

import { useItemByBarcodeQuery, ItemFragment } from '@admin/schema';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@shared/components/bootstrap';
import { client } from '@admin/libraries/apollo';

const BARCODE_DOES_NOT_EXIST = 'Item barcode does not exist or does not belong to this account.';
const ITEM_ID_BARCODE_REGEX = /^[A-Za-z\d]*$/;

export const SearchBar: React.FC<{
  accountID: string;
  disabled?: boolean;
  className?: string;
  onItemFound(item?: ItemFragment): void;
}> = ({ accountID, disabled, className, onItemFound }) => {
  const [query, setQuery] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  const { data, loading } = useItemByBarcodeQuery({
    client,
    variables: {
      accountID,
      barcode: query,
    },
    skip: !query || query.length < 7,
  });

  const onBarcodeSearch = () => {
    if (!!data && !!data.item) {
      onItemFound(data.item);
      setErrorMessage('');
    } else {
      onItemFound(undefined);
      setErrorMessage(BARCODE_DOES_NOT_EXIST);
    }
  };

  const onQueryChange = (newQuery: string) => {
    if (ITEM_ID_BARCODE_REGEX.test(newQuery)) {
      setQuery(newQuery);
    }
  };

  return (
    <div className={className}>
      <div>
        <div className="input-group" style={{ width: '240px' }}>
          <input
            name="item-search-input"
            className="form-control"
            type="text"
            placeholder="Item Barcode"
            disabled={disabled}
            value={query}
            onChange={(event) => onQueryChange(event.target.value)}
          />
          <span className="input-group-btn">
            <Button
              name="item-search-btn"
              kind="primary"
              type="button"
              disabled={loading || disabled}
              onClick={onBarcodeSearch}
            >
              <FontAwesomeIcon icon="search" />
            </Button>
          </span>
        </div>
      </div>
      <div>
        <div>
          <p className="text-danger">{errorMessage}</p>
        </div>
      </div>
    </div>
  );
};

(function () {
  angular.module('app').service('CalendarService', [
    function () {
      const SECTION_KINDS = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];

      function Section(kind, day) {
        this.kind = kind;
        this.day = day;
      }

      function Entry(value) {
        this.value = value;
        this.date = value.date();
        this.key = this.value.format('YYYY-MM-DD');
      }

      function Calendar() {
        this.focus = moment.utc();
        this.sections = _.map(SECTION_KINDS, function (kind, index) {
          return new Section(kind, index);
        });

        this.reset = function () {
          const focus = this.focus;
          const start = moment(focus).startOf('month');
          const until = moment(focus).endOf('month');
          this.entries = (function () {
            const results = [];
            for (let date = start.date(); date <= until.date(); date++) {
              results.push(new Entry(moment(focus).date(date)));
            }
            return results;
          })();

          let padded = [];
          padded = padded.concat(new Array(moment(start).diff(moment(start).startOf('week'), 'days')));
          padded = padded.concat(this.entries);
          padded = padded.concat(new Array(moment(until).endOf('week').diff(moment(until), 'days')));
          this.chunks = _.chunk(padded, this.sections.length);
        };

        this.title = function () {
          return this.focus.format('MMMM YYYY');
        };

        this.next = function () {
          this.focus.add(1, 'months');
          this.reset();
        };

        this.prev = function () {
          this.focus.subtract(1, 'months');
          this.reset();
        };

        this.reset();
      }

      this.initialize = function () {
        return new Calendar();
      };
    },
  ]);
})();

import { FontWeight } from '@clutter/clean';
import styled from '@emotion/styled';
import React from 'react';

import { Panel } from '@admin/components/helpers/panel';
import { LabelledAttribute } from '@admin/components/helpers/labelled_attribute';
import { Summary as PromotionSummary } from '@admin/components/self_storage/promotions/summary';
import { SelfStorage__ReservationFragment } from '@admin/schema';
import { formatDate } from '@shared/utils';

import { Form } from './form';
import { getFeaturesForReservation } from '../utils';

const PLACEHOLDER = '-';

const Heading = styled.div`
  font-size: 16px;
  font-weight: ${FontWeight.Medium};
  margin-bottom: 8px;
`;

const SectionContainer = styled.div`
  margin-bottom: 24px;
`;

export const Container: React.FC<{ reservation: SelfStorage__ReservationFragment; onSave(): void }> = ({
  reservation,
  onSave,
}) => {
  const { width, length, unitType, quotedPrice, promotion, facility, name, email, phone, date, smsSubscribed } =
    reservation;
  const { address, bookingLink, partner, phoneNumber } = facility;

  return (
    <Panel>
      <Panel.Header>
        <Panel.Title>Edit Reservation</Panel.Title>
      </Panel.Header>
      <Panel.Body>
        <div className="row">
          <SectionContainer className="col-md-6">
            <Heading>Unit Details</Heading>
            <LabelledAttribute label="Size" attribute={`${width} × ${length} ${unitType}`} />
            <LabelledAttribute label="Amenities" attribute={getFeaturesForReservation(reservation).join(', ')} />
            <LabelledAttribute label="Price" attribute={`$${quotedPrice}/mo`} />
            <LabelledAttribute
              label="Promotion"
              attribute={promotion ? <PromotionSummary promotion={promotion} /> : PLACEHOLDER}
            />
          </SectionContainer>
          <SectionContainer className="col-md-6">
            <Heading>Facility Details</Heading>
            <LabelledAttribute label="Company" attribute={partner?.name || PLACEHOLDER} />
            <LabelledAttribute
              label="Address"
              attribute={`${address.street}, ${address.city}, ${address.state} ${address.zip}`}
            />
            <LabelledAttribute label="Phone Number" attribute={phoneNumber || PLACEHOLDER} />
          </SectionContainer>
        </div>
        <SectionContainer>
          <Heading>Reservation Details</Heading>
          <LabelledAttribute label="Name" attribute={name || PLACEHOLDER} />
          <LabelledAttribute label="Email" attribute={email || PLACEHOLDER} />
          <LabelledAttribute label="Phone" attribute={phone || PLACEHOLDER} />
          <LabelledAttribute label="Move-in Date" attribute={date ? formatDate(date) : PLACEHOLDER} />
          <LabelledAttribute label="Text Messages" attribute={smsSubscribed ? 'Yes' : 'No'} />
          <LabelledAttribute
            label="Booking Link"
            attribute={
              <a className="btn-link" target="_blank" href={bookingLink}>
                {bookingLink}
              </a>
            }
          />
        </SectionContainer>
        <hr />
        <Form reservation={reservation} onSave={onSave} />
      </Panel.Body>
    </Panel>
  );
};

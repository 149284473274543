(function () {
  angular.module('app').factory('Phone', [
    '$resource',
    function ($resource) {
      return $resource(
        '/phone/:id.json',
        {
          id: '@id',
        },
        {
          plausible: {
            url: '/phone/plausible.json',
            method: 'POST',
          },
          update: {
            url: '/phones/:id.json',
            method: 'PUT',
          },
        },
      );
    },
  ]);
})();

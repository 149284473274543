import * as React from 'react';
import { useState } from 'react';

import { client } from '@admin/libraries/apollo';
import { useDispatchAuditOrderEventsQuery } from '@admin/schema';

import { Panel } from '@admin/components/helpers/panel';

import { OrderAuditImages } from './order_audit_images';

const DEFAULT_COLLAPSED = false;

export const AuditOrderEventImagesPanel: React.FC<{ dispatchID: string; timezone: string }> = ({
  dispatchID,
  timezone,
}) => {
  const { data } = useDispatchAuditOrderEventsQuery({
    client,
    variables: { dispatchID },
  });

  const [collapsed, setCollapsed] = useState<boolean>(DEFAULT_COLLAPSED);

  return (
    <Panel>
      <Panel.Header>
        <Panel.Collapse collapsed={collapsed} onChange={setCollapsed} />
        <Panel.Title>Order Audit Images</Panel.Title>
      </Panel.Header>
      <Panel.Body>
        {data?.dispatchAuditOrderEvents && (
          <OrderAuditImages auditOrderEvents={data.dispatchAuditOrderEvents} timezone={timezone} />
        )}
      </Panel.Body>
    </Panel>
  );
};

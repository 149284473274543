import React from 'react';

import { Currency } from '@shared/components/helpers';
import { Pricing, Subscription } from '@admin/schema';

type AngularOrGraphqlSubscription = Pick<Subscription, 'id' | 'name' | 'quantity'> & {
  // @deprecated This type is not available via GraphQL and is used inconsistently
  display_quantity?: number;
  pricing: Pick<Pricing, 'amount' | 'currency'>;
};

export const Summary: React.FC<{
  subscriptions: AngularOrGraphqlSubscription[];
  showCost: boolean;
}> = ({ subscriptions, showCost = false }) => {
  if (!subscriptions) {
    return null;
  }

  const lastSubscriptionIndex = subscriptions.length - 1;

  return (
    <span>
      {subscriptions.map((subscription, index) => (
        <span key={subscription.id}>
          {subscription.display_quantity} × {subscription.name}
          {showCost && (
            <span>
              {' '}
              (
              <Currency
                value={subscription.pricing.amount * subscription.quantity}
                currency={subscription.pricing.currency}
              />{' '}
              per month)
            </span>
          )}
          {index < lastSubscriptionIndex && ', '}
        </span>
      ))}
    </span>
  );
};

import moment from 'moment';

(function () {
  const app = angular.module('app');

  app.controller('WorkforceSurveyIndexController', [
    '$scope',
    'WorkforceSurvey',
    function ($scope, WorkforceSurvey) {
      WorkforceSurvey.query((data) => {
        $scope.surveys = data;
      });
    },
  ]);

  app.controller('WorkforceSurveyShowController', [
    '$scope',
    '$stateParams',
    'WorkforceSurvey',
    function ($scope, $stateParams, WorkforceSurvey) {
      $scope.id = $stateParams.id;
      $scope.survey = WorkforceSurvey.get({ id: $stateParams.id });
    },
  ]);

  app.controller('WorkforceSurveyEditController', [
    '$rootScope',
    '$scope',
    '$state',
    '$stateParams',
    'WorkforceSurvey',
    function ($rootScope, $scope, $state, $stateParams, WorkforceSurvey) {
      $scope.survey = WorkforceSurvey.get({ id: $stateParams.id });
      $scope.survey.$promise.then(() => {
        $scope.today = new Date();
        $scope.survey.starts_on = new Date($scope.survey.starts_on);

        // Using this hack as mentioned in
        // https://github.com/angular-ui/bootstrap/issues/2628
        // due to the bug in angular.
        $scope.survey.starts_on.setMinutes(
          $scope.survey.starts_on.getMinutes() + $scope.survey.starts_on.getTimezoneOffset(),
        );
      });

      $scope.save = () => {
        $scope.survey.starts_on = moment($scope.survey.starts_on).day('Monday').toDate();

        $scope.survey.$update(
          () => {
            $state.go('surveys');
          },
          (error) => {
            $rootScope.$broadcast('message:group:display', error.data);
          },
        );
      };
    },
  ]);

  app.controller('WorkforceSurveyCreateController', [
    '$rootScope',
    '$scope',
    '$state',
    'WorkforceSurvey',
    function ($rootScope, $scope, $state, WorkforceSurvey) {
      $scope.survey = new WorkforceSurvey();
      $scope.survey.busy_days = [];
      $scope.survey.min_required_busy_days = 0;
      const week = moment(new Date()).isoWeek();
      $scope.survey.starts_on = moment().utc().day('Monday').week(week).toDate();

      // Show current week or more
      $scope.today = new Date();

      $scope.addSurvey = () => {
        $scope.survey.starts_on = moment($scope.survey.starts_on).day('Monday').toDate();

        $scope.survey.$save(
          () => {
            $state.go('surveys');
          },
          (error) => {
            $rootScope.$broadcast('message:group:display', error.data);
          },
        );
      };
    },
  ]);
})();

import { Dialog, useConfirmation } from '@admin/components/confirmation';
import { DateTimePicker } from '@admin/components/helpers/date_time_picker';
import { Button } from '@shared/components/bootstrap';
import axios from 'axios';
import { DateTime } from 'luxon';

import React, { useState } from 'react';

export const Uncancel = ({ tz, orderID }: { tz: string; orderID: string }) => {
  const [newDate, setNewDate] = useState(() => DateTime.local().minus({ minutes: 1 }));
  const { confirmation, confirm } = useConfirmation();

  const handleUncancel = async () => {
    if (newDate.toMillis() > Date.now()) {
      const scheduled = newDate.setZone(tz, { keepLocalTime: true }).toISO();
      await axios.patch(`/orders/${orderID}/uncancel.json`, { scheduled_at: scheduled });
      await confirm({ title: 'Order successfully uncanceled', description: '', showCancel: false });
      location.reload();
    } else {
      confirm({ title: 'Uhoh', description: 'Please select a date in the future', showConfirm: false });
    }
  };

  return (
    <span>
      Uncancel and Reschedule to:
      <div id="uncancel_scheduled" className="form-control-static">
        <DateTimePicker dt={newDate} onChange={(val) => val && setNewDate(val)} allowPastDate={false} />
      </div>
      <Button kind="danger" onClick={handleUncancel}>
        Uncancel
      </Button>
      {confirmation && <Dialog confirmation={confirmation} />}
    </span>
  );
};

import * as React from 'react';
import { useEffect, useState } from 'react';

import { DEFAULT_HEIGHT } from '@admin/utils/subscribe';

import { IAccountPackage, ILaborRate } from '@admin/types';

import {
  AppointmentQuote,
  PlanKindEnum,
  PricingGroupLaborEntryFragment,
  PricingGroupPricingEntryFragment,
  PricingSetFragment,
  Pricing__QuotableEnum,
  Pricing__SourceEnum,
} from '@admin/schema';

import {
  dimensionsForCustomPricingGroupEntry,
  filteredPricingGroupEntries,
  priceForCustomPricingGroupEntry,
  suggestedProtectionPricingGroupEntry,
  suggestedStoragePricingGroupEntry,
} from '@admin/utils/subscribe';

import { OnboardingAppointmentPricing } from '../appointment_pricing/onboarding_appointment_pricing';
import { UpgradeDowngradeAppointmentPricing } from '../appointment_pricing/upgrade_downgrade_appointment_pricing';

import { PricingSetFetcher } from './pricing_set_fetcher';

import { ProtectionFormGroup } from './protection_form_group';
import { StorageFormGroup } from './storage_form_group';
import { Total } from './total';

import { Selector as RateGroupSelector } from '../rate_group/selector';

import { CustomDimensionsFormGroup } from './custom_dimensions_form_group';

export const Subscribe: React.FC<{
  cuft?: number;
  zip?: string;
  accountID: number;
  reonboard: boolean;
  onChange(_: {
    activePricingSet?: PricingSetFragment;
    labor?: PricingGroupLaborEntryFragment;
    laborRate?: ILaborRate;
    storage?: PricingGroupPricingEntryFragment;
    protection?: PricingGroupPricingEntryFragment;
    length?: number;
    width?: number;
    quantity?: number;
    accountPackages?: IAccountPackage[];
    appointmentQuote?: AppointmentQuote;
  }): void;
}> = ({ cuft, reonboard, zip, accountID, onChange }) => {
  const [activePricingSet, setActivePricingSet] = useState<PricingSetFragment | undefined>(undefined);
  const [labor, setLabor] = useState<PricingGroupLaborEntryFragment | undefined>(undefined);
  const [laborRate, setLaborRate] = useState<ILaborRate | undefined>(undefined);
  const [storage, setStorage] = useState<PricingGroupPricingEntryFragment | undefined>(undefined);
  const [protection, setProtection] = useState<PricingGroupPricingEntryFragment | undefined>(undefined);
  const [length, setLength] = useState<number | undefined>(undefined);
  const [width, setWidth] = useState<number | undefined>(undefined);
  const [quantity, setQuantity] = useState<number | undefined>(undefined);
  const [msrp, setMSRP] = useState<number | undefined>(undefined);
  const [customCuft, setCustomCuft] = useState<number | undefined>(undefined);
  const [accountPackages, setAccountPackages] = useState<IAccountPackage[]>([]);
  const [appointmentQuote, setAppointmentQuote] = useState<AppointmentQuote | undefined>(undefined);

  // Default an entry for protection on fetching an active pricing set.
  useEffect(() => {
    if (!activePricingSet || protection) {
      return;
    }
    const entries = activePricingSet.protectionPricingGroupEntries;
    setProtection(suggestedProtectionPricingGroupEntry(entries));
  }, [activePricingSet]);

  useEffect(() => {
    onChange({
      labor,
      laborRate,
      storage,
      protection,
      length,
      width,
      quantity,
      activePricingSet,
      accountPackages,
      appointmentQuote,
    });
  }, [
    labor,
    laborRate,
    storage,
    protection,
    length,
    width,
    quantity,
    activePricingSet,
    accountPackages,
    appointmentQuote,
  ]);

  useEffect(() => {
    const dimensions = dimensionsForCustomPricingGroupEntry(storage, cuft);
    if (dimensions) {
      setLength(dimensions.length);
      setWidth(dimensions.width);
    } else {
      setLength(undefined);
      setWidth(undefined);
    }
  }, [storage, cuft]);

  useEffect(() => {
    const price = priceForCustomPricingGroupEntry(storage, length, width, activePricingSet);
    setMSRP(price);
    setQuantity(price);
  }, [length, width, storage]);

  useEffect(() => {
    if (!labor || !cuft || !activePricingSet) {
      return;
    }
    const entries = filteredPricingGroupEntries(activePricingSet.storagePricingGroupEntries, labor.rateGroup?.id);
    let suggestion;
    if (storage?.pricing?.plan?.cuft) {
      suggestion = suggestedStoragePricingGroupEntry(entries, storage?.pricing?.plan?.cuft);
    } else {
      suggestion = suggestedStoragePricingGroupEntry(entries, cuft);
    }

    setStorage(suggestion);
  }, [cuft, labor]);

  useEffect(() => {
    if (length && width) {
      setCustomCuft(length * width * DEFAULT_HEIGHT);
    }
  }, [length, width]);

  const custom = storage && storage.pricing.plan.kind === PlanKindEnum.Custom;
  const selectedCuft = customCuft || storage?.pricing?.plan?.cuft || 0;

  const setAppointmentData = (
    updatedAccountPackages: IAccountPackage[],
    updatedLaborRate?: ILaborRate,
    updatedAppointmentQuote?: AppointmentQuote,
  ) => {
    setLaborRate(updatedLaborRate);
    setAccountPackages(updatedAccountPackages);
    setAppointmentQuote(updatedAppointmentQuote);
  };

  return (
    <PricingSetFetcher zip={zip} accountID={accountID} source="admin_reonboarding" onFetch={setActivePricingSet}>
      <div className="panel">
        <div className="panel-body">
          <div className="form-horizontal">
            {activePricingSet && (
              <div className="form-group">
                <label className="col-sm-2 control-label">Rate Group:</label>
                <div className="col-sm-5">
                  <RateGroupSelector
                    initialRateGroupId={labor?.rateGroup?.id}
                    pricingSetId={activePricingSet.id}
                    onSelect={(rateGroupId?: string) => {
                      if (rateGroupId) {
                        const selectedLabor = activePricingSet.laborPricingGroupEntries.find(
                          (entry) => entry.rateGroup?.id === rateGroupId,
                        );
                        setLabor(selectedLabor);
                      }
                    }}
                  />
                </div>
              </div>
            )}
            <ProtectionFormGroup protection={protection} onSelect={setProtection} />
            <StorageFormGroup
              storage={storage}
              quantity={quantity}
              rateGroupId={labor?.rateGroup?.id}
              onSelect={setStorage}
            />
            {custom && (
              <CustomDimensionsFormGroup
                length={length}
                width={width}
                quantity={quantity}
                msrp={msrp}
                onLength={setLength}
                onWidth={setWidth}
                onQuantity={setQuantity}
              />
            )}
            <Total storage={storage} protection={protection} quantity={quantity} />
            <hr />
            <div className="form-group">
              <label className="col-sm-2 control-label">Additional Onboarding Fees:</label>
            </div>
            {reonboard && (
              <OnboardingAppointmentPricing
                pricingSetId={activePricingSet?.id}
                rateGroupId={labor?.rateGroup?.id}
                isCurbside={labor?.rateGroup?.curbside}
                cuft={selectedCuft}
                zip={zip}
                quotableType={Pricing__QuotableEnum.Account}
                quotableId={accountID}
                source={Pricing__SourceEnum.AdminReonboarding}
                setAppointmentData={setAppointmentData}
              />
            )}
            {!reonboard && (
              <UpgradeDowngradeAppointmentPricing
                accountId={accountID.toString()}
                pricingSet={activePricingSet}
                rateGroupId={labor?.rateGroup?.id}
                isCurbside={labor?.rateGroup?.curbside}
                cuft={selectedCuft}
                setAppointmentData={setAppointmentData}
                zip={zip}
              />
            )}
          </div>
        </div>
      </div>
    </PricingSetFetcher>
  );
};

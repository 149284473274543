import * as React from 'react';
import { useAccountAttachmentsQuery } from '@admin/schema';
import { Spinner } from '@admin/components/spinner';
import { client } from '@admin/libraries/apollo';
import { accountPortalURL } from '@admin/config/routes';
import { AnchorButton as Link, Table, Text } from '@shared/components/bootstrap';

export const Attachments: React.FC<{ accountID: string }> = ({ accountID }) => {
  const { data, loading } = useAccountAttachmentsQuery({ client, variables: { accountID } });
  const ingestedDocuments = data?.account?.ingestedDocuments;

  if (loading) {
    return <Spinner />;
  }

  if (!ingestedDocuments?.length) {
    return (
      <Text alignment="center" tag="p">
        This account does not have any ingested attachments.
      </Text>
    );
  }

  return (
    <Table responsive striped>
      <tbody>
        {ingestedDocuments?.map(({ id, filename, url }) => (
          <tr key={id}>
            <td className="text-left">
              <div>{filename}</div>
            </td>
            <td className="text-right">
              <Link kind="primary" target="_blank" href={`${accountPortalURL}${url}`}>
                Download
              </Link>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

import { DateTime } from 'luxon';
import React from 'react';

import {
  Admin__PaymentPlan__OfferFragment as PaymentPlanOffer,
  Admin__Billing__InvoiceFragment as Invoice,
  PaymentPlan__Offer__State as OfferState,
} from '@admin/schema';

import { LabelledAttribute } from '@admin/components/helpers/labelled_attribute';
import { Currency, Pluralize } from '@shared/components/helpers';
import { capitalize } from '@shared/utils/capitalize';

import { displayableText } from './helpers';

const formatState = (offer: PaymentPlanOffer) => {
  if (offer.state === OfferState.Approved && offer.expired) {
    return <>Expired on {DateTime.fromISO(offer.expiry).toLocaleString(DateTime.DATETIME_MED)}</>;
  } else if (offer.state === OfferState.Approved) {
    return (
      <>
        {capitalize(offer.state)} (expires {DateTime.fromISO(offer.expiry).toLocaleString(DateTime.DATETIME_MED)})
      </>
    );
  } else if (offer.state === OfferState.Accepted) {
    return <>Accepted on {DateTime.fromISO(offer.acceptedAt!).toLocaleString(DateTime.DATETIME_MED)}</>;
  }
  return capitalize(offer.state);
};

const InvoiceList: React.FC<{ invoices: Invoice[] }> = ({ invoices }) => (
  <ul>
    {invoices.map((invoice) => (
      <li key={invoice.id}>
        #{invoice.id} - {invoice.summary} - <Currency value={invoice.amountDue} />
      </li>
    ))}
  </ul>
);

export const Attributes: React.FC<{ offer: PaymentPlanOffer }> = ({ offer }) => (
  <>
    <LabelledAttribute
      label="Created At"
      attribute={DateTime.fromISO(offer.createdAt).toLocaleString(DateTime.DATETIME_MED)}
    />
    <LabelledAttribute label="Created By" attribute={offer.user.name} />
    <LabelledAttribute label="State" attribute={formatState(offer)} />
    <LabelledAttribute label="Plan Type" attribute={displayableText(offer.type)} />
    <LabelledAttribute
      label="Settled Invoices"
      attribute={offer.settledInvoices.length ? <InvoiceList invoices={offer.settledInvoices} /> : '-'}
    />
    <LabelledAttribute
      label="One Time Fee"
      attribute={offer.oneTimeFee ? <Currency value={offer.oneTimeFee} /> : 'N/A'}
    />
    <LabelledAttribute
      label="Storage Term"
      attribute={
        offer.storageTerm && offer.storageTermEndDate ? (
          <>
            <Pluralize count={offer.storageTerm} singular="month" plural="months" /> (ending{' '}
            {DateTime.fromISO(offer.storageTermEndDate).toLocaleString(DateTime.DATE_MED)})
          </>
        ) : (
          'N/A'
        )
      }
    />
    <LabelledAttribute
      label="Monthly Fee"
      attribute={offer.monthlyFee ? <Currency value={offer.monthlyFee} /> : 'N/A'}
    />
    <LabelledAttribute
      label="Monthly Fee Term"
      attribute={
        offer.monthlyFeeTerm && offer.monthlyFeeTermEndDate ? (
          <>
            <Pluralize count={offer.monthlyFeeTerm} singular="month" plural="months" /> (ending{' '}
            {DateTime.fromISO(offer.monthlyFeeTermEndDate).toLocaleString(DateTime.DATE_MED)})
          </>
        ) : (
          'N/A'
        )
      }
    />
    <LabelledAttribute
      label="Expiry Date"
      attribute={DateTime.fromISO(offer.expiry).toLocaleString(DateTime.DATETIME_MED)}
    />
  </>
);

import { SelfStorage__FeeFragment, SelfStorage__Fee__Kind } from '@admin/schema';
import styled from '@emotion/styled';
import { Badge, Checkbox, Radio, Tooltip } from '@shared/components/bootstrap';
import { Currency } from '@shared/components/helpers';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Field } from '../rentals/field';
import { Name } from './name';
import { Summary } from './summary';

const ALREADY_SUBSCRIBED_TOOLTIP_TEXT = 'This fee has already been added by another rental at this facility.';

const LockFeeInputField: React.FC<{
  fee: SelfStorage__FeeFragment;
  selected: boolean;
  onChange(selected: boolean): void;
}> = ({ fee, selected, onChange }) => (
  <>
    <Radio>
      <input type="radio" name="lockFee" checked={selected} onChange={(event) => onChange(event.target.checked)} />
      Purchase New Lock (<Currency value={fee.price} />)
    </Radio>
    <Radio>
      <input type="radio" name="lockFee" checked={!selected} onChange={(event) => onChange(!event.target.checked)} />
      Use Existing Lock (<Currency value={0} />)
    </Radio>
  </>
);

const DefaultFeeInputField: React.FC<{
  isAlreadySubscribed: boolean;
  fee: SelfStorage__FeeFragment;
  selected: boolean;
  onChange(selected: boolean): void;
}> = ({ isAlreadySubscribed, fee, selected, onChange }) => (
  <Checkbox>
    {isAlreadySubscribed ? (
      <input type="checkbox" checked disabled />
    ) : (
      <input
        type="checkbox"
        checked={selected !== fee.waivable}
        onChange={(event) => onChange(event.target.checked !== fee.waivable)}
      />
    )}
    {fee.waivable ? 'Waive' : 'Add'} <Summary fee={fee} />{' '}
    {fee.recurring && (
      <>
        (<Currency value={fee.price} />
        /mo)
      </>
    )}
    {isAlreadySubscribed && (
      <>
        {' '}
        <Tooltip title={ALREADY_SUBSCRIBED_TOOLTIP_TEXT}>
          <Badge style="primary">Included</Badge>
        </Tooltip>
      </>
    )}
  </Checkbox>
);

const FeeInputField: React.FC<{
  fee: SelfStorage__FeeFragment;
  isAlreadySubscribed: boolean;
  selected: boolean;
  onChange(selected: boolean): void;
}> = (props) => {
  const InputField = props.fee.kind === SelfStorage__Fee__Kind.Lock ? LockFeeInputField : DefaultFeeInputField;
  return <InputField {...props} />;
};

const TextArea = styled.textarea`
  resize: vertical;
  min-height: 34px;
`;

const WaiveAdminFeeReasonField: React.FC = () => {
  const { register } = useFormContext<{
    notes: {
      waiveAdminFeeReason?: string;
    };
  }>();

  return (
    <tr>
      <td />
      <td colSpan={2} className="text-left">
        <Field required id="waive-admin-reason-field" name="notes.waiveAdminFeeReason" label="Waive Fee Reason">
          <TextArea
            id="waive-admin-reason-field"
            className="form-control"
            name="notes.waiveAdminFeeReason"
            rows={2}
            ref={register({ required: true, minLength: 4 })}
          />
        </Field>
      </td>
    </tr>
  );
};

export const Row: React.FC<{
  fee: SelfStorage__FeeFragment;
  isAlreadySubscribed: boolean;
  selected: boolean;
  onChange(selected: boolean): void;
}> = ({ selected, fee, isAlreadySubscribed, onChange }) => (
  <>
    <tr>
      <td className="text-left">
        <Name fee={fee} />
      </td>
      <td className="text-left">
        <FeeInputField fee={fee} isAlreadySubscribed={isAlreadySubscribed} onChange={onChange} selected={selected} />
      </td>
      <td className="text-right">{isAlreadySubscribed ? '-' : <Currency value={selected ? fee.price : 0} />}</td>
    </tr>

    {fee.kind === SelfStorage__Fee__Kind.Admin && !selected && <WaiveAdminFeeReasonField />}
  </>
);

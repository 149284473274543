import React, { useState } from 'react';

import { client } from '@admin/libraries/apollo';
import { Hrs__RoleDefault, useHrsRoleDefaultsQuery } from '@admin/schema';

import { Panel } from '@admin/components/helpers/panel';
import { Table, AnchorButton as Link } from '@shared/components/bootstrap';
import { Spinner } from '@admin/components/spinner';
import styled from '@emotion/styled';
import { Role } from '../../users/role';
import { UpsertModal } from './upsert_modal';

const RoleLabels = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  gap: 4px;
`;

export const HRSRoleDefaultsList: React.FC = () => {
  const [showUpsertModal, setShowUpsertModal] = useState<boolean>(false);
  const [editingRoleDefault, setEditingRoleDefault] = useState<Hrs__RoleDefault | undefined>(undefined);

  const { data, loading, refetch } = useHrsRoleDefaultsQuery({
    client,
  });

  return (
    <>
      <Panel>
        <Panel.Body>
          <Table responsive striped>
            <thead>
              <tr>
                <th className="col-sm-2">Job Code & Title</th>
                <th className="col-sm-2">Roles</th>
                <th className="col-sm-1 text-right">
                  <a
                    className="btn btn-mint btn-labeled fa fa-plus"
                    onClick={() => {
                      setEditingRoleDefault(undefined);
                      setShowUpsertModal(true);
                    }}
                  >
                    Add
                  </a>
                </th>
              </tr>
            </thead>

            <tbody>
              {data?.hrsRoleDefaults.map((roleDefault) => (
                <tr key={roleDefault.id}>
                  <td className="col-sm-2">{roleDefault.businessTitle}</td>
                  <td className="col-sm-2">
                    <RoleLabels>
                      {roleDefault.roles.map((role, index) => (
                        <Role key={index} role={role} />
                      ))}
                    </RoleLabels>
                  </td>
                  <td className="col-sm-1 text-right">
                    <Link
                      kind="primary"
                      onClick={() => {
                        setEditingRoleDefault(roleDefault);
                        setShowUpsertModal(true);
                      }}
                    >
                      Edit
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {loading && <Spinner />}
        </Panel.Body>
      </Panel>
      {showUpsertModal && (
        <UpsertModal
          currentBusinessTitles={data?.hrsBusinessTitles ?? []}
          roleDefault={editingRoleDefault}
          closeModal={() => {
            setEditingRoleDefault(undefined);
            setShowUpsertModal(false);
            refetch();
          }}
        />
      )}
    </>
  );
};

import {
  NightTransport__RouteFragment,
  NightTransport__DriveFragment,
  NightTransport__CarpoolFragment,
  NightTransport__ResupplyFragment,
  NightTransport__DriveTaskFragment,
  NightTransport__DispatchFragment,
} from '@admin/schema';

export enum FacilityType {
  Warehouse = 'Warehouse',
  Depot = 'Depot',
}

export enum NightTransportDirection {
  'Inbound',
  'Outbound',
  'Adhoc',
}

type UUID = { uuid: string };

export type NightTransportRoute = Omit<Partial<NightTransport__RouteFragment>, '__typename'> & UUID;

export type NightTransportDrive = Omit<Partial<NightTransport__DriveFragment>, '__typename' | 'driveDispatch'> &
  UUID & { predecessorUUID?: string };
export type NightTransportCarpool = Omit<Partial<NightTransport__CarpoolFragment>, '__typename'> &
  UUID & { driveUUID?: string };
export type NightTransportResupply = Omit<
  Partial<NightTransport__ResupplyFragment>,
  '__typename' | 'resupplyDispatch'
> &
  UUID & { driveUUID?: string };

export type NightTransportAction = NightTransportDrive &
  NightTransportCarpool &
  NightTransportResupply & { __typename?: string; dispatch?: NightTransport__DispatchFragment };

export type NightTransportTask = UUID & {
  id: number;
  routeUUID?: string;
  position: number;
  action: NightTransportAction;
  requiredDriveTaskUUID?: string;
  editing: boolean;
};

export type NightTransportRequiredDrive = NightTransport__DriveTaskFragment & UUID;

export type NightTransportPlan = {
  routes: NightTransportRoute[];
  tasks: NightTransportTask[];
  requiredDriveTasks: Record<string, NightTransportRequiredDrive>;
};

import axios from 'axios';

import { IVehicleTypes } from '../types/vehicle';

class VehicleTypes {
  public async fetch(): Promise<IVehicleTypes | null> {
    try {
      const response = await axios.get('/vehicle_types.json');
      return this.parseResponse(response.data);
    } catch {
      return null;
    }
  }

  private parseResponse = (res: any[]) => {
    const types: { [typeId: string]: string } = {};

    res
      .filter((vt) => vt.field_dispatchable)
      .forEach((vt) => {
        types[vt.id] = vt.name;
      });

    return types;
  };
}

export { VehicleTypes };

import { OrderStateEnum as Order__State } from '@admin/schema';

export const ORDER_STATES: Order__State[] = [
  Order__State.Approved,
  Order__State.Canceled,
  Order__State.Completed,
  Order__State.Confirmed,
  Order__State.Enroute,
  Order__State.Graded,
  Order__State.Packed,
  Order__State.Palletized,
  Order__State.Pending,
  Order__State.Prepared,
  Order__State.Returned,
  Order__State.Unconfirmed,
];

export const ORDER_STATE_TO_NAME: Record<Order__State, string> = {
  [Order__State.Approved]: 'Approved',
  [Order__State.Canceled]: 'Canceled',
  [Order__State.Completed]: 'Completed',
  [Order__State.Confirmed]: 'Confirmed',
  [Order__State.Enroute]: 'Enroute',
  [Order__State.Graded]: 'Graded',
  [Order__State.Packed]: 'Packed',
  [Order__State.Palletized]: 'Palletized',
  [Order__State.Pending]: 'Pending',
  [Order__State.Prepared]: 'Prepared',
  [Order__State.Returned]: 'Returned',
  [Order__State.Unconfirmed]: 'Unconfirmed',
};

export const ORDER_STATE_TO_KIND: Record<Order__State, 'info' | 'success' | 'primary' | 'default' | 'danger'> = {
  [Order__State.Approved]: 'primary',
  [Order__State.Canceled]: 'danger',
  [Order__State.Completed]: 'info',
  [Order__State.Confirmed]: 'default',
  [Order__State.Enroute]: 'default',
  [Order__State.Graded]: 'default',
  [Order__State.Packed]: 'default',
  [Order__State.Palletized]: 'default',
  [Order__State.Pending]: 'default',
  [Order__State.Prepared]: 'default',
  [Order__State.Returned]: 'default',
  [Order__State.Unconfirmed]: 'default',
};

export type OrderStateParam =
  | 'approved'
  | 'canceled'
  | 'completed'
  | 'confirmed'
  | 'enroute'
  | 'graded'
  | 'packed'
  | 'palletized'
  | 'pending'
  | 'prepared'
  | 'returned'
  | 'unconfirmed';

export const ORDER_STATE_TO_PARAM: Record<Order__State, OrderStateParam> = {
  [Order__State.Approved]: 'approved',
  [Order__State.Canceled]: 'canceled',
  [Order__State.Completed]: 'completed',
  [Order__State.Confirmed]: 'confirmed',
  [Order__State.Enroute]: 'enroute',
  [Order__State.Graded]: 'graded',
  [Order__State.Packed]: 'packed',
  [Order__State.Palletized]: 'palletized',
  [Order__State.Pending]: 'pending',
  [Order__State.Prepared]: 'prepared',
  [Order__State.Returned]: 'returned',
  [Order__State.Unconfirmed]: 'unconfirmed',
};

export const ORDER_STATE_FROM_PARAM: Record<OrderStateParam, Order__State> = {
  approved: Order__State.Approved,
  canceled: Order__State.Canceled,
  completed: Order__State.Completed,
  confirmed: Order__State.Confirmed,
  enroute: Order__State.Enroute,
  graded: Order__State.Graded,
  packed: Order__State.Packed,
  palletized: Order__State.Palletized,
  pending: Order__State.Pending,
  prepared: Order__State.Prepared,
  returned: Order__State.Returned,
  unconfirmed: Order__State.Unconfirmed,
};

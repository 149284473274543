import { Availability__BlockReasonEnum, Availability__OrderClassificationEnum } from '@admin/schema';
import { camelCase, capitalize, startCase } from 'lodash';

export const displayableText = (text: string) => text.split('_').map(capitalize).join(' ');

export const CLASSIFICATION_OPTIONS = Object.values(Availability__OrderClassificationEnum)
  .map((val) => ({
    id: val,
    name: displayableText(val),
  }))
  .sort((a, b) => (b.name > a.name ? 1 : -1));

export const formatReasonAsString = (reason: Availability__BlockReasonEnum) => startCase(camelCase(reason));

export const BLOCK_REASON_OPTIONS = Object.values(Availability__BlockReasonEnum).map((val) => ({
  id: val,
  name: formatReasonAsString(val),
}));

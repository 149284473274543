import * as React from 'react';
import { useState } from 'react';

import { Button, Modal, Text } from '@shared/components/bootstrap';

import { client } from '@admin/libraries/apollo';
import { CustomerTicketFragment, Status as StatusType, useResolveCustomerTicketMutation } from '@admin/schema';

export const ResolveButton: React.FC<{ customerTicket: CustomerTicketFragment }> = ({ customerTicket }) => {
  const { id, assignee } = customerTicket;

  const [resolve, { loading: resolving }] = useResolveCustomerTicketMutation({
    client,
    variables: { id },
  });

  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [formError, setFormError] = useState<string>();

  const onResolve = async () => {
    const response = await resolve();
    if (response && response.data) {
      if (response.data.resolveCustomerTicket.status === StatusType.Unprocessable) {
        const errorMessage = response.data.resolveCustomerTicket.error;
        setFormError(`There was a problem resolving this ticket: ${errorMessage}`);
      }
    }
  };

  const onClose = () => {
    setShowConfirm(false);
    setFormError(undefined);
  };

  return (
    <>
      <Button kind="success" onClick={() => setShowConfirm(true)} disabled={resolving}>
        Resolve
      </Button>
      {showConfirm && (
        <Modal centered onClose={onClose}>
          <Modal.Content>
            <Modal.Header>
              <Modal.Close close={onClose} disabled={resolving} />
            </Modal.Header>
            <Modal.Body>
              {!assignee ? (
                <Text style="danger">An assignee is required in order to resolve this customer ticket.</Text>
              ) : (
                <>
                  <p>Are you sure you want to resolve this ticket?</p>
                  {formError && (
                    <Text tag="p" style="danger">
                      {formError}
                    </Text>
                  )}
                  <Button kind="success" onClick={onResolve} disabled={resolving}>
                    Yes
                  </Button>
                  <Button kind="danger" onClick={onClose} disabled={resolving}>
                    No
                  </Button>
                </>
              )}
            </Modal.Body>
          </Modal.Content>
        </Modal>
      )}
    </>
  );
};

import { Dimensions } from '@admin/components/accounts/pricing/edit';
import { FieldFormGroup, NumberFormControl } from '@admin/components/fields';
import { Titleize } from '@admin/components/helpers/titleize';
import { PriceMatch__Kind } from '@admin/schema';
import styled from '@emotion/styled';
import { Radio } from '@shared/components/bootstrap';
import React from 'react';

const Container = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;

const InputGroupList = styled.div`
  display: flex;
`;

const InputGroupSeparator = styled.div`
  padding: 8px;
  line-height: 60px;
`;

const DimensionsFormGroup: React.FC<{
  dimensions?: Dimensions;
  onChange(dimensions: Dimensions): void;
}> = ({ dimensions, onChange }) => (
  <div>
    <InputGroupList>
      <FieldFormGroup label="Length" id="length">
        <div>
          <NumberFormControl
            id="length"
            placeholder="L (feet)"
            value={dimensions?.length}
            min={0}
            onChange={(length) => onChange({ ...dimensions, length })}
          />
        </div>
      </FieldFormGroup>
      <InputGroupSeparator>×</InputGroupSeparator>
      <div>
        <FieldFormGroup label="Width" id="width">
          <NumberFormControl
            id="width"
            placeholder="W (feet)"
            value={dimensions?.width}
            min={0}
            onChange={(width) => onChange({ ...dimensions, width })}
          />
        </FieldFormGroup>
      </div>
      <InputGroupSeparator>×</InputGroupSeparator>
      <FieldFormGroup label="Height" id="height">
        <div>
          <NumberFormControl
            id="height"
            placeholder="4 = H (feet)"
            value={undefined}
            min={0}
            onChange={(height) => onChange({ ...dimensions, height })}
            disabled
          />
        </div>
      </FieldFormGroup>
    </InputGroupList>
  </div>
);

export const CustomFields: React.FC<{
  dimensions?: Dimensions;
  priceMatchKind?: PriceMatch__Kind;
  onChangeDimensions(dimensions: Dimensions): void;
  onChangePriceMatchKind(kind: PriceMatch__Kind): void;
}> = ({ dimensions, priceMatchKind, onChangeDimensions, onChangePriceMatchKind }) => (
  <Container>
    <DimensionsFormGroup dimensions={dimensions} onChange={onChangeDimensions} />
    <div>
      {Object.values(PriceMatch__Kind).map((kind) => (
        <Radio key={kind}>
          <input
            type="radio"
            value={kind}
            checked={priceMatchKind === kind}
            onChange={(event) => {
              if (event.target.checked) {
                onChangePriceMatchKind(kind);
              }
            }}
          />
          <Titleize text={kind} />
        </Radio>
      ))}
    </div>
  </Container>
);

import React, { useState } from 'react';

import { Panel } from '@admin/components/helpers/panel';
import { Table } from './table';

const DEFAULT_COLLAPSED = false;

export const OrderLaborClocksPanel: React.FC<{ orderID: string }> = ({ orderID }) => {
  const [collapsed, setCollapsed] = useState<boolean>(DEFAULT_COLLAPSED);

  return (
    <Panel>
      <Panel.Header>
        <Panel.Collapse collapsed={collapsed} onChange={setCollapsed} />
        <Panel.Title>Labor Clocks</Panel.Title>
      </Panel.Header>
      <Panel.Body>{!collapsed && <Table orderID={orderID} />}</Panel.Body>
    </Panel>
  );
};

import moment from 'moment';

const TRAVEL_BUFFER = 55 * 60 * 1000;

export const getOrderEndTime = (order) => {
  const estimatedDurationInMs = (order.estimated_duration || 0) * 1000;
  const dispatchOffsetInMs = (order.dispatch_offset || 0) * 1000;
  return moment(order.scheduled) + dispatchOffsetInMs + estimatedDurationInMs + TRAVEL_BUFFER;
};

export const getOrderLeaveTime = (order) => getOrderStartTime(order) - TRAVEL_BUFFER;

export const getOrderStartTime = (order) => {
  const dispatchOffsetInMs = (order.dispatch_offset || 0) * 1000;
  return moment(order.scheduled) + dispatchOffsetInMs;
};

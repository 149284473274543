(function () {
  const app = angular.module('app');

  app.service('ItemCategoryService', [
    '$q',
    'ItemCategory',
    function ($q, ItemCategory) {
      const self = this;

      function categorySizingSelectionDisplayName(value, unit) {
        return unit ? String(value) + '-' + unit : String(value);
      }

      function selectableSizingSelections(result) {
        const unit = result.sizing_metric.unit;
        if (result.sizing_metric.range) {
          const range = result.sizing_metric.range.split(',');
          const values = [];
          for (let value = range[0]; value <= range[1]; value++) {
            values.push(categorySizingSelectionDisplayName(value, unit));
          }
          return values;
        } else {
          return _.map(result.sizing_metric.enumerations, function (enumeration) {
            return categorySizingSelectionDisplayName(enumeration, unit);
          });
        }
      }

      function generateItemCategoryOptions() {
        const options = [];
        _.each(self.query, function (pairedItemCategory) {
          if (pairedItemCategory.sizing_metric) {
            _.each(selectableSizingSelections(pairedItemCategory), function (selectableSizingSelection) {
              const selection = selectableSizingSelection.split('-')[0];

              options.push({
                name: pairedItemCategory.name + ' (' + selectableSizingSelection + ')',
                category: pairedItemCategory,
                sizing_selection: selection,
                custom: pairedItemCategory.custom,
              });
            });
          } else {
            options.push({
              name: pairedItemCategory.name,
              category: pairedItemCategory,
              sizing_selection: null,
              custom: pairedItemCategory.custom,
            });
          }
        });
        return options;
      }

      self.query = ItemCategory.query();

      self.findItemCategory = function (id) {
        return self.query.find(function (object) {
          return object.id === id;
        });
      };

      self.findItemCategoryDefaults = function (itemCategory, sizingSelection) {
        if (itemCategory) {
          return itemCategory.defaults[sizingSelection || 'default'];
        }
      };

      const deferred = $q.defer();
      self.options = {
        $promise: deferred.promise,
      };

      self.query.$promise.then(
        function () {
          const options = generateItemCategoryOptions();
          deferred.resolve(options);
        },
        deferred.reject,
        deferred.notify,
      );
    },
  ]);
})();

import React from 'react';

import { Maybe } from '@admin/schema';

import { Toggle } from './toggle';

export const MonthlyFeeInput: React.FC<{
  included: boolean;
  monthlyFee?: Maybe<number>;
  monthlyFeeTerm?: Maybe<number>;
  setIncluded(included: boolean): void;
  onChange(monthlyFee?: Maybe<number>, monthlyFeeTerm?: Maybe<number>): void;
}> = ({ monthlyFee, monthlyFeeTerm, included, onChange, setIncluded }) => {
  const onToggle = (newIncluded: boolean) => {
    if (!newIncluded) {
      onChange(undefined, undefined);
    }
    setIncluded(newIncluded);
  };

  const onFeeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newMonthlyFee = event.currentTarget.value ? Number(event.currentTarget.value) : undefined;
    onChange(newMonthlyFee, monthlyFeeTerm);
  };

  const onTermChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newMonthlyFeeTerm = event.currentTarget.value ? Number(event.currentTarget.value) : undefined;
    onChange(monthlyFee, newMonthlyFeeTerm);
  };

  return (
    <div className="form-group">
      <label className="control-label">Monthly Fee</label>
      <div className="row">
        <Toggle className="col-md-2" included={included} onToggle={onToggle} />
        <div className="col-md-4">
          {included && (
            <div className="row">
              <div className="col-md-6">
                <div className="input-group">
                  <span className="input-group-addon">$</span>
                  <input type="number" className="form-control" value={monthlyFee || ''} onChange={onFeeChange} />
                </div>
              </div>
              <div className="col-md-6">
                <div className="input-group">
                  <input type="number" className="form-control" value={monthlyFeeTerm || ''} onChange={onTermChange} />
                  <span className="input-group-addon">months</span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

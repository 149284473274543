import { Panel } from '@admin/components/helpers/panel';
import React, { useContext, useEffect, useState } from 'react';

import { GanttChart } from '@admin/components/helpers/gantt_chart';
import { Legend } from '@admin/components/helpers/gantt_chart/legend';

import {
  NightTransportRequiredDrive,
  NightTransportRoute,
  NightTransportTask,
} from '@admin/components/night_transport/planner/types';
import { useNightTransportRouteTimelinesLazyQuery } from '@admin/schema';
import { GanttRow } from '@admin/components/helpers/gantt_chart/types';
import { client } from '@admin/libraries/apollo';
import { createRoutesInput, validateTask } from '@admin/components/night_transport/planner/util';
import { Alert } from '@shared/components/bootstrap';
import { Context } from '@admin/components/night_transport/planner/context';
import { createLegendMapping, formatRoutesForGantt } from './util';

export const Timeline: React.FC<{
  routes: NightTransportRoute[];
  tasks: NightTransportTask[];
  requiredDriveTasks: Record<string, NightTransportRequiredDrive>;
}> = ({ routes, tasks, requiredDriveTasks }) => {
  const [routeTimelines, setRouteTimelines] = useState<GanttRow[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>();

  const [getRouteTimelines, { data, error, loading }] = useNightTransportRouteTimelinesLazyQuery({
    client,
  });

  const { tz } = useContext(Context);

  useEffect(() => {
    if (!data || !tz) {
      return;
    }
    setRouteTimelines(formatRoutesForGantt(data.nightTransportRouteTimelines, tz));
  }, [tz, data]);

  useEffect(() => setErrorMessage(error?.message), [error]);

  useEffect(() => {
    const validRoutes = routes.filter((route) => route.driver && route.arrival && route.origin);
    const validTasks = tasks.filter((task) => !validateTask(task));
    const empty = validRoutes.length < 1 || validTasks.length < 1;
    const routesInput = empty ? [] : createRoutesInput(validRoutes, validTasks, requiredDriveTasks);
    const routesWithTasks = routesInput.filter((route) => route.tasks.length > 0);
    setErrorMessage(undefined);
    getRouteTimelines({ variables: { input: { routes: routesWithTasks } } });
  }, [routes, tasks, requiredDriveTasks, getRouteTimelines]);

  return (
    <Panel>
      <Panel.Header>
        <Panel.Title>Timeline</Panel.Title>
      </Panel.Header>
      <Panel.Body>
        {errorMessage && <Alert style="danger">{errorMessage}</Alert>}
        {routeTimelines.length > 0 && tz && <GanttChart loading={loading} rows={routeTimelines} tz={tz} />}
      </Panel.Body>
      <Panel.Footer>
        <div>
          <p>
            <strong>Legend:</strong>
          </p>
          <Legend eventMapping={createLegendMapping()} />
        </div>
      </Panel.Footer>
    </Panel>
  );
};

import template from '@admin/angular/templates/images_upload.html';

import angular from 'angular';

const app = angular.module('app');

function controller() {
  this.attach = (file, reader) => this.uploads.push({ file, reader, state: 'pending' });
}

app.component('imagesUpload', {
  template,
  bindings: {
    uploads: '=',
  },
  controller,
});

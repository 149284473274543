import React, { useContext } from 'react';
import styled from '@emotion/styled';

import { Panel } from '@admin/components/helpers/panel';
import { InputFormGroup } from '@admin/components/fields/input_form_group';
import { SelectFormGroup } from '@admin/components/fields/select_form_group';
import { ClutterGeoEnum } from '@admin/schema';
import { Button, Row, Col } from '@shared/components/bootstrap';

import { Context } from './context';

const FilterButtons = styled.div`
  display: flex;
  gap: 8px;
  justify-content: end;
  padding-top: 24px;
`;

const SlimPanelBody = styled(Panel.Body)`
  padding: 5px 15px 0px;
`;

export enum Mode {
  Viewing,
  Editing,
}

const EditButton: React.FC = () => {
  const { date, geo, loading, onMode } = useContext(Context);

  const onEdit = () => {
    onMode(Mode.Editing);
  };

  return (
    <Button loading={loading} disabled={!date || !geo} kind="primary" onClick={onEdit}>
      Edit
    </Button>
  );
};

const SaveButton: React.FC = () => {
  const { loading, onSave, tasks } = useContext(Context);

  return (
    <Button disabled={tasks.some(({ editing }) => editing)} kind="primary" loading={loading} onClick={onSave}>
      Save
    </Button>
  );
};

const CancelButton: React.FC = () => {
  const { loading, onCancel, onMode } = useContext(Context);

  const handleClick = () => {
    onCancel();
    onMode(Mode.Viewing);
  };

  return (
    <Button kind="default" loading={loading} onClick={handleClick}>
      Cancel
    </Button>
  );
};

const ViewingButtons: React.FC = () => <EditButton />;

const EditingButtons: React.FC = () => (
  <>
    <CancelButton />
    <SaveButton />
  </>
);

const ModeButtons: React.FC = () => {
  const { mode } = useContext(Context);

  const Buttons = (() => {
    switch (mode) {
      case Mode.Viewing:
        return ViewingButtons;
      case Mode.Editing:
        return EditingButtons;
    }
  })();

  return (
    <FilterButtons>
      <Buttons />
    </FilterButtons>
  );
};

const GeoFormGroup: React.FC = () => {
  const { geo, loading, onGeo, mode } = useContext(Context);
  const geos = Object.keys(ClutterGeoEnum).sort();

  return (
    <SelectFormGroup
      name="filter_geo_id"
      label="Geo"
      value={geo ?? ''}
      disabled={mode === Mode.Editing || loading}
      onChange={(event) => {
        onGeo(event.target.value || undefined);
      }}
    >
      <option value="" disabled={!!geo}>
        {' '}
        - Geo -{' '}
      </option>
      {geos.map((geoName) => (
        <option key={geoName} value={geoName}>
          {geoName}
        </option>
      ))}
    </SelectFormGroup>
  );
};

const DateFormGroup: React.FC = () => {
  const { date, onDate, mode, loading } = useContext(Context);

  return (
    <InputFormGroup
      disabled={mode === Mode.Editing || loading}
      label="Date"
      name="filter_date"
      value={date ?? ''}
      onChange={(event) => onDate(event.target.value || undefined)}
      type="date"
    />
  );
};

export const Filters: React.FC = () => (
  <Panel>
    <SlimPanelBody>
      <Row>
        <Col md={4}>
          <GeoFormGroup />
        </Col>
        <Col md={4}>
          <DateFormGroup />
        </Col>
        <Col md={4}>
          <ModeButtons />
        </Col>
      </Row>
    </SlimPanelBody>
  </Panel>
);

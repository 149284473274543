import template from '@admin/angular/templates/plans_preview.html';

(() => {
  const app = angular.module('app');

  app.component('plansPreview', {
    template,
    bindings: {
      plans: '<',
    },
    controller: [
      '$filter',
      function ($filter) {
        const titleize = $filter('titleize');
        const summarize = (quantity, name) => quantity + ' × ' + titleize(name);

        const reset = () => {
          const counted = {};
          _.each(this.plans, (plan) => {
            counted[plan.name] = counted[plan.name] || 0;
            counted[plan.name]++;
          });

          this.summary = _.map(counted, summarize).join(' + ');
        };

        this.$onInit = reset;
        this.$onChanges = reset;
      },
    ],
  });
})();

import React from 'react';

import { Barcode, Item, ItemStateEnum as ItemState } from '@admin/schema';

const STATES: Record<ItemState, string> = {
  [ItemState.Auctioned]: 'auctioned',
  [ItemState.Disposed]: 'disposed',
  [ItemState.Enroute]: 'enroute',
  [ItemState.Flagged]: 'flagged',
  [ItemState.Lost]: 'lost',
  [ItemState.OnTrailer]: 'on-trailer',
  [ItemState.Packed]: 'packed',
  [ItemState.Prepared]: 'prepared',
  [ItemState.Pulled]: 'pulled',
  [ItemState.Returned]: 'returned',
  [ItemState.Stored]: 'stored',
};

export const Summary: React.FC<{
  item: Pick<Item, 'state'> & {
    barcode: Pick<Barcode, 'value'>;
  };
}> = ({ item }) => (
  <>
    #{item.barcode.value} <strong>({STATES[item.state]})</strong>
  </>
);

import { COLORS } from '@clutter/clean';
import { faLock, faUnlock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from '@emotion/styled';
import * as React from 'react';
import { useState } from 'react';

import { Tooltip } from '@shared/components/bootstrap';

import { SelfStorage__RentalFragment } from '@admin/schema';

import { Dialog } from './overlock/dialog';

const Button = styled.button`
  background: none;
  border: none;
  transition: opacity 0.2s;

  &:hover {
    opacity: 0.8;
  }
`;

const LOCKED_TOOLTIP = 'Overlocked unit. Click to unlock.';
const UNLOCKED_TOOLTIP = 'Unit is not overlocked. Click to lock.';

export const Overlock: React.FC<{
  rental: SelfStorage__RentalFragment;
}> = ({ rental }) => {
  const [toggled, setToggled] = useState(false);
  const overlocked = rental.overlocked;

  const onToggle = () => setToggled(!toggled);

  return (
    <>
      {toggled && <Dialog rental={rental} onClose={onToggle} />}
      <Tooltip title={overlocked ? LOCKED_TOOLTIP : UNLOCKED_TOOLTIP}>
        <Button disabled={toggled} title={overlocked ? 'Unlock' : 'Lock'} type="button" onClick={onToggle}>
          <FontAwesomeIcon icon={overlocked ? faLock : faUnlock} color={overlocked ? COLORS.toucan : COLORS.hippo} />
        </Button>
      </Tooltip>
    </>
  );
};

import * as React from 'react';

import { ControlLabel, FormGroup } from '@shared/components/bootstrap';

import { NumberFormControl } from '@admin/components/fields';

export const LongitudeFormGroup: React.FC<{
  longitude?: number;
  disabled?: boolean;
  onChange(longitude?: number): void;
}> = ({ longitude, onChange, disabled }) => (
  <FormGroup>
    <ControlLabel htmlFor="address-longitude">Longitude</ControlLabel>
    <NumberFormControl id="address-longitude" disabled={disabled} value={longitude} onChange={onChange} />
  </FormGroup>
);

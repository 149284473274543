(function () {
  const app = angular.module('app');

  app.directive('enableForRole', [
    'UserService',
    function (UserService) {
      return {
        restrict: 'A',
        link: function (scope, element, attributes) {
          const roles = attributes.enableForRole.split(' ');
          const allowed = _.intersection(roles, UserService.roles).length > 0;
          if (allowed) {
            element.removeAttr('disabled');
          } else {
            element.attr('disabled', 'disabled');
          }

          // Prevent link following if disabled
          $('a').on('click', function (event) {
            if ($(this).is('[disabled]')) {
              event.preventDefault();
            }
          });
        },
      };
    },
  ]);
})();

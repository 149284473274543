import React, { useContext, useState } from 'react';
import styled from '@emotion/styled';
import { useDrag } from 'react-dnd';

import { FacilityFragment, VehicleFragment } from '@admin/schema';
import { UUID } from '@shared/utils';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Tooltip } from '@clutter/clean';
import { DriveFormGroup } from './action/drive_form_group';
import { Context } from './context';
import { FacilityFormGroup } from './facility_form_group';
import { Mode } from './filters';
import { NightTransportAction, NightTransportTask } from './types';
import { InlineSelectFormGroup } from './inline_select_form_group';
import { VehicleFormGroup } from './vehicle_form_group';

type SpecificActionType = 'Extension Drive' | 'Optional Drive' | 'Carpool Task';
type ActionType = {
  action: NightTransportAction;
  type: SpecificActionType;
};

const ACTION_TYPE_TO_ACTION: { [key: string]: string } = {
  'Extension Drive': 'NightTransport__Drive',
  'Optional Drive': 'NightTransport__Drive',
  'Carpool Task': 'NightTransport__Carpool',
};

const TaskItem = styled.div<{ dragging: boolean }>`
  padding: 12px 12px 0;
  background-color: #f2f9ff;
  border-color: #337ab7;
  border-width: 1.8px;
  border-style: solid;
  opacity: ${({ dragging }) => (dragging ? 0.5 : 1.0)};
`;

const TaskTitle = styled.div`
  font-weight: bold;
`;

const TaskForm = styled.div`
  display: flex;
  flex-direction: column;
`;

const DEFAULT_ACTION: NightTransportAction = {
  __typename: 'NightTransport__Drive',
  uuid: UUID(),
};

const DEFAULT_ACTION_TYPE: ActionType = {
  action: DEFAULT_ACTION,
  type: 'Optional Drive',
};

const createTask = (action: NightTransportAction, id: number): NightTransportTask => ({
  uuid: UUID(),
  id,
  position: 0,
  action,
  editing: false,
});

export const OptionalAction: React.FC = () => {
  const { mode, loading, tasks } = useContext(Context);
  const [actionType, setActionType] = useState<ActionType>(DEFAULT_ACTION_TYPE);
  const { action } = actionType;
  const currentDriveUUID = action.__typename === 'NightTransport__Drive' ? action.predecessorUUID : action.driveUUID;
  const validAction =
    action.__typename === 'NightTransport__Drive' ? !!(action.origin && action.destination) : !!action.driveUUID;

  const setAction = (change: NightTransportAction) => {
    setActionType({ ...actionType, action: { ...change } });
  };

  const canDrag = mode === Mode.Editing && validAction && !loading;

  const [{ dragging }, drag] = useDrag(
    () => ({
      type: 'Task',
      item: createTask(action, tasks.length + 1),
      canDrag,
      end(_, monitor) {
        if (monitor.didDrop()) setActionType(DEFAULT_ACTION_TYPE);
      },
      collect: (monitor) => ({
        dragging: monitor.isDragging(),
      }),
    }),
    [mode, action, loading, tasks.length],
  );

  const onChangeType = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setActionType({
      type: event.target.value as SpecificActionType,
      action: { ...DEFAULT_ACTION, __typename: ACTION_TYPE_TO_ACTION[event.target.value]! },
    });
  };

  const onChangeDrive = (driveUUID: string) => {
    if (action.__typename === 'NightTransport__Drive') {
      const predecessor = tasks.find(({ action: taskAction }) => taskAction.uuid === driveUUID);
      setAction({ ...action, predecessorUUID: driveUUID, origin: predecessor?.action.destination });
    } else {
      setAction({ ...action, driveUUID });
    }
  };

  const onChangeOrigin = (origin: FacilityFragment) => {
    setAction({ ...action, origin });
  };

  const onChangeDestination = (destination: FacilityFragment) => {
    setAction({ ...action, destination });
  };

  const onChangeVehicle = (vehicle: VehicleFragment) => {
    setAction({ ...action, vehicle });
  };

  const tooltipText = (() => {
    if (actionType.type === 'Carpool Task') return 'You must select an associated task to enable dragging.';
    else if (actionType.type === 'Extension Drive')
      return 'You must select an associated task and destination to enable dragging.';
    else if (actionType.type === 'Optional Drive')
      return 'You must select an origin and destination to enable dragging.';
  })();

  return (
    <TaskItem ref={drag} dragging={dragging}>
      <Box.Flex gap="4px" margin="0 0 4px 0">
        <TaskTitle>Optional Route Task</TaskTitle>
        {!canDrag && (
          <Tooltip text={tooltipText}>
            <FontAwesomeIcon icon="question-circle" />
          </Tooltip>
        )}
      </Box.Flex>
      <TaskForm>
        <InlineSelectFormGroup
          value={actionType.type}
          label="Task Type"
          onChange={onChangeType}
          name="optional_type"
          disabled={mode !== Mode.Editing || loading}
        >
          <option value="Optional Drive">Optional Drive</option>
          <option value="Extension Drive">Extension Drive</option>
          <option value="Carpool Task">Carpool Task</option>
        </InlineSelectFormGroup>
        {actionType.type !== 'Optional Drive' && (
          <DriveFormGroup
            name="optional_drive_id"
            label="Task #"
            currentDriveUUID={currentDriveUUID}
            onChangeAction={onChangeDrive}
          />
        )}
        {actionType.type === 'Optional Drive' && (
          <>
            <FacilityFormGroup
              name="optional_origin_id"
              label="Origin"
              currentFacility={action.origin}
              onChangeFacility={onChangeOrigin}
              showForm
            />
            <FacilityFormGroup
              name="optional_destination_id"
              label="Dest."
              currentFacility={action.destination}
              onChangeFacility={onChangeDestination}
              showForm
            />
            <VehicleFormGroup
              name="optional_vehicle_id"
              label="Vehicle"
              currentVehicle={action.vehicle || undefined}
              onChangeVehicle={onChangeVehicle}
              showForm
            />
          </>
        )}
        {actionType.type === 'Extension Drive' && (
          <>
            <FacilityFormGroup
              name="optional_origin_id"
              label="Origin"
              currentFacility={action.origin}
              onChangeFacility={onChangeOrigin}
              disabled
              showForm
            />
            <FacilityFormGroup
              name="optional_destination_id"
              label="Dest."
              currentFacility={action.destination}
              onChangeFacility={onChangeDestination}
              showForm
            />
          </>
        )}
      </TaskForm>
    </TaskItem>
  );
};

/* eslint-disable no-param-reassign */

import angular from 'angular';
import isEmpty from 'lodash';
import moment from 'moment';

const app = angular.module('app');

app.controller('SmsInboxController', [
  '$location',
  '$scope',
  'MessageThread',
  'PaginationService',
  'FiltersService',
  'ConfirmationService',
  'UserService',
  'Message',
  'MessageCacheService',
  'ErrorService',
  'MessageObjection',
  'LeadObjectionService',
  'MessageFollowup',
  'FollowupService',
  'SmsConstants',
  function SmsInboxController(
    $location,
    $scope,
    MessageThread,
    PaginationService,
    FiltersService,
    ConfirmationService,
    UserService,
    Message,
    MessageCacheService,
    ErrorService,
    MessageObjection,
    LeadObjectionService,
    MessageFollowup,
    FollowupService,
    SmsConstants,
  ) {
    const setDefaultFilters = (filters) => {
      if (isEmpty(filters)) {
        filters = { state: 'pending' };

        if (UserService.in_sales_groups) {
          filters.department = 'sales';
        } else if (UserService.in_care_groups) {
          filters.department = 'care';
        }
      }

      return filters;
    };

    const afterSave = (messageThread) => {
      messageThread.requestPending = false;
      messageThread.state = 'resolved';
      messageThread.unread_count = 0;
      messageThread.touched = true;
    };

    const afterError = (error, messageThread) => {
      messageThread.requestPending = false;
      ErrorService.handle(error);
    };

    this.$onInit = () => {
      $scope.filters = setDefaultFilters($location.search());
      $scope.pagination = PaginationService.initialize({ page: $location.search().page });
      $scope.filter();
      LeadObjectionService.list(LeadObjectionService.VISIBILITY_SMS).then((data) => {
        $scope.objectionReasons = data;
      });
      $scope.maxDisplayLength = SmsConstants.MAX_TEXT_DISPLAY;
    };

    $scope.resolveMessage = function resolveMessage(messageThread) {
      ConfirmationService.confirm({
        title: 'Are you sure?',
        description: 'Are you sure you want to resolve this message thread?',
        acceptButton: 'Resolve!',
        declineButton: 'Cancel',
      }).then(() => {
        messageThread.requestPending = true;
        const params = {
          recipient_id: messageThread.recipient_id,
          recipient_type: messageThread.recipient_type,
        };

        MessageThread.resolve(params).$promise.then(() => {
          afterSave(messageThread);
        });
      });
    };

    $scope.isPending = (messageThread) => messageThread.state === 'pending';
    $scope.isPendingAccount = (messageThread) =>
      $scope.isPending(messageThread) && messageThread.recipient_type === 'Account';
    $scope.isPendingLead = (messageThread) =>
      $scope.isPending(messageThread) && messageThread.recipient_type === 'Lead';

    $scope.isOverdue = function isOverdue(messageThread) {
      const hours = moment.duration(moment().diff(messageThread.created_at)).hours();
      return messageThread.kind === 'inbound' && $scope.isPending(messageThread) && hours > 5;
    };

    $scope.filter = function filterMesssages(filters) {
      MessageCacheService.clearCache();
      if (!filters) {
        filters = $scope.filters || {};
      } else {
        $scope.pagination.reset();
      }

      filters = FiltersService.cleanup(filters);
      filters.page = $scope.pagination.page;
      $location.search(filters);

      MessageThread.get(filters).$promise.then((data) => {
        $scope.pagination.prepare(data.pagination);
        $scope.message_threads = data.results;
      });

      $scope.linkToMessages = function linkToMessages(recipientType, recipientId) {
        let link;
        if (recipientType === 'Account') {
          link = `viewAccount({ id: ${recipientId}, scroll: 'messages-component', tab: 'Messages' })`;
        } else if (recipientType === 'Lead') {
          link = `editLead({ id: ${recipientId}, scroll: 'messages-component' })`;
        } else if (recipientType === 'Assignment') {
          link = `viewDispatch({ id: ${recipientId}, scroll: 'messages-component' })`;
        }
        return link;
      };
    };

    $scope.replyTo = (messageThread, body) => {
      messageThread.requestPending = true;
      const message = new Message({
        body,
        phone_id: messageThread.phone_id,
        recipient_id: messageThread.recipient_id,
        recipient_type: messageThread.recipient_type,
      });
      message
        .$save()
        .then(() => afterSave(messageThread))
        .catch((error) => afterError(error, messageThread));
    };

    $scope.closeLead = (messageThread) => {
      messageThread.requestPending = true;
      const messageObjection = new MessageObjection({
        message_id: messageThread.id,
        objection_reason: messageThread.objection_reason,
        recipient_id: messageThread.recipient_id,
        recipient_type: messageThread.recipient_type,
      });
      messageObjection
        .$save()
        .then(() => afterSave(messageThread))
        .catch((error) => afterError(error, messageThread));
    };

    $scope.resolveButtonPresent = (messageThread) =>
      $scope.isPendingAccount(messageThread) && messageThread.unread_count && !messageThread.attachment_count;

    $scope.closeButtonPresent = (messageThread) =>
      $scope.isPendingLead(messageThread) &&
      !LeadObjectionService.isFollowupScheduledObjection(messageThread.objection_reason);

    $scope.scheduleButtonPresent = (messageThread) =>
      $scope.isPendingLead(messageThread) &&
      LeadObjectionService.isFollowupScheduledObjection(messageThread.objection_reason);

    $scope.scheduleFollowUp = (messageThread, timeObj) => {
      messageThread.requestPending = true;

      const messageFollowup = new MessageFollowup({
        dst_flag: timeObj.timeZone.dst_flag,
        followup_scheduled_at: FollowupService.getFollowupTime(timeObj),
        message_id: messageThread.id,
        objection_reason: messageThread.objection_reason,
        phone_id: messageThread.phone_id,
        recipient_id: messageThread.recipient_id,
        recipient_type: messageThread.recipient_type,
        time_zone_offset: timeObj.timeZone.time_zone_offset,
      });

      messageFollowup
        .$save()
        .then(() => afterSave(messageThread))
        .catch((error) => afterError(error, messageThread));
    };

    $scope.clearObjection = function clearObjection(messageThread) {
      messageThread.objection_reason = '';
    };
  },
]);

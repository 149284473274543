import React from 'react';

import { Item, ItemStateEnum as Item__State } from '@admin/schema';

import { Label } from '@admin/components/helpers/label';

const ITEM_STATE_TO_NAME: Record<Item__State, string> = {
  [Item__State.Auctioned]: 'Auctioned',
  [Item__State.Disposed]: 'Disposed',
  [Item__State.Enroute]: 'Enroute',
  [Item__State.Flagged]: 'Flagged',
  [Item__State.Lost]: 'Lost',
  [Item__State.OnTrailer]: 'On-Trailer',
  [Item__State.Packed]: 'Packed',
  [Item__State.Prepared]: 'Preapred',
  [Item__State.Pulled]: 'Pulled',
  [Item__State.Returned]: 'Returned',
  [Item__State.Stored]: 'Stored',
};

const ITEM_STATE_TO_KIND: Record<Item__State, 'danger' | 'info' | 'muted'> = {
  [Item__State.Auctioned]: 'info',
  [Item__State.Disposed]: 'info',
  [Item__State.Enroute]: 'info',
  [Item__State.Flagged]: 'danger',
  [Item__State.Lost]: 'danger',
  [Item__State.OnTrailer]: 'info',
  [Item__State.Packed]: 'info',
  [Item__State.Prepared]: 'info',
  [Item__State.Pulled]: 'info',
  [Item__State.Returned]: 'info',
  [Item__State.Stored]: 'info',
};

export const ItemState: React.FC<{ item: Pick<Item, 'state'> }> = ({ item: { state } }) => (
  <Label kind={ITEM_STATE_TO_KIND[state]}>{ITEM_STATE_TO_NAME[state]}</Label>
);

(function () {
  angular.module('app').factory('Ship', [
    '$resource',
    function ($resource) {
      return $resource('/accounts/:account_id/orders/:order_id/ship.json', {
        account_id: '@account_id',
        order_id: '@order_id',
      });
    },
  ]);
})();

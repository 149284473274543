import axios, { AxiosResponse } from 'axios';
import { DateTime } from 'luxon';
import React, { useEffect, useMemo, useState } from 'react';

import { GoogleMap, GoogleMapProps, Polyline, withGoogleMap } from 'react-google-maps';

import { useAxiosRequest } from '@admin/hooks';
import { useDebounce } from '@shared/hooks';

import { Panel } from '@admin/components/helpers/panel';

const Filters: React.FC<{
  date?: string; // i.e. yyyy-mm-dd
  onChange(date: string | undefined): void;
}> = ({ date, onChange }) => (
  <form className="form-inline mar-btm">
    <div className="form-grouping">
      <label>Date:</label>{' '}
      <input
        type="date"
        className="form-control form-control-fit"
        value={date ?? ''}
        onChange={(event) => onChange(event.target.value || undefined)}
      />
    </div>
  </form>
);

type Checkin = {
  id: number;
  latitude: number;
  longitude: number;
  timestamp: string;
};

type Props = {
  id: string | number;
  type: 'users' | 'vehicles';
};

const GoogleMapWithJS = withGoogleMap(GoogleMap);

const GoogleMapWithDOM: React.FC<GoogleMapProps> = (props) => (
  <GoogleMapWithJS
    {...props}
    containerElement={<div style={{ height: `320px` }} />}
    mapElement={<div style={{ height: `100%` }} />}
  />
);

const DEFAULT_ZOOM = 11;

const DEFAULT_CENTER = {
  lat: +34.0,
  lng: -118.5,
};

const POLYLINE_OPTIONS = {
  strokeColor: '#E74C3C',
  strokeWeight: 2.0,
  strokeOpacity: 0.8,
};

export const Map: React.FC<Props> = ({ id, type }) => {
  const [date, setDate] = useState<string | undefined>(() => DateTime.local().toISODate());
  const debouncedDate = useDebounce(date);
  const [request, setRequest] = useState<Promise<AxiosResponse<Checkin[]>> | undefined>();

  useEffect(() => {
    setRequest(axios.get(`/${type}/${id}/checkins.json`, { params: { date: debouncedDate } }));
  }, [id, type, debouncedDate]);

  const { response } = useAxiosRequest(request);

  const path = useMemo(
    () =>
      response?.data.map((checkin) => ({
        lat: Number(checkin.latitude),
        lng: Number(checkin.longitude),
      })),
    [response?.data],
  );

  const center = useMemo(() => {
    if (!path?.length) return DEFAULT_CENTER;
    const lats = path.map(({ lat }) => lat);
    const lngs = path.map(({ lng }) => lng);
    return {
      lat: (Math.min(...lats) + Math.max(...lats)) / 2.0,
      lng: (Math.min(...lngs) + Math.max(...lngs)) / 2.0,
    };
  }, [path]);

  return (
    <Panel>
      <Panel.Header>
        <Panel.Title>Checkins</Panel.Title>
      </Panel.Header>
      <Panel.Body>
        <Filters date={date} onChange={setDate} />
        <GoogleMapWithDOM center={center} zoom={DEFAULT_ZOOM}>
          {path && <Polyline path={path} options={POLYLINE_OPTIONS} />}
        </GoogleMapWithDOM>
      </Panel.Body>
    </Panel>
  );
};

import React from 'react';
import { Formik, Form as FormikForm } from 'formik';

import { Workforce__PtoRequestGroupReview__Input, usePtoRequestGroupReviewMutation } from '@admin/schema';

import { client } from '@admin/libraries/apollo';
import { FormikInputFormGroup } from '@shared/components/fields/formik/formik_input_form_group';
import { Button, Panel, Modal } from '@shared/components/bootstrap';
import { FormikRadioFormGroup } from '@admin/components/fields/formik/formik_radio_form_group';
import { DateTime } from 'luxon';
import { Spacer } from '@shared/components/helpers';

type PTORequestType = {
  date: string;
  hours: number;
};

export type PTORequestGroupSummaryType = {
  id: string;
  kindName: string;
  requests: PTORequestType[];
  totalHours: number;
  userName: string;
};

export const ReviewModal: React.FC<{
  ptoRequestGroup: PTORequestGroupSummaryType;
  closeModal(): void;
}> = ({ ptoRequestGroup, closeModal }) => {
  const [save, { loading }] = usePtoRequestGroupReviewMutation({
    client,
    onCompleted: () => {
      closeModal();
    },
  });

  const onSubmit = (input: Workforce__PtoRequestGroupReview__Input) => {
    save({
      variables: {
        input,
      },
    });
  };

  return (
    <Modal onClose={closeModal}>
      <Formik<Workforce__PtoRequestGroupReview__Input>
        initialValues={{
          requestGroupID: ptoRequestGroup.id,
          approve: true,
          note: '',
        }}
        onSubmit={onSubmit}
      >
        <FormikForm>
          <Panel>
            <Panel.Body>
              <p className="h4">
                {ptoRequestGroup.userName} is requesting {ptoRequestGroup.totalHours} hours of{' '}
                {ptoRequestGroup.kindName} PTO:
              </p>
              <Spacer height="16px" />
              <ul>
                {ptoRequestGroup.requests.map((request, i) => (
                  <li key={i}>
                    {DateTime.fromISO(request.date).toLocaleString(DateTime.DATE_MED)} - {request.hours} Hours
                  </li>
                ))}
              </ul>
              <FormikRadioFormGroup
                name="approve"
                options={[
                  {
                    value: true,
                    label: 'Approve',
                  },
                  {
                    value: false,
                    label: 'Reject',
                  },
                ]}
              />
              <FormikInputFormGroup label="Note (Optional)" name="note" />
            </Panel.Body>
            <Panel.Footer align="right">
              <Button kind="primary" onClick={closeModal}>
                Cancel
              </Button>
              <Button loading={loading} kind="primary" type="submit">
                Submit
              </Button>
            </Panel.Footer>
          </Panel>
        </FormikForm>
      </Formik>
    </Modal>
  );
};

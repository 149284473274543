import * as React from 'react';
import { useState } from 'react';

import { Dialog as ConfirmationDialog, useConfirmation } from '@admin/components/confirmation';
import { CurrencyFormControl, FieldFormGroup, InputFormGroup } from '@admin/components/fields';
import { Panel } from '@admin/components/helpers/panel';
import { CREDIT_REASONS } from '@admin/constants/credit_reasons';
import { Status, useBillingBuildChargeMutation } from '@admin/schema';
import { client } from '@admin/libraries/apollo';

import { Button, InputGroup, Text } from '@shared/components/bootstrap';
import { SelectProductAndEntity, Product } from './select_product_and_entity';

const groupedCreditReasons = () =>
  CREDIT_REASONS.reduce((acc, creditReason) => {
    acc[creditReason.category] = acc[creditReason.category] || [];
    acc[creditReason.category].push(creditReason);
    return acc;
  }, {} as { [key: string]: typeof CREDIT_REASONS });

export const AddCreditForm: React.FC<{
  accountID: string;
  onCancel(): void;
  onSave(): void;
}> = ({ accountID, onCancel, onSave }) => {
  const [reason, setReason] = useState<string | undefined>();
  const [note, setNote] = useState<string>('');
  const [amount, setAmount] = useState<number | undefined>();
  const [product, setProduct] = useState<Product | undefined>();
  const [entityValue, setEntityValue] = useState<string | undefined>();
  const [entityKind, setEntityKind] = useState<string | undefined>();
  const { confirmation, confirm } = useConfirmation();
  const [save, { loading }] = useBillingBuildChargeMutation({ client });

  const disabled = !reason || !product || !note || (product === Product.SelfStorage && !entityValue);

  const isValid = async () => {
    const validationErrors = [];
    if (!amount || amount! < 0) {
      validationErrors.push('Must enter a positive amount.');
    }
    if (validationErrors.length) {
      await confirm({
        title: 'Whoops',
        description: validationErrors.join(' '),
        confirm: 'Continue',
        showCancel: false,
      });
      return false;
    }
    return true;
  };

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (!(await isValid())) {
      return false;
    }
    const response = await save({
      variables: {
        input: {
          amount: -amount!,
          kind: 'Credit',
          note: `${reason} - ${note}`,
          accountID,
          product: product! as string,
          [entityKind!]: entityValue,
          autoAdvance: true,
        },
      },
    });
    if (response?.data?.result.status === Status.Ok) {
      onSave();
    }
  };

  return (
    <>
      {confirmation && <ConfirmationDialog confirmation={confirmation} />}
      <form role="form" onSubmit={onSubmit}>
        <Panel>
          <Panel.Header>
            <Panel.Title>Add Credit</Panel.Title>
          </Panel.Header>
          <Panel.Body>
            <SelectProductAndEntity
              accountID={accountID}
              onSelectProduct={setProduct}
              onSelectEntity={(updatedEntityKind, updatedEntityValue) => {
                setEntityKind(updatedEntityKind);
                setEntityValue(updatedEntityValue);
              }}
              product={product}
              entityValue={entityValue}
            />
            <FieldFormGroup label="Reason for Credit:">
              <select
                name="reason"
                className="form-control"
                value={reason}
                onChange={(event) => setReason(event.target.value || undefined)}
              >
                <option disabled={!!reason} value=""></option>
                {Object.entries(groupedCreditReasons()).map(([category, creditReasons]) => (
                  <optgroup key={category} label={category}>
                    {creditReasons.map(({ name }) => (
                      <option key={name} value={`${category} - ${name}`}>
                        {name}
                      </option>
                    ))}
                  </optgroup>
                ))}
              </select>
            </FieldFormGroup>
            <InputFormGroup
              type="text"
              label="Notes:"
              name="notes"
              value={note}
              onChange={(event) => setNote(event.target.value)}
            />
            <FieldFormGroup label="Amount:">
              <InputGroup>
                <InputGroup.Addon>$</InputGroup.Addon>
                <CurrencyFormControl amount={amount} onChange={setAmount} name="amount" />
              </InputGroup>
            </FieldFormGroup>
          </Panel.Body>
          <Panel.Footer>
            <Text alignment="right" tag="div">
              <Button kind="danger" onClick={onCancel} disabled={loading}>
                Cancel
              </Button>{' '}
              <Button kind="primary" type="submit" loading={loading} disabled={disabled}>
                Add Credit
              </Button>
            </Text>
          </Panel.Footer>
        </Panel>
      </form>
    </>
  );
};

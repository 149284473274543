import React from 'react';
import { FieldFormGroupProps } from '@admin/components/fields';
import { useField } from 'formik';
import { FormikFieldFormGroup } from '@shared/components/fields/formik/formik_field_form_group';
import { MultiselectCalendar } from '@admin/components/fields/multiselect_calendar';

export const FormikMultiselectCalendarGroup: React.FC<FieldFormGroupProps & { name: string }> = ({
  has,
  help,
  label,
  ...input
}) => {
  const [_, { value }, { setValue }] = useField(input.name);

  return (
    <FormikFieldFormGroup name={input.name} help={help} label={label} id={input.id}>
      <MultiselectCalendar selected={value} setSelected={setValue} />
    </FormikFieldFormGroup>
  );
};

import { useLeadCloseCrmLinkQuery } from '@admin/schema';
import { client } from '@admin/libraries/apollo';
import React from 'react';
import { FEATURE_FLAG } from '@shared/config/feature_flag';

export const CloseCRMButtonLink: React.FC<{ id: string }> = ({ id }) => {
  const { data } = useLeadCloseCrmLinkQuery({ client, variables: { id } });

  const href = data?.lead.closeCRMLink || undefined;

  if (!href || !FEATURE_FLAG.close_crm_sync_2024_03) {
    return null;
  }

  return (
    <a className="btn btn-success" href={href}>
      Close Lead URL
    </a>
  );
};

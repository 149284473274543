import { useField } from 'formik';
import React from 'react';

import { FieldFormGroupProps } from '@admin/components/fields';
import { FormikFieldFormGroup } from '@shared/components/fields/formik/formik_field_form_group';
import { DateTimePicker } from '@admin/components/helpers/date_time_picker';

export const FormikDateTimePicker: React.FC<
  Omit<FieldFormGroupProps, 'has'> & { name: string; allowPastDate?: boolean }
> = ({ help, label, allowPastDate = true, ...input }) => {
  const [_, { value }, { setValue }] = useField(input.name);

  return (
    <FormikFieldFormGroup name={input.name} help={help} label={label} id={input.id}>
      <DateTimePicker id={input.id} dt={value} onChange={setValue} allowPastDate={allowPastDate} required={false} />
    </FormikFieldFormGroup>
  );
};

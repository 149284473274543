import angular from 'angular';
import template from '@admin/angular/templates/retail_delivery_form.html';

const app = angular.module('app');

app.component('retailDeliveryForm', {
  template,
  bindings: {
    account: '<account',
    order: '<order',
  },
  controller: [
    'RetailDelivery',
    'RETAIL_DELIVERY_ATTRIBUTES',
    function (RetailDelivery, RETAIL_DELIVERY_ATTRIBUTES) {
      this.attributes = RETAIL_DELIVERY_ATTRIBUTES;
      this.resource = RetailDelivery;
    },
  ],
});

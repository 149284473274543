(function () {
  const app = angular.module('app');

  app.factory('MessageCacheService', [
    'Message',
    function (Message) {
      let cache = {};
      const service = {
        clearCache: clearCache,
        getMessages: getMessages,
      };

      return service;

      function clearCache() {
        cache = {};
      }

      function getMessages(recipientType, recipientId) {
        if (!cache[recipientType]) {
          cache[recipientType] = {};
        }

        if (!cache[recipientType][recipientId]) {
          cache[recipientType][recipientId] = Message.query({
            recipient_type: recipientType,
            recipient_id: recipientId,
          });
        }

        return cache[recipientType][recipientId];
      }
    },
  ]);
})();

import React from 'react';

import { client } from '@admin/libraries/apollo';
import { Barcode, Item, useItemActivateMutation } from '@admin/schema';
import { UserRole } from '@admin/types';

import { Button } from '@shared/components/bootstrap';
import { Roles } from '@admin/components/helpers/roles';

import {
  useConfirmation,
  Dialog as ConfirmationDialog,
  Response as ConfirmationResponse,
} from '@admin/components/confirmation';

export const ItemActivateButton: React.FC<{
  item: Pick<Item, 'id' | 'active'> & { barcode: Pick<Barcode, 'id' | 'value'> };
}> = ({ item }) => {
  const { confirmation, confirm } = useConfirmation();

  const [execute, { loading }] = useItemActivateMutation({ client, variables: { id: item.id } });

  const onClick = async () => {
    const confirmed = await confirm({
      title: `Are you sure you want to undelete #${item.barcode.value}?`,
      description: 'Undeleting an item should only be used to fix an accidental deletion.',
    });
    if (confirmed !== ConfirmationResponse.Confirm) return;

    await execute();
  };

  if (item.active) return null;

  return (
    <Roles show={[UserRole.Admin, UserRole.DataCorrector]}>
      {confirmation && <ConfirmationDialog confirmation={confirmation} />}

      <Button loading={loading} kind="danger" onClick={onClick}>
        Undelete
      </Button>
    </Roles>
  );
};

import React from 'react';

import { SelectFormControl } from '@admin/components/fields';
import { Thumbnails } from '@admin/components/helpers/thumbnails';
import { Claim__IssueSelectionFragment, Claim__IssueSelection__Kind } from '@admin/schema';
import { ListGroup } from '@shared/components/bootstrap';
import { Currency } from '@shared/components/helpers';

import { Resolution } from './resolution';
import { ClaimResolutionEdits, ClaimIssueKindEdits } from '../types';
import { DISPLAYABLE_ISSUE } from '../contants';

const DECLINE_REASONS = ['Not covered', 'Previously compensated', 'Clutter not at fault'];

export const IssueSelection: React.FC<{
  selection: Claim__IssueSelectionFragment;
  issueKindEdits: ClaimIssueKindEdits;
  resolutionEdits: ClaimResolutionEdits;
  setIssueKindEdits(edits: ClaimIssueKindEdits): void;
  setResolutionEdits(edits: ClaimResolutionEdits): void;
}> = ({ selection, issueKindEdits, resolutionEdits, setIssueKindEdits, setResolutionEdits }) => {
  const { id, compensationRequested, declaration, kind, photos } = selection;

  return (
    <>
      <ListGroup.Item>
        <div className="row">
          <div className="col-sm-2">
            <strong>Issue Type</strong>
          </div>
          <div className="col-sm-2">
            <strong>Images</strong>
          </div>
          <div className="col-sm-2">
            <strong>Requesting Compensation</strong>
          </div>
          <div className="col-sm-1">
            <strong>Declared</strong>
          </div>
          <div className="col-sm-5">
            <strong>Valid?</strong>
          </div>
        </div>
      </ListGroup.Item>
      <ListGroup.Item>
        <div className="row">
          <div className="col-sm-2">
            <SelectFormControl
              options={[
                Claim__IssueSelection__Kind.Appointment,
                Claim__IssueSelection__Kind.Billing,
                Claim__IssueSelection__Kind.Other,
              ]}
              value={issueKindEdits[id] ?? kind ?? undefined}
              name={(currentKind) => (currentKind ? DISPLAYABLE_ISSUE[currentKind] : '')}
              onChange={(option) =>
                setIssueKindEdits({ ...issueKindEdits, [id]: option as Claim__IssueSelection__Kind })
              }
            />
          </div>
          <div className="col-sm-2">
            <Thumbnails attachments={photos} condensed />
          </div>
          <div className="col-sm-2">{compensationRequested ? 'Yes' : 'No'}</div>
          <div className="col-sm-1">{<Currency value={declaration || 0} />}</div>
          <div className="col-sm-5">
            <Resolution
              disabled={false}
              declineReasons={DECLINE_REASONS}
              selection={selection}
              resolutionEdits={resolutionEdits}
              onResolve={setResolutionEdits}
            />
          </div>
        </div>
      </ListGroup.Item>
    </>
  );
};

import { Pluralize } from '@shared/components/helpers';
import * as React from 'react';
import cn from 'classnames';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faQuestionCircle,
  faTimesCircle,
  faCar,
  faBuilding,
  faExclamationCircle,
} from '@fortawesome/free-solid-svg-icons';

import { faCheckCircle, faCircle } from '@fortawesome/free-regular-svg-icons';
import { Text } from '@shared/components/bootstrap';
import { Address } from '@admin/types/address';

const NAMED_BUILDING_TYPES = ['Storage Facility / Warehouse', 'Commercial', 'Donation Center'];
const SUBTYPED_BUILDING_TYPES = ['Apartment', 'Commercial'];
const ENVIRONMENT_BUILDING_TYPES = ['Storage Facility / Warehouse'];

const TRUCK_HEIGHT_REQUIREMENT = 168; // inches (i.e. 14 feet)

const Time: React.FC<{ hour: number }> = ({ hour }) => (
  <>
    {hour % 12 === 0 ? 12 : hour % 12} {hour >= 12 ? 'PM' : 'AM'}
  </>
);

const Checked: React.FC<{ checked?: boolean | null }> = ({ checked }) => (
  <FontAwesomeIcon icon={checked ? faCheckCircle : faCircle} />
);

const Missing: React.FC<{ label?: string }> = ({ label }) => (
  <Text style="danger">
    <FontAwesomeIcon icon={faTimesCircle} /> {label}
  </Text>
);

const Section: React.FC = ({ children }) => <div className="address-section">{children}</div>;
const SectionContent: React.FC = ({ children }) => <div className="address-section-content">{children}</div>;
const SectionIcon: React.FC = ({ children }) => <div className="address-section-icon">{children}</div>;
const SectionLabel: React.FC = ({ children }) => <span className="address-section-label">{children}</span>;
const List: React.FC = ({ children }) => <span className="address-list">{children}</span>;
const ListEntry: React.FC = ({ children }) => <span className="address-list-entry">{children}</span>;

const Preview: React.FC<{ address: Address; mode?: 'inline' | 'block' }> = ({ address, mode }) => (
  <div className={cn('address', mode && `address-${mode}`)}>
    <div className="address-line">
      <Section>
        <SectionLabel>{address.building_type}:</SectionLabel>

        {address.building_type === 'House' && (
          <List>
            <ListEntry>
              {address.stories ? (
                <Pluralize count={address.stories} singular="Story" plural="Stories" />
              ) : (
                <Missing label="Stories" />
              )}
            </ListEntry>
            <ListEntry>
              {address.front_door_steps ? (
                <Pluralize count={address.front_door_steps} singular="Step in Front" plural="Steps in Front" />
              ) : (
                <Missing label="Steps in Front" />
              )}
            </ListEntry>
            <ListEntry>
              {address.square_feet ? <span>{address.square_feet} sqft</span> : <Missing label="Sq Ft" />}
            </ListEntry>
          </List>
        )}
        {address.building_type !== 'House' && (
          <List>
            {address.building_type && NAMED_BUILDING_TYPES.includes(address.building_type) && address.business_name && (
              <ListEntry>{address.business_name}</ListEntry>
            )}
            {address.building_subtype &&
              address.building_type &&
              SUBTYPED_BUILDING_TYPES.includes(address.building_type) && (
                <ListEntry>{address.building_subtype}</ListEntry>
              )}
            {address.building_type &&
              ENVIRONMENT_BUILDING_TYPES.includes(address.building_type) &&
              address.environment && (
                <ListEntry>
                  <Text transform="capitalize">{address.environment}</Text>
                </ListEntry>
              )}
            <ListEntry>{address.floor ? <span>Floor #{address.floor}</span> : <Missing label="Floor" />}</ListEntry>
            <ListEntry>
              <Checked checked={address.stairs} /> Stairs
            </ListEntry>
            <ListEntry>
              <Checked checked={address.elevator} /> Elevator
            </ListEntry>
            <ListEntry>
              <Checked checked={address.service_elevator} /> Service Elevator
            </ListEntry>
          </List>
        )}
      </Section>

      <Section>
        <SectionIcon>
          <FontAwesomeIcon icon={faCar} />
        </SectionIcon>

        <SectionContent>
          <List>
            <ListEntry>
              <Checked checked={address.parking_street} /> Street
            </ListEntry>
            <ListEntry>
              <Checked checked={address.parking_driveway} /> Driveway
            </ListEntry>
            <ListEntry>
              <Checked checked={address.parking_lot} /> Lot
            </ListEntry>
            <ListEntry>
              <Checked checked={address.parking_alley} /> Alley/Behind Building
            </ListEntry>
            {address.parking_street && (
              <ListEntry>
                <Checked checked={address.parking_permit} /> Permits
              </ListEntry>
            )}
          </List>

          {address.parking_street && (
            <div>
              {address.parking_in_front ? (
                <div>Where can we park? In front of building</div>
              ) : (
                <div>On Street: {address.parking_on_specific_street}</div>
              )}
            </div>
          )}
          {!address.max_truck_height_unknown && address.max_truck_height && (
            <div>
              Is parking location tall enough to park a 14 foot tall truck?
              {address.max_truck_height < TRUCK_HEIGHT_REQUIREMENT ? 'No' : 'Yes'}
            </div>
          )}
          <div>
            {address.parking_instructions ? (
              <span>Anything else we should know? {address.parking_instructions}</span>
            ) : (
              <Text style="danger">No Additional Parking Instructions</Text>
            )}
          </div>
        </SectionContent>
      </Section>

      <Section>
        <SectionIcon>
          <FontAwesomeIcon icon={faExclamationCircle} />
        </SectionIcon>

        <SectionContent>
          <List>
            <ListEntry>
              <Checked checked={address.paperwork} /> Paperwork / COI
            </ListEntry>

            <ListEntry>
              <Checked checked={address.steep} /> Steep Driveway or Road
            </ListEntry>

            <ListEntry>
              <Checked checked={address.gated} /> Gated Community
            </ListEntry>

            <ListEntry>
              <Checked checked={address.service_entrance} /> Service Entrance
            </ListEntry>

            <ListEntry>
              <Checked checked={address.loading_dock} /> Loading Dock
            </ListEntry>

            <ListEntry>
              <Checked checked={address.code} /> Code {address.code_value && <span>{address.code_value}</span>}
            </ListEntry>
          </List>
        </SectionContent>
      </Section>

      <Section>
        <SectionIcon>
          <FontAwesomeIcon icon={faBuilding} />
        </SectionIcon>

        <SectionContent>
          <ListEntry>
            <strong>Building restrictions: </strong>
            {address.building_restrictions_answer && (
              <Text transform="capitalize">{address.building_restrictions_answer}</Text>
            )}
          </ListEntry>
        </SectionContent>
      </Section>

      <Section>
        <SectionContent>
          <ListEntry>
            <strong>Earliest start time: </strong>
            {address.building_opening_hour && (
              <span>
                <Time hour={address.building_opening_hour} />
              </span>
            )}
          </ListEntry>
        </SectionContent>
      </Section>

      <Section>
        <SectionContent>
          <ListEntry>
            <strong>Latest end time: </strong>
            {address.building_closing_hour && (
              <span>
                <Time hour={address.building_closing_hour} />
              </span>
            )}
          </ListEntry>
        </SectionContent>
      </Section>

      <Section>
        <SectionIcon>
          <FontAwesomeIcon icon={faQuestionCircle} />
        </SectionIcon>

        <SectionContent>
          {address.additional_notes ? (
            <span>{address.additional_notes}</span>
          ) : (
            <Text style="danger">No Address Notes</Text>
          )}
        </SectionContent>
      </Section>
    </div>
  </div>
);

export { Preview };

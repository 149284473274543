(function () {
  const app = angular.module('app');

  app.controller('ItemGroupNewController', [
    '$scope',
    '$state',
    '$stateParams',
    'Page',
    'Account',
    'Item',
    'ItemGroup',
    'ItemGroupModalService',
    function ($scope, $state, $stateParams, Page, Account, Item, ItemGroup, ItemGroupModalService) {
      const accountID = $stateParams.account_id;
      $scope.group = new ItemGroup({
        account_id: accountID,
        kind: 'attached',
        items: [],
      });
      $scope.account = Account.get({ id: accountID });

      $scope.account.$promise.then((account) => {
        Page.reset({ title: `Acct: ${account.customer.name} - New Item Group` });
      });

      Item.groupables({ account_id: $stateParams.account_id }).$promise.then((groupedItems) => {
        $scope.groupedItems = groupedItems;
      });

      $scope.invalid = function () {
        return $scope.group.items.length < 2;
      };

      $scope.confirm = function () {
        ItemGroupModalService.present($scope.group, () => {
          $state.go('inventory', { account_id: accountID });
        });
      };
    },
  ]);
})();

import cn from 'classnames';
import angular from 'angular';

const COMPONENT_CLASS = 'required-input-helper';

function controller() {
  this.cssClasses = () => {
    const { direction = 'bottom', isFilled, inlineBlock } = this;
    const directionalClass = `${COMPONENT_CLASS}--${direction}`;
    const requiredClass = `${directionalClass}--required`;
    const filledClass = `${directionalClass}--filled`;
    const inlineBlockClass = `${COMPONENT_CLASS}--inline-block`;
    return cn(COMPONENT_CLASS, directionalClass, {
      [requiredClass]: !isFilled,
      [filledClass]: isFilled,
      [inlineBlockClass]: inlineBlock,
    });
  };
}

angular.module('app').component('requiredInputHelper', {
  bindings: {
    isFilled: '<',
    inlineBlock: '<?',
    direction: '<?',
  },
  transclude: true,
  controller,
  template: `<div
      class="required-input-helper"
      ng-class="$ctrl.cssClasses()"
    >
      <ng-transclude></ng-transclude>
    </div>`,
});

(function () {
  const app = angular.module('app');

  const ESC = 13;

  app.directive('autosubmit', [
    function () {
      return {
        restrict: 'A',
        link: function (scope, element, attrs) {
          element.bind('keypress', function (event) {
            if (event.which === ESC) {
              scope.$apply(function () {
                scope.$eval(attrs.autosubmit);
              });
            }
          });
        },
      };
    },
  ]);
})();

import styled from '@emotion/styled';
import * as React from 'react';

import { Button, Modal } from '@shared/components/bootstrap';

import { useOverlockSelfStorageRentalMutation, SelfStorage__RentalFragment } from '@admin/schema';
import { client } from '@admin/libraries/apollo';

const Title = styled.div`
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  text-align: center;
`;

const Summary = styled.p`
  font-size: 18px;
  font-weight: 200;
  line-height: 24px;
  text-align: center;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -8px;
`;

const Action = styled.div`
  padding: 8px;
`;

export const Dialog: React.FC<{
  rental: SelfStorage__RentalFragment;
  onClose(): void;
}> = ({ rental, onClose }) => {
  const overlocked = rental.overlocked;
  const name = `#${rental.unit.name} at ${rental.unit.facility.name}`;
  const [overlock, { loading }] = useOverlockSelfStorageRentalMutation({ client });

  const onSave = async () => {
    await overlock({ variables: { id: rental.id, overlocked: !overlocked } });
    onClose();
  };

  return (
    <Modal onClose={onClose}>
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>
            <Title>
              {overlocked ? 'Unlock' : 'Overlock'} {name}
            </Title>
          </Modal.Title>
          <Modal.Close close={onClose} />
        </Modal.Header>
        <Modal.Body>
          <Summary>
            Are you sure you want to {overlocked ? 'unlock' : 'overlock'} {name}?
          </Summary>
        </Modal.Body>
        <Modal.Footer>
          <Actions>
            <Action>
              <Button size="lg" kind="default" disabled={loading} onClick={onClose}>
                Close
              </Button>
            </Action>
            <Action>
              <Button size="lg" kind="danger" loading={loading} onClick={onSave}>
                {overlocked ? 'Unlock' : 'Overlock'}
              </Button>
            </Action>
          </Actions>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};

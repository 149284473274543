import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';

import { client } from '@admin/libraries/apollo';
import { useBuildZendeskTicketMutation } from '@admin/schema';

import { zendeskTicketURL } from '@admin/config/zendesk';
import { AnchorButton, Button, HelpBlock, Modal, Text } from '@shared/components/bootstrap';
import { CustomerTicketDocument, Status as StatusType } from '@admin/schema';

export const ConfirmationModal: React.FC<{
  customerTicketID: string;
  onClose(): void;
}> = ({ customerTicketID, onClose }) => {
  const [newZDTicketID, setNewZDTicketID] = useState<string | undefined>();
  const [error, setError] = useState<string | undefined>();

  const [buildZendeskTicket, { loading }] = useBuildZendeskTicketMutation({ client });

  const createZendeskTicket = async () => {
    const response = await buildZendeskTicket({
      variables: { customerTicketID },
      refetchQueries: [{ query: CustomerTicketDocument, variables: { id: customerTicketID } }],
    });
    if (response && response.data) {
      if (response.data.buildZendeskTicket.status === StatusType.Unprocessable) {
        setError(`There was an error creating the zendesk ticket: ${response.data.buildZendeskTicket.error}`);
      } else if (response.data.buildZendeskTicket.status === StatusType.Ok) {
        setError(undefined);
        const { zdTicketID } = response.data.buildZendeskTicket.zendeskTicketAssociation!;
        setNewZDTicketID(zdTicketID);
      }
    }
  };

  const closeModal = () => {
    setError(undefined);
    setNewZDTicketID(undefined);
    onClose();
  };

  return (
    <Modal onClose={closeModal}>
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>Create Zendesk Ticket</Modal.Title>
          <Modal.Close close={closeModal} />
        </Modal.Header>
        <Modal.Body>
          {!error && !newZDTicketID && (
            <>
              <Text tag="p">This will create a new ticket in Zendesk.</Text>
              <Text tag="p">Note: This will not send an email to the customer.</Text>
            </>
          )}
          {!!newZDTicketID && (
            <>
              <Text tag="p">Zendesk ticket #{newZDTicketID} was successfully created!</Text>
              <AnchorButton kind="primary" href={zendeskTicketURL({ id: newZDTicketID })} target="_blank">
                View ticket on Zendesk <FontAwesomeIcon icon={faExternalLinkAlt} />
              </AnchorButton>
            </>
          )}
          {!!error && <HelpBlock className="text-danger">{error}.</HelpBlock>}
        </Modal.Body>
        <Modal.Footer>
          {!newZDTicketID && (
            <div>
              <Button kind="warning" onClick={closeModal}>
                Cancel
              </Button>
              <Button kind="success" loading={loading} onClick={createZendeskTicket}>
                {!error ? 'Create Ticket' : 'Try Again'}
              </Button>
            </div>
          )}
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};

import * as React from 'react';
import { AdminDropOffAppointmentFragment, StorageArrangementFragment } from '@admin/schema';
import styled from '@emotion/styled';

const getName = (storageArrangement: StorageArrangementFragment) => {
  if (storageArrangement.__typename === 'SelfStorage__Hold') {
    return storageArrangement.unit.name;
  } else if (storageArrangement.__typename === 'DropOff__ThirdPartySelfStorageUnit') {
    return storageArrangement.name;
  }
};

const Label = styled.div`
  margin-bottom: 10px;
  display: inline-block;
  font-weight: bold;
`;

export const StorageArrangementManager: React.FC<{
  appointment: AdminDropOffAppointmentFragment;
}> = ({ appointment: { storageArrangement, facility } }) => (
  <>
    <Label>Facility: </Label> {facility?.name}{' '}
    <p>
      <strong>Hours: </strong> {facility.hours.join(', ')}
    </p>
    <p>
      <strong>Unit: </strong>
      <>{storageArrangement && getName(storageArrangement)}</>{' '}
    </p>
  </>
);

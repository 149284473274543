import * as React from 'react';

import { Panel } from '@admin/components/helpers/panel';

import { FileUploader } from './file_uploader';

export const NotesUploader: React.FC = () => (
  <Panel>
    <Panel.Header>
      <Panel.Title>Notes Uploader</Panel.Title>
    </Panel.Header>
    <Panel.Body>
      <p>
        Notes are uploaded in bulk by uploading spreadsheet. Download and replace the following template:{' '}
        {
          <a href="/files/notes.csv" className="btn-link" download="" target="_blank">
            notes.csv
          </a>
        }
      </p>
      <FileUploader />
    </Panel.Body>
  </Panel>
);

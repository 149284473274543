import { debounce } from 'lodash';
import * as React from 'react';

interface ISelectWithOtherProps {
  option?: string;
  disabled?: boolean;
  options: string[];
  placeholder: string;
  onSelect(reason: string): void;
}

export const SelectWithOther = (props: ISelectWithOtherProps) => {
  let onOther = (reason: string) => props.onSelect(reason);
  onOther = debounce(onOther, 200);

  return (
    <div className="btn-group select-with-other">
      <button
        disabled={props.disabled}
        type="button"
        className="btn btn-default dropdown-toggle"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        {props.option || props.placeholder} <span className="caret" />
      </button>
      <ul className="dropdown-menu">
        {props.options.map((option) => (
          <li key={option}>
            <a href="#" onClick={() => props.onSelect(option)}>
              {option}
            </a>
          </li>
        ))}
        <li role="separator" className="divider" />
        <li>
          <input
            onChange={(event) => onOther(event.target.value)}
            className="form-control"
            type="text"
            placeholder="Other"
          />
        </li>
      </ul>
    </div>
  );
};

import * as React from 'react';

import { Label } from '@admin/components/helpers/label';
import { Panel } from '@admin/components/helpers/panel';
import { Text } from '@shared/components/bootstrap';
import { Order__AssessmentType, Rating } from '@admin/schema';

const DISCLAIMER =
  'Note: These issues are self reported, and may not correspond to other \
information in TEAL such as damage reports or timeliness data.';

const EXPLANATION: { [key in Rating]: string } = {
  [Rating.Poor]: DISCLAIMER,
  [Rating.Good]: 'M&P had no issues to report.',
};

const ReasonLabel: React.FC<{
  reason: string;
  chosen: boolean | string;
}> = ({ reason, chosen }) => (
  <>
    {typeof chosen === 'boolean' ? (
      <Label key={reason} kind={chosen ? 'warning' : 'default'}>
        {chosen ? <i className="fa fa-check-circle-o" /> : <i className="fa fa-circle-o" />} {reason.replace(/_/g, ' ')}
      </Label>
    ) : (
      <span>{chosen}</span>
    )}{' '}
  </>
);

export const OrderAssessment: React.FC<{
  assessment: Pick<Order__AssessmentType, 'id' | 'otherReason' | 'rating' | 'reasons'>;
}> = ({ assessment }) => (
  <div className="order-assessment-panel">
    <Panel>
      <Panel.Header>
        <h3 className="panel-title">M&amp;P Reported Issues</h3>
      </Panel.Header>
      <Panel.Body>
        <Text tag="p">
          <strong>{EXPLANATION[assessment.rating]}</strong>
        </Text>
        <div className="table-responsive">
          <table className="table">
            <tbody>
              <tr>
                <td className="col-zero col-nowrap text-left">
                  <strong>Rating</strong>:
                </td>
                <td className="col-fill text-left">
                  <Label kind={assessment.rating === Rating.Good ? 'success' : 'danger'}>{assessment.rating}</Label>
                </td>
              </tr>
              {Object.keys(assessment.reasons).map((category, index) => (
                <tr key={index} id={`${category}-issues`}>
                  <td className="col-zero col-nowrap text-left">
                    <Text tag="span" transform="capitalize">
                      <strong>{category} issues:</strong>
                    </Text>
                  </td>
                  <td className="col-fill col-nowrap text-left">
                    {Object.keys(assessment.reasons[category]).map((reason) => (
                      <ReasonLabel key={reason} reason={reason} chosen={assessment.reasons[category][reason]} />
                    ))}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Panel.Body>
    </Panel>
  </div>
);

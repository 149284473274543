import { sumBy } from 'lodash';

import { Claim__ItemSelectionFragment, Claim__PropertySelectionFragment, Maybe } from '@admin/schema';

export const calculateTotalApproved = (
  itemSelections: Claim__ItemSelectionFragment[],
  propertySelection?: Maybe<Claim__PropertySelectionFragment>,
) => {
  const itemTotalApproved = sumBy(itemSelections, ({ assessment }) => assessment?.approvedAmount || 0);
  if (!propertySelection) {
    return itemTotalApproved;
  }
  const propertyTotalApproved = propertySelection.assessment?.approvedAmount || 0;
  return itemTotalApproved + propertyTotalApproved;
};

export const calculateTotalClaimed = (
  itemSelections: Claim__ItemSelectionFragment[],
  propertySelection?: Maybe<Claim__PropertySelectionFragment>,
) => {
  const itemTotalClaimed = sumBy(itemSelections, ({ declaration }) => declaration || 0);
  if (!propertySelection) {
    return itemTotalClaimed;
  }
  const propertyTotalClaimed = propertySelection.declaration || 0;
  return itemTotalClaimed + propertyTotalClaimed;
};

export const calculateTotalCompensated = (
  itemSelections: Claim__ItemSelectionFragment[],
  propertySelection?: Maybe<Claim__PropertySelectionFragment>,
) => {
  const itemTotalCompensated = sumBy(itemSelections, (selection) => selection.resolution?.amount || 0);
  if (!propertySelection) {
    return itemTotalCompensated;
  }
  const propertyTotalCompensated = propertySelection.resolution?.amount || 0;
  return itemTotalCompensated + propertyTotalCompensated;
};

angular.module('app').factory('User', [
  '$resource',
  function ($resource) {
    return $resource(
      '/users/:id.json',
      {
        id: '@id',
      },
      {
        index: {
          method: 'GET',
          isArray: true,
        },
        show: {
          method: 'GET',
          isArray: false,
        },
        dispatching: {
          method: 'GET',
          url: '/dispatching/users.json',
          isArray: true,
        },
      },
    );
  },
]);

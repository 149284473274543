(function () {
  angular.module('app').service('UploaderService', [
    '$http',
    '$q',
    function ($http, $q) {
      this.upload = function (file) {
        const deferred = $q.defer();

        $http.post('/presigned_urls', { filename: file.name }).then(function (response) {
          const url = _.head(response.data.url.split('?'));
          $http({
            url: response.data.url,
            method: 'PUT',
            data: file,
            headers: { 'Content-Type': file.type },
            uploadEventHandlers: {
              progress: function (event) {
                deferred.notify(event.loaded / event.total);
              },
            },
          }).then(function () {
            deferred.resolve(url);
          }, deferred.reject);
        }, deferred.reject);

        return deferred.promise;
      };

      this.uploadFiles = (uploads) => {
        const uploader = (upload) => {
          const promise = this.upload(upload.file);
          _.set(upload, 'state', 'uploading');

          const success = (url) => {
            _.set(upload, 'state', 'success');
            _.set(upload, 'url', url);
          };

          const failure = (error) => {
            _.set(upload, 'state', 'failure');
            _.set(upload, 'error', error);
          };

          const status = (progress) => _.set(upload, 'progress', progress);

          promise.then(success, failure, status);
          return promise;
        };

        const unprocessed = _.filter(uploads, (upload) => !upload.url);
        return $q.all(_.map(unprocessed, uploader));
      };
    },
  ]);
})();

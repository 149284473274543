import { Panel } from '@admin/components/helpers/panel';
import { Spinner } from '@admin/components/spinner';
import { locationURL, palletURL } from '@admin/config/routes';
import { LogisticsLoadQuery, LogisticsLoadInventoryQuery, useLogisticsLoadInventoryQuery } from '@admin/schema';
import styled from '@emotion/styled';
import { Lightbox } from '@shared/components/helpers/lightbox';
import { client } from '@admin/libraries/apollo';
import { COLORS } from '@clutter/clean';
import { capitalize } from 'lodash';
import React, { useState } from 'react';

type LogisticsLoad = LogisticsLoadQuery['load'];
type Pallet = LogisticsLoadInventoryQuery['palletInventory'][number];

const Link = styled.a`
  color: ${COLORS.tealJungle};
`;

const DamagedPallet = styled.span`
  color: ${COLORS.toucan};
`;

const formatPalletState = (pallet: Pallet) => {
  const formattedState = capitalize(pallet.state);
  return pallet.isDamaged ? <DamagedPallet>{formattedState} (damaged)</DamagedPallet> : <span>{formattedState}</span>;
};

export const LogisticsLoadInventoryPanel: React.FC<{ load: LogisticsLoad }> = ({ load }) => {
  const { data, loading } = useLogisticsLoadInventoryQuery({ client, variables: { id: load.id } });
  const [selection, setSelection] = useState<string | undefined>(undefined);
  const [collapsed, setCollapsed] = useState<boolean>(false);

  const pallets = data && data.palletInventory;
  return (
    <Panel>
      <Panel.Header>
        <Panel.Collapse collapsed={collapsed} onChange={setCollapsed} />
        <Panel.Title>Load Inventory</Panel.Title>
      </Panel.Header>
      <Panel.Body>
        <div className="grid">
          <div className="row">
            {!collapsed && loading && <Spinner />}
            {!collapsed &&
              pallets &&
              pallets.map((pallet) => (
                <div key={pallet.id} className="entry col-xs-6 col-sm-4 col-md-3 col-lg-2">
                  <ul className="list-unstyled">
                    <li>
                      Pallet:{' '}
                      <Link href={palletURL(pallet.id)} target="_blank">
                        #{pallet.barcode.value}
                      </Link>
                    </li>
                    <li>State: {formatPalletState(pallet)}</li>
                    <li>
                      Location:{' '}
                      {pallet.location && (
                        <Link href={locationURL(pallet.location.id)} target="_blank">
                          {pallet.location.value}
                        </Link>
                      )}
                      {!pallet.location && 'Not Located'}, {pallet.warehouse.name}
                    </li>
                    <li>
                      Type: {pallet.type.name}, {pallet.height && `${pallet.height.value} inches`}
                    </li>
                    <li>
                      {/* TODO: skuValues should be a distinct array of skus, when iOS is able to change their side this can remove the use of Sets */}
                      Skus: {Array.from(new Set(pallet.skuValues)).join(', ')}
                    </li>
                    <li>Number of Items: {pallet.itemsCount}</li>
                  </ul>
                  {pallet.grade?.image && (
                    <figure className="brick-square">
                      <a
                        href="#"
                        onClick={(event) => {
                          event.preventDefault();
                          event.stopPropagation();
                          setSelection(pallet.grade!.image!.source);
                        }}
                      >
                        <img className="location-image" src={pallet.grade.image.size} />
                      </a>
                    </figure>
                  )}
                </div>
              ))}
            {selection && <Lightbox imageURL={selection} onClose={() => setSelection(undefined)} />}
          </div>
        </div>
      </Panel.Body>
    </Panel>
  );
};

import React from 'react';

import { client } from '@admin/libraries/apollo';
import { useItemExceptionsQuery } from '@admin/schema';

import { Spinner } from '@admin/components/spinner';
import { Table } from './table';

export const ItemExceptionsPanel: React.FC<{ itemId: string; timezone: string }> = ({ itemId, timezone }) => {
  const { data } = useItemExceptionsQuery({
    client,
    variables: { itemId },
  });

  return (
    <div className="panel">
      <div className="panel-heading">
        <h3 className="panel-title">Item Exceptions</h3>
      </div>
      <div className="panel-body">
        {!data && <Spinner />}
        {!!data && !!data.itemExceptions && !!data.itemExceptions.length && (
          <Table itemExceptions={data.itemExceptions} timezone={timezone} />
        )}
        {!!data && !!data.itemExceptions && !data.itemExceptions.length && <p className="text-center">No Exceptions</p>}
      </div>
    </div>
  );
};

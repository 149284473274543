import React from 'react';

import { Panel as SharedPanel } from '@admin/components/helpers/panel';
import { Roles } from '@admin/components/helpers/roles';
import { Spinner } from '@admin/components/spinner';
import { newPaymentPlanOfferURL } from '@admin/config/routes';
import { useAccountPaymentPlanOffersQuery, PaymentPlan__Offer__State as OfferState } from '@admin/schema';
import { UserRole } from '@admin/types';
import { AnchorButton } from '@shared/components/bootstrap';
import { client } from '@admin/libraries/apollo';

import { Table } from './table';

const OPEN_OFFER_STATES = [OfferState.Draft, OfferState.Approved];

export const PaymentPlansPanel: React.FC<{
  accountID: string;
}> = ({ accountID }) => {
  const { data, loading } = useAccountPaymentPlanOffersQuery({ variables: { accountID }, client });

  const offers = data && data.offers;

  const openOffersExist = offers && offers.some((offer) => OPEN_OFFER_STATES.includes(offer.state) && !offer.expired);

  return (
    <>
      <SharedPanel>
        <SharedPanel.Header>
          <SharedPanel.Title>Payment Plans</SharedPanel.Title>
        </SharedPanel.Header>
        <SharedPanel.Body>
          {loading && <Spinner />}
          {offers && <Table accountID={accountID} offers={offers} />}
        </SharedPanel.Body>
        {!openOffersExist && (
          <Roles show={[UserRole.Admin, UserRole.Collections]}>
            <SharedPanel.Footer align="right">
              <AnchorButton kind="primary" href={newPaymentPlanOfferURL({ accountID })}>
                Create New Payment Plan
              </AnchorButton>
            </SharedPanel.Footer>
          </Roles>
        )}
      </SharedPanel>
    </>
  );
};

import styled from '@emotion/styled';

export const Validator = styled.div<{
  color: 'green' | 'red' | 'goldenrod';
  position: 'left' | 'right' | 'bottom' | 'top';
}>`
  border-${({ position }) => position}-color: ${({ color }) => color};
  border-${({ position }) => position}-style: solid;
  border-${({ position }) => position}-width: 3px;
  padding-${({ position }) => position}: 6px;
`;

import styled from '@emotion/styled';
import { client } from '@admin/libraries/apollo';
import React, { useState } from 'react';

import { Panel } from '@admin/components/helpers/panel';
import { Timestamp } from '@admin/components/timestamp';
import { Text } from '@shared/components/bootstrap';
import { ILightboxCarouselInput, LightboxWithCarousel } from '@shared/components/helpers/lightbox';

import { accountItemURL, locationURL, logisticsDetailedSKUURL, palletURL } from '@admin/config/routes';
import {
  OpsAudit__ImageFragment,
  useAuditClaimItemSelectionQuery,
  useAuditItemQuery,
  useLogisticsSkusQuery,
  usePalletGradeQuery,
  usePalletTaskQuery,
} from '@admin/schema';

import { ItemDetails } from './item_details';

const ImageElement = styled.img`
  margin: 10px 0 10px 0;
`;

const Scrollable = styled.div`
  max-height: 80vh;
  overflow: hidden;
  overflow-y: scroll;
`;

export const AuditablesPanel: React.FC<{
  images: OpsAudit__ImageFragment[];
  resourceID: string;
  resourceType: string;
}> = ({ images, resourceID, resourceType }) => {
  const [selection, setSelection] = useState<number | undefined>(undefined);

  const { data: itemData } = useAuditItemQuery({
    client,
    variables: { itemID: resourceID },
    skip: resourceType !== 'Item',
  });
  const { data: taskData } = usePalletTaskQuery({
    client,
    variables: { palletTaskID: resourceID },
    skip: resourceType !== 'Pallet::Task',
  });
  const { data: gradeData } = usePalletGradeQuery({
    client,
    variables: { gradeID: resourceID },
    skip: resourceType !== 'Grade',
  });
  const { data: skuData } = useLogisticsSkusQuery({
    client,
    variables: { ids: [resourceID] },
    skip: resourceType !== 'Logistics::Sku',
  });
  const { data: claimItemSelectionData } = useAuditClaimItemSelectionQuery({
    client,
    variables: { claimItemSelectionID: resourceID },
    skip: resourceType !== 'Claim::ItemSelection',
  });

  let resourceURL;
  let item;
  let skuValue: string | undefined;

  if (resourceType === 'Item') {
    if (itemData?.item) {
      item = itemData.item;
      resourceURL = accountItemURL(itemData.item.accountID, resourceID);
    }
  } else if (resourceType === 'Pallet::Task') {
    if (taskData?.palletTask?.destinationLocationId) {
      resourceURL = locationURL(taskData.palletTask.destinationLocationId);
    }
  } else if (resourceType === 'Grade') {
    if (gradeData?.grade) {
      resourceURL = palletURL(gradeData.grade.palletID);
    }
  } else if (resourceType === 'Pallet') {
    resourceURL = palletURL(resourceID);
  } else if (resourceType === 'Logistics::Sku') {
    if (skuData?.logisticsSkus?.[0]) {
      skuValue = skuData.logisticsSkus[0].value;
      resourceURL = logisticsDetailedSKUURL({ id: resourceID });
    }
  } else if (resourceType === 'Claim::ItemSelection') {
    if (claimItemSelectionData?.itemSelection?.item) {
      item = claimItemSelectionData.itemSelection.item;
      resourceURL = accountItemURL(item.accountID, item.id);
    }
  }

  const imageSizeArray: ILightboxCarouselInput[] = images.map((image) => ({ src: image.source }));

  return (
    <div className="ops-audit-auditables-panel">
      <Panel>
        <Panel.Header>
          <Panel.Title>
            {'Audit Images for: '}
            {resourceURL && (
              <a href={resourceURL} className="btn-link" target="_blank">
                {skuValue && <span>SKU: {skuValue}</span>}
                {item && <span>Item {item.id}</span>}
                {!skuValue && !item && (
                  <span>
                    {resourceType} {resourceID}
                  </span>
                )}
              </a>
            )}
            {!resourceURL && (
              <span>
                {resourceType} {resourceID}
              </span>
            )}
            {item && <ItemDetails item={item} />}
          </Panel.Title>
        </Panel.Header>
        <Panel.Body>
          <Scrollable>
            {images.length === 0 && <Text>No images available for this audit.</Text>}
            {images.map((image, index) => (
              <div key={image.id} className="col-lg-4">
                <Text tag="p" alignment="center">
                  {image.name ? `${image.name} - ` : null}Taken at:{' '}
                  <Timestamp value={image.createdAt} format="medium" />
                </Text>
                <figure>
                  <a
                    href="#"
                    onClick={(event) => {
                      event.preventDefault();
                      event.stopPropagation();
                      setSelection(index);
                    }}
                  >
                    <ImageElement className="brick-image" src={image.size} />
                  </a>
                </figure>
              </div>
            ))}
            {selection !== undefined && (
              <LightboxWithCarousel
                imageInputs={imageSizeArray}
                defaultActiveIndex={selection}
                onClose={() => setSelection(undefined)}
              />
            )}
          </Scrollable>
        </Panel.Body>
      </Panel>
    </div>
  );
};

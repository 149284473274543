import React from 'react';

import { PageHeader } from '@admin/components/helpers/page_header';

import { useTaskOrderContext } from '@admin/components/orders/task_orders/components/task_order_context';
import { Breadcrumbs } from '@admin/components/orders/task_orders/components/breadcrumbs';
import { CreatePanel } from './components/create_panel';

export const TaskOrderCreate = () => {
  const { accountID, data } = useTaskOrderContext();

  if (!data) return null;

  return (
    <>
      <PageHeader>
        <Breadcrumbs />
      </PageHeader>
      <CreatePanel accountID={accountID} />
    </>
  );
};

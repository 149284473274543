import styled from '@emotion/styled';
import React, { useState } from 'react';

import { Select } from '@clutter/clean';

import { client } from '@admin/libraries/apollo';

import { startCase } from 'lodash';

import {
  useEstimationOrderEstimatedCuftAndCuftOverrideReasonQuery,
  useEstimationOverrideEstimatedCuftMutation,
  Estimation__EstimatedCuftOverrideReason,
  Status,
  EstimationOrderEstimatedCuftAndCuftOverrideReasonDocument,
} from '@admin/schema';
import { Roles } from '@admin/components/helpers/roles';
import { UserRole } from '@admin/types';
import { Alert, Button } from '@shared/components/bootstrap';

const StyledAlert = styled.div`
  div {
    margin: 0px;
  }
`;

const StyledWidget = styled.div`
  display: flex;
`;

const StyledElement = styled.div`
  display: flex;
  margin-right: 10px;
  margin-bottom: 10px;
`;

const NO_CUFT_OVERRIDE = 'No CuFt Override';

type Maybe<T> = T | undefined;

export const DispatchCuftOverrideForm: React.FC<{
  orderID: string;
  onChange?(estimatedDuration: number): void;
}> = ({ orderID, onChange }) => {
  const [error, setError] = useState<Maybe<string>>(undefined);
  const [editing, setEditing] = useState<boolean>(false);
  const [requestedEstimatedCuft, setRequestedEstimatedCuft] = useState<Maybe<number>>(undefined);
  const [requestedEstimatedCuftOverrideReason, setRequestedEstimatedCuftOverrideReason] =
    useState<Maybe<string>>(undefined);

  const [executeEditSubmit, { loading: mutationLoading }] = useEstimationOverrideEstimatedCuftMutation({
    client,
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: EstimationOrderEstimatedCuftAndCuftOverrideReasonDocument, variables: { orderID: orderID } },
    ],
  });

  const { data: queryData, loading: queryLoading } = useEstimationOrderEstimatedCuftAndCuftOverrideReasonQuery({
    client,
    variables: { orderID },
    onCompleted: (data) => {
      setRequestedEstimatedCuft(data.order.estimatedCuft);
      setRequestedEstimatedCuftOverrideReason(data.order.estimatedCuftOverrideReason ?? undefined);
    },
  });

  const currentEstimatedCuft = queryData?.order?.estimatedCuft;
  const currentEstimatedCuftOverrideReason = queryData?.order?.estimatedCuftOverrideReason ?? undefined;

  const dropdownOptions: Array<{ value: string; label: string }> = Object.entries(
    Estimation__EstimatedCuftOverrideReason,
  ).map(([key, value]) => ({
    value: value,
    label: startCase(key),
  }));
  dropdownOptions.push({ value: '', label: NO_CUFT_OVERRIDE });

  const resetValues = () => {
    setRequestedEstimatedCuft(currentEstimatedCuft);
    setRequestedEstimatedCuftOverrideReason(currentEstimatedCuftOverrideReason);
  };

  async function save() {
    try {
      const payload = await executeEditSubmit({
        variables: {
          orderID: orderID,
          estimatedCuft: requestedEstimatedCuft,
          reason:
            requestedEstimatedCuftOverrideReason === ''
              ? undefined
              : (requestedEstimatedCuftOverrideReason as Estimation__EstimatedCuftOverrideReason),
        },
      });
      const res = payload.data?.estimationOverrideEstimatedCuft;
      if (res?.status === Status.Ok) {
        setError(undefined);
        if (onChange) {
          onChange(payload.data?.estimationOverrideEstimatedCuft?.order?.estimatedDuration || 0);
        }
      } else {
        resetValues();
        setError(res?.error || undefined);
      }
    } catch (e) {
      resetValues();
      setError('Sorry, something went wrong. Please try again or contact Tech Support.');
    } finally {
      setEditing(false);
    }
  }

  const cancelEditing = () => {
    resetValues();
    setEditing(false);
  };

  return (
    <StyledWidget>
      <StyledElement>
        <input
          type="number"
          value={requestedEstimatedCuft || ''}
          onChange={(event) => setRequestedEstimatedCuft(Number(event.currentTarget.value))}
          disabled={!editing || queryLoading || !requestedEstimatedCuftOverrideReason}
        />
      </StyledElement>
      <StyledElement>
        <Select
          name="override-reason-select"
          value={requestedEstimatedCuftOverrideReason || ''}
          options={dropdownOptions}
          onChange={(event: any) => {
            setRequestedEstimatedCuftOverrideReason(event);
            if (event === '') {
              setRequestedEstimatedCuft(undefined);
            }
          }}
          disabled={!editing || queryLoading}
        />
      </StyledElement>
      <StyledElement>
        <Roles show={[UserRole.Admin, UserRole.Dispatcher]}>
          {error && (
            <StyledAlert>
              <Alert style="danger" onClose={() => setError(undefined)}>
                {error}
              </Alert>
            </StyledAlert>
          )}
          {!editing && !error && (
            <Button kind="primary" onClick={() => setEditing(true)} loading={queryLoading}>
              Edit
            </Button>
          )}
          {editing && (
            <>
              <Button kind="danger" onClick={() => cancelEditing()} loading={mutationLoading}>
                Cancel
              </Button>{' '}
              <Button
                kind="primary"
                onClick={() => save()}
                loading={mutationLoading}
                disabled={!requestedEstimatedCuft && requestedEstimatedCuftOverrideReason !== ''}
              >
                Save
              </Button>
            </>
          )}
        </Roles>
      </StyledElement>
    </StyledWidget>
  );
};

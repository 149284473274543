import { Box } from '@clutter/clean';
import React, { useState } from 'react';

import { FileFormControl } from '@admin/components/fields/file_form_control';
import { Roles } from '@admin/components/helpers/roles';
import { client } from '@admin/libraries/apollo';
import { Status, useZendeskUserBulkMergeMutation } from '@admin/schema';
import { UserRole } from '@admin/types';
import { Alert, Button, FormGroup, Panel } from '@shared/components/bootstrap';

export const ZendeskUpload: React.FC = () => {
  const [file, setFile] = useState<File | undefined>();
  const [signedID, setSignedID] = useState<string | undefined>();

  const [execute, { loading: saving, data, reset, error }] = useZendeskUserBulkMergeMutation({ client });

  const onUpload = (spreadsheet: File, spreadsheetID: string) => {
    setFile(spreadsheet);
    setSignedID(spreadsheetID);
    reset();
  };

  const onSave = async (event: React.FormEvent) => {
    event.preventDefault();
    event.stopPropagation();
    if (saving) return;
    if (!signedID) return;
    await execute({ variables: { signedID } });
    setFile(undefined);
    setSignedID(undefined);
  };

  const result = data?.result;

  return (
    <Roles show={[UserRole.Admin]}>
      <form onSubmit={onSave}>
        <Panel>
          <Panel.Header>
            <Panel.Title>Bulk Zendesk User Merge Upload</Panel.Title>
          </Panel.Header>
          <Panel.Body>
            {(result?.status === Status.Unprocessable || !!error) && (
              <Alert style="danger">{result?.error ?? error?.message ?? 'An unknown error occurred.'}</Alert>
            )}
            {result?.status === Status.Ok && (
              <Alert style="success">Your Zendesk sheet has been processed! Jobs are enqueued to merge users.</Alert>
            )}
            <Box margin="0 0 24px">
              <p>Zendesk users can be merged in bulk by uploading a CSV. The following two columns are required:</p>
              <ul>
                <li>
                  <b>ZD_MAIN_USER_ID</b>: Main Zendesk user ID.
                </li>
                <li>
                  <b>ZD_DUPLICATE_USER_IDS</b>: Zendesk user IDs to be merged into the main Zendesk user. IDs must be
                  formatted as in array in the CSV e.g. [10001, 20002].
                </li>
              </ul>
              <p>
                Template:{' '}
                <a href="/files/zendesk_user_merge.csv" className="btn-link" download target="_blank">
                  zendesk_user_merge.csv
                </a>
              </p>
            </Box>
            <FormGroup>
              <FileFormControl accept="text/csv" label={file?.name} disabled={saving} onUpload={onUpload} />
            </FormGroup>
          </Panel.Body>
          <Panel.Footer>
            <Button type="submit" kind="primary" loading={saving} disabled={!file || !signedID}>
              Upload
            </Button>
          </Panel.Footer>
        </Panel>
      </form>
    </Roles>
  );
};

import React from 'react';
import { Order, OrderSubtypeEnum, OrderTypeEnum } from '@admin/schema';
import { ModalSize } from '@shared/components/bootstrap';
import { Dialog as ConfirmationDialog, Response as ConfirmationResponse } from '@admin/components/confirmation';
import {
  MoveCancelReasonSelection,
  OnboardingCancelReasonSelection,
} from '@admin/components/orders/actions/cancel/reason_selection';

export const ConfirmationModal: React.FC<{
  order: Pick<Order, 'simultaneous' | 'type' | 'subtype' | 'itemsCount'>;
  reasonID?: string;
  setReasonID(reasonID: string): void;
  onClose(): void;
  onConfirm(): void;
}> = ({ order, reasonID, setReasonID, onClose, onConfirm }) => {
  let collectReason = false;
  let title = 'Are you sure you want to cancel this order and any linked follow-ups?';
  let description: string | JSX.Element = '';
  if (order.type === OrderTypeEnum.Pickup) {
    title = `Cancelling this order will delete the ${order.itemsCount} items attached. Are you sure you want to cancel this order?`;
  }
  if (order.simultaneous) {
    title = 'Are you sure you want to cancel this order along with the linked order?';
    description = 'This order is linked to another order. Canceling it will cancel both orders.';
  } else if (order.subtype === OrderSubtypeEnum.Onboarding) {
    collectReason = true;
    description = <OnboardingCancelReasonSelection reasonID={reasonID} setReasonID={setReasonID} />;
  } else if (order.type === OrderTypeEnum.Move) {
    collectReason = true;
    description = <MoveCancelReasonSelection reasonID={reasonID} setReasonID={setReasonID} />;
  }
  const confirmation = { title, description, size: ModalSize.Large };
  const onResponse = (response: ConfirmationResponse) => {
    onClose();
    if (response === ConfirmationResponse.Confirm) {
      onConfirm();
    }
  };
  const disabled = collectReason && !reasonID;

  return <ConfirmationDialog confirmation={{ ...confirmation, respond: onResponse, disabled }} />;
};

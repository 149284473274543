import * as React from 'react';

import { client } from '@admin/libraries/apollo';

import { useItemByIdQuery } from '@admin/schema';

export const Barcode: React.FC<{
  accountID: string | number;
  id: string | number;
}> = ({ accountID, id }) => {
  const { data } = useItemByIdQuery({
    client,
    variables: {
      accountID: String(accountID),
      id: String(id),
    },
  });

  return <>{data && data.item && `#${data.item.barcode.value}`}</>;
};

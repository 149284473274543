import React from 'react';
import { FieldFormGroupProps } from '@admin/components/fields';
import { AddressFinderFormControl } from '@admin/components/fields/address_finder_form_control';
import { useField } from 'formik';
import { FormikFieldFormGroup } from '@shared/components/fields/formik/formik_field_form_group';

export const FormikAddressFinderFormGroup = ({
  accountID,
  id,
  help,
  name,
  label,
}: {
  accountID: string;
  name: string;
} & Omit<FieldFormGroupProps, 'has'>) => {
  const [_, { value }, { setValue }] = useField(name);
  return (
    <FormikFieldFormGroup name={name} id={id} label={label} help={help}>
      <AddressFinderFormControl
        id="address_input"
        name="Address"
        value={value}
        accountID={accountID}
        onChange={setValue}
      />
    </FormikFieldFormGroup>
  );
};

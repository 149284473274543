import React, { useEffect } from 'react';

import { Billing__SourceFragment, useAccountSourcesQuery } from '@admin/schema';

import { Radio } from '@shared/components/bootstrap';

import { client } from '@admin/libraries/apollo';

import { Detailed } from './detailed';

export const Radios: React.FC<{
  name: string;
  accountID: string;
  selectedID?: string;
  onSelect(source: Billing__SourceFragment): void;
}> = ({ name, accountID, selectedID, onSelect }) => {
  const { data } = useAccountSourcesQuery({ client, variables: { accountID } });
  const sources = data?.account?.sources;

  useEffect(() => {
    if (!sources || selectedID !== undefined) {
      return;
    }
    const selection = sources.find((source) => source.default);
    if (selection) {
      onSelect(selection);
    }
  }, [sources]);

  return (
    <>
      {sources?.map((source) => (
        <Radio key={source.id}>
          <input
            type="radio"
            name={name}
            value={source.id}
            checked={selectedID === source.id}
            onChange={(event) => {
              if (event.target.checked) {
                onSelect(source);
              }
            }}
          />
          <Detailed source={source} />
        </Radio>
      ))}
    </>
  );
};

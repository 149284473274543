(function () {
  const app = angular.module('app');

  app.directive('dispatchedMovers', function () {
    return {
      restrict: 'E',
      scope: {
        dispatch: '=',
      },
      template:
        '<a ui-sref="viewDispatch({id:dispatch.id})"><span ng-repeat="assignment in dispatch.assignments">{{assignment.user.name}} ({{assignment.role}})<span ng-show="!$last">, </span></span><a/>',
    };
  });
})();

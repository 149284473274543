(function () {
  const app = angular.module('app');

  app.controller('WarehousesIndexController', [
    '$scope',
    'Warehouse',
    function ($scope, Warehouse) {
      $scope.warehouses = Warehouse.query();
    },
  ]);

  app.controller('WarehousesShowController', [
    '$scope',
    '$stateParams',
    'Warehouse',
    'Page',
    function ($scope, $stateParams, Warehouse, Page) {
      $scope.id = $stateParams.id;
      $scope.warehouse = Warehouse.get({ id: $scope.id });
      $scope.warehouse.$promise.then(function () {
        Page.reset({ title: 'Warehouse - ' + $scope.warehouse.name });
      });
    },
  ]);
})();

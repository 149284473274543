import { Availability__JobAvailabilityKindEnum } from '@admin/schema';

export const KIND_TO_DISPLAY_MAPPING = {
  [Availability__JobAvailabilityKindEnum.PickupOnboarding]: 'Pickup Onboarding',
  [Availability__JobAvailabilityKindEnum.PickupSubsequent]: 'Pickup Subsequent',
  [Availability__JobAvailabilityKindEnum.ReturnSubsequent]: 'Return Subsequent',
  [Availability__JobAvailabilityKindEnum.ReturnFinal]: 'Return Final',
  [Availability__JobAvailabilityKindEnum.Moving]: 'Moving',
};

export const KIND_OPTIONS = Object.values(Availability__JobAvailabilityKindEnum)
  .map((val) => ({
    id: val,
    name: KIND_TO_DISPLAY_MAPPING[val],
  }))
  .sort((a, b) => (b.name > a.name ? 1 : -1));

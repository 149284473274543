import * as React from 'react';

export const Picker: React.FC<{
  categories: { [key: string]: string[] };
  className?: string;
  selectedCategory: string;
  selectedSubCategory?: string;
  showSubCategoryIfEmpty?: boolean;
  onChange(category: string, subCategory: string): void;
}> = ({ categories, className, selectedCategory, selectedSubCategory, showSubCategoryIfEmpty = false, onChange }) => {
  const subCategories = (selectedCategory && categories[selectedCategory]) || [];
  const showSubCategorySelect = showSubCategoryIfEmpty || subCategories.length > 0;

  return (
    <>
      <div className={className}>
        <select
          id="ticket-category"
          className="form-control"
          value={selectedCategory}
          onChange={(event) => onChange(event.currentTarget.value, '')}
          required
        >
          <option value="">-- Select a category --</option>
          {Object.keys(categories).map((category, key) => (
            <option key={key} value={category}>
              {category}
            </option>
          ))}
        </select>
      </div>
      {showSubCategorySelect && (
        <div className={className}>
          <select
            id="ticket-sub-category"
            className="form-control"
            value={selectedSubCategory}
            onChange={(event) => onChange(selectedCategory, event.target.value)}
            required
          >
            <option value="">-- Select a sub category --</option>
            {subCategories.map((subCategory, key) => (
              <option key={key} value={subCategory}>
                {subCategory}
              </option>
            ))}
          </select>
        </div>
      )}
    </>
  );
};

import axios from 'axios';

interface IVehicleAssignmentParams {
  serialCode: string;
  warehouseId: string | undefined;
  newDispatchId?: number | null;
  oldDispatchId?: number;
  notes: string;
}

class VehicleAssignment {
  private params: IVehicleAssignmentParams;

  constructor(params: IVehicleAssignmentParams) {
    this.params = params;
  }

  public async save(): Promise<any> {
    try {
      const params = {
        serial_code: this.params.serialCode,
        new_dispatch_id: this.params.newDispatchId,
        notes: this.params.notes,
        old_dispatch_id: this.params.oldDispatchId,
      };

      if (this.params.newDispatchId === this.params.oldDispatchId || Number.isNaN(this.params.oldDispatchId)) {
        delete params.old_dispatch_id;
      }
      if (this.params.newDispatchId === 0) {
        params.new_dispatch_id = null;
      }

      const result = await axios.put(
        `/warehouses/${this.params.warehouseId}/vehicle_route_matcher/dispatch_vehicle_assignment.json`,
        params,
      );
      return result;
    } catch {
      return null;
    }
  }
}

export { VehicleAssignment };

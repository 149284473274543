import _ from 'lodash';
import * as React from 'react';
import { useEffect, useState } from 'react';

import { PlanFragment, SubscriptionFragment } from '@admin/schema';
import { IInventoryMetrics } from '@admin/types';

export interface IPlanCalculatorInputProps {
  accountID: string;
}

export interface IPlanCalculatorDataProps {
  cuft: number;
  estimatedMovers: number;
  inventoryMetrics?: IInventoryMetrics;
  subscriptions: SubscriptionFragment[];
  suggestedPlan?: PlanFragment;
}

export const PlanCalculator: React.FC<IPlanCalculatorInputProps & IPlanCalculatorDataProps> = ({
  cuft,
  inventoryMetrics,
  estimatedMovers,
  subscriptions,
  suggestedPlan,
}) => {
  const [planSummary, setPlanSummary] = useState<string>('');
  const [planCuft, setPlanCuft] = useState<number>(0);
  const [utilization, setUtilization] = useState<number>(0);
  const [unsizedItemsCount, setUnsizedItemsCount] = useState<number>(0);
  const [staleUsage, setStaleUsage] = useState<boolean>(false);

  useEffect(() => {
    if (!inventoryMetrics) {
      return;
    }
    setUtilization(inventoryMetrics.cuftUsed);
    setStaleUsage(inventoryMetrics.staleUsage);
    setUnsizedItemsCount(inventoryMetrics.unsizedItemsCount);
  }, [inventoryMetrics]);

  const summarize = (subscription: SubscriptionFragment) =>
    subscription.quantity + ' × ' + subscription.pricing.plan.name;
  const totalCuft = (subscription: SubscriptionFragment) => {
    const subscriptionCuft = subscription.customDimension
      ? subscription.customDimension.cuft
      : subscription.pricing.plan.cuft;
    return Number(subscription.quantity) * Number(subscriptionCuft);
  };
  useEffect(() => {
    if (!subscriptions) {
      return;
    }
    setPlanCuft(_.sum(subscriptions.map(totalCuft)));
    setPlanSummary(subscriptions.map(summarize).sort().join(' + '));
  }, [subscriptions]);

  return (
    <>
      <ul className="list-unstyled">
        {!!unsizedItemsCount && (
          <li>
            <span className="text-danger">
              Warning: {unsizedItemsCount} item(s) in the current inventory are missing a size.
            </span>
          </li>
        )}
        {!!staleUsage && (
          <li>
            <span className="text-danger">Warning: The plan utilization is out of date.</span>
          </li>
        )}
      </ul>
      <table className="table">
        <tbody>
          <tr>
            <td className="text-left">Current Plan:</td>
            <td className="text-right">
              <strong>
                {planSummary && <>{planSummary}&nbsp;</>}
                {planCuft > 0 && <>({planCuft} cuft)</>}
                {!planSummary && <>None</>}
              </strong>
            </td>
          </tr>
          {!!utilization && (
            <tr>
              <td className="text-left">Current Utilization:</td>
              <td className="text-right">
                <strong>{utilization.toFixed(2)} cuft</strong>
              </td>
            </tr>
          )}
          <tr>
            <td className="text-left">Calculated Cubic Footage:</td>
            <td className="text-right">
              <strong>{cuft.toFixed(2)} cuft</strong>
            </td>
          </tr>
          <tr>
            <td className="text-left">Plan Quote:</td>
            <td className="text-right">
              <strong>
                {suggestedPlan && (
                  <>
                    1 x {suggestedPlan.name} ({suggestedPlan.cuft} cuft)
                  </>
                )}
              </strong>
            </td>
          </tr>
          <tr>
            <td className="text-left">Leftover Space from Quote:</td>
            <td className="text-right">
              <strong>{suggestedPlan && <>{(suggestedPlan.cuft - utilization - cuft).toFixed(2)} cuft</>}</strong>
            </td>
          </tr>
          <tr>
            <td className="text-left">Estimated Movers (subject to change):</td>
            <td className="text-right">
              <strong>{estimatedMovers}</strong>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

(function () {
  const app = angular.module('app');

  app.controller('AvailabilityCalendarController', [
    '$scope',
    'AvailabilityService',
    'CalendarService',
    function ($scope, AvailabilityService, CalendarService) {
      $scope.calendar = CalendarService.initialize();

      $scope.status = function (entry) {
        if (entry) {
          return AvailabilityService.status($scope.unavailabilities, { date: entry.value });
        }
      };

      $scope.next = function () {
        $scope.calendar.next();
      };

      $scope.prev = function () {
        $scope.calendar.prev();
      };
    },
  ]);
})();

import { useField } from 'formik';
import React from 'react';
import { DateTime } from 'luxon';
import { FieldFormGroupProps } from '@shared/components/fields/field_form_group';
import { FormikFieldFormGroup } from '@shared/components/fields/formik/formik_field_form_group';

export function formatDateTimeForInput(datetime: DateTime) {
  return datetime.startOf('minute').toISO({ includeOffset: false, suppressSeconds: true, suppressMilliseconds: true });
}

// Component for selecting a date and time without respect to timezone.
// Value should be provided in ISO8601 format with truncated milliseconds and seconds, and without timezone.
// The exported helper `formatDateTimeForInput` can be used to generate this format from a DateTime instance.
export const FormikLocalTimestampFormGroup: React.FC<FieldFormGroupProps & { name: string }> = ({
  help,
  label,
  name,
  id,
}) => {
  const [{ value }, _, { setValue }] = useField(name);

  return (
    <FormikFieldFormGroup name={name} help={help} label={label} id={id}>
      <input
        id={id}
        name={name}
        type="datetime-local"
        className="form-control"
        value={value && formatDateTimeForInput(DateTime.fromISO(value))}
        onChange={(event) => setValue(event.target.value)}
      />
    </FormikFieldFormGroup>
  );
};

(function () {
  const app = angular.module('app');

  app.directive('clPagination', function () {
    return {
      restrict: 'E',
      templateUrl: 'partials/cl_pagination/index.html',
      scope: {
        pagination: '=',
        callback: '=',
      },
      controller: [
        '$scope',
        function ($scope) {
          $scope.changePage = function (page) {
            $scope.pagination.page = page;
            $scope.callback();
          };
        },
      ],
    };
  });
})();

import {
  Account__ClaimsSummaryFragment,
  Claim__Assessment__Coverage,
  Claim__ItemSelectionFragment,
  Claim__PropertySelectionFragment,
  DetailedClaimFragment,
  ProtectionPlan,
} from '@admin/schema';
import { MINIMAL_PROTECTION_PLAN_SLUG } from '@admin/types';

import { IResolutionEdits, selectionAmount } from './helpers';
import { compensated, denied } from '../helpers';
import { DISPLAYABLE_CLASSIFICATION } from '../contants';

const currency = (value: number) => value.toLocaleString('en', { style: 'currency', currency: 'USD' });

const INTRODUCTION =
  'The itemized results from the claim valuation are below. An FAQ explaining how each claim is ' +
  'evaluated is included at the bottom of the page.';

export const evaluationSummary = (
  claim: DetailedClaimFragment,
  itemEdits: IResolutionEdits,
  propertyEdit: IResolutionEdits,
  accountSummary: Account__ClaimsSummaryFragment,
) => {
  const { itemSelections, propertySelection } = claim;
  const summary = [];

  summary.push(`${INTRODUCTION} ${protectionPlanSummary(accountSummary.latestProtectionPlan)}`);
  const itemizedEvaluation = [];
  let paidOutMinimalCoverage = 0;
  let paidOutAdditionalCoverage = 0;
  for (const selection of itemSelections) {
    const { assessment } = selection;
    const amount = selectionAmount(itemEdits, selection) || 0;
    if (compensated(selection)) {
      if (assessment?.coverage === Claim__Assessment__Coverage.DollarPerPound) {
        paidOutMinimalCoverage += amount;
      } else {
        paidOutAdditionalCoverage += amount;
      }
    }
    itemizedEvaluation.push(itemEvaluation(selection, amount));
  }

  let propertyAmount = 0;
  if (propertySelection) {
    propertyAmount = selectionAmount(propertyEdit, propertySelection) || 0;
  }

  summary.push(
    [
      'Claim Summary',
      `Approved under $1/lb: ${currency(paidOutMinimalCoverage)}`,
      `Approved under additional coverage: ${currency(paidOutAdditionalCoverage)}`,
      `Approved under property coverage: ${currency(propertyAmount)}`,
    ].join('\n'),
  );
  summary.push(pastClaimPayments(accountSummary));
  if (itemSelections.length) {
    summary.push(`Itemized Evaluation Results\n${itemizedEvaluation.join('\n\n')}`);
  }
  if (propertySelection) {
    summary.push(propertyEvaluation(propertySelection, propertyAmount));
  }

  return summary.join('\n\n');
};

const protectionPlanSummary = (plan: ProtectionPlan) => {
  if (plan.slug === MINIMAL_PROTECTION_PLAN_SLUG) {
    return 'The Protection Plan on your account covers up to $1/lb for all stored items.';
  } else {
    return (
      `The Protection Plan on your account covers up to ${currency(plan.maxCovered)} in ` +
      'aggregate per account. Any claims beyond that are covered at $1/lb for stored items.'
    );
  }
};

const pastClaimPayments = (accountSummary: Account__ClaimsSummaryFragment) => {
  const itemPayments = accountSummary.paidItemCompensation + accountSummary.pendingItemCompensation;
  const propertyPayments = accountSummary.paidPropertyCompensation + accountSummary.pendingPropertyCompensation;
  return [
    'Past Claim Payments',
    `Payments for stored items: ${currency(itemPayments)}`,
    `Payments for property damage: ${currency(propertyPayments)}`,
  ].join('\n');
};

const itemEvaluation = (selection: Claim__ItemSelectionFragment, amount: number) => {
  const evaluation = [];
  evaluation.push(
    `Item Barcode: #${selection.item?.barcode.value ?? 'Not Inventoried'}`,
    `Item Name: ${selection.name}`,
    `Claim Type: ${selection.kind.toLowerCase()}`,
  );
  let result;
  if (compensated(selection)) {
    evaluation.push(`Item Weight (lbs): ${selection.assessment?.weight}`);
    result = DISPLAYABLE_CLASSIFICATION[selection.assessment!.classification];
  } else {
    result = selection.resolution?.reason;
  }
  evaluation.push(`Evaluation Results: ${result}`, `Approved Amount: ${currency(amount)}`);
  return evaluation.join('\n');
};

const propertyEvaluation = (selection: Claim__PropertySelectionFragment, amount: number) => {
  const evaluation = [];
  evaluation.push(`Property Name: ${selection.name}`);
  if (denied(selection)) {
    evaluation.push(`Evaluation Results: ${selection.resolution?.reason}`);
  }
  evaluation.push(`Approved Amount: ${currency(amount)}`);
  return evaluation.join('\n');
};

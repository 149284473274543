(function () {
  angular.module('app').filter('hero', function () {
    return function (objects) {
      let hero = _.find(objects, function (object) {
        return !!object.hero;
      });

      if (!hero) {
        hero = _.find(objects, function (object) {
          return !!object;
        });
      }

      return hero;
    };
  });
})();

/* eslint-disable no-unused-vars */
import React from 'react';
import { find } from 'lodash';
import PropTypes from 'prop-types';
import { Col, Panel, Radio, Row } from '@shared/components/bootstrap';
import { getDedupedValidOrdersForDispatch } from './utils';
import { colorInterpolatorForIndex } from '../../../helpers/colors';
import RoutePanelOrderRow from './RoutePanelOrderRow';

const getLeadAssignment = (dispatch) => find(dispatch.assignments, { role: 'lead' });
const NO_LEAD_MESSAGE = 'NO LEAD';

const RoutePanel = ({
  dispatch,
  index,
  selected,
  onClick,
  activeOrderId,
  onOrderClick,
  hoveredOrderId,
  onOrderHover,
  onOrderHoverOut,
}) => (
  <Row className="dashboard-feed-item">
    <Col xs={12}>
      <Panel style={{ backgroundColor: colorInterpolatorForIndex(index)(0.2), cursor: 'pointer' }}>
        <Panel.Header
          onClick={onClick}
          className="ops-route-panel-header"
          style={{
            backgroundColor: colorInterpolatorForIndex(index)(0.2),
            borderColor: colorInterpolatorForIndex(index)(0.8),
          }}
        >
          <Col xs={4}>
            <Radio>
              <input type="radio" name="focusedRoute" checked={selected} readOnly inline />
              <h1 id={`dispatch-panel-${dispatch.id}`}>Route {index + 1} </h1>
            </Radio>
          </Col>
          <Col xs={4}>
            <h2> #{dispatch.id} </h2>
          </Col>
          <Col xs={4}>
            <h3>{`${dispatch.vehicles.map((a) => a.serial_code).join(', ')}`}</h3>
          </Col>
          <Col xs={4}>
            <h4>{getLeadAssignment(dispatch) ? getLeadAssignment(dispatch).user.name : NO_LEAD_MESSAGE}</h4>
          </Col>
        </Panel.Header>
        <Panel.Body className="ops-route-panel-content">
          {getDedupedValidOrdersForDispatch(dispatch).map((order, i) => {
            const isSelected = activeOrderId === order.id;
            const hovered = hoveredOrderId === order.id;
            return (
              <RoutePanelOrderRow
                key={`order-panel-${order.id}`}
                id={`order-panel-${order.id}`}
                selected={isSelected}
                index={i}
                hovered={hovered}
                order={order}
                onClick={onOrderClick}
                onMouseOver={onOrderHover}
                onMouseOut={onOrderHoverOut}
              />
            );
          })}
        </Panel.Body>
      </Panel>
    </Col>
  </Row>
);

RoutePanel.propTypes = {
  index: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  activeOrderId: PropTypes.number,
  selected: PropTypes.bool.isRequired,
  dispatch: PropTypes.object.isRequired,
  hoveredOrderId: PropTypes.number,
  onOrderClick: PropTypes.func.isRequired,
  onOrderHover: PropTypes.func.isRequired,
  onOrderHoverOut: PropTypes.func.isRequired,
};

export { RoutePanel };
/* eslint-enable */

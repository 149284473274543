import React from 'react';

import { DateTime } from 'luxon';

import { NotificationFragment } from '@admin/schema';

import { Button, Modal } from '@shared/components/bootstrap';

import { LocaleString } from './locale_string';

interface IEmailEventsModalProps {
  deliveries: NotificationFragment['deliveries'];
  onClose(): void;
}

export const EmailEventsModal: React.FC<IEmailEventsModalProps> = ({ deliveries, onClose }) => (
  <Modal onClose={onClose} size={Modal.Size.Large}>
    <Modal.Content>
      <Modal.Body>
        <div className="email-events-dialog dialog">
          <div className="dialog-contents">
            <div className="dialog-title">Events</div>
            <div className="dialog-description">
              <ul>
                {deliveries.map((delivery, i, { length }) => (
                  <li className="text-left" key={delivery.id}>
                    <span className="label label-primary">TEAL</span> <strong>{delivery.state.toLowerCase()}</strong>
                    {' on '}
                    <LocaleString
                      date={delivery.sent || delivery.scheduled || delivery.updatedAt || delivery.createdAt}
                      format={DateTime.DATETIME_MED_WITH_SECONDS}
                    />
                    {delivery.triggerer && (
                      <span> {`(${i === length - 1 ? 'triggered by' : 'resent by'} ${delivery.triggerer})`}</span>
                    )}
                    <ul>
                      {delivery.emailEvents.map((event) => (
                        <li key={event.id}>
                          <span className="label label-success">{event.sender}</span> <strong>{event.event}</strong>
                          {' on '}
                          <LocaleString date={event.timestamp} format={DateTime.DATETIME_MED_WITH_SECONDS} />
                        </li>
                      ))}
                    </ul>
                  </li>
                ))}
              </ul>
            </div>
            <div className="dialog-actions">
              <Button size="lg" kind="primary" onClick={onClose}>
                Close
              </Button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal.Content>
  </Modal>
);

import axios from 'axios';
import * as React from 'react';
import { useEffect, useState } from 'react';

import { IAccountAgreementSummary } from '@admin/types';

export const Summary: React.FC<{
  accountID: number;
}> = ({ accountID }) => {
  const [agreementSummary, setAgreementSummary] = useState<IAccountAgreementSummary | undefined>(undefined);
  useEffect(() => {
    const request = axios.get<IAccountAgreementSummary>(`/accounts/${accountID}/agreement_summary.json`);
    request.then(({ data }) => {
      setAgreementSummary(data);
    });
  }, [accountID]);

  if (!agreementSummary) {
    return null;
  }

  const { summaries } = agreementSummary;

  return (
    <ul>
      {summaries.map((summary, index) => (
        <li key={index}>{summary}</li>
      ))}
    </ul>
  );
};

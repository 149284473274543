import React from 'react';
import Spinner from 'react-spinkit';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import DatePicker from 'react-datepicker';
import { Col, Panel, Row } from '@shared/components/bootstrap';
import { selectRegionEntities } from '../../../redux';

const RegionDateSelector = ({ regionId, onRegionChange, regions, ready, date, onDateChange }) => {
  const regionOptions = regions.map((region) => ({
    value: region.id,
    label: region.name,
  }));
  return (
    <Panel className="dashboard-feed-item region-selector">
      <Panel.Body>
        <Row className="dashboard-selector-loader">
          <Col sm={8}>
            <DatePicker
              className="form-control dashboard-date-picker"
              onChange={(value) => onDateChange(value)}
              selected={date}
            />
          </Col>
          <Col sm={4} className="selector-controls">
            {!ready && <Spinner className="dd-spinner" name="circle" fadeIn="none" />}
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <Select
              name="form-field-name"
              value={regionOptions.find((option) => option.value === regionId)}
              onChange={onRegionChange}
              options={regionOptions}
            />
          </Col>
        </Row>
      </Panel.Body>
    </Panel>
  );
};

RegionDateSelector.propTypes = {
  regionId: PropTypes.number,
  onRegionChange: PropTypes.func.isRequired,
  regions: PropTypes.array.isRequired,
  ready: PropTypes.bool,
  onDateChange: PropTypes.func.isRequired,
  date: PropTypes.object,
};

export default connect(
  createStructuredSelector({
    regions: selectRegionEntities,
  }),
)(RegionDateSelector);

import React, { useState } from 'react';

import { ConfirmationModal } from '@shared/components/helpers/confirmation_modal';
import { client } from '@admin/libraries/apollo';
import { useResendIterableNotificationMutation } from '@admin/schema';

const INITIAL_MESSAGE = 'Once a notification is queued it cannot be canceled.';
const ERROR_MESSAGE = 'Something went wrong. Please try again or contact tech support.';

export const ResendIterableModal: React.FC<{
  accountId: string;
  kind: string;
  onClose(): void;
}> = ({ onClose, accountId, kind }) => {
  const [resend] = useResendIterableNotificationMutation({
    client,
    variables: { accountId, kind },
  });

  const [error, setError] = useState(false);

  const onConfirm = () => {
    setError(false);
    resend().catch(() => setError(true));
  };

  return (
    <ConfirmationModal
      confirmKind="primary"
      onConfirm={onConfirm}
      onCancel={onClose}
      message={error ? ERROR_MESSAGE : INITIAL_MESSAGE}
      confirmText="Resend"
      title="Are you sure?"
    />
  );
};

(function () {
  angular.module('app').factory('Item', [
    '$resource',
    function ($resource) {
      return $resource(
        '/accounts/:account_id/items/:id.json',
        {
          id: '@id',
          account_id: '@account_id',
        },
        {
          index: {
            method: 'GET',
            isArray: true,
          },
          groupables: {
            method: 'GET',
            isArray: true,
            url: '/accounts/:account_id/items/groupables.json',
          },
          requestables: {
            method: 'GET',
            isArray: true,
            url: '/accounts/:account_id/items/requestables.json',
          },
          create: {
            method: 'POST',
          },
          update: {
            method: 'PATCH',
          },
          bulkUpdate: {
            method: 'PATCH',
            url: '/bulk/items.json',
          },
        },
      );
    },
  ]);
})();

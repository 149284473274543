import * as React from 'react';
import { ErrorMessage, FormContext, useForm } from 'react-hook-form';

import {
  Alert,
  AnchorButton as Link,
  Breadcrumb,
  Button,
  ControlLabel,
  FormGroup,
  HelpBlock,
  Radio,
} from '@shared/components/bootstrap';

import { Panel } from '@admin/components/helpers/panel';
import { Spinner } from '@admin/components/spinner';
import { Spacer } from '@shared/components/helpers';

import {
  SelfStorage__ReservationFragment,
  SelfStorage__UnitGroupFragment,
  Status,
  useModifySelfStorageReservationMutation,
  useSelfStorageReservationQuery,
} from '@admin/schema';
import { client } from '@admin/libraries/apollo';

import {
  detailSelfStorageReservationURL as reservationURL,
  listSelfStorageReservationsURL as reservationsURL,
} from '@admin/config/routes';
import { Container as ThirdPartyContainer } from './third_party/container';
import { Quote } from './quote';

interface IFormData {
  date: string;
  quote: 'enabled' | 'disabled';
  group?: SelfStorage__UnitGroupFragment;
}

const Breadcrumbs: React.FC<{ reservation?: SelfStorage__ReservationFragment }> = ({ reservation }) => (
  <div className="page-header">
    <Breadcrumb>
      <Breadcrumb.Item>
        <a href={reservationsURL()}>Reservations</a>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        <a href={reservation ? reservationURL(reservation) : '#'}>Reservation #{reservation?.id || '-'}</a>
      </Breadcrumb.Item>
      <Breadcrumb.Item>Edit</Breadcrumb.Item>
    </Breadcrumb>
  </div>
);

const Container: React.FC<{
  reservation: SelfStorage__ReservationFragment;
  onSave(): void;
}> = ({ reservation, onSave }) => {
  const [save, { loading, data }] = useModifySelfStorageReservationMutation({ client });
  const form = useForm<IFormData>({
    defaultValues: {
      date: reservation.date ?? undefined,
      quote: 'disabled',
    },
  });

  const onSubmit = async (input: IFormData) => {
    const result = await save({
      variables: {
        id: reservation.id,
        input: {
          date: input.date,
          ...(input.group && {
            classificationID: input.group.classification.id,
            priceID: input.group.price.id,
            promotionID: input.group.promotion?.id,
          }),
        },
      },
    });

    if (result && result.data?.modify.status === Status.Ok) {
      onSave();
    }
  };

  return (
    <FormContext {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Panel>
          <Panel.Header>
            <Panel.Title>Edit Reservation</Panel.Title>
          </Panel.Header>
          <Panel.Body>
            <FormGroup has={form.errors.date ? 'error' : undefined}>
              <ControlLabel htmlFor="reservation_date">Move-In Date:</ControlLabel>
              <input
                id="reservation_date"
                type="date"
                className="form-control"
                name="date"
                ref={form.register({ required: 'please enter a date' })}
              />
              <ErrorMessage name="date">{({ message }) => <HelpBlock>{message}</HelpBlock>}</ErrorMessage>
            </FormGroup>
            <FormGroup>
              <ControlLabel>Quote:</ControlLabel>
              <Radio>
                <input type="radio" name="quote" ref={form.register()} value="disabled" />
                Leave as is
              </Radio>
              <Radio>
                <input type="radio" name="quote" ref={form.register()} value="enabled" />
                Change quote
              </Radio>
            </FormGroup>
            {form.watch('quote') === 'enabled' && <Quote facilityID={reservation.facility.id} />}
            {data?.modify?.error && <Alert style="danger">{data?.modify.error}</Alert>}
          </Panel.Body>
          <Panel.Footer align="right">
            <Link kind="default" href={reservationURL(reservation)} disabled={loading}>
              Cancel
            </Link>
            <Spacer width="8px" />
            <Button kind="primary" type="submit" loading={loading}>
              Save
            </Button>
          </Panel.Footer>
        </Panel>
      </form>
    </FormContext>
  );
};

export const Form: React.FC<{
  id: string;
  onSave(): void;
}> = ({ id, onSave }) => {
  const { data } = useSelfStorageReservationQuery({ client, variables: { id } });
  const reservation = data?.reservation;

  if (!reservation) {
    return <Spinner />;
  }

  return (
    <>
      <Breadcrumbs reservation={reservation} />
      {reservation.facility.partner?.isThirdParty ? (
        <ThirdPartyContainer reservation={reservation} onSave={onSave} />
      ) : (
        <Container reservation={reservation} onSave={onSave} />
      )}
    </>
  );
};

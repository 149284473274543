import * as React from 'react';

import styled from '@emotion/styled';

import { client } from '@admin/libraries/apollo';
import { useDispatchPreTripAssessmentQuery } from '@admin/schema';

const Heading = styled.div`
  padding: 1.5rem 2.5rem 0.5rem;
  font-weight: bold;
  font-size: 14px;
`;

const PreTripIssues = styled.div`
  padding: 1rem 2.2rem;
`;

const IssuesBox = styled.div`
  border-style: solid;
  border-color: lightgray;
  border-width: thin;
  border-radius: 1rem;
  padding: 1rem;
`;

const IssuesHeading = styled.div`
  font-weight: bold;
`;

const IssuesText = styled.div`
  font-size: 12px;
`;

export const PreTripResults: React.FC<{ dispatchID: string }> = ({ dispatchID }) => {
  const { data } = useDispatchPreTripAssessmentQuery({
    client,
    variables: { dispatchID },
  });

  const dispatchPreTripAssessment = data?.dispatch.preTripAssessment;
  if (!dispatchPreTripAssessment) return null;

  const issues =
    dispatchPreTripAssessment.unresolvedSelections
      ?.map((selection) => `${selection.preTripItem.name} (${selection.preTripOption.name})`)
      ?.join(', ') ?? 'None!';
  const other = dispatchPreTripAssessment.other;

  return (
    <>
      <Heading>Pre-Trip Assessment Results</Heading>
      <PreTripIssues>
        <IssuesBox>
          <IssuesHeading>Reported Item Issues:</IssuesHeading>
          <IssuesText>{issues}</IssuesText>
          {other && (
            <>
              <br />
              <IssuesHeading>M&amp;P also reported:</IssuesHeading>
              <IssuesText>{other}</IssuesText>
            </>
          )}
        </IssuesBox>
      </PreTripIssues>
    </>
  );
};

import React from 'react';

import { TicketGroupFragment } from '@admin/schema';

export const Picker: React.FC<{
  ticketGroups: TicketGroupFragment[];
  className?: string;
  selectedGroupID?: string;
  selectedAssigneeID?: string;
  showAssigneePicker?: boolean;
  onChange(groupID?: string, userID?: string): void;
}> = ({ ticketGroups, className, selectedGroupID, selectedAssigneeID, showAssigneePicker = true, onChange }) => {
  const selectedGroup = ticketGroups.find((group) => group.id === selectedGroupID);

  return (
    <>
      <div className={className}>
        <select
          id="ticket-group-select"
          className="form-control"
          value={selectedGroupID}
          onChange={(event) => onChange(event.currentTarget.value, '')}
          required
        >
          <option value="">-- Group --</option>
          {ticketGroups.map((group) => (
            <option key={group.id} value={group.id}>
              {group.name}
            </option>
          ))}
        </select>
      </div>
      {showAssigneePicker && (
        <div className={className}>
          <select
            id="user-select"
            className="form-control"
            value={selectedAssigneeID}
            onChange={(event) => onChange(selectedGroupID, event.currentTarget.value)}
          >
            <option value="">-- Assignee --</option>
            {!!selectedGroup &&
              selectedGroup.users.map((user) => (
                <option key={user.id} value={user.id}>
                  {user.name}
                </option>
              ))}
          </select>
        </div>
      )}
    </>
  );
};

import { CustomFields } from '@admin/components/accounts/pricing/custom_fields';
import { validate } from '@admin/components/accounts/pricing/validation';
import {
  Dialog as ConfirmationDialog,
  Response as ConfirmationResponse,
  useConfirmation,
} from '@admin/components/confirmation';
import { DiscountSelector } from '@admin/components/discount_selector/discount_selector';
import { CurrencyFormControl, FieldFormGroup, SelectFormGroup } from '@admin/components/fields';
import { Roles } from '@admin/components/helpers/roles';
import { Spinner } from '@admin/components/spinner';
import { accountsURL, accountURL, newReferralURL } from '@admin/config/routes';
import { client } from '@admin/libraries/apollo';
import {
  Coupon,
  CustomDimensionInput,
  Plan,
  PlanKindEnum,
  PriceMatch__Kind,
  Pricing,
  useUpdateAccountPricingMutation,
  useAccountWithPricingOptionsQuery,
  Status,
  AccountSubscriptionsDocument,
} from '@admin/schema';
import { UserRole } from '@admin/types';
import { IDiscount } from '@admin/types/discount';
import styled from '@emotion/styled';
import {
  Alert,
  AnchorButton,
  Breadcrumb,
  Button,
  InputGroup,
  Panel,
  Radio,
  Spacing,
} from '@shared/components/bootstrap';
import { currency } from '@shared/utils';
import React, { useEffect, useState } from 'react';

export type Dimensions = Partial<CustomDimensionInput>;

export type Errors = { [field: string]: string };

const CancelButton = styled(Button)`
  margin-right: 8px;
`;

const discountFromCoupon = (coupon: Pick<Coupon, 'name' | 'id'> | undefined | null): IDiscount | undefined =>
  coupon ? { name: coupon.name, coupon_id: coupon.id } : undefined;

export const Edit: React.FC<{ accountID: string; onSave(): void }> = ({ accountID, onSave }) => {
  const [discount, setDiscount] = useState<IDiscount | undefined>();
  const [price, setPrice] = useState<number | undefined>();
  const [protectionPricingID, setProtectionPricingID] = useState<string | undefined>();
  const [storagePricing, setStoragePricing] = useState<
    (Pick<Pricing, 'id'> & { plan: Pick<Plan, 'id' | 'kind'> }) | undefined
  >();
  const [customDimensions, setCustomDimensions] = useState<Dimensions | undefined>();
  const [priceMatchKind, setPriceMatchKind] = useState<PriceMatch__Kind | undefined>();
  const [errors, setErrors] = useState<Errors | undefined>();
  const { confirmation, confirm } = useConfirmation();
  const [update, { loading }] = useUpdateAccountPricingMutation({
    client,
    refetchQueries: [{ query: AccountSubscriptionsDocument, variables: { accountID } }],
    awaitRefetchQueries: true,
  });

  const { data } = useAccountWithPricingOptionsQuery({ client, variables: { accountID }, fetchPolicy: 'network-only' });

  useEffect(() => {
    if (data) {
      setDiscount(discountFromCoupon(data.account.coupon));
      setPrice(data.account.currentStorageSubscription?.total);
      setProtectionPricingID(data.account.currentProtectionSubscription?.pricing.id);
      setStoragePricing(data.account.currentStorageSubscription?.pricing);

      // The types of customDimension and the dimension input aren't the same, which can result
      // in a GraphQL error with 'cuft,' 'id,' and '__typename' unexpectedly passed in
      const initialDimensions = data.account.currentStorageSubscription?.customDimension;
      setCustomDimensions(
        initialDimensions
          ? {
              length: initialDimensions.length,
              width: initialDimensions.width,
            }
          : undefined,
      );

      setPriceMatchKind(data.account.priceMatchKind ?? undefined);
    }
  }, [data]);

  if (!data) {
    return <Spinner />;
  }

  const currentStorageSubscription = data.account.currentStorageSubscription;
  const storagePricings = data.account.pricingOptions.storagePricings;
  const protectionPricings = data.account.pricingOptions.protectionPricings;

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    event.stopPropagation();

    setErrors(undefined);

    const isCustom = storagePricing?.plan.kind === PlanKindEnum.Custom;

    const validation = validate(
      {
        couponID: discount?.coupon_id,
        promoCode: discount?.promo_code,
        price,
        storagePricingID: storagePricing?.id,
        protectionPricingID,
        dimensions: isCustom ? customDimensions : undefined,
        priceMatchKind: isCustom ? priceMatchKind : undefined,
      },
      isCustom,
    );

    if (validation.valid) {
      setErrors(undefined);
      const confirmed = await confirm({
        title: 'Update Pricing?',
        description: 'Are you sure you want to update this customer’s pricing?',
      });
      if (confirmed !== ConfirmationResponse.Confirm) return;

      const result = await update({ variables: { accountID, input: validation.input } });
      if (result?.data?.update.status === Status.Ok) {
        onSave();
      } else {
        setErrors({ base: result?.data?.update.error?.message ?? 'An unknown error occurred' });
      }
    } else {
      setErrors(validation.errors);
    }
  };

  const onStoragePricingChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedPricing = storagePricings.find((pricing) => pricing.id === event.target.value);
    setStoragePricing(selectedPricing);

    if (selectedPricing?.plan.kind === PlanKindEnum.Custom) {
      if (selectedPricing?.id === currentStorageSubscription?.pricing.id) {
        // For custom subscriptions the current price is not the amount of the pricing
        setPrice(currentStorageSubscription?.total);
        setCustomDimensions(currentStorageSubscription?.customDimension ?? undefined);
        setPriceMatchKind(data.account.priceMatchKind ?? undefined);
      } else {
        setPrice(undefined);
      }
    } else {
      setPrice(selectedPricing?.amount);
    }
  };

  return (
    <Roles show={[UserRole.Admin, UserRole.L2Agent, UserRole.Billing, UserRole.BillingAdmin]}>
      <div className="text-overflow pad-top pad-btm page-header">
        <Breadcrumb>
          <Breadcrumb.Item>
            <a href={accountsURL()}>Accounts</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <a href={accountURL(data.account)}>
              #{data.account.number} ({data.account.customer.name})
            </a>
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Edit Pricing</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      {confirmation && <ConfirmationDialog confirmation={confirmation} />}
      <form onSubmit={onSubmit}>
        <Panel>
          <Panel.Body>
            {errors?.base && <Alert style="danger">{errors.base}</Alert>}
            <FieldFormGroup label="Coupon">
              <DiscountSelector
                onDiscountSelected={(selectedDiscount) => setDiscount(selectedDiscount ?? undefined)}
                initDiscount={discountFromCoupon(data.account.coupon)}
                referrers={false}
                placeholder="Type promo code"
              />
              <Spacing mt={4} mb={4} tag="div">
                <AnchorButton kind="info" href={newReferralURL()}>
                  Add Referral
                </AnchorButton>
              </Spacing>
            </FieldFormGroup>
            {storagePricings.length > 0 && (
              <>
                <SelectFormGroup
                  id="storage_pricing"
                  label="Storage Plan"
                  value={storagePricing?.id}
                  onChange={onStoragePricingChange}
                  has={errors?.storagePricing ? 'error' : undefined}
                  help={errors?.storagePricing}
                >
                  <option disabled value="">
                    - Storage Plan -
                  </option>
                  {storagePricings.map((pricing) => (
                    <option value={pricing.id} key={pricing.id}>
                      {pricing.plan.name} ({pricing.plan.cuft} cuft)
                    </option>
                  ))}
                </SelectFormGroup>
                {storagePricing?.plan.kind === PlanKindEnum.Custom && (
                  <CustomFields
                    dimensions={customDimensions}
                    priceMatchKind={priceMatchKind}
                    onChangeDimensions={setCustomDimensions}
                    onChangePriceMatchKind={setPriceMatchKind}
                  />
                )}
                <FieldFormGroup
                  id="price"
                  label="Price"
                  has={errors?.price ? 'error' : undefined}
                  help={`${errors?.price ?? ''} Current Rate: ${
                    currentStorageSubscription ? currency(currentStorageSubscription.total) : '-'
                  }`}
                >
                  <InputGroup>
                    <InputGroup.Addon>$</InputGroup.Addon>
                    <CurrencyFormControl id="price" amount={price} onChange={setPrice} />
                  </InputGroup>
                </FieldFormGroup>
              </>
            )}
            {protectionPricings.length > 0 && (
              <FieldFormGroup
                label="Protection Plan"
                has={errors?.protectionPricing ? 'error' : undefined}
                help={errors?.protectionPricing}
              >
                {protectionPricings.map((pricing) => (
                  <Radio key={pricing.id}>
                    <input
                      type="radio"
                      value={pricing.id}
                      checked={protectionPricingID === pricing.id}
                      onChange={(event) => {
                        if (event.target.checked) {
                          setProtectionPricingID(pricing.id);
                        }
                      }}
                    />
                    {pricing.plan.name} - {currency(pricing.amount)}/month
                  </Radio>
                ))}
              </FieldFormGroup>
            )}
          </Panel.Body>
          <Panel.Footer align="right">
            <CancelButton kind="default" onClick={onSave} disabled={loading}>
              Cancel
            </CancelButton>
            <Button type="submit" kind="primary" loading={loading} disabled={loading}>
              Submit
            </Button>
          </Panel.Footer>
        </Panel>
      </form>
    </Roles>
  );
};

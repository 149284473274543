(function () {
  angular.module('app').factory('ItemGroup', [
    '$resource',
    function ($resource) {
      return $resource(
        '/item_groups/:id.json',
        {
          id: '@id',
          account_id: '@account_id',
        },
        {
          create: {
            method: 'POST',
            url: '/accounts/:account_id/item_groups/:id.json',
          },
          update: {
            method: 'PATCH',
            url: '/accounts/:account_id/item_groups/:id.json',
          },
          get: {
            isArray: false,
            method: 'GET',
            url: '/item_groups/:id.json',
          },
          query: {
            isArray: true,
            method: 'GET',
            url: '/accounts/:account_id/item_groups.json',
          },
        },
      );
    },
  ]);
})();

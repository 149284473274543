import React from 'react';
import { useField } from 'formik';

import Select from 'react-select/creatable';

import { FieldFormGroupProps, FieldFormGroup } from '@shared/components/fields/field_form_group';

type SelectFormControlProps<T extends string | number> = React.SelectHTMLAttributes<HTMLSelectElement> &
  FieldFormGroupProps & { name: string; options: Array<Option<T>> };

interface Option<T> {
  label: string;
  value: T;
}

export const FormikAutosuggestFormGroup = <T extends string | number>({
  has,
  help,
  label,
  options,
  ...input
}: SelectFormControlProps<T>) => {
  const [{ value }, { error, touched }, { setValue }] = useField<T | undefined>(input.name);
  const amendedOptions = value ? options.concat([{ label: value.toString(), value }]) : options;
  const getValue = () => amendedOptions.filter((option) => value === option.value);

  return (
    <FieldFormGroup has={touched && error ? 'error' : undefined} help={error ?? help} label={label} id={input.id}>
      <Select
        id={input.id}
        options={options}
        value={getValue()}
        name={input.name}
        onChange={(option) => setValue(option?.value)}
        placeholder={input.placeholder}
        isDisabled={input.disabled}
      />
    </FieldFormGroup>
  );
};

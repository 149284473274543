import { DateTime } from 'luxon';
import React, { useState } from 'react';

import { PageHeader } from '@admin/components/helpers/page_header';
import { Alert, Breadcrumb, Panel } from '@shared/components/bootstrap';
import {
  Availability__BlockReasonEnum,
  Availability__FacilityCuftLimitFiltersInput,
  AvailabilityFacilityCuftLimitsDocument,
  useAvailabilityFacilityCuftLimitsDestroyMutation,
  useAvailabilityFacilityCuftLimitsQuery,
} from '@admin/schema';

import { client } from '@admin/libraries/apollo';
import { Spinner } from '@admin/components/spinner';
import { Pagination } from '@admin/components/pagination';

import { AvailabilityControls } from '@admin/components/availability/controls';
import { warehouseCapacityNewURL, warehouseOperatingScheduleTemplateNewURL } from '@admin/config/routes';
import { Entries } from './entries';
import { Filters } from './filters';

const DEFAULT_FILTERS = {
  warehouseIDs: [],
  fromDate: DateTime.local().toISODate(),
  tillDate: DateTime.local().plus({ days: 30 }).toISODate(),
};

export const List: React.FC = () => {
  const [filters, setFilters] = useState<Availability__FacilityCuftLimitFiltersInput>(DEFAULT_FILTERS);
  const [page, setPage] = useState<number>();
  const [selectedIDs, setSelectedIDs] = useState<string[]>([]);

  const { data, loading: querying } = useAvailabilityFacilityCuftLimitsQuery({
    client,
    variables: {
      page,
      filters: {
        warehouseIDs: filters.warehouseIDs,
        fromDate: filters.fromDate || null,
        tillDate: filters.tillDate || null,
      },
    },
  });

  const [destroy, { data: destroyData, loading: destroying }] = useAvailabilityFacilityCuftLimitsDestroyMutation({
    client,
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: AvailabilityFacilityCuftLimitsDocument,
        variables: {
          page,
          filters: {
            warehouseIDs: filters.warehouseIDs,
            fromDate: filters.fromDate || null,
            tillDate: filters.tillDate || null,
          },
        },
      },
    ],
    onCompleted: () => setSelectedIDs([]),
  });

  const onDestroy = async () => {
    await destroy({
      variables: {
        IDs: selectedIDs,
      },
    });
  };

  const onPageChange = (pageNumber: number) => {
    setSelectedIDs([]);
    setPage(pageNumber);
  };

  const paginated = data?.paginated;
  const options =
    paginated?.results
      .filter((result) => result.reason !== Availability__BlockReasonEnum.WeeklySchedule)
      .map(({ id }) => id) ?? [];
  const policy = data?.policy;
  const loading = querying || destroying;

  return (
    <>
      <PageHeader>
        <Breadcrumb>
          <Breadcrumb.Item active>Warehouse Capacity</Breadcrumb.Item>
        </Breadcrumb>
      </PageHeader>
      <Panel>
        <Panel.Body>
          {destroyData?.result?.error && <Alert style="danger">{destroyData.result.error}</Alert>}
          <Filters filters={filters} onChange={setFilters} />
          <AvailabilityControls
            createHref={warehouseCapacityNewURL()}
            weeklyScheduleHref={warehouseOperatingScheduleTemplateNewURL()}
            loading={loading}
            options={options}
            selectedOptions={selectedIDs}
            create={policy?.create}
            destroy={policy?.destroy}
            onDestroy={onDestroy}
            setSelectedOptions={setSelectedIDs}
          />
          {querying && <Spinner />}
          {paginated && (
            <Entries
              facilityCuftLimits={paginated.results}
              selectedOptions={selectedIDs}
              setSelectedOptions={setSelectedIDs}
            />
          )}
          {paginated && <Pagination onPage={onPageChange} pagination={paginated.pagination} />}
        </Panel.Body>
      </Panel>
    </>
  );
};

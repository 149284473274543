/* eslint no-underscore-dangle: ["error", { "allow": ["_user_options", "_destroy"] }] */
import angular from 'angular';
import moment from 'moment';

const app = angular.module('app');

app.controller('ShiftsIndexController', [
  '$q',
  '$location',
  '$filter',
  '$resource',
  'Shift',
  'Team',
  'ErrorService',
  'ConfirmationService',
  'FiltersService',
  'HistoryModalService',
  function (
    $q,
    $location,
    $filter,
    $resource,
    Shift,
    Team,
    ErrorService,
    ConfirmationService,
    FiltersService,
    HistoryModalService,
  ) {
    const Approve = $resource('/shifts/approve.json');
    const Actionable = $resource('/shifts/actionables.json');
    const number = $filter('number');

    const self = this;
    self.teams = Team.query({
      assignable: true,
    });
    self.filters = $location.search() || {};
    if (self.filters.team_id) {
      self.filters.team_id = Number(self.filters.team_id);
    }
    self.filters.from = self.filters.from ? new Date(moment(self.filters.from, 'YYYY-MM-DD')) : new Date();
    self.filters.till = self.filters.till ? new Date(moment(self.filters.till, 'YYYY-MM-DD')) : new Date();
    self.mode = self.filters.mode;
    self.selections = [];
    self.showModal = false;
    self.editingShiftID = null;

    function distanceBetweenTimestamps(from, till, tz, units) {
      if (!(from && till)) {
        return 0;
      }
      return moment.tz(from, tz).diff(moment.tz(till, tz), units);
    }

    function resetFromAndTillFilters() {
      self.filters.from = new Date();
      self.filters.till = new Date();
    }

    function restrictFromAndTillFilters(attribute) {
      const from = moment(self.filters.from);
      const till = moment(self.filters.till);

      if (till < from) {
        switch (attribute) {
          case 'from':
            self.filters.till = self.filters.from;
            break;
          case 'till':
            self.filters.from = self.filters.till;
            break;
          default:
            resetFromAndTillFilters();
            break;
        }
      } else if (till.diff(from, 'days') > 7) {
        switch (attribute) {
          case 'from':
            self.filters.till = new Date(from.add(7, 'days'));
            break;
          case 'till':
            self.filters.from = new Date(till.subtract(7, 'days'));
            break;
          default:
            resetFromAndTillFilters();
            break;
        }
      }
    }

    function presentShiftsHistoryController(shift) {
      HistoryModalService.present(shift.id, 'SHIFT', shift.user.tz);
    }

    self.hideModal = () => {
      self.editingShiftID = null;
      self.showModal = false;
    };

    self.updateShift = (shiftObject) => {
      const shift = _.find(self.shifts, ({ id: shift_id }) => String(shift_id) === String(shiftObject.id));
      const {
        date,
        duration,
        notes,
        approvedAt,
        overriddenAt,
        disputedAt,
        deletedAt,
        updatedAt,
        status,
        violations,
        started,
        ended,
        workBreaks,
        deleter,
      } = shiftObject;

      shift.date = date;
      shift.duration = duration;
      shift.notes = notes;
      shift.approved_at = approvedAt;
      shift.overridden_at = overriddenAt;
      shift.disputed_at = disputedAt;
      shift.deleted_at = deletedAt;
      shift.updated_at = updatedAt;
      shift.status = _.lowerCase(status);
      shift.violations = violations;

      shift.started.timestamp = started.timestamp;

      if (ended) {
        if (!shift.ended) {
          shift.ended = _.omit(ended, ['__typname']);
          shift.ended.facility = shift.started.facility;
        } else {
          shift.ended.timestamp = ended.timestamp;
        }
      } else {
        delete shift.ended;
      }

      if (deleter && !shift.deleter) {
        shift.deleter = _.omit(deleter, ['__typname']);
      }

      shift.workBreaks = _.map(workBreaks, (wb) => _.omit(wb, ['__typname']));
    };

    function presentShiftsDialogController(shift) {
      self.editingShiftID = shift.id;
      self.showModal = true;
    }

    function shiftsWithoutApprovalForTimeClockPlus() {
      return _.reject(self.shifts, (shift) => shift.approved_at || shift.deleted_at || !shift.user.time_clock_plus);
    }

    function shiftsWithoutTimeClockPlus() {
      return _.reject(self.shifts, (shift) => shift.user.time_clock_plus);
    }

    function names(shifts) {
      return _.map(shifts, (shift) => `${shift.user.name} (${shift.date})`).join(' + ');
    }

    function confirmShiftsWithoutTimeClockPlus(callback) {
      const shifts = shiftsWithoutTimeClockPlus();
      if (shifts.length > 0) {
        ConfirmationService.confirm({
          title: 'Users without Time Clock Plus',
          description: `The following people are disabled for Time Clock Plus and will be skipped: ${names(shifts)}`,
          acceptButton: 'Proceed Without Them',
          declineButton: 'Cancel',
        }).then(callback);
      } else {
        callback();
      }
    }

    function verifyShiftsWithoutApprovalForTimeClockPlus(callback) {
      const shifts = shiftsWithoutApprovalForTimeClockPlus();
      if (shifts.length > 0) {
        ErrorService.handle({
          title: 'Shifts needing Approval',
          message: `The following shifts must be reviewed prior to saving for Time Clock Plus: ${names(shifts)}`,
        });
      } else {
        callback();
      }
    }

    function verifyForTimeClockPlus(callback) {
      if (!self.shifts) {
        return;
      }

      verifyShiftsWithoutApprovalForTimeClockPlus(() => confirmShiftsWithoutTimeClockPlus(callback));
    }

    function insertShiftSelection(shift) {
      self.selections.push(shift);
    }

    function removeShiftSelection(shift) {
      _.remove(self.selections, (other) => shift.id === other.id);
    }

    self.allShiftsSelected = function () {
      const shifts = _.filter(self.shifts, (shift) => shift.started && shift.ended && !shift.approver);
      return shifts.length === self.selections.length;
    };

    self.toggleAllSelection = function ($event) {
      const { checked } = $event.target;
      const shifts = _.filter(self.shifts, (shift) => shift.started && shift.ended && !shift.approver);
      _.each(shifts, checked ? insertShiftSelection : removeShiftSelection);
    };

    self.hasSelectedShift = (shift) => self.selections && !!_.find(self.selections, (other) => other.id === shift.id);

    self.toggleShiftSelection = function ($event, shift) {
      if ($event.target.checked) {
        insertShiftSelection(shift);
      } else {
        removeShiftSelection(shift);
      }
    };

    self.reviewing = () => self.mode === 'review:all' || self.mode === 'review:team';

    self.toggleMode = (mode) => {
      self.selections = [];
      self.mode = mode;
      self.filter();
    };

    self.approveSelectedShifts = () => {
      if (self.approving) {
        return;
      }

      self.approving = new Approve({ shift_ids: _.map(self.selections, (shift) => shift.id) });
      self.approving.$save(
        () => {
          self.toggleMode('normal');
          self.approving = null;
          self.filter();
        },
        (error) => {
          self.approving = null;
          ErrorService.handle(error);
        },
      );
    };

    self.exportForTimeClockPlus = () => {
      verifyForTimeClockPlus(() => {
        const filters = FiltersService.cleanup(self.filters);
        window.open(`/shifts/time-clock-plus.csv?${_.map(filters, (value, key) => `${key}=${value}`).join('&')}`);
      });
    };

    self.add = (user) => {
      const shift = new Shift({ date: self.filters.date, user });
      presentShiftsDialogController(shift);
    };

    self.edit = (shift) => {
      presentShiftsDialogController(shift);
    };

    self.history = (shift) => {
      presentShiftsHistoryController(shift);
    };

    self.filter = (attribute) => {
      restrictFromAndTillFilters(attribute);

      const filters = FiltersService.cleanup(self.filters);
      $location.search(filters);
      filters.mode = self.mode;

      if (self.deferred) {
        self.deferred.reject();
      }
      self.deferred = $q.defer();
      self.deferred.promise.then((shifts) => {
        self.dates = _.uniq(_.map(shifts, 'date'));
        self.shifts = shifts;
      });
      self.dates = null;
      self.shifts = null;
      self.actionables = Actionable.query(filters);
      Shift.query(filters).$promise.then(self.deferred.resolve, self.deferred.reject, self.deferred.notify);
    };

    // a work break is required if working more than four hours.
    self.warnForNoWorkBreak = (shift) => !shift.work_breaks.length && shift.duration > 60 * 60 * 4;

    self.warnForWorkBreakTiming = function (shift, wb) {
      // if a shift is less than 30 minutes or
      // taken after five hours of working it might be a policy violation.
      return (
        distanceBetweenTimestamps(wb.ended, wb.started, shift.user.tz, 'minutes') < 30 ||
        (shift.started && distanceBetweenTimestamps(wb.started, shift.started.timestamp, shift.user.tz, 'hours') > 5)
      );
    };

    self.map = function (entry) {
      self.modal = {
        visible: !!entry,
        hint: `${entry.facility.name} is ${number(entry.distance, 4)} mile(s) away`,
        map: {
          center: {
            latitude: entry.latitude,
            longitude: entry.longitude,
          },
          zoom: 9,
          marker: {
            id: `${entry.id} ${entry.type}`,
            coords: {
              latitude: entry.latitude,
              longitude: entry.longitude,
            },
          },
        },
      };
    };

    self.lightbox = function (image) {
      if (image) {
        self.modal = {
          visible: !!image,
          image,
        };
      }
    };

    self.filter();
  },
]);

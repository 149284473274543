const app = angular.module('app');

app.controller('PhoneCallsIndexController', [
  '$location',
  'PhoneCall',
  'PaginationService',
  'FiltersService',
  function ($location, PhoneCall, PaginationService, FiltersService) {
    const self = this;
    self.agents = {};
    self.filters = $location.search();
    self.pagination = PaginationService.initialize({ page: $location.search().page });

    self.filter = function (f) {
      let filters = f;

      if (!f) {
        filters = self.filters || {};
      } else {
        self.pagination.reset();
      }

      filters = FiltersService.cleanup(filters);
      filters.page = self.pagination.page;
      $location.search(filters);

      self.phone_calls = null;

      PhoneCall.get(filters, (data) => {
        self.pagination.prepare(data.pagination);
        self.phone_calls = data.results;
      });
    };

    PhoneCall.callers((data) => {
      self.agents = data;
      if (self.filters.user_id !== undefined) {
        // need to convert query string to integer in order to drigger the agent drop down refresh
        self.filters.user_id = parseInt(self.filters.user_id, 10);
      }
      self.filter();
    });
  },
]);

app.controller('PhoneCallsShowController', [
  '$scope',
  '$stateParams',
  'PhoneCall',
  function ($scope, $stateParams, PhoneCall) {
    $scope.phone_call = PhoneCall.get({
      id: $stateParams.id,
    });
  },
]);

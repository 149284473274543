import styled from '@emotion/styled';
import * as React from 'react';
import { useState } from 'react';

import { SelfStorage__UnitGroupFragment } from '@admin/schema';

import { filter as filterBySize, Size, Sizing } from './sizing';
import { filter as filterBySort, Sort, Sorting } from './sorting';

const Container = styled.div`
  margin: -6px;
  display: flex;
  justify-content: space-between;
`;

const Filter = styled.div`
  padding: 6px;
`;

export const Filters: React.FC<{
  groups: SelfStorage__UnitGroupFragment[];
  children(_: { filtered: SelfStorage__UnitGroupFragment[] }): React.ReactNode;
}> = ({ groups, children }) => {
  const [size, setSize] = useState<Size>(() => new Set());
  const [sort, setSort] = useState<Sort>(Sort.Price);

  let filtered = groups;
  filtered = filterBySize(filtered, size);
  filtered = filterBySort(filtered, sort);

  return (
    <>
      {!!groups.length && (
        <Container>
          <Filter>
            <Sizing selection={size} onSelect={setSize} groups={groups} />
          </Filter>
          <Filter>
            <Sorting selection={sort} onSelect={setSort} />
          </Filter>
        </Container>
      )}
      {children({ filtered })}
    </>
  );
};

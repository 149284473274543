import { last } from 'lodash';
import * as React from 'react';
import { useState } from 'react';

import { Panel } from '@admin/components/helpers/panel';
import { Alert, Button } from '@shared/components/bootstrap';
import { Manager } from '@shared/components/files';
import { Spacer } from '@shared/components/helpers';
import { client } from '@admin/libraries/apollo';

import { SelfStorage__Upload__Kind, useSaveSelfStorageUploadMutation, Status } from '@admin/schema';
import { FileUploader } from './file_uploader';

const SAMPLE_URL = '/files/self_storage_upload.csv';

export const UploadPanel: React.FC<{ onUpload(): void }> = ({ onUpload }) => {
  const [file, setFile] = useState<File | undefined>();
  const [spreadsheetID, setSpreadsheetID] = useState<string | undefined>();

  const cancel = () => {
    setFile(undefined);
    setSpreadsheetID(undefined);
    onUpload();
  };

  const [save, { data, loading }] = useSaveSelfStorageUploadMutation({
    client,
    onCompleted: cancel,
  });

  const result = data?.result;
  const error = result?.error;
  const status = result?.status;

  const submit = () => {
    if (!spreadsheetID) {
      throw new Error('Unable to submit without a spreadsheetID');
    }

    const input = {
      kind: SelfStorage__Upload__Kind.Pricing,
      spreadsheetID,
    };

    save({ variables: { input } });
  };

  return (
    <Panel>
      <Panel.Header>
        <Panel.Title>Upload Pricing</Panel.Title>
      </Panel.Header>
      <Panel.Body>
        {!file &&
          status &&
          (() => {
            switch (status) {
              case Status.Unprocessable:
                return <Alert style="danger">{error}</Alert>;
              case Status.Ok:
                return <Alert style="info">Your pricing changes have been saved.</Alert>;
            }
          })()}
        <p>
          Select a spreadsheet to change prices (
          <a className="btn-link" target="_blank" download href={SAMPLE_URL}>
            sample
          </a>
          ):
        </p>
        <Manager
          onSave={(updatedFile, signedID) => {
            setFile(updatedFile);
            setSpreadsheetID(signedID);
          }}
          children={({ uploads }) => {
            const upload = last(uploads);
            return <FileUploader disabled={loading} file={file} upload={upload} />;
          }}
        />
      </Panel.Body>
      <Panel.Footer align="right">
        <Button kind="default" onClick={cancel} disabled={!file || loading}>
          Cancel
        </Button>
        <Spacer width="8px" />
        <Button kind="primary" onClick={submit} disabled={!file || !spreadsheetID} loading={loading}>
          Save
        </Button>
      </Panel.Footer>
    </Panel>
  );
};

import { DateTime } from 'luxon';
import * as React from 'react';

import { Region } from '@admin/schema';

import { dispatcherURL } from '@admin/config/routes';

const FROM_ISO_OPTIONS = { setZone: true };

export const Dispatch: React.FC<{
  scheduled: string;
  region: Pick<Region, 'id' | 'parentID'>;
}> = ({ scheduled, region }) => {
  const url = dispatcherURL({
    date: DateTime.fromISO(scheduled, FROM_ISO_OPTIONS).toISODate(),
    regionID: region.parentID || region.id,
  });

  return (
    <a href={url} className="btn btn-primary">
      Dispatch
    </a>
  );
};

import * as React from 'react';
import { useEffect } from 'react';

const REFRESH = 'notes.refresh';

export const Events: React.FC<{
  refetch(): void;
}> = ({ refetch, children }) => {
  // NOTE: using document event listener to simplify angular / react integration.
  useEffect(() => {
    const listener: EventListener = () => refetch();
    document.addEventListener(REFRESH, listener);
    return () => document.removeEventListener(REFRESH, listener);
  }, []);

  return <>{children}</>;
};

import angular from 'angular';
import thunk from 'redux-thunk';

angular.module('app').factory('thunkFactory', [
  'Region',
  'Order',
  'User',
  'Dispatch',
  'Checkin',
  '$state',
  'uiGmapGoogleMapApi',
  'PusherService',
  'UserService',
  'Lead',
  '$rootScope',
  (Region, Order, User, Dispatch, Checkin, $state, uiGmapGoogleMapApi, PusherService, UserService, Lead, $rootScope) =>
    thunk.withExtraArgument({
      uiGmapGoogleMapApi,
      $state,
      services: {
        PusherService,
        UserService,
      },
      resources: {
        Region,
        Order,
        User,
        Dispatch,
        Checkin,
        Lead,
      },
      $rootScope,
    }),
]);

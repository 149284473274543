/* eslint-disable no-param-reassign */
(function () {
  const app = angular.module('app');

  app.controller('LocationsShowController', [
    '$scope',
    '$stateParams',
    'Page',
    'Location',
    'Warehouse',
    function ($scope, $stateParams, Page, Location, Warehouse) {
      $scope.id = $stateParams.id;
      $scope.location = Location.get({ id: $scope.id });
      $scope.category_names = [];

      $scope.location.$promise.then(() => {
        $scope.location.categories.forEach((obj) => {
          $scope.category_names.push(obj.name);
        });

        const { barcode } = $scope.location;
        const located = $scope.location.located.object;

        if ($scope.location.located.type === 'warehouse') {
          $scope.warehouse = Warehouse.get({ id: located.id });
          $scope.warehouse.$promise.then(() => {
            Page.reset({ title: `${$scope.warehouse.abbreviation}:#${barcode.value}` });
          });
        } else {
          Page.reset({ title: `${$scope.located.type}:#${barcode.value}` });
        }
      });
    },
  ]);

  app.controller('LocationsEditController', [
    '$scope',
    '$state',
    '$q',
    '$stateParams',
    'Location',
    'ItemCategory',
    'ErrorService',
    function ($scope, $state, $q, $stateParams, Location, ItemCategory, ErrorService) {
      $scope.id = $stateParams.id;
      $scope.location = Location.get({ id: $scope.id });
      $scope.categories = ItemCategory.query();
      $scope.editing = true;
      $scope.tick = 'ticked';

      $q.all([$scope.location.$promise, $scope.categories.$promise]).then(() => {
        $scope.categories.forEach((category) => {
          category[$scope.tick] = !!$scope.location.categories.find((other) => other.id === category.id);
        });
      });

      $scope.save = () => {
        $scope.state = 'saving';
        $scope.location.barcode_attributes = $scope.location.barcode;
        $scope.location.$update(
          () => {
            $state.go('viewLocation', { warehouse_id: $scope.warehouse_id, id: $scope.location.id });
          },
          (error) => {
            $scope.state = null;
            ErrorService.handle(error);
          },
        );
      };
    },
  ]);

  app.controller('WarehouseLocationsNewController', [
    '$scope',
    '$resource',
    '$state',
    '$stateParams',
    'Page',
    'Warehouse',
    'ErrorService',
    function ($scope, $resource, $state, $stateParams, Page, Warehouse, ErrorService) {
      const Location = $resource('/warehouses/:warehouse_id/locations/:id.json', {
        warehouse_id: '@warehouse_id',
        id: '@id',
      });

      $scope.warehouse_id = $stateParams.warehouse_id;
      $scope.location = new Location({ warehouse_id: $scope.warehouse_id, type: 'shelf' });
      $scope.warehouse = Warehouse.get({ id: $scope.warehouse_id });

      $scope.warehouse.$promise.then(() => {
        Page.reset({ title: `${$scope.warehouse.abbreviation}: New Location` });
      });

      $scope.save = function () {
        $scope.state = 'saving';
        $scope.location.$save(
          () => {
            $state.go('viewLocation', { warehouse_id: $scope.warehouse_id, id: $scope.location.id });
          },
          (error) => {
            $scope.state = null;
            ErrorService.handle(error);
          },
        );
      };
    },
  ]);

  app.controller('WarehouseLocationsIndexController', [
    '$location',
    '$scope',
    '$resource',
    '$stateParams',
    'Page',
    'Warehouse',
    'PaginationService',
    'FiltersService',
    'DebounceService',
    function (
      $location,
      $scope,
      $resource,
      $stateParams,
      Page,
      Warehouse,
      PaginationService,
      FiltersService,
      DebounceService,
    ) {
      const Location = $resource('/warehouses/:warehouse_id/locations/:id.json', {
        warehouse_id: '@warehouse_id',
        id: '@id',
      });

      $scope.filters = $location.search();
      $scope.warehouse_id = $stateParams.warehouse_id;
      $scope.pagination = PaginationService.initialize({ page: $location.search().page });
      $scope.warehouse = Warehouse.get({ id: $scope.warehouse_id });

      $scope.warehouse.$promise.then(() => {
        Page.reset({ title: `${$scope.warehouse.abbreviation}: Locations` });
      });

      const debounce = DebounceService.initialize();
      $scope.filter = function (filters) {
        if (!filters) {
          filters = $scope.filters || {};
        } else {
          $scope.pagination.reset();
        }

        filters = FiltersService.cleanup(filters);
        filters.page = $scope.pagination.page;
        $location.search(filters);

        debounce.execute(Location.get(_.merge({ warehouse_id: $scope.warehouse_id }, filters))).then((data) => {
          $scope.pagination.prepare(data.pagination);
          $scope.locations = data.results;
        });
      };

      $scope.filter();
    },
  ]);
})();

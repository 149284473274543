import React from 'react';

import { client } from '@admin/libraries/apollo';
import {
  useActivePalletRequestsQuery,
  ActivePalletRequestsQuery,
  useWarehouseQuery,
  PalletRequestKindEnum,
} from '@admin/schema';

import { Panel } from '@admin/components/helpers/panel';
import { Spinner } from '@admin/components/spinner';
import { Timestamp } from '@admin/components/timestamp';
import { palletURL, locationURL, warehousesURL } from '@admin/config/routes';

import styled from '@emotion/styled';
import { Breadcrumb, Table } from '@shared/components/bootstrap';
import { COLORS } from '@root/colors';

type RequestType = ActivePalletRequestsQuery['activePalletRequests'][number];

const Link = styled.a`
  color: ${COLORS.blueLight};
`;

const RequestsTable: React.FC<{
  requests: RequestType[];
}> = ({ requests }) => (
  <Table striped responsive>
    <thead>
      <tr className="load-list__row">
        <th className="col-sm-1 text-left">Requested At</th>
        <th className="col-sm-1 text-left">Pallet</th>
        <th className="col-sm-1 text-left">Location</th>
        <th className="col-sm-1 text-left">Item Count</th>
        <th className="col-sm-1 text-left">Density</th>
        <th className="col-sm-1 text-left">Requester</th>
        <th className="col-sm-2 text-left">Customer(s)</th>
      </tr>
    </thead>
    <tbody>
      <>
        {requests
          .sort((a, b): number => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
          .map((request) => (
            <tr className="load-list__row" key={request.id}>
              <td className="col-sm-1 text-left">
                <Timestamp value={request.createdAt} timezone={request.pallet.warehouse.tz} />
              </td>
              <td className="col-sm-1 text-left">
                <Link href={palletURL(request.pallet.id)} target="_blank">
                  {request.pallet.barcode.value}
                </Link>
              </td>
              <td className="col-sm-1 text-left">
                {request.pallet.location && (
                  <Link href={locationURL(request.pallet.location.id)} target="_blank">
                    {request.pallet.location.value}
                  </Link>
                )}
                {!request.pallet.location && 'Unknown Location'}
              </td>
              <td className="col-sm-1 text-left">{request.pallet.itemsCount}</td>
              <td className="col-sm-1 text-left">
                {request.pallet.densityMetrics.percentage
                  ? request.pallet.densityMetrics.percentage.toFixed(2)
                  : request.pallet.densityMetrics.percentage}
                %
              </td>
              <td className="col-sm-1 text-left">{request.requestor.name}</td>
              <td className="col-sm-2 text-left">
                {Array.from(
                  new Set(
                    request.pallet.items
                      .map((i) => i.account.customer.name)
                      .concat(request.pallet.palletInventories.map((pi) => pi.sku.account.customer.name)),
                  ),
                ).join(', ')}
              </td>
            </tr>
          ))}
      </>
    </tbody>
  </Table>
);

export const PalletRequestsList: React.FC<{ warehouseID: string }> = ({ warehouseID }) => {
  const { data, loading } = useActivePalletRequestsQuery({
    client,
    variables: {
      warehouseId: warehouseID,
      kind: PalletRequestKindEnum.Outbound,
    },
  });

  const { data: warehouseData } = useWarehouseQuery({ client, variables: { id: warehouseID } });

  const requests = data && data.activePalletRequests;
  return (
    <>
      <h1 className="page-header text-overflow">
        <Breadcrumb>
          <Breadcrumb.Item>
            <a href={warehousesURL()}>Warehouses</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{warehouseData?.warehouse.name} - Pallet Requests</Breadcrumb.Item>
        </Breadcrumb>
      </h1>
      <Panel>
        <Panel.Header>
          <Panel.Title>Active Pallet Requests</Panel.Title>
        </Panel.Header>
        <Panel.Body>
          {loading && <Spinner />}
          {!loading && requests && <RequestsTable requests={requests} />}
        </Panel.Body>
      </Panel>
    </>
  );
};

import { useLatestCallback } from '@shared/hooks/use_latest';
import React from 'react';
import { useEffect } from 'react';
import { ApolloError } from '@apollo/client';

import { Retry } from '@shared/components/helpers';

import {
  SelfStorage__FeeFragment,
  SelfStorage__UnitFragment,
  SelfStorage__Fee__Kind,
  useSelfStorageFeesQuery,
  useSelfStorageCurrentFacilityFeesQuery,
} from '@admin/schema';
import { client } from '@admin/libraries/apollo';

import { Row } from './row';

const useSelfStorageCurrentFacilityFeeKinds = (accountID?: string, facilityID?: string) => {
  const { data } = useSelfStorageCurrentFacilityFeesQuery({
    variables: { accountID: accountID! },
    skip: !accountID,
    client,
  });
  const account = data?.account;
  if (facilityID && account) {
    const fees = account.fees.filter((fee) => fee.facilityID === facilityID);
    return new Set(fees.map(({ kind }) => kind));
  }
};

const isApplicableFee = (fee: SelfStorage__FeeFragment, unit?: SelfStorage__UnitFragment) =>
  fee.kind !== SelfStorage__Fee__Kind.Electricity || unit?.classification?.electricity;

const FACILITY_FEE_KINDS = [
  SelfStorage__Fee__Kind.UnlimitedAccess,
  SelfStorage__Fee__Kind.ExtendedAccess,
  SelfStorage__Fee__Kind.Convenience,
];

const Error: React.FC<{
  error: ApolloError;
  refetch(): Promise<any>;
}> = (props) => (
  <tr>
    <td colSpan={3}>
      <Retry {...props} fallback="An unknown error occurred while loading the facility fees." />
    </td>
  </tr>
);

export const Rows: React.FC<{
  accountID?: string;
  facilityID?: string;
  selections?: SelfStorage__FeeFragment[];
  unit?: SelfStorage__UnitFragment;
  onChange(selections?: SelfStorage__FeeFragment[]): void;
}> = ({ accountID, facilityID, selections, unit, onChange }) => {
  const { data, error, refetch } = useSelfStorageFeesQuery({
    variables: { facilityID: facilityID! },
    skip: !facilityID,
    client,
  });
  const fees = data?.fees;
  const currentFeeKinds = useSelfStorageCurrentFacilityFeeKinds(accountID, facilityID);

  const latestOnChange = useLatestCallback(onChange);

  useEffect(() => {
    latestOnChange(fees?.filter(({ waivable }) => waivable));
  }, [fees, latestOnChange]);

  useEffect(() => {
    if (selections?.some((fee) => !isApplicableFee(fee, unit))) {
      latestOnChange(selections.filter((fee) => isApplicableFee(fee, unit)));
    }
  }, [unit?.classification?.electricity, latestOnChange]);

  if (error) {
    return <Error error={error} refetch={refetch} />;
  }

  if (!fees) {
    return null;
  }

  const applicableFees = fees.filter((fee) => isApplicableFee(fee, unit));
  return (
    <>
      {applicableFees.map((fee) => (
        <Row
          key={fee.id}
          fee={fee}
          isAlreadySubscribed={!!currentFeeKinds?.has(fee.kind) && FACILITY_FEE_KINDS.includes(fee.kind)}
          selected={!!selections?.includes(fee)}
          onChange={(selected) => {
            if (!selections) {
              return;
            }
            onChange(selected ? [...selections, fee] : selections.filter(({ id }) => id !== fee.id));
          }}
        />
      ))}
    </>
  );
};

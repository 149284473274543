import React, { useRef, createElement, useEffect } from 'react';

type TrixHTMLElement = HTMLElement & {
  editor: {
    loadHTML(html: string): void;
  };
};

export type HTMLFormControlProps = Omit<React.HTMLAttributes<HTMLElement>, 'value' | 'onChange'> & {
  value: string;
  onChange(value: string): void;
};

export const HTMLFormControl: React.FC<HTMLFormControlProps> = ({ value, onChange, ...props }) => {
  const trixHTMLElementRef = useRef<TrixHTMLElement>(null);
  const onChangeRef = useRef(onChange);
  const TrixEditor = createElement('trix-editor', { ...props, ref: trixHTMLElementRef });

  useEffect(() => {
    onChangeRef.current = onChange;
  }, [onChange]);

  useEffect(() => {
    const element = trixHTMLElementRef.current;
    if (!element || element.innerHTML === value) return;

    const editor = element.editor;
    editor.loadHTML(value);
  }, [value]);

  useEffect(() => {
    const element = trixHTMLElementRef.current;
    if (!element) return;

    const listener = (event: React.ChangeEvent<TrixHTMLElement>) => {
      onChangeRef.current(event.target.innerHTML);
    };

    element.addEventListener('trix-change', listener as any);
    return () => {
      element.removeEventListener('trix-change', listener as any);
    };
  }, []);

  return <>{TrixEditor}</>;
};

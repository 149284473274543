import classNames from 'classnames';
import * as React from 'react';

import { ILabel } from '@admin/types/label';
import { Label } from './helpers/label';

interface ILabelGroup {
  className?: string;
  labels: ILabel[];
}

const LabelGroup = ({ className, labels }: ILabelGroup) => (
  <>
    {labels.map((label, index) => (
      <Label
        className={classNames(className, label.color?.startsWith('#') ? undefined : label.color ?? 'green', {
          'mar-lft-small': index,
        })}
        key={index}
        style={{ background: label.color?.[0] === '#' ? label.color : undefined }}
      >
        {label.name}
      </Label>
    ))}
  </>
);

export default LabelGroup;

const app = angular.module('app');

app.service(
  'MarkerService',
  () =>
    class MarkerService {
      constructor(map) {
        this.map = map;
        this.markersByDispatch = {};
      }

      iterateDispatches(fn) {
        const dispatchIds = Object.keys(this.markersByDispatch);
        dispatchIds.forEach((dispatchId) => fn(Number(dispatchId), this.markersByDispatch[dispatchId]));
      }

      clearMarkers(dispatchIdException) {
        this.iterateDispatches((dispatchId, dispatchMarkers) => {
          if (dispatchId !== dispatchIdException) {
            dispatchMarkers.forEach((marker) => marker.setMap(null));
            delete this.markersByDispatch[dispatchId];
          }
        });
      }

      hideMarkers(dispatchIdExceptions) {
        this.iterateDispatches((dispatchId, dispatchMarkers) => {
          if (!_.includes(dispatchIdExceptions, dispatchId)) {
            dispatchMarkers.forEach((marker) => marker.setMap(null));
          }
        });
      }

      unhideMarkers(dispatchIdException) {
        const googleMap = this.map.control.getGMap();
        this.iterateDispatches((dispatchId, dispatchMarkers) => {
          if (dispatchId !== dispatchIdException) {
            dispatchMarkers.forEach((marker) => marker.setMap(googleMap));
          }
        });
      }

      addMarker(dispatchId, marker) {
        this.markersByDispatch[dispatchId] = this.markersByDispatch[dispatchId]
          ? this.markersByDispatch[dispatchId].concat(marker)
          : [marker];
      }
    },
);

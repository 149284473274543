(function () {
  const app = angular.module('app');

  app.controller('ApplicationController', [
    '$rootScope',
    '$scope',
    '$transitions',
    'Page',
    function ($rootScope, $scope, $transitions, Page) {
      $scope.Page = Page;
      $transitions.onSuccess({}, (transition) => {
        const state = transition.to();
        $rootScope.$broadcast('message:clear');

        if (!$scope.Page.title()) {
          $scope.Page.reset({ title: state.data && state.data.title ? state.data.title : null });
        }
      });
    },
  ]);
})();

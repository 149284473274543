import styled from '@emotion/styled';
import React from 'react';

import { SelfStorage__FeeFragment } from '@admin/schema';

import { Currency, Spacer } from '@shared/components/helpers';
import { TD } from './helpers';

const Label = styled.label`
  cursor: pointer;
`;

export const ElectricityCell: React.FC<{
  fee?: SelfStorage__FeeFragment;
  checked: boolean;
  highlight: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}> = ({ fee, checked, highlight, onChange }) => (
  <TD className="text-left" highlight={!!fee && highlight}>
    {fee && (
      <Label>
        <input type="checkbox" checked={checked} onChange={onChange} />
        <Spacer width="6px" inline />
        <Currency value={fee.price} />
      </Label>
    )}
  </TD>
);

import React from 'react';
import PropTypes from 'prop-types';
import { layoutEventSystem } from '../propTypes';
import DashboardDispatchMarkers from './DashboardDispatchMarkers';

const DashboardDispatchesMarkers = ({ dispatches, ...rest }) =>
  dispatches.map((dispatch, i) => (
    <DashboardDispatchMarkers dispatch={dispatch} index={i} key={`dispatch:${dispatch.id}`} {...rest} />
  ));

DashboardDispatchesMarkers.propTypes = {
  dispatches: PropTypes.array.isRequired,
  directionsByDispatch: PropTypes.object,
  gMaps: PropTypes.object,
  ...layoutEventSystem,
};

export default DashboardDispatchesMarkers;

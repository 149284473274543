/* eslint no-param-reassign: 0 */
import { isWarehouseOrder } from '../../helpers/order';

const app = angular.module('app');

app.controller('DispatchesIndexController', [
  '$location',
  '$filter',
  '$scope',
  '$state',
  'Dispatch',
  'Region',
  'FiltersService',
  'DebounceService',
  'AccountService',
  function ($location, $filter, $scope, $state, Dispatch, Region, FiltersService, DebounceService, AccountService) {
    if ($state.params.region) {
      $scope.filters = $state.params;
      $scope.filters.region_id = $scope.filters.region.id;
      $scope.filters.region = null;
    } else {
      $scope.filters = $location.search();
    }

    $scope.regions = Region.query();
    if ($scope.filters.region_id) {
      $scope.filters.region_id = Number($scope.filters.region_id);
    }
    if ($scope.filters.date) {
      $scope.filters.date = new Date(moment($scope.filters.date, 'YYYY-MM-DD'));
    }

    $scope.generateTooltip = function (order) {
      let tooltipTemplate = '<span>';

      tooltipTemplate += `${AccountService.getCustomerNames(order.account)}<br />`;
      tooltipTemplate += `${$filter('date')(order.scheduled, 'medium')}<br />`;

      angular.forEach(order.account.subscriptions, (subscription) => {
        tooltipTemplate += `x${subscription.display_quantity} ${subscription.name} <br />`;
      });

      tooltipTemplate += $filter('address')(order.address);
      tooltipTemplate += '</span>';

      return tooltipTemplate;
    };

    $scope.checkOrderInvalid = function (dispatch, order) {
      if (dispatch.status === 'valid' || !order.flagged_at) {
        return false;
      }

      const createdAt = new Date(dispatch.created_at);
      const updatedAt = new Date(dispatch.updated_at);
      const flaggedAt = new Date(order.flagged_at);

      return flaggedAt > createdAt && flaggedAt > updatedAt;
    };

    const debounce = DebounceService.initialize();
    $scope.filter = function (filters) {
      if (!filters) {
        filters = $scope.filters || {};
      }

      filters = FiltersService.cleanup(filters);
      $location.search(filters);

      $scope.dispatches = null;
      debounce.execute(Dispatch.query(filters)).then((dispatches) => {
        $scope.dispatches = dispatches;
      });
    };

    $scope.filter();
  },
]);

app.controller('DispatchesShowController', [
  '$scope',
  '$q',
  '$stateParams',
  '$filter',
  'Dispatch',
  'AccountService',
  'PhoneCall',
  'Page',
  function ($scope, $q, $stateParams, $filter, Dispatch, AccountService, PhoneCall, Page) {
    $scope.filters = {};
    $scope.getCustomerNames = AccountService.getCustomerNames;

    $scope.id = $stateParams.id;

    $scope.dispatchPromise = Dispatch.get({ id: $stateParams.id }).$promise;
    $q.all([$scope.dispatchPromise]).then((results) => {
      $scope.dispatch = results[0];
      $scope.filters.region = results[0].region;
      $scope.$broadcast('dispatch:loaded');

      Page.reset({ title: `${$scope.dispatch.region.abbreviation}:${$scope.dispatch.id}` });
    });

    $scope.lightbox = function (image) {
      $scope.modal = {
        visible: !!image,
        source: $filter('resize')(image, 1920, 1920, 'clip'),
      };
    };

    $scope.hasWarehouseOrder = () => $scope.dispatch && $scope.dispatch.orders.some(isWarehouseOrder);

    $scope.getLastOrderEvent = (order) => {
      const lastLinearOrderEvent = order.order_events_excluding_non_lead_assignments
        .filter((event) => event.event_name !== 'clockin' && event.event_name !== 'clockout')
        .sort((a, b) => (a.timestamp < b.timestamp ? 1 : -1))
        .find((e) => e.category === 'linear_orders');

      return lastLinearOrderEvent ? lastLinearOrderEvent.event_name.replace('_', ' ').toUpperCase() : '';
    };

    PhoneCall.get({ dispatch_id: $stateParams.id }, (data) => {
      $scope.phone_calls = data.results;
    });

    $scope.getIconClass = function (event) {
      let classString = '';
      switch (event.type) {
        case 'Order':
        case 'Dispatch':
          classString = 'fa-calendar icon-purple';
          break;
        case 'Shift':
          if (event.action === 'start') {
            classString = 'fa-play';
          } else {
            classString = 'fa-stop';
          }
          classString += ' icon-blue';
          break;
        case 'Event': // genereated from geotriggers
          if (event.direction === 'Arrival') {
            classString = 'fa-truck fa-flip-horizontal';
          } else {
            classString = 'fa-truck';
          }
          break;

        case 'Audit':
          switch (event.subtype) {
            case 'started':
            case 'ended':
              classString = 'fa-calendar icon-blue';
              break;
            case 'create':
              classString = 'fa-plus icon-pink';
              break;
            case 'state':
              switch (event.action) {
                case 'pending':
                  classString = 'fa-recycle icon-pink';
                  break;
                case 'packed':
                  classString = 'fa-sign-in icon-turquoise';
                  break;
                case 'returned':
                  classString = 'fa-sign-out icon-turquoise';
                  break;
                case 'palletized':
                case 'graded':
                case 'prepared':
                case 'enroute':
                  classString = 'fa-recycle icon-turquoise';
                  break;
                case 'completed':
                  classString = 'fa-check icon-blue';
                  break;
                case 'canceled':
                  classString = 'fa-times icon-red';
                  break;
                default:
                  break;
              }
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }

      return classString;
    };
  },
]);

app.controller('DispatchesEditController', [
  '$scope',
  '$stateParams',
  '$state',
  '$q',
  'Dispatch',
  'Vehicle',
  'ErrorService',
  'Warehouse',
  function ($scope, $stateParams, $state, $q, Dispatch, Vehicle, ErrorService, Warehouse) {
    $scope.vehicles = Vehicle.query();
    $scope.warehouses = Warehouse.query();
    $scope.dispatch = Dispatch.get({ id: $stateParams.id });
    $scope.vehiclePrompts = {
      selectAll: 'Select all',
      selectNone: 'Clear all vehicles',
      reset: 'Undo all',
      search: 'Search for a vehicle',
      nothingSelected: 'Assign one or more vehicles',
    };
    $scope.tick = 'ticked';

    $q.all([$scope.dispatch.$promise, $scope.vehicles.$promise, $scope.warehouses.$promise]).then(() => {
      const regionWarehouses = $scope.warehouses.filter(
        (warehouse) => $scope.dispatch.region && _.find(warehouse.region_ids, (id) => $scope.dispatch.region.id === id),
      );
      const regionWarehouseIds = regionWarehouses.map((w) => w.id);

      $scope.vehicles.forEach((vehicle) => {
        // eslint-disable-next-line no-param-reassign
        vehicle[$scope.tick] = !!$scope.dispatch.vehicles.find((other) => other.id === vehicle.id);
      });

      $scope.vehicles = $scope.vehicles.filter(
        (vehicle) => regionWarehouseIds.includes(vehicle.warehouse_id) && vehicle.status === 'active',
      );
    });

    $scope.id = $stateParams.id;

    $scope.hasWarehouseOrder = () =>
      $scope.dispatch && $scope.dispatch.orders && $scope.dispatch.orders.some(isWarehouseOrder);

    $scope.save = function () {
      $scope.dispatch.$update(
        () => {
          $state.go('viewDispatch', { id: $scope.dispatch.id });
        },
        (error) => {
          ErrorService.handle(error);
        },
      );
    };
  },
]);

import angular from 'angular';

angular.module('app').component('orderAvailability', {
  bindings: {
    subscriptions: '<',
    order: '<',
    timezone: '<',
    onScheduleChange: '&',
  },
  template: `
    <availability
      region-id="$ctrl.order.region.id"
      order-id="$ctrl.order.id"
      order="$ctrl.order"
      predecessor-id="$ctrl.order.predecessor_id"
      account-id="$ctrl.order.account_id"
      order-type="$ctrl.order.type"
      order-subtype="$ctrl.order.subtype"
      address="$ctrl.order.address"
      items="$ctrl.order.items"
      inventory="$ctrl.order.inventory"
      subscriptions="$ctrl.cleanedSubscriptions"
      timezone="$ctrl.timezone"
      target-date="$ctrl.orderScheduledAt"
      selected-event="{{$ctrl.selectedEvent}}"
      active-event="{{$ctrl.activeEvent}}"
      on-schedule-event-click="$ctrl.onScheduleEventClick({
        id: $id,
        duration: $duration,
        calenderQuoteId: $calenderQuoteId,
        laborRate: $laborRate,
        perMoverHourAdjustmentAmount: $perMoverHourAdjustmentAmount,
      })"
      on-schedule-event-clear="$ctrl.onScheduleEventClear()"
    />
  `,
  controller: [
    '$scope',
    'SubscriptionService',
    function ($scope, SubscriptionService) {
      this.onScheduleEventClick = ({ id, duration, calenderQuoteId, laborRate, perMoverHourAdjustmentAmount }) => {
        this.selectedEvent = id;
        this.calenderQuoteId = calenderQuoteId;
        this.order.labor_rate_id = laborRate?.id;
        this.order.per_mover_hour_adjustment_amount = perMoverHourAdjustmentAmount;
        this.order.scheduled = new Date(id);
        this.order.scheduled_override = false;
        this.order.duration = duration.valueOf() / 1000; // seconds
        if (this.onScheduleChange) {
          this.onScheduleChange({ $schedule: this.order.scheduled });
        }
      };

      this.onScheduleEventClear = () => {
        delete this.scheduled;
        delete this.selectedEvent;
      };

      $scope.$watch(
        () => JSON.stringify(SubscriptionService.getSubscriptionsForAvailabilityParameters(this.subscriptions)),
        () => {
          this.cleanedSubscriptions = SubscriptionService.getSubscriptionsForAvailabilityParameters(this.subscriptions);
        },
      );
    },
  ],
});

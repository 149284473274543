import styled from '@emotion/styled';
import React, { useCallback, useEffect, useState } from 'react';

import { usePusher } from '@admin/hooks';
import { UUID } from '@shared/utils/uuid';

import { AnchorButton as Link, Button, Modal, Table } from '@shared/components/bootstrap';

import { Label } from '@admin/components/helpers/label';
import { Spinner } from '@admin/components/spinner';
import { Retry } from '@shared/components/helpers/retry';

import { useGenerateSelfStorageSigningMutation } from '@admin/schema';

import { client } from '@admin/libraries/apollo';
import { ISigning } from './signing';

const Title = styled.div`
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  text-align: center;
`;

const Description = styled.div`
  font-size: 18px;
  font-weight: 200;
  line-height: 24px;
  text-align: center;
`;

const Documents = styled.div`
  margin: 16px 0 0;
  font-size: 14px;
  font-weight: 200;
  line-height: 18px;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -8px;
`;

const Action = styled.div`
  padding: 8px;
`;

const Code = styled.div`
  font-size: 48px;
  line-height: 64px;
  letter-spacing: 8px;
  text-align: center;
`;

export const Dialog: React.FC<ISigning> = ({ input, onCancel, onSubmit }) => {
  const [channel] = useState(UUID);
  const [signedIDs, setSignedIDs] = useState<Set<string>>(() => new Set());
  const [generate, { data, loading, error }] = useGenerateSelfStorageSigningMutation({
    client,
    variables: {
      channel,
      input,
    },
  });
  const signing = data?.signing;
  const signatures = signing?.signatures;

  useEffect(() => {
    generate();
  }, [channel, input, generate]);

  usePusher(
    channel,
    'signed',
    useCallback(
      (signatureIDs: number[]) => {
        const replacement = new Set<string>(signatureIDs.map(String));
        setSignedIDs(replacement);
      },
      [signatures],
    ),
  );

  const onSave = () => {
    onSubmit(signatures?.map((signature) => signature.id));
  };

  const onSkip = () => {
    onSubmit();
  };

  const ready = signatures?.every((signature) => signedIDs.has(signature.id));

  return (
    <Modal
      onClose={() => {
        /* noop */
      }}
    >
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>
            <Title>Contracts</Title>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error && <Retry error={error} refetch={generate} />}
          {loading && <Spinner />}
          {signing && (
            <>
              <Description>Please ensure the customer signs the following documents:</Description>
              <Documents>
                <Table>
                  <tbody>
                    {signing.signatures.map((signature) => (
                      <tr key={signature.id}>
                        <td className="text-left">{signature.document.name}</td>
                        <td className="text-right">
                          {signedIDs.has(signature.id) ? (
                            <Label kind="info">signed</Label>
                          ) : (
                            <Label kind="warning">unsigned</Label>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Documents>
              <Description>
                They can enter the following code at{' '}
                <a href={new URL(signing.url).origin} target="_blank">
                  {new URL(signing.url).host}
                </a>
                :
              </Description>
              <Code>{signing.code}</Code>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Actions>
            <Action>
              <Button kind="default" size="lg" onClick={onCancel}>
                Cancel
              </Button>
            </Action>
            {!ready && (
              <Action>
                <Link kind="info" size="lg" href={signing?.url} loading={loading} target="_blank">
                  Sign
                </Link>
              </Action>
            )}
            {ready && (
              <Action>
                <Button kind="primary" size="lg" onClick={onSave}>
                  Save
                </Button>
              </Action>
            )}
            <Action>
              <Button kind="warning" size="lg" onClick={onSkip}>
                Skip
              </Button>
            </Action>
          </Actions>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};

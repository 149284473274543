/* eslint no-param-reassign: ["error", { "props": true,
  "ignorePropertyModificationsFor": ["$scope", "item", "pallet"] }] */
(function () {
  const app = angular.module('app');

  app.controller('InventoriesShowController', [
    '$scope',
    '$stateParams',
    'Account',
    'Page',
    function ($scope, $stateParams, Account, Page) {
      $scope.account_id = $stateParams.account_id;
      $scope.account = Account.get({ id: $scope.account_id }, (account) => {
        Page.reset({ title: `Acct: ${account.customer.name} - Inventory` });
      });
    },
  ]);

  app.controller('InventoriesGridController', [
    '$scope',
    '$filter',
    'Inventory',
    function ($scope, $filter, Inventory) {
      let inventory = null;

      function hasOpenOrResolvedIncidents(item) {
        return item.open_incidents_count > 0 || item.resolved_incidents_count > 0;
      }
      function hasOpenDelayedIncidents(item) {
        return item.delayed;
      }
      $scope.filter = function (filters) {
        $scope.filters = filters;
        function matches(item) {
          if (filters.state && filters.state === 'incidents') {
            return hasOpenOrResolvedIncidents(item);
          }
          if (filters.state && filters.state === 'delayed') {
            return hasOpenDelayedIncidents(item);
          }
          return item.state === filters.state;
        }
        if (filters) {
          $scope.inventory = angular.copy(inventory);
          _.each($scope.inventory.pallets, (pallet) => {
            pallet.items = _.filter(pallet.items, matches);
          });
          $scope.inventory.other = _.filter($scope.inventory.other, matches);
        } else {
          $scope.inventory = inventory;
        }
      };

      $scope.init = function (type, id) {
        inventory = Inventory.get({
          resource_type: type,
          resource_id: id,
        });

        inventory.$promise.then(() => {
          $scope.filter();
          $scope.stats = {};
          $scope.aggregate = inventory.stats;
          $scope.aggregate.items.cuft = 0;
          const groupIdsCuftAccountedFor = {};
          const groupIdsAggregateCuftAccountedFor = {};

          function stat(state) {
            if (!$scope.stats[state]) {
              $scope.stats[state] = {
                items: { count: 0, cuft: 0 },
                pallets: { count: 0 },
              };
            }
            return $scope.stats[state];
          }

          function addCuftToStats(item) {
            if (item.latest_open_incident_kind === 'duplicate') {
              return;
            }
            if (!groupIdsCuftAccountedFor[item.state]) {
              groupIdsCuftAccountedFor[item.state] = {};
            }
            let itemCuft = 0;
            if (item.group_id) {
              if (!groupIdsCuftAccountedFor[item.state][item.group_id]) {
                groupIdsCuftAccountedFor[item.state][item.group_id] = true;
                itemCuft = parseFloat(item.group.cuft);
              }
            } else {
              itemCuft = parseFloat(item.cuft);
            }
            if (!Number.isNaN(itemCuft)) {
              stat(item.state).items.cuft += itemCuft;
              if (!groupIdsAggregateCuftAccountedFor[item.group_id] || !item.group_id) {
                groupIdsAggregateCuftAccountedFor[item.group_id] = true;
                $scope.aggregate.items.cuft += itemCuft;
              }
            }
          }

          function setup(item) {
            if (item.group_id) {
              item.group = _.find(inventory.item_groups, (group) => group.id === item.group_id);
            }
            stat(item.state).items.count += 1;
            addCuftToStats(item);
            if (hasOpenOrResolvedIncidents(item)) {
              stat('incidents').items.count += 1;
            }
            if (hasOpenDelayedIncidents(item)) {
              stat('delayed').items.count += 1;
            }
          }

          _.each(inventory.other, setup);
          _.each(inventory.pallets, (pallet) => {
            const states = _.map(pallet.items, (item) => item.state);
            _.each(pallet.items, setup);
            _.each(_.uniq(states), (state) => {
              stat(state).pallets.count += 1;
            });
          });

          $scope.states = _.map(Object.keys($scope.stats), (state) => state).sort();

          $scope.lightbox = (image) => {
            $scope.modal = {
              visible: !!image,
              source: $filter('resize')(image, 1920, 1920, 'clip'),
            };
          };
        });
      };
    },
  ]);
})();

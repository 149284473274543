import * as React from 'react';

import { Button } from '@shared/components/bootstrap';

import { dispatchAudioPlayerLoadEvent } from './events';

export const Listen: React.FC<{
  agent?: string;
  date?: string | null;
  src: string;
  pageID: string;
  className?: string;
}> = ({ agent, date, src, pageID, className }) => {
  const onClick = () => {
    dispatchAudioPlayerLoadEvent({
      agent,
      date,
      src,
      pageID,
    });
  };

  return (
    <Button kind="primary" onClick={onClick} className={className}>
      Play
    </Button>
  );
};

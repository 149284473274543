import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { Workforce__JobCodeFragment, Workforce__Shift__ActivityInput } from '@admin/schema';
import { Box, COLORS } from '@clutter/clean';
import styled from '@emotion/styled';

import { DurationInput } from '@admin/components/fields';
import { Field } from './field';

const HalfWidthContainer = styled(Box)`
  max-width: 50%;
`;

export interface IActivityInput extends Workforce__Shift__ActivityInput {
  key: string;
}

export const ActivityFields: React.FC<{
  index: number;
  activityInput: IActivityInput;
  jobCodes: Workforce__JobCodeFragment[];
  handleRemove(index: number): void;
  handleUpdate(indexToUpdate: number, updatedActivity: Workforce__Shift__ActivityInput): void;
}> = ({ index, activityInput, jobCodes, handleRemove, handleUpdate }) => {
  const { errors, register, unregister } = useFormContext<Workforce__Shift__ActivityInput>();
  useEffect(() => {
    register({ name: `activities.${index}.id` });
    register({ name: `activities.${index}.jobCodeID` });
    register({ name: `activities.${index}.durationMinutes` });

    return () => {
      unregister(`activities.${index}.id`);
      unregister(`activities.${index}.jobCodeID`);
      unregister(`activities.${index}.durationMinutes`);
    };
  }, [register, index]);

  return (
    <Box.Flex flexDirection="row" justifyContent="space-between" alignItems="flex-end">
      <HalfWidthContainer>
        <Field
          id={`activity_${index}_job_code`}
          error={errors.jobCodeID}
          label="Job Code"
          actionLabel={index === 0 ? undefined : 'REMOVE'}
          actionColor={COLORS.toucan}
          action={() => handleRemove(index)}
        >
          <select
            className="form-control"
            id={`activity_${index}_job_code`}
            value={activityInput.jobCodeID}
            onChange={(event) => {
              handleUpdate(index, { ...activityInput, jobCodeID: event.target.value });
            }}
          >
            <option value="">Select a job code...</option>
            {jobCodes.map((jobCode) => (
              <option key={jobCode.id} value={jobCode.id}>
                {jobCode.name}
              </option>
            ))}
          </select>
        </Field>
      </HalfWidthContainer>
      <HalfWidthContainer>
        <Field id={`activity_${index}_duration`} error={errors.durationMinutes} label={`Duration - Split ${index + 1}`}>
          <DurationInput
            duration={activityInput.durationMinutes || 0}
            onChange={(durationMinutes) => handleUpdate(index, { ...activityInput, durationMinutes: durationMinutes })}
            unit="minutes"
          />
        </Field>
      </HalfWidthContainer>
    </Box.Flex>
  );
};

const app = angular.module('app');

app.component('messages', {
  templateUrl: 'partials/messages/widget.html',
  controller: 'MessagesController as messages_controller',
  bindings: {
    recipient: '<',
    recipientType: '<',
    order: '<?',
  },
});

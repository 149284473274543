import React, { useEffect } from 'react';

import { DetailedClaimFragment } from '@admin/schema';

import { Assess as AssessComponent } from './assess';
import { compensated } from './helpers';
import { Offer as OfferComponent } from './offer';
import { Summary as SummaryComponent } from './summary';
import { Verify as VerifyComponent } from './verify';

export enum ClaimSteps {
  Summary,
  Verify,
  Assess,
  Offer,
}

export interface IClaimStepProps {
  claim: DetailedClaimFragment;
  editable?: boolean;
  onNext(claim: DetailedClaimFragment): void;
}

export const stepEnabled = (step: ClaimSteps, claim: DetailedClaimFragment) =>
  step === ClaimSteps.Summary ||
  step === ClaimSteps.Verify ||
  (step === ClaimSteps.Assess && showAssessStep(claim)) ||
  (step === ClaimSteps.Offer && showOfferStep(claim));

const showAssessStep = (claim: DetailedClaimFragment) => {
  const { itemSelections, propertySelection } = claim;
  const selections = propertySelection ? [...itemSelections, propertySelection] : itemSelections;
  let hasCompensated = false;

  for (const selection of selections) {
    if (!selection.resolution) {
      return false;
    }
    if (compensated(selection)) {
      hasCompensated = true;
    }
  }
  return hasCompensated;
};

const showOfferStep = (claim: DetailedClaimFragment) => {
  const { itemSelections, propertySelection } = claim;
  const selections = propertySelection ? [...itemSelections, propertySelection] : itemSelections;

  for (const selection of selections) {
    if (!selection.resolution?.kind || (compensated(selection) && !selection.assessment)) {
      return false;
    }
  }
  return true;
};

const stepComponent = (step: ClaimSteps) => {
  switch (step) {
    case ClaimSteps.Summary:
      return SummaryComponent;
    case ClaimSteps.Verify:
      return VerifyComponent;
    case ClaimSteps.Assess:
      return AssessComponent;
    case ClaimSteps.Offer:
      return OfferComponent;
  }
};

export const Steps: React.FC<{
  claim: DetailedClaimFragment;
  editable: boolean;
  step: ClaimSteps;
  onStepChange(step: ClaimSteps): void;
}> = ({ claim, editable, step, onStepChange }) => {
  useEffect(() => {
    if (!editable) {
      onStepChange(ClaimSteps.Summary);
    }
  }, [editable]);

  const StepComponent = stepComponent(step);
  const onNext = (updatedClaim: DetailedClaimFragment) => {
    switch (step) {
      case ClaimSteps.Summary:
        return onStepChange(ClaimSteps.Verify);
      case ClaimSteps.Verify:
        return showAssessStep(updatedClaim) ? onStepChange(ClaimSteps.Assess) : onStepChange(ClaimSteps.Offer);
      case ClaimSteps.Assess:
        return onStepChange(ClaimSteps.Offer);
      default:
        return onStepChange(ClaimSteps.Summary);
    }
  };

  return <StepComponent claim={claim} editable={editable} onNext={onNext} />;
};

(function () {
  const app = angular.module('app');

  app.service('PaginationService', [
    function () {
      function Paginator(options) {
        const self = this;
        self.pagesAtOnce = 5;

        if (options && options.page) {
          self.page = options.page;
        }

        // Define Protoype Methods
        self.getPagesToShow = function (currentPage, totalPages) {
          const toReturn = [];

          currentPage = currentPage || 1;
          currentPage = Number(currentPage);
          self.pagesAtOnce = Number(self.pagesAtOnce);

          const onEachSide = Math.floor(self.pagesAtOnce / 2);
          const start = _.max([currentPage - onEachSide, 1]);
          const extraOffset = _.min([currentPage - onEachSide - 1, 0]);
          const end = _.min([currentPage + onEachSide - extraOffset, totalPages]);

          for (let i = start; i <= end; i++) {
            toReturn.push(i);
          }
          return toReturn;
        };

        self.reset = function () {
          delete self.page;
        };

        self.prepare = function (page) {
          if (angular.isUndefined(page)) {
            page = {
              current_page: 1,
              next_page: null,
              prev_page: null,
              total_pages: 1,
              total_count: 0,
            };
          }

          self.currentPage = page.current_page;
          self.nextPage = page.next_page;
          self.previousPage = page.prev_page;
          self.totalPages = page.total_pages;
          self.totalCount = page.total_count;
          self.pagesToShow = self.getPagesToShow(self.currentPage, self.totalPages);
        };

        self.prepare(options);
      }

      this.initialize = function (options) {
        return new Paginator(options);
      };
    },
  ]);
})();

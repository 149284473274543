import * as React from 'react';
import { client } from '@admin/libraries/apollo';
import { useAddressesQuery, useGeocodeLazyQuery, useWarehouseAddressesQuery } from '@admin/schema';
import { addressViewURL } from '@admin/config/routes';
import { find, some, omit } from 'lodash';
import { Address } from '@admin/types/address';
import { Form } from './form';
import { Map } from '../helpers/map';
import { Preview } from './preview';
import { FacilityAddress, Selector as SelfStorageAddressSelector } from '../self_storage/addresses/selector';
import { ROLES } from '../../config/roles';
import { Preview as AddressDetailPreview } from './detail/preview';

const Selector: React.FC<{
  selection?: any;
  account: any;
  showAccountAddresses?: boolean;
  warehouseReturn?: boolean;
  potentiallyInvalid?: boolean;
  onSelect(address: Address): void;
  onWarehouseSelect(region: any): void;
  onGeocode(geocode: any): void;
}> = ({
  selection,
  account,
  showAccountAddresses,
  warehouseReturn,
  potentiallyInvalid,
  onSelect,
  onWarehouseSelect,
  onGeocode,
}) => {
  const [geocode] = useGeocodeLazyQuery({ client });
  const { data: addressesData, refetch } = useAddressesQuery({ client, variables: { accountID: account.id } });
  const accountAddresses = addressesData?.addresses;

  const { data: warehousesData } = useWarehouseAddressesQuery({ client });
  const warehouses = warehousesData?.warehouses;

  const [managing, setManaging] = React.useState<Address | undefined>();
  const [kind, setKind] = React.useState<'self-storage' | undefined>();
  const [selectedWarehouse, setSelectedWarehouse] = React.useState<Address>();

  React.useEffect(() => {
    const warehouseSelected = selection
      ? warehouses?.find((warehouse) => warehouse.address.id === String(selection?.id))
      : undefined;
    if (warehouseSelected) {
      setSelectedWarehouse(warehouseSelected?.address as Address);
    }
  }, [selection, warehouses]);

  const onSelectAddress = (address: Address) => {
    onSelect(address);
    refetch();
  };

  const onChange = (changes: Address) => {
    setManaging(changes);
  };

  const select = (address: Address) => {
    setKind(undefined);

    if (!address) {
      return;
    }

    const query = { id: address.id };
    if (warehouseReturn && some(warehouses, { address: query })) {
      const warehouseSelected = find(warehouses, (warehouse) => warehouse.address.id === address.id);
      onWarehouseSelect(warehouseSelected?.address?.region);
    }
    setManaging(undefined);

    onSelectAddress(address);

    geocode({
      variables: {
        input: {
          zip: address.zip!,
          city: address.city!,
          state: address.state!,
          street: address.street,
        },
      },
    }).then(({ data }) => {
      onGeocode(data!.geocode);
    });
  };

  const onSelectSelfStorageAddress = (address?: FacilityAddress) => {
    setManaging(undefined);
    if (address) {
      setKind('self-storage');
    } else {
      setKind(undefined);
    }
    onSelect(address as Address);
  };

  const onLoadedSelfStorageAddress = (addresses: FacilityAddress[]) => {
    if (selection && addresses.some((address: any) => address.id === String(selection.id))) {
      setKind('self-storage');
    }
  };

  const selected = (value: Address) => {
    if (selection) {
      return value.id === String(selection.id);
    }
  };

  const cancel = () => {
    setManaging(undefined);
  };

  return (
    <div className="row">
      <div className="col-sm-6">
        {!managing && (
          <table className="table table-striped">
            <tbody>
              {!warehouseReturn && (
                <>
                  {accountAddresses?.map((entry) => {
                    const { details } = entry;
                    const addressNoDetails = omit(entry, 'details');
                    const flattenedAddress = { ...addressNoDetails, ...details };

                    return (
                      <tr key={flattenedAddress.id}>
                        <td className="text-left col-fill">
                          <label className="flush-label" htmlFor="address_{{address.id}}">
                            <div className="text-dark">
                              <Preview address={flattenedAddress} mode="inline" />
                            </div>
                            {selected(flattenedAddress as Address) && (
                              <div className="text-mute">
                                <AddressDetailPreview address={flattenedAddress as Address} mode="block" />
                              </div>
                            )}
                          </label>
                          {!flattenedAddress.safe_and_sanitary && (
                            <div className="label label-danger">Prohibited for unsafe or unsanitary conditions</div>
                          )}
                          {potentiallyInvalid && selected(flattenedAddress as Address) && (
                            <div className="label label-danger">
                              Potentially invalid address. Please double-check the address before continuing.
                            </div>
                          )}
                        </td>
                        <td className="text-right col-nowrap col-zero">
                          <button
                            className="btn btn-default"
                            onClick={() => {
                              setManaging(flattenedAddress as Address);
                            }}
                          >
                            Edit
                          </button>{' '}
                          <a className="btn btn-default" href={addressViewURL(account.id, flattenedAddress.id)}>
                            View
                          </a>
                        </td>
                        <td className="text-center col-nowrap col-zero">
                          <input
                            type="radio"
                            id={`address_${flattenedAddress.id}`}
                            checked={!!selected(flattenedAddress as Address)}
                            disabled={!flattenedAddress.safe_and_sanitary && !ROLES.some((r) => r === 'admin')}
                            onChange={() => select((flattenedAddress as Address) ?? undefined)}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </>
              )}
              {warehouses && (
                <tr className="warehouse-selector">
                  <td className="text-left col-fill">
                    <label className="flush-label" htmlFor="address_warehouse">
                      <div className="text-dark">Clutter Warehouse</div>
                    </label>
                  </td>
                  <td className="text-left col-fill">
                    <select
                      id="address_warehouse"
                      className="form-control"
                      style={{ width: '180px' }}
                      value={String(selection?.id) || ''}
                      onChange={(event) => {
                        const addresses = warehouses?.map(({ address }) => address);
                        const address = addresses?.find(({ id }) => id === event.target.value);
                        setSelectedWarehouse(address as Address);
                        select(address as Address);
                      }}
                    >
                      <option value="">- Clutter Warehouse -</option>
                      {warehouses?.map((warehouse) => (
                        <option key={warehouse.id} value={warehouse.address.id}>
                          {warehouse.name}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td className="text-center col-nowrap col-zero">
                    <input
                      type="radio"
                      id="warehouse_address"
                      checked={selectedWarehouse && selected(selectedWarehouse)}
                      disabled={!selectedWarehouse}
                      onChange={() => selectedWarehouse && select(selectedWarehouse)}
                    />
                  </td>
                </tr>
              )}
              {!warehouseReturn && (
                <tr>
                  <td className="text-left col-fill">Self Storage Facility</td>
                  <td className="text-left col-fill">
                    <SelfStorageAddressSelector
                      selectedID={selection?.id}
                      onSelect={onSelectSelfStorageAddress}
                      onLoaded={onLoadedSelfStorageAddress}
                    />
                  </td>
                  <td className="text-center col-nowrap col-zero">
                    <input type="radio" checked={kind === 'self-storage'} disabled={kind !== 'self-storage'} />
                  </td>
                </tr>
              )}
            </tbody>
            {showAccountAddresses && !warehouseReturn && (
              <tfoot>
                <tr>
                  <td className="col-fill"></td>
                  <td className="col-zero"></td>
                  <td className="text-right col-nowrap col-zero">
                    <button
                      className="btn btn-default"
                      onClick={() => {
                        setManaging({});
                      }}
                    >
                      Add New
                    </button>
                  </td>
                </tr>
              </tfoot>
            )}
          </table>
        )}

        {managing && (
          <Form account={account} address={managing} onCancel={cancel} onSelect={select} onChange={onChange} />
        )}
      </div>
      <div className="col-sm-6">
        <Map center={managing || selection} marker={managing || selection} />
      </div>
    </div>
  );
};
export { Selector };

import React, { useState } from 'react';
import { client } from '@admin/libraries/apollo';
import { opsAuditConfigurationEditURL } from '@admin/config/routes';

import { Roles } from '@admin/components/helpers/roles';

import {
  Maybe,
  PaginatedConfigurationListDocument,
  PaginatedConfigurationListQuery,
  Status,
  usePauseConfigurationMutation,
  useUnpauseConfigurationMutation,
} from '@admin/schema';
import { opsAuditConfigurationsAuditReviewURL } from '@admin/config/routes';
import { Timestamp } from '@shared/components/helpers';
import { Button } from '@shared/components/bootstrap';
import { UserRole } from '@admin/types';

type ConfigurationType = PaginatedConfigurationListQuery['paginated']['results'][number];

export const Row: React.FC<{
  configuration: ConfigurationType;
}> = ({ configuration }) => {
  const [error, setError] = useState<Maybe<string>>();
  const [paused, setPaused] = useState<boolean>(!!configuration.pausedAt);
  const [pauseConfiguration, { loading: pauseLoading }] = usePauseConfigurationMutation({
    client,
    awaitRefetchQueries: true,
    refetchQueries: [{ query: PaginatedConfigurationListDocument }],
  });
  const [unpauseConfiguration, { loading: unpauseLoading }] = useUnpauseConfigurationMutation({
    client,
    awaitRefetchQueries: true,
    refetchQueries: [{ query: PaginatedConfigurationListDocument }],
  });

  const handleClick = async (selectedConfiguration: ConfigurationType) => {
    if (paused) {
      const result = await unpauseConfiguration({ variables: { configurationID: selectedConfiguration.id } });
      if (result?.data?.unpauseConfiguration) {
        if (result.data.unpauseConfiguration.status === Status.Unprocessable) {
          setError(result.data.unpauseConfiguration.error);
        } else {
          setError(undefined);
          setPaused(false);
        }
      }
    } else {
      const result = await pauseConfiguration({ variables: { configurationID: selectedConfiguration.id } });
      if (result?.data?.pauseConfiguration) {
        if (result.data.pauseConfiguration.status === Status.Unprocessable) {
          setError(result.data.pauseConfiguration.error);
        } else {
          setError(undefined);
          setPaused(true);
        }
      }
    }
  };

  return (
    <>
      {error && (
        <tr>
          <td colSpan={10}>{error}</td>
        </tr>
      )}
      <tr>
        <td>{configuration.name}</td>
        <td>{configuration.refreshIntervalMinutes}</td>
        <td>{configuration.hoursUntilExpired ?? 'No Expiration'}</td>
        <td>
          {configuration.lastRanAt && <Timestamp value={configuration.lastRanAt} format={Timestamp.Format.DateTime} />}
        </td>
        <td>{configuration.pendingCount}</td>
        <td className="text-right">
          <Roles show={[UserRole.Admin]}>
            <a className="btn btn-default" href={opsAuditConfigurationEditURL(configuration.id)}>
              Edit Config
            </a>{' '}
            <Button
              className={'btn btn-warning'}
              onClick={() => handleClick(configuration)}
              disabled={pauseLoading || unpauseLoading}
            >
              {paused ? `Unpause Audits` : `Pause Audits`}
            </Button>{' '}
          </Roles>
          <a className={'btn btn-primary'} href={opsAuditConfigurationsAuditReviewURL(configuration.id)}>
            Review Audits
          </a>
        </td>
      </tr>
    </>
  );
};

import * as React from 'react';

import { useOrderPolicyQuery } from '@admin/schema';

import { client } from '@admin/libraries/apollo';

import styled from '@emotion/styled';
import { Cancel } from './actions/cancel';
import { Complete } from './actions/complete';
import { CreateFollowUp } from './actions/create_follow_up';
import { Dispatch } from './actions/dispatch';
import { Modify } from './actions/modify';
import { Ship } from './actions/ship';
import { SelfStorageAuction } from './actions/self_storage_auction';
import { Uncancel } from './actions/uncancel';
import { Reschedule } from './actions/reschedule';

const Container = styled.span`
  & > * {
    margin-left: 4px;
  }
`;

export const OrderActions: React.FC<{
  accountID: string;
  orderID: string;
  canceled: boolean;
}> = ({ accountID, orderID, canceled }) => {
  const { data } = useOrderPolicyQuery({
    client,
    variables: { orderID: String(orderID) },
    fetchPolicy: 'network-only',
  });
  if (!data || !data.order) {
    return null;
  }
  const { type, scheduled, region, policy, state } = data.order;

  const { cancel, completeManually, dispatch, createFollowUp, modify, review, ship, viewSelfStorageAuction, uncancel } =
    policy;

  if (canceled) {
    return <Container>{uncancel && <Uncancel orderID={orderID} tz={region.tz} />}</Container>;
  }

  return (
    <Container>
      {cancel && <Cancel order={data.order} />}
      {dispatch && <Dispatch scheduled={scheduled} region={region} />}
      {createFollowUp && <CreateFollowUp accountID={accountID} orderID={orderID} />}
      {modify && <Modify review={review} type={type} accountID={accountID} orderID={orderID} />}
      {completeManually && <Complete order={data.order} />}
      {ship && <Ship accountID={accountID} orderID={orderID} />}
      {viewSelfStorageAuction && <SelfStorageAuction orderID={orderID} state={state} />}
      {modify && <Reschedule accountID={accountID} orderID={orderID} />}
    </Container>
  );
};

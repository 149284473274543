import styled from '@emotion/styled';
import moment from 'moment';
import * as React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { Rows as FeeRows } from '@admin/components/self_storage/fees/rows';
import { Rows as PromotionRows } from '@admin/components/self_storage/promotions/rows';
import { Rows as ProtectionRows } from '@admin/components/self_storage/protections/rows';
import { Summary as UnitSummary } from '@admin/components/self_storage/units/summary';
import { COLORS } from '@root/colors';
import { Table } from '@shared/components/bootstrap';
import { Currency, Spacer } from '@shared/components/helpers';

import {
  Maybe,
  SelfStorage__FeeFragment,
  SelfStorage__ReservationFragment,
  SelfStorage__PriceFragment,
  SelfStorage__PromotionFragment,
  SelfStorage__ProtectionFragment,
  useSelfStorageUnitQuery,
  useSelfStorageProrationQuery,
} from '@admin/schema';
import { client } from '@admin/libraries/apollo';
import { Section } from '../components/section';
import { SectionHeading } from '../components/section_heading';
import { monthlySavings } from '../utils';
import { PriceOverrideRateField } from './price_override_rate_field';
import { PriceOverrideReasonField } from './price_override_reason_field';

import { Policy } from './policy';

function castToNumber(value?: string) {
  if (value === '' || value === undefined) {
    return undefined;
  }

  const castedValue = Number(value);
  if (!isNaN(castedValue)) {
    return castedValue;
  }
}

function calculateTotals(
  rate?: number,
  promotion?: Maybe<SelfStorage__PromotionFragment>,
  protection?: Maybe<SelfStorage__ProtectionFragment>,
  fees: SelfStorage__FeeFragment[] = [],
  prorationRatio = 1,
) {
  let monthly = rate || 0.0;
  if (promotion) {
    monthly -= monthlySavings(monthly, promotion);
  }

  // Price minus savings can't be less than zero
  if (monthly < 0) {
    monthly = 0;
  }

  if (protection) {
    monthly += protection.price;
  }

  for (const fee of fees.filter(({ recurring }) => recurring)) {
    monthly += fee.price;
  }

  const prorated = monthly * prorationRatio;

  let moveIn = 0.0;
  for (const fee of fees.filter(({ recurring }) => !recurring)) {
    moveIn += fee.price;
  }

  return {
    total: prorated + moveIn,
    monthly,
    prorated,
    moveIn,
  };
}

const FixedWidthTD = styled.td`
  width: 144px;
`;

const OverrideButton = styled.button`
  padding: 0;
`;

const PriceNote = styled.div`
  color: ${COLORS.grayDark};
`;

const FooterCell = styled.td<{ borderTop?: boolean; colSpan?: number }>`
  text-align: right;
  ${({ borderTop }) =>
    borderTop ? `border-top: 1px solid ${COLORS.grayLighter} !important;` : 'border-top: none !important;'}
`;

const TableSpacer = styled.tr`
  height: 20px;
`;

const FillerRow: React.FC = ({ children }) => (
  <tr>
    <td className="text-left" colSpan={3}>
      {children}
    </td>
  </tr>
);

const PricingRow: React.FC<{
  overrideVisible: boolean;
  unitPrice?: Maybe<SelfStorage__PriceFragment>;
  quotedPrice?: Maybe<SelfStorage__PriceFragment>;
  onToggleOverride(): void;
}> = ({ overrideVisible, unitPrice, quotedPrice, onToggleOverride }) => (
  <>
    <div>
      {!overrideVisible ? unitPrice ? <Currency value={unitPrice.quotedRate} /> : '-' : <PriceOverrideRateField />}
    </div>
    <OverrideButton className="btn-link" type="button" onClick={onToggleOverride}>
      {!overrideVisible ? 'Override' : 'Reset'}
    </OverrideButton>
    {(quotedPrice?.quotedRate !== unitPrice?.quotedRate || overrideVisible) && <Spacer height="12px" />}
    {quotedPrice && quotedPrice.quotedRate !== unitPrice?.quotedRate && (
      <PriceNote>
        Quoted: <Currency value={quotedPrice.quotedRate} />
      </PriceNote>
    )}
    {overrideVisible && unitPrice && (
      <PriceNote>
        Current Rate: <Currency value={unitPrice.quotedRate} />
      </PriceNote>
    )}
  </>
);

export const Summary: React.FC<{
  accountID?: string;
  facilityID?: string;
  reservation?: SelfStorage__ReservationFragment;
}> = ({ accountID, facilityID, reservation }) => {
  const [protection, setProtection] = useState<SelfStorage__ProtectionFragment>();
  const [fees, setFees] = useState<SelfStorage__FeeFragment[] | undefined>();
  const [overrideVisible, setOverrideVisible] = useState(false);
  const { register, unregister, setValue, setError, watch, formState } = useFormContext<{
    feeIDs?: string[];
    protectionID?: string;
    unitID?: string;
    priceOverride?: {
      rate?: string;
      reason?: string;
    };
  }>();

  const unitID = watch('unitID');
  const priceOverride = watch('priceOverride');
  const { data } = useSelfStorageUnitQuery({
    client,
    variables: { id: unitID! },
    skip: !unitID,
  });
  const unit = data?.unit;

  const unitPrice = unit?.price;
  const unitPromotion = unit?.promotion;
  const quotedPrice = reservation?.price;
  const quotedPromotion = reservation?.promotion;

  // When we support adding any promotion to a rental, let's change this out for a query
  const selectedPromotionID = watch('promotionID');
  let selectedPromotion;
  if (unitPromotion?.id === selectedPromotionID) {
    selectedPromotion = unitPromotion;
  } else if (quotedPromotion?.id === selectedPromotionID) {
    selectedPromotion = quotedPromotion;
  }

  const proration = useSelfStorageProrationQuery({
    client,
    variables: {
      accountID: accountID!,
      facilityID: facilityID!,
    },
    skip: !accountID || !facilityID,
  })?.data?.proration;
  const subtotal = (overrideVisible && priceOverride && castToNumber(priceOverride.rate)) || unitPrice?.quotedRate;
  const totals = calculateTotals(subtotal, selectedPromotion, protection, fees, proration?.ratio);

  useEffect(() => {
    register({ name: 'feeIDs' }, { required: 'An issue loading fees is preventing your submission.' });
    register({ name: 'protectionID' });
    return () => {
      unregister('feeIDs');
      unregister('protectionID');
    };
  }, [register]);

  const handleToggleOverride = () => {
    if (!overrideVisible && formState.isSubmitted) {
      setError([
        {
          type: 'required',
          name: 'priceOverride.rate',
          message: 'Please provide a price.',
        },
        {
          type: 'required',
          name: 'priceOverride.reason',
        },
      ]);
    }
    setOverrideVisible(!overrideVisible);
  };

  return (
    <Section>
      <SectionHeading>Plan Summary & Costs</SectionHeading>
      <Table striped responsive>
        <tbody>
          <tr>
            <td className="text-left">
              <strong>Unit Details</strong>
            </td>
            <td className="text-left">{unit && <UnitSummary unit={unit} />}</td>
            <FixedWidthTD className="text-right">
              {unit ? (
                <PricingRow
                  overrideVisible={overrideVisible}
                  unitPrice={unitPrice}
                  quotedPrice={quotedPrice}
                  onToggleOverride={handleToggleOverride}
                />
              ) : (
                '-'
              )}
            </FixedWidthTD>
          </tr>
          {overrideVisible && (
            <FillerRow>
              <PriceOverrideReasonField />
            </FillerRow>
          )}
          <PromotionRows
            quotedPromotion={quotedPromotion}
            unitPromotion={unitPromotion}
            selectedPromotion={selectedPromotion}
            subtotal={subtotal}
          />
          <ProtectionRows
            facilityID={facilityID}
            selection={protection}
            onChange={(newProtection) => {
              setProtection(newProtection);
              setValue('protectionID', newProtection ? newProtection.id : undefined);
            }}
          />
          {!watch('protectionID') && (
            <FillerRow>
              <p>
                <strong>Select a protection plan or provide proof of insurance.</strong>
              </p>
              <Policy />
            </FillerRow>
          )}
          <FeeRows
            unit={unit}
            accountID={accountID}
            facilityID={facilityID}
            selections={fees}
            onChange={(newFees) => {
              setFees(newFees);
              setValue(
                'feeIDs',
                newFees?.map(({ id }) => id),
              );
            }}
          />
        </tbody>
        <tfoot>
          {proration && (
            <>
              <tr className="text-bold">
                <td />
                <td className="text-right">Monthly Subtotal</td>
                <td className="text-right">{unitPrice ? <Currency value={totals.monthly} /> : '-'}</td>
              </tr>
              <TableSpacer>
                <FooterCell colSpan={3} />
              </TableSpacer>
              <tr>
                <FooterCell colSpan={2}>Proration through {moment(proration.periodEnd).format('MMM Do')}</FooterCell>
                <FooterCell>{unitPrice ? <Currency value={totals.prorated - totals.monthly} /> : '-'}</FooterCell>
              </tr>
              <tr>
                <FooterCell colSpan={2}>Move-in charges</FooterCell>
                <FooterCell>{unitPrice ? <Currency value={totals.moveIn} /> : '-'}</FooterCell>
              </tr>
            </>
          )}
          <tr className="text-bold">
            <FooterCell />
            <FooterCell borderTop={!!proration}>Total Due Today</FooterCell>
            <FooterCell borderTop={!!proration}>
              {unitPrice || priceOverride ? <Currency value={totals.total} /> : '-'}
            </FooterCell>
          </tr>
        </tfoot>
      </Table>
      <Spacer height="16px" />
    </Section>
  );
};

(function () {
  angular.module('app').controller('PasswordsEditController', [
    '$state',
    'Password',
    'ErrorService',
    function ($state, Password, ErrorService) {
      const self = this;
      self.password = new Password();
      self.save = function () {
        self.password.$save(
          function () {
            $state.go('dashboard');
          },
          function (error) {
            ErrorService.handle(error);
          },
        );
      };
    },
  ]);
})();

import * as React from 'react';
import { forwardRef } from 'react';

import { FieldFormGroupProps } from '@shared/components/fields/field_form_group';

import { FormikFieldFormGroup } from '@shared/components/fields/formik/formik_field_form_group';
import { TextAreaFormControlType, TextAreaFormControlProps } from '../text_area_form_control';
import { FormikTextAreaFormControl } from './formik_text_area_form_control';

type TextAreaFormGroupType = TextAreaFormControlType;
type TextAreaFormGroupProps = TextAreaFormControlProps & FieldFormGroupProps & { name: string };

export const FormikTextAreaFormGroup = forwardRef<TextAreaFormGroupType, TextAreaFormGroupProps>(
  ({ help, label, children, ...textarea }, ref) => (
    <FormikFieldFormGroup name={textarea.name} help={help} label={label} id={textarea.id}>
      <FormikTextAreaFormControl ref={ref} {...textarea} />
    </FormikFieldFormGroup>
  ),
);

import React, { useState } from 'react';
import axios from 'axios';

import { Panel } from '@admin/components/helpers/panel';
import { Button, Col, Row, AnchorButton as Link, Alert } from '@shared/components/bootstrap';

import { Fields } from '@admin/components/address/fields';
import { Fields as DetailFields } from '@admin/components/address/detail/fields';
import { Map } from '@admin/components/helpers/map';

import { Address } from '@admin/types/address';

export const AccountAddressForm: React.FC<{
  defaults?: Address;
  accountID: string;
  addressID?: string;
  onSave(address: { id: string }): void;
}> = ({ accountID, addressID, defaults = {}, onSave }) => {
  const [address, setAddress] = useState<Address>(defaults);
  const [error, setError] = useState<string | undefined>();
  const [submitting, setSubmitting] = useState<boolean>(false);

  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    event.stopPropagation();

    if (submitting) return;
    setSubmitting(true);
    setError(undefined);

    const request = addressID
      ? axios.patch<{ id: string }>(`/accounts/${accountID}/addresses/${addressID}.json`, address)
      : axios.post<{ id: string }>(`/accounts/${accountID}/addresses.json`, address);

    request
      .then((response) => {
        setSubmitting(false);
        onSave(response.data);
      })
      .catch(({ response }) => {
        setSubmitting(false);
        setError(response?.data?.message ?? 'An unknown error occurred.');
      });
  };

  return (
    <>
      <form role="form" onSubmit={onSubmit}>
        <Panel>
          <Panel.Header>
            <Panel.Title>Address</Panel.Title>
          </Panel.Header>
          <Panel.Body>
            <Row>
              <Col xs={6}>
                <Fields
                  shouldFlagAmbiguousAddress
                  shouldFlagInvalidAddress
                  disabled={!!addressID}
                  fields={address}
                  onChange={setAddress}
                />
                <hr />
                <DetailFields fields={address} onChange={setAddress} />
              </Col>
              <Col xs={6}>
                <Map center={address} marker={address} />
              </Col>
            </Row>
            {error && <Alert style="danger">{error}</Alert>}
          </Panel.Body>
          <Panel.Footer align="right">
            <Link href={`/accounts/${accountID}`} kind="default" disabled={submitting}>
              Cancel
            </Link>
            <Button type="submit" kind="primary" loading={submitting}>
              Save
            </Button>
          </Panel.Footer>
        </Panel>
      </form>
    </>
  );
};

import React, { useState } from 'react';

import { client } from '@admin/libraries/apollo';
import { useHrsLocationsQuery } from '@admin/schema';

import { Panel } from '@admin/components/helpers/panel';
import { Table, AnchorButton as Link } from '@shared/components/bootstrap';
import { Spinner } from '@admin/components/spinner';
import { HRSLocationType, UpsertModal } from './upsert_modal';

export const HRSLocationsList: React.FC = () => {
  const [showUpsertModal, setShowUpsertModal] = useState<boolean>(false);
  const [editingLocation, setEditingLocation] = useState<HRSLocationType | undefined>(undefined);

  const { data, loading, refetch } = useHrsLocationsQuery({
    client,
  });

  return (
    <>
      <Panel>
        <Panel.Body>
          <Table responsive striped>
            <thead>
              <tr>
                <th className="col-sm-2">Code</th>
                <th className="col-sm-2">Region</th>
                <th className="col-sm-1 text-right">
                  <a
                    className="btn btn-mint btn-labeled fa fa-plus"
                    onClick={() => {
                      setEditingLocation(undefined);
                      setShowUpsertModal(true);
                    }}
                  >
                    Add
                  </a>
                </th>
              </tr>
            </thead>

            <tbody>
              {data?.hrsLocations.map((location) => (
                <tr key={location.id}>
                  <td className="col-sm-2">{location.code}</td>
                  <td className="col-sm-2">{location.region?.name}</td>
                  <td className="col-sm-1 text-right">
                    <Link
                      kind="primary"
                      onClick={() => {
                        setEditingLocation(location);
                        setShowUpsertModal(true);
                      }}
                    >
                      Edit
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {loading && <Spinner />}
        </Panel.Body>
      </Panel>
      {showUpsertModal && (
        <UpsertModal
          location={editingLocation}
          regionOptions={data?.regions ?? []}
          closeModal={() => {
            setEditingLocation(undefined);
            setShowUpsertModal(false);
            refetch();
          }}
        />
      )}
    </>
  );
};

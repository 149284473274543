import styled from '@emotion/styled';
import * as React from 'react';

import { Button, Modal } from '@shared/components/bootstrap';

import { IConfirmation } from './confirmation';
import { Response } from './response';

const Title = styled.div`
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  text-align: center;
  white-space: normal;
`;

const Description = styled.div`
  font-size: 18px;
  font-weight: 200;
  line-height: 24px;
  text-align: center;
  white-space: normal;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -8px;
`;

const Action = styled.div`
  padding: 8px;
`;

export const Dialog: React.FC<{
  confirmation: IConfirmation;
}> = ({ confirmation }) => {
  const onClose = () => confirmation.respond(Response.Close);
  const onConfirm = () => confirmation.respond(Response.Confirm);
  const onCancel = () => confirmation.respond(Response.Cancel);

  return (
    <Modal onClose={onClose} size={confirmation.size}>
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>
            <Title>{confirmation.title}</Title>
          </Modal.Title>
          <Modal.Close close={onClose} />
        </Modal.Header>
        <Modal.Body>
          <Description>{confirmation.description}</Description>
        </Modal.Body>
        <Modal.Footer>
          <Actions>
            {confirmation.showCancel !== false && (
              <Action>
                <Button kind="default" size="lg" onClick={onCancel} disabled={confirmation.disabled}>
                  {confirmation.cancel ?? 'No'}
                </Button>
              </Action>
            )}
            {confirmation.showConfirm !== false && (
              <Action>
                <Button kind="danger" size="lg" onClick={onConfirm} disabled={confirmation.disabled}>
                  {confirmation.confirm ?? 'Yes'}
                </Button>
              </Action>
            )}
          </Actions>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};

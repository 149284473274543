import { capitalize } from 'lodash';
import React from 'react';

import { Label } from '@admin/components/helpers/label';
import { Panel } from '@admin/components/helpers/panel';
import { Spinner } from '@admin/components/spinner';
import { editTicketURL, ticketURL } from '@admin/config/routes';
import { Ticket__TicketableInput, TicketFragment, useTicketsQuery } from '@admin/schema';
import { AnchorButton, Table } from '@shared/components/bootstrap';
import { Timestamp } from '@shared/components/helpers';
import { client } from '@admin/libraries/apollo';
import cn from 'classnames';
import { DateTime } from 'luxon';

import { CreateTicket } from './create_ticket';

const SHORT_DATE_TIME_OPTIONS: Intl.DateTimeFormatOptions = {
  year: '2-digit',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
};

const statusStyle = (ticket: TicketFragment) => {
  switch (ticket.state) {
    case 'resolved':
      return ticket.resolutionType === 'did_not_do' ? 'default' : 'success';
    case 'open':
      return 'warning';
    case 'paused':
      return 'danger';
    case 'pending':
      return 'info';
    default:
      return 'default';
  }
};

const statusText = (ticket: TicketFragment) =>
  ticket.state === 'resolved' && ticket.resolutionType === 'did_not_do'
    ? 'Closed - No Action Taken'
    : capitalize(ticket.state);

const ticketAssignee = (ticket: TicketFragment) => {
  if (ticket.assignee) {
    return ticket.assignee.name;
  }
  if (ticket.group) {
    return `(${ticket.group.name})`;
  }
  return '-';
};

const Row: React.FC<{
  ticket: TicketFragment;
}> = ({ ticket }) => {
  const { id, category, subCategory, description, assignee, dueDate, updatedAt, latestNote, read, state, owner } =
    ticket;

  return (
    <tr
      className={cn({
        'ticket-unread': !read,
        'ticket-unassigned': !assignee,
        'ticket-overdue': state === 'open' && DateTime.fromISO(dueDate) < DateTime.local(),
      })}
    >
      <td className="col-md-1">{id}</td>
      <td className="col-md-1">
        <Timestamp value={updatedAt} format={Timestamp.Format.DateTime} />
      </td>
      <td className="col-md-2">
        {category} {subCategory && ` - ${subCategory}`}
      </td>
      <td className="col-md-2">{description}</td>
      <td className="col-md-2">{latestNote?.body}</td>
      <td className="col-md-1">{owner.name}</td>
      <td className="col-md-1">{ticketAssignee(ticket)}</td>
      <td className="col-md-2">
        <Label kind={statusStyle(ticket)}>{statusText(ticket)}</Label>
        {dueDate && (
          <div>
            due <Timestamp value={dueDate} format={Timestamp.Format.Custom} options={SHORT_DATE_TIME_OPTIONS} />
          </div>
        )}
      </td>
      <td className="col-zero col-nowrap">
        <AnchorButton kind="primary" href={ticketURL({ id })} target="_blank" style={{ marginRight: '4px' }}>
          View
        </AnchorButton>
        <AnchorButton kind="primary" href={editTicketURL({ id })} target="_blank">
          Edit
        </AnchorButton>
      </td>
    </tr>
  );
};

const TaskTicketsTable: React.FC<{
  ticketable: Ticket__TicketableInput;
}> = ({ ticketable }) => {
  const { data, loading } = useTicketsQuery({
    client,
    variables: {
      ticketable,
    },
  });

  if (loading || !data) {
    return <Spinner />;
  }

  return (
    <Table responsive>
      <thead>
        <tr>
          <th className="col-md-1">ID</th>
          <th className="col-md-1">Last Updated</th>
          <th className="col-md-2">Type</th>
          <th className="col-md-2">Description</th>
          <th className="col-md-2">Latest Note</th>
          <th className="col-md-1">Creator</th>
          <th className="col-md-1">Assignee</th>
          <th className="col-md-2">Status</th>
          <th className="col-zero col-nowrap" />
        </tr>
      </thead>
      <tbody>
        {data.tickets.map((ticket) => (
          <Row key={ticket.id} ticket={ticket} />
        ))}
      </tbody>
    </Table>
  );
};

export const TicketsPanel: React.FC<{
  ticketable: Ticket__TicketableInput;
}> = ({ ticketable }) => (
  <Panel>
    <Panel.Header>
      <Panel.Title>Task Tickets</Panel.Title>
    </Panel.Header>
    <Panel.Body>
      <TaskTicketsTable ticketable={ticketable} />
    </Panel.Body>
    <Panel.Footer align="right">
      <CreateTicket ticketable={ticketable} />
    </Panel.Footer>
  </Panel>
);

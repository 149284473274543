import * as React from 'react';

import { IOutboundDispatch } from './types/outbound_dispatch';

interface ISaveButtonProps {
  serialCode?: string;
  selectedDispatchId?: number;
  warehouseId?: string;
  outboundDispatches?: IOutboundDispatch[];
  disabled: boolean;
  notes?: string;
  handleVehicleAssignment(
    serialCode: string | undefined,
    dispatchId: number | undefined,
    warehouseId: string | undefined,
    oldDispatchId: number | undefined,
    notes: string | undefined,
  ): void;
}

class SaveButton extends React.Component<ISaveButtonProps> {
  public render() {
    const { outboundDispatches, serialCode, warehouseId, selectedDispatchId, disabled, notes } = this.props;
    const assignedDispatch = outboundDispatches && outboundDispatches.find((d) => d.vehicleCodes.includes(serialCode!));
    return (
      <button
        className={`btn-primary vehicle-assignment__submit-button ${
          disabled ? 'vehicle-assignment__submit-button--disabled' : ''
        }`}
        disabled={disabled}
        onClick={() =>
          this.props.handleVehicleAssignment(
            serialCode,
            selectedDispatchId,
            warehouseId,
            assignedDispatch && assignedDispatch.dispatchId,
            notes,
          )
        }
      >
        Submit
      </button>
    );
  }
}

export { SaveButton };

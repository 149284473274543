(function () {
  const app = angular.module('app');

  const FILTERS = ['region_id', 'date'];

  app.controller('AvailabilityDailyController', [
    '$scope',
    '$q',
    'Unavailability',
    'OperationsConstants',
    'AvailabilityDailyService',
    function ($scope, $q, Unavailability, OperationsConstants, AvailabilityDailyService) {
      $scope.times = OperationsConstants.TIMES;
      $scope.min = _.min($scope.times);
      $scope.max = _.max($scope.times);

      $scope.filtering = function () {
        return (
          $scope.filters.mode === 'daily' &&
          _.every(FILTERS, function (filter) {
            return !!$scope.filters[filter];
          })
        );
      };

      $scope.filter = function () {
        $scope.results = null;
        if ($scope.filtering()) {
          const filters = _.pick($scope.filters, FILTERS);
          $scope.unavailabilities = Unavailability.query(filters);

          $q.all([$scope.users.$promise, $scope.unavailabilities.$promise]).then(function () {
            $scope.results = AvailabilityDailyService.results($scope.filters, $scope.users, $scope.unavailabilities);
          });
        }
      };

      $scope.entries = function (user) {
        if (!$scope.results) {
          return;
        }
        return $scope.results[user.id];
      };

      $scope.filter($scope.filters);
      $scope.$on('filter', $scope.filter);
    },
  ]);
})();

import { take } from 'lodash';
import * as React from 'react';
import { useState } from 'react';
import { UserFragment } from '@admin/schema';
import { ListGroup } from '@shared/components/bootstrap';
import { Entry } from './entry';

const DEFAULT_LIMIT = 5;

type Ticket = {
  assignee?: UserFragment | null;
  owner: UserFragment;
};

export type TicketMessage = {
  id: string;
  body: string;
  user: UserFragment;
  createdAt: string;
  ticket: Ticket;
  readAt?: string | null;
};

export const List: React.FC<{
  messages?: TicketMessage[];
}> = ({ messages }) => {
  const [limit, setLimit] = useState<number | undefined>(DEFAULT_LIMIT);
  const more = () => setLimit(undefined);
  const less = () => setLimit(DEFAULT_LIMIT);

  return (
    <>
      {messages && (
        <ListGroup>
          {(limit ? take(messages, limit) : messages).map((message) => (
            <ListGroup.Item key={message.id}>
              <Entry message={message} />
            </ListGroup.Item>
          ))}
        </ListGroup>
      )}
      {messages && messages.length > DEFAULT_LIMIT && (
        <p className="text-center">
          <button type="button" onClick={limit ? more : less} className="btn btn-default">
            Show {limit ? 'All' : 'Fewer'} Messages
          </button>
        </p>
      )}
    </>
  );
};

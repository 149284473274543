(() => {
  const app = angular.module('app');

  const DIMENSIONS = ['num_movers', 'length', 'width', 'height'];

  const REQUIRED_FIELDS = ['length', 'width', 'height', 'category'];

  const filterNullValues = (obj) => _.omitBy(obj, (val) => val === undefined || val === null);

  app.controller('ItemCategorizablesModalController', [
    '$scope',
    'items',
    'Item',
    'ItemCategoryService',
    'ErrorService',
    function ($scope, items, Item, ItemCategoryService, ErrorService) {
      $scope.items = items;
      $scope.formState = {};
      const setupItemCategoryDimensionsFor = (selection) => {
        if (!selection) {
          $scope.formState = _.omit($scope.formState, DIMENSIONS);
          return;
        }
        const categoryDefaults = ItemCategoryService.findItemCategoryDefaults(
          selection.category,
          selection.sizing_selection,
        );
        Object.assign($scope.formState, _.pick(categoryDefaults, DIMENSIONS));
      };

      $scope.selectedItemCategory = (_item, model) => setupItemCategoryDimensionsFor(model);

      $scope.revertItemCategoryDimensions = () => {
        setupItemCategoryDimensionsFor(null);
        $scope.formState.dimensions_overwritten = false;
      };

      $scope.overrideItemCategoryDimensions = () => {
        if ($scope.formState.category) {
          $scope.formState.dimensions_overwritten = true;
        }
      };

      $scope.save = () => {
        if ($scope.saving) {
          return;
        }
        if ($scope.savable()) {
          $scope.saving = true;
          const { category } = $scope.formState;
          const state = _.omit($scope.formState, ['category']);
          if (category && category.id) {
            state.category_id = category.id;
          }
          if (!state.dimensions_overwritten) {
            DIMENSIONS.forEach((key) => _.unset(state, key));
          }
          const item = filterNullValues(state);
          item.custom_category_name = state.custom_category_name;

          Item.bulkUpdate({
            item_ids: items.map((i) => i.id),
            item,
          })
            .$promise.then($scope.confirm)
            .catch(ErrorService.handle)
            .then(() => {
              delete $scope.saving;
            });
        }
      };

      $scope.savable = () => REQUIRED_FIELDS.every((requiredKey) => !!$scope.formState[requiredKey]);
    },
  ]);
})();

(function () {
  angular.module('app').filter('orderAppointment', function () {
    return function (order) {
      if (order.full_pack) {
        return ['Full Pack Requested', order.other].join('\n');
      } else {
        return order.other;
      }
    };
  });
})();

import React from 'react';
import { Order, OrderTypeEnum } from '@admin/schema';
import { Titleize } from '@admin/components/helpers/titleize';

export const ConfirmationDescription: React.FC<{ order: Pick<Order, 'type'> }> = ({ order }) => (
  <>
    <p>
      Manually completing this <Titleize text={order.type} /> will{' '}
      {(() => {
        if (order.type === OrderTypeEnum.Return || order.type === OrderTypeEnum.Disposal) {
          return <>update all unflagged items and set the appointment state to 'completed'.</>;
        } else if (order.type === OrderTypeEnum.Pickup) {
          return <>set the appointment state to 'packed'.</>;
        } else {
          return <>set the appointment state to 'completed'.</>;
        }
      })()}
    </p>

    <p>Ensure labor clocks are correctly adjusted since this will immediately bill the customer for the appointment.</p>
  </>
);

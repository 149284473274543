import * as React from 'react';

import { FieldFormGroup } from '@admin/components/fields';

export const NotesFormGroup: React.FC<{
  value?: string;
  onChange(value?: string): void;
}> = ({ value, onChange }) => (
  <FieldFormGroup id="offline_payment_notes" label="Notes:">
    <textarea
      required
      id="offline_payment_notes"
      className="form-control"
      value={value ?? ''}
      placeholder="Notes"
      onChange={(event) => onChange(event.target.value || undefined)}
    />
  </FieldFormGroup>
);

import React from 'react';
import { sortBy } from 'lodash';
import { DateTime } from 'luxon';

export const DropOffDisplay: React.FC<{
  orderDate: DateTime;
  dropOffDate: DateTime;
  orderComponent: React.ReactChild;
  dropOffComponent: React.ReactChild;
}> = ({ orderComponent, orderDate, dropOffComponent, dropOffDate }) => {
  const displayData = [
    {
      key: 'order',
      date: orderDate,
      display: orderComponent,
    },
    {
      key: 'drop_off',
      date: dropOffDate,
      display: dropOffComponent,
    },
  ];

  const chronological = sortBy(displayData, ({ date }) => date);

  return (
    <>
      {chronological.map(({ key, display }) => (
        <p key={key}>{display}</p>
      ))}
    </>
  );
};

import * as React from 'react';

import { IVehicle } from './types/vehicle';

interface INoteProps {
  vehicle?: IVehicle;
  selectedDispatchId?: number;
  dispatchesNotes?: any[];
  updatedNote?: string;
  handleNotes(serialCode: string | undefined, note: string | undefined): void;
}

interface INoteState {
  note?: string | undefined;
}

class Note extends React.Component<INoteProps, INoteState> {
  constructor(props: INoteProps) {
    super(props);
    if (!this.props.vehicle) {
      return;
    }
    const { vehicle, dispatchesNotes, selectedDispatchId, updatedNote } = this.props;
    const dispatchNote =
      dispatchesNotes &&
      dispatchesNotes.find((n) => n.serialCode === vehicle.serialCode && n.dispatchId === selectedDispatchId);
    const note = updatedNote || (dispatchNote ? dispatchNote.notes : undefined);

    this.state = {
      note,
    };
  }

  public componentDidUpdate(prevProps: INoteProps) {
    if (prevProps.dispatchesNotes !== this.props.dispatchesNotes || prevProps.updatedNote !== this.props.updatedNote) {
      if (!this.props.vehicle) {
        return null;
      }
      const { vehicle, dispatchesNotes, selectedDispatchId, updatedNote } = this.props;
      const dispatchNote =
        dispatchesNotes &&
        dispatchesNotes.find((n) => n.serialCode === vehicle.serialCode && n.dispatchId === selectedDispatchId);
      let note = dispatchNote ? dispatchNote.notes : undefined;
      note = updatedNote || note;

      this.setState({ note });
    }
  }

  public render() {
    if (!this.props.vehicle) {
      return null;
    }
    const { vehicle, handleNotes } = this.props;

    return (
      <div className="notes-container">
        <span className="vehicle-assignment__note--message">
          Notes wont save until a dispatch is selected and save button is clicked
        </span>
        <textarea
          rows={3}
          className="form-control notes-container__text-area"
          onChange={(e) => this.handleNoteChange(e.currentTarget.value)}
          onBlur={(e) => handleNotes(vehicle.serialCode, e.currentTarget.value)}
          value={this.state.note || ''}
        />
      </div>
    );
  }

  private handleNoteChange = (note: string) => {
    this.setState({ note });
  };
}

export { Note };

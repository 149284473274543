import angular from 'angular';

angular.module('app').config([
  '$stateProvider',
  '$locationProvider',
  '$httpProvider',
  '$urlMatcherFactoryProvider',
  function routes($stateProvider, $locationProvider, $httpProvider, $urlMatcherFactoryProvider) {
    $locationProvider.html5Mode(true);
    $urlMatcherFactoryProvider.strictMode(false);

    /* For each state...
    - data.subSection determines what will be highlighted in the primary and
    secondary nav.
    - If data.subSection is not set, NavbarController will look for a
    subSection matching the state's name. If it finds one, the appropriate
    items in the primary and secondary nav will be highlighted automatically.
    - If you want to highlight the primary nav only (not the secondary nav),
    set data.section but not data.subSection.
    */
    $stateProvider
      .state('inbox', {
        url: '/',
        template: '<react-component name="HomeTicketQueue" />',
        data: {
          title: 'Inbox',
        },
      })
      .state('newTicket', {
        url: '/tickets/new?account_id',
        templateUrl: 'partials/tickets/new.html',
        controller: 'TicketNewController',
        data: {
          subSection: 'inbox',
          title: 'New Ticket',
        },
      })
      .state('viewTicket', {
        url: '/tickets/:id',
        templateUrl: 'partials/tickets/show.html',
        controller: 'TicketEditController',
        data: {
          subSection: 'inbox',
          title: 'View Ticket',
        },
      })
      .state('editTicket', {
        url: '/tickets/:id/edit',
        templateUrl: 'partials/tickets/edit.html',
        controller: 'TicketEditController',
        data: {
          subSection: 'inbox',
          title: 'Edit Ticket',
        },
      })
      .state('itSupport', {
        url: '/it',
        component: 'itSupport',
        data: {
          subSection: 'itSupport',
          title: 'IT Support',
        },
      })
      .state('ticketmanagement', {
        url: '/ticket_management',
        templateUrl: 'partials/ticket_managements/show.html',
        controller: 'TicketManagementController',
        data: {
          title: 'Ticket Management',
        },
      })
      .state('manageItemCategories', {
        url: '/item_category_management',
        templateUrl: 'partials/item_category_managements/show.html',
        controller: 'ItemCategoryManagementController',
        data: {
          section: 'settings',
          title: 'Item Category Management',
        },
      })
      .state('map', {
        url: '/map?date&region&order&dispatch&checkin&account_id',
        templateUrl: 'partials/ops_dashboards/show.html',
        reloadOnSearch: false,
        params: {
          date: null,
          region: null,
          order: null,
          dispatch: null,
          checkin: null,
          account_id: null,
        },
        data: {
          section: 'jobs',
          title: 'Ops Dashboard',
        },
      })
      .state('smsInbox', {
        url: '/smsinbox',
        templateUrl: 'partials/message_threads/index.html',
        controller: 'SmsInboxController',
        data: {
          title: 'SMS Inbox',
        },
      })
      .state('plans', {
        url: '/plans',
        templateUrl: 'partials/plans/index.html',
        controller: 'PlansIndexController',
        data: {
          title: 'Plans',
        },
      })
      .state('viewPlan', {
        url: '/plans/:id',
        templateUrl: 'partials/plans/show.html',
        controller: 'PlansShowController',
        data: {
          subSection: 'plans',
          title: 'Plan',
        },
      })
      .state('phoneCalls', {
        url: '/phone_calls',
        templateUrl: 'partials/phone_calls/index.html',
        controller: 'PhoneCallsIndexController as phone_calls_index_controller',
        data: {
          title: 'Phone Calls',
        },
      })
      .state('viewPhoneCall', {
        url: '/phone_calls/:id',
        templateUrl: 'partials/phone_calls/show.html',
        controller: 'PhoneCallsShowController',
        data: {
          subSection: 'phoneCalls',
          title: 'Phone Call',
        },
      })
      .state('shifts', {
        url: '/shifts',
        templateUrl: 'partials/shifts/index.html',
        controller: 'ShiftsIndexController as controller',
        data: {
          title: 'Shifts',
        },
      })
      .state('itemsExplorer', {
        url: '/items',
        component: 'itemsExplorer',
        data: {
          title: 'Items Explorer',
        },
      })
      .state('availability', {
        url: '/availability',
        templateUrl: 'partials/availabilities/show.html',
        controller: 'AvailabilityController',
        data: {
          title: 'Availability',
        },
      })
      .state('ptoRequests', {
        url: '/pto_requests?user_id',
        template: '<react-component name="PTORequestsList" props="$resolve" />',
        data: {
          title: 'PTO Requests',
        },
        resolve: {
          userID: ['$stateParams', ($stateParams) => $stateParams.user_id],
        },
      })
      .state('dispatcher', {
        url: '/dispatcher?date&region_id',
        templateUrl: 'partials/dispatchers/show.html',
        controller: 'DispatcherController',
        params: {
          date: { value: null, dynamic: true },
          region_id: { value: null, dynamic: true },
        },
        data: {
          subSection: 'dispatches',
          title: 'Dispatcher',
        },
      })
      .state('dispatches', {
        url: '/dispatches',
        templateUrl: 'partials/dispatches/index.html',
        controller: 'DispatchesIndexController',
        params: { date: null, region: null },
        data: {
          title: 'Dispatches',
        },
      })
      .state('viewDispatch', {
        url: '/dispatches/:id',
        templateUrl: 'partials/dispatches/show.html',
        controller: 'DispatchesShowController',
        params: { date: null, region: null },
        data: {
          subSection: 'dispatches',
          title: 'Dispatch',
        },
      })
      .state('editDispatch', {
        url: '/dispatches/:id/edit',
        template: '<react-component name="DispatchesEdit" props="$resolve" />',
        data: {
          subSection: 'dispatches',
          title: 'Dispatch',
        },
        resolve: {
          dispatchID: ['$stateParams', ($stateParams) => $stateParams.id],
        },
      })
      .state('accounts', {
        reloadOnSearch: false,
        url: '/accounts?page&state&query',
        params: {
          page: { type: 'int' },
          query: null,
          state: null,
        },
        template: '<react-component name="AccountsTable" props="$resolve" />',
        data: {
          title: 'Accounts',
        },
        resolve: {
          filters: ['$stateParams', (filters) => filters],
          onFilter: [
            '$state',
            ($state) => (filters) => {
              $state.go('.', filters, {
                location: 'replace',
                inherit: false,
                notify: false,
                reload: false,
              });
            },
          ],
        },
      })
      .state('viewAccount', {
        url: '/accounts/:id',
        templateUrl: 'partials/accounts/show.html',
        controller: 'AccountsShowController',
        params: {
          scroll: undefined,
          tab: undefined,
        },
        data: {
          subSection: 'accounts',
          title: 'Account',
        },
        resolve: {
          account: [
            'Account',
            '$stateParams',
            (Account, $stateParams) => Account.get({ id: $stateParams.id }).$promise,
          ],
        },
      })
      .state('viewCategorizables', {
        url: '/accounts/:account_id/items/categorizables',
        templateUrl: 'partials/items/categorizables.html',
        controller: 'ItemsCategorizablesIndexController',
        data: {
          subSection: 'accounts',
          title: 'Update Items',
        },
      })
      .state('viewItem', {
        url: '/accounts/:account_id/items/:id',
        templateUrl: 'partials/items/show.html',
        controller: 'ItemsShowController',
        data: {
          subSection: 'accounts',
          title: 'Item',
        },
      })
      .state('newReturn', {
        url: '/accounts/:account_id/returns/new?external&warehouse_return',
        component: 'returnForm',
        data: {
          subSection: 'accounts',
          title: 'Return',
        },
        resolve: {
          account: [
            '$stateParams',
            'Account',
            ($stateParams, Account) => Account.get({ id: $stateParams.account_id }).$promise,
          ],
          order: [
            '$stateParams',
            'Return',
            ($stateParams, Return) =>
              new Return({ account_id: $stateParams.account_id, external: $stateParams.external !== 'false' }),
          ],
        },
      })
      .state('editReturn', {
        url: '/accounts/:account_id/returns/:id/edit',
        component: 'returnForm',
        resolve: {
          account: [
            '$stateParams',
            'Account',
            ($stateParams, Account) => Account.get({ id: $stateParams.account_id }).$promise,
          ],
          order: [
            '$stateParams',
            'Return',
            ($stateParams, Return) => Return.get({ account_id: $stateParams.account_id, id: $stateParams.id }).$promise,
          ],
        },
        data: {
          subSection: 'accounts',
          title: 'Return',
        },
      })
      .state('newPickup', {
        url: '/accounts/:account_id/pickups/new?external',
        component: 'pickupForm',
        params: {
          onboardingDate: undefined,
        },
        resolve: {
          account: [
            '$stateParams',
            'Account',
            ($stateParams, Account) => Account.get({ id: $stateParams.account_id }).$promise,
          ],
          order: [
            '$stateParams',
            'Pickup',
            ($stateParams, Pickup) =>
              new Pickup({ account_id: $stateParams.account_id, external: $stateParams.external !== 'false' }),
          ],
        },
        data: {
          subSection: 'accounts',
          title: 'Pickup',
        },
      })
      .state('editPickup', {
        url: '/accounts/:account_id/pickups/:id/edit',
        component: 'pickupForm',
        resolve: {
          account: [
            '$stateParams',
            'Account',
            ($stateParams, Account) => Account.get({ id: $stateParams.account_id }).$promise,
          ],
          order: [
            '$stateParams',
            'Pickup',
            ($stateParams, Pickup) => Pickup.get({ account_id: $stateParams.account_id, id: $stateParams.id }).$promise,
          ],
        },
        data: {
          subSection: 'accounts',
          title: 'Pickup',
        },
      })
      .state('editMove', {
        url: '/accounts/:account_id/moves/:id/edit',
        component: 'moveForm',
        resolve: {
          account: [
            '$stateParams',
            'Account',
            ($stateParams, Account) => Account.get({ id: $stateParams.account_id }).$promise,
          ],
          order: [
            '$stateParams',
            'Move',
            ($stateParams, Move) => Move.get({ account_id: $stateParams.account_id, id: $stateParams.id }).$promise,
          ],
        },
        data: {
          subSection: 'accounts',
          title: 'Move',
        },
      })
      .state('taskOrder', {
        url: '/accounts/:account_id/task_orders/*path',
        template: `
          <react-component
            name="TaskOrderRouter"
          />
        `,
        data: {
          subSection: 'accounts',
          title: 'Task Order',
        },
        resolve: {
          accountID: ['$stateParams', ($stateParams) => $stateParams.account_id],
          orderID: ['$stateParams', ($stateParams) => $stateParams.id],
          go: ['$state', ($state) => $state.go],
        },
      })
      .state('inventory', {
        url: '/accounts/:account_id/inventory',
        templateUrl: 'partials/inventories/show.html',
        controller: 'InventoriesShowController',
        data: {
          subSection: 'accounts',
          title: 'Inventory',
        },
      })
      .state('newAccountItemGroup', {
        url: '/accounts/:account_id/item_groups/new',
        templateUrl: 'partials/item_groups/new.html',
        controller: 'ItemGroupNewController',
        data: {
          title: 'New Item Group',
        },
      })
      .state('editCustomer', {
        url: '/accounts/:account_id/customers/:id/edit',
        templateUrl: 'partials/customers/edit.html',
        controller: 'CustomersEditController',
        data: {
          subSection: 'accounts',
          title: 'Customer',
        },
      })
      .state('editPreferences', {
        url: '/accounts/:account_id/preferences/edit',
        template: `
          <react-component
            name="AccountSettings"
            props="{
              accountID: $resolve.accountID,
              onSave: $resolve.onSave,
            }"
          />
        `,
        data: {
          subSection: 'accounts',
          title: 'Preferences',
        },
        resolve: {
          accountID: ['$stateParams', ($stateParams) => $stateParams.account_id],
          onSave: [
            '$state',
            '$stateParams',
            ($state, $stateParams) => () => $state.go('viewAccount', { id: $stateParams.account_id }),
          ],
        },
      })
      .state('newSubscribe', {
        url: '/accounts/:account_id/subscribes/new',
        templateUrl: 'partials/subscribes/new.html',
        controller: 'SubscribesNewController',
        data: {
          subSection: 'accounts',
          title: 'Subscriptions',
        },
        resolve: {
          subscribeSetUp: [
            'SubscribeSetupService',
            '$stateParams',
            (SubscribeSetupService, $stateParams) => SubscribeSetupService.setup($stateParams.account_id),
          ],
        },
      })
      .state('newAddress', {
        url: '/accounts/:account_id/addresses/new',
        data: {
          subSection: 'accounts',
          title: 'Addresses',
        },
        template: `
          <react-component name="AccountAddressNew" props="$resolve" />
        `,
        resolve: {
          accountID: ['$stateParams', ($stateParams) => $stateParams.account_id],
          onSave: [
            '$state',
            '$stateParams',
            ($state, $stateParams) => (address) => {
              $state.go('viewAddress', { account_id: $stateParams.account_id, id: address.id });
            },
          ],
        },
      })
      .state('viewAddress', {
        url: '/accounts/:account_id/addresses/:id',
        data: {
          subSection: 'accounts',
          title: 'Address',
        },
        template: `
          <react-component name="AccountAddressShow" props="$resolve" />
        `,
        resolve: {
          accountID: ['$stateParams', ($stateParams) => $stateParams.account_id],
          addressID: ['$stateParams', ($stateParams) => $stateParams.id],
        },
      })
      .state('editAddress', {
        url: '/accounts/:account_id/addresses/:id/edit',
        data: {
          subSection: 'accounts',
          title: 'Edit Address',
        },
        template: `
          <react-component name="AccountAddressEdit" props="$resolve" />
        `,
        resolve: {
          accountID: ['$stateParams', ($stateParams) => $stateParams.account_id],
          addressID: ['$stateParams', ($stateParams) => $stateParams.id],
          onSave: [
            '$state',
            '$stateParams',
            ($state, $stateParams) => (address) => {
              $state.go('viewAddress', { account_id: $stateParams.account_id, id: address.id });
            },
          ],
        },
      })
      .state('viewPallet', {
        url: '/pallets/:id',
        templateUrl: 'partials/pallets/show.html',
        controller: 'PalletsShowController',
        data: {
          subSection: 'accounts',
          title: 'Pallet',
        },
      })
      .state('calendar', {
        url: '/calendar',
        templateUrl: 'partials/calendars/show.html',
        controller: 'CalendarsShowController',
        data: {
          title: 'Calendar',
        },
      })
      .state('orders', {
        reloadOnSearch: false,
        url: '/orders?page&date&query&type&subtype&service&state&dispatched&region_id',
        params: {
          page: null,
          date: null,
          query: null,
          type: null,
          subtype: null,
          service: null,
          dispatched: null,
          state: { array: true },
          region_id: { array: true },
        },
        template: `
          <react-component name="OrdersTable" props="$resolve" />
        `,
        data: {
          title: 'Orders',
        },
        resolve: {
          filters: [
            '$stateParams',
            ({ region_id: regionIDs, state: states, ...filters }) => ({
              regionIDs,
              states,
              ...filters,
            }),
          ],
          onFilter: [
            '$state',
            ($state) =>
              ({ regionIDs, states, ...filters }) => {
                $state.go(
                  '.',
                  {
                    region_id: regionIDs,
                    state: states,
                    ...filters,
                  },
                  {
                    location: 'replace',
                    inherit: false,
                    notify: false,
                    reload: false,
                  },
                );
              },
          ],
        },
      })
      .state('redirectOrder', {
        url: '/orders/:id',
        controller: 'OrderRedirectController',
        data: {
          title: 'Order',
        },
      })
      .state('viewOrder', {
        url: '/accounts/:account_id/orders/:id',
        templateUrl: 'partials/orders/show.html',
        controller: 'OrdersShowController',
        data: {
          subSection: 'accounts',
          title: 'Order',
        },
      })
      .state('rescheduleOrder', {
        url: '/accounts/:account_id/orders/:order_id/reschedule',
        template: `
          <react-component
            name="OrderReschedule"
            props="{
              accountID: $resolve.accountID,
              orderID: $resolve.orderID,
            }"
          />
        `,
        data: {
          subSection: 'accounts',
          title: 'Reschedule',
        },
        resolve: {
          orderID: ['$stateParams', ($stateParams) => $stateParams.order_id],
          accountID: ['$stateParams', ($stateParams) => $stateParams.account_id],
        },
      })
      .state('newShip', {
        url: '/accounts/:account_id/orders/:order_id/ship/new',
        templateUrl: 'partials/ships/new.html',
        controller: 'ShipsShowController',
        data: {
          subSection: 'accounts',
          title: 'Ship',
        },
      })
      .state('newFollowUp', {
        url: '/accounts/:account_id/moves/:order_id/follow_up/new',
        template: `
          <react-component
            name="ManageFollowUps"
            props="{
              orderID: $resolve.orderID,
            }"
          />
        `,
        data: {
          subSection: 'accounts',
          title: 'New Follow-Up',
        },
        resolve: {
          orderID: ['$stateParams', ($stateParams) => $stateParams.order_id],
          accountID: ['$stateParams', ($stateParams) => $stateParams.account_id],
        },
      })
      .state('warehouses', {
        url: '/warehouses',
        templateUrl: 'partials/warehouses/index.html',
        controller: 'WarehousesIndexController',
        data: {
          title: 'Warehouses',
        },
      })
      .state('createWarehouse', {
        url: '/warehouses/new',
        template: '<react-component name="CreateWarehouse" />',
        data: {
          subSection: 'warehouses',
          title: 'Add Warehouse',
        },
      })
      .state('palletRequestsList', {
        url: '/warehouses/:id/pallet_requests',
        template: '<react-component name="PalletRequestsList" props="{warehouseID : $resolve.id}" />',
        data: {
          subSection: 'warehouses',
          title: 'Pallet Requests',
        },
        resolve: {
          id: ['$stateParams', ($stateParams) => $stateParams.id],
        },
      })
      .state('viewWarehouse', {
        url: '/warehouses/:id',
        template: '<react-component name="WarehouseDetails" props="{id: $resolve.id}" />',
        controller: 'WarehousesShowController',
        data: {
          subSection: 'warehouses',
          title: 'Warehouse',
        },
        resolve: {
          id: ['$stateParams', ($stateParams) => $stateParams.id],
        },
      })
      .state('locations', {
        url: '/warehouses/:warehouse_id/locations',
        templateUrl: 'partials/locations/index.html',
        controller: 'WarehouseLocationsIndexController',
        data: {
          subSection: 'warehouses',
          title: 'Locations',
        },
      })
      .state('orderLeadTimes', {
        url: '/warehouses/:warehouse_id/order_lead_times',
        template: `
          <react-component
            name="OrderLeadTimeList"
            props="{
              warehouseID: $resolve.warehouseID
            }"
          />
        `,
        data: {
          subSection: 'warehouses',
          title: 'Order Lead Times',
        },
        resolve: {
          warehouseID: ['$stateParams', ($stateParams) => $stateParams.warehouse_id],
        },
      })
      .state('listTransferPool', {
        url: '/transferpools',
        templateUrl: 'partials/transfers_pools/index.html',
        controller: 'TransferPoolIndexController',
        data: {
          subSection: 'transfers',
          title: 'Transfer Pool',
        },
      })
      .state('viewTransferPool', {
        url: '/transferpools/:id',
        templateUrl: 'partials/transfers_pools/show.html',
        controller: 'TransferPoolShowController',
        data: {
          subSection: 'transfers',
          title: 'Transfer Pool',
        },
      })
      .state('editTransferPool', {
        url: '/transferpools/:id/edit',
        templateUrl: 'partials/transfers_pools/edit.html',
        controller: 'TransferPoolEditController',
        data: {
          subSection: 'transfers',
          title: 'Transfer Pool',
        },
      })
      .state('transfers', {
        url: '/transfers',
        templateUrl: 'partials/transfers/index.html',
        controller: 'TransfersIndexController',
        data: {
          title: 'Transfers',
        },
      })
      .state('editTransfer', {
        url: '/transfers/:id/edit',
        templateUrl: 'partials/transfers/edit.html',
        controller: 'TransfersEditController',
        data: {
          subSection: 'transfers',
          title: 'Edit Transfer',
        },
      })
      .state('viewTransfer', {
        url: '/transfers/:id',
        templateUrl: 'partials/transfers/show.html',
        controller: 'TransfersShowController',
        data: {
          subSection: 'transfers',
          title: 'Transfer',
        },
      })
      .state('transferables', {
        url: '/transferables',
        templateUrl: 'partials/transferables/index.html',
        controller: 'TransferablesIndexController',
        data: {
          subSection: 'transfers',
          title: 'Transferables',
        },
      })
      .state('newLocation', {
        url: '/warehouses/:warehouse_id/locations/new',
        templateUrl: 'partials/locations/new.html',
        controller: 'WarehouseLocationsNewController',
        data: {
          subSection: 'warehouses',
          title: 'Locations - New',
        },
      })
      .state('viewLocation', {
        url: '/locations/:id',
        templateUrl: 'partials/locations/show.html',
        controller: 'LocationsShowController',
        data: {
          subSection: 'warehouses',
          title: 'Location',
        },
      })
      .state('editLocation', {
        url: '/locations/:id/edit',
        templateUrl: 'partials/locations/edit.html',
        controller: 'LocationsEditController',
        data: {
          subSection: 'warehouses',
          title: 'Location - Edit',
        },
      })
      .state('users', {
        url: '/users',
        template: '<react-component name="UsersList" />',
        data: {
          title: 'Users',
        },
      })
      .state('newUser', {
        url: '/users/new',
        template: '<react-component name="UserForm" props="$resolve" />',
        data: {
          subSection: 'users',
          title: 'New User',
        },
        resolve: {
          onSave: ['$state', ($state) => () => $state.go('users')],
        },
      })
      .state('viewUser', {
        url: '/users/:id',
        template: `
          <react-component
            name="UserDetails"
            props="{ id: $resolve.id }"
          />
        `,
        resolve: {
          id: ['$stateParams', ($stateParams) => $stateParams.id],
        },
        data: {
          subSection: 'users',
          title: 'User',
        },
      })
      .state('editUser', {
        url: '/users/:id/edit',
        template: '<react-component name="UserForm" props="$resolve" />',
        data: {
          subSection: 'users',
          title: 'Edit User',
        },
        resolve: {
          id: ['$stateParams', ($stateParams) => $stateParams.id],
          onSave: ['$state', ($state) => () => $state.go('users')],
        },
      })
      .state('userCallGroupManager', {
        url: '/user_call_groups',
        templateUrl: 'partials/user_call_groups/index.html',
        controller: 'UserCallGroupManagerController',
        data: {
          title: 'Assign User Call Groups',
        },
        resolve: {
          users: ['User', (User) => User.query({ 'roles[]': ['l1_agent', 'l2_agent'] }).$promise],
          callGroups: ['CallGroup', (CallGroup) => CallGroup.query().$promise],
          userCallGroups: ['UserCallGroup', (UserCallGroup) => UserCallGroup.query().$promise],
        },
      })
      .state('callGroups', {
        url: '/call_groups',
        templateUrl: 'partials/call_groups/index.html',
        controller: 'CallGroupListController',
        data: {
          title: 'Call Groups',
        },
      })
      .state('newCallGroup', {
        url: '/call_groups/new',
        templateUrl: 'partials/call_groups/new.html',
        controller: 'CallGroupCreateController',
        data: {
          subSection: 'callGroups',
          title: 'New Call Group',
        },
      })
      .state('viewCallGroup', {
        url: '/call_groups/:id',
        templateUrl: 'partials/call_groups/show.html',
        controller: 'CallGroupViewController',
        data: {
          subSection: 'callGroups',
          title: 'Call Group',
        },
      })
      .state('editCallGroup', {
        url: '/call_groups/:id/edit',
        templateUrl: 'partials/call_groups/edit.html',
        controller: 'CallGroupEditController',
        data: {
          subSection: 'callGroups',
          title: 'Edit Call Group',
        },
      })
      .state('systemPhones', {
        url: '/system_phones',
        templateUrl: 'partials/system_phones/index.html',
        controller: 'SystemPhoneListController',
        data: {
          title: 'System Phones',
        },
      })
      .state('newSystemPhone', {
        url: '/system_phones/new',
        templateUrl: 'partials/system_phones/new.html',
        controller: 'SystemPhoneNewController',
        data: {
          subSection: 'systemPhones',
          title: 'New System Phone',
        },
      })
      .state('editSystemPhone', {
        url: '/system_phones/:id/edit',
        templateUrl: 'partials/system_phones/edit.html',
        controller: 'SystemPhoneEditController',
        data: {
          subSection: 'systemPhones',
          title: 'Edit System Phone',
        },
      })
      .state('viewSystemPhone', {
        url: '/system_phones/:id',
        templateUrl: 'partials/system_phones/show.html',
        controller: 'SystemPhoneViewController',
        data: {
          subSection: 'systemPhones',
          title: 'System Phone',
        },
      })
      .state('vehicles', {
        url: '/vehicles?warehouse_ids&status&vehicle_type_id',
        templateUrl: 'partials/vehicles/index.html',
        controller: 'VehicleListController',
        data: {
          title: 'Fleet Management',
        },
      })
      .state('newVehicle', {
        url: '/vehicles/new?warehouse_ids&status&vehicle_type_id',
        templateUrl: 'partials/vehicles/new.html',
        controller: 'VehicleCreateController',
        data: {
          subSection: 'vehicles',
          title: 'New Vehicle',
        },
      })
      .state('showVehicle', {
        url: '/vehicles/:id?warehouse_ids&status&vehicle_type_id',
        templateUrl: 'partials/vehicles/show.html',
        controller: 'VehicleShowController',
        data: {
          subSection: 'vehicles',
          title: 'Fleet Management',
        },
      })
      .state('editVehicle', {
        url: '/vehicles/:id/edit?warehouse_ids&status&vehicle_type_id',
        templateUrl: 'partials/vehicles/edit.html',
        controller: 'VehicleEditController',
        data: {
          subSection: 'vehicles',
          title: 'Edit Vehicle',
        },
      })
      .state('surveys', {
        url: '/workforces/surveys',
        templateUrl: 'partials/workforces/survey_index.html',
        controller: 'WorkforceSurveyIndexController',
        data: {
          title: 'Workforce Management',
        },
      })
      .state('newSurvey', {
        url: '/workforces/surveys/new',
        templateUrl: 'partials/workforces/survey_new.html',
        controller: 'WorkforceSurveyCreateController',
        data: {
          subSection: 'surveys',
          title: 'New Survey',
        },
      })
      .state('showSurvey', {
        url: '/workforces/surveys/:id',
        templateUrl: 'partials/workforces/survey_show.html',
        controller: 'WorkforceSurveyShowController',
        data: {
          subSection: 'surveys',
          title: 'Workforce Management',
        },
      })
      .state('editSurvey', {
        url: '/workforces/surveys/:id/edit',
        templateUrl: 'partials/workforces/survey_edit.html',
        controller: 'WorkforceSurveyEditController',
        data: {
          subSection: 'surveys',
          title: 'Edit Survey',
        },
      })
      .state('newSurveyResponse', {
        url: '/workforces/surveys/:survey_id/responses/new',
        templateUrl: 'partials/workforces/response_new.html',
        controller: 'WorkforceSurveyResponseCreateController',
        data: {
          subSection: 'survey responses',
          title: 'New Survey Response',
        },
      })
      .state('voicemails', {
        url: '/voicemails',
        templateUrl: 'partials/voicemails/index.html',
        controller: 'VoicemailsIndexController as voicemails_index_controller',
        data: {
          title: 'Voicemails',
        },
      })
      .state('viewVoicemail', {
        url: '/voicemails/:id',
        templateUrl: 'partials/voicemails/show.html',
        controller: 'VoicemailsShowController',
        data: {
          subSection: 'voicemails',
          title: 'Voicemail',
        },
      })
      .state('leads', {
        reloadOnSearch: false,
        url: '/leads?query&state&page',
        params: {
          page: { type: 'int' },
          query: null,
          state: null,
        },
        template: `
          <react-component
            name="LeadsList"
            props="$resolve"
          />
        `,
        data: {
          title: 'Leads',
        },
        resolve: {
          onSelect: ['$state', ($state) => (lead) => $state.go('editLead', { id: lead.id })],
        },
      })
      .state('editLead', {
        url: '/leads/:id/edit',
        component: 'checkoutForm',
        params: {
          scroll: undefined,
        },
        data: {
          subSection: 'leads',
          title: 'Edit Lead',
        },
        resolve: {
          coupons: ['Coupon', (Coupon) => Coupon.query({ active: true }).$promise],
        },
      })
      .state('newSelfStorageReservation', {
        url: '/self_storage/reservations/new',
        template: `
          <react-component
            name="SelfStorageReservationNew"
            props="{
              onSave: $resolve.onSave,
            }"
          />
        `,
        data: {
          title: 'Book Self-Storage Reservations',
          subSection: 'selfStorageReservations',
        },
        resolve: {
          onSave: [
            '$state',
            ($state) => (reservation) => $state.go('selfStorageReservationDetail', { id: reservation.id }),
          ],
        },
      })
      .state('selfStorageReservations', {
        url: '/self_storage/reservations?search&state&from&till&page',
        template: `
          <react-component
            name="SelfStorageReservationList"
            props="$resolve"
          />
        `,
        data: {
          title: 'Self-Storage Reservations',
        },
        reloadOnSearch: false,
        resolve: {
          page: ['$stateParams', ($stateParams) => $stateParams.page && Number($stateParams.page)],
          search: ['$stateParams', ($stateParams) => $stateParams.search],
          state: ['$stateParams', ($stateParams) => $stateParams.state],
          from: ['$stateParams', ($stateParams) => $stateParams.from],
          till: ['$stateParams', ($stateParams) => $stateParams.till],
          onFilter: [
            '$state',
            ($state) => (filters) => {
              $state.go(
                '.',
                {
                  page: filters.page,
                  search: filters.search,
                  state: filters.state,
                  from: filters.from,
                  till: filters.till,
                },
                {
                  location: 'replace',
                  inherit: false,
                  notify: false,
                  reload: false,
                },
              );
            },
          ],
        },
      })
      .state('editSelfStorageReservation', {
        url: '/self_storage/reservations/{id:int}/edit',
        template: `
          <react-component
            name="SelfStorageReservationForm"
            props="{
              id: $resolve.id,
              onSave: $resolve.onSave,
            }"
          />
        `,
        data: {
          title: 'Self-Storage Reservation',
          subSection: 'selfStorageReservations',
        },
        resolve: {
          id: ['$stateParams', ($stateParams) => $stateParams.id],
          onSave: [
            '$state',
            '$stateParams',
            ($state, $stateParams) => () => $state.go('selfStorageReservationDetail', { id: $stateParams.id }),
          ],
        },
      })
      .state('selfStorageReservationDetail', {
        url: '/self_storage/reservations/{id:int}',
        template: `
          <react-component
            name="SelfStorageReservationDetail"
            props="{ id: $resolve.id }"
          />
        `,
        data: {
          title: 'Self-Storage Reservation',
          subSection: 'selfStorageReservations',
        },
        resolve: {
          id: ['$stateParams', ($stateParams) => $stateParams.id],
        },
      })
      .state('selfStorageRentalsList', {
        url: '/self_storage/rentals',
        template: '<react-component name="SelfStorageRentalsList" />',
        data: {
          title: 'Self-Storage Rentals',
        },
      })
      .state('selfStorageNewRentalWalkIn', {
        url: '/self_storage/rentals/new?accountID',
        template: `<react-component
                    name="SelfStorageRentalForm"
                    props="{ accountID: $resolve.accountID }"
                  />`,
        data: {
          title: 'Self-Storage New Rental - Walk-In',
          subSection: 'selfStorageReservations',
        },
        resolve: {
          accountID: ['$stateParams', ($stateParams) => $stateParams.accountID],
        },
      })
      .state('selfStorageNewRental', {
        url: '/self_storage/reservations/{id:int}/rentals/new?accountID',
        template: `<react-component
                    name="SelfStorageRentalForm"
                    props="{ reservationID: $resolve.id, accountID: $resolve.accountID }"
                  />`,
        data: {
          title: 'Self-Storage New Rental',
          subSection: 'selfStorageReservations',
        },
        resolve: {
          id: ['$stateParams', ($stateParams) => $stateParams.id],
          accountID: ['$stateParams', ($stateParams) => $stateParams.accountID],
        },
      })
      .state('cancelSelfStorageRental', {
        url: '/self_storage/rentals/cancel?accountID',
        template: `
          <react-component
            name="SelfStorageRentalCancel"
            props="{ accountID: $resolve.accountID }"
          />
        `,
        data: {
          title: 'Self-Storage Move-Out',
        },
        resolve: {
          accountID: ['$stateParams', ($stateParams) => $stateParams.accountID],
        },
      })
      .state('selfStorageEditBilling', {
        url: '/self_storage/edit_billing?accountID',
        template: `
          <react-component
            name="SelfStorageEditBilling"
            props="{
              accountID: $resolve.accountID,
              onSave: $resolve.onSave
            }"
          />
        `,
        data: {
          subSection: 'accounts',
          title: 'Self-Storage Edit Billing',
        },
        resolve: {
          accountID: ['$stateParams', ($stateParams) => $stateParams.accountID],
          onSave: [
            '$state',
            '$stateParams',
            ($state, $stateParams) => () => $state.go('viewAccount', { id: $stateParams.accountID }),
          ],
        },
      })
      .state('editPassword', {
        url: '/password/edit',
        templateUrl: 'partials/passwords/edit.html',
        controller: 'PasswordsEditController as passwords_form_controller',
        data: {
          subSection: 'users',
          title: 'Password',
        },
      })
      .state('redirects', {
        url: '/marketing/redirects',
        templateUrl: 'partials/redirects/index.html',
        controller: 'RedirectsController',
        data: {
          title: 'Marketing Dashboard',
        },
      })
      .state('newRedirect', {
        url: '/marketing/redirects/new',
        templateUrl: 'partials/redirects/new.html',
        controller: 'RedirectNewController',
        data: {
          subSection: 'redirects',
          title: 'New Redirect',
        },
      })
      .state('editRedirect', {
        url: '/marketing/redirects/:id/edit',
        templateUrl: 'partials/redirects/edit.html',
        controller: 'RedirectEditController',
        data: {
          subSection: 'redirects',
          title: 'Edit Redirect',
        },
      })
      .state('affiliates', {
        url: '/marketing/affiliates',
        templateUrl: 'partials/affiliates/index.html',
        controller: 'AffiliatesController',
        data: {
          title: 'Affiliates',
        },
      })
      .state('newAffiliate', {
        url: '/marketing/affiliates/new',
        templateUrl: 'partials/affiliates/new.html',
        controller: 'AffiliateNewController',
        data: {
          subSection: 'affiliates',
          title: 'New Affiliate',
        },
        resolve: {
          affiliateKinds: ['Affiliate', (Affiliate) => Affiliate.kinds().$promise],
        },
      })
      .state('editAffiliate', {
        url: '/marketing/affiliates/:id/edit',
        templateUrl: 'partials/affiliates/edit.html',
        controller: 'AffiliateEditController',
        data: {
          subSection: 'affiliates',
          title: 'Edit Affiliate',
        },
        resolve: {
          affiliate: [
            '$stateParams',
            'Affiliate',
            ($stateParams, Affiliate) => Affiliate.show({ id: $stateParams.id }).$promise,
          ],
          affiliateKinds: ['Affiliate', (Affiliate) => Affiliate.kinds().$promise],
        },
      })
      .state('showAffiliate', {
        url: '/marketing/affiliates/:id',
        templateUrl: 'partials/affiliates/show.html',
        controller: 'AffiliateShowController',
        data: {
          subSection: 'affiliates',
          title: 'View Affiliate',
        },
        resolve: {
          affiliate: [
            '$stateParams',
            'Affiliate',
            ($stateParams, Affiliate) => Affiliate.show({ id: $stateParams.id }).$promise,
          ],
          affiliateInvoices: [
            '$stateParams',
            'AffiliateInvoice',
            ($stateParams, AffiliateInvoice) => AffiliateInvoice.query({ affiliate_id: $stateParams.id }).$promise,
          ],
        },
      })
      .state('affiliateInvoices', {
        url: '/marketing/affiliate_invoices',
        templateUrl: 'partials/affiliate_invoices/index.html',
        controller: 'AffiliateInvoicesController',
        data: {
          subSection: 'affiliates',
          title: 'View Affiliate Invoices',
        },
      })
      .state('coupons', {
        url: '/marketing/coupons',
        template: '<react-component name="CouponsDashboard" />',
        data: {
          title: 'Coupons',
        },
      })
      .state('newCoupon', {
        url: '/marketing/coupons/new',
        template: '<react-component name="CouponsNew" props="{ onSave: $resolve.onSave }" />',
        data: {
          subSection: 'coupons',
          title: 'New Coupon',
        },
        resolve: {
          onSave: ['$state', ($state) => () => $state.go('coupons')],
        },
      })
      .state('showCoupon', {
        url: '/marketing/coupons/:id',
        redirectTo: 'editCoupon',
      })
      .state('editCoupon', {
        url: '/marketing/coupons/:id/edit',
        template: '<react-component name="CouponsEdit" props="{ id: $resolve.id, onSave: $resolve.onSave }" />',
        data: {
          subSection: 'coupons',
          title: 'Edit Coupon',
        },
        resolve: {
          id: ['$stateParams', ($stateParams) => $stateParams.id],
          onSave: ['$state', ($state) => () => $state.go('coupons')],
        },
      })
      .state('referrals', {
        url: '/marketing/referrals',
        templateUrl: 'partials/referrals/index.html',
        controller: 'ReferralsController',
        data: {
          subSection: 'referrals',
          title: 'Referrals',
        },
      })
      .state('newReferral', {
        url: '/marketing/referrals/new',
        templateUrl: 'partials/referrals/new.html',
        data: {
          subSection: 'referrals',
          title: 'New Referral',
        },
      })
      .state('utmPhones', {
        url: '/marketing/utm_phones/new?:systemPhoneId',
        templateUrl: 'partials/utm_phones/new.html',
        controller: 'UtmPhoneController',
        data: {
          title: 'Add Campaign to Phone',
        },
      })
      .state('qrCodeGenerator', {
        url: '/marketing/qr_codes?value',
        template: `<react-component
                    name="QrCodePanel"
                    props="{ value: $resolve.value }"
                  />`,
        data: {
          title: 'QR Code Generator',
        },
        resolve: {
          value: ['$stateParams', ($stateParams) => $stateParams.value],
        },
      })
      .state('newPricingUpload', {
        url: '/pricing_uploads/new',
        template: '<react-component name="PricingUploadForm" />',
        data: {
          subSection: 'pricingUploads',
          title: 'Import Market Pricings',
        },
      })
      .state('pricingUploads', {
        url: '/pricing_uploads',
        template: '<react-component name="PricingUploadsList" />',
        data: {
          subSection: 'pricingUploads',
          title: 'Pricing Uploads',
        },
      })
      .state('newIngestion', {
        url: '/ingestions/new',
        template: '<react-component name="IngestionUploadForm" />',
        data: {
          subSection: 'ingestions',
          title: 'Import Ingestions',
        },
      })
      .state('ingestions', {
        url: '/ingestions',
        template: '<react-component name="IngestionUploadsList" />',
        data: {
          subSection: 'ingestions',
          title: 'Ingestions',
        },
      })
      .state('yieldManagement', {
        url: '/yield_management',
        template: `<react-component name="RateAdjustmentUploaderForm" props="{ product: 'DOOR_TO_DOOR' }"/>`,
        data: {
          title: 'Yield Management',
        },
      })
      .state('hrsRoleDefaults', {
        url: '/hrs_role_defaults',
        template: '<react-component name="HRSRoleDefaultsList" />',
        data: {
          title: 'HRS Role Defaults',
        },
      })
      .state('hrsProfileDefaults', {
        url: '/hrs_profile_defaults',
        template: '<react-component name="HRSProfileDefaultsList" />',
        data: {
          title: 'HRS Profile Defaults',
        },
      })
      .state('hrsLocations', {
        url: '/hrs_locations',
        template: '<react-component name="HRSLocationsList" />',
        data: {
          title: 'HRS Locations',
        },
      })
      .state('featureFlags', {
        url: '/feature_flags',
        template: '<react-component name="FeatureFlagsList" />',
        data: {
          title: 'Feature Flags',
        },
      })
      .state('trainingUploads', {
        url: '/training_uploads',
        template: '<react-component name="TrainingUploadList" />',
        data: {
          title: 'Training Uploads',
        },
      })
      .state('trainingUploadAttestations', {
        url: '/training_uploads/:id/attestations',
        template: '<react-component name="TrainingUploadAttestationsList" props="{ trainingUploadID: $resolve.id }" />',
        data: {
          title: 'Training Upload Attestations',
        },
        resolve: {
          id: ['$stateParams', ($stateParams) => $stateParams.id],
        },
      })
      .state('selfStorageFacilities', {
        url: '/self_storage/facilities',
        template: '<react-component name="SelfStorageFacilitiesList" />',
        data: {
          title: 'Self-Storage Facilities',
        },
      })
      .state('selfStorageUnitsIndex', {
        url: '/self_storage/units',
        template: '<react-component name="SelfStorageUnits" />',
        data: {
          title: 'Self-Storage Units',
        },
      })
      .state('selfStorageUnitDetail', {
        url: '/self_storage/units/:id',
        template: '<react-component name="SelfStorageUnitDetail" props="{ id: $resolve.id }" />',
        data: {
          title: 'Self-Storage Unit',
          subSection: 'selfStorageUnitsIndex',
        },
        resolve: {
          id: ['$stateParams', ($stateParams) => $stateParams.id],
        },
      })
      .state('editSelfStorageUnit', {
        url: '/self_storage/units/:id/edit',
        template: '<react-component name="SelfStorageUnitForm" props="{ id: $resolve.id }" />',
        data: {
          title: 'Self-Storage Unit',
          subSection: 'selfStorageUnitsIndex',
        },
        resolve: {
          id: ['$stateParams', ($stateParams) => $stateParams.id],
        },
      })
      .state('selfStorageRateAdjustmentUploader', {
        url: '/self_storage/rate_adjustments',
        template: `<react-component name="RateAdjustmentUploaderForm" props="{ product: 'SELF_STORAGE' }"/>`,
        data: {
          title: 'Rate Adjustments',
        },
      })
      .state('timeClock', {
        url: '/timetable/time_clocks',
        template: '',
        controller: () => (window.location.href = '/timetable/time_clocks'),
        data: {
          title: 'Time Clock',
        },
      })
      .state('gratuities', {
        url: '/gratuities',
        template: '<react-component name="GratuitiesDashboard" />',
        data: {
          title: 'Tips',
        },
      })
      .state('vehicleRouteMatcher', {
        url: '/vehicle_route_matcher',
        template: '<react-component name="VehicleRouteMatcher" />',
        data: {
          title: 'Vehicle Route Matcher',
        },
      })
      .state('claims', {
        url: '/claims?page&status&owner_id&has_damaged_item&has_damaged_property&has_lost_item&query',
        template: `
          <react-component
            name="ClaimsDashboard"
            props="$resolve"
          />
        `,
        data: {
          title: 'Claims',
        },
        reloadOnSearch: false,
        resolve: {
          page: ['$stateParams', ($stateParams) => $stateParams.page && Number($stateParams.page)],
          status: ['$stateParams', ($stateParams) => $stateParams.status],
          ownerID: ['$stateParams', ($stateParams) => $stateParams.owner_id],
          hasDamagedItem: [
            '$stateParams',
            ($stateParams) => ($stateParams.has_damaged_item ? $stateParams.has_damaged_item === 'true' : undefined),
          ],
          hasDamagedProperty: [
            '$stateParams',
            ($stateParams) =>
              $stateParams.has_damaged_property ? $stateParams.has_damaged_property === 'true' : undefined,
          ],
          hasLostItem: [
            '$stateParams',
            ($stateParams) => ($stateParams.has_lost_item ? $stateParams.has_lost_item === 'true' : undefined),
          ],
          query: ['$stateParams', ($stateParams) => $stateParams.query],
          onFilter: [
            '$state',
            ($state) => (filters) => {
              $state.go(
                '.',
                {
                  page: filters.page,
                  status: filters.status,
                  owner_id: filters.ownerID,
                  has_damaged_item: filters.hasDamagedItem,
                  has_damaged_property: filters.hasDamagedProperty,
                  has_lost_item: filters.hasLostItem,
                  query: filters.query,
                },
                {
                  location: 'replace',
                  inherit: false,
                  notify: false,
                  reload: false,
                },
              );
            },
          ],
        },
      })
      .state('claim', {
        url: '/claims/:id',
        template: `
          <react-component
            name="ClaimDetails"
            props="{ id: $resolve.id}"
          />
          <react-component
            name="HistoriesPanel"
            props="{
              type: 'CLAIM',
              id: $resolve.id,
            }"
          />
        `,
        data: {
          title: 'Claim',
          subSection: 'claims',
        },
        resolve: {
          id: ['$stateParams', ($stateParams) => $stateParams.id],
        },
      })
      .state('viewCustomerTicket', {
        url: '/customer_tickets/:id',
        template: `
          <react-component
            name="CustomerTicketDetails"
            props="{ id: $resolve.id }"
          />
          <react-component
            name="HistoriesPanel"
            props="{
              type: 'CUSTOMER_TICKET',
              id: $resolve.id,
            }"
          />
        `,
        data: {
          title: 'Customer Ticket',
          subSection: 'customerTickets',
        },
        resolve: {
          id: ['$stateParams', ($stateParams) => $stateParams.id],
        },
      })
      .state('customerTickets', {
        url: '/customer_tickets',
        template: `
          <react-component
            name="CustomerTicketQueue"
          />
        `,
        data: {
          title: 'Customer Tickets',
        },
      })
      .state('paymentPlan', {
        url: '/accounts/:account_id/payment_plans/:offer_id',
        template: `
          <react-component
            name="PaymentPlanDetails"
            props="{
              accountID: $resolve.accountID,
              offerID: $resolve.offerID,
            }"
          />
        `,
        data: {
          title: 'Payment Plan',
          subSection: 'accounts',
        },
        resolve: {
          accountID: ['$stateParams', ($stateParams) => $stateParams.account_id],
          offerID: ['$stateParams', ($stateParams) => $stateParams.offer_id],
        },
      })
      .state('newPaymentPlan', {
        url: '/accounts/:account_id/payment_plans/new?type',
        template: `
          <react-component
            name="PaymentPlanNew"
            props="{
              accountID: $resolve.accountID,
              type: $resolve.type,
            }"
          />
        `,
        data: {
          title: 'New Payment Plan',
          subSection: 'accounts',
        },
        resolve: {
          accountID: ['$stateParams', ($stateParams) => $stateParams.account_id],
          type: ['$stateParams', ($stateParams) => $stateParams.type],
        },
      })
      .state('newVehicleType', {
        url: '/vehicle_types/new',
        template: '<react-component name="CreateVehicleType" />',
        data: {
          title: 'New Vehicle Type',
        },
      })
      .state('vehicleTypes', {
        url: '/vehicle_types',
        template: '<react-component name="VehicleTypes" />',
        data: {
          title: 'Vehicle Types',
        },
      })
      .state('editVehicleType', {
        url: '/vehicle_types/:id/edit',
        template: '<react-component name="EditVehicleTypes" props="{ id: $resolve.id }" />',
        data: {
          title: 'Edit Vehicle Type',
        },
        resolve: {
          id: ['$stateParams', ($stateParams) => $stateParams.id],
        },
      })
      .state('depotsList', {
        url: '/depots',
        template: '<react-component name="DepotsList" />',
        data: {
          title: 'Depots',
        },
      })
      .state('createDepot', {
        url: '/depots/new',
        template: '<react-component name="CreateDepot" />',
        data: {
          title: 'Add Depot',
          subSection: 'depotsList',
        },
      })
      .state('editDepot', {
        url: '/depots/:id/edit',
        template: '<react-component name="EditDepot" props="{id : $resolve.id}"/>',
        data: {
          title: 'Edit Depot',
          subSection: 'depotsList',
        },
        resolve: {
          id: ['$stateParams', ($stateParams) => $stateParams.id],
        },
      })
      .state('regions', {
        url: '/regions',
        template: '<react-component name="RegionsDashboard" />',
        data: {
          title: 'Regions',
        },
      })
      .state('region', {
        url: '/regions/:id',
        template: '<react-component name="RegionDetails" props="{id: $resolve.id}"/>',
        data: {
          title: 'Region',
          subSection: 'regions',
        },
        resolve: {
          id: ['$stateParams', ($stateParams) => $stateParams.id],
        },
      })
      .state('editRegion', {
        url: '/regions/:id/edit',
        template: `<react-component name="RegionEdit" props="{id: $resolve.id}"/>`,
        data: {
          title: 'Region',
          subSection: 'regions',
        },
        resolve: {
          id: ['$stateParams', ($stateParams) => $stateParams.id],
        },
      })
      .state('palletTypes', {
        url: '/pallet_types',
        template: '<react-component name="PalletTypes" />',
        data: {
          title: 'Pallet Types',
        },
      })
      .state('createPalletTypes', {
        url: '/pallet_types/new',
        template: '<react-component name="CreatePalletType" />',
        data: {
          title: 'Create Pallet Type',
          subSection: 'palletTypes',
        },
      })
      .state('editPalletTypes', {
        url: '/pallet_types/:id/edit',
        template: `<react-component
                    name="EditPalletType"
                    props="{id : $resolve.id}"/>`,
        data: {
          title: 'Edit Pallet Type',
          subSection: 'palletTypes',
        },
        resolve: {
          id: ['$stateParams', ($stateParams) => $stateParams.id],
        },
      })
      .state('notesUploader', {
        url: '/notes_uploader',
        template: '<react-component name="NotesUploader" />',
        data: {
          title: 'Notes Uploader',
        },
      })
      .state('opsAuditConfigurations', {
        url: '/ops_audit/configurations',
        template: '<react-component name="OpsAuditConfigurations" />',
        data: {
          title: 'Ops Audit Configurations',
        },
      })
      .state('opsAuditAuditReview', {
        url: '/ops_audit/configurations/:id/audit_review',
        template: `<react-component
                    name="OpsAuditAuditReview"
                    props="{ configurationID: $resolve.configurationID }"
                  />`,
        data: {
          title: 'Ops Audit Review',
          subSection: 'opsAuditReview',
        },
        resolve: {
          configurationID: ['$stateParams', ($stateParams) => $stateParams.id],
        },
      })
      .state('opsAuditAudit', {
        url: '/ops_audit/audits/:id',
        template: `<react-component
                    name="OpsAuditAudit"
                    props="{ auditID: $resolve.auditID }"
                  />`,
        data: {
          title: 'Ops Audit Audit',
          subSection: 'opsAuditReview',
        },
        resolve: {
          auditID: ['$stateParams', ($stateParams) => $stateParams.id],
        },
      })
      .state('loads', {
        url: '/logistics/loads?search&state&type&appointmentDate&warehouseIDs&page',
        template: '<react-component name="LogisticsLoads" props="$resolve" />',
        data: {
          title: 'Loads',
          subSection: 'loads',
        },
        reloadOnSearch: false,
        resolve: {
          page: ['$stateParams', ($stateParams) => $stateParams.page && Number($stateParams.page)],
          search: ['$stateParams', ($stateParams) => $stateParams.search],
          state: ['$stateParams', ($stateParams) => $stateParams.state],
          type: ['$stateParams', ($stateParams) => $stateParams.type],
          appointmentDate: ['$stateParams', ($stateParams) => $stateParams.appointmentDate],
          warehouseIDs: ['$stateParams', ($stateParams) => $stateParams.warehouseIDs],
          onFilter: [
            '$state',
            ($state) => (filters) => {
              $state.go(
                '.',
                {
                  page: filters.page,
                  search: filters.search,
                  state: filters.state,
                  type: filters.type,
                  appointmentDate: filters.appointmentDate,
                  warehouseIDs: filters.warehouseIDs,
                },
                {
                  location: 'replace',
                  inherit: false,
                  notify: false,
                  reload: false,
                },
              );
            },
          ],
        },
      })
      .state('createLoad', {
        url: '/logistics/loads/new',
        template: '<react-component name="LogisticsNewLoad" />',
        data: {
          title: 'Create Load',
          subSection: 'loads',
        },
      })
      .state('editLoad', {
        url: '/logistics/loads/:id/edit',
        template: `<react-component
                    name="LogisticsEditLoad"
                    props="{id : $resolve.id}"/>`,
        data: {
          title: 'Edit Load',
          subSection: 'loads',
        },
        resolve: {
          id: ['$stateParams', ($stateParams) => $stateParams.id],
        },
      })
      .state('detailedLoad', {
        url: '/logistics/loads/:id',
        template: `<react-component
                    name="LogisticsDetailedLoad"
                    props="{id : $resolve.id}"/>`,
        data: {
          title: 'Detailed Load',
          subSection: 'loads',
        },
        resolve: {
          id: ['$stateParams', ($stateParams) => $stateParams.id],
        },
      })
      .state('purchaseOrders', {
        url: '/logistics/purchase_orders',
        template: '<react-component name="LogisticsPurchaseOrders" />',
        data: {
          title: 'Purchase Orders',
        },
      })
      .state('newPurchaseOrder', {
        url: '/logistics/purchase_orders/new?accountID',
        template: '<react-component name="LogisticsNewPurchaseOrder" props="{ accountID: $resolve.accountID }" />',
        data: {
          title: 'Create Purchase Order',
        },
        resolve: {
          accountID: ['$stateParams', ($stateParams) => $stateParams.accountID],
        },
      })
      .state('editPurchaseOrder', {
        url: '/logistics/purchase_orders/:id/edit?accountID',
        template:
          '<react-component name="LogisticsEditPurchaseOrder" props="{ accountID: $resolve.accountID, id: $resolve.id }" />',
        data: {
          title: 'Edit Purchase Order',
        },
        resolve: {
          id: ['$stateParams', ($stateParams) => $stateParams.id],
          accountID: ['$stateParams', ($stateParams) => $stateParams.accountID],
        },
      })
      .state('newSku', {
        url: '/logistics/skus/new?accountID',
        template: '<react-component name="LogisticsNewSku" props="{ accountID: $resolve.accountID }" />',
        data: {
          title: 'Create SKU',
        },
        resolve: {
          accountID: ['$stateParams', ($stateParams) => $stateParams.accountID],
        },
      })
      .state('editSku', {
        url: '/logistics/skus/:id/edit',
        template: '<react-component name="LogisticsEditSku" props="{ id: $resolve.id }" />',
        data: {
          title: 'Edit SKU',
        },
        resolve: {
          id: ['$stateParams', ($stateParams) => $stateParams.id],
        },
      })
      .state('detailedSku', {
        url: '/logistics/skus/:id',
        template: '<react-component name="LogisticsDetailedSku" props="{ id: $resolve.id }" />',
        data: {
          title: 'Detailed SKU',
        },
        resolve: {
          id: ['$stateParams', ($stateParams) => $stateParams.id],
        },
      })
      .state('qaQrCodes', {
        url: '/qaQrCodes?value',
        template: `<react-component
                    name="QrCodePanel"
                    props="{ value: $resolve.value }"
                  />`,
        data: {
          title: 'QA QR Code',
        },
        resolve: {
          value: ['$stateParams', ($stateParams) => $stateParams.value],
        },
      })
      .state('testOrders', {
        url: '/test_orders',
        template: '<react-component qa name="TestOrders" />',
        data: {
          title: 'Test Orders',
        },
      })
      .state('newShuttle', {
        url: '/accounts/:account_id/self_storage_shuttles/new?external',
        component: 'selfStorageShuttleForm',
        params: {
          onboardingDate: undefined,
        },
        resolve: {
          account: [
            '$stateParams',
            'Account',
            ($stateParams, Account) => Account.get({ id: $stateParams.account_id }).$promise,
          ],
          order: [
            '$stateParams',
            'SelfStorageShuttle',
            ($stateParams, SelfStorageShuttle) =>
              new SelfStorageShuttle({
                account_id: $stateParams.account_id,
                external: $stateParams.external !== 'false',
              }),
          ],
        },
        data: {
          subSection: 'accounts',
          title: 'Shuttle',
        },
      })
      .state('editShuttle', {
        url: '/accounts/:account_id/shuttles/:id/edit',
        component: 'selfStorageShuttleForm',
        resolve: {
          account: [
            '$stateParams',
            'Account',
            ($stateParams, Account) => Account.get({ id: $stateParams.account_id }).$promise,
          ],
          order: [
            '$stateParams',
            'SelfStorageShuttle',
            ($stateParams, SelfStorageShuttle) =>
              SelfStorageShuttle.get({ account_id: $stateParams.account_id, id: $stateParams.id }).$promise,
          ],
        },
        data: {
          subSection: 'accounts',
          title: 'Shuttle',
        },
      })
      .state('editAccountPricing', {
        url: '/accounts/:account_id/pricing/edit',
        template: `
          <react-component
            name="EditAccountPricing"
            props="{
              accountID: $resolve.accountID,
              onSave: $resolve.onSave,
            }"
          />
        `,
        data: {
          subSection: 'accounts',
          title: 'Edit Pricing',
        },
        resolve: {
          accountID: ['$stateParams', ($stateParams) => $stateParams.account_id],
          onSave: [
            '$state',
            '$stateParams',
            ($state, $stateParams) => () => $state.go('viewAccount', { id: $stateParams.account_id }),
          ],
        },
      })
      .state('createLogisticsAccount', {
        url: '/logistics/accounts/new',
        template: '<react-component name="LogisticsNewAccount" />',
        data: {
          title: 'Create Logistics Account',
        },
      })
      .state('editRetailDelivery', {
        url: '/accounts/:account_id/retail_deliveries/:id/edit',
        component: 'retailDeliveryForm',
        resolve: {
          account: [
            '$stateParams',
            'Account',
            ($stateParams, Account) => Account.get({ id: $stateParams.account_id }).$promise,
          ],
          order: [
            '$stateParams',
            'RetailDelivery',
            ($stateParams, RetailDelivery) =>
              RetailDelivery.get({ account_id: $stateParams.account_id, id: $stateParams.id }).$promise,
          ],
        },
        data: {
          subSection: 'accounts',
          title: 'Retail Delivery',
        },
      })
      .state('opsAuditEditConfiguration', {
        url: '/ops_audit/configurations/:id/edit',
        template: '<react-component name="OpsAuditEditConfiguration" props="{ id: $resolve.id }" />',
        data: {
          title: 'Edit Ops Audit Configuration',
        },
        resolve: {
          id: ['$stateParams', ($stateParams) => $stateParams.id],
        },
      })
      .state('nightTransportPlanner', {
        url: '/night_transport/planner',
        template: '<react-component name="NightTransportPlanner" />',
        data: {
          title: 'Night Planner',
        },
      })
      .state('landingLocationStateList', {
        url: '/landing/location/states',
        template: '<react-component name="LandingLocationStateList" />',
        data: {
          title: 'Landing - Location - States',
        },
      })
      .state('newLandingLocationState', {
        url: '/landing/location/states/new',
        template: '<react-component name="LandingLocationStateForm" props="$resolve" />',
        data: {
          subSection: 'landingLocationStateList',
          title: 'Landing - Location - States - New',
        },
        resolve: {
          id: ['$stateParams', ($stateParams) => $stateParams.id],
          onSave: [
            '$state',
            ($state) => () => {
              $state.go('landingLocationStateList');
            },
          ],
        },
      })
      .state('editLandingLocationState', {
        url: '/landing/location/states/{id:int}/edit',
        template: '<react-component name="LandingLocationStateForm" props="$resolve" />',
        data: {
          subSection: 'landingLocationStateList',
          title: 'Landing - Location - States - Edit',
        },
        resolve: {
          id: ['$stateParams', ($stateParams) => $stateParams.id],
          onSave: [
            '$state',
            ($state) => () => {
              $state.go('landingLocationStateList');
            },
          ],
        },
      })
      .state('landingLocationCityList', {
        url: '/landing/location/cities',
        template: '<react-component name="LandingLocationCityList" />',
        data: {
          title: 'Landing - Location - Cities',
        },
      })
      .state('newLandingLocationCity', {
        url: '/landing/location/cities/new',
        template: '<react-component name="LandingLocationCityForm" props="$resolve" />',
        data: {
          subSection: 'landingLocationCityList',
          title: 'Landing - Location - Cities - New',
        },
        resolve: {
          onSave: [
            '$state',
            ($state) => (city) => {
              $state.go('landingLocationCityDetails', { id: city.id });
            },
          ],
        },
      })
      .state('editLandingLocationCity', {
        url: '/landing/location/cities/{id:int}/edit',
        template: '<react-component name="LandingLocationCityForm" props="$resolve" />',
        data: {
          subSection: 'landingLocationCityList',
          title: 'Landing - Location - Cities - Edit',
        },
        resolve: {
          id: ['$stateParams', ($stateParams) => $stateParams.id],
          onSave: [
            '$state',
            ($state) => (city) => {
              $state.go('landingLocationCityDetails', { id: city.id });
            },
          ],
        },
      })
      .state('landingLocationCityDetails', {
        url: '/landing/location/cities/{id:int}',
        template: '<react-component name="LandingLocationCityDetails" props="$resolve" />',
        data: {
          subSection: 'landingLocationCityList',
          title: 'Landing - Location - Cities - Details',
        },
        resolve: {
          id: ['$stateParams', ($stateParams) => $stateParams.id],
        },
      })
      .state('landingLocationNeighborhoodList', {
        url: '/landing/location/neighborhoods',
        template: '<react-component name="LandingLocationNeighborhoodList" />',
        data: {
          title: 'Landing - Location - Neighborhoods - List',
        },
      })
      .state('newLandingLocationNeighborhood', {
        url: '/landing/location/neighborhoods/new',
        template: '<react-component name="LandingLocationNeighborhoodForm" props="$resolve" />',
        data: {
          subSection: 'landingLocationNeighborhoodList',
          title: 'Landing - Location - Neighborhoods - New',
        },
        resolve: {
          onSave: [
            '$state',
            ($state) => (neighborhood) => {
              $state.go('landingLocationNeighborhood', { id: neighborhood.id });
            },
          ],
        },
      })
      .state('editLandingLocationNeighborhood', {
        url: '/landing/location/neighborhoods/{id:int}/edit',
        template: '<react-component name="LandingLocationNeighborhoodForm" props="$resolve" />',
        data: {
          subSection: 'landingLocationNeighborhoodList',
          title: 'Landing - Location - Neighborhoods - Edit',
        },
        resolve: {
          id: ['$stateParams', ($stateParams) => $stateParams.id],
          onSave: [
            '$state',
            ($state) => (neighborhood) => {
              $state.go('landingLocationNeighborhood', { id: neighborhood.id });
            },
          ],
        },
      })
      .state('landingLocationNeighborhood', {
        url: '/landing/location/neighborhoods/{id:int}',
        template: '<react-component name="LandingLocationNeighborhoodDetails" props="$resolve" />',
        data: {
          subSection: 'landingLocationNeighborhoodList',
          title: 'Landing - Location - Neighborhoods - Details',
        },
        resolve: {
          id: ['$stateParams', ($stateParams) => $stateParams.id],
        },
      })
      .state('landingLocationRouteList', {
        url: '/landing/location/routes',
        template: '<react-component name="LandingLocationRouteList" />',
        data: {
          title: 'Landing - Location - Routes - List',
        },
      })
      .state('newLandingLocationRoute', {
        url: '/landing/location/routes/new',
        template: '<react-component name="LandingLocationRouteForm" props="$resolve" />',
        data: {
          subSection: 'landingLocationRouteList',
          title: 'Landing - Location - Routes - New',
        },
        resolve: {
          onSave: [
            '$state',
            ($state) => (route) => {
              $state.go('landingLocationRoute', { id: route.id });
            },
          ],
        },
      })
      .state('editLandingLocationRoute', {
        url: '/landing/location/routes/{id:int}/edit',
        template: '<react-component name="LandingLocationRouteForm" props="$resolve" />',
        data: {
          subSection: 'landingLocationRouteList',
          title: 'Landing - Location - Routes - Edit',
        },
        resolve: {
          id: ['$stateParams', ($stateParams) => $stateParams.id],
          onSave: [
            '$state',
            ($state) => (route) => {
              $state.go('landingLocationRoute', { id: route.id });
            },
          ],
        },
      })
      .state('landingLocationRoute', {
        url: '/landing/location/routes/{id:int}',
        template: '<react-component name="LandingLocationRouteDetails" props="$resolve" />',
        data: {
          subSection: 'landingLocationRouteList',
          title: 'Landing - Location - Routes - Details',
        },
        resolve: {
          id: ['$stateParams', ($stateParams) => $stateParams.id],
        },
      })
      .state('newLandingLocationContent', {
        url: '/landing/location/contents/new?location_id',
        template: '<react-component name="LandingLocationContentForm" props="$resolve" />',
        data: {
          title: 'Landing - Location - Contents - New',
        },
        resolve: {
          locationID: ['$stateParams', ($stateParams) => $stateParams.location_id],
          onSave: [
            '$state',
            ($state) => (content) => {
              $state.go('landingLocationContent', { id: content.id });
            },
          ],
        },
      })
      .state('editLandingLocationContent', {
        url: '/landing/location/contents/{id:int}/edit',
        template: '<react-component name="LandingLocationContentForm" props="$resolve" />',
        data: {
          title: 'Landing - Location - Contents - Edit',
        },
        resolve: {
          id: ['$stateParams', ($stateParams) => $stateParams.id],
          onSave: [
            '$state',
            ($state) => (content) => {
              $state.go('landingLocationContent', { id: content.id });
            },
          ],
        },
      })
      .state('landingLocationContent', {
        url: '/landing/location/contents/{id:int}',
        template: '<react-component name="LandingLocationContentDetails" props="$resolve" />',
        data: {
          title: 'Landing - Location - Contents - Details',
        },
        resolve: {
          id: ['$stateParams', ($stateParams) => $stateParams.id],
        },
      })
      .state('globalUnavailability', {
        url: '/global_unavailability',
        template: '<react-component name="GlobalUnavailabilityList" />',
        data: {
          title: 'Global Unavailability',
        },
      })
      .state('globalUnavailabilityNew', {
        url: '/global_unavailability/new',
        template: '<react-component name="GlobalUnavailabilityForm" />',
        data: {
          title: 'Global Unavailability - New',
          subSection: 'globalUnavailability',
        },
      })
      .state('jobAvailability', {
        url: '/job_availability',
        template: '<react-component name="JobAvailabilityList" />',
        data: {
          title: 'Job Availability',
        },
      })
      .state('jobAvailabilityNew', {
        url: '/job_availability/new',
        template: '<react-component name="JobAvailabilityForm" />',
        data: {
          title: 'Job Availability - New One-Off',
          subSection: 'jobAvailability',
        },
      })
      .state('warehouseCapacity', {
        url: '/warehouse_capacity',
        template: '<react-component name="WarehouseCapacityList" />',
        data: {
          title: 'Warehouse Capacity',
        },
      })
      .state('warehouseCapacityNew', {
        url: '/warehouse_capacity/new',
        template: '<react-component name="WarehouseCapacityForm" />',
        data: {
          title: 'Warehouse Capacity - New One-Off',
          subSection: 'warehouseCapacity',
        },
      })
      .state('warehouseOperatingScheduleTemplateNew', {
        url: '/warehouses/operating_schedule_templates/new',
        template: '<react-component name="WarehouseOperatingScheduleTemplateCreateForm" />',
        data: {
          title: 'Warehouse Capacity - New Weekly Schedule',
          subSection: 'warehouseCapacity',
        },
      })
      .state('regionOperatingScheduleTemplateNew', {
        url: '/regions/operating_schedule_templates/new',
        template: '<react-component name="RegionOperatingScheduleTemplateCreateForm" />',
        data: {
          title: 'Job Availability - New Weekly Schedule',
          subSection: 'jobAvailability',
        },
      })
      .state('availabilityLaborAdjustment', {
        url: '/availability_labor_adjustment',
        template: '<react-component name="AvailabilityLaborAdjustmentList" />',
        data: {
          title: 'Availability Labor Adjustment',
        },
      })
      .state('availabilityLaborAdjustmentNew', {
        url: '/availability_labor_adjustment/new',
        template: '<react-component name="AvailabilityLaborAdjustmentForm" />',
        data: {
          title: 'Availability Labor Adjustment - New',
          subSection: 'availabilityLaborAdjustment',
        },
      })
      .state('availabilityRescheduleOffers', {
        url: '/availability_reschedule_offers',
        template: '<react-component name="AvailabilityRescheduleOffersList" />',
        data: {
          title: 'Availability Reschedule Offers',
        },
      })
      .state('availabilityRescheduleOffersNew', {
        url: '/availability_reschedule_offers/new',
        template: '<react-component name="AvailabilityRescheduleOffersForm" />',
        data: {
          title: 'Availability Reschedule Offers - New',
          subSection: 'availabilityRescheduleOffers',
        },
      })
      .state('auctionUploads', {
        url: '/auction_uploads',
        template: '<react-component name="AuctionUploadList" />',
        data: {
          title: 'Auction Uploads',
        },
      })
      .state('auctionUploadNew', {
        url: '/auction_uploads/new',
        template: '<react-component name="AuctionUploadForm" />',
        data: {
          title: 'Import Auctions',
          subSection: 'auctionUploads',
        },
      })
      .state('zendeskUploads', {
        url: '/zendesk/uploads',
        template: '<react-component name="ZendeskUpload" />',
        data: {
          title: 'Zendesk Uploads',
        },
      })
      .state('contractorPasswordResetRequestApprove', {
        url: '/contractor_password_reset_requests/:mfaRequestID',
        template: '<react-component name="ContractorPasswordResetRequestApproveForm" props="$resolve" />',
        data: {
          title: 'Contractor Password Reset Request',
        },
        resolve: {
          mfaRequestID: ['$stateParams', ($stateParams) => $stateParams.mfaRequestID],
        },
      });

    $httpProvider.interceptors.push([
      '$q',
      '$location',
      '$window',
      function interceptor($q, $location, $window) {
        return {
          response: function resp(response) {
            if (response.status === 401 && response.status === 403) {
              // eslint-disable-next-line no-console
              console.log(`Response: ${response.status}`);
              // eslint-disable-next-line no-param-reassign
              $window.location.href = '/users/sign_in';
            }

            return response || $q.when(response);
          },

          responseError: function resp(rejection) {
            if (rejection.status === 401 && rejection.status === 403) {
              // eslint-disable-next-line no-console
              console.log(`Response: ${rejection.status}`);
              // eslint-disable-next-line no-param-reassign
              $window.location.href = '/users/sign_in';
            }

            return $q.reject(rejection);
          },
        };
      },
    ]);
  },
]);

(function () {
  const app = angular.module('app');

  app.directive('autofocus', [
    function () {
      return {
        restrict: 'A',
        link: function (scope, element) {
          element.focus();
        },
      };
    },
  ]);
})();

import * as React from 'react';

export const Size: React.FC<{
  classification: {
    length: number;
    width: number;
    height: number;
  };
}> = ({ classification }) => (
  <>
    {classification.width}′ × {classification.length}′ × {classification.height}′
  </>
);

import React, { useRef, useState } from 'react';

import {
  Estimation__CategoryType,
  Estimation__OrderItemEstimation,
  OrderStateEnum,
  OrderTypeEnum,
  Status,
  useEstimationEstimatedItemsCreateMutation,
  useEstimationsForOrderQuery,
} from '@admin/schema';
import { client } from '@admin/libraries/apollo';
import { Spinner } from '@admin/components/spinner';
import { Box } from '@clutter/clean';
import styled from '@emotion/styled';
import { Alert, Button } from '@shared/components/bootstrap';
import { capitalize } from 'lodash';
import { ApolloError } from '@apollo/client';
import Select, { SingleValue } from 'react-select';

const TABLE_MAX_WIDTH = '900px';
const StyledData = styled.td<{ selected: boolean }>`
  font-weight: ${({ selected }) => (selected ? 'bold' : 'medium')};
  text-align: left;
`;

const Buttons = styled.div`
  display: flex;
  gap: 8px;
  padding-top: 20px;
  padding-bottom: 8px;
`;

const AlertContainer = styled(Box)`
  max-width: ${TABLE_MAX_WIDTH};
`;

const cuftTotalDisplay = (cuft?: number | null, quantity?: number | null) => {
  if (!cuft || !quantity || quantity < 1) {
    return '-';
  } else {
    return (cuft * quantity).toFixed(2);
  }
};

type RangeCategory = {
  id: string;
  name: string;
  defaultCuft?: number | null;
};

type AgentInputCategory = {
  categoryID: string;
  name: string;
  quantity: number;
  cuft?: number | null;
};

type QuantityRange = {
  lower: number;
  upper: number;
};

type RangeCustomerEstimation = Pick<Estimation__OrderItemEstimation, 'categoryID' | 'name' | 'cuft'>;

type Option = {
  value: string;
  label: string;
  cuft?: number | null;
};

const getQuantityRange = (name?: string): QuantityRange => {
  if (!name || name === 'None') {
    return { lower: 0, upper: 0 };
  } else if (name.includes('-')) {
    const range = name.split('-');
    const lowerRange = +range[0];
    const upperRange = +range[1];
    return { lower: isNaN(lowerRange) ? 0 : lowerRange, upper: isNaN(upperRange) ? 0 : upperRange };
  } else {
    return { lower: isNaN(+name) ? 0 : +name, upper: isNaN(+name) ? 0 : +name };
  }
};

const quantityTotalDisplay = (currentQuantity: number, boxName?: string, otherItemName?: string) => {
  const boxesRange = getQuantityRange(boxName);
  const otherItemsRange = getQuantityRange(otherItemName);
  const lowerTotal = boxesRange.lower + otherItemsRange.lower + currentQuantity;
  const upperTotal = boxesRange.upper + otherItemsRange.upper + currentQuantity;
  if (lowerTotal === upperTotal) return lowerTotal;
  else return `${lowerTotal} - ${upperTotal}`;
};

const convertSelectionToInput = (category: RangeCategory): AgentInputCategory => {
  const { id, name, defaultCuft } = category;
  return { name, categoryID: id, cuft: defaultCuft, quantity: 1 };
};

const buildAgentSelections = (itemEstimations: Estimation__OrderItemEstimation[]) =>
  itemEstimations.reduce((obj, estimation) => {
    const { categoryID, name, agentQuantity, cuft } = estimation;
    if (agentQuantity > 0) obj.set(estimation.categoryID, { categoryID, name, cuft, quantity: agentQuantity });
    return obj;
  }, new Map<string, AgentInputCategory>());

const DropdownItemEstimationRow: React.FC<{
  categoryName: string;
  editing: boolean;
  categories: Estimation__CategoryType[];
  agentSelection?: AgentInputCategory | null;
  customerSelection?: RangeCustomerEstimation | null;
  setSelection: React.Dispatch<React.SetStateAction<AgentInputCategory | null | undefined>>;
}> = ({ categoryName, editing, categories, agentSelection, customerSelection, setSelection }) => {
  const hasAgentSelection = agentSelection !== undefined;
  const hasCustomerSelection = customerSelection
    ? customerSelection.name !== 'None' && customerSelection.name !== '0'
    : false;

  return (
    <tr>
      <StyledData selected={hasAgentSelection || hasCustomerSelection}>{categoryName}</StyledData>
      <StyledData selected={hasCustomerSelection}>{hasCustomerSelection ? customerSelection?.cuft : '-'}</StyledData>
      <StyledData selected={hasCustomerSelection}>{hasCustomerSelection ? customerSelection?.name : '-'}</StyledData>
      <StyledData selected={hasAgentSelection}>{agentSelection?.cuft ?? '-'}</StyledData>
      <StyledData selected={hasAgentSelection}>
        {editing ? (
          <select
            className="form-control"
            value={agentSelection?.categoryID ?? ''}
            onChange={(event) => {
              const selectedCategory = categories.find((category) => category.id === event.target.value);
              setSelection(selectedCategory ? convertSelectionToInput(selectedCategory) : undefined);
            }}
          >
            <option label="-" value="">
              '-'
            </option>
            {categories.map(({ id, name }) => (
              <option key={id} label={name} value={id}>
                {name}
              </option>
            ))}
          </select>
        ) : (
          <span>{agentSelection?.name ?? '-'}</span>
        )}
      </StyledData>
    </tr>
  );
};

const ItemEstimationRow: React.FC<{
  itemEstimation: Estimation__OrderItemEstimation;
  agentSelections: Map<string, AgentInputCategory>;
  editing: boolean;
  setAgentSelections: React.Dispatch<React.SetStateAction<Map<string, AgentInputCategory> | undefined>>;
}> = ({ itemEstimation, agentSelections, editing, setAgentSelections }) => {
  const { categoryID, name, agentQuantity, customerQuantity, cuft } = itemEstimation;
  const agentSelection = agentSelections?.get(categoryID);
  const agentSelected = !!((agentQuantity && agentQuantity > 0) || (agentSelection && agentSelection.quantity > 0));
  const customerSelected = !!(customerQuantity && customerQuantity > 0);
  const rowSelected = agentSelected || customerSelected;
  if (!rowSelected) return null;

  const onItemEstimationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (agentSelections) {
      const quantity = parseInt(event.target.value);
      if (isNaN(quantity)) {
        const selections = agentSelections;
        selections.delete(categoryID);
        setAgentSelections(new Map(selections));
      } else {
        setAgentSelections(
          new Map(
            agentSelections.set(categoryID, {
              name,
              categoryID,
              cuft,
              ...agentSelection,
              quantity,
            }),
          ),
        );
      }
    }
  };

  return (
    <tr key={categoryID}>
      <StyledData selected={!!rowSelected}>{name}</StyledData>
      <StyledData selected={customerSelected}>{cuftTotalDisplay(cuft, customerQuantity)}</StyledData>
      <StyledData selected={customerSelected}>{customerSelected ? customerQuantity : '-'}</StyledData>
      <StyledData selected={agentSelected}>
        {cuftTotalDisplay(agentSelection?.cuft, agentSelection?.quantity)}
      </StyledData>
      <StyledData selected={agentSelected}>
        {editing ? (
          <input
            value={agentSelection?.quantity ?? ''}
            placeholder="-"
            type="number"
            onChange={onItemEstimationChange}
          />
        ) : (
          <span>{agentSelection?.quantity ?? '-'}</span>
        )}
      </StyledData>
    </tr>
  );
};

const NewEstimatedItemRow: React.FC<{
  itemEstimations?: Estimation__OrderItemEstimation[];
  agentSelections?: Map<string, AgentInputCategory>;
  setAgentSelections(selections: Map<string, AgentInputCategory>): void;
  handleScroll(): void;
}> = ({ itemEstimations, agentSelections, setAgentSelections, handleScroll }) => {
  const [newSelection, setNewSelection] = useState<AgentInputCategory>();
  const [selectedOption, setSelectedOption] = useState<SingleValue<Option>>();

  const options: Option[] = Array.from(itemEstimations || [])
    .filter((selection) => selection.agentQuantity === 0 && selection.customerQuantity === 0)
    .map((selection) => ({
      value: selection.categoryID,
      label: selection.name,
      cuft: selection.cuft,
    }));

  return (
    <tr>
      <StyledData colSpan={2} selected={false}>
        <span onClick={() => handleScroll()}>
          <Select
            value={selectedOption}
            onChange={(option: SingleValue<Option>) => {
              setSelectedOption(option ?? undefined);
              const { value, label, cuft } = option as Option;
              setNewSelection(agentSelections?.get(value) ?? { categoryID: value, name: label, cuft, quantity: 0 });
            }}
            options={options}
          />
        </span>
      </StyledData>
      <StyledData selected={false}>-</StyledData>
      <StyledData selected>{cuftTotalDisplay(newSelection?.cuft, newSelection?.quantity)}</StyledData>
      <StyledData selected>
        <Box.Flex justifyContent="space-between" alignContent="row">
          <input
            value={newSelection?.quantity ?? ''}
            placeholder="-"
            type="number"
            onChange={(event) => {
              if (agentSelections && newSelection) {
                const quantity = parseInt(event.target.value);
                setNewSelection((current) => {
                  if (current) return { ...current, quantity: isNaN(quantity) ? 0 : quantity };
                });
              }
            }}
          />
          <Button
            kind="primary"
            onClick={() => {
              if (agentSelections && newSelection) {
                setAgentSelections(new Map(agentSelections.set(newSelection.categoryID, newSelection)));
                setNewSelection(undefined);
                setSelectedOption(undefined);
              }
            }}
          >
            Add
          </Button>
        </Box.Flex>
      </StyledData>
    </tr>
  );
};

export const OrderPanel: React.FC<{ orderID: string; algorithmID: string; isVirtualWalkthrough: boolean }> = ({
  orderID,
  algorithmID,
  isVirtualWalkthrough,
}) => {
  const [error, setError] = useState<string>();
  const [editing, setEditing] = useState<boolean>(false);
  const [agentSelections, setAgentSelections] = useState<Map<string, AgentInputCategory>>();
  const [agentBoxSelection, setAgentBoxSelection] = useState<AgentInputCategory | null>();
  const [agentOtherItemSelection, setAgentOtherItemSelection] = useState<AgentInputCategory | null>();
  const [estimatedItemsCreate, { loading }] = useEstimationEstimatedItemsCreateMutation({ client });
  const { data } = useEstimationsForOrderQuery({
    client,
    skip: !algorithmID,
    variables: { orderID, algorithmID: algorithmID! },
    onCompleted: (estimationsForOrderData) => {
      if (estimationsForOrderData) {
        const { itemEstimations, agentBoxEstimation, agentOtherItemEstimation } =
          estimationsForOrderData.estimationsForOrder;
        if (itemEstimations) {
          setAgentSelections(buildAgentSelections(itemEstimations));
        }
        if (agentBoxEstimation) {
          setAgentBoxSelection(agentBoxEstimation ? { ...agentBoxEstimation, quantity: 1 } : null);
        }
        if (agentOtherItemEstimation) {
          setAgentOtherItemSelection(agentOtherItemEstimation ? { ...agentOtherItemEstimation, quantity: 1 } : null);
        }
      }
    },
  });

  const scrollDivRef = useRef<HTMLDivElement>(null);

  if (!data) {
    return <Spinner />;
  }

  const {
    estimationsForOrder: {
      agentBoxEstimation,
      customerBoxEstimation,
      itemEstimations,
      agentOtherItemEstimation,
      customerOtherItemEstimation,
      roomEstimations,
      allItemsInListedRooms,
      itemsRequireMoreThanOneMover,
    },
    boxCategories,
    otherItemCategories,
    order,
  } = data;

  const canEdit = order.type !== OrderTypeEnum.Move && order.state === OrderStateEnum.Approved;

  const agentSelectedItemCount = () =>
    Array.from(agentSelections?.values() ?? []).reduce((total, item) => {
      total += item.quantity;
      return total;
    }, 0);

  const agentTotalItemCuft = () => {
    let cuftTotal = Array.from(agentSelections?.values() ?? []).reduce((total, item) => {
      total += item.quantity * (item.cuft ?? 0);
      return total;
    }, 0);
    cuftTotal += agentOtherItemSelection?.cuft ?? 0;
    cuftTotal += agentBoxSelection?.cuft ?? 0;
    return cuftTotal;
  };

  let customerTotalItems = 0;
  let customerTotalItemCuft = 0;
  itemEstimations.forEach(({ customerQuantity, cuft }) => {
    if (customerQuantity && customerQuantity > 0) {
      customerTotalItems += customerQuantity;
      customerTotalItemCuft += customerQuantity * (cuft ?? 0);
    }
  });
  customerTotalItemCuft += customerBoxEstimation?.cuft ?? 0;
  customerTotalItemCuft += customerOtherItemEstimation?.cuft ?? 0;
  let requireMoreThanOneMoverAnswer = 'Not Answered';
  if (itemsRequireMoreThanOneMover) {
    requireMoreThanOneMoverAnswer = itemsRequireMoreThanOneMover.split('_').map(capitalize).join(' ');
  }

  const handleCancel = () => {
    setAgentSelections(buildAgentSelections(itemEstimations));
    setAgentBoxSelection(agentBoxEstimation ? { ...agentBoxEstimation, quantity: 1 } : null);
    setAgentOtherItemSelection(agentOtherItemEstimation ? { ...agentOtherItemEstimation, quantity: 1 } : null);
    setEditing(false);
  };

  const handleSave = async () => {
    try {
      const response = await estimatedItemsCreate({
        variables: {
          input: {
            orderID,
            algorithmID,
            otherItemCategoryName: agentOtherItemSelection?.name,
            boxCategoryName: agentBoxSelection?.name,
            itemInputs: Array.from(agentSelections?.values() ?? [])
              .filter(({ quantity }) => quantity > 0)
              .map(({ categoryID, quantity }) => ({
                categoryID,
                quantity,
              })),
          },
        },
      });

      if (response.data?.result?.error) {
        setError(response.data.result.error);
      }

      if (response.data?.result?.status === Status.Ok) {
        setError(undefined);
        setEditing(false);
      }
    } catch (e: any) {
      const message = (e as ApolloError).message || 'An unknown error occurred. Please contact technical support.';
      setError(message);
    }
  };

  const scrollToBottom = () => {
    if (scrollDivRef.current) {
      scrollDivRef.current.scrollTop = scrollDivRef.current.scrollHeight;
    }
  };

  return (
    <>
      <Box.Flex justifyContent="space-between" alignContent="row" maxWidth={TABLE_MAX_WIDTH}>
        <h3 className="text-thin">Item Selection</h3>
        <Buttons>
          {editing ? (
            <>
              <Button kind="danger" loading={loading} onClick={handleCancel}>
                Cancel
              </Button>
              <Button kind="primary" loading={loading} onClick={handleSave}>
                Save
              </Button>
            </>
          ) : (
            canEdit && (
              <Button kind="primary" onClick={() => setEditing(true)}>
                Edit
              </Button>
            )
          )}
        </Buttons>
      </Box.Flex>
      {error && (
        <AlertContainer>
          <Alert style="danger">{error}</Alert>
        </AlertContainer>
      )}
      <div
        ref={scrollDivRef}
        className="table-responsive"
        style={{ maxWidth: TABLE_MAX_WIDTH, maxHeight: '500px', overflowY: 'scroll', overscrollBehavior: 'contain' }}
      >
        <table className="table table-striped">
          <thead>
            <tr key="header">
              <th className="text-left">Name</th>
              <th className="text-left">Customer Estimated Cuft</th>
              <th className="text-left">Customer Quantity</th>
              <th className="text-left">Agent Estimated Cuft</th>
              <th className="text-left">Agent Quantity</th>
            </tr>
          </thead>
          <tbody>
            {(agentBoxEstimation || customerBoxEstimation) && boxCategories.length > 0 && (
              <DropdownItemEstimationRow
                categoryName="Boxes"
                agentSelection={agentBoxSelection}
                customerSelection={customerBoxEstimation}
                editing={editing}
                categories={boxCategories}
                setSelection={setAgentBoxSelection}
              />
            )}
            {agentSelections &&
              itemEstimations.map((option) => (
                <ItemEstimationRow
                  key={option.categoryID}
                  itemEstimation={option}
                  agentSelections={agentSelections}
                  editing={editing}
                  setAgentSelections={setAgentSelections}
                />
              ))}
            {otherItemCategories.length > 0 && (
              <DropdownItemEstimationRow
                categoryName="Other"
                agentSelection={agentOtherItemSelection}
                customerSelection={customerOtherItemEstimation}
                editing={editing}
                categories={otherItemCategories}
                setSelection={setAgentOtherItemSelection}
              />
            )}
            {editing && (
              <NewEstimatedItemRow
                itemEstimations={itemEstimations}
                agentSelections={agentSelections}
                setAgentSelections={setAgentSelections}
                handleScroll={scrollToBottom}
              />
            )}
            <tr>
              <td className="text-left">
                <strong>Total</strong>
              </td>
              <td className="text-left">
                <strong>{customerTotalItemCuft.toFixed(2)}</strong>
              </td>
              <td className="text-left">
                <strong>
                  {quantityTotalDisplay(
                    customerTotalItems,
                    customerBoxEstimation?.name,
                    customerOtherItemEstimation?.name,
                  )}
                </strong>
              </td>
              <td className="text-left">
                <strong>{agentTotalItemCuft().toFixed(2)}</strong>
              </td>
              <td className="text-left">
                <strong>
                  {quantityTotalDisplay(
                    agentSelectedItemCount(),
                    agentBoxSelection?.name,
                    agentOtherItemSelection?.name,
                  )}
                </strong>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <hr />
      <p>
        Do any of your items require more than 1 person to carry?&nbsp;
        <strong>{requireMoreThanOneMoverAnswer}</strong>
      </p>
      {!isVirtualWalkthrough && (
        <>
          <hr />
          <h3 className="text-thin">Room Selection</h3>
          <div className="table-responsive" style={{ maxWidth: '400px' }}>
            <table className="table table-striped">
              <thead>
                <tr key="header">
                  <th className="text-left">Name</th>
                  <th className="text-left">Quantity</th>
                </tr>
              </thead>
              <tbody>
                {roomEstimations.map((option) => (
                  <tr key={option.categoryID}>
                    <StyledData selected={option.quantity > 0}>{option.name}</StyledData>
                    <StyledData selected={option.quantity > 0}>{option.quantity}</StyledData>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <p>
            Do the rooms above contain all the items that you plan to store?&nbsp;
            <strong>{allItemsInListedRooms ? 'Yes' : 'No'}</strong>
          </p>
        </>
      )}
    </>
  );
};

import * as React from 'react';
import { useState } from 'react';

import moment from 'moment-timezone';

import { PalletGradeFragment } from '@admin/schema';
import { Timestamp } from '@admin/components/timestamp';

import { ILightboxCarouselInput, LightboxWithCarousel } from '@shared/components/helpers/lightbox';

const DATETIME_FORMAT = 'MMM D, YYYY h:mm:ss A z';

const convertDateTime = (datetime: string, timezone: string) => moment(datetime).tz(timezone).format(DATETIME_FORMAT);

export const PalletGradeImages: React.FC<{
  palletGrades: PalletGradeFragment[];
  timezone: string;
}> = ({ palletGrades, timezone }) => {
  const [selection, setSelection] = useState<ILightboxCarouselInput[] | undefined>(undefined);
  return (
    <div>
      {palletGrades
        .filter((grade) => grade.image)
        .map((grade) => (
          <div key={grade.image!.id} className="col-xs-6 col-md-4 col-lg-2">
            {grade.createdAt && (
              <span>
                {grade.user.name} graded ({grade.id}) at{' '}
                <Timestamp value={grade.createdAt} format="medium" timezone={timezone} />
              </span>
            )}

            <figure className="brick-square">
              <a
                href="#"
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  const images = [
                    {
                      src: grade.image!.source,
                      caption: `Wrapped Image taken at ${convertDateTime(grade.image!.createdAt, timezone)}`,
                    },
                  ];
                  if (grade.frontImage) {
                    images.push({
                      src: grade.frontImage.source,
                      caption: `Front Image taken at ${convertDateTime(grade.frontImage.createdAt, timezone)}`,
                    });
                  }

                  if (grade.backImage) {
                    images.push({
                      src: grade.backImage.source,
                      caption: `Back Image taken at ${convertDateTime(grade.backImage.createdAt, timezone)}`,
                    });
                  }

                  setSelection(images);
                }}
              >
                <img className="location-image" src={grade.image!.size} />
              </a>
            </figure>
          </div>
        ))}
      {selection && <LightboxWithCarousel imageInputs={selection} onClose={() => setSelection(undefined)} />}
    </div>
  );
};

import wt from '@clutter/wt';

(function () {
  const app = angular.module('app:run', []);

  app.run([
    '$anchorScroll',
    '$rootScope',
    '$transitions',
    'UserService',
    'Page',
    function ($anchorScroll, $rootScope, $transitions, UserService, Page) {
      $rootScope.user = UserService;

      $transitions.onStart({}, function (transition) {
        const toState = transition.to();
        $anchorScroll();

        if (toState.resolve) {
          $rootScope.loading = true;
        } else {
          $rootScope.loading = false;
        }
        Page.reset({ title: toState.data.title });
      });

      $transitions.onSuccess({}, function (transition) {
        const toState = transition.to();
        if (toState.resolve) {
          $rootScope.loading = false;
        }
        setTimeout(() => {
          wt.track('pageview');
        });
      });
    },
  ]);
})();

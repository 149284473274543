import { OrderSubtypeEnum as Order__Subtype } from '@admin/schema';

export const ORDER_SUBTYPES: Order__Subtype[] = [
  Order__Subtype.Onboarding,
  Order__Subtype.Subsequent,
  Order__Subtype.Final,
  Order__Subtype.None,
  Order__Subtype.Packing,
  Order__Subtype.Unpacking,
  Order__Subtype.Travel,
];

export const ORDER_SUBTYPE_TO_NAME: Record<Order__Subtype, string> = {
  [Order__Subtype.Onboarding]: 'Onboarding',
  [Order__Subtype.Subsequent]: 'Subsequent',
  [Order__Subtype.Final]: 'Final',
  [Order__Subtype.None]: 'None',
  [Order__Subtype.Packing]: 'Packing',
  [Order__Subtype.Unpacking]: 'Unpacking',
  [Order__Subtype.Travel]: 'Travel',
  [Order__Subtype.Cancelation]: 'Cancelation',
  [Order__Subtype.SupplyKit]: 'Supply Kit',
};

export type OrderSubtypeParam =
  | 'onboarding'
  | 'subsequent'
  | 'final'
  | 'none'
  | 'packing'
  | 'unpacking'
  | 'travel'
  | 'cancelation'
  | 'supply-kit';

export const ORDER_SUBTYPE_TO_PARAM: Record<Order__Subtype, OrderSubtypeParam> = {
  [Order__Subtype.Onboarding]: 'onboarding',
  [Order__Subtype.Subsequent]: 'subsequent',
  [Order__Subtype.Final]: 'final',
  [Order__Subtype.None]: 'none',
  [Order__Subtype.Packing]: 'packing',
  [Order__Subtype.Unpacking]: 'unpacking',
  [Order__Subtype.Travel]: 'travel',
  [Order__Subtype.Cancelation]: 'cancelation',
  [Order__Subtype.SupplyKit]: 'supply-kit',
};

export const ORDER_SUBTYPE_FROM_PARAM: Record<OrderSubtypeParam, Order__Subtype> = {
  onboarding: Order__Subtype.Onboarding,
  subsequent: Order__Subtype.Subsequent,
  final: Order__Subtype.Final,
  none: Order__Subtype.None,
  packing: Order__Subtype.Packing,
  unpacking: Order__Subtype.Unpacking,
  travel: Order__Subtype.Travel,
  cancelation: Order__Subtype.Cancelation,
  'supply-kit': Order__Subtype.SupplyKit,
};

(function () {
  angular.module('app').factory('Schedule', [
    '$resource',
    function ($resource) {
      return $resource(
        '/schedule.json',
        {
          date: '@date',
        },
        {
          show: {
            method: 'GET',
            isArray: false,
          },
        },
      );
    },
  ]);
})();

import { Label } from '@admin/components/helpers/label';
import { Titleize } from '@admin/components/helpers/titleize';
import { SelfStorage__Status as SelfStorage__Rental__Status } from '@admin/schema';

import * as React from 'react';

const style = (status: SelfStorage__Rental__Status) => {
  switch (status) {
    case SelfStorage__Rental__Status.Active:
      return 'success';
    case SelfStorage__Rental__Status.Canceled:
      return 'warning';
    case SelfStorage__Rental__Status.Pending:
      return 'info';
    case SelfStorage__Rental__Status.Canceling:
      return 'warning';
  }
};

export const Status: React.FC<{ status: SelfStorage__Rental__Status }> = ({ status }) => (
  <Label kind={style(status)}>
    <Titleize text={status} />
  </Label>
);

(function () {
  angular.module('app').factory('DispatchDeserializer', [
    function () {
      return function (data) {
        if (data.arrival) {
          data.arrival = new Date(data.arrival);
        }

        return data;
      };
    },
  ]);
})();

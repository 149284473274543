import template from '@admin/angular/templates/images_inputted.html';

import angular from 'angular';

const app = angular.module('app');

function controller() {
  this.unattach = (upload) => _.remove(this.uploads, upload);
}

app.component('imagesInputted', {
  template,
  bindings: {
    uploads: '<',
  },
  controller,
});

(function () {
  const app = angular.module('app');

  app.controller('AffiliatesController', [
    '$rootScope',
    '$location',
    '$scope',
    'Affiliate',
    'FiltersService',
    function ($rootScope, $location, $scope, Affiliate, FiltersService) {
      $scope.filters = $location.search();

      $scope.filter = function (filters) {
        if (!filters) {
          filters = $scope.filters || {};
        }

        filters = FiltersService.cleanup(filters);
        $location.search(filters);

        Affiliate.query(filters).$promise.then(function (data) {
          $scope.affiliates = data;
        });
      };

      $scope.filter();

      $scope.delete = function (id) {
        Affiliate.delete(
          { id: id },
          function () {
            $scope.filter();
          },
          function (error) {
            $rootScope.$broadcast('message:group:display', error.data);
          },
        );
      };
    },
  ]);

  app.controller('AffiliateNewController', [
    '$rootScope',
    '$scope',
    '$state',
    'Affiliate',
    'affiliateKinds',
    function ($rootScope, $scope, $state, Affiliate, affiliateKinds) {
      $scope.affiliate = new Affiliate();
      $scope.affiliate.address = {};
      $scope.kinds = affiliateKinds;
      $scope.affiliate.kind = 'Affiliate';

      $scope.addAffiliate = function () {
        const affiliate = $scope.affiliate;
        affiliate.$save(
          function () {
            $state.go('affiliates');
          },
          function (error) {
            $rootScope.$broadcast('message:group:display', error.data);
          },
        );
      };
    },
  ]);

  app.controller('AffiliateEditController', [
    '$rootScope',
    '$scope',
    '$state',
    'affiliate',
    'affiliateKinds',
    function ($rootScope, $scope, $state, affiliate, affiliateKinds) {
      $scope.kinds = affiliateKinds;
      $scope.affiliate = affiliate;

      $scope.editAffiliate = function () {
        $scope.affiliate.$update(
          function () {
            $state.go('affiliates');
          },
          function (error) {
            $rootScope.$broadcast('message:group:display', error.data);
          },
        );
      };
    },
  ]);

  app.controller('AffiliateShowController', [
    '$scope',
    'affiliate',
    'affiliateInvoices',
    function ($scope, affiliate, affiliateInvoices) {
      $scope.affiliate = affiliate;
      $scope.affiliateInvoices = affiliateInvoices;
    },
  ]);
})();

import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { DateTime } from 'luxon';

import { Workforce__WorkBreakInput } from '@admin/schema';
import { COLORS } from '@clutter/clean';

import { DateTimePicker } from './date_time_picker';
import { Field } from './field';

export interface IWorkBreakInput extends Workforce__WorkBreakInput {
  key: string;
}

export const WorkBreakFields: React.FC<{
  index: number;
  workBreakInput: Workforce__WorkBreakInput;
  tz: string;
  removeWorkBreak(index: number): void;
  updateWorkBreak(index: number, workBreak: Workforce__WorkBreakInput): void;
}> = ({ index, workBreakInput, tz, removeWorkBreak, updateWorkBreak }) => {
  const { register, unregister } = useFormContext<Workforce__WorkBreakInput>();
  useEffect(() => {
    register({ name: `workBreaks.${index}.id` });
    register({ name: `workBreaks.${index}.started` });
    register({ name: `workBreaks.${index}.ended` });

    return () => {
      unregister(`workBreaks.${index}.id`);
      unregister(`workBreaks.${index}.started`);
      unregister(`workBreaks.${index}.ended`);
    };
  }, [register, index]);

  return (
    <>
      <Field
        id={`break_${index}_start`}
        label={`Break ${index + 1} Start`}
        actionLabel="REMOVE"
        actionColor={COLORS.toucan}
        action={() => removeWorkBreak(index)}
      >
        <DateTimePicker
          dt={DateTime.fromISO(workBreakInput.started || '').setZone(tz)}
          onChange={(newDateTime) => {
            updateWorkBreak(index, { ...workBreakInput, started: newDateTime?.toString() });
          }}
          tz={tz}
        />
      </Field>
      <Field id={`break_${index}_end`} label={`Break ${index + 1} End`}>
        <DateTimePicker
          dt={DateTime.fromISO(workBreakInput.ended || '').setZone(tz)}
          onChange={(newDateTime) => {
            updateWorkBreak(index, { ...workBreakInput, ended: newDateTime?.toString() });
          }}
          tz={tz}
        />
      </Field>
    </>
  );
};

import { SelfStorage__FeeFragment, SelfStorage__Fee__Kind } from '@admin/schema';
import React from 'react';

const getFeeName = (fee: SelfStorage__FeeFragment) => {
  switch (fee.kind) {
    case SelfStorage__Fee__Kind.Admin:
      return 'Admin Fee';
    case SelfStorage__Fee__Kind.Convenience:
      return 'Convenience';
    case SelfStorage__Fee__Kind.Lock:
      return 'Lock Fee';
    case SelfStorage__Fee__Kind.Electricity:
      return 'Electricity Fee';
    case SelfStorage__Fee__Kind.UnlimitedAccess:
      return '24-Hour Access';
    case SelfStorage__Fee__Kind.ExtendedAccess:
      return 'Extended Hours Access';
  }
};

export const Name: React.FC<{ fee: SelfStorage__FeeFragment }> = ({ fee }) => {
  const feeName = getFeeName(fee);

  return <strong>{feeName}</strong>;
};

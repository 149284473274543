(function () {
  angular.module('app').filter('address', function () {
    return function (address) {
      let string = _.compact([address.street, address.aptsuite]).join(' ');
      if (address.city) {
        string += ', ' + address.city;
      }
      if (address.neighborhood) {
        string += ' (' + address.neighborhood + ')';
      }
      if (address.state) {
        string += ', ' + address.state;
      }
      if (address.zip) {
        string += ' ' + address.zip;
      }
      return string;
    };
  });
})();

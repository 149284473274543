import * as React from 'react';
import styled from '@emotion/styled';

import { DateTime } from 'luxon';

import { Tooltip, TooltipPlacement } from '@shared/components/bootstrap/tooltip';
import { useScale } from './context';
import { GanttEvent } from './types';

const format = (date: DateTime) => date.toFormat('t');

const Container = styled.div<{ left: number; width: number }>`
  position: absolute;
  left: ${({ left }) => `${left}%`};
  width: ${({ width }) => `${width}%`};
`;

const Bar = styled.div<{ color: string }>`
  background-color: ${({ color }) => color};
  height: 1rem;
`;

const Subtitle = styled.div`
  position: relative;
  left: -35px;
  max-width: 70px;
  font-size: 10px;
  text-align: center;
`;

export const Event: React.FC<{
  event: GanttEvent;
  placement?: TooltipPlacement;
}> = ({ event, placement }) => {
  const { startTimestamp, endTimestamp, name, color } = event;
  const scale = useScale();
  const left = scale(startTimestamp);
  let title = `${name}: ${format(startTimestamp)} - ${format(endTimestamp)}`;
  if (event.details) {
    title = `${title} (${event.details})`;
  }
  const width = scale(endTimestamp) - scale(startTimestamp);

  return (
    <Container left={left} width={width}>
      <Tooltip placement={placement} title={title}>
        <Bar color={color} />
        {event.subtitle && <Subtitle>{event.subtitle}</Subtitle>}
      </Tooltip>
    </Container>
  );
};

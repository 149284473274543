import * as React from 'react';

import { SelfStorage__FeeFragment, SelfStorage__Fee__Kind } from '@admin/schema';

export const Summary: React.FC<{ fee: SelfStorage__FeeFragment }> = ({ fee }) => {
  switch (fee.kind) {
    case SelfStorage__Fee__Kind.Convenience:
      return <>Package Acceptance</>;
    case SelfStorage__Fee__Kind.Admin:
      return <>Admin Fee</>;
    case SelfStorage__Fee__Kind.Lock:
      return <>Lock Fee</>;
    case SelfStorage__Fee__Kind.Electricity:
      return <>Electricity</>;
    case SelfStorage__Fee__Kind.UnlimitedAccess:
      return <>24-Hour Access</>;
    case SelfStorage__Fee__Kind.ExtendedAccess:
      return <>Extended Hours</>;
  }
};

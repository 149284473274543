import { OrderServiceTypeEnum as Order__Service } from '@admin/schema';

export const ORDER_SERVICES = [
  Order__Service.FullService,
  Order__Service.CurbsidePickup,
  Order__Service.Doorstep,
  Order__Service.Auction,
  Order__Service.Disposal,
  Order__Service.ThirdPartyReturn,
  Order__Service.DropOff,
  Order__Service.Capsule,
  Order__Service.Shipment,
  Order__Service.Facility,
  Order__Service.LongDistance,
];

export const ORDER_SERVICE_TO_STATUS: Record<Order__Service, 'active' | 'legacy'> = {
  [Order__Service.FullService]: 'active',
  [Order__Service.Shipment]: 'active',
  [Order__Service.Facility]: 'active',
  [Order__Service.LongDistance]: 'active',
  [Order__Service.Auction]: 'active',
  [Order__Service.Disposal]: 'legacy',
  [Order__Service.CurbsidePickup]: 'legacy',
  [Order__Service.Doorstep]: 'legacy',
  [Order__Service.ThirdPartyReturn]: 'legacy',
  [Order__Service.DropOff]: 'legacy',
  [Order__Service.Capsule]: 'legacy',
};

export const ORDER_SERVICE_TO_NAME: Record<Order__Service, string> = {
  [Order__Service.FullService]: 'Full Service',
  [Order__Service.CurbsidePickup]: 'Curbside Pickup',
  [Order__Service.Doorstep]: 'Doorstep',
  [Order__Service.Auction]: 'Auction',
  [Order__Service.Disposal]: 'Disposal',
  [Order__Service.ThirdPartyReturn]: 'Third Party Return',
  [Order__Service.DropOff]: 'Drop-Off',
  [Order__Service.Capsule]: 'Capsule',
  [Order__Service.Shipment]: 'Shipment',
  [Order__Service.Facility]: 'Facility',
  [Order__Service.LongDistance]: 'Long-Distance',
};

export type OrderServiceParam =
  | 'auction'
  | 'capsule'
  | 'disposal'
  | 'doorstep'
  | 'shipment'
  | 'curbside-pickup'
  | 'full-service'
  | 'third-party-return'
  | 'drop-off'
  | 'facility'
  | 'long-distance';

export const ORDER_SERVICE_TO_PARAM: Record<Order__Service, OrderServiceParam> = {
  [Order__Service.Auction]: 'auction',
  [Order__Service.Capsule]: 'capsule',
  [Order__Service.Disposal]: 'disposal',
  [Order__Service.Doorstep]: 'doorstep',
  [Order__Service.Shipment]: 'shipment',
  [Order__Service.CurbsidePickup]: 'curbside-pickup',
  [Order__Service.FullService]: 'full-service',
  [Order__Service.ThirdPartyReturn]: 'third-party-return',
  [Order__Service.DropOff]: 'drop-off',
  [Order__Service.Facility]: 'facility',
  [Order__Service.LongDistance]: 'long-distance',
};

export const ORDER_SERVICE_FROM_PARAM: Record<OrderServiceParam, Order__Service> = {
  auction: Order__Service.Auction,
  capsule: Order__Service.Capsule,
  disposal: Order__Service.Disposal,
  doorstep: Order__Service.Doorstep,
  shipment: Order__Service.Shipment,
  facility: Order__Service.Facility,
  'curbside-pickup': Order__Service.CurbsidePickup,
  'full-service': Order__Service.FullService,
  'third-party-return': Order__Service.ThirdPartyReturn,
  'drop-off': Order__Service.DropOff,
  'long-distance': Order__Service.LongDistance,
};

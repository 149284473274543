import { DateTime } from 'luxon';
import React, { useState } from 'react';

import { PageHeader } from '@admin/components/helpers/page_header';
import {
  Availability__LaborAdjustmentFiltersInput,
  AvailabilityLaborAdjustmentsDocument,
  useAvailabilityLaborAdjustmentsDeleteMutation,
  useAvailabilityLaborAdjustmentsQuery,
} from '@admin/schema';
import { Alert, Breadcrumb, Panel } from '@shared/components/bootstrap';

import { client } from '@admin/libraries/apollo';
import { Spinner } from '@admin/components/spinner';
import { Pagination } from '@admin/components/pagination';
import { AvailabilityControls } from '@admin/components/availability/controls';
import { availabilityLaborAdjustmentNewURL } from '@admin/config/routes';
import { Entries } from './entries';
import { Filters } from './filters';

const DEFAULT_FILTERS: Availability__LaborAdjustmentFiltersInput = {
  fromDate: DateTime.local().toISODate(),
  tillDate: DateTime.local().plus({ days: 30 }).toISODate(),
  regionIDs: [],
  orderClassifications: [],
};

export const List: React.FC = () => {
  const [filters, setFilters] = useState<Availability__LaborAdjustmentFiltersInput>(DEFAULT_FILTERS);
  const [page, setPage] = useState<number>();
  const [selectedIDs, setSelectedIDs] = useState<string[]>([]);

  const { data, loading: querying } = useAvailabilityLaborAdjustmentsQuery({
    client,
    variables: {
      page,
      filters: {
        regionIDs: filters.regionIDs,
        fromDate: filters.fromDate,
        tillDate: filters.tillDate,
        orderClassifications: filters.orderClassifications,
      },
    },
  });

  const [destroy, { data: destroyData, loading: destroying }] = useAvailabilityLaborAdjustmentsDeleteMutation({
    client,
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: AvailabilityLaborAdjustmentsDocument,
        variables: {
          page,
          filters: {
            regionIDs: filters.regionIDs,
            fromDate: filters.fromDate,
            tillDate: filters.tillDate,
            orderClassifications: filters.orderClassifications,
          },
        },
      },
    ],
    onCompleted: () => setSelectedIDs([]),
  });

  const onDestroy = async () => {
    await destroy({
      variables: {
        IDs: selectedIDs,
      },
    });
  };

  const onPageChange = (pageNumber: number) => {
    setSelectedIDs([]);
    setPage(pageNumber);
  };

  const paginated = data?.paginated;
  const options = paginated?.results.map(({ id }) => id) ?? [];
  const policy = data?.policy;
  const loading = querying || destroying;

  return (
    <>
      <PageHeader>
        <Breadcrumb>
          <Breadcrumb.Item active>Availability Labor Adjustment</Breadcrumb.Item>
        </Breadcrumb>
      </PageHeader>
      <Panel>
        <Panel.Body>
          {destroyData?.result?.error && <Alert style="danger">{destroyData.result.error}</Alert>}
          <Filters filters={filters} onChange={setFilters} />
          <AvailabilityControls
            createHref={availabilityLaborAdjustmentNewURL()}
            loading={loading}
            options={options}
            selectedOptions={selectedIDs}
            create={policy?.create}
            destroy={policy?.destroy}
            onDestroy={onDestroy}
            setSelectedOptions={setSelectedIDs}
          />
          {querying && <Spinner />}
          {paginated && (
            <Entries
              availabilityLaborAdjustments={paginated.results}
              selectedOptions={selectedIDs}
              setSelectedOptions={setSelectedIDs}
            />
          )}
          {paginated && <Pagination onPage={onPageChange} pagination={paginated.pagination} />}
        </Panel.Body>
      </Panel>
    </>
  );
};

const app = angular.module('app');

// common functionality for the form-based controllers
function setupTicketFormController(
  $scope,
  $http,
  $state,
  $q,
  User,
  Ticket,
  Ticketable,
  TicketGroup,
  Warehouse,
  UploaderService,
  ErrorService,
  UserService,
) {
  $scope.subcats = [];
  $scope.ticket.assignable = 'group';

  if ($scope.ticket.$promise) {
    $scope.ticket.$promise.then(() => {
      $scope.ticket.assignable = $scope.ticket.warehouse ? 'warehouse' : 'group';
      if ($scope.ticket.images) {
        $scope.uploads = $scope.ticket.images;
      }
    });
  }

  const now = moment();
  $scope.minimum_time = now.format('HH:mm');
  $scope.minimum_date = now.format('Y-MM-DD');

  $scope.uploads = [];

  $scope.save = function () {
    if ($scope.saving) {
      return;
    }
    UploaderService.uploadFiles($scope.uploads)
      .then(() => {
        const method = $scope.ticket.id ? 'update' : 'create';
        $scope.saving = _.pick($scope.ticket, [
          'id',
          'description',
          'ticketable_id',
          'ticketable_type',
          'due_date',
          'go_live_time',
          'live_now',
          'category',
          'sub_category',
          'notify',
          'business_unit',
          'area',
        ]);

        _.merge($scope.saving, {
          assignee_id: $scope.ticket.assignee ? $scope.ticket.assignee.id : null,
          warehouse_id: $scope.ticket.warehouse ? $scope.ticket.warehouse.id : null,
          group_id: $scope.ticket.group ? $scope.ticket.group.id : null,
          owner_id: $scope.ticket.owner ? $scope.ticket.owner.id : null,
          images_attributes: $scope.uploads.map((upload) => ({ url: upload.url, state: 'uploaded' })),
        });

        return Ticket[method]($scope.saving).$promise;
      })
      .then(() => $state.go('inbox'))
      .catch((error) => {
        delete $scope.saving;
        ErrorService.flash(error);
      });
  };

  $scope.groups = TicketGroup.query({ filter: 'internal' });
  $scope.warehouses = Warehouse.query();

  function assigneesForGroup() {
    return _.find($scope.groups, (group) => group.id === $scope.ticket.group.id).users;
  }

  function assigneesForWarehouse() {
    return _.filter(
      $scope.users,
      (user) =>
        _.includes(user.roles, 'warehouse') &&
        _.some(user.region_ids, (id) => _.includes($scope.ticket.warehouse.region_ids, id)),
    );
  }

  $scope.reassign = function () {
    if ($scope.ticket.assignable === 'warehouse' && $scope.ticket.group) {
      $scope.ticket.group = null;
    }
    if ($scope.ticket.assignable === 'group' && $scope.ticket.warehouse) {
      $scope.ticket.warehouse = null;
    }

    $scope.assignees = (function () {
      if ($scope.ticket.group) {
        return assigneesForGroup();
      }
      if ($scope.ticket.warehouse) {
        return assigneesForWarehouse();
      }

      return _.filter($scope.users, (user) => _.includes(user.roles, 'admin'));
    })();

    // Non admins users can only assign within Tech Support groups if the ticket is an existing tech ticket.
    if (
      !UserService.hasRole('admin') &&
      !($scope.ticket.id && ['Tech Support', 'Growth Tech Support'].includes($scope.ticket.group?.name))
    ) {
      $scope.groups = $scope.groups.filter((group) => !['Tech Support', 'Growth Tech Support'].includes(group.name));
    }
  };

  $scope.users = User.query({ 'roles[]': ['admin', 'warehouse'] });
  $q.all([$scope.users.$promise, $scope.groups.$promise, $scope.warehouses.$promise, $scope.ticket.$promise]).then(
    $scope.reassign,
  );

  $scope.categories = Ticket.categories();
  $scope.business_units = Ticket.business_units();
  $scope.areas = Ticket.areas();
  $scope.getTicketables = (val) =>
    Ticketable.query({
      query: val,
    }).$promise.then((ticketablesResource) => ticketablesResource);

  $scope.updateTicketableLabel = (ticketable) => {
    $scope.ticket.ticketable_id = ticketable.ticketable_id;
    $scope.ticket.ticketable_type = ticketable.ticketable_type;
    $scope.ticketable = ticketable;
  };

  $scope.$watch('ticket.go_live_time', (newValue, oldValue) => {
    if (oldValue === undefined) {
      return; // simply skip that
    }
    $scope.ticket.due_date = new Date(moment(new Date(newValue)).add(2, 'days').format('YYYY-MM-DD HH:mm'));
  });
}

app.controller('TicketClaimController', [
  '$scope',
  'Ticket',
  'ticket',
  'UserService',
  'ErrorService',
  function ($scope, Ticket, ticket, UserService, ErrorService) {
    $scope.ticket = ticket;

    $scope.confirm = function () {
      $scope.saving = { ticket_id: $scope.ticket.id, assignee_id: UserService.id };
      Ticket.claim($scope.saving).$promise.then(() => {
        $scope.ticket.assignee = {
          id: UserService.id,
          name: UserService.name,
        };
        $scope.dismiss();
      }, ErrorService.flash);
    };

    $scope.dismiss = function () {
      $scope.closeThisDialog();
    };
  },
]);

app.controller('TicketEditController', [
  '$scope',
  '$filter',
  '$uibModal',
  '$http',
  '$state',
  '$stateParams',
  '$q',
  'ngDialog',
  'User',
  'Ticket',
  'Ticketable',
  'TicketGroup',
  'Warehouse',
  'ConfirmationService',
  'ErrorService',
  'UploaderService',
  'UserService',
  function (
    $scope,
    $filter,
    $uibModal,
    $http,
    $state,
    $stateParams,
    $q,
    ngDialog,
    User,
    Ticket,
    Ticketable,
    TicketGroup,
    Warehouse,
    ConfirmationService,
    ErrorService,
    UploaderService,
    UserService,
  ) {
    const reasons = $http.get('/ticket_resolution_reasons.json');

    $scope.ticket = Ticket.get({ id: $stateParams.id });
    $scope.ticket.$promise.then(() => {
      $scope.ticket.due_date = new Date(moment(new Date($scope.ticket.due_date)).format('YYYY-MM-DD HH:mm'));
      $scope.ticket.go_live_time = new Date(moment(new Date($scope.ticket.go_live_time)).format('YYYY-MM-DD HH:mm'));
      $scope.ticket.live_now = false;
    });

    setupTicketFormController(
      $scope,
      $http,
      $state,
      $q,
      User,
      Ticket,
      Ticketable,
      TicketGroup,
      Warehouse,
      UploaderService,
      ErrorService,
      UserService,
    );

    $scope.lightbox = (image) => {
      $scope.modal = {
        visible: !!image,
        source: $filter('resize')(image, 1920, 1920, 'clip'),
      };
    };

    $scope.claim = function () {
      ngDialog.open({
        template: 'partials/tickets/claim.html',
        controller: 'TicketClaimController',
        resolve: {
          ticket() {
            return $scope.ticket;
          },
        },
      });
    };

    $scope.resolve = function (size) {
      const modalInstance = $uibModal.open({
        animation: true,
        templateUrl: 'resolveModal.html',
        controller: 'ResolveInstanceController',
        resolve: {
          reasons,
          isTechSupport: $scope.ticket.assigned_to_tech_support,
          ticket: () => $scope.ticket,
        },
        size,
      });

      modalInstance.result.then(
        (resolution) => {
          $scope.ticket.initiate_resolve = true;
          $scope.ticket.resolution_type = resolution.type;
          if (!_.isNil(resolution.reason)) {
            $scope.ticket.resolution_attributes = { reason: resolution.reason };
          }
          $scope.ticket.$update(
            () => {
              $state.go('inbox');
            },
            (error) => {
              ErrorService.flash(error);
            },
          );
        },
        () => {},
      );
    };

    $scope.reopen = function () {
      $uibModal.open({
        animation: true,
        templateUrl: 'reopenModal.html',
        controller: 'ReopenInstanceController',
        windowClass: 'reopen-modal',
        resolve: {
          ticket: () => $scope.ticket,
        },
        size: 'md',
      });
    };

    $scope.delete = function () {
      // clean up the ticket for saving across the wire
      ConfirmationService.confirm({
        title: 'Are you sure?',
        description: 'Are you sure you want to delete this ticket?',
      }).then(() => {
        $scope.ticket.$delete(
          () => {
            $state.go('inbox');
          },
          (error) => {
            ErrorService.flash(error);
          },
        );
      });
    };
  },
]);

app.controller('TicketNewController', [
  '$scope',
  '$http',
  '$state',
  '$stateParams',
  '$q',
  'User',
  'Ticket',
  'Ticketable',
  'TicketGroup',
  'Warehouse',
  'ErrorService',
  'UploaderService',
  'UserService',
  function (
    $scope,
    $http,
    $state,
    $stateParams,
    $q,
    User,
    Ticket,
    Ticketable,
    TicketGroup,
    Warehouse,
    ErrorService,
    UploaderService,
    UserService,
  ) {
    $scope.ticket = new Ticket();
    $scope.ticket.go_live_time = new Date(moment(new Date()).format('YYYY-MM-DD HH:mm'));
    $scope.ticket.due_date = new Date(moment(new Date()).add(2, 'days').format('YYYY-MM-DD HH:mm'));
    $scope.ticket.live_now = true;
    $scope.ticket.notify = true;

    setupTicketFormController(
      $scope,
      $http,
      $state,
      $q,
      User,
      Ticket,
      Ticketable,
      TicketGroup,
      Warehouse,
      UploaderService,
      ErrorService,
      UserService,
    );

    function setExistingAccount(accountId) {
      $http.get(`/accounts/${accountId}.json`).then((response) => {
        $scope.ticketable = response.data;
        $scope.ticketable.ticketable_id = $scope.ticketable.id;
        $scope.ticketable.name = $scope.ticketable.customer.name;
        $scope.ticketable.ticketable_type = 'Account';
        $scope.ticketable.email = $scope.ticketable.customer.email;
        $scope.ticket.ticketable_id = $scope.ticketable.ticketable_id;
        $scope.ticket.ticketable_type = 'Account';
      });
    }

    // if we have a account id in the query string, get the account
    const accountGiven = $stateParams.account_id;
    if (accountGiven) {
      setExistingAccount(accountGiven);
    }
  },
]);

app.controller('ResolveInstanceController', [
  '$scope',
  '$uibModalInstance',
  'reasons',
  'isTechSupport',
  'ticket',
  function ($scope, $uibModalInstance, reasons, isTechSupport, ticket) {
    $scope.reasons = reasons.data;
    $scope.resolution = {};
    $scope.isTechSupport = isTechSupport;
    $scope.ticket = ticket;

    $scope.ok = function (type) {
      $uibModalInstance.close({ type, reason: $scope.resolution.reason });
    };

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };

    $scope.resolveTechSupport = function (type, reason) {
      $uibModalInstance.close({ type, reason: reason });
    };
  },
]);

app.controller('ReopenInstanceController', [
  '$scope',
  '$uibModalInstance',
  'ticket',
  '$filter',
  '$state',
  function ($scope, $uibModalInstance, ticket, $filter, $state) {
    $scope.ticket = ticket;
    $scope.ok = function () {
      $scope.ticket.initiate_reopen = true;
      $scope.ticket.$update(
        () => {
          $uibModalInstance.close();
          $state.go('inbox');
        },
        (error) => {
          $scope.errors = _.map(error.data, (value, key) => `${$filter('transform')(key, ['titleize'])} ${value}`);
        },
      );
    };

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  },
]);

(function () {
  const app = angular.module('app');

  const DIMENSIONS = ['num_movers', 'length', 'width', 'height'];

  app.controller('ItemGroupModalController', [
    '$scope',
    'group',
    'ItemGroup',
    'ItemCategoryService',
    'ItemGroupAttributesService',
    'ErrorService',
    function ($scope, group, ItemGroup, ItemCategoryService, ItemGroupAttributesService, ErrorService) {
      $scope.group = group;

      function setupItemGroupDimensionsFor(selection) {
        const defaults = selection
          ? ItemCategoryService.findItemCategoryDefaults(selection.category, selection.sizing_selection)
          : null;
        _.each(DIMENSIONS, function (size) {
          if (defaults) {
            $scope.group[size] = defaults[size];
          } else {
            delete $scope.group[size];
          }
        });
      }

      $scope.selectedItemCategory = function (_item, model) {
        setupItemGroupDimensionsFor(model);
      };

      $scope.revertItemGroupDimensions = function () {
        setupItemGroupDimensionsFor(group.category);
        $scope.group.dimensions_overwritten = false;
      };

      $scope.overrideItemGroupDimensions = function () {
        if (!group.category) {
          return;
        }
        $scope.group.dimensions_overwritten = true;
      };

      $scope.save = function () {
        if ($scope.saving) {
          return;
        }
        if ($scope.savable()) {
          $scope.saving = ItemGroupAttributesService.convert(group);
          const method = $scope.group.id ? 'update' : 'create';
          ItemGroup[method]($scope.saving)
            .$promise.then(function () {
              $scope.confirm();
              delete $scope.saving;
            })
            .catch(function (error) {
              delete $scope.saving;
              ErrorService.handle(error);
            });
        }
      };

      $scope.savable = function () {
        return (
          $scope.group.category &&
          $scope.group.length &&
          $scope.group.width &&
          $scope.group.height &&
          $scope.group.hero_image
        );
      };
    },
  ]);
})();

import * as React from 'react';

import { ControlLabel, FormGroup } from '@shared/components/bootstrap';

import { NumberFormControl } from '@admin/components/fields';

export const LatitudeFormGroup: React.FC<{
  latitude?: number;
  disabled?: boolean;
  onChange(latitude?: number): void;
}> = ({ latitude, onChange, disabled }) => (
  <FormGroup>
    <ControlLabel htmlFor="address-latitude">Latitude</ControlLabel>
    <NumberFormControl disabled={disabled} id="address-latitude" value={latitude} onChange={onChange} />
  </FormGroup>
);

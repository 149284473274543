import * as React from 'react';

import { client } from '@admin/libraries/apollo';

import { PricingSetFragment, usePricingSetFetcherQuery } from '@admin/schema';

import { PricingSetContext } from './pricing_set_context';

export const PricingSetFetcher: React.FC<{
  zip?: string;
  accountID?: number;
  source: string;
  onFetch?(_?: PricingSetFragment): void;
}> = ({ zip, accountID, source, children, onFetch }) => {
  const { data } = usePricingSetFetcherQuery({
    client,
    variables: {
      zip: zip!,
      accountID: String(accountID),
      source: source,
    },
    skip: !zip,
    onCompleted: ({ pricingSet }) => {
      if (!onFetch) return;
      onFetch(pricingSet ?? undefined);
    },
  });

  return <PricingSetContext.Provider value={data?.pricingSet ?? {}}>{children}</PricingSetContext.Provider>;
};

import React, { useState } from 'react';
import { DateTime } from 'luxon';

import { client } from '@admin/libraries/apollo';
import {
  ShiftUpdateMutation,
  Status,
  useJobCodesQuery,
  useShiftQuery,
  useShiftCreateMutation,
  useShiftDeleteMutation,
  useShiftUpdateMutation,
  useNewShiftOptionsQuery,
  Workforce__ShiftInput,
} from '@admin/schema';
import { Spinner } from '@admin/components/spinner';

import { ShiftModal } from './modal';

type ShiftReturn = ShiftUpdateMutation['result']['shift'];

export const ShiftModalContainer: React.FC<{
  id?: string;
  onClose(): void;
  onChange(shift: ShiftReturn): void;
}> = ({ id, onClose, onChange }) => {
  const editMode = !!id;
  const [error, setError] = useState<string | null>();
  const { data: jobCodesData, loading: jobCodesLoading } = useJobCodesQuery({ client });
  const { data: shiftData, loading: shiftLoading } = useShiftQuery({ client, variables: { id: id! }, skip: !editMode });
  const { data: optionsData, loading: optionsLoading } = useNewShiftOptionsQuery({ client, skip: editMode });
  const [shiftUpdate, { loading: shiftUpdating }] = useShiftUpdateMutation({ client });
  const [shiftCreate, { loading: shiftCreating }] = useShiftCreateMutation({ client });
  const [shiftDelete, { loading: shiftDeleting }] = useShiftDeleteMutation({ client });

  const sanitizeInputs = (shift: Workforce__ShiftInput): Workforce__ShiftInput => {
    const sanitizedInput = { ...shift };
    if (!sanitizedInput.ended || !DateTime.fromISO(sanitizedInput.ended.timestamp).isValid) {
      delete sanitizedInput.ended;
    }
    if (sanitizedInput.ended && !sanitizedInput.ended.facilityID) {
      sanitizedInput.ended.facilityID = sanitizedInput.started.facilityID;
    }
    if (!sanitizedInput.workBreaks) {
      sanitizedInput.workBreaks = [];
    }
    if (!sanitizedInput.activities) {
      sanitizedInput.activities = [];
    }
    sanitizedInput.workBreaks.forEach((wb) => {
      if (!wb.ended || !DateTime.fromISO(wb.ended).isValid) delete wb.ended;
    });

    return sanitizedInput;
  };

  const onSave = async (shift: Workforce__ShiftInput) => {
    const sanitizedInput = sanitizeInputs(shift);
    try {
      const shiftSave = editMode ? shiftUpdate : shiftCreate;
      const result = await shiftSave({
        variables: {
          input: sanitizedInput,
        },
      });

      if (result.data?.result.status === Status.Ok) {
        if (!editMode) {
          location.reload();
        }
        onChange(result.data.result.shift);
        setError(undefined);
        onClose();
      } else {
        setError(result.data?.result.error);
        window.scrollTo(0, 0);
      }
    } catch (e) {
      setError('Sorry, an unexpected error occurred. If the problem persists, contact Tech Support.');
      window.scrollTo(0, 0);
    }
  };

  const onDelete = async (shiftID: string) => {
    try {
      const result = await shiftDelete({
        variables: { id: shiftID },
      });

      if (result.data?.result.status === Status.Ok) {
        onChange(result.data.result.shift);
        setError(undefined);
        onClose();
      } else {
        setError(result.data?.result.error);
        window.scrollTo(0, 0);
      }
    } catch (e) {
      setError('Sorry, an unexpected error occurred. If the problem persists, contact Tech Support.');
      window.scrollTo(0, 0);
    }
  };

  const canEdit = editMode && shiftData?.shift;
  const canCreate = !editMode && optionsData?.users && optionsData?.facilities;
  const canProceed = jobCodesData?.jobCodes && (canEdit || canCreate);
  const loading = shiftLoading || optionsLoading || jobCodesLoading;
  if (loading || !canProceed) {
    return <Spinner />;
  }

  return (
    <ShiftModal
      shift={shiftData?.shift}
      jobCodes={jobCodesData!.jobCodes}
      users={optionsData?.users}
      facilities={optionsData?.facilities}
      error={error || undefined}
      loading={shiftUpdating || shiftCreating || shiftDeleting}
      editMode={editMode}
      onSave={onSave}
      onDelete={onDelete}
      onClose={onClose}
    />
  );
};

(() => {
  const app = angular.module('app');
  const TEMPLATE = `
    <dispatcher-estimation-form order="ngDialogData.order" mode="ngDialogData.mode" on-save="closeThisDialog()">
    </dispatcher-estimation-form>
  `;

  app.service('DispatcherEstimationFormModalService', [
    'ngDialog',
    'Order',
    function (ngDialog, Order) {
      this.present = (order, mode) => {
        Order.get({
          account_id: order.account_id,
          id: order.id,
        }).$promise.then((other) => {
          const tempDispatchOffset = order.dispatch_offset;
          _.assign(order, other);
          order.dispatch_offset = tempDispatchOffset;
        });

        return ngDialog.open({
          template: TEMPLATE,
          plain: true,
          data: {
            order,
            mode,
          },
        });
      };
    },
  ]);
})();

import * as React from 'react';
import { useFormContext } from 'react-hook-form';

import { usePhoneValidation } from '@admin/hooks';

import { mask, parse, REGEX } from '@admin/utils/phone';

import { Field } from '../field';

const ID = 'customer-phone';
const NAME = 'customer.phone';

export const PhoneField: React.FC<{ defaultValue?: string }> = ({ defaultValue }) => {
  const validate = usePhoneValidation();
  const { register } = useFormContext<{
    customer: {
      phone: string;
    };
  }>();

  return (
    <Field required id={ID} name={NAME} label="Phone Number">
      <input
        id={ID}
        className="form-control"
        type="text"
        name={NAME}
        ref={register({
          required: 'Please provide a phone number',
          validate: async (phone: string) =>
            (REGEX.test(phone) && (await validate(phone))) || 'Please provide a valid phone number',
        })}
        onChange={(event) => {
          event.target.value = mask(parse(event.target.value));
        }}
        defaultValue={defaultValue}
      />
    </Field>
  );
};

import * as React from 'react';

import { Billing__Source } from '@admin/schema';

import { Summary } from './summary';

export const Detailed: React.FC<{
  source: Pick<Billing__Source, 'brand' | 'kind' | 'number' | 'expiration' | 'default'>;
}> = ({ source }) => (
  <>
    <strong>{source.brand || source.kind}</strong> <Summary source={source} /> {source.default && <>(default)</>}
  </>
);

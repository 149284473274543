export const REGEX = /^(\+1)?\s*[\(]?(\d{3})[\)]?[\-\s]*(\d{3})[\-\s]*(\d{4})$/; // i.e. "+1 (000) 000-0000"

const STRIP_REGEX = /\D/g;
const FORMAT_REGEX = /^(?:\+\d)?(\d{1,3})?(\d{1,3})?(\d{1,4})?$/;

// i.e. '(888) 123-4567' to '8881234567'
export const parse = (value: string) => value.replace(STRIP_REGEX, '');

// i.e. '8881234567' to '(888) 123-4567'
export const mask = (value: string) =>
  value.replace(FORMAT_REGEX, (_, area, locale, subscriber) => {
    const components = [];
    if (locale) {
      components.push(`(${area})`);
    } else {
      components.push(area);
    }

    components.push(' ');
    components.push(locale);
    if (locale && subscriber) {
      components.push('-');
    }
    components.push(subscriber);

    return components.join('').trim();
  });

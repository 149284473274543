(function () {
  const app = angular.module('app');

  function convert(value, source, target) {
    switch (source) {
      case 'hours':
        value *= 3600;
        break;
      case 'minutes':
        value *= 60;
        break;
    }
    switch (target) {
      case 'hours':
        value /= 3600;
        break;
      case 'minutes':
        value /= 60;
        break;
    }

    return value;
  }

  app.directive('durationUnitConverter', function () {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function (scope, element, attrs, ngModel) {
        if (ngModel) {
          ngModel.$parsers.push(function (value) {
            if (value !== null && value !== undefined) {
              return convert(value, attrs.target, attrs.source);
            }
          });
          ngModel.$formatters.push(function (value) {
            if (value !== null && value !== undefined) {
              return convert(value, attrs.source, attrs.target);
            }
          });
        }
      },
    };
  });
})();

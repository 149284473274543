import React from 'react';

const DISPLAYED_OVERRIDE_REASONS = new Set(['Customer Requested Override']);

export const MoverCountIcon = ({ movers, overrideReason }: { movers: number; overrideReason?: string }) => (
  <>
    <i className="fa fa-users" />
    <span>
      {movers} {DISPLAYED_OVERRIDE_REASONS.has(overrideReason!) && `(${overrideReason})`}
    </span>
  </>
);

import React from 'react';
import { DateTime } from 'luxon';

import { orderURL } from '@admin/config/routes';
import { Titleize } from '@admin/components/helpers/titleize';
import { Spinner } from '@admin/components/spinner';
import { client } from '@admin/libraries/apollo';
import { Order, useOrdersMissingSignatureQuery } from '@admin/schema';

type ordersType = Pick<Order, 'id' | 'type' | 'subtype' | 'serviceType' | 'scheduled'>;

const OrderList: React.FC<{ orders?: ordersType[] }> = ({ orders }) => {
  if (!orders?.length) {
    return null;
  }

  return (
    <ul>
      {orders.map((order, i) => (
        <li key={i}>
          <a className="pointer underline" href={orderURL({ id: order.id })}>
            {DateTime.fromISO(order.scheduled, { setZone: true }).toLocaleString(DateTime.DATE_MED)} (
            <Titleize text={order.type} /> | <Titleize text={order.subtype} /> |{' '}
            <Titleize text={String(order.serviceType)} />)
          </a>
        </li>
      ))}
    </ul>
  );
};

export const OrdersMissingSignature: React.FC<{ accountID: string }> = ({ accountID }) => {
  const { data, loading } = useOrdersMissingSignatureQuery({
    variables: { accountID },
    client,
  });

  if (loading) {
    return <Spinner />;
  }

  const ComplianceStatus: React.FC = () => {
    if (data?.ordersMissingSignature.length) {
      return <span className="text-danger">Not Compliant - Orders with missing signatures listed below</span>;
    }
    return <span>Compliant - All required documents have been signed.</span>;
  };

  return (
    <div>
      <strong>Compliance Status: </strong>
      <ComplianceStatus />
      <OrderList orders={data?.ordersMissingSignature} />
    </div>
  );
};

import template from '@admin/angular/templates/subscriptions_preview.html';

(() => {
  const app = angular.module('app');

  const PLAN_TYPE_STORAGE = 'StoragePlan';

  app.component('subscriptionsPreview', {
    template,
    bindings: {
      subscriptions: '<',
    },
    controller: [
      function () {
        const summarize = (subscription) => subscription.quantity + ' × ' + subscription.plan.name;
        const cuft = (subscription) => Number(subscription.quantity) * Number(subscription.plan.cubic_footage);

        this.$onInit = () => {
          this.summary = _.filter(this.subscriptions, (subscription) => subscription.plan.type === PLAN_TYPE_STORAGE)
            .map(summarize)
            .sort()
            .join(' + ');
          this.cuft = _.sum(this.subscriptions.map(cuft));
        };
      },
    ],
  });
})();

import React from 'react';
import styled from '@emotion/styled';

import { NotificationFragment, useSentIterableEmailQuery } from '@admin/schema';

import { Modal } from '@shared/components/bootstrap';
import { client } from '@admin/libraries/apollo';

import { Spinner } from '../spinner';

// TODO: Can probably be removed by applying `centered` to <Modal /> once TEAL is on Bootstrap V4
const StyledModal = styled(Modal)`
  .modal-dialog {
    max-height: 94vh;
    margin-top: 3vh;
    overflow: auto;
  }
`;

export const ViewEmailModal: React.FC<{
  notification: NotificationFragment;
  onClose(): void;
}> = ({ notification, onClose }) => {
  const { data, loading, error } = useSentIterableEmailQuery({
    client,
    variables: { notificationId: notification.id },
  });

  return (
    <StyledModal onClose={onClose} size={Modal.Size.Large}>
      <Modal.Content>
        <Modal.Body>
          {error && <h3>Cannot preview email. Please contact Tech Support if this issue persists.</h3>}
          {loading ? <Spinner /> : <div dangerouslySetInnerHTML={{ __html: data?.result.html ?? '' }} />}
        </Modal.Body>
      </Modal.Content>
    </StyledModal>
  );
};

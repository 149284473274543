(function () {
  const app = angular.module('app');

  app.component('discover', {
    templateUrl: 'partials/discovers/preview.html',
    bindings: {
      object: '=',
      type: '=',
    },
  });
})();

import React from 'react';
import { Formik, Form as FormikForm } from 'formik';

import { Status, Hrs__RoleDefault, useHrsRoleDefaultUpsertMutation, Hrs__RoleDefaultInput } from '@admin/schema';

import { client } from '@admin/libraries/apollo';
import { USER_ROLES, USER_ROLE_TO_NAME } from '@admin/constants/user_role';
import { Alert, Button, Panel, Modal } from '@shared/components/bootstrap';
import { FormikMultiselectFormGroup } from '@admin/components/fields/formik/formik_multiselect_form_group';
import { FormikAutosuggestFormGroup } from '@admin/components/fields/formik/formik_autosuggest_form_group';

const USER_ROLES_OPTIONS = USER_ROLES.map((role) => ({ id: role, name: USER_ROLE_TO_NAME[role] }));

export const UpsertModal: React.FC<{
  currentBusinessTitles: string[];
  roleDefault?: Hrs__RoleDefault;
  closeModal(): void;
}> = ({ currentBusinessTitles, roleDefault, closeModal }) => {
  const [save, { loading, data }] = useHrsRoleDefaultUpsertMutation({
    client,
    onCompleted: ({ result }) => {
      if (result?.status === Status.Ok) closeModal();
    },
  });

  const onSubmit = (input: Hrs__RoleDefaultInput) => {
    save({
      variables: {
        id: roleDefault?.id,
        input,
      },
    });
  };

  return (
    <Modal onClose={closeModal}>
      <Formik<Hrs__RoleDefaultInput>
        initialValues={{
          businessTitle: roleDefault?.businessTitle ?? '',
          roles: roleDefault?.roles ?? [],
        }}
        onSubmit={onSubmit}
      >
        <FormikForm>
          <Panel>
            <Panel.Body>
              {data?.result?.error && <Alert style="danger">{data.result.error}</Alert>}
              <FormikAutosuggestFormGroup
                label="Business Title"
                name="businessTitle"
                options={currentBusinessTitles.map((option) => ({ label: option, value: option }))}
              />
              <FormikMultiselectFormGroup label="Roles" name="roles" entries={USER_ROLES_OPTIONS} />
            </Panel.Body>
            <Panel.Footer align="right">
              <Button kind="primary" onClick={closeModal}>
                Cancel
              </Button>
              <Button loading={loading} kind="primary" type="submit">
                Save
              </Button>
            </Panel.Footer>
          </Panel>
        </FormikForm>
      </Formik>
    </Modal>
  );
};

import angular from 'angular';

import { transformReferralsResult } from '../../models';

const app = angular.module('app');

app.controller('ReferralsController', [
  '$rootScope',
  '$location',
  '$scope',
  'Referral',
  'PaginationService',
  'FiltersService',
  'ConfirmationService',
  function ($rootScope, $location, $scope, Referral, PaginationService, FiltersService, ConfirmationService) {
    $scope.filters = $location.search();
    $scope.pagination = PaginationService.initialize({ page: $location.search().page });

    $scope.filter = (filters) => {
      if (!filters) {
        filters = $scope.filters || {};
      } else {
        $scope.pagination.reset();
      }

      filters = FiltersService.cleanup(filters);
      filters.page = $scope.pagination.page;
      $location.search(filters);

      Referral.get(filters).$promise.then((data) => {
        $scope.pagination.prepare(data.pagination);
        $scope.referrals = data.results.map(transformReferralsResult);
      });
    };

    $scope.pay = (referral) => {
      ConfirmationService.confirm({
        title: 'Are you sure?',
        description: 'Did you pay ' + referral.referrer.name + '?',
      }).then(() => {
        referral.state = 'paid';
        Referral.update(
          { id: referral.id },
          referral,
          () => {
            $scope.filter();
          },
          (error) => {
            referral.state = 'payment_due';
            $rootScope.$broadcast('message:group:display', error.data);
          },
        );
      });
    };

    $scope.filter();
  },
]);

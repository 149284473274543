import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import { zendeskTicketURL } from '@admin/config/zendesk';
import { AnchorButton, Col, Grid, Row, Text } from '@shared/components/bootstrap';
import { Timestamp } from '@shared/components/helpers';
import { ZendeskTicketAssociationFragment } from '@admin/schema';

export const TicketAttributes: React.FC<{
  ticketAssociation: ZendeskTicketAssociationFragment;
}> = ({ ticketAssociation }) => {
  const { zdTicketID, createdAt, creator } = ticketAssociation;

  return (
    <Grid fluid>
      <Row>
        <Col md={1}>
          <Text alignment="left" tag="p">
            ID# {zdTicketID}
          </Text>
        </Col>
        <Col md={4}>
          <Text tag="p">
            Created: <Timestamp value={createdAt} format={Timestamp.Format.DateTime} />
          </Text>
        </Col>
        <Col md={4}>
          <Text tag="p">Created By: {creator?.name}</Text>
        </Col>
        <Col md={3}>
          <AnchorButton kind="primary" target="_blank" href={zendeskTicketURL({ id: zdTicketID })}>
            View on Zendesk <FontAwesomeIcon icon={faExternalLinkAlt} />
          </AnchorButton>
        </Col>
      </Row>
    </Grid>
  );
};

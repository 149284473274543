import angular from 'angular';
import moment from 'moment';

angular.module('app').filter('amDifference', [
  function () {
    function amDifferenceFilter(value, otherValue, unit, usePrecision) {
      if (value === undefined || value === null) {
        return '';
      }

      const date = moment(value);
      const date2 = moment(otherValue);

      if (!date.isValid() || !date2.isValid()) {
        return '';
      }

      return date.diff(date2, unit, usePrecision);
    }

    return amDifferenceFilter;
  },
]);

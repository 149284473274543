(function () {
  const app = angular.module('app');

  app.component('itemGroup', {
    templateUrl: 'partials/items/group.html',
    bindings: {
      item: '=',
      inventory: '=',
    },
  });
})();

import { AdminDropOffAppointmentFragment } from '@admin/schema';
import { DateTime } from 'luxon';
import * as React from 'react';

import { Label } from '@admin/components/helpers/label';

const formatTime = (time: string): string => DateTime.fromISO(time).toLocaleString(DateTime.DATE_FULL);

export const Summary: React.FC<{ appointment: AdminDropOffAppointmentFragment }> = ({ appointment }) => (
  <>
    <p>
      <strong>ID:</strong> {appointment.id}
    </p>
    <p>
      <strong>Status:</strong> <Label kind="info">{appointment.state}</Label>
    </p>
    {appointment.expectedAt && (
      <p>
        <strong>Expected Arrival:</strong> {formatTime(appointment.expectedAt)}
      </p>
    )}
    {appointment.canceledAt && (
      <p>
        <strong>Canceled At:</strong> {formatTime(appointment.canceledAt)}
      </p>
    )}
    {appointment.completedAt && (
      <p>
        <strong>Completed At:</strong> {formatTime(appointment.completedAt)}
      </p>
    )}
  </>
);

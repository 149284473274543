(function () {
  const app = angular.module('app');

  app.controller('UtmPhoneController', [
    '$rootScope',
    '$scope',
    '$state',
    '$stateParams',
    'SystemPhone',
    'Campaign',
    'UtmPhone',
    function ($rootScope, $scope, $state, $stateParams, SystemPhone, Campaign, UtmPhone) {
      $scope.utmPhone = new UtmPhone();
      SystemPhone.query().$promise.then(function (data) {
        $scope.systemPhones = data;
        if ($stateParams.systemPhoneId) {
          $scope.utmPhone.phoneId = parseInt($stateParams.systemPhoneId);
          $scope.reloadCampaign();
        }
      });

      $scope.reloadCampaign = function () {
        $scope.systemPhone = _.find($scope.systemPhones, function (phone) {
          return phone.id === $scope.utmPhone.phoneId;
        });
        if ($scope.systemPhone && $scope.systemPhone.campaign_id) {
          Campaign.get({ id: $scope.systemPhone.campaign_id }).$promise.then(function (data) {
            $scope.campaign = data;
          });
        } else {
          $scope.campaign = new Campaign();
        }
      };

      $scope.addUtmPhone = function () {
        if ($scope.systemPhone) {
          ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'].forEach(function (element) {
            $scope.utmPhone[element] = $scope.campaign[element];
          });

          $scope.utmPhone.$save(
            function () {
              $state.go('systemPhones');
            },
            function (error) {
              $rootScope.$broadcast('message:group:display', error.data);
            },
          );
        }
      };
    },
  ]);
})();

import * as React from 'react';
import axios from 'axios';

import styled from '@emotion/styled';

import { capitalize } from '@shared/utils/capitalize';

import { ITicket } from '@admin/types';

import {
  Text,
  Label,
  Button,
  Textarea,
  Radio,
  Box,
  Input,
  COLORS,
  TagSelector,
  Select,
  TextButton,
} from '@clutter/clean';

interface IResolveTicketProps {
  reasonTypes: string[];
  ticket: ITicket;
  onSuccess(resolution: string, reason: string): void;
  onClose(): void;
}

const RESOLUTION_OPTIONS = [
  {
    value: 'done',
    label: <Text.Body>Did it</Text.Body>,
  },
  {
    value: 'did_not_do',
    label: <Text.Body>Won't do it</Text.Body>,
  },
];

const FullWidthTextArea = styled(Textarea)`
  width: 100%;
`;

const FullWidthInput = styled(Input)`
  width: 100%;
`;

const FullWidthSelect = styled(Select)`
  width: 100%;
`;

const TITLE_CAPTION = 'Title to use for this issue on Pivotal';

const CONTEXT_CAPTION =
  "Give some context around the system that had an issue. If it's 3 AM \
and I just got paged about this, what do I need to know about this part of Teal?";

const ISSUE_CAPTION = 'What went wrong? Why was this ticket created?';

const ISSUE_RESOLUTION_CAPTION =
  'What steps did you take to resolve this tech ticket? Including code \
snippets that you ran can be useful for the next Engineer who runs into this';

const TAGS_CAPTION = 'Additional tags that might be useful for categorizing this ticket';

const TEAM_OWNER_CAPTION = 'The team this issue should belong to, if you know it';

const EXISTING_STORY_CAPTION = 'The ID for the Pivotal Story that already exists for this ticket';

enum ResolveStep {
  TealResolution,
  PivotalCreation,
  PivotalUpdate,
}

const ResolveTicket: React.FC<IResolveTicketProps> = ({ reasonTypes, ticket, onSuccess, onClose }) => {
  const [resolveStep, setResolveStep] = React.useState(ResolveStep.TealResolution);
  const [resolutionType, setResolutionType] = React.useState<string | undefined>(undefined);
  const [reasonType, setReasonType] = React.useState<string | undefined>(undefined);

  const [existingPivotalTicket, setExistingPivotalTicket] = React.useState(false);

  const [loading, setLoading] = React.useState(false);
  const [resolutionError, setError] = React.useState(undefined);

  const submitTealResolutions = (reason: string | undefined, resolution: string | undefined) => {
    setReasonType(reason);
    setResolutionType(resolution);
    if (resolution !== undefined && reason !== undefined) {
      setResolveStep(ResolveStep.PivotalCreation);
    }
  };

  const submitPivotalData = async (
    title: string | undefined,
    context: string | undefined,
    issueDescription: string | undefined,
    resolutionSteps: string | undefined,
    tags: string[],
    pod: string | undefined,
  ) => {
    try {
      setLoading(true);
      await axios.post(`/tickets/${ticket.id}/track.json`, {
        ticket_title: title,
        context: context,
        issue_description: issueDescription,
        steps_to_resolve: resolutionSteps,
        issue_reason: reasonType,
        tags: tags,
        pod: pod,
      });
      onSuccess(resolutionType!, reasonType!);
    } catch (error) {
      setError(error as any);
      setLoading(false);
    }
  };

  const updatePivotalData = async (storyId: string) => {
    try {
      setLoading(true);
      await axios.post(`/tickets/${ticket.id}/track.json`, {
        existing_story_id: storyId,
      });
      onSuccess(resolutionType!, reasonType!);
    } catch (error) {
      setError(error as any);
      setLoading(false);
    }
  };

  const skipPivotalTicket = () => {
    onSuccess(resolutionType!, reasonType!);
  };

  const onExistingPivotalTicketChange = (existing: boolean) => {
    if (existing) {
      setResolveStep(ResolveStep.PivotalUpdate);
    } else {
      setResolveStep(ResolveStep.PivotalCreation);
    }
    setExistingPivotalTicket(existing);
  };

  return (
    <Box.Flex padding="16px" alignContent="center" flexDirection="column">
      {resolveStep === ResolveStep.TealResolution && (
        <TealTicketResolutions reasonTypes={reasonTypes} onClose={onClose} onNext={submitTealResolutions} />
      )}
      {resolveStep !== ResolveStep.TealResolution && (
        <PivotalFlowSelector existing={existingPivotalTicket} onChange={onExistingPivotalTicketChange} />
      )}
      {resolveStep === ResolveStep.PivotalCreation && (
        <PivotalTicketForm
          loading={loading}
          onClose={onClose}
          onSubmit={submitPivotalData}
          onSkip={skipPivotalTicket}
        />
      )}
      {resolveStep === ResolveStep.PivotalUpdate && (
        <PivotalTicketUpdateForm loading={loading} onClose={onClose} onSubmit={updatePivotalData} />
      )}

      {resolutionError && (
        <Box
          color={COLORS.cloud}
          background={COLORS.toucan}
          borderRadius="4px"
          padding="8px 8px 8px 8px"
          margin="8px 0 0 0"
        >
          <Text.Callout>resolutionError</Text.Callout>
        </Box>
      )}
    </Box.Flex>
  );
};

interface ITealTicketResolutionProps {
  reasonTypes: string[];
  onClose(): void;
  onNext(reason: string, resolution: string): void;
}

const TealTicketResolutions: React.FC<ITealTicketResolutionProps> = ({ reasonTypes, onClose, onNext }) => {
  const [resolutionType, setResolutionType] = React.useState<string | undefined>(undefined);
  const [reasonType, setReasonType] = React.useState<string | undefined>(undefined);

  const reasonOptions = reasonTypes.map((reasonOption) => ({
    value: reasonOption,
    label: <Text.Body>{capitalize(reasonOption)}</Text.Body>,
  }));

  const onClick = () => {
    if (reasonType !== undefined && resolutionType !== undefined) {
      onNext(reasonType, resolutionType);
    }
  };

  return (
    <Box>
      <Box padding="0 0 8px 0">
        <Label>
          <Text.Title size="extraSmall">Category</Text.Title>
        </Label>
        <Radio.Selector name="category" value={reasonType} options={reasonOptions} onChange={setReasonType} />
      </Box>
      <Box padding="0 0 8px 0">
        <Label>
          <Text.Title size="extraSmall">Did you do it?</Text.Title>
        </Label>
        <Radio.Selector
          name="resolutionType"
          value={resolutionType}
          options={RESOLUTION_OPTIONS}
          onChange={setResolutionType}
        />
      </Box>
      <Box.Flex flexDirection="row" justifyContent="end">
        <Box padding="0 16px 0 0">
          <Button size="small" kind="secondary" onClick={onClose}>
            Cancel
          </Button>
        </Box>
        <Button
          size="small"
          kind="primary"
          disabled={resolutionType === undefined || reasonType === undefined}
          onClick={onClick}
        >
          Next
        </Button>
      </Box.Flex>
    </Box>
  );
};

interface IPivotalTicketFormProps {
  loading: boolean;
  onClose(): void;
  onSubmit(
    title: string | undefined,
    context: string | undefined,
    issueDescription: string | undefined,
    resolutionSteps: string | undefined,
    tags: string[],
    pod: string | undefined,
  ): void;
  onSkip(): void;
}

const PivotalTicketForm: React.FC<IPivotalTicketFormProps> = ({ loading, onClose, onSubmit, onSkip }) => {
  const [title, setTitle] = React.useState<string | undefined>(undefined);
  const [context, setContext] = React.useState<string | undefined>(undefined);
  const [issueDescription, setIssueDescription] = React.useState<string | undefined>(undefined);
  const [resolutionSteps, setResolutionSteps] = React.useState<string | undefined>(undefined);
  const [additionalTags, setAdditionalTags] = React.useState('');
  const [teamOwnerTag, setTeamOwnerTag] = React.useState<string | undefined>(undefined);

  const teamOwnerOptions = [
    {
      value: 'billing',
      label: 'Billing',
    },
    {
      value: 'enterprise',
      label: 'Enterprise',
    },
    {
      value: 'moving',
      label: 'Moving',
    },
    {
      value: 'marketplace',
      label: 'Marketplace',
    },
    {
      value: 'self storage',
      label: 'Self Storage',
    },
    {
      value: 'smart storage',
      label: 'Smart Storage',
    },
    {
      value: undefined,
      label: 'Unknown',
    },
  ];

  const submitData = () => {
    const resolvedTags = additionalTags.split(',').map((s) => s.trim());

    onSubmit(title, context, issueDescription, resolutionSteps, resolvedTags, teamOwnerTag);
  };

  return (
    <Box>
      <Box padding="0 0 8px 0">
        <Label>
          <Text.Title size="extraSmall">Pivotal Title</Text.Title>
          <Text.Caption>{TITLE_CAPTION}</Text.Caption>
        </Label>
        <FullWidthInput
          onChange={(event) => {
            setTitle(event.target.value);
          }}
        />
      </Box>
      <Box padding="0 0 8px 0">
        <Label>
          <Text.Title size="extraSmall">Context</Text.Title>
          <Text.Caption>{CONTEXT_CAPTION}</Text.Caption>
        </Label>
        <FullWidthTextArea
          onChange={(event) => {
            setContext(event.target.value);
          }}
        />
      </Box>
      <Box padding="0 0 8px 0">
        <Label>
          <Text.Title size="extraSmall">The Issue</Text.Title>
          <Text.Caption>{ISSUE_CAPTION}</Text.Caption>
        </Label>
        <FullWidthTextArea
          onChange={(event) => {
            setIssueDescription(event.target.value);
          }}
        />
      </Box>
      <Box padding="0 0 8px 0">
        <Label>
          <Text.Title size="extraSmall">The Fix</Text.Title>
          <Text.Caption>{ISSUE_RESOLUTION_CAPTION}</Text.Caption>
        </Label>
        <FullWidthTextArea
          onChange={(event) => {
            setResolutionSteps(event.target.value);
          }}
        />
      </Box>
      <Box padding="0 0 8px 0">
        <Label>
          <Text.Title size="extraSmall">Tags</Text.Title>
          <Text.Caption>{TAGS_CAPTION}</Text.Caption>
        </Label>
        <FullWidthInput
          onChange={(event) => {
            setAdditionalTags(event.target.value);
          }}
        />
      </Box>
      <Box padding="0 0 8px 0">
        <Label>
          <Text.Title size="extraSmall">Team Owner</Text.Title>
          <Text.Caption>{TEAM_OWNER_CAPTION}</Text.Caption>
        </Label>
        <FullWidthSelect
          name="Team Owner"
          placeholder="Unknown"
          value={teamOwnerTag}
          options={teamOwnerOptions}
          onChange={(value?: any) => setTeamOwnerTag(value as string)}
        />
      </Box>
      <Box.Flex flexDirection="row" alignItems="center" justifyContent="end">
        <Box padding="0 16px 0 0">
          <TextButton size="medium" kind="primary" onClick={onSkip}>
            Skip
          </TextButton>
        </Box>
        <Box padding="0 16px 0 0">
          <Button size="small" kind="secondary" disabled={loading} onClick={onClose}>
            Cancel
          </Button>
        </Box>
        <Button
          size="small"
          kind="primary"
          disabled={title === undefined || loading}
          loading={loading}
          onClick={submitData}
        >
          Resolve
        </Button>
      </Box.Flex>
    </Box>
  );
};

interface IPivotalFlowSelectorProps {
  existing: boolean;
  onChange(existing: boolean): void;
}

const PivotalFlowSelector: React.FC<IPivotalFlowSelectorProps> = ({ existing, onChange }) => (
  <Box.Flex color={COLORS.panther} flexDirection="column" alignItems="center">
    <Text.Title size="extraSmall">Is this an existing issue?</Text.Title>
    <Box.Flex flexDirection="row" alignItems="center" padding="8px 0 0 0">
      <Box padding="0 16px 0 0">
        <TagSelector.Tag selected={!existing} size="medium" onClick={() => onChange(false)}>
          New Issue
        </TagSelector.Tag>
      </Box>
      <TagSelector.Tag selected={existing} size="medium" onClick={() => onChange(true)}>
        Existing Issue
      </TagSelector.Tag>
    </Box.Flex>
  </Box.Flex>
);

interface IPivotalTicketUpdateFormProps {
  loading: boolean;
  onClose(): void;
  onSubmit(storyId: string): void;
}

const PivotalTicketUpdateForm: React.FC<IPivotalTicketUpdateFormProps> = ({ loading, onClose, onSubmit }) => {
  const [storyId, setStoryId] = React.useState<string | undefined>(undefined);

  const submitData = () => {
    if (storyId !== undefined) {
      onSubmit(storyId);
    }
  };

  return (
    <Box>
      <Box padding="0 0 8px 0">
        <Label>
          <Text.Title size="extraSmall">Existing Pivotal Story ID</Text.Title>
          <Text.Caption>{EXISTING_STORY_CAPTION}</Text.Caption>
        </Label>
        <FullWidthInput
          onChange={(event) => {
            setStoryId(event.target.value);
          }}
        />
      </Box>
      <Box.Flex flexDirection="row" justifyContent="end">
        <Box padding="0 16px 0 0">
          <Button size="small" kind="secondary" disabled={loading} onClick={onClose}>
            Cancel
          </Button>
        </Box>
        <Button
          size="small"
          kind="primary"
          disabled={storyId === undefined || loading}
          loading={loading}
          onClick={submitData}
        >
          Resolve
        </Button>
      </Box.Flex>
    </Box>
  );
};

export { ResolveTicket };

import React from 'react';

import { accountsURL, accountURL } from '@admin/config/routes';
import { Account, Customer } from '@admin/schema';
import { Breadcrumb } from '@shared/components/bootstrap';

export const AccountBreadcrumbs: React.FC<{
  account: Pick<Account, 'id' | 'number'> & {
    customer: Pick<Customer, 'name'>;
  };
}> = ({ account }) => (
  <Breadcrumb>
    <Breadcrumb.Item>
      <a href={accountsURL()}>Accounts</a>
    </Breadcrumb.Item>
    <Breadcrumb.Item>
      <a href={accountURL({ id: account.id })}>{`#${account.number} (${account.customer.name})`}</a>
    </Breadcrumb.Item>
    <Breadcrumb.Item active>New Move-In</Breadcrumb.Item>
  </Breadcrumb>
);

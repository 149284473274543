const app = angular.module('app');

app.controller('VoicemailsIndexController', [
  '$location',
  'Voicemail',
  'PaginationService',
  'FiltersService',
  'DebounceService',
  function ($location, Voicemail, PaginationService, FiltersService, DebounceService) {
    const self = this;
    self.filters = $location.search();
    self.pagination = PaginationService.initialize({ page: $location.search().page });
    const debounce = DebounceService.initialize();

    self.filter = function (params) {
      let filters = params;
      if (!filters) {
        filters = self.filters || {};
      } else {
        self.pagination.reset();
      }

      filters = FiltersService.cleanup(filters);
      filters.page = self.pagination.page;
      $location.search(filters);

      self.voicemails = null;
      debounce.execute(Voicemail.get(filters)).then((data) => {
        self.pagination.prepare(data.pagination);
        self.voicemails = data.results;
      });
    };

    self.inboundMissed = (voicemail) =>
      voicemail.recent_phone_call.aasm_state === 'missed' && voicemail.recent_phone_call.direction === 'inbound';

    self.filter();
  },
]);

app.controller('VoicemailsShowController', [
  '$scope',
  '$stateParams',
  'Voicemail',
  function ($scope, $stateParams, Voicemail) {
    $scope.voicemail = Voicemail.get({
      id: $stateParams.id,
    });
  },
]);

(function () {
  angular.module('app').filter('categoryQueryMatcher', [
    function () {
      return function (categoryOptions, query) {
        categoryOptions = categoryOptions || [];
        query = (query || '').toLowerCase();
        return filterByCategory(categoryOptions, query);
      };

      function filterByCategory(categoryOptions, query) {
        const terms = query.split(' ');
        return categoryOptions.filter(function (option) {
          if (option.virtualCategory) {
            return true;
          }
          if (option.custom && !option.customName) {
            return false;
          }

          return terms.every(function (term) {
            const name = option.name.toLowerCase();
            const synonymString =
              option.category && option.category.synonyms ? option.category.synonyms.join(' ').toLowerCase() : '';
            return name.includes(term) || synonymString.includes(term);
          });
        });
      }
    },
  ]);
})();

(function () {
  const app = angular.module('app');

  app.controller('SystemPhoneListController', [
    '$scope',
    'SystemPhone',
    function ($scope, SystemPhone) {
      $scope.system_phone_collection = SystemPhone.query();
    },
  ]);

  app.controller('SystemPhoneViewController', [
    '$scope',
    '$stateParams',
    'SystemPhone',
    function ($scope, $stateParams, SystemPhone) {
      $scope.system_phone = SystemPhone.get({
        id: $stateParams.id,
      });
    },
  ]);

  app.controller('SystemPhoneNewController', [
    '$rootScope',
    '$scope',
    '$state',
    'SystemPhone',
    function ($rootScope, $scope, $state, SystemPhone) {
      $scope.system_phone = new SystemPhone();

      $scope.save = function () {
        $scope.system_phone.$save(
          function () {
            $state.go('systemPhones');
          },
          function (error) {
            $rootScope.$broadcast('message:group:display', error.data);
          },
        );
      };
    },
  ]);

  app.controller('SystemPhoneEditController', [
    '$rootScope',
    '$scope',
    '$state',
    '$stateParams',
    'SystemPhone',
    function ($rootScope, $scope, $state, $stateParams, SystemPhone) {
      $scope.system_phone = SystemPhone.get({
        id: $stateParams.id,
      });

      $scope.save = function () {
        $scope.system_phone.$update(
          function () {
            $state.go('systemPhones');
          },
          function (error) {
            $rootScope.$broadcast('message:group:display', error.data);
          },
        );
      };
    },
  ]);
})();

import React from 'react';

import { Maybe } from '@admin/schema';

import { Toggle } from './toggle';

export const OneTimeFeeInput: React.FC<{
  included: boolean;
  oneTimeFee?: Maybe<number>;
  setIncluded(included: boolean): void;
  onChange(oneTimeFee?: number): void;
}> = ({ included, oneTimeFee, setIncluded, onChange }) => {
  const onToggle = (newIncluded: boolean) => {
    if (!newIncluded) {
      onChange(undefined);
    }
    setIncluded(newIncluded);
  };

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newOneTimeFee = event.currentTarget.value ? Number(event.currentTarget.value) : undefined;
    onChange(newOneTimeFee);
  };

  return (
    <div className="form-group">
      <label className="control-label">One Time Fee</label>
      <div className="row">
        <Toggle className="col-md-2" included={included} onToggle={onToggle} />
        <div className="col-md-2">
          {included && (
            <div className="input-group">
              <span className="input-group-addon">$</span>
              <input type="number" className="form-control" value={oneTimeFee ?? ''} onChange={onInputChange} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

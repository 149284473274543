import template from '@admin/angular/templates/order_moving_address_selectors.html';

import angular from 'angular';

const app = angular.module('app');

app.component('orderMovingAddressSelectors', {
  template,
  bindings: {
    account: '<',
    order: '<',
    region: '=',
    managing: '=',
  },
  controller: [
    function ctrl() {
      this.originAddressSelected = (address) => {
        this.order.moving_operation.origin_address = address;
      };

      this.destinationAddressSelected = (address) => {
        this.order.moving_operation.destination_address = address;
      };

      this.originGeocoded = ({ potentially_invalid }) => {
        this.originPotentiallyInvalid = potentially_invalid;
      };

      this.destinationGeocoded = ({ potentially_invalid }) => {
        this.destinationPotentiallyInvalid = potentially_invalid;
      };

      this.$onInit = () => {
        this.showAccountAddresses = true;
        this.warehouseReturn = false;
        this.originPotentiallyInvalid = false;
        this.destinationPotentiallyInvalid = false;
        this.onWarehouseSelect = () => {};
      };
    },
  ],
});

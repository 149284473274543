import React, { useEffect, useState } from 'react';
import { depotsURL } from '@admin/config/routes';
import { client } from '@admin/libraries/apollo';
import { isEqual } from 'lodash';
import { DepotInput, useDepotQuery, useModifyDepotMutation } from '@admin/schema';
import { DepotForm } from './depot_form';

export const EditDepot: React.FC<{ id: string }> = ({ id }) => {
  const [savedDepot, setSavedDepot] = useState<DepotInput | undefined>(undefined);
  const [depot, setDepot] = useState<DepotInput | undefined>(undefined);
  const [error, setError] = useState<string | undefined>();

  const { data, loading: loadingQuery } = useDepotQuery({ client, variables: { id } });
  const [execute, { loading: loadingMutation }] = useModifyDepotMutation({ client });

  const onSave = async (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    if (!depot || loadingMutation) {
      return;
    }
    const input = depot;
    const variables = { input, id };
    const response = await execute({ variables });
    if (response && response.data) {
      setError(response.data.modifyDepot.error || undefined);
      if (response.data.modifyDepot.error === null) {
        window.location.href = depotsURL();
      }
    }
  };

  useEffect(() => {
    if (data && data.depot && depot === undefined) {
      const newDepot = {
        name: data.depot.name,
        abbreviation: data.depot.abbreviation,
        active: data.depot.active,
        address: {
          aptsuite: data.depot.address.aptsuite || '',
          street: data.depot.address.street,
          city: data.depot.address.city,
          state: data.depot.address.state,
          country: data.depot.address.country || 'United States',
          zip: data.depot.address.zip,
          latitude: data.depot.address.latitude ?? undefined,
          longitude: data.depot.address.longitude ?? undefined,
        },
        regionIDs: data.depot.regions.map((region) => region.id),
      };
      setSavedDepot(newDepot);
      setDepot(newDepot);
    }
  }, [data, depot]);

  return (
    <div className="panel">
      <div className="panel-heading">
        <h3 className="panel-title">Edit Depot</h3>
      </div>
      {error && <div className="alert alert-danger">{error}</div>}
      {loadingQuery || !depot ? null : (
        <form
          onSubmit={async (event) => {
            event.preventDefault();
            event.stopPropagation();
          }}
        >
          <div className="panel-body">
            <DepotForm depot={depot} onChange={setDepot} />
          </div>
          <div className="panel-footer text-right">
            <button
              type="button"
              className="btn btn-primary"
              disabled={loadingMutation || isEqual(savedDepot, depot)}
              onClick={onSave}
            >
              Save
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

import React from 'react';

import { PaginatedConfigurationListQuery } from '@admin/schema';
import { Table as BSTable } from '@shared/components/bootstrap';

import { Row } from './table/row';

type ConfigurationType = PaginatedConfigurationListQuery['paginated']['results'][number];

export const Table: React.FC<{
  configurations: ConfigurationType[];
}> = ({ configurations }) => (
  <BSTable>
    <thead>
      <tr>
        <th>Name</th>
        <th>Refresh Interval Minutes</th>
        <th>Audit Expiration Hours</th>
        <th>Last Ran At</th>
        <th>Pending Audit Count</th>
        <th />
      </tr>
    </thead>
    <tbody>
      {configurations.map((configuration) => (
        <Row key={configuration.id} configuration={configuration} />
      ))}
    </tbody>
  </BSTable>
);

import angular from 'angular';

const app = angular.module('app');

app.factory('FollowupService', [
  'CallCenterUnavailability',
  'FollowupTimeZone',
  'TimeZone',
  (CallCenterUnavailability, FollowupTimeZone, TimeZone) => {
    const ARIZONA_TIME = 'Arizona';
    const MOUNTAIN_TIME = 'Mountain Time (US & Canada)';

    const timeZonePromise = FollowupTimeZone.query().$promise.then((timeZones) => {
      _.each(timeZones, (tz) => {
        tz.visible = tz.full_name !== ARIZONA_TIME;
      });
      return timeZones;
    });

    const unavailabilityPromise = CallCenterUnavailability.query().$promise.then((dates) =>
      dates.map((date) => moment(date, 'YYYY-MM-DD').toDate()),
    );

    const service = {
      addErrors: addErrors,
      clearErrors: clearErrors,
      constants: {
        FOLLOWUP_FIELDS_SELECTOR: 'followup-fields',
        SALES_DEPARTMENT: 'sales',
      },
      fetchTimeZoneGuess: fetchTimeZoneGuess,
      fetchTimeZones: fetchTimeZones,
      fetchUnavailabilities: fetchUnavailabilities,
      getMatchingTimeZone: getMatchingTimeZone,
      getFollowupTime: getFollowupTime,
      hasError: hasError,
      updateSelections: updateSelections,
    };

    return service;

    // Function declarations

    function addErrors(selections) {
      return {
        day: _.every([selections.year, selections.month, selections.date], _.isNumber)
          ? null
          : 'Date must be populated',
        time: _.every([selections.hour, selections.minute], _.isNumber) ? null : 'Time must be populated',
        timeZone: selections.timeZone ? null : 'Time zone must be populated',
      };
    }

    function clearErrors(previousErrors, updates) {
      const errors = angular.copy(previousErrors);

      if (_.some([updates.year, updates.month, updates.date], _.isNumber)) {
        errors.day = null;
      }
      if (_.some([updates.hour, updates.minute], _.isNumber)) {
        errors.time = null;
      }
      if ('timeZone' in updates) {
        errors.timeZone = null;
      }

      return errors;
    }

    function fetchTimeZoneGuess(data, timeZones) {
      const params = {};

      if (data.zip) {
        params.zip = data.zip;
      } else if (data.phone) {
        params.area_code = data.phone.substr(2, 3);
      }

      return TimeZone.get(params).$promise.then((tzData) => service.getMatchingTimeZone(tzData, timeZones));
    }

    function fetchTimeZones() {
      return timeZonePromise.then(angular.copy);
    }

    function fetchUnavailabilities() {
      return unavailabilityPromise;
    }

    function getFollowupTime(timeObj) {
      const followupTime = moment(timeObj);
      followupTime.subtract(timeObj.offsetMinutes, 'minutes');
      return followupTime.toDate();
    }

    function getMatchingTimeZone(tzData, timeZones) {
      const tz = _.find(
        timeZones,
        (timezone) =>
          timezone.time_zone_offset === Number(tzData.time_zone_offset) && timezone.dst_flag === tzData.dst_flag,
      );

      // If needed, show ARIZONA_TIME and hide Mountain Time.
      if (tz && tz.full_name === ARIZONA_TIME) {
        tz.visible = true;
        const mountainTime = _.find(timeZones, { full_name: MOUNTAIN_TIME });
        if (mountainTime) {
          mountainTime.visible = false;
        }
      }
      return tz;
    }

    function hasError(errors) {
      return _.some(errors, _.isString);
    }

    function updateSelections(selections, updates) {
      return _.assign({}, selections, updates);
    }
  },
]);

import angular from 'angular';

const app = angular.module('app');

app.constant('ObjectionsList', {
  FOLLOWUP_SCHEDULED: 'Follow Up Scheduled',
  FOLLOWUP_EXPIRED: 'Follow Up Expired',
  CURRENT_CUSTOMER: 'Current Customer',
  CALL_ISSUE: 'Call Issue',
});

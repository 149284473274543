import { faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import { Manager, Selector, Uploader } from '@shared/components/files';

export const FileFormControl: React.FC<{
  multiple?: boolean;
  saving?: boolean;
  disabled?: boolean;
  label?: string;
  accept: string;
  onUpload(file: File, signedID: string): void;
}> = ({ multiple, label, onUpload, accept, saving, disabled }) => (
  <Manager onSave={onUpload}>
    {({ uploads }) => (
      <>
        <label className="btn btn-default">
          <FontAwesomeIcon icon={saving ? faSpinner : faPaperclip} spin={saving} />{' '}
          <Selector disabled={disabled} multiple={!!multiple} accept={accept} /> {label ?? 'Choose File'}
        </label>
        {uploads.map((entry) => (
          <Uploader key={entry.uuid} {...entry} />
        ))}
      </>
    )}
  </Manager>
);

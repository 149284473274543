import { round } from 'lodash';

import { ClaimSelection } from '../types';

export interface IResolutionEdits {
  [selectionID: string]: number | undefined;
}

export type IResolutionInput = {
  selectionID: string;
  amount?: number;
};

export const selectionAmount = (edits: IResolutionEdits, selection: ClaimSelection) =>
  edits.hasOwnProperty(selection.id) ? edits[selection.id] : selection.resolution?.amount;

export const buildOfferInput = (
  itemEdits: IResolutionEdits,
  propertyID: string | undefined,
  propertyEdit: IResolutionEdits,
  issueID: string | undefined,
  issueEdit: IResolutionEdits,
) => {
  let input = {};
  const editedItemIDs = Object.keys(itemEdits);
  if (editedItemIDs.length) {
    const itemResolutions: IResolutionInput[] = [];
    editedItemIDs.forEach((selectionID) => {
      const amount = itemEdits[selectionID];
      itemResolutions.push({ selectionID, amount: amount ? round(amount, 2) : amount });
    });
    input = { itemResolutions };
  }
  if (propertyID && Object.keys(propertyEdit).length) {
    const amount = propertyEdit[propertyID];
    const propertyResolution = { selectionID: propertyID, amount: amount ? round(amount, 2) : amount };
    input = { ...input, propertyResolution };
  }
  if (issueID && Object.keys(issueEdit).length) {
    const amount = issueEdit[issueID];
    const issueResolution = { selectionID: issueID, amount: amount ? round(amount, 2) : amount };
    input = { ...input, issueResolution };
  }
  return input;
};

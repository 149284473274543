import { createContext } from 'react';

import { SelfStorage__AccountForRentingFragment } from '@admin/schema';

export const Context = createContext<{
  account?: SelfStorage__AccountForRentingFragment;
  element: IStripeElement;
}>({
  element: {
    mount: () => {
      /* noop */
    },
    unmount: () => {
      /* noop */
    },
  },
});

import styled from '@emotion/styled';
import * as React from 'react';

import { accountURL } from '@admin/config/routes';
import { Roles } from '@admin/components/helpers/roles';
import { UserRole } from '@admin/types';
import { client } from '@admin/libraries/apollo';
import { Spacer } from '@shared/components/helpers';

import {
  Logistics__FulfillmentMethodTypeEnum,
  Logistics__PurchaseOrderInput,
  useAccountBreadCrumbsQueryQuery,
} from '@admin/schema';
import { LogisticsPurchaseOrderForm, DEFAULT_ADDRESS } from './form/form';
import { AccountBreadcrumbs } from '../breadcrumbs/account_breadcrumbs';

const LargeFont = styled.div`
  font-size: 2.5em;
`;

export const LogisticsNewPurchaseOrder: React.FC<{ accountID: string }> = ({ accountID }) => {
  const formData: Logistics__PurchaseOrderInput = {
    accountID,
    number: '',
    fulfillmentMethod: Logistics__FulfillmentMethodTypeEnum.Freight,
    shipToAddress: DEFAULT_ADDRESS,
    fulfillmentExpectations: [],
    phoneNumber: '',
  };

  const account = useAccountBreadCrumbsQueryQuery({ client, variables: { accountID } }).data?.account;

  const onSubmit = async () => {
    window.location.href = accountURL({ id: accountID });
  };

  return (
    <Roles show={[UserRole.Admin, UserRole.EnterpriseManager, UserRole.Manager]}>
      {account && (
        <LargeFont>
          <AccountBreadcrumbs account={account} newItem={'New Purchase Order'} />
        </LargeFont>
      )}
      <Spacer height="10px" />
      <LogisticsPurchaseOrderForm formData={formData} onSave={onSubmit} />
    </Roles>
  );
};

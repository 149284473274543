import React from 'react';
import { Formik, Form as FormikForm } from 'formik';

import {
  Landing__Location__Neighborhood,
  Landing__Location__Route,
  Landing__Location__RouteInput,
  useLandingLocationRouteUpsertMutation,
  useLandingLocationRouteQuery,
  LandingLocationRouteQuery,
  Landing__Location__RouteLocationKind,
  Landing__Location__RouteLocationInput,
  useRouteLocationOptionsQuery,
} from '@admin/schema';

import { client } from '@admin/libraries/apollo';

import { Breadcrumb, Panel, AnchorButton as Link, Button, Alert } from '@shared/components/bootstrap';
import { PageHeader } from '@admin/components/helpers/page_header';
import { Spinner } from '@admin/components/spinner';
import { FormikSelectFormGroup } from '@shared/components/fields/formik/formik_select_form_group';
import { FormikInputFormGroup } from '@shared/components/fields/formik/formik_input_form_group';
import { FormikCheckboxFormGroup } from '@admin/components/fields/formik/formik_checkbox_form_group';

type FormikInput = Omit<Landing__Location__RouteInput, 'origin' | 'destination'> & {
  origin: string;
  destination: string;
};

const FormikPointFormGroup: React.FC<{
  id: string;
  name: string;
  label: string;
}> = (props) => {
  const { data, loading } = useRouteLocationOptionsQuery({ client });

  return (
    <FormikSelectFormGroup disabled={loading} {...props}>
      <option value=""> - Location - </option>
      {data?.states.map((state) => (
        <React.Fragment key={state.id}>
          <option key={state.id} value={`${Landing__Location__RouteLocationKind.State}:${state.id}`}>
            {state.name}
          </option>
          {state.cities.map((city) => (
            <React.Fragment key={city.id}>
              <option key={city.id} value={`${Landing__Location__RouteLocationKind.City}:${city.id}`}>
                &emsp;{city.name}
              </option>
              {city.neighborhoods.map((neighborhood) => (
                <option
                  key={neighborhood.id}
                  value={`${Landing__Location__RouteLocationKind.Neighborhood}:${neighborhood.id}`}
                >
                  &emsp;&emsp;{neighborhood.name}
                </option>
              ))}
            </React.Fragment>
          ))}
        </React.Fragment>
      ))}
    </FormikSelectFormGroup>
  );
};

const Fields: React.FC<{
  route?: LandingLocationRouteQuery['route'];
  onSave(neighborhood: Pick<Landing__Location__Neighborhood, 'id'>): void;
}> = ({ route, onSave }) => {
  const [save, { loading, data }] = useLandingLocationRouteUpsertMutation({
    client,
    onCompleted: ({ result }) => {
      if (result?.resource) onSave(result.resource);
    },
  });

  const onSubmit = ({ origin, destination, ...input }: FormikInput) => {
    save({
      variables: {
        id: route?.id,
        input: {
          ...input,
          origin: parseLocation(origin),
          destination: parseLocation(destination),
        },
      },
    });
  };

  const parseLocation = (value: string): Landing__Location__RouteLocationInput => {
    const [kind, id] = value.split(':');

    return { kind: kind as Landing__Location__RouteLocationKind, id };
  };

  const locationTypenameToKind = (
    value: 'Landing__Location__State' | 'Landing__Location__City' | 'Landing__Location__Neighborhood',
  ) => {
    switch (value) {
      case 'Landing__Location__State':
        return Landing__Location__RouteLocationKind.State;
      case 'Landing__Location__City':
        return Landing__Location__RouteLocationKind.City;
      case 'Landing__Location__Neighborhood':
        return Landing__Location__RouteLocationKind.Neighborhood;
    }
  };

  return (
    <Formik<FormikInput>
      initialValues={{
        slug: route?.slug ?? '',
        featured: route?.featured ?? false,
        origin: route?.origin ? `${locationTypenameToKind(route.origin.__typename!)}:${route.origin.id}` : '',
        destination: route?.destination
          ? `${locationTypenameToKind(route.destination.__typename!)}:${route.destination.id}`
          : '',
      }}
      onSubmit={onSubmit}
    >
      <FormikForm>
        <Panel>
          <Panel.Body>
            {data?.result?.error && <Alert style="danger">{data.result.error}</Alert>}
            <FormikPointFormGroup id="origin" name="origin" label="Origin:" />
            <FormikPointFormGroup id="destination" name="destination" label="Destination:" />
            <FormikInputFormGroup
              id="slug"
              name="slug"
              label="Slug:"
              help={'eg: california/los-angeles/santa-monica-to-boston'}
            />
            <FormikCheckboxFormGroup label="Featured" id="featured" name="featured" />
          </Panel.Body>
          <Panel.Footer align="right">
            <Link disabled={loading} kind="default" href="/landing/location/routes">
              Cancel
            </Link>
            <Button loading={loading} kind="primary" type="submit">
              Save
            </Button>
          </Panel.Footer>
        </Panel>
      </FormikForm>
    </Formik>
  );
};

export const LandingLocationRouteForm: React.FC<{
  id?: string;
  onSave(route: Pick<Landing__Location__Route, 'id'>): void;
}> = ({ id, onSave }) => {
  const { data } = useLandingLocationRouteQuery({
    client,
    fetchPolicy: 'network-only',
    variables: { id: id! },
    skip: !id,
  });
  const route = data?.route;

  return (
    <>
      <PageHeader>
        <Breadcrumb>
          <Breadcrumb.Item>Landing</Breadcrumb.Item>
          <Breadcrumb.Item>Location</Breadcrumb.Item>
          <Breadcrumb.Item>
            <a href="/landing/location/cities">Routes</a>
          </Breadcrumb.Item>
          {id && (
            <Breadcrumb.Item>
              <a href={`/landing/location/routes/${id}`}>{route?.id ?? '-'}</a>
            </Breadcrumb.Item>
          )}
          <Breadcrumb.Item active>{id ? 'Edit' : 'New'}</Breadcrumb.Item>
        </Breadcrumb>
      </PageHeader>

      {!id || route ? <Fields route={route} onSave={onSave} /> : <Spinner />}
    </>
  );
};

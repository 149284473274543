import angular from 'angular';

const app = angular.module('app');

app.controller('MessagesController', [
  '$scope',
  '$filter',
  'Message',
  'MessageThread',
  'ConfirmationService',
  'PaginationService',
  'ErrorService',
  'AccountService',
  'SmsConstants',
  'Lead',
  function messagesController(
    $scope,
    $filter,
    Message,
    MessageThread,
    ConfirmationService,
    PaginationService,
    ErrorService,
    AccountService,
    SmsConstants,
    Lead,
  ) {
    this.isForAccount = () => this.recipientType === 'Account';
    this.isForLead = () => this.recipientType === 'Lead';
    this.isForAssignment = () => this.recipientType === 'Assignment';

    this.maxLength = SmsConstants.MAX_LENGTH;
    this.pagination = PaginationService.initialize();
    this.smsEnabled = false;

    this.isResolvable = (message) => message.is_resolvable === true;
    this.lightbox = (image) => {
      this.modal = {
        visible: !!image,
        source: $filter('resize')(image, 1920, 1920, 'clip'),
      };
    };

    this.save = () => {
      if (this.isForAccount() && this.phoneToSendTo) {
        this.message.phone_id = this.phoneToSendTo.id;
      } else if (this.isForLead() && this.recipient) {
        this.message.phone_id = this.recipient.phone_id;
      } else if (this.isForAssignment() && this.recipient) {
        this.message.recipient_id = this.phoneToSendTo.recipient_id;
        this.message.recipient_type = this.phoneToSendTo.recipient_type;
        this.message.phone_id = this.phoneToSendTo.id;
      }

      this.smsEnabled = false;

      if (this.message.phone_id) {
        this.message.$save(
          () => {
            this.initMessage();
            this.initMessages();
          },
          (error) => {
            this.initMessages();
            ErrorService.handle(error);
          },
        );
      }
    };

    this.resolve = (message) => {
      ConfirmationService.confirm({
        title: 'Are you sure?',
        description: 'Are you sure you want to mark this message as resolved?',
        acceptButton: 'Resolve Message',
        declineButton: 'Cancel',
      }).then(() => {
        this.smsEnabled = false;
        const params = {
          recipient_id: this.recipient.id,
          recipient_type: this.recipientType,
        };

        MessageThread.resolve(params).$promise.then(() => {
          /* eslint-disable no-param-reassign */
          message.is_resolvable = false;
          message.unread_count = 0;
          /* eslint-enable no-param-reassign */
          this.loadPreferences();
        });
      });
    };

    this.initMessage = () => {
      this.message = new Message();
      this.message.body = '';
      this.message.recipient_type = this.recipientType;
      this.message.recipient_id = this.recipient.id;
      if (this.order) {
        if (this.order.parent_id) {
          this.order_id = this.order.parent_id;
        } else {
          this.order_id = this.order.id;
        }
        this.message.order_id = this.order_id;
      }
      if (this.isForAssignment()) {
        this.assignment_ids = this.recipient.assignments.map((assignment) => assignment.id);
      }
    };

    this.initMessages = () => {
      if (this.order) {
        if (this.order.parent_id) {
          this.order_id = this.order.parent_id;
        } else {
          this.order_id = this.order.id;
        }
      }
      return this.messageQuery();
    };

    this.messageQuery = () => {
      let filters = {
        recipient_type: this.recipientType,
        recipient_id: this.recipient.id,
        page: this.pagination.page,
        order_id: this.order_id,
      };

      if (this.isForAssignment() && this.assignment_ids.length) {
        filters = {
          recipient_type: this.recipientType,
          'recipient_ids[]': this.assignment_ids,
          page: this.pagination.page,
        };
      }

      return Message.query(filters).$promise.then((data) => {
        this.pagination.prepare(data.pagination);
        this.messages = data.results;
        this.loadPreferences();
      });
    };
    const outgoingAllowed = async (number) => {
      try {
        let params = {
          recipient_id: this.recipient.id,
          recipient_type: this.recipientType,
          number,
        };
        if (this.isForAssignment()) {
          params = {
            recipient_type: this.recipientType,
            'recipient_ids[]': this.assignment_ids,
            page: this.pagination.page,
          };
        }
        const resp = await Message.outgoing_allowed(params).$promise;
        return resp.allowed !== false;
      } catch (error) {
        // In the event that there is an error retrieving allowed from the server, we say we are allowed.
        // Otherwise, if something is wrong with this endpoint, all messages would be disallowed.
        return true;
      }
    };

    this.loadPreferences = async () => {
      const number = this.isForAccount() ? this.phoneToSendTo.number : this.recipient.phone;
      const allowed = await outgoingAllowed(number);
      this.smsEnabled = this.recipientHasPhone() && allowed;
      this.setPhoneSmsConsentIfLead();
      this.setPlaceHolder();
      $scope.$apply();
    };

    this.setPhoneSmsConsentIfLead = async () => {
      if (this.isForLead()) {
        const lead = await Lead.get({ id: this.recipient.id }).$promise;
        this.recipient.phone_commercial_sms = lead.phone_commercial_sms;
        this.recipient.phone_transactional_sms = lead.phone_transactional_sms;
      }
    };

    this.loadRecipientPhone = () => {
      if (this.isForAccount() && this.recipient.customers) {
        this.phoneList = AccountService.getPhoneList(this.recipient.customers);
        if (this.order && this.order.contact) {
          const phoneListItem = this.order.contact.phone_callable;
          phoneListItem.name = `${this.order.contact.name}, Day of Contact`;
          if (this.order.contact) {
            this.phoneList.unshift(phoneListItem);
          }
          [this.phoneToSendTo] = this.phoneList;
        }
        [this.phoneToSendTo] = this.phoneList;
        this.phoneToFilterBy = null;
      } else if (this.isForAssignment()) {
        this.phoneList = [];
        let result = [];
        this.recipient.phone_callables.forEach((phoneCallable) => {
          this.recipient.assignments.forEach((assignment) => {
            if (assignment.user.id === phoneCallable.user.id) {
              const phoneListItem = JSON.parse(JSON.stringify(phoneCallable));
              phoneListItem.name = `${assignment.user.name}, ${assignment.role}`;
              phoneListItem.recipient_id = assignment.id;
              phoneListItem.recipient_type = 'Assignment';
              phoneListItem.id = phoneCallable.phone.id;
              result = result.concat(phoneListItem);
            }
          });
        });
        this.phoneList = result;
        [this.phoneToSendTo] = this.phoneList;
        this.phoneToFilterBy = null;
      }
    };

    this.recipientHasPhone = () => {
      const isToday = (someDate) => {
        const today = new Date();
        return (
          someDate.getDate() === today.getDate() &&
          someDate.getMonth() === today.getMonth() &&
          someDate.getFullYear() === today.getFullYear()
        );
      };

      if (this.isForAccount()) {
        return !!this.phoneToSendTo;
      } else if (this.isForLead()) {
        return !!this.recipient.phone_id;
      } else if (this.isForAssignment) {
        return !!this.phoneToSendTo && isToday(this.recipient.arrival);
      }
      return false;
    };

    this.setPlaceHolder = () => {
      if (this.recipientHasPhone()) {
        if (!this.smsEnabled) {
          this.placeholder = SmsConstants.PLACEHOLDER_UNSUBSCRIBED;
        } else {
          this.placeholder = SmsConstants.PLACEHOLDER;
        }
      } else if (this.isForAssignment()) {
        this.placeholder = SmsConstants.PLACEHOLDER_INACTIVE_DISPATCH;
      } else {
        this.placeholder = SmsConstants.PLACEHOLDER_NO_PHONE;
      }
    };

    $scope.$on('messages_controller.loadPreferences', async (_event, cb) => {
      await this.loadPreferences();
      cb();
    });

    this.$onInit = () => {
      const recipientWatch = $scope.$watch('messages_controller.recipient.$resolved', (value) => {
        if (value) {
          this.loadRecipientPhone();
          this.initMessage();
          this.initMessages();

          recipientWatch();
        }
      });
    };

    this.canSendMessage = () =>
      this.message && this.message.body.length > 0 && this.message.body.length <= this.maxLength;

    this.handleFilterChange = () => {
      if (this.phoneToFilterBy && this.phoneToFilterBy.active) {
        this.phoneToSendTo = this.phoneToFilterBy;
      }
    };

    this.hasMultiplePhones = () => this.phoneList && this.phoneList.length > 1;

    this.statusLabel = (message) =>
      message.consent_blocked ? 'blocked' : message.twilio_status || message.iterable_status;
  },
]);

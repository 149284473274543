const app = angular.module('app');

app.controller('SourcesFormController', [
  '$rootScope',
  '$scope',
  function ($rootScope, $scope) {
    $scope.onSave = (source) => {
      $rootScope.$broadcast('message:display', {
        type: 'info',
        body: `····${source.number} is now attached to this account.`,
      });

      $rootScope.$broadcast('inlineform:saved', {
        form: 'billingSourceAdd',
      });

      // Reload account model since it should have new address associated
      $scope.account.$get({ id: $scope.account.id });
    };

    $scope.onClose = () => {
      $scope.resetForm('billingSourceAdd');
    };
  },
]);

import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { Maybe, SelfStorage__PromotionFragment } from '@admin/schema';

import { Discount } from './discount';

export const Rows: React.FC<{
  quotedPromotion?: Maybe<SelfStorage__PromotionFragment>;
  unitPromotion?: Maybe<SelfStorage__PromotionFragment>;
  selectedPromotion?: Maybe<SelfStorage__PromotionFragment>;
  subtotal?: number;
}> = ({ quotedPromotion, unitPromotion, selectedPromotion, subtotal }) => {
  const { formState, register, setValue } = useFormContext<{
    promotionID?: string;
  }>();

  useEffect(() => {
    setValue('promotionID', unitPromotion?.id, formState.isSubmitted);
  }, [unitPromotion?.id]);

  return (
    <tr>
      <td className="text-left">
        <strong>Promotion</strong>
      </td>
      <td className="text-left">
        {quotedPromotion || unitPromotion ? (
          <select name="promotionID" className="form-control" ref={register}>
            <option value="">Remove promotion</option>
            {unitPromotion && <option value={unitPromotion.id}>{unitPromotion.name}</option>}
            {quotedPromotion && quotedPromotion?.id !== unitPromotion?.id && (
              <option value={quotedPromotion.id}>{quotedPromotion.name}</option>
            )}
          </select>
        ) : (
          '-'
        )}
      </td>
      <td className="text-right">
        {subtotal && selectedPromotion ? <Discount amount={subtotal} promotion={selectedPromotion} /> : '-'}
      </td>
    </tr>
  );
};

import { InputFormGroup } from '@admin/components/fields/input_form_group';
import { Panel } from '@admin/components/helpers/panel';
import { Pagination } from '@admin/components/pagination';
import { Spinner } from '@admin/components/spinner';
import { client } from '@admin/libraries/apollo';
import { useTrainingUploadAttestationsPaginatedQuery } from '@admin/schema';
import { Col, Row, Table } from '@shared/components/bootstrap';
import { Timestamp } from '@shared/components/helpers';
import { useDebounce } from '@shared/hooks';
import React, { useState } from 'react';

export const TrainingUploadAttestationsList: React.FC<{ trainingUploadID: string }> = ({ trainingUploadID }) => {
  const [page, setPage] = useState<number | undefined>();
  const [userQuery, setUserQuery] = useState<string | undefined>();

  const { data, loading } = useTrainingUploadAttestationsPaginatedQuery({
    client,
    variables: { trainingUploadID: trainingUploadID, userQuery: useDebounce(userQuery), page: page },
  });
  const paginated = data?.paginated;

  return (
    <>
      <Panel>
        <Panel.Body>
          <Row>
            <Col sm={6}>
              <InputFormGroup
                label="Search"
                type="text"
                value={userQuery ?? ''}
                onChange={(event) => {
                  setUserQuery(event.target.value || '');
                  setPage(1);
                }}
                placeholder="Search by user's name"
              />
            </Col>
          </Row>
        </Panel.Body>
      </Panel>
      <Panel>
        <Table responsive striped>
          <thead>
            <tr>
              <th>User</th>
              <th>Attested At</th>
            </tr>
          </thead>
          <tbody>
            {paginated?.results.map((attestation) => (
              <tr key={attestation.id}>
                <td>{attestation.user.name}</td>
                <td>{<Timestamp value={attestation.createdAt} format={Timestamp.Format.DateTime} />}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        {loading && <Spinner />}
        {paginated && <Pagination pagination={paginated.pagination} onPage={setPage} />}
      </Panel>
    </>
  );
};

import * as React from 'react';

import { AvailabilityCalender } from '@admin/components/availability_widget/availability_calendar';
import { CheckAvailabilityButton } from '@admin/components/availability_widget/check_availability_button';
import { AvailabilityPreferencesResource } from '@admin/resources/availability_preferences';

interface IProps {
  onCheck: () => void;
  onDaySelected: (dates: Date[]) => void;
  leadId: number;
  selectedDays: Date[];
  disabled?: boolean;
  shouldUpdateDates?: boolean;
  planId?: number;
  zip?: string;
}

export const AvailabilityWidget: React.FC<IProps> = ({
  selectedDays,
  onDaySelected,
  disabled,
  shouldUpdateDates,
  leadId,
  onCheck,
}) => {
  const [month, setMonth] = React.useState<Date>(new Date());

  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
      <AvailabilityCalender
        selectedDays={selectedDays}
        onDaySelected={onDaySelected}
        disabled={disabled}
        month={month}
        onMonthChange={setMonth}
      />
      <CheckAvailabilityButton
        onClick={() => {
          if (shouldUpdateDates) {
            AvailabilityPreferencesResource.updatedPreferredDates(selectedDays, leadId);
          }
          onCheck();
        }}
        disabled={disabled || selectedDays.length === 0}
      />
    </div>
  );
};

import { Panel } from '@admin/components/helpers/panel';
import { Roles } from '@admin/components/helpers/roles';
import { ROLES } from '@admin/config/roles';
import { accountURL } from '@admin/config/routes';
import { CreateTicket as CreateTechSupportTicket } from '@admin/components/tickets/tech_support/create_ticket';
import { client } from '@admin/libraries/apollo';
import { TicketTicketableType, UserRole } from '@admin/types';
import {
  Logistics__SkuTeachInStatusEnum,
  LogisticsDetailedSkuDocument,
  LogisticsDetailedSkuQuery,
  Status,
  useReteachLogisticsSkuMutation,
  useSkuDeleteMutation,
} from '@admin/schema';
import { logisticsEditSKUURL } from '@admin/config/routes';
import { Alert, Button } from '@shared/components/bootstrap';
import { Spacer } from '@shared/components/helpers';
import styled from '@emotion/styled';
import { COLORS } from '@root/colors';
import { capitalize, intersection, lowerCase, startCase } from 'lodash';
import React, { useState } from 'react';
import { MarkDuplicateModal } from './mark_duplicate_modal';

const Link = styled.a`
  color: ${COLORS.blueLight};
`;

type LogisticsSku = LogisticsDetailedSkuQuery['logisticsSku'];

export const FALLBACK_ERROR = 'Sorry, an unexpected error occurred. If the problem persists, contact Tech Support.';

export const LogisticsSkuBasicInfoPanel: React.FC<{ sku: LogisticsSku }> = ({ sku }) => {
  const [error, setError] = React.useState<string | undefined>(undefined);
  const [executeReteach, { loading: loadingReteach }] = useReteachLogisticsSkuMutation({
    client,
    awaitRefetchQueries: true,
    refetchQueries: [{ query: LogisticsDetailedSkuDocument, variables: { id: sku.id } }],
  });

  const [executeDelete, { loading: deleting }] = useSkuDeleteMutation({ client });

  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);

  async function onDelete(skuID: string) {
    try {
      const result = await executeDelete({ variables: { skuID } });

      if (result?.data?.skuDelete.status === Status.Ok) {
        setError(undefined);
      } else {
        setError(result?.data?.skuDelete.error || FALLBACK_ERROR);
        window.scrollTo(0, 0);
      }
    } catch (e) {
      setError(FALLBACK_ERROR);
      window.scrollTo(0, 0);
    }
  }

  const accountLink = intersection(ROLES, [UserRole.EnterpriseManager, UserRole.Admin, UserRole.Manager]).length > 0;

  return (
    <>
      <Panel>
        {error && <Alert style="danger">{error}</Alert>}
        <Panel.Header>
          <Panel.Collapse collapsed={collapsed} onChange={setCollapsed} />
          <Panel.Title>
            Sku {sku.value} Details {sku.deletedAt ? '(Deleted)' : null}
            <Roles show={[UserRole.Admin, UserRole.Manager, UserRole.EnterpriseManager]}>
              <span style={{ float: 'right' }}>
                <CreateTechSupportTicket
                  ticketable={{
                    id: sku.id,
                    type: TicketTicketableType.LogisticsSku,
                    displayName: 'Sku ' + sku.value,
                  }}
                />
              </span>
            </Roles>
          </Panel.Title>
        </Panel.Header>
        <Panel.Body>
          {!collapsed && (
            <>
              <div className="row">
                <div className="col-md-6">
                  <ul className="list-unstyled">
                    <li>
                      <strong>Account: </strong>
                      {accountLink && (
                        <Link href={accountURL({ id: sku.account.id })}>{sku.account.customer.name}</Link>
                      )}
                      {!accountLink && <span>{sku.account.customer.name}</span>}
                    </li>
                    <li>
                      <strong>Description:</strong>
                      {` ${sku.description}`}
                    </li>
                    <li>
                      <strong>Counting Unit:</strong>
                      {` ${startCase(lowerCase(sku.countingUnit))}`}
                    </li>
                    <li>
                      <strong>GTIN Barcode:</strong>
                      {` ${sku.gtinBarcode ?? ''}`}
                    </li>
                    <li>
                      <strong>Item Weight:</strong>
                      {` ${sku.itemWeight ?? ''}`}
                    </li>
                    <li>
                      <strong>Item Length:</strong>
                      {` ${sku.itemLength ?? ''}`}
                    </li>
                    <li>
                      <strong>Item Width:</strong>
                      {` ${sku.itemWidth ?? ''}`}
                    </li>
                    <li>
                      <strong>Item Height:</strong>
                      {` ${sku.itemHeight ?? ''}`}
                    </li>
                    <li>
                      <strong>Teach in Status:</strong>
                      {` ${capitalize(sku.teachInStatus)}`}
                    </li>
                    <li>
                      <strong>VBW Eligible:</strong>
                      {` ${sku.vbwEligible}`}
                    </li>
                    {sku.vbwOptOutReason && (
                      <li>
                        <strong>VBW Opt Out Reason:</strong>
                        {` ${sku.vbwOptOutReason}`}
                      </li>
                    )}
                    <li>
                      <strong>Verified:</strong>
                      {` ${sku.verified}`}
                    </li>
                    <li>
                      <strong>Serial Number Barcodes:</strong>
                      {` ${sku.hasBarcode}`}
                    </li>
                  </ul>
                </div>
              </div>
            </>
          )}
        </Panel.Body>
        <Panel.Footer align="right">
          <Roles show={[UserRole.Admin, UserRole.EnterpriseManager]}>
            <Button kind="danger" type="submit" onClick={() => setShowModal(true)} disabled={!!sku.deletedAt}>
              Mark Duplicate
            </Button>
            <Spacer width="8px" />
            <Button
              kind="danger"
              type="submit"
              onClick={() => onDelete(sku.id)}
              loading={deleting}
              disabled={!!sku.deletedAt}
            >
              Delete
            </Button>
            <Spacer width="8px" />
            {sku.teachInStatus === Logistics__SkuTeachInStatusEnum.Taught && (
              <>
                <Button
                  kind="warning"
                  type="submit"
                  onClick={() => executeReteach({ variables: { id: sku.id } })}
                  loading={loadingReteach}
                >
                  Reteach
                </Button>
                <Spacer width="8px" />
              </>
            )}
          </Roles>
          <Roles show={[UserRole.Admin, UserRole.EnterpriseManager, UserRole.Manager]}>
            <Button
              kind="primary"
              type="submit"
              onClick={() => (window.location.href = logisticsEditSKUURL({ id: sku.id }))}
            >
              Edit
            </Button>
          </Roles>
        </Panel.Footer>
      </Panel>
      {showModal && (
        <MarkDuplicateModal
          accountID={sku.account.id}
          duplicateSkuID={sku.id}
          duplicateSkuLabel={`${sku.value} (${startCase(lowerCase(sku.countingUnit))})`}
          hideModal={() => setShowModal(false)}
        />
      )}
    </>
  );
};

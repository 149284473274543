const app = angular.module('app');

app.controller('TicketGroupManageController', [
  '$scope',
  'User',
  'TicketGroup',
  'CallGroup',
  'ErrorService',
  'ConfirmationService',
  function ($scope, User, TicketGroup, CallGroup, ErrorService, ConfirmationService) {
    $scope.users = User.query();
    $scope.callGroups = CallGroup.query();

    $scope.assign = function (user) {
      const existing = _.some($scope.form.users, (other) => other.id === user.id);
      if (!existing) {
        $scope.form.users.push(user);
      }
    };

    $scope.unassign = function (user) {
      _.remove($scope.form.users, user);
    };

    $scope.add = function () {
      $scope.selection = null;
      $scope.form = new TicketGroup({ name: null, users: [] });
    };

    $scope.edit = function (group) {
      $scope.selection = group;
      $scope.form = angular.copy($scope.selection);
    };

    $scope.save = function () {
      const method = $scope.selection ? 'update' : 'create';
      TicketGroup[method]({
        id: $scope.form.id,
        name: $scope.form.name,
        internal_ticket_assignable: $scope.form.internal_ticket_assignable,
        customer_ticket_assignable: $scope.form.customer_ticket_assignable,
        user_ids: _.map($scope.form.users, (user) => user.id),
        call_group_id: $scope.form.call_group_id,
      }).$promise.then((response) => {
        if ($scope.selection) {
          $scope.selection.name = $scope.form.name;
          $scope.selection.internal_ticket_assignable = $scope.form.internal_ticket_assignable;
          $scope.selection.customer_ticket_assignable = $scope.form.customer_ticket_assignable;
          $scope.selection.users = $scope.form.users;
          $scope.selection.call_group_id = $scope.form.call_group_id;
        } else {
          $scope.groups.push(response);
        }
        $scope.cancel();
      }, ErrorService.handle);
    };

    $scope.cancel = function () {
      $scope.selection = null;
      $scope.form = null;
    };

    $scope.destroy = function (group) {
      if (!group.default) {
        ConfirmationService.confirm({
          title: 'Are You Sure?',
          description:
            'Removing this group will remove it from any rules it is associated with. ' +
            `Are you sure you want to delete "${group.name}"?`,
          acceptButton: 'Continue',
          declineButton: 'Cancel',
        }).then(() => {
          group.$remove((response) => {
            /* eslint-disable-next-line no-param-reassign */
            group.destroyed = !!response;
            _.remove($scope.groups, group);
          }, ErrorService.handle);
        });
      }
    };
  },
]);

import styled from '@emotion/styled';
import React, { useState } from 'react';

import { Dialog } from '@admin/components/helpers/dialog';
import { Panel } from '@admin/components/helpers/panel';
import { FileFormControl } from '@admin/components/fields/file_form_control';
import { InputFormControl } from '@shared/components/fields/input_form_control';
import { Button, Breadcrumb, FormGroup, Radio } from '@shared/components/bootstrap';

import {
  useSavePricingUploadMutation,
  usePricingUploadQuery,
  Pricing__Upload__State,
  Pricing__Upload__Service,
} from '@admin/schema';

import { client } from '@admin/libraries/apollo';

import { usePusher } from '@admin/hooks';

const ROOT_PATH = '/pricing_uploads';

const Actions = styled.div`
  display: flex;
  margin: -4px;
  justify-content: flex-end;
`;

const Action = styled.div`
  padding: 4px;
`;

const useCombinedQueryMutation = () => {
  const [save, { loading: saving, data: saved }] = useSavePricingUploadMutation({ client });
  const uploadID = saved?.save.upload?.id;
  const { data: queried, refetch } = usePricingUploadQuery({
    client,
    variables: { id: uploadID! },
    skip: !uploadID,
  });

  const upload = queried?.upload ?? saved?.save.upload;

  return {
    save,
    refetch,
    saving,
    upload,
  };
};

export const PricingUploadForm: React.FC = () => {
  const [file, setFile] = useState<File | undefined>();
  const [service, setService] = useState<Pricing__Upload__Service | undefined>();
  const [description, setDescription] = useState<string>('');
  const [spreadsheetID, setSpreadsheetID] = useState<string | undefined>();
  const { save, saving, upload, refetch } = useCombinedQueryMutation();
  const loading = saving || upload?.state === Pricing__Upload__State.Processing;

  usePusher<{ id: number }>('pricing-upload', 'changed', async ({ id }) => {
    if (!upload || String(id) !== upload.id) return;
    refetch();
  });

  const onUpload = (updatedFile: File, updatedSpreadsheetID: string) => {
    setFile(updatedFile);
    setSpreadsheetID(updatedSpreadsheetID);
  };

  const onSave = (event: React.FormEvent) => {
    event.preventDefault();
    event.stopPropagation();

    if (!spreadsheetID) return;
    if (!description) return;
    if (!service) return;

    save({
      variables: {
        input: {
          spreadsheetID,
          description,
          service,
        },
      },
    });
  };

  return (
    <>
      {upload && (
        <>
          {upload.state === Pricing__Upload__State.Processed && (
            <Dialog
              title="Processed"
              description="Your upload processed!"
              onClose={() => (location.href = ROOT_PATH)}
            />
          )}

          {upload.state === Pricing__Upload__State.Failed && (
            <Dialog
              title="Failed"
              description={upload.error ?? 'Your upload failed!'}
              onClose={() => (location.href = ROOT_PATH)}
            />
          )}
        </>
      )}

      <div className="page-header">
        <Breadcrumb>
          <Breadcrumb.Item>
            <a href={ROOT_PATH}>Pricing Uploads</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Import</Breadcrumb.Item>
        </Breadcrumb>
      </div>

      <form onSubmit={onSave}>
        <Panel>
          <Panel.Header>
            <Panel.Title>Upload CSV</Panel.Title>
          </Panel.Header>
          <Panel.Body>
            <p>
              Pricing sets can be uploaded in bulk by uploading a CSV. Download and replace one of the following
              templates:{' '}
              <a href="/files/market_pricings.csv" className="btn-link" download target="_blank">
                storage_pricings.csv
              </a>
              {' or '}
              <a href="/files/moving_pricings.csv" className="btn-link" download target="_blank">
                moving_pricings.csv
              </a>
            </p>
            <FormGroup>
              <Radio>
                <input
                  type="radio"
                  value={Pricing__Upload__Service.Storage}
                  checked={service === Pricing__Upload__Service.Storage}
                  onChange={() => {
                    setService(Pricing__Upload__Service.Storage);
                  }}
                />
                Storage
              </Radio>
              <Radio>
                <input
                  type="radio"
                  value={Pricing__Upload__Service.Moving}
                  checked={service === Pricing__Upload__Service.Moving}
                  onChange={() => {
                    setService(Pricing__Upload__Service.Moving);
                  }}
                />
                Moving
              </Radio>
            </FormGroup>
            <FormGroup>
              <InputFormControl
                placeholder="Description"
                type="text"
                value={description}
                onChange={(event) => {
                  setDescription(event.target.value);
                }}
              />
            </FormGroup>
            <FormGroup>
              <FileFormControl accept="text/csv" label={file?.name} saving={saving} onUpload={onUpload} />
            </FormGroup>
          </Panel.Body>
          <Panel.Footer>
            <Actions>
              <Action>
                <Button
                  type="submit"
                  kind="primary"
                  disabled={!spreadsheetID || !description || !service}
                  loading={loading}
                >
                  Save
                </Button>
              </Action>
            </Actions>
          </Panel.Footer>
        </Panel>
      </form>
    </>
  );
};

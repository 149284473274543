import * as React from 'react';

import { usePhoneCallPolicyQuery } from '@admin/schema';
import { client } from '@admin/libraries/apollo';

const TWILIO_HOST = 'api.twilio.com';

export const Download: React.FC<{
  src: string;
}> = ({ src }) => {
  const { data, loading } = usePhoneCallPolicyQuery({
    client,
  });

  if (loading) return null;

  if (!data?.phoneCallPolicy?.download) {
    return null;
  }

  const url = src.includes(TWILIO_HOST) ? `${src}?Download=true` : src;
  return (
    <a className="btn btn-success" href={url}>
      Download
    </a>
  );
};

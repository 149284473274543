import * as React from 'react';

import { HistoryResourceUsageFragment } from '@admin/schema';

import { Timestamp } from '@admin/components/timestamp';

export const Usage: React.FC<{
  resource: HistoryResourceUsageFragment;
  timestamp: string;
  timezone?: string;
}> = ({ resource, timestamp, timezone }) => (
  <tr>
    <td className="col-nowrap col-md-2 text-left" colSpan={2}>
      <Timestamp value={timestamp} timezone={timezone} />
    </td>
    <td className="col-nowrap col-md-2 text-left" colSpan={2}>
      <strong>{resource.user.name}</strong> <span>estimated:</span>
    </td>
    <td className="col-md-8 text-left" colSpan={8}>
      <span>
        Usage <strong>{resource.cuft}</strong> cubic feet
      </span>
    </td>
  </tr>
);

import * as React from 'react';

import { Currency } from '@shared/components/helpers';
import { SelfStorage__PromotionFragment } from '@admin/schema';
import { monthlySavings } from '../utils';

export const Discount: React.FC<{
  amount: number;
  promotion: SelfStorage__PromotionFragment;
}> = ({ amount, promotion }) => {
  const savings = monthlySavings(amount, promotion);
  return (
    <>
      <Currency value={-(savings < 0 ? amount : savings)} />
      <div>this month</div>
    </>
  );
};

import React, { useState } from 'react';

import { Panel } from '@admin/components/helpers/panel';
import { client } from '@admin/libraries/apollo';
import { UserRole } from '@admin/types';
import { Claim__FiltersInput, Claim__Status, UserFragment, useUsersSelectorQuery } from '@admin/schema';

type FiltersProps = {
  filters: Claim__FiltersInput;
  onFilter(filters: Claim__FiltersInput): void;
};

export const Filters: React.FC<FiltersProps> = ({ filters, onFilter }) => {
  const [owners, setOwners] = useState<UserFragment[]>([]);

  useUsersSelectorQuery({
    client,
    variables: { filters: { role: UserRole.Claims } },
    onCompleted: (data) => setOwners(data.users),
  });

  return (
    <Panel>
      <Panel.Body>
        <div className="form">
          <div className="form-group">
            <input
              value={filters.query || ''}
              onChange={(event) => onFilter({ query: event.target.value || undefined })}
              placeholder="Search by customer name, account number, email, phone number"
              className="form-control"
            />
          </div>
        </div>
        <div className="form-inline">
          <div className="form-group">
            <label className="mar-hor">
              <strong>Status: </strong>
            </label>
            <select
              className="form-control"
              value={filters.status ?? undefined}
              onChange={(event) => onFilter({ status: (event.target.value as Claim__Status) || undefined })}
            >
              <option value="">All</option>
              {(Object.keys(Claim__Status) as Array<keyof typeof Claim__Status>).map((status) => (
                <option key={status} value={Claim__Status[status]}>
                  {status === 'InReview' ? 'In Review' : status}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label className="mar-hor">
              <strong>Assignee: </strong>
            </label>
            <select
              id="owner-select"
              className="form-control"
              value={filters.ownerID ?? undefined}
              onChange={(event) => onFilter({ ownerID: event.target.value || undefined })}
            >
              <option value="">All</option>
              <option value="unassigned">Unassigned</option>
              {owners.map((owner) => (
                <option key={owner.id} value={owner.id}>
                  {owner.name}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label className="mar-hor">
              <strong>Kind: </strong>
            </label>
            <div className="checkbox-inline">
              <label>
                <input
                  checked={filters.hasDamagedItem || false}
                  type="checkbox"
                  onChange={(event) => onFilter({ hasDamagedItem: event.target.checked || undefined })}
                />
                Damage
              </label>
            </div>
            <div className="checkbox-inline">
              <label>
                <input
                  checked={filters.hasLostItem || false}
                  type="checkbox"
                  onChange={(event) => onFilter({ hasLostItem: event.target.checked || undefined })}
                />
                Lost
              </label>
            </div>
            <div className="checkbox-inline">
              <label>
                <input
                  checked={filters.hasDamagedProperty || false}
                  type="checkbox"
                  onChange={(event) => onFilter({ hasDamagedProperty: event.target.checked || undefined })}
                />
                Property
              </label>
            </div>
            <div className="checkbox-inline">
              <label>
                <input
                  checked={filters.hasIssue || false}
                  type="checkbox"
                  onChange={(event) => onFilter({ hasIssue: event.target.checked || undefined })}
                />
                Issue
              </label>
            </div>
          </div>
        </div>
      </Panel.Body>
    </Panel>
  );
};

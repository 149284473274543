(function () {
  const app = angular.module('app');

  app.controller('PalletsListController', [
    '$scope',
    '$resource',
    'PaginationService',
    'DebounceService',
    'PalletService',
    function ($scope, $resource, PaginationService, DebounceService, PalletService) {
      const debounce = DebounceService.initialize();

      const Customer = $resource('/customers.json');
      const Pallet = $resource('/:resource_type/:resource_id/pallets.json', {
        resource_type: '@resource_type',
        resource_id: '@resource_id',
      });

      $scope.pagination = PaginationService.initialize();

      $scope.filter = function () {
        const params = { resource_id: $scope.id, resource_type: $scope.type, page: $scope.pagination.page };
        $scope.pallets = null;
        debounce.execute($scope.paginated ? Pallet.get(params) : Pallet.query(params)).then((data) => {
          if ($scope.paginated) {
            $scope.pagination.prepare(data.pagination);
            $scope.pallets = data.results;
          } else {
            $scope.pallets = data;
          }

          const accountIds = PalletService.getAllAccountIds($scope.pallets);

          Customer.query({ 'account_ids[]': accountIds }).$promise.then((customers) => {
            $scope.pallets = PalletService.updatePallets($scope.pallets, customers);
          });
        });
      };

      $scope.init = function (type, id, paginated) {
        $scope.type = type;
        $scope.id = id;
        $scope.paginated = !!paginated;
        $scope.filter();
      };
    },
  ]);

  app.controller('PalletsShowController', [
    '$scope',
    '$stateParams',
    'Pallet',
    'Warehouse',
    'Page',
    'Account',
    function ($scope, $stateParams, Pallet, Warehouse, Page, Account) {
      $scope.id = $stateParams.id;
      $scope.customersItems = [];
      Pallet.get({ id: $scope.id }).$promise.then((pallet) => {
        $scope.pallet = pallet;
        $scope.warehouse = Warehouse.get({ id: $scope.pallet.warehouse_id });
        const accountsItems = {};
        pallet.items_without_skus.forEach((item) => {
          if (accountsItems[item.account_id]) {
            accountsItems[item.account_id].push(item);
          } else {
            accountsItems[item.account_id] = [item];
          }
        });

        Object.keys(accountsItems).forEach((accountId) => {
          Account.get({ id: accountId }, (account) => {
            $scope.customersItems.push({
              customer_name: account.customer.name,
              account,
              items: accountsItems[accountId],
            });
          });
        });
        Page.reset({ title: `MU:#${$scope.pallet.barcode.value}` });
      });
    },
  ]);
})();

import { DateTime } from 'luxon';
import React from 'react';

import { Titleize } from '@admin/components/helpers/titleize';
import { Spinner } from '@admin/components/spinner';
import { client } from '@admin/libraries/apollo';
import { Account__TermCommitment, Account__TermCommitment__Type, useTermCommitmentsQuery } from '@admin/schema';
import { Currency, Pluralize } from '@shared/components/helpers';

const formatType = (contractType: Account__TermCommitment__Type) => contractType.replace(/_/g, ' ');

const Contracts: React.FC<{ contracts?: Account__TermCommitment[] }> = ({ contracts }) => {
  if (!contracts?.length) {
    return <span>None</span>;
  }

  return (
    <ul>
      {contracts.map((contract, i) => (
        <li key={i}>
          <Pluralize count={contract.term} singular="month" plural="months" />
          {contract.contractEndDate && (
            <> ending {DateTime.fromISO(contract.contractEndDate).toLocaleString(DateTime.DATE_FULL)}</>
          )}{' '}
          (<Titleize text={formatType(contract.type)} />)
        </li>
      ))}
    </ul>
  );
};

export const TermCommitments: React.FC<{ accountID: string }> = ({ accountID }) => {
  const { data, loading } = useTermCommitmentsQuery({
    variables: { accountID },
    client,
    fetchPolicy: 'cache-and-network',
  });

  if (loading) {
    return <Spinner />;
  }

  const contracts = data?.account.termCommitments;
  const terminationAgreement = data?.account.terminationAgreement;

  return (
    <>
      <div>
        <strong>Term Commitments: </strong>
        <Contracts contracts={contracts} />
      </div>
      <div>
        <strong>Account Closure Policy: </strong>
        {!terminationAgreement && 'None'}
        {terminationAgreement && (
          <>
            <Currency value={terminationAgreement.earlyTerminationFee} /> fee if this account is closed prior to{' '}
            {DateTime.fromISO(terminationAgreement.contractEndDate!).toLocaleString(DateTime.DATE_FULL)}
          </>
        )}
      </div>
    </>
  );
};

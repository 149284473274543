import styled from '@emotion/styled';
import React, { useState } from 'react';
import { capitalize } from 'lodash';

import { Roles } from '@admin/components/helpers/roles';
import { UserRole } from '@admin/types';
import {
  AuditResultsDocument,
  AuditResultsQuery,
  OpsAudit__ExceptionStatus,
  useEditAuditExceptionMutation,
} from '@admin/schema';
import { COLORS } from '@root/colors';
import { Button } from '@shared/components/bootstrap';
import { client } from '@admin/libraries/apollo';

import { AuditExceptionModal } from './audit_exception_modal';

type AuditType = AuditResultsQuery['audits'][number];

const Details = styled.div`
  text-align: left;
  margin-bottom: 16px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 6px;
  button {
    flex-grow: 1;
    margin-right: 12px;
    &:last-of-type {
      margin: 0px;
    }
  }
`;

const ButtonFullWidth = styled.div`
  button {
    width: 100%;
  }
`;

const ExceptionStatus = styled.div<{ status: string }>(({ status }) => {
  switch (status) {
    case OpsAudit__ExceptionStatus.Pending:
      return { color: COLORS.yellow };
    case OpsAudit__ExceptionStatus.Approved:
      return { color: COLORS.green };
    case OpsAudit__ExceptionStatus.Denied:
      return { color: COLORS.red };
    default:
      return {};
  }
});

export const AuditExceptionColumn: React.FC<{
  audit: AuditType;
}> = ({ audit }) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const { exception, failed } = audit;

  const [execute, { loading }] = useEditAuditExceptionMutation({
    client,
    refetchQueries: [
      { query: AuditResultsDocument, variables: { resourceID: audit.resourceID, resourceType: audit.resourceType } },
    ],
  });

  const editAuditException = (status?: string) => {
    execute({
      variables: {
        input: { auditID: audit.id, status },
      },
    });
  };

  if (!failed) {
    return null;
  }

  return (
    <>
      {exception && (
        <>
          <Details>
            <ExceptionStatus status={exception.status}>
              {capitalize(exception.status)} Exception Request
            </ExceptionStatus>
            <div>{exception.creator.name}</div>
            <div>Reason code: {exception.reason}</div>
          </Details>
          <Roles show={[UserRole.Admin, UserRole.AUDIT_REVIEWER, UserRole.Manager]}>
            <ButtonFullWidth>
              <Button kind="primary" onClick={() => setShowModal(true)}>
                View Details
              </Button>
            </ButtonFullWidth>
          </Roles>
          <Roles show={[UserRole.Admin, UserRole.AUDIT_REVIEWER]}>
            <ButtonContainer>
              <Button
                kind="primary"
                loading={loading}
                disabled={exception.status === OpsAudit__ExceptionStatus.Approved}
                onClick={() => editAuditException('Approved')}
              >
                Approve
              </Button>
              <Button
                kind="danger"
                loading={loading}
                disabled={exception.status === OpsAudit__ExceptionStatus.Denied}
                onClick={() => editAuditException('Denied')}
              >
                Deny
              </Button>
            </ButtonContainer>
          </Roles>
        </>
      )}
      {!exception && (
        <Roles show={[UserRole.Admin, UserRole.Manager]}>
          <ButtonFullWidth>
            <Button kind="primary" onClick={() => setShowModal(true)}>
              Create Exception
            </Button>
          </ButtonFullWidth>
        </Roles>
      )}
      <AuditExceptionModal show={showModal} audit={audit} onClose={() => setShowModal(false)} />
    </>
  );
};

import { Panel } from '@admin/components/helpers/panel';
import { Pagination } from '@admin/components/pagination';
import { Spinner } from '@admin/components/spinner';
import { usePusher } from '@admin/hooks';
import { client } from '@admin/libraries/apollo';
import { PaginatedPricingUploadsQuery, Pricing__Upload__State, usePaginatedPricingUploadsQuery } from '@admin/schema';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnchorButton as Link, Badge, Table, Text, Tooltip } from '@shared/components/bootstrap';
import { Timestamp } from '@shared/components/helpers';
import React, { useState } from 'react';

type Upload = PaginatedPricingUploadsQuery['paginated']['results'][number];

const PRICING_UPLOAD_STATE_TO_STYLE: Record<Pricing__Upload__State, 'danger' | 'success' | 'info'> = {
  [Pricing__Upload__State.Failed]: 'danger',
  [Pricing__Upload__State.Processed]: 'success',
  [Pricing__Upload__State.Processing]: 'info',
  [Pricing__Upload__State.Pending]: 'info',
};

const Status: React.FC<{ upload: Upload }> = ({ upload }) => (
  <Badge style={PRICING_UPLOAD_STATE_TO_STYLE[upload.state]}>
    {upload.state.toLowerCase()}{' '}
    {upload.state === Pricing__Upload__State.Processing && <FontAwesomeIcon spin icon="spinner" />}
  </Badge>
);

const Row: React.FC<{ upload: Upload }> = ({ upload }) => (
  <tr>
    <td>{upload.id}</td>
    <td>{upload.user.name}</td>
    <td>{upload.processingAt && <Timestamp value={upload.processingAt} format={Timestamp.Format.DateTime} />}</td>
    <td>{upload.processedAt && <Timestamp value={upload.processedAt} format={Timestamp.Format.DateTime} />}</td>
    <td>{upload.service}</td>
    <td>
      <a className="btn-link" href={upload.spreadsheet.url} target="_blank">
        <strong>{upload.spreadsheet.filename}</strong>
      </a>
      <Text style="muted" tag="div">
        {upload.description}
      </Text>
    </td>
    <td>
      {upload.error ? (
        <Tooltip title={upload.error}>
          <Status upload={upload} />
        </Tooltip>
      ) : (
        <Status upload={upload} />
      )}
    </td>
  </tr>
);

export const List: React.FC = () => {
  const [page, setPage] = useState<number | undefined>();
  const { data, loading, refetch } = usePaginatedPricingUploadsQuery({ client, variables: { page: page } });
  const paginated = data?.paginated;

  usePusher<{ id: number }>('pricing-upload', 'changed', () => {
    refetch();
  });

  return (
    <Panel>
      <Table responsive striped>
        <thead>
          <tr>
            <th>ID</th>
            <th>Uploader</th>
            <th>Processing at</th>
            <th>Processed at</th>
            <th>Service</th>
            <th>File</th>
            <th>State</th>
          </tr>
        </thead>
        <tbody>
          {paginated?.results.map((upload) => (
            <Row key={upload.id} upload={upload}></Row>
          ))}
        </tbody>
      </Table>
      {loading && <Spinner />}
      {paginated && <Pagination pagination={paginated.pagination} onPage={setPage} />}
      <Panel.Footer>
        <Text alignment="right" tag="div">
          <Link kind="default" href="/pricing_uploads/new">
            New
          </Link>
        </Text>
      </Panel.Footer>
    </Panel>
  );
};

import React, { useContext } from 'react';

import { Context } from '../context';
import { Mode } from '../filters';
import { NightTransportTask, NightTransportRequiredDrive, NightTransportDirection } from '../types';
import { getRequiredDriveTask, getRoot, taskDirection } from '../task';
import { InlineSelectFormGroup } from '../inline_select_form_group';

const isOutbound = (
  task: NightTransportTask,
  tasks: NightTransportTask[],
  requiredDriveTasks: Record<string, NightTransportRequiredDrive>,
) => {
  const rootTask = getRoot(task, tasks);
  const rootRequiredDriveTask = getRequiredDriveTask(requiredDriveTasks, rootTask.requiredDriveTaskUUID);
  return taskDirection(rootRequiredDriveTask, task) === NightTransportDirection.Outbound;
};

export const DriveFormGroup: React.FC<{
  name: string;
  label: string;
  currentDriveUUID?: string;
  outboundOnly?: boolean;
  onChangeAction(driveUUID?: string): void;
}> = ({ name: formGroupName, label, currentDriveUUID, outboundOnly = false, onChangeAction }) => {
  const { mode, tasks, loading, requiredDriveTasks } = useContext(Context);
  const driveTasks = tasks.filter(
    (task) =>
      task.action.__typename === 'NightTransport__Drive' &&
      (!outboundOnly || isOutbound(task, tasks, requiredDriveTasks)),
  );

  const onChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    onChangeAction(event.target.value);
  };

  return (
    <InlineSelectFormGroup
      value={currentDriveUUID ?? ''}
      label={label}
      onChange={onChange}
      name={formGroupName}
      disabled={mode !== Mode.Editing || loading}
    >
      <option value="">- Task -</option>

      {driveTasks.map(({ id, uuid, action }) => (
        <option value={action.uuid} key={uuid}>
          Task #{id}
        </option>
      ))}
    </InlineSelectFormGroup>
  );
};

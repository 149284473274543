import React, { useState, useEffect } from 'react';
import { Button, Modal, Text, Alert } from '@shared/components/bootstrap';
import { Spacer } from '@shared/components/helpers';
import { client } from '@admin/libraries/apollo';
import {
  useLogisticsSkusForAccountLazyQuery,
  useSkuMergeMutation,
  Status,
  Logistics__Sku__CountingUnitEnum,
} from '@admin/schema';
import { lowerCase, startCase } from 'lodash';
import styled from '@emotion/styled';
import { SingleSelectFormControl } from '@admin/components/fields/single_select_form_control';
import { FALLBACK_ERROR } from './basic_info_panel';

const StyledDiv = styled.div`
  width: 50%;
  margin: auto;
`;

const StyledSpan = styled.span<{ validated: boolean }>`
  color: ${({ validated }) => (validated ? '#008000' : '#DAA520')};
`;

export const MarkDuplicateModal: React.FC<{
  accountID: string;
  duplicateSkuID: string;
  duplicateSkuLabel: string;
  hideModal(): void;
}> = ({ accountID, duplicateSkuID, duplicateSkuLabel, hideModal }) => {
  const [skuID, setSkuID] = useState<string>('');
  const [skuLabel, setSkuLabel] = useState<string>('');
  const [inputString, setInputString] = useState<string>('');
  const [error, setError] = React.useState<string | undefined>(undefined);
  const [executeMerge, { loading: merging }] = useSkuMergeMutation({ client });
  const [getSkus, { data: skuData, loading }] = useLogisticsSkusForAccountLazyQuery({
    client,
    variables: { accountID },
  });

  useEffect(() => {
    getSkus();
  }, []);

  function formatSkuLabel(skuValue: string, skuCountingUnit: Logistics__Sku__CountingUnitEnum) {
    return `${skuValue} (${startCase(lowerCase(skuCountingUnit))})`;
  }

  async function onMerge() {
    try {
      const result = await executeMerge({ variables: { skuID, duplicateSkuID } });
      if (result?.data?.skuMerge.status === Status.Ok) {
        window.location.reload();
      } else {
        setError(result?.data?.skuMerge.error || FALLBACK_ERROR);
        window.scrollTo(0, 0);
      }
    } catch (e) {
      setError(FALLBACK_ERROR);
      window.scrollTo(0, 0);
    }
  }

  return (
    <Modal onClose={hideModal}>
      <Modal.Content>
        <Modal.Header>
          {error && <Alert style="danger">{error}</Alert>}
          <Modal.Title>
            <strong>Mark SKU as Duplicate</strong>
          </Modal.Title>
          <Modal.Close close={hideModal} />
        </Modal.Header>
        <Modal.Body>
          <Text>
            <p>
              <strong>Warning:</strong> Marking this SKU as a duplicate will delete it from Teal.
              <br />
              <strong>This cannot be reversed, even by the tech team.</strong>
              <br />
              Please double check that you want to proceed with this action.
            </p>
            This SKU is a duplicate of:
            <SingleSelectFormControl
              value={skuID}
              onChange={(selectedSkuID: string) => {
                const selectedSku = skuData?.logisticsSkus.find((sku) => sku.id === selectedSkuID);
                if (!selectedSku) return;
                setSkuID(selectedSkuID);
                setSkuLabel(formatSkuLabel(selectedSku.value, selectedSku.countingUnit));
              }}
              options={
                skuData?.logisticsSkus
                  .filter((sku) => sku.id !== duplicateSkuID)
                  .map((sku) => ({
                    value: sku.id,
                    label: formatSkuLabel(sku.value, sku.countingUnit),
                  })) ?? []
              }
              disabled={loading}
            />
            {skuID && (
              <>
                <p>
                  All inventory, fulfillment expectations, pallet weights, and shipping labels for{' '}
                  <strong>{duplicateSkuLabel}</strong> will be converted to <strong>{skuLabel}</strong>.
                </p>
                <p>
                  <strong>{duplicateSkuLabel}</strong> will be deleted.
                </p>
                <StyledDiv>
                  To confirm you want to mark <strong>{duplicateSkuLabel}</strong> as a duplicate and{' '}
                  <strong>delete it</strong>, please type <strong>“DELETE”</strong> below.
                  <Spacer height="8px" />
                  <StyledDiv>
                    Confirm Duplicate
                    <input
                      placeholder="Type DELETE"
                      value={inputString}
                      type="string"
                      onChange={(event) => {
                        setInputString(event.target.value);
                      }}
                    />
                    <StyledSpan validated={inputString === 'DELETE'}>▔▔▔▔▔▔▔▔▔▔▔▔</StyledSpan>
                  </StyledDiv>
                </StyledDiv>
              </>
            )}
          </Text>
        </Modal.Body>
        <Modal.Footer>
          {
            <div>
              <Button kind="default" onClick={hideModal} loading={merging}>
                Cancel
              </Button>
              <Button kind="danger" onClick={onMerge} loading={merging} disabled={!skuID || inputString !== 'DELETE'}>
                Mark Duplicate
              </Button>
            </div>
          }
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};

import * as React from 'react';

export const Formatter: React.FC<{
  resource: {
    length?: number;
    width?: number;
    height?: number;
  };
  fallback?: string;
}> = ({ resource, fallback = null }) => {
  const { length, width, height } = resource;
  if (!length || !width || !height) {
    return fallback ? <>{fallback}</> : null;
  }
  return (
    <>
      {length}" × {width}" × {height}" ({((length * width * height) / 1728).toFixed(2)} cuft)
    </>
  );
};

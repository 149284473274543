import { addDays, format, parse, subDays } from 'date-fns';
import * as React from 'react';

import axios from 'axios';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import { ActiveVehicles } from './active_vehicles';
import { Header } from './header';
import { PendingDispatches } from './pending_dispatches';

import { IDepot } from './types/depot';
import { IDispatchNote } from './types/dispatch_note';
import { IOption } from './types/option';
import { IOutboundDispatch } from './types/outbound_dispatch';
import { IPendingDispatch } from './types/pending_dispatch';
import { IVehiclesByType } from './types/vehicle';
import { IVehicleTypes } from './types/vehicle';
import { IWarehouse } from './types/warehouse';

import { Depot } from './utilities/depot';
import { OutboundDispatches } from './utilities/outbound_dispatch';
import { VehicleAssignment } from './utilities/vehicle_assignment';
import { VehicleTypes } from './utilities/vehicle_types';
import { Vehicles } from './utilities/vehicles';
import { Warehouses } from './utilities/warehouses';

library.add(faSpinner);

interface IVehicleRouteMatcherState {
  warehouses?: IWarehouse[] | null;
  currentDate?: any;
  dispatchedVehicles: IVehiclesByType | null;
  pendingDispatches?: IPendingDispatch[];
  outboundDispatches?: IOutboundDispatch[];
  dispatchesNotes?: IDispatchNote[];
  warehouseId?: string | undefined;
  vehicleTypes?: IVehicleTypes | null;
  loading: boolean;
  dispatchesLoading: boolean;
  filterDepotOptions?: IDepot[];
  filterVehicleOptions: IOption[] | undefined;
  filteredActiveVehicles: IOption[] | undefined;
  filteredDepots: IOption[] | undefined;
  filteredVehicles: IOption[] | undefined;
  hasReturns: IOption | null;
  filteredAssignmentOptions: IOption | undefined;
  filteredActiveVehicleDepots: IOption[] | undefined;
  filteredStatus: IOption | undefined;
}

class VehicleRouteMatcher extends React.Component<{}, IVehicleRouteMatcherState> {
  constructor(props: any) {
    super(props);
    const warehouse = new URLSearchParams(window.location.search).get('warehouse');
    const date = new URLSearchParams(window.location.search).get('date');
    const currentDate = date ? parse(date) : addDays(new Date(), 1);

    this.state = {
      currentDate,
      warehouseId: warehouse || undefined,
      filteredDepots: undefined,
      dispatchedVehicles: null,
      hasReturns: null,
      filteredVehicles: undefined,
      filteredAssignmentOptions: undefined,
      loading: false,
      dispatchesLoading: false,
      filteredActiveVehicleDepots: undefined,
      filteredStatus: undefined,
      filteredActiveVehicles: undefined,
      filterVehicleOptions: undefined,
    };
  }

  public componentDidMount() {
    axios.all([this.fetchWarehouses(), this.fetchVehicleTypes()]).then(() => {
      if (this.state.warehouseId) {
        this.fetchDepots(this.state.warehouseId);
        this.fetchPendingDispatch(this.state.warehouseId);
        this.fetchOutboundDispatches(this.state.warehouseId);
        this.fetchVehicles(this.state.warehouseId);
      }
    });
  }

  public render() {
    if (!this.state.warehouses) {
      return null;
    }

    return (
      <div className="vehicles-route-matcher">
        <Header
          currentDate={this.state.currentDate}
          warehouses={this.state.warehouses}
          warehouseId={this.state.warehouseId}
          depots={this.state.filterDepotOptions}
          filteredDepots={this.state.filteredDepots}
          hasReturns={this.state.hasReturns}
          handleDateChange={this.handleDateChange}
          handleWarehouseChange={this.handleWarehouseChange}
          handleFilterDepots={this.handleFilterDepots}
          handleFilterReturns={this.handleFilterReturns}
          filterVehicleOptions={this.state.filterVehicleOptions}
          filteredVehicles={this.state.filteredVehicles}
          handleFilterVehicles={this.handleFilterVehicles}
          handleFilterAssignmentValidation={this.handleFilterAssignmentValidation}
          filteredAssignmentOptions={this.state.filteredAssignmentOptions}
          filteredActiveVehicles={this.state.filteredActiveVehicles}
          handleActiveVehicleFilter={this.handleActiveVehicleFilter}
          handleFilterActiveVehicleDepots={this.handleFilterActiveVehicleDepots}
          filteredActiveVehicleDepots={this.state.filteredActiveVehicleDepots}
          filteredStatus={this.state.filteredStatus}
          handleFilterActiveVehicleStatus={this.handleFilterActiveVehicleStatus}
        />
        <div className="vehicle-list-container">
          <ActiveVehicles
            dispatchedVehicles={this.state.dispatchedVehicles}
            outboundDispatches={this.state.outboundDispatches}
            dispatchesNotes={this.state.dispatchesNotes}
            warehouseId={this.state.warehouseId}
            vehicleTypes={this.state.vehicleTypes}
            handleVehicleAssignment={this.handleVehicleAssignment}
            loading={this.state.loading}
          />
          <PendingDispatches pendingDispatches={this.state.pendingDispatches} loading={this.state.dispatchesLoading} />
        </div>
      </div>
    );
  }

  private handleDateChange = (currentDate: Date | null) => {
    this.setState({ currentDate }, () => {
      this.updateUrl();
      if (this.state.warehouseId) {
        this.fetchData(this.state.warehouseId);
        this.fetchOutboundDispatches(this.state.warehouseId);
      }
    });
  };

  private handleWarehouseChange = (warehouseId: string) => {
    this.setState({ warehouseId, filteredDepots: [] }, () => {
      this.updateUrl();
      this.fetchData(warehouseId);
      this.fetchDepots(warehouseId);
      this.fetchOutboundDispatches(warehouseId);
    });
  };

  private fetchData = (warehouseId: string) => {
    this.setState({ loading: true, dispatchesLoading: true });
    this.fetchPendingDispatch(warehouseId).then(() => this.fetchVehicles(warehouseId));
  };

  private async fetchDepots(warehouseId: string) {
    await new Depot({ warehouseId }).fetch().then((filterDepotOptions) => this.setState({ filterDepotOptions }));
  }

  private async fetchVehicles(warehouseId: string) {
    const currentDate = this.getCurrentDate();
    const date = this.formatDate(currentDate);
    const prevDate = this.formatDate(this.getPreviousDate());

    let inboundDockItemsPerHour = 0;
    if (this.state.warehouses) {
      const warehouse = this.state.warehouses.find((w) => w.id.toString() === warehouseId);
      inboundDockItemsPerHour = warehouse ? warehouse.inboundDockItemsPerHour : 0;
    }

    const depotIds = this.state.filteredActiveVehicleDepots
      ? this.state.filteredActiveVehicleDepots.map((d: IDepot) => d.value).join(',')
      : undefined;

    const filteredVehicleTypes = this.state.filteredActiveVehicles
      ? this.state.filteredActiveVehicles.map((vt: IOption) => vt.value)
      : null;

    const filteredStatus = this.state.filteredStatus ? this.state.filteredStatus.value : null;

    const dispatchedVehicles = await new Vehicles({
      warehouseId,
      date,
      prevDate,
      inboundDockItemsPerHour,
      filteredVehicleTypes,
      depotIds,
      filteredStatus,
    }).fetch();

    this.setState({ dispatchedVehicles, loading: false });
  }

  private async fetchOutboundDispatches(warehouseId: string) {
    const currentDate = this.getCurrentDate();
    const date = this.formatDate(currentDate);
    await new OutboundDispatches({
      warehouseId,
      date,
      vehicleTypes: this.state.vehicleTypes,
    })
      .fetch()
      .then((d) =>
        this.setState({
          outboundDispatches: d && d.outboundDispatches,
        }),
      );
  }

  private async fetchPendingDispatch(warehouseId: string) {
    const currentDate = this.getCurrentDate();
    const date = this.formatDate(currentDate);
    const depotIds = this.state.filteredDepots
      ? this.state.filteredDepots.map((d: IDepot) => d.value).join(',')
      : undefined;
    const hasReturns = this.state.hasReturns !== null ? this.state.hasReturns.value.toString() : 'all';

    const filteredVehicleTypes = this.state.filteredVehicles
      ? this.state.filteredVehicles.map((vt: IOption) => vt.value).join(',')
      : undefined;

    const filteredAssignmentOptions = this.state.filteredAssignmentOptions
      ? this.state.filteredAssignmentOptions.value
      : null;
    this.setState({ dispatchesLoading: true });

    await new OutboundDispatches({
      warehouseId,
      date,
      vehicleTypes: this.state.vehicleTypes,
      depotIds,
      filteredVehicleTypes,
      hasReturns,
      filteredAssignmentOptions,
    })
      .fetch()
      .then((d: any) =>
        this.setState({
          pendingDispatches: d.pendingDispatches,
          dispatchesNotes: d.dispatchesNotes,
          dispatchesLoading: false,
        }),
      );
  }

  private handleVehicleAssignment = async (
    serialCode: string,
    dispatchId: number,
    warehouseId: string,
    oldDispatchId: number,
    notes: string,
  ) => {
    const assignment = new VehicleAssignment({
      serialCode,
      warehouseId: this.state.warehouseId,
      newDispatchId: dispatchId,
      oldDispatchId,
      notes,
    });
    assignment.save().then(() => {
      this.fetchOutboundDispatches(warehouseId);
      this.fetchData(warehouseId);
    });
  };

  private getCurrentDate = () => {
    const { currentDate } = this.state;

    // 5 hours is subtracted for night workers working between 12:00AM to 5:00AM still show previous date by default.
    return currentDate;
  };

  private getPreviousDate = () => {
    const currentDate = this.getCurrentDate();

    return subDays(currentDate, 1);
  };

  private formatDate = (date: Date) => format(date, 'YYYY-MM-DD');

  private fetchWarehouses = async () => {
    await new Warehouses().fetch().then((warehouses) => this.setState({ warehouses }));
  };

  private fetchVehicleTypes = async () => {
    await new VehicleTypes().fetch().then((vehicleTypes) => {
      if (!vehicleTypes) {
        return undefined;
      }
      const filterVehicleOptions: any = [];
      Object.keys(vehicleTypes).forEach((key) => filterVehicleOptions.push({ value: key, label: vehicleTypes[key] }));

      this.setState({ vehicleTypes, filterVehicleOptions });
    });
  };

  private updateUrl = () => {
    const url = new URL(window.location.href);
    const queryString = url.search;
    const searchParams = new URLSearchParams(queryString);
    if (this.state.warehouseId) {
      searchParams.set('warehouse', this.state.warehouseId);
    }

    if (this.state.currentDate) {
      const date = this.formatDate(this.state.currentDate);
      searchParams.set('date', date);
    }

    url.search = searchParams.toString();
    const newUrl = url.toString();

    if (newUrl) {
      window.history.pushState({}, '', newUrl);
    }
  };

  private handleFilterDepots = (filteredDepots: IOption[]) => {
    this.setState({ filteredDepots }, () => {
      if (this.state.warehouseId) {
        this.fetchPendingDispatch(this.state.warehouseId);
      }
    });
  };

  private handleFilterReturns = (hasReturns: IOption) => {
    this.setState({ hasReturns }, () => {
      if (this.state.warehouseId) {
        this.fetchPendingDispatch(this.state.warehouseId);
      }
    });
  };

  private handleFilterVehicles = (filteredVehicles: any) => {
    this.setState({ filteredVehicles }, () => {
      if (this.state.warehouseId) {
        this.fetchPendingDispatch(this.state.warehouseId);
      }
    });
  };

  private handleFilterAssignmentValidation = (filteredAssignmentOptions: IOption) => {
    this.setState({ filteredAssignmentOptions }, () => {
      if (this.state.warehouseId) {
        this.fetchPendingDispatch(this.state.warehouseId);
      }
    });
  };

  private handleActiveVehicleFilter = (filteredActiveVehicles: IOption[]) => {
    this.setState({ filteredActiveVehicles }, () => {
      if (this.state.warehouseId) {
        this.fetchVehicles(this.state.warehouseId);
      }
    });
  };

  private handleFilterActiveVehicleDepots = (filteredActiveVehicleDepots: IOption[]) => {
    this.setState({ filteredActiveVehicleDepots }, () => {
      if (this.state.warehouseId) {
        this.fetchVehicles(this.state.warehouseId);
      }
    });
  };

  private handleFilterActiveVehicleStatus = (filteredStatus: IOption) => {
    this.setState({ filteredStatus }, () => {
      if (this.state.warehouseId) {
        this.fetchVehicles(this.state.warehouseId);
      }
    });
  };
}

export { VehicleRouteMatcher };

(() => {
  const app = angular.module('app');

  app.component('validatableInput', {
    bindings: {
      model: '=?',
      disabled: '<?',
      skipValidationWhenBlank: '@',
      type: '@',
    },
    template: [
      '$attrs',
      'InputTypeConstants',
      ($attrs, InputTypeConstants) => {
        const type = $attrs.type;
        let inputAttrs = $attrs.inputAttrs;
        let inputType = '';
        let fieldId = '';
        switch (type) {
          case InputTypeConstants.TYPE_PHONE_NUMBER:
            inputType = 'phone number';
            fieldId = _.kebabCase(inputType);
            break;
          case InputTypeConstants.TYPE_EMAIL:
            inputType = 'email address';
            fieldId = type;
            inputAttrs += ' remove-angular-email-validation';
            break;
        }
        return `
        <div ng-class="{'has-error': showInvalidMessage}">
          <input ${inputAttrs} type="${type}" ng-model="$ctrl.model" ng-disabled="$ctrl.disabled" input-validate valid-callback="$ctrl.validCallback()"
            invalid-callback="$ctrl.invalidCallback()" id="validatable-${fieldId}-input" skip-validation-when-blank="{{$ctrl.skipValidationWhenBlank}}"></input>
          <span class="validation-error text-danger" ng-show="$ctrl.showInvalidMessage">Please enter a valid ${inputType}.</span>
        </div>`;
      },
    ],
    controller: [
      '$rootScope',
      function ($rootScope) {
        const self = this;

        self.showInvalidMessage = false;

        self.validCallback = function () {
          $rootScope.$broadcast('validation:successful', { [this.type]: true });
          self.showInvalidMessage = false;
        };

        self.invalidCallback = function () {
          $rootScope.$broadcast('validation:failed', { [this.type]: false });
          self.showInvalidMessage = true;
        };
      },
    ],
  });

  app.directive('removeAngularEmailValidation', function () {
    const EMAIL_REGEXP = /.+/;

    return {
      require: '?ngModel',
      link: function (scope, elm, attrs, ctrl) {
        // only apply the validator if ngModel is present and AngularJS has added the email validator
        if (ctrl && ctrl.$validators.email) {
          // this will overwrite the default AngularJS email validator
          ctrl.$validators.email = function (modelValue) {
            return ctrl.$isEmpty(modelValue) || EMAIL_REGEXP.test(modelValue);
          };
        }
      },
    };
  });
})();

import React, { useState } from 'react';
import { Formik, Form as FormikForm, useField } from 'formik';

import {
  Landing__Location__ContentInput,
  LandingLocationContentQuery,
  useLandingLocationContentQuery,
  useLandingLocationContentUpsertMutation,
  Landing__Location__Service,
  Landing__Location__ReviewInput,
  Landing__Location__Content,
} from '@admin/schema';

import { Rating as ReviewRating } from '@admin/components/landing/location/reviews/rating';

import { client } from '@admin/libraries/apollo';

import { Panel } from '@admin/components/helpers/panel';
import { Breadcrumb, AnchorButton as Link, Button, Alert, Table, Modal } from '@shared/components/bootstrap';
import { PageHeader } from '@admin/components/helpers/page_header';
import { Spinner } from '@admin/components/spinner';
import { FormikInputFormGroup } from '@shared/components/fields/formik/formik_input_form_group';
import { FormikSelectFormGroup } from '@shared/components/fields/formik/formik_select_form_group';
import { FormikFileFormGroup } from '@admin/components/fields/formik/formik_file_form_group';
import { FormikHTMLFormGroup } from '@admin/components/fields/formik/formik_html_form_group';

type FormInput = Omit<Landing__Location__ContentInput, 'service' | 'locationID'> & {
  service?: Landing__Location__ContentInput['service'];
};

const DEFAULT_RATING = 5;

const ReviewModal: React.FC<{
  review?: Landing__Location__ReviewInput;
  onClose(): void;
  onSave(input: Landing__Location__ReviewInput): void;
}> = ({ review, onClose, onSave }) => {
  const onSubmit = (input: Landing__Location__ReviewInput) => {
    if (!input.author || !input.message) return;
    onSave(input);
  };

  return (
    <Modal onClose={onClose}>
      <Formik<Landing__Location__ReviewInput>
        initialValues={{
          author: review?.author ?? '',
          message: review?.message ?? '',
          rating: review?.rating ?? DEFAULT_RATING,
        }}
        onSubmit={onSubmit}
      >
        <FormikForm>
          <Modal.Content>
            <Modal.Header>
              <Modal.Title>Review</Modal.Title>
              <Modal.Close close={onClose} />
            </Modal.Header>
            <Modal.Body>
              <FormikInputFormGroup id="author" name="author" label="Author:" />
              <FormikInputFormGroup id="message" name="message" label="Message:" />
              <FormikSelectFormGroup id="rating" name="rating" label="Rating:">
                {Array.from({ length: 5 }).map((_, index) => (
                  <option key={index} value={index + 1}>
                    {index + 1} Star(s)
                  </option>
                ))}
              </FormikSelectFormGroup>
            </Modal.Body>
            <Modal.Footer>
              <Button type="submit" kind="primary">
                Save
              </Button>
            </Modal.Footer>
          </Modal.Content>
        </FormikForm>
      </Formik>
    </Modal>
  );
};

const ReviewsPanel: React.FC = () => {
  const [field, _, helpers] = useField<FormInput['reviews']>('reviews');
  const [index, setIndex] = useState<number | undefined>();

  return (
    <>
      <Panel>
        <Panel.Header>
          <Panel.Title>Reviews</Panel.Title>
        </Panel.Header>
        <Panel.Body>
          <Table striped>
            <thead>
              <tr>
                <th className="text-left">Author</th>
                <th className="text-left">Message</th>
                <th className="text-left">Rating</th>
                <th className="col-zero" />
              </tr>
            </thead>
            <tbody>
              {field.value.map((review, key) => (
                <tr key={key}>
                  <td className="text-left">{review.author}</td>
                  <td className="text-left">{review.message}</td>
                  <td className="text-left">
                    <ReviewRating review={review} />
                  </td>
                  <td className="col-zero">
                    <Button kind="primary" onClick={() => setIndex(key)}>
                      Edit
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Panel.Body>
        <Panel.Footer align="right">
          <Button kind="primary" onClick={() => setIndex(field.value.length)}>
            Add
          </Button>
        </Panel.Footer>
      </Panel>

      {index !== undefined && (
        <ReviewModal
          onSave={(input) => {
            const reviews = [...field.value];
            reviews[index] = {
              ...reviews[index],
              ...input,
              rating: Number(input.rating),
            };
            helpers.setValue(reviews);
            setIndex(undefined);
          }}
          onClose={() => setIndex(undefined)}
          review={field.value[index]}
        />
      )}
    </>
  );
};

const Fields: React.FC<{
  content?: LandingLocationContentQuery['content'];
  locationID?: string;
  onSave(content: Pick<Landing__Location__Content, 'id'>): void;
}> = ({ content, locationID, onSave }) => {
  const [save, { loading, data }] = useLandingLocationContentUpsertMutation({
    client,
    onCompleted: ({ result }) => {
      if (result?.resource) onSave(result.resource);
    },
  });

  const onSubmit = ({ service, title, description, ...input }: FormInput) => {
    if (!service) return;

    save({
      variables: {
        id: content?.id,
        input: {
          ...input,
          locationID,
          service,
          title: title || null,
          description: description || null,
        },
      },
    });
  };

  return (
    <Formik<FormInput>
      initialValues={{
        service: content?.service,
        blurb: content?.blurb ?? '',
        title: content?.title ?? '',
        headline: content?.headline ?? '',
        description: content?.description ?? '',
        heroAlt: content?.heroAlt ?? '',
        reviews:
          content?.reviews?.map((review) => ({
            id: review.id,
            author: review.author,
            message: review.message,
            rating: review.rating,
          })) ?? [],
      }}
      onSubmit={onSubmit}
    >
      <FormikForm>
        {data?.result?.error && <Alert style="danger">{data.result.error}</Alert>}

        <Panel>
          <Panel.Header>
            <Panel.Title>Content</Panel.Title>
          </Panel.Header>
          <Panel.Body>
            <FormikSelectFormGroup id="service" name="service" label="Service:">
              <option> - Service - </option>
              <option value={Landing__Location__Service.Moving}>Moving</option>
              <option value={Landing__Location__Service.SmartStorage}>Smart Storage</option>
            </FormikSelectFormGroup>
            <FormikInputFormGroup id="title" name="title" label="Title:" />
            <FormikInputFormGroup id="headline" name="headline" label="Headline:" />
            <FormikInputFormGroup id="description" name="description" label="Description:" />
            <FormikHTMLFormGroup id="blurb" name="blurb" label="Blurb:" />
            <FormikInputFormGroup id="heroAlt" name="heroAlt" label="Hero Alt:" />
            <FormikFileFormGroup id="heroImage" name="heroImage" label="Hero Image:" accept="image/*" />
          </Panel.Body>
        </Panel>

        <ReviewsPanel />

        <Panel>
          <Panel.Footer align="right">
            {content?.id && (
              <Link disabled={loading} kind="default" href={`/landing/location/contents/${content.id}`}>
                Cancel
              </Link>
            )}
            <Button loading={loading} kind="primary" type="submit">
              Save
            </Button>
          </Panel.Footer>
        </Panel>
      </FormikForm>
    </Formik>
  );
};

export const LandingLocationContentForm: React.FC<{
  id?: string;
  locationID?: string; // a global ID for a city / neighborhood
  onSave(content: Pick<Landing__Location__Content, 'id'>): void;
}> = ({ id, locationID, onSave }) => {
  const { data } = useLandingLocationContentQuery({
    client,
    fetchPolicy: 'network-only',
    variables: { id: id! },
    skip: !id,
  });
  const content = data?.content;

  return (
    <>
      <PageHeader>
        <Breadcrumb>
          <Breadcrumb.Item>Landing</Breadcrumb.Item>
          <Breadcrumb.Item>Location</Breadcrumb.Item>
          <Breadcrumb.Item>Content</Breadcrumb.Item>
          {id && (
            <Breadcrumb.Item>
              <a href={`/landing/location/contents/${id}`}>{id}</a>
            </Breadcrumb.Item>
          )}
          <Breadcrumb.Item active>{id ? 'Edit' : 'New'}</Breadcrumb.Item>
        </Breadcrumb>
      </PageHeader>

      {!id || content ? <Fields content={content} locationID={locationID} onSave={onSave} /> : <Spinner />}
    </>
  );
};

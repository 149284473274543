import React, { useState } from 'react';
import { Panel } from '@admin/components/helpers/panel';
import { client } from '@admin/libraries/apollo';
import { DamagedItemsQuery, DamagedItem__Kind, useDamagedItemsQuery } from '@admin/schema';
import { Timestamp } from '@admin/components/timestamp';
import { Lightbox } from '@shared/components/helpers';
import styled from '@emotion/styled';
import { COLORS } from '@clutter/clean';
import { TimeZoneContext } from '../dispatch/context';

const ImagesContainer = styled.div`
  display: flex;
  width: 100%;
  height: fit-content;
  white-space: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
`;

const ImageContainer = styled.div`
  border: 1px solid;
  &:hover {
    cursor: pointer;
  }
`;

const Image = styled.img`
  margin: 0;
  padding: 0;
  border: none;
  display: block;
  width: 100%;
`;

export const DamagedItemsPanel: React.FC<{ orderID: string }> = ({ orderID }) => {
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const { data, loading } = useDamagedItemsQuery({ client, variables: { orderID } });

  if (loading || !data) {
    return null;
  }

  const timezone = data.order.region!.tz;
  const damagedItems = data.order.damagedItems;
  if (damagedItems.length === 0) {
    return null;
  }

  return (
    <TimeZoneContext.Provider value={timezone}>
      <Panel>
        <Panel.Header>
          <Panel.Collapse collapsed={collapsed} onChange={setCollapsed} />
          <Panel.Title>Damaged Items</Panel.Title>
        </Panel.Header>
        {collapsed || (
          <Panel.Body>
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th className="text-left col-sm-4">Details</th>
                    <th className="text-left col-sm-8">Images</th>
                  </tr>
                </thead>
                <tbody>
                  <Rows damagedItems={damagedItems} timezone={timezone} />
                </tbody>
              </table>
            </div>
          </Panel.Body>
        )}
      </Panel>
    </TimeZoneContext.Provider>
  );
};

interface IImageSelection {
  imageSrc: string;
}

const Images: React.FC<{ damagedItem: DamagedItemsQuery['order']['damagedItems'][0] }> = ({ damagedItem }) => {
  const [selection, setSelection] = useState<IImageSelection | undefined>();
  return (
    <>
      <ImagesContainer>
        {damagedItem.images.map((image) => (
          <div key={image.id} className="col-xs-6 col-md-4 col-lg-2">
            <ImageContainer
              onClick={() => {
                setSelection({ imageSrc: image.source });
              }}
            >
              <Image src={image!.size} />
            </ImageContainer>
          </div>
        ))}
      </ImagesContainer>
      {selection && <Lightbox imageURL={selection.imageSrc} onClose={() => setSelection(undefined)} />}
    </>
  );
};

const Rows: React.FC<{ damagedItems: DamagedItemsQuery['order']['damagedItems']; timezone: string }> = ({
  damagedItems,
  timezone,
}) => (
  <>
    {damagedItems.map((damagedItem) => (
      <tr key={damagedItem.id}>
        <td className="text-left col-sm-4">
          <ul>
            <li>
              <strong>Reported at:</strong>{' '}
              <Timestamp value={damagedItem.timestamp} format="medium" timezone={timezone} />
            </li>
            <li>
              <strong>Reported by:</strong> {damagedItem.user.name}
            </li>
            <li>
              <strong>Kind: </strong>
              <strong
                style={{
                  color: damagedItem.kind === DamagedItem__Kind.Preexisting ? COLORS.tealPrimary : COLORS.toucan,
                }}
              >
                {damagedItem.kind}
              </strong>
            </li>
            <li>
              <strong>Reason:</strong> {damagedItem.reason}
            </li>
          </ul>
        </td>
        <td className="text-left col-sm-8">
          <Images damagedItem={damagedItem} />
        </td>
      </tr>
    ))}
  </>
);

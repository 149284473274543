import { sumBy } from 'lodash';
import React, { useState, useEffect } from 'react';

import { ListGroup } from '@shared/components/bootstrap';
import { Currency } from '@shared/components/helpers';

import { IResolutionEdits, selectionAmount } from './helpers';
import { IssueSelection } from './issue_selection';
import { ItemSelections } from './item_selections';
import { OfferButtons } from './offer_buttons';
import { PropertySelection } from './property_selection';
import { compensated } from '../helpers';
import { IClaimStepProps } from '../steps';
import { calculateTotalApproved, calculateTotalClaimed } from '../totals';
import { IssueSelectionDescription } from '../issue_selection_description';

export const Offer: React.FC<IClaimStepProps> = ({ claim, onNext }) => {
  const [issueEdit, setIssueEdit] = useState<IResolutionEdits>({});
  const [itemEdits, setItemEdits] = useState<IResolutionEdits>({});
  const [propertyEdit, setPropertyEdit] = useState<IResolutionEdits>({});

  const { issueSelection, itemSelections, propertySelection } = claim;

  const totalClaimed = calculateTotalClaimed(itemSelections, propertySelection);
  const totalApproved = calculateTotalApproved(itemSelections, propertySelection);

  const issueTotalCompensated = issueSelection ? selectionAmount(issueEdit, issueSelection) || 0 : 0;
  const itemTotalCompensated = sumBy(itemSelections, (selection) => selectionAmount(itemEdits, selection) || 0);
  const propertyTotalCompensated = propertySelection ? selectionAmount(propertyEdit, propertySelection) || 0 : 0;
  const totalCompensated = issueTotalCompensated + itemTotalCompensated + propertyTotalCompensated;

  useEffect(() => {
    let itemResolutionChanges = {};
    for (const selection of itemSelections) {
      if (selection.resolution?.amount) {
        continue;
      }
      const approvedAmount = selection.assessment?.approvedAmount;
      const amount = compensated(selection) ? approvedAmount : 0;
      itemResolutionChanges = { ...itemResolutionChanges, [selection.id]: amount };
    }
    if (Object.keys(itemResolutionChanges).length) {
      setItemEdits({ ...itemEdits, ...itemResolutionChanges });
    }
  }, []);

  return (
    <ListGroup className="list-group-flush">
      {issueSelection && <IssueSelection selection={issueSelection} edit={issueEdit} setEdit={setIssueEdit} />}
      {!!itemSelections.length && (
        <ItemSelections
          selections={itemSelections}
          accountID={claim.account.id}
          edits={itemEdits}
          setEdits={setItemEdits}
        />
      )}
      {propertySelection && (
        <PropertySelection selection={propertySelection} edit={propertyEdit} setEdit={setPropertyEdit} />
      )}
      {(!!itemSelections.length || propertySelection) && (
        <ListGroup.Item>
          <div className="row">
            <div className="col-sm-1 col-sm-offset-6">
              <strong>
                <Currency value={totalClaimed} />
              </strong>
            </div>
            <div className="col-sm-1 col-sm-offset-2">
              <strong>
                <Currency value={totalApproved} />
              </strong>
            </div>
            <div className="col-sm-2 text-right">
              <strong>
                <Currency value={totalCompensated} />
              </strong>
            </div>
          </div>
        </ListGroup.Item>
      )}
      {issueSelection && <IssueSelectionDescription issueSelection={issueSelection} />}
      <OfferButtons
        claim={claim}
        issueEdit={issueEdit}
        itemEdits={itemEdits}
        propertyEdit={propertyEdit}
        totalCompensated={totalCompensated}
        onOffered={onNext}
      />
    </ListGroup>
  );
};

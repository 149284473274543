import moment from 'moment';
import { isArray, compact, includes, find, sortBy } from 'lodash';
import { createSelector } from 'reselect';
import { selectDispatchEntities, selectCheckinEntities, selectRegionEntities } from '../../../redux';

export const selectFilteredDispatches = createSelector(
  [(_, props) => props.regionId, (_, props) => props.date, selectDispatchEntities],
  (regionId, date, dispatches) => {
    if (!isArray(dispatches)) {
      return [];
    }
    const compactedDispatches = compact(dispatches);
    const filteredDispatches = compactedDispatches.filter(
      (dispatch) => dispatch.region.id === regionId && moment(dispatch.arrival).isSame(moment(date), 'date'),
    );
    return sortBy(filteredDispatches, 'id');
  },
);

export const selectFilteredCheckins = createSelector(
  [selectFilteredDispatches, selectCheckinEntities],
  (dispatches, checkins) => {
    if (!isArray(dispatches) || !isArray(checkins)) {
      return [];
    }
    const dispatchIds = compact(dispatches).map((dispatch) => dispatch.id);
    const compactedCheckins = compact(checkins);
    return compactedCheckins.filter((checkin) => includes(dispatchIds, checkin.dispatch.id));
  },
);

export const selectFilteredRegion = createSelector(
  [(_, props) => props.regionId, selectRegionEntities],
  (regionId, regions) => {
    if (!isArray(regions)) {
      return null;
    }
    const compactedRegions = compact(regions);
    return find(compactedRegions, { id: regionId });
  },
);

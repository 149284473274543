(function () {
  const app = angular.module('app');

  app.controller('RedirectsController', [
    '$rootScope',
    '$location',
    '$scope',
    'Redirect',
    'PaginationService',
    'FiltersService',
    function ($rootScope, $location, $scope, Redirect, PaginationService, FiltersService) {
      $scope.filters = $location.search();
      $scope.pagination = PaginationService.initialize({ page: $location.search().page });

      $scope.filter = function (filters) {
        if (!filters) {
          filters = $scope.filters || {};
        } else {
          $scope.pagination.reset();
        }

        filters = FiltersService.cleanup(filters);
        filters.page = $scope.pagination.page;
        $location.search(filters);

        Redirect.get(filters).$promise.then(function (data) {
          $scope.pagination.prepare(data.pagination);
          $scope.redirects = data.results;
        });
      };

      $scope.filter();

      $scope.delete = function (id) {
        Redirect.delete(
          { id: id },
          function () {
            $scope.filter();
          },
          function (error) {
            $rootScope.$broadcast('message:group:display', error.data);
          },
        );
      };
    },
  ]);

  app.controller('RedirectNewController', [
    '$rootScope',
    '$scope',
    '$state',
    'Redirect',
    function ($rootScope, $scope, $state, Redirect) {
      $scope.redirect = new Redirect();

      $scope.addRedirect = function () {
        $scope.redirect.slugs = $scope.redirect.slugs.map(function (slug) {
          return slug.text.toLowerCase();
        });
        const redirect = $scope.redirect;
        redirect.$save(
          function () {
            $state.go('redirects');
          },
          function (error) {
            $rootScope.$broadcast('message:group:display', error.data);
          },
        );
      };
    },
  ]);

  app.controller('RedirectEditController', [
    '$rootScope',
    '$scope',
    '$state',
    '$stateParams',
    'Redirect',
    function ($rootScope, $scope, $state, $stateParams, Redirect) {
      Redirect.get({ id: $stateParams.id }).$promise.then(function (data) {
        $scope.redirect = data;
      });

      $scope.editRedirect = function () {
        $scope.redirect.slugs = $scope.redirect.slugs.map(function (slug) {
          return slug.text.toLowerCase();
        });
        const redirect = $scope.redirect;
        redirect.$update(
          function () {
            $state.go('redirects');
          },
          function (error) {
            $rootScope.$broadcast('message:group:display', error.data);
          },
        );
      };
    },
  ]);
})();

import { useField } from 'formik';
import * as React from 'react';
import { forwardRef } from 'react';

import { FieldFormGroupProps } from '../field_form_group';

import { InputFormControlType, InputFormControlProps, InputFormControl } from '../input_form_control';

type InputFormGroupType = InputFormControlType;
type InputFormGroupProps = InputFormControlProps & FieldFormGroupProps & { name: string };

export const FormikInputFormControl = forwardRef<InputFormGroupType, InputFormGroupProps>(
  ({ help, label, children, ...input }, ref) => {
    const [field] = useField(input.name);
    return <InputFormControl ref={ref} {...field} {...input} />;
  },
);

import * as React from 'react';

import { Modal } from '@shared/components/bootstrap';
import { GiftCardResource, Status } from '../../resources/gift_card';

enum Result {
  Pristine = 'pristine',
  Success = 'success',
  Error = 'error',
}

const statusToLinkText = (status: Status | null) => {
  switch (status) {
    case Status.Retrieved:
    case Status.Retrievable:
    case Status.TooSoon:
      return 'Resend Link';
    case Status.NoEmail:
      return 'Issue';
    case Status.TooLate:
      return 'Extend for 30 days';
  }
};

export const GiftCardWidget: React.FC<{
  accountID: number;
}> = ({ accountID }) => {
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [result, setResult] = React.useState<Result>(Result.Pristine);
  const [status, setStatus] = React.useState<Status | null>(null);
  const [statusText, setStatusText] = React.useState<string>('loading...');
  const getStatus = () =>
    GiftCardResource.status(accountID)
      .then((statusInfo) => {
        setStatus(statusInfo.status);
        setStatusText(statusInfo.text);
      })
      .catch(() => setStatusText('Error retrieving status'));

  React.useEffect(() => {
    getStatus();
  }, []);

  const issueGiftCard = async (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    if (result === 'success') {
      return;
    }

    try {
      await GiftCardResource.issue(accountID);
      setResult(Result.Success);
    } catch (e) {
      setResult(Result.Error);
    }
    await getStatus();
  };

  const closeModal = () => setShowModal(false);

  return (
    <div className="gift-card-widget">
      {showModal && (
        <Modal onClose={closeModal}>
          <Modal.Content>
            <Modal.Header>
              <Modal.Title>Are you sure?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h4>
                This will send the account holder an email notification allowing them to redeem an Amazon gift card.
              </h4>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                className="btn btn-info"
                onClick={(e) => {
                  issueGiftCard(e);
                  closeModal();
                }}
              >
                Issue
              </button>{' '}
              <button type="button" className="btn btn-danger" onClick={closeModal}>
                Cancel
              </button>
            </Modal.Footer>
          </Modal.Content>
        </Modal>
      )}
      <div>
        <strong>Gift Card Status:&nbsp;</strong>
        <span>
          {statusText}&nbsp;
          {status !== Status.NotApplicable && (
            <a
              href="#"
              onClick={(e) => {
                if (result !== Result.Success) {
                  if (status === Status.NoEmail) {
                    setShowModal(true);
                  } else {
                    issueGiftCard(e);
                  }
                }
              }}
              className="underline"
            >
              {result === Result.Pristine && statusToLinkText(status)}
              {result === Result.Success && 'Success!'}
              {result === Result.Error && 'Something went wrong... click to retry'}
            </a>
          )}
        </span>
      </div>
    </div>
  );
};

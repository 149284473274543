import React from 'react';
import { Formik, Form as FormikForm } from 'formik';

import {
  Landing__Location__NeighborhoodInput,
  LandingLocationNeighborhoodQuery,
  useLandingLocationNeighborhoodQuery,
  useLandingLocationNeighborhoodUpsertMutation,
  useLandingLocationCitiesQuery,
  Landing__Location__Neighborhood,
} from '@admin/schema';

import { client } from '@admin/libraries/apollo';

import { Breadcrumb, Panel, AnchorButton as Link, Button, Alert } from '@shared/components/bootstrap';
import { PageHeader } from '@admin/components/helpers/page_header';
import { Spinner } from '@admin/components/spinner';
import { FormikSelectFormGroup } from '@shared/components/fields/formik/formik_select_form_group';
import { FormikInputFormGroup } from '@shared/components/fields/formik/formik_input_form_group';

const FormikCityFormGroup: React.FC<{
  id: string;
  name: string;
  label: string;
}> = (props) => {
  const { data, loading } = useLandingLocationCitiesQuery({ client });

  return (
    <FormikSelectFormGroup disabled={loading} {...props}>
      <option value=""> - City - </option>
      {data?.cities.map((city) => (
        <option key={city.id} value={city.id}>
          {city.name}
        </option>
      ))}
    </FormikSelectFormGroup>
  );
};

type FormInput = Omit<Landing__Location__NeighborhoodInput, 'latitude' | 'longitude'> & {
  latitude: string;
  longitude: string;
};

const Fields: React.FC<{
  neighborhood?: LandingLocationNeighborhoodQuery['neighborhood'];
  onSave(neighborhood: Pick<Landing__Location__Neighborhood, 'id'>): void;
}> = ({ neighborhood, onSave }) => {
  const [save, { loading, data }] = useLandingLocationNeighborhoodUpsertMutation({
    client,
    onCompleted: ({ result }) => {
      if (result?.resource) onSave(result.resource);
    },
  });

  const onSubmit = (input: FormInput) => {
    const latitude = Number(input.latitude) || undefined;
    const longitude = Number(input.longitude) || undefined;
    if (!latitude || !longitude) return;

    save({
      variables: {
        id: neighborhood?.id,
        input: {
          ...input,
          latitude,
          longitude,
        },
      },
    });
  };

  return (
    <Formik<FormInput>
      initialValues={{
        name: neighborhood?.name ?? '',
        slug: neighborhood?.slug ?? '',
        latitude: neighborhood ? String(neighborhood.latitude) : '',
        longitude: neighborhood ? String(neighborhood.longitude) : '',
        cityID: neighborhood?.city.id ?? '',
      }}
      onSubmit={onSubmit}
    >
      <FormikForm>
        <Panel>
          <Panel.Body>
            {data?.result?.error && <Alert style="danger">{data.result.error}</Alert>}
            <FormikCityFormGroup id="cityID" name="cityID" label="City:" />
            <FormikInputFormGroup id="slug" name="slug" label="Slug:" />
            <FormikInputFormGroup id="name" name="name" label="Name:" />
            <FormikInputFormGroup id="latitude" name="latitude" label="Latitude:" />
            <FormikInputFormGroup id="longitude" name="longitude" label="Longitude:" />
          </Panel.Body>
          <Panel.Footer align="right">
            <Link disabled={loading} kind="default" href="/landing/location/neighborhoods">
              Cancel
            </Link>
            <Button loading={loading} kind="primary" type="submit">
              Save
            </Button>
          </Panel.Footer>
        </Panel>
      </FormikForm>
    </Formik>
  );
};

export const LandingLocationNeighborhoodForm: React.FC<{
  id?: string;
  onSave(neighborhood: Pick<Landing__Location__Neighborhood, 'id'>): void;
}> = ({ id, onSave }) => {
  const { data } = useLandingLocationNeighborhoodQuery({
    client,
    fetchPolicy: 'network-only',
    variables: { id: id! },
    skip: !id,
  });
  const neighborhood = data?.neighborhood;

  return (
    <>
      <PageHeader>
        <Breadcrumb>
          <Breadcrumb.Item>Landing</Breadcrumb.Item>
          <Breadcrumb.Item>Location</Breadcrumb.Item>
          <Breadcrumb.Item>
            <a href="/landing/location/cities">Neighborhoods</a>
          </Breadcrumb.Item>
          {id && (
            <Breadcrumb.Item>
              <a href={`/landing/location/neighborhoods/${id}`}>{neighborhood?.name ?? '-'}</a>
            </Breadcrumb.Item>
          )}
          <Breadcrumb.Item active>{id ? 'Edit' : 'New'}</Breadcrumb.Item>
        </Breadcrumb>
      </PageHeader>

      {!id || neighborhood ? <Fields neighborhood={neighborhood} onSave={onSave} /> : <Spinner />}
    </>
  );
};

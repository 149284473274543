import React from 'react';
import { GoogleMap, withGoogleMap } from 'react-google-maps';
import { flowRight, isEmpty, pick, isEqual, flatten, find, last, keys, lowerCase } from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { featureCollection, envelope, point } from '@turf/turf';
import PropTypes from 'prop-types';
import { selectFilteredDispatches, selectFilteredCheckins, selectFilteredRegion } from './selectors';
import mapOptions from './mapOptions.json';
import { getValidOrders, getDedupedValidOrdersForDispatch, withProps } from './utils';
import { DashboardDispatchesMarkers, DashboardWarehouseMarker } from './DashboardMarkers';
import { loadCheckinEntitiesForDispatches, getUiGmapGoogleMapApi } from '../../../redux';
import { getOrderEndTime, getOrderLeaveTime } from '../../../models';
import { layoutEventSystem } from './propTypes';
import { BOUNDS_PADDING } from './contants';

const pointFromRoute = (route) => ({
  lat: Number(route.latitude),
  lng: Number(route.longitude),
});

const OK = 'OK';

const waitFor = (time) => new Promise((resolve) => setTimeout(resolve, time));

const MAP_OPTIONS = {
  gestureHandling: 'greedy',
  styles: mapOptions,
};

class DashboardMap extends React.Component {
  static propTypes = {
    ready: PropTypes.bool.isRequired,
    getUiGmapGoogleMapApi: PropTypes.func.isRequired,
    dispatches: PropTypes.array.isRequired,
    region: PropTypes.object,
    onReadyChange: PropTypes.func.isRequired,
    loadCheckinEntitiesForDispatches: PropTypes.func.isRequired,
    ...layoutEventSystem,
  };

  state = {
    directionsByDispatch: {},
  };

  _focusRegion = (region) => {
    if (this._map && region) {
      this._fitBounds(flatten(region.coordinates));
    }
  };

  _fitBounds(points) {
    const features = featureCollection(points.map((c) => point([Number(c.longitude), Number(c.latitude)])));
    const { geometry } = envelope(features);
    const { coordinates } = geometry;
    const flattenedCoordinates = flatten(coordinates);
    const [sw, _, ne] = flattenedCoordinates;
    const bounds = {
      east: ne[0],
      west: sw[0],
      north: ne[1],
      south: sw[1],
    };
    this._map.fitBounds(bounds, BOUNDS_PADDING);
  }

  _getDispatchIds = (dispatches) => {
    if (!isEmpty(dispatches)) {
      return dispatches.map((dispatch) => dispatch.id);
    }
    return [];
  };

  _loadDirectionData = async (newProps = this.props) => {
    if (this.state.directionsByDispatch[newProps.activeDispatchId]) {
      return;
    }
    newProps.onReadyChange(false);
    try {
      await waitFor(500); // give animations half a second before requesting route data
      const dispatch = this._findDispatch(newProps.activeDispatchId, newProps.dispatches);
      const directionsForDispatch = await this._loadDirectionsForRoute(this._getRoutesForDispatch(dispatch));
      this.setState(({ directionsByDispatch }) => ({
        directionsByDispatch: {
          ...directionsByDispatch,
          [newProps.activeDispatchId]: directionsForDispatch,
        },
      }));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
    newProps.onReadyChange(true);
  };

  _getActiveDispatch = (props = this.props) => this._findDispatch(props.activeDispatchId, props.dispatches);

  _getActiveOrder = (props = this.props) => this._findOrder(props.activeOrderId, props.dispatches);

  _getActiveCheckin = (props = this.props) => this._findCheckin(props.activeCheckinId, props.checkins);

  _findOrder = (id, dispatches = this.props.dispatches) => find(getValidOrders(dispatches), { id });

  _findDispatch = (id, dispatches = this.props.dispatches) => find(dispatches, { id });

  _findCheckin = (id, checkins = this.props.checkins) => find(checkins, { id });

  _directionServiceRoute = (config) =>
    new Promise((resolve, reject) =>
      this._gMapsDirectionService.route(config, (result, status) => (status === OK ? resolve(result) : reject(result))),
    );

  _loadDirectionsForRoute = (route) => {
    const pairs = route.reduce((memo, leg, i, routes) => (i > 0 ? memo.concat([[routes[i - 1], leg]]) : memo), []);
    return Promise.all(
      pairs.map(([start, end]) => {
        const config = {
          origin: pointFromRoute(start.coordinate),
          provideRouteAlternatives: false,
          drivingOptions: {
            trafficModel: this._gMaps.TrafficModel.PESSIMISTIC,
          },
          travelMode: this._gMaps.TravelMode.DRIVING,
          destination: pointFromRoute(end.coordinate),
        };
        if (end.departFor) {
          config.drivingOptions.departureTime = end.departFor.toDate();
        } else {
          config.drivingOptions.departureTime = new Date();
        }
        if (moment(config.drivingOptions.departureTime).isBefore(new Date())) {
          config.drivingOptions.departureTime = new Date();
        }
        return this._directionServiceRoute(config).then((result) => {
          result.id = end.id;
          result.key = `${start.type}:${start.id}::${end.type}:${end.id}`;
          result.type = end.type;
          result.completed = end.completed;
          return result;
        });
      }),
    );
  };

  _getRoutesForDispatch = (dispatch) => {
    if (!dispatch) {
      return [];
    }
    const warehouse = {
      type: 'warehouse',
      id: 'leave',
      coordinate: pick(dispatch.warehouse.address, ['latitude', 'longitude']),
    };
    const orders = getDedupedValidOrdersForDispatch(dispatch);
    const lastOrder = last(orders);
    return [
      warehouse,
      ...orders.map((o, i) => ({
        type: 'order',
        completed: lowerCase(o.state) === 'completed',
        id: o.id,
        coordinate: pick(o.address, ['latitude', 'longitude']),
        departFor: moment(i === 0 ? getOrderLeaveTime(o) : getOrderEndTime(orders[i - 1])),
      })),
      {
        ...warehouse,
        id: 'return',
        completed: dispatch.state !== 'active',
        departFor: moment(getOrderEndTime(lastOrder)),
      },
    ];
  };

  _eventSystem = () => pick(this.props, keys(layoutEventSystem));

  async componentDidMount() {
    this._gMaps = await this.props.getUiGmapGoogleMapApi();
    this._gMapsDirectionService = new this._gMaps.DirectionsService();
    if (!isEmpty(this._getDispatchIds())) {
      this.props.loadCheckinEntitiesForDispatches(this._getDispatchIds());
    }
    if (this.props.region) {
      this._focusRegion(this.props.region);
    }
    this.props.onReadyChange(true);
  }

  _checkForMapFocusChange = (currentProps, newProps) => {
    if (newProps.activeOrderId && currentProps.activeOrderId !== newProps.activeOrderId) {
      // order focus - focus on the single order
      const activeOrder = this._getActiveOrder(newProps);
      if (activeOrder && activeOrder.state !== 'canceled') {
        this._map.panTo({ lat: Number(activeOrder.address.latitude), lng: Number(activeOrder.address.longitude) });
      }
    } else if (newProps.activeCheckinId && currentProps.activeCheckinId !== newProps.activeCheckinId) {
      // checkin focus - focus on the single checkin
      const activeCheckin = this._getActiveCheckin(newProps);
      if (activeCheckin) {
        this._map.panTo({ lat: Number(activeCheckin.latitude), lng: Number(activeCheckin.longitude) });
      }
    } else if (
      newProps.activeDispatchId &&
      (currentProps.activeDispatchId !== newProps.activeDispatchId ||
        (!newProps.activeOrderId && currentProps.activeOrderId))
    ) {
      // no order focus - let's look at the whole dispatch
      const activeDispatch = this._getActiveDispatch(newProps);
      this._fitBounds(
        getDedupedValidOrdersForDispatch(activeDispatch)
          .map((o) => o.address)
          .concat([activeDispatch.warehouse.address]),
      );
    } else if (!isEqual(currentProps.region, newProps.region)) {
      // we have a new region
      this._focusRegion(newProps.region);
    }
  };

  componentDidUpdate(prevProps) {
    if (!isEqual(this._getDispatchIds(prevProps.dispatches), this._getDispatchIds(this.props.dispatches))) {
      this.props.loadCheckinEntitiesForDispatches(this._getDispatchIds(this.props.dispatches));
    }
    if (this.props.ready && this.props.ready !== prevProps.ready) {
      this._checkForMapFocusChange({}, prevProps);
    } else {
      this._checkForMapFocusChange(prevProps, this.props);
    }

    // direction loader logic
    if (this.props.activeDispatchId) {
      if (prevProps.activeDispatchId !== this.props.activeDispatchId) {
        this._loadDirectionData(this.props);
      } else if (
        !isEqual(
          this._getRoutesForDispatch(this._findDispatch(prevProps.activeDispatchId)),
          this._getRoutesForDispatch(this._findDispatch(this.props.activeDispatchId, this.props.dispatches)),
        )
      ) {
        // route data is now invalid
        this.setState(
          ({ directionsByDispatch }) => ({
            directionsByDispatch: {
              ...directionsByDispatch,
              [this.props.activeDispatchId]: null,
            },
          }),
          () => this._loadDirectionData(),
        );
      }
    }
  }

  render() {
    const { dispatches = [] } = this.props;
    return (
      <GoogleMap
        ref={(ref) => (this._map = ref)}
        defaultZoom={14}
        defaultCenter={{ lat: 34.026308, lng: -118.3813549 }}
        options={MAP_OPTIONS}
      >
        <DashboardWarehouseMarker key="warehouses" dispatches={dispatches} />,
        <DashboardDispatchesMarkers
          key="dispatches"
          gMaps={this._gMaps}
          dispatches={dispatches}
          directionsByDispatch={this.state.directionsByDispatch}
          {...this._eventSystem()}
        />
      </GoogleMap>
    );
  }
}

const mapsSelector = createSelector(
  selectFilteredDispatches,
  selectFilteredCheckins,
  selectFilteredRegion,
  (dispatches, checkins, region) => ({
    dispatches: dispatches.map((d) => ({
      ...d,
      checkins: checkins.filter((c) => c.dispatch.id === d.id),
    })),
    region,
  }),
);

const mapsDispatchMethods = {
  loadCheckinEntitiesForDispatches,
  getUiGmapGoogleMapApi,
};

export default flowRight(
  connect(mapsSelector, mapsDispatchMethods),
  // This is to get around a requirement that react google maps has
  withProps({
    loadingElement: <div style={{ height: '100%' }} />,
    containerElement: <div className="min-content-height" style={{ height: '400px' }} />,
    mapElement: <div style={{ height: '100%' }} />,
  }),
  withGoogleMap,
)(DashboardMap);

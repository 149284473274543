import React from 'react';
import { Order__CancelReason, useMoveCancelReasonsQuery, useOnboardingCancelReasonsQuery } from '@admin/schema';
import { Spinner } from '@admin/components/spinner';
import { client } from '@admin/libraries/apollo';
import { includes } from 'lodash';

const FILTERED_OUT_REASONS = ['Pricing - $750 Prepay'];

const ReasonSelection: React.FC<{
  title: string;
  reasonID?: string;
  setReasonID(reasonID: string): void;
  reasons?: Order__CancelReason[];
}> = ({ title, reasonID, setReasonID, reasons }) => {
  if (!reasons) {
    return <Spinner />;
  }

  return (
    <div className="text-center">
      <div>{title}</div>
      <select onChange={(event) => setReasonID(event.target.value)} value={reasonID}>
        <option value="">Select a Reason</option>
        {filterCancelReasons(reasons).map((cancelReason) => (
          <option key={cancelReason.id} value={cancelReason.id}>
            {cancelReason.kind}
          </option>
        ))}
      </select>
    </div>
  );
};

function filterCancelReasons(reasons: Order__CancelReason[]) {
  return reasons.filter((cancelReason) => !includes(FILTERED_OUT_REASONS, cancelReason.kind));
}

export const OnboardingCancelReasonSelection: React.FC<{ reasonID?: string; setReasonID(reason: string): void }> = ({
  reasonID,
  setReasonID,
}) => {
  const { data } = useOnboardingCancelReasonsQuery({ client });

  return (
    <ReasonSelection
      title="Why are you cancelling this onboarding?"
      reasonID={reasonID}
      setReasonID={setReasonID}
      reasons={data?.reasons}
    />
  );
};

export const MoveCancelReasonSelection: React.FC<{ reasonID?: string; setReasonID(reasonID: string): void }> = ({
  reasonID,
  setReasonID,
}) => {
  const { data } = useMoveCancelReasonsQuery({ client });

  return (
    <ReasonSelection
      title="Why are you cancelling this move?"
      reasonID={reasonID}
      setReasonID={setReasonID}
      reasons={data?.reasons}
    />
  );
};

import wt from '@clutter/wt';

import { currentUser } from '@admin/config/current_user';
import { WT_COOKIE_DOMAIN, WT_COOKIE_EXPIRES, WT_TRACKER_DOMAIN, WT_TRACKER_URL } from '@shared/config/wt';

wt.initialize({
  trackerUrl: WT_TRACKER_URL,
  trackerDomain: WT_TRACKER_DOMAIN,
  cookieOptions: {
    domain: WT_COOKIE_DOMAIN,
    expires: WT_COOKIE_EXPIRES,
  },
});

wt.set({ user_id: currentUser?.id });

(function () {
  const app = angular.module('app');

  app.controller('ItemCategoriesController', [
    '$scope',
    'EventConstants',
    function ($scope, EventConstants) {
      $scope.editingCategories = false;

      $scope.toggleCategoriesMode = function () {
        $scope.editingCategories = !$scope.editingCategories;

        if ($scope.editingCategories) {
          $scope.$broadcast('edit_category_mode:enabled');
          setTimeout($scope.focusOnNextCategoryElement, 300);
        } else {
          $scope.$broadcast('edit_category_mode:disabled');
        }
      };

      $scope.$on(EventConstants.ITEM_CATEGORY_EDITOR_SAVED, function (options) {
        $scope.focusOnNextCategoryElement(options.event);
      });

      $scope.focusOnNextCategoryElement = function (event) {
        const pendingContainers = $('.category-search-input:visible').filter(function () {
          return this.value === '';
        });

        let firstPendingElement = pendingContainers.get(0);

        if (event) {
          const eventContainer = $(event.target).closest('.category-search-input');
          if (pendingContainers.get(0) === eventContainer.get(0)) {
            // if user enters skips current container, do not treat current container as first element
            firstPendingElement = pendingContainers.get(1);
          }
        }

        if (firstPendingElement) {
          firstPendingElement.focus();
        }
      };
    },
  ]);
})();

(function () {
  angular.module('app').factory('WorkforceSurveyResponse', [
    '$resource',
    function ($resource) {
      return $resource(
        '/workforces/surveys/:survey_id/responses/:id.json',
        {
          survey_id: '@survey_id',
          id: '@id',
        },
        {
          create: {
            method: 'POST',
          },
        },
      );
    },
  ]);
})();

(() => {
  angular.module('app').service('ErrorService', [
    'ConfirmationService',
    '$rootScope',

    function (ConfirmationService, $rootScope) {
      const TITLE = 'Whoops';
      const TEMPLATE = '/partials/dialogs/_default.html';
      const DEFAULT = 'An unknown error occurred. Please contact technical support.';
      const MAINTENANCE = 'The server is undergoing maintenance. Please try again shortly.';
      const EXCEPTIONAL = 'The server encountered an unexpected error. Please try again shortly.';
      const FORBIDDEN = "Forbidden - You don't have permission to perform this action";

      const display = (description, title) =>
        ConfirmationService.confirm({
          template: TEMPLATE,
          title: title || TITLE,
          description: description || DEFAULT,
        });

      this.message = (response) => {
        if (response && response.data && response.data.message) {
          return response.data.message;
        }
        if (response && response.message) {
          return response.message;
        }

        switch (response.status) {
          case 403:
            return FORBIDDEN;
          case 500:
            return EXCEPTIONAL;
          case 503:
            return MAINTENANCE;
          default:
            return DEFAULT;
        }
      };

      this.handle = (response) => display(this.message(response));

      this.flash = (response) => {
        $rootScope.$broadcast('message:display', {
          type: 'danger',
          body: this.message(response),
        });
      };

      this.refreshBanner = (errorObj) => {
        $rootScope.$broadcast('message:clear');
        $rootScope.$broadcast('message:group:display', errorObj);
      };
    },
  ]);
})();

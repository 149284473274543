import { DateTime } from 'luxon';
import React, { useEffect, useRef, useState } from 'react';

import { client } from '@admin/libraries/apollo';

import {
  Scalars,
  Order,
  OrderStateEnum as Order__State,
  OrderServiceTypeEnum as Order__Service,
  OrderSubtypeEnum as Order__Subtype,
  OrderTypeEnum as Order__Type,
  Region,
  Order__Policy,
  usePaginatedIndexOrdersQuery,
  useRegionsSelectorQuery,
} from '@admin/schema';

import {
  ORDER_TYPES,
  ORDER_TYPE_TO_NAME,
  ORDER_TYPE_TO_PARAM,
  ORDER_TYPE_FROM_PARAM,
  OrderTypeParam,
  ORDER_TYPE_TO_STATUS,
} from '@admin/constants/order_type';
import {
  ORDER_SUBTYPES,
  ORDER_SUBTYPE_TO_NAME,
  ORDER_SUBTYPE_TO_PARAM,
  ORDER_SUBTYPE_FROM_PARAM,
  OrderSubtypeParam,
} from '@admin/constants/order_subtype';
import {
  OrderStateParam,
  ORDER_STATES,
  ORDER_STATE_TO_NAME,
  ORDER_STATE_TO_PARAM,
  ORDER_STATE_FROM_PARAM,
} from '@admin/constants/order_state';
import {
  OrderServiceParam,
  ORDER_SERVICES,
  ORDER_SERVICE_TO_NAME,
  ORDER_SERVICE_TO_PARAM,
  ORDER_SERVICE_FROM_PARAM,
  ORDER_SERVICE_TO_STATUS,
} from '@admin/constants/order_services';

import { orderURL, accountURL } from '@admin/config/routes';

import { useQA } from '@admin/hooks/use_qa';
import { useDebounce } from '@shared/hooks';

import { FormattedServiceType } from '@admin/components/orders/formatted_service_type';
import { Pagination } from '@admin/components/pagination';
import { Spinner } from '@admin/components/spinner';
import { MultiselectFormControl } from '@admin/components/fields/multiselect_form_control';
import { Button, Col, Panel, Row, Table, AnchorButton as Link } from '@shared/components/bootstrap';
import { Preview as AddressPreview } from '@admin/components/address/preview';
import { CustomerNames as AccountCustomerNames } from '@admin/components/account/customer_names';
import { Label } from '@admin/components/helpers/label';
import { InputFormGroup } from '@admin/components/fields/input_form_group';
import { SelectFormGroup } from '@admin/components/fields/select_form_group';
import { FieldFormGroup } from '@shared/components/fields/field_form_group';
import { State } from './state';
import { Summary } from './summary';
import { Scheduled } from './scheduled';

const DEFAULT_PAGE = 1;

const ORDER_STATE_OPTIONS = ORDER_STATES.map((state) => ({
  id: state,
  name: ORDER_STATE_TO_NAME[state],
}));

type DispatchedParam = 'no' | 'yes';

const DISPATCHED_FROM_PARAM: Record<DispatchedParam, boolean> = {
  no: false,
  yes: true,
};

const dispatchedToParam = (dispatched?: boolean) => (dispatched === undefined ? undefined : dispatched ? 'yes' : 'no');

const SearchFormGroup: React.FC<{
  query?: string;
  onChange(query?: string): void;
}> = ({ query, onChange }) => (
  <InputFormGroup
    label="Search"
    type="text"
    placeholder="Search by name, email, phone, address, account # or order #"
    value={query ?? ''}
    onChange={(event) => onChange(event.target.value || undefined)}
  />
);

const DateFormGroup: React.FC<{
  date?: string; // e.g. "2022-12-31" (ISO formatted)
  onChange(date?: string): void; // e.g. "2020-12-31" (ISO formatted)
}> = ({ date, onChange }) => (
  <InputFormGroup
    label="Date:"
    type="date"
    value={date ?? ''}
    onChange={(event) => onChange(event.target.value || undefined)}
  />
);

const RegionsFormGroup: React.FC<{
  regionIDs?: string[];
  onChange(regionIDs?: string[]): void;
}> = ({ regionIDs, onChange }) => {
  const { data } = useRegionsSelectorQuery({ client });
  const options = data?.regions;

  return (
    <FieldFormGroup label="Region:">
      <MultiselectFormControl
        placeholder="- Region -"
        selectedIDs={regionIDs ?? []}
        onChange={(selectedIDs) => onChange(selectedIDs.length === 0 ? undefined : selectedIDs)}
        options={options ?? []}
        disabled={!options}
      />
    </FieldFormGroup>
  );
};

const ACTIVE_ORDER_TYPES = ORDER_TYPES.filter((type) => ORDER_TYPE_TO_STATUS[type] === 'active');
const LEGACY_ORDER_TYPES = ORDER_TYPES.filter((type) => ORDER_TYPE_TO_STATUS[type] === 'legacy');

const TypeFormGroup: React.FC<{
  type?: Order__Type;
  onChange(type?: Order__Type): void;
}> = ({ type, onChange }) => (
  <SelectFormGroup
    label="Type:"
    value={type ?? ''}
    onChange={(event) => onChange((event.target.value as Order__Type) || undefined)}
  >
    <option value="">- Type -</option>
    {ACTIVE_ORDER_TYPES.map((value) => (
      <option key={value} value={value}>
        {ORDER_TYPE_TO_NAME[value]}
      </option>
    ))}
    <optgroup label="Legacy">
      {LEGACY_ORDER_TYPES.map((value) => (
        <option key={value} value={value}>
          {ORDER_TYPE_TO_NAME[value]}
        </option>
      ))}
    </optgroup>
  </SelectFormGroup>
);

const SubtypeFormGroup: React.FC<{
  subtype?: Order__Subtype;
  onChange(subtype?: Order__Subtype): void;
}> = ({ subtype, onChange }) => (
  <SelectFormGroup
    label="Subtype:"
    value={subtype ?? ''}
    onChange={(event) => onChange((event.target.value as Order__Subtype) || undefined)}
  >
    <option value="">- Subtype -</option>
    {ORDER_SUBTYPES.map((value) => (
      <option key={value} value={value}>
        {ORDER_SUBTYPE_TO_NAME[value]}
      </option>
    ))}
  </SelectFormGroup>
);

const StatesFormGroup: React.FC<{
  states?: Order__State[];
  onChange(states?: Order__State[]): void;
}> = ({ states, onChange }) => (
  <FieldFormGroup label="State:">
    <MultiselectFormControl
      placeholder="- State -"
      selectedIDs={states ?? []}
      onChange={(changes) => onChange(changes.length === 0 ? undefined : changes)}
      options={ORDER_STATE_OPTIONS}
    />
  </FieldFormGroup>
);

const DispatchedFormGroup: React.FC<{
  dispatched?: boolean;
  onChange(dispatched?: boolean): void;
}> = ({ dispatched, onChange }) => (
  <SelectFormGroup
    label="Dispatched:"
    value={dispatchedToParam(dispatched)}
    onChange={(event) => {
      onChange(DISPATCHED_FROM_PARAM[event.target.value as DispatchedParam]);
    }}
  >
    <option value="">- Dispatched -</option>
    <option value="yes">Yes</option>
    <option value="no">No</option>
  </SelectFormGroup>
);

const ACTIVE_ORDER_SERVICES = ORDER_SERVICES.filter((service) => ORDER_SERVICE_TO_STATUS[service] === 'active');
const LEGACY_ORDER_SERVICES = ORDER_SERVICES.filter((service) => ORDER_SERVICE_TO_STATUS[service] === 'legacy');

const ServiceFormGroup: React.FC<{
  service?: Order__Service;
  onChange(service?: Order__Service): void;
}> = ({ service, onChange }) => (
  <SelectFormGroup
    label="Service:"
    value={service ?? ''}
    onChange={(event) => onChange((event.target.value as Order__Service) || undefined)}
  >
    <option value="">- Service -</option>
    {ACTIVE_ORDER_SERVICES.map((value) => (
      <option key={value} value={value}>
        {ORDER_SERVICE_TO_NAME[value]}
      </option>
    ))}
    <optgroup label="Legacy">
      {LEGACY_ORDER_SERVICES.map((value) => (
        <option key={value} value={value}>
          {ORDER_SERVICE_TO_NAME[value]}
        </option>
      ))}
    </optgroup>
  </SelectFormGroup>
);

const Dispatcher: React.FC<{
  order: Pick<Order, 'id' | 'state' | 'scheduled' | 'dispatched'> & {
    region: Pick<Region, 'id' | 'parentID' | 'tz'>;
    policy: Pick<Order__Policy, 'dispatch'>;
  };
}> = ({ order }) => {
  if (order.state === Order__State.Canceled)
    return (
      <Button disabled kind="default">
        Canceled
      </Button>
    );
  if (order.state === Order__State.Completed)
    return (
      <Button disabled kind="default">
        Completed
      </Button>
    );
  if (order.dispatched)
    return (
      <Button disabled kind="default">
        Dispatched
      </Button>
    );
  if (!order.policy.dispatch) return null;

  const region = order.region;
  const scheduled = DateTime.fromISO(order.scheduled, { zone: region.tz });
  const regionID = region.parentID ?? region.id;
  const date = scheduled.toFormat('yyyy-MM-dd');
  const dispatcherURL = `/dispatcher?date=${date}&region_id=${regionID}`;

  return (
    <Link kind="default" href={dispatcherURL}>
      Dispatch
    </Link>
  );
};

type Filters = {
  page?: number;
  query?: string;
  date?: string;
  type?: OrderTypeParam;
  subtype?: OrderSubtypeParam;
  service?: OrderServiceParam;
  dispatched?: DispatchedParam;
  states?: OrderStateParam[];
  regionIDs?: Array<Scalars['ID']>;
};

export const OrdersTable: React.FC<{
  filters: Filters;
  onFilter(filters: Filters): void;
}> = ({ filters, onFilter }) => {
  const qa = useQA();
  const onFilterRef = useRef(onFilter);

  const [page, setPage] = useState(filters.page ? Number(filters.page) : DEFAULT_PAGE);
  const [query, setQuery] = useState(filters.query);
  const [date, setDate] = useState(filters.date); // e.g. ISO formatted "2022-12-31"
  const [type, setType] = useState(filters.type && ORDER_TYPE_FROM_PARAM[filters.type]);
  const [subtype, setSubtype] = useState(filters.subtype && ORDER_SUBTYPE_FROM_PARAM[filters.subtype]);
  const [service, setService] = useState(filters.service && ORDER_SERVICE_FROM_PARAM[filters.service]);
  const [dispatched, setDispatched] = useState(filters.dispatched && DISPATCHED_FROM_PARAM[filters.dispatched]);
  const [states, setStates] = useState<Order__State[] | undefined>(() =>
    filters.states?.map((entry) => ORDER_STATE_FROM_PARAM[entry]).filter((state) => !!state),
  );
  const [regionIDs, setRegionIDs] = useState(filters.regionIDs);

  useEffect(() => {
    onFilterRef.current = onFilter;
  }, [onFilter]);

  useEffect(() => {
    onFilterRef.current({
      page: page !== DEFAULT_PAGE ? page : undefined,
      query,
      date,
      type: type && ORDER_TYPE_TO_PARAM[type],
      subtype: subtype && ORDER_SUBTYPE_TO_PARAM[subtype],
      service: service && ORDER_SERVICE_TO_PARAM[service],
      states: states?.map((state) => ORDER_STATE_TO_PARAM[state]),
      dispatched: dispatchedToParam(dispatched),
      regionIDs,
    });
  }, [page, query, date, type, subtype, service, dispatched, states, regionIDs]);

  const { data, loading } = usePaginatedIndexOrdersQuery({
    client: client,
    variables: {
      page,
      filters: {
        query: useDebounce(query),
        date,
        type,
        subtype,
        service,
        dispatched,
        states,
        regionIDs,
      },
    },
  });

  const orders = data?.paginatedOrders.results;
  const pagination = data?.paginatedOrders.pagination;

  const withResetDefaultPage =
    <T extends any>(fn: (arg?: T) => void) =>
    (arg?: T) => {
      setPage(DEFAULT_PAGE);
      fn(arg);
    };

  return (
    <>
      <Panel>
        <Panel.Body>
          <form>
            <Row>
              <Col sm={8}>
                <SearchFormGroup query={query} onChange={withResetDefaultPage(setQuery)} />
              </Col>
              <Col sm={4}>
                <DateFormGroup date={date} onChange={withResetDefaultPage(setDate)} />
              </Col>
            </Row>

            <Row>
              <Col sm={2}>
                <RegionsFormGroup regionIDs={regionIDs} onChange={withResetDefaultPage(setRegionIDs)} />
              </Col>
              <Col sm={2}>
                <TypeFormGroup type={type} onChange={withResetDefaultPage(setType)} />
              </Col>
              <Col sm={2}>
                <SubtypeFormGroup subtype={subtype} onChange={withResetDefaultPage(setSubtype)} />
              </Col>
              <Col sm={2}>
                <StatesFormGroup states={states} onChange={withResetDefaultPage(setStates)} />
              </Col>
              <Col sm={2}>
                <DispatchedFormGroup dispatched={dispatched} onChange={withResetDefaultPage(setDispatched)} />
              </Col>
              <Col sm={2}>
                <ServiceFormGroup service={service} onChange={withResetDefaultPage(setService)} />
              </Col>
            </Row>

            {qa && (
              <div className="clearfix">
                <div className="pull-right">
                  <Link kind="primary" href="/test_orders">
                    QA Test Orders
                  </Link>
                </div>
              </div>
            )}
          </form>
        </Panel.Body>
      </Panel>

      <Panel>
        <Panel.Body>
          <Table striped responsive>
            <thead>
              <tr>
                <th className="col-sm-3">Details</th>
                <th className="col-sm-3">Subscription</th>
                <th className="col-sm-3">Customer</th>
                <th className="col-sm-3">Address</th>
                <th className="col-zero"></th>
                <th className="col-zero"></th>
              </tr>
            </thead>

            <tbody>
              {orders?.map((order) => (
                <tr key={order.id}>
                  <td className="col-sm-3">
                    <div>
                      <span>#{order.number}</span>
                      <span> - </span>
                      <Summary order={order} />
                      <span> - </span>
                      <span>
                        <strong>{order.movers}</strong> movers
                      </span>
                    </div>
                    <div>
                      <FormattedServiceType order={order} />
                    </div>
                    <div>
                      <span>Scheduled:</span>{' '}
                      <span>
                        <Scheduled order={order} />
                      </span>
                    </div>
                    <div>
                      <State state={order.state} />
                    </div>
                    {!!order.flaggedAt && (
                      <div>
                        <Label kind="default">
                          Modified on {DateTime.fromISO(order.flaggedAt).toLocaleString(DateTime.DATETIME_MED)}
                        </Label>
                      </div>
                    )}
                  </td>
                  <td className="col-sm-3">
                    {order.account.subscriptions.map((subscription) => (
                      <div key={subscription.id}>
                        {!subscription.custom && <>{subscription.quantity} × </>} {subscription.name}
                      </div>
                    ))}
                  </td>
                  <td className="col-sm-3">
                    <a href={accountURL(order.account)} target="_blank">
                      <AccountCustomerNames account={order.account} />
                    </a>
                  </td>
                  <td className="col-sm-3">
                    <a href={order.address.map} target="_blank">
                      <AddressPreview address={order.address} />
                    </a>
                  </td>
                  <td className="col-zero text-center">
                    <Dispatcher order={order} />
                  </td>
                  <td className="col-zero text-center">
                    <Link kind="primary" href={orderURL(order)}>
                      View
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {loading && <Spinner />}
          {pagination && <Pagination pagination={pagination} onPage={setPage} />}
        </Panel.Body>
      </Panel>
    </>
  );
};

(() => {
  const DEFAULT_PRECISION = 2;
  const DEFAULT_FORMAT = 'miles';

  const MILES_CONVERSION_FACTOR = 0.00062137;

  angular.module('app').filter('distance', function () {
    return (distance, format, precision) => {
      if (!distance) {
        return;
      }
      if (!precision) {
        precision = DEFAULT_PRECISION;
      }
      if (!format) {
        format = DEFAULT_FORMAT;
      }
      switch (format) {
        case 'miles':
          return `${_.round(Number(distance) * MILES_CONVERSION_FACTOR, precision)} miles`;
        default:
          throw new Error(`invalid format ${format}`);
      }
    };
  });
})();

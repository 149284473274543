import { NightTransportPlanQuery, NightTransport__TaskFragment } from '@admin/schema';
import { UUID } from '@shared/utils';
import { groupBy, zip } from 'lodash';

import { NightTransportDrive, NightTransportPlan, NightTransportRoute, NightTransportTask } from './types';

const DISPATCHABLE_ACTIONS = ['NightTransport__Drive', 'NightTransport__Resupply'];

// Return zip of As and Bs with an A paired to the first B that satisfies the predicate.
// A B can only be paired once. If there is no match, the rest of the As will be zipped with
// the rest of the unmatched Bs and vice versa.
function zipBy<A, B>(as: A[], bs: B[], predicate: (a: A, b: B) => boolean) {
  const result = [];
  const unmatched = [];
  const potentialMatches = [...bs];
  let i = 0;

  while (i < as.length && potentialMatches.length > 0) {
    const a = as[i];
    const matchIndex = potentialMatches.findIndex((b) => predicate(a, b));

    if (matchIndex !== -1) {
      result.push([a, potentialMatches[matchIndex]]);
      potentialMatches.splice(matchIndex, 1);
    } else {
      unmatched.push(a);
    }
    i++;
  }

  return [...result, ...zip(unmatched, potentialMatches)];
}

const match = (generated: NightTransportTask, persisted: NightTransportTask) => {
  switch (persisted.action.__typename) {
    case 'NightTransport__Drive':
      return generated.action.vehicle?.id === persisted.action.vehicle?.id;
    default:
      return false;
  }
};

const connect = (generated: NightTransportTask[], persisted: NightTransportTask[]) =>
  zipBy(generated, persisted, match)
    .filter(([a, b]) => !!a && !!b)
    .reduce(
      (acc, [generatedTask, persistedTask]) => ({
        ...acc,
        [persistedTask!.uuid]: generatedTask!.requiredDriveTaskUUID,
      }),
      {},
    );

const addRequiredDriveTask = (
  persisted: NightTransportTask[],
  generated: NightTransportTask[],
): NightTransportTask[] => {
  const persistedByDispatchID = groupBy(
    persisted.filter(
      (task) =>
        !task.action.predecessorUUID && task.action.dispatch && DISPATCHABLE_ACTIONS.includes(task.action.__typename!),
    ),
    (task) => task.action.dispatch!.id,
  );

  const generatedByDispatchID = groupBy(generated, (task) => task.action.dispatch!.id);

  const tasksByUUIDToRequiredDriveTaskUUID: Record<string, string> = Object.keys(generatedByDispatchID).reduce(
    (acc, dispatchID) => ({
      ...acc,
      ...connect(generatedByDispatchID[dispatchID], persistedByDispatchID[dispatchID] || []),
    }),
    {},
  );

  return persisted.map((task) => ({ ...task, requiredDriveTaskUUID: tasksByUUIDToRequiredDriveTaskUUID[task.uuid] }));
};

export const preprocess = (data: NightTransportPlanQuery): NightTransportPlan => {
  const { routes, requiredDriveTasks } = data;

  const processedRoutes = routes.map(
    ({ __typename: _, ...routeProperties }): NightTransportRoute => ({
      ...routeProperties,
      uuid: UUID(),
    }),
  );

  const routesByID = processedRoutes.reduce<Record<string, NightTransportRoute>>((map, route) => {
    map[route.id!] = route;
    return map;
  }, {});

  const drivesByID: Record<string, NightTransportDrive> = {};

  const convertedTasks = routes
    .reduce<NightTransport__TaskFragment[]>((flatTasks, route) => flatTasks.concat(route.tasks), [])
    .map(({ __typename, ...taskProperties }, index) => {
      const { action: actionProperties } = taskProperties;

      const action = { ...actionProperties, uuid: UUID() };
      const task: NightTransportTask = {
        ...taskProperties,
        id: index + 1,
        routeUUID: routesByID[taskProperties.route.id].uuid,
        uuid: UUID(),
        action,
        editing: false,
      };

      if (actionProperties.__typename === 'NightTransport__Resupply') {
        task.action.dispatch = actionProperties.resupplyDispatch;
      }

      if (actionProperties.__typename === 'NightTransport__Drive') {
        task.action.dispatch = actionProperties.driveDispatch || undefined;
        drivesByID[actionProperties.id] = action;
      }

      return task;
    });

  const processedTasks = convertedTasks
    .filter((task) => !!task)
    .map((task): NightTransportTask => {
      const { action } = task!;

      if (action.__typename === 'NightTransport__Carpool' || action.__typename === 'NightTransport__Resupply') {
        return { ...task!, action: { ...action, driveUUID: drivesByID[action.drive!.id]!.uuid } };
      } else if (action.__typename === 'NightTransport__Drive' && action.predecessor) {
        return { ...task!, action: { ...action, predecessorUUID: drivesByID[action.predecessor.id]!.uuid } };
      } else {
        return task!;
      }
    });

  const processedRequiredDriveTasks = requiredDriveTasks.map((drive) => ({ uuid: UUID(), ...drive }));

  const convertedRequiredDriveTasks = processedRequiredDriveTasks.map((drive, index) => ({
    uuid: UUID(),
    id: index + 1,
    position: index + 1,
    requiredDriveTaskUUID: drive.uuid,
    editing: false,
    action: {
      ...drive,
      uuid: UUID(),
      __typename: 'NightTransport__Drive',
      origin: drive.origin || undefined,
      destination: drive.destination || undefined,
    },
  }));

  const persistedTasks = addRequiredDriveTask(processedTasks, convertedRequiredDriveTasks);

  const assignedRequiredDriveTasks = groupBy(persistedTasks, (task) => task.requiredDriveTaskUUID);
  const remainingConvertedRequiredDriveTasks = convertedRequiredDriveTasks
    .filter((task) => !(task.requiredDriveTaskUUID in assignedRequiredDriveTasks))
    .map((task, index) => ({
      ...task,
      id: persistedTasks.length + index + 1,
    }));

  return {
    routes: processedRoutes,
    tasks: persistedTasks.concat(remainingConvertedRequiredDriveTasks),
    requiredDriveTasks: processedRequiredDriveTasks.reduce((acc, elem) => ({ ...acc, [elem.uuid]: elem }), {}),
  };
};

const app = angular.module('app');

app.controller('AddressesBaseController', [
  '$scope',
  'AddressGeocodeService',
  function ($scope, AddressGeocodeService) {
    $scope.validAddress = false;

    $scope.map_geocode = _.debounce((address) => {
      const geocode = AddressGeocodeService.geocode(_.pick(address, ['street', 'zip']));

      geocode.then((data) => {
        $scope.validAddress = true;
        $scope.address.city = data.city;
        $scope.address.state = data.state;
        $scope.address.country = data.country;
      });

      geocode.catch(() => {
        $scope.validAddress = false;
      });
    }, 400);

    $scope.init = function (address) {
      $scope.address = address;
      $scope.map_geocode(address);
    };
  },
]);

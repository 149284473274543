import styled from '@emotion/styled';
import { omit } from 'lodash';
import * as React from 'react';
import { useState } from 'react';

import { Panel } from '@admin/components/helpers/panel';
import { Pagination } from '@admin/components/pagination';
import { Spinner } from '@admin/components/spinner';
import { Button } from '@shared/components/bootstrap';
import { Pluralize, Spacer } from '@shared/components/helpers';
import { useDebounce } from '@shared/hooks';

import {
  usePaginatedSelfStorageUnitsQuery,
  useReservableSelfStorageUnitsCountQuery,
  SelfStorage__Unit__FiltersInput,
  SelfStorage__ClassificationFacilityInput,
} from '@admin/schema';
import { client } from '@admin/libraries/apollo';
import { AddPromotionModal } from './add_promotion_modal';
import { Filters } from './filters';
import { RemovePromotionModal } from './remove_promotion_modal';
import { Table } from './table';
import { UploadPanel } from './upload_panel';

const SpinnerWrapper = styled.div`
  display: flex;
`;

export const SelfStorageUnits: React.FC<{}> = () => {
  const [page, setPage] = useState<number | undefined>(undefined);
  const [filters, setFilters] = useState<SelfStorage__Unit__FiltersInput>({});
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [removeModalVisible, setRemoveModalVisible] = useState(false);
  const [selectedIDs, setSelectedIDs] = useState<{ [key: string]: SelfStorage__ClassificationFacilityInput }>({});
  const [selectedPromotionIDs, setSelectedPromotionIDs] = useState(() => new Set<string>());
  function addIDs(IDs: { [key: string]: SelfStorage__ClassificationFacilityInput & { promotionID?: string } }) {
    setSelectedIDs({
      ...selectedIDs,
      ...IDs,
    });
  }

  function addPromotionIDs(promotionIDs: string[]) {
    setSelectedPromotionIDs(new Set([...Array.from(selectedPromotionIDs), ...promotionIDs]));
  }

  function removeIDs(keys: string[]) {
    setSelectedIDs(omit(selectedIDs, keys));
  }

  function removePromotionIDs(promotionIDs: string[]) {
    const newPromotionIDs = new Set(Array.from(selectedPromotionIDs));
    promotionIDs.forEach((promotionID) => newPromotionIDs.delete(promotionID));
    setSelectedPromotionIDs(newPromotionIDs);
  }

  const { data, loading, refetch } = usePaginatedSelfStorageUnitsQuery({
    client,
    variables: {
      page,
      filters: {
        ...filters,
        search: useDebounce(filters.search),
      },
    },
  });

  const paginated = data && data.paginated;
  const selectedIDsList = Object.values(selectedIDs);
  const reservableSelfStorageUnitsCount = useReservableSelfStorageUnitsCountQuery({
    client,
    variables: { ids: selectedIDsList },
    skip: !selectedIDsList.length,
  })?.data?.count;
  return (
    <div>
      <UploadPanel onUpload={refetch} />
      <Panel>
        <Panel.Header>
          <Panel.Title>Self-Storage Prices & Availability</Panel.Title>
        </Panel.Header>
        <Panel.Body>
          <Filters
            filters={filters}
            onFilter={(value) => {
              setFilters(value);
              setPage(undefined);
            }}
          />
          <Spacer height="8px" />
          {loading && (
            <>
              <SpinnerWrapper>
                <Spinner />
              </SpinnerWrapper>
              <Spacer height="8px" />
            </>
          )}
          <Table
            units={paginated?.results ?? []}
            selectedIDs={selectedIDs}
            addIDs={addIDs}
            addPromotionIDs={addPromotionIDs}
            removeIDs={removeIDs}
            removePromotionIDs={removePromotionIDs}
          />
          {paginated && <Pagination pagination={paginated.pagination} onPage={setPage} />}
        </Panel.Body>
        <Panel.Footer align="right">
          <Button kind="danger" onClick={() => setRemoveModalVisible(true)} disabled={!selectedPromotionIDs.size}>
            Remove Promotion
            {!!selectedPromotionIDs.size && reservableSelfStorageUnitsCount !== undefined && (
              <>
                {' '}
                from{' '}
                <Pluralize count={reservableSelfStorageUnitsCount} singular="Rentable Unit" plural="Rentable Units" />
              </>
            )}
          </Button>
          <Spacer width="8px" />
          <Button kind="primary" onClick={() => setAddModalVisible(true)} disabled={!selectedIDsList.length}>
            Add Promotion
            {reservableSelfStorageUnitsCount !== undefined && (
              <>
                {' '}
                to{' '}
                <Pluralize count={reservableSelfStorageUnitsCount} singular="Rentable Unit" plural="Rentable Units" />
              </>
            )}
          </Button>
        </Panel.Footer>
      </Panel>
      <AddPromotionModal
        count={reservableSelfStorageUnitsCount}
        IDs={selectedIDsList}
        show={addModalVisible}
        onClose={() => setAddModalVisible(false)}
        onSave={() => {
          setAddModalVisible(false);
          setSelectedIDs({});
          setSelectedPromotionIDs(new Set());
        }}
      />
      <RemovePromotionModal
        count={reservableSelfStorageUnitsCount}
        IDs={Array.from(selectedPromotionIDs)}
        show={removeModalVisible}
        onClose={() => setRemoveModalVisible(false)}
        onSave={() => {
          setRemoveModalVisible(false);
          setSelectedIDs({});
          setSelectedPromotionIDs(new Set());
        }}
      />
    </div>
  );
};

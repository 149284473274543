import { Availability__BlockReasonEnum, FacilityCuftLimitFragment } from '@admin/schema';
import React from 'react';
import { Table } from '@shared/components/bootstrap';
import { DateTime } from 'luxon';
import { formatReasonAsString } from '../util';

export const Entries: React.FC<{
  facilityCuftLimits: FacilityCuftLimitFragment[];
  selectedOptions: string[];
  setSelectedOptions: React.Dispatch<React.SetStateAction<string[]>>;
}> = ({ facilityCuftLimits, selectedOptions, setSelectedOptions }) => (
  <Table striped responsive>
    <thead>
      <tr>
        <th className="text-left" />
        <th className="text-left">Date</th>
        <th className="text-left">Warehouse</th>
        <th className="text-left">Reason</th>
        <th className="text-left">Total Max CuFt</th>
        <th className="text-left">Returns Max CuFt</th>
        <th className="text-left">Facility Pickups Max CuFt</th>
        <th className="text-left">Disposals Max CuFt</th>
        <th className="text-left">Pickups Max CuFt</th>
        <th className="text-left">Transfers Max CuFt</th>
        <th className="text-left">Added By</th>
      </tr>
    </thead>
    <tbody>
      {facilityCuftLimits.map((limit) => (
        <tr key={limit.id}>
          <td>
            <input
              data-test-id={`toDelete${limit.id}`}
              checked={selectedOptions.includes(limit.id)}
              disabled={limit.reason === Availability__BlockReasonEnum.WeeklySchedule}
              type="checkbox"
              onChange={(event) => {
                const checked = event.target.checked;
                setSelectedOptions((current) => {
                  if (checked) {
                    return Array.from(new Set([...current, limit.id]));
                  } else {
                    return current.filter((option) => option !== limit.id);
                  }
                });
              }}
            />
          </td>
          <td className="text-left">{DateTime.fromISO(limit.date).toLocaleString()}</td>
          <td className="text-left">{limit.facility.name}</td>
          <td className="text-left">{limit.reason && formatReasonAsString(limit.reason)}</td>
          <td className="text-left">{limit.maximumInboundOutboundCuft}</td>
          <td className="text-left">{limit.maximumOutboundCuft}</td>
          <td className="text-left">{limit.maximumOutboundFacilityCuft}</td>
          <td className="text-left">{limit.maximumOutboundDisposalCuft}</td>
          <td className="text-left">{limit.maximumInboundCuft}</td>
          <td className="text-left">{limit.maximumInboundTransferCuft}</td>
          <td className="text-left">{limit.creator.name}</td>
        </tr>
      ))}
    </tbody>
  </Table>
);

import * as React from 'react';

import { Breadcrumb } from '@shared/components/bootstrap';

import { accountsURL, accountURL } from '@admin/config/routes';

interface IBreadcrumbsProps {
  account: {
    id: string | number;
    number: string;
    customer: {
      name: string;
    };
  };
  active?: string;
}

export const Breadcrumbs: React.FC<IBreadcrumbsProps> = ({ account, active }) => (
  <Breadcrumb>
    <Breadcrumb.Item>
      <a href={accountsURL()} className="text-primary">
        {' '}
        Accounts{' '}
      </a>
    </Breadcrumb.Item>
    <Breadcrumb.Item active={!active}>
      {active ? (
        <a href={accountURL({ id: account.id })}>
          #{account.number} ({account.customer.name})
        </a>
      ) : (
        <>
          #{account.number} ({account.customer.name})
        </>
      )}
    </Breadcrumb.Item>
    {active && <Breadcrumb.Item active>{active}</Breadcrumb.Item>}
  </Breadcrumb>
);

import * as React from 'react';

import { Availability__JobAvailabilityFiltersInput } from '@admin/schema';

import { Row, Col } from '@shared/components/bootstrap';
import { FieldFormGroup, InputFormGroup, MultiselectFormControl } from '@admin/components/fields';
import { Selector as RegionSelector } from '@admin/components/regions/selector';

import { KIND_OPTIONS } from './utils';

export const Filters: React.FC<{
  filters: Availability__JobAvailabilityFiltersInput;
  onChange(filters: Availability__JobAvailabilityFiltersInput): void;
}> = ({ filters, onChange }) => (
  <Row>
    <Col md={3}>
      <InputFormGroup
        id="from_date"
        label="From Date:"
        type="date"
        min="0000-01-01"
        max="9999-12-31"
        value={filters.fromDate || ''}
        onChange={(event) => onChange({ ...filters, fromDate: event.target.value || '' })}
      />
    </Col>
    <Col md={3}>
      <InputFormGroup
        id="till_date"
        label="Till Date:"
        type="date"
        min="0000-01-01"
        max="9999-12-31"
        value={filters.tillDate || ''}
        onChange={(event) => onChange({ ...filters, tillDate: event.target.value || '' })}
      />
    </Col>
    <Col md={3}>
      <FieldFormGroup label="Regions:">
        <RegionSelector selectedIDs={filters.regionIDs} onChange={(regionIDs) => onChange({ ...filters, regionIDs })} />
      </FieldFormGroup>
    </Col>
    <Col md={3}>
      <FieldFormGroup label="Kinds:">
        <MultiselectFormControl
          placeholder="Kinds"
          options={KIND_OPTIONS}
          selectedIDs={filters.kinds}
          onChange={(kinds) =>
            onChange({
              ...filters,
              kinds,
            })
          }
        />
      </FieldFormGroup>
    </Col>
  </Row>
);

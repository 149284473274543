import { Box } from '@clutter/clean';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import { Button } from '@shared/components/bootstrap';
import { Manager, Selector, Uploader } from '@shared/components/files';

type InputProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

export const UploadFileButton: React.FC<
  InputProps & {
    disabled?: boolean;
    file?: File;
    onUpload(file: File, signedID: string): void;
    onRemoveFile(): void;
  }
> = ({ disabled, file, onUpload, onRemoveFile, ...props }) => (
  <Manager onSave={onUpload}>
    {({ uploads }) => (
      <Box.Flex flexDirection="row" alignItems="center">
        {!file && !uploads.length && (
          <label className="btn btn-default">
            <FontAwesomeIcon icon="paperclip" /> Upload Screenshot
            <Selector {...props} disabled={disabled} multiple={false} accept="image/*" />
          </label>
        )}
        {file && (
          <>
            <Box>{file.name}</Box>
            <Box>
              <Button className="text-danger" kind="link" disabled={disabled} onClick={onRemoveFile}>
                <FontAwesomeIcon icon="times" /> Remove
              </Button>
            </Box>
          </>
        )}
        {uploads.map((entry) => (
          <Uploader key={entry.uuid} {...entry} />
        ))}
      </Box.Flex>
    )}
  </Manager>
);

import React, { useState } from 'react';
import { client } from '@admin/libraries/apollo';
import { accountURL } from '@admin/config/routes';
import { Logistics__AccountInput, Status, useBuildLogisticsAccountMutation } from '@admin/schema';
import { AccountForm as LogisticsAccountForm } from './form/form';

const FALLBACK_ERROR = 'Sorry, an unexpected error occurred. If the problem persists, contact Tech Support.';

export const LogisticsNewAccount: React.FC<{}> = () => {
  const [error, setError] = useState<string | null | undefined>(undefined);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [execute] = useBuildLogisticsAccountMutation({ client });

  const setErrorAndReturnToTop = (updatedError: string | null | undefined) => {
    setError(updatedError);
    window.scrollTo(0, 0);
    setSubmitting(false);
  };

  const onSubmit = async (data: Logistics__AccountInput, errors: string | null | undefined) => {
    setSubmitting(true);
    if (errors == null) {
      setError(undefined);
      try {
        const result = await execute({
          variables: {
            input: data,
          },
        });

        if (result.data?.buildLogisticsAccount.status === Status.Unprocessable) {
          setErrorAndReturnToTop(result.data.buildLogisticsAccount.error);
        } else if (result.data && result.data.buildLogisticsAccount.id) {
          setSubmitting(false);
          setError(undefined);
          const id: string = result.data.buildLogisticsAccount.id;
          window.location.href = accountURL({ id });
        }
      } catch (e) {
        setErrorAndReturnToTop(FALLBACK_ERROR);
      }
    } else {
      setErrorAndReturnToTop(errors || 'Please fill out all required fields');
    }
  };

  return <LogisticsAccountForm error={error} onSubmit={onSubmit} submitting={submitting} />;
};

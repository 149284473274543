import {
  SelfStorage__AddressesInput,
  SelfStorage__CustomerInput,
  SelfStorage__RentInput,
  SelfStorage__RentalInput,
  SelfStorage__PolicyInput,
} from '@admin/schema';

import { FormAddress, FormAddresses, FormBilling, FormCustomer, FormData, FormPolicy } from './types';

const buildAddressInput = (type: 'billing' | 'mailing', address?: FormAddress) => {
  if (!address) {
    return undefined;
  } else {
    const { id, ...attributes } = address;
    return {
      [`${type}ID`]: id && id !== 'other' ? id : undefined,
      [type]: !id || id === 'other' ? attributes : undefined,
    };
  }
};

const buildAddressesInput = (addresses: FormAddresses): SelfStorage__AddressesInput => ({
  ...buildAddressInput('billing', addresses.billing),
  ...buildAddressInput('mailing', addresses.mailing),
});

const buildCustomerInput = (customer: FormCustomer): SelfStorage__CustomerInput => ({
  ...customer,
  name: [customer.name.first, customer.name.initials, customer.name.last].filter((value) => !!value).join(' '),
});

const buildPolicyInput = (policy: FormPolicy): SelfStorage__PolicyInput => ({
  ...policy,
  amount: Number(policy.amount),
});

const buildRentalInput = (data: FormData): SelfStorage__RentalInput => ({
  gateCode: data.gateCode,
  identification: data.identification,
  policy: data.policy && buildPolicyInput(data.policy),
});

const buildSourceID = (billing: FormBilling) =>
  billing.method !== 'tokenizer' && billing.method !== 'default' ? billing.method : undefined;

export const buildRentInput = ({
  data,
  token,
  accountID,
  reservationID,
}: {
  data: FormData;
  token?: {
    id: string;
    type: 'card';
  };
  accountID?: string;
  reservationID?: string;
}): SelfStorage__RentInput => ({
  accountID,
  reservationID,
  military: data.military,
  addresses: buildAddressesInput(data.addresses),
  phones: data.phones,
  unitID: data.unitID,
  protectionID: data.protectionID || undefined,
  promotionID: data.promotionID || undefined,
  sourceID: buildSourceID(data.billing),
  feeIDs: data.feeIDs,
  notes: data.notes,
  token: token && {
    id: token.id,
    type: token.type,
  },
  rental: buildRentalInput(data),
  customer: data.customer && buildCustomerInput(data.customer),
  priceOverride: data.priceOverride && {
    rate: Number(data.priceOverride.rate),
    reason: data.priceOverride.reason,
  },
});

import * as React from 'react';

import { SelfStorage__UnitGroupFragment } from '@admin/schema';

import { FilterGroup, FilterOption } from './filter';

export enum Sort {
  Price = 'Price',
  Size = 'Size',
}

const SORTS = [Sort.Price, Sort.Size];

type Comparison = (group: SelfStorage__UnitGroupFragment) => number;
const compare = (a: SelfStorage__UnitGroupFragment, b: SelfStorage__UnitGroupFragment, fn: Comparison) => fn(a) - fn(b);
const size = ({ classification: { length, width, height } }: SelfStorage__UnitGroupFragment) => length * width * height;
const price = (entry: SelfStorage__UnitGroupFragment) => entry.price.rate;

export const filter = (entries: readonly SelfStorage__UnitGroupFragment[], option: Sort) => {
  const fn = (() => {
    switch (option) {
      case Sort.Price:
        return price;
      case Sort.Size:
        return size;
    }
  })();
  return [...entries].sort((a, b) => compare(a, b, fn));
};

export const Sorting: React.FC<{
  selection: Sort;
  onSelect(sort: Sort): void;
}> = ({ selection, onSelect }) => (
  <FilterGroup>
    {SORTS.map((sort, index) => (
      <FilterOption key={index} onSelect={() => onSelect(sort)} selected={selection === sort} children={sort} />
    ))}
  </FilterGroup>
);

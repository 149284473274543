import angular from 'angular';

const app = angular.module('app');

// NOTE: once a contact is persisted it cannot be deleted (only modified).
app.service('ContactNestedAttributesService', [
  function () {
    this.saveable = (contact) => contact && (contact.name || contact.phone);

    this.convert = (contact) => {
      if (this.saveable(contact)) {
        return {
          id: contact.id,
          name: contact.name,
          phone_attributes: { number: contact.phone },
        };
      }

      return null;
    };
  },
]);

import React, { useState } from 'react';

import { newAccountPickupURL, newAccountReturnURL, newAccountTaskOrderURL } from '@admin/config/routes';
import { Account__State, useAccountPageButtonsQuery } from '@admin/schema';
import { AnchorButton, Button, Modal } from '@shared/components/bootstrap';
import { client } from '@admin/libraries/apollo';

export const NewOrderButtons: React.FC<{ accountID: string }> = ({ accountID }) => {
  const [showAlert, setShowAlert] = useState<boolean>(false);

  const { data } = useAccountPageButtonsQuery({ client, variables: { accountID } });
  const account = data?.account;

  const pickupText = account?.onboardable ? 'New Onboarding' : 'New Pickup';
  const accountHasLoggedIn = account && account.signInCount > 0;
  const permissions = account?.permissions;
  const canBookOrders = permissions?.bookOrders;
  const canOverride = permissions?.overrideBookOrders;

  return (
    <>
      <AnchorButton.Group>
        {permissions?.bookWarehouseReturn && account?.state === Account__State.Suspended && (
          <AnchorButton kind="danger" href={newAccountReturnURL({ accountID, warehouseReturn: true })}>
            New Auction
          </AnchorButton>
        )}
        {permissions?.bookMaintenancePickup && (
          <AnchorButton kind="danger" href={newAccountPickupURL({ accountID, external: false })}>
            New Maintenance Pickup
          </AnchorButton>
        )}
        {canBookOrders && (
          <>
            {canOverride && (
              <AnchorButton kind="primary" href={newAccountTaskOrderURL({ accountID })}>
                New Task Order
              </AnchorButton>
            )}
            {accountHasLoggedIn || canOverride ? (
              <AnchorButton kind="primary" href={newAccountReturnURL({ accountID })}>
                New Return
              </AnchorButton>
            ) : (
              <Button kind="primary" onClick={() => setShowAlert(true)}>
                New Return
              </Button>
            )}
            {accountHasLoggedIn || canOverride || account?.onboardable ? (
              <AnchorButton kind="primary" href={newAccountPickupURL({ accountID })}>
                {pickupText}
              </AnchorButton>
            ) : (
              <Button kind="primary" onClick={() => setShowAlert(true)}>
                {pickupText}
              </Button>
            )}
          </>
        )}
      </AnchorButton.Group>
      {showAlert && (
        <Modal onClose={() => setShowAlert(false)}>
          <Modal.Content>
            <Modal.Header>
              <Modal.Title>Unable to Create New Order</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              This customer has not activated their online account and agreed to Clutter’s Terms of Service. This must
              be done before booking any new appointments. Please direct the customer here to set up their online
              account: https://account.clutter.com/activate.
            </Modal.Body>
            <Modal.Footer>
              <Button kind="default" onClick={() => setShowAlert(false)}>
                Close
              </Button>
            </Modal.Footer>
          </Modal.Content>
        </Modal>
      )}
    </>
  );
};

import React, { useState } from 'react';

import { Alert, Button, Modal, FormGroup, ControlLabel } from '@shared/components/bootstrap';
import { useLeadCreateMutation, Lead__ServiceNeeds } from '@admin/schema';
import { client } from '@admin/libraries/apollo';
import { ApolloProvider } from '@apollo/client';

enum Source {
  Yelp = 'affiliate:yelp',
  Thumbtack = 'affiliate:thumbtack',
  Angi = 'affiliate:angi',
  MoversCom = 'affiliate:movers_com',
  MovingCom = 'affiliate:moving_com',
  EquateMedia = 'affiliate:equate_media',
  GoogleLocalServices = 'affiliate:google_local_services',
  Bark = 'affiliate:bark',
  Billy = 'affiliate:billy',
  MoveMatcher = 'affiliate:move_matcher',
  Other = 'affiliate:other',
}

const getServiceNeeds = (smartStorage: boolean, selfStorage: boolean, moving: boolean) => {
  const serviceNeeds = [];
  if (smartStorage) {
    serviceNeeds.push(Lead__ServiceNeeds.SmartStorage);
  }
  if (selfStorage) {
    serviceNeeds.push(Lead__ServiceNeeds.SelfStorage);
  }
  if (moving) {
    serviceNeeds.push(Lead__ServiceNeeds.Moving);
  }
  return serviceNeeds;
};

export const CreateLead: React.FC<{
  onClose(): void;
  onSave(lead: { id: string }): void;
}> = ({ onClose, onSave }) => {
  const [leadCreateMutation, { loading, data }] = useLeadCreateMutation({
    onCompleted: ({ result }) => {
      if (result?.lead) onSave(result.lead);
    },
  });
  const [name, setName] = useState<string | undefined>();
  const [email, setEmail] = useState<string | undefined>();
  const [phone, setPhone] = useState<string | undefined>();
  const [source, setSource] = useState<string | undefined>();
  const [zip, setZip] = useState<string | undefined>();
  const [smartStorage, setSmartStorage] = useState(false);
  const [selfStorage, setSelfStorage] = useState(false);
  const [moving, setMoving] = useState(false);
  const valid = (email || phone) && source;
  const error = data?.result?.error;
  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const serviceNeeds = getServiceNeeds(smartStorage, selfStorage, moving);
    if (!valid) {
      return;
    }
    await leadCreateMutation({
      variables: {
        input: {
          name,
          email,
          phone,
          source,
          zip,
          serviceNeeds,
        },
      },
    });
  };

  return (
    <Modal onClose={onClose}>
      <form onSubmit={onSubmit}>
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>Create a New Lead</Modal.Title>
            <Modal.Close close={onClose} />
          </Modal.Header>
          <Modal.Body>
            {error && <Alert style={'danger'}>{error}</Alert>}
            <FormGroup>
              <ControlLabel htmlFor="name">Name:</ControlLabel>
              <input
                id="name"
                className="form-control"
                placeholder="Name"
                value={name || ''}
                onChange={(event) => setName(event.target.value || undefined)}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel htmlFor="email">Email:</ControlLabel>
              <input
                id="email"
                className="form-control"
                placeholder="Email"
                value={email || ''}
                onChange={(event) => setEmail(event.target.value || undefined)}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel htmlFor="phone">Phone Number:</ControlLabel>
              <input
                id="phone-number"
                className="form-control"
                placeholder="Phone Number"
                value={phone || ''}
                onChange={(event) => setPhone(event.target.value || undefined)}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel htmlFor="source">Lead Source:</ControlLabel>
              <select
                id="source"
                className="form-control"
                value={source || ''}
                onChange={(event) => setSource((event.target.value || undefined) as Source)}
              >
                <option value=""> - Lead Source -</option>
                <option value={Source.Yelp}>Yelp</option>
                <option value={Source.Thumbtack}>Thumbtack</option>
                <option value={Source.Angi}>Angi</option>
                <option value={Source.MoversCom}>Movers.com</option>
                <option value={Source.MovingCom}>Moving.com</option>
                <option value={Source.EquateMedia}>Equate Media</option>
                <option value={Source.GoogleLocalServices}>Google Local Services</option>
                <option value={Source.Bark}>Bark</option>
                <option value={Source.Billy}>Billy</option>
                <option value={Source.MoveMatcher}>Move Matcher</option>
                <option value={Source.Other}>Other</option>
              </select>
            </FormGroup>
            <FormGroup>
              <ControlLabel htmlFor="zip">Zipcode:</ControlLabel>
              <input
                id="zip"
                className="form-control"
                placeholder="Zipcode"
                value={zip || ''}
                onChange={(event) => setZip(event.target.value.trim() || undefined)}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel htmlFor="serviceNeeds">Service Needs:</ControlLabel>
              <div className="checkbox">
                <label>
                  <input type="checkbox" checked={smartStorage} onChange={() => setSmartStorage(!smartStorage)} />
                  Smart Storage
                </label>
              </div>
              <div className="checkbox">
                <label>
                  <input type="checkbox" checked={selfStorage} onChange={() => setSelfStorage(!selfStorage)} />
                  Self Storage
                </label>
              </div>
              <div className="checkbox">
                <label>
                  <input type="checkbox" checked={moving} onChange={() => setMoving(!moving)} />
                  Moving
                </label>
              </div>
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <Button kind={'primary'} type={'submit'} disabled={!valid} loading={loading}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal.Content>
      </form>
    </Modal>
  );
};

export const CreateLeadButton: React.FC<{
  onSave(lead: { id: string }): void;
}> = ({ onSave }) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  return (
    <ApolloProvider client={client}>
      <Button kind="default" onClick={() => setShowModal(true)}>
        Create Lead
      </Button>
      {showModal && <CreateLead onClose={() => setShowModal(false)} onSave={onSave} />}
    </ApolloProvider>
  );
};

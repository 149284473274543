(function () {
  angular.module('app').filter('map', function () {
    return function (address) {
      const components = [address.street, address.aptsuite, address.city, address.state, address.county, address.zip];
      const term = components.join();
      const url = 'https://maps.google.com/?q=' + encodeURIComponent(term);
      return url;
    };
  });
})();

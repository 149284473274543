import {
  NightTransportAction,
  NightTransportCarpool,
  NightTransportDrive,
  NightTransportRequiredDrive,
  NightTransportRoute,
  NightTransportTask,
} from '@admin/components/night_transport/planner/types';
import { FacilityInput__Kind } from '@admin/schema';

const validateDrive = (drive: NightTransportDrive) => {
  const { origin, destination } = drive;
  if (!destination || !origin) {
    return `Invalid ${!origin ? 'origin' : ''}${!origin && !destination ? ' and ' : ''}${
      !destination ? 'destination' : ''
    }`;
  }
};

const validateResupply = (resupply: NightTransportAction) => {
  const { driveUUID, dispatch } = resupply;
  if (!driveUUID || !dispatch) {
    return `${!driveUUID ? 'Invalid route task' : ''}${!driveUUID && !dispatch ? ' and ' : ''}${
      !dispatch ? 'This task cannot be a resupply' : ''
    }`;
  }
};

const validateCarpool = (carpool: NightTransportCarpool) => {
  const { driveUUID } = carpool;
  if (!driveUUID) {
    return 'Invalid route task';
  }
};

export const validateTask = (task: NightTransportTask) => {
  const { action } = task;
  if (action.__typename === 'NightTransport__Drive') {
    return validateDrive(action);
  } else if (action.__typename === 'NightTransport__Resupply') {
    return validateResupply(action);
  } else {
    return validateCarpool(action);
  }
};

const getRequiredTaskVehicle = (
  requiredDriveTasks: Record<string, NightTransportRequiredDrive>,
  requiredDriveTaskUUID?: string,
) => {
  const vehicleSuggestion = requiredDriveTaskUUID && requiredDriveTasks[requiredDriveTaskUUID]?.vehicleSuggestion;
  return vehicleSuggestion && vehicleSuggestion.__typename === 'Vehicle' ? vehicleSuggestion.id : null;
};

const convertAction = (
  requiredDriveTasks: Record<string, NightTransportRequiredDrive>,
  action: NightTransportAction,
  requiredDriveTaskUUID?: string,
) => {
  if (action.__typename === 'NightTransport__Drive') {
    return {
      drive: {
        uuid: action.uuid,
        dispatchID: action.dispatch?.id,
        predecessorUUID: action.predecessorUUID,
        vehicleID: getRequiredTaskVehicle(requiredDriveTasks, requiredDriveTaskUUID) || action.vehicle?.id,
        origin: { id: action.origin!.id, type: FacilityInput__Kind[action.origin!.__typename] },
        destination: { id: action.destination!.id, type: FacilityInput__Kind[action.destination!.__typename] },
      },
    };
  } else if (action.__typename === 'NightTransport__Carpool') {
    return {
      carpool: {
        driveUUID: action.driveUUID!,
      },
    };
  } else {
    return {
      resupply: {
        driveUUID: action.driveUUID!,
        dispatchID: action.dispatch!.id,
        vehicleID: getRequiredTaskVehicle(requiredDriveTasks, requiredDriveTaskUUID),
      },
    };
  }
};

export const createRoutesInput = (
  routes: NightTransportRoute[],
  tasks: NightTransportTask[],
  requiredDriveTasks: Record<string, NightTransportRequiredDrive>,
) =>
  routes.map((route) => ({
    driverID: route.driver?.id,
    arrival: route.arrival!,
    origin: {
      id: route.origin!.id,
      type: FacilityInput__Kind[route.origin!.__typename],
    },
    tasks: tasks
      .filter((task) => task.routeUUID === route.uuid)
      .sort(({ position: positionA }, { position: positionB }) => positionA - positionB)
      .map((task, index) => {
        const action = convertAction(requiredDriveTasks, task.action, task.requiredDriveTaskUUID);
        return {
          uuid: task.uuid,
          position: index + 1,
          action: action,
        };
      }),
  }));

import * as React from 'react';

import { ControlLabel, FormGroup } from '@shared/components/bootstrap';

import {
  SelfStorage__RentalFiltersInput as SelfStorage__Rental__FiltersInput,
  SelfStorage__Status as SelfStorage__Rental__Status,
} from '@admin/schema';

import { Selector as SelfStorageFacilitySelector } from '@admin/components/self_storage/facilities/selector';

export const Filters: React.FC<{
  filters: SelfStorage__Rental__FiltersInput;
  onChange(filters: SelfStorage__Rental__FiltersInput): void;
}> = ({ filters, onChange }) => (
  <div className="row">
    <div className="col-md-6">
      <FormGroup>
        <ControlLabel>Search:</ControlLabel>
        <input
          className="form-control"
          type="search"
          placeholder="Search"
          value={filters.query || ''}
          onChange={(event) => onChange({ ...filters, query: event.target.value || undefined })}
        />
      </FormGroup>
    </div>
    <div className="col-md-3">
      <FormGroup>
        <ControlLabel>Facility:</ControlLabel>
        <SelfStorageFacilitySelector
          selectedIDs={filters.facilityIDs || []}
          onChange={(facilityIDs) => onChange({ ...filters, facilityIDs })}
        />
      </FormGroup>
    </div>
    <div className="col-md-3">
      <FormGroup>
        <ControlLabel>Status:</ControlLabel>
        <select
          className="form-control"
          value={filters.status || undefined}
          onChange={(event) =>
            onChange({ ...filters, status: (event.target.value || undefined) as SelfStorage__Rental__Status })
          }
        >
          <option value=""> - Status - </option>
          <option value={SelfStorage__Rental__Status.Active}>Active</option>
          <option value={SelfStorage__Rental__Status.Canceled}>Canceled</option>
          <option value={SelfStorage__Rental__Status.Pending}>Pending</option>
        </select>
      </FormGroup>
    </div>
  </div>
);

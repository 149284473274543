import React, { useState } from 'react';
import { DateTime } from 'luxon';
import { Calendar } from '@clutter/clean';
import styled from '@emotion/styled';
import { Button } from '@shared/components/bootstrap';

const Container = styled.div`
  margin: 10px 0;
`;

const ViewMoreButton = styled(Button)`
  margin-top: 5px;
`;

export const MultiselectCalendar: React.FC<{ selected: DateTime[]; setSelected: (value: DateTime[]) => void }> = ({
  selected,
  setSelected,
}) => {
  const [tillDate, setTillDate] = useState<DateTime>(DateTime.local().endOf('month'));
  const from = DateTime.local().startOf('month');

  const onSelect = (value: DateTime) => {
    const foundIndex = selected.findIndex((s) => value.hasSame(s, 'day'));
    if (foundIndex !== -1) {
      const removed = [...selected];
      removed.splice(foundIndex, 1);
      setSelected(removed);
    } else {
      setSelected([...selected, value]);
    }
  };

  return (
    <Container>
      <Calendar.WeekdayLabels />
      <Calendar.Days
        selected={(date) => selected.findIndex((s) => date.hasSame(s, 'day')) !== -1}
        onSelect={onSelect}
        from={from}
        till={tillDate}
        showMonthTitles
      />
      <ViewMoreButton kind="primary" onClick={() => setTillDate(tillDate.plus({ months: 1 }))}>
        View More
      </ViewMoreButton>
    </Container>
  );
};

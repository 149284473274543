const app = angular.module('app');

app.component('itemsGrid', {
  templateUrl: 'partials/items/grid.html',
  bindings: {
    items: '=',
    selections: '=?',
    sizeable: '<?',
    classes: '@',
  },
  controller: [
    function () {
      this.onSelect = (selections) => {
        this.selections = selections;
      };

      this.$onInit = () => {
        this.classes = this.classes || 'col-xs-6 col-sm-4 col-md-3 col-lg-2';
      };
    },
  ],
});

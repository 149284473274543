import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';

import { faSync } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { client } from '@admin/libraries/apollo';

import { CreateModal } from '@admin/components/customer_tickets/create_modal';
import { Table } from '@admin/components/customer_tickets/queue/table';
import { Panel } from '@admin/components/helpers/panel';
import { Pagination } from '@admin/components/pagination';
import { Spinner } from '@admin/components/spinner';
import {
  CustomerTicketSortInput,
  PaginatedCustomerTicketListQuery,
  usePaginatedCustomerTicketListQuery,
} from '@admin/schema';
import { Button } from '@shared/components/bootstrap';
import { Retry, Timestamp } from '@shared/components/helpers';

const NUM_TICKETS_PER_PAGE = 5;

export const CustomerTicketPanel: React.FC<{
  customerTicketableID: string;
  customerTicketableType: string;
}> = ({ customerTicketableID, customerTicketableType }) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [lastRefreshed, setLastRefreshed] = useState<DateTime>(DateTime.local());
  const [tickets, setTickets] = useState<PaginatedCustomerTicketListQuery>();
  const [sort, setSort] = useState<CustomerTicketSortInput>();

  const queryVariables = {
    filters: {
      customerTicketableID,
      customerTicketableType,
    },
    sort,
    numPer: NUM_TICKETS_PER_PAGE,
  };
  const { data, error, refetch } = usePaginatedCustomerTicketListQuery({
    client,
    variables: queryVariables,
  });
  useEffect(() => setTickets(data), [data]);

  const refresh = async () => {
    await refetch({
      filters: { customerTicketableID, customerTicketableType },
      page: 1,
      sort,
      numPer: NUM_TICKETS_PER_PAGE,
    });
    setSort(undefined);
    setLastRefreshed(DateTime.local());
  };

  if (error) {
    return <Retry error={error} refetch={refetch} />;
  }

  if (!tickets) {
    return <Spinner />;
  }

  const { paginatedCustomerTicketList } = tickets;

  return (
    <>
      <Panel>
        <Panel.Header>
          <Panel.Title>Customer Tickets</Panel.Title>
        </Panel.Header>
        <Panel.Body>
          <div className="text-right">
            <p style={{ fontStyle: 'italic' }}>
              Last refreshed: <Timestamp value={lastRefreshed.toISO()} format={Timestamp.Format.DateTime} />
            </p>
            <Button kind="default" onClick={refresh}>
              Refresh <FontAwesomeIcon icon={faSync} />
            </Button>
          </div>
          <Table customerTickets={paginatedCustomerTicketList.results} sort={sort} setSort={setSort} />
          <Pagination
            pagination={paginatedCustomerTicketList.pagination}
            onPage={(page) =>
              refetch({
                filters: { customerTicketableID, customerTicketableType },
                page,
                sort,
                numPer: NUM_TICKETS_PER_PAGE,
              })
            }
          />
        </Panel.Body>
        <Panel.Footer align="right">
          <Button kind="primary" onClick={() => setShowModal(true)}>
            Create Customer Ticket
          </Button>
        </Panel.Footer>
      </Panel>
      {showModal && (
        <CreateModal
          customerTicketableID={customerTicketableID}
          customerTicketableType={customerTicketableType}
          onClose={() => setShowModal(false)}
        />
      )}
    </>
  );
};

import * as React from 'react';
import styled from '@emotion/styled';

import { TooltipPlacement } from '@shared/components/bootstrap/tooltip';

import { GanttEvent } from './types';
import { Event } from './event';

const Container = styled.div`
  z-index: 1;
  position: relative;
  padding-bottom: 30px;
`;

export const EventStream: React.FC<{
  events: GanttEvent[];
  placement?: TooltipPlacement;
}> = ({ events, placement }) => (
  <Container>
    {events.map((event, index) => (
      <Event key={`${index}-${event.name}-${event.startTimestamp}`} event={event} placement={placement} />
    ))}
  </Container>
);

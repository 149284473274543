import cn from 'classnames';
import { DateTime } from 'luxon';
import * as React from 'react';

import { Summary as PromotionSummary } from '@admin/components/self_storage/promotions/summary';

import { AnchorButton as Link } from '@shared/components/bootstrap';

import { SelfStorage__Reservation__State, SelfStorage__ReservationFragment } from '@admin/schema';

const date = (value: string) => DateTime.fromISO(value).toLocaleString(DateTime.DATE_FULL);

export const StateLabel: React.FC<{ state: SelfStorage__ReservationFragment['state'] }> = ({ state }) => (
  <span
    className={cn(
      'label text-lowercase',
      state === SelfStorage__Reservation__State.Pending && 'label-info',
      state === SelfStorage__Reservation__State.Canceled && 'label-default',
      state === SelfStorage__Reservation__State.Lost && 'label-danger',
      state === SelfStorage__Reservation__State.Won && 'label-success',
    )}
  >
    {state}
  </span>
);

export const Table: React.FC<{
  entries: SelfStorage__ReservationFragment[];
}> = ({ entries }) => (
  <div className="table-responsive">
    <table className="table table-striped">
      <thead>
        <tr>
          <th className="text-left">Booked Date</th>
          <th className="text-left">Expected Move-In</th>
          <th className="text-left">Name</th>
          <th className="text-left">Partner</th>
          <th className="text-left">Facility</th>
          <th className="text-left">Reserved Unit</th>
          <th className="text-left">Agent</th>
          <th className="text-left">Status</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {entries.map((entry) => (
          <tr key={entry.id}>
            <td className="text-left">{date(entry.createdAt || entry.updatedAt)}</td>
            <td className="text-left">{entry.date && date(entry.date)}</td>
            <td className="text-left">{entry.name}</td>
            <td className="text-left">{entry.facility.partner?.name}</td>
            <td className="text-left">{entry.facility.name}</td>
            <td className="text-left">
              {entry.width && entry.length && (
                <div>
                  {entry.width}′ × {entry.length}′
                </div>
              )}
              {entry.unitType && entry.quotedPrice && (
                <div>
                  {entry.unitType} at ${entry.quotedPrice}/mo
                </div>
              )}
              {entry.promotion && (
                <div>
                  <PromotionSummary promotion={entry.promotion} />
                </div>
              )}
            </td>
            <td className="text-left">{entry.user.name}</td>
            <td className="text-left">
              <StateLabel state={entry.state} />
            </td>
            <td className="text-right">
              <Link kind="default" href={`/self_storage/reservations/${entry.id}`}>
                View
              </Link>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

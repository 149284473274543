import template from '@admin/angular/templates/images_panel.html';

import angular from 'angular';

const app = angular.module('app');

app.component('imagesPanel', {
  template,
  bindings: {
    images: '<',
    imgsPerRow: '<',
    margin: '<',
  },
  controller() {
    this.$onInit = () => {
      this.margin = this.margin || 1;
      this.imgsPerRow = this.imgsPerRow || 4;

      this.styles = {
        margin: `${this.margin}%`,
        width: `${(100 - this.imgsPerRow * 2 * this.margin) / this.imgsPerRow}%`,
      };
    };
  },
});

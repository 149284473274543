import styled from '@emotion/styled';
import { intersection } from 'lodash';
import React, { useState } from 'react';

import { Roles } from '@admin/components/helpers/roles';
import { NotesPanel } from '@admin/components/notes/panel';
import { ROLES } from '@admin/config/roles';
import {
  AuditResultsDocument,
  AuditResultsQuery,
  CreateAuditExceptionPayload,
  EditAuditExceptionPayload,
  NoteNotableEnum,
  OpsAudit__ExceptionStatus,
  useCreateAuditExceptionMutation,
  useEditAuditExceptionMutation,
} from '@admin/schema';
import { UserRole } from '@admin/types';
import { COLORS } from '@root/colors';
import { Button, Modal } from '@shared/components/bootstrap';
import { client } from '@admin/libraries/apollo';

import { AnswerListItem } from '../table/row';

type AuditType = AuditResultsQuery['audits'][number];

const ModalHeader = styled.div`
  text-align: center;
`;

const Answers = styled.div`
  background-color: #f0f2f4;
  padding: 24px 24px 24px 0;
`;

const Form = styled.div`
  margin-left: 40px;
  flex-grow: 1;
`;

const ModalBody = styled.div`
  display: flex;
  margin: 40px;
`;

const Error = styled.div`
  color: ${COLORS.red};
`;

const TextArea = styled.textarea`
  padding: 6px;
  width: 100%;
`;

export const AuditExceptionModal: React.FC<{
  audit: AuditType;
  show: boolean;
  onClose(): void;
}> = ({ audit, show, onClose }) => {
  const { answers, exception } = audit;

  const [reason, setReason] = useState<string | undefined>(exception?.reason);
  const [initialNote, setInitialNote] = useState<string | undefined>();
  const [error, setError] = useState<string | null | undefined>();

  const close = () => {
    setError(undefined);
    onClose();
  };

  const handleResponse = ({ status, error: err }: CreateAuditExceptionPayload | EditAuditExceptionPayload) => {
    if (status === 'OK') {
      close();
    } else {
      setError(err);
    }
  };

  const [executeCreateAudit, { loading: createLoading }] = useCreateAuditExceptionMutation({
    client,
    refetchQueries: [
      { query: AuditResultsDocument, variables: { resourceID: audit.resourceID, resourceType: audit.resourceType } },
    ],
    onCompleted: (data) => handleResponse(data.createAuditException),
  });
  const [executeEditAudit, { loading: editLoading }] = useEditAuditExceptionMutation({
    client,
    refetchQueries: [
      { query: AuditResultsDocument, variables: { resourceID: audit.resourceID, resourceType: audit.resourceType } },
    ],
    onCompleted: (data) => handleResponse(data.editAuditException),
  });

  const createAuditException = () => {
    if (reason) {
      executeCreateAudit({
        variables: {
          input: { auditID: audit.id, reason, initialNote },
        },
      });
    }
  };

  const editAuditException = (status?: string) => {
    if (reason) {
      executeEditAudit({
        variables: {
          input: { auditID: audit.id, reason, status },
        },
      });
    }
  };

  let submit;
  if (!exception) {
    submit = (
      <Button kind="primary" loading={createLoading} disabled={!reason} onClick={createAuditException}>
        Create Exception
      </Button>
    );
  } else {
    const editRoles =
      exception.status === OpsAudit__ExceptionStatus.Pending ? [UserRole.Admin, UserRole.Manager] : [UserRole.Admin];
    submit = (
      <>
        <Roles show={editRoles}>
          <Button
            kind="primary"
            loading={editLoading}
            disabled={!reason || reason === exception?.reason || intersection(ROLES, editRoles).length === 0}
            onClick={() => editAuditException()}
          >
            Confirm Edit
          </Button>
        </Roles>
        <Roles show={[UserRole.Admin, UserRole.AUDIT_REVIEWER]}>
          <Button
            kind="primary"
            loading={editLoading}
            disabled={!reason}
            onClick={() => editAuditException('Approved')}
          >
            Approve
          </Button>
          <Button kind="danger" loading={editLoading} disabled={!reason} onClick={() => editAuditException('Denied')}>
            Deny
          </Button>
        </Roles>
      </>
    );
  }

  return (
    <>
      {show && (
        <Modal size={Modal.Size.Large} onClose={close} centered>
          <Modal.Content>
            <ModalHeader className="modal-header">
              <Modal.Title tag="h6">{exception?.status || 'Create'} Audit Exception</Modal.Title>
              <Modal.Close close={close} />
            </ModalHeader>
            <ModalBody>
              <Answers>
                <ol className="text-left">
                  {answers.map((answer) => (
                    <AnswerListItem key={answer.question.id} failed={answer.failed}>
                      {answer.question.prompt + ': ' + answer.response}
                    </AnswerListItem>
                  ))}
                </ol>
              </Answers>
              <Form>
                <div className="form-group">
                  <label className="control-label">Exception Reason</label>
                  <select onChange={(event) => setReason(event.target.value)} value={reason} className="form-control">
                    <option hidden disabled selected>
                      None
                    </option>
                    {audit.configuration.exceptionReasonCodes.map((code) => (
                      <option key={code} value={code}>
                        {code}
                      </option>
                    ))}
                  </select>
                </div>
                {exception ? (
                  <NotesPanel type={NoteNotableEnum.OpsAuditException} id={exception.id} />
                ) : (
                  <>
                    <div>Additional Note (Optional)</div>
                    <TextArea value={initialNote} onChange={(event) => setInitialNote(event.target.value)} />
                  </>
                )}
              </Form>
            </ModalBody>
            <Modal.Footer>
              {submit}
              {error && <Error>{error}</Error>}
            </Modal.Footer>
          </Modal.Content>
        </Modal>
      )}
    </>
  );
};

import * as React from 'react';

import { IItem } from '@admin/types';
import { IItemGroup } from '@admin/types';

import { Hero } from '@admin/components/items/hero';

import { Formatter as CategoryFormatter } from '@admin/components/categories/formatter';
import { Metadata as ItemMetadata } from '@admin/components/items/metadata';

import { MaterialCategoryPreview as ItemMaterialCategoryPreview } from '@admin/components/items/material_category_preview';
import { PartCategoryPreview as ItemPartCategoryPreview } from '@admin/components/items/part_category_preview';

export const Table: React.FC<{
  items: IItem[];
  groups: IItemGroup[];
}> = ({ items, groups }) => (
  <table className="table">
    <tbody>
      {items.map((item) => {
        const group = groups.find(({ id }) => id === item.group_id);
        return (
          <tr key={item.id}>
            <td className="col-nowrap col-zero text-left">
              <Hero selection={item} width={40} height={40} />
            </td>
            <td className="col-fill col-nowrap text-left">
              {group && (
                <span>
                  <i className="fa fa-link" /> <span>to</span>{' '}
                  <span>
                    <CategoryFormatter resource={group} />
                  </span>{' '}
                  <span>#{group.id}</span>{' '}
                  {item.packaging && (
                    <span>
                      <span>as</span> <ItemMaterialCategoryPreview item={item} />{' '}
                      <ItemPartCategoryPreview item={item} />
                    </span>
                  )}
                </span>
              )}
            </td>
            <td className="col-nowrap col-zero text-right">
              <ItemMetadata item={item} />
            </td>
          </tr>
        );
      })}
    </tbody>
  </table>
);

(function () {
  const app = angular.module('app');

  function buildConstraintsForFilters(filters) {
    const now = new Date();
    const till = filters.till;
    const from = filters.from;

    return {
      from: {
        min: new Date(till ? moment(till).subtract(5, 'days') : moment(now).subtract(2, 'months')),
        max: new Date(till ? moment(till) : moment(now).add(2, 'months')),
      },
      till: {
        min: new Date(from ? moment(from) : moment(now).subtract(2, 'months')),
        max: new Date(from ? moment(from).add(5, 'days') : moment(now).add(2, 'months')),
      },
    };
  }

  function setupCustomersOnTransferables(customers, transferables) {
    _.each(transferables, function (transferable) {
      _.each(transferable.pallets, function (pallet) {
        pallet.customers = _.filter(customers, function (customer) {
          return _.some(pallet.account_ids, function (id) {
            return id === customer.account_id;
          });
        });
      });
    });
  }

  function accountIDsForTransferables(transferables) {
    let accountIDs = [];
    _.each(transferables, function (transferable) {
      _.each(transferable.pallets, function (pallet) {
        accountIDs = accountIDs.concat(pallet.account_ids);
      });
    });
    return _.uniq(accountIDs);
  }

  app.controller('TransferablesIndexController', [
    '$scope',
    '$location',
    'ngDialog',
    'Transferable',
    'Region',
    'Warehouse',
    'Customer',
    'FiltersService',
    'DebounceService',
    function ($scope, $location, ngDialog, Transferable, Region, Warehouse, Customer, FiltersService, DebounceService) {
      $scope.filters = FiltersService.parse($location.search(), {
        from: 'date',
        till: 'date',
        region_id: 'number',
        warehouse_id: 'number',
      });

      if (!($scope.filters.from || $scope.filters.till)) {
        const now = new Date();
        $scope.filters.from = new Date(moment(now).add(0, 'days'));
        $scope.filters.till = new Date(moment(now).add(3, 'days'));
      }

      const debounce = DebounceService.initialize();

      $scope.regions = Region.query();
      $scope.warehouses = Warehouse.query();

      $scope.filter = function () {
        const filters = FiltersService.cleanup($scope.filters);
        $location.search(filters);

        $scope.constraints = buildConstraintsForFilters($scope.filters);

        $scope.transferables = null;
        debounce.execute(Transferable.query(filters)).then(function (transferables) {
          $scope.selections = [];
          $scope.transferables = transferables;

          const accountIDs = accountIDsForTransferables(transferables);
          Customer.query({ 'account_ids[]': accountIDs }).$promise.then(function (customers) {
            setupCustomersOnTransferables(customers, transferables);
          });
        });
      };

      $scope.schedulable = function (transferable) {
        return _.intersection($scope.selections, transferable.pallets);
      };

      $scope.schedule = function (transferable) {
        ngDialog
          .openConfirm({
            template: 'partials/transferables/modal.html',
            controller: 'TransferabesFormController',
            resolve: {
              transferable: function () {
                return transferable;
              },
              pallets: function () {
                return $scope.schedulable(transferable);
              },
              regions: function () {
                return $scope.regions;
              },
              warehouses: function () {
                return $scope.warehouses;
              },
            },
          })
          .then($scope.filter);
      };

      $scope.filter();
    },
  ]);

  app.controller('TransferabesFormController', [
    '$scope',
    '$http',
    'regions',
    'warehouses',
    'transferable',
    'pallets',
    'ErrorService',
    function ($scope, $http, regions, warehouses, transferable, pallets, ErrorService) {
      $scope.transferable = transferable;
      $scope.regions = regions;
      $scope.warehouses = warehouses;
      $scope.pallets = pallets;
      $scope.form = {
        pallet_ids: _.map(pallets, function (pallet) {
          return pallet.id;
        }),
        origin_id: transferable.warehouse.id,
      };

      $scope.origin = function (warehouse) {
        return warehouse.id === $scope.transferable.warehouse.id;
      };

      $scope.destination = function (warehouse) {
        return warehouse.id !== $scope.transferable.warehouse.id;
      };

      $scope.cancel = function () {
        $scope.closeThisDialog();
      };

      $scope.save = function () {
        if ($scope.saving) {
          return;
        }
        $scope.saving = $scope.form;
        $http({ method: 'POST', url: '/bulk/transferrequest.json', data: $scope.form }).then(
          $scope.confirm,
          function (error) {
            delete $scope.saving;
            ErrorService.handle(error);
          },
        );
      };
    },
  ]);
})();

import template from '@admin/angular/templates/materials_selector.html';

(() => {
  const app = angular.module('app');

  const ATTRIBUTES = ['id', 'quantity', 'selected'];

  const DIMENSIONS = ['length', 'width', 'height'];

  app.component('materialsSelector', {
    template,
    bindings: {
      resource: '<',
      calculatedCubicFootage: '=?',
    },
    controller: [
      '$q',
      '$scope',
      'MaterialsService',
      'ConversionService',
      function ($q, $scope, MaterialsService, ConversionService) {
        const categories = MaterialsService.categories;

        const findMaterialSelectionByMaterialCategoryID = (id) =>
          _.find(this.resource.material_selections, (object) => object.material_category_id === id);

        const calculateCubicFootage = () =>
          _.sum(
            _.map(this.options, (option) => {
              const quantity = option.quantity || 0;
              const dimensions = _.map(DIMENSIONS, (dimension) => Number(option.material_category[dimension] || 0));
              return ConversionService.cubicInchesToFeet(quantity * _.reduce(dimensions, _.multiply));
            }),
          );

        const reset = () => {
          this.options = _.map(categories, (category) =>
            _.merge({ material_category: category }, findMaterialSelectionByMaterialCategoryID(category.id)),
          );
          this.calculatedCubicFootage = calculateCubicFootage();
        };

        this.save = () => {
          this.resource.material_selections = _.map(this.options, (option) =>
            _.merge(_.pick(option, ATTRIBUTES), { material_category_id: option.material_category.id }),
          );
          this.calculatedCubicFootage = calculateCubicFootage();
        };

        this.$onInit = () => {
          $q.all([this.resource.$promise, categories.$promise]).then(() => {
            reset();
            $scope.$watch('$ctrl.resource.material_selections', reset);
          });
        };
      },
    ],
  });
})();

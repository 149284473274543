(function () {
  const app = angular.module('app');

  app.service('RoomCategoryService', [
    'RoomCategory',
    function (RoomCategory) {
      this.categories = RoomCategory.query();
    },
  ]);
})();

import template from '@admin/angular/templates/warehouse_claimers.html';

import angular from 'angular';

const app = angular.module('app');

app.component('warehouseClaimers', {
  template,
  bindings: {
    dispatchId: '<',
  },
  controller: [
    'WarehouseClaimer',
    'ConfirmationService',
    '$window',
    function (WarehouseClaimer, ConfirmationService, $window) {
      this.$onInit = () => {
        WarehouseClaimer.get({ dispatch_id: this.dispatchId }).$promise.then((data) => {
          this.claimers = data;
        });
      };
      this.cancel = function (taskId) {
        ConfirmationService.confirm({
          title: 'Are you sure you want to cancel this inbound dispatch task?',
          description: '',
          acceptButton: 'Yes, Cancel This Task',
          declineButton: 'No, Nevermind',
        }).then(() => {
          WarehouseClaimer.cancel({ dispatch_id: this.dispatchId, id: taskId }).$promise.then(() => {
            $window.location.reload();
          });
        });
      };
    },
  ],
});

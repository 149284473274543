import React, { useState, useEffect } from 'react';

import { Button, Text } from '@shared/components/bootstrap';
import { client } from '@admin/libraries/apollo';
import { DetailedClaimFragment, Status, useSaveOfferMutation } from '@admin/schema';

import { ConfirmationModal } from './confirmation_modal';
import { buildOfferInput, IResolutionEdits, selectionAmount } from './helpers';
import { compensated } from '../helpers';

type SharedButtonProps = {
  claim: DetailedClaimFragment;
  issueEdit: IResolutionEdits;
  itemEdits: IResolutionEdits;
  propertyEdit: IResolutionEdits;
  disabled?: boolean;
};

const SaveButton: React.FC<
  SharedButtonProps & {
    setSaving(saving: boolean): void;
    setError(error: string | undefined): void;
  }
> = ({ claim, issueEdit, itemEdits, propertyEdit, disabled = false, setSaving, setError }) => {
  const [saveOffer, { loading }] = useSaveOfferMutation({ client });

  useEffect(() => {
    setSaving(loading);
  }, [loading]);

  const onSave = async () => {
    if (!Object.keys(itemEdits).length && !Object.keys(propertyEdit).length && !Object.keys(issueEdit).length) {
      setError(undefined);
      return;
    }
    const resolutions = buildOfferInput(
      itemEdits,
      claim.propertySelection?.id,
      propertyEdit,
      claim.issueSelection?.id,
      issueEdit,
    );
    const response = await saveOffer({ variables: { input: { claimID: claim.id, ...resolutions } } });
    if (response.data?.saveOffer.status === Status.Unprocessable) {
      setError(response.data.saveOffer.error ?? undefined);
    }
  };

  return (
    <Button kind="default" disabled={disabled} loading={loading} onClick={onSave}>
      Save
    </Button>
  );
};

const OfferButton: React.FC<
  SharedButtonProps & {
    totalCompensated: number;
    onOffered(claim: DetailedClaimFragment): void;
  }
> = ({ claim, issueEdit, itemEdits, propertyEdit, totalCompensated, disabled = false, onOffered }) => {
  const [showModal, setShowModal] = useState<boolean>(false);

  const { issueSelection, itemSelections, propertySelection } = claim;

  let hasCompensatedSelections = itemSelections.some((selection) => compensated(selection));
  if (issueSelection) {
    hasCompensatedSelections = hasCompensatedSelections || compensated(issueSelection);
  }
  if (propertySelection) {
    hasCompensatedSelections = hasCompensatedSelections || compensated(propertySelection);
  }

  return (
    <>
      <Button
        kind={hasCompensatedSelections ? 'info' : 'danger'}
        disabled={disabled}
        onClick={() => setShowModal(true)}
      >
        {hasCompensatedSelections ? 'Offer' : 'Deny'}
      </Button>
      {showModal && (
        <ConfirmationModal
          claim={claim}
          hasCompensatedSelections={hasCompensatedSelections}
          totalCompensated={totalCompensated}
          issueEdit={issueEdit}
          itemEdits={itemEdits}
          propertyEdit={propertyEdit}
          onOffered={onOffered}
          onHide={() => setShowModal(false)}
        />
      )}
    </>
  );
};

export const OfferButtons: React.FC<{
  claim: DetailedClaimFragment;
  issueEdit: IResolutionEdits;
  itemEdits: IResolutionEdits;
  propertyEdit: IResolutionEdits;
  totalCompensated: number;
  onOffered(claim: DetailedClaimFragment): void;
}> = ({ claim, issueEdit, itemEdits, propertyEdit, totalCompensated, onOffered }) => {
  const [saving, setSaving] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();

  const { issueSelection, itemSelections, propertySelection } = claim;

  const offerButtonEnabled = () => {
    for (const selection of itemSelections) {
      const amount = selectionAmount(itemEdits, selection);
      if (compensated(selection) && !(Number(amount) >= 0)) {
        return false;
      }
    }

    if (propertySelection) {
      const amount = selectionAmount(propertyEdit, propertySelection);

      if (compensated(propertySelection) && !(Number(amount) >= 0)) {
        return false;
      }
    }

    if (issueSelection) {
      const amount = selectionAmount(issueEdit, issueSelection);

      if (compensated(issueSelection) && !(Number(amount) >= 0)) {
        return false;
      }
    }
    return true;
  };

  return (
    <>
      {error && (
        <div className="mar-top text-right">
          <Text style="danger">{error}</Text>
        </div>
      )}
      <div className="mar-top pull-right">
        <SaveButton
          claim={claim}
          issueEdit={issueEdit}
          itemEdits={itemEdits}
          propertyEdit={propertyEdit}
          setSaving={setSaving}
          setError={setError}
        />{' '}
        <OfferButton
          claim={claim}
          issueEdit={issueEdit}
          itemEdits={itemEdits}
          propertyEdit={propertyEdit}
          totalCompensated={totalCompensated}
          disabled={saving || !offerButtonEnabled()}
          onOffered={onOffered}
        />
      </div>
    </>
  );
};

import $ from 'jquery';

angular.module('app').service('UserService', [
  '$window',
  '$http',
  function service($window, $http) {
    const { user } = $window.clutter;
    this.id = user.id;
    this.tab_id = $window.clutter.tab_id;
    this.name = user.name;
    this.roles = user.roles;
    this.region_ids = user.region_ids;
    this.notification_count = user.notification_count;
    this.tz = user.tz;
    this.remindCoolDown = !user.in_sales_groups;
    this.in_sales_groups = user.in_sales_groups;
    this.in_care_groups = user.in_care_groups;
    if (user.image_source) {
      this.image = { source: user.image_source, state: 'uploaded' };
    }

    this.triggerAlerting = function () {
      return $http.put('users/alerting');
    };
    this.triggerHangup = function () {
      return $http.put('users/hangup');
    };
    this.triggerRecover = function ({ forceLoggedOut }) {
      return $http.put(`users/recover?tab_id=${this.tab_id}&force_logout=${forceLoggedOut}`);
    };
    this.triggerLeave = function () {
      return $http.put('users/leave');
    };

    this.triggerForceLeave = function (id) {
      return $http.patch(`/users/${id}/status.json`);
    };

    this.triggerWarming = function (conversaionId) {
      const warmingParams = $.param({
        conversation_id: conversaionId,
      });
      return $http.put(`users/warming?${warmingParams}`);
    };
    this.triggerJoin = function () {
      return $http.put(`users/join?tab_id=${this.tab_id}`);
    };
    this.triggerAnswer = function (conversaionId) {
      const answerParams = $.param({
        conversation_id: conversaionId,
      });
      return $http.put(`users/answer?${answerParams}`);
    };

    this.triggerHold = function (callSid, bool) {
      const holdParams = {
        user_id: this.id,
        call_sid: callSid,
        hold: bool,
      };

      return $http.post('conversations/conference/hold', holdParams);
    };

    this.triggerCancel = function (callSid) {
      const cancelParams = {
        call_sid: callSid,
      };

      return $http.post('conversations/conference/cancel', cancelParams);
    };
    this.triggerConference = function (transfereeId, callSid) {
      const conferenceParams = {
        transferer_id: this.id,
        transferee_id: transfereeId,
        call_sid: callSid,
        hold: true,
      };

      return $http.post('conversations/conference', conferenceParams);
    };

    function hasAccess(role) {
      return _.includes(user.roles, role);
    }

    function isIronMountainUser() {
      return user.external_group === 'iron_mountain';
    }

    function isIronMountainUserWithoutTicketAccess() {
      return isIronMountainUser() && !hasAccess('care');
    }

    this.hasRole = hasAccess;

    this.access = (state) => {
      switch (state) {
        case 'inbox':
          return !isIronMountainUserWithoutTicketAccess();
        case 'changePassword':
          return (
            hasAccess('admin') ||
            hasAccess('l2_agent') ||
            hasAccess('l1_agent') ||
            hasAccess('manager') ||
            hasAccess('mover') ||
            hasAccess('self_storage_associate')
          );
        case 'itSupport':
          return (
            hasAccess('admin') ||
            hasAccess('l2_agent') ||
            hasAccess('manager') ||
            hasAccess('mover') ||
            hasAccess('self_storage_associate')
          );
        case 'selfStorageReservations':
        case 'smsInbox':
        case 'phoneCalls':
        case 'viewAccount':
        case 'voicemails':
          return (
            hasAccess('admin') ||
            hasAccess('l2_agent') ||
            hasAccess('l1_agent') ||
            hasAccess('manager') ||
            hasAccess('self_storage_associate')
          );
        case 'leads':
        case 'partnerLeads':
        case 'accounts':
          return hasAccess('admin') || hasAccess('l2_agent') || hasAccess('l1_agent') || hasAccess('manager');
        case 'orders':
        case 'transfers':
        case 'calendar':
        case 'itemsExplorer':
        case 'newTechSupportTicket':
          return hasAccess('admin') || hasAccess('l2_agent') || hasAccess('manager');
        case 'timeClock':
          return hasAccess('admin') || hasAccess('l2_agent') || hasAccess('self_storage_associate');
        case 'dispatches':
          return hasAccess('admin') || hasAccess('care') || hasAccess('manager');
        case 'users':
          return hasAccess('admin') || (hasAccess('care') && !isIronMountainUser()) || hasAccess('manager');
        case 'customerTickets':
          return hasAccess('admin') || hasAccess('l2_agent') || hasAccess('l1_agent');
        case 'gratuities':
          return hasAccess('admin') || hasAccess('billing');
        case 'auctionUploads':
          return hasAccess('admin') || hasAccess('manager') || hasAccess('billing_admin') || hasAccess('billing');
        case 'editLead':
        case 'selfStorageNewRental':
        case 'selfStorageNewRentalWalkIn':
        case 'selfStorageRentalsList':
        case 'selfStorageReservationDetail':
          return hasAccess('admin') || hasAccess('manager') || hasAccess('self_storage_associate');
        case 'shifts':
          return hasAccess('admin') || hasAccess('timekeeper');
        case 'availability':
        case 'selfStorageFacilities':
        case 'selfStorageRateAdjustmentUploader':
        case 'createWarehouse':
        case 'createDepot':
        case 'depotsList':
        case 'palletTypes':
        case 'createPalletType':
        case 'editPalletType':
        case 'testOrders':
        case 'hrsRoleDefaults':
        case 'hrsProfileDefaults':
        case 'hrsLocations':
        case 'featureFlags':
          return hasAccess('admin');
        case 'opsAuditConfigurations':
        case 'opsAuditEditConfiguration':
        case 'notesUploader':
          return hasAccess('admin') || hasAccess('auditor');
        case 'redirects':
        case 'coupons':
        case 'utmPhones':
        case 'referrals':
        case 'qrCodeGenerator':
          return hasAccess('marketing');
        case 'loads':
        case 'detailedLoad':
        case 'purchaseOrders':
          return (
            hasAccess('admin') ||
            hasAccess('enterprise_manager') ||
            hasAccess('manager') ||
            (hasAccess('warehouse') && !isIronMountainUser())
          );
        case 'createLoad':
        case 'editLoad':
        case 'newSku':
        case 'editSku':
          return hasAccess('admin') || hasAccess('enterprise_manager') || hasAccess('manager');
        case 'nightTransportPlanner':
          return hasAccess('admin') || hasAccess('dispatcher') || hasAccess('manager');
        case 'landingLocationStateList':
        case 'landingLocationCityList':
        case 'landingLocationNeighborhoodList':
        case 'landingLocationRouteList':
          return hasAccess('admin');
        case 'globalUnavailability':
        case 'jobAvailability':
        case 'warehouseCapacity':
        case 'availabilityLaborAdjustment':
        case 'availabilityRescheduleOffers':
          return hasAccess('admin') || hasAccess('l2_agent') || hasAccess('manager') || hasAccess('ops_leadership');
        case 'warehouses':
        case 'regions':
          return true;
        case null:
          return false;
        default:
          return hasAccess('admin') || hasAccess('manager');
      }
    };
  },
]);

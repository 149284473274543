(function () {
  angular.module('app').factory('VehicleType', [
    '$resource',
    function ($resource) {
      return $resource(
        '/vehicle_types/:id.json',
        {
          id: '@id',
        },
        {
          index: {
            method: 'GET',
            isArray: true,
          },
          create: {
            method: 'POST',
          },
          show: {
            method: 'GET',
          },
          update: {
            method: 'PUT',
          },
        },
      );
    },
  ]);
})();

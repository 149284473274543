import React from 'react';

import { Button, ModalSize } from '@shared/components/bootstrap';

import { Order, Status, useOrderCompleteMutation } from '@admin/schema';

import {
  Dialog as ConfirmationDialog,
  Response as ConfirmationResponse,
  useConfirmation,
} from '@admin/components/confirmation';
import { ConfirmationDescription } from '@admin/components/orders/actions/complete/confirmation_description';
import { client } from '@admin/libraries/apollo';

export const Complete: React.FC<{
  order: Pick<Order, 'id' | 'type' | 'state'>;
}> = ({ order }) => {
  const { confirmation, confirm } = useConfirmation();
  const [completeOrder, { loading }] = useOrderCompleteMutation({ client });

  const onConfirm = async () => {
    const confirmed = await confirm({
      title: 'Are you sure you want to complete this order?',
      description: <ConfirmationDescription order={order} />,
      size: ModalSize.Large,
    });
    if (confirmed !== ConfirmationResponse.Confirm) return;
    const response = await completeOrder({
      variables: { orderID: order.id },
    });
    if (response.data?.orderComplete?.status !== Status.Ok) {
      await confirm({
        title: 'There was an error completing this order',
        description: response?.data?.orderComplete?.error || '',
        showConfirm: false,
        cancel: 'OK',
        size: ModalSize.Large,
      });
    }
  };

  return (
    <>
      <Button kind="primary" onClick={() => onConfirm()} loading={loading}>
        Complete
      </Button>
      {confirmation && <ConfirmationDialog confirmation={confirmation} />}
    </>
  );
};

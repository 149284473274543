(function () {
  angular.module('app').factory('AffiliateDeserializer', [
    function () {
      return function (data) {
        data.payout = parseFloat(data.payout);
        data.address = data.address ? data.address : {};
        return data;
      };
    },
  ]);
})();

import * as React from 'react';
import { useFormContext } from 'react-hook-form';

import { SelfStorage__ReservationFragment } from '@admin/schema';
import { splitName } from '@admin/utils';
import { Spacer } from '@shared/components/helpers';
import { Section } from '../components/section';
import { SectionHeading } from '../components/section_heading';
import { Field } from './field';

import { EmailField } from './customer/email_field';
import { PhoneField } from './customer/phone_field';

import { FormData } from './form/types';

type FieldsData = Pick<FormData, 'customer'>;

export const Customer: React.FC<{
  reservation?: SelfStorage__ReservationFragment;
}> = ({ reservation }) => {
  const { register } = useFormContext<FieldsData>();

  let name;
  if (reservation?.name) {
    name = splitName(reservation.name);
  }

  return (
    <Section>
      <SectionHeading>Customer Contact Details</SectionHeading>
      <div className="row">
        <div className="col-md-5">
          <Field required id="customer-name-first" name="customer.name.first" label="First Name">
            <input
              id="customer-name-first"
              name="customer.name.first"
              className="form-control"
              type="text"
              ref={register({ required: 'Please provide a first name' })}
              defaultValue={name?.first}
            />
          </Field>
        </div>
        <div className="col-md-2">
          <Field id="customer-name-initials" name="customer.name.initials" label="Middle Initial">
            <input
              id="customer-name-initials"
              name="customer.name.initials"
              className="form-control"
              type="text"
              ref={register}
              defaultValue={name?.middle}
            />
          </Field>
        </div>
        <div className="col-md-5">
          <Field required id="customer-name-last" name="customer.name.last" label="Last Name">
            <input
              id="customer-name-last"
              name="customer.name.last"
              className="form-control"
              type="text"
              ref={register({ required: 'Please provide a last name' })}
              defaultValue={name?.last}
            />
          </Field>
        </div>
      </div>
      <PhoneField defaultValue={reservation?.phone} />
      <EmailField defaultValue={reservation?.email} />
      <Spacer height="36px" />
    </Section>
  );
};

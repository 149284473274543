import { BillingRadios } from '@admin/components/self_storage/billing/components/billing_radios';
import {
  SelfStorage__Rental__BillingEnum,
  SelfStorage__Subscription,
  Status,
  useModifySelfStorageDefaultSourceMutation,
} from '@admin/schema';
import { Button, ControlLabel, FormGroup, Text } from '@shared/components/bootstrap';
import { client } from '@admin/libraries/apollo';
import React, { useState } from 'react';
import { Radios as SourcesRadios } from './radios';

export const Form: React.FC<{
  accountID: string;
  subscription: Pick<SelfStorage__Subscription, 'id' | 'billing' | 'defaultSource'>;
  onSave(): void;
  onCancel(): void;
}> = ({ accountID, subscription, onSave, onCancel }) => {
  const [modify, { loading }] = useModifySelfStorageDefaultSourceMutation({
    client,
  });
  const [sourceID, setSourceID] = useState<string | undefined>();
  const [billing, setBilling] = useState<SelfStorage__Rental__BillingEnum | undefined>();
  const [error, setError] = useState<string | undefined>();

  const defaultSourceID = subscription.defaultSource?.id;
  const subscriptionID = subscription.id;
  const currentBilling = subscription.billing;

  const unchanged =
    (sourceID === defaultSourceID || (!defaultSourceID && sourceID === '')) && billing === currentBilling;

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();

    if (loading || !billing) {
      return;
    }

    const response = await modify({
      variables: {
        subscriptionID,
        sourceID: sourceID === '' ? undefined : sourceID,
        billing,
      },
    });

    if (response && response?.data?.result?.status === Status.Ok) {
      onSave();
    } else {
      setError('Unknown error occurred. Please try again later.');
    }
  };

  return (
    <form onSubmit={onSubmit}>
      <FormGroup>
        <ControlLabel htmlFor="source">
          <strong>Source:</strong>
        </ControlLabel>
        <SourcesRadios
          name="source"
          accountID={accountID}
          selectedID={sourceID}
          defaultID={defaultSourceID}
          onSelect={(id) => setSourceID(id)}
        />
      </FormGroup>
      <FormGroup>
        <ControlLabel htmlFor="billing">
          <strong>Billing:</strong>
        </ControlLabel>
        <BillingRadios
          name="billing"
          onSelect={(selectedBilling) => setBilling(selectedBilling)}
          defaultBilling={currentBilling}
          selectedBilling={billing}
        />
      </FormGroup>
      {error && (
        <Text tag="p" style="danger" alignment="center">
          <strong>{error}</strong>
        </Text>
      )}
      <Text alignment="right" tag="div">
        <Button kind="danger" disabled={loading} onClick={onCancel}>
          Cancel
        </Button>{' '}
        <Button kind="primary" type="submit" loading={loading} disabled={unchanged}>
          Change Source
        </Button>
      </Text>
    </form>
  );
};

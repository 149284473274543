import * as React from 'react';

import { client } from '@admin/libraries/apollo';

import { RateGroup, usePricingSetRateGroupsQuery, useStorageTermsQuery } from '@admin/schema';

export const Selector: React.FC<{
  initialRateGroupId?: string;
  pricingSetId?: string;
  onSelect(rateGroupId: string): void;
}> = ({ initialRateGroupId, pricingSetId, onSelect }) => {
  const { data: dataForPricingSetRateGroupsQuery } = usePricingSetRateGroupsQuery({
    client,
    variables: { pricingSetId: pricingSetId! },
    skip: !pricingSetId,
  });

  const { data: dataForStorageTermQuery } = useStorageTermsQuery({ client });
  const storageTerms = dataForStorageTermQuery?.storageTerms;

  const rateGroupStorageTerm = (rateGroup: RateGroup) =>
    storageTerms?.find((storageTerm) => storageTerm.rateGroup.id === rateGroup.id);

  const upperCaseFirst = (str: string) => {
    const lower = str.toLowerCase();
    return lower.charAt(0).toUpperCase() + lower.substring(1);
  };

  const onChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    onSelect(event.target.value);
  };

  return (
    <select
      id="rate-group-select"
      className="form-control"
      value={initialRateGroupId}
      onChange={onChange}
      disabled={!pricingSetId || !dataForPricingSetRateGroupsQuery?.rateGroups}
    >
      <option disabled={!!initialRateGroupId} value={''}>
        Select a Rate Group
      </option>
      {dataForPricingSetRateGroupsQuery?.rateGroups?.map(rateGroupStorageTerm).map(
        (entry) =>
          entry && (
            <option key={entry.rateGroup.id} value={entry.rateGroup.id}>
              {entry.rateGroup.name.split('_').map(upperCaseFirst).join(' ')}
              {', '}
              {entry.name}
            </option>
          ),
      )}
    </select>
  );
};

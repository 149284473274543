import * as React from 'react';

import { DateTime } from 'luxon';

import { IOrderEvent } from '@admin/types';
import { Tooltip, TooltipPlacement } from '@shared/components/bootstrap/tooltip';
import { format } from './utils';
import { useTimeZone } from '../context';
import { useScale } from './order_gantt';

export const Events: React.FC<{
  events: IOrderEvent[];
  isTerminalEvent: (eventName: string) => boolean;
  getName: (eventName: string) => string;
  getColor: (eventName: string) => string;
  placement?: TooltipPlacement;
  final?: DateTime;
}> = ({ events, placement, final, isTerminalEvent, getName, getColor }) => {
  const timeZone = useTimeZone();

  return (
    <>
      {events.map(({ category, eventName, timestamp }, index) => {
        const isLastEvent = index === events.length - 1;

        if (isTerminalEvent(eventName) || (isLastEvent && !final)) {
          return null;
        }

        const start = DateTime.fromISO(timestamp).setZone(timeZone);
        const end = isLastEvent ? final! : DateTime.fromISO(events[index + 1].timestamp).setZone(timeZone);

        return (
          <Event
            key={`${category}-${eventName}-${timestamp}-${placement}`}
            start={start}
            end={end}
            eventName={getName(eventName)}
            color={getColor(eventName)}
            placement={placement}
          />
        );
      })}
    </>
  );
};

const Event: React.FC<{
  start: DateTime;
  end: DateTime;
  eventName: string;
  placement?: TooltipPlacement;
  color: string;
}> = ({ start, end, eventName, placement, color }) => {
  const scale = useScale();
  const left = scale(start);
  const title = `${eventName}: ${format(start)} - ${format(end)}`;
  const width = scale(end) - scale(start);

  return (
    <div
      style={{
        position: 'absolute',
        left: `${left}%`,
        width: `${width}%`,
      }}
    >
      <Tooltip key={title} placement={placement} title={title}>
        <div
          style={{
            backgroundColor: color,
            height: '1rem',
          }}
        />
      </Tooltip>
    </div>
  );
};

const app = angular.module('app');

app.directive('commaList', function () {
  return {
    link: function () {
      angular.element('[comma-list] > .comma').remove();
      angular.element('[comma-list]:not(:last)').append('<span class="comma">,</span>');
    },
  };
});

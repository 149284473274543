import * as React from 'react';
import { useState } from 'react';

import { client } from '@admin/libraries/apollo';

import { Account, Customer, usePaginatedReferableAccountsQuery } from '@admin/schema';

import { AsyncFormControl } from '@admin/components/fields/async_form_control';

const DEFAULT_REFERABLE = true;

const Option: React.FC<{
  option: Pick<Account, 'id' | 'number'> & { customer: Pick<Customer, 'id' | 'name' | 'email'> };
}> = ({ option }) => (
  <>
    <div>
      <strong>{option.customer.name}</strong>
    </div>
    <div>{option.customer.email}</div>
    <div>#{option.number}</div>
  </>
);

export const AccountFormControl: React.FC<{
  onSelect(account: Pick<Account, 'id'>): void;
}> = ({ onSelect }) => {
  const [search, setSearch] = useState<string>();
  const [selection, setSelection] = useState<any>(undefined);
  const { data } = usePaginatedReferableAccountsQuery({
    client,
    variables: {
      query: search!,
      referable: DEFAULT_REFERABLE,
    },
    skip: !search,
  });

  const accounts = data?.paginated?.results;

  return (
    <AsyncFormControl
      placeholder="Search accounts by name, email, phone, or account #"
      selection={selection}
      onSelect={(option) => {
        setSearch(option.customer.name);
        setSelection(option);
        onSelect(option);
      }}
      search={search}
      onSearch={setSearch}
      options={accounts}
      option={Option}
    />
  );
};

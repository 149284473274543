import React, { useState } from 'react';
import styled from '@emotion/styled';

import { Panel } from '@admin/components/helpers/panel';
import { Timestamp } from '@admin/components/timestamp';
import { Lightbox } from '@shared/components/helpers';
import { Image, Walkthrough } from '@admin/schema';

const ImagesContainer = styled.div`
  display: flex;
  width: 100%;
  height: fit-content;
  white-space: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
`;

const ImageContainer = styled.div`
  display: inline-block;
  width: 295px;
  height: 295px;
  border: solid;
  border-width: 1px;
  margin: 2px;
  padding: 2px;

  &:hover {
    cursor: pointer;
  }
`;

const formattedImageUrl = (source: string, w = 290, h = 290, fit = 'fill') =>
  `${source}?w=${w}&h=${h}&fit=${fit}&bg=FFF`;

interface IWalkthroughsProps {
  walkthroughs: Array<
    Pick<Walkthrough, 'id' | 'name'> & { images: Array<Pick<Image, 'id' | 'createdAt' | 'source' | 'name'>> }
  >;
  timeZone: string;
}

export const Walkthroughs: React.FC<IWalkthroughsProps> = ({ walkthroughs, timeZone }) => {
  const [selection, setSelection] = useState<{ source: string } | undefined>(undefined);

  return walkthroughs.length ? (
    <div className="order-assessment-panel">
      <Panel>
        <Panel.Header>
          <h3 className="panel-title">Walkthrough Images</h3>
        </Panel.Header>
        <Panel.Body>
          {walkthroughs.map((walkthrough) => (
            <>
              <h4>{walkthrough.name}</h4>
              <ImagesContainer>
                {walkthrough.images.map((image) => (
                  <div key={image.id}>
                    <div>
                      <Timestamp value={image.createdAt} format="medium" timezone={timeZone} />
                    </div>
                    <ImageContainer
                      onClick={() => {
                        setSelection(image);
                      }}
                    >
                      <img src={formattedImageUrl(image.source)} alt={image.name ?? ''} />
                    </ImageContainer>
                  </div>
                ))}
              </ImagesContainer>
            </>
          ))}
        </Panel.Body>
      </Panel>
      {selection && <Lightbox imageURL={selection.source} onClose={() => setSelection(undefined)} />}
    </div>
  ) : null;
};

/** @jsx jsx */

import * as React from 'react';
import { useContext, useEffect, useState } from 'react';

import { css, jsx } from '@emotion/react';

import { max, min } from 'lodash';
import { DateTime } from 'luxon';

import { scaleLinear, ScaleLinear } from 'd3-scale';

import { IDispatchOrder, IOrderEvent } from '@admin/types';

import styled from '@emotion/styled';
import { createContext } from 'react';
import { Checkpoint } from './checkpoint';
import { OrderGanttRows } from './order_gantt_rows';
import { TimeAxis } from './time_axis';

import { COLORS } from '../../../../colors';
import { useTimeZone } from '../context';
import { KNOWN_EVENT_NAMES } from './utils';

const hasSameDate = (d1: DateTime, d2: DateTime) =>
  d1.hasSame(d2, 'year') && d1.hasSame(d2, 'month') && d1.hasSame(d2, 'day');

const CUTOFF_COLOR = 'redDark';

const ARRIVAL_COLOR = 'blueDarker';

const getTimeStamps = (orders: IDispatchOrder[], arrival: DateTime, cutoffTime: DateTime, timeZone: string) => {
  const timeStamps = orders
    .reduce(
      (memo, { orderEventsExcludingNonLeadAssignments, simulatedOrderEvents }) =>
        orderEventsExcludingNonLeadAssignments.concat(simulatedOrderEvents).concat(memo),
      [] as IOrderEvent[],
    )
    .filter(({ eventName }) => KNOWN_EVENT_NAMES.includes(eventName))
    .filter(({ timestamp }) => hasSameDate(arrival, DateTime.fromISO(timestamp).setZone(timeZone)))
    .map(({ timestamp }) => DateTime.fromISO(timestamp).setZone(timeZone))
    .concat([arrival]);

  return timeStamps.concat(cutoffTime);
};

const getScale = (timestamps: DateTime[]): ScaleLinear<number, number> => {
  const start = min(timestamps)!.startOf('hour');
  const end = max(timestamps)!.endOf('hour').plus(1); // Round up to hour by adding 1 millisecond

  return scaleLinear().domain([start, end]).range([0, 100]);
};

const Empty = () => <p>No order events for dispatch.</p>;

const Container = styled.div`
  position: relative;

  .tooltip {
    min-width: 250px;
  }
`;

const ScaleContext = createContext<ScaleLinear<number, number> | undefined>(undefined);
export const useScale = () => {
  const scale = useContext(ScaleContext);
  if (scale === undefined) {
    throw new Error('useScale must be used within a ScaleProvider');
  }
  return scale;
};

export const OrderGantt: React.FC<{
  arrival: string;
  cutoff: string;
  clockout: string | null;
  orders: IDispatchOrder[];
}> = ({ arrival, cutoff, clockout, orders }) => {
  const timeZone = useTimeZone();
  const [now, setNow] = useState<DateTime | undefined>(DateTime.local().setZone(timeZone));
  const arrivalTime = DateTime.fromISO(arrival).setZone(timeZone);
  const cutoffTime = DateTime.fromISO(cutoff).setZone(timeZone);
  let clockoutTime;
  if (clockout) {
    clockoutTime = DateTime.fromISO(clockout).setZone(timeZone);
  }

  useEffect(() => {
    const isCurrent = now && hasSameDate(now, arrivalTime);
    if (!isCurrent) {
      setNow(undefined);
    }

    const interval = setInterval(() => setNow(DateTime.local().setZone(timeZone)), 1000);
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, []);

  const timestamps = getTimeStamps(orders, arrivalTime, cutoffTime, timeZone);
  const scale = getScale(timestamps);

  const latestTimestamp = max(timestamps)!.endOf('hour').plus(1);
  const end = min([now, latestTimestamp]);

  if (timestamps.length <= 1) {
    return <Empty />;
  }

  const redDark = css`
    color: ${COLORS[CUTOFF_COLOR]};
  `;

  const blueDarker = css`
    color: ${COLORS[ARRIVAL_COLOR]};
  `;

  return (
    <ScaleContext.Provider value={scale}>
      <Container>
        <Checkpoint label="Dispatch Arrival" timestamp={arrivalTime} css={blueDarker} />
        <Checkpoint label="Dispatch Cutoff" timestamp={cutoffTime} css={redDark} />
        {now && <Checkpoint label="Now" timestamp={now} />}
        <OrderGanttRows orders={orders} endTime={clockoutTime || end} />
        <TimeAxis />
      </Container>
    </ScaleContext.Provider>
  );
};

import React from 'react';

import { Button, FormGroup, HelpBlock } from '@shared/components/bootstrap';

import { useUserForceLeaveCallCenterMutation, User, User__Policy } from '@admin/schema';

import { client } from '@admin/libraries/apollo';
import {
  Dialog as ConfirmationDialog,
  Response as ConfirmationResponse,
  useConfirmation,
} from '@admin/components/confirmation';

export const ForceLeaveCallCenter: React.FC<{
  user: Pick<User, 'id' | 'name' | 'state'> & {
    policy: Pick<User__Policy, 'canForceLeaveCallCenter'>;
  };
}> = ({ user }) => {
  const [execute, { loading, error }] = useUserForceLeaveCallCenterMutation({ client, variables: { id: user.id } });
  const { confirmation, confirm } = useConfirmation();

  const onClick = async () => {
    const confirmed = await confirm({
      title: 'Force Leave Call Center',
      description: <>Are you sure you want to reset the status of {user.name}.</>,
    });
    if (confirmed !== ConfirmationResponse.Confirm) return;

    execute();
  };

  return (
    <FormGroup has={error ? 'error' : undefined}>
      {confirmation && <ConfirmationDialog confirmation={confirmation} />}
      <Button disabled={!user.policy.canForceLeaveCallCenter} loading={loading} kind="danger" onClick={onClick}>
        Force Leave Call Center
      </Button>
      {!user.policy.canForceLeaveCallCenter && <HelpBlock>You are not authorized to perform this action.</HelpBlock>}
      {error && <HelpBlock>{error.message}</HelpBlock>}
    </FormGroup>
  );
};

import { formatPhoneForHuman } from '@admin/utils/format_phone_for_human';

(function () {
  angular.module('app').filter('telephoneDetail', [
    function () {
      return function (phone) {
        if (phone && phone.number) {
          let detail = formatPhoneForHuman(phone.number);

          if (phone.name) {
            detail = `${detail} (${phone.name})`;
          }

          return detail;
        }
      };
    },
  ]);
})();

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { useEffect, useState } from 'react';

import { NoteNotableInput, useBuildNoteMutation } from '@admin/schema';

import { client } from '@admin/libraries/apollo';

import { Button } from '@shared/components/bootstrap';

import { Manager, Selector, Uploader } from '@shared/components/files';
import { Files } from './files';

interface ICache {
  read(key: string): string | undefined;
  write(key: string, value: string): void;
  delete(key: string): void;
}

const CACHE: ICache = {
  read: (key) => localStorage.getItem(key) || undefined,
  write: (key, value) => localStorage.setItem(key, value),
  delete: (key) => localStorage.removeItem(key),
};

export const NotesForm: React.FC<
  NoteNotableInput & {
    cache?: ICache;
    onSave(): void;
  }
> = ({ cache = CACHE, id, type, onSave }) => {
  const key = `notes:${type}:${id}`;
  const [body, setBody] = useState<string | undefined>(cache.read(key));
  const [uploaded, setUploaded] = useState<
    Array<{
      file: File;
      signedID: string;
    }>
  >([]);
  useEffect(() => {
    if (body) {
      cache.write(key, body);
    } else {
      cache.delete(key);
    }
  }, [id, type, body]);

  const [save, { loading }] = useBuildNoteMutation({ client });

  const onUploaded = (file: File, signedID: string) => {
    setUploaded((current) => [...current, { file, signedID }]);
  };

  const onReset = () => {
    setBody(undefined);
    setUploaded([]);
  };

  return (
    <Manager onSave={onUploaded}>
      {({ uploads }) => {
        const saveable = body && !!body.trim().length && !uploads.length && !loading;

        const onSubmit = async (event: React.FormEvent) => {
          event.preventDefault();
          event.stopPropagation();
          if (!saveable) {
            return;
          }
          await save({
            variables: {
              input: {
                body: body!,
                files: uploaded.map(({ signedID }) => signedID),
              },
              notable: {
                id,
                type,
              },
            },
          });
          onReset();
          onSave();
        };

        return (
          <form onSubmit={onSubmit}>
            <div className="form-group">
              <textarea
                placeholder="Add additional notes here."
                className="form-control"
                value={body || ''}
                onChange={(event) => setBody(event.target.value)}
              />
            </div>
            {!!uploaded.length && (
              <div className="form-group">
                <Files>
                  {uploaded.map((entry) => (
                    <Files.Upload key={entry.signedID}>
                      <span>{entry.file.name}</span>{' '}
                      <a
                        href="#"
                        onClick={(event) => {
                          event.preventDefault();
                          event.stopPropagation();
                          setUploaded(uploaded.filter(({ signedID }) => signedID !== entry.signedID));
                        }}
                      >
                        <FontAwesomeIcon icon="times" />
                      </a>
                    </Files.Upload>
                  ))}
                </Files>
              </div>
            )}
            {uploads.map((entry) => (
              <Uploader key={entry.uuid} {...entry} />
            ))}
            <div className="form-group">
              <div className="clearfix">
                <div className="pull-left">
                  <label className="btn btn-default">
                    <FontAwesomeIcon icon="paperclip" />
                    <Selector />
                  </label>
                </div>
                <div className="pull-right">
                  <Button type="submit" disabled={!saveable} kind="primary">
                    Add Note
                  </Button>
                </div>
              </div>
            </div>
          </form>
        );
      }}
    </Manager>
  );
};

(function () {
  angular.module('app').filter('itemCategoryDefaults', function () {
    return function (item) {
      if (!item || !item.category || !item.category.defaults) {
        return;
      }

      return item.sizing_selection ? item.category.defaults[item.sizing_selection] : item.category.defaults.default;
    };
  });
})();

import axios from 'axios';
import { memoize } from 'lodash';
import * as React from 'react';
import { useEffect, useState } from 'react';

import { IItem, IPartCategory } from '@admin/types';

const CATEGORIES = memoize(() => axios.get<IPartCategory[]>('/part_categories.json'));

export const PartCategoryPreview: React.FC<{
  item: IItem;
}> = ({ item }) => {
  const [category, setCategory] = useState<IPartCategory | undefined>(undefined);
  useEffect(() => {
    CATEGORIES().then((response) => {
      setCategory(response.data.find(({ id }) => !!item.packaging && id === item.packaging.part_category_id));
    });
  }, [item]);
  return category ? <>{category.name}</> : null;
};

import { PricingPerItemFeeDocument } from '@admin/schema';
import { client } from '@admin/libraries/apollo';
import { ORDER_TYPE_MAP, ORDER_SUBTYPE_MAP } from './order_type_map';

export const pricingPerItemFee = async ({
  regionID,
  orderType,
  orderSubtype,
}: {
  regionID: string | null;
  orderType: keyof typeof ORDER_TYPE_MAP;
  orderSubtype: keyof typeof ORDER_SUBTYPE_MAP;
}) => {
  if (!regionID) {
    return { error: 'A region is required to fetch per item fee.' };
  }

  const input = {
    regionID,
    orderType: (() => {
      if (orderType && ORDER_TYPE_MAP.hasOwnProperty(orderType)) {
        return ORDER_TYPE_MAP[orderType];
      }
      throw new Error(`invalid order type provided: "${orderType}"`);
    })(),
    orderSubtype: (() => {
      if (orderSubtype && ORDER_SUBTYPE_MAP.hasOwnProperty(orderSubtype)) {
        return ORDER_SUBTYPE_MAP[orderSubtype];
      }
      throw new Error(`invalid order subtype provided: "${orderSubtype}"`);
    })(),
  };

  const { data, loading } = await client.query({
    query: PricingPerItemFeeDocument,
    variables: { input },
  });

  if (!data || loading) {
    return {};
  }

  return { data: data.pricingPerItemFee };
};

import React from 'react';

import { Claim__ItemSelectionFragment, Claim__PropertySelectionFragment } from '@admin/schema';
import { Currency } from '@shared/components/helpers';

import { AssessmentSummary } from '../assessment_summary';
import { IResolutionEdits, selectionAmount } from './helpers';
import { denied } from '../helpers';

export const validAmount = (amount?: number) => typeof amount === 'number' && amount >= 0;

export const Selection: React.FC<{
  selection: Claim__ItemSelectionFragment | Claim__PropertySelectionFragment;
  showWeight?: boolean;
  edits: IResolutionEdits;
  setEdits(edits: IResolutionEdits): void;
}> = ({ selection, showWeight = false, edits, setEdits }) => {
  const { id, resolution, assessment } = selection;

  const amount = selectionAmount(edits, selection);

  const updateAmount = (amountString?: string) => {
    const parsedAmount = Number(amountString);
    const newAmount = !!amountString && validAmount(parsedAmount) ? parsedAmount : undefined;
    setEdits({ ...edits, [id]: newAmount });
  };

  return (
    <>
      <div className="col-sm-2">
        <AssessmentSummary
          assessment={assessment}
          kind={resolution?.kind}
          reason={resolution?.reason}
          showWeight={showWeight}
        />
      </div>
      <div className="col-sm-1">{!!assessment && <Currency value={Number(assessment.approvedAmount)} />}</div>
      <div className="col-sm-2">
        <div className="input-group">
          <span className="input-group-addon">$</span>
          <input
            name="amount"
            disabled={denied(selection)}
            value={amount ?? ''}
            className="form-control"
            type="number"
            onChange={(event) => updateAmount(event.currentTarget.value)}
          />
        </div>
      </div>
    </>
  );
};

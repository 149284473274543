import { Spinner } from '@admin/components/spinner';
import { Address, useSelfStorageAddressesQuery } from '@admin/schema';
import { client } from '@admin/libraries/apollo';
import { useEffect, useRef } from 'react';
import * as React from 'react';

export type FacilityAddress = Pick<
  Address,
  'id' | 'aptsuite' | 'latitude' | 'longitude' | 'city' | 'state' | 'street' | 'zip' | 'schedulable'
>;

export const Selector: React.FC<{
  onSelect: (address?: FacilityAddress) => void;
  selectedID?: number | string;
  onLoaded: (addresses: FacilityAddress[]) => void;
}> = ({ onSelect, selectedID, onLoaded }) => {
  const { data } = useSelfStorageAddressesQuery({ client });
  const facilities = data?.facilities;

  const onLoadedRef = useRef(onLoaded);

  useEffect(() => {
    onLoadedRef.current = onLoaded;
  }, [onLoaded]);

  useEffect(() => {
    if (facilities) {
      const addresses = facilities.map(({ address }) => address);
      onLoadedRef.current(addresses);
    }
  }, [facilities]);

  if (!facilities) {
    return <Spinner />;
  } else {
    return (
      <select
        id="self-storage-address"
        className="form-control"
        value={String(selectedID) || ''}
        onChange={(event) => {
          const addresses = facilities.map(({ address }) => address);
          const address = addresses.find(({ id }) => id === event.target.value);
          onSelect(address);
        }}
      >
        <option value="">- Self Storage Facility -</option>
        {facilities?.map((facility) => (
          <option key={facility.id} value={facility.address.id}>
            {facility.name}
          </option>
        ))}
      </select>
    );
  }
};

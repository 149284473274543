import { CancelReasonSelector } from '@admin/components/account_rate_adjustments/cancel_reason_selector';
import { Summary, SummaryScheduling } from '@admin/components/rate_adjustment/schedulings/summary';
import { client } from '@admin/libraries/apollo';
import {
  RateAdjustment__Scheduling__CancelReason,
  Status,
  useCancelRateAdjustmentSchedulingMutation,
} from '@admin/schema';
import { Alert, Button, Modal } from '@shared/components/bootstrap';
import React, { useState } from 'react';

type CancelScheduling = SummaryScheduling & { id: string };

const CancelDialog: React.FC<{
  scheduling: CancelScheduling;
  onClose(): void;
  onCancel?(): void;
}> = ({ scheduling, onClose, onCancel }) => {
  const [cancelReason, setCancelReason] = useState<RateAdjustment__Scheduling__CancelReason | undefined>();
  const [cancelAdjustmentScheduling, { loading, data }] = useCancelRateAdjustmentSchedulingMutation({
    client,
  });

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();

    if (!cancelReason) {
      return;
    }
    const response = await cancelAdjustmentScheduling({ variables: { id: scheduling.id, cancelReason } });

    if (response.data?.result.status === Status.Ok) {
      onCancel?.();
      onClose();
    }
  };

  return (
    <Modal onClose={onClose}>
      <form onSubmit={onSubmit}>
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>Cancel Rate Adjustment</Modal.Title>
            <Modal.Close close={onClose} />
          </Modal.Header>
          <Modal.Body>
            {data?.result?.error && <Alert style="danger">{data?.result?.error}</Alert>}
            Are you sure you want to cancel the rate adjustment on{' '}
            <strong>
              <Summary scheduling={scheduling} />
            </strong>
            ?
            <br />
            <br />
            <CancelReasonSelector cancelReason={cancelReason} setCancelReason={setCancelReason} />
          </Modal.Body>
          <Modal.Footer>
            <Button size="lg" kind="default" disabled={loading} onClick={onClose}>
              Close
            </Button>
            <Button size="lg" kind="danger" loading={loading} type="submit">
              Confirm
            </Button>
          </Modal.Footer>
        </Modal.Content>
      </form>
    </Modal>
  );
};

export const CancelButton: React.FC<{ scheduling: CancelScheduling; onCancel?(): void }> = ({
  scheduling,
  onCancel,
}) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  return (
    <>
      <Button className="underline" kind="link" onClick={() => setShowModal(true)}>
        Cancel
      </Button>
      {showModal && <CancelDialog scheduling={scheduling} onCancel={onCancel} onClose={() => setShowModal(false)} />}
    </>
  );
};

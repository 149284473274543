import * as React from 'react';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import styled from '@emotion/styled';

import { Roles } from '@admin/components/helpers/roles';
import { UserRole } from '@admin/types';

import { Box } from '@clutter/clean';
import { COLORS } from '@root/colors';
import { IDepot } from './types/depot';
import { IOption } from './types/option';
import { IWarehouse } from './types/warehouse';

import { CreateTicket as CreateTechSupportTicket } from '../tickets/tech_support/create_ticket';

interface IHeaderProps {
  warehouses: IWarehouse[];
  currentDate: Date;
  warehouseId?: string | undefined;
  depots?: IDepot[];
  filteredDepots?: IOption[];
  filteredVehicles?: IOption[];
  filterVehicleOptions: IOption[] | undefined;
  filteredActiveVehicles: IOption[] | undefined;
  hasReturns: IOption | null;
  filteredAssignmentOptions?: IOption;
  filteredActiveVehicleDepots?: IOption[];
  filteredStatus?: IOption;
  handleWarehouseChange(warehouse: string): void;
  handleDateChange(currentDate: Date | null): void;
  handleFilterDepots(options: any): void;
  handleFilterReturns(options: any): void;
  handleFilterVehicles(options: any): void;
  handleFilterAssignmentValidation(options: any): void;
  handleActiveVehicleFilter(options: any): void;
  handleFilterActiveVehicleDepots(options: any): void;
  handleFilterActiveVehicleStatus(options: any): void;
}

interface IHeaderState {
  selectedOption: any;
}

const returnOptions = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

const assignmentOptions = [
  {
    label: 'No vehicles assigned',
    value: 'no_vehicles_assigned',
  },
  {
    label: 'Vehicle requirements not met  & No vehicles assigned',
    value: 'vehicle_requirements_not_met',
  },
  {
    label: 'Vehicle preferences not met',
    value: 'vehicle_preferences_not_met',
  },
  {
    label: 'Vehicle successfully assigned',
    value: 'vehicle_successfully_assigned',
  },
];

const vehicleStatusOptions = [
  { label: 'In Field', value: 'in_field' },
  { label: 'Backlog', value: 'backlog' },
  { label: 'Empty', value: 'empty' },
];

const Filter = styled(Select)`
  width: 25%;
`;

const SELECT_CLASS_PREFIX = 'Filter';

class Header extends React.Component<IHeaderProps, IHeaderState> {
  public render() {
    if (!this.props.currentDate) {
      return null;
    }

    const {
      warehouses,
      warehouseId,
      currentDate,
      handleDateChange,
      handleWarehouseChange,
      depots,
      handleFilterDepots,
      filteredDepots,
      hasReturns,
      handleFilterReturns,
      filterVehicleOptions,
      filteredVehicles,
      handleFilterVehicles,
      filteredAssignmentOptions,
      handleFilterAssignmentValidation,
      filteredActiveVehicles,
      handleActiveVehicleFilter,
      handleFilterActiveVehicleDepots,
      filteredActiveVehicleDepots,
      filteredStatus,
      handleFilterActiveVehicleStatus,
    } = this.props;

    return (
      <Box position="sticky" top="144px" style={{ zIndex: 100 }} background={COLORS.white} padding="16px">
        <Box.Flex justifyContent="space-between" margin="0 0 24px">
          <div>
            <label htmlFor="warehouse">Warehouse:</label>
            <select
              className="form-control header--select"
              id="warehouse"
              value={warehouseId}
              onChange={(e) => handleWarehouseChange(e.currentTarget.value)}
            >
              <option style={{ display: 'none' }} value="default">
                Pick a warehouse
              </option>
              {warehouses.map((warehouse) => (
                <option key={warehouse.id} value={warehouse.id}>
                  {warehouse.name}
                </option>
              ))}
            </select>
          </div>
          <div>
            <span>Outbound Dispatches:</span>
            <DatePicker selected={currentDate} onChange={handleDateChange} className="form-control" />
            <Roles show={[UserRole.L2Agent, UserRole.Dispatcher, UserRole.Manager, UserRole.Admin]}>
              <div className="header--tech-support-ticket">
                <CreateTechSupportTicket ticketable={{ feature: 'VehicleMatcher', displayName: 'Vehicle Matcher' }} />
              </div>
            </Roles>
          </div>
        </Box.Flex>
        <Box.Flex justifyContent="space-between">
          <Box.Flex gap="8px" width="50%">
            <Filter
              classNamePrefix={SELECT_CLASS_PREFIX}
              options={filterVehicleOptions}
              value={filteredActiveVehicles}
              onChange={handleActiveVehicleFilter}
              isMulti={true}
              placeholder="Vehicle Types"
            />
            <Filter
              classNamePrefix={SELECT_CLASS_PREFIX}
              options={depots}
              value={filteredActiveVehicleDepots}
              onChange={handleFilterActiveVehicleDepots}
              isMulti={true}
              placeholder="Depots"
            />
            <Filter
              classNamePrefix={SELECT_CLASS_PREFIX}
              options={vehicleStatusOptions}
              value={filteredStatus}
              onChange={handleFilterActiveVehicleStatus}
              placeholder="Status"
            />
          </Box.Flex>
          <Box.Flex gap="8px" width="50%">
            <Filter
              classNamePrefix={SELECT_CLASS_PREFIX}
              options={depots}
              value={filteredDepots}
              onChange={handleFilterDepots}
              isMulti={true}
              placeholder="Depots"
            />
            <Filter
              classNamePrefix={SELECT_CLASS_PREFIX}
              options={returnOptions}
              value={hasReturns}
              onChange={handleFilterReturns}
              placeholder="Has Returns"
            />
            <Filter
              classNamePrefix={SELECT_CLASS_PREFIX}
              options={filterVehicleOptions}
              value={filteredVehicles}
              onChange={handleFilterVehicles}
              isMulti={true}
              placeholder="Vehicle Types"
            />
            <Filter
              classNamePrefix={SELECT_CLASS_PREFIX}
              options={assignmentOptions}
              value={filteredAssignmentOptions}
              onChange={handleFilterAssignmentValidation}
              placeholder="Assignment Validation"
            />
          </Box.Flex>
        </Box.Flex>
      </Box>
    );
  }
}

export { Header };

(function () {
  const app = angular.module('app');

  app.service('LeadObjectionDropdownHelper', [
    'ObjectionsList',
    'LeadStates',
    function (ObjectionsList, LeadStates) {
      const self = this;

      self.doNotShowObjection = function (objection, lead) {
        return !lead || self.doNotShowObjectionByState(objection, lead.state);
      };

      self.doNotShowObjectionByState = function (objection, state) {
        return self.followupScheduledAndClosed(objection, state) || self.followupExpired(objection);
      };

      self.followupScheduledAndClosed = function (objection, state) {
        return objection === ObjectionsList.FOLLOWUP_SCHEDULED && state && state.toLowerCase() === LeadStates.CLOSED;
      };

      self.followupExpired = function (objection) {
        let doNotShow = false;
        if (objection === ObjectionsList.FOLLOWUP_EXPIRED) {
          doNotShow = true;
        }
        return doNotShow;
      };
    },
  ]);
})();

(() => {
  const app = angular.module('app');

  app.service('RegionService', [
    'Region',
    function (Region) {
      this.query = Region.query();
    },
  ]);
})();

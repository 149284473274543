import React from 'react';
import { client } from '@admin/libraries/apollo';

import styled from '@emotion/styled';

import { useEstimationSelectedRoomsQuery } from '@admin/schema';

const StyledTable = styled.div`
  max-width: 800px;
`;

export const Table: React.FC<{ orderID: string; algorithmID: string | null }> = ({ orderID, algorithmID }) => {
  const { data } = useEstimationSelectedRoomsQuery({
    client,
    skip: !algorithmID,
    variables: {
      orderID: orderID,
      algorithmID: algorithmID!,
    },
  });

  const countByCategory: Map<string, number> = new Map();
  const packRequestedByCategory: Map<string, number> = new Map();

  data?.estimationSelectedRooms.forEach((room) => {
    countByCategory.set(room.roomCategory.name, (countByCategory.get(room.roomCategory.name) ?? 0) + 1);

    if (room.packingRequested) {
      packRequestedByCategory.set(
        room.roomCategory.name,
        (packRequestedByCategory.get(room.roomCategory.name) ?? 0) + 1,
      );
    }
  });

  return (
    <>
      <h3 className="text-thin">Room Selection</h3>
      <StyledTable>
        <div className="table-responsive">
          <table className="table table-striped">
            <thead>
              <tr key="header">
                <th className="text-left">Category</th>
                <th className="text-left">Quantity</th>
                <th className="text-left">Packing Requested Quantity</th>
              </tr>
            </thead>
            <tbody>
              {Array.from(countByCategory.keys()).map((categoryName) => (
                <tr key={`room-${categoryName}`}>
                  <th className="text-left">{categoryName}</th>
                  <th className="text-left">{countByCategory.get(categoryName)}</th>
                  <th className="text-left">{packRequestedByCategory.get(categoryName) ?? 0}</th>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </StyledTable>
    </>
  );
};

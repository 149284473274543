import styled from '@emotion/styled';
import React from 'react';

import { SelfStorage__ReservationFragment } from '@admin/schema';
import { Currency, Spacer } from '@shared/components/helpers';
import { SectionHeading } from '../components/section_heading';

const Table = styled.table`
  width: 360px;
  max-width: 100%;
  font-size: 14px;

  th,
  td {
    padding-bottom: 10px;
    text-align: left;
  }
  td {
    padding-left: 36px;
  }
`;

const TableRule = styled.tr`
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  th,
  td {
    padding-bottom: 16px;
  }

  & + tr th,
  & + tr td {
    padding-top: 16px;
  }
`;

export const Quote: React.FC<{
  reservation: SelfStorage__ReservationFragment;
}> = ({ reservation }) => {
  const classification = reservation.classification;
  return (
    <div>
      <SectionHeading>Quote</SectionHeading>
      <Table>
        <tbody>
          <tr>
            <th>Type:</th>
            <td>{reservation.unitType}</td>
          </tr>
          <tr>
            <th>Size:</th>
            <td>
              {classification && `${classification.width}’ x ${classification.length}’ x ${classification.height}’`}
            </td>
          </tr>
          <tr>
            <th>Location:</th>
            <td>{classification && classification.indoors ? 'Indoors' : 'Outdoors'}</td>
          </tr>
          <tr>
            <th>Floor:</th>
            <td>{classification && classification.firstFloor ? 'Ground' : 'Non-Ground'}</td>
          </tr>
          <tr>
            <th>Climate Controlled:</th>
            <td>{classification && classification.climate ? 'Yes' : 'No'}</td>
          </tr>
          <TableRule>
            <th>Obstructed:</th>
            <td>{classification && classification.obstructed ? 'Yes' : 'No'}</td>
          </TableRule>
          <tr>
            <th>Price:</th>
            <td>
              <b>
                {reservation.price && (
                  <>
                    <Currency value={reservation.price.quotedRate} /> / month
                  </>
                )}
              </b>
            </td>
          </tr>
          <tr>
            <th>Promotion:</th>
            <td>{reservation.promotion && reservation.promotion.name}</td>
          </tr>
        </tbody>
      </Table>
      <Spacer height="36px" />
    </div>
  );
};

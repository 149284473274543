import styled from '@emotion/styled';
import * as React from 'react';

import { Alert } from '@shared/components/bootstrap';

import { SelfStorage__UnitGroupFragment } from '@admin/schema';
import { Entry } from './entry';

const Columns = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 6px -6px;
`;

const Column = styled.div`
  padding: 6px;
`;

export const Entries: React.FC<{
  groups: SelfStorage__UnitGroupFragment[];
  selection?: SelfStorage__UnitGroupFragment;
  onSelect(group: SelfStorage__UnitGroupFragment): void;
}> = ({ groups, selection, onSelect }) => {
  if (!groups.length) {
    return <Alert style="info">No units are currently available for this facility.</Alert>;
  }

  return (
    <Columns>
      {groups.map((group, index) => (
        <Column key={index}>
          <Entry group={group} selected={selection === group} onSelect={() => onSelect(group)} />
        </Column>
      ))}
    </Columns>
  );
};

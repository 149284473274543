(function () {
  angular.module('app').factory('SystemPhone', [
    '$resource',
    function ($resource) {
      return $resource(
        '/system_phones/:id.json',
        {
          id: '@id',
        },
        {
          create: {
            method: 'POST',
          },
          index: {
            method: 'GET',
            isArray: true,
          },
          show: {
            method: 'GET',
            isArray: false,
          },
          update: {
            method: 'PUT',
          },
          destroy: {
            method: 'DELETE',
          },
        },
      );
    },
  ]);
})();

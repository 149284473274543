import cn from 'classnames';
import React from 'react';

import { Button, Col, Grid, ListGroup, Row } from '@shared/components/bootstrap';

type IAccordionProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const Toggle: React.FC<{
  collapsed: boolean;
  onToggle(isCollapsed: boolean): void;
}> = ({ collapsed, onToggle, children }) => (
  <ListGroup.Item>
    <Row>
      <Col md={11}>{children}</Col>
      <Col md={1} className="text-right">
        <Button onClick={() => onToggle(!collapsed)}>
          <i className={cn('fa', collapsed ? 'fa-plus' : 'fa-minus')} />
        </Button>
      </Col>
    </Row>
  </ListGroup.Item>
);

const Collapse: React.FC<{ collapsed: boolean }> = ({ collapsed, children }) => {
  if (collapsed) {
    return null;
  }
  return <ListGroup.Item>{children}</ListGroup.Item>;
};

const Accordion: React.FC<IAccordionProps> = ({ children }) => (
  <Grid fluid className="accordion">
    <ListGroup>{children}</ListGroup>
  </Grid>
);

const Combined = Object.assign(Accordion, {
  Toggle,
  Collapse,
});

export { Combined as Accordion };

import React from 'react';
import { accountURL } from '@admin/config/routes';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import { TaskOrderEdit } from './task_order_edit';
import { TaskOrderCreate } from './task_order_create';
import { TaskOrderContextProvider } from './components/task_order_context';

const getAccountID = () => window.location.pathname.match(/accounts\/(\d+)/)?.[1];

export const TaskOrderRouter = () => {
  const accountID = getAccountID();

  if (!accountID) {
    window.location.pathname = '/';
    return null;
  }

  return (
    <TaskOrderContextProvider accountID={accountID}>
      <BrowserRouter>
        <Switch>
          <Route path={'/accounts/:accountID/task_orders/:orderID/edit'} exact>
            <TaskOrderEdit />
          </Route>
          <Route path={'/accounts/:accountID/task_orders/new'} exact>
            <TaskOrderCreate />
          </Route>
          <Route>
            <Redirect to={accountID ? accountURL({ id: accountID }) : '/'} />;
          </Route>
        </Switch>
      </BrowserRouter>
    </TaskOrderContextProvider>
  );
};

import * as React from 'react';
import { useEffect } from 'react';

import { Omit } from '@admin/utils/omit';

const clamp = (min?: number, max?: number, value?: number) => {
  if (value === undefined) {
    return value;
  } else if (min !== undefined && min > value) {
    return min;
  } else if (max !== undefined && max < value) {
    return max;
  } else {
    return value;
  }
};

export const NumberFormControl: React.FC<
  {
    min?: number;
    max?: number;
    value?: number;
    onChange(value?: number): void;
  } & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'min' | 'max' | 'value' | 'onChange'>
> = ({ min, max, value, onChange, ...props }) => {
  useEffect(() => {
    const adjusted = clamp(min, max, value);
    if (adjusted !== value) {
      onChange(adjusted);
    }
  }, [min, max]);

  return (
    <input
      {...props}
      min={min}
      max={max}
      className="form-control"
      type="number"
      value={value === undefined ? '' : value}
      onChange={(event) => {
        const raw = event.target.value;
        const num = Number(raw);
        onChange(!raw.length || Number.isNaN(num) ? undefined : clamp(min, max, num));
      }}
    />
  );
};

angular.module('app').filter(
  'affirmation',
  () =>
    function (input) {
      if (input === false) {
        return 'No';
      } else if (input === true) {
        return 'Yes';
      }
      return 'error';
    },
);

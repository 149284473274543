(function () {
  const app = angular.module('app');

  app.controller('TicketGroupingManageController', [
    '$scope',
    'TicketGrouping',
    'ErrorService',
    function ($scope, TicketGrouping, ErrorService) {
      $scope.assign = function (group) {
        const existing = _.some($scope.form.groups, function (other) {
          return other.id === group.id;
        });
        if (!existing) {
          $scope.form.groups.push(group);
        }
      };

      $scope.unassign = function (group) {
        _.remove($scope.form.groups, group);
      };

      $scope.edit = function (grouping) {
        $scope.selection = grouping;
        $scope.form = angular.copy($scope.selection);
      };

      $scope.save = function () {
        TicketGrouping.save({
          slug: $scope.selection.slug,
          group_ids: _.map($scope.form.groups, function (group) {
            return group.id;
          }),
        }).$promise.then(function () {
          $scope.selection.groups = $scope.form.groups;
          $scope.cancel();
        }, ErrorService.handle);
      };

      $scope.cancel = function () {
        $scope.selection = null;
        $scope.form = null;
      };
    },
  ]);
})();

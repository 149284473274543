import React from 'react';
import { Formik, Form as FormikForm } from 'formik';

import { Status, useFeatureFlagUpsertMutation, FeatureFlagInput } from '@admin/schema';

import { client } from '@admin/libraries/apollo';
import { FormikInputFormGroup } from '@shared/components/fields/formik/formik_input_form_group';
import { Alert, Button, Panel, Modal } from '@shared/components/bootstrap';
import { FormikMultiselectFormGroup } from '../fields/formik/formik_multiselect_form_group';

type IDWithName = {
  id: string;
  name: string;
};

export type FeatureFlagType = {
  id: string;
  name: string;
  description?: string | null;
  resourceName: string;
  whitelistedObjects: IDWithName[];
};

export const UpsertModal: React.FC<{
  featureFlag?: FeatureFlagType;
  regionOptions: IDWithName[];
  userOptions: IDWithName[];
  warehouseOptions: IDWithName[];
  closeModal(): void;
}> = ({ featureFlag, regionOptions, userOptions, warehouseOptions, closeModal }) => {
  const [save, { loading, data }] = useFeatureFlagUpsertMutation({
    client,
    onCompleted: ({ result }) => {
      if (result?.status === Status.Ok) closeModal();
    },
  });

  const onSubmit = (input: FeatureFlagInput) => {
    save({
      variables: {
        id: featureFlag?.id,
        input,
      },
    });
  };

  let OPTIONS: IDWithName[] = [];
  if (featureFlag?.resourceName === 'Region') {
    OPTIONS = regionOptions.map((option) => ({ id: option.id, name: `${option.id} - ${option.name}` }));
  } else if (featureFlag?.resourceName === 'User') {
    OPTIONS = userOptions.map((option) => ({ id: option.id, name: `${option.id} - ${option.name}` }));
  } else if (featureFlag?.resourceName === 'Warehouse') {
    OPTIONS = warehouseOptions.map((option) => ({ id: option.id, name: `${option.id} - ${option.name}` }));
  } else {
    OPTIONS = [];
  }

  const whitelistEditingDisabled = OPTIONS.length === 0;

  return (
    <Modal onClose={closeModal}>
      <Formik<FeatureFlagInput>
        initialValues={{
          name: featureFlag?.name ?? '',
          description: featureFlag?.description ?? '',
          resourceName: featureFlag?.resourceName ?? '',
          whitelistedIDs: featureFlag?.whitelistedObjects.map((object) => object.id) ?? [],
        }}
        onSubmit={onSubmit}
      >
        <FormikForm>
          <Panel>
            <Panel.Body>
              {data?.result?.error && <Alert style="danger">{data.result.error}</Alert>}
              {whitelistEditingDisabled && (
                <Alert style="danger">
                  This feature flag is not editable via Teal. Please submit a tech support ticket with the requested
                  changes.
                </Alert>
              )}
              <FormikInputFormGroup label="Name" name="name" disabled={true} />
              <FormikInputFormGroup label="Resource Name" name="resourceName" disabled={true} />
              <FormikInputFormGroup label="Description" name="description" disabled={false} />
              <FormikMultiselectFormGroup
                label="Whitelist"
                name="whitelistedIDs"
                entries={OPTIONS}
                disabled={whitelistEditingDisabled}
              />
            </Panel.Body>
            <Panel.Footer align="right">
              <Button kind="primary" onClick={closeModal}>
                Cancel
              </Button>
              <Button loading={loading} kind="primary" type="submit" disabled={whitelistEditingDisabled}>
                Save
              </Button>
            </Panel.Footer>
          </Panel>
        </FormikForm>
      </Formik>
    </Modal>
  );
};

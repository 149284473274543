import { colorInterpolatorForIndex } from '@admin/helpers/colors';
import { DateTime } from 'luxon';

import { COLORS } from '@root/colors';

export const KNOWN_EVENT_NAMES = [
  'clockin',
  'navigated',
  'entered',
  'parked',
  'signed_approval',
  'ended',
  'exited_to_facility',
  'facility_arrival',
  'exited',
  'depot_arrival',
  'clockout',
  'canceled',
  'rescheduled',
];

enum Colors {
  Green = 0,
  Orange = 1,
  Purple = 2,
  Pink = 3,
  Yellow = 5,
  Brown = 6,
}

const PROPER_EVENT_NAME_MAP: { [key: string]: string } = {
  'Clock In/Out': 'Clock In',
};

const getProperEventName = (eventName: string) => PROPER_EVENT_NAME_MAP[eventName] || eventName;

const COLOR_EVENTS: { [key: string]: number } = {
  'Clock In': Colors.Brown,
  Drive: Colors.Green,
  Prep: Colors.Orange,
  Park: Colors.Yellow,
  Order: Colors.Purple,
  Depart: Colors.Pink,
  'Return to Depot': Colors.Green,
  'Clock Out': Colors.Brown,
  'Drive to Facility': Colors.Green,
  'Truck Swap': Colors.Yellow,
  Canceled: Colors.Green,
};

export const format = (date: DateTime) => date.toFormat('t');

const breakEventInterpolator = (shade: number) => (shade > 0.5 ? COLORS.grayLight : COLORS.grayLightest);

export const getColorforEvent = (eventName: string) => {
  if (eventName === 'Break') {
    return breakEventInterpolator;
  } else {
    return colorInterpolatorForIndex(COLOR_EVENTS[getProperEventName(eventName)]);
  }
};

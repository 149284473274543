import styled from '@emotion/styled';
import * as React from 'react';

import { Account__ClaimsSummaryFragment } from '@admin/schema';
import { Text } from '@shared/components/bootstrap';
import { Currency } from '@shared/components/helpers';

const SummaryContainer = styled.div`
  width: 300px;
`;

const SummaryRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Row = ({ description, amount }: { description: string; amount: number }) => (
  <SummaryRow>
    <Text>{description}:</Text>
    <Currency value={amount} />
  </SummaryRow>
);

export const Summary: React.FC<{ summary: Account__ClaimsSummaryFragment }> = ({ summary }) => (
  <SummaryContainer>
    <Text tag="p">
      <strong>Latest Protection Plan: {summary.latestProtectionPlan.name}</strong>
    </Text>
    <Row description={'Paid item claims'} amount={summary.paidItemCompensation} />
    <Row description={'Approved item claims'} amount={summary.pendingItemCompensation} />
    <strong>
      <Row description={'Total item compensation'} amount={summary.totalItemCompensation} />
    </strong>
    <Row description={'Paid property claims'} amount={summary.paidPropertyCompensation} />
    <Row description={'Approved property claims'} amount={summary.pendingPropertyCompensation} />
    <strong>
      <Row description={'Total property claims'} amount={summary.totalPropertyCompensation} />
    </strong>
  </SummaryContainer>
);

import { currency } from '@shared/utils/currency';
import { intersection } from 'lodash';
import * as React from 'react';
import { useEffect, useState } from 'react';

import { Alert, Button, InputGroup, Text } from '@shared/components/bootstrap';

import { Panel } from '@admin/components/helpers/panel';
import { Titleize } from '@admin/components/helpers/titleize';
import { Currency } from '@shared/components/helpers';

import { Selector as InvoiceItemKindsSelector } from '@admin/components/billing/invoice_item_kinds/selector';
import { Select as SourcesSelect } from '@admin/components/billing/sources/select';

import {
  Dialog as ConfirmationDialog,
  Response as ConfirmationResponse,
  useConfirmation,
} from '@admin/components/confirmation';

import { CurrencyFormControl } from '@admin/components/fields';
import { FieldFormGroup } from '@admin/components/fields';
import { ROLES } from '@admin/config/roles';
import { UserRole, BillingMethod, BillingMethodSourceType } from '@admin/types';

import { Billing__Source__Kind, Status, useBillingBuildChargeMutation } from '@admin/schema';
import { client } from '@admin/libraries/apollo';
import { SelectProductAndEntity, Product } from './select_product_and_entity';

const Confirm: React.FC<{
  amount: number;
  kind: string;
  source?: BillingMethod;
}> = ({ amount, kind, source }) => (
  <>
    {source?.type === BillingMethodSourceType.BalanceOnly
      ? 'Are you sure you want to create a balance of '
      : 'Are you sure you want to charge customer '}
    <Currency value={amount} /> for "{kind}"?{' '}
    {(() => {
      switch (source?.type) {
        case BillingMethodSourceType.Source:
          return (
            <>
              The charge is being made on the <Titleize text={source.brand || source.kind} /> ····{source.number}.
            </>
          );
        case BillingMethodSourceType.Other:
          return <>This charge is being recorded using a {source.kind && <Titleize text={source.kind} />} payment.</>;
        case BillingMethodSourceType.BalanceOnly:
          return '';
        default:
          return <>This charge won't be processed until a payment method is assigned to the account.</>;
      }
    })()}
  </>
);

const PREPAYMENT = 'Prepayment';
const FIRST_MONTH_PREPAY = 'First Month Prepay';

export const ChargeForm: React.FC<{
  accountID: string;
  initialKind?: string;
  onCancel(): void;
  onSave(description: string): void;
}> = ({ accountID, initialKind, onCancel, onSave }) => {
  const [error, setError] = useState<string | undefined>();
  const [product, setProduct] = useState<Product | undefined>();
  const [entityValue, setEntityValue] = useState<string | undefined>();
  const [entityKind, setEntityKind] = useState<string | undefined>();
  const [amount, setAmount] = useState<number | undefined>();
  const [kind, setKind] = useState<string | undefined>(initialKind);
  const [note, setNote] = useState<string | undefined>();
  const [source, setSource] = useState<BillingMethod | undefined>();
  const [save, { loading }] = useBillingBuildChargeMutation({ client });
  const { confirmation, confirm } = useConfirmation();

  useEffect(() => {
    if (kind !== PREPAYMENT && source?.type === BillingMethodSourceType.Other) {
      setSource(undefined);
    }
  }, [kind, source]);

  const disabled =
    !product ||
    !amount ||
    !note ||
    !kind ||
    (source?.type === BillingMethodSourceType.Other && !source.kind) ||
    (product === Product.SelfStorage && !entityValue);

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();

    if (loading || !amount || !note || !kind || !product) {
      return;
    }

    if (amount > 10000) {
      setError('Cannot charge over $10,000!');
      return;
    }

    if (source?.type === BillingMethodSourceType.Source) {
      if (kind === PREPAYMENT && source.kind === Billing__Source__Kind.BankAccount) {
        setError('Cannot prepay with a bank account!');
        return;
      }
    }

    if (source?.type === BillingMethodSourceType.Other) {
      if (!source.kind) {
        setError('Select a method for ACH / Cash / Check / etc.');
        return;
      }
    }

    const confirmed = await confirm({
      title: `${source?.type === BillingMethodSourceType.BalanceOnly ? 'Create Balance?' : 'Charge Customer?'}`,
      description: <Confirm amount={amount} kind={kind} source={source} />,
    });
    if (confirmed !== ConfirmationResponse.Confirm) return;

    const response = await save({
      variables: {
        input: {
          amount,
          kind,
          note,
          accountID,
          product,
          [entityKind!]: entityValue,
          sourceID: source && source.type === BillingMethodSourceType.Source ? source.id : undefined,
          autoAdvance: source?.type !== BillingMethodSourceType.BalanceOnly,
          source:
            source && source.type === BillingMethodSourceType.Other && source.kind
              ? {
                  kind: source.kind,
                  transactionNumber: source.transactionNumber,
                }
              : undefined,
        },
      },
    });
    if (!response) {
      return;
    }

    setError(response.data?.result.error ?? undefined);
    if (response.data?.result.status === Status.Ok) {
      onSave(`${currency(amount)} for ${kind}`);
    }
  };

  return (
    <>
      {confirmation && <ConfirmationDialog confirmation={confirmation} />}
      <form role="form" onSubmit={onSubmit}>
        <Panel>
          <Panel.Header>
            <Panel.Title>New Invoice</Panel.Title>
          </Panel.Header>
          <Panel.Body>
            {error && (
              <Alert style="danger" onClose={() => setError(undefined)}>
                {error}
              </Alert>
            )}
            <SelectProductAndEntity
              accountID={accountID}
              onSelectProduct={setProduct}
              onSelectEntity={(updatedEntityKind, updatedEntityValue) => {
                setEntityKind(updatedEntityKind);
                setEntityValue(updatedEntityValue);
              }}
              product={product}
              entityValue={entityValue}
            />
            <FieldFormGroup label="Type:" id="charge_kind">
              <InvoiceItemKindsSelector name="charge_kind" accountID={accountID} kind={kind} onChange={setKind} />
            </FieldFormGroup>
            <FieldFormGroup label="Amount:" id="charge_amount">
              <InputGroup>
                <InputGroup.Addon>$</InputGroup.Addon>
                <CurrencyFormControl name="charge_amount" amount={amount} onChange={setAmount} />
              </InputGroup>
            </FieldFormGroup>
            <FieldFormGroup label="Notes:" id="charge_notes">
              <textarea
                name="charge_notes"
                className="form-control"
                placeholder="Describe the situation"
                value={note}
                onChange={(event) => setNote(event.target.value || undefined)}
              />
            </FieldFormGroup>
            <SourcesSelect
              id="charge_source_id"
              source={source}
              setSource={setSource}
              accountID={accountID}
              showOtherSource={
                kind === PREPAYMENT &&
                intersection(ROLES, [UserRole.Collections, UserRole.SelfStorageAssociate]).length > 0
              }
              showCreateBalance={
                kind !== PREPAYMENT &&
                kind !== FIRST_MONTH_PREPAY &&
                intersection(ROLES, [UserRole.Collections]).length > 0
              }
            />
          </Panel.Body>
          <Panel.Footer>
            <Text alignment="right" tag="div">
              <Button kind="danger" disabled={loading} onClick={onCancel}>
                Cancel
              </Button>{' '}
              <Button kind="primary" type="submit" loading={loading} disabled={disabled}>
                Charge
              </Button>
            </Text>
          </Panel.Footer>
        </Panel>
      </form>
    </>
  );
};

(() => {
  const app = angular.module('app');

  app.component('characterCounter', {
    templateUrl: 'partials/components/character_counter.html',
    bindings: {
      characterCount: '<',
      characterLimit: '<',
    },
  });
})();

const app = angular.module('app');

app.directive('invoiceActions', () => ({
  restrict: 'E',
  templateUrl: 'partials/invoices/invoice_actions.html',
  scope: {
    invoice: '=?',
  },
  controller: [
    '$scope',
    '$rootScope',
    'ngDialog',
    'Invoice',
    function InvoiceActionsController($scope, $rootScope, ngDialog, Invoice) {
      $scope.lastCharge = function (invoice) {
        return _.find(invoice.charges, (charge) => charge.stripe_id === invoice.last_stripe_charge_id);
      };

      $scope.invoiceDialog = function (invoice, template, controllerName, actionFunction) {
        ngDialog.openConfirm({
          template,
          controllerAs: controllerName,
          controller: [
            '$scope',
            'Invoice',
            '$timeout',
            // eslint-disable-next-line no-shadow
            function ($scope, Invoice, $timeout) {
              let pollPromise;
              const self = this;
              self.loading = false;
              self.errors = null;
              self.invoice = invoice;
              self.params = {};
              self.params.amountToPay = invoice.amount_owed;
              self.params.date = null;
              self.params.notes = null;
              self.doStop = false;

              self.closeModal = function () {
                self.doStop = true;
                $scope.confirm();
              };

              $scope.$on('destroy', () => {
                if (pollPromise) {
                  $timeout.cancel(pollPromise);
                }
              });

              self.deferredBalance = function () {
                return self.invoice.amount_owed - self.params.amountToPay;
              };

              self.dateRequired = function () {
                if (self.deferredBalance() > 0) {
                  return !(self.params.date === null || self.params.date === undefined);
                }
                return true;
              };

              self.canSubmit = function () {
                return self.deferredBalance() >= 0 && self.dateRequired() && self.params.notes && !self.loading;
              };

              // eslint-disable-next-line no-shadow
              self.invoiceAction = function (invoice) {
                self.loading = true;
                self.errors = null;

                actionFunction
                  .action(self.params)
                  .$promise.then((response) => {
                    const accountID = invoice.account_id;
                    const attemptID = response.attempt_id;

                    const poll = function () {
                      Invoice.find({
                        account_id: accountID,
                        attempt_id: attemptID,
                      })
                        .$promise.then((updatedInvoice) => {
                          if (updatedInvoice.message) {
                            self.errors = updatedInvoice.message;
                            self.loading = false;
                          } else {
                            angular.copy(updatedInvoice, invoice);
                            $rootScope.$broadcast('message:display', {
                              type: 'info',
                              body: actionFunction.message(),
                            });
                            self.closeModal(response);
                          }
                        })
                        .catch(() => {
                          if (!self.doStop) {
                            pollPromise = $timeout(poll, 5000);
                          }
                        });
                    };

                    poll();
                  })
                  .catch((response) => {
                    self.errors = response.data.message;
                  });
              };
            },
          ],
          resolve: {
            invoice() {
              return invoice;
            },
          },
        });
      };

      $scope.payInvoice = function (invoice) {
        const actionFunction = {
          action(params) {
            return Invoice.pay({
              id: invoice.id,
              account_id: invoice.account_id,
              amount: params.amountToPay,
              notes: params.notes,
              date: params.date,
            });
          },
          message() {
            return 'Invoice Successfully paid!';
          },
        };

        $scope.invoiceDialog(invoice, 'partials/invoices/pay_modal.html', 'payModal', actionFunction);
      };

      $scope.refetchAndBroadcast = function (attemptID, message, refetchAll) {
        const invoice = $scope.invoice;
        Invoice.find({ account_id: invoice.account_id, attempt_id: attemptID }).$promise.then((response) => {
          angular.copy(response, invoice);
          $rootScope.$broadcast('message:display', {
            type: 'info',
            body: message,
          });
        });
        // For re-bill, a new invoice is created so we need to refetch all invoices
        if (refetchAll) {
          $rootScope.$broadcast('account:refresh');
        }
      };

      $scope.expand = function (invoice) {
        // eslint-disable-next-line no-param-reassign
        invoice.open = true;
      };
    },
  ],
}));

import angular from 'angular';

import DEPOT from '@admin/images/maps/markers/depot.svg';
import PICKUP from '@admin/images/maps/markers/pickup.svg';
import RETURN from '@admin/images/maps/markers/return.svg';
import WAREHOUSE from '@admin/images/maps/markers/warehouse.svg';
import VEHICLE from '@admin/images/maps/markers/vehicle.svg';
import PROGRESS from '@admin/images/maps/markers/progress.svg';

(function () {
  const app = angular.module('app');

  app.controller('DispatchMapController', [
    '$scope',
    '$interval',
    'Checkin',
    'DispatchMap',
    'GoogleMapStyleConstants',
    '$q',
    '$filter',
    'uiGmapGoogleMapApi',
    function ($scope, $interval, Checkin, DispatchMap, GoogleMapStyleConstants, $q, $filter, uiGmapGoogleMapApi) {
      $scope.dispatchMap = {
        center: {
          latitude: GoogleMapStyleConstants.DEFAULT_LATITUDE,
          longitude: GoogleMapStyleConstants.DEFAULT_LONGITUDE,
        },
        styles: GoogleMapStyleConstants.STYLES,
        zoom: 11,
        markers: [],
        polylines: [],
        polylineLatestIds: [],
      };

      $scope.$on('dispatch:loaded', function () {
        DispatchMap.get({ dispatch_id: $scope.dispatch.id }).$promise.then(function (mapInfo) {
          $scope.dispatchMap.info = mapInfo;
          uiGmapGoogleMapApi.then(function () {
            $scope.drawMap();
          });
        });
      });

      $scope.drawMap = function () {
        $scope.dispatchMap.markers = $scope.generateStaticMarkers();
        const date = $scope.dispatch.arrival;

        const promises = $scope.dispatch.vehicles.map(function (vehicle) {
          return $scope.getCheckins('vehicle', vehicle.id, date).$promise;
        });

        $q.all(promises).then(function (data) {
          const vehiclePointsCount = data.reduce(function (acc, points) {
            return points.length + acc;
          }, 0);

          if (vehiclePointsCount > 3) {
            $scope.dispatch.vehicles.forEach(function (vehicle, index) {
              const polylineColor =
                GoogleMapStyleConstants.VEHICLE_PATH_COLORS[index % GoogleMapStyleConstants.VEHICLE_PATH_COLORS.length];
              $scope.drawVehiclePath(date, vehicle, polylineColor);
            });
          } else {
            $scope.drawLeadPath(date);
          }
        });
      };

      $scope.generateStaticMarkers = function () {
        const markers = [];

        const depot = $scope.dispatchMap.info.depot;
        markers.push(marker('depot', depot.id, depot.address, [depot.name], { url: DEPOT }));

        const warehouse = $scope.dispatchMap.info.warehouse;
        markers.push(marker('warehouse', warehouse.id, warehouse.address, [warehouse.name], { url: WAREHOUSE }));

        $scope.dispatch.orders.forEach(function (order) {
          const icon = order.type === 'pickup' ? { url: PICKUP } : { url: RETURN };
          const notes = [
            order.account.customer.name + ' (#' + order.number + ')',
            'Scheduled Time: ' + $filter('timeLocale')(order.scheduled),
          ];

          notes.push([order.address.street, order.address.aptsuite].join(', '));
          markers.push(marker('order', order.id, order.address, notes, icon));
        });

        if (markers.length > 0) {
          $scope.dispatchMap.center = {
            latitude:
              _.sum(
                _.map(markers, function (m) {
                  return m.coordinates.latitude;
                }),
              ) / markers.length,
            longitude:
              _.sum(
                _.map(markers, function (m) {
                  return m.coordinates.longitude;
                }),
              ) / markers.length,
          };
        }

        return markers;
      };

      $scope.updateMarker = function (type, id, existingMarker) {
        const index = _.findIndex($scope.dispatchMap.markers, { type: type, id: id });

        if (index === -1) {
          $scope.dispatchMap.markers.push(existingMarker);
        } else {
          $scope.dispatchMap.markers[index] = existingMarker;
        }
      };

      $scope.drawPolylines = function (id, coordinates, strokeOptions) {
        strokeOptions = strokeOptions || {};

        const lastCheckin = _.last(coordinates);
        const lastCheckinId = lastCheckin ? lastCheckin.id : null;
        const index = _.findIndex($scope.dispatchMap.polylines, { id: id });
        const polyLine = {
          id: id,
          coordinates: coordinates,
          stroke: {
            color: strokeOptions.color || '#2ECC71',
            weight: strokeOptions.weight || 2.0,
            opacity: strokeOptions.opacity || 0.8,
          },
        };

        if (index === -1) {
          $scope.dispatchMap.polylines.push(polyLine);
          $scope.dispatchMap.polylineLatestIds.push(lastCheckinId);
        } else {
          // need to perform array copy. If you replace the coordinates array with a new object,
          // the map will not update
          angular.copy(coordinates, $scope.dispatchMap.polylines[index].coordinates);
          $scope.dispatchMap.polylineLatestIds[index] = lastCheckinId;
        }
      };

      $scope.drawLeadPath = function (date) {
        const type = 'user';
        const user = _.find($scope.dispatch.assignments, function (assignment) {
          return assignment.role === 'lead';
        }).user;

        const refreshLeadUser = function () {
          const polylineId = 'user:' + user.id + ':polyline';
          const index = _.findIndex($scope.dispatchMap.polylines, { id: polylineId });
          const sinceId = index === -1 ? null : $scope.dispatchMap.polylineLatestIds[index];

          $scope.getCheckins(type, user.id, date, sinceId).$promise.then(function (points) {
            const lastCheckin = points[points.length - 1];
            if (lastCheckin) {
              const icon = { url: PROGRESS, size: new google.maps.Size(20, 28) };

              $scope.updateMarker(type, user.id, marker(type, user.id, lastCheckin, ['Lead', user.name], icon));
            }

            const coordinates = index === -1 ? [] : $scope.dispatchMap.polylines[index].coordinates;
            const newCoords = coordinates.concat(
              _.map(points, function (point) {
                return { latitude: point.latitude, longitude: point.longitude, id: point.id };
              }),
            );

            $scope.drawPolylines(polylineId, newCoords, { color: '#F39C12' });
          });
        };

        const intervalPromise = $interval(refreshLeadUser, 1000 * window.clutter.dispatchMapPollingInterval);
        $scope.$on('$destroy', function () {
          $interval.cancel(intervalPromise);
        });

        refreshLeadUser();
      };

      $scope.drawVehiclePath = function (date, vehicle, polylineColor) {
        const type = 'vehicle';

        const refreshVehicle = function () {
          const polylineId = 'vehicle:' + vehicle.id + ':polyline';
          const index = _.findIndex($scope.dispatchMap.polylines, { id: polylineId });
          const sinceId = index === -1 ? null : $scope.dispatchMap.polylineLatestIds[index];

          $scope.getCheckins(type, vehicle.id, date, sinceId).$promise.then(function (points) {
            const lastCheckin = points[points.length - 1];

            if (lastCheckin) {
              const icon = { url: VEHICLE, size: new google.maps.Size(24, 20) };

              $scope.updateMarker(type, vehicle.id, marker(type, vehicle.id, lastCheckin, [vehicle.name], icon));
            }

            const coordinates = index === -1 ? [] : $scope.dispatchMap.polylines[index].coordinates;
            const newCoords = coordinates.concat(
              _.map(points, function (point) {
                return { latitude: point.latitude, longitude: point.longitude, id: point.id };
              }),
            );
            $scope.drawPolylines(polylineId, newCoords, { color: polylineColor });
          });
        };

        const intervalPromise = $interval(refreshVehicle, 1000 * window.clutter.dispatchMapPollingInterval);
        $scope.$on('$destroy', function () {
          $interval.cancel(intervalPromise);
        });

        refreshVehicle();
      };

      $scope.getCheckins = function (type, id, date, sinceId) {
        const parameters = {
          resource_id: id,
          resource_type: type + 's',
          date: moment(date).format('YYYY-MM-DD'),
        };

        if (sinceId) {
          parameters.since_id = sinceId;
        }

        return Checkin.query(parameters);
      };

      function marker(type, id, coordinates, notes, icon) {
        return {
          type: type,
          id: id,
          notes: notes.join('\n'),
          coordinates: {
            latitude: Number(coordinates.latitude),
            longitude: Number(coordinates.longitude),
          },
          icon: {
            url: icon.url,
            scaledSize: icon.size || new google.maps.Size(24, 32),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(12, 32),
          },
        };
      }
    },
  ]);
})();

import React, { useState } from 'react';
import { Label } from '@admin/components/helpers/label';
import { titleize } from '@admin/components/helpers/titleize';
import { Pagination } from '@admin/components/pagination';
import { AttestationEventReviewModal } from '@admin/components/shifts/attestation/attestation_event_review_modal';
import { Spinner } from '@admin/components/spinner';
import { client } from '@admin/libraries/apollo';
import { AttestationEventFragment, TimeClockPlusEnum, useAttestationEventsPaginatedQuery } from '@admin/schema';
import { Button, Table } from '@shared/components/bootstrap';
import { useDebounce } from '@shared/hooks';
import { formatDate } from '@shared/utils';

const formatTimeClockPlus = (timeClockPlus?: string) => {
  switch (timeClockPlus) {
    case 'enabled': {
      return TimeClockPlusEnum.Enabled;
    }
    case 'disabled': {
      return TimeClockPlusEnum.Disabled;
    }
    default: {
      return undefined;
    }
  }
};

export const AttestationIssuesTable: React.FC<{
  filters: {
    from?: Date;
    till?: Date;
    mode?: string;
    team_id?: string;
    time_clock_plus?: string;
    query?: string;
  };
}> = ({ filters }) => {
  const [page, setPage] = useState<number | undefined>();

  const { data, loading } = useAttestationEventsPaginatedQuery({
    client,
    variables: {
      filters: {
        from: filters.from?.toISOString(),
        till: filters.till?.toISOString(),
        teamId: filters.team_id,
        timeClockPlus: formatTimeClockPlus(filters.time_clock_plus),
        query: useDebounce(filters.query),
      },
      page,
    },
  });
  const paginated = data?.paginated;
  const [selectedEvent, setSelectedEvent] = useState<AttestationEventFragment>();

  return (
    <>
      <h3>Attestation Issues</h3>
      <Table responsive data-test-id="attestation-issues-table">
        <thead>
          <tr>
            <th className="col-sm-2">Name</th>
            <th className="col-sm-2">Type</th>
            <th className="col-sm-2">Shift Date</th>
            <th className="col-sm-2">Due Date</th>
            <th className="col-sm-2">State</th>
            <th className="col-sm-2"></th>
          </tr>
        </thead>
        <tbody>
          {paginated?.results.map((attestationEvent, key) => {
            const reviewed = !!attestationEvent.reviewedAt;
            return (
              <tr key={key}>
                <td className="col-sm-2">
                  <div>{attestationEvent.shift.user.name}</div>
                </td>
                <td className="col-sm-2">
                  <div>{titleize(attestationEvent.kind)}</div>
                </td>
                <td className="col-sm-2">
                  <div>{formatDate(attestationEvent.shift.date)}</div>
                </td>
                <td className="col-sm-2">
                  <div>{formatDate(attestationEvent.reviewDueDate)}</div>
                </td>
                <td className="col-sm-2">
                  <Label kind={reviewed ? 'default' : 'primary'}>{reviewed ? 'Resolved' : 'Action Required'}</Label>
                </td>
                <td className="col-sm-2">
                  {!reviewed && (
                    <Button kind="default" onClick={() => setSelectedEvent(attestationEvent)}>
                      Review
                    </Button>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      {!loading && !paginated?.results && (
        <div className="text-center">No Attestation Issues Found Matching Criteria</div>
      )}
      {selectedEvent && (
        <AttestationEventReviewModal event={selectedEvent} onClose={() => setSelectedEvent(undefined)} />
      )}
      {loading && <Spinner />}
      {paginated && <Pagination pagination={paginated.pagination} onPage={setPage} />}
    </>
  );
};

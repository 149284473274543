import { isEmpty } from 'lodash';
import { Account__UpdatePricingInput, PriceMatch__Kind } from '@admin/schema';
import { Dimensions, Errors } from './edit';

const PRICE_ERROR = 'Please enter a positive price.';
const NO_PRICE_MATCH_KIND_ERROR = 'Please select a custom subscription reason.';
const LENGTH_WIDTH_ERROR = 'Please enter a positive length and width.';
const HEIGHT_ERROR = 'Please enter a positive height or leave it blank.';

const isValidNumber = (num: number | undefined): boolean => num !== undefined && num > 0;

export const validate = (
  input: {
    promoCode?: string;
    couponID?: string;
    price?: number;
    storagePricingID?: string;
    protectionPricingID?: string;
    priceMatchKind?: PriceMatch__Kind;
    dimensions?: Dimensions;
  },
  isCustom: boolean,
): { errors: Errors; valid: false } | { input: Account__UpdatePricingInput; valid: true } => {
  const { price, storagePricingID, priceMatchKind, dimensions } = input;

  const validationErrors: Errors = {};

  if (storagePricingID && !isValidNumber(price)) {
    validationErrors.price = PRICE_ERROR;
  }

  if (isCustom) {
    if (!priceMatchKind) {
      validationErrors.storagePricing = NO_PRICE_MATCH_KIND_ERROR;
    }
    if (!isValidNumber(dimensions?.length) || !isValidNumber(dimensions?.width)) {
      if (validationErrors.storagePricing) {
        validationErrors.storagePricing += ` ${LENGTH_WIDTH_ERROR}`;
      } else {
        validationErrors.storagePricing = LENGTH_WIDTH_ERROR;
      }
    }
    if (dimensions?.height !== undefined && dimensions?.height !== null && dimensions.height <= 0) {
      if (validationErrors.storagePricing) {
        validationErrors.storagePricing += ` ${HEIGHT_ERROR}`;
      } else {
        validationErrors.storagePricing = HEIGHT_ERROR;
      }
    }
  }

  if (!isEmpty(validationErrors)) {
    return { valid: false, errors: validationErrors };
  } else {
    return { valid: true, input: input as Account__UpdatePricingInput };
  }
};

import { useField } from 'formik';
import React from 'react';

import { FieldFormGroupProps } from '@admin/components/fields';
import { FormikFieldFormGroup } from '@shared/components/fields/formik/formik_field_form_group';
import { Radio } from '@clutter/clean';

export const FormikRadioFormGroup: React.FC<
  Omit<FieldFormGroupProps, 'has'> & { name: string; options: Array<{ value: any; label: React.ReactNode }> }
> = ({ help, label, options, ...input }) => {
  const [_, { value }, { setValue }] = useField(input.name);

  return (
    <FormikFieldFormGroup name={input.name} help={help} label={label} id={input.id}>
      <Radio.Selector name={input.name} onChange={setValue} value={value} options={options} />
    </FormikFieldFormGroup>
  );
};

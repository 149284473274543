import * as React from 'react';

import { useField } from 'formik';
import { FieldFormGroup, FieldFormGroupProps } from '@shared/components/fields/field_form_group';

type Props = {
  name: string;
} & Omit<FieldFormGroupProps, 'has'>;

export const FormikFieldFormGroup: React.FC<Props> = ({ help, name, ...props }) => {
  const [_, { error, touched }] = useField(name);
  return (
    <FieldFormGroup {...props} has={touched && error ? 'error' : undefined} help={touched ? error ?? help : help} />
  );
};

import cn from 'classnames';
import * as React from 'react';

interface ILabelProps {
  kind?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark' | 'muted' | 'default';
  className?: string;
  style?: React.CSSProperties;
}

export const Label: React.FC<ILabelProps> = ({ children, kind, className, style }) => (
  <span className={cn(className, 'label', kind && `label-${kind}`)} style={style}>
    {children}
  </span>
);

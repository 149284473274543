(function () {
  const app = angular.module('app');

  app.directive('hideForRole', [
    'UserService',
    function (UserService) {
      return {
        restrict: 'A',
        link: function (scope, element, attributes) {
          const roles = attributes.hideForRole.split(' ');
          const matched = _.intersection(roles, UserService.roles).length > 0;
          element[matched ? 'addClass' : 'removeClass']('ng-hide');
        },
      };
    },
  ]);
})();

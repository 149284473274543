const jquery = require('jquery');

const selfClosingTagRegex =
  /<(?!area|br|col|embed|hr|img|input|link|meta|param)(([a-z][^\/\0>\x20\t\r\n\f]*)[^>]*)\/>/gi;
jquery.htmlPrefilter = function (html) {
  return html.replace(selfClosingTagRegex, '<$1></$2>');
};

window.jQuery = jquery;
window.$ = jquery;
window.jquery = jquery;
const angular = require('angular');

window.angular = angular;
const moment = require('moment');

window.moment = moment;
require('moment-timezone');
const lodash = require('lodash');

window._ = lodash;
window.humanizeDuration = require('humanize-duration');

import { camelCase, startCase } from 'lodash';

import { Availability__GlobalUnavailabilityReasonEnum } from '@admin/schema';

export const formatReasonAsString = (reason: Availability__GlobalUnavailabilityReasonEnum) =>
  startCase(camelCase(reason));

export const REASON_OPTIONS = Object.values(Availability__GlobalUnavailabilityReasonEnum).map((val) => ({
  id: val,
  name: formatReasonAsString(val),
}));

export const formatPercentage = (value: number) => `${(value * 100).toFixed(2)}%`;

import React from 'react';

import { Customer } from '@admin/schema';

export const CustomerNames: React.FC<{
  account: {
    customer: Pick<Customer, 'id' | 'name'>;
    customers: ReadonlyArray<Pick<Customer, 'id' | 'name'>>;
  };
}> = ({ account }) => {
  const customers = [account.customer, ...account.customers.filter(({ id }) => id !== account.customer.id)];
  const names = customers.map(({ name }) => name).join(', ');
  return <>{names}</>;
};

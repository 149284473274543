import { capitalize } from 'lodash';
import React from 'react';

import { Claim__AssessmentFragment, Claim__ResolutionKind, Maybe } from '@admin/schema';
import { Currency } from '@shared/components/helpers';

import { DISPLAYABLE_CLASSIFICATION } from './contants';

export const AssessmentSummary: React.FC<{
  assessment?: Maybe<Claim__AssessmentFragment>;
  kind?: Maybe<Claim__ResolutionKind>;
  reason?: Maybe<string>;
  showWeight?: boolean;
}> = ({ assessment, kind, reason, showWeight = false }) => {
  if (!kind) {
    return null;
  }

  return (
    <>
      {kind === Claim__ResolutionKind.Compensated && assessment && (
        <>
          <Currency value={assessment.verifiedValue} /> | {showWeight && <>{assessment.weight} lbs | </>}
          {DISPLAYABLE_CLASSIFICATION[assessment.classification]}
        </>
      )}
      {kind === Claim__ResolutionKind.Denied && (
        <>
          {capitalize(Claim__ResolutionKind.Denied)} - {reason}
        </>
      )}
    </>
  );
};

import { array } from '../utils';

const SET = 'regions/SET';
const CONCAT = 'regions/CONCAT';

export const setRegions = (data) => ({
  type: SET,
  data,
  unique: true,
});

export const concatRegions = (data) => ({
  type: CONCAT,
  data,
  unique: true,
});

export default array({
  SET,
  CONCAT,
});

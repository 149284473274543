import React from 'react';
import { FormikErrors } from 'formik';

import { FormikInputFormGroup } from '@shared/components/fields/formik/formik_input_form_group';

type CustomerFieldType = { name: string; phone: string; email: string };

export const validateCustomerFields = ({ name, phone, email }: CustomerFieldType): FormikErrors<CustomerFieldType> => {
  const formErrors: FormikErrors<CustomerFieldType> = {};

  if (!name) {
    formErrors.name = 'A name is required';
  }
  if (!email || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
    formErrors.email = 'A valid email is required';
  }
  if (!phone) {
    formErrors.phone = 'A valid phone number is required';
  }

  return formErrors;
};

export const CustomerFields: React.FC = () => (
  <>
    <div className="col-sm-4">
      <FormikInputFormGroup id="name" name="name" label="Name" required={true} />
    </div>
    <div className="col-sm-4">
      <FormikInputFormGroup id="email" name="email" label="Email" required={true} />
    </div>
    <div className="col-sm-4">
      <FormikInputFormGroup id="phone" name="phone" label="Phone" required={true} />
    </div>
  </>
);

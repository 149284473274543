import { capitalize } from 'lodash';
import React from 'react';

import { Thumbnails } from '@admin/components/helpers/thumbnails';
import { Claim__ItemSelectionFragment, Claim__ItemSelectionKind } from '@admin/schema';
import { ListGroup } from '@shared/components/bootstrap';
import { Currency } from '@shared/components/helpers';

import { AssessmentInputs } from './assessment_inputs';
import { AssessmentEditInput, AssessmentEdits } from './helpers';
import { ItemLink } from '../item_link';

const Selection: React.FC<{
  accountID: string;
  selection: Claim__ItemSelectionFragment;
  edits: AssessmentEdits;
  setEdits(edit: AssessmentEdits): void;
}> = ({ accountID, selection, edits, setEdits }) => {
  const { id, item, name, photos, receipts, kind, productURL, declaration, assessment } = selection;

  const updateAssessment = (changes: AssessmentEditInput) => {
    const newChanges = { ...edits[id], ...changes };
    setEdits({ ...edits, [id]: newChanges });
  };

  const disableClassification = kind === Claim__ItemSelectionKind.Lost;

  return (
    <div className="row">
      <div className="col-sm-2">
        <ItemLink accountID={accountID} itemID={item?.id} itemName={name ?? undefined} />
      </div>
      <div className="col-sm-1">{capitalize(kind)}</div>
      <div className="col-sm-1">
        <Thumbnails attachments={photos.concat(receipts)} condensed />
      </div>
      <div className="col-sm-1">
        <a className="btn-link text-overflow" href={productURL ?? undefined} target="_blank">
          {productURL}
        </a>
      </div>
      <div className="col-sm-1">
        <Currency value={Number(declaration || 0)} />
      </div>
      <AssessmentInputs
        assessment={assessment ?? undefined}
        disableClassification={disableClassification}
        edits={edits[id]}
        setEdits={updateAssessment}
      />
    </div>
  );
};

export const ItemSelections: React.FC<{
  accountID: string;
  selections: Claim__ItemSelectionFragment[];
  edits: AssessmentEdits;
  setEdits(edit: AssessmentEdits): void;
}> = ({ accountID, selections, edits, setEdits }) => (
  <>
    <ListGroup.Item>
      <div className="row">
        <div className="col-sm-2">
          <strong>Item</strong>
        </div>
        <div className="col-sm-1">
          <strong>Type</strong>
        </div>
        <div className="col-sm-1">
          <strong>Images</strong>
        </div>
        <div className="col-sm-1">
          <strong>URL</strong>
        </div>
        <div className="col-sm-1">
          <strong>Declared</strong>
        </div>
        <div className="col-sm-2">
          <strong>Verified value</strong>
        </div>
        <div className="col-sm-2">
          <strong>Weight (lbs)</strong>
        </div>
        <div className="col-sm-2">
          <strong>Classification</strong>
        </div>
      </div>
    </ListGroup.Item>
    {selections.map((selection) => (
      <ListGroup.Item key={selection.id}>
        <Selection accountID={accountID} selection={selection} edits={edits} setEdits={setEdits} />
      </ListGroup.Item>
    ))}
  </>
);

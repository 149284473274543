import styled from '@emotion/styled';
import { LogisticsSkusForAccountQuery } from '@admin/schema';
import { Button } from '@shared/components/bootstrap';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { SingleSelectFormControl } from '@admin/components/fields/single_select_form_control';
import { Field, StyledRow } from './field';

export const RemoveButton = styled(Button)`
  margin: 5px 0px 0px;
`;

type Sku = LogisticsSkusForAccountQuery['logisticsSkus'][number];

export const FulfillmentExpectationFields: React.FC<{
  skus: Sku[];
  item: any;
  index: number;
  removeFulfillmentExpectation(index: number): void;
  initialSkuID: string | undefined;
}> = ({ skus, item, index, removeFulfillmentExpectation, initialSkuID }) => {
  const [skuID, setSkuID] = useState<string>(initialSkuID || '');
  const { errors, register } = useFormContext<{
    fulfillmentExpectations?: Array<{
      skuID: string;
      quantity: number;
    }>;
  }>();

  const skuOptions = skus.map((sku) => ({ value: sku.id, label: sku.value }));

  return (
    <StyledRow className="row">
      <input
        type="hidden"
        id={`fe_id_${index}_hidden`}
        defaultValue={item.fulfillmentExpectationID}
        name={`fulfillmentExpectations[${index}].fulfillmentExpectationID`}
        ref={register()}
        disabled={true}
      />
      <div className="col-md-5">
        <Field error={errors.fulfillmentExpectations} id={`sku_id_${index}_hidden`} label="SKU Number">
          <SingleSelectFormControl
            id={`sku_id_${index}`}
            value={skuID}
            onChange={(value) => value && setSkuID(value)}
            options={skuOptions}
          />
          <input
            type="hidden"
            id={`sku_id_${index}_hidden`}
            name={`fulfillmentExpectations[${index}].skuID`}
            ref={register()}
            defaultValue={skuID}
          />
        </Field>
      </div>
      <div className="col-md-5">
        <Field error={errors.fulfillmentExpectations} id={`quantity_${index}`} label="Quantity" required={true}>
          <input
            id={`quantity_${index}`}
            type="number"
            className="form-control"
            defaultValue={item.quantity}
            name={`fulfillmentExpectations[${index}].quantity`}
            ref={register()}
          />
        </Field>
      </div>
      <div className="col-md-2">
        <RemoveButton kind="danger" onClick={() => removeFulfillmentExpectation(index)}>
          Delete
        </RemoveButton>
      </div>
    </StyledRow>
  );
};

(function () {
  angular.module('app').service('TransformBuilder', [
    '$http',
    function ($http) {
      const self = this;

      function guarded(fn) {
        return function (data) {
          return data && typeof data === 'object' ? fn(data) : data;
        };
      }

      function guardedTransformers(transformers) {
        return _.map(transformers, guarded);
      }

      self.transformResponse = function (...args) {
        return $http.defaults.transformResponse.concat(guardedTransformers(args));
      };

      self.transformRequest = function (...args) {
        return guardedTransformers(args).concat($http.defaults.transformRequest);
      };
    },
  ]);
})();

import React from 'react';

import styled from '@emotion/styled';
import { DateTime, Duration, Interval } from 'luxon';

import { useTimeZone, useScale } from './context';

const format = (date: DateTime) => date.toFormat('t ZZZZ');

const Axis = styled.div`
  height: 2rem;
  border-top: solid 2px;
`;

const Timestamp = styled.span<{ left: number }>`
  position: absolute;
  border-left: solid 2px;
  padding-left: 2px;
  font-size: 10px;
  left: ${({ left }) => `${left}%`};
`;

export const TimeAxis: React.FC = () => {
  const scale = useScale();
  const timezone = useTimeZone();
  const [start, end] = scale.domain().map((value) => DateTime.fromMillis(value).setZone(timezone));
  const interval = Interval.fromDateTimes(start, end);
  const intervals = interval.splitBy(Duration.fromObject({ minutes: 60 }));

  return (
    <Axis>
      {intervals.map(({ start: intervalStart }) => {
        const formatted = format(intervalStart);
        return (
          <Timestamp key={formatted} left={scale(intervalStart)}>
            {formatted}
          </Timestamp>
        );
      })}
    </Axis>
  );
};

import { DateTime } from 'luxon';
import React from 'react';

import { Order, Region } from '@admin/schema';

export const Scheduled: React.FC<{
  order: Pick<Order, 'scheduled'> & { region: Pick<Region, 'tz'> };
}> = ({ order }) => {
  const scheduled = DateTime.fromISO(order.scheduled, { zone: order.region.tz });

  return (
    <>
      {scheduled.toLocaleString(DateTime.DATETIME_MED)} {scheduled.toFormat('ZZZZ')}
    </>
  );
};

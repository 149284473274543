import React, { useState } from 'react';

import { client } from '@admin/libraries/apollo';
import { useFeatureFlagsQuery } from '@admin/schema';

import { Panel } from '@admin/components/helpers/panel';
import { Table, AnchorButton as Link } from '@shared/components/bootstrap';
import { Spinner } from '@admin/components/spinner';
import styled from '@emotion/styled';
import { DateTime } from 'luxon';
import { FeatureFlagType, UpsertModal } from './upsert_modal';
import { Label } from '../helpers/label';

const WhitelistLabels = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  gap: 4px;
`;

export const FeatureFlagsList: React.FC<{ resourceName?: string; resourceID?: string; editAccess: boolean }> = ({
  resourceName,
  resourceID,
  editAccess = true,
}) => {
  const [showUpsertModal, setShowUpsertModal] = useState<boolean>(false);
  const [editingFeatureFlag, setEditingFeatureFlag] = useState<FeatureFlagType | undefined>(undefined);

  const { data, loading, refetch } = useFeatureFlagsQuery({
    client,
    variables: { resourceName: resourceName },
  });

  let featureFlags = data?.featureFlags;
  if (resourceName) {
    featureFlags = featureFlags?.filter((featureFlag) => featureFlag.resourceName === resourceName);
  }

  if (featureFlags === undefined || featureFlags.length === 0) {
    return null;
  }

  return (
    <>
      <Panel>
        <Panel.Body>
          <Table responsive striped>
            <thead>
              <tr>
                <th className="col-sm-2">Name</th>
                {!resourceName && <th className="col-sm-2">Resource</th>}
                <th className="col-sm-2">Description</th>
                <th className="col-sm-2">Whitelisted</th>
                {editAccess && (
                  <>
                    <th className="col-sm-2">Created</th>
                    <th className="col-sm-2">Updated</th>
                    <th className="col-sm-1 text-right"></th>
                  </>
                )}
              </tr>
            </thead>

            <tbody>
              {featureFlags?.map((featureFlag) => (
                <tr key={featureFlag.id}>
                  <td className="col-sm-2">{featureFlag.name}</td>
                  {!resourceName && <td className="col-sm-2">{featureFlag.resourceName}</td>}
                  <td className="col-sm-2">{featureFlag.description}</td>
                  <td className="col-sm-2">
                    {resourceID ? (
                      featureFlag.whitelistedObjects.find(
                        (whitelistedObject) => whitelistedObject.id === resourceID,
                      ) ? (
                        'Yes'
                      ) : (
                        'No'
                      )
                    ) : (
                      <WhitelistLabels>
                        {featureFlag.whitelistedObjects.map((object, _) => (
                          <Label kind="default" key={object.id}>
                            {object.id} - {object.name}
                          </Label>
                        ))}
                      </WhitelistLabels>
                    )}
                  </td>
                  {editAccess && (
                    <>
                      <td className="col-sm-2">
                        {DateTime.fromISO(featureFlag.createdAt).toLocaleString(DateTime.DATETIME_MED)}
                      </td>
                      <td className="col-sm-2">
                        {DateTime.fromISO(featureFlag.updatedAt).toLocaleString(DateTime.DATETIME_MED)}
                      </td>
                      <td className="col-sm-1 text-right">
                        <Link
                          kind="primary"
                          onClick={() => {
                            setEditingFeatureFlag(featureFlag);
                            setShowUpsertModal(true);
                          }}
                        >
                          Edit
                        </Link>
                      </td>
                    </>
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
          {loading && <Spinner />}
        </Panel.Body>
      </Panel>
      {showUpsertModal && (
        <UpsertModal
          featureFlag={editingFeatureFlag}
          regionOptions={data?.regions ?? []}
          userOptions={data?.users ?? []}
          warehouseOptions={data?.warehouses ?? []}
          closeModal={() => {
            setEditingFeatureFlag(undefined);
            setShowUpsertModal(false);
            refetch();
          }}
        />
      )}
    </>
  );
};

import { NightTransportPlanQueryVariables } from '@admin/schema';
import { createContext } from 'react';

import { Mode } from './filters';
import { NightTransportRequiredDrive, NightTransportRoute, NightTransportTask } from './types';

export const Context = createContext<{
  mode: Mode;
  date?: string; // yyyy-mm-dd
  geo?: string;
  tz?: string;
  tasks: NightTransportTask[];
  requiredDriveTasks: Record<string, NightTransportRequiredDrive>;
  loading: boolean;
  errors: Record<string, string>;
  onMode(mode: Mode): void;
  onDate(date?: string): void;
  onGeo(geo?: string): void;
  onAddRoute(_?: NightTransportRoute): void;
  onAddTask(_?: NightTransportTask): void;
  onCancel(_?: NightTransportPlanQueryVariables): void;
  onChangeRoute(_?: NightTransportRoute): void;
  onChangeTask(_?: NightTransportTask): void;
  onDeleteRoute(_?: string): void;
  onDeleteTask(_?: string): void;
  onMoveTask(task: NightTransportTask, position: number, routeUUID?: string): void;
  onSwapTasks(taskA: NightTransportTask, posA: number, taskB: NightTransportTask, posB: number): void;
  onSave(): void;
  onChangeTaskRoute(changedTask: NightTransportTask, previousRouteUUID?: string): void;
}>({
  mode: Mode.Viewing,
  tasks: [],
  requiredDriveTasks: {},
  loading: false,
  errors: {},
  onMode: () => {
    /* noop */
  },
  onDate: () => {
    /* noop */
  },
  onGeo: () => {
    /* noop */
  },
  onAddRoute: () => {
    /* noop */
  },
  onAddTask: () => {
    /* noop */
  },
  onCancel: () => {
    /* noop */
  },
  onChangeRoute: () => {
    /* noop */
  },
  onChangeTask: () => {
    /* noop */
  },
  onDeleteRoute: () => {
    /* noop */
  },
  onDeleteTask: () => {
    /* noop */
  },
  onMoveTask: () => {
    /* noop */
  },
  onSwapTasks: () => {
    /* noop */
  },
  onSave: () => {
    /* noop */
  },
  onChangeTaskRoute: () => {
    /* noop */
  },
});

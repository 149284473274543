(function () {
  const app = angular.module('app');

  app.constant('SmsConstants', {
    MAX_LENGTH: 1600,
    PLACEHOLDER: 'What would you like to say?',
    PLACEHOLDER_UNSUBSCRIBED: 'This person has unsubscribed from SMS messages',
    PLACEHOLDER_INACTIVE_DISPATCH: 'This dispatch is no longer active.',
    PLACEHOLDER_NO_PHONE:
      'No phone number on record. Please enter a phone number and save this lead to enable texting.',
    MAX_TEXT_DISPLAY: 160,
  });
})();

(function () {
  const point = (coordinate) => ({
    lat: Number(coordinate.latitude),
    lng: Number(coordinate.longitude),
  });

  const defaultMapParams = (maps) => ({
    drivingOptions: {
      trafficModel: maps.TrafficModel.PESSIMISTIC,
      departureTime: new Date(),
    },
    travelMode: maps.TravelMode.DRIVING,
  });

  const mapCoordinatesToRouteParams =
    (maps) =>
    (coordinates, options = defaultMapParams) => ({
      ...(_.isFunction(options) ? options(maps) : options),
      origin: point(_.first(coordinates)),
      destination: point(_.last(coordinates)),
      waypoints: coordinates.slice(1, -1).map((location) => ({ location: point(location) })),
    });

  angular.module('app').service('RouteService', [
    '$q',
    'uiGmapGoogleMapApi',
    function ($q, uiGmapGoogleMapApi) {
      this.getRoute = (coordinates, options) =>
        $q((resolve, reject) =>
          uiGmapGoogleMapApi.then((maps) => {
            const directionsService = new maps.DirectionsService();
            const params = mapCoordinatesToRouteParams(maps)(coordinates, options);
            return directionsService.route(params, (directionsServiceResult, directionServiceStatus) =>
              directionServiceStatus === 'OK' ? resolve(directionsServiceResult) : reject(directionsServiceResult),
            );
          }),
        );
    },
  ]);
})();

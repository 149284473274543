import * as React from 'react';

import { useSelfStorageRentalsWithUnitsQuery } from '@admin/schema';
import { client } from '@admin/libraries/apollo';

export const Selector: React.FC<{
  name: string;
  accountID: string;
  selectedID?: string;
  onSelect(selectedID?: string): void;
}> = ({ name, accountID, selectedID, onSelect }) => {
  const { data, loading } = useSelfStorageRentalsWithUnitsQuery({ client, variables: { accountID } });
  const rentals = data?.rentals || [];

  return (
    <select
      name={name}
      className="form-control"
      disabled={loading}
      value={selectedID || ''}
      onChange={(event) => onSelect(event.target.value || undefined)}
    >
      <option disabled={!!selectedID} value="">
        - Unit -
      </option>
      {rentals.map((rental) => (
        <option key={rental.id} value={rental.id}>
          {rental.name}
        </option>
      ))}
    </select>
  );
};

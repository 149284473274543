import * as React from 'react';
import { forwardRef } from 'react';

import { InputFormGroupType, InputFormGroupProps, FieldFormGroup, ZipFormControl } from '@admin/components/fields';

const DEFAULTS = {
  id: 'address-zip',
  label: 'ZIP',
  type: 'text',
};

export const ZipFormGroup = forwardRef<InputFormGroupType, InputFormGroupProps>((props, ref) => {
  const { has, help, label, ...input } = { ...DEFAULTS, ...props };
  return (
    <FieldFormGroup has={has} help={help} label={label} id={input.id}>
      <ZipFormControl ref={ref} {...input} />
    </FieldFormGroup>
  );
});

import * as React from 'react';

import { Barcode } from '@admin/components/items/barcode';
import { accountItemURL } from '@admin/config/routes';

export const ItemLink: React.FC<{
  accountID: string;
  itemID?: string;
  itemName?: string;
}> = ({ accountID, itemID, itemName }) => (
  <>
    {itemID ? (
      <a href={accountItemURL(accountID, itemID)}>
        <Barcode accountID={accountID} id={itemID} /> - ({itemName || '?'})
      </a>
    ) : (
      <span>Not inventoried - ({itemName || '?'})</span>
    )}
  </>
);

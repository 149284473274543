import {
  faCog,
  faCubes,
  faDollar,
  faGavel,
  faHome,
  faTruck,
  faUser,
  faEdit,
  faClock,
  faCalendar,
  faBuilding,
  faBarChart,
  faPersonChalkboard,
} from '@fortawesome/free-solid-svg-icons';

const app = angular.module('app');

const linksDictionary = {
  home: [
    {
      text: 'Home',
      state: 'inbox',
    },
    {
      text: 'IT Support',
      state: 'itSupport',
    },
  ],

  account: [
    {
      text: 'Leads',
      state: 'leads',
    },
    {
      text: 'Accounts',
      state: 'accounts',
    },
    {
      text: '|',
      state: null,
    },
    {
      text: 'SMS',
      state: 'smsInbox',
      showCount: true,
    },
    {
      text: 'Voicemails',
      state: 'voicemails',
    },
    {
      text: 'Calls',
      state: 'phoneCalls',
    },
    {
      text: 'Claims',
      state: 'claims',
    },
    {
      text: 'Customer Tickets',
      state: 'customerTickets',
    },
  ],

  selfStorage: [
    {
      text: 'Reservations',
      state: 'selfStorageReservations',
    },
    {
      text: 'Units',
      state: 'selfStorageUnitsIndex',
    },
    {
      text: 'Facilities',
      state: 'selfStorageFacilities',
    },
    {
      text: 'Rentals',
      state: 'selfStorageRentalsList',
    },
    {
      text: 'Rate Adjustments',
      state: 'selfStorageRateAdjustmentUploader',
    },
  ],

  jobs: [
    {
      text: 'Orders',
      state: 'orders',
    },
    {
      text: 'Dispatches',
      state: 'dispatches',
    },
    {
      text: 'Map',
      state: 'map',
    },
    {
      text: 'Transfers',
      state: 'transfers',
    },
    {
      text: 'PTO Requests',
      state: 'ptoRequests',
    },
    {
      text: 'Fleet',
      state: 'vehicles',
    },
    {
      text: 'View Calendar',
      state: 'calendar',
    },
    {
      text: 'Shifts',
      state: 'shifts',
    },
    {
      text: 'Items Explorer',
      state: 'itemsExplorer',
    },
    {
      text: 'Vehicle Route Matcher',
      state: 'vehicleRouteMatcher',
    },
    {
      text: 'Night Transport Planner',
      state: 'nightTransportPlanner',
    },
    {
      text: 'Auction Uploader',
      state: 'auctionUploads',
    },
  ],

  settings: [
    {
      text: 'Users',
      state: 'users',
    },
    {
      text: 'Plans',
      state: 'plans',
    },
    {
      text: 'Warehouses',
      state: 'warehouses',
    },
    {
      text: 'System Phones',
      state: 'systemPhones',
    },
    {
      text: 'Call Groups',
      state: 'callGroups',
    },
    {
      text: 'User Call Groups',
      state: 'userCallGroupManager',
    },
    {
      text: 'Task Groups',
      state: 'ticketmanagement',
    },
    {
      text: 'Pricing Uploads',
      state: 'pricingUploads',
    },
    {
      text: 'Ingestions',
      state: 'ingestions',
    },
    {
      text: 'Depots',
      state: 'depotsList',
    },
    {
      text: 'Regions',
      state: 'regions',
    },
    {
      text: 'Pallet Types',
      state: 'palletTypes',
    },
    {
      text: 'Notes Uploader',
      state: 'notesUploader',
    },
    {
      text: 'Yield Management',
      state: 'yieldManagement',
    },
    {
      text: 'HRS Role Defaults',
      state: 'hrsRoleDefaults',
    },
    {
      text: 'HRS Profile Defaults',
      state: 'hrsProfileDefaults',
    },
    {
      text: 'HRS Locations',
      state: 'hrsLocations',
    },
    {
      text: 'Feature Flags',
      state: 'featureFlags',
    },
    {
      text: 'Zendesk Uploads',
      state: 'zendeskUploads',
    },
  ],

  marketing: [
    {
      text: 'Referrals',
      state: 'referrals',
    },
    {
      text: 'Tips',
      state: 'gratuities',
    },
    {
      text: 'Affiliates',
      state: 'affiliates',
    },
    {
      text: 'URL Redirects',
      state: 'redirects',
    },
    {
      text: 'Coupons',
      state: 'coupons',
    },
    {
      text: 'Set Campaign to Phone',
      state: 'utmPhones',
    },
    {
      text: 'QR Code Generator',
      state: 'qrCodeGenerator',
    },
  ],

  timetable: [
    {
      text: 'Time Clock',
      state: 'timeClock',
    },
  ],

  opsAuditConfigurations: [
    {
      text: 'Ops Audit Configurations',
      state: 'opsAuditConfigurations',
    },
  ],
  logistics: [
    {
      text: 'Loads',
      state: 'loads',
    },
    {
      text: 'Purchase Orders',
      state: 'purchaseOrders',
    },
    {
      text: 'Create Account',
      state: 'createLogisticsAccount',
    },
  ],
  content: [
    {
      text: 'Landing Locations States',
      state: 'landingLocationStateList',
    },
    {
      text: 'Landing Locations Cities',
      state: 'landingLocationCityList',
    },
    {
      text: 'Landing Locations Neighborhoods',
      state: 'landingLocationNeighborhoodList',
    },
    {
      text: 'Landing Locations Routes',
      state: 'landingLocationRouteList',
    },
  ],
  appointmentAvailability: [
    {
      text: 'Global Unavailability',
      state: 'globalUnavailability',
    },
    {
      text: 'Job Availability',
      state: 'jobAvailability',
    },
    {
      text: 'Warehouse Capacity',
      state: 'warehouseCapacity',
    },
    {
      text: 'Availability Labor Adjustment',
      state: 'availabilityLaborAdjustment',
    },
    {
      text: 'Availability Reschedule Offers',
      state: 'availabilityRescheduleOffers',
    },
  ],
  trainingUploads: [
    {
      text: 'Uploads',
      state: 'trainingUploads',
    },
  ],
};

app.controller('NavbarController', [
  'UserService',
  '$rootScope',
  '$scope',
  '$transitions',
  'SmsLiveService',
  'SmsPendingCountService',
  'MessageStats',
  'PusherConstants',
  function (
    UserService,
    $rootScope,
    $scope,
    $transitions,
    SmsLiveService,
    SmsPendingCountService,
    MessageStats,
    PusherConstants,
  ) {
    const self = this;

    self.user = UserService;
    self.notification_count = UserService.notification_count;

    $rootScope.$on(PusherConstants.BROADCAST_SMS_PENDING_COUNT_EVENT, (event, data) => {
      self.smsCount = SmsPendingCountService.getPendingMessageCount(data);
      $scope.$apply();
    });

    MessageStats.get()
      .$promise.then((data) => {
        self.smsCount = SmsPendingCountService.getPendingMessageCount(data);
      })
      .finally(() => {
        SmsLiveService.subscribe_to_pusher();
      });

    function defaultStateForSection(section) {
      const link = _.find(linksDictionary[section], (subSection) => self.user.access(subSection.state));

      if (link) {
        return link.state;
      }
      return undefined;
    }

    self.primaryNavLinks = [
      {
        name: 'home',
        icon: faHome,
        label: 'Home',
        state: defaultStateForSection('home'),
      },
      {
        name: 'account',
        icon: faUser,
        label: 'Account',
        state: defaultStateForSection('account'),
      },
      {
        name: 'selfStorage',
        icon: faBuilding,
        label: 'Self-Storage',
        state: defaultStateForSection('selfStorage'),
      },
      {
        name: 'jobs',
        icon: faTruck,
        label: 'Jobs',
        state: defaultStateForSection('jobs'),
      },
      {
        name: 'reports',
        icon: faBarChart,
        label: 'Reports',
        state: defaultStateForSection('reports'),
      },
      {
        name: 'settings',
        icon: faCog,
        label: 'Settings',
        state: defaultStateForSection('settings'),
      },
      {
        name: 'marketing',
        icon: faDollar,
        label: 'Marketing',
        state: defaultStateForSection('marketing'),
      },
      {
        name: 'timetable',
        icon: faClock,
        label: 'Timetable',
        state: defaultStateForSection('timetable'),
      },
      {
        name: 'opsAuditConfigurations',
        icon: faGavel,
        label: 'Ops Audit',
        state: defaultStateForSection('opsAuditConfigurations'),
      },
      {
        name: 'logistics',
        icon: faCubes,
        label: '3PL',
        state: defaultStateForSection('logistics'),
      },
      {
        name: 'content',
        icon: faEdit,
        label: 'CMS',
        state: defaultStateForSection('content'),
      },
      {
        name: 'appointmentAvailability',
        icon: faCalendar,
        label: 'Availability',
        state: defaultStateForSection('appointmentAvailability'),
      },
      {
        name: 'training',
        icon: faPersonChalkboard,
        label: 'Training',
        state: defaultStateForSection('trainingUploads'),
      },
    ];

    self.primaryNav = '';
    self.setPrimaryNav = function (section) {
      self.primaryNav = section;
      self.secondaryNavLinks = linksDictionary[self.primaryNav];
    };

    self.secondaryNav = '';
    self.setSecondaryNav = function (subsection) {
      self.secondaryNav = subsection;
    };

    self.asideShown = false;
    self.toggleAsideShown = function () {
      self.asideShown = !self.asideShown;
    };

    function showAside() {
      self.asideShown = true;
      angular.element(document.getElementById('container')).addClass('aside-in');
    }

    $rootScope.$on('showAside', () => showAside());

    $rootScope.$on('aside:loaded', () => {
      if (self.user.hasRole('l1_agent') || self.user.hasRole('l2_agent')) {
        showAside();
      }
    });

    $scope.$watch(
      angular.bind(this, function () {
        return this.asideShown;
      }),
      (newVal) => {
        const $button = document.querySelectorAll('#page-alert button')[0];
        if ($button) {
          if (newVal === true) {
            $button.style.right = '230px';
          } else {
            $button.style.right = '10px';
          }
        }
      },
    );

    self.refreshNav = function (state) {
      const secondaryNav = state.data.subSection || state.name;

      const targetSectionKey = _.findKey(linksDictionary, (section) =>
        _.some(section, (subSection) => subSection.state === secondaryNav),
      );

      const targetSubSection = _.find(
        linksDictionary[targetSectionKey],
        (subSection) => subSection.state === secondaryNav,
      );

      if (targetSubSection) {
        self.setPrimaryNav(targetSectionKey);
        self.setSecondaryNav(targetSubSection.state);
      } else {
        // If no targetSubSection was found, do not highlight anything in the
        // secondary nav.
        // In the primary nav, highlight either state.data.subsection (if it
        // is defined) or 'inbox' (the defautlt).
        self.setPrimaryNav(state.data.section || 'inbox');
        self.setSecondaryNav('');
      }
    };

    $transitions.onSuccess({}, (transition) => {
      self.refreshNav(transition.to());
    });
  },
]);

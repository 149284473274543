export function toggleBooleanArrayByIndex(array: boolean[], index: number) {
  const newArray = array.slice();
  newArray.splice(index, 1, !array[index]);
  return newArray;
}

export function updateStringArrayItemByIndex(array: string[], item: string, index: number) {
  const newArray = array.slice();
  newArray.splice(index, 1, item);
  return newArray;
}

export function removeArrayItemByIndex(array: any[], index: number) {
  const newArray = array.filter((element, i) => i !== index);
  return newArray;
}

export function transformStringArrayToBooleanArray(answerChoicesArray: string[], stringArray: string[]) {
  const booleanArray: boolean[] = [];
  for (const element of answerChoicesArray) {
    if (stringArray.includes(element)) {
      booleanArray.push(true);
    } else {
      booleanArray.push(false);
    }
  }
  return booleanArray;
}

export function transformBooleanArrayToStringArray(answerChoicesArray: string[], booleanArray: boolean[]) {
  const stringArray: string[] = [];
  for (const index in answerChoicesArray) {
    if (booleanArray[index]) {
      stringArray.push(answerChoicesArray[index]);
    }
  }
  return stringArray;
}

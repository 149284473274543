(function () {
  const app = angular.module('app');

  app.component('materialsPreview', {
    templateUrl: 'partials/materials/preview.html',
    bindings: {
      resource: '<',
    },
    controller: [
      'MaterialsService',
      '$q',
      function (MaterialsService, $q) {
        const self = this;

        function findMaterialCategoryForID(id) {
          return _.find(MaterialsService.categories, function (object) {
            return object.id === id;
          });
        }

        self.$onInit = function () {
          $q.all([self.resource.$promise, MaterialsService.categories.$promise]).then(function () {
            self.material_selections = _.map(self.resource.material_selections, function (selection) {
              return _.merge(
                {
                  material_category: findMaterialCategoryForID(selection.material_category_id),
                },
                selection,
              );
            });
          });
        };
      },
    ],
  });
})();

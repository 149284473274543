import { capitalize } from 'lodash';
import React from 'react';

import { ROLES } from '@admin/config/roles';
import { RateAdjustment__Scheduling__CancelReason as CancelReason } from '@admin/schema';
import { UserRole } from '@admin/types';

export const CancelReasonSelector: React.FC<{
  cancelReason?: CancelReason;
  setCancelReason(cancelReason: CancelReason): void;
}> = ({ cancelReason, setCancelReason }) => {
  const cancelReasonOptions = [CancelReason.Waived];

  if (ROLES.includes(UserRole.Admin)) {
    cancelReasonOptions.push(CancelReason.Admin);
  }

  return (
    <select
      value={cancelReason || ''}
      onChange={(event) => setCancelReason((event.target.value as CancelReason) || undefined)}
      className="form-control"
      required
    >
      <option value="">- Select a reason -</option>
      {cancelReasonOptions.map((reason) => (
        <option key={reason} value={reason}>
          {reason.split('_').map(capitalize).join(' ')}
        </option>
      ))}
    </select>
  );
};

(function () {
  const app = angular.module('app');

  app.factory('PalletService', function () {
    const service = {
      getAllAccountIds: getAllAccountIds,
      updatePallets: updatePallets,
    };

    return service;

    // This method could be optimized further.
    function getAllAccountIds(pallets) {
      const accountIds = [];

      _.each(pallets, function (pallet) {
        _.each(pallet.items, function (item) {
          accountIds.push(item.account_id);
        });
      });

      return _.uniq(accountIds);
    }

    function isCustomerOnPallet(customer, pallet) {
      return _.some(pallet.items, function (item) {
        return item.account_id === customer.account_id;
      });
    }

    function updatePallets(pallets, customers) {
      const primaryCustomers = _.filter(customers, { primary: true });

      _.each(pallets, function (pallet) {
        pallet.primaryCustomers = _.filter(primaryCustomers, function (customer) {
          return isCustomerOnPallet(customer, pallet);
        });
      });

      return pallets;
    }
  });
})();

import React from 'react';
import { DateTime } from 'luxon';

import { AvailabilityGlobalUnavailabilityQuery } from '@admin/schema';

import { Table } from '@shared/components/bootstrap';

import { formatPercentage, formatReasonAsString } from '../utils';

export const Entries: React.FC<{
  unavailabilities: AvailabilityGlobalUnavailabilityQuery['paginated']['results'];
  selectedOptions: string[];
  setSelectedOptions: React.Dispatch<React.SetStateAction<string[]>>;
}> = ({ unavailabilities, selectedOptions, setSelectedOptions }) => (
  <Table striped responsive>
    <thead>
      <tr>
        <th className="text-left" />
        <th className="text-left">Date</th>
        <th className="text-left">Region</th>
        <th className="text-left">Name</th>
        <th className="text-left">Reason</th>
        <th className="text-left">Capacity Reduction Percentage</th>
        <th className="text-left">Added By</th>
      </tr>
    </thead>
    <tbody>
      {unavailabilities.map((unavailability) => (
        <tr key={unavailability.id}>
          <td>
            <input
              data-test-id={`toDelete${unavailability.id}`}
              checked={selectedOptions.includes(unavailability.id)}
              type="checkbox"
              onChange={(event) => {
                const checked = event.target.checked;
                setSelectedOptions((current) => {
                  if (checked) {
                    return Array.from(new Set([...current, unavailability.id]));
                  } else {
                    return current.filter((option) => option !== unavailability.id);
                  }
                });
              }}
            />
          </td>
          <td className="text-left">{DateTime.fromISO(unavailability.date).toLocaleString()}</td>
          <td className="text-left">{unavailability.region.name}</td>
          <td className="text-left">{unavailability.name}</td>
          <td className="text-left">{formatReasonAsString(unavailability.reason)}</td>
          <td className="text-left">{formatPercentage(unavailability.capacityReductionPercentage)}</td>
          <td className="text-left">{unavailability.creator.name}</td>
        </tr>
      ))}
    </tbody>
  </Table>
);

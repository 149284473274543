(() => {
  const app = angular.module('app');

  const ATTRIBUTES = [
    'id',
    'quantity',
    'sizing_selection',
    'notes',
    'length',
    'width',
    'height',
    'dimensions_overwritten',
    'custom_category_name',
  ];

  app.service('EstimatedItemNestedAttributesService', [
    'NestedAttributesService',
    function (NestedAttributesService) {
      this.convert = (selections) =>
        NestedAttributesService.convert(selections, (selection) => {
          if (selection && !selection._destroy) {
            return _.merge(_.pick(selection, ATTRIBUTES), {
              category_id: selection.category ? selection.category.id : null,
            });
          }
        });
    },
  ]);
})();

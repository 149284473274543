import template from '@admin/angular/templates/dispatcher_order.html';

import angular from 'angular';
import { isWarehouseOrder } from '../../helpers/order';

const app = angular.module('app');

app.component('dispatcherOrder', {
  template,
  bindings: {
    order: '<',
    dispatch: '<',
    mode: '<',
    undispatched: '<?',
    dragstart: '&',
    dragmove: '&',
    dragend: '&',
  },

  controller: [
    '$element',
    'DispatcherEstimationFormModalService',
    'AccountService',
    'DispatcherConstants',
    '$filter',
    function ctrl($element, DispatcherEstimationFormModalService, AccountService, DispatcherConstants, $filter) {
      this.$onInit = () => {
        // eslint-disable-next-line no-undef
        requestAnimationFrame(() => angular.element('[data-toggle="tooltip"]', $element.context).tooltip());
      };

      this.estimate = () => {
        DispatcherEstimationFormModalService.present(this.order, this.mode);
      };

      this.errors = () => {
        if (this.order.rescheduled && !this.dispatch) {
          return 'rescheduled';
        }

        if (_.get(this, 'dispatch.arrival')) {
          const arrival = moment(this.dispatch.arrival);
          const scheduled = moment(this.order.scheduled);
          if (!arrival.isSame(scheduled, 'day')) return 'rescheduled';
        }
      };

      this.sizing = () => {
        if (!this.dispatch) {
          return 'default';
        }
        if (this.order.estimated_duration && this.order.estimated_duration > 60.0 * 60.0) {
          return 'expanded';
        }
        return 'collapsed';
      };

      this.formattedTime = () => {
        const scheduledTime = new Date(this.order.scheduled);
        const scheduleTime = $filter('datetz')(scheduledTime, this.order.region.tz, 'h:mm', true).replace(/:00/g, '');

        const endTime = $filter('datetz')(
          scheduledTime.getTime() +
            this.order.sla_window_size * DispatcherConstants.DEFAULT_WINDOW_SLOT_DURATION * 1000,
          this.order.region.tz,
          'h:mm a',
          true,
        ).replace(/:00/g, '');

        const startTime = $filter('datetz')(
          scheduledTime.getTime() + this.order.dispatch_offset * 1000,
          this.order.region.tz,
          'h:mm a',
          true,
        ).replace(/:00/g, '');

        return `SLA: ${scheduleTime}-${endTime} ${this.undispatched ? '' : `(est: ${startTime})`}`;
      };

      this.isWarehouseOrder = isWarehouseOrder;
      this.getCustomerName = (account) => {
        const customer = AccountService.getPrimaryCustomer(account);
        return customer.name;
      };

      this.whiteGloveTag = () => {
        const tags = this.order.tags.filter(
          (tag) => tag.includes('Basic') || tag.includes('Packing Help') || tag.includes('Full Service'),
        );

        return tags.length ? tags[0] : undefined;
      };

      this.extendedServiceAreaTag = () => {
        const tags = this.order.tags.filter((tag) => tag.includes('Service Area') && tag !== 'Extended Service Area');

        return tags.length ? tags[0] : undefined;
      };
    },
  ],
});

import angular from 'angular';
import copy from 'copy-to-clipboard';

const app = angular.module('app');

app.component('checkoutButtonsContainer', {
  templateUrl: 'partials/components/checkout_buttons_container.html',
  bindings: {
    formFlags: '<',
    lead: '<',
    saveLead: '&',
  },
  controller: [
    'Lead',
    function CheckoutButtonsContainerController(Lead) {
      this.disableSaveLead = () => !(this.lead.phone || this.lead.email);

      this.copyCheckoutUrl = (event) => {
        event.target.textContent = 'Copying...'; // eslint-disable-line no-param-reassign
        Lead.checkout_url({ id: this.lead.id }).$promise.then((data) => {
          event.target.textContent = 'Copied'; // eslint-disable-line no-param-reassign
          copy(data.checkout_url);
        });
      };
    },
  ],
});

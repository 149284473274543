import styled from '@emotion/styled';
import * as React from 'react';
import { FieldError, useForm } from 'react-hook-form';

import {
  Alert,
  AnchorButton as Link,
  Breadcrumb,
  Button,
  Checkbox,
  ControlLabel,
  FormGroup,
  HelpBlock,
  InputGroup,
} from '@shared/components/bootstrap';

import { Panel } from '@admin/components/helpers/panel';
import { Spinner } from '@admin/components/spinner';
import { Spacer } from '@shared/components/helpers';

import { detailSelfStorageUnitURL as unitURL, listSelfStorageUnitsURL as unitsURL } from '@admin/config/routes';

import {
  Status,
  SelfStorage__Classification__Amenity,
  SelfStorage__Classification__Category,
  SelfStorage__UnitFragment,
  useSelfStorageUnitQuery,
  useModifySelfStorageUnitMutation,
} from '@admin/schema';
import { client } from '@admin/libraries/apollo';

const MaxWidthForm = styled.form`
  .form-group {
    max-width: 600px;
  }
`;

interface IFormData {
  rentable: boolean;
  name: string;
  floor: number;
  classification: {
    category: SelfStorage__Classification__Category;
    length: number;
    width: number;
    height: number;
    electricity: boolean;
    obstructed: boolean;
    climate: boolean;
    indoors: boolean;
  };
  price: {
    rate: number;
    pushRate: number;
  };
}

const DIMENSION_REGEX = /^\d+(\.\d)?$/;
const FLOOR_REGEX = /^\d+$/;
const PRICE_REGEX = /^\d+(\.\d{1,2})?$/;
const GROUND_FLOORS = [0, 1];

const Breadcrumbs: React.FC<{ unit?: SelfStorage__UnitFragment }> = ({ unit }) => (
  <div className="page-header">
    <Breadcrumb>
      <Breadcrumb.Item>
        <a href={unitsURL()}>Units</a>
      </Breadcrumb.Item>
      <Breadcrumb.Item>{unit?.facility.name || '-'}</Breadcrumb.Item>
      <Breadcrumb.Item>
        <a href={unit ? unitURL(unit) : '#'}>#{unit?.name || '-'}</a>
      </Breadcrumb.Item>
      <Breadcrumb.Item>Edit</Breadcrumb.Item>
    </Breadcrumb>
  </div>
);

const Field: React.FC<{
  error?: FieldError;
  id: string;
  label: string;
}> = ({ error, id, label, children }) => (
  <FormGroup has={error ? 'error' : undefined}>
    <ControlLabel htmlFor={id}>{label}:</ControlLabel>
    {children}
    {error?.message && <HelpBlock>{error.message}</HelpBlock>}
  </FormGroup>
);

const Container: React.FC<{ unit: SelfStorage__UnitFragment }> = ({ unit }) => {
  const [save, { loading, data }] = useModifySelfStorageUnitMutation({ client });
  const { errors, register, handleSubmit } = useForm<IFormData>({
    defaultValues: {
      ...(unit as IFormData),
    },
  });

  const onSubmit = async (input: IFormData) => {
    const result = await save({
      variables: {
        id: unit.id,
        input: {
          ...input,
          floor: Number(input.floor),
          classification: {
            category: input.classification.category,
            obstructed: input.classification.obstructed,
            length: Number(input.classification.length),
            width: Number(input.classification.width),
            height: Number(input.classification.height),
            amenities: [
              input.classification.climate && SelfStorage__Classification__Amenity.ClimateControlled,
              input.classification.indoors && SelfStorage__Classification__Amenity.Indoors,
              input.classification.electricity && SelfStorage__Classification__Amenity.Electricity,
              GROUND_FLOORS.includes(Number(input.floor)) && SelfStorage__Classification__Amenity.FirstFloor,
            ].filter((value): value is SelfStorage__Classification__Amenity => !!value),
          },
          price: {
            rate: Number(input.price.rate),
            pushRate: Number(input.price.pushRate),
          },
        },
      },
    });

    if (result?.data?.modify.status === Status.Ok) {
      location.replace(unitURL(unit));
    }
  };

  return (
    <MaxWidthForm onSubmit={handleSubmit(onSubmit)}>
      <Panel>
        <Panel.Header>
          <Panel.Title>Edit Unit</Panel.Title>
        </Panel.Header>
        <Panel.Body>
          <Field error={errors.name} id="unit_name" label="Name">
            <input
              id="unit_name"
              type="text"
              className="form-control"
              name="name"
              ref={register({ required: 'please enter a name' })}
            />
          </Field>
          <Field error={errors.classification?.category} id="unit_classification_category" label="Category">
            <select
              className="form-control"
              name="classification.category"
              id="unit_classification_category"
              ref={register({ required: 'please select a category' })}
            >
              <option value={SelfStorage__Classification__Category.Locker}>Locker</option>
              <option value={SelfStorage__Classification__Category.ParkingMotorcycle}>Parking (Motorcycle)</option>
              <option value={SelfStorage__Classification__Category.ParkingVehicle}>Parking (Vehicle)</option>
              <option value={SelfStorage__Classification__Category.Unit}>Unit</option>
            </select>
          </Field>
          <Field error={errors.classification?.length} id="unit_classification_length" label="Length (door-side)">
            <InputGroup>
              <input
                id="unit_classification_length"
                type="number"
                step="0.5"
                className="form-control"
                name="classification.length"
                ref={register({
                  required: 'please enter a length',
                  pattern: {
                    value: DIMENSION_REGEX,
                    message: 'please enter a valid length formatted as 0.0',
                  },
                })}
              />
              <InputGroup.Addon>ft</InputGroup.Addon>
            </InputGroup>
          </Field>
          <Field error={errors.classification?.width} id="unit_classification_width" label="Width (depth)">
            <InputGroup>
              <input
                id="unit_classification_width"
                type="number"
                step="0.5"
                className="form-control"
                name="classification.width"
                ref={register({
                  required: 'please enter a width',
                  pattern: {
                    value: DIMENSION_REGEX,
                    message: 'please enter a valid width formatted as 0.0',
                  },
                })}
              />
              <InputGroup.Addon>ft</InputGroup.Addon>
            </InputGroup>
          </Field>
          <Field error={errors.classification?.height} id="unit_classification_height" label="Height">
            <InputGroup>
              <input
                id="unit_classification_height"
                type="number"
                step="0.5"
                className="form-control"
                name="classification.height"
                ref={register({
                  required: 'please enter a height',
                  pattern: {
                    value: DIMENSION_REGEX,
                    message: 'please enter a valid height formatted as 0.0',
                  },
                })}
              />
              <InputGroup.Addon>ft</InputGroup.Addon>
            </InputGroup>
          </Field>
          <Field error={errors.floor} id="unit_floor" label="Floor">
            <input
              id="unit_floor"
              type="number"
              className="form-control"
              name="floor"
              ref={register({
                required: 'please enter a floor',
                pattern: {
                  value: FLOOR_REGEX,
                  message: 'please enter whole number for floor',
                },
              })}
            />
          </Field>
          <Checkbox>
            <input type="checkbox" name="rentable" id="unit_rentable" ref={register} />
            Rentable (does not impact rented units)
          </Checkbox>
          <Checkbox>
            <input id="unit_classification_climate" type="checkbox" name="classification.climate" ref={register} />
            Climate Controlled
          </Checkbox>
          <Checkbox>
            <input
              id="unit_classification_obstructed"
              type="checkbox"
              name="classification.obstructed"
              ref={register}
            />
            Obstructed
          </Checkbox>
          <Checkbox>
            <input
              id="unit_classification_electricity"
              type="checkbox"
              name="classification.electricity"
              ref={register}
            />
            Electricity
          </Checkbox>
          <Checkbox>
            <input id="unit_classification_indoors" type="checkbox" name="classification.indoors" ref={register} />
            Indoors
          </Checkbox>
          <hr />
          <h3>Pricing</h3>
          <p>
            Price changes will not affect existing customers. Promotions can be updated from the main pricing view and
            can not be edited at the individual unit level.
          </p>
          <Field error={errors.price?.rate} id="unit_price_rate" label="Standard Rate">
            <InputGroup>
              <InputGroup.Addon>$</InputGroup.Addon>
              <input
                id="unit_price_rate"
                type="number"
                className="form-control"
                name="price.rate"
                step="any"
                ref={register({
                  required: 'please enter a standard rate',
                  pattern: {
                    value: PRICE_REGEX,
                    message: 'please enter a currency formatted as 0.00',
                  },
                })}
              />
            </InputGroup>
          </Field>
          <Field error={errors.price?.rate} id="unit_price_push_rate" label="Web Rate">
            <InputGroup>
              <InputGroup.Addon>$</InputGroup.Addon>
              <input
                id="unit_price_push_rate"
                type="number"
                className="form-control"
                name="price.pushRate"
                step="any"
                ref={register({
                  required: 'please enter a web rate',
                  pattern: {
                    value: PRICE_REGEX,
                    message: 'please enter a currency formatted as 0.00',
                  },
                })}
              />
            </InputGroup>
          </Field>
          {data?.modify?.error && <Alert style="danger">{data?.modify.error}</Alert>}
        </Panel.Body>
        <Panel.Footer align="right">
          <Link kind="default" href={unitURL(unit)} disabled={loading}>
            Cancel
          </Link>
          <Spacer width="8px" />
          <Button kind="primary" type="submit" loading={loading}>
            Save
          </Button>
        </Panel.Footer>
      </Panel>
    </MaxWidthForm>
  );
};

export const Form: React.FC<{ id: string }> = ({ id }) => {
  const { data } = useSelfStorageUnitQuery({ client, variables: { id } });
  const unit = data?.unit;

  if (!unit) {
    return <Spinner />;
  }

  return (
    <>
      <Breadcrumbs unit={unit} />
      <Container unit={unit} />
    </>
  );
};

// http://odetocode.com/blogs/scott/archive/2014/10/13/confirm-password-validation-in-angularjs.aspx

(function () {
  const app = angular.module('app');

  app.directive('compareTo', function () {
    return {
      require: 'ngModel',
      scope: {
        otherModelValue: '=compareTo',
      },
      link: function (scope, element, attributes, ngModel) {
        ngModel.$validators.compareTo = function (modelValue) {
          return modelValue === scope.otherModelValue;
        };

        scope.$watch('otherModelValue', function () {
          ngModel.$validate();
        });
      },
    };
  });
})();

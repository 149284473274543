(() => {
  const app = angular.module('app');

  app.factory('UserShifts', [
    '$resource',
    function ($resource) {
      return $resource('/user/shifts.json');
    },
  ]);
})();

import { useCallback, useState } from 'react';

import { IConfirmation } from './confirmation';
import { Response } from './response';

export const useConfirmation = () => {
  const [confirmation, setConfirmation] = useState<IConfirmation | undefined>();

  const confirm = useCallback(
    (
      props: Pick<
        IConfirmation,
        'title' | 'description' | 'confirm' | 'cancel' | 'showConfirm' | 'showCancel' | 'size'
      >,
    ) => {
      const promise = new Promise<Response>((resolve) => {
        setConfirmation({
          ...props,
          respond: (response) => {
            setConfirmation(undefined);
            resolve(response);
          },
        });
      });

      return promise;
    },
    [],
  );

  return { confirm, confirmation };
};

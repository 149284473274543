import { SourceIcon } from '@admin/components/billing/sources/source_icon';
import styled from '@emotion/styled';
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useState } from 'react';

import { Label } from '@admin/components/helpers/label';
import { Ordinalize } from '@admin/components/helpers/ordinalize';
import { Titleize } from '@admin/components/helpers/titleize';
import { Button, Table, Text } from '@shared/components/bootstrap';

import { Size } from '@admin/components/self_storage/classifications/size';
import { Status } from '@admin/components/self_storage/rentals/status';

import { Overlock } from '@admin/components/self_storage/rentals/overlock';

import { SelfStorage__RentalFragment, SelfStorage__RentalPolicy } from '@admin/schema';
import { Details } from './details';

const BillingWrapper = styled.div`
  width: fit-content;
`;

const SourceWrapper = styled.div`
  white-space: nowrap;
  padding-top: 6px;
`;

const Entry: React.FC<{
  rental: SelfStorage__RentalFragment;
  permissions?: SelfStorage__RentalPolicy;
}> = ({ rental, permissions }) => {
  const [selected, setSelected] = useState<boolean>(false);
  const onToggle = () => setSelected(!selected);
  return (
    <>
      <tr key={rental.id}>
        <td className="text-left col-zero">
          <Button kind="default" onClick={onToggle} title={selected ? 'Less' : 'More'}>
            <FontAwesomeIcon icon={selected ? faChevronDown : faChevronRight} />
          </Button>
        </td>
        <td className="text-left col-zero">{rental.id}</td>
        <td className="text-left col-md-2">{rental.unit.facility.name}</td>
        <td className="text-left col-md-1">{rental.unit.name}</td>
        <td className="text-left col-md-1">
          <Size classification={rental.unit.classification} /> {rental.unit.classification.kind.name}
        </td>
        <td className="text-left col-md-1">{rental.unit.floor}</td>
        <td className="text-left col-md-2">
          {rental.activatedAt && DateTime.fromISO(rental.activatedAt).toLocaleString(DateTime.DATETIME_MED)}
        </td>
        <td className="text-left col-md-2">
          {rental.canceledAt && DateTime.fromISO(rental.canceledAt).toLocaleString(DateTime.DATETIME_MED)}
        </td>
        <td className="text-left col-md-2">
          <BillingWrapper>
            <Text tag="div" wrapping="nowrap">
              <Label kind="default">
                <Titleize text={rental.subscription.billing} />
              </Label>
              {rental.subscription.billsOn && (
                <>
                  {' '}
                  on <Ordinalize value={DateTime.fromISO(rental.subscription.billsOn).day} />
                </>
              )}
            </Text>
            {rental.subscription.defaultSource && (
              <SourceWrapper>
                <SourceIcon inline source={rental.subscription.defaultSource} scale={2} extraDetails />
              </SourceWrapper>
            )}
          </BillingWrapper>
        </td>
        <td className="text-left col-zero col-nowrap">
          <Status status={rental.status} /> {permissions?.modify && <Overlock rental={rental} />}
        </td>
      </tr>
      {selected && (
        <>
          <tr>
            <td />
            <td colSpan={9}>
              <Details rentalID={rental.id} />
            </td>
          </tr>
        </>
      )}
    </>
  );
};

export const Entries: React.FC<{
  rentals: SelfStorage__RentalFragment[];
  permissions?: SelfStorage__RentalPolicy;
}> = ({ rentals, permissions }) => {
  if (!rentals.length) {
    return (
      <Text alignment="center" tag="p">
        This account does not have any self-storage rentals.
      </Text>
    );
  }

  return (
    <Table responsive>
      <thead>
        <tr>
          <th className="text-left col-zero" />
          <th className="text-left col-md-1">ID</th>
          <th className="text-left col-md-2">Facility</th>
          <th className="text-left col-md-1">Unit #</th>
          <th className="text-left col-md-1">Unit Details</th>
          <th className="text-left col-md-1">Floor</th>
          <th className="text-left col-md-2">Move-In Date</th>
          <th className="text-left col-md-2">Move-Out Date</th>
          <th className="text-left col-md-2">Billing</th>
          <th className="text-left col-zero">Status</th>
        </tr>
      </thead>
      <tbody>
        {rentals.map((rental) => (
          <Entry key={rental.id} rental={rental} permissions={permissions} />
        ))}
      </tbody>
    </Table>
  );
};

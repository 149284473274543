(function () {
  angular.module('app').factory('BillingDateChange', [
    '$resource',
    function ($resource) {
      return $resource('/accounts/:account_id/billing_date.json', {
        account_id: '@account_id',
        day_of_month: '@day_of_month',
      });
    },
  ]);
})();

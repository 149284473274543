(function () {
  const app = angular.module('app');

  app.directive('leadStatus', [
    '$location',
    '$rootScope',
    'UserService',
    'LeadObjectionDropdownHelper',
    'LeadObjectionService',
    function ($location, $rootScope, UserService, LeadObjectionDropdownHelper, LeadObjectionService) {
      function link(scope) {
        scope.doNotShowObjection = LeadObjectionDropdownHelper.doNotShowObjection;
        scope.hasObjectionChanged = LeadObjectionService.hasObjectionChanged;
        scope.isFollowupScheduledObjection = LeadObjectionService.isFollowupScheduledObjection;
        scope.isWon = LeadObjectionService.isWon;
        scope.tz = UserService.tz;
        scope.showLastObjection = $location.path().match(/\bleads\b\/.*\/edit/);
        LeadObjectionService.list(LeadObjectionService.VISIBILITY_CHECKOUT).then((data) => {
          scope.objectionReasons = data;
        });
        scope.followUpScheduledAtChanged = function (newValue) {
          if (!_.isUndefined(scope.lead.state)) {
            $rootScope.$broadcast('lead:statusChanged', {
              objection_reason: scope.lead.objection_reason,
              followup_scheduled_at: newValue,
              state: scope.lead.state,
            });
          }
        };
      }
      return {
        restrict: 'E',
        scope: {
          followupErrors: '<',
          followupSelections: '<',
          lead: '=',
          updateFollowupSelections: '&',
          validatedZip: '<?',
        },
        templateUrl: 'partials/checkouts/_lead_status.html',
        link: link,
      };
    },
  ]);
})();

import * as React from 'react';
import axios from 'axios';
import { Address } from '@admin/types/address';
import { Fields } from './fields';
import { Fields as DetailFields } from './detail/fields';
import { Dialog } from '../errors/dialog';

const Form: React.FC<{
  account: { id: number };
  address: Address;
  onCancel(): void;
  onChange(changes: Address): void;
  onSelect(address: Address): void;
}> = ({ address, account, onCancel, onSelect, onChange }) => {
  const [error, setError] = React.useState<string | null>(null);
  const [saving, setSaving] = React.useState<boolean>(false);
  const [requestError, setRequestError] = React.useState<string>();

  const savable = () => !error && !saving && address.street && address.city && address.state && address.zip;

  const save = async () => {
    if (!savable()) {
      return;
    }

    setSaving(true);
    try {
      if (address.id) {
        // update
        await axios
          .put<Address>(`/accounts/${account.id}/addresses/${address.id}.json`, { address: address })
          .then(({ data }) => onSelect(data));
      } else {
        // create
        await axios
          .post<Address>(`/accounts/${account.id}/addresses.json`, { address: address })
          .then(({ data }) => onSelect(data));
      }
    } catch (e: any) {
      const message =
        e?.response?.data?.message ||
        e?.response?.message ||
        'An unknown error occurred. Please contact technical support.';
      setRequestError(message);
    }
    setSaving(false);
  };

  return (
    <>
      {requestError && <Dialog title="Whoops" description={requestError} onClose={() => setRequestError(undefined)} />}
      <form className="form-horizontal">
        <Fields
          disabled={!!address.id}
          fields={address}
          shouldFlagAmbiguousAddress
          shouldFlagInvalidAddress
          onChange={onChange}
          onGeocode={(geocode) => {
            onChange({
              ...address,
              latitude: geocode?.latitude,
              longitude: geocode?.longitude,
            });
          }}
          onError={setError}
        />
        <hr />
        <DetailFields fields={address} onChange={onChange} />
        <hr />
        <div className="clearfix">
          <div className="pull-right">
            <a href="#" className="btn btn-default" onClick={onCancel}>
              Cancel
            </a>{' '}
            <button className="btn btn-primary" onClick={() => save()} disabled={!savable()}>
              Save
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export { Form };

import styled from '@emotion/styled';
import { flatMap, startCase } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';

import { Panel } from '@admin/components/helpers/panel';
import { OpsAudit__AuditAnswersInputType, OpsAudit__AuditFragment, OpsAudit__QuestionFragment } from '@admin/schema';
import { COLORS } from '@root/colors';
import { Alert, Button } from '@shared/components/bootstrap';
import { Spacer } from '@shared/components/helpers';

import { CategoryPicker } from './category_picker';

const Radio = styled.input`
  margin: 4px 0 0;
`;

const Label = styled.label`
  margin: 4px;
`;

const Scrollable = styled.div`
  max-height: 80vh;
  overflow: hidden;
  overflow-y: scroll;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const MarginButton = styled(Button)<{ order: number }>`
  order: ${({ order }) => order};
  margin: 0 5px;
`;

const HR = styled.hr`
  margin: 20px 0px;
`;

const QuestionListItem = styled.li<{ failed: boolean }>(({ failed }) => ({
  color: failed ? COLORS.red : 'initial',
}));

interface IOpsAuditWithAuditQuestion {
  auditID: string;
  question: OpsAudit__QuestionFragment;
}

interface IIdentifierResponse {
  auditID: string;
  questionID: string;
  response?: string;
}

const otherQuestionSlug = 'other_item';
const incorrectlyOtherAnswer = 'Incorrect category selected';
const disassembledAnswer = 'Disassembled part';

const getSelectionKey = (auditID: string, questionID: string) => `${auditID}_${questionID}`;

const validDefaultAnswer = (answer: number | null | undefined) => answer != null;

const defectAnswer = (
  audit: OpsAudit__AuditFragment,
  question: OpsAudit__QuestionFragment,
  selections: Map<string, IIdentifierResponse>,
) => {
  const selectedResponse = selections.get(getSelectionKey(audit.id, question.id))?.response;
  return !!selectedResponse && question.defectAnswers.includes(selectedResponse);
};

const QuestionsList: React.FC<{
  audit: OpsAudit__AuditFragment;
  initialInputFocusRef: React.RefObject<HTMLInputElement>;
  selections: Map<string, IIdentifierResponse>;
  isParentAudit: boolean;
  updateResponses(auditID: string, questionID: string, response: string): void;
}> = ({ audit, initialInputFocusRef, selections, isParentAudit, updateResponses }) => {
  const questionCompare = (a: OpsAudit__QuestionFragment, b: OpsAudit__QuestionFragment) => {
    const valueA = a.ordering ? a.ordering : parseInt(a.id, 10);
    const valueB = b.ordering ? b.ordering : parseInt(b.id, 10);
    return valueA - valueB;
  };

  return (
    <ol>
      {audit.questions &&
        audit.questions
          .sort((a: OpsAudit__QuestionFragment, b: OpsAudit__QuestionFragment) => questionCompare(a, b))
          .map((question, questionIndex) => (
            <QuestionListItem
              key={getSelectionKey(audit.id, question.id)}
              failed={defectAnswer(audit, question, selections)}
            >
              {question.prompt}
              <div className="row">
                {question.answerChoices.map((choice, index) => (
                  <div key={choice} className="col-md-4 col-lg-4">
                    <Radio
                      autoFocus={
                        isParentAudit &&
                        questionIndex === 0 &&
                        (validDefaultAnswer(question.defaultAnswer) ? question.defaultAnswer === index : index === 0)
                      }
                      type="radio"
                      value={choice}
                      name={getSelectionKey(audit.id, question.id)}
                      id={choice + '_' + getSelectionKey(audit.id, question.id)}
                      onChange={(event) => {
                        if (event.target.checked) {
                          updateResponses(audit.id, question.id, event.target.value);
                        }
                      }}
                      checked={selections.get(getSelectionKey(audit.id, question.id))?.response === choice}
                      ref={
                        isParentAudit &&
                        questionIndex === 0 &&
                        (validDefaultAnswer(question.defaultAnswer) ? question.defaultAnswer === index : index === 0)
                          ? initialInputFocusRef
                          : undefined
                      }
                      required
                    />
                    <Label htmlFor={choice + '_' + getSelectionKey(audit.id, question.id)}>{choice}</Label>
                  </div>
                ))}
              </div>
            </QuestionListItem>
          ))}
    </ol>
  );
};

export const QuestionsPanel: React.FC<{
  parentAudit: OpsAudit__AuditFragment;
  childAudits: OpsAudit__AuditFragment[];
  answersSaving: boolean;
  onSave(answerInputs: OpsAudit__AuditAnswersInputType[]): void;
  onReviewLater(): void;
  onNotAuditable(): void;
}> = ({ parentAudit, childAudits, answersSaving, onSave, onReviewLater, onNotAuditable }) => {
  const [selections, setSelections] = useState<Map<string, IIdentifierResponse>>(new Map());
  const [categoryChange, setCategoryChange] = useState<boolean>(false);
  const [disassembledPart, setDisassembledPart] = useState<boolean>(false);
  const initialInputFocusRef = useRef<HTMLInputElement>(null);
  const auditQuestions: IOpsAuditWithAuditQuestion[] = flatMap(
    [parentAudit].concat(childAudits).filter((audit) => audit.questions != null),
    (audit) => audit.questions!.map((q) => ({ auditID: audit.id, question: q })),
  );
  const otherAudit = auditQuestions.some((auditQuestion) => auditQuestion.question.slug === otherQuestionSlug);

  useEffect(() => {
    setCategoryChange(otherAudit && Array.from(selections.values()).some((a) => a.response === incorrectlyOtherAnswer));
  }, [otherAudit, selections]);

  useEffect(() => {
    setDisassembledPart(otherAudit && Array.from(selections.values()).some((a) => a.response === disassembledAnswer));
  }, [otherAudit, selections]);

  useEffect(() => {
    const options = new Map<string, IIdentifierResponse>();
    auditQuestions.forEach((auditWithQuestions) => {
      const response =
        auditWithQuestions.question.defaultAnswer != null
          ? auditWithQuestions.question.answerChoices[auditWithQuestions.question.defaultAnswer]
          : undefined;
      options.set(getSelectionKey(auditWithQuestions.auditID, auditWithQuestions.question.id), {
        auditID: auditWithQuestions.auditID,
        questionID: auditWithQuestions.question.id,
        response,
      });
    });
    setSelections(options);
  }, [parentAudit, childAudits]);

  const resetFocus = () => {
    if (!!initialInputFocusRef && !!initialInputFocusRef.current) {
      initialInputFocusRef.current.focus();
    }
  };

  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    event.stopPropagation();
    if (answersSaving) {
      return;
    }
    const answerInputs: OpsAudit__AuditAnswersInputType[] = [];
    selections.forEach((value, _) => {
      const answerInput = answerInputs.find((ai) => ai.auditID === value.auditID);
      if (answerInput && value.response) {
        answerInput.answerInputs.push({ questionID: value.questionID, response: value.response });
      } else if (value.response) {
        answerInputs.push({
          auditID: value.auditID,
          answerInputs: [{ questionID: value.questionID, response: value.response }],
        });
      }
    });
    onSave(answerInputs);
    resetFocus();
  };

  const updateResponses = (auditID: string, questionID: string, response: string) => {
    const options = new Map(selections);
    options.set(getSelectionKey(auditID, questionID), { auditID, questionID, response });
    setSelections(options);
  };

  const disableSubmit = () => {
    let disable = false;
    selections.forEach((selection, _) => {
      if (!selection.response) {
        disable = true;
      }
    });
    return disable;
  };

  const disableSkipping = () => {
    const disable = auditQuestions.length === 0;
    return disable || answersSaving;
  };

  const childAuditCompare = (a: OpsAudit__AuditFragment, b: OpsAudit__AuditFragment) => {
    if (a.resourceType === b.resourceType) {
      return parseInt(a.resourceID) - parseInt(b.resourceID);
    } else {
      return a.resourceType < b.resourceType ? -1 : 1;
    }
  };

  const sanitizedQuestionGroup = parentAudit.questionGroup
    ? "('" + parentAudit.questionGroup + "') question group"
    : '';
  return (
    <form onSubmit={onSubmit}>
      <Panel>
        <Panel.Header>
          <Panel.Title>Questions {sanitizedQuestionGroup}</Panel.Title>
        </Panel.Header>
        <Panel.Body>
          <Scrollable>
            <QuestionsList
              audit={parentAudit}
              updateResponses={updateResponses}
              initialInputFocusRef={initialInputFocusRef}
              selections={selections}
              isParentAudit={true}
            />
            {childAudits
              .filter((audit) => audit != null)
              .sort((a, b) => childAuditCompare(a, b))
              .map((childAudit) => (
                <div key={childAudit.id}>
                  <Spacer height="8px" />
                  <hr />
                  <h5>
                    Questions for {startCase(childAudit.resourceType)} - {childAudit.resourceID}{' '}
                  </h5>
                  <QuestionsList
                    audit={childAudit}
                    updateResponses={updateResponses}
                    initialInputFocusRef={initialInputFocusRef}
                    selections={selections}
                    isParentAudit={false}
                  />
                </div>
              ))}
          </Scrollable>
          {categoryChange && parentAudit.resourceID && (
            <>
              <HR />
              <div>
                <CategoryPicker itemID={parentAudit.resourceID} onSubmit={onSubmit} />
              </div>
            </>
          )}
          {disassembledPart && (
            <Alert style="warning">
              {
                'Selecting this answer and moving to the next audit will assign a teal task to the audit manager to link this part to a group.'
              }
            </Alert>
          )}
        </Panel.Body>
        <Panel.Footer>
          <ButtonGroup className="text-right">
            <MarginButton
              order={3}
              kind="primary"
              disabled={answersSaving || disableSubmit() || categoryChange}
              onClick={onSubmit}
            >
              Next Audit
            </MarginButton>
            <MarginButton
              order={2}
              kind="default"
              disabled={disableSkipping()}
              onClick={() => {
                onReviewLater();
                resetFocus();
              }}
            >
              Review Later
            </MarginButton>
            <MarginButton
              order={1}
              kind="danger"
              disabled={disableSkipping()}
              onClick={() => {
                onNotAuditable();
                resetFocus();
              }}
            >
              Not Auditable
            </MarginButton>
          </ButtonGroup>
        </Panel.Footer>
      </Panel>
    </form>
  );
};

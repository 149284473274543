import angular from 'angular';
import { isEmpty, map, pick } from 'lodash';

/* eslint-disable import/extensions, import/no-unresolved */
import { buildInventoryAttributes } from '@admin/utils';
/* eslint-enable import/extensions, import/no-unresolved */

angular.module('app').factory('OrderSerializer', [
  'EstimatedItemNestedAttributesService',
  'MaterialSelectionsNestedAttributesService',
  'ContactNestedAttributesService',
  (EstimatedItemNestedAttributesService, MaterialSelectionsNestedAttributesService, ContactNestedAttributesService) =>
    (data) => {
      const METADATA_ATTRIBUTES = [
        'full_move_out',
        'full_pack',
        'pickup_deadline',
        'property_square_footage',
        'recommended_plans',
        'requested_movers',
        'follow_up_job',
        'follow_up_job_exception_reason',
      ];
      const parameters = angular.copy(data);

      if (parameters.material_selections) {
        parameters.material_selections_attributes = MaterialSelectionsNestedAttributesService.convert(
          parameters.material_selections,
        );
        delete parameters.material_selections;
      }

      const metadata = pick(parameters, METADATA_ATTRIBUTES);
      if (!isEmpty(metadata)) {
        if (parameters.metadata_id) {
          metadata.id = parameters.metadata_id;
        }
        if (parameters.id) {
          metadata.order_id = parameters.id;
        }
        parameters.metadata_attributes = metadata;

        METADATA_ATTRIBUTES.forEach((key) => {
          delete parameters[key];
        });

        delete parameters.metadata_id;
      }

      if (parameters.contact) {
        parameters.contact_attributes = ContactNestedAttributesService.convert(parameters.contact);
      }

      if (parameters.region) {
        parameters.region_id = parameters.region.id;
        delete parameters.region;
      }

      if (parameters.address && parameters.address.id) {
        parameters.address_id = parameters.address.id;
        delete parameters.address;
      }

      if (parameters.address) {
        parameters.address_attributes = parameters.address;
        delete parameters.address;
      }

      if (parameters.moving_operation) {
        parameters.moving_operation_attributes = {
          id: parameters.moving_operation.id,
        };

        if (parameters.moving_operation.origin_address) {
          parameters.address_id = parameters.moving_operation.origin_address.id;
          parameters.moving_operation_attributes.origin_address_id = parameters.address_id;
        }

        if (parameters.moving_operation.destination_address) {
          parameters.moving_operation_attributes.destination_address_id =
            parameters.moving_operation.destination_address.id;
        }

        if (parameters.moving_operation.packing) {
          parameters.moving_operation_attributes.packing = parameters.moving_operation.packing;
        }

        if (parameters.moving_operation.move_size) {
          parameters.moving_operation_attributes.move_size = parameters.moving_operation.move_size;
        }

        if (parameters.moving_operation.moving_quote_id) {
          parameters.moving_operation_attributes.moving_quote_id = parameters.moving_operation.moving_quote_id;
        }

        delete parameters.moving_operation;
      }

      if (parameters.moving_selections) {
        parameters.moving_selections_attributes = parameters.moving_selections;
        delete parameters.moving_selections;
      }

      if (parameters.rescheduler) {
        parameters.rescheduler_id = parameters.rescheduler.id;
        parameters.rescheduler_type = parameters.rescheduler.type;
        delete parameters.rescheduler;
      }

      if (parameters.items) {
        parameters.item_ids = map(parameters.items, (item) => item.id);
        delete parameters.items;
      }

      // DEPRECATED - use "inventory" instead
      if (parameters.estimated_items) {
        parameters.estimated_items_attributes = EstimatedItemNestedAttributesService.convert(
          parameters.estimated_items,
        );
        delete parameters.estimated_items;
      }

      if (parameters.inventory) {
        // NOTE: using data instead of parameters due to issues with angular.copy
        Object.assign(parameters, buildInventoryAttributes(data.inventory));
        delete parameters.inventory;
      }

      return parameters;
    },
]);

(function () {
  angular.module('app').filter('percentage', function () {
    return function (percent, options) {
      let value = percent * 100.0;
      if (typeof options !== 'undefined' && options !== null) {
        if (options.precision) {
          value = _.round(value, options.percision);
        }
      }
      return value + '%';
    };
  });
})();

import { round } from 'lodash';
import * as React from 'react';

const DEFAULT_PRECISION = 2;
const DEFAULT_FORMAT = 'miles';

const METERS_TO_MILES_CONVERSION_FACTOR = 0.00062137; // i.e. 1 meter = 0.000621371 miles

export const Distance: React.FC<{
  value: number; // meters
  precision?: number;
  format?: 'miles';
}> = ({ value, precision = DEFAULT_PRECISION, format = DEFAULT_FORMAT }) => {
  switch (format) {
    case 'miles':
      return <>{round(value * METERS_TO_MILES_CONVERSION_FACTOR, precision)} miles</>;
    default:
      throw new Error(`invalid format ${format}`);
  }
};

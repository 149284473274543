import React from 'react';

import { Order } from '@admin/schema';
import { ORDER_TYPE_TO_NAME } from '@admin/constants/order_type';
import { ORDER_SUBTYPE_TO_NAME } from '@admin/constants/order_subtype';

export const Summary: React.FC<{
  order: Pick<Order, 'id' | 'maintenance' | 'type' | 'subtype'>;
}> = ({ order: { type, subtype, maintenance } }) => (
  <>
    <span>{ORDER_TYPE_TO_NAME[type]}</span>
    <span> - </span>
    <span>{ORDER_SUBTYPE_TO_NAME[subtype]}</span>
    {maintenance && (
      <>
        <span> - </span>
        <span>Maintenance</span>
      </>
    )}
  </>
);

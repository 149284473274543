(function () {
  const app = angular.module('app');

  app.service('TimeService', [
    function () {
      const self = this;

      self.timing = function (value) {
        return value.hour() * 3600 + value.minute() * 60;
      };

      self.overlaps = function (value, flex, start, until) {
        return (
          value &&
          start &&
          until &&
          self.timing(start) < self.timing(moment(value).add(flex)) &&
          self.timing(until) > self.timing(moment(value).subtract(flex))
        );
      };

      self.msToTime = function (duration) {
        const milliseconds = parseInt((duration % 1000) / 100);
        let seconds = parseInt((duration / 1000) % 60);
        let minutes = parseInt((duration / (1000 * 60)) % 60);
        let hours = parseInt((duration / (1000 * 60 * 60)) % 24);
        const days = parseInt(duration / (1000 * 60 * 60) / 24);

        hours = hours < 10 ? '0' + hours : hours;
        minutes = minutes < 10 ? '0' + minutes : minutes;
        seconds = seconds < 10 ? '0' + seconds : seconds;

        return days + 'd ' + hours + 'h ' + minutes + 'm ' + seconds + '.' + milliseconds + 's';
      };

      self.hoursBetween = function (date1, date2) {
        const duration = self.getDiff(date1, date2);
        return parseInt(duration / (1000 * 60 * 60));
      };

      self.getDiff = function (date1, date2) {
        return moment(date1).diff(moment(date2));
      };
    },
  ]);
})();

import * as React from 'react';
import { FC } from 'react';
import styled from '@emotion/styled';
import { DateTime } from 'luxon';

import { Currency } from '@shared/components/helpers/currency';

export const AvailabilityDateContainer = styled.div`
  padding: 10px 0;
`;

const AvailabilityDateDay = styled.div`
  font-size: 16px;
`;

const AvailabilityDateLaborRate = styled.div`
  font-size: 14px;
`;

export const AvailabilityDate: FC<{
  labor?: boolean;
  date: DateTime;
  laborRate?: {
    id: string;
    amount: number;
  } | null;
}> = ({ labor, date, laborRate }) => (
  <AvailabilityDateContainer>
    <AvailabilityDateDay>{date.day}</AvailabilityDateDay>
    {labor && (
      <AvailabilityDateLaborRate>
        <Currency value={laborRate?.amount ?? 0} precision="automatic" />
        /hr
      </AvailabilityDateLaborRate>
    )}
  </AvailabilityDateContainer>
);

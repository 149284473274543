import React, { useState } from 'react';

import { Accordion } from '@admin/components/helpers/accordion';
import { Panel } from '@admin/components/helpers/panel';
import { Button, Text } from '@shared/components/bootstrap';
import { ZendeskTicketAssociationFragment } from '@admin/schema';
import { Comments } from './comments';
import { ConfirmationModal } from './confirmation_modal';
import { TicketAttributes } from './ticket_attributes';

const ZendeskTicket: React.FC<{
  ticketAssociation: ZendeskTicketAssociationFragment;
}> = ({ ticketAssociation }) => {
  const [collapsed, setCollapsed] = useState<boolean>(true);
  const { zdTicketID } = ticketAssociation;
  return (
    <>
      <Accordion.Toggle collapsed={collapsed} onToggle={setCollapsed}>
        <TicketAttributes ticketAssociation={ticketAssociation} />
      </Accordion.Toggle>
      <Accordion.Collapse collapsed={collapsed}>
        <Comments zdTicketID={zdTicketID} />
      </Accordion.Collapse>
    </>
  );
};

const ZendeskTickets: React.FC<{
  ticketAssociations: ZendeskTicketAssociationFragment[];
}> = ({ ticketAssociations }) => {
  if (!ticketAssociations || ticketAssociations.length === 0) {
    return <Text tag="p">There are no Zendesk tickets associated with this customer ticket.</Text>;
  }

  return (
    <Accordion>
      {ticketAssociations.map((ticketAssociation) => (
        <ZendeskTicket key={ticketAssociation.id} ticketAssociation={ticketAssociation} />
      ))}
    </Accordion>
  );
};

export const ZendeskTicketsPanel: React.FC<{
  customerTicketID: string;
  ticketAssociations: ZendeskTicketAssociationFragment[];
}> = ({ customerTicketID, ticketAssociations }) => {
  const [showModal, setShowModal] = useState<boolean>(false);

  return (
    <>
      <Panel className="mar-top">
        <Panel.Header>
          <Panel.Title>Zendesk Tickets</Panel.Title>
        </Panel.Header>
        <Panel.Body>
          <ZendeskTickets ticketAssociations={ticketAssociations} />
        </Panel.Body>
        <Panel.Footer align="right">
          <Button kind="primary" onClick={() => setShowModal(true)}>
            Create Zendesk Ticket
          </Button>
        </Panel.Footer>
      </Panel>
      {showModal && <ConfirmationModal customerTicketID={customerTicketID} onClose={() => setShowModal(false)} />}
    </>
  );
};

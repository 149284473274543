(function () {
  angular.module('app').service('TZDateTimeService', [
    function () {
      const self = this;

      // Converts a value from a locale TZ to UTC.
      self.utcForValueWithTZ = function (value, tz) {
        return value && tz ? new Date(moment.utc(moment.tz(value, tz).format('YYYY-MM-DD HH:mm'))) : null;
      };

      // Converts date and time components from a locale TZ to UTC.
      self.utcForDateTimeWithTZ = function (date, time, tz) {
        if (date && time && tz) {
          const format = moment.tz(date, tz).format('YYYY-MM-DD') + ' ' + moment.tz(date, tz).format('HH:mm');
          return new Date(moment.utc(format));
        } else {
          return null;
        }
      };

      // Converts a value from UTC to a locale TZ.
      self.localForValueWithTZ = function (value, tz) {
        return value && tz ? new Date(moment.tz(moment(value).utc().format('YYYY-MM-DD HH:mm'), tz)) : null;
      };

      // Converts date and time components from UTC to a locale.
      self.localForDateTimeWithTZ = function (date, time, tz) {
        if (date && time && tz) {
          const format = moment(date).utc().format('YYYY-MM-DD') + ' ' + moment(time).utc().format('HH:mm');
          return new Date(moment.utc(moment.tz(format, tz)));
        } else {
          return null;
        }
      };
    },
  ]);
})();

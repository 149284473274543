import angular from 'angular';

const app = angular.module('app');

app.factory('BusinessHours', [
  '$resource',
  ($resource) =>
    $resource('/business_hours/show/:date/:dept.json', {
      date: '@date',
      dept: '@dept',
    }),
]);

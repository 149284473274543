(function () {
  angular.module('app').filter('addressParking', function () {
    return function (address) {
      function combineStreetParking() {
        const components = [];
        if (address.parking_street) {
          if (address.parking_in_front) {
            components.push('in front of building');
          } else {
            components.push('park on ' + address.parking_on_specific_street);
          }
        }

        if (address.parking_permit) {
          components.push('permit required');
        }

        if (components.length) {
          return 'Street (' + components.join(', ') + ')';
        }
      }

      const components = [];

      const combinedStreetParking = combineStreetParking();
      if (combinedStreetParking) {
        components.push(combinedStreetParking);
      }

      if (address.parking_driveway) {
        components.push('Driveway');
      }
      if (address.parking_lot) {
        components.push('Lot');
      }
      if (address.parking_alley) {
        components.push('Alley / Behind Building');
      }

      if (address.parking_instructions) {
        components.push('Notes: ' + address.parking_instructions);
      }

      if (!components) {
        components.push('N/A');
      }

      return components;
    };
  });
})();

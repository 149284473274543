(function () {
  angular.module('app').filter('dateRange', function () {
    return function (input, min, max, exclude) {
      min = parseInt(min);
      max = parseInt(max);
      exclude = parseInt(exclude);
      for (let i = min; i <= max; i++) {
        if (i === exclude) {
          continue;
        }
        input.push(i.toString());
      }
      return input;
    };
  });
})();

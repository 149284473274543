import React from 'react';
import { Form as FormikForm, Formik, FormikErrors } from 'formik';

import { client } from '@admin/libraries/apollo';
import {
  Availability__BlockReasonEnum,
  Availability__JobAvailabilitiesCreateInput,
  Status,
  useAvailabilityJobAvailabilitiesCreateMutation,
  useRegionsSelectorQuery,
} from '@admin/schema';
import { Alert, AnchorButton, Breadcrumb, Button, Panel } from '@shared/components/bootstrap';
import { FormikInputFormGroup } from '@shared/components/fields/formik/formik_input_form_group';
import { FormikMultiselectFormGroup } from '@admin/components/fields/formik/formik_multiselect_form_group';
import { PageHeader } from '@admin/components/helpers/page_header';
import { jobAvailabilityListURL } from '@admin/config/routes';

import { FormikSelectFormGroup } from '@shared/components/fields/formik/formik_select_form_group';
import { FormikMultiselectCalendarGroup } from '@admin/components/fields/formik/formik_multiselect_calendar_group';
import { KIND_OPTIONS } from './utils';
import { BLOCK_REASON_OPTIONS } from '../util';

const DEFAULT_VALUES: Availability__JobAvailabilitiesCreateInput = {
  dates: [],
  kinds: [],
  regionIDs: [],
  maximumMoverMinutes: 0,
  reason: '',
};

const validateInput = ({
  dates,
  kinds,
  regionIDs,
  maximumMoverMinutes,
  reason,
}: Availability__JobAvailabilitiesCreateInput): FormikErrors<Availability__JobAvailabilitiesCreateInput> => {
  const formErrors: FormikErrors<Availability__JobAvailabilitiesCreateInput> = {};

  if (dates.length === 0) {
    formErrors.dates = 'At least one date is required';
  }

  if (!kinds || kinds.length === 0) {
    formErrors.kinds = 'At least one kind is required';
  }

  if (!regionIDs || regionIDs.length === 0) {
    formErrors.regionIDs = 'At least one region is required';
  }

  if (maximumMoverMinutes < 0) {
    formErrors.maximumMoverMinutes = 'The maximum mover minutes must be at least 0';
  }

  if (!reason) {
    formErrors.reason = 'A reason must be provided';
  }

  return formErrors;
};

export const JobAvailabilityForm: React.FC = () => {
  const [save, { loading, data }] = useAvailabilityJobAvailabilitiesCreateMutation({ client });
  const { data: regionData } = useRegionsSelectorQuery({ client });
  const regions = regionData?.regions ?? [];

  const onSubmit = async (input: Availability__JobAvailabilitiesCreateInput) => {
    const results = await save({
      variables: {
        input,
      },
    });

    if (results.data?.result.status === Status.Ok) {
      window.location.href = jobAvailabilityListURL();
    }
  };

  return (
    <>
      <PageHeader>
        <Breadcrumb>
          <Breadcrumb.Item>
            <a href={jobAvailabilityListURL()}>Job Availability</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item active>New One-Off</Breadcrumb.Item>
        </Breadcrumb>
      </PageHeader>
      <Formik<Availability__JobAvailabilitiesCreateInput>
        initialValues={DEFAULT_VALUES}
        onSubmit={onSubmit}
        validate={validateInput}
        validateOnChange={false}
        validateOnBlur={false}
      >
        <FormikForm>
          <Panel>
            <Panel.Body>
              {data?.result?.error && <Alert style="danger">{data.result.error}</Alert>}
              {data?.result?.status === Status.Ok && (
                <Alert style="success">{`Successfully created new Job Availability entries.`}</Alert>
              )}
              <FormikMultiselectCalendarGroup id="dates" name="dates" label="Dates" />
              <FormikMultiselectFormGroup id="regionIDs" name="regionIDs" label="Regions:" entries={regions} />
              <FormikSelectFormGroup id="reason" name="reason" label="Reason:">
                <option value=""> - Select Reason - </option>
                {BLOCK_REASON_OPTIONS.filter(
                  (reason) => reason.id !== Availability__BlockReasonEnum.WeeklySchedule,
                ).map((reason) => (
                  <option key={reason.id} value={reason.name}>
                    {reason.name}
                  </option>
                ))}
              </FormikSelectFormGroup>
              <FormikMultiselectFormGroup id="kinds" name="kinds" label="Kinds:" entries={KIND_OPTIONS} />
              <FormikInputFormGroup
                id="maximumMoverMinutes"
                type="number"
                name="maximumMoverMinutes"
                label="Maximum Mover Minutes:"
              />
            </Panel.Body>
            <Panel.Footer align="right">
              <AnchorButton disabled={loading} kind="default" href={jobAvailabilityListURL()}>
                Cancel
              </AnchorButton>
              <Button loading={loading} kind="primary" type="submit">
                Save
              </Button>
            </Panel.Footer>
          </Panel>
        </FormikForm>
      </Formik>
    </>
  );
};

import React from 'react';
import styled from '@emotion/styled';
import { Form as FormikForm, Formik, FormikErrors, FormikHelpers } from 'formik';

import { Alert, AnchorButton, Breadcrumb, Button, Panel } from '@shared/components/bootstrap';
import { availabilityLaborAdjustmentListURL } from '@admin/config/routes';
import { PageHeader } from '@admin/components/helpers/page_header';
import {
  Availability__LaborAdjustmentsCreateInput,
  Status,
  useAvailabilityLaborAdjustmentsCreateMutation,
  useRegionsSelectorQuery,
} from '@admin/schema';
import { client } from '@admin/libraries/apollo';
import { FormikMultiselectFormGroup } from '@admin/components/fields/formik/formik_multiselect_form_group';
import { FormikMultiselectCalendarGroup } from '@admin/components/fields/formik/formik_multiselect_calendar_group';

import { CLASSIFICATION_OPTIONS } from '../util';
import { FormikAdjustmentRanges } from './formik_adjustment_ranges';

const DEFAULT_VALUES: Availability__LaborAdjustmentsCreateInput = {
  dates: [],
  regionIDs: [],
  orderClassifications: [],
  adjustmentRanges: [{ lowThresholdPercentage: 0, highThresholdPercentage: 0, perMoverHourAdjustmentAmount: 0 }],
};

const Container = styled.div`
  .form-group {
    margin-bottom: 24px;
  }
`;

const validateInput = ({
  dates,
  regionIDs,
  orderClassifications,
  adjustmentRanges,
}: Availability__LaborAdjustmentsCreateInput): FormikErrors<Availability__LaborAdjustmentsCreateInput> => {
  const formErrors: FormikErrors<Availability__LaborAdjustmentsCreateInput> = {};

  if (dates.length === 0) {
    formErrors.dates = 'At least one date is required';
  }

  if (regionIDs.length === 0) {
    formErrors.regionIDs = 'At least one region is required';
  }

  if (orderClassifications.length === 0) {
    formErrors.orderClassifications = 'At least one order classification is required';
  }

  if (adjustmentRanges.length === 0) {
    formErrors.adjustmentRanges = 'At least one adjustment range is required';
  }

  return formErrors;
};

export const AvailabilityLaborAdjustmentForm: React.FC = () => {
  const [save, { loading, data }] = useAvailabilityLaborAdjustmentsCreateMutation({ client });
  const { data: regionData } = useRegionsSelectorQuery({ client });
  const regions = regionData?.regions ?? [];

  const onSubmit = async (
    input: Availability__LaborAdjustmentsCreateInput,
    { resetForm }: FormikHelpers<Availability__LaborAdjustmentsCreateInput>,
  ) => {
    const results = await save({
      variables: { input },
    });

    if (results.data?.result.status === Status.Ok) {
      resetForm();
    }
  };

  return (
    <Container>
      <PageHeader>
        <Breadcrumb>
          <Breadcrumb.Item>
            <a href={availabilityLaborAdjustmentListURL()}>Availability Labor Adjustments</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item active>New</Breadcrumb.Item>
        </Breadcrumb>
      </PageHeader>
      <Formik<Availability__LaborAdjustmentsCreateInput>
        initialValues={DEFAULT_VALUES}
        onSubmit={onSubmit}
        validate={validateInput}
        validateOnChange={false}
        validateOnBlur={false}
      >
        <FormikForm>
          <Panel>
            <Panel.Body>
              <FormikMultiselectFormGroup id="regionIDs" name="regionIDs" label="Regions:" entries={regions} />
              <FormikMultiselectFormGroup
                id="orderClassifications"
                name="orderClassifications"
                label="Order Classifications:"
                entries={CLASSIFICATION_OPTIONS}
              />
              <FormikMultiselectCalendarGroup id="dates" name="dates" label="Dates" />
              <FormikAdjustmentRanges id="adjustmentRanges" name="adjustmentRanges" label="Adjustment Ranges" />
              {data?.result?.error && <Alert style="danger">{data.result.error}</Alert>}
              {data?.result?.status === Status.Ok && (
                <Alert style="success">Successfully created new Availability Labor Adjustments.</Alert>
              )}
            </Panel.Body>
            <Panel.Footer align="right">
              <AnchorButton disabled={loading} kind="default" href={availabilityLaborAdjustmentListURL()}>
                Cancel
              </AnchorButton>
              <Button loading={loading} kind="primary" type="submit">
                Save
              </Button>
            </Panel.Footer>
          </Panel>
        </FormikForm>
      </Formik>
    </Container>
  );
};

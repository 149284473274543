(function () {
  const app = angular.module('app');

  app.component('storageSnapshotButton', {
    templateUrl: 'partials/storage_snapshot_button/widget.html',
    bindings: {
      accountId: '=',
    },
    controller: [
      '$rootScope',
      'ConfirmationService',
      'AccountMetrics',
      'Usage',
      '$filter',
      'ErrorService',
      'ItemCategorizationObserverService',
      function (
        $rootScope,
        ConfirmationService,
        AccountMetrics,
        Usage,
        $filter,
        ErrorService,
        ItemCategorizationObserverService,
      ) {
        const self = this;

        self.saveSnapshot = function () {
          self.createSnapshot();
        };

        self.$onInit = function () {
          const metricsPromise = AccountMetrics.get({ id: self.accountId }).$promise;
          self.refresh(metricsPromise);
          ItemCategorizationObserverService.registerCallback(self.refresh);
        };

        self.$onDestroy = function () {
          ItemCategorizationObserverService.removeCallback(self.refresh);
        };

        self.refresh = function (metricsPromise) {
          metricsPromise.then(function (metrics) {
            self.accountMetrics = metrics;
          });
        };

        self.createSnapshot = function () {
          self.saving = true;
          const usage = new Usage({ account_id: self.accountId });
          usage.$save(
            function () {
              $rootScope.$broadcast('message:display', {
                type: 'info',
                body: 'Snapshot Successfully Created!',
              });
              delete self.saving;
            },
            function (error) {
              ErrorService.handle(error);
              delete self.saving;
            },
          );
        };
      },
    ],
  });
})();

import { Claim__Assessment__Classification, Claim__IssueSelection__Kind } from '@admin/schema';

export const DISPLAYABLE_CLASSIFICATION = {
  [Claim__Assessment__Classification.MinorDamage]: 'Minor Damage',
  [Claim__Assessment__Classification.MajorDamage]: 'Major Damage',
  [Claim__Assessment__Classification.TotalLoss]: 'Total Loss',
};

export const DISPLAYABLE_ISSUE = {
  [Claim__IssueSelection__Kind.Appointment]: 'Appointment',
  [Claim__IssueSelection__Kind.Billing]: 'Billing',
  [Claim__IssueSelection__Kind.Other]: 'Other',
};

import { Dialog, useConfirmation } from '@admin/components/confirmation';
import { Roles } from '@admin/components/helpers/roles';
import { client } from '@admin/libraries/apollo';
import { Maybe, useOrderUserUpdateMutation, User__Role, useUsersSelectorQuery } from '@admin/schema';
import { UserRole } from '@admin/types';
import { Button } from '@shared/components/bootstrap';
import { parseGQLErrorUnion } from '@shared/utils/gql_errors';
import React, { useState, useEffect } from 'react';

export const BookedByToggle = ({ order }: { order: { id: string; user?: Maybe<{ name: string; id: string }> } }) => {
  const [active, setActive] = useState(false);
  const [userId, setUserId] = useState(order.user?.id);
  const [updateUser, { error, data }] = useOrderUserUpdateMutation({ client });
  const { confirm, confirmation } = useConfirmation();

  const resolvedError = error ?? (data && parseGQLErrorUnion(data?.orderUserUpdate?.result)[1]);

  useEffect(() => {
    if (resolvedError) {
      confirm({
        title: 'Error',
        description: resolvedError instanceof Error ? resolvedError.message : resolvedError.errorMessage,
        showCancel: false,
        confirm: 'OK',
      });
    }
  }, [resolvedError, confirm]);

  const users =
    useUsersSelectorQuery({
      variables: { filters: { roles: [User__Role.L2Agent] } },
      skip: !active,
      client,
    }).data?.users || [];

  return (
    <p>
      <strong>Booked by:</strong>{' '}
      {active ? (
        <>
          <select value={userId} disabled={!users.length} onChange={(e) => setUserId(e.currentTarget.value)}>
            {users.map((user) => (
              <option key={user.id} value={user.id}>
                {user.name}
              </option>
            ))}
          </select>
          <Button
            kind="danger"
            onClick={() => {
              setActive(false);
            }}
          >
            Cancel
          </Button>
        </>
      ) : (
        <span>{order.user?.name ?? 'Customer'}</span>
      )}
      <Roles show={[UserRole.Admin, UserRole.Manager]}>
        {' '}
        <Button
          kind="primary"
          onClick={() => {
            if (active && userId) updateUser({ variables: { orderID: order.id, userID: userId } });
            setActive((value) => !value);
          }}
        >
          {active ? 'Save' : 'Edit'}
        </Button>
      </Roles>
      {confirmation && <Dialog confirmation={confirmation} />}
    </p>
  );
};

(function () {
  angular.module('app').factory('Voicemail', [
    '$resource',
    function ($resource) {
      return $resource(
        '/voicemails/:id.json',
        {
          id: '@id',
        },
        {
          index: {
            method: 'GET',
            isArray: true,
          },
          show: {
            method: 'GET',
            isArray: false,
          },
        },
      );
    },
  ]);
})();

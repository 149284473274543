import React from 'react';

import { ROLES } from '@admin/config/roles';
import { Spinner } from '@admin/components/spinner';
import { Picker } from '@admin/components/ticket_groups/picker';
import { useInternalTicketGroupsQuery } from '@admin/schema';
import { UserRole } from '@admin/types';
import { client } from '@admin/libraries/apollo';
import { intersection } from 'lodash';

export const TicketGroupPicker: React.FC<{
  className?: string;
  selectedGroupID?: string;
  selectedAssigneeID?: string;
  onChange(groupID?: string, userID?: string): void;
}> = ({ className, selectedGroupID, selectedAssigneeID, onChange }) => {
  const { data, loading } = useInternalTicketGroupsQuery({ client });

  if (!data || loading) {
    return <Spinner />;
  }

  let groups = data.internalTicketGroups;

  // Only Admin users can directly assign to Tech Support Groups.
  if (intersection(ROLES, [UserRole.Admin]).length === 0) {
    groups = groups.filter((g) => g.name !== 'Tech Support' && g.name !== 'Growth Tech Support');
  }

  return (
    <Picker
      ticketGroups={[...groups].sort((a, b) => (a.name < b.name ? -1 : 1))}
      className={className}
      selectedGroupID={selectedGroupID}
      selectedAssigneeID={selectedAssigneeID}
      onChange={onChange}
    />
  );
};

import { Spinner } from '@admin/components/spinner';
import { useSelfStorageShuttleQuery } from '@admin/schema';
import { client } from '@admin/libraries/apollo';
import React from 'react';

export const ShuttleFacilityAddress: React.FC<{
  shuttleID: string;
  nameOnly?: boolean;
}> = ({ shuttleID, nameOnly }) => {
  const { data, loading } = useSelfStorageShuttleQuery({ client, variables: { id: shuttleID } });

  const facility = data?.shuttle?.reservation?.facility;

  if (loading) {
    return <Spinner />;
  } else if (!facility) {
    return <span>Reservation no longer linked to this shuttle order</span>;
  } else if (nameOnly) {
    return <span>{facility.name}</span>;
  } else {
    return (
      <div>
        <strong>Facility: </strong>
        {facility.name}
        <a target="_blank" href={facility.address.map}>
          <div className="address">
            <div className="address-line">
              <span className="address-street">{facility.address.street}</span>
              <span className="address-aptsuite"> {facility.address.aptsuite}</span>
            </div>
            {facility.address.businessName && (
              <div className="address-line">
                <span className="address-business-name">{facility.address.businessName}</span>
              </div>
            )}
            <span className="address-separator"> </span>
            <div className="address-line">
              <span className="address-state">{facility.address.state}</span>
              <span className="address-zip"> {facility.address.zip}</span>
            </div>
          </div>
        </a>
      </div>
    );
  }
};

import styled from '@emotion/styled';
import { faClipboard, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';

import { COLORS } from '@root/colors';

enum Status {
  Default,
  Copied,
  Failed,
}

const Button = styled.button<{ status: Status }>`
  border: none;
  background: none;

  color: ${({ status }) => {
    switch (status) {
      case Status.Default:
        return COLORS.gray;
      case Status.Copied:
        return COLORS.green;
      case Status.Failed:
        return COLORS.red;
    }
  }};

  &:hover {
    color: ${COLORS.blue};
  }
`;

export const CopyText: React.FC<{ text: string }> = ({ text }) => {
  const [status, setStatus] = useState<Status>(Status.Default);

  useEffect(() => {
    if (status !== Status.Default) {
      const timeout = setTimeout(() => {
        setStatus(Status.Default);
      }, 2000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [status]);

  const onClick = () => {
    try {
      navigator.clipboard.writeText(text);
      setStatus(Status.Copied);
    } catch (error) {
      setStatus(Status.Failed);
    }
  };

  return (
    <Button status={status} type="button" onClick={onClick}>
      <FontAwesomeIcon
        icon={(() => {
          switch (status) {
            case Status.Default:
              return faClipboard;
            case Status.Copied:
              return faCheck;
            case Status.Failed:
              return faTimes;
          }
        })()}
      />
    </Button>
  );
};

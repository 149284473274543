import { createDepotURL, editDepotURL } from '@admin/config/routes';
import { client } from '@admin/libraries/apollo';
import * as React from 'react';
import { useDepotsQuery } from '@admin/schema';
import { ROLES } from '@admin/config/roles';
import { UserRole } from '@admin/types';
import { Box } from '@clutter/clean';

interface RegionDetailsProperties {
  regionID: string;
  defaultDepotID?: string | null;
  modifyingRegion: boolean;
  onClickMakeDefault(newDefaultDepotID: string): void;
}

export const DepotsList: React.FC<{
  regionDetailsProperties?: RegionDetailsProperties;
  showPanelHeading?: boolean;
}> = ({ regionDetailsProperties, showPanelHeading = true }) => {
  const regionID = regionDetailsProperties?.regionID;
  const defaultDepotID = regionDetailsProperties?.defaultDepotID;
  const { data } = useDepotsQuery({ client, variables: { filters: regionID ? { regionIDs: [regionID] } : undefined } });

  const editAccess = ROLES.includes(UserRole.Admin);

  return (
    <div className="panel depot-list">
      {showPanelHeading && (
        <div className="panel-heading">
          <h3 className="panel-title depot-list__title">Depots</h3>
        </div>
      )}
      <div className="panel-body">
        <div className="table-responsive">
          <table className="table table-striped depot-list__table">
            <thead>
              <tr className="depot-list__row">
                <th className="col-sm-2 text-left">Name</th>
                <th className="col-sm-1 text-left">Abbreviation</th>
                <th className="col-sm-4 text-left">Address</th>
                <th className="col-sm-3 text-left">Regions</th>
                <th className="col-sm-1 text-left">Status</th>
                {editAccess && (
                  <th className="col-sm-1 text-right">
                    <a className="btn btn-mint btn-labeled fa fa-plus" href={createDepotURL()}>
                      Add
                    </a>
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {data &&
                data.depots &&
                data.depots.map((depot) => (
                  <tr className="depot-list__row" key={depot.id}>
                    <td className="col-sm-2 text-left">{depot.name}</td>
                    <td className="col-sm-1 text-left">{depot.abbreviation}</td>
                    <td className="col-sm-4 text-left">
                      {depot.address.aptsuite !== '' && depot.address.aptsuite !== null
                        ? `${depot.address.aptsuite}, ${depot.address.street}, ${depot.address.city}, ${depot.address.state}`
                        : `${depot.address.street}, ${depot.address.city}, ${depot.address.state}`}
                    </td>
                    <td className="col-sm-3 text-left">{depot.regions.map((r) => r.name).join(', ')}</td>
                    <td className="col-sm-1 text-left">
                      {depot.active ? 'Active' : 'Inactive'} {defaultDepotID === depot.id ? '(Default)' : undefined}
                    </td>
                    {editAccess && (
                      <td className="col-sm-1 text-right">
                        <Box.Flex padding="16px" gap={'4px'} justifyContent={'right'}>
                          {defaultDepotID !== depot.id && (
                            <button
                              className="btn btn-warning"
                              onClick={() => regionDetailsProperties?.onClickMakeDefault(depot.id)}
                              disabled={regionDetailsProperties?.modifyingRegion}
                            >
                              Make Default
                            </button>
                          )}
                          <a className="btn btn-primary" href={editDepotURL({ id: depot.id })}>
                            Edit
                          </a>
                        </Box.Flex>
                      </td>
                    )}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

import * as React from 'react';

export const Reschedule: React.FC<{
  accountID: string;
  orderID: string;
}> = ({ accountID, orderID }) => (
  <a href={`/accounts/${accountID}/orders/${orderID}/reschedule`} className="btn btn-primary">
    Reschedule
  </a>
);

(function () {
  const app = angular.module('app');

  app.controller('InvoiceTableController', [
    '$scope',
    'ngDialog',
    'Invoice',
    'PaginationService',
    function ($scope, ngDialog, Invoice, PaginationService) {
      const self = this;

      self.account_id = $scope.id;

      self.getInvoices = function () {
        Invoice.get(
          {
            account_id: $scope.id,
            page: self.pagination.page,
          },
          function (response) {
            self.pagination.prepare(response.pagination);
            $scope.$parent.invoices = response.results;
            self.invoices = response.results;
          },
        );
      };

      self.openBillingDateChangeModal = function (account) {
        ngDialog.openConfirm({
          template: 'partials/invoices/billing_date_change_modal.html',
          controllerAs: 'billingDateChangeModal',
          controller: [
            '$scope',
            '$rootScope',
            'BillingDateChange',
            function ($scope, $rootScope, BillingDateChange) {
              // eslint-disable-next-line no-shadow
              const self = this;
              self.loading = false;

              self.closeModal = function () {
                $scope.confirm();
              };

              self.closeModal = function () {
                $scope.confirm();
              };

              self.lastChanged = function () {
                return account.billing_date_changed_on;
              };

              self.subscriptionBillingDay = function () {
                return account.subscription_billing_day;
              };

              self.getBillingDateChange = function () {
                self.loading = true;
                self.errors = null;
                BillingDateChange.get(
                  {
                    account_id: account.id,
                    day_of_month: self.dayOfMonth,
                  },
                  function (response) {
                    self.preview = response;
                  },
                );
                self.loading = false;
              };

              self.postBillingDateChange = function () {
                self.loading = true;
                BillingDateChange.save(
                  {
                    account_id: account.id,
                    day_of_month: self.dayOfMonth,
                  },
                  function () {
                    account.billing_date_changeable = false;
                    self.closeModal();
                    $rootScope.$broadcast('message:display', {
                      type: 'info',
                      body: 'Billing Date Succesfully Changed!',
                    });
                  },
                  function (response) {
                    self.loading = false;
                    self.errors = response.data.message;
                  },
                );
              };
            },
          ],
        });
      };

      self.pagination = PaginationService.initialize();
      self.getInvoices();

      // Listeners
      $scope.$on('account:refresh', function () {
        self.getInvoices();
      });

      self.latest_refund_date = function (invoice) {
        return moment.max(
          _.map(invoice.refunds, function (refund) {
            return moment(refund.date);
          }),
        )._i;
      };
    },
  ]);
})();

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { createStructuredSelector } from 'reselect';
import { isEmpty, isEqual, find, map, flatten, flowRight, assign } from 'lodash';
import { Col, Grid, Row } from '@shared/components/bootstrap';
import DashboardMap from './DashboardMap';
import RegionDateSelector from './RegionDateSelector';
import RoutePanels from './RoutePanels';

import {
  loadRegionEntities,
  loadDispatchEntities,
  listenToRegionEntities,
  listenToDispatchEntities,
} from '../../../redux';
import { selectFilteredDispatches, selectFilteredRegion, selectFilteredCheckins } from './selectors';
import { withRouterState } from '../../../helpers/routes';

/**
 * Layout component - holds page emphemeral state like ready state, focus, and hover
 */
class Dashboard extends React.Component {
  static propTypes = {
    date: PropTypes.object,
    regionId: PropTypes.number,
    regions: PropTypes.array,
    checkins: PropTypes.array,
    activeOrderId: PropTypes.number,
    activeDispatchId: PropTypes.number,
    activeCheckinId: PropTypes.number,
    go: PropTypes.func.isRequired,
    $state: PropTypes.object.isRequired,
  };

  state = {
    hoveredOrderId: null,
    hoveredDispatchedId: null,
    hoveredCheckinId: null,
    ready: false,
    mapReady: false,
  };

  constructor() {
    super();
    this.changeRegionState = this.changeRegionState.bind(this);
    this.changeDateState = this.changeDateState.bind(this);
    this.subscribeToDispatches = this.subscribeToDispatches.bind(this);
    this.subscribeToRegion = this.subscribeToRegion.bind(this);
    this.unsubDispatches = this.unsubDispatches.bind(this);
    this.unsubRegion = this.unsubRegion.bind(this);
  }

  async componentDidMount() {
    this.subscribeToDispatches(this.props.dispatches);
    this.subscribeToRegion(this.props.regionId);
    const promises = [this.props.loadRegionEntities()];

    // if region is not set - do not load
    if (this.props.regionId && this.props.date) {
      promises.push(
        this.props.loadDispatchEntities({
          region_id: this.props.regionId,
          date: moment(this.props.date).format('YYYY-MM-DD'),
        }),
      );
    }
    await Promise.all(promises);
    this.setState({ ready: true });
  }

  async componentDidUpdate(prevProps) {
    const { date: previousDate, regionId: previousRegionId, dispatches: previousDispatches } = prevProps;
    const { date: currentDate, regionId: currentRegionId, dispatches: currentDispatches } = this.props;

    const promises = [];
    if (previousRegionId !== currentRegionId || previousDate !== currentDate) {
      // load new region on region change
      promises.push(
        this.subscribeToRegion(currentRegionId),
        this.props.loadDispatchEntities({
          region_id: currentRegionId,
          date: moment(currentDate).format('YYYY-MM-DD'),
        }),
      );
    }

    // subscribe to new dispatches on dispatch change
    if (
      !isEqual(
        previousDispatches.map((dispatch) => dispatch.id),
        currentDispatches.map((dispatch) => dispatch.id),
      )
    ) {
      promises.push(this.subscribeToDispatches(currentDispatches));
    }
    if (isEmpty(promises)) {
      return;
    }
    this.setState({ ready: false });
    await Promise.all(promises);
    this.setState({ ready: true });
  }

  /**
   * Unsubs existing dispatch subscriptions and subscribes to new ones
   * @param dispatches
   */
  subscribeToDispatches(dispatches) {
    this.unsubDispatches();
    if (isEmpty(dispatches)) {
      return;
    }
    const dispatchIds = dispatches.map((dispatch) => dispatch.id);
    this._dispatchesUnsubscriptions = dispatchIds.map((dispatchId) => this.props.listenToDispatchEntities(dispatchId));
  }

  subscribeToRegion(regionId) {
    this.unsubRegion();
    if (!regionId) {
      return;
    }
    this._regionUnsubscription = this.props.listenToRegionEntities(regionId);
  }

  unsubRegion() {
    if (this._regionUnsubscription) {
      this._regionUnsubscription();
      delete this._regionUnsubscription;
    }
  }

  unsubDispatches() {
    if (this._dispatchesUnsubscriptions) {
      this._dispatchesUnsubscriptions.forEach((unsub) => unsub());
      delete this._dispatchesUnsubscriptions;
    }
  }

  componentWillUnmount() {
    this.unsubRegion();
    this.unsubDispatches();
  }

  _getFormattedStateParamDate = (date = this.props.date) =>
    date && moment(date).isValid() ? moment(date).format('YYYY-MM-DD') : null;

  _getCurrentUrlState = () => {
    const { activeDispatchId: dispatch, activeOrderId: order, activeCheckinId: checkin, regionId: region } = this.props;
    return {
      date: this._getFormattedStateParamDate(),
      dispatch,
      order,
      checkin,
      region,
    };
  };

  _updateUrlState = (changes) => this.props.go(assign({}, this._getCurrentUrlState(), changes));

  /**
   * Routing method for region
   * @param event
   */
  changeRegionState(event) {
    const region = event && event.value;
    this._updateUrlState({
      region,
    });
  }

  /**
   * Routing method for date
   * @param date
   */
  changeDateState(date) {
    this._updateUrlState({
      date: this._getFormattedStateParamDate(date),
    });
  }

  _findOrder = (id, props = this.props) => find(flatten(map(props.dispatches, 'orders')), { id });

  _findCheckin = (id, props = this.props) => find(props.checkins, { id });

  /**
   * State management regarding focus that's shared by child components.
   * Focus is specific to the dashboard so redux isn't a good implementation for this.
   * @returns {...hoveredIds, ...activeIds, ...activeHandlers}
   * @private
   */
  _eventSystem = () => {
    const { hoveredDispatchId, hoveredOrderId, hoveredCheckinId } = this.state;
    const { activeDispatchId, activeOrderId, activeCheckinId } = this.props;
    return {
      activeDispatchId,
      activeOrderId,
      activeCheckinId,
      hoveredDispatchId,
      hoveredOrderId,
      hoveredCheckinId,
      onDispatchClick: (id) => {
        if (!id || this.props.activeDispatchId === id) {
          return this._updateUrlState({ dispatch: null, order: null });
        }
        this._updateUrlState({ dispatch: id, order: null, checkin: null });
      },
      onOrderClick: (id) => {
        if (!id || this.props.activeOrderId === id) {
          return this._updateUrlState({ order: null });
        }
        const order = this._findOrder(id);
        this._updateUrlState({
          order: id,
          dispatch: order.dispatch_id,
          checkin: null,
        });
      },
      onDispatchHover: (id) => this.setState({ hoveredDispatchId: id }),
      onDispatchHoverOut: () => this.setState({ hoveredDispatchId: null }),
      onOrderHover: (id) => this.setState({ hoveredOrderId: id }),
      onOrderHoverOut: () => this.setState({ hoveredOrderId: null }),
      onCheckinClick: (id) => {
        if (!id || this.props.activeCheckinId === id) {
          return this._updateUrlState({ checkin: null });
        }
        const checkin = this._findCheckin(id);
        this._updateUrlState({
          order: null,
          dispatch: checkin.dispatch.id,
          checkin: id,
        });
      },
      onCheckinHover: (id) => this.setState({ hoveredCheckinId: id }),
      onCheckinHoverOut: () => this.setState({ hoveredCheckinId: null }),
    };
  };

  /**
   * <input /> Date is temporary
   * @returns {Element}
   */
  render() {
    const { activeDispatchId, activeOrderId, regionId, date } = this.props;
    const { ready, mapReady } = this.state;
    return (
      <Grid fluid className="dashboard-grid">
        <Row className="row-no-padding">
          <Col sm={3} className="dashboard-feed" id="dispatches_wrapper">
            <RegionDateSelector
              onRegionChange={this.changeRegionState}
              ready={ready && mapReady}
              regionId={regionId}
              date={date}
              onDateChange={this.changeDateState}
            />
            <RoutePanels
              {...this._eventSystem()}
              activeOrderId={activeOrderId}
              activeDispatchId={activeDispatchId}
              date={date}
              regionId={regionId}
            />
          </Col>
          <Col sm={9}>
            <DashboardMap
              ready={ready}
              {...this._eventSystem()}
              date={date}
              onReadyChange={(updatedMapReady) => this.setState({ mapReady: updatedMapReady })}
              regionId={regionId}
            />
          </Col>
        </Row>
      </Grid>
    );
  }
}
export default flowRight(
  withRouterState,
  connect(
    createStructuredSelector({
      dispatches: selectFilteredDispatches,
      region: selectFilteredRegion,
      checkins: selectFilteredCheckins,
    }),
    {
      loadRegionEntities,
      loadDispatchEntities,
      listenToRegionEntities,
      listenToDispatchEntities,
    },
  ),
)(Dashboard);

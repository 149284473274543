import * as React from 'react';
import { useState } from 'react';

import { Panel } from '@admin/components/helpers/panel';
import { Pagination } from '@admin/components/pagination';
import { Spinner } from '@admin/components/spinner';
import { newAccountShuttleURL } from '@admin/config/routes';
import { AnchorButton as Link, Text } from '@shared/components/bootstrap';

import { IPagination } from '@shared/types';

import {
  cancelSelfStorageRentalURL,
  editBillingSelfStorageRentalURL,
  newSelfStorageRentalURL,
} from '@admin/config/routes';

import { usePaginatedSelfStorageRentalsQuery, useSelfStorageRentalPolicyQuery } from '@admin/schema';

import { client } from '@admin/libraries/apollo';
import { Entries } from './rentals/entries';

export const Rentals: React.FC<{ accountID: number | string }> = ({ accountID }) => {
  const [page, setPage] = useState<number | undefined>();
  const { data, loading } = usePaginatedSelfStorageRentalsQuery({
    client,
    variables: { filters: { accountID: String(accountID) }, page },
  });
  const { data: policy } = useSelfStorageRentalPolicyQuery({ client });

  const paginated = data?.paginated;
  const permissions = policy?.permissions;
  return (
    <Panel>
      <Panel.Header>
        <Panel.Title>Self-Storage</Panel.Title>
      </Panel.Header>
      <Panel.Body>
        {loading && <Spinner />}
        {paginated && <Entries rentals={paginated.results} permissions={permissions} />}
        {paginated && <Pagination onPage={setPage} pagination={paginated.pagination as IPagination} />}
      </Panel.Body>
      <Panel.Footer>
        {permissions?.modify && (
          <Text alignment="right" tag="div">
            <Link kind="primary" href={newAccountShuttleURL({ accountID })}>
              New Shuttle
            </Link>{' '}
            <Link kind="default" href={editBillingSelfStorageRentalURL({ accountID })}>
              Edit Billing
            </Link>{' '}
            <Link kind="default" href={cancelSelfStorageRentalURL({ accountID })}>
              Move-Out
            </Link>{' '}
            <Link kind="primary" href={newSelfStorageRentalURL({ accountID })}>
              Move-In
            </Link>
          </Text>
        )}
      </Panel.Footer>
    </Panel>
  );
};

import React, { useState } from 'react';

import { Panel } from '@admin/components/helpers/panel';

import QRCode from 'qrcode.react';
import { InputFormGroup } from '@admin/components/fields';

export const QrCodePanel: React.FC<{
  value: string;
}> = ({ value: initialValue = 'http://www.clutter.com' }) => {
  const [value, setValue] = useState(initialValue);

  return (
    <Panel>
      <Panel.Body>
        <div>
          <h1>QR Code Value: {value}</h1>
          <QRCode value={value} size={256} />
        </div>
        <div className="text-left mar-top">
          <InputFormGroup
            label="Change QR Code Value"
            value={value}
            onChange={(e) => setValue(e.currentTarget.value)}
          />
        </div>
      </Panel.Body>
    </Panel>
  );
};

import template from '@admin/angular/templates/move_form.html';

const app = angular.module('app');

app.component('moveForm', {
  template,
  bindings: {
    account: '<account',
    order: '<order',
  },
  controller: [
    'Move',
    'MOVE_ATTRIBUTES',
    function (Move, MOVE_ATTRIBUTES) {
      this.attributes = MOVE_ATTRIBUTES;
      this.resource = Move;
    },
  ],
});

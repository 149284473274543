import { client } from '@admin/libraries/apollo';
import { useValidEmailLazyQuery } from '@admin/schema';

// NOTE: useful for react-hook-form async validation callbacks.
export const useEmailValidation = () => {
  const [validate] = useValidEmailLazyQuery({ client });
  return async (email: string) => {
    const { data } = await validate({ variables: { email } });
    return data?.valid;
  };
};

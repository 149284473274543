import { setEntityData, Region, Dispatch, Checkin, Order } from '../entities';
import { setRegions } from '../domain/regions';
import { concatDispatches } from '../domain/dispatches';
import { concatCheckins } from '../domain/checkins';

const MODEL_EVENT_DEFAULT = 'system/MODEL_EVENT_DEFAULT';

export const loadRegionEntities =
  () =>
  async (dispatch, getState, { resources }) => {
    const resourceData = await resources.Region.query().$promise;
    const result = dispatch(setEntityData(resourceData, [Region]));
    dispatch(setRegions(result));
    return result;
  };

const handleModelPush = (event, model) => {
  switch (event) {
    case 'order:update':
    case 'order:create':
      return setOrderEntity(model);
    case 'order:destroy':
      return; // TODO
    case 'dispatch:update':
    case 'dispatch:create':
      return setDispatchEntity(model);
    case 'dispatch:destroy':
      return; // TODO
    case 'checkins:create':
    case 'dispatch:checkins:create':
      return setCheckinEntities(model);
    default:
      return {
        type: MODEL_EVENT_DEFAULT,
        data: {
          event,
          model,
        },
      };
  }
};

export const listenToEntityEvent =
  (channel) =>
  (dispatch, getState, { services }) =>
    services.PusherService.subscribe(channel, (event, payload) => dispatch(handleModelPush(event, payload)));

export const listenToRegionEntities = (id) => listenToEntityEvent(`region@${id}`);
export const listenToDispatchEntities = (id) => listenToEntityEvent(`dispatch@${id}`);

export const loadDispatchEntities =
  (query) =>
  async (dispatch, getState, { resources }) => {
    const resourceData = await resources.Dispatch.query(query).$promise;
    const result = dispatch(setEntityData(resourceData, [Dispatch]));
    dispatch(concatDispatches(result));
    return result;
  };

export const loadCheckinEntitiesForDispatches =
  (dispatches) =>
  async (dispatch, getState, { resources }) => {
    const resourceData = await resources.Checkin.latest({
      dispatch_ids: dispatches,
    }).$promise;
    const result = dispatch(setEntityData(resourceData, [Checkin]));
    dispatch(concatCheckins(result));
    return result;
  };

export const setCheckinEntities = (checkins) => (dispatch) => {
  const result = dispatch(setEntityData(checkins, [Checkin]));
  dispatch(concatCheckins(result));
};

export const setDispatchEntity = (dispatchModel) => (dispatch) => {
  const result = dispatch(setEntityData(dispatchModel, Dispatch));
  dispatch(concatDispatches(result));
};

export const setOrderEntity = (order) => setEntityData(order, Order);

export const getUiGmapGoogleMapApi =
  () =>
  (dispatch, getState, { uiGmapGoogleMapApi }) =>
    Promise.resolve(uiGmapGoogleMapApi);

import angular from 'angular';
import Pusher from 'pusher-js';
import { findIndex } from 'lodash';

const app = angular.module('app');

app.service('PusherService', [
  '$window',
  '$rootScope',
  'Logger',
  'UserService',
  'PusherConstants',
  function pusherService($window, $rootScope, Logger, UserService, PusherConstants) {
    const config = $window.clutter.config.pusher;
    const options = { cluster: config.cluster };

    if ($window.clutter.env() === 'test') {
      Object.assign(options, {
        wsHost: config.host,
        wsPort: config.port,
      });
    } else {
      Object.assign(options, { authEndpoint: '/pusher/auth' });
    }

    const pusher = new Pusher(config.key, options);

    const service = {
      subscriptions: {},
      client: pusher,
      join: () => {
        service.client.connection.bind('unavailable', service.unavailable);
        service.client.connection.bind('connecting_in', service.try_to_reconnect);
        service.client.connection.bind('failed', service.connection_failed);
        service.client.connection.bind('disconnected', service.connection_disconnected);
        Pusher.log = (message) => {
          if (window.console && window.console.log) {
            window.console.log(message);
          }
        };

        $rootScope.$broadcast(PusherConstants.PUSHER_CLIENT_JOINED);
      },
      getSubscription(channel) {
        service.subscriptions[channel] = service.subscriptions[channel] || {};
        const { subscription = service.client.subscribe(channel), count = 0 } = service.subscriptions[channel];
        service.subscriptions[channel] = {
          count: count + 1,
          subscription,
        };
        return subscription;
      },
      clearSubscription(channel) {
        if (!service.subscriptions[channel]) {
          return;
        }
        if (service.subscriptions[channel].count - 1 <= 0) {
          service.client.unsubscribe(channel);
          service.subscriptions[channel] = null;
        }
      },
      subscribe(channel, cb) {
        const subscription = service.getSubscription(channel);
        const { global_callbacks: globalCallbacks } = subscription.bind_all(cb);
        return () => {
          service.clearSubscription(channel);
          globalCallbacks.splice(findIndex(globalCallbacks, cb), 1);
        };
      },
      // needed in case pusher is told to disconnect before it is setup
      // case of error in the inital twilio connect call
      leave: () => service.client.disconnect(),
      // pusher websocket events -> angular events
      connection_disconnected: () => {
        service.handle_disconnect();
        Logger.info({ message: `CC_${UserService.name}: broadcast pusher connection disconnected` });
      },

      connection_failed: () => {
        service.handle_disconnect();
        Logger.info({ message: `CC_${UserService.name}: broadcast pusher connection failed` });
      },

      handle_disconnect: () => {
        $rootScope.$broadcast('pusher:disconnected');
        $rootScope.$broadcast('member:removed', {
          id: UserService.id,
          info: { name: UserService.name, email: '', status: 'offline' },
          user_id: UserService.id,
        });
      },

      try_to_reconnect: () => {
        Logger.info({ message: `CC_${UserService.name}: pusher is trying to reconnect` });
      },

      unavailable: () => {
        Logger.info({ message: `CC_${UserService.name}: pusher is unavailable, should reconnect automatically` });
      },
    };

    $rootScope.$on(PusherConstants.PUSHER_CONNECT, () => service.join());

    return service;
  },
]);

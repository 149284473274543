import * as React from 'react';
import { useState } from 'react';

import { Pagination } from '@admin/components/pagination';
import { Spinner } from '@admin/components/spinner';

import { usePaginatedSelfStorageReservationsQuery } from '@admin/schema';
import { client } from '@admin/libraries/apollo';
import { Table } from './table';

export const Summary: React.FC<{
  leadID: string;
}> = ({ leadID }) => {
  const [page, setPage] = useState<number | undefined>(undefined);
  const { data, loading } = usePaginatedSelfStorageReservationsQuery({
    client,
    variables: {
      page,
      filters: { leadID },
    },
  });

  const paginated = data && data.paginated;
  return (
    <>
      {loading && <Spinner />}
      {!loading && paginated && !!paginated.results.length && (
        <>
          <h4>Self Storage Reservations</h4>
          <Table entries={paginated.results} />
          <Pagination pagination={paginated.pagination} onPage={setPage} />
          <hr />
        </>
      )}
    </>
  );
};

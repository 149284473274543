(function () {
  const app = angular.module('app');

  const HOUR = 3600;
  const MINUTE = 60;

  function time(value, tz) {
    const parsed = tz ? moment.tz(value, tz) : moment.utc(value);
    return parsed.hour() * HOUR + parsed.minute() * MINUTE;
  }

  app.directive('timeseries', [
    '$filter',
    function ($filter) {
      return {
        restrict: 'A',

        scope: {
          start: '=start',
          until: '=until',
          min: '=min',
          max: '=max',
          tz: '=?tz',
        },

        link: function (scope, element) {
          const percentage = $filter('percentage');

          function style() {
            const tz = scope.tz;
            const min = time(scope.min, tz);
            const max = time(scope.max, tz);
            let start = (time(scope.start || scope.min, tz) - min) / (max - min);
            let until = (time(scope.until || scope.max, tz) - min) / (max - min);
            if (start < 0.0) {
              start = 0.0;
            }
            if (until > 1.0) {
              until = 1.0;
            }
            const width = until - start;

            const stylings = {
              left: percentage(start),
              right: percentage(until),
              width: percentage(width),
            };

            _.each(stylings, function (value, key) {
              element.css(key, value);
            });
          }

          scope.$watch('start', style);
          scope.$watch('until', style);
        },
      };
    },
  ]);
})();

import styled from '@emotion/styled';
import React, { useState } from 'react';

import { Breadcrumb, Panel, AnchorButton as Link, Table } from '@shared/components/bootstrap';

import { DebouncedInputFormGroup } from '@admin/components/fields';
import { PageHeader } from '@admin/components/helpers/page_header';
import { Pagination } from '@admin/components/pagination';
import { Spinner } from '@admin/components/spinner';

import { client } from '@admin/libraries/apollo';
import { usePaginatedLandingLocationNeighborhoodsQuery } from '@admin/schema';

const INITIAL_PAGE = 1;

const Links = styled.div`
  display: flex;
  gap: 4px;
`;

export const List: React.FC = () => {
  const [page, setPage] = useState<number>(INITIAL_PAGE);
  const [query, setQuery] = useState<string | undefined>();
  const { data, loading } = usePaginatedLandingLocationNeighborhoodsQuery({
    client,
    fetchPolicy: 'cache-and-network',
    variables: {
      page,
      query,
    },
  });

  const paginated = data?.paginated;

  return (
    <>
      <PageHeader>
        <Breadcrumb>
          <Breadcrumb.Item>Landing</Breadcrumb.Item>
          <Breadcrumb.Item>Location</Breadcrumb.Item>
          <Breadcrumb.Item active>Neighborhoods</Breadcrumb.Item>
        </Breadcrumb>
      </PageHeader>

      <Panel>
        <Panel.Body>
          <DebouncedInputFormGroup
            placeholder="Search"
            initial={query}
            onDebounce={(value) => {
              setQuery(value);
              setPage(INITIAL_PAGE);
            }}
          />
        </Panel.Body>
      </Panel>

      <Panel>
        <Panel.Body>
          <Table responsive striped>
            <thead>
              <tr>
                <th className="text-left">ID</th>
                <th className="text-left">Name</th>
                <th className="text-left">Slug</th>
                <th className="col-zero" />
              </tr>
            </thead>
            <tbody>
              {paginated?.results.map((entry) => (
                <tr key={entry.id}>
                  <td className="text-left">{entry.id}</td>
                  <td className="text-left">{entry.name}</td>
                  <td className="text-left">{entry.slug}</td>
                  <td className="col-zero">
                    <Links>
                      <Link kind="primary" href={`/landing/location/neighborhoods/${entry.id}/edit`}>
                        Edit
                      </Link>
                      <Link kind="primary" href={`/landing/location/neighborhoods/${entry.id}`}>
                        View
                      </Link>
                    </Links>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {loading && <Spinner />}
          {paginated && <Pagination onPage={setPage} pagination={paginated.pagination} />}
        </Panel.Body>
        <Panel.Footer align="right">
          <Link kind="primary" href="/landing/location/neighborhoods/new">
            New
          </Link>
        </Panel.Footer>
      </Panel>
    </>
  );
};

import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Logistics__CarrierDetailInput, Logistics__TransportationArrangedByEnum } from '@admin/schema';

import { Field } from './field';

export const CarrierInfoFields: React.FC<{}> = () => {
  const { errors, register, watch } = useFormContext<{
    mode: string;
    trailerNumber?: string;
    freightChargeTerms?: string;
    drop: boolean;
    carrierDetails: Logistics__CarrierDetailInput;
  }>();

  return (
    <>
      <Field error={errors.mode} id="mode" label="Mode" required={true}>
        <select
          className="form-control"
          name="mode"
          id="mode"
          defaultValue="TL"
          ref={register({ required: 'please select a mode' })}
        >
          <option value="TL">TL</option>
          <option value="LTL">LTL</option>
          <option value="RAIL">RAIL</option>
          <option value="FLEET">FLEET</option>
        </select>
      </Field>
      <Field error={errors.trailerNumber} id="trailer_number" label="Trailer Number" required={false}>
        <input id="trailer_number" type="text" className="form-control" name="trailerNumber" ref={register} />
      </Field>
      <Field error={errors.drop} id="drop" label="Drop Trailer/Container?" required={true}>
        <input id="drop" type="checkbox" className="form-control" name="drop" ref={register} />
      </Field>
      <input id="carrier_details_id" type="hidden" name="carrierDetails.id" ref={register} disabled={true} />
      <Field
        error={errors.carrierDetails?.transportationArrangedBy}
        id="transportation_arranged_by"
        label="Transportation Arranged By"
        required={false}
      >
        <select
          className="form-control"
          name="carrierDetails.transportationArrangedBy"
          id="transportation_arranged_by"
          ref={register}
        >
          <option value="">Who is the transportation arranged by?</option>
          <option value={Logistics__TransportationArrangedByEnum.Shipper}>Shipper</option>
          <option value={Logistics__TransportationArrangedByEnum.Clutter}>Clutter</option>
        </select>
      </Field>
      <Field
        error={errors.carrierDetails?.businessName}
        id="carrier_business_name"
        label="Carrier Name"
        required={watch('carrierDetails.transportationArrangedBy') === Logistics__TransportationArrangedByEnum.Shipper}
      >
        <input
          id="carrier_business_name"
          type="text"
          className="form-control"
          name="carrierDetails.businessName"
          ref={register}
        />
      </Field>
      <Field error={errors.carrierDetails?.contactName} id="carrier_contact_name" label="Contact Name" required={false}>
        <input
          id="carrier_contact_name"
          type="text"
          className="form-control"
          name="carrierDetails.contactName"
          ref={register}
        />
      </Field>
      <Field
        error={errors.carrierDetails?.contactPhone}
        id="carrier_contact_phone"
        label="Contact Phone"
        required={false}
      >
        <input
          id="carrier_contact_phone"
          type="tel"
          className="form-control"
          name="carrierDetails.contactPhone"
          ref={register}
        />
      </Field>
      <Field
        error={errors.carrierDetails?.contactEmail}
        id="carrier_contact_email"
        label="Contact Email"
        required={false}
      >
        <input
          id="carrier_contact_email"
          type="email"
          className="form-control"
          name="carrierDetails.contactEmail"
          ref={register}
        />
      </Field>
      <Field error={errors.carrierDetails?.scac} id="carrier_scac" label="SCAC" required={false}>
        <input id="carrier_scac" type="text" className="form-control" name="carrierDetails.scac" ref={register} />
      </Field>
      <Field error={errors.mode} id="freight_charge_terms" label="Freight Charge Terms" required={true}>
        <select
          className="form-control"
          name="freightChargeTerms"
          id="freight_charge_terms"
          defaultValue="collect_payment"
          ref={register({ required: 'please select a charge term' })}
        >
          <option value="collect_payment">Collect Payment</option>
          <option value="third_party">Third Party</option>
          <option value="prepaid">Prepaid</option>
        </select>
      </Field>
    </>
  );
};

(function () {
  const app = angular.module('app');

  app.controller('ItemsShowController', [
    '$scope',
    '$stateParams',
    '$filter',
    'Item',
    'Account',
    'Page',
    function ($scope, $stateParams, $filter, Item, Account, Page) {
      $scope.id = $stateParams.id;
      $scope.account_id = $stateParams.account_id;

      $scope.item = Item.get({
        id: $scope.id,
        account_id: $scope.account_id,
      });

      $scope.item.$promise.then(() => {
        Page.reset({ title: `Item:#${$scope.item.barcode.value}` });
      });

      $scope.account = Account.get({ id: $scope.account_id });

      $scope.save = function () {
        $scope.item[$scope.item.id ? '$update' : '$create']();
      };

      $scope.lightbox = function (image) {
        $scope.modal = {
          visible: !!image,
          source: $filter('resize')(image, 1920, 1920, 'clip'),
        };
      };
    },
  ]);
})();

import angular from 'angular';

const app = angular.module('app');

app.controller('CreateReferralFormController', [
  'Referral',
  '$rootScope',
  '$scope',
  '$state',
  function (Referral, $rootScope, $scope, $state) {
    $scope.onDiscountSelected = (discount) => {
      $scope.account.coupon_id = _.get(discount, 'coupon_id', '');
    };

    $scope.onAccountSelected = (account) => {
      $scope.account = account;
      $scope.orderID = undefined;
    };

    $scope.onDiscountSelected = (referrer) => {
      $scope.referrer = referrer;
    };

    $scope.onOrderSelected = (orderID) => {
      $scope.orderID = orderID;
    };

    $scope.save = () => {
      Referral.save({
        promo_code: $scope.referrer.promo_code,
        referable_id: $scope.account.id,
        apply_referral_coupon: $scope.applyReferralCoupon,
        credit_referrer: $scope.creditReferrer,
        order_id: $scope.orderID,
      }).$promise.then(
        () => {
          $state.go('referrals');
        },
        (error) => {
          $rootScope.$broadcast('message:group:display', error.data);
        },
      );
    };
  },
]);

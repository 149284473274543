import { client } from '@admin/libraries/apollo';
import * as React from 'react';
import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import Select from 'react-select';

interface ICategory {
  id: string;
  name: string;
}

interface IQueryData {
  categories: ICategory[];
}

interface IOption {
  label: string;
  value: string;
}

const CategoriesSelect: React.FC<{
  categories: ICategory[];
  selectedIDs: string[];
  onChange(selectedIDs: string[]): void;
}> = ({ categories, selectedIDs, onChange }) => {
  const options: IOption[] = useMemo(
    () =>
      [
        { label: 'All', value: 'all' },
        { label: 'None', value: 'none' },
      ].concat(categories.map((category) => ({ value: category.id, label: category.name }))),
    [categories],
  );
  if (selectedIDs.some((id) => id === 'all')) {
    selectedIDs = categories.map((category) => category.id);
    onChange(selectedIDs);
  } else if (selectedIDs.some((id) => id === 'none')) {
    selectedIDs = [];
    onChange(selectedIDs);
  }
  const value = options.filter((option) => selectedIDs.includes(option.value));
  return (
    <Select
      isMulti
      options={options}
      value={value}
      onChange={(selectedOptions) => {
        onChange(selectedOptions.map((category: IOption) => category.value));
      }}
      placeholder="Categories"
    />
  );
};

export const CategoriesSelector: React.FC<{
  selectedIDs: string[];
  query: any;
  onChange(selectedIDs: string[]): void;
}> = ({ onChange, selectedIDs, query }) => {
  const { data } = useQuery<IQueryData>(query, { client });
  return (
    <CategoriesSelect categories={(data && data.categories) || []} selectedIDs={selectedIDs} onChange={onChange} />
  );
};

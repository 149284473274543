import React from 'react';

import { Picker } from '@admin/components/helpers/category_with_sub_category_picker';
import { Spinner } from '@admin/components/spinner';
import { useTicketCategoriesQuery } from '@admin/schema';
import { client } from '@admin/libraries/apollo';

export const Categories: React.FC<{
  className?: string;
  selectedCategory: string;
  selectedSubCategory?: string;
  onChange(category: string, subCategory: string): void;
}> = ({ className, selectedCategory, selectedSubCategory, onChange }) => {
  const { data, loading } = useTicketCategoriesQuery({ client });

  if (!data || loading) {
    return <Spinner />;
  }

  return (
    <Picker
      className={className}
      categories={data.ticketCategories}
      selectedCategory={selectedCategory}
      selectedSubCategory={selectedSubCategory}
      onChange={onChange}
    />
  );
};

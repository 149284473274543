import { sortBy } from 'lodash';
import * as React from 'react';

import { InboundVehicle } from './inbound_vehicle';
import { Loader } from './loader';

import { IDispatchNote } from './types/dispatch_note';
import { IOutboundDispatch } from './types/outbound_dispatch';
import { IVehiclesByType } from './types/vehicle';
import { IVehicleTypes } from './types/vehicle';

interface IActiveVehiclesProps {
  dispatchedVehicles: IVehiclesByType | null;
  outboundDispatches?: IOutboundDispatch[];
  dispatchesNotes?: IDispatchNote[];
  warehouseId?: string;
  vehicleTypes?: IVehicleTypes | null;
  loading: boolean;
  handleVehicleAssignment(
    serialCode: string | undefined,
    dispatchId: number | undefined,
    warehouseId: string | undefined,
    oldDispatchId: number | undefined,
    notes: string | undefined,
  ): void;
}

class ActiveVehicles extends React.Component<IActiveVehiclesProps> {
  public render() {
    if (!this.props.vehicleTypes) {
      return null;
    }

    const {
      dispatchedVehicles,
      outboundDispatches,
      dispatchesNotes,
      handleVehicleAssignment,
      warehouseId,
      vehicleTypes,
      loading,
    } = this.props;
    let keys: string[] = [];

    if (dispatchedVehicles) {
      keys = Object.keys(dispatchedVehicles);
    }

    return (
      <div className="active-vehicles">
        <h5 className="active-vehicles__truck-label">Active Trucks</h5>
        <h5 className="active-vehicles__assignment-label">Outbound Assignments</h5>
        <div className="active-vehicles__container">
          {loading && <Loader loading={loading} />}
          {!loading && (
            <div className="active-vehicles__list">
              {keys.map((key) => (
                <div key={key}>
                  <InboundVehicle
                    dispatchedVehicles={dispatchedVehicles && sortBy(dispatchedVehicles[key], 'serialCode')}
                    vehicleType={vehicleTypes[key]}
                    outboundDispatches={outboundDispatches}
                    dispatchesNotes={dispatchesNotes}
                    handleVehicleAssignment={handleVehicleAssignment}
                    warehouseId={warehouseId}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export { ActiveVehicles };

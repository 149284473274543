import { round } from 'lodash';
import React, { useState, useEffect } from 'react';

import {
  Admin__Billing__InvoiceFragment as Invoice,
  PaymentPlan__Offer__State as OfferState,
  Status,
  useSavePaymentPlanOfferMutation,
} from '@admin/schema';

import { editPaymentPlanOfferURL } from '@admin/config/routes';
import { BillingMethod } from '@admin/types';
import { OfferChanges, InvoiceSelections } from '@admin/types/payment_plan';
import { Button, Text } from '@shared/components/bootstrap';
import { client } from '@admin/libraries/apollo';

import { validateOfferChanges } from './helpers';
import { OfferModal } from './offer_modal';

type InvoiceIDMapping = { [id: string]: Invoice };

export const OfferButtons: React.FC<{
  accountID: string;
  offerID?: string;
  state?: OfferState;
  oneTimeFeeIncluded: boolean;
  storageTermIncluded: boolean;
  monthlyFeeIncluded: boolean;
  offerChanges: OfferChanges;
  setOfferChanges(changes: OfferChanges): void;
  invoices: Invoice[];
  invoiceSelections: InvoiceSelections;
  finishEditing?(): void;
  payImmediately: boolean;
  source?: BillingMethod;
}> = ({
  accountID,
  offerID,
  state,
  oneTimeFeeIncluded,
  storageTermIncluded,
  monthlyFeeIncluded,
  offerChanges,
  setOfferChanges,
  invoices,
  invoiceSelections,
  finishEditing,
  payImmediately,
  source,
}) => {
  const [showOfferModal, setShowOfferModal] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const [outstandingInvoices, setOutstandingInvoices] = useState<InvoiceIDMapping>({});

  useEffect(() => {
    if (!invoices) {
      return;
    }
    const outstanding: InvoiceIDMapping = {};
    invoices.forEach((invoice) => (outstanding[invoice.id] = invoice));
    setOutstandingInvoices(outstanding);
  }, [invoices]);

  const [save, { loading: saving }] = useSavePaymentPlanOfferMutation({ client });

  const selectedInvoiceIDs = () => Object.keys(invoiceSelections).filter((id) => invoiceSelections[id]);
  const selectedInvoices = () => selectedInvoiceIDs().map((id) => outstandingInvoices[id]);

  const validateAllChanges = () => {
    const selectedOutstandingInvoices = selectedInvoices();
    return validateOfferChanges(
      offerChanges,
      selectedOutstandingInvoices,
      oneTimeFeeIncluded,
      storageTermIncluded,
      monthlyFeeIncluded,
      payImmediately,
      source,
    );
  };

  const onPreviewOffer = () => {
    const errorMessage = validateAllChanges();
    if (errorMessage) {
      setError(errorMessage);
    } else {
      setError(undefined);
      setShowOfferModal(true);
    }
  };

  const onSave = async () => {
    if (state && state !== OfferState.Draft) {
      const errorMessage = validateAllChanges();
      if (errorMessage) {
        setError(errorMessage);
        return;
      }
    } else if (!offerChanges.type || !offerChanges.expiry) {
      setError('Please select a plan type and a valid expiry date to save.');
      return;
    }
    setError(undefined);

    const variables = {
      input: {
        ...offerChanges,
        accountID,
        oneTimeFee: offerChanges.oneTimeFee ? round(offerChanges.oneTimeFee, 2) : undefined,
        settledInvoiceIDs: selectedInvoiceIDs(),
        offerID,
      },
    };
    const response = await save({ variables });
    if (response && response.data) {
      if (response.data.saveOffer.status === Status.Unprocessable) {
        setError(`There was a problem saving the payment plan offer: ${response.data.saveOffer.error}`);
      } else if (response.data.saveOffer.status === Status.Ok) {
        setError(undefined);
        if (!offerID) {
          location.replace(editPaymentPlanOfferURL({ accountID, offerID: response.data.saveOffer.offer!.id }));
        } else if (finishEditing) {
          finishEditing();
        }
      }
    }
  };

  return (
    <div className="form-group text-right">
      {error && (
        <Text tag="p" style="danger">
          {error}
        </Text>
      )}
      {!!offerID && (
        <Button kind="warning" disabled={saving} onClick={finishEditing}>
          Cancel
        </Button>
      )}
      {!payImmediately && (
        <Button kind="default" className="mar-lft" loading={saving} onClick={onSave}>
          Save
        </Button>
      )}
      <Button kind="primary" className="mar-lft" disabled={saving} onClick={onPreviewOffer}>
        {payImmediately ? 'Review Payment' : 'Preview Offer'}
      </Button>
      {showOfferModal && (
        <OfferModal
          offerID={offerID}
          accountID={accountID}
          selectedInvoices={selectedInvoices()}
          offerChanges={offerChanges}
          setOfferChanges={setOfferChanges}
          onOffer={finishEditing}
          onClose={() => setShowOfferModal(false)}
          payImmediately={payImmediately}
          source={source}
        />
      )}
    </div>
  );
};

import React from 'react';

import { displayableText, formatState } from '@admin/components/payment_plans/helpers';
import { editPaymentPlanOfferURL } from '@admin/config/routes';
import { PaymentPlan__Offer, User } from '@admin/schema';
import { AnchorButton, Table as SharedTable, Text } from '@shared/components/bootstrap';
import { Timestamp } from '@shared/components/helpers';

type PaymentPlanUser = Pick<User, 'id' | 'name'>;
type PaymentPlanOffer = Pick<PaymentPlan__Offer, 'id' | 'type' | 'state' | 'createdAt' | 'expiry' | 'expired'> & {
  user: PaymentPlanUser;
};

const Row: React.FC<{
  accountID: string;
  offer: PaymentPlanOffer;
}> = ({ accountID, offer }) => (
  <tr>
    <td className="col-md-1">{offer.id}</td>
    <td className="col-md-2">{<Timestamp value={offer.createdAt} format={Timestamp.Format.DateTime} />}</td>
    <td className="col-md-2">{offer.user.name}</td>
    <td className="col-md-2">{displayableText(offer.type)}</td>
    <td className="col-md-2">{formatState(offer.state, offer.expired)}</td>
    <td className="col-md-2">{<Timestamp value={offer.expiry} format={Timestamp.Format.DateTime} />}</td>
    <td className="col-md-1 text-right">
      <AnchorButton kind="primary" href={editPaymentPlanOfferURL({ accountID, offerID: offer.id })}>
        View
      </AnchorButton>
    </td>
  </tr>
);

export const Table: React.FC<{
  accountID: string;
  offers: PaymentPlanOffer[];
}> = ({ accountID, offers }) => {
  if (!offers.length) {
    return (
      <Text alignment="center" tag="p">
        This account does not have any payment plans.
      </Text>
    );
  }

  return (
    <SharedTable>
      <thead>
        <tr>
          <th className="col-md-1">ID</th>
          <th className="col-md-2">Created At</th>
          <th className="col-md-2">Created By</th>
          <th className="col-md-2">Type</th>
          <th className="col-md-2">State</th>
          <th className="col-md-2">Expires</th>
          <th className="col-md-1" />
        </tr>
      </thead>
      <tbody>
        {offers.map((offer) => (
          <Row key={offer.id} accountID={accountID} offer={offer} />
        ))}
      </tbody>
    </SharedTable>
  );
};

import { faChevronRight, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';

import { Button, Panel as BSPanel } from '@shared/components/bootstrap';

const Collapse: React.FC<{
  collapsed: boolean;
  onChange(collapsed: boolean): void;
}> = ({ collapsed, onChange }) => (
  <BSPanel.Control>
    <Button kind="default" onClick={() => onChange(!collapsed)}>
      <FontAwesomeIcon icon={collapsed ? faChevronRight : faChevronDown} />
    </Button>
  </BSPanel.Control>
);

type IPanelProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
const Panel: React.FC<IPanelProps> = (props) => <div {...props} className="panel" />;

const Combined = Object.assign(Panel, {
  ...BSPanel,
  Collapse,
});

export { Combined as Panel };

import React from 'react';
import { Button, Modal, Text } from '@shared/components/bootstrap';
import { InsufficientSkuTruckCuftQuery } from '@admin/schema';
import { logisticsDetailedSKUURL } from '@admin/config/routes';
import styled from '@emotion/styled';
import { COLORS } from '@root/colors';
import { Spacer } from '@shared/components/helpers';

const Link = styled.a`
  color: ${COLORS.blueLight};
`;

const LoadTitle = styled.div`
  font-size: 18px;
`;

type insufficientSkuTruckCuftObject = InsufficientSkuTruckCuftQuery['insufficientSkuTruckCuft'];

export const SkuTruckCuftConfirmationModal: React.FC<{
  insufficientSkuTruckCuftObject: insufficientSkuTruckCuftObject;
  onCancel(): void;
  onConfirm(): void;
}> = ({ insufficientSkuTruckCuftObject, onCancel, onConfirm }) => (
  <Modal onClose={onCancel}>
    <Modal.Content>
      <Modal.Header>
        <Modal.Title>
          Warning:{' '}
          {insufficientSkuTruckCuftObject.validForKnownDimensions
            ? 'Cannot Calculate Truck Fit'
            : 'Request Cannot Fit On One Trailer'}
        </Modal.Title>
        <Modal.Close close={onCancel} />
      </Modal.Header>
      <Modal.Body>
        {!insufficientSkuTruckCuftObject.validForKnownDimensions && (
          <Text>
            The quantities requested for this load cannot fit on one 53' dry van trailer. Additional loads may need to
            be booked.
            <strong> Please inform the customer immediately to discuss alternative options.</strong>
          </Text>
        )}
        <Spacer height="8px" />
        {insufficientSkuTruckCuftObject.skusWithoutDimensions.length > 0 && (
          <Text>
            We cannot determine the recommended load configuration because we don't know the dimensions of every SKU.
            <Spacer height="8px" />
            SKU's without dimensions:{' '}
            {insufficientSkuTruckCuftObject.skusWithoutDimensions.map((sku, index) => (
              <>
                <Link key={sku.id} href={logisticsDetailedSKUURL({ id: sku.id })} target="_blank">
                  {sku.value}
                </Link>
                {index !== insufficientSkuTruckCuftObject.skusWithoutDimensions.length - 1 && ', '}
              </>
            ))}
          </Text>
        )}
        {!insufficientSkuTruckCuftObject.validForKnownDimensions &&
          insufficientSkuTruckCuftObject.skusWithoutDimensions.length === 0 && (
            <>
              <h4>Suggested Load Configuration</h4>
              {insufficientSkuTruckCuftObject.suggestedLoads.map((loadSuggestion, index) => (
                <div key={`load_suggestion_${index}`}>
                  <LoadTitle>Load {index + 1}</LoadTitle>
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th className="col-md-1">SKU</th>
                        <th className="col-md-1">Quantity</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loadSuggestion.suggestedExpectations.map((expectationSuggestion) => (
                        <tr key={`expectation_suggestion_${expectationSuggestion.sku.id}`}>
                          <td className="col-md-1">
                            <Link href={logisticsDetailedSKUURL({ id: expectationSuggestion.sku.id })} target="_blank">
                              {expectationSuggestion.sku.value}
                            </Link>
                          </td>
                          <td className="col-md-1">{expectationSuggestion.quantity}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ))}
            </>
          )}
      </Modal.Body>
      <Modal.Footer>
        {
          <div>
            <Button kind="secondary" onClick={onCancel}>
              Cancel
            </Button>
            <Button kind="warning" onClick={onConfirm}>
              Continue
            </Button>
          </div>
        }
      </Modal.Footer>
    </Modal.Content>
  </Modal>
);

(function () {
  const TYPES = {
    DATE: 'date',
    TIME: 'time',
  };

  angular.module('app').service('SerializerService', [
    function () {
      const self = this;

      self.types = TYPES;

      self.transform = function (mappings) {
        return function (data) {
          const params = angular.copy(data);

          _.each(mappings, function (type, key) {
            if (params[key]) {
              switch (type) {
                case self.types.DATE:
                  params[key] = moment.utc(moment(params[key])).format('YYYY-MM-DD');
                  break;
                case self.types.TIME:
                  params[key] = moment.utc(moment(params[key])).format('HH:mm');
                  break;
              }
            }
          });

          return params;
        };
      };
    },
  ]);
})();

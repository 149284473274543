import React from 'react';

import { client } from '@admin/libraries/apollo';

import { useQA } from '@admin/hooks/use_qa';
import { Order, OrderStateEnum, OrderTypeEnum, useOrderReturnRouteMutation } from '@admin/schema';

import { Button } from '@shared/components/bootstrap';

export const OrderReturnRouteButton: React.FC<{
  order: Pick<Order, 'type' | 'id' | 'state'>;
}> = ({ order }) => {
  const qa = useQA();
  const [execute, { loading }] = useOrderReturnRouteMutation({
    client,
    variables: { orderID: String(order.id) },
  });

  const routeable = order.state === OrderStateEnum.Approved && order.type === OrderTypeEnum.Return;
  if (!qa || !routeable) return null;

  const route = async () => {
    await execute();
    location.reload();
  };

  return (
    <Button loading={loading} kind="warning" onClick={route}>
      Auto-Route Return
    </Button>
  );
};

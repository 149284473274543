/* eslint-disable no-return-assign */
(function () {
  angular.module('app').controller('DispatcherVehicleRequestsController', [
    '$scope',
    'dispatch',
    'VehicleType',
    function ($scope, dispatch, VehicleType) {
      $scope.dispatch = dispatch;
      $scope.vehicleTypes = VehicleType.query();

      const recipe = dispatch.vehicle_recipe == null ? {} : dispatch.vehicle_recipe;
      $scope.returnCuft = dispatch.orders.reduce((accumulator, order) => {
        if (order.items_cuft == null) {
          return accumulator;
        }
        return accumulator + Number(order.items_cuft);
      }, 0);

      $scope.numDrivers = dispatch.assignments.reduce((accumulator, assignment) => {
        if (assignment.user.roles.includes('driver')) {
          return accumulator + 1;
        }
        return accumulator;
      }, 0);

      const getPreferenceArray = () => {
        const preferenceArray = [];
        if ($scope.dispatch.vehicle_preferences && $scope.dispatch.vehicle_preferences.length > 0) {
          $scope.dispatch.vehicle_preferences.forEach(
            (preference) =>
              (preferenceArray[preference.vehicle_type_id] = {
                id: preference.id,
                vehicle_type_id: preference.vehicle_type_id,
                quantity: preference.quantity,
              }),
          );
        }
        return preferenceArray;
      };

      const mapPreferenceArray = () => {
        const preferenceObjects = _.map($scope.form.preference_types, (preference, index) => {
          if (preference && (preference.id || preference.quantity > 0)) {
            return {
              id: preference.id,
              vehicle_type_id: preference.vehicle_type_id ? preference.vehicle_type_id : index,
              quantity: preference.quantity,
              _destroy: preference.quantity === null && preference.id,
            };
          }
          return null;
        });

        return preferenceObjects.filter((pref) => pref && (pref.vehicle_type_id || pref.quantity || pref.id));
      };

      const calculateSeatsAvailable = () => {
        $scope.seatsAvailable = $scope.form.preference_types.reduce((accumulator, selectedType, idx) => {
          if (selectedType.quantity > 0) {
            return accumulator + selectedType.quantity * $scope.vehicleTypeMap[idx].num_seats;
          }
          return accumulator;
        }, 0);
      };

      const calculateCuftUsed = () => {
        $scope.cuftUsed = $scope.form.preference_types.reduce((accumulator, selectedType, idx) => {
          if (selectedType.quantity > 0) {
            return accumulator + selectedType.quantity * $scope.vehicleTypeMap[idx].cuft;
          }
          return accumulator;
        }, 0);
      };

      /*
        if 1 vehicle preferred. Set min cuft to lowest of allowable types
        if >1 vehicle preferred. Set min cuft to min(sum(preferred_trucks), sum(smallest & largest trucks))
      */
      const calculateMinCuft = () => {
        const allowedTypeCufts = [];
        $scope.form.allowed_vehicle_type_ids.forEach((typeId) => {
          allowedTypeCufts.push($scope.vehicleTypeMap[typeId].cuft);
        });
        const prefQuantity = $scope.form.preference_types
          .filter(Boolean)
          .reduce((accumulator, type) => accumulator + type.quantity, 0);

        if (prefQuantity === 1) {
          return Math.min(...allowedTypeCufts);
        } else if (prefQuantity > 1) {
          return Math.min($scope.cuftUsed, Math.min(...allowedTypeCufts) + Math.max(...allowedTypeCufts));
        }
        return 0;
      };

      $scope.vehicleTypes.$promise.then((data) => {
        $scope.vehicleTypeMap = {};
        data.forEach((type) => {
          $scope.vehicleTypeMap[type.id] = type;
        });

        if (recipe.allowed_vehicle_type_ids == null) {
          $scope.form.allowed_vehicle_type_ids = Object.keys($scope.vehicleTypeMap).map(Number);
        }

        calculateSeatsAvailable();
        calculateCuftUsed();
      });

      const vehiclePreferenceArray = getPreferenceArray();

      $scope.form = {
        allowed_vehicle_type_ids: recipe.allowed_vehicle_type_ids == null ? [] : recipe.allowed_vehicle_type_ids,
        min_cuft: parseFloat(recipe.minimum_cuft),
        max_quantity: recipe.max_quantity || $scope.numDrivers,
        min_quantity: recipe.min_quantity || 1,
        vehicle_request_notes: recipe.notes,
        preference_types: dispatch.vehicle_preferences == null ? [] : vehiclePreferenceArray,
      };

      $scope.returnCuftPercentage = () =>
        $scope.returnCuft > 0 && $scope.cuftUsed > 0 ? ($scope.returnCuft / $scope.cuftUsed) * 100 : 0;

      $scope.save = function () {
        $scope.dispatch.vehicle_preferences = mapPreferenceArray();
        if ($scope.dispatch.vehicle_recipe == null) {
          $scope.dispatch.vehicle_recipe = {};
        }

        $scope.dispatch.vehicle_recipe.max_quantity = $scope.form.max_quantity;
        $scope.dispatch.vehicle_recipe.min_quantity = $scope.form.min_quantity;
        $scope.dispatch.vehicle_recipe.minimum_cuft = $scope.form.min_cuft;
        $scope.dispatch.vehicle_recipe.notes = $scope.form.vehicle_request_notes;
        $scope.dispatch.vehicle_recipe.allowed_vehicle_type_ids = $scope.form.allowed_vehicle_type_ids.filter(
          (id) => id,
        );
        $scope.closeThisDialog();
      };

      $scope.$watch(
        'form.preference_types',
        () => {
          if ($scope.vehicleTypeMap) {
            calculateCuftUsed();
            calculateSeatsAvailable();
            $scope.form.min_cuft = calculateMinCuft();
          }
        },
        true,
      );

      $scope.$watch(
        'form.allowed_vehicle_type_ids',
        () => {
          if ($scope.vehicleTypeMap) {
            $scope.form.min_cuft = calculateMinCuft();
          }
        },
        true,
      );
    },
  ]);
})();

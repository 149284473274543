import { OrderTypeEnum, OrderSubtypeEnum, OrderServiceTypeEnum } from '@admin/schema';

export const ORDER_TYPE_MAP = {
  pickup: OrderTypeEnum.Pickup,
  return: OrderTypeEnum.Return,
  restock: OrderTypeEnum.Restock,
  'selfstorage-shuttle': OrderTypeEnum.SelfStorageShuttle,
  'moving-move': OrderTypeEnum.Move,
  'retail-delivery': OrderTypeEnum.RetailDelivery,
} as const;

export const ORDER_SUBTYPE_MAP = {
  onboarding: OrderSubtypeEnum.Onboarding,
  subsequent: OrderSubtypeEnum.Subsequent,
  final: OrderSubtypeEnum.Final,
  none: OrderSubtypeEnum.None,
  packing: OrderSubtypeEnum.Packing,
  unpacking: OrderSubtypeEnum.Unpacking,
  travel: OrderSubtypeEnum.Travel,
  cancelation: OrderSubtypeEnum.Cancelation,
  'supply-kit': OrderSubtypeEnum.SupplyKit,
} as const;

export const ORDER_SERVICE_TYPE_MAP = {
  full_service: OrderServiceTypeEnum.FullService,
  shipment: OrderServiceTypeEnum.Shipment,
  facility: OrderServiceTypeEnum.Facility,
  auction: OrderServiceTypeEnum.Auction,
  disposal: OrderServiceTypeEnum.Disposal,
  third_party_return: OrderServiceTypeEnum.ThirdPartyReturn,
  curbside_pickup: OrderServiceTypeEnum.CurbsidePickup,
  doorstep: OrderServiceTypeEnum.Doorstep,
  capsule: OrderServiceTypeEnum.Capsule,
  drop_off: OrderServiceTypeEnum.DropOff,
  long_distance: OrderServiceTypeEnum.LongDistance,
};

export function orderSubtypeMapKeyForValue(value: OrderSubtypeEnum) {
  return (Object.keys(ORDER_SUBTYPE_MAP) as Array<keyof typeof ORDER_SUBTYPE_MAP>).find(
    (key) => ORDER_SUBTYPE_MAP[key] === value,
  );
}

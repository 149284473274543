import { intersection, lowerCase, startCase } from 'lodash';
import * as React from 'react';
import { useForm } from 'react-hook-form';

import { client } from '@admin/libraries/apollo';

import { ROLES } from '@admin/config/roles';
import { Panel } from '@admin/components/helpers/panel';
import { Roles } from '@admin/components/helpers/roles';
import { Spinner } from '@admin/components/spinner';
import { UserRole } from '@admin/types';
import { Alert, Button } from '@shared/components/bootstrap';

import {
  Logistics__BuildSkuInput,
  Logistics__ModifySkuInput,
  Logistics__Sku__CountingUnitEnum as CountingUnitEnum,
  Logistics__SkuTeachInStatusEnum,
  PalletHeightUnitEnum,
  PalletSizeEnum,
  Status,
  useBuildLogisticsSkuMutation,
  useModifyLogisticsSkuMutation,
  useLogisticsSkuFormOptionsQuery,
  Logistics__Sku__VbwOptOutReasonEnum as OptOutReasonEnum,
} from '@admin/schema';

import { Field } from './field';

export type EditFormData = {
  id: string;
  value: string;
  description: string;
  hasBarcode: boolean;
  defaultPalletTypeID: string;
  defaultPalletHeightValue: number;
  itemsPerPallet?: number;
  weightPerPallet?: number;
  gtinBarcode?: string;
  nmfcClass?: string;
  nmfcNumber?: string;
  itemWeight?: number;
  itemLength?: number;
  itemWidth?: number;
  itemHeight?: number;
  teachInStatus: Logistics__SkuTeachInStatusEnum;
  requiresTeachIn?: boolean;
  disableVBW?: boolean;
  vbwOptOutReason?: OptOutReasonEnum;
  countingUnit: CountingUnitEnum;
};
export type NewFormData = {
  accountID: string;
  value: string;
  description: string;
  hasBarcode: boolean;
  defaultPalletTypeID: string;
  defaultPalletHeightValue: number;
  itemsPerPallet?: number;
  weightPerPallet?: number;
  gtinBarcode?: string;
  nmfcClass?: string;
  nmfcNumber?: string;
  itemWeight?: number;
  itemLength?: number;
  itemWidth?: number;
  itemHeight?: number;
  teachInStatus: Logistics__SkuTeachInStatusEnum;
  disableVBW?: boolean;
  vbwOptOutReason?: OptOutReasonEnum;
  countingUnit: CountingUnitEnum;
};
export type FormData = EditFormData | NewFormData;

function isEditFormData(formData: FormData): formData is EditFormData {
  return (formData as EditFormData).id !== undefined;
}

const VBW_ELIGIBLE_ROLES = [UserRole.Admin, UserRole.EnterpriseManager];
const hasVBWEligibleRoles = intersection(ROLES, VBW_ELIGIBLE_ROLES).length > 0;

const FALLBACK_ERROR = 'Sorry, an unexpected error occurred. If the problem persists, contact Tech Support.';

const taughtDisabled = (formData: FormData) =>
  isEditFormData(formData) && formData.teachInStatus === Logistics__SkuTeachInStatusEnum.Taught;

const optOutReasonDescription = (optOutReason: OptOutReasonEnum) => {
  switch (optOutReason) {
    case OptOutReasonEnum.VariableWeight:
      return 'Individual SKUs do not have a consistent weight';
    case OptOutReasonEnum.Other:
      return 'Other';
  }
};

export const LogisticsSkuForm: React.FC<{
  formData: FormData;
  onSave(id: string | undefined): void;
}> = ({ formData, onSave }) => {
  const [error, setError] = React.useState<string | undefined>(undefined);
  const { errors, handleSubmit, register, watch } = useForm<FormData>({ defaultValues: formData });
  const { data: optionsData, loading: optionsLoading } = useLogisticsSkuFormOptionsQuery({ client });
  const [executeEditSubmit, { loading: editSkuLoading }] = useModifyLogisticsSkuMutation({ client });
  const [executeNewSubmit, { loading: newSkuLoading }] = useBuildLogisticsSkuMutation({ client });
  const submitLoading = editSkuLoading || newSkuLoading;
  const canSetVBWEligible: boolean = hasVBWEligibleRoles;

  function transformEdit(editFormData: EditFormData): Logistics__ModifySkuInput {
    const defaultPalletHeightValue = parseInt(String(editFormData.defaultPalletHeightValue), 10);
    const input: Logistics__ModifySkuInput = {
      id: editFormData.id,
      value: editFormData.value,
      description: editFormData.description,
      hasBarcode: editFormData.hasBarcode,
      defaultPalletTypeID: editFormData.defaultPalletTypeID === '0' ? null : editFormData.defaultPalletTypeID,
      defaultPalletHeight: defaultPalletHeightValue
        ? { unit: PalletHeightUnitEnum.Inches, value: defaultPalletHeightValue }
        : null,
      itemsPerPallet: editFormData.itemsPerPallet ? parseInt(String(editFormData.itemsPerPallet), 10) : null,
      weightPerPallet: editFormData.weightPerPallet ? parseFloat(String(editFormData.weightPerPallet)) : null,
      gtinBarcode: editFormData.gtinBarcode,
      nmfcClass: editFormData.nmfcClass,
      nmfcNumber: editFormData.nmfcNumber,
      itemWeight: editFormData.itemWeight ? parseFloat(String(editFormData.itemWeight)) : null,
      itemLength: editFormData.itemLength ? parseFloat(String(editFormData.itemLength)) : null,
      itemWidth: editFormData.itemWidth ? parseFloat(String(editFormData.itemWidth)) : null,
      itemHeight: editFormData.itemHeight ? parseFloat(String(editFormData.itemHeight)) : null,
      verified: true,
      teachInStatus: formData.teachInStatus,
      countingUnit: editFormData.countingUnit,
    };
    if (canSetVBWEligible) {
      if (editFormData.teachInStatus === Logistics__SkuTeachInStatusEnum.NotRequired) {
        input.vbwEligible = false;
        input.vbwOptOutReason = null;
      } else {
        input.vbwEligible = !editFormData.disableVBW;
        if (editFormData.disableVBW === true) {
          input.vbwOptOutReason = editFormData.vbwOptOutReason;
        } else {
          input.vbwOptOutReason = null;
        }
      }
    }
    return input;
  }

  function transformNew(newFormData: NewFormData): Logistics__BuildSkuInput {
    const defaultPalletHeightValue = parseInt(String(newFormData.defaultPalletHeightValue), 10);
    const input: Logistics__BuildSkuInput = {
      accountID: newFormData.accountID,
      value: newFormData.value,
      description: newFormData.description,
      hasBarcode: newFormData.hasBarcode,
      defaultPalletTypeID: newFormData.defaultPalletTypeID === '0' ? null : newFormData.defaultPalletTypeID,
      defaultPalletHeight: defaultPalletHeightValue
        ? { unit: PalletHeightUnitEnum.Inches, value: defaultPalletHeightValue }
        : null,
      itemsPerPallet: newFormData.itemsPerPallet ? parseInt(String(newFormData.itemsPerPallet), 10) : null,
      weightPerPallet: newFormData.weightPerPallet ? parseFloat(String(newFormData.weightPerPallet)) : null,
      gtinBarcode: newFormData.gtinBarcode,
      nmfcClass: newFormData.nmfcClass,
      nmfcNumber: newFormData.nmfcNumber,
      itemWeight: newFormData.itemWeight ? parseFloat(String(newFormData.itemWeight)) : null,
      itemLength: newFormData.itemLength ? parseFloat(String(newFormData.itemLength)) : null,
      itemWidth: newFormData.itemWidth ? parseFloat(String(newFormData.itemWidth)) : null,
      itemHeight: newFormData.itemHeight ? parseFloat(String(newFormData.itemHeight)) : null,
      verified: true,
      teachInStatus: formData.teachInStatus,
      countingUnit: newFormData.countingUnit,
    };

    if (canSetVBWEligible) {
      input.vbwEligible = !newFormData.disableVBW;
      if (newFormData.disableVBW === true) {
        input.vbwOptOutReason = newFormData.vbwOptOutReason;
      }
    } else {
      input.vbwEligible = true;
    }
    return input;
  }

  type SubmitPayload = {
    status: Status;
    sku?: { id: string } | null;
    error?: string | null;
  };
  async function executeSubmit(data: FormData): Promise<SubmitPayload | undefined> {
    if (isEditFormData(data)) {
      const input = transformEdit(data);
      const result = await executeEditSubmit({ variables: { input: input } });
      return result.data?.modifyLogisticsSku;
    } else {
      const input = transformNew(data);
      const result = await executeNewSubmit({ variables: { input: input } });
      return result.data?.buildLogisticsSku;
    }
  }

  async function onSubmit(data: FormData) {
    try {
      const payload = await executeSubmit(data);

      if (payload?.status === Status.Ok) {
        setError(undefined);
        onSave(payload.sku?.id);
      } else {
        setError(payload?.error || FALLBACK_ERROR);
        window.scrollTo(0, 0);
      }
    } catch (e) {
      setError(FALLBACK_ERROR);
      window.scrollTo(0, 0);
    }
  }

  let palletHeightValues: number[] = [];
  const defaultPalletTypeID = watch('defaultPalletTypeID');
  const defaultPalletType = optionsData?.palletTypes.find((palletType) => palletType.id === defaultPalletTypeID);
  switch (defaultPalletType?.legacySize) {
    case PalletSizeEnum.Single:
    case PalletSizeEnum.Double:
      palletHeightValues = [30, 40, 50, 60, 70, 80, 90];
      break;
    case PalletSizeEnum.Crated:
      palletHeightValues = [30, 45, 60];
      break;
    case PalletSizeEnum.Oversized:
    default:
      palletHeightValues = [];
      break;
  }

  if (optionsLoading || !optionsData) {
    return <Spinner />;
  }

  return (
    <Panel>
      {error && <Alert style="danger">{error}</Alert>}
      <form id="skuForm" onSubmit={handleSubmit(onSubmit)}>
        <Panel.Title>{isEditFormData(formData) ? 'Edit Sku' : 'New SKU'}</Panel.Title>
        <Panel.Body>
          {watch('id') && (
            <input
              id="id"
              name="id"
              type="hidden"
              disabled
              value={watch('id') as string}
              ref={register({ required: true })}
            />
          )}
          {watch('accountID') && (
            <input
              id="account_id"
              name="accountID"
              type="hidden"
              disabled
              value={watch('accountID') as string}
              ref={register({ required: true })}
            />
          )}
          <Field id="value" label="Value" error={errors.value} required>
            <input id="value" name="value" type="text" className="form-control" ref={register({ required: true })} />
          </Field>
          <Field id="description" label="Description" error={errors.description} required>
            <input
              id="description"
              name="description"
              type="text"
              className="form-control"
              ref={register({ required: true })}
            />
          </Field>
          <Field id="counting_unit" label="Counting Unit" error={errors.countingUnit} required>
            <select
              id="counting_unit"
              name="countingUnit"
              defaultValue={watch('countingUnit')}
              className="form-control"
              ref={register}
            >
              {Object.values(CountingUnitEnum).map((unit) => (
                <option key={unit} value={unit}>
                  {startCase(lowerCase(unit))}
                </option>
              ))}
            </select>
          </Field>
          <Field id="has_barcode" label="Has Unique Barcodes" error={errors.hasBarcode}>
            <input id="has_barcode" name="hasBarcode" type="checkbox" className="form-control" ref={register} />
          </Field>
          <Field id="default_pallet_type_id" label="Default Pallet Type" error={errors.defaultPalletTypeID}>
            <select
              id="default_pallet_type_id"
              name="defaultPalletTypeID"
              defaultValue={watch('defaultPalletTypeID')}
              className="form-control"
              ref={register}
            >
              <option key={0} value={0}>
                None Selected
              </option>
              {optionsData.palletTypes
                .filter((palletType) => palletType.active)
                .sort((a, b) => a.position - b.position)
                .map((palletType) => (
                  <option key={palletType.id} value={palletType.id}>
                    {palletType.name}
                  </option>
                ))}
            </select>
          </Field>
          <Field id="default_pallet_height_value" label="Default Pallet Height" error={errors.defaultPalletHeightValue}>
            <select
              id="default_pallet_height_value"
              name="defaultPalletHeightValue"
              defaultValue={watch('defaultPalletHeightValue')}
              className="form-control"
              ref={register}
            >
              <option key={0} value={0}>
                None Selected
              </option>
              {palletHeightValues.map((palletHeightValue) => (
                <option key={palletHeightValue} value={palletHeightValue}>
                  {palletHeightValue} inches
                </option>
              ))}
            </select>
          </Field>
          <Field id="item_weight" label="Weight Per Item (lbs)" error={errors.itemWeight}>
            <input
              id="item_weight"
              name="itemWeight"
              type="number"
              min="0.00001"
              step="any"
              className="form-control"
              disabled={taughtDisabled(formData)}
              ref={register({ min: 0.00001 })}
            />
          </Field>
          <Field id="item_length" label="Length Per Item (in)" error={errors.itemLength}>
            <input
              id="item_length"
              name="itemLength"
              type="number"
              min="0.00001"
              step="any"
              className="form-control"
              ref={register({ min: 0.00001 })}
            />
          </Field>
          <Field id="item_width" label="Width Per Item (in)" error={errors.itemWidth}>
            <input
              id="item_width"
              name="itemWidth"
              type="number"
              min="0.00001"
              step="any"
              className="form-control"
              ref={register({ min: 0.00001 })}
            />
          </Field>
          <Field id="item_height" label="Height Per Item (in)" error={errors.itemHeight}>
            <input
              id="item_height"
              name="itemHeight"
              type="number"
              min="0.00001"
              step="any"
              className="form-control"
              ref={register({ min: 0.00001 })}
            />
          </Field>
          <Field id="items_per_pallet" label="Items per Pallet" error={errors.itemsPerPallet}>
            <input
              id="items_per_pallet"
              name="itemsPerPallet"
              type="number"
              min="1"
              className="form-control"
              ref={register({ min: 1 })}
            />
          </Field>
          <Field id="weight_per_pallet" label="Weight per Pallet (lbs)" error={errors.weightPerPallet}>
            <input
              id="weight_per_pallet"
              name="weightPerPallet"
              type="number"
              min="0"
              step="0.1"
              className="form-control"
              ref={register({ min: 0 })}
            />
          </Field>
          <Field
            id="gtinBarcode"
            label="SKU-Level Barcode (UPC-A, EAN-13, ITF-14, Code-128)"
            error={errors.gtinBarcode}
          >
            <input
              id="gtinBarcode"
              name="gtinBarcode"
              type="text"
              className="form-control"
              ref={register}
              disabled={taughtDisabled(formData)}
            />
          </Field>
          <Field id="nmfc_class" label="NMFC Class" error={errors.nmfcClass}>
            <input id="nmfc_class" name="nmfcClass" type="text" className="form-control" ref={register} />
          </Field>
          <Field id="nmfc_number" label="NMFC Number" error={errors.nmfcNumber}>
            <input id="nmfc_number" name="nmfcNumber" type="text" className="form-control" ref={register} />
          </Field>
          <Roles show={VBW_ELIGIBLE_ROLES}>
            <Field id="disable_vbw" label="Disable VBW (Verify by Weight) for this SKU" error={errors.disableVBW}>
              <br />
              <em>
                Select this option if the SKU represents a Purchase Order and may actually include multiple products
              </em>
              <input
                id="disable_vbw"
                name="disableVBW"
                type="checkbox"
                className="form-control"
                ref={register}
                disabled={!canSetVBWEligible}
              />
            </Field>
            <Field id="vbw_opt_out_reason" label="Reason for disabling VBW" error={errors.vbwOptOutReason}>
              <select
                id="vbw_opt_out_reason"
                name="vbwOptOutReason"
                defaultValue={watch('vbwOptOutReason')}
                className="form-control"
                ref={register({ required: watch('disableVBW') })}
                disabled={!watch('disableVBW')}
              >
                <option key={0} value={undefined}></option>
                {Object.values(OptOutReasonEnum).map((reasonValue) => (
                  <option key={reasonValue} value={reasonValue}>
                    {optOutReasonDescription(reasonValue)}
                  </option>
                ))}
              </select>
            </Field>
          </Roles>
        </Panel.Body>
        <Panel.Footer>
          <Button kind="primary" type="submit" loading={submitLoading}>
            Save
          </Button>
        </Panel.Footer>
      </form>
    </Panel>
  );
};

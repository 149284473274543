import React from 'react';

import { Claim__ResolutionFragment, Claim__ResolutionKind } from '@admin/schema';

export const ResolutionSummary: React.FC<{
  resolution: Claim__ResolutionFragment;
}> = ({ resolution }) => {
  const { kind, reason } = resolution;

  if (!kind) {
    return null;
  }

  return (
    <>
      {kind === Claim__ResolutionKind.Compensated && <>Valid</>}
      {kind === Claim__ResolutionKind.Denied && <>Denied - {reason}</>}
    </>
  );
};

(() => {
  const app = angular.module('app');

  app.service('ItemsCategorizablesModalService', [
    'ngDialog',
    function (ngDialog) {
      const self = this;
      self.present = (items, callback) => {
        if (items) {
          ngDialog
            .openConfirm({
              template: 'partials/item_categorizables/modal.html',
              controller: 'ItemCategorizablesModalController',
              resolve: {
                items: () => items,
              },
            })
            .then(() => callback(items));
        }
      };
    },
  ]);
})();

import React from 'react';

import { Summary as PromotionSummary } from '@admin/components/self_storage/promotions/summary';
import { AnchorButton as Link, Table as BSTable } from '@shared/components/bootstrap';
import { Currency, Timestamp } from '@shared/components/helpers';

import { detailSelfStorageUnitURL as unitURL } from '@admin/config/routes';

import { SelfStorage__ClassificationFacilityInput, SelfStorage__UnitFragment } from '@admin/schema';
import { getClimateForUnit, getLocationForUnit, getSizeForUnit } from './helpers';

const STATUS_LABELS = {
  AVAILABLE: 'Available',
  UNAVAILABLE: 'Unavailable',
  RESERVED: 'Reserved',
  RENTED: 'Rented',
  AUCTION: 'Auction',
  HELD: 'Held',
  DESIGNATED: 'Designated',
};

export const Table: React.FC<{
  units: SelfStorage__UnitFragment[];
  selectedIDs?: { [key: string]: SelfStorage__ClassificationFacilityInput };
  addIDs?: (IDs: { [key: string]: SelfStorage__ClassificationFacilityInput }) => void;
  addPromotionIDs?: (IDs: string[]) => void;
  removeIDs?: (keys: string[]) => void;
  removePromotionIDs?: (IDs: string[]) => void;
}> = ({ units, selectedIDs, addIDs, addPromotionIDs, removeIDs, removePromotionIDs }) => {
  const groupIDs: { [key: string]: SelfStorage__ClassificationFacilityInput } = {};
  const keysByUnitID: { [key: string]: string } = {};
  const promotionIDs = new Set<string>();
  units.forEach((unit) => {
    const groupID =
      unit.facility && unit.classification
        ? {
            facilityID: unit.facility.id,
            classificationID: unit.classification.id,
          }
        : undefined;
    if (groupID && unit.id) {
      const key = JSON.stringify(groupID);
      groupIDs[key] = groupID;
      keysByUnitID[unit.id] = key;
    }
    if (unit.promotion) {
      promotionIDs.add(unit.promotion.id);
    }
  });

  const allChecked = selectedIDs && Object.keys(groupIDs).every((key) => !!selectedIDs[key]);
  return (
    <BSTable>
      <thead>
        <tr>
          {selectedIDs && addIDs && addPromotionIDs && removeIDs && removePromotionIDs && (
            <th>
              <input
                type="checkbox"
                checked={allChecked}
                onChange={() => {
                  if (!allChecked) {
                    addIDs(groupIDs);
                    addPromotionIDs(Array.from(promotionIDs));
                  } else {
                    removeIDs(Object.keys(groupIDs));
                    removePromotionIDs(Array.from(promotionIDs));
                  }
                }}
              />
            </th>
          )}
          <th>Uploaded</th>
          <th>Facility</th>
          <th>Unit Name</th>
          <th>Unit Type</th>
          <th>Unit Size</th>
          <th>Location</th>
          <th>Climate</th>
          <th>Pricing</th>
          <th>Status</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {units.map((unit, index) => {
          const key = unit.id && keysByUnitID[unit.id];
          const groupID = key && groupIDs[key];

          return (
            <tr key={unit.id || index}>
              {selectedIDs && key && groupID && addIDs && addPromotionIDs && removeIDs && removePromotionIDs && (
                <td>
                  <input
                    type="checkbox"
                    checked={!!selectedIDs[key]}
                    onChange={() => {
                      if (!selectedIDs[key]) {
                        addIDs({ [key]: groupID });
                        if (unit.promotion) {
                          addPromotionIDs([unit.promotion.id]);
                        }
                      } else {
                        removeIDs([key]);
                        if (unit.promotion) {
                          removePromotionIDs([unit.promotion.id]);
                        }
                      }
                    }}
                  />
                </td>
              )}
              <td>{<Timestamp value={unit.updatedAt || new Date()} format={Timestamp.Format.Date} />}</td>
              <td>{unit.facility && unit.facility.name}</td>
              <td>{unit.name}</td>
              <td>{unit.classification && unit.classification.kind && unit.classification.kind.name}</td>
              <td>{getSizeForUnit(unit)}</td>
              <td>{getLocationForUnit(unit)}</td>
              <td>{getClimateForUnit(unit)}</td>
              <td>
                {unit.price && (
                  <div>
                    <span>
                      Standard: <Currency value={unit.price.rate} />
                    </span>
                    <span> | </span>
                    <span>
                      Web: <Currency value={unit.price.pushRate} />
                    </span>
                  </div>
                )}
                {unit.promotion && (
                  <div>
                    <PromotionSummary promotion={unit.promotion} />
                  </div>
                )}
              </td>
              <td>{unit.status && STATUS_LABELS[unit.status]}</td>
              <td>
                {unit.id && (
                  <Link kind="default" href={unitURL({ id: unit.id })}>
                    View
                  </Link>
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
    </BSTable>
  );
};

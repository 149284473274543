import styled from '@emotion/styled';
import React from 'react';

import { SelfStorage__Classification, SelfStorage__Unit } from '@admin/schema';

import { getOrdinalSuffix } from '@shared/utils/get_ordinal_suffix';

const DetailsList = styled.ul`
  margin: 0px 0px 0px -24px;
`;

export const UnitDetails: React.FC<{
  unit: Pick<SelfStorage__Unit, 'id' | 'floor'> & {
    classification: Pick<SelfStorage__Classification, 'id' | 'climate' | 'indoors'>;
  };
}> = ({ unit }) => (
  <DetailsList>
    <li>{unit.classification.indoors ? 'Indoors' : 'Outdoors'}</li>
    {unit.floor && (
      <li>
        {unit.floor}
        {getOrdinalSuffix(unit.floor)} Floor
      </li>
    )}
    {unit.classification.climate && <li>Climate Controlled</li>}
  </DetailsList>
);

import template from '@admin/angular/templates/items_table.html';

(() => {
  const app = angular.module('app');

  app.component('itemsTable', {
    template,
    bindings: {
      items: '<',
      groups: '<?',
      mode: '<?',
    },
    controller: function () {
      this.group = (item) => {
        if (this.groups && item.group_id) {
          return _.find(this.groups, (group) => group.id === item.group_id);
        }
      };
    },
  });
})();

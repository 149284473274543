import { css, SerializedStyles } from '@emotion/react';
import { Maybe } from '@admin/schema';

export const poorAssessmentStyle = css`
  color: red;
`;

export const goodAssessmentStyle = css`
  color: green;
`;

export const assess =
  (criterion: (value: number, source: number) => Maybe<SerializedStyles>) =>
  (values: Array<number | null | undefined>, source: Array<number | null | undefined>) =>
    values.map((_, i) => {
      const value = values[i];
      const sourceValue = source[i];

      if (value === undefined || value === null || sourceValue === undefined || sourceValue === null) {
        return null;
      }

      return criterion(value, sourceValue);
    });

import styled from '@emotion/styled';
import * as React from 'react';

import { Button, Modal } from '@shared/components/bootstrap';

const Title = styled.div`
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  text-align: center;
  white-space: normal;
`;

const Description = styled.div`
  font-size: 18px;
  font-weight: 200;
  line-height: 24px;
  text-align: center;
  white-space: normal;
`;

const Actions = styled.div`
  text-align: center;
`;

export const Dialog: React.FC<{
  title: string;
  description: string;
  onClose(): void;
}> = ({ title, description, onClose }) => (
  <Modal onClose={onClose}>
    <Modal.Content>
      <Modal.Header>
        <Modal.Title>
          <Title>{title}</Title>
        </Modal.Title>
        <Modal.Close close={onClose} />
      </Modal.Header>
      <Modal.Body>
        <Description>{description}</Description>
      </Modal.Body>
      <Modal.Footer>
        <Actions>
          <Button kind="default" size="lg" onClick={onClose}>
            Continue
          </Button>
        </Actions>
      </Modal.Footer>
    </Modal.Content>
  </Modal>
);

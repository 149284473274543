import template from '@admin/angular/templates/order_uncancel_action.html';

function controller($http, $window, ConfirmationService, ErrorService) {
  this.scheduled_at = moment().subtract(1, 'minutes');

  this.uncancel = () => {
    $http.patch(`/orders/${this.order.id}/uncancel.json`, { scheduled_at: this.scheduled_at }).then(() => {
      ConfirmationService.confirm({
        title: 'Order successfully uncanceled.',
        showDeclineButton: false,
        acceptButton: 'Done',
        dialogOptions: { showClose: false },
      }).then(() => {
        $window.location.reload();
      });
    }, ErrorService.handle);
  };

  this.disableUncancel = () => this.scheduled_at == null || !(this.scheduled_at >= Date.now());
}

angular.module('app').component('orderUncancelAction', {
  bindings: {
    order: '<',
  },
  template,
  controller: ['$http', '$window', 'ConfirmationService', 'ErrorService', controller],
});

import { Status, User, useUserActivateMutation } from '@admin/schema';
import React from 'react';
import { client } from '@admin/libraries/apollo';
import { Alert, Button, Modal } from '@shared/components/bootstrap';
import styled from '@emotion/styled';

const Title = styled.div`
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  text-align: center;
`;

const Summary = styled.p`
  font-size: 18px;
  font-weight: 200;
  line-height: 24px;
  text-align: center;
`;

const Buttons = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
`;

export const Activate: React.FC<{
  user: Pick<User, 'id' | 'name'>;
  onClose(): void;
}> = ({ onClose, user }) => {
  const [execute, { data, loading }] = useUserActivateMutation({ client, variables: { id: user.id } });

  const onConfirm = async () => {
    const result = await execute();
    if (result?.data?.result.status === Status.Ok) {
      onClose();
    }
  };

  return (
    <Modal onClose={onClose}>
      <Modal.Content>
        <Modal.Header>
          <Modal.Close close={onClose} />
        </Modal.Header>
        <Modal.Body>
          {data?.result?.error && <Alert style="danger">{data.result.error}</Alert>}
          <Title>Activate "{user.name}"</Title>
          <Summary>Are you sure you want to activate "{user.name}"?</Summary>
        </Modal.Body>
        <Modal.Footer>
          <Buttons>
            <Button disabled={loading} kind="default" onClick={onClose} size="lg">
              No
            </Button>
            <Button loading={loading} kind="danger" onClick={onConfirm} size="lg">
              Yes
            </Button>
          </Buttons>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};

import { round, sumBy } from 'lodash';
import { DateTime } from 'luxon';
import React, { useState } from 'react';

import { LabelledAttribute } from '@admin/components/helpers/labelled_attribute';
import { displayableText } from '@admin/components/payment_plans/helpers';
import { editPaymentPlanOfferURL } from '@admin/config/routes';
import {
  Admin__Billing__InvoiceFragment as Invoice,
  Status,
  useExtendPaymentPlanOfferMutation,
  useAcceptPaymentPlanOfferMutation,
} from '@admin/schema';
import { OfferChanges } from '@admin/types/payment_plan/offer';
import { Button, Modal, Text } from '@shared/components/bootstrap';
import { Currency, Timestamp } from '@shared/components/helpers';
import { capitalize } from '@shared/utils/capitalize';
import { client } from '@admin/libraries/apollo';
import { BillingMethod, BillingMethodSourceType } from '@admin/types';

const sourceDescription = (source: BillingMethod) => {
  if (source.type === BillingMethodSourceType.Source) {
    return `${source.brand} ${source.number} expires ${source.expiration}`;
  } else if (source.type === BillingMethodSourceType.Other) {
    return `${capitalize(source.kind!.toLowerCase().replace('_', ' '))} ${source.transactionNumber || ''}`;
  }
};

export const OfferModal: React.FC<{
  offerID?: string;
  accountID: string;
  selectedInvoices: Invoice[];
  offerChanges: OfferChanges;
  setOfferChanges(changes: OfferChanges): void;
  onOffer?(): void;
  onClose(): void;
  payImmediately: boolean;
  source?: BillingMethod;
}> = ({
  offerID,
  accountID,
  selectedInvoices,
  offerChanges,
  setOfferChanges,
  onOffer,
  onClose,
  payImmediately,
  source,
}) => {
  const [error, setError] = useState<string | undefined>();
  const [extendOffer, { loading: extendOfferLoading }] = useExtendPaymentPlanOfferMutation({ client });
  const [acceptOffer, { loading: acceptOfferLoading }] = useAcceptPaymentPlanOfferMutation({ client });
  const loading = extendOfferLoading || acceptOfferLoading;

  const makeRequest = async () => {
    const input = {
      ...offerChanges,
      accountID,
      offerID: offerID || offerChanges.offerID,
      oneTimeFee: offerChanges.oneTimeFee ? round(offerChanges.oneTimeFee, 2) : undefined,
      settledInvoiceIDs: selectedInvoices.map(({ id }) => id),
    };
    let sourceID;
    let offlineSource;
    let response;
    if (payImmediately) {
      input.expiry = DateTime.local().toISO();
      if (source?.type === BillingMethodSourceType.Source) {
        sourceID = source.id;
      } else if (source?.type === BillingMethodSourceType.Other) {
        offlineSource = { kind: source.kind!, transactionNumber: source.transactionNumber };
      }
      response = (await acceptOffer({ variables: { input, sourceID, offlineSource } })).data!.acceptOffer;
    } else {
      response = (await extendOffer({ variables: { input } })).data!.extendOffer;
    }
    return [response.status, response.error, response.offer] as const;
  };

  const onConfirmAndOffer = async () => {
    const [status, requestError, offer] = await makeRequest();
    if (status === Status.Unprocessable) {
      setOfferChanges({ ...offerChanges, offerID: offer?.id });
      setError(`There was a problem extending or accepting the payment plan offer: ${requestError}`);
    } else if (status === Status.Ok) {
      setError(undefined);
      if (!offerID) {
        location.replace(editPaymentPlanOfferURL({ accountID, offerID: offer!.id }));
      } else if (onOffer) {
        onOffer();
      }
    }
  };

  const totalAmountDue = sumBy(selectedInvoices, (invoice) => invoice.amountDue);
  return (
    <Modal onClose={onClose}>
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>Confirm and {payImmediately ? 'Pay' : 'Offer'}</Modal.Title>
          <Modal.Close close={onClose} />
        </Modal.Header>
        <Modal.Body>
          <LabelledAttribute label="Plan Type" attribute={displayableText(offerChanges.type!)} />
          <LabelledAttribute
            label={payImmediately ? `Included Invoices (Total: $${totalAmountDue})` : 'Forgiven Invoices'}
            attribute={
              <ul>
                {selectedInvoices.map((invoice) => (
                  <li key={invoice.id}>
                    #{invoice.id} - {invoice.summary} - <Currency value={invoice.amountDue} />
                  </li>
                ))}
              </ul>
            }
          />
          {offerChanges.oneTimeFee && (
            <LabelledAttribute
              label={payImmediately ? 'Amount Due' : 'One Time Fee'}
              attribute={<Currency value={offerChanges.oneTimeFee} />}
            />
          )}
          {offerChanges.storageTerm && (
            <LabelledAttribute label="Storage Commitment" attribute={<>{offerChanges.storageTerm} months</>} />
          )}
          {offerChanges.monthlyFee && (
            <LabelledAttribute label="Monthly Fee" attribute={<Currency value={offerChanges.monthlyFee} />} />
          )}
          {offerChanges.monthlyFeeTerm && (
            <LabelledAttribute label="Monthly Fee Term" attribute={<>{offerChanges.monthlyFeeTerm} months</>} />
          )}
          {payImmediately && <LabelledAttribute label="Source" attribute={sourceDescription(source!)} />}
          {!payImmediately && (
            <LabelledAttribute
              label="Expiry Date"
              attribute={<Timestamp value={offerChanges.expiry!} format={Timestamp.Format.DateTime} />}
            />
          )}
          {error && (
            <Text tag="p" style="danger" alignment="right">
              {error}
            </Text>
          )}
          <div className="mar-top text-right">
            <Button kind="default" disabled={loading} onClick={onClose}>
              Cancel
            </Button>
            <Button kind="success" className="mar-lft" loading={loading} onClick={onConfirmAndOffer}>
              Confirm and {payImmediately ? 'Pay' : 'Offer'}
            </Button>
          </div>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};

(function () {
  angular.module('app').factory('Customer', [
    '$resource',
    function ($resource) {
      return $resource(
        '/accounts/:account_id/customers/:id.json',
        {
          id: '@id',
          account_id: '@account_id',
        },
        {
          show: {
            method: 'GET',
            isArray: false,
          },
          update: {
            method: 'PUT',
          },
          query: {
            method: 'GET',
            isArray: true,
            url: '/customers.json',
          },
        },
      );
    },
  ]);
})();

(function () {
  const DEFAULT_OFFSET = 0;

  angular.module('app').service('DispatcherPositionService', [
    'DispatcherConstants',
    function (DispatcherConstants) {
      const self = this;

      const TIMES = {
        from: DispatcherConstants.DEFAULT_TIMES_FROM,
        till: DispatcherConstants.DEFAULT_TIMES_TILL,
      };

      function normalize(value, tz) {
        const adjusted = moment.tz(value, tz);
        return (
          (adjusted.hour() * 60 * 60 + adjusted.minute() * 60 - TIMES.from * 60 * 60) /
          ((TIMES.till - TIMES.from) * 60 * 60)
        );
      }

      self.positionForOrder = function (order, options) {
        const tz = order.region.tz;
        let travelAndJobDuration = order.estimated_duration;
        if (order.moving) {
          travelAndJobDuration += order.moving_operation.estimated_duration;
        }
        const duration = moment.duration(
          travelAndJobDuration || DispatcherConstants.DEFAULT_ESTIMATED_DURATION,
          DispatcherConstants.ESTIMATED_DURATION_UNITS,
        );
        const offset = moment.duration(
          options.offset ? options.offset : DEFAULT_OFFSET,
          DispatcherConstants.DISPATCHER_OFFSET_UNITS,
        );
        const from = moment(order.scheduled) + offset;
        let till = moment(order.scheduled) + offset + duration;

        if (options.padded) {
          till += moment.duration(order.sla_window_size * DispatcherConstants.DEFAULT_WINDOW_SLOT_DURATION, 'seconds');
        }

        return {
          top: normalize(from, tz),
          bottom: normalize(till, tz),
        };
      };

      self.positionForTime = function (time, tz) {
        return normalize(time, tz);
      };
    },
  ]);
})();

import styled from '@emotion/styled';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';

import { LabelledAttribute } from '@admin/components/helpers/labelled_attribute';
import { Files } from '@admin/components/notes/files';
import {
  CustomerTicket__Priority,
  CustomerTicket__ContactPreference,
  CustomerTicketFragment,
  AttachmentFragment,
  ActiveStorageAttachment,
} from '@admin/schema';
import { CUSTOMER_TICKET_PRIORITIES } from '@admin/types/customer_ticket';
import { Timestamp } from '@shared/components/helpers';
import { capitalize } from 'lodash';
import { FEATURE_FLAG } from '@shared/config/feature_flag';

const Container = styled.div`
  .labelled-attribute {
    margin: 5px 0;
  }
`;

const formatPriority = (priority: CustomerTicket__Priority) => {
  const ticketPriority = CUSTOMER_TICKET_PRIORITIES.find((p) => p.value === priority);
  return `${ticketPriority?.name} - ${ticketPriority?.details}`;
};

const formatContactPreference = (contactPreference?: CustomerTicket__ContactPreference | null) => {
  if (
    contactPreference &&
    [
      CustomerTicket__ContactPreference.Chat,
      CustomerTicket__ContactPreference.Email,
      CustomerTicket__ContactPreference.Phone,
    ].includes(contactPreference)
  ) {
    return capitalize(contactPreference);
  }
  return '-';
};

const FileList: React.FC<{ files: ActiveStorageAttachment[] }> = ({ files }) => (
  <Files>
    {files.map((file) => (
      <Files.Download key={file.id} target="_blank" href={file.imgixURL}>
        <FontAwesomeIcon icon={faDownload} color="#444444" /> {file.filename}
      </Files.Download>
    ))}
  </Files>
);

export const Attributes: React.FC<{
  customerTicket: CustomerTicketFragment & {
    files: Array<{ __typename?: 'ActiveStorageAttachment' } & AttachmentFragment>;
  };
  customerTicketableLink: string;
}> = ({ customerTicket, customerTicketableLink }) => {
  const {
    customerTicketable,
    customerTicketableType,
    assignee,
    creator,
    category,
    group,
    subCategory,
    description,
    state,
    createdAt,
    priority,
    actionRequiredAt,
    files,
    contactPreference,
    allowIncomingCalls,
  } = customerTicket;

  return (
    <Container>
      <a href={customerTicketableLink} target="_blank">
        <LabelledAttribute label={customerTicketableType} attribute={customerTicketable.shortDisplay} />
      </a>
      <LabelledAttribute
        label="Opened on"
        attribute={
          <>
            <Timestamp value={createdAt} format={Timestamp.Format.DateTime} />{' '}
            <span>(by {creator ? creator.name : 'Customer'})</span>
          </>
        }
      />
      <LabelledAttribute label="Group" attribute={group?.name ?? '-'} />
      <LabelledAttribute label="Assignee" attribute={assignee?.name ?? 'Unassigned'} />
      <LabelledAttribute label="Priority" attribute={priority ? formatPriority(priority) : '-'} />
      <LabelledAttribute
        label="Action Required At"
        attribute={actionRequiredAt ? <Timestamp value={actionRequiredAt} format={Timestamp.Format.DateTime} /> : 'N/A'}
      />
      <LabelledAttribute label="State" attribute={state} />
      <LabelledAttribute label="Contact Preference" attribute={formatContactPreference(contactPreference)} />
      <LabelledAttribute
        label="Category"
        attribute={
          <>
            {category}
            {subCategory && ` - ${subCategory}`}
          </>
        }
      />
      <LabelledAttribute label="Description" attribute={<p className="pre-wrap">{description}</p>} />
      <LabelledAttribute
        label="Customer uploaded files"
        attribute={files?.length ? <FileList files={files} /> : 'N/A'}
      />
      {FEATURE_FLAG.new_ivr_flow_2022_06 && (
        <div className="checkbox">
          <label>
            <input type="checkbox" checked={allowIncomingCalls} disabled={true} />
            Allow Incoming Calls
          </label>
        </div>
      )}
    </Container>
  );
};

(function () {
  angular.module('app').factory('Ticket', [
    '$resource',
    function ($resource) {
      return $resource(
        '/tickets/:id.json',
        {
          id: '@id',
        },
        {
          index: {
            method: 'GET',
            isArray: true,
          },
          show: {
            method: 'GET',
          },
          create: {
            method: 'POST',
          },
          update: {
            method: 'PATCH',
          },
          delete: {
            method: 'DELETE',
          },
          claim: {
            method: 'PATCH',
            url: '/tickets/:ticket_id/claim.json',
            params: {
              ticket_id: '@ticket_id',
            },
          },
          assignees: {
            method: 'GET',
            url: '/tickets/assignees.json',
            isArray: true,
          },
          creators: {
            method: 'GET',
            url: '/tickets/creators.json',
            isArray: true,
          },
          categories: {
            method: 'GET',
            url: '/tickets/categories.json',
          },
          business_units: {
            method: 'GET',
            url: '/tickets/business_units.json',
            isArray: true,
          },
          areas: {
            method: 'GET',
            url: '/tickets/areas.json',
            isArray: true,
          },
        },
      );
    },
  ]);
})();

import styled from '@emotion/styled';
import React from 'react';

import { displayableText } from '@admin/components/payment_plans/helpers';
import { Admin__Billing__InvoiceFragment as Invoice } from '@admin/schema';
import { InvoiceSelections } from '@admin/types/payment_plan/offer';
import { Checkbox, Button, Table } from '@shared/components/bootstrap';
import { Timestamp, Currency } from '@shared/components/helpers';

const SelectAllButton = styled(Button)`
  padding: 0;
`;

const Row: React.FC<{
  invoice: Invoice;
  checked: boolean;
  onChange(checked: boolean): void;
}> = ({ invoice, checked, onChange }) => {
  const { id, createdAt, summary, status, amountDue } = invoice;

  return (
    <tr>
      <td className="col-zero">
        <Checkbox>
          <input
            name="invoice"
            type="checkbox"
            checked={checked}
            onChange={(event) => onChange(event.target.checked)}
          />
        </Checkbox>
      </td>
      <td className="col-md-2 text-left">#{id}</td>
      <td className="col-md-2 text-left">
        <Timestamp value={createdAt || ''} format={Timestamp.Format.Date} />
      </td>
      <td className="col-md-3 text-left">{summary}</td>
      <td className="col-md-3 text-left">{displayableText(status)}</td>
      <td className="col-md-2 text-left">
        <Currency value={amountDue} />
      </td>
    </tr>
  );
};

export const InvoiceTable: React.FC<{
  invoices: Invoice[];
  invoiceSelections: InvoiceSelections;
  totalChecked: number;
  setInvoiceSelections(invoiceSelections: InvoiceSelections, checkedAmount: number): void;
}> = ({ invoices, invoiceSelections, totalChecked, setInvoiceSelections }) => {
  const onSelectAll = () => {
    const allInvoices: { selections: InvoiceSelections; total: number } = { selections: {}, total: 0 };
    invoices.reduce((acc, invoice) => {
      acc.selections[invoice.id] = true;
      acc.total += invoice.amountDue;
      return acc;
    }, allInvoices);
    setInvoiceSelections(allInvoices.selections, allInvoices.total);
  };

  return (
    <Table responsive>
      <thead>
        <tr>
          <th className="col-zero"></th>
          <th className="col-md-2 text-left">Invoice</th>
          <th className="col-md-2 text-left">Date</th>
          <th className="col-md-3 text-left">Description</th>
          <th className="col-md-3 text-left">Status</th>
          <th className="col-md-2 text-left">Amount Due</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <SelectAllButton kind="link" onClick={onSelectAll}>
              Select All ({invoices.length})
            </SelectAllButton>
          </td>
        </tr>
        {invoices.map((invoice) => (
          <Row
            key={invoice.id}
            invoice={invoice}
            checked={!!invoiceSelections[invoice.id]}
            onChange={(checked) => {
              const newAmount = checked ? totalChecked + invoice.amountDue : totalChecked - invoice.amountDue;
              setInvoiceSelections(
                {
                  ...invoiceSelections,
                  [invoice.id]: checked,
                },
                newAmount,
              );
            }}
          />
        ))}
        <tr>
          <td className="col-zero" />
          <td className="col-md-2" />
          <td className="col-md-2" />
          <td className="col-md-3" />
          <td className="col-md-3 text-right">
            <strong>Total:</strong>
          </td>
          <td className="col-md-2 text-left">
            <strong>
              <Currency value={totalChecked} />
            </strong>
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

(function () {
  const app = angular.module('app');

  app.component('searchBar', {
    bindings: {
      initialSearchTerm: '<?',
      onChange: '&',
      placeholderText: '@',
    },
    templateUrl: 'partials/components/search_bar.html',

    controller: function () {
      const self = this;
      self.handleChange = handleChange;
      self.$onInit = onInit;

      function handleChange() {
        self.onChange({ searchTerm: self.searchTerm });
      }

      function onInit() {
        self.searchTerm = self.initialSearchTerm;
      }
    },
  });
})();

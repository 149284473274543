import template from '@admin/angular/templates/spinner.html';

(() => {
  const app = angular.module('app');

  app.component('spinner', {
    template,
    bindings: {
      visible: '<',
    },
  });
})();

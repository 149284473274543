import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCaretDown as fasCaretDown,
  faCheckCircle as fasCheckCircle,
  faCheckSquare as fasCheckSquare,
  faChevronDown as fasChevronDown,
  faChevronRight as fasChevronRight,
  faDownload as fasDownload,
  faExclamationCircle as fasExclamationCircle,
  faExclamationTriangle as fasExclamationTriangle,
  faHistory as fasHistory,
  faMinus as fasMinus,
  faPaperclip as fasPaperclip,
  faPlus as fasPlus,
  faQuestionCircle as fasQuestionCircle,
  faSearch as fasSearch,
  faSignInAlt as fasSignInAlt,
  faSignOutAlt as fasSignOutAlt,
  faStopwatch as fasStopwatch,
  faThermometerHalf as fasThermometerHalf,
  faTimes as fasTimes,
  faTruckMoving as fasTruckMoving,
  faUsers as fasUsers,
  faList as fasList,
  faPencil as fasPencil,
  faRefresh as fasRefresh,
} from '@fortawesome/free-solid-svg-icons';

import {
  faCheckCircle as farCheckCircle,
  faCheckSquare as farCheckSquare,
  faCircle as farCircle,
  faEdit as farEdit,
  faSave as farSave,
  faSquare as farSquare,
  faTimesCircle as farTimesCircle,
} from '@fortawesome/free-regular-svg-icons';

library.add(
  fasCaretDown,
  farCheckCircle,
  farCircle,
  farEdit,
  farSave,
  farSquare,
  farCheckSquare,
  farTimesCircle,
  fasCheckCircle,
  fasCheckSquare,
  fasChevronDown,
  fasChevronRight,
  fasDownload,
  fasExclamationCircle,
  fasExclamationTriangle,
  fasHistory,
  fasMinus,
  fasSignInAlt,
  fasSignOutAlt,
  fasStopwatch,
  fasThermometerHalf,
  fasPaperclip,
  fasPlus,
  fasTruckMoving,
  fasSearch,
  fasTimes,
  fasUsers,
  fasList,
  fasPencil,
  fasQuestionCircle,
  fasRefresh,
);

import React, { useState } from 'react';

import { Panel } from '@admin/components/helpers/panel';
import {
  OrderServiceTypeEnum,
  OrderTypeEnum,
  useDispatchUpdateMutation,
  useDispatchEditDispatchQuery,
  useDispatchEditRegionQuery,
  DispatchEditDispatchQuery,
} from '@admin/schema';
import { Breadcrumb, Button, Alert, Col } from '@shared/components/bootstrap';
import { client } from '@admin/libraries/apollo';
import { FieldFormGroup, MultiselectFormControl, SelectFormGroup } from '@admin/components/fields';
import { dispatchesURL, dispatchURL } from '@admin/config/routes';
import { DateTime } from 'luxon';
import { Spinner } from '@admin/components/spinner';

type Dispatch = DispatchEditDispatchQuery['dispatch'];
type Order = DispatchEditDispatchQuery['dispatch']['orders'][0];

const Breadcrumbs = ({ dispatch }: { dispatch: Dispatch }) => {
  const { id, region, arrival } = dispatch;

  const date = DateTime.fromISO(arrival, { zone: region.tz });
  const dateCopy = date.toFormat('MM/dd/yyyy');
  const dateParam = date.toFormat('yyyy-MM-dd');

  return (
    <div className="page-header text-overflow">
      <Breadcrumb>
        <Breadcrumb.Item>
          <a href={dispatchesURL({ region: region.id })}>{dispatch.region.name}</a>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <a href={dispatchesURL({ region: region.id, date: dateParam })}>{dateCopy}</a>
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Dispatch #{id}</Breadcrumb.Item>
      </Breadcrumb>
    </div>
  );
};

const isWarehouseOrder = (order: Order) =>
  order.address.addressableType === 'Warehouse' &&
  order.type !== OrderTypeEnum.Restock &&
  order.serviceType !== OrderServiceTypeEnum.DropOff;

export const Edit: React.FC<{ dispatchID: string }> = ({ dispatchID }) => {
  const [vehicleIDs, setVehicleIDs] = useState<string[]>([]);
  const [inboundWarehouseID, setInboundWarehouseID] = useState<string>('');
  const [error, setError] = useState<string | null>(null);

  const { loading, data } = useDispatchEditDispatchQuery({
    client,
    variables: { dispatchID },
    onCompleted: ({ dispatch }) => {
      setVehicleIDs(dispatch.vehicles.map(({ id }) => id));
      setInboundWarehouseID(dispatch.inboundWarehouse?.id || '');
    },
  });

  const { loading: loadingRegion, data: dataRegion } = useDispatchEditRegionQuery({
    client,
    variables: {
      vehicleFilters: { regionIDs: [data?.dispatch.region.id as string], active: true, fieldDispatchable: true },
      warehouseFilters: { regionIDs: [data?.dispatch.region.id as string], active: true },
    },
    skip: !data,
  });

  const [save, { loading: submitting }] = useDispatchUpdateMutation({ client });

  if (loading || loadingRegion) return <Spinner />;
  if (!data || !dataRegion) return null;

  const { dispatch } = data;
  const { vehicles, warehouses } = dataRegion;
  const hasWarehouseOrder = dispatch.orders.some(isWarehouseOrder);

  const saveDispatch: React.FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    const response = await save({ variables: { id: dispatch.id, input: { vehicleIDs, inboundWarehouseID } } });
    const saveError = response.data?.result.error;

    if (!saveError) {
      window.location.href = dispatchURL({ id: dispatch.id });
    } else {
      setError(saveError);
    }
  };

  return (
    <>
      <Breadcrumbs dispatch={dispatch} />

      <form className="form-horizontal" onSubmit={saveDispatch}>
        <Panel>
          <Panel.Header>
            <Panel.Title>Details</Panel.Title>
          </Panel.Header>

          <Panel.Body>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Col sm={6} style={{ marginTop: '8px' }}>
                <FieldFormGroup label="Vehicle(s)">
                  <MultiselectFormControl
                    disabled={hasWarehouseOrder}
                    placeholder="Assign one or more vehicles"
                    options={vehicles}
                    selectedIDs={vehicleIDs}
                    onChange={(ids) => setVehicleIDs(ids)}
                  />
                </FieldFormGroup>
              </Col>

              <Col sm={6} style={{ marginTop: '8px' }}>
                <SelectFormGroup
                  onChange={(event) => setInboundWarehouseID(event.target.value)}
                  label="Inbound Warehouse"
                >
                  <option disabled={!!inboundWarehouseID} value="">
                    Select inbound warehouse
                  </option>
                  {warehouses.map(({ id, name }) => (
                    <option key={id} value={id} selected={id === inboundWarehouseID}>
                      {name}
                    </option>
                  ))}
                </SelectFormGroup>
              </Col>

              {error && (
                <Col sm={6} style={{ marginTop: '8px' }}>
                  <Alert style="danger">{error}</Alert>
                </Col>
              )}
            </div>
          </Panel.Body>

          <Panel.Footer align="right">
            <a href={dispatchURL({ id: dispatch.id })} className="btn btn-default">
              Cancel
            </a>
            <Button type="submit" kind="primary" loading={submitting}>
              Save
            </Button>
          </Panel.Footer>
        </Panel>
      </form>
    </>
  );
};

import React from 'react';
import { useState } from 'react';

import { ItemExceptionFragment } from '@admin/schema';

import { Timestamp } from '@admin/components/timestamp';
import { Lightbox } from '@shared/components/helpers/lightbox';

export const Entry: React.FC<{
  itemException: ItemExceptionFragment;
  timezone: string;
}> = ({ itemException, timezone }) => {
  const [selection, setSelection] = useState<string | undefined>(undefined);
  const { createdAt, creator, reason, explanation, deletedAt, image } = itemException;
  return (
    <tr>
      <td className="col-sm-2 text-left">
        <Timestamp value={createdAt} format="medium" timezone={timezone} />
      </td>
      <td className="col-sm-1 text-left">{creator.name}</td>
      <td className="col-sm-2 text-left">{reason}</td>
      <td className="col-sm-2 text-left">{explanation}</td>
      <td className="col-sm-2 text-left">
        {!!deletedAt && <Timestamp value={deletedAt} format="medium" timezone={timezone} />}
      </td>
      <td className="col-sm-3 text-left">
        {!!image && (
          <figure className="thumbnail">
            <a
              href="#"
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                setSelection(image.source);
              }}
            >
              <img className="item-exception-image" src={image.size} />
            </a>
          </figure>
        )}
        {selection && <Lightbox imageURL={selection} onClose={() => setSelection(undefined)} />}
      </td>
    </tr>
  );
};

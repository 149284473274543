import React, { FC, useRef } from 'react';
import { XYCoord } from 'react-dnd';
import { useDrag, useDrop } from 'react-dnd';

export const ITEM_TYPE = 'item';

export interface DragItem {
  index: number;
  id: string;
  type: string;
}

export const Item: FC<{
  id: any;
  index: number;
  moveItem: (dragIndex: number, hoverIndex: number) => void;
  onFinish(): void;
  tag?: React.ElementType<any>;
}> = ({ id, index, moveItem, children, onFinish, tag: Tag = 'div' as const }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: string | symbol | null }>({
    accept: ITEM_TYPE,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const activeItemIndex = item.index;

      if (activeItemIndex === index) {
        return;
      }

      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      if (
        (activeItemIndex < index && hoverClientY < hoverMiddleY) ||
        (activeItemIndex > index && hoverClientY > hoverMiddleY)
      ) {
        // Dragging over an item, but not passed the midpoint in either direction
        return;
      }

      moveItem(activeItemIndex, index);
      // Copied from this example, this prevents flickering for opaque reasons: https://codesandbox.io/s/compassionate-hugle-t7y24c
      item.index = index;
    },
    drop() {
      onFinish();
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ITEM_TYPE,
    item: () => ({ id, index }),
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  return (
    <Tag
      ref={ref}
      style={{
        opacity,
      }}
      data-handler-id={handlerId}
    >
      {children}
    </Tag>
  );
};

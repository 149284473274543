import React from 'react';
import { Formik, Form as FormikForm } from 'formik';

import {
  Status,
  Hrs__ProfileDefault,
  useHrsProfileDefaultUpsertMutation,
  Hrs__ProfileDefaultInput,
  Hrs__Location,
  Team,
} from '@admin/schema';

import { client } from '@admin/libraries/apollo';
import { FormikInputFormGroup } from '@shared/components/fields/formik/formik_input_form_group';
import { Alert, Button, Panel, Modal } from '@shared/components/bootstrap';
import { FormikSelectFormGroup } from '@shared/components/fields/formik/formik_select_form_group';
import { FormikAutosuggestFormGroup } from '@admin/components/fields/formik/formik_autosuggest_form_group';

export const UpsertModal: React.FC<{
  currentBusinessTitles: string[];
  locationOptions: Hrs__Location[];
  profileDefault?: Hrs__ProfileDefault;
  teamOptions: Team[];
  closeModal(): void;
}> = ({ currentBusinessTitles, locationOptions, profileDefault, teamOptions, closeModal }) => {
  const [save, { loading, data }] = useHrsProfileDefaultUpsertMutation({
    client,
    onCompleted: ({ result }) => {
      if (result?.status === Status.Ok) closeModal();
    },
  });

  const onSubmit = (input: Hrs__ProfileDefaultInput) => {
    save({
      variables: {
        id: profileDefault?.id,
        input,
      },
    });
  };

  return (
    <Modal onClose={closeModal}>
      <Formik<Hrs__ProfileDefaultInput>
        initialValues={{
          businessTitle: profileDefault?.businessTitle ?? '',
          locationID: profileDefault?.location?.id ?? null,
          orgLevel1: profileDefault?.orgLevel1,
          orgLevel2: profileDefault?.orgLevel2,
          orgLevel3: profileDefault?.orgLevel3,
          orgLevel4: profileDefault?.orgLevel4,
          teamID: profileDefault?.team?.id,
        }}
        onSubmit={onSubmit}
      >
        <FormikForm>
          <Panel>
            <Panel.Body>
              {data?.result?.error && <Alert style="danger">{data.result.error}</Alert>}
              <FormikAutosuggestFormGroup
                label="Business Title"
                name="businessTitle"
                options={currentBusinessTitles.map((option) => ({ label: option, value: option }))}
              />
              <FormikSelectFormGroup label="Location" name="locationID">
                <option value=""> - Select - </option>
                {locationOptions.map((location) => (
                  <option key={location.id} value={location.id}>
                    {location.code}
                  </option>
                ))}
              </FormikSelectFormGroup>
              <FormikInputFormGroup label="Org Level 1" name="orgLevel1" />
              <FormikInputFormGroup label="Org Level 2" name="orgLevel2" />
              <FormikInputFormGroup label="Org Level 3" name="orgLevel3" />
              <FormikInputFormGroup label="Org Level 4" name="orgLevel4" />
              <FormikSelectFormGroup label="Team" name="teamID">
                <option value="">None</option>
                {teamOptions.map((team) => (
                  <option key={team.id} value={team.id}>
                    {team.name}
                  </option>
                ))}
              </FormikSelectFormGroup>
            </Panel.Body>
            <Panel.Footer align="right">
              <Button kind="primary" onClick={closeModal}>
                Cancel
              </Button>
              <Button loading={loading} kind="primary" type="submit">
                Save
              </Button>
            </Panel.Footer>
          </Panel>
        </FormikForm>
      </Formik>
    </Modal>
  );
};

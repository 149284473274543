import React, { useMemo } from 'react';

import { DateTime, DateTimeFormatOptions } from 'luxon';

interface IFormattedDateProps {
  date: string;
  format?: DateTimeFormatOptions;
}

function toDateTime(dateStr: string) {
  return DateTime.fromISO(dateStr);
}

export const LocaleString: React.FC<IFormattedDateProps> = ({ date, format }) => {
  const resolvedDate = useMemo(() => toDateTime(date), [date]);

  return <>{resolvedDate.toLocaleString(format)}</>;
};

import { Spacer } from '@shared/components/helpers';
import React from 'react';

export const WarehouseReturnServiceTypeSelector: React.FC<{
  onChange: (id: string) => void;
  serviceType?: string;
}> = ({ onChange, serviceType }) => (
  <div className="row">
    <Spacer height="8px" />
    <div className="col-md-6">
      <select
        id="return-service-type"
        className="form-control"
        value={serviceType ?? ''}
        onChange={(event) => {
          onChange(event.target.value);
        }}
      >
        <option value="">- Service Type -</option>
        <option value={'auction'}> Auction </option>
      </select>
    </div>
  </div>
);

import angular from 'angular';

const app = angular.module('app');

app.run([
  '$rootScope',
  function ($rootScope) {
    document.addEventListener('broadcast', (event) => {
      $rootScope.$broadcast(event.name, event.data);
    });
  },
]);

import * as React from 'react';

import { Picker } from '@admin/components/helpers/category_with_sub_category_picker';
import { CUSTOMER_TICKET_CATEGORIES } from '@shared/config/customer_ticket_categories';

export const Categories: React.FC<{
  className?: string;
  selectedCategory: string;
  selectedSubCategory?: string;
  showSubCategoryIfEmpty?: boolean;
  onChange(category: string, subCategory: string): void;
}> = ({ className, selectedCategory, selectedSubCategory, showSubCategoryIfEmpty = false, onChange }) => (
  <Picker
    categories={CUSTOMER_TICKET_CATEGORIES}
    className={className}
    selectedCategory={selectedCategory}
    selectedSubCategory={selectedSubCategory}
    showSubCategoryIfEmpty={showSubCategoryIfEmpty}
    onChange={onChange}
  />
);

(function () {
  const TYPES = {
    DATE: 'date',
    TIME: 'time',
  };

  angular.module('app').service('DeserializerService', [
    function () {
      const self = this;

      self.types = TYPES;

      self.instance = function (mappings) {
        return function (data) {
          if (data) {
            _.each(mappings, function (type, key) {
              if (data[key]) {
                switch (type) {
                  case self.types.DATE:
                    data[key] = new Date(data[key]);
                    break;
                  case self.types.TIME:
                    data[key] = new Date(data[key]);
                    break;
                }
              }
            });
          }
          return data;
        };
      };

      self.collection = function (mappings) {
        const fn = self.instance(mappings);
        return function (data) {
          _.each(data, fn);
          return data;
        };
      };
    },
  ]);
})();

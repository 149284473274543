/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import $ from 'jquery';
import { selectFilteredDispatches } from './selectors';
import { RoutePanel } from './RoutePanel';
import { layoutEventSystem } from './propTypes';

const SCROLL_OFFSET = 20;

const scrollToInContainer = (id) => {
  const $container = $('#dispatches_wrapper');
  const $element = $(`#${id}`);
  if (!$element.length) {
    return;
  }
  const { top: panelTop } = $element.offset();
  const { top: containerTop } = $container.offset();
  const scrollTop = panelTop - containerTop + ($container.scrollTop() - SCROLL_OFFSET);
  $container.animate(
    {
      scrollTop,
    },
    700,
  );
};

class RoutePanels extends React.Component {
  componentDidUpdate({ activeDispatchId: previousActiveDispatchId }) {
    if (previousActiveDispatchId !== this.props.activeDispatchId) {
      scrollToInContainer(`dispatch-panel-${this.props.activeDispatchId}`);
    }
  }

  render() {
    const {
      dispatches = [],
      activeDispatchId,
      activeOrderId,
      hoveredOrderId,
      onDispatchClick,
      onOrderClick,
      onOrderHover,
      onOrderHoverOut,
    } = this.props;

    return (
      <div>
        {dispatches.map((dispatch, i) => (
          <RoutePanel
            index={i}
            key={dispatch.id}
            onClick={() => onDispatchClick(dispatch.id)}
            activeOrderId={activeOrderId}
            selected={activeDispatchId === dispatch.id}
            dispatch={dispatch}
            hoveredOrderId={hoveredOrderId}
            onOrderClick={onOrderClick}
            onOrderHover={onOrderHover}
            onOrderHoverOut={onOrderHoverOut}
          />
        ))}
      </div>
    );
  }
}

RoutePanels.propTypes = {
  date: PropTypes.object,
  dispatches: PropTypes.array,
  regionId: PropTypes.number,
  ...layoutEventSystem,
};

/* eslint-disable import/no-default-export */
export default connect(
  createStructuredSelector({
    dispatches: selectFilteredDispatches,
  }),
)(RoutePanels);

import angular from 'angular';

const app = angular.module('app');

app.directive('drop', [
  '$parse',
  function directive($parse) {
    return {
      restrict: 'A',
      scope: {
        drop: '=',
      },
      link: function link(scope, element, attrs) {
        function handle(name, event) {
          const raw = attrs[name];
          if (raw) {
            const fn = $parse(raw);
            scope.$apply(() => {
              fn(scope.$parent, { $event: event });
            });
          }
        }

        function dragover(event) {
          event.preventDefault();
          event.stopPropagation();
        }

        function dragenter(event) {
          event.preventDefault();
          event.stopPropagation();
          handle('dragenter', event);
        }

        function dragleave(event) {
          event.preventDefault();
          event.stopPropagation();
          handle('dragleave', event);
        }

        function dropped(event) {
          event.preventDefault();
          event.stopPropagation();
          handle('dropped', event);
        }

        function configure(enabled) {
          const method = enabled ? 'bind' : 'unbind';
          element[method]('dragenter', dragenter);
          element[method]('dragleave', dragleave);
          element[method]('dragover', dragover);
          element[method]('drop', dropped);
        }

        scope.$watch('drop', configure);
      },
    };
  },
]);

import { AddressForm } from '@admin/components/address/address_form';
import { SelectFormControl } from '@admin/components/fields';
import { Selector as RegionsSelector } from '@admin/components/regions/selector';
import { TZS } from '@admin/constants/time_zone';
import { WarehouseInput } from '@admin/schema';
import { Box } from '@clutter/clean';

import * as React from 'react';

const DAYS = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'] as const;
type Day = typeof DAYS[number];

const AccessHoursInput: React.FC<{
  dayToUpdate: Day;
  editAccess: boolean;
  warehouse: WarehouseInput;
  onChange(warehouse: WarehouseInput): void;
}> = ({ dayToUpdate, editAccess, warehouse, onChange }) => {
  function updateCustomerAccessHours(newTime: string, typesToUpdate: Array<'open' | 'close'>) {
    const updatedHours = warehouse.customerAccessHours.map((oldAccessHours) => {
      if (oldAccessHours.day === dayToUpdate) {
        return {
          day: dayToUpdate,
          open: typesToUpdate.includes('open') ? newTime : oldAccessHours.open,
          close: typesToUpdate.includes('close') ? newTime : oldAccessHours.close,
        };
      } else {
        return oldAccessHours;
      }
    });

    onChange({
      ...warehouse,
      customerAccessHours: updatedHours,
    });
  }

  const relevantAccessHours = warehouse.customerAccessHours?.find((hours) => hours.day === dayToUpdate);
  return (
    <Box display="flex" flex-direction="row" justify-content="space-between">
      <Box padding={'16px'} width={'80px'}>
        <p className="text-capitalize">{dayToUpdate}</p>
      </Box>
      <Box padding={'8px 0px 0px 16px'} width={'150px'}>
        <input
          id={`warehouse__customer_access_instructions${dayToUpdate}__open`}
          type="time"
          className="form-control"
          value={relevantAccessHours?.open}
          onChange={(event) => {
            updateCustomerAccessHours(event.target.value, ['open']);
          }}
          disabled={!editAccess}
        />
      </Box>
      <Box padding={'16px 0px 0px 16px'}>
        <p>to</p>
      </Box>
      <Box padding={'8px 0px 0px 16px'} width={'150px'}>
        <input
          id={`warehouse__customer_access_instructions${dayToUpdate}__close`}
          type="time"
          className="form-control"
          value={relevantAccessHours?.close}
          onChange={(event) => {
            updateCustomerAccessHours(event.target.value, ['close']);
          }}
          disabled={!editAccess}
        />
      </Box>
      {editAccess && (
        <Box padding={'10px 0px 0px 16px'} height="50px">
          <button
            type="button"
            className="btn btn-danger"
            onClick={() => {
              updateCustomerAccessHours('', ['open', 'close']);
            }}
          >
            Clear
          </button>
        </Box>
      )}
    </Box>
  );
};

export const WarehouseForm: React.FC<{
  warehouse: WarehouseInput;
  editAccess: boolean;
  onChange(warehouse: WarehouseInput): void;
}> = ({ onChange, editAccess, warehouse }) => {
  const { name, abbreviation, tz, height, thirdPartyLogistics, active, customerAccessInstructions } = warehouse;

  return (
    <div className="row">
      <div className="col-sm-6">
        <div className="form-group">
          <label className="control-label" htmlFor="warehouse__name">
            <strong>Name</strong>
          </label>
          <input
            id="warehouse__name"
            type="text"
            className="form-control"
            value={name}
            onChange={(event) => {
              onChange({ ...warehouse, name: event.target.value });
            }}
            disabled={!editAccess}
          />
        </div>
        <div className="form-group">
          <label className="control-label" htmlFor="warehouse__abbr">
            <strong>Abbreviation</strong>
          </label>
          <input
            id="warehouse__abbr"
            type="text"
            className="form-control"
            value={abbreviation}
            onChange={(event) => {
              onChange({ ...warehouse, abbreviation: event.target.value });
            }}
            disabled={!editAccess}
          />
        </div>
        <div className="form-group">
          <label className="control-label" htmlFor="warehouse__tz">
            <strong>Time Zone</strong>
          </label>
          <SelectFormControl
            id="warehouse__tz"
            options={TZS}
            value={tz ?? undefined}
            onChange={(option) => {
              onChange({ ...warehouse, tz: option || '' });
            }}
            prompt=" - Select Time Zone -"
            disabled={!editAccess}
          />
        </div>
        <div className="form-group">
          <label className="control-label" htmlFor="warehouse__height">
            <strong>Height</strong>
          </label>
          <input
            id="warehouse__height"
            type="number"
            className="form-control"
            value={height ?? undefined}
            onChange={(event) => {
              onChange({ ...warehouse, height: parseInt(event.target.value, 10) });
            }}
            disabled={!editAccess}
          />
        </div>
        <div className="form-group">
          <label className="control-label" htmlFor="customer__access__instructions">
            <strong>Customer Access Instructions</strong>
          </label>
          <input
            id="customer__access__instructions"
            type="text"
            className="form-control"
            value={customerAccessInstructions ?? ''}
            onChange={(event) => {
              onChange({
                ...warehouse,
                customerAccessInstructions: event.target.value !== '' ? event.target.value : null,
              });
            }}
            disabled={!editAccess}
          />
        </div>
        <div className="form-group">
          <label className="control-label" htmlFor="customer__access__hours">
            <strong>Customer Access Hours</strong>
          </label>
          {DAYS.map((day) => (
            <React.Fragment key={day}>
              <AccessHoursInput dayToUpdate={day} editAccess={editAccess} warehouse={warehouse} onChange={onChange} />
            </React.Fragment>
          ))}
        </div>
        <div className="form-group">
          <label className="control-label">
            <strong>Regions:</strong>
          </label>
          <RegionsSelector
            selectedIDs={warehouse.regionIDs}
            disabled={!editAccess}
            onChange={(regionIDs) => {
              onChange({ ...warehouse, regionIDs });
            }}
          />
        </div>
        <div className="form-group">
          <label className="control-label" htmlFor="warehouse__3pl">
            <strong>Third Party Logistics</strong>
          </label>
          <input
            id="warehouse__3pl"
            type="checkbox"
            className="form-control"
            checked={thirdPartyLogistics}
            onChange={(event) => {
              onChange({ ...warehouse, thirdPartyLogistics: event.target.checked });
            }}
            disabled={!editAccess}
          />
        </div>
        <div className="form-group">
          <label className="control-label" htmlFor="warehouse__active">
            <strong>Active</strong>
          </label>
          <input
            id="warehouse__active"
            type="checkbox"
            className="form-control"
            checked={active}
            onChange={(event) => {
              onChange({ ...warehouse, active: event.target.checked });
            }}
            disabled={!editAccess}
          />
        </div>
      </div>
      <div className="col-sm-6">
        <AddressForm
          address={warehouse.address}
          disabled={!editAccess}
          onChange={(address) => {
            onChange({ ...warehouse, address });
          }}
        />
      </div>
    </div>
  );
};

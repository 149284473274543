import template from '@admin/angular/templates/panel.html';

(() => {
  const app = angular.module('app');

  app.component('panel', {
    template,
    transclude: {
      title: '?panelTitle',
      content: '?panelContent',
      actions: '?panelActions',
    },
    controller: [
      function () {
        this.toggle = () => {
          this.collapsed = !this.collapsed;
        };
      },
    ],
  });
})();

import { getSnsDiscount } from '../../utils/sns';

(function () {
  const app = angular.module('app');

  app.controller('SubscribesNewController', [
    '$window',
    '$scope',
    '$state',
    '$stateParams',
    'Subscribe',
    'ErrorService',
    'MoneyMathService',
    'subscribeSetUp',
    'CustomDimensionConstants',
    'Account',
    function (
      $window,
      $scope,
      $state,
      $stateParams,
      Subscribe,
      ErrorService,
      MoneyMathService,
      subscribeSetUp,
      CustomDimensionConstants,
      Account,
    ) {
      const { user } = $window.clutter;
      $scope.account = Account.get({ id: $stateParams.account_id });
      $scope.subscribe = new Subscribe({ account_id: $stateParams.account_id });
      $scope.plans = subscribeSetUp.plans;
      $scope.subscribe.subscriptions = subscribeSetUp.subscriptions;
      $scope.account = subscribeSetUp.account;
      $scope.customDimensionConstants = CustomDimensionConstants;

      const discount = 1 - getSnsDiscount($scope.account.created_at);
      const getNumPlans = () =>
        $scope.plans.reduce(
          (count, plan) => (plan.quantity.desired > 0 && plan.type === 'StoragePlan' ? count + 1 : count),
          0,
        );
      const initialNumStoragePlans = getNumPlans();
      const customPlan = $scope.plans.find((plan) => plan.kind === 'custom');
      $scope.update_price_match = (kind) => {
        if (kind === 'switch_n_save') {
          customPlan.quantity.desired = Math.round(customPlan.quantity.custom * discount);
        } else {
          customPlan.quantity.desired = customPlan.quantity.custom;
        }
        $scope.account.price_match_kind = kind;
      };

      $scope.update_desired_quantity = (plan) => {
        if (plan.kind === 'custom' && !plan.quantity.custom) {
          $scope.account.price_match_kind = undefined;
          customPlan.custom_dimension.length = 0;
          customPlan.custom_dimension.width = 0;
        }
        if (plan.kind === 'custom' && $scope.account.price_match_kind === 'switch_n_save') {
          plan.quantity.desired = Math.round(Number(plan.quantity.custom) * discount);
        } else if (plan.kind === 'custom') {
          plan.quantity.desired = plan.quantity.custom;
        }
      };

      $scope.quantity_changed = function (plan) {
        return +plan.quantity.current !== +plan.quantity.desired;
      };

      $scope.plan_discount = function (plan) {
        const total = plan.currentPricing.amount * plan.quantity.desired;
        const planDiscount = plan.coupon ? MoneyMathService.applyDecimalDiscount(total, plan.coupon.percent) : 0;
        if (!$scope.quantity_changed(plan)) {
          return planDiscount;
        }
        return 0;
      };

      $scope.plan_total = function (plan) {
        const total = Math.floor(plan.currentPricing.amount * plan.quantity.desired);
        const planDiscount = plan.coupon ? MoneyMathService.applyDecimalDiscount(total, plan.coupon.percent) : 0;
        if (!$scope.quantity_changed(plan)) {
          return total - planDiscount;
        }
        return total;
      };

      $scope.discount = function () {
        return _.sum($scope.plans, (plan) => $scope.plan_discount(plan));
      };

      $scope.total = function () {
        return _.sum($scope.plans, (plan) => {
          if (plan.currentPricing) {
            return plan.currentPricing.amount * plan.quantity.desired;
          }
          return 0;
        });
      };

      $scope.increase = function (plan) {
        if (plan.kind === 'custom') {
          _.set(plan, 'quantity.custom', Math.max(plan.quantity.custom + 1, 0));
          $scope.update_desired_quantity(plan);
        } else {
          _.set(plan, 'quantity.desired', Math.max(plan.quantity.desired + 1, 0));
        }
      };

      $scope.decrease = function (plan) {
        _.set(plan, 'quantity.desired', Math.max(plan.quantity.desired - 1, 0));
        if (plan.kind === 'custom') {
          _.set(plan, 'quantity.custom', Math.max(plan.quantity.custom - 1, 0));
          $scope.update_desired_quantity(plan);
        }
      };

      const validate = () => {
        const numStoragePlans = getNumPlans();
        if (
          !_.includes($scope.account.tags, 'Omni') &&
          !_.includes(user.roles, 'admin') &&
          numStoragePlans > 1 &&
          initialNumStoragePlans < 2
        ) {
          ErrorService.handle(
            Error(
              'Only one plan subscription is allowed per account. Please remove additional ' +
                'subscription(s), then save the page. If this account is a special case (meaning it requires more ' +
                'than one plan subscription), please submit a ticket.',
            ),
          );
          return false;
        }

        const customPlanIncomplete =
          !customPlan.custom_dimension.length || !customPlan.custom_dimension.width || !customPlan.quantity.desired;
        const customPlanPristine =
          !customPlan.custom_dimension.length &&
          !customPlan.custom_dimension.width &&
          !customPlan.quantity.desired &&
          !$scope.account.price_match_kind;
        if (!customPlanPristine && customPlanIncomplete) {
          ErrorService.handle(
            Error(
              'Page cannot be saved because the Custom plan needs at least one field ' +
                'completed. Please complete all Dimension and Quantity fields to save the page.',
            ),
          );
          return false;
        }

        return true;
      };

      $scope.submit = function () {
        if (!validate()) return;

        $scope.subscribe.subscriptions = _.map(
          _.reject($scope.plans, (plan) => !plan.quantity.desired),
          (plan) => ({
            pricing_id: plan.currentPricing.id,
            quantity: Math.round(plan.quantity.desired),
            dimensions: plan.custom_dimension,
          }),
        );
        $scope.subscribe.price_match_kind = $scope.account.price_match_kind;

        $scope.subscribe.$save(
          () => {
            $state.go('viewAccount', { id: $stateParams.account_id });
          },
          (error) => {
            ErrorService.handle(error);
          },
        );
      };
    },
  ]);
})();

import React from 'react';

import { Spacer } from '@shared/components/helpers';
import { Section } from '../components/section';
import { SectionHeading } from '../components/section_heading';
import { Radios as FacilityRadios } from '../facilities/radios';

export const FacilitySelector: React.FC<{ facilityID?: string; setFacilityID: (facilityID?: string) => void }> = ({
  facilityID,
  setFacilityID,
}) => (
  <Section>
    <SectionHeading>Facility</SectionHeading>
    <FacilityRadios selectedID={facilityID} onSelect={setFacilityID} />
    <Spacer height="36px" />
  </Section>
);

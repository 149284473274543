import { capitalize } from 'lodash';
import { PaymentPlan__Offer__State as OfferState } from '@admin/schema';

export const displayableText = (text: string) => text.split('_').map(capitalize).join(' ');

export const formatState = (state: OfferState, expired: boolean) => {
  if (state === OfferState.Approved && expired) {
    return 'Expired';
  }
  return capitalize(state);
};

import React from 'react';
import PropTypes from 'prop-types';
import { maxBy, compact, get, find } from 'lodash';
import moment from 'moment';
import { layoutEventSystem } from '../propTypes';
import DashboardDirections from './DashboardDirections';
import DashboardOrdersMarkers from './DashboardOrdersMarkers';
import DashboardCheckinsMarkers from './DashboardCheckinsMarkers';
import { getDedupedValidOrdersForDispatch, isDispatchHoveredOrSelected, isDispatchSelected } from '../utils';
import { colorInterpolatorForIndex } from '../../../../helpers/colors';

const getLead = (dispatch) => {
  const assignments = get(dispatch, 'assignments');
  const leadAssignment = find(assignments, { role: 'lead' });
  return get(leadAssignment, 'user');
};

const getLeadCheckin = (dispatch) => {
  const leadId = get(getLead(dispatch), 'id');
  const userCheckins = dispatch.checkins.filter(({ resource }) => resource.type === 'User' && resource.id === leadId);
  return maxBy(userCheckins, (checkin) => moment(checkin.timestamp).valueOf());
};

const DashboardDispatchMarkers = ({ dispatch, directionsByDispatch = {}, index, gMaps, ...eventSystem }) => {
  const leadCheckin = getLeadCheckin(dispatch);
  const directions = isDispatchSelected(eventSystem, dispatch.id) ? directionsByDispatch[dispatch.id] || [] : [];
  const interpolator = colorInterpolatorForIndex(index);
  const fillColor = interpolator(0.7);
  const strokeColor = interpolator(1);
  const strokeOpacity = !dispatch.id || isDispatchHoveredOrSelected(eventSystem, dispatch.id) ? 1 : 0.3;
  const fillOpacity = isDispatchHoveredOrSelected(eventSystem, dispatch.id) ? 1 : 0.7;
  const checkinsToShow = compact([...dispatch.checkins.filter((c) => get(c, 'resource.type') !== 'User'), leadCheckin]);
  const orders = getDedupedValidOrdersForDispatch(dispatch);
  return [
    <DashboardDirections
      key={`${dispatch.id}:directions`}
      directions={directions}
      dispatch={dispatch}
      fillColor={fillColor}
      strokeColor={strokeColor}
      gMaps={gMaps}
      {...eventSystem}
    />,
    <DashboardOrdersMarkers
      dispatch={dispatch}
      key={`${dispatch.id}:orders`}
      fillColor={fillColor}
      strokeOpacity={strokeOpacity}
      fillOpacity={fillOpacity}
      strokeColor={strokeColor}
      orders={orders}
      {...eventSystem}
    />,
    <DashboardCheckinsMarkers
      key={`${dispatch.id}:checkins`}
      fillColor={fillColor}
      dispatch={dispatch}
      strokeOpacity={strokeOpacity}
      fillOpacity={fillOpacity}
      strokeColor={strokeColor}
      checkins={checkinsToShow}
      {...eventSystem}
    />,
  ];
};

DashboardDispatchMarkers.propTypes = {
  dispatch: PropTypes.object.isRequired,
  directionsByDispatch: PropTypes.object,
  index: PropTypes.number.isRequired,
  gMaps: PropTypes.object,
  ...layoutEventSystem,
};

export default DashboardDispatchMarkers;

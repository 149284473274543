import { Spinner } from '@admin/components/spinner';
import { logisticsDetailedLoadURL } from '@admin/config/routes';
import { Roles } from '@admin/components/helpers/roles';
import { UserRole } from '@admin/types';
import { useLogisticsLoadQuery } from '@admin/schema';

import { client } from '@admin/libraries/apollo';
import React from 'react';

import { LogisticsLoadForm } from './form/form';

export const LogisticsEditLoad: React.FC<{ id: string }> = ({ id }) => {
  const { data, loading } = useLogisticsLoadQuery({ client, variables: { id } });

  const onSubmit = async (newID: string) => {
    window.location.href = logisticsDetailedLoadURL({ id: newID });
  };

  if (loading || !data) {
    return <Spinner />;
  }

  const load = data.load;
  const inputLoad = {
    id: load.id,
    type: load.type,
    number: load.number,
    trailerNumber: load.trailerNumber,
    warehouseID: String(load.warehouse.id),
    appointmentDate: load.appointmentDate,
    requestedAppointmentDate: load.requestedAppointmentDate,
    requestedAppointmentWindow: load.requestedAppointmentWindow,
    freightChargeTerms: load.freightChargeTerms,
    handlingMethod: load.handlingMethod,
    shipToAddress: load.shipTo && {
      street: load.shipTo.street,
      aptsuite: load.shipTo.aptsuite,
      city: load.shipTo.city,
      state: load.shipTo.state,
      zip: load.shipTo.zip,
      country: load.shipTo.country,
      latitude: load.shipTo.latitude,
      longitude: load.shipTo.longitude,
      businessName: load.shipTo.businessName,
    },
    billToAddress: load.billTo && {
      street: load.billTo.street,
      aptsuite: load.billTo.aptsuite,
      city: load.billTo.city,
      state: load.billTo.state,
      zip: load.billTo.zip,
      country: load.billTo.country,
      latitude: load.billTo.latitude,
      longitude: load.billTo.longitude,
      businessName: load.billTo.businessName,
    },
    specialInstructions: load.specialInstructions,
    mode: load.mode,
    fulfillmentExpectations: load.fulfillmentExpectations.map((fe) => ({
      fulfillmentExpectationID: fe.id,
      accountID: fe.sku.account.id,
      skuID: fe.sku.id,
      quantity: fe.quantity,
      purchaseOrderID: fe.purchaseOrder?.id,
      ingestedCount: fe.ingestedCount,
    })),
    bolNumber: load.billOfLadingNumber,
    usePalletLabels: load.palletLabelsRequired,
    itemLabelsRequired: load.itemLabelsRequired,
    trailerSealRequired: load.trailerSealRequired,
    draft: load.state === 'draft',
    drop: load.drop,
    releaseNumber: load.releaseNumber,
    reference1: load.reference1,
    reference2: load.reference2,
    reference3: load.reference3,
    reference4: load.reference4,
    reference5: load.reference5,
    targetPalletHeight: load.targetPalletHeight,
    palletHeightPreference: load.palletHeightPreference,
    carrierDetails: {
      id: load.carrierDetails?.id,
      transportationArrangedBy: load.carrierDetails?.transportationArrangedBy,
      businessName: load.carrierDetails?.businessName,
      contactName: load.carrierDetails?.contactName,
      contactPhone: load.carrierDetails?.contactPhone,
      contactEmail: load.carrierDetails?.contactEmail,
      scac: load.carrierDetails?.scac,
    },
  };

  return (
    <Roles show={[UserRole.Admin, UserRole.EnterpriseManager, UserRole.Manager]}>
      <LogisticsLoadForm load={inputLoad} editMode state={load.state} onSave={onSubmit} />
    </Roles>
  );
};

import styled from '@emotion/styled';
import React, { useState } from 'react';
import { client } from '@admin/libraries/apollo';

import { Panel } from '@admin/components/helpers/panel';
import { Pagination } from '@admin/components/pagination';
import { Spinner } from '@admin/components/spinner';
import { usePaginatedConfigurationListQuery } from '@admin/schema';
import { Spacer } from '@shared/components/helpers';

import { Table } from './table';

const SpinnerWrapper = styled.div`
  display: flex;
`;

export const OpsAuditConfigurations: React.FC = () => {
  const [page, setPage] = useState<number | undefined>(undefined);

  const { data, loading } = usePaginatedConfigurationListQuery({ client, variables: { page } });

  const paginated = data && data.paginated;
  return (
    <div className="ops-audit-configurations">
      <Panel>
        <Panel.Header>
          <Panel.Title>Ops Audit Tool</Panel.Title>
        </Panel.Header>
        <Panel.Body>
          <Spacer height="8px" />
          {loading && (
            <>
              <SpinnerWrapper>
                <Spinner />
              </SpinnerWrapper>
              <Spacer height="8px" />
            </>
          )}
          <Table configurations={(paginated && paginated.results) || []} />
          {paginated && <Pagination pagination={paginated.pagination} onPage={setPage} />}
        </Panel.Body>
      </Panel>
    </div>
  );
};

(() => {
  // display duration in seconds in hour:minutes format
  angular.module('app').filter('shiftDuration', function () {
    return function ({ start_time: startTime, until_time: untilTime }, timezone) {
      const start = moment.tz(startTime, timezone);
      const until = moment.tz(untilTime, timezone);

      const stripMeridiem = start.format('a') === until.format('a') && start.hours() < until.hours();
      const meridiemToken = stripMeridiem ? '' : 'A';
      const segments = [];
      if (start.minutes() === 0) {
        segments.push(start.format(`h${meridiemToken}`));
      } else {
        segments.push(start.format(`h:mm${meridiemToken}`));
      }
      if (until.isValid()) {
        if (until.minutes() === 0) {
          segments.push(until.format('hA'));
        } else {
          segments.push(until.format('h:mmA'));
        }
      }
      return segments.join(' - ');
    };
  });
})();

(function () {
  angular.module('app').filter('contains', function () {
    return function (collection, value, fromIndex) {
      if (!collection || !value) {
        return '';
      }

      fromIndex = fromIndex || 0;
      return _.includes(collection, value, fromIndex);
    };
  });
})();

/** @jsx jsx */
import { Logistics__Sku__BulkUploadPreview, Logistics__Sku__BulkUploadPreview__Sku } from '@admin/schema';
import { Table } from '@shared/components/bootstrap';
import { css, jsx } from '@emotion/react';
import React from 'react';
import { Box, SkeletonLoader } from '@clutter/clean';
import { Spacer } from '@shared/components/helpers';

const HEADERS: Array<keyof Logistics__Sku__BulkUploadPreview__Sku> = [
  'value',
  'description',
  'nmfcNumber',
  'nmfcClass',
  'teachInStatus',
  'vbwOptOutReason',
  'gtinBarcode',
  'weightPerPallet',
  'itemWeight',
  'itemLength',
  'itemWidth',
  'itemHeight',
  'itemsPerPallet',
  'deletedAt',
  'accountID',
  'defaultPalletTypeID',
  'defaultPalletHeightID',
  'hasBarcode',
  'verified',
  'vbwEligible',
  'countingUnit',
];

const persistedCellStyle = css`
  background: #eee;
`;

const newCellStyle = css`
  background: green;
  color: white;
`;

const errorStyle = css`
  color: red;
`;

type SkuTableProps = { skus: Logistics__Sku__BulkUploadPreview__Sku[] };

const SkuTable: React.FC<SkuTableProps> = ({ skus }) => (
  <Table striped responsive>
    <thead>
      <tr>
        <th>id</th>

        {HEADERS.map((header) => (
          <th key={header} className="text-left">
            {header}
          </th>
        ))}
      </tr>
    </thead>

    <tbody>
      {skus.map((sku, i) => (
        <tr key={i}>
          <td css={sku.id ? persistedCellStyle : newCellStyle}>{sku.id || 'pending'}</td>

          {HEADERS.map((header, j) => (
            <td key={`${header}${i}${j}`} className="text-left">
              {sku[header]?.toString()}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  </Table>
);

export const SkuTableSkeletonLoader = () => (
  <Box.Flex width="100%" justifyContent="space-between" flexDirection="column">
    <SkeletonLoader height="16px" />
    <Spacer height="4px" />
    <SkeletonLoader height="20px" />
    <Spacer height="4px" />
    <SkeletonLoader height="20px" />
    <Spacer height="4px" />
    <SkeletonLoader height="20px" />
    <Spacer height="4px" />
    <SkeletonLoader height="20px" />
  </Box.Flex>
);

type SkuBulkUploadPreviewProps = {
  preview: Logistics__Sku__BulkUploadPreview;
};

export const SkuBulkUploadPreview: React.FC<SkuBulkUploadPreviewProps> = ({ preview }) => {
  switch (preview.__typename) {
    case 'Logistics__Sku__BulkUploadPreview__Error':
      return <p css={errorStyle}>{preview.message}</p>;
    case 'Logistics__Sku__BulkUploadPreview__Preview':
      return <SkuTable skus={preview.skus} />;
  }

  return null;
};

import axios from 'axios';

import { format, parse } from 'date-fns';

import { IVehiclesByType } from '../types/vehicle';

interface IVehiclesParams {
  warehouseId: string;
  date: string;
  prevDate: string;
  inboundDockItemsPerHour: number;
  filteredVehicleTypes?: any;
  depotIds?: string;
  filteredStatus?: string;
}

class Vehicles {
  private params: IVehiclesParams;

  constructor(params: IVehiclesParams) {
    this.params = params;
  }

  public async fetch(): Promise<IVehiclesByType | null> {
    let params = { date: this.params.date };

    if (this.params.filteredVehicleTypes && this.params.filteredVehicleTypes.length > 0) {
      params = Object.assign(params, { vehicle_types: this.params.filteredVehicleTypes });
    }

    if (this.params.depotIds && this.params.depotIds.length > 0) {
      params = Object.assign(params, { depot_ids: this.params.depotIds });
    }

    if (this.params.filteredStatus && this.params.filteredStatus.length > 0) {
      params = Object.assign(params, { status: this.params.filteredStatus });
    }

    try {
      const response = await axios.get(
        `/warehouses/${this.params.warehouseId}/vehicle_route_matcher/active_vehicles.json`,
        { params },
      );

      return this.parseResponse(response.data.vehicles);
    } catch {
      return null;
    }
  }

  private parseResponse = (res: any[]) => {
    const vehiclesByType: any = {};

    res.forEach((vehicle) => {
      if (!vehicle.serial_code.includes('RQT')) {
        let item = {
          serialCode: vehicle.serial_code,
          numSeats: vehicle.num_seats,
          vehicleId: vehicle.id,
          cuft: parseInt(vehicle.cuft, 10),
          dispatchIdRegions: [],
          depotName: undefined,
          totalCuft: 0,
          pickupsItemCount: 0,
          displayDetails: false,
          dispatchVehicleCount: 0,
          arrival: '',
          cuftPerItemCount: 0,
          estimatedIngestionHours: 0,
        };

        if (vehicle.dispatches.length > 0) {
          const arrivalDate = format(parse(vehicle.dispatches[0].arrival), 'YYYY-MM-DD');
          const pickupTotalCuft = this.calculateTotalCuft(vehicle.dispatches, arrivalDate);
          const pickupItemCount = this.calcualtePickupItemCount(vehicle.dispatches, arrivalDate);

          item = {
            ...item,
            dispatchIdRegions: vehicle.dispatches
              .filter((d: any) => arrivalDate === this.params.prevDate || !d.pickups_completed)
              .map((d: any) => [d.id, d.region.abbreviation]), // TODO: refactor and put this into an object with specific type
            depotName: vehicle.dispatches[0].depot.name,
            totalCuft: pickupTotalCuft,
            pickupsItemCount: pickupItemCount,
            dispatchVehicleCount: new Set(
              vehicle.dispatches
                .filter((d: any) => arrivalDate === this.params.prevDate || !d.pickups_completed)
                .map((d: any) => d.vehicles.map((v: any) => v.serial_code))
                .flat(),
            ).size,
            arrival: arrivalDate,
            displayDetails:
              arrivalDate === this.params.prevDate ||
              vehicle.dispatches.some((d: any) => d.pickups_present && !d.pickups_completed),
            cuftPerItemCount: pickupItemCount > 0 ? pickupTotalCuft / pickupItemCount : 0,
            estimatedIngestionHours: this.estimateIngestionHours(pickupItemCount, this.params.inboundDockItemsPerHour),
          };
        }

        if (vehiclesByType[vehicle.vehicle_type_id]) {
          vehiclesByType[vehicle.vehicle_type_id].push(item);
        } else {
          vehiclesByType[vehicle.vehicle_type_id] = [item];
        }
      }
    });

    return vehiclesByType;
  };

  private calculateTotalCuft = (dispatches: any[], arrivalDate: string) =>
    dispatches
      .filter((d: any) => arrivalDate === this.params.prevDate || !d.pickups_completed)
      .reduce((acc: number, d: any) => acc + (Number(d.pickups_total_cuft) || 0), 0);

  private calcualtePickupItemCount = (dispatches: any[], arrivalDate: string) =>
    dispatches
      .filter((d: any) => arrivalDate === this.params.prevDate || !d.pickups_completed)
      .reduce((acc: number, d: any) => acc + (d.pickups_item_count || 0), 0);

  private estimateIngestionHours = (pickupItemCount: number, dockItemsPerHour: number) =>
    dockItemsPerHour > 0 ? pickupItemCount / dockItemsPerHour : 0;
}

export { Vehicles };

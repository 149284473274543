import React from 'react';
import { OrderSubtypeEnum } from '@admin/schema';
import { FormikSelectFormGroup } from '@shared/components/fields/formik/formik_select_form_group';

export const OPTIONS = [
  {
    subtype: OrderSubtypeEnum.None,
    displayName: 'Packing & Unpacking',
  },
  {
    subtype: OrderSubtypeEnum.Packing,
    displayName: 'Packing Only',
  },
  {
    subtype: OrderSubtypeEnum.Unpacking,
    displayName: 'Unpacking Only',
  },
  {
    subtype: OrderSubtypeEnum.Travel,
    displayName: 'Travel Only',
  },
];

export const FormikMovingSubtypeFormGroup: React.FC<{
  id: string;
  name: string;
  label: string;
}> = (props) => (
  <FormikSelectFormGroup {...props}>
    {OPTIONS.map((option) => (
      <option value={option.subtype} key={option.subtype}>
        {option.displayName}
      </option>
    ))}
  </FormikSelectFormGroup>
);

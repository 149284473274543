import React, { useState } from 'react';
import { Panel } from '@admin/components/helpers/panel';
import { Roles } from '@admin/components//helpers/roles';
import { Preview as AddressPreview } from '@admin/components/address/preview';
import { Preview as AddressDetailPreview } from '@admin/components/address/detail/preview';
import { AddressDetails as FacilityAddressDetails } from '@admin/components/facility/address_details';

import { Account, DetailedAddressFragment } from '@admin/schema';
import { UserRole } from '@admin/types';

import { AnchorButton as Link } from '@shared/components/bootstrap';

const flattenAddress = (address: DetailedAddressFragment) => ({
  // Note: These fields are pulled from the manually defined Address type
  id: address.id,
  street: address.street,
  aptsuite: address.aptsuite,
  business_name: address.businessName,
  city: address.city,
  state: address.state,
  country: address.country,
  zip: address.zip,
  latitude: address.latitude,
  longitude: address.longitude,
  building_type: address.details?.buildingType,
  building_subtype: address.details?.buildingSubtype,
  floor: address.details?.floor,
  stories: address.details?.stories,
  front_door_steps: address.details?.frontDoorSteps,
  square_feet: address.details?.squareFeet,
  stairs: address.details?.stairs,
  elevator: address.details?.elevator,
  service_elevator: address.details?.serviceElevator,
  paperwork: address.details?.paperwork,
  steep: address.details?.steep,
  gated: address.details?.gated,
  service_entrance: address.details?.serviceEntrance,
  loading_dock: address.details?.loadingDock,
  code: address.details?.code,
  parking_street: address.details?.parkingStreet,
  parking_driveway: address.details?.parkingDriveway,
  parking_lot: address.details?.parkingLot,
  parking_alley: address.details?.parkingAlley,
  parking_instructions: address.details?.parkingInstructions,
  parking_permit: address.details?.parkingPermit,
  parking_in_front: address.details?.parkingInFront,
  parking_on_specific_street: address.details?.parkingOnSpecificStreet,
  code_value: address.details?.codeValue,
  building_opening_hour: address.details?.buildingOpeningHour,
  building_closing_hour: address.details?.buildingClosingHour,
  building_restrictions_answer: address.details?.buildingRestrictionsAnswer,
  environment: address.details?.environment,
  max_truck_height_unknown: address.details?.maxTruckHeightUnknown,
  max_truck_height: address.details?.maxTruckHeight,
  additional_notes: address.details?.additionalNotes,
  long_carry: address.details?.longCarry,
  safe_and_sanitary: address.safeAndSanitary,
});

export const AddressPanel: React.FC<{
  address: DetailedAddressFragment;
  account: Pick<Account, 'id'>;
  title: string;
}> = ({ address, account, title }) => {
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const flattened = flattenAddress(address);

  return (
    <Panel>
      <Panel.Header>
        <Panel.Collapse collapsed={collapsed} onChange={setCollapsed} />

        <Panel.Title>{title}</Panel.Title>
      </Panel.Header>

      {!collapsed && (
        <>
          <Panel.Body>
            <AddressPreview address={flattened} />
            <hr className="compact-hr" />
            <AddressDetailPreview address={flattened} />
            {address.addressableId && address.addressableType && (
              <FacilityAddressDetails facilityID={address.addressableId} facilityType={address.addressableType} />
            )}
          </Panel.Body>

          {address.addressableType !== 'Warehouse' && (
            <Roles hide={[UserRole.L1Agent]}>
              <Panel.Footer align="right">
                <Link kind="primary" href={`/accounts/${account.id}/addresses/${address.id}/edit`}>
                  Edit
                </Link>
              </Panel.Footer>
            </Roles>
          )}
        </>
      )}
    </Panel>
  );
};

import * as React from 'react';

import { newAccountOrderShipURL } from '@admin/config/routes';

export const Ship: React.FC<{
  accountID: string;
  orderID: string;
}> = ({ accountID, orderID }) => (
  <a href={newAccountOrderShipURL({ accountID, orderID })} className="btn btn-primary">
    Ship
  </a>
);

(function () {
  const app = angular.module('app');

  app.directive('modal', function () {
    return {
      restrict: 'A',
      scope: {
        visible: '=?',
        animating: '=?',
      },

      link: function (scope, element) {
        const $element = $(element);

        scope.$watch(
          function () {
            return scope.visible;
          },
          function (value) {
            $element.modal(value ? 'show' : 'hide');
          },
        );

        $element.on('show.bs.modal', function () {
          scope.animating = true;
        });

        $element.on('hide.bs.modal', function () {
          scope.animating = true;
        });

        $element.on('shown.bs.modal', function () {
          scope.$apply(function () {
            scope.visible = true;
            scope.animating = false;
          });
        });

        $element.on('hidden.bs.modal', function () {
          scope.$apply(function () {
            scope.visible = false;
            scope.animating = false;
          });
        });
      },
    };
  });
})();

import * as React from 'react';

import styled from '@emotion/styled';

import { COLORS, Text, FontWeight, Button } from '@clutter/clean';

import bedroom from '@admin/images/illustrations/rooms/bedroom.svg';
import livingRoom from '@admin/images/illustrations/rooms/living_room.svg';
import closet from '@admin/images/illustrations/rooms/closet.svg';
import kitchen from '@admin/images/illustrations/rooms/kitchen.svg';
import diningRoom from '@admin/images/illustrations/rooms/dining_room.svg';
import office from '@admin/images/illustrations/rooms/office.svg';
import garage from '@admin/images/illustrations/rooms/garage.svg';
import patio from '@admin/images/illustrations/rooms/patio.svg';
import shed from '@admin/images/illustrations/rooms/shed.svg';
import basement from '@admin/images/illustrations/rooms/basement.svg';
import plus from '@admin/images/icons/plus.svg';
import minus from '@admin/images/icons/minus.svg';

type Room = {
  field: string;
  label: string;
  countable?: boolean;
  icon: string;
};

const ROOM_INFO: readonly Room[] = [
  {
    field: 'bedrooms',
    label: 'Bedrooms',
    countable: true,
    icon: bedroom,
  },
  {
    field: 'living_rooms',
    label: 'Living rooms',
    countable: true,
    icon: livingRoom,
  },
  {
    field: 'closets',
    label: 'Closets',
    countable: true,
    icon: closet,
  },
  {
    field: 'kitchen',
    label: 'Kitchen',
    icon: kitchen,
  },
  {
    field: 'dining_room',
    label: 'Dining room',
    icon: diningRoom,
  },
  {
    field: 'office',
    label: 'Office',
    icon: office,
  },
  {
    field: 'garage',
    label: 'Garage',
    icon: garage,
  },
  {
    field: 'patio',
    label: 'Outdoor furniture',
    icon: patio,
  },
  {
    field: 'shed',
    label: 'Shed',
    icon: shed,
  },
  {
    field: 'basement',
    label: 'Basement',
    icon: basement,
  },
] as const;

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: -8px;
`;

const Card = styled.div`
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin: 8px;
  width: 150px;
`;

const Display = styled.div`
  padding: 16px;
  border-bottom: 1.5px solid ${COLORS.grayBorder};
  text-align: center;
  height: 144px;
  img {
    margin-top: 8px;
  }
  ${Text.Body} {
    white-space: nowrap;
    margin: 0 -16px;
  }
`;

const Action = styled.div`
  padding: 16px;
  height: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const CountableInput = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const Icon = styled.img`
  &:hover {
    cursor: pointer;
  }
`;

type RoomSelectionProps = Room & {
  onChange: (field: string, value: number | boolean) => void;
  value: number | boolean;
  editable?: boolean;
};

const RoomSelection: React.FC<RoomSelectionProps> = ({ field, label, countable, icon, onChange, value, editable }) => (
  <Card key={field}>
    <Display>
      <Text.Body weight={FontWeight.Medium}>{label}</Text.Body>
      <img src={icon} />
    </Display>
    <Action>
      {editable ? (
        countable ? (
          <CountableInput>
            <Icon
              src={minus}
              onClick={() => {
                if (value) {
                  onChange(field, Number(value) - 1);
                }
              }}
            />
            <Text.Title size="small">{value}</Text.Title>
            <Icon src={plus} onClick={() => onChange(field, Number(value) + 1)} />
          </CountableInput>
        ) : (
          <Button kind={value ? 'primary' : 'secondary'} fullWidth onClick={() => onChange(field, !value)}>
            {value ? 'Remove' : 'Add'}
          </Button>
        )
      ) : (
        <Text.Title size="small">{Number(value)}</Text.Title>
      )}
    </Action>
  </Card>
);

interface IRoomsProps {
  movingRooms: { [field: string]: boolean | number };
  editable?: boolean;
  onChange?: (field: string, value: boolean | number) => void;
}

export const Rooms: React.FC<IRoomsProps> = ({ movingRooms, editable, onChange }) => (
  <CardContainer>
    {ROOM_INFO.map((room) => {
      const { countable, field } = room;
      const defaultValue = countable ? 0 : false;
      const value = movingRooms[field] || defaultValue;
      return (
        <RoomSelection
          {...room}
          editable={editable}
          key={field}
          onChange={(updatedField, updatedValue) => onChange && onChange(updatedField, updatedValue)}
          value={value}
        />
      );
    })}
  </CardContainer>
);

import React from 'react';

import { Box } from '@clutter/clean';
import { useParams } from 'react-router-dom';

import { PageHeader } from '@admin/components/helpers/page_header';
import { client } from '@admin/libraries/apollo';
import { useTaskOrderEditQuery } from '@admin/schema';
import { OrderMap } from '@admin/components/orders/map';
import { Panel } from '@admin/components/helpers/panel';
import { Col } from '@shared/components/bootstrap';

import { EditPanel } from './components/edit_panel';
import { TasksPanel } from './components/tasks_panel';
import { useTaskOrderContext } from './components/task_order_context';
import { Breadcrumbs } from './components/breadcrumbs';

export const TaskOrderEdit = () => {
  const { orderID } = useParams();
  const { accountID, data: { account } = {} } = useTaskOrderContext();
  const { data: { order } = {}, loading, error } = useTaskOrderEditQuery({ client, variables: { orderID } });

  if (!account || loading) {
    return null;
  }

  if (!order || error) {
    throw new Error('Cannot load task order');
  }

  return (
    <>
      <PageHeader>
        <Breadcrumbs order={order} />
      </PageHeader>
      <Panel>
        <Panel.Header>
          <Panel.Title>Edit Tasks</Panel.Title>
        </Panel.Header>
        <Panel.Body>
          <Box.Flex margin="0 -8px">
            <Col xs={12} lg={6}>
              <TasksPanel accountID={accountID} orderID={order.id} initialTasks={order.tasks ?? []} />
            </Col>
            <Col xs={12} lg={6}>
              <OrderMap orderID={order.id} />
            </Col>
          </Box.Flex>
        </Panel.Body>
      </Panel>
      <EditPanel order={order} />
    </>
  );
};

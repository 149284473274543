import { capitalize } from 'lodash';
import * as React from 'react';

import { Panel } from '@admin/components/helpers/panel';
import { Spinner } from '@admin/components/spinner';
import { AnchorButton as Link, Table, Text } from '@shared/components/bootstrap';

import { useAccountAddressesQuery, useAddressPolicyQuery } from '@admin/schema';
import { client } from '@admin/libraries/apollo';

export const Addresses: React.FC<{ accountID: string }> = ({ accountID }) => {
  const { data, loading } = useAccountAddressesQuery({ client, variables: { accountID } });
  const { data: policy } = useAddressPolicyQuery({ client });
  const addresses = data?.account?.addresses;

  return (
    <Panel>
      <Panel.Header>
        <Panel.Title>Addresses</Panel.Title>
      </Panel.Header>
      <Panel.Body>
        {loading && <Spinner />}
        <Table responsive striped>
          <tbody>
            {addresses?.map(({ id, aptsuite, street, neighborhood, city, state, zip, businessName, kinds }) => (
              <tr key={id}>
                <td className="text-left">
                  <div>
                    {street} {aptsuite}
                  </div>
                  {businessName && <div>{businessName}</div>}
                  <div>
                    {city}
                    {neighborhood && <> ({neighborhood})</>}, {state}, {zip}
                  </div>
                  {kinds.map((kind, index) => (
                    <span key={index} className="label label-default">
                      {capitalize(kind)}
                    </span>
                  ))}
                </td>
                <td className="text-right">
                  <Link kind="primary" href={`/accounts/${accountID}/addresses/${id}`}>
                    Details
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Panel.Body>
      <Panel.Footer>
        {policy?.permissions?.create && (
          <Text alignment="right" tag="div">
            <Link kind="primary" href={`/accounts/${accountID}/addresses/new`}>
              Add
            </Link>
          </Text>
        )}
      </Panel.Footer>
    </Panel>
  );
};

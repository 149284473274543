import * as React from 'react';
import { Formatter as DimensionsFormatter } from './formatter';

export const Labels: React.FC<{
  resource: {
    length?: number;
    width?: number;
    height?: number;
    dimensions_overwritten?: number;
  };
}> = ({ resource }) => {
  const { dimensions_overwritten } = resource;
  return (
    <>
      <span className="label label-info">
        <DimensionsFormatter resource={resource} fallback="No Dimensions" />
      </span>
      {typeof dimensions_overwritten === 'boolean' && (
        <>
          <br />
          <span className={'label ' + (dimensions_overwritten ? 'label-dark' : 'label-default')}>
            {dimensions_overwritten ? 'Dim Overwritten' : 'Default Dim'}
          </span>
        </>
      )}
    </>
  );
};

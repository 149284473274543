import React from 'react';
import { Breadcrumb } from '@shared/components/bootstrap';
import { accountsURL, accountURL, orderURL } from '@admin/config/routes';
import { Order } from '@admin/schema';
import { useTaskOrderContext } from '@admin/components/orders/task_orders/components/task_order_context';

export const Breadcrumbs: React.FC<{
  order?: Pick<Order, 'number' | 'id'>;
}> = ({ order }) => {
  const { accountID, data } = useTaskOrderContext();

  if (!data) return null;

  return (
    <Breadcrumb>
      <Breadcrumb.Item>
        <a href={accountsURL()} className="text-primary">
          Accounts
        </a>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        <a href={accountURL({ id: accountID })} className="text-primary">
          {data.account.customer.name}
        </a>
      </Breadcrumb.Item>
      {order ? (
        <>
          <Breadcrumb.Item>
            <a href={orderURL({ id: order.id, account: { id: accountID } })} className="text-primary">
              Task Order #{order.number}
            </a>
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Edit</Breadcrumb.Item>
        </>
      ) : (
        <Breadcrumb.Item active>New Task Order</Breadcrumb.Item>
      )}
    </Breadcrumb>
  );
};

import * as React from 'react';

import { PricingGroupPricingEntryFragment } from '@admin/schema';

import { StorageSelect } from './storage_select';
import { StorageTotal } from './storage_total';

export const StorageFormGroup: React.FC<{
  storage?: PricingGroupPricingEntryFragment;
  quantity?: number;
  rateGroupId?: string;
  onSelect(storage: PricingGroupPricingEntryFragment): void;
}> = ({ storage, quantity, rateGroupId, onSelect }) => (
  <div className="form-group">
    <label className="col-sm-2 control-label">Storage:</label>
    <div className="col-sm-5">
      <StorageSelect storage={storage} rateGroupId={rateGroupId} onSelect={onSelect} />
    </div>
    <div className="col-sm-5 form-total">
      <StorageTotal storage={storage} quantity={quantity} />
    </div>
  </div>
);

import * as React from 'react';

import { client } from '@admin/libraries/apollo';
import { useSettingsQuery } from '@admin/schema';

import { Breadcrumbs } from './breadcrumbs';
import { AccountSettingsForm } from './settings/form';

export const Settings: React.FC<{
  accountID: number;
  onSave(): void;
}> = ({ accountID, onSave }) => {
  const { data } = useSettingsQuery({
    client,
    fetchPolicy: 'network-only',
    variables: { accountID: String(accountID) },
  });

  if (!data?.account) {
    return null;
  }

  return (
    <>
      <div className="page-header text-overflow">
        <Breadcrumbs account={data.account} active="Preferences" />
      </div>
      <AccountSettingsForm accountID={data.account.id} savedSettings={data.account.settings} onSave={onSave} />
    </>
  );
};

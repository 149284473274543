import styled from '@emotion/styled';
import { DateTime } from 'luxon';
import React, { useState } from 'react';

import { Panel } from '@admin/components/helpers/panel';
import { Timestamp } from '@admin/components/timestamp';
import { Text } from '@shared/components/bootstrap';
import { ILightboxCarouselInput, LightboxWithCarousel } from '@shared/components/helpers/lightbox';

import { DetailedImageFragment } from '@admin/schema';

const ImageElement = styled.img`
  margin: 10px 0 10px 0;
`;

const Scrollable = styled.div`
  max-height: 80vh;
  overflow: hidden;
  overflow-y: scroll;
`;

export const ImagesPanel: React.FC<{
  images: DetailedImageFragment[];
}> = ({ images }) => {
  const [selection, setSelection] = useState<number | undefined>(undefined);
  const imageSizeArray: ILightboxCarouselInput[] = images.map((image) => ({ src: image.source }));

  return (
    <Panel>
      <Panel.Header>
        <Panel.Title>Images</Panel.Title>
      </Panel.Header>
      <Panel.Body>
        <Scrollable>
          {images
            .sort(
              (a: DetailedImageFragment, b: DetailedImageFragment) =>
                DateTime.fromISO(a.createdAt).diff(DateTime.fromISO(b.createdAt)).milliseconds,
            )
            .map((image, index) => (
              <div key={image.id} className="col-lg-4">
                <Text tag="p" alignment="center">
                  {image.name ? `${image.name} - ` : null}Taken at:{' '}
                  <Timestamp value={image.createdAt} format="medium" />
                  {image.user?.name ? ` by: ${image.user.name}` : null}
                </Text>
                <figure>
                  <a
                    href="#"
                    onClick={(event) => {
                      event.preventDefault();
                      event.stopPropagation();
                      setSelection(index);
                    }}
                  >
                    <ImageElement className="brick-image" src={image.size} />
                  </a>
                </figure>
              </div>
            ))}
          {selection !== undefined && (
            <LightboxWithCarousel
              imageInputs={imageSizeArray}
              defaultActiveIndex={selection}
              onClose={() => setSelection(undefined)}
            />
          )}
        </Scrollable>
      </Panel.Body>
    </Panel>
  );
};

(function () {
  const app = angular.module('app');

  app.component('categorySearchInput', {
    templateUrl: 'partials/categories/search_input.html',
    bindings: {
      item: '=',
      onSubmitCallback: '&',
      onSelectCallback: '&',
    },
    controller: [
      'ItemCategoryService',
      function (ItemCategoryService) {
        const self = this;

        function queryMatchesCategoryName(displayName, query) {
          displayName = displayName || '';
          query = query || '';

          const terms = query.toLowerCase().split(' ');
          return terms.every(function (term) {
            return displayName.toLowerCase().includes(term);
          });
        }

        function appendClickToAddCategory(options) {
          const customOption = _.find(options, function (option) {
            return option.category.custom;
          });
          options.push({
            name: 'Click here to add a new item',
            category: customOption.category,
            virtualCategory: true,
          });
        }

        function getCustomOption(options) {
          return _.find(options, function (option) {
            return option.custom;
          });
        }

        function clickToAddOption(options) {
          return _.find(options, function (option) {
            return option.virtualCategory;
          });
        }

        self.$onInit = function () {
          ItemCategoryService.options.$promise.then(function (options) {
            const localOptions = angular.copy(
              _.filter(
                options,
                (option) =>
                  (option.category.move_and_pack_visible && !option.category.deleted_at) ||
                  (self.item.category && option.category.id === self.item.category.id),
              ),
            );
            appendClickToAddCategory(localOptions);
            self.options = localOptions;

            self.selectedCategory = _.find(localOptions, function (option) {
              return (
                option.category &&
                self.item.category &&
                option.category.id === self.item.category.id &&
                (!self.item.sizing_selection || option.sizing_selection === self.item.sizing_selection)
              );
            });

            if (self.item.custom_category_name) {
              self.selectedCategory.name = self.item.custom_category_name;
              self.customName = true;
            }
          });
        };

        self.updateQuery = function () {
          const clickToAdd = clickToAddOption(self.options);
          clickToAdd.name = 'click here to add new category: ' + self.selectedCategory;
          self.customCategoryName = self.selectedCategory;
        };

        self.handleSubmit = function (e) {
          if (e.keyCode === 13) {
            self.onSubmitCallback({ event: e });
          }
        };

        self.onSelect = function (option, model, label) {
          self.item.category = option.category;
          self.item.sizing_selection = option.sizing_selection;
          self.item.custom_category_name = null;

          if (option) {
            const customOption = getCustomOption(self.options);

            if (option.virtualCategory) {
              // adding new custom category
              customOption.name = self.customCategoryName;
              customOption.customName = true;
              self.item.custom_category_name = self.customCategoryName;
              self.selectedCategory = customOption;
            } else if (option.customName) {
              // adding existing custom category
              self.item.custom_category_name = customOption.name;
            }
          } else if (option && option.customName) {
            self.item.custom_category_name = self.customCategoryName;
          }

          self.onSelectCallback({ option: option, model: model, label: label });
        };

        self.displaySynonyms = function (synonyms, categoryName, query) {
          if (queryMatchesCategoryName(categoryName, query)) {
            return;
          }

          return synonyms.some(function (synonym) {
            return synonym.toLowerCase().indexOf(query.toLowerCase()) !== -1;
          });
        };
      },
    ],
  });
})();

import React from 'react';

import { User__State } from '@admin/schema';

import { Label } from '@admin/components/helpers/label';

import { USER_STATE_TO_NAME, USER_STATE_TO_KIND } from '@admin/constants/user_state';

export const State: React.FC<{ state: User__State }> = ({ state }) => (
  <Label kind={USER_STATE_TO_KIND[state]}>{USER_STATE_TO_NAME[state]}</Label>
);

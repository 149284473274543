import React, { useState } from 'react';

import { client } from '@admin/libraries/apollo';
import { AttendancePolicyViolation__Kind, useAttendancePolicyViolationsQuery } from '@admin/schema';

import { Panel } from '@admin/components/helpers/panel';
import { Table, AnchorButton as Link } from '@shared/components/bootstrap';
import { Spinner } from '@admin/components/spinner';
import { DateTime } from 'luxon';
import { Box } from '@clutter/clean';
import { CreateEndedEarlyViolationModal } from './create_ended_early_violation_modal';
import { ReviewModal } from './review_modal';

export const AttendancePolicyViolationsList: React.FC<{
  from?: string;
  teamID?: string;
  teamReviewMode?: boolean;
  till?: string;
  timeClockPlusUsersOnly?: boolean;
  userName?: string;
}> = ({ from, teamID, teamReviewMode, till, timeClockPlusUsersOnly, userName }) => {
  const [showCreateEndedEarlyViolationModal, setShowCreateEndedEarlyViolationModal] = useState<boolean>(false);
  const [violationUnderReview, setViolationUnderReview] = useState<
    | {
        id: string;
        confirmedNoCall: boolean;
        waived: boolean;
        reviewKind: 'toggleConfirmedNoCall' | 'toggleWaived';
      }
    | undefined
  >(undefined);

  const VIOLATION_KIND_TO_LABEL: Record<
    AttendancePolicyViolation__Kind,
    'No-Show' | 'Shift Started Late' | 'Shift Ended Early'
  > = {
    [AttendancePolicyViolation__Kind.NoShow]: 'No-Show',
    [AttendancePolicyViolation__Kind.StartedLate]: 'Shift Started Late',
    [AttendancePolicyViolation__Kind.EndedEarly]: 'Shift Ended Early',
  };

  const { data, loading, refetch } = useAttendancePolicyViolationsQuery({
    variables: {
      from: from,
      teamID: teamID,
      teamReviewMode: teamReviewMode,
      till: till,
      timeClockPlusUsersOnly: timeClockPlusUsersOnly,
      userName: userName,
    },
    client,
  });

  return (
    <>
      <Panel>
        <Panel.Body>
          <h3>Attendance Policy Violations</h3>
          <Table responsive striped>
            <thead>
              <tr>
                <th className="col-sm-2">Name</th>
                <th className="col-sm-2">Kind</th>
                <th className="col-sm-2">Shift Date</th>
                <th className="col-sm-2">Expected Time</th>
                <th className="col-sm-2">Actual Time</th>
                <th className="col-sm-2">Status</th>
                <th className="col-sm-1 text-right">
                  <a
                    className="btn btn-mint btn-labeled fa fa-plus"
                    onClick={() => {
                      setShowCreateEndedEarlyViolationModal(true);
                    }}
                  >
                    Add Shift Ended Early Violation
                  </a>
                </th>
              </tr>
            </thead>

            <tbody>
              {data?.attendancePolicyViolations.map((violation) => (
                <tr key={violation.id}>
                  <td className="col-sm-2">{violation.user.name}</td>
                  <td className="col-sm-2">
                    <div>{VIOLATION_KIND_TO_LABEL[violation.kind]}</div>
                    {violation.confirmedNoCall && <div>(Not Called In)</div>}
                  </td>
                  <td className="col-sm-2">
                    {DateTime.fromISO(violation.shiftDate).toLocaleString(DateTime.DATE_MED)}
                  </td>
                  <td className="col-sm-2">
                    {DateTime.fromISO(violation.expectedTime).toLocaleString(DateTime.DATETIME_MED)}
                  </td>
                  <td className="col-sm-2">
                    {violation.actualTime &&
                      DateTime.fromISO(violation.actualTime).toLocaleString(DateTime.DATETIME_MED)}
                  </td>
                  <td className="col-sm-2">
                    <div>{violation.waived ? 'Waived' : 'Applied'}</div>
                    {violation.reviewer && <div>by: {violation.reviewer?.name}</div>}
                    {violation.reviewedAt && (
                      <div>on: {DateTime.fromISO(violation.reviewedAt).toLocaleString(DateTime.DATETIME_MED)}</div>
                    )}
                    {violation.note && <div>with note: {violation.note}</div>}
                  </td>
                  <td className="col-sm-1 text-right">
                    <Link
                      kind="warning"
                      onClick={() => {
                        setViolationUnderReview({
                          ...violation,
                          reviewKind: 'toggleWaived',
                        });
                      }}
                    >
                      {violation.waived ? 'Apply' : 'Waive'}
                    </Link>
                    {!violation.waived && violation.kind === AttendancePolicyViolation__Kind.NoShow && (
                      <Box margin={'8px 0 0 0'}>
                        <Link
                          kind="warning"
                          onClick={() => {
                            setViolationUnderReview({
                              ...violation,
                              reviewKind: 'toggleConfirmedNoCall',
                            });
                          }}
                        >
                          {violation.confirmedNoCall ? 'Mark as Called In' : 'Mark as Not Called In'}
                        </Link>
                      </Box>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {data?.attendancePolicyViolations.length === 0 && (
            <div className="text-center">No Attendance Policy Violations Found Matching Criteria</div>
          )}
          {loading && <Spinner />}
        </Panel.Body>
      </Panel>
      {showCreateEndedEarlyViolationModal && (
        <CreateEndedEarlyViolationModal
          userOptions={data?.users ?? []}
          closeModal={() => {
            setShowCreateEndedEarlyViolationModal(false);
            refetch();
          }}
        />
      )}
      {violationUnderReview && (
        <ReviewModal
          violation={violationUnderReview}
          reviewKind={violationUnderReview.reviewKind}
          closeModal={() => {
            setViolationUnderReview(undefined);
            refetch();
          }}
        />
      )}
    </>
  );
};

import angular from 'angular';

const app = angular.module('app');

app.component('smsFollowupScheduler', {
  templateUrl: 'partials/components/sms_followup_scheduler.html',
  bindings: {
    cancel: '&',
    disabled: '<',
    leadId: '<',
    schedule: '&',
  },
  controller: [
    'FollowupService',
    'Lead',
    function (FollowupService, Lead) {
      const fetchLeadTimeZone = () =>
        Lead.get({ id: this.leadId }).$promise.then(
          (data) =>
            FollowupService.getMatchingTimeZone(data, this.timeZones) ||
            FollowupService.fetchTimeZoneGuess(data, this.timeZones),
        );

      this.updateSelections = (updates) => {
        this.selections = FollowupService.updateSelections(this.selections, updates);
        this.errors = FollowupService.clearErrors(this.errors, updates);
      };

      this.$onInit = () => {
        this.errors = {};
        this.selections = {};
        FollowupService.fetchUnavailabilities().then((dates) => {
          this.unavailabilities = dates;
        });
        FollowupService.fetchTimeZones()
          .then((timeZones) => {
            this.timeZones = timeZones;
          })
          .then(fetchLeadTimeZone)
          .then((timeZone) => {
            if (this.selections && !this.selections.timeZone) {
              this.updateSelections({ timeZone });
            }
          });
      };

      this.onClick = () => {
        this.errors = FollowupService.addErrors(this.selections);
        if (!FollowupService.hasError(this.errors)) {
          this.schedule({ timeObj: this.selections });
        }
      };
    },
  ],
});

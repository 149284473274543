(function () {
  const app = angular.module('app');

  app.component('dropdownFilter', {
    bindings: {
      canSelectAll: '<?',
      filterLabel: '@',
      filterOptions: '<',
      initialSelectionId: '<?',
      onChange: '&',
    },
    templateUrl: 'partials/components/dropdown_filter.html',
    controller: [
      '$q',
      function ($q) {
        const self = this;
        self.handleChange = handleChange;
        self.$onInit = onInit;

        function handleChange() {
          self.onChange({ selectionId: self.selectedOption ? self.selectedOption.id : null });
        }

        // filterOptions could be either a $resource class object, a raw $q promise or a plain array
        function onInit() {
          $q.when(self.filterOptions.$promise || self.filterOptions).then(initSelect);
        }

        function initSelect(resolvedFilterOptions) {
          self.resolvedFilterOptions = resolvedFilterOptions;

          if (self.initialSelectionId) {
            self.selectedOption = _.find(resolvedFilterOptions, function (option) {
              return option.id === self.initialSelectionId || option.id === Number(self.initialSelectionId);
            });

            // If there is no option matching the initialSelectionId, clear the filter.
            if (!self.selectedOption) {
              self.handleChange();
            }
          }
        }
      },
    ],
  });
})();

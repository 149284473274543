import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { DirectionsRenderer, GoogleMap, Marker, Polygon, withGoogleMap } from 'react-google-maps';

import { client } from '@admin/libraries/apollo';

import { useMapRegionsQuery } from '@admin/schema';
import { COLORS } from '@clutter/clean';

interface ICoordinate {
  latitude?: number | string | null;
  longitude?: number | string | null;
}

interface IMapProps {
  center?: ICoordinate;
  marker?: ICoordinate;
  directions?: google.maps.DirectionsResult;
  pins?: Array<ICoordinate & { label?: google.maps.MarkerLabel }>;
  directionPins?: Array<ICoordinate & { label?: google.maps.MarkerLabel }>;
}

const DEFAULT_ZOOM = 10;
const DEFAULT_LATITUDE = 34.052235;
const DEFAULT_LONGITUDE = -118.243683;

const DEFAULT_OPTIONS = {
  streetViewControl: false,
  disableDoubleClickZoom: true,
  mapTypeControl: false,
};

const DEFAULT_POLYGON_FILL_OPACITY = 0.3;
const DEFAULT_POLYGON_STROKE_OPACITY = 1.0;

const DIRECTIONS_OPTIONS: google.maps.DirectionsRendererOptions = {
  polylineOptions: {
    strokeColor: COLORS.tealBrand,
    strokeWeight: 5,
  },
};
// i.e. [[[lat,lng],...],...]
type Coordinates = number[][][];

const Map: React.FC<IMapProps> = ({ center, marker, pins, directions }) => {
  const { data } = useMapRegionsQuery({ client });

  const polygons = useMemo(
    () =>
      data?.regions.map((region) => ({
        id: region.id,
        color: region.hexColor,
        paths: (region.coordinates as Coordinates).map((points) =>
          points.map(([lat, lng]) => ({
            lat,
            lng,
          })),
        ),
      })),
    [data?.regions],
  );

  return (
    <GoogleMap
      zoom={DEFAULT_ZOOM}
      center={{
        lat: Number(center?.latitude ?? DEFAULT_LATITUDE),
        lng: Number(center?.longitude ?? DEFAULT_LONGITUDE),
      }}
      options={DEFAULT_OPTIONS}
    >
      {marker && marker.latitude && marker.longitude && (
        <Marker
          position={{
            lat: Number(marker.latitude),
            lng: Number(marker.longitude),
          }}
        />
      )}
      {!directions &&
        pins &&
        pins.map((pin, key) => (
          <Marker
            key={key}
            label={pin.label}
            position={{
              lat: Number(pin.latitude),
              lng: Number(pin.longitude),
            }}
          />
        ))}
      {directions && <DirectionsRenderer directions={directions} options={DIRECTIONS_OPTIONS} />}
      {polygons &&
        polygons.map((polygon) => (
          <Polygon
            key={polygon.id}
            paths={polygon.paths}
            options={{
              fillColor: polygon.color,
              fillOpacity: DEFAULT_POLYGON_FILL_OPACITY,
              strokeColor: polygon.color,
              strokeOpacity: DEFAULT_POLYGON_STROKE_OPACITY,
            }}
          />
        ))}
    </GoogleMap>
  );
};

const MapWithJS = withGoogleMap(Map);
const MapWithDOM: React.FC<Omit<IMapProps, 'directions'> & { renderDirections?: boolean }> = (props) => {
  const [directions, setDirections] = useState<google.maps.DirectionsResult | undefined>();

  const { directionPins, renderDirections } = props;

  useEffect(() => {
    if (directionPins && directionPins.length > 1 && window.google && renderDirections) {
      const directionsService = new window.google.maps.DirectionsService();
      directionsService.route(
        {
          origin: {
            lat: Number(directionPins[0].latitude),
            lng: Number(directionPins[0].longitude),
          },
          destination: {
            lat: Number(directionPins[1].latitude),
            lng: Number(directionPins[1].longitude),
          },
          travelMode: google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          if (status === google.maps.DirectionsStatus.OK) {
            setDirections(result);
          }
        },
      );
    }
  }, [directionPins?.length, renderDirections]);

  return (
    <MapWithJS
      {...props}
      directions={directions}
      containerElement={<div style={{ height: `320px` }} />}
      mapElement={<div style={{ height: `100%` }} />}
    />
  );
};

export { MapWithDOM as Map };

import * as React from 'react';

import DispatchMetrics from './dispatch_metrics';
import { IPendingDispatch } from './types/pending_dispatch';

interface IOutboundVehicleListProps {
  pendingDispatches?: IPendingDispatch[];
}

class OutboundVehicleList extends React.Component<IOutboundVehicleListProps> {
  public render() {
    const { pendingDispatches } = this.props;
    if (!pendingDispatches) {
      return null;
    }
    return (
      <div className="outbound-vehicle-list">
        <table className="outbound-vehicle-list__table">
          <tbody>
            {pendingDispatches.map((d, index) => (
              <tr className="outbound-vehicle-list__row" key={index}>
                <td className={this.validationMessage(d).className}>
                  {this.validationMessage(d).message}
                  <br />
                  {d.vehiclesSerialCode}
                </td>
                <td>
                  {d.requestedVehicles.map((v, idx) => (
                    <span key={idx}>{v}</span>
                  ))}
                </td>
                <td>
                  <span>{d.requestedVehicleTypes && `Type: ${d.requestedVehicleTypes}`}</span>
                  <span>{d.vehicleCount && `Count ${d.vehicleCount}`}</span>
                  <span>{d.seatsNeeded && `Seats Needed ${d.seatsNeeded}`}</span>
                  <span>Min CUFT {d.minCuft}</span>
                </td>
                <td>
                  <span>
                    <a href={'/dispatches/' + d.dispatchId} target="_blank" className="outbound-vehicle-list__link">
                      {d.dispatchId}
                    </a>
                  </span>
                  <span>{d.depotName}</span>
                  <span>{d.arrival}</span>
                </td>
                <td>
                  <DispatchMetrics
                    itemCount={d.itemCount}
                    totalCuft={d.dispatchTotalCuft}
                    cuftPerItemCount={d.cuftPerItemCount}
                    estimatedHours={d.outboundDockItemPerHour}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  private validationMessage(dispatch: IPendingDispatch) {
    if (dispatch.vehicleAssigned) {
      return { className: 'outbound-vehicle-list__row--red', message: 'No vehicle Assigned' };
    } else if (dispatch.vehicleRequirementsSatisfied) {
      if (dispatch.vehiclePreferencesSatisfied) {
        return { className: 'outbound-vehicle-list__row--green', message: 'Vehicle Successfully Assigned' };
      } else {
        return { className: 'outbound-vehicle-list__row--orange', message: 'Vehicle Preferences Not Met' };
      }
    } else {
      return { className: 'outbound-vehicle-list__row--brown', message: 'Vehicle Requirements Not Met' };
    }
  }
}

export { OutboundVehicleList };

import React, { useState } from 'react';

import { client } from '@admin/libraries/apollo';
import { Logistics__ParcelCarrierEnum, useParcelCarrierAccountsQuery } from '@admin/schema';

import { Panel } from '@admin/components/helpers/panel';
import { Spinner } from '@admin/components/spinner';
import { Alert, Button, Table, Text } from '@shared/components/bootstrap';

import { ParcelCarrierAccountRow } from './row';
import { CreateParcelCarrierAccountRow } from './create_row';

export const ParcelCarrierAccountsPanel: React.FC<{ accountID: string }> = ({ accountID }) => {
  const [building, setBuilding] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const { data, loading } = useParcelCarrierAccountsQuery({ client, variables: { accountID } });

  if (loading || !data) {
    return <Spinner />;
  }

  return (
    <Panel>
      <Panel.Header>
        <Panel.Title>Parcel Carrier Accounts</Panel.Title>
      </Panel.Header>
      <Panel.Body>
        <Table>
          <thead>
            <tr>
              <th className="text-left col-md-2">Carrier</th>
              <th className="text-left col-md-3">Account Number</th>
              <th className="text-left col-md-1">Country</th>
              <th className="text-left col-md-2">Postal Code</th>
              <th className="text-right col-md-4" />
            </tr>
          </thead>
          <tbody>
            {data.parcelCarrierAccounts.map((account) => (
              <ParcelCarrierAccountRow key={account.id} account={account} index={account.id} />
            ))}
            {error && (
              <tr>
                <td className="text-left" colSpan={5}>
                  <Alert style="danger">{error}</Alert>
                </td>
              </tr>
            )}
            {building && (
              <CreateParcelCarrierAccountRow
                key={0}
                accountID={accountID}
                setBuilding={setBuilding}
                setError={setError}
              />
            )}
          </tbody>
        </Table>
      </Panel.Body>
      <Panel.Footer>
        <Text alignment="right" tag="div">
          {!building && (
            <Button
              kind="primary"
              className="text-right"
              onClick={() => setBuilding(true)}
              disabled={Object.values(Logistics__ParcelCarrierEnum).length === data.parcelCarrierAccounts.length}
            >
              Add Account
            </Button>
          )}
          {building && (
            <Button kind="primary" className="text-right" onClick={() => setBuilding(false)}>
              Cancel
            </Button>
          )}
        </Text>
      </Panel.Footer>
    </Panel>
  );
};

import React from 'react';

import { MovingQuoteFragment } from '@admin/schema';

import { Distance } from '@admin/components/helpers/distance';
import { Time } from '@admin/components/helpers/time';

export const QuoteDetailRows: React.FC<{ quote: MovingQuoteFragment }> = ({ quote }) => (
  <>
    <tr>
      <td className="text-left">Selected Mover Count:</td>
      <td className="text-right">
        <strong>{quote.moverSelection}</strong>
      </td>
    </tr>
    <tr>
      <td className="text-left">Recommended Mover Count:</td>
      <td className="text-right">
        <strong>{quote.suggestedMoverCount}</strong>
      </td>
    </tr>
    <tr>
      <td className="text-left">Labor Rate:</td>
      <td className="text-right">
        <strong>{quote.laborRate?.name}</strong>
      </td>
    </tr>
    <tr>
      <td className="text-left">Packing Supplies Fee:</td>
      <td className="text-right">
        <strong>{quote.materialPackageSetEntry ? `$${quote.materialPackageSetEntry.fixedCost}` : 'None'}</strong>
      </td>
    </tr>
    <tr>
      <td className="text-left">Truck Count:</td>
      <td className="text-right">
        <strong>{quote.truckCount}</strong>
      </td>
    </tr>
    <tr>
      <td className="text-left">Estimated CuFt:</td>
      <td className="text-right">
        <strong>{quote.estimatedCuft}</strong>
      </td>
    </tr>
    <tr>
      <td className="text-left">Drive Distance:</td>
      <td className="text-right">
        <strong>{quote.driveDistance && <Distance value={quote.driveDistance} />}</strong>
      </td>
    </tr>
    <tr>
      <td className="text-left">Origin to Destination Drive Duration:</td>
      <td className="text-right">
        <strong>{quote.drivetimeDuration && <Time value={quote.drivetimeDuration} />}</strong>
      </td>
    </tr>
    <tr>
      <td className="text-left">Deadtime Drive Distance:</td>
      <td className="text-right">
        <strong>{quote.deadtimeDriveDistance && <Distance value={quote.deadtimeDriveDistance} />}</strong>
      </td>
    </tr>
    <tr>
      <td className="text-left">Deadtime Drive Duration:</td>
      <td className="text-right">
        <strong>{quote.deadtimeDriveDuration && <Time value={quote.deadtimeDriveDuration} />}</strong>
      </td>
    </tr>
  </>
);

import styled from '@emotion/styled';
import { ControlLabel, FormGroup, HelpBlock, Button } from '@shared/components/bootstrap';
import React from 'react';
import { FieldError } from 'react-hook-form';

const RequiredControlLabel = styled(ControlLabel)`
  ::after {
    content: '*';
    color: red;
    margin-left: 2px;
  }
`;
export const StyledRow = styled.div`
  display: flex;
  align-items: center;
`;
export const RemoveCargoDetailsButton = styled(Button)`
  margin: 5px 0px 0px;
`;
export const Info = styled.span`
  font-size: 10px;
`;

export const Field: React.FC<{
  error?: FieldError | FieldError[];
  id: string;
  label: string;
  required?: boolean;
}> = ({ error, id, label, required, children }) => (
  <FormGroup has={error ? 'error' : undefined}>
    {required && (
      <RequiredControlLabel htmlFor={id}>
        <strong>{label}</strong>
      </RequiredControlLabel>
    )}
    {!required && (
      <ControlLabel htmlFor={id}>
        <strong>{label}</strong>
      </ControlLabel>
    )}
    {children}
    {Array.isArray(error) && error.map((e) => e?.message && <HelpBlock>{e.message}</HelpBlock>)}
    {!Array.isArray(error) && error?.message && <HelpBlock>{error.message}</HelpBlock>}
  </FormGroup>
);

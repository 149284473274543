import { combineReducers } from 'redux';
import entities from './entities';
import domain from './domain';

export default combineReducers({
  entities,
  domain,
});

export * from './entities';
export * from './actions';
export * from './domain';
export * from './selectors';

(function () {
  angular.module('app').service('AvailabilityDailyService', [
    'AvailabilityService',
    function (AvailabilityService) {
      this.results = function (filters, users, unavailabilities) {
        const results = {};

        _.each(users, function (user) {
          results[user.id] = AvailabilityService.filter(unavailabilities, {
            user_id: user.id,
            date: filters.date,
          });
        });

        return results;
      };
    },
  ]);
})();

import { dispatchDocumentEvent, useDocumentEventListener } from '@shared/hooks';

const AUDIO_PLAYER_LOAD = 'audio:player:load';
const AUDIO_PLAYER_PLAY = 'audio:player:play';
const AUDIO_PLAYER_PAUSE = 'audio:player:pause';

export interface IAudioPlayerLoadEventDetails {
  src: string;
  agent?: string;
  date?: string | null;
  pageID?: number | string; // i.e. a phone call ID
}

export const dispatchAudioPlayerLoadEvent = (detail: IAudioPlayerLoadEventDetails) => {
  dispatchDocumentEvent(AUDIO_PLAYER_LOAD, detail);
};

export const useAudioPlayerLoadEvent = (callback: (detail: IAudioPlayerLoadEventDetails) => void) => {
  useDocumentEventListener(AUDIO_PLAYER_LOAD, callback);
};

export const useAudioPlayerPauseEvent = (callback: () => void) => {
  useDocumentEventListener(AUDIO_PLAYER_PAUSE, callback);
};

export const useAudioPlayerPlayEvent = (callback: () => void) => {
  useDocumentEventListener(AUDIO_PLAYER_PLAY, callback);
};

import { DateTime } from 'luxon';
import React, { useState } from 'react';

import { customerTicketURL } from '@admin/config/routes';
import { client } from '@admin/libraries/apollo';
import { CustomerTicket, CustomerTicket__Priority, Status, useBuildCustomerTicketMutation } from '@admin/schema';

import { Categories } from '@admin/components/customer_tickets/categories';
import { TicketGroupPicker } from '@admin/components/customer_tickets/ticket_group_picker';
import { DateTimePicker } from '@admin/components/helpers/date_time_picker';
import { AnchorButton, Button, Modal, Text } from '@shared/components/bootstrap';

import { PriorityPicker } from './priority_picker';

export const CreateModal: React.FC<{
  customerTicketableID: string;
  customerTicketableType: string;
  onClose(): void;
}> = ({ customerTicketableID, customerTicketableType, onClose }) => {
  const [category, setCategory] = useState<string>('');
  const [subCategory, setSubCategory] = useState<string | undefined>(undefined);
  const [description, setDescription] = useState<string>('');
  const [groupID, setGroupID] = useState<string>('');
  const [assigneeID, setAssigneeID] = useState<string | undefined>(undefined);
  const [actionRequiredAt, setActionRequiredAt] = useState<DateTime | undefined>(undefined);
  const [priority, setPriority] = useState<CustomerTicket__Priority>();
  const [newCustomerTicket, setNewCustomerTicket] = useState<Pick<CustomerTicket, 'id'> | undefined>();
  const [formError, setFormError] = useState<string>();

  const [createTicket, { loading }] = useBuildCustomerTicketMutation({ client });

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    event.stopPropagation();
    const response = await createTicket({
      variables: {
        input: {
          customerTicketableID,
          customerTicketableType,
          category,
          subCategory,
          description,
          groupID,
          assigneeID,
          actionRequiredAt: actionRequiredAt as any,
          priority: priority!,
        },
      },
    });
    if (response && response.data) {
      if (response.data.buildCustomerTicket.status === Status.Unprocessable) {
        const errorMessage = response.data.buildCustomerTicket.error;
        setFormError(errorMessage ?? undefined);
      } else {
        setFormError(undefined);
        setNewCustomerTicket(response.data.buildCustomerTicket.customerTicket!);
      }
    }
  };

  const onCategoryChange = (newCategory: string, newSubCategory: string) => {
    setCategory(newCategory);
    setSubCategory(newSubCategory);
  };

  const onTicketGroupChange = (newGroupID: string, newAssigneeID: string) => {
    setGroupID(newGroupID);
    setAssigneeID(newAssigneeID);
  };

  return (
    <Modal centered onClose={onClose}>
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>Create Customer Ticket</Modal.Title>
          <Modal.Close close={onClose} />
        </Modal.Header>
        <Modal.Body>
          {!newCustomerTicket && (
            <form onSubmit={onSubmit}>
              <div className="row">
                <Categories
                  className="col-md-6"
                  selectedCategory={category}
                  selectedSubCategory={subCategory}
                  onChange={onCategoryChange}
                />
              </div>
              <div className="mar-top">
                <textarea
                  required
                  className="form-control"
                  value={description}
                  placeholder="Describe the issue"
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
              <div className="row mar-top">
                <TicketGroupPicker
                  className="col-md-6"
                  selectedGroupID={groupID}
                  selectedAssigneeID={assigneeID}
                  onChange={onTicketGroupChange}
                />
              </div>
              <div className="mar-top">
                <PriorityPicker priority={priority} onChange={setPriority} />
              </div>
              <div className="mar-top">
                <Text tag="p" alignment="center">
                  <strong>When would you like to follow up?</strong>
                </Text>
                <DateTimePicker dt={actionRequiredAt} onChange={setActionRequiredAt} />
              </div>
              {formError && (
                <Text tag="p" style="danger" alignment="center">
                  <strong>{formError}</strong>
                </Text>
              )}
              <Button className="center-block" kind="primary" type="submit" disabled={loading}>
                Create
              </Button>
            </form>
          )}
          {!!newCustomerTicket && (
            <>
              <Text tag="p">Customer ticket #{newCustomerTicket.id} was successfully created!</Text>
              <AnchorButton kind="primary" href={customerTicketURL(newCustomerTicket)} target="_blank">
                View Ticket
              </AnchorButton>
            </>
          )}
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};

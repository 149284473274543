import * as React from 'react';

import { MultiselectFormControl } from '@admin/components/fields';

import { useSelfStorageFacilitiesQuery } from '@admin/schema';
import { client } from '@admin/libraries/apollo';

export const Selector: React.FC<{
  selectedIDs: string[];
  disabled?: boolean;
  onChange(selectedIDs: string[]): void;
}> = ({ onChange, selectedIDs, disabled = false }) => {
  const { data } = useSelfStorageFacilitiesQuery({ client });
  const facilities = data?.facilities ?? [];

  return (
    <MultiselectFormControl
      placeholder="Facilities"
      options={facilities}
      selectedIDs={selectedIDs}
      disabled={disabled}
      onChange={onChange}
    />
  );
};

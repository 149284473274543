import template from '@admin/angular/templates/checkout_form.html';

import angular from 'angular';

const app = angular.module('app');

// This component proxies a logic from the legacy checkouts controller. Doing
// this "outside to inside" refactor (the opposite of the normal approach)
// avoids refactoring everything in the checkouts controller all at once.
app.component('checkoutForm', {
  template,
  bindings: {
    coupons: '<',
  },
});

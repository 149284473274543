(() => {
  // display duration in seconds in hour:minutes format
  angular.module('app').filter('duration', function () {
    return function (duration, format) {
      if (!Number.isInteger(duration)) {
        return '';
      }

      const momentDuration = moment.duration(duration, 's');
      switch (format) {
        case 'humanize':
          return momentDuration.humanize();
        case 'seconds':
          return `${Math.round(momentDuration.as('seconds'))} secs`;
        case 'minutes':
          return `${Math.round(momentDuration.as('minutes'))} mins`;
        case 'hours':
          return `${Math.round(momentDuration.as('hours'))} hours`;
        default: {
          const hours = Math.floor(duration / 3600);
          const minutes = Math.floor(duration / 60) % 60;
          const paddedMinutes = (minutes < 10 ? '0' : '') + minutes;

          return `${hours}:${paddedMinutes}`;
        }
      }
    };
  });
})();

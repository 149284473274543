import { DateTime } from 'luxon';
import * as React from 'react';

import { Button } from '@shared/components/bootstrap';
import {
  Dialog as ConfirmationDialog,
  Response as ConfirmationResponse,
  useConfirmation,
} from '@admin/components/confirmation';

import { currency } from '@shared/utils/currency';

import { Billing__Receipt__Transaction, useBillingReceiptSendMutation } from '@admin/schema';
import { client } from '@admin/libraries/apollo';

export const Action: React.FC<{
  transaction: {
    id: string;
    type: 'charge' | 'refund' | 'offline_payment';
    amount: string | number;
    date: string;
  };
}> = ({ transaction }) => {
  const [save, { loading }] = useBillingReceiptSendMutation({
    client,
    variables: {
      input: {
        id: transaction.id,
        type: (() => {
          switch (transaction.type) {
            case 'charge':
              return Billing__Receipt__Transaction.Charge;
            case 'refund':
              return Billing__Receipt__Transaction.Refund;
            case 'offline_payment':
              return Billing__Receipt__Transaction.OfflinePayment;
          }
        })(),
      },
    },
  });

  const name = (() => {
    switch (transaction.type) {
      case 'charge':
        return 'Charge';
      case 'refund':
        return 'Refund';
      case 'offline_payment':
        return 'Payment';
    }
  })();

  const { confirmation, confirm } = useConfirmation();
  const onClick = async () => {
    if (loading) {
      return;
    }

    const confirmed = await confirm({
      title: `Send ${name} Receipt?`,
      description: `
          Are you sure you want to send a receipt for ${currency(Number(transaction.amount))}
          on ${DateTime.fromISO(transaction.date).toLocaleString(DateTime.DATE_MED)}?
        `,
      confirm: `Send ${name} Receipt`,
      cancel: 'Cancel',
    });
    if (confirmed !== ConfirmationResponse.Confirm) return;

    save();
  };

  return (
    <>
      {confirmation && <ConfirmationDialog confirmation={confirmation} />}
      <Button kind="default" onClick={onClick} loading={loading}>
        Send {name} Receipt
      </Button>
    </>
  );
};

import axios from 'axios';

import { IDepot } from '../types/depot';

interface IDepotParams {
  warehouseId: string;
}

class Depot {
  private params: IDepotParams;

  constructor(params: IDepotParams) {
    this.params = params;
  }

  public async fetch(): Promise<any> {
    try {
      const response = await axios.get('/depots.json', { params: { warehouse_id: this.params.warehouseId } });
      return this.parseResponse(response.data);
    } catch {
      return null;
    }
  }

  private parseResponse = (res: any) => res.map((d: IDepot) => ({ label: d.abbreviation, value: d.id }));
}

export { Depot };

import React from 'react';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import $ from 'jquery';
import { pick, omitBy, defaults, isEqual, get, flowRight, first, mapValues } from 'lodash';
import Dashboard from './DashboardLayout';
import { withRouterState, withUser } from '../../../helpers';
import { getUiGmapGoogleMapApi } from '../../../redux';

const defaultUrlProps = (props) => ({
  date: moment().format('YYYY-MM-DD'),
  region: first(props.user.region_ids) || 1,
});

const pickCompact = (obj, paths) => omitBy(pick(obj, paths), (item) => item === undefined);

/**
 * Interacts directly with the angular directive
 */
class DispatchLiveDashboardRoot extends React.Component {
  static propTypes = {
    store: PropTypes.object.isRequired,
    $stateParams: PropTypes.object.isRequired,
    $state: PropTypes.object.isRequired,
  };

  state = {
    stateOverrides: {},
  };

  async componentDidMount() {
    await this.props.store.dispatch(getUiGmapGoogleMapApi());
    await this.props.store.dispatch(this._handleRedirectForOrders);
    this._handleRedirectForDefaults();
    this.setState({ ready: true });
    $('body').addClass('single-view-app');
  }

  componentWillUnmount() {
    $('body').removeClass('single-view-app');
  }

  _handleRedirectForDefaults = () => {
    const stateParams = this._mergedStateParams();
    const stateParamsChange = defaults({}, stateParams, defaultUrlProps(this.props));
    if (!isEqual(stateParamsChange, stateParams)) {
      this._go(stateParamsChange);
    }
  };

  _handleRedirectForOrders = async (dispatch, getState, { resources }) => {
    const stateParams = this._mergedStateParams();
    const stateParamsChange = defaults({}, stateParams);
    if (!stateParams.region || !stateParams.dispatch) {
      if (stateParams.order && stateParams.account_id) {
        const order = await resources.Order.get({ id: stateParams.order, account_id: stateParams.account_id }).$promise;
        stateParamsChange.dispatch = order.dispatch_id;
        stateParamsChange.date = moment(order.scheduled).format('YYYY-MM-DD');
        stateParamsChange.region = get(order, 'region.id');
      }
    }
    if (stateParams.account_id) {
      stateParamsChange.account_id = null;
    }
    if (!stateParams.order) {
      stateParamsChange.order = null;
    }
    if (!isEqual(stateParamsChange, stateParams)) {
      this._go(stateParamsChange);
    }
  };

  _go = (params) => {
    let newParams = params;
    const currentParams = this._mergedStateParams();
    if (
      (currentParams.date && newParams.date !== currentParams.date) ||
      (currentParams.region && String(newParams.region) !== String(currentParams.region))
    ) {
      newParams = mapValues(newParams, (val, key) => (key !== 'date' && key !== 'region' ? null : val));
    }

    this.setState({ stateOverrides: newParams });
    return this.props.$state.go('map', newParams, {
      location: 'replace',
      notify: false,
      reload: false,
    });
  };

  _mergedStateParams = () => {
    const urlProps = ['date', 'region', 'order', 'dispatch', 'checkin', 'account_id'];
    return {
      ...pickCompact(this.props.$stateParams, urlProps),
      ...pickCompact(this.state.stateOverrides, urlProps),
    };
  };

  render() {
    const { store } = this.props;

    const {
      date,
      region: regionId,
      order: activeOrderId,
      dispatch: activeDispatchId,
      checkin: activeCheckinId,
    } = this._mergedStateParams();
    if (!this.state.ready) {
      return null;
    }

    return (
      <Provider store={store}>
        <Dashboard
          go={this._go}
          date={date ? moment(date).toDate() : null}
          regionId={Number(regionId)}
          activeCheckinId={activeCheckinId ? Number(activeCheckinId) : null}
          activeDispatchId={activeDispatchId ? Number(activeDispatchId) : null}
          activeOrderId={activeOrderId ? Number(activeOrderId) : null}
        />
      </Provider>
    );
  }
}

export default flowRight(withRouterState, withUser)(DispatchLiveDashboardRoot);

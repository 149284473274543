import React, { useEffect, useState } from 'react';
import { useForm, FormContext } from 'react-hook-form';

import { Panel } from '@admin/components/helpers/panel';
import { MultiselectFormControl } from '@admin/components/fields';
import { client } from '@admin/libraries/apollo';
import { useOpsAuditConfigurationModifyMutation, Status, OpsAudit__QuestionInput } from '@admin/schema';
import { opsAuditConfigurationsURL } from '@admin/config/routes';
import { Button, Alert, AnchorButton as Link } from '@shared/components/bootstrap';
import { Spacer } from '@shared/components/helpers';

import { QuestionField } from './question_field';
import { Field } from './field';

const DEFAULT_ERROR_MESSAGE = 'Sorry, something went wrong. Please try again or contact Tech Support.';

const OPTIONS = [
  'Auditor Error',
  'Policy',
  'Materials',
  'Customer Behavior',
  'Not Scannable',
  'Cannot Disassemble',
].map((option) => ({ id: option, name: option }));

export type EditFormData = {
  id: string;
  hoursUntilExpired?: number;
  refreshIntervalMinutes?: number;
  name: string;
  dataQuery: string;
  exceptionReasonCodes: string[];
  questions: OpsAudit__QuestionInput[];
};

export const OpsAuditConfigurationForm: React.FC<{
  formData: EditFormData;
  onSave(id: string | undefined): void;
}> = ({ formData, onSave }) => {
  const form = useForm<EditFormData>({ defaultValues: formData });
  const [executeModifySubmit, { loading }] = useOpsAuditConfigurationModifyMutation({ client });
  const [error, setError] = useState<string | undefined>(undefined);
  const [exceptionReasonCodes, setExceptionReasonCodes] = useState<string[]>(formData.exceptionReasonCodes ?? []);

  const onSubmit = async (data: EditFormData) => {
    try {
      const result = await executeModifySubmit({
        variables: {
          input: {
            id: data.id,
            refreshIntervalMinutes: parseInt(String(data.refreshIntervalMinutes), 10),
            hoursUntilExpired: data.hoursUntilExpired ? parseInt(String(data.hoursUntilExpired), 10) : undefined,
            name: data.name,
            dataQuery: data.dataQuery,
            exceptionReasonCodes: exceptionReasonCodes,
            questions: data.questions,
          },
        },
      });
      if (result.data?.opsAuditConfigurationModify.status === Status.Unprocessable) {
        window.scrollTo(0, 0);
        setError(result.data?.opsAuditConfigurationModify.error ?? DEFAULT_ERROR_MESSAGE);
        return;
      }
    } catch (e) {
      window.scrollTo(0, 0);
      setError(DEFAULT_ERROR_MESSAGE);
      return;
    }
    onSave(formData.id);
  };

  useEffect(() => {
    form.register({ name: 'id' });
    form.setValue('id', formData.id);
    return () => {
      form.unregister('id');
    };
  }, [form.register, form.unregister, form.setValue, formData.id]);

  return (
    <FormContext {...form}>
      <Panel>
        <form id="configurationForm" onSubmit={form.handleSubmit(onSubmit)}>
          <Panel.Body>
            {error && <Alert style="danger">{error}</Alert>}
            <h3>Audit Configuration</h3>
            <hr />
            <Field id="name" label="Name" error={form.errors.name} required>
              <input
                id="name"
                name="name"
                type="string"
                className="form-control"
                ref={form.register({ required: true })}
              />
            </Field>
            <Field
              id="refreshIntervalMinutes"
              label="Refresh Interval Minutes"
              error={form.errors.refreshIntervalMinutes}
              required
            >
              <input
                id="refreshIntervalMinutes"
                name="refreshIntervalMinutes"
                type="number"
                className="form-control"
                min="0"
                ref={form.register({ required: true, min: 0 })}
              />
            </Field>
            <Field id="hoursUntilExpired" label="Hours Until Expired" error={form.errors.hoursUntilExpired} required>
              <input
                id="hoursUntilExpired"
                name="hoursUntilExpired"
                type="number"
                className="form-control"
                min="1"
                ref={form.register({ required: true, min: 1 })}
              />
            </Field>
            <Field id="dataQuery" label="Data Query" error={form.errors.dataQuery} required>
              <input
                id="dataQuery"
                name="dataQuery"
                type="string"
                className="form-control"
                ref={form.register({ required: true })}
              />
            </Field>
            <Field id="exceptionReasonCodes" label="Exception Reason Codes">
              <MultiselectFormControl
                options={OPTIONS}
                selectedIDs={exceptionReasonCodes}
                onChange={setExceptionReasonCodes}
              />
            </Field>
            <Spacer height="10px" />
            <h3>Audit Questions</h3>
            {formData.questions.map((question, questionIndex) => (
              <QuestionField question={question} questionIndex={questionIndex} key={questionIndex} />
            ))}
          </Panel.Body>
          <Panel.Footer>
            <Link kind="default" href={opsAuditConfigurationsURL()} disabled={loading}>
              Cancel
            </Link>
            <Spacer width="10px" inline />
            <Button kind="primary" type="submit" loading={loading}>
              Save
            </Button>
          </Panel.Footer>
        </form>
      </Panel>
    </FormContext>
  );
};

import * as React from 'react';

import { client } from '@admin/libraries/apollo';
import { Query } from '@apollo/client/react/components';
import { Panel } from '@admin/components/helpers/panel';
import { IPalletTask } from '@admin/types';
import * as QUERY from './query.gql';

import { PalletTaskImages } from './pallet_task_images';

interface IQueryData {
  palletTasks: IPalletTask[];
}

export const LocationImagesPanel: React.FC<{ destinationLocationID: string; timezone: string }> = ({
  destinationLocationID,
  timezone,
}) => (
  <Query<IQueryData, { destinationLocationID: string }>
    client={client}
    query={QUERY}
    variables={{ destinationLocationID }}
  >
    {({ data, loading }) => {
      if (!data || loading) {
        return null;
      }

      return (
        <Panel>
          <Panel.Header>
            <Panel.Title>Images from Pallet Tasks</Panel.Title>
          </Panel.Header>
          <Panel.Body>{data && <PalletTaskImages palletTasks={data.palletTasks} timezone={timezone} />}</Panel.Body>
        </Panel>
      );
    }}
  </Query>
);

import angular from 'angular';
import { each } from 'lodash';

const app = angular.module('app');

app.component('attachedItems', {
  templateUrl: 'partials/items/attached.html',
  bindings: {
    item: '=',
    group: '=?',
  },
  controller: [
    '$scope',
    '$filter',
    'ItemGroup',
    'ItemGroupModalService',
    'HistoryModalService',
    function ($scope, $filter, ItemGroup, ItemGroupModalService, HistoryModalService) {
      this.$onInit = () => {
        this.item.$promise.then((item) => {
          if (item.group_id) {
            this.group = ItemGroup.get({ id: item.group_id });
            this.group.$promise.then(() => {
              const clone = angular.copy(this.group);
              delete clone.items;
              each(this.group.items, (i) => {
                i.group = clone;
              });
            });
          }
        });
      };

      this.manage = () => {
        if (this.group) {
          this.group.$promise.then(() => {
            ItemGroupModalService.present(angular.copy(this.group), (group) => {
              this.group = group;
            });
          });
        }
      };

      this.history = () => {
        HistoryModalService.present(this.item.group_id, 'ITEM_GROUP');
      };

      $scope.lightbox = (image) => {
        $scope.modal = {
          visible: !!image,
          source: $filter('resize')(image, 1920, 1920, 'clip'),
        };
      };
    },
  ],
});

import styled from '@emotion/styled';
import { DateTime } from 'luxon';
import React, { useState } from 'react';

import { client } from '@admin/libraries/apollo';

import { Roles } from '@admin/components/helpers/roles';
import { Timestamp } from '@admin/components/timestamp';
import {
  LaborClock,
  Status,
  User,
  useModifyLaborClockMutation,
  Assignment__Role,
  useDeleteLaborClockMutation,
  OrderLaborClocksDocument,
} from '@admin/schema';
import { UserRole } from '@admin/types';
import { Alert, Button } from '@shared/components/bootstrap';

const StyledAlert = styled.div`
  div {
    margin: 0px;
  }
`;

type LaborClocksRowType = Pick<LaborClock, 'id' | 'role' | 'started' | 'ended' | 'orderID'> & {
  user: Pick<User, 'id' | 'name'>;
};

export const Row: React.FC<{
  laborClock: LaborClocksRowType;
  defaultDate: DateTime | undefined;
  tz?: string;
}> = ({ laborClock, tz, defaultDate }) => {
  const timeZone = tz ?? DateTime.local().zoneName;
  const toLocalIso = (dateTimeISO: string | null | undefined) =>
    dateTimeISO
      ? DateTime.fromISO(dateTimeISO, { zone: timeZone }).toISO({ suppressSeconds: true, includeOffset: false })
      : undefined;

  const [error, setError] = useState<string | undefined>(undefined);
  const [editing, setEditing] = useState<boolean>(false);
  const [confirmingDeletion, setConfirmingDeletion] = useState<boolean>(false);
  const [endedTime, setEndedTime] = useState<string | undefined>(toLocalIso(laborClock.ended));
  const [startedTime, setStartedTime] = useState<string | undefined>(toLocalIso(laborClock.started));
  const [executeEditSubmit, { loading: loadingEditSubmit }] = useModifyLaborClockMutation({
    client,
    awaitRefetchQueries: true,
    refetchQueries: [{ query: OrderLaborClocksDocument, variables: { orderID: laborClock.orderID } }],
  });
  const [executeDelete, { loading: loadingDelete }] = useDeleteLaborClockMutation({
    client,
    awaitRefetchQueries: true,
    refetchQueries: [{ query: OrderLaborClocksDocument, variables: { orderID: laborClock.orderID } }],
  });
  const loading = loadingEditSubmit || loadingDelete;

  const getEndedDateTime = (value: string | undefined) => {
    if (value && defaultDate) {
      const newDt = DateTime.fromISO(value, { zone: timeZone });
      return newDt.toISO();
    } else {
      return laborClock.ended;
    }
  };

  const getStartedDateTime = (value: string | undefined) => {
    if (value && defaultDate) {
      const newDt = DateTime.fromISO(value, { zone: timeZone });
      return newDt.toISO();
    } else {
      return laborClock.started;
    }
  };

  async function save() {
    try {
      const input = { started: getStartedDateTime(startedTime), ended: getEndedDateTime(endedTime) };
      const payload = await executeEditSubmit({ variables: { id: laborClock.id, input: input } });
      const res = payload.data?.modifyLaborClock;
      if (res?.status === Status.Ok) {
        setError(undefined);
      } else {
        setStartedTime(toLocalIso(laborClock.started));
        setEndedTime(toLocalIso(laborClock.ended));
        setError(res?.error || undefined);
      }
    } catch (e) {
      setStartedTime(toLocalIso(laborClock.started));
      setEndedTime(toLocalIso(laborClock.ended));
      setError('Sorry, something went wrong. Please try again or contact Tech Support.');
    } finally {
      setEditing(false);
    }
  }

  async function deleteLaborClock() {
    try {
      const payload = await executeDelete({ variables: { id: laborClock.id } });
      const res = payload.data?.deleteLaborClock;
      if (res?.status === Status.Ok) {
        setError(undefined);
      } else {
        setError(res?.error || undefined);
      }
    } catch (e) {
      setError('Sorry, something went wrong. Please try again or contact Tech Support.');
    } finally {
      setEditing(false);
      setConfirmingDeletion(false);
    }
  }

  const cancelEditing = () => {
    setStartedTime(toLocalIso(laborClock.started));
    setEndedTime(toLocalIso(laborClock.ended));
    setEditing(false);
  };

  return (
    <tr>
      <td className="text-left col-sm-3">{laborClock.user.name}</td>
      <td className="text-left col-sm-1">
        <span className={'label ' + (laborClock.role === Assignment__Role.Lead ? 'label-primary' : 'label-default')}>
          {laborClock.role.toLowerCase()}
        </span>
      </td>
      <td className="text-left col-sm-2">
        {!editing && laborClock.started && <Timestamp value={laborClock.started} format="small" timezone={tz} />}
        {editing && (
          <input
            type="datetime-local"
            value={startedTime}
            onChange={(event) => setStartedTime(event.currentTarget.value)}
          />
        )}
        {!editing && !laborClock.started && '-'}
      </td>
      <td className="text-left col-sm-2">
        {!editing && laborClock.ended && <Timestamp value={laborClock.ended} format="small" timezone={tz} />}
        {editing && (
          <input
            type="datetime-local"
            value={endedTime}
            onChange={(event) => setEndedTime(event.currentTarget.value)}
          />
        )}
        {!editing && !laborClock.ended && '-'}
      </td>
      <td className="text-left col-sm-1">
        {laborClock.ended &&
          laborClock.started &&
          DateTime.fromISO(laborClock.ended).diff(DateTime.fromISO(laborClock.started)).toFormat('hh:mm:ss')}
        {!(laborClock.ended && laborClock.started) && '-'}
      </td>
      <td className="text-right col-sm-3">
        <Roles show={[UserRole.Admin, UserRole.Dispatcher]}>
          {error && (
            <StyledAlert>
              <Alert style="danger" onClose={() => setError(undefined)}>
                {error}
              </Alert>
            </StyledAlert>
          )}
          {!editing && !error && (
            <Button kind="primary" onClick={() => setEditing(true)}>
              Edit
            </Button>
          )}
          {editing && !confirmingDeletion && (
            <>
              <Button kind="danger" onClick={() => setConfirmingDeletion(true)} loading={loading}>
                Delete
              </Button>{' '}
              <Button kind="secondary" onClick={() => cancelEditing()} loading={loading}>
                Cancel
              </Button>{' '}
              <Button kind="primary" onClick={() => save()} loading={loading}>
                Save
              </Button>
            </>
          )}
          {editing && confirmingDeletion && (
            <>
              {'Delete? '}
              <Button kind="primary" onClick={() => setConfirmingDeletion(false)} loading={loading}>
                Cancel
              </Button>{' '}
              <Button kind="danger" onClick={() => deleteLaborClock()} loading={loading}>
                Delete
              </Button>
            </>
          )}
        </Roles>
      </td>
    </tr>
  );
};

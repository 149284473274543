(function () {
  const app = angular.module('app');

  app.component('itemsEditorFilterPanel', {
    templateUrl: 'partials/components/items_editor_filter.html',
    bindings: {
      itemsCount: '<',
    },
    controller: [
      '$rootScope',
      'EventConstants',
      function ($rootScope, EventConstants) {
        const self = this;

        self.$onInit = function () {
          self.focusListener = $rootScope.$on(EventConstants.ITEM_CATEGORY_EDITOR_SAVED, function (options) {
            self.focusOnNextCategoryElement(options.event);
          });
        };

        self.$onDestroy = function () {
          self.focusListener();
        };

        self.toggleCategoriesMode = function () {
          self.editingCategories = !self.editingCategories;

          if (self.editingCategories) {
            $rootScope.$broadcast('edit_category_mode:enabled');
            setTimeout(self.focusOnNextCategoryElement, 300);
          } else {
            $rootScope.$broadcast('edit_category_mode:disabled');
          }
        };

        self.focusOnNextCategoryElement = function (event) {
          const pendingContainers = angular.element('.category-search-input:visible').filter(function () {
            return this.value === '';
          });

          let firstPendingElement = pendingContainers.get(0);

          if (event) {
            const eventContainer = angular.element(event.target).closest('.category-search-input');
            if (pendingContainers.get(0) === eventContainer.get(0)) {
              // if user enters skips current container, do not treat current container as first element
              firstPendingElement = pendingContainers.get(1);
            }
          }

          if (firstPendingElement) {
            firstPendingElement.focus();
          }
        };
      },
    ],
  });
})();

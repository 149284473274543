import React from 'react';

import { PackageSetEntryFragment } from '@admin/schema';

export const PackageSetEntry: React.FC<{
  amount: number;
  packageSetEntry: PackageSetEntryFragment;
}> = ({ amount, packageSetEntry }) => (
  <div className="form-group">
    <div className="col-sm-7 control-label">
      <input id={packageSetEntry.id} type="checkbox" checked={true} readOnly={true} />
      {` ${packageSetEntry.package.name}: `}
    </div>
    <div className="col-sm-5 form-total">{`$${amount}`}</div>
  </div>
);

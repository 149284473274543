import angular from 'angular';

const app = angular.module('app');

app.constant('TicketConstants', {
  PRIORITIES: [
    'Extremely Urgent - addressed within 1 hour',
    'High Priority - addressed by end of next day',
    'Medium Priority - addressed in 3 days',
    'Low Priority - no specific deadline',
  ],
});

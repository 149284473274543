import React from 'react';
import classNames from 'classnames';
import styled from '@emotion/styled';
import { COLORS } from '@clutter/clean';

export enum AdminTag {
  WebOnlyIncentive = 'Web Only Incentive',
}

const TagInner = styled.span`
  & + & {
    margin-left: 8px;
  }
`;

const TAG_BACKGROUND_COLOR: Record<string, string> = {
  [AdminTag.WebOnlyIncentive]: COLORS.sunset,
};

const DEFAULT_TAG_BACKGROUND_COLOR = 'black';

interface ITagProps {
  tag: string;
  className?: string;
  style?: React.CSSProperties;
}

export const Tag: React.FC<ITagProps> = ({ className, style, tag }) => (
  <TagInner
    className={classNames('label', className)}
    style={{ ...style, backgroundColor: TAG_BACKGROUND_COLOR[tag] ?? DEFAULT_TAG_BACKGROUND_COLOR }}
  >
    {tag}
  </TagInner>
);

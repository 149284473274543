/** @jsx jsx */

import * as React from 'react';

import { jsx } from '@emotion/react';

import styled from '@emotion/styled';

import { Timestamp } from '@shared/components/helpers';
import { MessageFragment } from '@admin/schema';

const Label = styled.div`
  display: flex;
  font-weight: bold;
`;

const Info = styled.div`
  padding-left: 3px;
  font-weight: normal;
`;

const MessageContainer = styled.div`
  border-top: 2px #ddd solid;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: center;
  padding: 10px 10px;
`;

export const MessageHistory: React.FC<{ message: MessageFragment }> = ({ message }) => (
  <MessageContainer>
    <div>
      <Timestamp value={message.createdAt} format={Timestamp.Format.DateTime} />
    </div>
    <div>
      <Label>
        Status: <Info>{message.twilioStatus}</Info>
      </Label>
      <Label>
        Direction: <Info>{message.kind}</Info>
      </Label>
    </div>
    <div>
      <Label>
        SMS: <Info>{message.body}</Info>
      </Label>
    </div>
  </MessageContainer>
);

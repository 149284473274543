(function () {
  const app = angular.module('app');

  app.component('zipCodeStatus', {
    templateUrl: 'partials/components/zip_code_status.html',
    controllerAs: 'zip_code_status_controller',
    bindings: {
      serviceAreaClassifier: '<',
      checkingMoving: '=?',
      checkingFullService: '=?',
      checkingSelfStorage: '=?',
      selfStorageAlwaysAvailable: '<?',
    },
    controller: [
      'ServiceAreaConstants',
      function (ServiceAreaConstants) {
        this.$onChanges = (changes) => {
          if (changes.serviceAreaClassifier && changes.serviceAreaClassifier.currentValue) {
            this.serviceAreaClassifier.$promise.then(() => {
              this.message = this.selfStorageAlwaysAvailable
                ? getUsStorageMessage(this.serviceAreaClassifier)
                : getMessage(
                    this.serviceAreaClassifier,
                    this.checkingMoving,
                    this.checkingFullService,
                    this.checkingSelfStorage,
                  );
              this.messageClass = getClassName(this.message);
            });
          }
        };

        function getMessage(classifier, checkingMoving, checkingFullService, checkingSelfStorage) {
          const statuses = ServiceAreaConstants.STATUSES;
          const availability = ServiceAreaConstants.AVAILABILITY;
          if (!classifier) {
            return '';
          } else if (!classifier.valid) {
            return statuses.INVALID_ZIP;
          } else if (checkingMoving) {
            return (
              'Moving: ' + (classifier.moving_service_eligible ? availability.AVAILABLE : availability.UNAVAILABLE)
            );
          } else if (checkingFullService) {
            return (
              'Full Service Storage: ' +
              (classifier.full_service_eligible ? availability.AVAILABLE : availability.UNAVAILABLE)
            );
          } else if (checkingSelfStorage) {
            return (
              'Self Storage: ' + (classifier.self_storage_eligible ? availability.AVAILABLE : availability.UNAVAILABLE)
            );
          } else if (classifier.full_service_eligible && classifier.self_storage_eligible) {
            return statuses.BOTH;
          } else if (classifier.full_service_eligible) {
            return statuses.FULL_SERVICE_ONLY;
          } else if (classifier.self_storage_eligible) {
            return statuses.SELF_STORAGE_ONLY;
          } else {
            return statuses.OUT_OF_SERVICE_AREA;
          }
        }

        function getClassName(message) {
          const statuses = ServiceAreaConstants.STATUSES;
          const availability = ServiceAreaConstants.AVAILABILITY;
          if (message.includes(availability.AVAILABLE)) {
            return 'text-success';
          }
          if (message.includes(availability.UNAVAILABLE)) {
            return 'text-danger';
          }
          switch (message) {
            case statuses.FULL_SERVICE_ONLY:
            case statuses.BOTH:
              return 'text-success';
            case statuses.SELF_STORAGE_ONLY:
              return 'text-primary';
            case statuses.INVALID_ZIP:
            case statuses.OUT_OF_SERVICE_AREA:
              return 'text-danger';
          }
        }

        function getUsStorageMessage(classifier) {
          const statuses = ServiceAreaConstants.STATUSES;
          if (!classifier) {
            return '';
          } else if (!classifier.valid) {
            return statuses.INVALID_ZIP;
          } else if (classifier.full_service_eligible) {
            return statuses.BOTH;
          } else {
            return statuses.SELF_STORAGE_ONLY;
          }
        }
      },
    ],
  });
})();

import { SerializedStyles } from '@emotion/react';
import { flatten } from 'lodash';

import { Maybe } from '@admin/schema';

type MetricAssessment = {
  value: Maybe<number>;
  assessment: Maybe<SerializedStyles>;
};

export type CuftMetric = {
  cuft: MetricAssessment;
  cuftPerHour: MetricAssessment;
};

export type CuftMetrics = {
  actual: CuftMetric[];
  estimated: CuftMetric[];
};

export const DEFAULT_METRICS: CuftMetrics = {
  actual: [],
  estimated: [],
};

export const appendMetrics = (a: CuftMetrics, b: CuftMetrics) => ({
  actual: a.actual.concat(b.actual),
  estimated: a.estimated.concat(b.estimated),
});

export const concatMetrics = (metrics: CuftMetrics[]) =>
  metrics.reduce((acc, elem) => appendMetrics(acc, elem), DEFAULT_METRICS);

export const getAssessments = (metrics: CuftMetrics) => ({
  actualCuft: flatten(metrics.actual.map(({ cuft, cuftPerHour }) => [cuft.value, cuftPerHour.value])),
  actualCuftAssessments: flatten(
    metrics.actual.map(({ cuft, cuftPerHour }) => [cuft.assessment, cuftPerHour.assessment]),
  ),

  estimatedCuft: flatten(metrics.estimated.map(({ cuft, cuftPerHour }) => [cuft.value, cuftPerHour.value])),
  estimatedCuftAssessments: flatten(
    metrics.estimated.map(({ cuft, cuftPerHour }) => [cuft.assessment, cuftPerHour.assessment]),
  ),
});

import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';

const DATE_FORMAT = 'yyyy-MM-dd';
const TIME_FORMAT = 'HH:mm';

export const DateTimePicker: React.FC<{
  id?: string;
  dt?: DateTime;
  disabled?: boolean;
  onChange(dt: DateTime | undefined): void;
  allowPastDate?: boolean;
  required?: boolean;
}> = ({ id, dt, disabled, onChange, allowPastDate, required = true }) => {
  const [date, setDate] = useState<string>(dt ? dt.toFormat(DATE_FORMAT) : '');
  const [time, setTime] = useState<string>(dt ? dt.toFormat(TIME_FORMAT) : '');
  const [formError, setFormError] = useState<string>();

  const today = DateTime.local().setZone('America/Los_Angeles');

  useEffect(() => {
    if (!date || !time) {
      onChange(undefined);
      return;
    }

    const newDt = DateTime.fromFormat(`${date} ${time}`, `${DATE_FORMAT} ${TIME_FORMAT}`);
    if (allowPastDate !== true && newDt < today) {
      onChange(undefined);
      setFormError('Please select a date and time in the future');
    } else {
      onChange(newDt);
      setFormError(undefined);
    }
  }, [date, time]);

  return (
    <div className="form-group">
      <div className="row">
        <div className="col-md-6">
          <input
            id={id ? id + '-date' : undefined}
            type="date"
            className="form-control"
            value={date}
            min={allowPastDate ? undefined : today.toFormat(DATE_FORMAT)}
            onChange={(event) => setDate(event.currentTarget.value)}
            disabled={disabled}
            required={required}
          />
        </div>
        <div className="col-md-6">
          <input
            id={id ? id + '-time' : undefined}
            type="time"
            className="form-control"
            value={time}
            onChange={(event) => setTime(event.currentTarget.value)}
            disabled={disabled}
            required={required}
          />
        </div>
      </div>
      {formError && <div className="text-danger">{formError}</div>}
    </div>
  );
};

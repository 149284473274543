import * as React from 'react';
import { useEffect, useState } from 'react';

import { Pagination } from '@admin/components/pagination';
import { Spinner } from '@admin/components/spinner';

import {
  SelfStorage__FacilityFragment,
  SelfStorage__Partner__Company,
  usePaginatedSelfStorageFacilitiesQuery,
} from '@admin/schema';
import { client } from '@admin/libraries/apollo';

const ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

export const Radios: React.FC<{
  zip?: string;
  selectedID?: string;
  onFetch?(results: SelfStorage__FacilityFragment[]): void;
  onSelect(selectedID: string): void;
}> = ({ zip, selectedID, onFetch, onSelect }) => {
  const [page, setPage] = useState<number | undefined>(undefined);
  const { data, loading } = usePaginatedSelfStorageFacilitiesQuery({
    client,
    variables: {
      page,
      filters: { company: SelfStorage__Partner__Company.Clutter, zip },
    },
  });

  const paginated = data && data.paginated;

  useEffect(() => {
    if (onFetch && paginated) {
      onFetch(paginated.results);
    }
  }, [paginated]);

  return (
    <>
      {loading && <Spinner />}
      {!loading && paginated && (
        <>
          {paginated.results.map((result, position) => {
            const { address } = result;
            const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
              if (event.target.checked) {
                onSelect(result.id);
              }
            };
            return (
              <div key={result.id} className="radio">
                <label>
                  <input type="radio" onChange={onChange} checked={result.id === selectedID} />{' '}
                  <span>{ALPHABET[position]}:</span> <strong>{result.name}</strong>
                  <br />
                  <span>
                    {address.street} {address.aptsuite} {address.city} {address.state} {address.zip}
                  </span>
                </label>
              </div>
            );
          })}
          <Pagination pagination={paginated.pagination} onPage={setPage} />
        </>
      )}
    </>
  );
};

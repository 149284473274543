import angular from 'angular';

const app = angular.module('app');

const promotionLabelController = function () {
  this.$onInit = () => {
    this.labelContent = `Promotion: ${this.promotion.name}`;
  };
};

app.component('promotionLabel', {
  templateUrl: 'partials/components/promotion_label.html',
  bindings: {
    promotion: '<',
  },
  controller: promotionLabelController,
});

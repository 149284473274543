import * as React from 'react';
import { FC, useContext } from 'react';
import { DateTime, Duration, Interval } from 'luxon';

import { Timeslot } from '@shared/components/timeslot/timeslot';

import { AvailabilityContext } from './availability_context';
import { AvailabilityPrompt } from './availability_prompt';

import { Availability } from './types';

const makeInterval = (availability: Availability, zone: string): Interval => {
  const start = DateTime.fromISO(availability.datetime, { zone });
  const duration = Duration.fromISO(availability.duration!);
  return Interval.after(start, duration);
};

export const AvailabilityTimeslot: FC = () => {
  const { availabilities, date, zone, selection, onSelect } = useContext(AvailabilityContext);
  const current = selection && makeInterval(selection, zone);
  if (!date) return null;

  const intervalsWithFeeAmounts = availabilities
    .map((availability) => ({
      interval: makeInterval(availability, zone),
      feeAmount: availability.feeAmount,
    }))
    .filter(
      ({
        interval: {
          start: { year, month, day },
        },
      }) => year === date.year && month === date.month && day === date.day,
    );

  return (
    <div>
      <AvailabilityPrompt>Please select an arrival window.</AvailabilityPrompt>
      <Timeslot
        date={date}
        intervalsWithFeeAmounts={intervalsWithFeeAmounts}
        disabled={() => !date}
        selected={(value: Interval) => !!current?.equals(value)}
        onSelect={(value: { interval: Interval; forced: boolean }) => {
          onSelect(availabilities.find((availability) => makeInterval(availability, zone).equals(value.interval))!);
        }}
        showPrices={true}
      />
    </div>
  );
};

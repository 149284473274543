/* eslint-disable no-param-reassign */

angular.module('app').factory('Account', [
  '$rootScope',
  '$resource',
  'TZDateTimeService',
  'TransformBuilder',
  'SubscriptionService',
  'AccountService',
  'AccountConstants',
  function (
    $rootScope,
    $resource,
    TZDateTimeService,
    TransformBuilder,
    SubscriptionService,
    AccountService,
    AccountConstants,
  ) {
    const Account = $resource(
      '/accounts/:id.json',
      {
        id: '@id',
        reason_id: '@reason_id',
      },
      {
        index: {
          method: 'GET',
          isArray: false,
        },
        get: {
          method: 'GET',
          isArray: false,
          transformResponse: TransformBuilder.transformResponse((data) => {
            data.cardless = data.tags.includes(AccountConstants.TAGS.CARDLESS);
            data.contract_start_date = TZDateTimeService.localForValueWithTZ(data.contract_start_date, data.tz);
            return data;
          }),
        },
        update: {
          method: 'PUT',
          transformResponse: TransformBuilder.transformResponse((data) => {
            data.contract_start_date = TZDateTimeService.localForValueWithTZ(data.contract_start_date, data.tz);
            return data;
          }),
        },
        unsuspend: {
          method: 'PUT',
          isArray: false,
          url: '/accounts/:id/unsuspend.json',
        },
        cancel: {
          method: 'PUT',
          isArray: false,
          url: '/accounts/:id/cancel.json',
        },
        updateCustomers: {
          method: 'POST',
          isArray: true,
          url: '/accounts/:id/customers.json',
        },
        destroy: {
          method: 'DELETE',
        },
      },
    );

    Account.prototype.storageSubscriptions = function () {
      return _.chain(this.subscriptions)
        .filter(SubscriptionService.planTypeFilter('StoragePlan'))
        .sortBy('plan.id')
        .value();
    };

    Account.prototype.protectionSubscriptions = function () {
      return _.chain(this.subscriptions)
        .filter(SubscriptionService.planTypeFilter('ProtectionPlan'))
        .sortBy('plan.id')
        .value();
    };

    return Account;
  },
]);

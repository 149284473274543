(() => {
  const app = angular.module('app');

  app.directive('inputValidate', [
    'InputValidationService',
    'InputTypeConstants',
    (InputValidationService, InputTypeConstants) => ({
      restrict: 'A',
      scope: {
        validCallback: '&',
        invalidCallback: '&',
        skipValidationWhenBlank: '@',
        type: '@',
      },
      link: (scope, element) => {
        const validate = (text, validationMethod) => {
          if (text) {
            validationMethod(text).then((response) => {
              executeCallback(response);
            });
          } else if (scope.skipValidationWhenBlank === 'true') {
            scope.$apply(() => {
              scope.validCallback();
            });
          }
        };

        const executeCallback = (response) => {
          if (response.data) {
            if (response.data.valid) {
              scope.validCallback();
            } else if (response.data.valid === false) {
              scope.invalidCallback();
            }
          }
        };

        let validationMethod;
        switch (scope.type) {
          case InputTypeConstants.TYPE_PHONE_NUMBER:
            validationMethod = InputValidationService.validatePhoneNumber;
            break;
          case InputTypeConstants.TYPE_EMAIL:
            validationMethod = InputValidationService.validateEmail;
            break;
        }

        if (validationMethod) {
          element.on('blur', () => {
            validate(element.val(), validationMethod);
          });
        }
      },
    }),
  ]);
})();

(function () {
  const app = angular.module('app');

  app.controller('ShipsShowController', [
    '$state',
    '$scope',
    '$filter',
    '$q',
    '$resource',
    '$stateParams',
    'Account',
    'Order',
    'Ship',
    'Page',
    'ErrorService',
    'ConfirmationService',
    function (
      $state,
      $scope,
      $filter,
      $q,
      $resource,
      $stateParams,
      Account,
      Order,
      Ship,
      Page,
      ErrorService,
      ConfirmationService,
    ) {
      $scope.orderID = $stateParams.order_id;
      $scope.accountID = $stateParams.account_id;

      const Shipment = $resource('/easypost/shipment.json', { assignment_id: '@assignment_id' });
      const debounces = {};

      function reset() {
        const account = $scope.account;
        const order = $scope.order;
        if (!account.id || !order.id) {
          return;
        }
        const title =
          'Account #' + account.number + ' (' + account.customer.name + ') - Order #' + order.number + ' - Ship';
        Page.reset({ title: title });

        $scope.ship = _.map($scope.order.items, function (item) {
          return { item: item, rates: [] };
        });
      }

      $scope.account = Account.get({ id: $scope.accountID }, reset);
      $scope.order = Order.get({ id: $scope.orderID, account_id: $scope.accountID }, reset);

      $scope.total = function () {
        return _.sum(
          _.map($scope.ship, function (entry) {
            return entry.rate ? Number(entry.rate.rate) : 0.0;
          }),
        );
      };

      $scope.dimensions = function (entry) {
        return { width: entry.width, height: entry.height, length: entry.length, weight: entry.weight };
      };

      $scope.calculate = function (entry) {
        if (debounces[entry.item.id]) {
          debounces[entry.item.id].reject();
          delete debounces[entry];
        }

        delete entry.shipment;
        delete entry.rate;

        const dimensions = $scope.dimensions(entry);
        if (_.every(_.values(dimensions))) {
          const defer = $q.defer();
          debounces[entry.item.id] = defer;

          defer.promise.then(function (shipment) {
            entry.shipment = shipment;
          });

          Shipment.save({
            account_id: $scope.accountID,
            order_id: $scope.orderID,
            item_id: entry.item.id,
            shipment: dimensions,
          }).$promise.then(defer.resolve, defer.reject, defer.notify);
        }
      };

      $scope.shippable = function () {
        return _.every($scope.ship, function (entry) {
          return !!entry.rate;
        });
      };

      $scope.showQuotedServiceUnavailableWarning = function (entry) {
        if (entry.item.shipment_item_quote) {
          const service = entry.item.shipment_item_quote.service;
          return _.every(entry.shipment.rates, function (rate) {
            return rate.service !== service;
          });
        }
        return false;
      };

      function ship() {
        const _ship = new Ship({
          account_id: $scope.accountID,
          order_id: $scope.orderID,
          shipments: _.map($scope.ship, function (entry) {
            return {
              item_id: entry.item.id,
              easy_post_rate_id: entry.rate ? entry.rate.id : null,
              easy_post_shipment_id: entry.shipment ? entry.shipment.id : null,
              carrier: entry.rate ? entry.rate.carrier : null,
              service: entry.rate ? entry.rate.service : null,
              rate: entry.rate ? entry.rate.rate : null,
              currency: entry.rate ? entry.rate.currency : null,
              width: entry.width,
              height: entry.height,
              length: entry.length,
              weight: entry.weight,
            };
          }),
        });

        $scope.saving = _ship;
        _ship
          .$save()
          .then(function () {
            $state.go('viewOrder', { account_id: $scope.accountID, id: $scope.orderID });
          })
          .catch(function (error) {
            delete $scope.saving;
            ErrorService.handle(error);
          });
      }

      $scope.save = function () {
        const address = $filter('address')($scope.order.address);
        ConfirmationService.confirm({
          title: 'Are you sure?',
          description:
            'Please verify that the shipping address (' +
            address +
            ') and dimensions are correct. ' +
            'Shipping an order purchases postage and cannot be undone. ' +
            'If you are not sure, contact your manager prior to shipping.',
          acceptButton: 'Verified, Ship This Order',
          declineButton: 'Whoops, I Forgot Something',
        }).then(ship);
      };
    },
  ]);
})();

import { DateTime } from 'luxon';
import * as React from 'react';
import { useState } from 'react';

import { DateTimePicker } from '@admin/components/helpers/date_time_picker';
import { Button, Modal, Text } from '@shared/components/bootstrap';
import { client } from '@admin/libraries/apollo';
import { Status as StatusType, useReopenCustomerTicketMutation } from '@admin/schema';

export const ReopenButton: React.FC<{ id: string }> = ({ id }) => {
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [actionRequiredAt, setActionRequiredAt] = useState<DateTime | undefined>();
  const [formError, setFormError] = useState<string>();

  const [reopen, { loading: reopening }] = useReopenCustomerTicketMutation({
    client,
    variables: {
      id,
      actionRequiredAt: String(actionRequiredAt),
    },
  });

  const onReopen = async () => {
    const response = await reopen();
    if (response && response.data) {
      if (response.data.reopenCustomerTicket.status === StatusType.Unprocessable) {
        const errorMessage = response.data.reopenCustomerTicket.error;
        setFormError(`There was a problem reopening this ticket: ${errorMessage}`);
      }
    }
  };

  const onClose = () => {
    setShowConfirm(false);
    setFormError(undefined);
  };

  return (
    <>
      <Button kind="success" onClick={() => setShowConfirm(true)} disabled={reopening}>
        Reopen
      </Button>
      {showConfirm && (
        <Modal centered onClose={onClose}>
          <Modal.Content>
            <Modal.Body>
              <p>Please select an action required date and time for this ticket</p>
              <DateTimePicker dt={actionRequiredAt} onChange={setActionRequiredAt} />
              {formError && (
                <Text tag="p" style="danger">
                  {formError}
                </Text>
              )}
              <Button kind="success" disabled={!actionRequiredAt || reopening} onClick={onReopen}>
                Reopen
              </Button>
              <Button kind="danger" onClick={onClose} disabled={reopening}>
                Cancel
              </Button>
            </Modal.Body>
          </Modal.Content>
        </Modal>
      )}
    </>
  );
};

(function () {
  angular.module('app').service('MonthService', [
    'Months',
    function (Months) {
      this.setMonthSelect = function () {
        const date = new Date();

        const months = [];
        const monthNames = Months.NAMES;
        for (let i = 0; i < 12; i++) {
          months.push({
            value: monthNames[date.getMonth()] + ' ' + date.getFullYear(),
            id: date.getMonth() + 1 + '-' + date.getFullYear(),
          });
          date.setMonth(date.getMonth() - 1);
        }
        return months;
      };

      this.indexbyOneToMonthDeserializer = function (data) {
        const deserializedData = _.map(data, function (value) {
          value.month = Months.NAMES[value.month - 1];
          return value;
        });
        return deserializedData;
      };
    },
  ]);
})();

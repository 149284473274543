import React from 'react';
import { omit } from 'lodash';

import { client } from '@admin/libraries/apollo';
import { useAccountAddressEditQuery } from '@admin/schema';

import { Spinner } from '@admin/components/spinner';
import { Breadcrumb } from '@shared/components/bootstrap/breadcrumb';

import { AccountAddressForm } from './form';

export const Edit: React.FC<{
  accountID: string;
  addressID: string;
  onSave(address: { id: string }): void;
}> = ({ accountID, addressID, onSave }) => {
  const { data } = useAccountAddressEditQuery({ client, variables: { accountID, addressID } });
  if (!data) return <Spinner />;

  const account = data.account;
  const defaults = {
    ...omit(data.account.address, '__typename', 'details'),
    ...omit(data.account.address.details, '__typename'),
  };

  return (
    <>
      <h1 className="page-header text-overflow">
        <Breadcrumb>
          <Breadcrumb.Item>
            <a href="/accounts">Accounts</a>
          </Breadcrumb.Item>{' '}
          <Breadcrumb.Item>
            <a href={`/accounts/${accountID}`}>
              #{account.number} ({account.customer.name})
            </a>
          </Breadcrumb.Item>{' '}
          <Breadcrumb.Item>
            <a href={`/accounts/${accountID}/addresses/${addressID}`}>Address</a>
          </Breadcrumb.Item>{' '}
          <Breadcrumb.Item active>Edit</Breadcrumb.Item>
        </Breadcrumb>
      </h1>

      <AccountAddressForm accountID={accountID} addressID={addressID} defaults={defaults} onSave={onSave} />
    </>
  );
};

import * as React from 'react';

import { FieldFormGroupProps } from '@shared/components/fields/field_form_group';

import { FormikFieldFormGroup } from '@shared/components/fields/formik/formik_field_form_group';
import { FormikHTMLFormControl } from './formik_html_form_control';

type HTMLFormGroupProps = FieldFormGroupProps & {
  name: string;
};

export const FormikHTMLFormGroup: React.FC<HTMLFormGroupProps> = ({ help, label, children, ...input }) => (
  <FormikFieldFormGroup name={input.name} help={help} label={label} id={input.id}>
    <FormikHTMLFormControl {...input} />
  </FormikFieldFormGroup>
);

import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import regions from './regions';
import dispatches from './dispatches';
import checkins from './checkins';

export default combineReducers({
  regions,
  dispatches,
  checkins,
});

export * from './regions';

export const selectDomain = (state) => state.domain;

export const selectRegions = createSelector(selectDomain, (state) => state.regions);
export const selectDispatches = createSelector(selectDomain, (state) => state.dispatches);
export const selectCheckins = createSelector(selectDomain, (state) => state.checkins);

import React from 'react';
import { Form as FormikForm, Formik, FormikErrors, FormikHelpers } from 'formik';

import { client } from '@admin/libraries/apollo';
import {
  Availability__GlobalUnavailabilitiesCreateInput,
  Availability__GlobalUnavailabilityReasonEnum,
  Status,
  useAvailabilityGlobalUnavailabilitiesCreateMutation,
  useRegionsSelectorQuery,
} from '@admin/schema';
import { Alert, AnchorButton, Breadcrumb, Button, Panel } from '@shared/components/bootstrap';
import { FormikInputFormGroup } from '@shared/components/fields/formik/formik_input_form_group';
import { FormikSelectFormGroup } from '@shared/components/fields/formik/formik_select_form_group';
import { FormikMultiselectFormGroup } from '@admin/components/fields/formik/formik_multiselect_form_group';
import { PageHeader } from '@admin/components/helpers/page_header';
import { globalUnavailabilityListURL } from '@admin/config/routes';

import { FormikMultiselectCalendarGroup } from '@admin/components/fields/formik/formik_multiselect_calendar_group';
import { REASON_OPTIONS } from './utils';

type FormInput = Omit<Availability__GlobalUnavailabilitiesCreateInput, 'reason'> & {
  reason: Availability__GlobalUnavailabilityReasonEnum | '';
};

const DEFAULT_VALUES: FormInput = {
  dates: [],
  name: '',
  reason: '',
  regionIDs: [],
  capacityReductionPercentage: 0,
};

const validateInput = ({
  dates,
  name,
  reason,
  regionIDs,
  capacityReductionPercentage,
}: FormInput): FormikErrors<FormInput> => {
  const formErrors: FormikErrors<FormInput> = {};

  if (dates.length === 0) {
    formErrors.dates = 'At least one date is required';
  }

  if (!name) {
    formErrors.name = 'A name is required';
  }

  if (!reason || !Object.values(Availability__GlobalUnavailabilityReasonEnum).some((val) => val === reason)) {
    formErrors.reason = 'A reason is required';
  }

  if (!regionIDs || regionIDs.length === 0) {
    formErrors.regionIDs = 'At least one region is required';
  }

  if (!capacityReductionPercentage || capacityReductionPercentage < -50 || capacityReductionPercentage > 100) {
    formErrors.capacityReductionPercentage = 'The capacity reduction percentage must be between -50 and 100';
  }

  return formErrors;
};

export const GlobalUnavailabilityForm: React.FC = () => {
  const [save, { loading, data }] = useAvailabilityGlobalUnavailabilitiesCreateMutation({ client });
  const { data: regionData } = useRegionsSelectorQuery({ client });
  const regions = regionData?.regions ?? [];

  const onSubmit = async (
    { capacityReductionPercentage, reason, ...input }: FormInput,
    { resetForm }: FormikHelpers<FormInput>,
  ) => {
    const results = await save({
      variables: {
        input: {
          ...input,
          reason: reason! as Availability__GlobalUnavailabilityReasonEnum,
          capacityReductionPercentage: capacityReductionPercentage! / 100.0,
        },
      },
    });

    if (results.data?.result.status === Status.Ok) {
      resetForm();
    }
  };

  return (
    <>
      <PageHeader>
        <Breadcrumb>
          <Breadcrumb.Item>
            <a href={globalUnavailabilityListURL()}>Global Unavailability</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item active>New</Breadcrumb.Item>
        </Breadcrumb>
      </PageHeader>
      <Formik<FormInput>
        initialValues={DEFAULT_VALUES}
        onSubmit={onSubmit}
        validate={validateInput}
        validateOnChange={false}
        validateOnBlur={false}
      >
        <FormikForm>
          <Panel>
            <Panel.Body>
              {data?.result?.error && <Alert style="danger">{data.result.error}</Alert>}
              {data?.result?.status === Status.Ok && (
                <Alert style="success">{`Successfully created new Global Unavailability entries.`}</Alert>
              )}
              <FormikMultiselectCalendarGroup id="dates" name="dates" label="Dates" />
              <FormikMultiselectFormGroup id="regionIDs" name="regionIDs" label="Regions:" entries={regions} />
              <FormikInputFormGroup
                id="capacityReductionPercentage"
                type="number"
                name="capacityReductionPercentage"
                label="Capacity Reduction Percentage:"
              />
              <FormikInputFormGroup id="name" name="name" label="Name:" />
              <FormikSelectFormGroup id="reason" name="reason" label="Reason:">
                <option value=""> - Reason - </option>
                {REASON_OPTIONS.map((reason) => (
                  <option key={reason.id} value={reason.id}>
                    {reason.name}
                  </option>
                ))}
              </FormikSelectFormGroup>
            </Panel.Body>
            <Panel.Footer align="right">
              <AnchorButton disabled={loading} kind="default" href={globalUnavailabilityListURL()}>
                Cancel
              </AnchorButton>
              <Button loading={loading} kind="primary" type="submit">
                Save
              </Button>
            </Panel.Footer>
          </Panel>
        </FormikForm>
      </Formik>
    </>
  );
};

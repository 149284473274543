(function () {
  const app = angular.module('app');

  app.service('ItemGroupModalService', [
    'ngDialog',
    function (ngDialog) {
      this.present = function (group, callback) {
        if (group) {
          ngDialog
            .openConfirm({
              template: 'partials/item_groups/modal.html',
              controller: 'ItemGroupModalController',
              resolve: {
                group: function () {
                  return group;
                },
              },
            })
            .then(function () {
              callback(group);
            });
        }
      };
    },
  ]);
})();

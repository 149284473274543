import React, { useEffect, useState } from 'react';

import { Modal } from '@shared/components/bootstrap';
import { useAccountReferralCodeQuery, useAccountReferralCodeUpdateMutation } from '@admin/schema';
import { client } from '@admin/libraries/apollo';
import { InputFormGroup } from '../fields';

const validateCode = (code: string) => /^[A-Z0-9]{4,}$/.test(code);

export const AccountReferralCode: React.FC<{
  accountID: number;
}> = ({ accountID }) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [error, setError] = useState<null | string>(null);
  const { data } = useAccountReferralCodeQuery({ client: client, variables: { accountID: accountID.toString() } });
  const [update] = useAccountReferralCodeUpdateMutation({ client });
  const [updatedCode, setUpdatedCode] = useState('');
  const referralCode = data?.account.referralCode.value ?? '';
  const canEditReferralCode = data?.currentUser.policy.canEditReferralCode || false;

  useEffect(() => {
    setUpdatedCode(referralCode);
  }, [referralCode]);

  const closeModal = () => {
    setShowModal(false);
  };

  const onSubmit = async () => {
    if (updatedCode === referralCode) {
      closeModal();
      return;
    }

    if (!validateCode(updatedCode)) {
      setError('Please enter a valid code.');
      return;
    }

    try {
      const result = (await update({ variables: { accountID: accountID.toString(), code: updatedCode } })).data;

      if (result?.accountReferralCodeUpdate.referralCode) {
        closeModal();
      } else {
        setError(result?.accountReferralCodeUpdate.error?.message || 'An unknown error occurred');
      }
    } catch (e: any) {
      setError(e.message);
    }
  };

  return (
    <div data-test-id="referral-code-item">
      {showModal && (
        <Modal onClose={closeModal}>
          <Modal.Content>
            <Modal.Header>
              <Modal.Title>Edit Referral Code</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <InputFormGroup
                id="updated_referral_code"
                label="New Code"
                has={error ? 'error' : undefined}
                help={error || 'Code must be at least 4 characters and contain only letters and numbers.'}
                type="text"
                value={updatedCode}
                onChange={(e) => {
                  setError(null);
                  setUpdatedCode(e.currentTarget.value.toLocaleUpperCase());
                }}
              />
            </Modal.Body>
            <Modal.Footer>
              <button type="button" className="btn btn-info" onClick={onSubmit}>
                Update
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => {
                  setUpdatedCode(referralCode);
                  closeModal();
                }}
              >
                Cancel
              </button>
            </Modal.Footer>
          </Modal.Content>
        </Modal>
      )}
      <div>
        <strong>Referral Code:</strong>{' '}
        <span>
          {data?.account.referralCode.value}{' '}
          {canEditReferralCode && (
            <a href="#" className="underline" onClick={() => setShowModal(true)}>
              Edit
            </a>
          )}
        </span>
      </div>
    </div>
  );
};

(function () {
  angular.module('app').filter('dateSuffix', [
    '$filter',
    function ($filter) {
      return function (input) {
        const suffixes = ['th', 'st', 'nd', 'rd'];
        const dtfilter = $filter('date')(input, 'dd');
        const day = parseInt(dtfilter, 10);
        const relevantDigits = day < 30 ? day % 20 : day % 30;
        const suffix = relevantDigits <= 3 ? suffixes[relevantDigits] : suffixes[0];
        return suffix;
      };
    },
  ]);
})();

import angular from 'angular';
import { each, find } from 'lodash';

const app = angular.module('app');

app.controller('VehicleListController', [
  '$scope',
  '$state',
  '$stateParams',
  '$q',
  'Vehicle',
  'Region',
  'Warehouse',
  'VehicleType',
  function ctrl($scope, $state, $stateParams, $q, Vehicle, Region, Warehouse, VehicleType) {
    $scope.filters = {
      warehouse_ids: $stateParams.warehouse_ids,
      status: $stateParams.status,
      vehicle_type_id:
        $stateParams.vehicle_type_id !== undefined ? parseInt($stateParams.vehicle_type_id, 10) : undefined,
    };
    $scope.regions = Region.query({ status: 'all' });
    $scope.warehouses = Warehouse.query({ status: 'all' });
    $scope.vehicleTypes = VehicleType.query();
    $scope.selectedWarehouseIds = $stateParams.warehouse_ids !== undefined ? $stateParams.warehouse_ids.split(',') : [];

    $scope.filter = () => {
      $scope.filters.warehouse_ids =
        $scope.filters.warehouse_ids !== undefined ? $scope.filters.warehouse_ids.toString() : '';
      if ($scope.deferred) {
        $scope.deferred.reject();
      }
      $scope.deferred = $q.defer();
      $scope.deferred.promise.then((data) => {
        const [vehicles, regions, warehouses] = data;
        each(vehicles, (vehicle) => {
          Object.assign(vehicle, {
            region: find(regions, { id: vehicle.region_id }),
            warehouse: find(warehouses, { id: vehicle.warehouse_id }),
          });
        });
        $scope.vehicles = vehicles;
      });
      $scope.vehicles = null;
      $state.go('.', {
        warehouse_ids: $scope.filters.warehouse_ids,
        status: $scope.filters.status,
        vehicle_type_id: $scope.filters.vehicle_type_id,
      });

      $q.all([Vehicle.index($scope.filters).$promise, $scope.regions.$promise, $scope.warehouses.$promise]).then(
        $scope.deferred.resolve,
        $scope.deferred.reject,
        $scope.deferred.notify,
      );
    };

    $scope.onMultiselectWarehouseChange = (selectedOptions) => {
      $scope.filters.warehouse_ids = selectedOptions;
      $scope.filter();
    };

    $scope.filter();
  },
]);

import React from 'react';

import { ItemExceptionFragment } from '@admin/schema';
import { Entry } from './entry';

interface ITableProps {
  itemExceptions: ItemExceptionFragment[];
  timezone: string;
}

const Table = ({ itemExceptions, timezone }: ITableProps) => (
  <div className="table-responsive">
    <table className="table table-striped">
      <thead>
        <tr>
          <th className="col-sm-1 text-left">Creation Date</th>
          <th className="col-sm-2 text-left">Creator</th>
          <th className="col-sm-2 text-left">Reason</th>
          <th className="col-sm-3 text-left">Explanation</th>
          <th className="col-sm-1 text-left">Deletion Date</th>
          <th className="col-sm-3 text-left">Image</th>
        </tr>
      </thead>
      <tbody>
        {itemExceptions.map((itemException, key) => (
          <Entry itemException={itemException} key={key} timezone={timezone} />
        ))}
      </tbody>
    </table>
  </div>
);

export { Table };

import { schema } from 'normalizr';

export const Checkin = new schema.Entity(
  'checkins',
  {},
  {
    processStrategy: (checkin) => {
      const checkinResource = { ...checkin };
      checkinResource.dispatch = checkinResource.dispatch || { id: checkinResource.dispatch_id };
      delete checkinResource.dispatch_id;
      checkinResource.resource = checkinResource.resource || {
        id: checkinResource.resource_id,
        type: checkinResource.resource_type,
      };
      checkinResource.resource.type = checkinResource.resource.type || checkinResource.resource_type;
      delete checkinResource.resource_type;
      delete checkinResource.resource_id;
      return checkinResource;
    },
  },
);

export const Region = new schema.Entity('regions');
export const Order = new schema.Entity('orders');
export const Dispatch = new schema.Entity('dispatches');
export const Address = new schema.Entity('addresses');
export const User = new schema.Entity('users');
export const Account = new schema.Entity('accounts');
export const Depot = new schema.Entity('depots');
export const Vehicle = new schema.Entity('vehicles');
export const Warehouse = new schema.Entity('warehouse');

const CheckinResource = new schema.Union(
  {
    User,
    Vehicle,
  },
  'type',
);

Checkin.define({
  dispatch: Dispatch,
  resource: CheckinResource,
});

Dispatch.define({
  region: Region,
  orders: [Order],
  depot: Depot,
  warehouse: Warehouse,
  vehicles: [Vehicle],
  assignments: [
    {
      user: User,
    },
  ],
});

Order.define({
  region: Region,
  dispatch: Dispatch,
  user: User,
  address: Address,
  account: Account,
});

Warehouse.define({
  address: Address,
});

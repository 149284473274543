import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers } from '@fortawesome/free-solid-svg-icons';

import React, { useEffect, useRef, useState } from 'react';

import { client } from '@admin/libraries/apollo';

import { CreateModal } from '@admin/components/accounts/containers/create_modal';
import { Col, Panel, Row, Table, AnchorButton as Link, Button } from '@shared/components/bootstrap';
import { Spinner } from '@admin/components/spinner';

import { usePaginatedAccountsQuery, Account__State } from '@admin/schema';

import { useDebounce } from '@shared/hooks';
import { Pagination } from '@shared/components/pagination';

import {
  ACCOUNT_STATES,
  ACCOUNT_STATE_TO_NAME,
  AccountStateParam,
  ACCOUNT_STATE_FROM_PARAM,
  ACCOUNT_STATE_TO_PARAM,
} from '@admin/constants/account_state';
import { InputFormGroup, SelectFormGroup } from '@admin/components/fields';
import { State } from './state';

const DEFAULT_PAGE = 1;

const QueryFormGroup: React.FC<{
  query?: string;
  onChange(query?: string): void;
}> = ({ query, onChange }) => (
  <InputFormGroup
    label="Search:"
    type="text"
    value={query ?? ''}
    onChange={(event) => onChange(event.target.value || undefined)}
    placeholder="Search by name, email, or phone"
  />
);

const StateFormGroup: React.FC<{
  state?: Account__State;
  onChange(state?: Account__State): void;
}> = ({ state, onChange }) => (
  <SelectFormGroup
    label="State:"
    onChange={(event) => onChange((event.target.value as Account__State) || undefined)}
    value={state ?? ''}
  >
    <option value=""> - State - </option>
    {ACCOUNT_STATES.map((value, index) => (
      <option value={value} key={index}>
        {ACCOUNT_STATE_TO_NAME[value]}
      </option>
    ))}
  </SelectFormGroup>
);

type Filters = {
  page?: number;
  query?: string;
  state?: AccountStateParam;
};

export const AccountsTable: React.FC<{
  filters: Filters;
  onFilter(filters: Filters): void;
}> = ({ filters, onFilter }) => {
  const onFilterRef = useRef(onFilter);
  const [page, setPage] = useState(filters.page ? filters.page : DEFAULT_PAGE);
  const [state, setState] = useState(filters.state && ACCOUNT_STATE_FROM_PARAM[filters.state]);
  const [query, setQuery] = useState(filters.query);
  const [showCreateAccountModal, setShowCreateAccountModal] = useState(false);
  const { data, loading } = usePaginatedAccountsQuery({
    client,
    variables: {
      page,
      state,
      query: useDebounce(query),
    },
  });

  useEffect(() => {
    onFilterRef.current = onFilter;
  }, [onFilter]);

  useEffect(() => {
    onFilterRef.current({
      page: page !== DEFAULT_PAGE ? page : undefined,
      state: state && ACCOUNT_STATE_TO_PARAM[state],
      query,
    });
  }, [state, query, page]);

  return (
    <>
      <Panel>
        <Panel.Body>
          <Row>
            <Col sm={8}>
              <QueryFormGroup query={query} onChange={setQuery} />
            </Col>
            <Col sm={4}>
              <StateFormGroup state={state} onChange={setState} />
            </Col>
          </Row>
        </Panel.Body>
      </Panel>

      <Panel>
        <Panel.Body>
          <Table responsive striped>
            <thead>
              <tr>
                <th className="col-sm-4 text-left">Account #</th>
                <th className="col-zero" />
                <th className="col-sm-4 text-left">Name</th>
                <th className="col-sm-4 text-left">Status</th>
                <th className="col-zero" />
              </tr>
            </thead>
            <tbody>
              {data?.paginated.results.map((account) => (
                <tr key={account.id}>
                  <td className="col-sm-4 text-left">{account.number}</td>
                  <td className="col-zero">{account.customers.length > 1 && <FontAwesomeIcon icon={faUsers} />}</td>
                  <td className="col-sm-4 text-left">{account.customer.name}</td>
                  <td className="col-sm-4 text-left">
                    <State account={account} />
                  </td>
                  <td className="col-zero">
                    <Link kind="primary" href={`/accounts/${account.id}`}>
                      View
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {loading && <Spinner />}
          {data && <Pagination pagination={data.paginated.pagination} onPage={setPage} />}
        </Panel.Body>
        <Panel.Footer align="right">
          <Button kind="primary" onClick={() => setShowCreateAccountModal(true)}>
            Create Containers Account
          </Button>
        </Panel.Footer>
      </Panel>
      {showCreateAccountModal && <CreateModal onClose={() => setShowCreateAccountModal(false)} />}
    </>
  );
};

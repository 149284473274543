import styled from '@emotion/styled';
import { Form as FormikForm, Formik, FormikErrors, FormikHelpers } from 'formik';
import { DateTime } from 'luxon';
import React from 'react';

import {
  Availability__RescheduleOffersCreateInput,
  Coupon__Subject,
  Status,
  useAvailabilityRescheduleOffersCreateMutation,
  useCouponsQuery,
  useRegionsSelectorQuery,
} from '@admin/schema';
import { client } from '@admin/libraries/apollo';
import { PageHeader } from '@admin/components/helpers/page_header';
import { Alert, AnchorButton, Breadcrumb, Button, Panel } from '@shared/components/bootstrap';
import { availabilityRescheduleOffersListURL } from '@admin/config/routes';
import { FormikMultiselectFormGroup } from '@admin/components/fields/formik/formik_multiselect_form_group';
import { CLASSIFICATION_OPTIONS } from '@admin/components/availability/util';
import { FormikInputFormGroup } from '@shared/components/fields/formik/formik_input_form_group';
import { FormikMultiselectCalendarGroup } from '@admin/components/fields/formik/formik_multiselect_calendar_group';
import { FormikSelectFormGroup } from '@shared/components/fields/formik/formik_select_form_group';
import { FormikDateTimePicker } from '@admin/components/fields/formik/formik_date_time_picker';

const Container = styled.div`
  .form-group {
    margin-bottom: 24px;
  }
`;

type FormInput = Omit<
  Availability__RescheduleOffersCreateInput,
  'orderMinimumMoverMinutes' | 'originTargetAvailabilityThreshold'
> & {
  orderMinimumMoverMinutes?: number;
  originTargetAvailabilityThreshold?: number;
};

const DEFAULT_VALUES: FormInput = {
  originDate: '',
  regionIDs: [],
  orderClassifications: [],
  targetDates: [],
  couponID: '',
  startAt: '',
  expireAt: '',
};

const validateInput = ({
  originDate,
  regionIDs,
  orderClassifications,
  targetDates,
  couponID,
  orderMinimumMoverMinutes,
  originTargetAvailabilityThreshold,
  startAt,
  expireAt,
}: FormInput): FormikErrors<FormInput> => {
  const formErrors: FormikErrors<FormInput> = {};

  const origin = DateTime.fromISO(originDate);
  if (!origin.isValid || origin <= DateTime.local()) {
    formErrors.originDate = 'A valid origin date in the future is required';
  }

  if (!regionIDs || regionIDs.length === 0) {
    formErrors.regionIDs = 'At least one region is required';
  }

  if (!targetDates || targetDates.length === 0) {
    formErrors.targetDates = 'At least one target date is required';
  }

  if (!orderClassifications || orderClassifications.length === 0) {
    formErrors.orderClassifications = 'At least one order classification is required';
  }

  if (!couponID) {
    formErrors.couponID = 'A coupon is required';
  }

  if (typeof orderMinimumMoverMinutes === 'undefined') {
    formErrors.orderMinimumMoverMinutes = 'Minimum mover minutes is required';
  }

  if (typeof originTargetAvailabilityThreshold === 'undefined') {
    formErrors.originTargetAvailabilityThreshold = 'Target availability % is required';
  }

  const start = DateTime.fromISO(startAt);
  if (!start.isValid) {
    formErrors.startAt = 'A valid start date is required';
  }

  const expire = DateTime.fromISO(expireAt);
  if (!expire.isValid || expire <= DateTime.local()) {
    formErrors.expireAt = 'A valid expiration in the future is required';
  }

  return formErrors;
};

export const RescheduleOfferForm: React.FC = () => {
  const [save, { loading, data }] = useAvailabilityRescheduleOffersCreateMutation({ client });
  const { data: regionData } = useRegionsSelectorQuery({ client });
  const { data: couponData } = useCouponsQuery({
    client,
    variables: { filters: { active: true, subject: Coupon__Subject.Order } },
  });
  const regions = regionData?.regions ?? [];
  const coupons = couponData?.result ?? [];

  const onSubmit = async (
    { orderMinimumMoverMinutes, originTargetAvailabilityThreshold, ...input }: FormInput,
    { resetForm }: FormikHelpers<FormInput>,
  ) => {
    const results = await save({
      variables: {
        input: {
          ...input,
          orderMinimumMoverMinutes: orderMinimumMoverMinutes!,
          originTargetAvailabilityThreshold: originTargetAvailabilityThreshold!,
        },
      },
    });

    if (results.data?.result.status === Status.Ok) {
      resetForm();
    }
  };

  return (
    <Container>
      <PageHeader>
        <Breadcrumb>
          <Breadcrumb.Item>
            <a href={availabilityRescheduleOffersListURL()}>Availability Reschedule Offers</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item active>New</Breadcrumb.Item>
        </Breadcrumb>
      </PageHeader>
      <Formik<FormInput>
        initialValues={DEFAULT_VALUES}
        onSubmit={onSubmit}
        validate={validateInput}
        validateOnChange={false}
        validateOnBlur={false}
      >
        <FormikForm>
          <Panel>
            <Panel.Body>
              {data?.result?.error && <Alert style="danger">{data.result.error}</Alert>}
              {data?.result?.status === Status.Ok && (
                <Alert style="success">Successfully created new Reschedule Offers.</Alert>
              )}
              <FormikMultiselectFormGroup id="regionIDs" name="regionIDs" label="Regions:" entries={regions} />
              <FormikMultiselectFormGroup
                id="orderClassifications"
                name="orderClassifications"
                label="Order Classifications:"
                entries={CLASSIFICATION_OPTIONS}
              />
              <FormikInputFormGroup id="originDate" type="date" name="originDate" label="Origin Date:" />
              <FormikMultiselectCalendarGroup id="targetDates" name="targetDates" label="Target Dates" />
              <FormikSelectFormGroup id="couponID" name="couponID" label="Coupon:">
                <option value=""> - Coupon - </option>
                {coupons.map((coupon) => (
                  <option key={coupon.id} value={coupon.id}>
                    {coupon.name}
                  </option>
                ))}
              </FormikSelectFormGroup>
              <FormikInputFormGroup
                id="orderMinimumMoverMinutes"
                type="number"
                name="orderMinimumMoverMinutes"
                label="Minimum Mover Minutes:"
                min={0}
              />
              <FormikInputFormGroup
                id="originTargetAvailabilityThreshold"
                type="number"
                name="originTargetAvailabilityThreshold"
                label="Target Availability (%):"
                min={0}
                max={100}
              />
              <FormikDateTimePicker id="startAt" name="startAt" label="Start At" />
              <FormikDateTimePicker id="expireAt" name="expireAt" label="Expire At" />
            </Panel.Body>
          </Panel>
          <Panel.Footer align="right">
            <AnchorButton disabled={loading} kind="default" href={availabilityRescheduleOffersListURL()}>
              Cancel
            </AnchorButton>
            <Button loading={loading} kind="primary" type="submit">
              Save
            </Button>
          </Panel.Footer>
        </FormikForm>
      </Formik>
    </Container>
  );
};

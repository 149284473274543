import React from 'react';
import { Form as FormikForm, Formik, FormikErrors } from 'formik';

import { client } from '@admin/libraries/apollo';
import {
  Availability__BlockReasonEnum,
  Availability__FacilityCuftLimitsCreateInput,
  Maybe,
  Status,
  useAvailabilityFacilityCuftLimitsCreateMutation,
  useWarehousesSelectorQuery,
} from '@admin/schema';
import { Alert, AnchorButton, Breadcrumb, Button, Panel } from '@shared/components/bootstrap';
import { FormikInputFormGroup } from '@shared/components/fields/formik/formik_input_form_group';
import { FormikMultiselectFormGroup } from '@admin/components/fields/formik/formik_multiselect_form_group';
import { PageHeader } from '@admin/components/helpers/page_header';
import { warehouseCapacityListURL } from '@admin/config/routes';
import { FormikSelectFormGroup } from '@shared/components/fields/formik/formik_select_form_group';
import { FormikMultiselectCalendarGroup } from '@admin/components/fields/formik/formik_multiselect_calendar_group';
import { BLOCK_REASON_OPTIONS } from '../util';

type CreateInput = Pick<Availability__FacilityCuftLimitsCreateInput, 'dates' | 'warehouseIDs' | 'reason'> & {
  maximumInboundCuft: Maybe<number> | '';
  maximumOutboundCuft: Maybe<number> | '';
  maximumInboundOutboundCuft: Maybe<number> | '';
  maximumInboundTransferCuft: Maybe<number> | '';
  maximumOutboundFacilityCuft: Maybe<number> | '';
  maximumOutboundDisposalCuft: Maybe<number> | '';
};

const DEFAULT_VALUES: CreateInput = {
  dates: [],
  warehouseIDs: [],
  maximumInboundCuft: '',
  maximumOutboundCuft: '',
  maximumInboundOutboundCuft: '',
  maximumInboundTransferCuft: '',
  maximumOutboundFacilityCuft: '',
  maximumOutboundDisposalCuft: '',
  reason: '',
};

const validateInput = ({
  dates,
  warehouseIDs,
  maximumInboundCuft,
  maximumInboundTransferCuft,
  maximumInboundOutboundCuft,
  maximumOutboundFacilityCuft,
  maximumOutboundCuft,
  maximumOutboundDisposalCuft,
  reason,
}: CreateInput): FormikErrors<CreateInput> => {
  const formErrors: FormikErrors<CreateInput> = {};

  if (dates.length === 0) {
    formErrors.dates = 'At least one date is required';
  }

  if (!warehouseIDs || warehouseIDs.length === 0) {
    formErrors.warehouseIDs = 'At least one warehouse is required';
  }

  if (maximumInboundCuft && maximumInboundCuft < 0) {
    formErrors.maximumInboundCuft = 'The maximum inbound cuft must be 0 or greater';
  }

  if (maximumInboundTransferCuft && maximumInboundTransferCuft < 0) {
    formErrors.maximumInboundTransferCuft = 'The maximum inbound transfer cuft must be 0 or greater';
  }

  if (maximumInboundOutboundCuft && maximumInboundOutboundCuft < 0) {
    formErrors.maximumInboundOutboundCuft = 'The maximum inbound outbound cuft must be 0 or greater';
  }

  if (maximumOutboundFacilityCuft && maximumOutboundFacilityCuft < 0) {
    formErrors.maximumOutboundFacilityCuft = 'The maximum outbound facility cuft must be 0 or greater';
  }

  if (maximumOutboundCuft && maximumOutboundCuft < 0) {
    formErrors.maximumOutboundCuft = 'The maximum outbound cuft must be 0 or greater';
  }

  if (maximumOutboundDisposalCuft && maximumOutboundDisposalCuft < 0) {
    formErrors.maximumOutboundDisposalCuft = 'The maximum outbound disposal cuft must be 0 or greater';
  }

  if (!reason) {
    formErrors.reason = 'A reason must be provided';
  }

  return formErrors;
};

const processCuftValue = (value: Maybe<number> | '') => (typeof value === 'number' ? value : null);

export const WarehouseCapacityForm: React.FC = () => {
  const [save, { loading, data }] = useAvailabilityFacilityCuftLimitsCreateMutation({ client });
  const { data: warehousesData } = useWarehousesSelectorQuery({ client, variables: { filters: { active: true } } });
  const warehouses = warehousesData?.warehouses ?? [];

  const onSubmit = async ({
    maximumInboundCuft,
    maximumOutboundCuft,
    maximumInboundTransferCuft,
    maximumInboundOutboundCuft,
    maximumOutboundFacilityCuft,
    maximumOutboundDisposalCuft,
    ...input
  }: CreateInput) => {
    const results = await save({
      variables: {
        input: {
          ...input,
          maximumInboundCuft: processCuftValue(maximumInboundCuft),
          maximumOutboundCuft: processCuftValue(maximumOutboundCuft),
          maximumInboundTransferCuft: processCuftValue(maximumInboundTransferCuft),
          maximumInboundOutboundCuft: processCuftValue(maximumInboundOutboundCuft),
          maximumOutboundFacilityCuft: processCuftValue(maximumOutboundFacilityCuft),
          maximumOutboundDisposalCuft: processCuftValue(maximumOutboundDisposalCuft),
        },
      },
    });

    if (results.data?.result.status === Status.Ok) {
      window.location.href = warehouseCapacityListURL();
    }
  };

  return (
    <>
      <PageHeader>
        <Breadcrumb>
          <Breadcrumb.Item>
            <a href={warehouseCapacityListURL()}>Warehouse Capacity</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item active>New One-Off</Breadcrumb.Item>
        </Breadcrumb>
      </PageHeader>
      <Formik<CreateInput>
        initialValues={DEFAULT_VALUES}
        onSubmit={onSubmit}
        validate={validateInput}
        validateOnChange={false}
        validateOnBlur={false}
      >
        <FormikForm>
          <Panel>
            <Panel.Body>
              {data?.result?.error && <Alert style="danger">{data.result.error}</Alert>}
              {data?.result?.status === Status.Ok && (
                <Alert style="success">{'Successfully created new Facility Cuft Limit entries.'}</Alert>
              )}
              <FormikMultiselectCalendarGroup id="dates" name="dates" label="Dates:" />
              <FormikMultiselectFormGroup
                id="warehouseIDs"
                name="warehouseIDs"
                label="Warehouses:"
                entries={warehouses}
              />
              <FormikSelectFormGroup id="reason" name="reason" label="Reason:">
                <option value=""> - Select Reason - </option>
                {BLOCK_REASON_OPTIONS.filter(
                  (reason) => reason.id !== Availability__BlockReasonEnum.WeeklySchedule,
                ).map((reason) => (
                  <option key={reason.id} value={reason.name}>
                    {reason.name}
                  </option>
                ))}
              </FormikSelectFormGroup>
              <FormikInputFormGroup
                id="maximumInboundCuft"
                type="number"
                name="maximumInboundCuft"
                label="Pickups Max CuFt:"
              />
              <FormikInputFormGroup
                id="maximumOutboundCuft"
                type="number"
                name="maximumOutboundCuft"
                label="Returns Max CuFt:"
              />
              <FormikInputFormGroup
                id="maximumInboundOutboundCuft"
                type="number"
                name="maximumInboundOutboundCuft"
                label="Total Max CuFt:"
              />
              <FormikInputFormGroup
                id="maximumInboundTransferCuft"
                type="number"
                name="maximumInboundTransferCuft"
                label="Transfers Max CuFt:"
              />
              <FormikInputFormGroup
                id="maximumOutboundFacilityCuft"
                type="number"
                name="maximumOutboundFacilityCuft"
                label="Facility Pickups Max CuFt:"
              />
              <FormikInputFormGroup
                id="maximumOutboundDisposalCuft"
                type="number"
                name="maximumOutboundDisposalCuft"
                label="Disposals Max CuFt:"
              />
            </Panel.Body>
            <Panel.Footer align="right">
              <AnchorButton disabled={loading} kind="default" href={warehouseCapacityListURL()}>
                Cancel
              </AnchorButton>
              <Button loading={loading} kind="primary" type="submit">
                Save
              </Button>
            </Panel.Footer>
          </Panel>
        </FormikForm>
      </Formik>
    </>
  );
};

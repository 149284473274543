import * as React from 'react';

import { Currency } from '@shared/components/helpers';

import { PlanKindEnum, PricingGroupPricingEntryFragment } from '@admin/schema';

export const Total: React.FC<{
  storage?: PricingGroupPricingEntryFragment;
  protection?: PricingGroupPricingEntryFragment;
  quantity?: number;
}> = ({ storage, protection, quantity }) => {
  let total = 0;
  const custom = storage && storage.pricing.plan.kind === PlanKindEnum.Custom;
  if (storage) {
    total += custom ? (quantity || 0) * storage.pricing.amount : storage.pricing.amount;
  }
  if (protection) {
    total += protection.pricing.amount;
  }
  return (
    <div className="fees">
      <div className="form-group">
        <div className="monthly-fees">
          <div className="col-sm-7 control-label">
            <strong>Storage and Protection Monthly Total:</strong>
          </div>
          <div className="col-sm-5 form-total">
            <strong className="text-success">
              <Currency value={total} />
            </strong>
          </div>
        </div>
      </div>
    </div>
  );
};

import React, { FC, useState } from 'react';

import { Button } from '@shared/components/bootstrap';
import { SendGridStatusModal } from './send_grid_status_modal';

export const SendGridStatusButton: FC<{
  email?: string;
}> = ({ email }) => {
  const [visible, setVisible] = useState(false);
  const onToggle = () => setVisible(!visible);

  return (
    <>
      <Button kind="info" type="button" onClick={onToggle} disabled={!email}>
        Status of '{email ?? '-'}'
      </Button>
      {email && visible && <SendGridStatusModal email={email} onClose={onToggle} />}
    </>
  );
};

/** @jsx jsx */
import React from 'react';
import { css, jsx, SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { DateTime, Duration } from 'luxon';
import { zip } from 'lodash';

import { Maybe } from '@admin/schema';
import { OrderTypeEnum } from '@admin/schema';
import { IDispatchOrder } from '@admin/types';
import {
  assessDuration,
  durationValueInMinutes,
  DURATION_HEADER_EVENTS,
  DURATION_HEADERS,
  DurationHeader,
  durationCopy,
  getIntervals,
  getBreakIntervals,
  overlap,
  overlapCopy,
  combine,
} from './duration_utils';

import { formatNumber, orderCuftHeaders, getCuftMetricsForPickup, getCuftMetricsForReturn } from './cuft_utils';

import { getColorforEvent } from '../../dispatch/order_events/utils';
import { concatMetrics, getAssessments } from './cuft_metrics';

const Container = styled.div`
  position: relative;
  width: 100%;
  z-index: 5;
  background-color: #f6f8fa;
  border-bottom: 1px solid gray;

  &:last-child {
    border-bottom: none;
  }

  th,
  td {
    height: 30px;
  }
`;

const AssessedValues: React.FC<{
  values: Array<string | null | undefined>;
  assessments: Array<Maybe<SerializedStyles>>;
}> = ({ values, assessments }) => (
  <React.Fragment>
    {assessments.map((assessmentStyle, i) => (
      <td key={`${values[i] || 'N/A'}${i}`} css={assessmentStyle}>
        {values[i] || 'N/A'}
      </td>
    ))}
  </React.Fragment>
);

const getColor = (header: DurationHeader) => {
  const name = DURATION_HEADER_EVENTS[header];
  return getColorforEvent(name)(0.5);
};

const augmentWithBreakDuration = (eventDurations: string[], breakOverlaps: Array<string | undefined>) =>
  zip(eventDurations, breakOverlaps).map(combine);

const subtractOverlap = ([duration, overlapDuration]: [Maybe<Duration> | undefined, Maybe<Duration> | undefined]) => {
  if (overlapDuration) {
    return duration?.minus(overlapDuration) || null;
  }

  return duration || null;
};

type OrderDetailProps = Pick<
  IDispatchOrder,
  'orderEventsExcludingNonLeadAssignments' | 'simulatedOrderEvents' | 'services'
> & {
  next?: DateTime;
  breakEvents: IDispatchOrder['simulatedOrderEvents'];
  simulatedBreakEvents: IDispatchOrder['simulatedOrderEvents'];
};
export const OrderDetail: React.FC<OrderDetailProps> = ({
  orderEventsExcludingNonLeadAssignments,
  simulatedOrderEvents,
  services,
  next,
  breakEvents,
  simulatedBreakEvents,
}) => {
  const simulatedEventIntervals = getIntervals(simulatedOrderEvents, next);
  const eventIntervals = getIntervals(orderEventsExcludingNonLeadAssignments, next);

  const simulatedDurations = simulatedEventIntervals.map((interval) => interval?.toDuration() || null);
  const actualDurations = eventIntervals.map((interval) => interval?.toDuration() || null);

  const simulatedBreakIntervals = getBreakIntervals(simulatedBreakEvents, next);
  const breakIntervals = getBreakIntervals(breakEvents, next);

  const simulatedOverlap = simulatedEventIntervals.map((interval) => {
    const overlapDuration = simulatedBreakIntervals
      .map((breakInterval) => overlap(interval, breakInterval))
      .reduce((acc, elem) => acc.plus(elem), Duration.fromObject({ minutes: 0 }));

    return overlapDuration.valueOf() > 0 ? overlapDuration : null;
  });

  const actualOverlap = eventIntervals.map((interval) => {
    const overlapDuration = breakIntervals
      .map((breakInterval) => overlap(interval, breakInterval))
      .reduce((acc, elem) => acc.plus(elem), Duration.fromObject({ minutes: 0 }));

    return overlapDuration.valueOf() > 0 ? overlapDuration : null;
  });

  const simulatedDurationsWithoutBreak = zip(simulatedDurations, simulatedOverlap).map(subtractOverlap);
  const actualDurationsWithoutBreak = zip(actualDurations, simulatedOverlap).map(subtractOverlap);

  const simulatedDurationsWithoutBreakValues = simulatedDurationsWithoutBreak.map(durationValueInMinutes);
  const actualDurationsWithoutBreakValues = actualDurationsWithoutBreak.map(durationValueInMinutes);

  const simulatedDurationAssessments = assessDuration(
    simulatedDurationsWithoutBreakValues,
    actualDurationsWithoutBreakValues,
  );
  const actualDurationAssessments = assessDuration(
    actualDurationsWithoutBreakValues,
    simulatedDurationsWithoutBreakValues,
  );

  const simulatedDurationCopies = augmentWithBreakDuration(
    simulatedDurationsWithoutBreak.map(durationCopy),
    simulatedOverlap.map(overlapCopy),
  );

  const actualDurationCopies = augmentWithBreakDuration(
    actualDurationsWithoutBreak.map(durationCopy),
    actualOverlap.map(overlapCopy),
  );

  const pickupServices = services.filter(({ type }) => type === OrderTypeEnum.Pickup);
  const returnServices = services.filter(({ type }) => type === OrderTypeEnum.Return);

  const numPickups = pickupServices.length;
  const numReturns = returnServices.length;
  const pickupHeaders = orderCuftHeaders('Pickup', numPickups);
  const returnHeaders = orderCuftHeaders('Return', numReturns);

  const pickupCuftMetrics = concatMetrics(pickupServices.map(getCuftMetricsForPickup));
  const returnCuftMetrics = concatMetrics(returnServices.map(getCuftMetricsForReturn));

  const {
    estimatedCuft: estimatedPickupCuft,
    estimatedCuftAssessments: estimatedPickupCuftAssessments,
    actualCuft: pickupCuft,
    actualCuftAssessments: pickupCuftAssessments,
  } = getAssessments(pickupCuftMetrics);

  const {
    estimatedCuft: estimatedReturnCuft,
    estimatedCuftAssessments: estimatedReturnCuftAssessments,
    actualCuft: returnCuft,
    actualCuftAssessments: returnCuftAssessments,
  } = getAssessments(returnCuftMetrics);

  return (
    <Container>
      <table className="table">
        <thead>
          <tr>
            <th>Metric</th>
            {pickupHeaders.map((copy) => (
              <th
                css={css`
                  border-top: 0.5em solid #daeeee !important;
                `}
                key={copy}
              >
                {copy}
              </th>
            ))}

            {returnHeaders.map((copy) => (
              <th
                css={css`
                  border-top: 0.5em solid #daeeee !important;
                `}
                key={copy}
              >
                {copy}
              </th>
            ))}

            {DURATION_HEADERS.map((header) => (
              <th
                key={header}
                css={css`
                  border-top: 0.5em solid ${getColor(header)} !important;
                `}
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>Expected</td>
            <AssessedValues
              values={estimatedPickupCuft.map(formatNumber)}
              assessments={estimatedPickupCuftAssessments}
            />
            <AssessedValues
              values={estimatedReturnCuft.map(formatNumber)}
              assessments={estimatedReturnCuftAssessments}
            />
            <AssessedValues values={simulatedDurationCopies} assessments={simulatedDurationAssessments} />
          </tr>

          <tr>
            <td>Actual</td>
            <AssessedValues values={pickupCuft.map(formatNumber)} assessments={pickupCuftAssessments} />
            <AssessedValues values={returnCuft.map(formatNumber)} assessments={returnCuftAssessments} />
            <AssessedValues values={actualDurationCopies} assessments={actualDurationAssessments} />
          </tr>
        </tbody>
      </table>
    </Container>
  );
};

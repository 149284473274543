import React, { forwardRef } from 'react';
import { useField } from 'formik';

import { FieldFormGroupProps } from '@shared/components/fields/field_form_group';

import { InputFormControlType, InputFormControlProps } from '@shared/components/fields/input_form_control';
import { FormikFieldFormGroup } from '@shared/components/fields/formik/formik_field_form_group';

type InputFormGroupType = InputFormControlType;
type InputFormGroupProps = InputFormControlProps & FieldFormGroupProps & { name: string };

export const FormikCheckboxFormGroup = forwardRef<InputFormGroupType, InputFormGroupProps>(
  ({ help, label, ...input }, ref) => {
    const [field, { value }, { setValue }] = useField(input.name);
    return (
      <FormikFieldFormGroup name={input.name} help={help} label={label} id={input.id}>
        <div>
          <input
            ref={ref}
            {...field}
            {...input}
            type="checkbox"
            onChange={(e) => setValue(e.target.checked)}
            checked={value}
          />
        </div>
      </FormikFieldFormGroup>
    );
  },
);

import React from 'react';

import { client } from '@admin/libraries/apollo';

import { useQA } from '@admin/hooks/use_qa';
import { Order, OrderStateEnum, OrderTypeEnum, useOrderPickupCompleteMutation } from '@admin/schema';

import { Button } from '@shared/components/bootstrap';

export const OrderPickupCompleteButton: React.FC<{
  order: Pick<Order, 'type' | 'id' | 'state'>;
}> = ({ order }) => {
  const qa = useQA();
  const [execute, { loading }] = useOrderPickupCompleteMutation({
    client,
    variables: { orderID: order.id },
  });

  const completable = order.state === OrderStateEnum.Packed && order.type === OrderTypeEnum.Pickup;
  if (!qa || !completable) return null;

  const complete = async () => {
    await execute();
    location.reload();
  };

  return (
    <Button loading={loading} kind="warning" onClick={complete}>
      Auto-Complete Pickup
    </Button>
  );
};

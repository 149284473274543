import * as React from 'react';

import { useSelfStoragePartnersQuery, SelfStorage__Partner__Company } from '@admin/schema';
import { client } from '@admin/libraries/apollo';

export const SingleSelector: React.FC<{
  selectedCompany?: SelfStorage__Partner__Company;
  onChange(selectedCompany: SelfStorage__Partner__Company): void;
}> = ({ onChange, selectedCompany }) => {
  const { data } = useSelfStoragePartnersQuery({ client });
  const partners = data?.partners ?? [];

  return (
    <select
      placeholder="Partners"
      className="form-control"
      value={selectedCompany}
      onChange={(event) => onChange(event.target.value as SelfStorage__Partner__Company)}
    >
      <option value="">-</option>
      {partners.map((partner, index) => {
        if (!partner.company) {
          return null;
        }
        return (
          <option value={partner.company} key={index}>
            {partner.name}
          </option>
        );
      })}
    </select>
  );
};

import React, { useState } from 'react';

import { SelectWithOther } from '@admin/components/helpers/select_with_other';
import { client } from '@admin/libraries/apollo';
import { Button, Modal, Text } from '@shared/components/bootstrap';
import { Status, useCloseClaimMutation } from '@admin/schema';

const EXPIRED_REASONS = ['Customer unresponsive', 'Customer canceled', 'Refused to sign offer'];

interface ICloseClaimModalProps {
  claimID: string;
}

export const CloseClaimButton: React.FC<ICloseClaimModalProps> = ({ claimID }) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [reason, setReason] = useState<string>('');
  const [error, setError] = useState<string | undefined>(undefined);

  const [closeClaim, { loading }] = useCloseClaimMutation({ client });

  const closeModal = () => setShowModal(false);

  const onCloseClaim = async () => {
    const response = await closeClaim({ variables: { claimID, reason } });
    if (response.data?.closeClaim.status === Status.Unprocessable) {
      setError(response.data.closeClaim.error ?? undefined);
    }
  };

  return (
    <>
      <div className="text-right">
        <Button kind="info" onClick={() => setShowModal(true)}>
          Close Claim
        </Button>
      </div>
      {showModal && (
        <Modal onClose={closeModal}>
          <Modal.Content>
            <Modal.Header>
              <Modal.Title>Close Claim</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <SelectWithOther
                options={EXPIRED_REASONS}
                option={reason}
                onSelect={setReason}
                placeholder="Select a Reason"
              />
            </Modal.Body>
            <Modal.Footer>
              {error && (
                <Text tag="p" style="danger">
                  {error}
                </Text>
              )}
              <Button kind="default" onClick={closeModal}>
                Cancel
              </Button>{' '}
              <Button kind="info" disabled={!reason} loading={loading} onClick={onCloseClaim}>
                Close Claim
              </Button>
            </Modal.Footer>
          </Modal.Content>
        </Modal>
      )}
    </>
  );
};

import * as React from 'react';

import { HistoryResourceNoteFragment } from '@admin/schema';

import { Timestamp } from '@admin/components/timestamp';

export const Note: React.FC<{
  resource: HistoryResourceNoteFragment;
  timestamp: string;
  timezone?: string;
}> = ({ resource, timestamp, timezone }) => (
  <tr>
    <td className="col-nowrap col-md-2 text-left" colSpan={2}>
      <Timestamp value={timestamp} timezone={timezone} />
    </td>
    <td className="col-nowrap col-md-2 text-left" colSpan={2}>
      <strong>{resource.user.name}</strong>
      <span>noted:</span>
    </td>
    <td className="col-md-8 text-left" colSpan={8}>
      <span>{resource.body}</span>
    </td>
  </tr>
);

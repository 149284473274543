import { client } from '@admin/libraries/apollo';
import { Order, useMultiDayContextQuery } from '@admin/schema';
import styled from '@emotion/styled';
import React from 'react';

const Link = styled.a`
  text-decoration: underline;
  :hover {
    cursor: pointer;
  }
`;

export const MultiDayContext: React.FC<{
  orderID: string;
  goToOrder(order: Pick<Order, 'id' | 'accountID'>): void;
}> = ({ orderID, goToOrder }) => {
  const { data } = useMultiDayContextQuery({ client, variables: { orderID } });
  const context = data?.order.multiDayContext;
  const predecessor = data?.order.predecessor;
  const successor = data?.order.successor;

  return (
    <>
      <p>
        <strong>Multi-day:</strong> {context && !context?.multiDay && 'No'}
      </p>
      {context?.multiDay && (
        <div>
          <ul>
            <li>
              Day: {context.currentDay} of {context.totalDays}
            </li>
            {predecessor && (
              <li>
                Previous Order:{' '}
                <Link
                  onClick={(event) => {
                    // Use Angular state to navigate to avoid call center disruption
                    event.preventDefault();
                    goToOrder(predecessor);
                  }}
                >
                  #{predecessor.number}
                </Link>
              </li>
            )}
            {successor && (
              <li>
                Next Order:{' '}
                <Link
                  onClick={(event) => {
                    // Use Angular state to navigate to avoid call center disruption
                    event.preventDefault();
                    goToOrder(successor);
                  }}
                >
                  #{successor.number}
                </Link>
              </li>
            )}
          </ul>
        </div>
      )}
    </>
  );
};

import * as React from 'react';
import { forwardRef } from 'react';

import { FieldFormGroupProps, FieldFormGroup } from '@shared/components/fields/field_form_group';

import {
  InputFormControlType,
  InputFormControlProps,
  InputFormControl,
} from '@shared/components/fields/input_form_control';

export type InputFormGroupType = InputFormControlType;
export type InputFormGroupProps = InputFormControlProps & FieldFormGroupProps;

export const InputFormGroup = forwardRef<InputFormGroupType, InputFormGroupProps>(
  ({ has, help, label, className, ...input }, ref) => (
    <FieldFormGroup has={has} help={help} label={label} className={className} id={input.id}>
      <InputFormControl ref={ref} {...input} />
    </FieldFormGroup>
  ),
);

const APPOINTMENTS: string[] = [
  'Reschedule',
  'Appointment Availability',
  'Schedule a New Appointment',
  'Problem with an Appointment',
  'Arrival ETA',
  'Cancel an Appointment',
  'Update Appointment Details',
  'Follow up on Delayed Items',
  'Confirm Appointment',
  'Other',
];

const BILLING_AND_PAYMENTS: string[] = [
  'Pay a Bill ',
  'Billing History',
  'Request Receipt',
  'Billed Incorrectly',
  'Change Bill Date',
  'Request Downgrade ',
  'Promotions, Coupons, and Discounts',
  'Update a Payment Method',
  'Rate Changes',
  'Payment Plans',
  'Other',
];

const CLAIMS: string[] = [
  'File a New Claim for Damage or Loss ',
  'Follow up on an Open Claim',
  'Check Payment Status',
  'File a Complaint',
  'Other',
];

const STORAGE_AND_ACCOUNT: string[] = [
  'Available Storage Space',
  'Trouble Locating a Stored Item',
  'Images are Unclear',
  'Update my Contact Info',
  'Forgot my Password',
  'Cancel Account',
  'Transfer Account',
  'Trouble with Online Account',
  'Other',
];

const OTHER_POLICIES: string[] = [
  'Storage Facility Access',
  'Damage or Loss Coverage',
  'Cancellation and Rescheduling Fee',
  'Other',
];

const DROP_OFF: string[] = ['Unit Check Up'];

const NON_TRANSITIONED_MARKET: string[] = [
  'Appointments',
  'Billing & Payments',
  'Certificate of Insurance (COI)',
  'Claims',
  'Other Policies',
  'Other',
  'Storage & Account',
];

const THIRD_PARTY_LEAD: string[] = ['Consultation', 'Initial Contact', 'Virtual Walkthrough'];

const PRE_RESERVATION: string[] = ['Appointments', 'Other Policies', 'Storage & Account'];

const NON_CUSTOMER: string[] = [
  'Accounts Payable - Finance/Accounting',
  'Customer Non-Account Number',
  "Customer's Representative",
  'Day of Contact (DOC)',
  'Employee Verification',
  'Fleet Claim',
  'Government Agency',
  'Marketing Opportunities',
];

const OTHER: string[] = ['Potential Employee - Candidate', 'Spam or Solicitation', 'Wrong Number'];

export const CUSTOMER_TICKET_CATEGORIES: { [key: string]: string[] } = {
  Appointments: APPOINTMENTS,
  'Billing and Payments': BILLING_AND_PAYMENTS,
  'Certificate of Insurance (COI)': [],
  Claims: CLAIMS,
  'Storage and Account': STORAGE_AND_ACCOUNT,
  'Other Policies': OTHER_POLICIES,
  'Drop Off': DROP_OFF,
  'Non-Transitioned Market': NON_TRANSITIONED_MARKET,
  'Third-Party Lead': THIRD_PARTY_LEAD,
  'Pre-Reservation': PRE_RESERVATION,
  'Non-Customer': NON_CUSTOMER,
  Other: OTHER,
};

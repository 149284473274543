import styled from '@emotion/styled';
import { DateTime } from 'luxon';
import * as React from 'react';

import { client } from '@admin/libraries/apollo';

import { Spinner } from '@admin/components/spinner';
import { Spacer } from '@shared/components/helpers';

import { useOrderMovingClocksQuery } from '@admin/schema';
import { Row } from './table/row';

const SpinnerWrapper = styled.div`
  display: flex;
`;

export const Table: React.FC<{
  orderID: string;
}> = ({ orderID }) => {
  const { data, loading } = useOrderMovingClocksQuery({ client, variables: { orderID } });

  const order = data?.order;
  const movingOperation = data?.order?.movingOperation;

  const defaultDate = order?.scheduled;
  const started = order?.started;
  const ended = order?.ended;
  const packedAt = movingOperation?.packedAt;
  const unpackedAt = movingOperation?.unpackedAt;
  const arrivedAtDestinationAt = movingOperation?.arrivedAtDestinationAt;
  const departedForDestinationAt = movingOperation?.departedForDestinationAt;

  const tz = order?.region?.tz;

  return (
    <>
      {loading && (
        <>
          <SpinnerWrapper>
            <Spinner />
          </SpinnerWrapper>
          <Spacer height="8px" />
        </>
      )}
      <div>
        <b>Note:</b> Changing moving clocks will not update labor invoices that have already been created. If you need
        to re-bill for labor, create a tech support ticket
      </div>
      <div className="table-responsive">
        <table className="table table-striped">
          <thead>
            <tr>
              <th className="text-left col-sm-2">Started</th>
              <th className="text-left col-sm-2">Packed</th>
              <th className="text-left col-sm-2">Departed For Destination</th>
              <th className="text-left col-sm-2">Arrived At Destination</th>
              <th className="text-left col-sm-2">Unpacked</th>
              <th className="text-left col-sm-2">Ended</th>
              <th className="text-left col-sm-2"></th>
            </tr>
          </thead>
          <tbody>
            {orderID && !loading && (
              <Row
                orderID={orderID}
                started={started ?? undefined}
                ended={ended ?? undefined}
                packedAt={packedAt ?? undefined}
                unpackedAt={unpackedAt ?? undefined}
                arrivedAtDestinationAt={arrivedAtDestinationAt ?? undefined}
                departedForDestinationAt={departedForDestinationAt ?? undefined}
                defaultDate={defaultDate ? DateTime.fromISO(defaultDate) : undefined}
                tz={tz}
              />
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

import { createContext, useContext } from 'react';
import { ScaleLinear } from 'd3-scale';

export const TimeZoneContext = createContext<string | undefined>(undefined);
export const useTimeZone = () => {
  const timeZone = useContext(TimeZoneContext);
  if (timeZone === undefined) {
    throw new Error('useTimeZone must be used within a TimeZoneProvider');
  }
  return timeZone;
};

export const ScaleContext = createContext<ScaleLinear<number, number> | undefined>(undefined);
export const useScale = () => {
  const scale = useContext(ScaleContext);
  if (scale === undefined) {
    throw new Error('useScale must be used within a ScaleProvider');
  }
  return scale;
};

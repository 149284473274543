(function () {
  const app = angular.module('app');

  app.controller('AffiliateInvoicesController', [
    '$rootScope',
    '$location',
    '$scope',
    'AffiliateInvoice',
    'FiltersService',
    'MonthService',
    'ConfirmationService',
    function ($rootScope, $location, $scope, AffiliateInvoice, FiltersService, MonthService, ConfirmationService) {
      $scope.filters = $location.search();

      $scope.filter = function (filters) {
        if (!filters) {
          filters = $scope.filters || {};
        }

        filters = FiltersService.cleanup(filters);
        $location.search(filters);

        AffiliateInvoice.query(filters).$promise.then(function (data) {
          $scope.affiliateInvoices = data;
        });
      };

      $scope.months = MonthService.setMonthSelect();

      if ($scope.filters.month === undefined) {
        const date = new Date();
        const month = date.getMonth() + 1 + '-' + date.getFullYear();
        $scope.filters.month = month;
      }
      $scope.filter();

      $scope.pay = function (invoice) {
        ConfirmationService.confirm({
          title: 'Are you sure?',
          description: 'Did you pay ' + invoice.affiliate.name + '?',
        }).then(function () {
          invoice.state = 'paid';
          AffiliateInvoice.update(
            { id: invoice.id },
            invoice,
            function () {},
            function (error) {
              invoice.state = 'pending';
              $rootScope.$broadcast('message:group:display', error.data);
            },
          );
        });
      };
    },
  ]);
})();

import React, { useEffect, useState } from 'react';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Shipment,
  ShipmentStatusEnum as ShipmentStatus,
  ShipmentCarrierEnum as ShipmentCarrier,
  useOrderShipmentsQuery,
  Item,
  Barcode,
} from '@admin/schema';
import { client } from '@admin/libraries/apollo';

import { Label } from '@admin/components/helpers/label';
import { Panel } from '@admin/components/helpers/panel';
import { Summary as ItemSummary } from '@admin/components/items/summary';
import { AnchorButton as Link, Table } from '@shared/components/bootstrap';
import { Currency } from '@shared/components/helpers';
import { Spinner } from '@admin/components/spinner';

const STATUSES: Record<ShipmentStatus, string> = {
  [ShipmentStatus.AvailableForPickup]: 'Available for Pickup',
  [ShipmentStatus.Cancelled]: 'Cancelled',
  [ShipmentStatus.Delivered]: 'Delivered',
  [ShipmentStatus.Error]: 'Error',
  [ShipmentStatus.Failure]: 'Failure',
  [ShipmentStatus.InTransit]: 'In Transit',
  [ShipmentStatus.OutForDelivery]: 'Out for Delivery',
  [ShipmentStatus.PreTransit]: 'Pre Transit',
  [ShipmentStatus.ReturnToSender]: 'Return to Sender',
  [ShipmentStatus.Unknown]: 'Unknown',
};

const CARRIERS: Record<ShipmentCarrier, string> = {
  [ShipmentCarrier.Fedex]: 'FedEx',
  [ShipmentCarrier.Ontrac]: 'OnTrac',
  [ShipmentCarrier.Ups]: 'UPS',
  [ShipmentCarrier.Usps]: 'USPS',
};

type ShipmentItem = Pick<Item, 'id' | 'state'> & {
  barcode: Pick<Barcode, 'id' | 'value'>;
};

const ShipmentSummary: React.FC<{ shipment: Pick<Shipment, 'id' | 'carrier' | 'service' | 'status'> }> = ({
  shipment: { id, carrier, service, status },
}) => (
  <div>
    {id} | {CARRIERS[carrier]} | {service} <Label kind="default">{STATUSES[status]}</Label>
  </div>
);

const ShipmentDetails: React.FC<{ shipment: Pick<Shipment, 'length' | 'width' | 'height' | 'weight' | 'rate'> }> = ({
  shipment: { length, width, height, weight, rate },
}) => (
  <div>
    {length} inches (L) × {width} inches (W) × {height} inches (H) | {weight} pounds |{' '}
    {rate && <Currency value={rate} />}
  </div>
);

const ShipmentItems: React.FC<{ shipment: { items: ShipmentItem[] } }> = ({ shipment: { items } }) => (
  <ul>
    {items.map((item) => (
      <li key={item.id}>
        <ItemSummary item={item} />
      </li>
    ))}
  </ul>
);

const ShipmentLinks: React.FC<{ shipment: Pick<Shipment, 'purchased' | 'labelURL' | 'trackingURL' | 'zplURL'> }> = ({
  shipment: { purchased, labelURL, trackingURL, zplURL },
}) => {
  if (!purchased) return <FontAwesomeIcon icon={faSpinner} spin />;

  return (
    <>
      {trackingURL && (
        <Link kind="primary" target="_blank" href={trackingURL}>
          Track
        </Link>
      )}{' '}
      {labelURL && (
        <Link kind="primary" target="_blank" href={labelURL}>
          Print Label
        </Link>
      )}{' '}
      {zplURL && (
        <Link kind="primary" target="_blank" href={zplURL}>
          Print ZPL
        </Link>
      )}{' '}
    </>
  );
};

const POLLING_INTERVAL = 2000;

export const OrderShipmentsPanel: React.FC<{
  orderID: string;
}> = ({ orderID }) => {
  const [collapsed, setCollapsed] = useState(false);
  const { data, startPolling, stopPolling, loading } = useOrderShipmentsQuery({ client, variables: { orderID } });
  const shipments = data?.order.shipments;
  const polling = shipments?.some(({ purchased }) => !purchased);
  const total = shipments?.reduce((memo, { rate }) => memo + (rate ?? 0), 0);

  useEffect(() => {
    if (!polling) return;

    startPolling(POLLING_INTERVAL);
    return () => stopPolling();
  }, [polling]);

  return (
    <Panel>
      <Panel.Header>
        <Panel.Collapse collapsed={collapsed} onChange={setCollapsed} />
        <Panel.Title>Shipments</Panel.Title>
      </Panel.Header>
      {!collapsed && (
        <Panel.Body>
          {total && (
            <p>
              Total: <Currency value={total} />
            </p>
          )}
          {shipments && (
            <Table striped>
              <tbody>
                {shipments.map((shipment) => (
                  <tr key={shipment.id}>
                    <td className="text-left">
                      <ShipmentSummary shipment={shipment} />
                      <ShipmentDetails shipment={shipment} />
                      <ShipmentItems shipment={shipment} />
                    </td>
                    <td className="text-right">
                      <ShipmentLinks shipment={shipment} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
          {loading && <Spinner />}
        </Panel.Body>
      )}
    </Panel>
  );
};

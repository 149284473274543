import axios from 'axios';

import { IWarehouse } from '../types/warehouse';

class Warehouses {
  public async fetch(): Promise<IWarehouse[] | null> {
    try {
      const response = await axios.get('/warehouses.json');
      return this.parseResponse(response.data);
    } catch {
      return null;
    }
  }

  private parseResponse = (res: any[]) => {
    const warehouses: any = [];

    res.forEach((warehouse) => {
      if (!warehouse.third_party_logistics) {
        warehouses.push({
          id: warehouse.id,
          name: warehouse.name,
          inboundDockItemsPerHour: warehouse.inbound_dock_items_per_hour,
        });
      }
    });

    return warehouses;
  };
}

export { Warehouses };

import * as React from 'react';

import {
  editAccountPickupURL,
  editAccountReturnURL,
  editAccountShuttleURL,
  editAccountMoveURL,
  editAccountRetailDeliveryURL,
  editAccountTaskOrderURL,
} from '@admin/config/routes';
import { OrderTypeEnum } from '@admin/schema';

export const Modify: React.FC<{
  type: OrderTypeEnum;
  review: boolean;
  accountID: string;
  orderID: string;
}> = ({ type, review, accountID, orderID }) => {
  const url = (() => {
    switch (type) {
      case OrderTypeEnum.Pickup:
        return editAccountPickupURL({ accountID, orderID });
      case OrderTypeEnum.Return:
        return editAccountReturnURL({ accountID, orderID });
      case OrderTypeEnum.SelfStorageShuttle:
        return editAccountShuttleURL({ accountID, orderID });
      case OrderTypeEnum.Move:
        return editAccountMoveURL({ accountID, orderID });
      case OrderTypeEnum.RetailDelivery:
        return editAccountRetailDeliveryURL({ accountID, orderID });
      case OrderTypeEnum.TaskOrder:
        return editAccountTaskOrderURL({ accountID, orderID });
    }
  })();
  if (!url) {
    return null;
  }
  return (
    <a href={url} className="btn btn-primary">
      {review ? 'Review' : 'Edit'}
    </a>
  );
};

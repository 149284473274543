import styled from '@emotion/styled';
import React, { useState, useEffect } from 'react';

import { Spinner } from '@admin/components/spinner';
import { client } from '@admin/libraries/apollo';
import { DetailedClaimFragment, Status, useAccountClaimsSummaryLazyQuery, useExtendOfferMutation } from '@admin/schema';
import { Button, FormGroup, HelpBlock, Modal, Text } from '@shared/components/bootstrap';
import { Currency } from '@shared/components/helpers';

import { evaluationSummary } from './evaluation_summary';
import { buildOfferInput, IResolutionEdits } from './helpers';

const DetailsInput = styled.textarea`
  resize: vertical;
  min-height: 450px;
`;

const EditDetails: React.FC<{
  details: string;
  totalCompensated: number;
  setDetails(details: string): void;
  onOfferConfirm(): void;
  onCancel(): void;
}> = ({ details, totalCompensated, setDetails, onOfferConfirm, onCancel }) => {
  const [error, setError] = useState<'error' | undefined>();

  const onFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const submittedDetails = details.trim();
    if (!submittedDetails) {
      setError('error');
    } else {
      setError(undefined);
      setDetails(submittedDetails);
      onOfferConfirm();
    }
  };

  return (
    <>
      <p>
        Are you sure you want to offer{' '}
        <strong>
          <Currency value={totalCompensated} />
        </strong>
        ?
      </p>
      <form id="submit-details-form" onSubmit={onFormSubmit}>
        <FormGroup has={error}>
          <DetailsInput
            name="details"
            className="form-control"
            form="submit-details-form"
            placeholder="Customer Explanation"
            value={details}
            onChange={(e) => setDetails(e.currentTarget.value)}
            required
          />
          {!!error && <HelpBlock>Customer explanation cannot be blank.</HelpBlock>}
        </FormGroup>
        <Button kind="danger" onClick={onCancel}>
          Cancel
        </Button>{' '}
        <Button type="submit" kind="info">
          Confirm
        </Button>
      </form>
    </>
  );
};

const ConfirmOffer: React.FC<{
  claim: DetailedClaimFragment;
  issueEdit: IResolutionEdits;
  itemEdits: IResolutionEdits;
  propertyEdit: IResolutionEdits;
  details: string;
  totalCompensated: number;
  onOfferSubmitted(claim: DetailedClaimFragment): void;
  onEdit(event: React.MouseEvent<HTMLButtonElement>): void;
}> = ({ claim, issueEdit, itemEdits, propertyEdit, details, totalCompensated, onOfferSubmitted, onEdit }) => {
  const [error, setError] = useState<string>();

  const [extendOffer, { loading: submitting }] = useExtendOfferMutation({ client });

  const onSubmit = async () => {
    const resolutions = buildOfferInput(
      itemEdits,
      claim.propertySelection?.id,
      propertyEdit,
      claim.issueSelection?.id,
      issueEdit,
    );
    const response = await extendOffer({
      variables: { input: { claimID: claim.id, details: details, ...resolutions } },
    });
    if (response.data) {
      if (response.data.extendOffer.status === Status.Unprocessable) {
        setError(response.data.extendOffer.error ?? undefined);
      } else {
        onOfferSubmitted(response.data.extendOffer.claim!);
      }
    }
  };

  return (
    <>
      <p>
        Offer amount:{' '}
        <strong>
          <Currency value={totalCompensated} />
        </strong>
        .
      </p>
      <FormGroup>
        <DetailsInput
          name="details--completed"
          className="form-control"
          placeholder="Customer Explanation"
          value={details}
          readOnly
        />
      </FormGroup>
      {error && (
        <Text tag="p" style="danger">
          {error}
        </Text>
      )}
      <Button kind="danger" disabled={submitting} onClick={onEdit}>
        Edit
      </Button>{' '}
      <Button kind="info" loading={submitting} onClick={onSubmit}>
        Send
      </Button>
    </>
  );
};

export const ConfirmationModal: React.FC<{
  claim: DetailedClaimFragment;
  issueEdit: IResolutionEdits;
  itemEdits: IResolutionEdits;
  propertyEdit: IResolutionEdits;
  hasCompensatedSelections: boolean;
  totalCompensated: number;
  onOffered(claim: DetailedClaimFragment): void;
  onHide(): void;
}> = ({ claim, issueEdit, itemEdits, propertyEdit, hasCompensatedSelections, totalCompensated, onOffered, onHide }) => {
  const [details, setDetails] = useState<string>('');
  const [showConfirmOffer, setShowConfirmOffer] = useState<boolean>(false);

  const [getClaimSummary, { data }] = useAccountClaimsSummaryLazyQuery({
    client,
    variables: { accountID: claim.account.id },
  });

  const prefillClaimDetails = !!(claim.itemSelections.length || claim.propertySelection);

  useEffect(() => {
    if (!prefillClaimDetails) {
      return;
    }
    getClaimSummary();
  }, [prefillClaimDetails]);

  useEffect(() => {
    if (!data) {
      return;
    }
    setDetails(evaluationSummary(claim, itemEdits, propertyEdit, data.account.claimsSummary));
  }, [data]);

  if (!data && prefillClaimDetails) {
    return <Spinner />;
  }

  return (
    <Modal keyboard={false} backdrop="static" onClose={onHide}>
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>Confirm {hasCompensatedSelections ? 'Offer' : 'Denial'}</Modal.Title>
          <Modal.Close close={onHide} />
        </Modal.Header>
        <Modal.Body className="text-center">
          {!showConfirmOffer ? (
            <EditDetails
              totalCompensated={totalCompensated}
              details={details}
              setDetails={setDetails}
              onCancel={onHide}
              onOfferConfirm={() => setShowConfirmOffer(true)}
            />
          ) : (
            <ConfirmOffer
              claim={claim}
              issueEdit={issueEdit}
              itemEdits={itemEdits}
              propertyEdit={propertyEdit}
              details={details}
              totalCompensated={totalCompensated}
              onEdit={() => setShowConfirmOffer(false)}
              onOfferSubmitted={onOffered}
            />
          )}
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};

/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

const toggleStyle = css`
  display: flex;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  border: 3px solid gray;
  font-size: 15px;
  font-weight: 900;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 200ms;
`;

const rotateStyle = css`
  transform: rotate(90deg);
`;

export const Toggle: React.FC<{ toggled?: boolean; onClick?: () => void; className?: string }> = ({
  toggled,
  onClick,
  className,
}) => (
  <button css={[toggleStyle, toggled && rotateStyle]} className={className} onClick={onClick}>
    <FontAwesomeIcon icon={'chevron-right'} />
  </button>
);

(function () {
  const app = angular.module('app');

  app.component('facilitySelect', {
    templateUrl: 'partials/components/facility_select.html',
    bindings: {
      selection: '=',
      name: '@',
    },
    controller: [
      'FacilitiesService',
      function (FacilitiesService) {
        const self = this;
        self.facilities = FacilitiesService.facilities;
        self.tracker = function (facility) {
          if (facility) {
            return String(facility.id) + ':' + String(facility.type);
          }
        };
      },
    ],
  });
})();

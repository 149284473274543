import angular from 'angular';

angular.module('app').factory('OrderEstimatedDuration', [
  '$resource',
  ($resource) =>
    $resource(
      '/orders/:id/estimate.json',
      { id: '@id' },
      {
        update: {
          method: 'PUT',
        },
      },
    ),
]);

import React, { useEffect } from 'react';

import { Claim__PropertySelectionFragment, Claim__Assessment__Classification } from '@admin/schema';
import { ListGroup } from '@shared/components/bootstrap';
import { Thumbnails } from '@admin/components/helpers/thumbnails';
import { Currency } from '@shared/components/helpers';

import { AssessmentInputs } from './assessment_inputs';
import { AssessmentEditInput } from './helpers';

export const PropertySelection: React.FC<{
  selection: Claim__PropertySelectionFragment;
  edit: AssessmentEditInput;
  setEdit(edit: AssessmentEditInput): void;
}> = ({ selection, edit, setEdit }) => {
  useEffect(() => {
    if (selection.assessment?.classification !== Claim__Assessment__Classification.TotalLoss) {
      setEdit({ ...edit, classification: Claim__Assessment__Classification.TotalLoss });
    }
  }, []);

  return (
    <>
      <ListGroup.Item>
        <div className="row">
          <div className="col-sm-2">
            <strong>Property</strong>
          </div>
          <div className="col-sm-1">
            <strong>Type</strong>
          </div>
          <div className="col-sm-1">
            <strong>Images</strong>
          </div>
          <div className="col-sm-1" />
          <div className="col-sm-1">
            <strong>Declared</strong>
          </div>
          <div className="col-sm-2">
            <strong>Verified value</strong>
          </div>
          <div className="col-sm-2" />
          <div className="col-sm-2">
            <strong>Classification</strong>
          </div>
        </div>
      </ListGroup.Item>
      <ListGroup.Item key={selection.id}>
        <div className="row">
          <div className="col-sm-2">{selection.name}</div>
          <div className="col-sm-1">Property Damage</div>
          <div className="col-sm-1">
            <Thumbnails attachments={selection.photos.concat(selection.quotes)} condensed />
          </div>
          <div className="col-sm-1" />
          <div className="col-sm-1">
            <Currency value={Number(selection.declaration || 0)} />
          </div>
          <AssessmentInputs
            assessment={selection.assessment ?? undefined}
            disableClassification={true}
            showWeight={false}
            edits={edit}
            setEdits={(changes) => setEdit({ ...edit, ...changes })}
          />
        </div>
      </ListGroup.Item>
    </>
  );
};

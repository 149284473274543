import styled from '@emotion/styled';
import React, { useState } from 'react';
import { client } from '@admin/libraries/apollo';

import { Spinner } from '@admin/components/spinner';

import {
  AuditItemDocument,
  OpsAudit__ModifyItemCategoryInput,
  SizingMetric,
  Status,
  useItemCategoriesQuery,
  useModifyItemCategoryMutation,
} from '@admin/schema';

import { Alert, Button } from '@shared/components/bootstrap';

import { SingleSelectFormControl } from '@admin/components/fields/single_select_form_control';

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 10px 0px 0px 0px;
`;

const FALLBACK_ERROR = 'Sorry, an unexpected error occurred. If the problem persists, contact Tech Support.';

interface IOption {
  value: string;
  label: string;
}

export const CategoryPicker: React.FC<{
  itemID: string;
  onSubmit: (event: React.FormEvent) => void;
}> = ({ itemID, onSubmit }) => {
  const { data: itemCategories, loading: loadingCategories } = useItemCategoriesQuery({ client });
  const [sizingMetric, setSizingMetric] = useState<SizingMetric | null | undefined>(undefined);
  const [error, setError] = useState<string | undefined>(undefined);
  const [executeCategoryChangeSubmit] = useModifyItemCategoryMutation({
    client,
    awaitRefetchQueries: true,
    refetchQueries: [{ query: AuditItemDocument, variables: { itemID } }],
  });
  const [categoryID, setCategoryID] = useState<string | undefined>(undefined);
  const [sizingSelection, setSizingSelection] = useState<string | undefined>(undefined);

  if (loadingCategories || !itemCategories || !itemCategories.categories) {
    return <Spinner />;
  }

  const categories = itemCategories.categories
    .filter(
      (category) =>
        category.name !== 'Other' && category.name !== 'Other Item' && category.parentID && category.moveAndPackVisible,
    )
    .sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0));

  const rangeArr = (range: string) => {
    const values = range.split(',');
    const min = parseInt(values[0]);
    const max = parseInt(values[1]);
    return Array.from({ length: max - min + 1 }, (_, i) => (min + i).toString());
  };

  const pluralize = (unit: string) => {
    switch (unit) {
      case 'shelf': {
        return 'shelves';
      }
      case 'cube': {
        return 'cubes';
      }
      case 'drawer': {
        return 'drawers';
      }
      case 'piece': {
        return 'pieces';
      }
      case 'inch': {
        return 'inches';
      }
      default: {
        return unit;
      }
    }
  };

  async function onUpdateCategory(event: React.FormEvent) {
    const input: OpsAudit__ModifyItemCategoryInput = {
      itemID: itemID,
      categoryID: categoryID!,
      sizingSelection: sizingSelection,
    };

    try {
      const result = await executeCategoryChangeSubmit({
        variables: {
          input: input,
        },
      });
      const resultFields = result.data?.modifyItemCategory;

      if (resultFields?.status === Status.Unprocessable) {
        setError(resultFields?.error || FALLBACK_ERROR);
        window.scrollTo(0, 0);
      } else {
        setError(undefined);
        onSubmit(event);
      }
    } catch (e) {
      setError(FALLBACK_ERROR);
      window.scrollTo(0, 0);
    }
  }

  return (
    <>
      {error && (
        <Alert style="danger" onClose={() => setError(undefined)}>
          {error}
        </Alert>
      )}
      <span>Select the correct item category for this item: </span>
      <SingleSelectFormControl
        options={categories.map((category): IOption => ({ value: category.id, label: category.name }))}
        onChange={(id) => {
          setCategoryID(id);
          setSizingMetric(categories.find((cat) => cat.id === id)?.sizingMetric);
        }}
        value={categoryID}
      />
      {sizingMetric && <div>Select the correct sizing for this category: </div>}
      {sizingMetric && sizingMetric.enumerations && sizingMetric.enumerations.length > 0 && (
        <SingleSelectFormControl
          options={sizingMetric.enumerations.map(
            (selection): IOption => ({
              value: selection,
              label: sizingMetric.unit ? `${selection} ${pluralize(sizingMetric.unit)}` : selection,
            }),
          )}
          onChange={(el) => {
            setSizingSelection(el);
          }}
          value={sizingSelection}
        />
      )}
      {sizingMetric && sizingMetric.range && (
        <SingleSelectFormControl
          options={rangeArr(sizingMetric.range).map((i) => ({
            value: i,
            label: sizingMetric.unit ? `${i} ${pluralize(sizingMetric.unit)}` : i,
          }))}
          onChange={(el) => setSizingSelection(el)}
          value={sizingSelection}
        />
      )}
      <ButtonContainer className="text-right">
        <Button kind="info" disabled={!categoryID || (!!sizingMetric && !sizingSelection)} onClick={onUpdateCategory}>
          {'Update Category & Next Audit'}
        </Button>
      </ButtonContainer>
    </>
  );
};

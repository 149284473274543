interface Window {
  clutter: {
    config?: {
      stripe: {
        stripePublishableKey: string;
      };
    };
  };
}

export const STRIPE_API_KEY: string = (window as any).clutter.config?.stripe.stripePublishableKey;

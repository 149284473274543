import styled from '@emotion/styled';
import { DateTime } from 'luxon';
import React, { useState } from 'react';

import { client } from '@admin/libraries/apollo';

import { Roles } from '@admin/components/helpers/roles';
import { Timestamp } from '@admin/components/timestamp';
import { OrderMovingClocksDocument, Status, useModifyMovingClocksMutation } from '@admin/schema';
import { UserRole } from '@admin/types';
import { Alert, Button } from '@shared/components/bootstrap';

const StyledAlert = styled.div`
  div {
    margin: 0px;
  }
`;

type Maybe<T> = T | undefined;

const DATE_FORMAT = 'yyyy-MM-dd';

export const Row: React.FC<{
  orderID: string;
  defaultDate: Maybe<DateTime>;
  started: Maybe<string>;
  ended: Maybe<string>;
  packedAt: Maybe<string>;
  unpackedAt: Maybe<string>;
  departedForDestinationAt: Maybe<string>;
  arrivedAtDestinationAt: Maybe<string>;
  tz: Maybe<string>;
}> = ({
  orderID,
  defaultDate,
  started,
  ended,
  packedAt,
  unpackedAt,
  departedForDestinationAt,
  arrivedAtDestinationAt,
  tz,
}) => {
  const timeZone = tz ?? DateTime.local().zoneName;
  const getTimeOnly = (dateTimeISO: Maybe<string> | null) =>
    dateTimeISO ? DateTime.fromISO(dateTimeISO, { zone: timeZone }).toFormat('HH:mm:ss') : undefined;
  const getISOTime = (value: Maybe<string>, base: Maybe<string>) => {
    if (value && defaultDate) {
      const dateValue = base ? DateTime.fromISO(base, { zone: timeZone }) : defaultDate;
      const newDt = DateTime.fromISO(`${dateValue.toFormat(DATE_FORMAT)}T${value}`, { zone: timeZone });
      return newDt.toISO();
    } else {
      return base;
    }
  };

  const [error, setError] = useState<Maybe<string>>(undefined);
  const [editing, setEditing] = useState<boolean>(false);
  const [endedTime, setEndedTime] = useState<Maybe<string>>(getTimeOnly(ended));
  const [startedTime, setStartedTime] = useState<Maybe<string>>(getTimeOnly(started));
  const [packedAtTime, setPackedAtTime] = useState<Maybe<string>>(getTimeOnly(packedAt));
  const [unpackedAtTime, setUnpackedAtTime] = useState<Maybe<string>>(getTimeOnly(unpackedAt));
  const [departedForDestinationAtTime, setDepartedForDestinationAtTime] = useState<Maybe<string>>(
    getTimeOnly(departedForDestinationAt),
  );
  const [arrivedAtDestinationAtTime, setArrivedAtDestinationAtTime] = useState<Maybe<string>>(
    getTimeOnly(arrivedAtDestinationAt),
  );
  const [executeEditSubmit, { loading }] = useModifyMovingClocksMutation({
    client,
    awaitRefetchQueries: true,
    refetchQueries: [{ query: OrderMovingClocksDocument, variables: { orderID: orderID } }],
  });

  const resetValues = () => {
    setStartedTime(getTimeOnly(started));
    setEndedTime(getTimeOnly(ended));
    setPackedAtTime(getTimeOnly(packedAt));
    setUnpackedAtTime(getTimeOnly(unpackedAt));
    setDepartedForDestinationAtTime(getTimeOnly(departedForDestinationAt));
    setArrivedAtDestinationAtTime(getTimeOnly(arrivedAtDestinationAt));
  };

  async function save() {
    try {
      const input = {
        started: getISOTime(startedTime, started),
        ended: getISOTime(endedTime, ended),
        packedAt: getISOTime(packedAtTime, packedAt),
        unpackedAt: getISOTime(unpackedAtTime, unpackedAt),
        departedForDestinationAt: getISOTime(departedForDestinationAtTime, departedForDestinationAt),
        arrivedAtDestinationAt: getISOTime(arrivedAtDestinationAtTime, arrivedAtDestinationAt),
      };
      const payload = await executeEditSubmit({
        variables: {
          orderID: orderID,
          input: input,
        },
      });
      const res = payload.data?.modifyMovingClocks;
      if (res?.status === Status.Ok) {
        setError(undefined);
      } else {
        resetValues();
        setError(res?.error || undefined);
      }
    } catch (e) {
      resetValues();
      setError('Sorry, something went wrong. Please try again or contact Tech Support.');
    } finally {
      setEditing(false);
    }
  }

  const cancelEditing = () => {
    resetValues();
    setEditing(false);
  };

  return (
    <tr>
      <td className="text-left col-sm-2">
        {!editing && started && <Timestamp value={started} format="small" timezone={tz} />}
        {editing && (
          <input
            type="time"
            value={startedTime || ''}
            onChange={(event) => setStartedTime(event.currentTarget.value)}
          />
        )}
        {!editing && !started && '-'}
      </td>
      <td className="text-left col-sm-2">
        {!editing && packedAt && <Timestamp value={packedAt} format="small" timezone={tz} />}
        {editing && (
          <input
            type="time"
            value={packedAtTime || ''}
            onChange={(event) => setPackedAtTime(event.currentTarget.value)}
          />
        )}
        {!editing && !packedAt && '-'}
      </td>
      <td className="text-left col-sm-2">
        {!editing && departedForDestinationAt && (
          <Timestamp value={departedForDestinationAt} format="small" timezone={tz} />
        )}
        {editing && (
          <input
            type="time"
            value={departedForDestinationAtTime || ''}
            onChange={(event) => setDepartedForDestinationAtTime(event.currentTarget.value)}
          />
        )}
        {!editing && !departedForDestinationAt && '-'}
      </td>
      <td className="text-left col-sm-2">
        {!editing && arrivedAtDestinationAt && (
          <Timestamp value={arrivedAtDestinationAt} format="small" timezone={tz} />
        )}
        {editing && (
          <input
            type="time"
            value={arrivedAtDestinationAtTime || ''}
            onChange={(event) => setArrivedAtDestinationAtTime(event.currentTarget.value)}
          />
        )}
        {!editing && !arrivedAtDestinationAt && '-'}
      </td>
      <td className="text-left col-sm-2">
        {!editing && unpackedAt && <Timestamp value={unpackedAt} format="small" timezone={tz} />}
        {editing && (
          <input
            type="time"
            value={unpackedAtTime || ''}
            onChange={(event) => setUnpackedAtTime(event.currentTarget.value)}
          />
        )}
        {!editing && !unpackedAt && '-'}
      </td>
      <td className="text-left col-sm-2">
        {!editing && ended && <Timestamp value={ended} format="small" timezone={tz} />}
        {editing && (
          <input type="time" value={endedTime || ''} onChange={(event) => setEndedTime(event.currentTarget.value)} />
        )}
        {!editing && !ended && '-'}
      </td>
      <td className="text-right col-sm-2">
        <Roles show={[UserRole.Admin, UserRole.Dispatcher]}>
          {error && (
            <StyledAlert>
              <Alert style="danger" onClose={() => setError(undefined)}>
                {error}
              </Alert>
            </StyledAlert>
          )}
          {!editing && !error && (
            <Button kind="primary" onClick={() => setEditing(true)}>
              Edit
            </Button>
          )}
          {editing && (
            <>
              <Button kind="danger" onClick={() => cancelEditing()} loading={loading}>
                Cancel
              </Button>{' '}
              <Button kind="primary" onClick={() => save()} loading={loading}>
                Save
              </Button>
            </>
          )}
        </Roles>
      </td>
    </tr>
  );
};

import * as React from 'react';

import { getOrdinalSuffix } from '@shared/utils/get_ordinal_suffix';

export const Ordinalize: React.FC<{ value: number }> = ({ value }) => (
  <>
    {value}
    {getOrdinalSuffix(value)}
  </>
);

import React from 'react';

import { Thumbnails } from '@admin/components/helpers/thumbnails';
import { Claim__IssueSelectionFragment, Claim__ResolutionKind } from '@admin/schema';
import { ListGroup } from '@shared/components/bootstrap';
import { Currency } from '@shared/components/helpers';

import { DISPLAYABLE_ISSUE } from '../contants';
import { ResolutionSummary } from '../resolution_summary';

export const IssueSelection: React.FC<{
  issueSelection: Claim__IssueSelectionFragment;
}> = ({ issueSelection }) => {
  const { compensationRequested, declaration, kind, photos, resolution } = issueSelection;
  return (
    <>
      <ListGroup.Item>
        <div className="row">
          <div className="col-sm-2">
            <strong>Issue Type</strong>
          </div>
          <div className="col-sm-2">
            <strong>Images</strong>
          </div>
          <div className="col-sm-2">
            <strong>Requesting Compensation</strong>
          </div>
          <div className="col-sm-1">
            <strong>Declared</strong>
          </div>
          <div className="col-sm-2">
            <strong>Classifications</strong>
          </div>
          <div className="col-sm-1">
            <strong>Approved</strong>
          </div>
          <div className="col-sm-2">
            <strong>Offered</strong>
          </div>
        </div>
      </ListGroup.Item>
      <ListGroup.Item>
        <div className="row">
          <div className="col-sm-2">{kind && DISPLAYABLE_ISSUE[kind]}</div>
          <div className="col-sm-2">
            <Thumbnails attachments={photos} condensed />
          </div>
          <div className="col-sm-2">{compensationRequested ? 'Yes' : 'No'}</div>
          <div className="col-sm-1">
            <Currency value={Number(declaration || 0)} />
          </div>
          <div className="col-sm-2">{!!resolution && <ResolutionSummary resolution={resolution} />}</div>
          <div className="col-sm-1">
            {!!resolution && (
              <Currency value={Number(resolution.kind === Claim__ResolutionKind.Compensated ? declaration : 0)} />
            )}
          </div>
          <div className="col-sm-2">{!!resolution && <Currency value={Number(resolution?.amount || 0)} />}</div>
        </div>
      </ListGroup.Item>
    </>
  );
};

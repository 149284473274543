import React from 'react';

import { TaskOrder__Task, useOrderMapQuery } from '@admin/schema';
import { client } from '@admin/libraries/apollo';

import { Map } from '@admin/components/helpers/map';
import { pick } from 'lodash';
import { Address } from '@portal/schema';

type Coordinate = {
  id: string;
  latitude: number;
  longitude: number;
  label?: google.maps.MarkerLabel;
};

function tasksToPins(
  tasks: Array<Pick<TaskOrder__Task, 'sequenceNumber'> & { address: Pick<Address, 'latitude' | 'longitude'> }>,
) {
  return tasks.map((t) => ({
    latitude: t.address.latitude,
    longitude: t.address.longitude,
    label: { text: 'T' + t.sequenceNumber.toString() },
  }));
}

export const OrderMap: React.FC<{ orderID: string }> = ({ orderID }) => {
  const { data } = useOrderMapQuery({ client, variables: { orderID } });
  const order = data?.order;
  if (!order) return null;

  if (!order.movingOperation && !order.tasks) {
    return <Map marker={order.address} center={order.address} />;
  }

  const addresses = order.tasks
    ? [pick(order.address, ['latitude', 'longitude'])].concat(tasksToPins(order.tasks))
    : order.movingOperation
    ? [order.movingOperation.originAddress, order.movingOperation.destinationAddress]
    : [];
  const pins = addresses.filter((address): address is Coordinate => !!address.latitude && !!address.longitude);

  const latitudes = pins.map(({ latitude }) => latitude);
  const longitudes = pins.map(({ longitude }) => longitude);

  const center =
    latitudes.length > 0 && longitudes.length > 0
      ? {
          latitude: (Math.min(...latitudes) + Math.max(...latitudes)) / 2.0,
          longitude: (Math.min(...longitudes) + Math.max(...longitudes)) / 2.0,
        }
      : undefined;

  return <Map pins={pins} renderDirections={!!order.movingOperation && !order.tasks} center={center} />;
};

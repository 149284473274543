(function () {
  angular.module('app').filter('humanizeList', function () {
    return function (items, limit, limitStr, conjunction) {
      // appropriated `oxford` from humanize-plus library: https://github.com/HubSpot/humanize/blob/master/src/humanize.js#L329
      if (!items) {
        return;
      }

      const numItems = items.length;
      conjunction = conjunction || 'and';

      let limitIndex;
      if (numItems < 2) {
        return String(items);
      } else if (numItems === 2) {
        return items.join(' ' + conjunction + ' ');
      } else if (limit !== undefined && limit !== null && numItems > limit) {
        const extra = numItems - limit;
        const str = extra <= 1 ? 'other' : 'others';
        limitIndex = limit;
        limitStr = limitStr !== undefined && limitStr !== null ? limitStr : ', ' + conjunction + ' ' + extra + str;
      } else {
        limitIndex = -1;
        limitStr = ', ' + conjunction + ' ' + items[numItems - 1];
      }

      return items.slice(0, limitIndex).join(', ') + limitStr;
    };
  });
})();

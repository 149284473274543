(function () {
  angular.module('app').factory('PhoneCall', [
    '$resource',
    function ($resource) {
      return $resource(
        '/phone_calls/:id.json',
        {
          id: '@id',
        },
        {
          index: {
            method: 'GET',
            isArray: true,
          },
          callers: {
            url: '/phone_calls/callers.json',
            method: 'GET',
            isArray: true,
          },
          show: {
            method: 'GET',
            isArray: false,
          },
          update: {
            method: 'PUT',
          },
          customer_ticket: {
            url: '/phone_calls/customer_ticket.json',
            method: 'GET',
            isArray: false,
          },
          outgoing_allowed: {
            url: '/phone_calls/outgoing_allowed.json',
            method: 'GET',
            isArray: false,
          },
        },
      );
    },
  ]);
})();

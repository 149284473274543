import angular from 'angular';

const app = angular.module('app');

app.factory('LeadSerializer', [
  'LeadObjectionService',
  (LeadObjectionService) => (data) => {
    const parameters = angular.copy(data);

    if (!LeadObjectionService.isFollowupScheduledObjection(parameters.objection_reason)) {
      delete parameters.dst_flag;
      delete parameters.followup_scheduled_at;
      delete parameters.time_zone_offset;
    }

    parameters.is_less_than_one_week = !!parameters.is_less_than_one_week;

    return parameters;
  },
]);

import { COLORS } from '@clutter/clean';
import styled from '@emotion/styled';
import { ControlLabel, FormGroup, HelpBlock, Button } from '@shared/components/bootstrap';
import React from 'react';
import { FieldError } from 'react-hook-form';

const ActionButton = styled(Button)<{ color?: string }>`
  color: ${({ color }) => color || COLORS.tealPrimary};
`;

export const Field: React.FC<{
  error?: FieldError | FieldError[];
  id: string;
  label: string;
  actionLabel?: string;
  actionColor?: string;
  action?(): void;
}> = ({ error, id, label, actionLabel, actionColor, action, children }) => (
  <FormGroup has={error ? 'error' : undefined}>
    <ControlLabel htmlFor={id}>
      <strong>{label}</strong>
    </ControlLabel>
    {actionLabel && actionColor && action && (
      <ActionButton size="sm" kind="link" color={actionColor} onClick={action}>
        {actionLabel}
      </ActionButton>
    )}
    {children}
    {Array.isArray(error)
      ? error.map((e, index) => e?.message && <HelpBlock key={index}>{e.message}</HelpBlock>)
      : error?.message && <HelpBlock>{error.message}</HelpBlock>}
  </FormGroup>
);

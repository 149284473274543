import { DateTime } from 'luxon';
import React from 'react';
import { TicketMessage } from './list';

export const Entry: React.FC<{
  message: TicketMessage;
}> = ({ message }) => (
  <>
    <p className="pre-wrap">{message.body}</p>
    <small>
      <span>by</span> <strong>{message.user.name}</strong> <span>on</span>{' '}
      <span>{DateTime.fromISO(message.createdAt).toLocaleString(DateTime.DATETIME_MED)}</span>{' '}
    </small>
  </>
);

import * as React from 'react';

import { Timestamp } from '@admin/components/timestamp';
import { SignatureFragment } from '@admin/schema';

export const Table: React.FC<{
  signatures: SignatureFragment[];
  tz: string | undefined;
}> = ({ signatures, tz }) => {
  if (!signatures.length) {
    return <p className="text-center">No signatures have been attached to this order.</p>;
  }

  return (
    <div className="table-responsive">
      <table className="table table-striped">
        <tbody>
          {signatures &&
            signatures.map((signature, index) => (
              <tr key={index}>
                <td className="text-left col-md-9">
                  <span>{signature.state}</span> <strong>{signature.documentKind}</strong>
                  {signature.skipped && (
                    <span>
                      {' '}
                      ({signature.reason} - "{signature.explanation}"){' '}
                    </span>
                  )}{' '}
                  <span>on</span>{' '}
                  <span>
                    <Timestamp value={signature.timestamp} format="medium" timezone={tz} />
                  </span>
                </td>
                <td className="text-right col-md-3">
                  {signature.contractUrl && (
                    <a href={signature.contractUrl} target="_blank" className="btn btn-primary">
                      View
                    </a>
                  )}
                  {signature.agreement?.imgixURL && (
                    <a href={signature.agreement.imgixURL} target="_blank" className="btn btn-primary">
                      View
                    </a>
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

import * as React from 'react';
import { useState } from 'react';

import { client } from '@admin/libraries/apollo';

import { Panel } from '@admin/components/helpers/panel';
import { Spinner } from '@admin/components/spinner';

import { useDispatchVehicleSealImagesQuery } from '@admin/schema';

import { InfoTable } from './info_table';
import { VehicleImages } from './vehicle_images';

const DEFAULT_COLLAPSED = false;

export const VehicleInformationPanel: React.FC<{ dispatchID: string }> = ({ dispatchID }) => {
  const { data, loading } = useDispatchVehicleSealImagesQuery({ client, variables: { dispatchID } });
  const [collapsed, setCollapsed] = useState<boolean>(DEFAULT_COLLAPSED);

  if (loading || !data || !data.dispatch) {
    return <Spinner />;
  }

  const dispatch = data!.dispatch;

  return (
    <Panel>
      <Panel.Header>
        <Panel.Collapse collapsed={collapsed} onChange={setCollapsed} />
        <Panel.Title>Vehicle Information</Panel.Title>
      </Panel.Header>
      <Panel.Body>
        <InfoTable dispatch={dispatch} />
        <br />
        <VehicleImages dispatch={dispatch} />
      </Panel.Body>
    </Panel>
  );
};

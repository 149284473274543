(function () {
  const app = angular.module('app');

  app.controller('CustomersPanelController', [
    'AccountService',
    function (AccountService) {
      const vm = this;
      vm.hasMultipleCustomers = hasMultipleCustomers;
      vm.showPrimaryLabel = showPrimaryLabel;
      vm.$onInit = onInit;

      function onInit() {
        const customers = vm.account.customers;

        vm.customerList = AccountService.sortForCustomerList(customers);
        vm.emailList = AccountService.sortForEmailList(customers);
        vm.phoneList = AccountService.getPhoneList(customers);
      }

      function hasMultipleCustomers() {
        return vm.customerList.length > 1;
      }

      function showPrimaryLabel(isPrimary, list) {
        return isPrimary && list.length > 1;
      }
    },
  ]);
})();

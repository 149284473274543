import * as React from 'react';
import { useState } from 'react';

import { Panel } from '@admin/components/helpers/panel';

import { PreTripResults } from './pre_trip_results';

const DEFAULT_COLLAPSED = false;

export const IssuesPanel: React.FC<{ dispatchID: string }> = ({ dispatchID }) => {
  const [collapsed, setCollapsed] = useState<boolean>(DEFAULT_COLLAPSED);
  return (
    <Panel>
      <Panel.Header>
        <Panel.Collapse collapsed={collapsed} onChange={setCollapsed} />
        <Panel.Title>Issues</Panel.Title>
      </Panel.Header>
      <PreTripResults dispatchID={dispatchID} />
    </Panel>
  );
};

import angular from 'angular';

angular.module('app').component('billingAddCreditForm', {
  template: `
    <react-component
      name="BillingAddCreditForm"
      props="{
        accountID: $ctrl.id,
        onSave: $ctrl.onSave,
        onCancel: $ctrl.onCancel,
      }"
    />
  `,
  bindings: {
    id: '<',
    onClose: '&',
  },
  controller: [
    '$rootScope',
    function ($rootScope) {
      this.onCancel = () => {
        $rootScope.$broadcast('inlineform:hide', {
          form: 'creditsAdd',
        });
        this.onClose();
      };

      this.onSave = () => {
        $rootScope.$broadcast('inlineform:saved', {
          form: 'creditsAdd',
        });
        $rootScope.$broadcast('inlineform:hide', {
          form: 'creditsAdd',
        });
        this.onClose();
      };
    },
  ],
});

(function () {
  angular.module('app').service('AvailabilityService', [
    function () {
      const STATUSES = {
        full: 'full',
        none: 'none',
      };

      const DAYS = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];

      this.status = function (unavailabilities, filters) {
        const filtered = this.filter(unavailabilities, filters);

        function none() {
          return _.some(filtered, function () {
            return true;
          });
        }

        if (none()) {
          return STATUSES.none;
        }
        return STATUSES.full;
      };

      this.available = function (unavailabilities, filters) {
        return !this.filter(unavailabilities, filters).length;
      };

      this.filter = function (unavailabilities, filters) {
        const date = filters.date ? moment.utc(filters.date) : null;
        filters.start_date = filters.start_date ? moment.utc(filters.start_date) : date;
        filters.until_date = filters.until_date ? moment.utc(filters.until_date) : date;

        const filtered = _.filter(unavailabilities, function (entry) {
          return (
            !entry.deleted_at &&
            (!filters.user_id || entry.user_id === filters.user_id) &&
            (!filters.until_date || !entry.start_date || filters.until_date.isSameOrAfter(entry.start_date, 'day')) &&
            (!filters.start_date || !entry.until_date || filters.start_date.isSameOrBefore(entry.until_date, 'day')) &&
            (!entry.reoccurring || entry[DAYS[date.day()]])
          );
        });

        return filtered;
      };
    },
  ]);
})();

(function () {
  const app = angular.module('app');

  app.service('DebounceService', [
    '$q',
    function ($q) {
      function Debounce() {
        const self = this;

        let deferred = null;
        self.execute = function (resource) {
          const promise = resource.$promise ? resource.$promise : resource;
          if (deferred) {
            deferred.reject();
          }
          deferred = $q.defer();
          promise.then(deferred.resolve, deferred.reject, deferred.notify);
          return deferred.promise;
        };

        return self;
      }

      this.initialize = function () {
        return new Debounce();
      };
    },
  ]);
})();

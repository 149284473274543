import styled from '@emotion/styled';
import React, { useState } from 'react';

import { Panel } from '@admin/components/helpers/panel';
import { Timestamp } from '@admin/components/timestamp';
import { Titleize } from '@admin/components/helpers/titleize';
import { Text } from '@shared/components/bootstrap';
import { AuditItemGroupQuery } from '@admin/schema';
import { ILightboxCarouselInput, LightboxWithCarousel } from '@shared/components/helpers/lightbox';
import { accountItemURL } from '@admin/config/routes';
import { COLORS } from '@root/colors';

type ItemGroup = AuditItemGroupQuery['itemGroup'];
type Item = AuditItemGroupQuery['itemGroup']['items'][number];
type PartialItem = AuditItemGroupQuery['itemGroup']['partialItems'][number];

const Image = styled.img`
  margin: 10px 0 10px 0;
`;

const Scrollable = styled.div`
  max-height: 80vh;
  overflow: hidden;
  overflow-y: scroll;
`;

const Link = styled.a`
  color: ${COLORS.blueLight};
`;

export const ItemGroupInfoPanel: React.FC<{
  itemGroup: ItemGroup;
}> = ({ itemGroup }) => {
  const [imageSelection, setImageSelection] = useState<number | undefined>(undefined);
  const itemGroupImages = [itemGroup.heroImage].concat(itemGroup.additionalImages);
  const imageSizeArray: ILightboxCarouselInput[] = itemGroupImages.map((image) => ({
    src: image.source,
  }));
  const probableCategory = (resource: Item | PartialItem) =>
    resource.category?.name || resource.partCategory?.name || resource.materialCategory?.name;

  return (
    <div className="ops-audit-item-group-info-panel">
      <Panel>
        <Panel.Header>
          <Panel.Title>Item Group {itemGroup?.id}</Panel.Title>
        </Panel.Header>
        <Panel.Body>
          <div className="row">
            <div className="col-md-12">
              <h4>Group Category: {itemGroup?.category.name}</h4>
              {itemGroup?.items.map((item) => {
                const category = probableCategory(item);
                return (
                  <ul key={item.id}>
                    <li>
                      <strong>{category}</strong> -{' '}
                      <Link href={accountItemURL(item.accountID, item.id)} target="_blank">
                        #{item.barcode.value}
                      </Link>
                    </li>
                    {item.includedParts.map((includedPart) => (
                      <li key={includedPart.id}>
                        {includedPart.category.name} - packed with {category}
                      </li>
                    ))}
                  </ul>
                );
              })}
              {itemGroup?.partialItems.map((partial) => {
                const category = probableCategory(partial);
                return (
                  <ul key={partial.id}>
                    <li>
                      <strong>{category}</strong> - <Titleize text={partial.state.replace(/_/g, ' ')} />
                    </li>
                    {partial.includedParts.map((includedPart) => (
                      <li key={includedPart.id}>
                        {includedPart.category.name} - packed with {category}
                      </li>
                    ))}
                  </ul>
                );
              })}
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Scrollable>
                {itemGroupImages.map((image, index) => (
                  <div key={image.id} className="col-md-4">
                    <Text tag="p" alignment="center">
                      Taken at: <Timestamp value={image.createdAt} format="medium" />
                    </Text>
                    <figure>
                      <a
                        href="#"
                        onClick={(event) => {
                          event.preventDefault();
                          event.stopPropagation();
                          setImageSelection(index);
                        }}
                      >
                        <Image className="brick-image" src={image.size} />
                      </a>
                    </figure>
                  </div>
                ))}
                {imageSelection != null && imageSizeArray && (
                  <LightboxWithCarousel
                    imageInputs={imageSizeArray}
                    defaultActiveIndex={imageSelection}
                    onClose={() => setImageSelection(undefined)}
                  />
                )}
              </Scrollable>
            </div>
          </div>
        </Panel.Body>
      </Panel>
    </div>
  );
};

import template from '@admin/angular/templates/phone_call_table.html';

import angular from 'angular';

const app = angular.module('app');

app.component('phoneCallTable', {
  template,
  bindings: {
    calls: '<',
    timezone: '<?',
  },
  controller() {
    this.showAgent = function (phoneCall) {
      return (
        phoneCall.direction === 'outbound' ||
        (phoneCall.status !== 'missed' && phoneCall.status !== 'hungup' && phoneCall.status !== 'voicemail')
      );
    };
  },
});

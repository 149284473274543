(function () {
  const app = angular.module('app');

  app.component('heroImagePicker', {
    templateUrl: 'partials/components/hero_image_picker.html',
    bindings: {
      items: '=',
      selection: '=?',
    },
    controller: [
      function () {
        this.$onInit = () => {
          this.images = [];
          _.each(this.items, (item) => {
            _.each(item.images, (image) => {
              this.images.push(image);
            });
          });
          if (this.selection) {
            const match = _.find(this.images, { id: this.selection.id });
            if (!match) {
              this.images.unshift(this.selection);
            } else {
              this.selection = match;
            }
          }
        };
      },
    ],
  });
})();

import * as React from 'react';

import { logisticsDetailedSKUURL } from '@admin/config/routes';
import { Roles } from '@admin/components/helpers/roles';
import { Logistics__Sku__CountingUnitEnum, Logistics__SkuTeachInStatusEnum } from '@admin/schema';
import { UserRole } from '@admin/types';

import { LogisticsSkuForm, NewFormData } from './form/form';

export const LogisticsNewSku: React.FC<{ accountID: string }> = ({ accountID }) => {
  const formData: NewFormData = {
    accountID,
    value: '',
    description: '',
    hasBarcode: false,
    defaultPalletTypeID: '0',
    defaultPalletHeightValue: 0,
    teachInStatus: Logistics__SkuTeachInStatusEnum.Pending,
    countingUnit: Logistics__Sku__CountingUnitEnum.MasterCarton,
  };

  const onSubmit = async (id?: string) => {
    if (id) {
      window.location.href = logisticsDetailedSKUURL({ id: id });
    }
  };

  return (
    <Roles show={[UserRole.Admin, UserRole.EnterpriseManager, UserRole.Manager]}>
      <LogisticsSkuForm formData={formData} onSave={onSubmit} />
    </Roles>
  );
};

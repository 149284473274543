import styled from '@emotion/styled';
import { DateTime } from 'luxon';
import * as React from 'react';

import { AuditResultsQuery, useAuditResultsQuery } from '@admin/schema';

import { client } from '@admin/libraries/apollo';

import { Spinner } from '@admin/components/spinner';
import { Spacer } from '@shared/components/helpers';

import { Row } from './table/row';

type AuditType = AuditResultsQuery['audits'][number];

const SpinnerWrapper = styled.div`
  display: flex;
`;

export const Table: React.FC<{
  resourceID: string;
  resourceType: string;
}> = ({ resourceID, resourceType }) => {
  const { data, loading } = useAuditResultsQuery({
    client,
    variables: { resourceID, resourceType },
  });

  const audits = data && data.audits;
  return (
    <>
      {loading && (
        <>
          <SpinnerWrapper>
            <Spinner />
          </SpinnerWrapper>
          <Spacer height="8px" />
        </>
      )}
      <div className="table-responsive">
        <table className="table table-striped">
          <thead>
            <tr>
              <th className="col-lg-1">Status</th>
              <th className="col-lg-2">Audit Type</th>
              <th className="col-lg-1">Auditor</th>
              <th className="col-lg-1">Resource</th>
              <th className="col-lg-1">Question Group</th>
              <th className="text-left">Responses</th>
              <th className="text-left">Exceptions</th>
              <th className="col-lg-2"></th>
            </tr>
          </thead>
          <tbody>
            {audits &&
              audits
                .sort(
                  (a: AuditType, b: AuditType) =>
                    DateTime.fromISO(b.updatedAt).diff(DateTime.fromISO(a.updatedAt)).milliseconds,
                )
                .map((audit, index) => <Row key={index} audit={audit} />)}
          </tbody>
        </table>
      </div>
    </>
  );
};

(function () {
  const app = angular.module('app');

  app.component('customersPanel', {
    templateUrl: 'partials/accounts/customers_panel.html',
    controller: 'CustomersPanelController as customers_panel_controller',
    bindings: {
      account: '<',
    },
  });
})();

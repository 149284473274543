import { client } from '@admin/libraries/apollo';
import * as React from 'react';
import { LogisticsPurchaseOrdersQuery, useLogisticsPurchaseOrdersQuery } from '@admin/schema';

import { Panel } from '@admin/components/helpers/panel';
import { Table } from '@shared/components/bootstrap';
import { DateTime } from 'luxon';

const DATE_FORMAT = 'yyyy-LL-dd';

type FulfillmentExpectation =
  LogisticsPurchaseOrdersQuery['logisticsPurchaseOrders'][number]['fulfillmentExpectations'][number];

export const LogisticsPurchaseOrders: React.FC<{}> = () => {
  const { data, loading } = useLogisticsPurchaseOrdersQuery({ client });

  if (loading || !data) {
    return <p className="text-center">Loading...</p>;
  }

  return (
    <Panel>
      <Panel.Header>
        <Panel.Title>Purchase Orders</Panel.Title>
      </Panel.Header>
      <Panel.Body>
        <Table striped responsive>
          <thead>
            <tr className="purchase-order-list__row">
              <th className="col-sm-2 text-left">Company</th>
              <th className="col-sm-1 text-left">PO Number</th>
              <th className="col-sm-1 text-left">Order Date</th>
              <th className="col-sm-1 text-left">Must Ship By</th>
              <th className="col-sm-3 text-left">SKUs and Quantities</th>
              <th className="col-sm-2 text-left">Additional Info</th>
              <th className="col-sm-1 text-left" />
              <th className="col-sm-1 text-left" />
            </tr>
          </thead>
          <tbody>
            <>
              {data.logisticsPurchaseOrders.map((purchaseOrder) => (
                <tr className="purchase-order-list__row" key={purchaseOrder.id}>
                  <td className="col-sm-2 text-left">{purchaseOrder.account.customer.name}</td>
                  <td className="col-sm-1 text-left">{purchaseOrder.number}</td>
                  <td className="col-sm-1 text-left">
                    {purchaseOrder.orderDate && DateTime.fromISO(purchaseOrder.orderDate).toFormat(DATE_FORMAT)}
                  </td>
                  <td className="col-sm-1 text-left">
                    {purchaseOrder.mustShipBy && DateTime.fromISO(purchaseOrder.mustShipBy).toFormat(DATE_FORMAT)}
                  </td>
                  <td className="col-sm-3 text-left">{formatSkus(purchaseOrder.fulfillmentExpectations)}</td>
                  <td className="col-sm-2 text-left">{purchaseOrder.additionalInfo}</td>
                  <td className="col-sm-1 text-right" />
                  <td className="col-sm-1 text-right" />
                </tr>
              ))}
            </>
          </tbody>
        </Table>
      </Panel.Body>
    </Panel>
  );
};

const formatSkus = (fulfillmentExpectations: FulfillmentExpectation[]): React.ReactNodeArray => {
  const aggregateFulfillmentExpectations: { [sku: string]: number } = {};
  fulfillmentExpectations.forEach((fulfillmentExpectation) => {
    if (aggregateFulfillmentExpectations.hasOwnProperty(fulfillmentExpectation.sku.value)) {
      aggregateFulfillmentExpectations[fulfillmentExpectation.sku.value] += fulfillmentExpectation.quantity;
    } else {
      aggregateFulfillmentExpectations[fulfillmentExpectation.sku.value] = fulfillmentExpectation.quantity;
    }
  });
  return Object.keys(aggregateFulfillmentExpectations).map((key, index) => (
    <>
      <span key={index}>
        <strong>{key}: </strong>
        {aggregateFulfillmentExpectations[key]}
      </span>
      <br />
    </>
  ));
};

import { InputFormGroup, NumberFormControl, SelectFormControl } from '@admin/components/fields';
import { Selector as FacilitiesSelector } from '@admin/components/self_storage/facilities/selector';
import { DimensionsFormGroup, KindFormGroup } from '@admin/components/self_storage/units/filters';
import {
  RateAdjustment__Scheduling__Filters__RangeInput,
  RateAdjustment__Scheduling__FiltersInput,
  RateAdjustment__Scheduling__State,
  RateAdjustment__Upload__Product,
} from '@admin/schema';
import styled from '@emotion/styled';
import { ControlLabel, FormGroup } from '@shared/components/bootstrap';
import { capitalize } from '@shared/utils/capitalize';
import * as React from 'react';

const InputGroupList = styled.div`
  display: flex;
  margin: -4px;
`;

const InputGroupItem = styled.div`
  padding: 4px;
  flex-grow: 1;
  flex-shrink: 1;
`;

const InputGroupSeparator = styled.div`
  padding: 4px;
  flex-grow: 0;
  flex-shrink: 0;
  line-height 34px;
`;

const PriceGroup: React.FC<{
  label: string;
  price?: RateAdjustment__Scheduling__Filters__RangeInput | null;
  onChange(price: RateAdjustment__Scheduling__Filters__RangeInput): void;
}> = ({ label, price, onChange }) => (
  <FormGroup>
    <ControlLabel>{label}</ControlLabel>
    <InputGroupList>
      <InputGroupItem>
        <NumberFormControl
          value={price?.min ?? undefined}
          placeholder="Min"
          onChange={(min) => onChange({ ...price, min })}
        />
      </InputGroupItem>
      <InputGroupSeparator>-</InputGroupSeparator>
      <InputGroupItem>
        <NumberFormControl
          value={price?.max ?? undefined}
          placeholder="Max"
          onChange={(max) => onChange({ ...price, max })}
        />
      </InputGroupItem>
    </InputGroupList>
  </FormGroup>
);

interface IFilterProps {
  filters: RateAdjustment__Scheduling__FiltersInput;
  onChange(filters: RateAdjustment__Scheduling__FiltersInput): void;
}

const SelfStorageFilters: React.FC<IFilterProps> = ({ filters, onChange }) => {
  const { unitClassification, facilityIDs } = filters;
  return (
    <div className="row">
      <div className="col-md-4">
        <FormGroup>
          <ControlLabel>Facility:</ControlLabel>
          <FacilitiesSelector
            selectedIDs={facilityIDs || []}
            onChange={(selectedIDs) => onChange({ ...filters, facilityIDs: selectedIDs })}
          />
        </FormGroup>
      </div>
      <div className="col-md-4">
        <KindFormGroup
          kind={unitClassification?.kind}
          onChange={(kind) => onChange({ ...filters, unitClassification: { ...unitClassification, kind } })}
        />
      </div>
      <div className="col-md-4">
        <DimensionsFormGroup
          classification={unitClassification}
          onChange={(classification) => {
            onChange({ ...filters, unitClassification: classification });
          }}
        />
      </div>
    </div>
  );
};

export const Filters: React.FC<IFilterProps> = ({ filters, onChange }) => {
  const { search, from, till, oldPrice, newPrice, product, state } = filters;
  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <InputFormGroup
            id="search"
            label="Search:"
            type="search"
            placeholder="Search"
            value={search || ''}
            onChange={(event) => onChange({ ...filters, search: event.target.value || undefined })}
          />
        </div>
        <div className="col-md-3">
          <PriceGroup
            label="Old Price:"
            price={oldPrice}
            onChange={(price) => onChange({ ...filters, oldPrice: price })}
          />
        </div>
        <div className="col-md-3">
          <PriceGroup
            label="New Price:"
            price={newPrice}
            onChange={(price) => onChange({ ...filters, newPrice: price })}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <FormGroup>
            <ControlLabel htmlFor="state">Status:</ControlLabel>
            <SelectFormControl
              value={state || ''}
              prompt="- All -"
              options={Object.values(RateAdjustment__Scheduling__State)}
              name={(option: RateAdjustment__Scheduling__State) =>
                option === RateAdjustment__Scheduling__State.Active ? 'Completed' : capitalize(option)
              }
              onChange={(value) => onChange({ ...filters, state: value || undefined })}
            />
          </FormGroup>
        </div>
        <div className="col-md-4">
          <InputFormGroup
            id="from"
            min="0000-01-01"
            max="9999-12-31"
            label="Scheduled Date From:"
            type="date"
            className="form-control"
            value={from || ''}
            onChange={(event) => onChange({ ...filters, from: event.target.value || undefined })}
          />
        </div>
        <div className="col-md-4">
          <InputFormGroup
            id="till"
            min="0000-01-01"
            max="9999-12-31"
            label="Scheduled Date Till:"
            type="date"
            value={till || ''}
            onChange={(event) => onChange({ ...filters, till: event.target.value || undefined })}
          />
        </div>
      </div>
      {product === RateAdjustment__Upload__Product.SelfStorage && (
        <SelfStorageFilters filters={filters} onChange={onChange} />
      )}
    </>
  );
};

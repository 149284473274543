import axios from 'axios';
import React, { useEffect, useState } from 'react';

import { client } from '@admin/libraries/apollo';
import { Maybe, Status, useModifyClaimOwnerMutation, UserFragment } from '@admin/schema';
import { IUser } from '@admin/types';
import { Button, Text } from '@shared/components/bootstrap';

export const Owner: React.FC<{
  claimID: string;
  editable: boolean;
  owner?: Maybe<UserFragment>;
}> = ({ claimID, editable, owner }) => {
  const [users, setUsers] = useState<IUser[]>([]);
  const [editing, setEditing] = useState<boolean>(false);
  const [userID, setUserID] = useState<string | undefined>(owner?.id);
  const [error, setError] = useState<string | undefined>(undefined);

  useEffect(() => {
    axios.get<IUser[]>('/users.json', { params: { role: 'claims' } }).then(({ data }) => setUsers(data));
  }, []);

  const [modifyOwner, { loading }] = useModifyClaimOwnerMutation({ client });

  const onSave = async () => {
    const response = await modifyOwner({ variables: { claimID, ownerID: userID } });
    if (response.data) {
      if (response.data.modifyClaimOwner.status === Status.Unprocessable) {
        setError(response.data.modifyClaimOwner.error ?? undefined);
        return;
      }
      setEditing(false);
    }
  };

  return (
    <>
      {editing ? (
        <>
          <select onChange={(event) => setUserID(event.target.value)} value={userID || ''}>
            <option>Select an Owner</option>
            {users.map((user) => (
              <option value={user.id} key={user.id}>
                {user.name}
              </option>
            ))}
          </select>
          <Button onClick={onSave} kind="link" loading={loading}>
            Save
          </Button>
          {error && (
            <Text tag="p" style="danger">
              {error}
            </Text>
          )}
        </>
      ) : (
        <>
          <span>{owner ? owner.name : 'Unassigned'}</span>
          {editable && (
            <Button onClick={() => setEditing(true)} kind="link">
              Change
            </Button>
          )}
        </>
      )}
    </>
  );
};

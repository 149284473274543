import React, { useEffect } from 'react';

import { SelfStorage__UnitGroupFragment } from '@admin/schema';
import { ErrorMessage, useFormContext } from 'react-hook-form';

import { FormGroup, HelpBlock } from '@shared/components/bootstrap';

import { Selector as GroupSelector } from '@admin/components/self_storage/groups/selector';

export const Quote: React.FC<{ facilityID: string }> = ({ facilityID }) => {
  const { errors, register, setValue, unregister, watch } = useFormContext<{
    group: SelfStorage__UnitGroupFragment;
  }>();

  useEffect(() => {
    register({ name: 'group' }, { required: 'please select an entry' });
    return () => {
      unregister('group');
    };
  }, [register]);

  return (
    <FormGroup has={errors.group ? 'error' : undefined}>
      <GroupSelector
        facilityID={facilityID}
        classificationID={watch('group')?.classification.id}
        priceID={watch('group')?.price.id}
        onSelect={(group) => {
          setValue('group', group);
        }}
      />
      <ErrorMessage name="group">{({ message }) => <HelpBlock>{message}</HelpBlock>}</ErrorMessage>
    </FormGroup>
  );
};

(function () {
  angular.module('app').filter('highlightTicketLinks', function () {
    return function (text, links) {
      if (text && links) {
        let safe = _.escape(text);
        const replacements = {};
        _.each(links, function (link) {
          if (!replacements[link.url]) {
            replacements[link.url] = link.name;
            safe = safe.replace(
              new RegExp(link.url, 'g'),
              '<a class="btn-link" href="' + link.url + '">' + link.name + '</a>',
            );
          }
        });
        return safe;
      }
    };
  });
})();

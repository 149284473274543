import * as React from 'react';

import { InputFormGroup } from '@admin/components/fields';

export const TransactionNumberFormGroup: React.FC<{
  disabled?: boolean;
  required: boolean;
  value?: string;
  onChange(value?: string): void;
}> = ({ disabled, required, value, onChange }) => (
  <InputFormGroup
    disabled={disabled}
    required={required}
    id="offline_payment_transaction_number"
    label="Transaction Number:"
    value={value ?? ''}
    onChange={(event) => onChange(event.target.value || undefined)}
  />
);

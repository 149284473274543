import { Spinner } from '@admin/components/spinner';
import {
  SelfStorage__ReservationFragment,
  useSelfStorageAddressesQuery,
  useSelfStorageUpcomingReservationsQuery,
  OrderStateEnum,
} from '@admin/schema';
import { Spacer } from '@shared/components/helpers';
import { client } from '@admin/libraries/apollo';
import React, { useEffect, useState } from 'react';

const ReservationSelector: React.FC<{
  onChange: (id: string) => void;
  facilityID: string;
  reservation?: SelfStorage__ReservationFragment;
}> = ({ onChange, facilityID, reservation: existingReservation }) => {
  const reservations = useSelfStorageUpcomingReservationsQuery({ client, variables: { facilityID } }).data
    ?.reservations;

  if (!reservations) {
    return <Spinner />;
  } else {
    return (
      <div className="col-md-6">
        <label>Which Upcoming Reservation</label>
        <select
          id="self-storage-reservations"
          className="form-control"
          value={existingReservation?.id ?? ''}
          onChange={(event) => {
            onChange(event.target.value);
          }}
        >
          <option value="">- Self Storage Reservations -</option>
          {reservations
            .filter((reservation) => !reservation.order || reservation.order.state === OrderStateEnum.Canceled)
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((reservation) => (
              <option key={reservation.id} value={reservation.id}>
                {reservation.name} ({reservation.email}) on {reservation.date}
              </option>
            ))}
        </select>
      </div>
    );
  }
};

export const FacilityReservationSelector: React.FC<{
  onChange: (id: string) => void;
  reservation?: SelfStorage__ReservationFragment;
}> = ({ onChange, reservation }) => {
  const [facilityID, setFacilityID] = useState<string | undefined>();
  const facilities = useSelfStorageAddressesQuery({ client }).data?.facilities;

  useEffect(() => {
    if (reservation && reservation.facility) {
      setFacilityID(String(reservation.facility.id));
    }
  }, []);

  if (!facilities) {
    return <Spinner />;
  } else {
    return (
      <div className="row">
        <Spacer height="8px" />
        <div className="col-md-6">
          <label>Which Facility Is The Shuttle For?</label>
          <select
            id="self-storage-address"
            className="form-control"
            value={facilityID ?? ''}
            onChange={(event) => {
              setFacilityID(event.target.value);
            }}
          >
            <option value="">- Self Storage Facility -</option>
            {facilities?.map((facility) => (
              <option key={facility.id} value={facility.id}>
                {facility.name}
              </option>
            ))}
          </select>
        </div>
        {facilityID && <ReservationSelector onChange={onChange} reservation={reservation} facilityID={facilityID} />}
      </div>
    );
  }
};

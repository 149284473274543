import { ErrorFragment } from '@admin/schema';

function isErrorFragment(data: any): data is ErrorFragment {
  return data.__typename === 'Error';
}

/**
 * A utility for splitting a union between a custom GQL error and some other
 * type into separate variables, mostly for type safety.
 *
 * @param data The data attribute returned by a GQL query
 *
 * NOTE: This function was copied from Landing and should match the code there
 */
export function parseGQLErrorUnion<T extends { __typename?: string }>(data: ErrorFragment | T | undefined | null) {
  const notError =
    data && !isErrorFragment(data)
      ? // TS doesn't narrow T correctly here for some reason
        (data as Exclude<T, ErrorFragment>)
      : undefined;
  const error = data && isErrorFragment(data) ? data : undefined;
  return [notError, error] as const;
}

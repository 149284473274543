import template from '@admin/angular/templates/images_input.html';

import angular from 'angular';

const app = angular.module('app');

app.component('imagesInput', {
  template,
  bindings: {
    onPicked: '&',
  },
});

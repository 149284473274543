(function () {
  angular.module('app').service('FiltersService', [
    'ClLocation',
    function (ClLocation) {
      this.cleanup = function (filters) {
        if (!filters) {
          return filters;
        }

        const results = {};
        _.each(filters, function (value, key) {
          if (value instanceof moment) {
            value = value.format('YYYY-MM-DD');
          }
          if (value instanceof Date) {
            value = moment(value).format('YYYY-MM-DD');
          }
          if (value && value.length !== 0) {
            results[key] = value;
          }
        });
        return results;
      };

      this.fromQueryParameters = function (types) {
        const parsed = ClLocation.getSearch();
        _.each(types, function (type, key) {
          switch (type) {
            case 'number':
              if (parsed[key]) {
                parsed[key] = Number(parsed[key]);
              }
              break;
            case 'date':
              if (parsed[key]) {
                parsed[key] = new Date(moment(parsed[key], 'YYYY-MM-DD'));
              }
              break;
            case 'boolean':
              if (parsed[key]) {
                parsed[key] = parsed[key] === 'true';
              }
              break;
          }
        });
        return parsed;
      };

      this.parse = function (filters, types) {
        const parsed = angular.copy(filters);
        _.each(types, function (type, key) {
          if (parsed[key]) {
            switch (type) {
              case 'number':
                parsed[key] = Number(filters[key]);
                break;
              case 'date':
                parsed[key] = new Date(moment(filters[key], 'YYYY-MM-DD'));
                break;
              case 'array':
                if (!(parsed[key] instanceof Array)) {
                  parsed[key] = [parsed[key]];
                }
                break;
            }
          }
        });
        return parsed;
      };
    },
  ]);
})();

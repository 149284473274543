import { getSnsDiscount } from '../../utils/sns';

(function () {
  angular.module('app').service('SubscribeSetupService', [
    '$q',
    'HistoricalPricing',
    'Account',
    'ErrorService',
    function ($q, HistoricalPricing, Account, ErrorService) {
      function getResources(accountId) {
        const accountPromise = Account.get({ id: accountId }).$promise;
        const historicalPricingPromise = HistoricalPricing.query({
          account_id: accountId,
        }).$promise;
        return $q.all({
          account: accountPromise,
          historicalPricings: historicalPricingPromise,
        });
      }

      function parameterizeSubscriptions(subscriptions) {
        return _.map(subscriptions, (subscription) => ({
          pricing_id: subscription.pricing.id,
          quantity: subscription.quantity,
        }));
      }

      function getCurrentPricing(pricings) {
        const subscriptionPricing = _.find(pricings, (pricing) => pricing.context === 'current_subscription');
        const currentPricing = _.find(pricings, (pricing) => pricing.context === 'current_pricing_set');
        const activePricing = _.find(pricings, (pricing) => pricing.context === 'active');
        const historicalPricing = _.find(pricings, (pricing) => pricing.context === 'historical');
        return subscriptionPricing || currentPricing || activePricing || historicalPricing;
      }

      const transformDimensions = function (dimensions) {
        return _.mapValues(dimensions, parseFloat);
      };

      function setUpPlans(historicalPricings, account) {
        return _.map(historicalPricings, (historicalPricing) => {
          const { plan } = historicalPricing;
          plan.quantity = {
            current: historicalPricing.current_subscription_quantity,
            desired: historicalPricing.current_subscription_quantity,
          };

          if (plan.kind === 'custom') {
            const discount = 1 - getSnsDiscount(account.created_at);
            plan.quantity.custom =
              account.price_match_kind === 'switch_n_save'
                ? Math.round(Number(plan.quantity.desired) / discount)
                : plan.quantity.desired;
          }

          plan.pricings = historicalPricing.pricings;
          plan.currentPricing = getCurrentPricing(historicalPricing.pricings);
          plan.coupon = historicalPricing.current_subscription_coupon;
          plan.custom_dimension = transformDimensions(historicalPricing.current_subscription_custom_dimension);
          return plan;
        });
      }

      this.setup = function (accountId) {
        const deferred = $q.defer();

        getResources(accountId).then((data) => {
          deferred.resolve({
            subscriptions: parameterizeSubscriptions(data.account.subscriptions),
            plans: setUpPlans(data.historicalPricings, data.account),
            account: data.account,
          });
        }, ErrorService.handle);

        return deferred.promise;
      };
    },
  ]);
})();

(function () {
  const app = angular.module('app');

  const START = 4;
  const UNTIL = 22;
  const TIMES = [];

  for (let hour = START; hour <= UNTIL; hour++) {
    TIMES.push(new Date(moment.utc({ hour: hour })));
  }

  app.constant('OperationsConstants', {
    TIMES: TIMES,
  });
})();

import * as React from 'react';
import { useState } from 'react';

import { ItemFragment, useItemByIdQuery } from '@admin/schema';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Text } from '@shared/components/bootstrap';
import { client } from '@admin/libraries/apollo';

import { SearchBar } from './search_bar';

const SAME_ITEM_ERROR = 'Item cannot be a duplicate of itself.';

export const DuplicateItemLinking: React.FC<{
  accountID: string;
  itemID: string;
  originalItemID?: string;
  className?: string;
  onChange(originalItem: ItemFragment | undefined): void;
}> = ({ accountID, itemID, originalItemID, className, onChange }) => {
  const query = originalItemID;
  const [searchedItem, setSearchedItem] = useState<ItemFragment | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const { loading } = useItemByIdQuery({
    client,
    variables: {
      accountID,
      id: query!,
    },
    skip: !query,
    onCompleted: ({ item }) => {
      setSearchedItem(item ?? undefined);
    },
  });

  const onSearch = (item?: ItemFragment) => {
    if (!!item && Number(item.id) === Number(itemID)) {
      setErrorMessage(SAME_ITEM_ERROR);
    } else {
      setSearchedItem(item);
      onChange(item);
      setErrorMessage('');
    }
  };

  const onRemove = () => {
    setSearchedItem(undefined);
    onChange(undefined);
  };

  return (
    <>
      <div className={className}>
        <Text tag="p">Duplicate of:</Text>
        {!searchedItem && (
          <div>
            <SearchBar accountID={accountID} disabled={loading} onItemFound={(item) => onSearch(item)} />
          </div>
        )}
        {!!searchedItem && (
          <div>
            <div className="row" style={{ display: 'flex', alignItems: 'center' }}>
              <div className="col-2 col-sm-2">
                <Text tag="span">#{searchedItem.barcode.value}</Text>
              </div>
              <div className="text-danger col-2 col-sm-2">
                <Button
                  name="duplicate-link-remove-btn"
                  className="text-danger vertical-align"
                  kind="link"
                  disabled={loading}
                  style={{ verticalAlign: 'middle' }}
                  onClick={() => onRemove()}
                >
                  <FontAwesomeIcon icon="times" /> Remove
                </Button>
              </div>
            </div>
          </div>
        )}
        <div>
          <p className="text-danger">{errorMessage}</p>
        </div>
      </div>
    </>
  );
};

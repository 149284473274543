(function () {
  angular.module('app').controller('MessageCenterController', [
    '$rootScope',
    '$scope',
    '$window',
    '$filter',
    function ($rootScope, $scope, $window, $filter) {
      const self = this;
      self.messages = [];
      self.remove = function (index) {
        self.messages.splice(index, 1);
      };

      self.conditionallyAddMessage = function (message) {
        for (let i = 0, len = self.messages.length; i < len; i++) {
          if (angular.equals(message, self.messages[i])) {
            // if the message is already being displayed
            return;
          }
        }

        self.messages.push(message);
        $scope.$applyAsync();
      };

      $rootScope.$on('message:display', function (event, message) {
        self.conditionallyAddMessage(message);
      });

      $rootScope.$on('message:clear', function () {
        self.messages = [];
      });

      $rootScope.$on('message:group:display', function (event, errorObj) {
        if (!errorObj || !angular.isObject(errorObj) || angular.equals(errorObj, {})) {
          self.conditionallyAddMessage({
            type: 'danger',
            body: 'Unknown error.',
          });

          return;
        }

        for (const key in errorObj) {
          if (errorObj.hasOwnProperty(key)) {
            self.conditionallyAddMessage({
              type: 'danger',
              body: $filter('transform')(key, ['titleize']) + ' ' + errorObj[key],
            });
          }
        }
      });

      // read messages
      angular.element(document).ready(function () {
        angular.forEach($window.clutter.flash, function (value, key) {
          if (value !== '') {
            self.conditionallyAddMessage({
              type: key,
              body: value,
            });
          }
        });
      });
    },
  ]);
})();

import * as React from 'react';
import { useState } from 'react';

import styled from '@emotion/styled';

import { formatPhoneForHuman } from '@admin/utils/format_phone_for_human';
import { Callable } from '@admin/schema';

const MAX_LENGTH = 1600;

const Button = styled.button`
  margin-left: 6px;
`;

const Header = styled.div`
  font-weight: 600;
  font-size: 16px;
  padding: 5px 0px;
`;

const Sender = styled.div`
  text-align: right;
  padding: 12px 0px;
`;

const SubText = styled.div`
  font-size: 12px;
  padding: 5px 0px;
  font-weight: 600;
`;

const TextArea = styled.textarea`
  padding: 6px 12px;
`;

export const MessageSender: React.FC<{
  callables: Array<Pick<Callable, 'number' | 'name' | 'role'>>;
  disabled: boolean;
  placeholder?: string;
  warning: string;
  refetch(): void;
  onCreate(body: string, phoneID: string): Promise<void>;
}> = ({ callables, disabled, placeholder, warning, refetch, onCreate }) => {
  const [body, setBody] = useState('');
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
  const [newMessagePhone, setNewMessagePhone] = useState<string>(callables[0]?.number);

  return (
    <>
      <Header>
        New Message to{' '}
        <select
          defaultValue={newMessagePhone}
          onChange={(event) => {
            setNewMessagePhone(event.target.value);
          }}
        >
          {callables.map((callable) => (
            <option key={callable.number} value={callable.number}>
              {formatPhoneForHuman(callable.number)} ({callable.name}, {callable.role})
            </option>
          ))}
        </select>
      </Header>
      <SubText>{warning}</SubText>
      <TextArea
        className="form-control"
        maxLength={MAX_LENGTH}
        name="message-body"
        placeholder={placeholder}
        value={body}
        disabled={disabled}
        onChange={(e) => setBody(e.target.value)}
      />
      <Sender>
        {body.length}/{MAX_LENGTH}
        <Button
          className="btn btn-primary"
          disabled={buttonDisabled || disabled}
          onClick={async () => {
            setButtonDisabled(true);
            await onCreate(body, newMessagePhone);
            setButtonDisabled(false);
            setBody('');
            refetch();
          }}
        >
          Send SMS
        </Button>
      </Sender>
    </>
  );
};

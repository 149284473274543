(function () {
  angular.module('app').filter('boolean', function () {
    return function (input) {
      if (input === false) {
        return '✘';
      } else if (input === true) {
        return '✔';
      }
      return 'error';
    };
  });
})();

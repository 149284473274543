import * as React from 'react';

import { DEFAULT_HEIGHT } from '@admin/utils/subscribe';

import { Currency } from '@shared/components/helpers';

export const CustomDimensionsFormGroup: React.FC<{
  length?: number;
  width?: number;
  quantity?: number;
  msrp?: number;
  onLength(length?: number): void;
  onWidth(width?: number): void;
  onQuantity(quantity?: number): void;
}> = ({ length, width, quantity, msrp, onLength, onWidth, onQuantity }) => (
  <div className="form-group">
    <label className="control-label col-sm-2">Dimensions:</label>
    <div className="col-sm-2">
      <div className="input-group">
        <span className="input-group-addon">Length</span>
        <input
          required
          className="form-control"
          type="number"
          step={0.5}
          min={1.0}
          value={length || ''}
          onChange={(event) => {
            onLength(Number(event.target.value) || undefined);
          }}
        />
        <span className="input-group-addon ft-addon">ft</span>
      </div>
      {length && width && <div className="text-center">SQFT: {length * width}</div>}
    </div>
    <div className="col-sm-2">
      <div className="input-group">
        <span className="input-group-addon">Width</span>
        <input
          required
          className="form-control"
          type="number"
          step={0.5}
          min={1.0}
          value={width || ''}
          onChange={(event) => {
            onWidth(Number(event.target.value) || undefined);
          }}
        />
        <span className="input-group-addon ft-addon">ft</span>
      </div>
      {length && width && <div className="text-center">CUFT: {length * width * DEFAULT_HEIGHT}</div>}
    </div>
    <div className="col-sm-2">
      <div className="input-group">
        <span className="input-group-addon">$</span>
        <input
          disabled={!length || !width}
          required
          className="form-control"
          type="number"
          step={1.0}
          min={1.0}
          value={quantity || ''}
          onChange={(event) => {
            onQuantity(Number(event.target.value) || undefined);
          }}
        />
      </div>
      {msrp && (
        <div className="text-center">
          Clutter Equivalent: <Currency value={msrp} />
        </div>
      )}
    </div>
  </div>
);

(function () {
  const app = angular.module('app');

  app.factory('AccountService', function () {
    const service = {
      addPhoneToList: addPhoneToList,
      customerCounter: 0,
      deletePhoneFromView: deletePhoneFromView,
      getCustomerNames: getCustomerNames,
      getCustomersToEdit: getCustomersToEdit,
      getCustomersToSave: getCustomersToSave,
      getEmptyCustomer: getEmptyCustomer,
      getEmptyPhone: getEmptyPhone,
      getNonDeletedPhones: getNonDeletedPhones,
      getPhoneList: getPhoneList,
      getPrimaryCustomer: getPrimaryCustomer,
      getPrimaryEmail: getPrimaryEmail,
      getPrimaryPhone: getPrimaryPhone,
      hasMultipleCustomers: hasMultipleCustomers,
      hasNonEmptyPhone: hasNonEmptyPhone,
      hasPrimaryEmail: hasPrimaryEmail,
      isEmptyCustomer: isEmptyCustomer,
      isEmptyPhone: isEmptyPhone,
      isPrimaryCustomer: isPrimaryCustomer,
      phoneCounter: 0,
      setPrimaryEmail: setPrimaryEmail,
      setPrimaryPhone: setPrimaryPhone,
      sortForCustomerList: sortForCustomerList,
      sortForEmailList: sortForEmailList,
      sortPhonesForOneCustomer: sortPhonesForOneCustomer,
      validateCustomers: validateCustomers,
    };

    return service;

    function addPhoneToList(phoneList) {
      phoneList.push(service.getEmptyPhone());
      return phoneList;
    }

    function deletePhoneFromView(phone, phoneList) {
      if (phone.id) {
        phone.deleted = true;
      } else {
        phoneList = _.reject(phoneList, function (current) {
          return phone === current;
        });
      }

      // Make sure the view contains at least one phone, even if that phone is empty.
      if (!service.getNonDeletedPhones(phoneList).length) {
        phoneList = service.addPhoneToList(phoneList);
      }

      return phoneList;
    }

    function getCustomerNames(account, separator) {
      if (account && account.customers) {
        const sortedCustomers = service.sortForCustomerList(account.customers);
        separator = angular.isDefined(separator) ? separator : ', ';
        return _.map(sortedCustomers, 'name').join(separator);
      }
    }

    // Use angular.copy to stick to the rule "to never change an object or
    // array property in the component scope"
    // https://docs.angularjs.org/guide/component
    function getCustomersToEdit(customerData) {
      let customers = angular.copy(customerData);
      customers = service.sortForCustomerList(customers);
      customers.forEach(function (customer) {
        customer.phones = getPhonesToEdit(customer.phones);
        customer.tempId = 'existing-' + customer.id;
      });
      return customers;
    }

    function setPrimaryToFalse(phones) {
      _.each(phones, function (phone) {
        if (_.isNil(phone.primary)) {
          phone.primary = false;
        }
      });
    }

    function getCustomersToSave(customerData) {
      let customers = angular.copy(customerData);

      // Remove any "empty" customers
      customers = _.reject(customers, service.isEmptyCustomer);

      // Remove any "empty" phones
      customers.forEach(function (customer) {
        customer.phones = _.reject(customer.phones, service.isEmptyPhone);
      });

      customers.forEach(function (customer) {
        setPrimaryToFalse(customer.phones);
      });

      return customers;
    }

    function getDuplicateEmails(customers) {
      const duplicates = [];
      const emails = _.groupBy(customers, function (customer) {
        return customer.email;
      });

      for (const email in emails) {
        if (emails[email].length > 1) {
          duplicates.push(email);
        }
      }

      return duplicates;
    }

    function getDuplicatePhones(customers) {
      const duplicates = [];
      let phoneList = customers.reduce(function (array, customer) {
        return array.concat(customer.phones);
      }, []);
      phoneList = _.reject(phoneList, function (phone) {
        return !phone.number;
      });
      const phoneObj = _.groupBy(phoneList, function (phone) {
        return phone.number;
      });

      for (const number in phoneObj) {
        if (phoneObj[number].length > 1) {
          duplicates.push(number);
        }
      }

      return duplicates;
    }

    function getEmptyCustomer(accountId) {
      service.customerCounter++;

      return {
        account_id: accountId,
        email: '',
        name: '',
        phones: [service.getEmptyPhone()],
        primary_email: false,
        tempId: 'new-' + service.customerCounter,
      };
    }

    function getEmptyPhone() {
      service.phoneCounter++;

      return {
        kind: 'unknown',
        number: '',
        primary: false,
        tempId: 'new-' + service.phoneCounter,
      };
    }

    function getNonDeletedPhones(phoneList) {
      return _.reject(phoneList, function (phone) {
        return phone.deleted;
      });
    }

    function getPhoneList(customers) {
      let result = [];
      const sortedCustomers = service.sortForCustomerList(customers);

      sortedCustomers.forEach(function (customer) {
        if (customer.phones) {
          const sortedPhones = service.sortPhonesForOneCustomer(customer.phones);

          sortedPhones.forEach(function (phone) {
            phone.name = customer.name;
          });

          result = result.concat(sortedPhones);
        }
      });

      return result;
    }

    function getPhonesToEdit(phoneList) {
      let phonesToEdit;

      if (phoneList.length) {
        phonesToEdit = service.sortPhonesForOneCustomer(phoneList);
        phonesToEdit.forEach(function (phone) {
          phone.tempId = 'existing-' + phone.id;
        });
      } else {
        phonesToEdit = service.addPhoneToList(phoneList);
      }

      return phonesToEdit;
    }

    function hasNonEmptyPhone(customer) {
      return _.some(customer.phones, function (phone) {
        return !service.isEmptyPhone(phone);
      });
    }

    function getPrimaryCustomer(accountOrCustomers) {
      const customers = accountOrCustomers.customers || accountOrCustomers;
      return _.find(customers, isPrimaryCustomer);
    }

    function getPrimaryEmail(account) {
      return _.find(account.customers, { primary_email: true }).email;
    }

    function getPrimaryPhone(account) {
      if (account && account.customers) {
        const primaryCustomer = service.getPrimaryCustomer(account.customers);
        return _.find(primaryCustomer.phones, { primary: true });
      }
    }

    function hasMultipleCustomers(account) {
      return account.customers.length > 1;
    }

    function hasPrimaryEmail(customer) {
      return customer && customer.primary_email;
    }

    function isEmptyCustomer(customer) {
      return !customer.id && !customer.name && !customer.email && !service.hasNonEmptyPhone(customer);
    }

    function isEmptyPhone(phone) {
      return !phone.id && !phone.number;
    }

    function isPrimaryCustomer(customer) {
      let isPrimary = false;

      if (customer && customer.phones) {
        isPrimary = customer.phones.some(function (number) {
          return number.primary;
        });
      }

      return isPrimary;
    }

    function setPrimaryEmail(customers, customerTempId) {
      customers.forEach(function (customer) {
        customer.primary_email = customer.tempId === customerTempId;
      });
      return customers;
    }

    function setPrimaryPhone(customers, phoneTempId) {
      customers.forEach(function (customer) {
        customer.phones.forEach(function (phone) {
          phone.primary = phone.tempId === phoneTempId;
        });
      });
      return customers;
    }

    function sortAllAfterPrimary(customers, primaryFunction) {
      const primaryIndex = _.findIndex(customers, primaryFunction);
      const primaryCustomer = customers[primaryIndex];
      const result = customers.slice();

      result.splice(primaryIndex, 1);
      result.sort(function (a, b) {
        return a.name.localeCompare(b.name);
      });

      if (primaryCustomer) {
        result.unshift(primaryCustomer);
      }

      return result;
    }

    function sortForCustomerList(customers) {
      return sortAllAfterPrimary(customers, service.isPrimaryCustomer);
    }

    function sortForEmailList(customers) {
      return sortAllAfterPrimary(customers, hasPrimaryEmail).filter(function (customer) {
        return customer.email;
      });
    }

    function sortPhonesForOneCustomer(phones) {
      const primaryIndex = _.findIndex(phones, { primary: true });
      const primaryPhone = phones[primaryIndex];
      let result = phones.slice();

      if (primaryIndex === -1) {
        result = _.sortBy(result, 'number');
      } else {
        result.splice(primaryIndex, 1);
        result = _.sortBy(result, 'number');
        result.unshift(primaryPhone);
      }

      return result;
    }

    function validateCustomers(customers) {
      const duplicateEmails = getDuplicateEmails(customers);
      const duplicatePhones = getDuplicatePhones(customers);
      let isValid = true;
      const messages = [];

      if (duplicateEmails.length) {
        isValid = false;
        duplicateEmails.forEach(function (email) {
          const message =
            'It looks like you entered email ' +
            email +
            ' multiple times. Please remove the duplicates and try saving again.';
          messages.push(message);
        });
      }

      if (duplicatePhones.length) {
        isValid = false;
        duplicatePhones.forEach(function (phone) {
          const message =
            'It looks like you entered phone ' +
            phone +
            ' multiple times. Please remove the duplicates and try saving again.';
          messages.push(message);
        });
      }

      return {
        isValid: isValid,
        messages: messages,
      };
    }
  });
})();

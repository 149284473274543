import React from 'react';
import { Form as FormikForm, Formik, FormikErrors } from 'formik';

import { client } from '@admin/libraries/apollo';
import {
  Status,
  Day,
  useWarehousesSelectorQuery,
  useAvailabilityOperatingScheduleTemplateCreateMutation,
  Availability__OperatingScheduleTemplateCreate__SchedulableType,
  useRegionsSelectorQuery,
  Availability__JobAvailabilityKindEnum,
} from '@admin/schema';
import { Alert, AnchorButton, Breadcrumb, Button, Panel } from '@shared/components/bootstrap';
import { FormikInputFormGroup } from '@shared/components/fields/formik/formik_input_form_group';
import { FormikMultiselectFormGroup } from '@admin/components/fields/formik/formik_multiselect_form_group';
import { PageHeader } from '@admin/components/helpers/page_header';
import { FormikCheckboxFormGroup } from '@admin/components/fields/formik/formik_checkbox_form_group';
import { jobAvailabilityListURL, warehouseCapacityListURL } from '@admin/config/routes';
import styled from '@emotion/styled';
import { KIND_OPTIONS } from '../job_availability/utils';

type FormInput = {
  effectiveDate: string;
  warehouseIDs: string[];
  regionIDs: string[];
  sunday: boolean;
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  jobKinds?: Availability__JobAvailabilityKindEnum[];
};

const DEFAULT_FORM_VALUES = {
  effectiveDate: '',
  warehouseIDs: [],
  regionIDs: [],
  sunday: false,
  monday: false,
  tuesday: false,
  wednesday: false,
  thursday: false,
  friday: false,
  saturday: false,
  jobKinds: [],
};

const WarningMessage = styled.p`
  color: orange;
  margin-top: -14px;
`;

export const WarehouseOperatingScheduleTemplateCreateForm: React.FC = () => (
  <OperatingScheduleTemplateCreateForm
    schedulableType={Availability__OperatingScheduleTemplateCreate__SchedulableType.Warehouse}
  />
);

export const RegionOperatingScheduleTemplateCreateForm: React.FC = () => (
  <OperatingScheduleTemplateCreateForm
    schedulableType={Availability__OperatingScheduleTemplateCreate__SchedulableType.Region}
  />
);

const OperatingScheduleTemplateCreateForm: React.FC<{
  schedulableType: Availability__OperatingScheduleTemplateCreate__SchedulableType;
}> = ({ schedulableType }) => {
  const [save, { loading, data }] = useAvailabilityOperatingScheduleTemplateCreateMutation({ client });
  const { data: warehousesData } = useWarehousesSelectorQuery({ client, variables: { filters: { active: true } } });
  const { data: regionsData } = useRegionsSelectorQuery({ client });

  const warehouses = warehousesData?.warehouses ?? [];
  const regions = regionsData?.regions ?? [];

  const previousPageURL = () => {
    switch (schedulableType) {
      case Availability__OperatingScheduleTemplateCreate__SchedulableType.Warehouse:
        return warehouseCapacityListURL();
      case Availability__OperatingScheduleTemplateCreate__SchedulableType.Region:
        return jobAvailabilityListURL();
    }
  };

  const validateInput = ({ warehouseIDs, regionIDs, jobKinds }: FormInput): FormikErrors<FormInput> => {
    const formErrors: FormikErrors<FormInput> = {};

    if (
      schedulableType === Availability__OperatingScheduleTemplateCreate__SchedulableType.Warehouse &&
      (!warehouseIDs || warehouseIDs.length === 0)
    ) {
      formErrors.warehouseIDs = 'At least one warehouse is required';
    }

    if (schedulableType === Availability__OperatingScheduleTemplateCreate__SchedulableType.Region) {
      if (!regionIDs || regionIDs.length === 0) {
        formErrors.regionIDs = 'At least one region is required';
      }

      if (!jobKinds || jobKinds.length === 0) {
        formErrors.jobKinds = 'At least one job kind is required';
      }
    }

    return formErrors;
  };

  const onSubmit = async ({
    effectiveDate,
    warehouseIDs,
    regionIDs,
    jobKinds,
    sunday,
    monday,
    tuesday,
    wednesday,
    thursday,
    friday,
    saturday,
  }: FormInput) => {
    const operatingDays: Day[] = [];
    if (sunday) {
      operatingDays.push(Day.Sunday);
    }
    if (monday) {
      operatingDays.push(Day.Monday);
    }
    if (tuesday) {
      operatingDays.push(Day.Tuesday);
    }
    if (wednesday) {
      operatingDays.push(Day.Wednesday);
    }
    if (thursday) {
      operatingDays.push(Day.Thursday);
    }
    if (friday) {
      operatingDays.push(Day.Friday);
    }
    if (saturday) {
      operatingDays.push(Day.Saturday);
    }

    const schedulableIDs = () => {
      switch (schedulableType) {
        case Availability__OperatingScheduleTemplateCreate__SchedulableType.Warehouse:
          return warehouseIDs;
        case Availability__OperatingScheduleTemplateCreate__SchedulableType.Region:
          return regionIDs;
      }
    };

    const results = await save({
      variables: {
        input: {
          effectiveDate,
          schedulableIDs: schedulableIDs(),
          schedulableType: schedulableType,
          operatingDays: operatingDays,
          jobKinds:
            schedulableType === Availability__OperatingScheduleTemplateCreate__SchedulableType.Region
              ? jobKinds
              : undefined,
        },
      },
    });

    if (results.data?.result.status === Status.Ok) {
      window.location.href = previousPageURL();
    }
  };

  return (
    <>
      <PageHeader>
        <Breadcrumb>
          {schedulableType === Availability__OperatingScheduleTemplateCreate__SchedulableType.Warehouse && (
            <Breadcrumb.Item>
              <a href={warehouseCapacityListURL()}>Warehouse Capacity</a>
            </Breadcrumb.Item>
          )}
          {schedulableType === Availability__OperatingScheduleTemplateCreate__SchedulableType.Region && (
            <Breadcrumb.Item>
              <a href={jobAvailabilityListURL()}>Job Availability</a>
            </Breadcrumb.Item>
          )}
          <Breadcrumb.Item active>New Weekly Schedule</Breadcrumb.Item>
        </Breadcrumb>
      </PageHeader>
      <Formik<FormInput>
        initialValues={DEFAULT_FORM_VALUES}
        onSubmit={onSubmit}
        validate={validateInput}
        validateOnChange={false}
        validateOnBlur={false}
      >
        <FormikForm>
          <Panel>
            <Panel.Body>
              {data?.result?.status === Status.Ok && (
                <Alert style="success">{'Successfully updated warehouse operating schedules.'}</Alert>
              )}
              <FormikInputFormGroup id="effectiveDate" type="date" name="effectiveDate" label="Effective Date:" />
              {schedulableType === Availability__OperatingScheduleTemplateCreate__SchedulableType.Warehouse && (
                <>
                  <WarningMessage>
                    The weekly operating schedule for the selected warehouses will be updated from the effective date
                    forward. Any existing weekly schedules in the future will be overwritten.
                  </WarningMessage>
                  <FormikMultiselectFormGroup
                    id="warehouseIDs"
                    name="warehouseIDs"
                    label="Warehouses:"
                    entries={warehouses}
                  />
                </>
              )}
              {schedulableType === Availability__OperatingScheduleTemplateCreate__SchedulableType.Region && (
                <>
                  <WarningMessage>
                    The weekly operating schedule for the selected regions will be updated from the effective date
                    forward. Any existing weekly schedules in the future will be overwritten.
                  </WarningMessage>
                  <FormikMultiselectFormGroup id="regionIDs" name="regionIDs" label="Regions:" entries={regions} />
                  <FormikMultiselectFormGroup id="jobKinds" name="jobKinds" label="Job Kinds:" entries={KIND_OPTIONS} />
                </>
              )}
              <FormikCheckboxFormGroup label="Open Sunday" id="sunday" name="sunday" />
              <FormikCheckboxFormGroup label="Open Monday" id="monday" name="monday" />
              <FormikCheckboxFormGroup label="Open Tuesday" id="tuesday" name="tuesday" />
              <FormikCheckboxFormGroup label="Open Wednesday" id="wednesday" name="wednesday" />
              <FormikCheckboxFormGroup label="Open Thursday" id="thursday" name="thursday" />
              <FormikCheckboxFormGroup label="Open Friday" id="friday" name="friday" />
              <FormikCheckboxFormGroup label="Open Saturday" id="saturday" name="saturday" />
            </Panel.Body>
            <Panel.Footer align="right">
              <AnchorButton disabled={loading} kind="default" href={previousPageURL()}>
                Cancel
              </AnchorButton>
              <Button loading={loading} kind="primary" type="submit">
                Save
              </Button>
            </Panel.Footer>
          </Panel>
        </FormikForm>
      </Formik>
    </>
  );
};

/* eslint-disable consistent-return */
import angular from 'angular';
import { map, max, min } from 'lodash';

angular.module('app').controller('DispatcherDispatchController', [
  '$scope',
  'ngDialog',
  'TZDateTimeService',
  'DispatcherConstants',
  function ($scope, ngDialog, TZDateTimeService, DispatcherConstants) {
    const { ARRIVAL_VALID_OFFSET } = DispatcherConstants;

    $scope.stopEditing = () => {
      if ($scope.editing) {
        $scope.editing = false;
        $scope.$apply();
      }
    };

    const updateDispatchArrival = () => {
      $scope.arrival = TZDateTimeService.utcForValueWithTZ($scope.dispatch.arrival, $scope.region.tz);
    };

    const updateDispatchBreakAt = () => {
      $scope.break_at = TZDateTimeService.utcForValueWithTZ($scope.dispatch.break_at, $scope.region.tz);
    };

    const getDispatchInfo = (dispatch, filters) => {
      const travel = moment.duration(
        DispatcherConstants.TRAVEL_WINDOW_DURATION,
        DispatcherConstants.TRAVEL_WINDOW_UNITS,
      );

      let date = filters.date ? moment(filters.date) : null;
      const arrival = dispatch.arrival ? moment(dispatch.arrival) : null;
      let start = min(map(dispatch.orders, (order) => moment(order.scheduled).subtract(travel)));

      let until = max(
        map(dispatch.orders, (order) => {
          const duration = moment.duration(
            order.estimated_duration || DispatcherConstants.DEFAULT_ESTIMATED_DURATION,
            DispatcherConstants.ESTIMATED_DURATION_UNITS,
          );
          return moment(order.scheduled).add(duration + travel);
        }),
      );

      if (arrival < start) {
        start = arrival;
      }
      if (arrival > until) {
        until = arrival;
      }

      if (start) {
        start = moment.utc({
          hour: start.hour(),
          minute: start.minute(),
          second: start.second(),
        });
      }

      if (until) {
        until = moment.utc({
          hour: until.hour(),
          minute: until.minute(),
          second: until.second(),
        });
      }

      if (date) {
        date = moment.utc({
          day: date.day(),
          month: date.month(),
          year: date.year(),
        });
      }

      return {
        date: filters.date,
        start_time: start,
        until_time: until,
      };
    };

    $scope.init = function (dispatch, region, date, depots, warehouses, vehicles) {
      $scope.date = date;
      $scope.region = region;
      $scope.dispatch = dispatch;
      $scope.dispatchInfo = getDispatchInfo(dispatch, this.filters);
      $scope.editing = false;
      $scope.dispatchDuration = dispatch.formatted_simulated_duration;

      $scope.$watch('dispatch.arrival', updateDispatchArrival);
      $scope.$watch('dispatch.break_at', updateDispatchBreakAt);

      $scope.depots = depots;
      $scope.warehouses = warehouses;
      $scope.vehicles = vehicles;

      $scope.filter();
    };

    $scope.onDispatchUpdated = function (dispatch) {
      $scope.dispatch = dispatch;
      $scope.dispatchInfo = getDispatchInfo(dispatch, this.filters);
    };

    $scope.filter = function () {
      const regionWarehouses = $scope.warehouses.filter(
        (warehouse) => $scope.region && _.find(warehouse.region_ids, (id) => $scope.region.id === id),
      );
      $scope.options = {
        depots: $scope.depots.filter(
          (depot) => $scope.region && _.find(depot.region_ids, (id) => $scope.region.id === id),
        ),
        warehouses: regionWarehouses,
      };
    };

    $scope.addons_formatted = () => $scope.dispatch.addons.join(', ');
    $scope.vehicleRequestFormatted = () => {
      const vehicleTypeNamesMap = {};
      $scope.vehicleTypes.forEach((type) => {
        vehicleTypeNamesMap[type.id] = type.name;
      });
      const prefFormatted = $scope.dispatch.vehicle_preferences
        .map((pref) => `${pref.quantity}${vehicleTypeNamesMap[pref.vehicle_type_id]}`)
        .join(', ');

      let recipeFormatted = '';
      if ($scope.dispatch.vehicle_recipe) {
        const recipe = $scope.dispatch.vehicle_recipe;
        recipeFormatted = `${recipe.max_quantity} trucks max; ${recipe.minimum_cuft} CUFT min`;
      }
      return `${prefFormatted}; ${recipeFormatted}`;
    };

    $scope.vehicleDetailsFormatted = () => {
      if ($scope.dispatch.vehicles.length > 0) {
        return $scope.dispatch.vehicles.map((v) => v.serial_code).join(', ');
      } else if ($scope.dispatch.vehicle_preferences.length > 0 || $scope.dispatch.vehicle_recipe) {
        return $scope.vehicleRequestFormatted();
      }
    };

    $scope.errors = function () {
      if ($scope.dispatch.arrival) {
        const arrival = moment($scope.dispatch.arrival);
        const orders = $scope.dispatch.orders
          .filter((order) => {
            const scheduledWithOffset = moment(order.scheduled).add(order.dispatch_offset, 'seconds');
            const diff = scheduledWithOffset.diff(arrival, 'minutes');
            return diff < ARRIVAL_VALID_OFFSET && diff > -200;
          })
          .map((order) => `#${order.number} (${order.account.customers[0].name}) invalid scheduled for arrival`);
        if (orders.length > 0) {
          return orders;
        }
      }
    };

    $scope.reschedule = (time) => {
      const { date } = $scope;
      const { tz } = $scope.region;
      $scope.dispatch.arrival = TZDateTimeService.localForDateTimeWithTZ(date, time, tz);
    };

    $scope.schedule_break = (time) => {
      const { date } = $scope;
      const { tz } = $scope.region;
      $scope.dispatch.break_at = TZDateTimeService.localForDateTimeWithTZ(date, time, tz);
    };

    $scope.displayDispatcherInstructions = () => {
      ngDialog.open({
        template: 'partials/dispatchers/instructions.html',
        controller: 'DispatcherInstructionsController',
        resolve: {
          dispatch: () => $scope.dispatch,
        },
      });
    };

    $scope.displayDispatcherVehicleRequests = () => {
      ngDialog.open({
        template: 'partials/dispatchers/vehicle_requests.html',
        controller: 'DispatcherVehicleRequestsController',
        resolve: {
          dispatch: () => $scope.dispatch,
        },
      });
    };
  },
]);

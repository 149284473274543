import styled from '@emotion/styled';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';

import { Field } from './field';

const TextArea = styled.textarea`
  resize: vertical;
  min-height: 34px;
`;

export const PriceOverrideReasonField: React.FC = () => {
  const { register } = useFormContext<{
    priceOverride: {
      reason?: string;
    };
  }>();

  return (
    <Field required id="price-override-reason" name="priceOverride.reason" label="Price Override Reason">
      <TextArea
        id="price-override-reason"
        className="form-control"
        name="priceOverride.reason"
        rows={2}
        ref={register({
          required: 'Please provide a price override reason',
          minLength: {
            value: 4,
            message: 'Please enter detailed price override reason',
          },
        })}
      />
    </Field>
  );
};

import 'angular-google-maps';
import 'angular-resource';
import 'angular-simple-logger';
import 'angular-ui-router';
import 'angular-messages';
import 'angular-moment';
import 'angular-sanitize';
import 'angular-ui-bootstrap';
import 'isteven-angular-multiselect/isteven-multi-select';
import 'isteven-angular-multiselect/isteven-multi-select.css';
import 'ng-dialog';
import 'ng-dialog/css/ngDialog-theme-default.css';
import 'ng-dialog/css/ngDialog.css';
import 'ng-tags-input';
import 'ng-tags-input/build/ng-tags-input.css';
import 'nginflection';
import 'ngreact';
import 'ngstorage';
import 'ng-redux';
import angular from 'angular';
import { compact } from 'lodash';
import moment from 'moment';
import '../style-loaders';
import rootReducer from '../redux';

const dependencies = [
  'ui.router',
  'uiGmapgoogle-maps',
  'ngResource',
  'ngSanitize',
  'ngDialog',
  'ngInflection',
  'ngMessages',
  'ngStorage',
  'angularMoment',
  'app:run',
  'ui.bootstrap',
  'ngTagsInput',
  'isteven-multi-select',
  'react',
  'ngRedux',
];

if (window.CONFIG.sentry_dsn) {
  dependencies.push('ngSentry');
}

const app = angular.module('app', dependencies);

moment.tz.link(['America/Los_Angeles|US/Pacific', 'America/New_York|US/Eastern']);

app.config([
  'uiGmapGoogleMapApiProvider',
  function (uiGmapGoogleMapApiProvider) {
    uiGmapGoogleMapApiProvider.configure({
      key: window.clutter.config.google.mapsApiKey,
      libraries: 'places',
    });
  },
]);

// TODO: remove when rejected promises are handled.
app.config([
  '$qProvider',
  function ($qProvider) {
    $qProvider.errorOnUnhandledRejections(false);
  },
]);

app.run([
  '$rootScope',
  function ($rootScope) {
    $rootScope.$on('$locationChangeSuccess', function () {
      angular.element(document).find('body').removeClass('modal-open');
      angular.element(document).find('.modal-backdrop').remove();
    });
  },
]);

app.config([
  '$ngReduxProvider',
  ($ngReduxProvider) =>
    $ngReduxProvider.createStoreWith(
      rootReducer,
      compact(['thunkFactory']),
      compact([window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()]),
      window.REDUX_STATE || {},
    ),
]);

window.app = app;

import { Duration } from 'luxon';

import angular from 'angular';

angular.module('app').filter('toTime', () => (input) => {
  const number = Math.round(Number(input)) || 0;
  if (number <= 0) {
    return '--:--';
  }

  return Duration.fromObject({ seconds: number }).toFormat('hh:mm:ss');
});

import axios from 'axios';
import * as React from 'react';
import { useEffect, useState } from 'react';

import { Timestamp } from '@shared/components/helpers';

interface IStats {
  latest_export_at?: string;
}

export const Stats = () => {
  const [stats, setStats] = useState<IStats | undefined>(undefined);

  useEffect(() => {
    const request = axios.get<IStats>('/gratuities/stats.json');
    request.then(({ data }) => setStats(data));
  }, []);

  if (!stats || !stats.latest_export_at) {
    return null;
  }

  return (
    <span className="mar-hor">
      Exported
      <>
        {' '}
        on <Timestamp value={stats.latest_export_at} format={Timestamp.Format.Date} />
      </>
      <>
        {' '}
        at <Timestamp value={stats.latest_export_at} format={Timestamp.Format.Time} />
      </>
    </span>
  );
};

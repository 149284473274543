import { UserNotificationPreferenceEnum } from '@admin/schema';
import { Phone } from '@shared/components/helpers/phone';
import React from 'react';

type Option = { label: React.ReactNode; value: UserNotificationPreferenceEnum };

export const PREFERENCE_TO_LABEL = {
  [UserNotificationPreferenceEnum.PersonalEmail]: 'Personal email',
  [UserNotificationPreferenceEnum.PersonalPhone]: 'SMS',
  [UserNotificationPreferenceEnum.PersonalEmailAndPhone]: 'SMS + personal email',
  [UserNotificationPreferenceEnum.WorkEmailOnly]: 'Work email only',
};

export const userNotificationPreferenceOptions = (phone?: string) =>
  [
    { label: 'Personal email', value: UserNotificationPreferenceEnum.PersonalEmail },
    phone && {
      label: (
        <>
          SMS to <Phone value={phone} />)
        </>
      ),
      value: UserNotificationPreferenceEnum.PersonalPhone,
    },
    phone && { label: 'SMS + personal email', value: UserNotificationPreferenceEnum.PersonalEmailAndPhone },
    { label: 'Work email only', value: UserNotificationPreferenceEnum.WorkEmailOnly },
  ].filter((o) => !!o) as Option[];

(function () {
  angular.module('app').factory('Message', [
    '$resource',
    function ($resource) {
      return $resource(
        '/messages.json',
        {
          id: '@id',
          parent_type: '@parent_type',
          parent_id: '@parent_id',
          phone_id: '@phone_id',
        },
        {
          query: {
            method: 'GET',
            isArray: false,
          },
          retry: {
            method: 'PUT',
            isArray: false,
          },
          outgoing_allowed: {
            url: '/messages/outgoing_allowed.json',
            method: 'GET',
            isArray: false,
          },
        },
      );
    },
  ]);
})();

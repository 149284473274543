import { Panel } from '@admin/components/helpers/panel';
import { logisticsLoadBolURL, logisticsLoadPackingSlipURL, logisticsLoadShippingLabelsURL } from '@admin/config/routes';
import { LogisticsLoadQuery } from '@admin/schema';
import styled from '@emotion/styled';
import { faFile } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { COLORS } from '@root/colors';

import React, { useState } from 'react';

type LogisticsLoad = LogisticsLoadQuery['load'];

const StyledIcon = styled(FontAwesomeIcon)`
  margin: 0 5px 0 0;
  color: ${COLORS.grayDarkest};
`;

const StyledCol = styled.div`
  text-align: center;
`;
export const LogisticsLoadDocumentsPanel: React.FC<{ load: LogisticsLoad }> = ({ load }) => {
  const [collapsed, setCollapsed] = useState<boolean>(false);
  return (
    <Panel>
      <Panel.Header>
        <Panel.Collapse collapsed={collapsed} onChange={setCollapsed} />
        <Panel.Title>Load Documents</Panel.Title>
      </Panel.Header>
      <Panel.Body>
        {!collapsed && (
          <div className="row">
            <StyledCol className="col-md-3">
              <a href={logisticsLoadBolURL({ id: load.id })} target="_blank">
                <StyledIcon icon={faFile} size="6x" />
                <div>Bill(s) of Lading</div>
              </a>
            </StyledCol>
            <StyledCol className="col-md-3">
              <a href={logisticsLoadShippingLabelsURL({ id: load.id }) + '?type=load,pallet'} target="_blank">
                <StyledIcon icon={faFile} size="6x" />
                <div>Pallet Labels</div>
              </a>
            </StyledCol>
            <StyledCol className="col-md-3">
              <a href={logisticsLoadShippingLabelsURL({ id: load.id }) + '?type=load,item'} target="_blank">
                <StyledIcon icon={faFile} size="6x" />
                <div>Item Labels</div>
              </a>
            </StyledCol>
            <StyledCol className="col-md-3">
              <a href={logisticsLoadPackingSlipURL({ id: load.id }) + '?type=load,pallet'} target="_blank">
                <StyledIcon icon={faFile} size="6x" />
                <div>Packing Slips</div>
              </a>
            </StyledCol>
          </div>
        )}
      </Panel.Body>
    </Panel>
  );
};

import React, { useState } from 'react';

import { Panel } from '@admin/components/helpers/panel';
import { Image, LogisticsLoadQuery } from '@admin/schema';
import { Timestamp } from '@shared/components/helpers';
import { Lightbox } from '@shared/components/helpers/lightbox';

type LogisticsLoad = LogisticsLoadQuery['load'];

function notNullImage<Image>(image: Image | null | undefined): image is Image {
  return image != null;
}

export const LogisticsLoadImagesPanel: React.FC<{ load: LogisticsLoad }> = ({ load }) => {
  const [selection, setSelection] = useState<string | undefined>(undefined);
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const { openTrailerImages, fullTrailerImages, trailerNumberImage, sealCodeImage, sealedTrailerImage } = load;

  const singleImages: Image[] = [trailerNumberImage, sealCodeImage, sealedTrailerImage].filter(notNullImage);
  const images = openTrailerImages.concat(fullTrailerImages).concat(singleImages);

  return (
    <Panel>
      <Panel.Header>
        <Panel.Collapse collapsed={collapsed} onChange={setCollapsed} />
        <Panel.Title>Load Photos</Panel.Title>
      </Panel.Header>
      <Panel.Body>
        <div className="grid">
          <div className="row">
            {!collapsed &&
              images &&
              images
                .sort((a, b) => (a.createdAt < b.createdAt ? -1 : 1))
                .map((image) => (
                  <div key={image.id} className="entry col-xs-6 col-sm-4 col-md-3 col-lg-2">
                    <ul className="list-unstyled">
                      <li>{image.name}</li>
                      <li>
                        <Timestamp value={image.createdAt} format={Timestamp.Format.DateTime} />
                      </li>
                    </ul>
                    <figure className="brick-square">
                      <a
                        href="#"
                        onClick={(event) => {
                          event.preventDefault();
                          event.stopPropagation();
                          setSelection(image.source);
                        }}
                      >
                        <img className="location-image" src={image.size} />
                      </a>
                    </figure>
                  </div>
                ))}
            {selection && <Lightbox imageURL={selection} onClose={() => setSelection(undefined)} />}
          </div>
        </div>
      </Panel.Body>
    </Panel>
  );
};

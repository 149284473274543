import styled from '@emotion/styled';
import React, { useState } from 'react';
import { Status, useLogisticsSkuBulkUploadPreviewLazyQuery, useSkuBulkUploadMutation } from '@admin/schema';
import { client } from '@admin/libraries/apollo';
import { UserRole } from '@admin/types';
import { Button, FormGroup } from '@shared/components/bootstrap';
import { Roles } from '@admin/components/helpers/roles';
import { Manager, Selector, Uploader } from '@shared/components/files';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Panel } from '@admin/components/helpers/panel';
import { SpinLoader } from '@clutter/clean';
import { SkuBulkUploadPreview, SkuTableSkeletonLoader } from './sku_bulk_upload_preview';

const SELECTOR_OPTIONS = {
  multiple: false,
  accept: 'text/csv',
};

const Actions = styled.div`
  display: flex;
  margin: -4px;
  justify-content: flex-end;
`;

const Action = styled.div`
  padding: 4px;
`;

const ErrorMessage = styled.p`
  color: red;
`;

const Instructions: React.FC = () => (
  <p>
    SKUs can be uploaded in bulk by uploading a CSV. SKUs with duplicate values are not permitted. SKUs with values that
    already exist in the database will be updated. SKUs with new values will be inserted. Download and replace the
    following template:{' '}
    <a href="/files/logistics_skus.csv" className="btn-link" download target="_blank">
      logistics_skus.csv
    </a>
  </p>
);

export const SkuBulkUploadForm: React.FC<{ accountID: string }> = ({ accountID }) => {
  const [file, setFile] = useState<File | null>(null);
  const [fileID, setFileID] = useState<string | null>(null);

  const [getSkus, { data, loading, error }] = useLogisticsSkuBulkUploadPreviewLazyQuery({
    client,
    fetchPolicy: 'no-cache',
  });
  const [upsert, { loading: upserting, data: upsertPayload, error: upsertError }] = useSkuBulkUploadMutation({
    client,
  });

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    event.stopPropagation();

    if (!fileID) {
      return;
    }

    upsert({ variables: { input: { file: fileID, accountID } } });
    setFile(null);
    setFileID(null);
  };

  const onUpload = (updatedFile: File, updatedFileID: string) => {
    setFile(updatedFile);
    setFileID(updatedFileID);
    getSkus({ variables: { input: { file: updatedFileID, accountID } } });
  };

  return (
    <Roles show={[UserRole.Admin]}>
      <form onSubmit={handleSubmit}>
        <Panel>
          <Panel.Header>
            <Panel.Title>Upload SKU CSV</Panel.Title>
          </Panel.Header>
          <Panel.Body>
            <Instructions />

            <FormGroup>
              <Manager onSave={onUpload}>
                {({ uploads }) => (
                  <>
                    <label className="btn btn-default">
                      <FontAwesomeIcon icon={'paperclip'} />
                      <Selector {...SELECTOR_OPTIONS} disabled={upserting} /> {file?.name ?? 'Choose File'}
                    </label>
                    {uploads.map((entry) => (
                      <Uploader key={entry.uuid} {...entry} />
                    ))}
                  </>
                )}
              </Manager>
            </FormGroup>
          </Panel.Body>

          <Panel.Footer>
            <Actions>
              <Action>
                <Button type="submit" kind="primary" disabled={!fileID || upserting || !!error} loading={upserting}>
                  Save
                </Button>
              </Action>
            </Actions>
          </Panel.Footer>

          {loading && <SkuTableSkeletonLoader />}
          {error && <ErrorMessage>There was an issue importing SKUs.</ErrorMessage>}
          {file && data?.preview && <SkuBulkUploadPreview preview={data.preview} />}

          {upserting && (
            <div>
              <SpinLoader />
            </div>
          )}

          {(upsertPayload || upsertError) &&
            (upsertPayload?.skuBulkUpload.status !== Status.Ok || upsertError ? (
              <ErrorMessage>There was an issue importing SKUs.</ErrorMessage>
            ) : (
              <p>Successfully imported SKUs.</p>
            ))}
        </Panel>
      </form>
    </Roles>
  );
};

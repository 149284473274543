import { IInvoiceActionFormProps } from '@admin/components/billing/invoices/types';
import { InputFormGroup } from '@admin/components/fields';
import { useBillingInvoiceReBillMutation } from '@admin/schema';
import { client } from '@admin/libraries/apollo';
import { pollForInvoiceEvent } from '@admin/utils/poll_for_invoice_event';
import { Button, Text } from '@shared/components/bootstrap';
import React, { useState } from 'react';
import { ReasonSelector } from './reason_selector';

export const InvoiceRebillForm: React.FC<IInvoiceActionFormProps> = ({ invoiceID, accountID, onSave, onCancel }) => {
  const [notes, setNotes] = useState<string | undefined>();
  const [reasonID, setReasonID] = useState<string | undefined>();
  const [error, setError] = useState<string | undefined>();
  const [polling, setPolling] = useState<boolean>(false);

  const [rebill, { loading }] = useBillingInvoiceReBillMutation({ client });

  const disabled = !notes || !reasonID;

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();

    if (loading || !notes || !reasonID) {
      return;
    }

    const response = await rebill({
      variables: {
        invoiceID,
        input: {
          notes,
          reasonID,
        },
      },
    });

    if (response?.data) {
      setPolling(true);
      await pollForInvoiceEvent(accountID, response.data.rebill.attemptID);
      setPolling(false);
      onSave(response.data.rebill.attemptID);
    } else {
      setError('Unknown error occurred. Please try again later.');
    }
  };

  return (
    <form onSubmit={onSubmit}>
      <ReasonSelector reasonID={reasonID} onSelect={setReasonID} invoiceID={invoiceID} />
      <InputFormGroup
        id="re_bill_notes"
        label="Notes:"
        type="text"
        placeholder="Notes"
        value={notes || ''}
        onChange={(event) => setNotes(event.target.value || undefined)}
      />
      {error && (
        <Text tag="p" style="danger" alignment="center">
          <strong>{error}</strong>
        </Text>
      )}
      <Text alignment="right" tag="div">
        <Button kind="danger" disabled={loading || polling} onClick={onCancel}>
          Cancel
        </Button>{' '}
        <Button kind="primary" type="submit" loading={loading || polling} disabled={disabled}>
          Re-Bill
        </Button>
      </Text>
    </form>
  );
};

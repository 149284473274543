import { Titleize } from '@admin/components/helpers/titleize';
import { SelfStorage__Rental__BillingEnum } from '@admin/schema';
import styled from '@emotion/styled';
import * as React from 'react';
import { useEffect } from 'react';

const BILLING_OPTIONS = [
  SelfStorage__Rental__BillingEnum.ChargeAutomatically,
  SelfStorage__Rental__BillingEnum.SendInvoice,
];

const PaddedLabel = styled.label`
  padding-left: 8px;
`;

export const BillingRadios: React.FC<{
  name: string;
  selectedBilling?: SelfStorage__Rental__BillingEnum;
  defaultBilling?: SelfStorage__Rental__BillingEnum;
  onSelect(billing: SelfStorage__Rental__BillingEnum): void;
}> = ({ name, selectedBilling, defaultBilling, onSelect }) => {
  useEffect(() => {
    if (selectedBilling === undefined) {
      if (defaultBilling) {
        onSelect(defaultBilling);
      } else {
        onSelect(SelfStorage__Rental__BillingEnum.ChargeAutomatically);
      }
    }
  }, []);

  return (
    <>
      {BILLING_OPTIONS.map((billingOption) => (
        <PaddedLabel key={billingOption}>
          <input
            type="radio"
            name={name}
            value={billingOption}
            checked={selectedBilling === billingOption}
            onChange={(event) => {
              if (event.target.checked) {
                onSelect(billingOption);
              }
            }}
          />{' '}
          <Titleize text={billingOption} />
        </PaddedLabel>
      ))}
    </>
  );
};

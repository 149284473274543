(() => {
  angular.module('app').service('SubscriptionService', [
    function () {
      const self = this;

      this.getSubscriptionsForAvailabilityParameters = (subscriptions) => {
        if (!subscriptions) {
          return [];
        }
        return subscriptions
          .map((subscription) => {
            let customDimensionLength = null;
            let customDimensionWidth = null;

            if (
              subscription &&
              subscription.custom_plan &&
              subscription.dimensions &&
              subscription.dimensions.length &&
              subscription.dimensions.width
            ) {
              customDimensionLength = subscription.dimensions.length.toString();
              customDimensionWidth = subscription.dimensions.width.toString();
            }

            return {
              plan_id: subscription.plan_id || _.get(subscription, 'plan.id'),
              quantity: subscription.quantity,
              type: _.get(subscription, 'plan.type'),
              custom_plan: subscription.custom_plan,
              custom_plan_dimension_length: customDimensionLength,
              custom_plan_dimension_width: customDimensionWidth,
            };
          })
          .filter(
            (subscription) =>
              subscription.quantity > 0 &&
              !!subscription.plan_id &&
              (subscription.type === 'StoragePlan' || !subscription.type),
          )
          .map((item) => _.omit(item, ['type']));
      };

      this.planTypeFilter = (planType) => ({
        plan: {
          type: planType,
        },
      });

      this.subscriptionForPricing = (pricing, subscriptions) =>
        _.find(subscriptions, (sub) => pricing.plan_id === sub.plan.id);

      this.subscribeSerializer = function (pricings, subscriptions) {
        return _.map(pricings, function (pricing) {
          const subscription = self.subscriptionForPricing(pricing, subscriptions);
          return {
            pricing_id: pricing.id,
            quantity: subscription.quantity,
            subscription_id: subscription.id,
          };
        });
      };

      this.quantityForPricing = (pricing, subscriptions) => {
        const subscription = this.subscriptionForPricing(pricing, subscriptions);
        if (subscription) {
          return subscription.quantity;
        } else {
          return 0;
        }
      };

      this.total = (pricings, subscriptions) =>
        _.reduce(
          pricings,
          (total, pricing) => {
            const quantity = this.quantityForPricing(pricing, subscriptions);
            return total + pricing.amount * quantity;
          },
          0,
        );
    },
  ]);
})();

import { DateTime } from 'luxon';
import * as React from 'react';

import { Billing__Source } from '@admin/schema';

export const Summary: React.FC<{
  source: Pick<Billing__Source, 'number' | 'expiration'>;
}> = ({ source }) => (
  <>
    <strong>····{source.number}</strong>
    {source.expiration && (
      <>
        {' '}
        expires <strong>{DateTime.fromISO(source.expiration).toFormat('yyyy/MM')}</strong>
      </>
    )}
  </>
);

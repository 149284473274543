import React from 'react';

import { client } from '@admin/libraries/apollo';

import { useZendeskTicketQuery, ZendeskTicketComment, ZendeskUser } from '@admin/schema';

import { Spinner } from '@admin/components/spinner';
import { Text } from '@shared/components/bootstrap';
import { Timestamp } from '@shared/components/helpers';

const Comment: React.FC<{
  comment: Pick<ZendeskTicketComment, 'createdAt' | 'body'> & {
    author?: Pick<ZendeskUser, 'name' | 'email'> | null;
  };
}> = ({ comment }) => (
  <>
    <hr />
    <div>
      <Text tag="p">
        {comment.author && (
          <strong>
            {comment.author.name} ({comment.author.email})
          </strong>
        )}
      </Text>
      <Text tag="p">
        <span className="small">
          <strong>
            <Timestamp value={comment.createdAt} format={Timestamp.Format.DateTime} />
          </strong>
        </span>
      </Text>
    </div>
    <div className="pre-wrap">
      <Text tag="p">{comment.body}</Text>
    </div>
  </>
);

export const Comments: React.FC<{
  zdTicketID: string;
}> = ({ zdTicketID }) => {
  const { data, loading } = useZendeskTicketQuery({ client, variables: { zdTicketID } });

  if (loading) {
    return <Spinner />;
  }

  if (!data || !data.zendeskTicket || data.zendeskTicket.comments.length === 0) {
    return (
      <Text tag="p">
        <strong>There are no emails on this ticket.</strong>
      </Text>
    );
  }

  const { zendeskTicket: ticket } = data;

  return (
    <>
      <Text tag="p">
        <strong>Subject: {ticket.subject}</strong>
      </Text>
      {ticket.comments.map((comment) => (
        <Comment key={comment.id} comment={comment} />
      ))}
    </>
  );
};

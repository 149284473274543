import * as React from 'react';

import { Loader } from './loader';
import { OutboundVehicleList } from './outbound_vehicle_list';

import { IPendingDispatch } from './types/pending_dispatch';

interface IPendingDispatchesProps {
  pendingDispatches?: IPendingDispatch[];
  loading: boolean;
}

class PendingDispatches extends React.Component<IPendingDispatchesProps> {
  public render() {
    const { loading } = this.props;
    return (
      <div className="pending-dispatches">
        <h5 className="pending-dispatches__label">Pending Outbound Dispatches</h5>
        <div className="pending-dispatches__container">
          {loading ? (
            <Loader loading={loading} />
          ) : (
            <div className="pending-dispatches__list">
              <OutboundVehicleList pendingDispatches={this.props.pendingDispatches} />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export { PendingDispatches };

import { SelectFormGroup } from '@admin/components/fields';
import * as React from 'react';

import { Billing__ForgivenessReason, useBillingForgivenessReasonsQuery } from '@admin/schema';
import { client } from '@admin/libraries/apollo';

export const ReasonSelector: React.FC<{
  reasonID?: string;
  onSelect(reasonID?: string): void;
}> = ({ reasonID, onSelect }) => {
  const { data, loading } = useBillingForgivenessReasonsQuery({ client });
  const reasons = data?.billingForgivenessReasons ?? [];
  const groupedReasons = reasons.reduce((acc, reason) => {
    if (reason.parentID) {
      acc[reason.parentID] = acc[reason.parentID] || [];
      acc[reason.parentID].push(reason);
    }
    return acc;
  }, {} as { [parentID: string]: [Billing__ForgivenessReason] });

  return (
    <SelectFormGroup
      id="forgiveness_reason"
      label="Reason:"
      value={reasonID || ''}
      onChange={(event) => onSelect(event.target.value || undefined)}
    >
      <option value="">{loading ? ' - Loading - ' : '- Reason -'}</option>
      {!loading &&
        Object.entries(groupedReasons).map(([parentID, children]) => {
          const parent = reasons.find((reason) => reason.id === parentID);
          return (
            <optgroup key={parentID} label={parent?.kind ?? 'Other'}>
              {children.map((reason) => (
                <option key={reason.id} value={reason.id}>
                  {reason.kind}
                </option>
              ))}
            </optgroup>
          );
        })}
    </SelectFormGroup>
  );
};

(function () {
  const app = angular.module('app');

  app.directive('checkoutActionButton', [
    '$rootScope',
    function ($rootScope) {
      function link(scope) {
        scope.saving = false;

        // note: we really should check here that the phone valid, not just that it exists, but for now we leave it up to the backend and validations in the checkouts controller
        scope.disableAction = function () {
          return scope.saving || scope.disableButton();
        };

        scope.saveAction = function () {
          scope.saving = true;
          scope.onClick();
        };

        $rootScope.$on('checkout_action:enable', function (event, id) {
          if (scope.buttonId === id) {
            scope.saving = false;
          }
        });

        $rootScope.$on('checkout_action:disable', function (event, id) {
          if (scope.buttonId === id) {
            scope.saving = true;
          }
        });
      }

      return {
        restrict: 'E',
        scope: {
          disableButton: '&',
          onClick: '&',
          buttonId: '@',
          buttonText: '=',
        },
        template:
          '<button ng-disabled="disableAction()" id="{{buttonId}}" class="btn btn-primary" ng-click="saveAction()">{{buttonText}}</button>',
        link: link,
      };
    },
  ]);
})();

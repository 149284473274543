import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Field, StyledRow } from './field';

const inputStyle = {
  textAlign: 'left' as const,
};

export const LoadSettingsFields: React.FC<{
  editMode: boolean;
  initialUsePalletLabels: boolean;
}> = ({ editMode, initialUsePalletLabels }) => {
  const { errors, register } = useFormContext<{
    usePalletLabels: boolean;
    itemLabelsRequired: boolean;
    trailerSealRequired: boolean;
  }>();

  return (
    <>
      <StyledRow className="row">
        <div className="col-md-4">
          <Field error={errors.usePalletLabels} id="use_pallet_labels" label="Use Pallet Labels?" required={true}>
            <input
              id="use_pallet_labels"
              type="checkbox"
              className="form-control"
              name="usePalletLabels"
              ref={register}
              disabled={editMode && initialUsePalletLabels}
              style={inputStyle}
            />
          </Field>
        </div>
        <div className="col-md-4">
          <Field error={errors.itemLabelsRequired} id="item_labels_required" label="Use Item Labels?" required={true}>
            <input
              id="item_labels_required"
              type="checkbox"
              className="form-control"
              name="itemLabelsRequired"
              ref={register}
              style={inputStyle}
            />
          </Field>
        </div>
        <div className="col-md-4">
          <Field
            error={errors.trailerSealRequired}
            id="trailer_seal_required"
            label="Trailer Seal Required?"
            required={true}
          >
            <input
              id="trailer_seal_required"
              type="checkbox"
              className="form-control"
              name="trailerSealRequired"
              ref={register}
              style={inputStyle}
            />
          </Field>
        </div>
      </StyledRow>
    </>
  );
};

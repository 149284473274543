import { Spinner } from '@admin/components/spinner';
import { History__Subject } from '@admin/schema';
import { HistoriesPanel } from '@admin/components/histories/panel';
import { client } from '@admin/libraries/apollo';
import React from 'react';
import { useLogisticsDetailedSkuQuery } from '@admin/schema';
import { OpsAuditResultsPanel } from '@admin/components/ops_audit/audit_results/audit_results_panel';
import { LogisticsSkuBasicInfoPanel } from './basic_info_panel';
import { ImagesPanel } from './images_panel';

export const LogisticsDetailedSku: React.FC<{ id: string }> = ({ id }) => {
  const { data, loading } = useLogisticsDetailedSkuQuery({ client, variables: { id } });

  if (loading || !data) {
    return <Spinner />;
  }
  const sku = data.logisticsSku;
  const images =
    data.logisticsSku.measuredWeightImage == null
      ? data.logisticsSku.verificationImages
      : data.logisticsSku.verificationImages.concat(data.logisticsSku.measuredWeightImage);
  document.title = `Sku: #${sku.value}`;

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <LogisticsSkuBasicInfoPanel sku={sku} />
        </div>
      </div>
      <ImagesPanel images={images} />
      <HistoriesPanel type={History__Subject.LogisticsSku} id={id} />
      <OpsAuditResultsPanel resourceType={'Logistics::Sku'} resourceID={id} />
    </>
  );
};

import template from '@admin/angular/templates/pickup_form.html';

(() => {
  const app = angular.module('app');

  app.component('pickupForm', {
    template,
    bindings: {
      account: '<account',
      order: '<order',
    },
    controller: [
      'Pickup',
      'PICKUP_ATTRIBUTES',
      function (Pickup, PICKUP_ATTRIBUTES) {
        this.attributes = PICKUP_ATTRIBUTES;
        this.resource = Pickup;
      },
    ],
  });
})();

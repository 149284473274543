import { difference, union } from 'lodash';
import * as React from 'react';

import { IItem, IWarehouse } from '@admin/types';

import { Pluralize } from '@shared/components/helpers/pluralize';

export const Warehouse: React.FC<{
  warehouse: IWarehouse;
  selections: IItem[];
  onSelect(selections: IItem[]): void;
}> = ({ warehouse, selections, onSelect }) => {
  const selectableItems = warehouse.items.filter((item) => !item.delayed);
  const selectedIDs = new Set(selections.map(({ id }) => id));
  const checked = selectableItems.every((item) => selectedIDs.has(item.id));

  return (
    <div className="panel">
      <div className="panel-body">
        <div className="pull-left">
          <div className="checkbox">
            <label>
              <input
                type="checkbox"
                checked={checked}
                onChange={(event) => {
                  onSelect((event.target.checked ? union : difference)(selections, selectableItems));
                }}
              />
            </label>
          </div>
        </div>

        <div className="pull-left">
          <div>
            <strong>{warehouse.name}</strong>
          </div>
          <div>
            <Pluralize count={selectableItems.length} singular="Item" plural="Items" />
          </div>
        </div>
      </div>
    </div>
  );
};

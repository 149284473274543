(function () {
  angular.module('app').filter('timeLocale', [
    '$window',
    function ($window) {
      return function (time, format) {
        const locale = $window.navigator.language || 'en-us';
        if (format === 'short') {
          return moment(time)
            .toDate()
            .toLocaleString(locale, { hour: 'numeric', minute: '2-digit', timeZoneName: 'short' });
        } else if (format === 'date') {
          return moment(time).toDate().toLocaleString(locale, { year: 'numeric', month: '2-digit', day: '2-digit' });
        } else {
          return moment(time).toDate().toLocaleString(locale, {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: 'numeric',
            minute: '2-digit',
            timeZoneName: 'short',
          });
        }
      };
    },
  ]);
})();

import React, { useEffect, useState } from 'react';

import { client } from '@admin/libraries/apollo';
import {
  Claim__Assessment__Classification,
  Claim__ItemSelectionKind,
  Status,
  useModifyClaimAssessmentsMutation,
} from '@admin/schema';
import { Button, ListGroup, Text } from '@shared/components/bootstrap';

import { buildModifyAssessmentsInput, AssessmentEditInput, AssessmentEdits } from './helpers';
import { ItemSelections } from './item_selections';
import { PropertySelection } from './property_selection';
import { compensated } from '../helpers';
import { IClaimStepProps } from '../steps';

export const Assess: React.FC<IClaimStepProps> = ({ claim, onNext }) => {
  const { itemSelections, propertySelection } = claim;
  const compensatedItems = itemSelections.filter((item) => compensated(item));
  const hasCompensatedProperty = propertySelection && compensated(propertySelection);

  const [itemEdits, setItemEdits] = useState<AssessmentEdits>({});
  const [propertyEdit, setPropertyEdit] = useState<AssessmentEditInput>({});
  const [error, setError] = useState<string>();

  const [saveAssessments, { loading }] = useModifyClaimAssessmentsMutation({ client });

  useEffect(() => {
    if (!compensatedItems.length) {
      return;
    }
    let classificationChanges = {};
    for (const selection of compensatedItems) {
      if (
        selection.kind === Claim__ItemSelectionKind.Lost &&
        selection.assessment?.classification !== Claim__Assessment__Classification.TotalLoss
      ) {
        const totalLossClassification = {
          ...itemEdits[selection.id],
          classification: Claim__Assessment__Classification.TotalLoss,
        };
        classificationChanges = { ...classificationChanges, [selection.id]: totalLossClassification };
      }
    }
    if (Object.keys(classificationChanges).length) {
      setItemEdits({ ...itemEdits, ...classificationChanges });
    }
  }, []);

  const nextStepEnabled = () => {
    for (const selection of compensatedItems) {
      const verifiedValue = itemEdits[selection.id]?.verifiedValue ?? selection.assessment?.verifiedValue;
      const weight = itemEdits[selection.id]?.weight ?? selection.assessment?.weight;
      const classification = itemEdits[selection.id]?.classification ?? selection.assessment?.classification;
      if (!verifiedValue || !weight || !classification) {
        return false;
      }
    }

    if (hasCompensatedProperty) {
      const verifiedValue = propertyEdit.verifiedValue ?? propertySelection!.assessment?.verifiedValue;
      const classification = propertyEdit.classification ?? propertySelection!.assessment?.classification;
      if (!verifiedValue || !classification) {
        return false;
      }
    }
    return true;
  };

  const onSave = async () => {
    if (!Object.keys(itemEdits).length && !Object.keys(propertyEdit).length) {
      setError(undefined);
      onNext(claim);
      return;
    }
    const assessments = buildModifyAssessmentsInput(itemEdits, propertySelection?.id, propertyEdit);
    const response = await saveAssessments({ variables: { input: { claimID: claim.id, ...assessments } } });
    if (response.data) {
      if (response.data.modifyClaimAssessments.status === Status.Unprocessable) {
        setError(response.data.modifyClaimAssessments.error ?? undefined);
      } else if (response.data.modifyClaimAssessments.claim) {
        setError(undefined);
        onNext(response.data.modifyClaimAssessments.claim!);
      }
    }
  };

  return (
    <>
      <ListGroup className="list-group-flush">
        {!!compensatedItems.length && (
          <ItemSelections
            accountID={claim.account.id}
            selections={compensatedItems}
            edits={itemEdits}
            setEdits={setItemEdits}
          />
        )}
        {hasCompensatedProperty && (
          <PropertySelection selection={propertySelection!} edit={propertyEdit} setEdit={setPropertyEdit} />
        )}
      </ListGroup>
      {error && (
        <div className="mar-top text-right">
          <Text style="danger">{error}</Text>
        </div>
      )}
      <div className="mar-top">
        <Button kind="primary" className="pull-right" disabled={!nextStepEnabled()} loading={loading} onClick={onSave}>
          Save & Continue
        </Button>
      </div>
    </>
  );
};

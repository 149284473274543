import { FetchResult } from '@apollo/client';
import styled from '@emotion/styled';
import React from 'react';

import { AnchorButton as Link, Button, Col, Row, Breadcrumb } from '@shared/components/bootstrap';

import { client } from '@admin/libraries/apollo';
import {
  Scalars,
  useUserCreateFormQuery,
  useUserUpdateFormQuery,
  useUserCreateMutation,
  useUserUpdateMutation,
  UserInput,
  User__Role,
  User__ExternalGroup,
  UserDefaultsFragment,
  UserFormFragment,
  Status,
  UserNotificationPreferenceEnum,
} from '@admin/schema';

import { TimeZone, TZS } from '@admin/constants/time_zone';
import { USER_ROLES, USER_ROLE_TO_NAME } from '@admin/constants/user_role';
import { USER_EXTERNAL_GROUPS, USER_EXTERNAL_GROUP_TO_NAME } from '@admin/constants/user_external_group';
import { USER_AGENT_PRIORITY_OPTIONS } from '@admin/constants/user_agent_priority';

import { useErrors, Errors } from '@admin/components/errors';
import { Panel } from '@admin/components/helpers/panel';
import { Spinner } from '@admin/components/spinner';
import { FormikInputFormGroup } from '@shared/components/fields/formik/formik_input_form_group';
import { FormikMultiselectFormGroup } from '@admin/components/fields/formik/formik_multiselect_form_group';
import { Form, Formik } from 'formik';
import { FormikSelectFormGroup } from '@shared/components/fields/formik/formik_select_form_group';
import { FormikRadioFormGroup } from '@admin/components/fields/formik/formik_radio_form_group';
import { userNotificationPreferenceOptions } from '@shared/utils/user_notification_preference';
import { ROLES } from '@admin/config/roles';
import { UserRole } from '@admin/types';
import { Avatar } from './avatar';

const DEFAULT_AGENT_PRIORITY = 2;

const USER_ROLES_OPTIONS = USER_ROLES.map((role) => ({ id: role, name: USER_ROLE_TO_NAME[role] }));
const USER_EXTERNAL_GROUP_OPTIONS = USER_EXTERNAL_GROUPS.map((group) => ({
  id: group,
  name: USER_EXTERNAL_GROUP_TO_NAME[group],
}));

const Buttons = styled.div`
  display: flex;
  gap: 4px;
`;

type Values = {
  tz: string;
  name: string;
  email: string;
  phone: string;
  workdayUserID: string;
  agentPriority: number | undefined;
  teamID: Scalars['ID'] | undefined;
  roles: User__Role[];
  regionIDs: Array<Scalars['ID']>;
  ownedTeamIDs: Array<Scalars['ID']>;
  externalGroup: User__ExternalGroup | undefined;
  notificationPreference: UserNotificationPreferenceEnum | undefined;
};

const Fields: React.FC<{
  saving: boolean;
  data: UserFormFragment & { user?: UserDefaultsFragment };
  save(input: UserInput): Promise<
    FetchResult<{
      result: {
        status: Status;
        error?: string | null;
      };
    }>
  >;
  onSave(): void;
}> = ({ data: { user, teams, regions }, save, saving, onSave }) => {
  const errors = useErrors();

  const initialValues = {
    tz: (user?.tz as TimeZone) ?? '',
    name: user?.name ?? '',
    email: user?.email ?? '',
    phone: user?.phone ?? '',
    workdayUserID: user?.workdayUserID ?? '',
    agentPriority: user?.agentPriority ?? DEFAULT_AGENT_PRIORITY,
    teamID: user?.team?.id,
    roles: user?.roles ?? [],
    regionIDs: user?.regions.map((r) => r.id) ?? [],
    ownedTeamIDs: user?.ownedTeams.map((t) => t.id) ?? [],
    externalGroup: user?.externalGroup ?? undefined,
    notificationPreference: user?.notificationPreference ?? undefined,
  };

  const onSubmit = async (values: Values) => {
    if (saving) return;

    const response = await save({
      ...values,
      roles: values.roles,
      regionIDs: values.regionIDs,
      ownedTeamIDs: values.ownedTeamIDs,
      externalGroup: (values.externalGroup as string) === '' ? null : values.externalGroup,
    });

    if (response.errors && response.errors.length) {
      const [error] = response.errors;
      errors.display(error);
      return;
    }

    if (response.data?.result.error) {
      errors.display(response.data.result.error);
      return;
    }

    if (response.data?.result.status === Status.Ok) {
      onSave();
    }
  };

  return (
    <>
      <div className="page-header">
        <Breadcrumb>
          <Breadcrumb.Item>
            <a href="/users">Users</a>
          </Breadcrumb.Item>
          {user && (
            <Breadcrumb.Item>
              <a href={`/users/${user.id}`}>{user.name}</a>
            </Breadcrumb.Item>
          )}
          <Breadcrumb.Item active>{user ? 'Edit' : 'New'}</Breadcrumb.Item>
        </Breadcrumb>
      </div>

      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        <Form>
          <Errors {...errors} />
          <Panel>
            <Panel.Header>
              <Panel.Title>Details</Panel.Title>
            </Panel.Header>
            <Panel.Body>
              <Row>
                <Col md={3}>{user && <Avatar actions user={user} />}</Col>
                <Col md={9}>
                  <FormikInputFormGroup id="user_name" label="Name" name="name" />
                  <FormikInputFormGroup id="user_email" label="Email" name="email" />
                  <FormikInputFormGroup id="user_phone" label="Phone" name="phone" />
                  <FormikInputFormGroup id="workday_user_id" label="Workday User ID" name="workdayUserID" />
                  <Row>
                    <Col sm={4}>
                      <FormikMultiselectFormGroup
                        id="user_roles"
                        label="Roles"
                        name="roles"
                        entries={
                          ROLES.includes(UserRole.Admin)
                            ? USER_ROLES_OPTIONS
                            : USER_ROLES_OPTIONS.filter(
                                (role) =>
                                  [
                                    User__Role.Mover,
                                    User__Role.Driver,
                                    User__Role.NightDriver,
                                    User__Role.Warehouse,
                                  ].includes(role.id) || user?.roles.includes(role.id),
                              )
                        }
                      />
                    </Col>
                    <Col sm={4}>
                      <FormikMultiselectFormGroup
                        id="user_region_ids"
                        label="Regions"
                        name="regionIDs"
                        entries={regions}
                      />
                    </Col>
                    <Col sm={4}>
                      <FormikMultiselectFormGroup
                        id="user_owned_team_ids"
                        label="Owned Teams"
                        name="ownedTeamIDs"
                        entries={teams}
                        disabled={!ROLES.includes(UserRole.Admin)}
                      />
                    </Col>
                  </Row>
                  <FormikRadioFormGroup
                    id="user_notification_preferences"
                    label="Schedule Notification Preference"
                    name="notificationPreference"
                    options={userNotificationPreferenceOptions(user?.phone ?? undefined)}
                  />
                  <FormikSelectFormGroup id="user_team_id" name="teamID" label="Team">
                    <option value=""> - None -</option>
                    {teams.map((team) => (
                      <option key={team.id} value={team.id}>
                        {team.name}
                      </option>
                    ))}
                  </FormikSelectFormGroup>
                  <FormikSelectFormGroup id="user_external_group" label="External Group" name="externalGroup">
                    <option value="">- None -</option>
                    {USER_EXTERNAL_GROUP_OPTIONS.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </FormikSelectFormGroup>
                  <FormikSelectFormGroup id="user_tz" label="Time Zone" name="tz">
                    {<option value=""> - Select - </option>}
                    {TZS.map((zone) => (
                      <option key={zone} value={zone}>
                        {zone}
                      </option>
                    ))}
                  </FormikSelectFormGroup>
                  <FormikSelectFormGroup id="user_agent_priority" label="Agent Priority" name="agentPriority">
                    {<option value=""> - Select - </option>}
                    {USER_AGENT_PRIORITY_OPTIONS.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </FormikSelectFormGroup>
                </Col>
              </Row>
            </Panel.Body>

            <Panel.Footer align="right">
              <Buttons>
                <Link kind="default" href="/users" disabled={saving}>
                  Cancel
                </Link>
                <Button type="submit" kind="primary" loading={saving}>
                  Save
                </Button>
              </Buttons>
            </Panel.Footer>
          </Panel>
        </Form>
      </Formik>
    </>
  );
};

const CreateForm: React.FC<{
  onSave(): void;
}> = ({ onSave }) => {
  const { data } = useUserCreateFormQuery({ client });
  const [execute, { loading: saving }] = useUserCreateMutation({ client });

  if (!data) return <Spinner />;

  return <Fields save={(input) => execute({ variables: { input } })} saving={saving} data={data} onSave={onSave} />;
};

const UpdateForm: React.FC<{
  id: Scalars['ID'];
  onSave(): void;
}> = ({ id, onSave }) => {
  const { data } = useUserUpdateFormQuery({ client, variables: { id } });
  const [execute, { loading: saving }] = useUserUpdateMutation({ client });

  if (!data) return <Spinner />;

  return <Fields save={(input) => execute({ variables: { input, id } })} saving={saving} data={data} onSave={onSave} />;
};

export const UserForm: React.FC<{
  id?: Scalars['ID'];
  onSave(): void;
}> = ({ id, onSave }) => (id ? <UpdateForm id={id} onSave={onSave} /> : <CreateForm onSave={onSave} />);

import { sumBy } from 'lodash';
import * as React from 'react';

import { Spinner } from '@admin/components/spinner';
import { client } from '@admin/libraries/apollo';
import { AccountClaimFragment, useClaimsPanelQuery } from '@admin/schema';
import { Table } from '@shared/components/bootstrap';
import { Currency, Timestamp } from '@shared/components/helpers';

import { Summary } from './claims/summary';

interface IAccountClaimsProps {
  accountID: string;
}

const FormatDate = ({ time }: { time: string }) =>
  time ? <Timestamp value={time} format={Timestamp.Format.Date} /> : <>N/A</>;

const totalClaimed = (
  itemSelections: AccountClaimFragment['itemSelections'],
  propertySelection?: AccountClaimFragment['propertySelection'],
) => {
  const itemTotalClaimed = sumBy(itemSelections, ({ declaration: itemDeclaration }) => Number(itemDeclaration) || 0);
  return propertySelection ? itemTotalClaimed + Number(propertySelection.declaration || 0) : itemTotalClaimed;
};

export const ClaimsTable: React.FC<IAccountClaimsProps> = ({ accountID }) => {
  const { loading, data } = useClaimsPanelQuery({ client, variables: { accountID } });

  if (loading) {
    return <Spinner />;
  }

  const claims = data?.accountClaims;
  const claimsSummary = data?.account?.claimsSummary;

  return (
    <>
      <Table striped responsive>
        <thead>
          <tr>
            <th>Number</th>
            <th>Opened On</th>
            <th>Status</th>
            <th>Claims Agent</th>
            <th>Amount Claimed</th>
            <th>Closed On</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {claims &&
            claims.map((claim, key) => (
              <tr key={key}>
                <td>{claim.number}</td>
                <td>{claim.openedAt && <FormatDate time={claim.openedAt} />}</td>
                <td>{claim.status.toLowerCase()}</td>
                <td>{claim.owner ? claim.owner.name : 'Unassigned'}</td>
                <td>
                  <Currency value={totalClaimed(claim.itemSelections, claim.propertySelection)} />
                </td>
                <td>{claim.closedAt && <FormatDate time={claim.closedAt} />}</td>
                <td>
                  <a className="btn btn-primary" href={`/claims/${claim.id}`}>
                    View
                  </a>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
      {claimsSummary && <Summary summary={claimsSummary} />}
    </>
  );
};

import angular from 'angular';
import { pick, head } from 'lodash';

const app = angular.module('app');

const parameterize = (address) => {
  const params = pick(address, ['zip', 'city', 'state']);
  if (address.street && address.street.length) {
    params.address = address.street;
  }
  return params;
};

app.service('AddressGeocodeService', [
  '$q',
  'Geocode',
  function service($q, Geocode) {
    const memo = {};
    this.geocode = (address) => {
      const params = parameterize(address);
      const key = JSON.stringify(params);
      if (!memo[key]) {
        memo[key] = Geocode.get(params);
      }

      const deferred = $q.defer();
      memo[key].$promise.then((geocodes) => {
        const geocode = head(geocodes);
        if (geocode) {
          geocode.latitude = Number(geocode.latitude);
          geocode.longitude = Number(geocode.longitude);
          deferred.resolve(geocode);
        } else {
          deferred.reject();
        }
      });

      return deferred.promise;
    };
  },
]);

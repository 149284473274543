import angular from 'angular';

angular.module('app').factory('Checkin', [
  '$resource',
  ($resource) =>
    $resource(
      '/:resource_type/:resource_id/checkins/:id.json',
      {
        id: '@id',
        resource_id: '@resource_id',
        resource_type: '@resource_type',
      },
      {
        index: {
          method: 'GET',
          isArray: true,
        },
        latest: {
          method: 'GET',
          isArray: true,
          url: '/checkins/latest.json',
          paramSerializer: 'NestedParamsSerializer',
        },
      },
    ),
]);

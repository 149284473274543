(function () {
  const app = angular.module('app');

  app.service('NestedAttributesService', [
    function () {
      this.convert = function (selections, fn) {
        return _.filter(
          _.map(selections, function (selection) {
            const attributes = fn(selection);
            return _.merge(attributes, {
              id: selection.id,
              _destroy: !attributes,
            });
          }),
          function (attribute) {
            return attribute.id || !attribute._destroy;
          },
        );
      };
    },
  ]);
})();

import * as React from 'react';

import { Breadcrumb } from '@shared/components/bootstrap';

import { regionsURL, regionURL } from '@admin/config/routes';

interface IBreadcrumbsProps {
  name: string;
  regionId?: string | number;
  edit?: boolean;
}

export const Breadcrumbs: React.FC<IBreadcrumbsProps> = ({ name, regionId, edit }) => (
  <Breadcrumb>
    <Breadcrumb.Item>
      <a href={regionsURL()} className="text-primary">
        {' '}
        Regions{' '}
      </a>
    </Breadcrumb.Item>
    {name &&
      (edit ? (
        <>
          <Breadcrumb.Item>
            <a href={regionURL({ id: regionId! })} className="text-primary">
              {name}
            </a>
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Edit</Breadcrumb.Item>
        </>
      ) : (
        <Breadcrumb.Item active>{name}</Breadcrumb.Item>
      ))}
  </Breadcrumb>
);

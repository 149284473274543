(function () {
  angular.module('app').factory('Inventory', [
    '$resource',
    function ($resource) {
      return $resource(
        '/:resource_type/:resource_id/inventory.json',
        {
          resource_type: '@resource_type',
          resource_id: '@resource_id',
        },
        {
          show: {
            method: 'GET',
          },
        },
      );
    },
  ]);
})();

/*

ConfirmationService.confirm({
title: 'My Title',
description: 'My description',
});

*/

(function () {
  const app = angular.module('app');

  app.service('ConfirmationService', [
    'ngDialog',
    function (ngDialog) {
      this.confirm = function (options) {
        const template = options.template || '/partials/dialogs/_confirmation.html';

        const controller = [
          '$rootScope',
          '$scope',
          function ($rootScope, $scope) {
            $scope.title = options.title;
            $scope.description = options.description;
            $scope.acceptButton = options.acceptButton;
            $scope.declineButton = options.declineButton;
            $scope.showDeclineButton = _.isNil(options.showDeclineButton) ? true : options.showDeclineButton;
            $scope.data = options.data;
          },
        ];

        const config = $.extend({}, { controller: controller, template: template }, options.dialogOptions);

        return ngDialog.openConfirm(config);
      };
    },
  ]);
})();

import React from 'react';

import { client } from '@admin/libraries/apollo';

import { useMovingConfigurationQuery } from '@admin/schema';

export const MovingOverrideLaborRateAmount: React.FC<{
  movers: number;
  movingConfigurationID: string;
}> = ({ movers, movingConfigurationID }) => {
  const { data } = useMovingConfigurationQuery({ client, variables: { movers, movingConfigurationID } });

  return <div>Updated Labor: ${data?.movingConfiguration?.laborRate?.amount}/hr</div>;
};

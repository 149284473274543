/**
 * Takes user name text and adds the dispatcher role badges onto them
 */
angular.module('app').filter('dispatchRoles', [
  () =>
    _.flow([
      _.concat, // put text and user arguments into array

      ([text, user]) =>
        user.driver_approved ? `${text} <span class="pull-right badge badge-pill blue">D</span>` : text,
    ]),
]);

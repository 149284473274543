import * as React from 'react';

import { Badge, Button, Table } from '@shared/components/bootstrap';
import { Titleize } from '@admin/components/helpers/titleize';
import { Spinner } from '@admin/components/spinner';

import {
  Billing__Source,
  Billing__Source__CvcCheck,
  Billing__Source__ZipCheck,
  SourcePolicy,
  useAccountSourcesQuery,
  useBillingSourceDefaultMutation,
  useBillingSourceDeleteMutation,
} from '@admin/schema';

import { client } from '@admin/libraries/apollo';

import {
  Dialog as ConfirmationDialog,
  Response as ConfirmationResponse,
  useConfirmation,
} from '@admin/components/confirmation';
import { useDocumentEventListener } from '@shared/hooks';
import { Summary } from './summary';
import { SourceIcon } from './source_icon';

const Delete: React.FC<{
  source: Pick<Billing__Source, 'id' | 'number' | 'active'>;
  count: number;
  permissions?: SourcePolicy;
  onChange(): void;
}> = ({ source, count, permissions, onChange }) => {
  const { confirmation, confirm } = useConfirmation();
  const [execute, { loading }] = useBillingSourceDeleteMutation({
    client,
    variables: {
      id: source.id,
    },
  });

  const onClick = async () => {
    const confirmedDelete = await confirm({
      title: 'Delete Payment Method',
      description: `Are you sure you want to delete •••• ${source.number}?`,
    });
    if (confirmedDelete !== ConfirmationResponse.Confirm) return;

    if (count < 2) {
      const confirmedDelinquency = await confirm({
        title: 'Are you sure?',
        description: 'There will be no card on file to charge and the account will likely be delinquent!',
      });
      if (confirmedDelinquency !== ConfirmationResponse.Confirm) return;
    }

    await execute();
    onChange();
  };

  return (
    <>
      {confirmation && <ConfirmationDialog confirmation={confirmation} />}
      <Button kind="danger" disabled={!source.active || !permissions?.delete} loading={loading} onClick={onClick}>
        Remove
      </Button>
    </>
  );
};

const CHECK_KIND: Record<Billing__Source__CvcCheck | Billing__Source__ZipCheck, 'success' | 'danger' | 'warning'> = {
  PASS: 'success',
  FAIL: 'danger',
  UNAVAILABLE: 'warning',
  UNCHECKED: 'warning',
};

const CheckBadge: React.FC<{
  label: string;
  check?: Billing__Source__CvcCheck | Billing__Source__ZipCheck | null;
}> = ({ label, check }) => {
  if (!check || check === 'UNAVAILABLE') return null;
  const style = CHECK_KIND[check] ?? 'info';

  return (
    <Badge style={style}>
      {label}: <Titleize text={check} />
    </Badge>
  );
};

const CVCCheckBadge: React.FC<{
  source: Pick<Billing__Source, 'cvcCheck'>;
}> = ({ source }) => <CheckBadge check={source.cvcCheck} label="CVC" />;

const ZIPCheckBadge: React.FC<{
  source: Pick<Billing__Source, 'zipCheck'>;
}> = ({ source }) => <CheckBadge check={source.zipCheck} label="ZIP" />;

const Default: React.FC<{
  source: Pick<Billing__Source, 'id' | 'default' | 'number'>;
  onChange(): void;
}> = ({ source, onChange }) => {
  const { confirmation, confirm } = useConfirmation();
  const [execute, { loading }] = useBillingSourceDefaultMutation({
    client,
    variables: {
      id: source.id,
    },
  });

  const onClick = async () => {
    const confirmed = await confirm({
      title: 'Make Default Payment Method',
      description: `Are you sure you want to mark •••• ${source.number} as the default payment method?`,
    });
    if (confirmed !== ConfirmationResponse.Confirm) return;

    await execute();
    onChange();
  };

  return (
    <>
      {confirmation && <ConfirmationDialog confirmation={confirmation} />}
      <Button disabled={source.default} loading={loading} kind="warning" onClick={onClick}>
        Default
      </Button>
    </>
  );
};

export const List: React.FC<{ accountID: number | string }> = ({ accountID }) => {
  const { data, loading, refetch } = useAccountSourcesQuery({ client, variables: { accountID: String(accountID) } });
  const sources = data?.account?.sources;

  useDocumentEventListener('billing:sources:refetch', refetch);

  return (
    <>
      <Table responsive striped condensed>
        <tbody>
          {sources?.map((source) => (
            <tr key={source.id}>
              <td className="text-left col-sm-1">
                <SourceIcon source={source} scale={3} />
              </td>
              <td className="text-left col-sm-2">
                <Titleize text={source.fundingType} /> <Titleize text={source.kind} />
              </td>
              <td className="text-left col-sm-4">
                <div>{source.name}</div>
                <div>
                  <Summary source={source} />
                </div>
              </td>
              <td className="text-left col-sm-4">
                <div>{source.stripeID}</div>
                <div>
                  <CVCCheckBadge source={source} /> <ZIPCheckBadge source={source} />
                </div>
              </td>
              <td className="text-right col-zero pad-no">
                <Delete source={source} count={sources.length} permissions={data?.permissions} onChange={refetch} />
              </td>
              <td className="text-right col-zero pad-no">
                <Default source={source} onChange={refetch} />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {loading && <Spinner />}
    </>
  );
};

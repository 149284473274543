import styled from '@emotion/styled';
import React from 'react';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { Button, Text } from '@shared/components/bootstrap';
import { Spacer } from '@shared/components/helpers';

import { useGenerateSelfStorageGateCodeMutation } from '@admin/schema';
import { client } from '@admin/libraries/apollo';

import { Section } from '../components/section';
import { SectionHeading } from '../components/section_heading';
import { Field } from './field';

const InputGroup = styled.div`
  display: flex;
`;

const ID = 'gate_code';
const NAME = 'gateCode';

export const GateCode: React.FC<{ accountID?: string; facilityID?: string }> = ({ accountID, facilityID }) => {
  const { register, setValue } = useFormContext<{ [NAME]?: string }>();
  const [execute, { data, loading }] = useGenerateSelfStorageGateCodeMutation({ client });

  const value = data && data.result && data.result.value;

  useEffect(() => {
    if (facilityID) {
      execute({ variables: { accountID, facilityID } });
    }
  }, [facilityID]);

  useEffect(() => {
    if (value) {
      setValue(NAME, value);
    }
  }, [value]);

  return (
    <Section>
      <SectionHeading>Gate Code</SectionHeading>
      <Text tag="p">
        Please remember to update the gate code in the appropriate system. The box below is only for record keeping
        purposes.
      </Text>
      <Field required id={ID} name={NAME} label="Code">
        <InputGroup>
          <input id={ID} name={NAME} className="form-control" ref={register({ required: 'Please provide a code' })} />
          <Button
            kind="info"
            loading={loading}
            disabled={!facilityID}
            onClick={() => execute({ variables: { accountID, facilityID: facilityID! } })}
          >
            Regenerate
          </Button>
        </InputGroup>
      </Field>
      <Spacer height="24px" />
    </Section>
  );
};

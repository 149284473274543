(function () {
  angular.module('app').factory('TicketGrouping', [
    '$resource',
    function ($resource) {
      return $resource(
        '/ticket_groupings/:slug.json',
        {
          slug: '@slug',
        },
        {
          save: {
            method: 'PATCH',
          },
        },
      );
    },
  ]);
})();

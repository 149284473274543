import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { ErrorMessage, useFormContext } from 'react-hook-form';

import { AutocompleteFormControl } from '@admin/components/address/autocomplete_form_control';
import { Spacer } from '@shared/components/helpers';

import { Checkbox, Radio, FormGroup, HelpBlock } from '@shared/components/bootstrap';
import { ZIP_REGEX } from '@shared/config/zip';
import { Section } from '../components/section';
import { SectionHeading } from '../components/section_heading';
import { Field } from './field';
import { Context } from './form/context';

import { FormData } from './form/types';

type FieldsData = Pick<FormData, 'addresses'>;

type Key = 'billing' | 'mailing';

const Options: React.FC<{ name: Key }> = ({ name }) => {
  const { errors, register, watch } = useFormContext<FieldsData>();
  const { account } = useContext(Context);
  const addresses = account?.addresses;

  if (!addresses) {
    return <Fields name={name} />;
  }

  return (
    <>
      <FormGroup has={errors.addresses?.[name]?.id ? 'error' : undefined}>
        {addresses?.map(({ id, street, aptsuite, city, state, zip }) => (
          <Radio key={id}>
            <input
              type="radio"
              name={`addresses.${name}.id`}
              value={id}
              ref={register({ required: `Please select a ${name} address.` })}
            />
            {street} {aptsuite}, {city}, {state}, {zip}
          </Radio>
        ))}
        <Radio>
          <input
            type="radio"
            name={`addresses.${name}.id`}
            value="other"
            ref={register({ required: `Please select a ${name} address.` })}
          />
          Use a new address
        </Radio>
        <ErrorMessage name={`addresses.${name}.id`}>{({ message }) => <HelpBlock>{message}</HelpBlock>}</ErrorMessage>
      </FormGroup>
      {watch(`addresses.${name}.id`) === 'other' && <Fields name={name} />}
    </>
  );
};

const Fields: React.FC<{ name: Key }> = ({ name }) => {
  const [autocomplete, setAutocomplete] = useState<{
    query?: string;
    error?: string;
  }>({});

  const { register, unregister, setValue } = useFormContext<FieldsData>();

  useEffect(() => {
    register(
      { name: `addresses.${name}.street` },
      {
        required: 'Please provide a street',
        validate: () => autocomplete.error,
      },
    );
    return () => {
      unregister(`addresses.${name}.street`);
    };
  }, [register, unregister, autocomplete.error]);

  return (
    <>
      <div className="row">
        <div className="col-md-9">
          <Field required id={`addresses-${name}-street`} name={`addresses.${name}.street`} label="Street">
            <AutocompleteFormControl
              id={`addresses-${name}-street`}
              onChange={(place) => {
                setAutocomplete({ query: place.street });
                setValue(`addresses.${name}.street`, place.street, true);
                setValue(`addresses.${name}.city`, place.city, true);
                setValue(`addresses.${name}.state`, place.state, true);
                setValue(`addresses.${name}.zip`, place.zip, true);
              }}
              query={autocomplete.query}
              onQuery={(query) => {
                setValue(`addresses.${name}.street`, query, true);
                setAutocomplete({ ...autocomplete, query });
              }}
              onError={(error) => {
                setAutocomplete({ ...autocomplete, error });
              }}
            />
          </Field>
        </div>
        <div className="col-md-3">
          <Field id={`addresses-${name}-aptsuite`} name={`addresses.${name}.aptsuite`} label="Apt / Suite">
            <input
              id={`addresses-${name}-aptsuite`}
              name={`addresses.${name}.aptsuite`}
              className="form-control"
              type="text"
              ref={register}
            />
          </Field>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <Field required id={`addresses-${name}-city`} name={`addresses.${name}.city`} label="City">
            <input
              id={`addresses-${name}-city`}
              name={`addresses.${name}.city`}
              className="form-control"
              type="text"
              ref={register({ required: 'Please provide a city' })}
            />
          </Field>
        </div>
        <div className="col-md-3">
          <Field required id={`addresses-${name}-state`} name={`addresses.${name}.state`} label="State">
            <input
              id={`addresses-${name}-state`}
              name={`addresses.${name}.state`}
              className="form-control"
              type="text"
              ref={register({ required: 'Please provide a state' })}
            />
          </Field>
        </div>
        <div className="col-md-3">
          <Field required id={`addresses-${name}-zip`} name={`addresses.${name}.zip`} label="ZIP">
            <input
              id={`addresses-${name}-zip`}
              name={`addresses.${name}.zip`}
              className="form-control"
              type="text"
              ref={register({
                required: 'Please provide a ZIP',
                pattern: {
                  value: ZIP_REGEX,
                  message: 'Please provide a valid ZIP',
                },
              })}
            />
          </Field>
        </div>
      </div>
    </>
  );
};

export const Addresses: React.FC = () => {
  const { register, watch } = useFormContext<FieldsData>();

  return (
    <>
      <Section>
        <fieldset>
          <SectionHeading>Billing Address</SectionHeading>
          <Options name="billing" />
        </fieldset>
        <Spacer height="36px" />
      </Section>
      <Section>
        <fieldset>
          <SectionHeading>Mailing Address</SectionHeading>
          <Checkbox>
            <input type="checkbox" name="addresses.sameBillingMailing" ref={register} />
            Same as Billing Address
          </Checkbox>
          {!watch('addresses.sameBillingMailing') && <Options name="mailing" />}
        </fieldset>
        <Spacer height="36px" />
      </Section>
    </>
  );
};

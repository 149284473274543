import styled from '@emotion/styled';
import { Carousel, useCarousel } from '@clutter/clean';
import React, { useState } from 'react';
import { EstimationUploadsQuery, useEstimationUploadsQuery } from '@admin/schema';
import { client } from '@admin/libraries/apollo';
import { Modal } from '@shared/components/bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons';

const Container = styled.div`
  text-align: center;
`;

const Slide = styled.div`
  min-width: 400px;
  width: 100%;
`;

const ImageThumbnail = styled.img`
  min-width: 400px;
  width: 100%;
  height: 250px;
  object-fit: contain;
  cursor: pointer;
`;

const VideoThumbnail = styled.video`
  min-width: 400px;
  width: 100%;
  height: 250px;
  object-fit: contain;
  cursor: pointer;
`;

const ImageFullSize = styled.img`
  width: auto;
  height: auto;
  max-height: 80vh;
  max-width: 100%;
  margin: auto;
  display: block;
`;

const VideoFullSize = styled.video`
  width: auto;
  height: auto;
  max-height: 80vh;
  max-width: 100%;
  margin: auto;
  display: block;
`;

const Navigation = styled.div`
  padding: 10px 0;
`;

const VideoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const VideoIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.25);
  width: 400px;
  height: 100%;
  font-size: 30px;
  cursor: pointer;
`;

export const Uploads: React.FC<{ orderID: string }> = ({ orderID }) => {
  const { data } = useEstimationUploadsQuery({
    client,
    variables: { orderID },
  });
  const [focusedMedia, setFocusedMedia] = useState<EstimationUploadsQuery['estimationUploads'][0]['media']>();

  const carouselProps = useCarousel({
    draggable: false,
    wrapAround: true,
    initialIndex: 0,
  });

  if (!data) return null;

  return (
    <>
      {focusedMedia && (
        <Modal size={Modal.Size.Large} onClose={() => setFocusedMedia(undefined)}>
          <Modal.Content>
            <Modal.Body>
              {focusedMedia.isImage && <ImageFullSize src={focusedMedia.imgixURL + '?w=1000'} />}
              {focusedMedia.isVideo && <VideoFullSize src={focusedMedia.imgixURL} controls loop muted autoPlay />}
            </Modal.Body>
          </Modal.Content>
        </Modal>
      )}
      <Container>
        <Carousel {...carouselProps} slidesToShow={1}>
          {data.estimationUploads.map((upload) => (
            <Slide key={upload.id}>
              {upload.media.isImage && (
                <ImageThumbnail onClick={() => setFocusedMedia(upload.media)} src={upload.media.imgixURL + '?w=400'} />
              )}
              {upload.media.isVideo && (
                <VideoContainer onClick={() => setFocusedMedia(upload.media)}>
                  <VideoThumbnail src={upload.media.imgixURL + '?w=400'} />
                  <VideoIconContainer>
                    <FontAwesomeIcon icon={faPlayCircle} />
                  </VideoIconContainer>
                </VideoContainer>
              )}
            </Slide>
          ))}
        </Carousel>
        {data.estimationUploads.length > 1 && (
          <Navigation>
            <button className="btn btn-default btn-active-primary" onClick={carouselProps.prev}>
              ‹
            </button>
            {data.estimationUploads.map((_, idx) => (
              <button
                key={'page-' + idx}
                className="btn btn-default btn-active-primary"
                onClick={() => carouselProps.setIdx(idx)}
                disabled={carouselProps.idx === idx}
              >
                {idx + 1}
              </button>
            ))}
            <button className="btn btn-default btn-active-primary" onClick={carouselProps.next}>
              ›
            </button>
          </Navigation>
        )}
      </Container>
    </>
  );
};

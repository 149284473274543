import React, { useState } from 'react';
import { DateTime } from 'luxon';

import { client } from '@admin/libraries/apollo';
import {
  Availability__GlobalUnavailabilityFiltersInput,
  AvailabilityGlobalUnavailabilityDocument,
  useAvailabilityGlobalUnavailabilitiesDestroyMutation,
  useAvailabilityGlobalUnavailabilityQuery,
} from '@admin/schema';

import { PageHeader } from '@admin/components/helpers/page_header';
import { Pagination } from '@admin/components/pagination';
import { Spinner } from '@admin/components/spinner';
import { globalUnavailabilityNewURL } from '@admin/config/routes';
import { Alert, Breadcrumb, Panel } from '@shared/components/bootstrap';
import { useDebounce } from '@shared/hooks';

import { AvailabilityControls } from '../controls';
import { Filters } from './filters';
import { Entries } from './list/entries';

const DEFAULT_FILTERS: Availability__GlobalUnavailabilityFiltersInput = {
  fromDate: DateTime.local().toISODate(),
  tillDate: DateTime.local().plus({ days: 30 }).toISODate(),
  reasons: [],
  regionIDs: [],
};

export const List: React.FC = () => {
  const [filters, setFilters] = useState<Availability__GlobalUnavailabilityFiltersInput>(DEFAULT_FILTERS);
  const [page, setPage] = useState<number>();
  const [selectedIDs, setSelectedIDs] = useState<string[]>([]);

  const { data, loading: querying } = useAvailabilityGlobalUnavailabilityQuery({
    client,
    variables: {
      page,
      filters: {
        ...filters,
        name: useDebounce(filters.name),
      },
    },
    fetchPolicy: 'network-only',
  });
  const [destroy, { data: destroyData, loading: destroying }] = useAvailabilityGlobalUnavailabilitiesDestroyMutation({
    client,
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: AvailabilityGlobalUnavailabilityDocument,
        variables: {
          filters,
        },
      },
    ],
    onCompleted: () => setSelectedIDs([]),
  });

  const onDestroy = async () => {
    await destroy({
      variables: {
        IDs: selectedIDs,
      },
    });
  };

  const onPageChange = (pageNumber: number) => {
    setSelectedIDs([]);
    setPage(pageNumber);
  };

  const paginated = data && data.paginated;
  const options = paginated?.results.map(({ id }) => id) ?? [];
  const policy = data && data.policy;
  const loading = querying || destroying;

  return (
    <>
      <PageHeader>
        <Breadcrumb>
          <Breadcrumb.Item active>Global Unavailability</Breadcrumb.Item>
        </Breadcrumb>
      </PageHeader>
      <Panel>
        <Panel.Body>
          {destroyData?.result?.error && <Alert style="danger">{destroyData.result.error}</Alert>}
          <Filters filters={filters} onChange={setFilters} />
          <AvailabilityControls
            createHref={globalUnavailabilityNewURL()}
            loading={loading}
            options={options}
            selectedOptions={selectedIDs}
            create={policy?.create}
            destroy={policy?.destroy}
            onDestroy={onDestroy}
            setSelectedOptions={setSelectedIDs}
          />
          {loading && <Spinner />}
          {paginated && (
            <Entries
              unavailabilities={paginated.results}
              selectedOptions={selectedIDs}
              setSelectedOptions={setSelectedIDs}
            />
          )}
          {paginated && <Pagination onPage={onPageChange} pagination={paginated.pagination} />}
        </Panel.Body>
      </Panel>
    </>
  );
};

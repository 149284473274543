(function () {
  angular.module('app').factory('ItemsExplorer', [
    '$resource',
    function ($resource) {
      return $resource(
        'items.json',
        {},
        {
          index: {
            method: 'GET',
            isArray: false,
            paramSerializer: 'NestedParamsSerializer',
          },
        },
      );
    },
  ]);
})();

import * as React from 'react';
import { useState } from 'react';

import { Panel } from '@admin/components/helpers/panel';
import { Pagination } from '@admin/components/pagination';
import { Spinner } from '@admin/components/spinner';
import { useDebounce } from '@shared/hooks';

import {
  SelfStorage__RentalFiltersInput as SelfStorage__Rental__FiltersInput,
  usePaginatedSelfStorageRentalsQuery,
} from '@admin/schema';
import { client } from '@admin/libraries/apollo';
import { Entries } from './list/entries';
import { Filters } from './list/filters';

export const List: React.FC = () => {
  const [filters, setFilters] = useState<SelfStorage__Rental__FiltersInput>({});
  const [page, setPage] = useState<number | undefined>();
  const { data, loading } = usePaginatedSelfStorageRentalsQuery({
    client,
    variables: {
      page,
      filters: {
        ...filters,
        query: useDebounce(filters.query),
      },
    },
  });
  const paginated = data && data.paginated;

  return (
    <Panel>
      <Panel.Body>
        <Filters
          filters={filters}
          onChange={(changes) => {
            setPage(undefined);
            setFilters(changes);
          }}
        />
        {paginated && <Entries rentals={paginated.results} />}
        {loading && <Spinner />}
        {paginated && <Pagination onPage={setPage} pagination={paginated.pagination} />}
      </Panel.Body>
    </Panel>
  );
};

const app = angular.module('app');

app.controller('TicketManagementController', [
  '$q',
  '$scope',
  'TicketGroup',
  'TicketGrouping',
  function ($q, $scope, TicketGroup, TicketGrouping) {
    const groups = TicketGroup.query({ filter: 'editable' });
    const groupings = TicketGrouping.query();
    $q.all([groups.$promise, groupings.$promise]).then(() => {
      _.each(groupings, (grouping) => {
        /* eslint-disable-next-line no-param-reassign */
        grouping.groups = _.filter(groups, (group) => _.some(grouping.groups, (other) => other.id === group.id));
      });
      $scope.groups = groups;
      $scope.groupings = groupings;
    });
  },
]);

(function () {
  const app = angular.module('app');

  app.component('editCustomersForm', {
    templateUrl: 'partials/customers/edit_customers_form.html',
    controller: 'EditCustomersFormController as edit_customers_form_controller',
    bindings: {
      account: '<',
      errorHandler: '&',
      successHandler: '&',
    },
  });
})();

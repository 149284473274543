import * as React from 'react';
import { useFormContext } from 'react-hook-form';

import { useEmailValidation } from '@admin/hooks';

import { REGEX } from '@admin/utils/email';

import { Field } from '../field';

const ID = 'customer-email';
const NAME = 'customer.email';

export const EmailField: React.FC<{ defaultValue?: string }> = ({ defaultValue }) => {
  const validate = useEmailValidation();
  const { register } = useFormContext<{
    customer: {
      email: string;
    };
  }>();

  return (
    <Field required id={ID} name={NAME} label="Email Address">
      <input
        id={ID}
        className="form-control"
        type="text"
        name={NAME}
        ref={register({
          required: 'Please provide an email address',
          validate: async (email) =>
            (REGEX.test(email) && (await validate(email))) || 'Please provide a valid email address',
        })}
        defaultValue={defaultValue}
      />
    </Field>
  );
};

import React from 'react';
import { DateTime } from 'luxon';

import { useWaitlistRequestsQuery } from '@admin/schema';

import { client } from '@admin/libraries/apollo';

export const WaitlistRequests: React.FC<{ orderID: string }> = ({ orderID }) => {
  const { data } = useWaitlistRequestsQuery({ variables: { orderID }, client });

  if (!data?.order.waitlistRequests.length) return null;

  return (
    <div>
      <strong>Waitlisted Dates:</strong>
      <ul>
        {data.order.waitlistRequests.map(({ date }) => (
          <li key={date}>{DateTime.fromISO(date).toLocaleString(DateTime.DATE_MED)}</li>
        ))}
      </ul>
    </div>
  );
};

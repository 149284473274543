import * as React from 'react';

import { PricingGroupPricingEntryFragment } from '@admin/schema';

import { Currency } from '@shared/components/helpers';

export const ProtectionTotal: React.FC<{
  protection?: PricingGroupPricingEntryFragment;
}> = ({ protection }) => {
  if (!protection) {
    return <>-</>;
  }
  return <Currency value={protection.pricing.amount} />;
};

(function () {
  angular.module('app').service('MoneyMathService', [
    function () {
      return {
        applyPercentDiscount: function (amount, discountPercentage) {
          return amount * (parseFloat(discountPercentage) / 100.0);
        },
        applyDecimalDiscount: function (amount, discountPercentage) {
          return amount * parseFloat(discountPercentage);
        },
        roundToHundred: function (number) {
          return Math.ceil(number / 100) * 100;
        },
      };
    },
  ]);
})();

/* eslint-disable import/prefer-default-export */

export const transformReferralsResult = (referral) => {
  if (referral.promo_type !== 'User') {
    return {
      ...referral,
      promo_label: referral.promo_type,
    };
  }
  return {
    ...referral,
    promo_label: referral.referrer.deleted_at ? 'Former Employee' : 'Employee',
  };
};

import angular from 'angular';
import { isPlainObject, isString } from 'lodash';

angular.module('app').filter('url', () => (url, parameters) => {
  let result = url;
  if (isString(url) && isPlainObject(parameters)) {
    Object.entries(parameters).forEach((parameter) => {
      result = result.replace(`/:${parameter[0]}/`, `/${parameter[1]}/`);
    });
  }
  return result;
});

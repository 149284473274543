(function () {
  const app = angular.module('app');

  const FILTERS = ['user_id'];

  const DAYS = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

  const ATTRIBUTES = [
    'id',
    'user_id',
    'reoccurring',
    'forever',
    'start_date',
    'until_date',
    'mon',
    'tue',
    'wed',
    'thu',
    'fri',
    'sat',
    'sun',
  ];

  const DEFAULTS = {
    forever: true,
    start_date: null,
    until_date: null,
    mon: false,
    tue: false,
    wed: false,
    thu: false,
    fri: false,
    sat: false,
    sun: false,
  };

  app.controller('AvailabilityManagementController', [
    '$scope',
    'Unavailability',
    'ErrorService',
    'ConfirmationService',
    function ($scope, Unavailability, ErrorService, ConfirmationService) {
      $scope.days = DAYS;

      $scope.filtering = function () {
        return (
          $scope.filters.mode === 'management' &&
          _.every(FILTERS, function (filter) {
            return !!$scope.filters[filter];
          })
        );
      };

      $scope.filter = function () {
        $scope.unavailabilities = null;
        if ($scope.filtering()) {
          const filters = _.pick($scope.filters, FILTERS);
          Unavailability.query(filters).$promise.then(function (unavailabilities) {
            $scope.unavailabilities = unavailabilities;
          });
        }
      };

      $scope.filter($scope.filters);
      $scope.$on('filter', function () {
        $scope.filter($scope.filters);
      });

      $scope.resource = function (unavailability) {
        if (!unavailability) {
          return $scope.form;
        }

        if (unavailability === $scope.selection) {
          return $scope.form;
        } else {
          return unavailability;
        }
      };

      $scope.add = function (reoccurring) {
        $scope.selection = null;
        $scope.form = new Unavailability(DEFAULTS);
        $scope.form.reoccurring = reoccurring;
        _.assign($scope.form, $scope.filters);
      };

      $scope.manage = function (unavailability) {
        $scope.selection = unavailability;
        $scope.form = angular.copy($scope.selection);
      };

      $scope.cancel = function () {
        $scope.selection = null;
        $scope.form = null;
      };

      $scope.save = function () {
        if ($scope.saving) {
          return;
        }
        const method = $scope.selection ? 'update' : 'create';
        $scope.saving = _.pick($scope.form, ATTRIBUTES);
        Unavailability[method]($scope.saving).$promise.then(
          function (response) {
            if ($scope.selection) {
              _.assign($scope.selection, $scope.saving);
            } else {
              $scope.form.id = response.id;
              $scope.unavailabilities.push($scope.form);
            }
            delete $scope.saving;
            $scope.cancel();
          },
          function (response) {
            delete $scope.saving;
            ErrorService.handle(response);
          },
        );
      };

      $scope.destroy = function (unavailability) {
        ConfirmationService.confirm({
          title: 'Are You Sure?',
          description: 'Are you sure you want to delete this?',
          acceptButton: 'Continue',
          declineButton: 'Cancel',
        }).then(function () {
          unavailability.$remove(function () {
            _.remove($scope.unavailabilities, unavailability);
          }, ErrorService.handle);
        });
      };
    },
  ]);
})();

import { logisticsNewLoadsURL } from '@admin/config/routes';
import { Roles } from '@admin/components/helpers/roles';
import { UserRole } from '@admin/types';
import styled from '@emotion/styled';
import { client } from '@admin/libraries/apollo';
import { Button } from '@shared/components/bootstrap';
import { useDebounce } from '@shared/hooks';
import React, { useEffect, useState } from 'react';
import {
  usePaginatedLoadQuery,
  LoadTypeEnum,
  useWarehousesQuery,
  Logistics__LoadsFilterInputType,
} from '@admin/schema';
import { MultiselectFormControl } from '@admin/components/fields';

import { Panel } from '@admin/components/helpers/panel';
import { Pagination } from '@admin/components/pagination';
import { Spinner } from '@admin/components/spinner';
import { LoadsTable as Table } from './table';

const StyledButton = styled(Button)`
  margin: 10px;
`;

enum State {
  Draft = 'draft',
  Pending = 'pending',
  Prepared = 'prepared',
  Completed = 'completed',
  Canceled = 'canceled',
}

export const LogisticsLoads: React.FC<
  Logistics__LoadsFilterInputType & {
    page?: number;
    onFilter(filters: Logistics__LoadsFilterInputType & { page?: number }): void;
  }
> = ({ onFilter, ...defaults }) => {
  const [page, setPage] = useState<number | undefined>(defaults.page);
  const [warehouseIDs, setWarehouseIDs] = useState<string[]>(defaults.warehouseIDs ?? []);
  const [search, setSearch] = useState<string | undefined>(defaults.search ?? undefined);
  const [state, setState] = useState<string | undefined>(defaults.state ?? undefined);
  const [type, setType] = useState<LoadTypeEnum | undefined>(defaults.type ?? undefined);
  const [appointmentDate, setAppointmentDate] = useState<string | undefined>(defaults.appointmentDate ?? undefined);

  const { data, loading } = usePaginatedLoadQuery({
    client,
    variables: {
      page,
      filters: {
        warehouseIDs,
        state,
        appointmentDate,
        type,
        search: useDebounce(search),
      },
    },
  });

  useEffect(() => {
    onFilter({
      warehouseIDs,
      search,
      state,
      appointmentDate,
      type,
      page,
    });
  }, [warehouseIDs, search, state, appointmentDate, type, page]);

  const warehouses = useWarehousesQuery({ client }).data?.warehouses || [];

  const paginated = data && data.paginated;
  return (
    <>
      <Panel>
        <Panel.Body>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label className="control-label" htmlFor="search">
                  Search:
                </label>
                <input
                  id="search"
                  className="form-control"
                  type="search"
                  placeholder="Search (Load Number, SKU Value, Customer Name, BOL Number, Trailer Number, Carrier Name, Release Number, Reference, Ship-to Address)"
                  value={search || ''}
                  onChange={(event) => {
                    setPage(undefined);
                    setSearch(event.target.value || undefined);
                  }}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form3group">
                <label className="control-label" htmlFor="type">
                  Type:
                </label>
                <select
                  id="type"
                  className="form-control"
                  value={type || ''}
                  onChange={(event) => {
                    setPage(undefined);
                    setType((event.target.value || undefined) as LoadTypeEnum);
                  }}
                >
                  <option value={''}> - All - </option>
                  <option value={LoadTypeEnum.Inbound}>Inbound</option>
                  <option value={LoadTypeEnum.Outbound}>Outbound</option>
                </select>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="form-group">
                <label className="control-label">Warehouse:</label>
                <MultiselectFormControl
                  placeholder="Warehouses"
                  options={warehouses}
                  selectedIDs={warehouseIDs}
                  onChange={(ids) => {
                    setPage(undefined);
                    setWarehouseIDs(ids);
                  }}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label className="control-label" htmlFor="state">
                  State:
                </label>
                <select
                  id="state"
                  className="form-control"
                  value={state || ''}
                  onChange={(event) => {
                    setPage(undefined);
                    setState((event.target.value || undefined) as State);
                  }}
                >
                  <option value=""> - All - </option>
                  <option value={State.Draft}>Draft</option>
                  <option value={State.Pending}>Pending</option>
                  <option value={State.Prepared}>Prepared</option>
                  <option value={State.Completed}>Completed</option>
                  <option value={State.Canceled}>Canceled</option>
                </select>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label className="control-label" htmlFor="appointmentDate">
                  Appointment Date:
                </label>
                <input
                  id="appointmentDate"
                  min="0000-01-01"
                  max="9999-12-31"
                  type="date"
                  className="form-control"
                  value={appointmentDate || ''}
                  onChange={(event) => {
                    setPage(undefined);
                    setAppointmentDate(event.target.value || undefined);
                  }}
                />
              </div>
            </div>
          </div>
        </Panel.Body>
      </Panel>
      <Panel>
        <Panel.Header>
          <Panel.Title>
            Loads
            <Roles show={[UserRole.Admin, UserRole.EnterpriseManager, UserRole.Manager]}>
              <StyledButton
                onClick={() => location.replace(logisticsNewLoadsURL())}
                kind="primary"
                className="pull-right"
              >
                New Load
              </StyledButton>
            </Roles>
          </Panel.Title>
        </Panel.Header>
        <Panel.Body>
          {loading && <Spinner />}
          {!loading && paginated && (
            <>
              <Table dateHeader="Appointment Date" loads={paginated.results} />
              <Pagination pagination={paginated.pagination} onPage={setPage} />
            </>
          )}
        </Panel.Body>
      </Panel>
    </>
  );
};

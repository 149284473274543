import React from 'react';

import { Formik, Form } from 'formik';
import { useRegionsSelectorQuery } from '@admin/schema';
import { client } from '@admin/libraries/apollo';
import { DateTime } from 'luxon';
import { FormikDateTimePicker } from '@admin/components/fields/formik/formik_date_time_picker';
import { Panel } from '@admin/components/helpers/panel';
import { Button, Col, Row } from '@shared/components/bootstrap';
import { FormikSelectFormGroup } from '@shared/components/fields/formik/formik_select_form_group';
import { FormikInputFormGroup } from '@shared/components/fields/formik/formik_input_form_group';
import { FormikDurationFormGroup } from '@admin/components/fields/formik/formik_duration_form_group.tsx';
import { FormikAddressFinderFormGroup } from '@admin/components/fields/formik/formik_address_finder_form_group';
import { ClientAddress } from '@admin/components/fields/address_finder_form_control';

export type Values = {
  scheduled?: DateTime;
  regionID?: string;
  movers?: string;
  estimatedDuration?: number;
  address?: ClientAddress;
};

const validate = (validateAddress: boolean) => (values: Values) => {
  const errors: Partial<Record<keyof Values, string>> = {};

  if (!values.regionID) {
    errors.regionID = 'Please select a region';
  }
  if (!values.movers || isNaN(parseInt(values.movers))) {
    errors.movers = 'Please provide a mover count';
  }
  if (!values.scheduled) {
    errors.scheduled = 'Please set an appointment time';
  }
  if (values.estimatedDuration === undefined) {
    errors.estimatedDuration = 'Please set an estimated duration';
  }
  if (validateAddress && values.address === undefined) {
    errors.address = 'Please select an address';
  }

  return errors;
};

export const DetailForm: React.FC<{
  addressInputConfig?: { accountID: string };
  initialValues: Values;
  onSubmit: (values: Values) => void;
  loading?: boolean;
}> = ({ initialValues, onSubmit, loading, addressInputConfig }) => {
  const { data: regionData } = useRegionsSelectorQuery({ client });

  return (
    <Formik<Values>
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={validate(!!addressInputConfig)}
      validateOnMount={false}
    >
      <Form>
        <Panel>
          <Panel.Header>
            <Panel.Title>Edit Details</Panel.Title>
          </Panel.Header>
          <Panel.Body>
            <Row>
              <Col md={6}>
                {addressInputConfig && (
                  <FormikAddressFinderFormGroup
                    name="address"
                    label="First Task Address"
                    accountID={addressInputConfig.accountID}
                  />
                )}
                <FormikSelectFormGroup id="region" name="regionID" label="Region">
                  {!initialValues.regionID && <option>Select</option>}
                  {regionData?.regions?.map(({ id, name }) => (
                    <option key={id} value={id}>
                      {name}
                    </option>
                  ))}
                </FormikSelectFormGroup>
                <FormikInputFormGroup id="movers" name="movers" label="Movers" type="number" step="1" />
                <FormikDateTimePicker id="scheduled" name="scheduled" label="Scheduled" allowPastDate={false} />
                <FormikDurationFormGroup name="estimatedDuration" label="Estimated Duration" unit="seconds" />
              </Col>
            </Row>
          </Panel.Body>
          <Panel.Footer>
            <Button type="submit" kind="primary" loading={loading}>
              Save
            </Button>
          </Panel.Footer>
        </Panel>
      </Form>
    </Formik>
  );
};

import styled from '@emotion/styled';
import { DateTime } from 'luxon';
import React from 'react';
import { useContext } from 'react';
import { ErrorMessage, useFormContext } from 'react-hook-form';
import { Ordinalize } from '@admin/components/helpers/ordinalize';
import { FormGroup, HelpBlock, Radio } from '@shared/components/bootstrap';
import { Pluralize, Spacer } from '@shared/components/helpers';
import { Element } from '@shared/components/stripe';

import { Detailed as BillingSourceDetailed } from '@admin/components/billing/sources/detailed';
import { SelfStorage__Rental__BillingEnum, SelfStorage__Status as SelfStorage__Rental__Status } from '@admin/schema';
import { COLORS } from '@clutter/clean';
import { Section } from '../components/section';
import { SectionHeading } from '../components/section_heading';
import { Field } from './field';

import { Context } from './form/context';

import { FormData } from './form/types';

type FieldsData = Pick<FormData, 'billing'>;

const BILLING = SelfStorage__Rental__BillingEnum.ChargeAutomatically;

const Hint = styled.div`
  color: ${COLORS.hippo};
`;

const TODAY = 'unit’s monthly bill date will be set today';

const Counts: React.FC<{ sourceID?: string }> = ({ sourceID }) => {
  const account = useContext(Context).account;
  const subscriptions = account?.subscriptions.filter(
    ({ billing, source }) => billing === BILLING && source?.id === sourceID,
  );

  if (!subscriptions?.length) {
    return <Hint>0 units, {TODAY}</Hint>;
  }

  const totals = new Map<number | undefined, number>();
  for (const subscription of subscriptions) {
    const day = subscription.billsOn ? DateTime.fromISO(subscription.billsOn).day : undefined;
    const count = subscription.rentals.filter(({ status }) => status === SelfStorage__Rental__Status.Active).length;
    totals.set(day, (totals.get(day) ?? 0) + count);
  }

  return (
    <>
      {Array.from(totals.keys()).map((day) => {
        const count = totals.get(day) as number;
        return (
          <Hint key={day ?? 'today'}>
            <Pluralize count={count} singular="unit" plural="units" />,{' '}
            {day ? (
              <>
                bills on the <Ordinalize value={day} />
              </>
            ) : (
              TODAY
            )}
          </Hint>
        );
      })}
    </>
  );
};

const Form: React.FC = () => {
  const element = useContext(Context).element;
  const { register } = useFormContext<FieldsData>();

  return (
    <>
      <Field id="card-name" name="billing.name">
        <input
          type="text"
          className="form-control"
          name="billing.name"
          placeholder="Name on Card"
          ref={register({ required: 'Please provide a valid name on card' })}
        />
      </Field>
      <FormGroup>
        <Element element={element} />
      </FormGroup>
    </>
  );
};

const Unbillable: React.FC = () => {
  const { register } = useFormContext<FieldsData>();

  return (
    <>
      <input type="hidden" name="billing.method" value="tokenizer" ref={register} />
      <Form />
    </>
  );
};

const Billable: React.FC = () => {
  const context = useContext(Context);
  const sources = context.account?.sources;
  const { errors, register, watch } = useFormContext<FieldsData>();

  return (
    <>
      <FormGroup has={errors.billing?.method ? 'error' : undefined}>
        <Radio>
          <input
            type="radio"
            name="billing.method"
            value="default"
            ref={register({ required: 'Please select a billing method' })}
          />
          Always use the default payment method on file
          <Counts />
        </Radio>
        {sources?.map((source) => (
          <Radio key={source.id}>
            <input
              type="radio"
              name="billing.method"
              value={source.id}
              ref={register({ required: 'Please select a billing method' })}
            />
            <BillingSourceDetailed source={source} />
            <Counts sourceID={source.id} />
          </Radio>
        ))}
        <Radio>
          <input
            type="radio"
            name="billing.method"
            value="tokenizer"
            ref={register({ required: 'Please select a billing method' })}
          />
          Use a new payment method
          <Hint>{TODAY}</Hint>
        </Radio>
        <ErrorMessage name={'billing.method'}>{({ message }) => <HelpBlock>{message}</HelpBlock>}</ErrorMessage>
      </FormGroup>
      {watch('billing.method') === 'tokenizer' && <Form />}
    </>
  );
};

export const Billing: React.FC = () => {
  const sources = useContext(Context).account?.sources;
  const billable = sources?.some((source) => source.billable);

  const Component = billable ? Billable : Unbillable;

  return (
    <Section>
      <SectionHeading>Payment Information</SectionHeading>
      <Component />
      <Spacer height="36px" />
    </Section>
  );
};

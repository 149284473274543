import cn from 'classnames';
import React from 'react';
import { Titleize } from '@admin/components/helpers/titleize';

import { DateTime } from 'luxon';

import { AppointmentStateEnum, OrderStateEnum } from '@admin/schema';

import { PaginatedOrderResult } from '../list';
import { DropOffDisplay } from './drop_off';

export const State: React.FC<{ order: PaginatedOrderResult }> = ({ order }) => {
  const tz = order.region.tz;
  const scheduled = DateTime.fromISO(order.scheduled).setZone(tz);

  const OrderState = () => (
    <span
      className={cn('label', {
        'label-danger': order.state === OrderStateEnum.Canceled,
        'label-info': order.state === OrderStateEnum.Completed,
        'label-default': order.state !== OrderStateEnum.Canceled && order.state !== OrderStateEnum.Completed,
      })}
    >
      <Titleize text={order.state} />
    </span>
  );

  const { dropOffAppointment } = order;

  if (dropOffAppointment) {
    const { expectedAt } = dropOffAppointment;
    const expectedAtDateTime = DateTime.fromISO(expectedAt, { setZone: true });

    const DropOffState = () => (
      <span
        className={cn('label', {
          'label-danger': dropOffAppointment.state === AppointmentStateEnum.Canceled,
          'label-info': dropOffAppointment.state === AppointmentStateEnum.Completed,
          'label-default':
            dropOffAppointment.state !== AppointmentStateEnum.Canceled &&
            dropOffAppointment.state !== AppointmentStateEnum.Completed,
        })}
      >
        <Titleize text={dropOffAppointment.state} />
      </span>
    );

    return (
      <DropOffDisplay
        orderDate={scheduled}
        dropOffDate={expectedAtDateTime}
        orderComponent={<OrderState />}
        dropOffComponent={<DropOffState />}
      />
    );
  }

  return <OrderState />;
};

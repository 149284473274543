import { DateTime } from 'luxon';
import React, { useState } from 'react';

import { PageHeader } from '@admin/components/helpers/page_header';
import { Alert, Breadcrumb, Panel } from '@shared/components/bootstrap';
import {
  Availability__RescheduleOfferFiltersInput,
  AvailabilityRescheduleOffersDocument,
  useAvailabilityRescheduleOffersDeleteMutation,
  useAvailabilityRescheduleOffersQuery,
} from '@admin/schema';
import { client } from '@admin/libraries/apollo';
import { Spinner } from '@admin/components/spinner';
import { Pagination } from '@admin/components/pagination';

import { availabilityRescheduleOffersNewURL } from '@admin/config/routes';
import { AvailabilityControls } from '@admin/components/availability/controls';
import { Entries } from './entries';
import { Filters } from './filters';

const DEFAULT_FILTERS: Availability__RescheduleOfferFiltersInput = {
  fromDate: DateTime.local().toISODate(),
  tillDate: DateTime.local().plus({ days: 30 }).toISODate(),
  regionIDs: [],
  orderClassifications: [],
};

export const List: React.FC = () => {
  const [filters, setFilters] = useState<Availability__RescheduleOfferFiltersInput>(DEFAULT_FILTERS);
  const [page, setPage] = useState<number>();
  const [selectedIDs, setSelectedIDs] = useState<string[]>([]);

  const { data, loading: querying } = useAvailabilityRescheduleOffersQuery({
    client,
    variables: {
      page,
      filters,
    },
  });

  const [destroy, { data: destroyData, loading: destroying }] = useAvailabilityRescheduleOffersDeleteMutation({
    client,
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: AvailabilityRescheduleOffersDocument,
        variables: {
          page,
          filters,
        },
      },
    ],
    onCompleted: () => setSelectedIDs([]),
  });

  const onDestroy = async () => {
    await destroy({
      variables: {
        IDs: selectedIDs,
      },
    });
  };

  const onPageChange = (pageNumber: number) => {
    setSelectedIDs([]);
    setPage(pageNumber);
  };

  const paginated = data?.paginated;
  const policy = data && data.policy;
  const options =
    paginated?.results.reduce((acc, { id, expired }) => {
      if (!expired) {
        acc.push(id);
      }
      return acc;
    }, [] as string[]) ?? [];
  const loading = querying || destroying;

  return (
    <>
      <PageHeader>
        <Breadcrumb>
          <Breadcrumb.Item active>Availability Reschedule Offers</Breadcrumb.Item>
        </Breadcrumb>
      </PageHeader>
      <Panel>
        <Panel.Body>
          {destroyData?.result?.error && <Alert style="danger">{destroyData.result.error}</Alert>}
          <Filters filters={filters} onChange={setFilters} />
          <AvailabilityControls
            createHref={availabilityRescheduleOffersNewURL()}
            loading={loading}
            options={options}
            selectedOptions={selectedIDs}
            create={policy?.create}
            destroy={policy?.destroy}
            onDestroy={onDestroy}
            setSelectedOptions={setSelectedIDs}
          />
          {loading && <Spinner />}
          {paginated && (
            <Entries
              availabilityRescheduleOffers={paginated.results}
              selectedOptions={selectedIDs}
              setSelectedOptions={setSelectedIDs}
            />
          )}
          {paginated && <Pagination onPage={onPageChange} pagination={paginated.pagination} />}
        </Panel.Body>
      </Panel>
    </>
  );
};

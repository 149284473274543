import * as React from 'react';

import { Billing__OfflinePayment__Kind } from '@admin/schema';

import { SelectFormGroup } from '@admin/components/fields';

export const KindFormGroup: React.FC<{
  kind?: Billing__OfflinePayment__Kind;
  onSelect(kind?: Billing__OfflinePayment__Kind): void;
}> = ({ kind, onSelect }) => {
  const onChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value || undefined;
    onSelect(value as Billing__OfflinePayment__Kind);
  };

  return (
    <SelectFormGroup required id="offline_payment_kind" label="Method:" value={kind || ''} onChange={onChange}>
      <option value="">- Kind -</option>
      <option value={Billing__OfflinePayment__Kind.Cash}>Cash</option>
      <option value={Billing__OfflinePayment__Kind.Check}>Check</option>
      <option value={Billing__OfflinePayment__Kind.MoneyOrder}>Money order</option>
      <option value={Billing__OfflinePayment__Kind.Ach}>ACH</option>
      <option value={Billing__OfflinePayment__Kind.Other}>Other</option>
    </SelectFormGroup>
  );
};

/* eslint-disable max-classes-per-file */
import { useState } from 'react';
import { debounce } from 'lodash';

declare const window: {
  clutter: {
    systemSpecStubs: Record<string, any>;
  };
};

interface AutocompleteService {
  getSuggestions: (
    _: string,
    callback: (suggestions: google.maps.places.AutocompletePrediction[] | null) => void,
    options: {
      componentRestrictions: google.maps.places.AutocompleteOptions['componentRestrictions'];
    },
  ) => void;
}

interface AutocompleteServiceConstructor {
  new (): AutocompleteService;
}

class StubbedAddressAutocompleteService implements AutocompleteService {
  public getSuggestions = (
    _: string,
    callback: (suggestions: google.maps.places.AutocompletePrediction[] | null) => void,
  ) => {
    callback(window.clutter.systemSpecStubs.googleAutocomplete || []);
  };
}

class GoogleAddressAutocompleteService implements AutocompleteService {
  private autocompleteService: google.maps.places.AutocompleteService;

  constructor() {
    this.autocompleteService = new google.maps.places.AutocompleteService();
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  public getSuggestions = debounce(
    (
      input: string,
      callback: (suggestions: google.maps.places.AutocompletePrediction[] | null) => void,
      options: {
        componentRestrictions: google.maps.places.AutocompleteOptions['componentRestrictions'];
      },
    ) => {
      this.autocompleteService.getPlacePredictions(
        {
          input,
          types: ['address'],
          componentRestrictions: options.componentRestrictions,
        },
        callback,
      );
    },
  );
}

const Service: AutocompleteServiceConstructor =
  process.env.NODE_ENV === 'test' ? StubbedAddressAutocompleteService : GoogleAddressAutocompleteService;

let service: AutocompleteService;

export const useAddressAutocompleteService = () => useState(() => service || (service = new Service()))[0];

import angular from 'angular';

(function () {
  angular.module('app').factory('WarehouseClaimer', [
    '$resource',
    function ($resource) {
      return $resource(
        '/dispatches/:dispatch_id/tasks.json',
        {
          id: '@id',
          dispatch_id: '@dispatch_id',
        },
        {
          get: { method: 'GET', isArray: true },
          cancel: {
            url: '/dispatches/:dispatch_id/tasks/:id/cancel',
            method: 'PATCH',
          },
        },
      );
    },
  ]);
})();

import angular from 'angular';

const app = angular.module('app');

app.component('followupFieldsContainer', {
  templateUrl: 'partials/components/followup_fields_container.html',
  bindings: {
    errors: '<',
    lead: '<?',
    selections: '<',
    validatedZip: '<?',
    onChange: '&',
  },
  controller: [
    'FollowupService',
    'TimeZone',
    function (FollowupService, TimeZone) {
      const updateTimeZoneByZip = () => {
        if (this.timeZones && this.validatedZip) {
          const params = { zip: this.validatedZip };
          TimeZone.get(params)
            .$promise.then((tzData) => FollowupService.getMatchingTimeZone(tzData, this.timeZones))
            .then((timeZone) => {
              this.updateSelections({ timeZone });
            });
        }
      };

      this.updateSelections = (updates) => {
        this.onChange({ updates });
      };

      const tzDataBecameAvailable = (changes) => {
        const tzData = changes && changes.lead && changes.lead.currentValue;
        return !!tzData && _.isBoolean(tzData.dst_flag) && angular.isDefined(tzData.time_zone_offset);
      };

      this.$onChanges = (changes) => {
        if (tzDataBecameAvailable(changes)) {
          this.updateSelections({ timeZone: FollowupService.getMatchingTimeZone(this.lead, this.timeZones) });
        } else if (changes.validatedZip && changes.validatedZip.currentValue) {
          updateTimeZoneByZip();
        }
      };

      this.$onInit = () => {
        FollowupService.fetchTimeZones()
          .then((timeZones) => {
            this.timeZones = timeZones;
          })
          .then(updateTimeZoneByZip);
        FollowupService.fetchUnavailabilities().then((dates) => {
          this.callCenterUnavailabilities = dates;
        });
      };
    },
  ],
});

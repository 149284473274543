import template from '@admin/angular/templates/room_selections_preview.html';

(function () {
  const app = angular.module('app');

  app.component('roomSelectionsPreview', {
    template,
    bindings: {
      resource: '<',
    },
    controller: [
      'RoomCategoryService',
      '$q',
      function (RoomCategoryService, $q) {
        const self = this;

        function findRoomCategoryByID(id) {
          return _.find(RoomCategoryService.categories, (object) => object.id === id);
        }

        self.$onInit = function () {
          $q.all([self.resource.$promise, RoomCategoryService.categories.$promise]).then(() => {
            self.room_selections = [];
            _.map(self.resource.room_selections, (selection) => {
              if (selection.quantity > 0 || selection.selected) {
                const category = findRoomCategoryByID(selection.room_category_id);
                self.room_selections.push({ ...selection, room_category: category });
              }
            });
          });
        };
      },
    ],
  });
})();

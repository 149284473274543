import * as React from 'react';

import { ControlLabel, FormGroup, HelpBlock } from '@shared/components/bootstrap';

export const StreetFormGroup: React.FC<{
  error?: string;
  street?: string;
  disabled?: boolean;
  onChange(street: string): void;
}> = ({ error, street, onChange, disabled }) => (
  <FormGroup has={error !== undefined ? 'error' : undefined}>
    <ControlLabel htmlFor="address-street">Street</ControlLabel>
    <input
      disabled={disabled}
      id="address-street"
      className="form-control"
      type="text"
      value={street || ''}
      onChange={(event) => {
        onChange(event.target.value);
      }}
    />
    {error && <HelpBlock>{error}</HelpBlock>}
  </FormGroup>
);

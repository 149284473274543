import angular from 'angular';

const app = angular.module('app');

app.factory('RetailDelivery', [
  '$resource',
  'TransformBuilder',
  'OrderSerializer',
  'OrderDeserializer',
  function ($resource, TransformBuilder, serializer, deserializer) {
    return $resource(
      '/accounts/:account_id/retail_deliveries/:id.json',
      {
        id: '@id',
        account_id: '@account_id',
      },
      {
        get: {
          method: 'GET',
          transformResponse: TransformBuilder.transformResponse(deserializer),
        },
        update: {
          method: 'PATCH',
          transformRequest: TransformBuilder.transformRequest(serializer),
          transformResponse: TransformBuilder.transformResponse(deserializer),
        },
      },
    );
  },
]);

import React from 'react';

import { CustomerTicket__ContactPreference } from '@admin/schema';

const CUSTOMER_TICKET_CONTACT_PREFERENCES: Array<{ value: CustomerTicket__ContactPreference; name: string }> = [
  {
    value: CustomerTicket__ContactPreference.Chat,
    name: 'Chat',
  },
  {
    value: CustomerTicket__ContactPreference.Email,
    name: 'Email',
  },
  {
    value: CustomerTicket__ContactPreference.Phone,
    name: 'Phone',
  },
];

export const ContactPreferencePicker: React.FC<{
  className?: string;
  contactPreference?: CustomerTicket__ContactPreference;
  onChange(contactPreference: CustomerTicket__ContactPreference): void;
}> = ({ className, contactPreference, onChange }) => (
  <div className={className}>
    <select
      id="ticket-contact-preference-select"
      className="form-control"
      value={contactPreference}
      onChange={(event) => onChange(event.currentTarget.value as CustomerTicket__ContactPreference)}
      required
    >
      <option value="">-- Contact Preference --</option>
      {CUSTOMER_TICKET_CONTACT_PREFERENCES.map(({ value, name }) => (
        <option key={value} value={value}>
          {name}
        </option>
      ))}
    </select>
  </div>
);

import * as React from 'react';

import { Spinner } from '@admin/components/spinner';
import { Picker } from '@admin/components/ticket_groups/picker';

import { client } from '@admin/libraries/apollo';
import { useCustomerTicketGroupsQuery } from '@admin/schema';

export const TicketGroupPicker: React.FC<{
  className?: string;
  selectedGroupID?: string;
  selectedAssigneeID?: string;
  showAssigneePicker?: boolean;
  onChange(groupID?: string, userID?: string): void;
}> = ({ className, selectedGroupID, selectedAssigneeID, showAssigneePicker = true, onChange }) => {
  const { data, loading } = useCustomerTicketGroupsQuery({ client });

  if (!data || loading) {
    return <Spinner />;
  }

  return (
    <Picker
      ticketGroups={data.customerTicketGroups}
      className={className}
      selectedGroupID={selectedGroupID}
      selectedAssigneeID={selectedAssigneeID}
      showAssigneePicker={showAssigneePicker}
      onChange={onChange}
    />
  );
};

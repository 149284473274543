import { User__Role } from '@admin/schema';

export const USER_ROLES = [
  User__Role.Admin,
  User__Role.AuditReviewer,
  User__Role.Auditor,
  User__Role.Billing,
  User__Role.BillingAdmin,
  User__Role.Care,
  User__Role.Claims,
  User__Role.Collections,
  User__Role.DataCorrector,
  User__Role.Dispatcher,
  User__Role.Driver,
  User__Role.EnterpriseManager,
  User__Role.L1Agent,
  User__Role.L2Agent,
  User__Role.Manager,
  User__Role.Marketing,
  User__Role.Mover,
  User__Role.NightDriver,
  User__Role.OpsLeadership,
  User__Role.SelfStorageAssociate,
  User__Role.ShipmentPurchaser,
  User__Role.Retention,
  User__Role.Teamlead,
  User__Role.Tester,
  User__Role.Timekeeper,
  User__Role.Warehouse,
];

export const USER_ROLE_TO_NAME: Record<User__Role, string> = {
  [User__Role.Admin]: 'Admin',
  [User__Role.AuditReviewer]: 'Audit Reviewer',
  [User__Role.Auditor]: 'Auditor',
  [User__Role.Billing]: 'Billing',
  [User__Role.BillingAdmin]: 'Billing Admin',
  [User__Role.Care]: 'Care',
  [User__Role.Claims]: 'Claims',
  [User__Role.Collections]: 'Collections',
  [User__Role.DataCorrector]: 'Data Corrector',
  [User__Role.Dispatcher]: 'Dispatcher',
  [User__Role.Driver]: 'Driver',
  [User__Role.EnterpriseManager]: 'Enterprise Manager',
  [User__Role.L1Agent]: 'L1 Agent',
  [User__Role.L2Agent]: 'L2 Agent',
  [User__Role.Manager]: 'Manager',
  [User__Role.Marketing]: 'Marketing',
  [User__Role.Mover]: 'Mover',
  [User__Role.NightDriver]: 'Night Driver',
  [User__Role.OpsLeadership]: 'Ops Leadership',
  [User__Role.ShipmentPurchaser]: 'Shipment Purchaser',
  [User__Role.SelfStorageAssociate]: 'Self-Storage Associate',
  [User__Role.Retention]: 'Retention',
  [User__Role.Teamlead]: 'Teamlead',
  [User__Role.Tester]: 'Tester',
  [User__Role.Timekeeper]: 'Timekeeper',
  [User__Role.Warehouse]: 'Warehouse',
};

import styled from '@emotion/styled';
import React, { useState } from 'react';

import { NotesPanel } from '@admin/components/notes/panel';
import { Spinner } from '@admin/components/spinner';
import { Alert } from '@shared/components/bootstrap';
import { Spacer } from '@shared/components/helpers';

import {
  NoteNotableEnum,
  OpsAudit__AuditAnswersInputType,
  useBuildOpsAuditAnswersMutation,
  useConfigurationAuditReviewQuery,
  useNotAuditableOpsAuditMutation,
  useSkipOpsAuditMutation,
  Status,
} from '@admin/schema';

import { client } from '@admin/libraries/apollo';

import { AuditablesPanel } from './auditables_panel';
import { QuestionsPanel } from './questions_panel';
import { DisassemblyReview } from './disassembly_audit/disassembly_review';

const SpinnerWrapper = styled.div`
  display: flex;
`;

export const OpsAuditAuditReview: React.FC<{
  configurationID: string;
}> = ({ configurationID }) => {
  const {
    data,
    loading,
    error: fetchError,
    refetch,
  } = useConfigurationAuditReviewQuery({
    client,
    variables: { configurationID },
    fetchPolicy: 'no-cache',
  });
  const [error, setError] = useState<string | null | undefined>();
  const [executeSubmit, { loading: answersSaving }] = useBuildOpsAuditAnswersMutation({ client });
  const [executeNotAuditable, { loading: auditDismissing }] = useNotAuditableOpsAuditMutation({ client });
  const [executeSkip, { loading: auditSkipping }] = useSkipOpsAuditMutation({ client });

  const audit = data && data.audit;

  const handleResult = (status: string, resultError: string | null | undefined) => {
    if (status === Status.Unprocessable) {
      setError(resultError);
    } else {
      refetch();
      setError(undefined);
    }
  };

  const submit = async (answerInputs: OpsAudit__AuditAnswersInputType[]) => {
    if (!audit) {
      return;
    }

    const result = await executeSubmit({
      variables: {
        auditAnswers: answerInputs,
      },
    });
    if (!result || !result.data) {
      return;
    }

    handleResult(result.data.buildOpsAuditAnswers.status, result.data.buildOpsAuditAnswers.error || undefined);
  };

  const skip = async () => {
    if (!audit) {
      return;
    }

    const result = await executeSkip({
      variables: {
        auditID: audit.id,
      },
    });
    if (!result || !result.data) {
      return;
    }
    handleResult(result.data.skipOpsAudit.status, result.data.skipOpsAudit.error);
  };

  const submitNotAuditable = async () => {
    if (!audit) {
      return;
    }

    const result = await executeNotAuditable({
      variables: {
        auditID: audit.id,
      },
    });
    if (!result || !result.data) {
      return;
    }
    handleResult(result.data.notAuditableOpsAudit.status, result.data.notAuditableOpsAudit.error);
  };

  return (
    <div className="ops-audit-audit-review">
      <Spacer height="8px" />
      {loading && (
        <>
          <SpinnerWrapper>
            <Spinner />
          </SpinnerWrapper>
          <Spacer height="8px" />
        </>
      )}
      {error && <Alert style="danger">{error}</Alert>}
      {fetchError && (
        <Alert style="danger">
          An error has occurred, please try again. If the error persists, contact Tech Support.
        </Alert>
      )}
      {!loading && !audit && !fetchError && (
        <Alert style="success">Currently, this configuration has no new audits to review.</Alert>
      )}
      <div className="row">
        <div className="col-md-8">
          {audit && audit?.configuration.name !== 'Disassembly Audit' && (
            <AuditablesPanel images={audit.images} resourceID={audit.resourceID} resourceType={audit.resourceType} />
          )}
          {audit?.configuration.name === 'Disassembly Audit' && <DisassemblyReview audit={audit} />}
        </div>
        <div className="col-md-4">
          {audit && (
            <QuestionsPanel
              parentAudit={audit}
              childAudits={audit.children}
              answersSaving={answersSaving || auditDismissing || auditSkipping}
              onSave={async (answerInputs) => {
                submit(answerInputs);
              }}
              onReviewLater={() => {
                skip();
              }}
              onNotAuditable={() => {
                submitNotAuditable();
              }}
            />
          )}
          {audit && <NotesPanel type={NoteNotableEnum.OpsAuditAudit} id={audit.id} />}
        </div>
      </div>
    </div>
  );
};

import { client } from '@admin/libraries/apollo';
import { useAccountBanQuery } from '@admin/schema';
import { COLORS, FontWeight } from '@clutter/clean';
import styled from '@emotion/styled';
import React from 'react';

const BanAlert = styled.div`
  color ${COLORS.toucan};
  font-weight: ${FontWeight.Bold};
`;

export const BanInfo: React.FC<{ accountID: string }> = ({ accountID }) => {
  const { data } = useAccountBanQuery({ client, variables: { accountID } });

  if (!data) {
    return null;
  }

  return (
    <>
      {data.account.bannedFromBooking && (
        <div>
          <BanAlert>This account has been disabled and cannot book further appointments</BanAlert>
          <strong>Disable Reason: </strong>
          <span>{data.account.banReason}</span>
        </div>
      )}
    </>
  );
};

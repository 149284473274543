(function () {
  angular.module('app').factory('Password', [
    '$resource',
    function ($resource) {
      return $resource(
        '/password.json',
        {},
        {
          save: { method: 'PATCH' },
        },
      );
    },
  ]);
})();

import { OrderSubtypeEnum } from '@portal/schema';
import { client } from '@admin/libraries/apollo';
import { useAccountAddressUpsertMutation, useCreateFollowUpMoveMutation, useOrderUpdateMutation } from '@admin/schema';
import { Button, Modal, Text } from '@shared/components/bootstrap';
import { DateTime, Duration } from 'luxon';
import React, { useState } from 'react';
import { RegionFragment } from '@admin/components/orders/region_form_group';
import { Form, Formik } from 'formik';
import { FormikCheckboxFormGroup } from '@admin/components/fields/formik/formik_checkbox_form_group';
import { FormikInputFormGroup } from '@shared/components/fields/formik/formik_input_form_group';
import { FormikRegionFormGroup } from '@admin/components/fields/formik/formik_region_form_group';
import { FormikMovingSubtypeFormGroup } from '@admin/components/fields/formik/formik_moving_subtype_form_group';
import {
  FormikLocalTimestampFormGroup,
  formatDateTimeForInput,
} from '@admin/components/fields/formik/formik_local_timestamp_form_group';
import { FormikAddressFinderFormGroup } from '@admin/components/fields/formik/formik_address_finder_form_group';
import { ManageFollowUpsOrder } from './manage';

type Address = {
  id: string | null;
  street: string;
  city: string;
  state: string;
  zip: string;
};

type FormValues = {
  scheduledTime?: string;
  subtype: OrderSubtypeEnum;
  originAddress?: Address;
  destinationAddress?: Address;
  moverCount: number;
  region: RegionFragment;
  checkAvailability: boolean;
  chargeRescheduleFee: boolean;
};

const AddressField = ({ accountID, kind }: { accountID: string; kind: 'origin' | 'destination' }) => {
  const fieldName = `${kind}Address`;
  const labelName = kind[0].toUpperCase() + kind.slice(1) + ' Address';

  return <FormikAddressFinderFormGroup label={labelName} name={fieldName} accountID={accountID} />;
};

export const CreateOrEditModal = ({
  editingOrder,
  sourceOrder,
  onSave,
  onCancel,
}: {
  editingOrder?: ManageFollowUpsOrder;
  sourceOrder: ManageFollowUpsOrder;
  onSave(): void;
  onCancel(): void;
}) => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [upsertAddress] = useAccountAddressUpsertMutation({ client });
  const [orderUpdate] = useOrderUpdateMutation({ client });
  const [orderCreate] = useCreateFollowUpMoveMutation({ client });

  const createAddress = async (input: Address) => {
    const { data: upsertAddressData } = await upsertAddress({
      variables: {
        accountID: sourceOrder.account.id,
        input: { ...input, details: { buildingType: 'Apartment', floor: 1, stories: 1, squareFeet: 500 } },
      },
    });
    if (upsertAddressData?.accountAddressUpsert.address) {
      return upsertAddressData.accountAddressUpsert.address;
    } else {
      setErrorMessage(upsertAddressData?.accountAddressUpsert.error ?? 'Could not create new address');
    }
  };

  const save = async (values: FormValues) => {
    const {
      scheduledTime,
      subtype,
      originAddress,
      destinationAddress,
      moverCount,
      region,
      checkAvailability,
      chargeRescheduleFee,
    } = values;

    if (!scheduledTime || !originAddress || !destinationAddress) {
      return;
    }

    setLoading(true);
    setErrorMessage(undefined);

    let resolvedOriginAddress = originAddress;
    let resolvedDestinationAddress = destinationAddress;

    if (!originAddress?.id) {
      const result = await createAddress(originAddress);
      if (result) {
        resolvedOriginAddress = result;
      }
    }

    if (!destinationAddress?.id) {
      const result = await createAddress(destinationAddress);
      if (result) {
        resolvedDestinationAddress = result;
      }
    }

    if (!resolvedOriginAddress.id || !resolvedDestinationAddress.id) {
      return;
    }

    const localScheduledTime = DateTime.fromISO(scheduledTime);

    if (editingOrder) {
      const result = await orderUpdate({
        variables: {
          input: {
            estimatedDuration: Duration.fromObject({ seconds: editingOrder.estimatedDuration ?? 0 }).toISO(),
            moverCount: moverCount,
            orderID: editingOrder.id,
            regionID: region.id,
            scheduledTime: DateTime.fromObject(
              {
                year: localScheduledTime.year,
                month: localScheduledTime.month,
                day: localScheduledTime.day,
                hour: localScheduledTime.hour,
                minute: localScheduledTime.minute,
              },
              { zone: region.tz },
            ).toISO(),
            subtype: subtype,
            movingOriginAddressID: resolvedOriginAddress.id,
            movingDestinationAddressID: resolvedDestinationAddress.id,
            skipRescheduleFee: !chargeRescheduleFee,
            skipAvailabilityCheck: !checkAvailability,
          },
        },
      });

      if (result.data?.result?.error) {
        setErrorMessage(result.data.result.error);
      } else {
        onSave();
      }
    } else {
      const result = await orderCreate({
        variables: {
          input: {
            moverCount: moverCount,
            sourceOrderID: sourceOrder.id,
            regionID: region.id,
            scheduledTime: DateTime.fromObject(
              {
                year: localScheduledTime.year,
                month: localScheduledTime.month,
                day: localScheduledTime.day,
                hour: localScheduledTime.hour,
                minute: localScheduledTime.minute,
              },
              { zone: region.tz },
            ).toISO(),
            subtype: subtype,
            originAddressID: resolvedOriginAddress.id,
            destinationAddressID: resolvedDestinationAddress.id,
          },
        },
      });

      if (result.data?.createFollowUpMove?.error) {
        setErrorMessage(result.data.createFollowUpMove.error.message);
      } else {
        onSave();
      }
    }

    setLoading(false);
  };

  return (
    <Modal onClose={onCancel}>
      <Formik<FormValues>
        initialValues={{
          scheduledTime:
            editingOrder &&
            formatDateTimeForInput(DateTime.fromISO(editingOrder.scheduled, { zone: editingOrder.region.tz })),
          subtype: editingOrder?.subtype || OrderSubtypeEnum.None,
          originAddress: (editingOrder || sourceOrder).movingOperation?.originAddress,
          destinationAddress: (editingOrder || sourceOrder).movingOperation?.destinationAddress,
          moverCount: (editingOrder || sourceOrder).movers,
          region: (editingOrder || sourceOrder).region,
          checkAvailability: true,
          chargeRescheduleFee: false,
        }}
        onSubmit={save}
      >
        {({ values }) => (
          <Form>
            <Modal.Content>
              <Modal.Header>
                <Modal.Title>{editingOrder ? 'Edit Order' : 'Create Order'}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <FormikLocalTimestampFormGroup
                  id="scheduled_time"
                  name="scheduledTime"
                  label={`Scheduled Time (${values.region?.tz})`}
                />
                <FormikMovingSubtypeFormGroup id="subtype" name="subtype" label="Moving Subtype" />
                {values.subtype !== OrderSubtypeEnum.Unpacking && (
                  <AddressField accountID={sourceOrder.account.id} kind={'origin'} />
                )}
                {values.subtype !== OrderSubtypeEnum.Packing && (
                  <AddressField accountID={sourceOrder.account.id} kind={'destination'} />
                )}
                <FormikInputFormGroup
                  id="mover_count"
                  name="moverCount"
                  label="Mover Count"
                  type="number"
                  min={1}
                  max={9}
                />
                <FormikRegionFormGroup id="region" name="region" label="Region" />
                {editingOrder && values.scheduledTime !== editingOrder.scheduled && (
                  <FormikCheckboxFormGroup
                    label="Check Availabiliity"
                    id="check_availability"
                    name="checkAvailability"
                  />
                )}
                {editingOrder &&
                  values.scheduledTime !== editingOrder.scheduled &&
                  editingOrder.cancellationFeeDescription && (
                    <FormikCheckboxFormGroup
                      label="Charge Reschedule Fee"
                      id="charge_reschedule_fee"
                      name="chargeRescheduleFee"
                    />
                  )}
              </Modal.Body>
              <Modal.Footer>
                {errorMessage && <Text style="danger">{errorMessage}</Text>}
                <Button kind="default" onClick={onCancel}>
                  Cancel
                </Button>
                <Button
                  kind="primary"
                  loading={loading}
                  type="submit"
                  disabled={!values.scheduledTime || !values.originAddress || !values.destinationAddress}
                >
                  Save
                </Button>
              </Modal.Footer>
            </Modal.Content>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

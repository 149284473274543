import React, { useState } from 'react';

import { client } from '@admin/libraries/apollo';
import { Landing__Location__Country, usePaginatedLandingLocationStatesQuery } from '@admin/schema';

import { Breadcrumb, Panel, AnchorButton as Link, Table } from '@shared/components/bootstrap';
import { DebouncedInputFormGroup } from '@admin/components/fields';
import { PageHeader } from '@admin/components/helpers/page_header';
import { Pagination } from '@admin/components/pagination';
import { Spinner } from '@admin/components/spinner';

const INITIAL_PAGE = 1;

const COUNTRY_TO_NAME: Record<Landing__Location__Country, string> = {
  [Landing__Location__Country.Ca]: 'Canada',
  [Landing__Location__Country.Us]: 'United States',
};

export const List: React.FC = () => {
  const [page, setPage] = useState<number>(INITIAL_PAGE);
  const [query, setQuery] = useState<string | undefined>();
  const { data, loading } = usePaginatedLandingLocationStatesQuery({
    client,
    fetchPolicy: 'cache-and-network',
    variables: {
      page,
      query,
    },
  });

  const paginated = data?.paginated;

  return (
    <>
      <PageHeader>
        <Breadcrumb>
          <Breadcrumb.Item>Landing</Breadcrumb.Item>
          <Breadcrumb.Item>Location</Breadcrumb.Item>
          <Breadcrumb.Item active>States</Breadcrumb.Item>
        </Breadcrumb>
      </PageHeader>

      <Panel>
        <Panel.Body>
          <DebouncedInputFormGroup
            placeholder="Search"
            initial={query}
            onDebounce={(value) => {
              setQuery(value);
              setPage(INITIAL_PAGE);
            }}
          />
        </Panel.Body>
      </Panel>

      <Panel>
        <Panel.Body>
          <Table responsive striped>
            <thead>
              <tr>
                <th className="text-left">ID</th>
                <th className="text-left">Name</th>
                <th className="text-left">Slug</th>
                <th className="text-left">Code</th>
                <th className="text-left">Country</th>
                <th className="text-right" />
              </tr>
            </thead>
            <tbody>
              {paginated?.results.map((entry) => (
                <tr key={entry.id}>
                  <td className="text-left">{entry.id}</td>
                  <td className="text-left">{entry.name}</td>
                  <td className="text-left">{entry.slug}</td>
                  <td className="text-left">{entry.code}</td>
                  <td className="text-left">{COUNTRY_TO_NAME[entry.country]}</td>
                  <td className="text-right">
                    <Link kind="primary" href={`/landing/location/states/${entry.id}/edit`}>
                      Edit
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {loading && <Spinner />}
          {paginated && <Pagination onPage={setPage} pagination={paginated.pagination} />}
        </Panel.Body>
        <Panel.Footer align="right">
          <Link kind="primary" href="/landing/location/states/new">
            New
          </Link>
        </Panel.Footer>
      </Panel>
    </>
  );
};

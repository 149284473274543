import styled from '@emotion/styled';
import React, { useState } from 'react';

import { Button, Modal } from '@shared/components/bootstrap';
import { Pluralize, Spacer } from '@shared/components/helpers';

import { NumberFormControl, SelectFormControl } from '@admin/components/fields';

import { getOrdinalSuffix } from '@shared/utils/get_ordinal_suffix';

import { COLORS } from '@root/colors';

import {
  useSaveSelfStoragePromotionBatchMutation,
  SelfStorage__ClassificationFacilityInput,
  SelfStorage__Promotion__Kind,
  PaginatedSelfStorageUnitsDocument,
} from '@admin/schema';
import { client } from '@admin/libraries/apollo';

export const PROMOTION_KINDS = [
  SelfStorage__Promotion__Kind.Amount,
  SelfStorage__Promotion__Kind.Percent,
  SelfStorage__Promotion__Kind.Override,
];

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${COLORS.grayLightest};
`;

export const Title = styled.h3`
  font-size: 24px;
  font-weight: 300;
`;

export const Footer = styled.div`
  display: flex;
`;

const FieldRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FieldLabel = styled.div`
  white-space: nowrap;
  font-size: 14px;
`;

const FormGroup = styled.div`
  width: 80px;
`;

const Summary = styled.div`
  text-align: center;
  height: 20px;
  font-size: 14px;
  color: ${COLORS.red};
`;

function formatDisplayValue(value: number) {
  if (value % 1) {
    return value.toFixed(2);
  } else {
    return value;
  }
}

const interval = (value?: number) => (value === 1 ? 'month' : 'months');

export const AddPromotionModal: React.FC<{
  count?: number;
  IDs: SelfStorage__ClassificationFacilityInput[];
  show: boolean;
  /** onClose is called when the modal is hidden or the cancel button is clicked */
  onClose: () => void;
  /** onSave is called when the promotion mutation is successfully executed */
  onSave: () => void;
}> = ({ count, IDs, show, onClose, onSave }) => {
  const [kind, setKind] = useState(SelfStorage__Promotion__Kind.Percent);
  const [value, setValue] = useState<number | undefined>(20);
  const [term, setTerm] = useState<number | undefined>(1);
  const [starting, setStarting] = useState<number | undefined>(undefined);
  const delay = starting !== undefined ? starting - 1 : 0;

  const [addPromotion, { loading }] = useSaveSelfStoragePromotionBatchMutation({
    client,
    onCompleted: onSave,
    refetchQueries: [{ query: PaginatedSelfStorageUnitsDocument }],
  });

  const handleAddPromotion = () => {
    if (value !== undefined && term !== undefined) {
      addPromotion({
        variables: {
          input: { IDs, kind, value, term, delay },
        },
      });
    }
  };

  const disabled = loading || value === undefined || term === undefined;
  const displayValue = value && formatDisplayValue(value);

  if (!show) {
    return null;
  }

  return (
    <Modal onClose={onClose}>
      <Modal.Content>
        <ModalWrapper>
          <Modal.Header>
            <Title>Add a Promotion</Title>
          </Modal.Header>
          <Modal.Body>
            <FieldRow>
              <FormGroup>
                <NumberFormControl
                  value={value}
                  onChange={setValue}
                  min={kind === SelfStorage__Promotion__Kind.Override ? 0 : 1}
                  max={kind === SelfStorage__Promotion__Kind.Percent ? 100 : undefined}
                />
              </FormGroup>
              <Spacer width="16px" />
              <FormGroup>
                <SelectFormControl
                  options={PROMOTION_KINDS}
                  value={kind}
                  onChange={(option: SelfStorage__Promotion__Kind) => {
                    if (option) {
                      setKind(option);
                    }
                  }}
                  name={(option: SelfStorage__Promotion__Kind) => {
                    switch (option) {
                      case SelfStorage__Promotion__Kind.Percent:
                        return '% off';
                      case SelfStorage__Promotion__Kind.Override:
                        return '$ /month';
                      case SelfStorage__Promotion__Kind.Amount:
                        return '$ off';
                    }
                  }}
                />
              </FormGroup>
            </FieldRow>
            <Spacer height="12px" />
            <FieldRow>
              <FieldLabel>for</FieldLabel>
              <Spacer width="12px" />
              <FormGroup>
                <NumberFormControl value={term} onChange={setTerm} min={1} />
              </FormGroup>
              <Spacer width="12px" />
              <FieldLabel>{interval(term)}</FieldLabel>
            </FieldRow>
            <Spacer height="12px" />
            <FieldRow>
              <FieldLabel>starting from the</FieldLabel>
              <Spacer width="12px" />
              <FormGroup>
                <NumberFormControl value={starting} onChange={setStarting} placeholder="1" min={1} />
              </FormGroup>
              <Spacer width="12px" />
              <FieldLabel>month</FieldLabel>
            </FieldRow>
            <Spacer height="24px" />
            <Summary>
              {value !== undefined && term !== undefined && (
                <>
                  {(() => {
                    switch (kind) {
                      case SelfStorage__Promotion__Kind.Amount:
                        return `$${displayValue} off`;
                      case SelfStorage__Promotion__Kind.Percent:
                        return `${displayValue}% off`;
                      case SelfStorage__Promotion__Kind.Override:
                        return `$${displayValue} per month`;
                    }
                  })()}
                  {' for '}
                  {term} {interval(term)}{' '}
                  {starting !== undefined && starting > 0 && (
                    <>
                      starting from the {starting}
                      {getOrdinalSuffix(starting)} month
                    </>
                  )}
                </>
              )}
            </Summary>
            <Spacer height="16px" />
            <Footer>
              <Button kind="danger" size="lg" onClick={onClose} disabled={loading}>
                Cancel
              </Button>
              <Spacer width="12px" />
              <Button kind="primary" size="lg" onClick={handleAddPromotion} disabled={disabled}>
                Update
                {typeof count === 'number' && (
                  <>
                    {' '}
                    <Pluralize count={count} singular="Rentable Unit" plural="Rentable Units" />
                  </>
                )}
              </Button>
            </Footer>
            <Spacer height="8px" />
          </Modal.Body>
        </ModalWrapper>
      </Modal.Content>
    </Modal>
  );
};

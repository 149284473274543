import React from 'react';
import { Formik, FormikErrors, Form as FormikForm } from 'formik';

import {
  Status,
  useAttendancePolicyViolationReviewMutation,
  AttendancePolicyViolation__Review__Input,
} from '@admin/schema';

import { client } from '@admin/libraries/apollo';
import { Alert, Button, Panel, Modal } from '@shared/components/bootstrap';
import { FormikTextAreaFormGroup } from '../fields/formik/formik_text_area_form_group';

export const ReviewModal: React.FC<{
  violation: { id: string; confirmedNoCall: boolean; waived: boolean };
  reviewKind: 'toggleConfirmedNoCall' | 'toggleWaived';
  closeModal(): void;
}> = ({ violation, reviewKind, closeModal }) => {
  const [save, { loading, data }] = useAttendancePolicyViolationReviewMutation({
    client,
    onCompleted: ({ result }) => {
      if (result?.status === Status.Ok) closeModal();
    },
  });

  const validate = (formValues: AttendancePolicyViolation__Review__Input) => {
    const formErrors: FormikErrors<AttendancePolicyViolation__Review__Input> = {};

    if (!formValues.note) {
      formErrors.note = 'Please add a note';
    }

    return formErrors;
  };

  const onSubmit = (input: AttendancePolicyViolation__Review__Input) => {
    save({
      variables: {
        input,
      },
    });
  };

  const toggleWaivedActionLabel = violation.waived ? 'Apply Violation' : 'Waive Violation';
  const toggleConfirmedNoCallActionLabel = violation.confirmedNoCall ? 'Mark as Called In' : 'Mark as Not Called In';
  const actionLabel = reviewKind === 'toggleWaived' ? toggleWaivedActionLabel : toggleConfirmedNoCallActionLabel;

  return (
    <Modal onClose={closeModal}>
      <Formik<AttendancePolicyViolation__Review__Input>
        initialValues={{
          id: violation.id,
          confirmedNoCall:
            reviewKind === 'toggleConfirmedNoCall' ? !violation.confirmedNoCall : violation.confirmedNoCall,
          note: undefined,
          waived: reviewKind === 'toggleWaived' ? !violation.waived : violation.waived,
        }}
        onSubmit={onSubmit}
        validate={validate}
      >
        {({ isValid }) => (
          <FormikForm>
            <Panel>
              <Panel.Header>
                <Panel.Title>{actionLabel}</Panel.Title>
              </Panel.Header>
              <Panel.Body>
                {data?.result?.error && <Alert style="danger">{data.result.error}</Alert>}
                <FormikTextAreaFormGroup id="note" name="note" label="Note" />
              </Panel.Body>
              <Panel.Footer align="right">
                <Button kind="primary" onClick={closeModal}>
                  Cancel
                </Button>
                <Button loading={loading} kind="primary" type="submit" disabled={!isValid}>
                  {actionLabel}
                </Button>
              </Panel.Footer>
            </Panel>
          </FormikForm>
        )}
      </Formik>
    </Modal>
  );
};

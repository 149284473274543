import * as React from 'react';

import { SelfStorage__UnitFragment } from '@admin/schema';

import { Summary as SelfStorageClassificationSummary } from '@admin/components/self_storage/classifications/summary';

export const Summary: React.FC<{ unit: SelfStorage__UnitFragment }> = ({ unit }) => (
  <>
    <div>{unit.name}</div>
    <SelfStorageClassificationSummary classification={unit.classification} />
  </>
);

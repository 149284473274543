import * as React from 'react';

import { Availability__GlobalUnavailabilityFiltersInput } from '@admin/schema';

import { Row, Col } from '@shared/components/bootstrap';
import { FieldFormGroup, InputFormGroup, MultiselectFormControl } from '@admin/components/fields';
import { Selector as RegionSelector } from '@admin/components/regions/selector';

import { REASON_OPTIONS } from './utils';

export const Filters: React.FC<{
  filters: Availability__GlobalUnavailabilityFiltersInput;
  onChange(filters: Availability__GlobalUnavailabilityFiltersInput): void;
}> = ({ filters, onChange }) => (
  <>
    <Row>
      <Col md={3}>
        <InputFormGroup
          id="from_date"
          label="From Date:"
          type="date"
          min="0000-01-01"
          max="9999-12-31"
          value={filters.fromDate || ''}
          onChange={(event) => onChange({ ...filters, fromDate: event.target.value || '' })}
        />
      </Col>
      <Col md={3}>
        <InputFormGroup
          id="till_date"
          label="Till Date:"
          type="date"
          min="0000-01-01"
          max="9999-12-31"
          value={filters.tillDate || ''}
          onChange={(event) => onChange({ ...filters, tillDate: event.target.value || '' })}
        />
      </Col>
      <Col md={6}>
        <FieldFormGroup label="Regions:">
          <RegionSelector
            selectedIDs={filters.regionIDs}
            onChange={(regionIDs) => onChange({ ...filters, regionIDs })}
          />
        </FieldFormGroup>
      </Col>
    </Row>
    <Row>
      <Col md={6}>
        <InputFormGroup
          id="name"
          label="Name:"
          type="text"
          value={filters.name ?? undefined}
          onChange={(event) => onChange({ ...filters, name: event.target.value || undefined })}
        />
      </Col>
      <Col md={6}>
        <FieldFormGroup label="Reasons:">
          <MultiselectFormControl
            placeholder="Reasons"
            options={REASON_OPTIONS}
            selectedIDs={filters.reasons}
            onChange={(reasons) =>
              onChange({
                ...filters,
                reasons,
              })
            }
          />
        </FieldFormGroup>
      </Col>
    </Row>
  </>
);

import React from 'react';
import { Formik, Form as FormikForm } from 'formik';

import {
  Landing__Location__Country,
  Landing__Location__StateInput,
  LandingLocationStateQuery,
  Status,
  useLandingLocationStateQuery,
  useLandingLocationStateUpsertMutation,
} from '@admin/schema';

import { client } from '@admin/libraries/apollo';

import { Breadcrumb, Panel, AnchorButton as Link, Button, Alert } from '@shared/components/bootstrap';
import { PageHeader } from '@admin/components/helpers/page_header';
import { Spinner } from '@admin/components/spinner';
import { FormikSelectFormGroup } from '@shared/components/fields/formik/formik_select_form_group';
import { FormikInputFormGroup } from '@shared/components/fields/formik/formik_input_form_group';

const DEFAULT_COUNTRY = Landing__Location__Country.Us;

const Fields: React.FC<{
  state?: LandingLocationStateQuery['state'];
  onSave(): void;
}> = ({ state, onSave }) => {
  const [save, { loading, data }] = useLandingLocationStateUpsertMutation({
    client,
    onCompleted: ({ result }) => {
      if (result?.status === Status.Ok) onSave();
    },
  });

  const onSubmit = (input: Landing__Location__StateInput) => {
    save({
      variables: {
        id: state?.id,
        input,
      },
    });
  };

  return (
    <Formik<Landing__Location__StateInput>
      initialValues={{
        country: state?.country ?? DEFAULT_COUNTRY,
        name: state?.name ?? '',
        slug: state?.slug ?? '',
        code: state?.code ?? '',
      }}
      onSubmit={onSubmit}
    >
      <FormikForm>
        <Panel>
          <Panel.Body>
            {data?.result?.error && <Alert style="danger">{data.result.error}</Alert>}
            <FormikSelectFormGroup id="country" name="country" label="Country:">
              <option value={Landing__Location__Country.Ca}>Canada</option>
              <option value={Landing__Location__Country.Us}>United States</option>
            </FormikSelectFormGroup>
            <FormikInputFormGroup id="slug" name="slug" label="Slug:" placeholder="e.g. new-york" />
            <FormikInputFormGroup id="name" name="name" label="Name:" placeholder="e.g. New York" />
            <FormikInputFormGroup id="code" name="code" label="Code:" placeholder="e.g. NY" />
          </Panel.Body>
          <Panel.Footer align="right">
            <Link disabled={loading} kind="default" href="/landing/location/states">
              Cancel
            </Link>
            <Button loading={loading} kind="primary" type="submit">
              Save
            </Button>
          </Panel.Footer>
        </Panel>
      </FormikForm>
    </Formik>
  );
};

export const LandingLocationStateForm: React.FC<{
  id?: string;
  onSave(): void;
}> = ({ id, onSave }) => {
  const { data } = useLandingLocationStateQuery({
    client,
    fetchPolicy: 'network-only',
    variables: { id: id! },
    skip: !id,
  });
  const state = data?.state;

  return (
    <>
      <PageHeader>
        <Breadcrumb>
          <Breadcrumb.Item>Landing</Breadcrumb.Item>
          <Breadcrumb.Item>Location</Breadcrumb.Item>
          <Breadcrumb.Item>
            <a href="/landing/location/states">States</a>
          </Breadcrumb.Item>
          {state && <Breadcrumb.Item>{state.name}</Breadcrumb.Item>}
          <Breadcrumb.Item active>{id ? 'Edit' : 'New'}</Breadcrumb.Item>
        </Breadcrumb>
      </PageHeader>

      {!id || state ? <Fields state={state} onSave={onSave} /> : <Spinner />}
    </>
  );
};

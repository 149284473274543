import React, { useState } from 'react';

import { Ticket__TicketableInput } from '@admin/schema';
import { Button, Modal } from '@shared/components/bootstrap';

import { TicketForm } from './form';

const CreateModal: React.FC<{
  ticketable: Ticket__TicketableInput;
  onClose(): void;
}> = ({ ticketable, onClose }) => (
  <Modal centered onClose={onClose}>
    <Modal.Content>
      <Modal.Header>
        <Modal.Title>Create Task Ticket</Modal.Title>
        <Modal.Close close={onClose} />
      </Modal.Header>
      <Modal.Body>
        <TicketForm ticketable={ticketable} onClose={onClose} />
      </Modal.Body>
    </Modal.Content>
  </Modal>
);

export const CreateTicket: React.FC<{
  ticketable: Ticket__TicketableInput;
}> = ({ ticketable }) => {
  const [showModal, setShowModal] = useState<boolean>(false);

  return (
    <>
      <Button kind="primary" onClick={() => setShowModal(true)}>
        Create Task Ticket
      </Button>
      {showModal && <CreateModal ticketable={ticketable} onClose={() => setShowModal(false)} />}
    </>
  );
};

import React from 'react';

import { MovingQuoteFragment } from '@admin/schema';
import { QuoteDurations } from './quote_duration';
import { QuoteDetailRows } from './quote_detail_rows';

export const QuoteSummary: React.FC<{
  quote: MovingQuoteFragment;
}> = ({ quote }) => (
  <>
    <div className="row">
      <div className="col-sm-4">
        <table className="table">
          <caption>Updated Values</caption>
          <tbody>
            <tr className={quote.eligibility ? 'success' : 'danger'}>
              <td className="text-left">Eligible:</td>
              <td className="text-right">
                <strong>{quote.eligibility ? 'Yes' : `No, ${quote.reasons.join(', ')}`}</strong>
              </td>
            </tr>
            <QuoteDetailRows quote={quote} />
          </tbody>
        </table>
      </div>
      <QuoteDurations quote={quote} />
    </div>
  </>
);

import React, { useState } from 'react';

import { client } from '@admin/libraries/apollo';
import { Status, useCancelAccountMutation } from '@admin/schema';
import { Alert, Button, Modal } from '@shared/components/bootstrap';

interface ICancelAccountProps {
  accountID: string;
  accountNumber: string;
  onCanceled(): void;
}

const CancelModal: React.FC<
  ICancelAccountProps & {
    onClose(): void;
  }
> = ({ accountID, accountNumber, onCanceled, onClose }) => {
  const [error, setError] = useState<string | undefined>();

  const [cancel, { loading }] = useCancelAccountMutation({ client });

  const onConfirm = async () => {
    const response = await cancel({ variables: { accountID } });
    if (response && response.data?.cancel) {
      if (response.data.cancel.error) {
        setError(`We were unable to cancel this account. Please contact support. ${response.data.cancel.error}`);
      } else if (response.data.cancel.status === Status.Ok) {
        onCanceled();
        onClose();
      }
    }
  };

  return (
    <Modal onClose={onClose}>
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>Cancel Account #{accountNumber}</Modal.Title>
          <Modal.Close close={onClose} />
        </Modal.Header>
        <Modal.Body>
          {error && (
            <Alert style="danger">
              <strong>Error: </strong>
              {error}
            </Alert>
          )}
          <div>Are you sure you want to close this account?</div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onClose} disabled={loading} size="lg" kind="danger">
            Quit
          </Button>
          <Button onClick={onConfirm} disabled={loading} size="lg" kind="primary">
            Cancel Account
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};

export const CancelButton: React.FC<ICancelAccountProps> = ({ accountID, accountNumber, onCanceled }) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  return (
    <>
      <Button kind="danger" onClick={() => setShowModal(true)}>
        Cancel Account
      </Button>
      {showModal && (
        <CancelModal
          accountID={accountID}
          accountNumber={accountNumber}
          onCanceled={onCanceled}
          onClose={() => setShowModal(false)}
        />
      )}
    </>
  );
};

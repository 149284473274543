const MarkerGenerator = ({ label, fontColor, bgColor, map, position }) => {
  /* eslint-disable max-len */
  const svg = `<?xml version="1.0"?>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
    <path d="M25 1C16.2 1 9 8.2 9 17c0 7 10 24 14.3 31 .4.6 1 1 1.7 1s1.3-.4 1.7-1C31 41.1 41 24 41 17c0-8.8-7.2-16-16-16z" fill="${bgColor}" stroke="black" stroke-width="1px" />
    <text x="50%" y="40%" font-size="18" font-family="Verdana-Bold" fill="${fontColor}" dominant-baseline="middle" text-anchor="middle" stroke="black" stroke-width="0.5px">
      ${label}
    </text>
  </svg>`;
  /* eslint-disable */

  return new google.maps.Marker({
    position,
    map,
    title: 'Dynamic SVG Marker',
    icon: {
      url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(svg)}`,
      scaledSize: new google.maps.Size(35, 35),
    },
    optimized: false,
  });
};

export { MarkerGenerator };

(function () {
  const app = angular.module('app');

  app.controller('WorkforceSurveyResponseCreateController', [
    '$rootScope',
    '$scope',
    '$stateParams',
    '$http',
    'WorkforceSurvey',
    'WorkforceSurveyResponse',
    function ($rootScope, $scope, $stateParams, $http, WorkforceSurvey, WorkforceSurveyResponse) {
      $scope.survey_id = $stateParams.survey_id;
      $scope.survey = WorkforceSurvey.get({ id: $stateParams.survey_id });
      $scope.survey_response = new WorkforceSurveyResponse();
      $scope.survey_response.selected_days = [];

      $scope.isBusy = (index) => {
        let value = '';
        if ($scope.survey.busy_days && $scope.survey.busy_days.includes(index)) {
          value = '*';
        }
        return value;
      };

      $scope.save = function () {
        $rootScope.$broadcast('message:clear');
        const savePromise = $http.post(`workforces/surveys/${$scope.survey.id}/responses`, {
          survey_response: $scope.survey_response,
        });
        savePromise.then(
          () => {
            $rootScope.$broadcast('message:display', {
              type: 'info',
              body: 'Response has been saved',
            });
          },
          (error) => {
            $rootScope.$broadcast('message:group:display', error.data);
          },
        );
      };
    },
  ]);
})();

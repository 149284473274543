import * as React from 'react';
import { useEffect, useState } from 'react';
import { useMessageCreateMutation } from '@admin/schema';
import { client } from '@admin/libraries/apollo';
import { Button } from '@shared/components/bootstrap';

interface ICache {
  read(key: string): string | undefined;
  write(key: string, value: string): void;
  delete(key: string): void;
}

const CACHE: ICache = {
  read: (key) => localStorage.getItem(key) || undefined,
  write: (key, value) => localStorage.setItem(key, value),
  delete: (key) => localStorage.removeItem(key),
};

export const TicketMessagesForm: React.FC<{
  id: string;
  cache?: ICache;
  onSave(): void;
}> = ({ cache = CACHE, id, onSave }) => {
  const key = `messages:${id}`;
  const [body, setBody] = useState<string | undefined>(() => cache.read(key));
  useEffect(() => {
    if (body) {
      cache.write(key, body);
    } else {
      cache.delete(key);
    }
  }, [id, body]);

  const [save, { loading }] = useMessageCreateMutation({ client });

  const onReset = () => {
    setBody(undefined);
  };

  const saveable = body && !!body.trim().length && !loading;

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    event.stopPropagation();
    await save({
      variables: {
        input: {
          ticketId: id,
          body: body,
        },
        sendPushNotification: true,
      },
    });
    onReset();
    onSave();
  };

  return (
    <form onSubmit={onSubmit}>
      <div className="form-group">
        <textarea
          placeholder="Add additional messages here."
          className="form-control"
          value={body || ''}
          onChange={(event) => setBody(event.target.value)}
        />
      </div>
      <div className="form-group">
        <div className="clearfix">
          <div className="pull-right">
            <Button type="submit" disabled={!saveable} kind="primary">
              Add Message
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
};

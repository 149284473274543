import { CustomerFields, validateCustomerFields } from '@admin/components/accounts/create/customer_fields';
import { FormikInputFormGroup } from '@shared/components/fields/formik/formik_input_form_group';
import { accountURL } from '@admin/config/routes';
import { client } from '@admin/libraries/apollo';
import { Status, useContainersAccountCreateMutation } from '@admin/schema';
import { Alert, Button, Col, Modal, Row } from '@shared/components/bootstrap';
import { Form as FormikForm, Formik, FormikErrors } from 'formik';
import React, { useState } from 'react';

type FormValues = { zip: string; name: string; phone: string; email: string };
const INITIAL_VALUES = {
  zip: '',
  name: '',
  phone: '',
  email: '',
};

export const CreateModal: React.FC<{ onClose(): void }> = ({ onClose }) => {
  const [save, { loading }] = useContainersAccountCreateMutation({ client });
  const [error, setError] = useState<string | undefined>();

  const validate = (data: FormValues) => {
    const errors: FormikErrors<FormValues> = validateCustomerFields(data);

    if (!data.zip) {
      errors.zip = 'Please enter a zip code';
    }

    return errors;
  };

  const onSubmit = async ({ name, phone, email, zip }: FormValues) => {
    const result = await save({ variables: { customerInput: { name, phone, email }, zip } });
    const data = result.data?.containersAccountCreate;

    if (data?.status === Status.Ok) {
      window.location.href = accountURL({ id: data.id! });
    } else {
      setError(data?.error ?? 'An unexpected error occurred. Please try again');
    }
  };

  return (
    <Formik<FormValues>
      initialValues={INITIAL_VALUES}
      onSubmit={onSubmit}
      validate={validate}
      validateOnChange={false}
      validateOnBlur={false}
    >
      <FormikForm>
        <Modal onClose={onClose}>
          <Modal.Content>
            <Modal.Header>Create Containers Account</Modal.Header>
            <Modal.Body>
              {error && <Alert style="danger">{error}</Alert>}
              <Row>
                <CustomerFields />
              </Row>
              <Row>
                <Col sm={4}>
                  <FormikInputFormGroup id="zip" label="Zip" name="zip" />
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={onClose} kind="secondary">
                Cancel
              </Button>
              <Button loading={loading} disabled={loading} kind="primary" type="submit">
                Submit
              </Button>
            </Modal.Footer>
          </Modal.Content>
        </Modal>
      </FormikForm>
    </Formik>
  );
};

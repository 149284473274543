export const CREDIT_REASONS = [
  {
    name: 'Duplicate Charge',
    category: 'Billing Error',
  },
  {
    name: 'Incorrect Plan',
    category: 'Billing Error',
  },
  {
    name: 'Incorrect Labor',
    category: 'Billing Error',
  },
  {
    name: 'Agent Mistake',
    category: 'Billing Error',
  },
  {
    name: 'Final Month Proration',
    category: 'Billing Adjustment',
  },
  {
    name: 'Sales Promotion',
    category: 'Billing Adjustment',
  },
  {
    name: 'Prepayment Refund',
    category: 'Billing Adjustment',
  },
  {
    name: 'AR Adjustment',
    category: 'Billing Adjustment',
  },
  {
    name: 'Marketing',
    category: 'Billing Adjustment',
  },
  {
    name: 'Clutter Error - Late Arrival',
    category: 'CX Credit',
  },
  {
    name: 'Clutter Error - Ops Reschedule',
    category: 'CX Credit',
  },
  {
    name: 'Clutter Error - Free Delivery',
    category: 'CX Credit',
  },
  {
    name: 'Charge Dispute - CX Issue',
    category: 'CX Credit',
  },
  {
    name: 'Charge Dispute - Plan Changes',
    category: 'CX Credit',
  },
  {
    name: 'Charge Dispute - Cancelation Fee',
    category: 'CX Credit',
  },
  {
    name: 'Charge Dispute - Labor Time',
    category: 'CX Credit',
  },
  {
    name: 'Charge Dispute - Other On-site Issue',
    category: 'CX Credit',
  },
  {
    name: 'Charge Dispute - Social Media',
    category: 'CX Credit',
  },
  {
    name: 'Claim - Lost Item',
    category: 'CX Credit',
  },
  {
    name: 'Claim - Damaged Item',
    category: 'CX Credit',
  },
  {
    name: 'Claim - Property Damage',
    category: 'CX Credit',
  },
];

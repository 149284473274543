import template from '@admin/angular/templates/order_room_selections_and_square_footage_preview.html';

(() => {
  const app = angular.module('app');

  app.component('orderRoomSelectionsAndSquareFootagePreview', {
    template,
    bindings: {
      order: '<',
    },
  });
})();

import { useRegionsQuery } from '@admin/schema';
import React from 'react';
import { client } from '@admin/libraries/apollo';
import { useField } from 'formik';
import { FormikFieldFormGroup } from '@shared/components/fields/formik/formik_field_form_group';

export const FormikRegionFormGroup: React.FC<{
  id: string;
  name: string;
  label: string;
}> = (props) => {
  const [{ value }, _1, helpers] = useField(props.name);

  const { data, loading } = useRegionsQuery({ client });
  const regions = data?.regions;

  const onChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const regionID = event.target.value || undefined;
    const region = regionID ? regions?.find(({ id }) => id === regionID) : undefined;
    helpers.setValue(region);
  };

  return (
    <FormikFieldFormGroup {...props}>
      <select
        id={props.id}
        name={props.name}
        value={value?.id}
        disabled={loading}
        onChange={onChange}
        className="form-control"
      >
        {!value && <option value=""> - Region - </option>}
        {regions?.map((region) => (
          <option key={region.id} value={region.id}>
            {region.name}
          </option>
        ))}
      </select>
    </FormikFieldFormGroup>
  );
};

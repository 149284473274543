import { Panel } from '@admin/components/helpers/panel';
import { Pagination } from '@admin/components/pagination';
import { Spinner } from '@admin/components/spinner';
import { client } from '@admin/libraries/apollo';
import { useTrainingUploadsPaginatedQuery, useTrainingUploadStatusUpdateMutation, Status } from '@admin/schema';
import { AnchorButton as Link, Table } from '@shared/components/bootstrap';
import { Timestamp } from '@shared/components/helpers';
import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Box } from '@clutter/clean';
import { trainingUploadAttestationsURL } from '@admin/config/routes';
import { Label } from '../helpers/label';
import { UploadModal } from './upload_modal';

const TeamLabels = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  gap: 4px;
`;

const TextLink = styled(Link)`
  color: teal;
  align-self: center;
`;

export const TrainingUploadList: React.FC = () => {
  const [page, setPage] = useState<number | undefined>();
  const [showUploadModal, setShowUploadModal] = useState<boolean>(false);
  const { data, loading, refetch } = useTrainingUploadsPaginatedQuery({ client, variables: { page: page } });
  const paginated = data?.paginated;

  const [updateStatus] = useTrainingUploadStatusUpdateMutation({
    client,
    onCompleted: ({ result }) => {
      if (result?.status === Status.Ok) refetch();
    },
  });

  return (
    <Panel>
      <Table responsive striped>
        <thead>
          <tr>
            <th>File</th>
            <th>Teams</th>
            <th>Uploader</th>
            <th>Uploaded At</th>
            <th>Archived At</th>
            <th className="col-sm-1 text-right">
              <a
                className="btn btn-mint btn-labeled fa fa-plus"
                onClick={() => {
                  setShowUploadModal(true);
                }}
              >
                Add
              </a>
            </th>
          </tr>
        </thead>
        <tbody>
          {paginated?.results.map((trainingUpload) => (
            <tr key={trainingUpload.id}>
              <td>
                <TextLink onClick={() => window.open(trainingUpload.url, '_blank')?.focus()}>
                  <strong>{trainingUpload.name}</strong>
                </TextLink>
              </td>
              <td>
                <TeamLabels>
                  {trainingUpload.teams.map((team, _) => (
                    <Label kind="default" key={team.id}>
                      {team.name}
                    </Label>
                  ))}
                </TeamLabels>
              </td>
              <td>{trainingUpload.uploader.name}</td>
              <td>{<Timestamp value={trainingUpload.createdAt} format={Timestamp.Format.DateTime} />}</td>
              <td>
                {trainingUpload.deletedAt && (
                  <Timestamp value={trainingUpload.deletedAt} format={Timestamp.Format.DateTime} />
                )}
              </td>
              <td>
                <Link kind="primary" href={trainingUploadAttestationsURL(trainingUpload.id)}>
                  View Attestations
                </Link>
                <Box margin={'8px 0 0 0'}>
                  <Link
                    kind="warning"
                    onClick={() => {
                      updateStatus({
                        variables: {
                          input: {
                            trainingUploadID: trainingUpload.id,
                            active: !!trainingUpload.deletedAt,
                          },
                        },
                      });
                    }}
                  >
                    {trainingUpload.deletedAt ? 'Unarchive' : 'Archive'}
                  </Link>
                </Box>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {loading && <Spinner />}
      {paginated && <Pagination pagination={paginated.pagination} onPage={setPage} />}
      {showUploadModal && (
        <UploadModal
          teamOptions={data?.teams ?? []}
          closeModal={(shouldRefetch) => {
            setShowUploadModal(false);
            if (shouldRefetch) {
              refetch();
            }
          }}
        ></UploadModal>
      )}
    </Panel>
  );
};

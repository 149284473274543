(function () {
  angular.module('app').service('ItemCategorizationObserverService', [
    'AccountMetrics',
    function (AccountMetrics) {
      const self = this;
      self.callbacks = [];

      self.registerCallback = function (handler) {
        self.callbacks.push(handler);
      };

      self.removeCallback = function (handler) {
        const index = self.callbacks.findIndex(function (val) {
          return val === handler;
        });

        if (index === -1) {
          return;
        }

        self.callbacks.splice(index, 1);
      };

      self.notify = function (accountId) {
        const metricsPromise = AccountMetrics.get({ id: accountId }).$promise;
        self.callbacks.forEach(function (handler) {
          handler(metricsPromise);
        });
      };
    },
  ]);
})();

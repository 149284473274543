(function () {
  const app = angular.module('app');

  app.controller('TransferPoolShowController', [
    '$stateParams',
    '$scope',
    'TransferPool',
    function ($stateParams, $scope, TransferPool) {
      $scope.id = $stateParams.id;
      $scope.transferpool = TransferPool.get({ id: $scope.id });
    },
  ]);

  app.controller('TransferPoolEditController', [
    '$stateParams',
    '$scope',
    '$resource',
    'TransferPool',
    'TransferRequest',
    'ConfirmationService',
    'ErrorService',
    function ($stateParams, $scope, $resource, TransferPool, TransferRequest, ConfirmationService, ErrorService) {
      $scope.id = $stateParams.id;
      $scope.transferpool = TransferPool.get({ id: $scope.id });
      $scope.pallets = $resource('/transferpools/:transferpool_id/pallets.json').query({ transferpool_id: $scope.id });

      $scope.remove = function (pallet) {
        ConfirmationService.confirm({
          title: 'Are you sure?',
          description:
            'Are you sure you want to remove the transer request for #' +
            pallet.barcode.value +
            ' from ' +
            $scope.transferpool.origin.name +
            ' to ' +
            $scope.transferpool.destination.name +
            '?',
        }).then(function () {
          TransferRequest.remove({
            pallet_id: pallet.id,
            origin_id: $scope.transferpool.origin.id,
            destination_id: $scope.transferpool.destination.id,
          }).$promise.then(function () {
            _.remove($scope.pallets, pallet);
          }, ErrorService.handle);
        });
      };
    },
  ]);

  app.controller('TransferPoolIndexController', [
    '$scope',
    '$location',
    'Warehouse',
    'TransferPool',
    'DebounceService',
    function ($scope, $location, Warehouse, TransferPool, DebounceService) {
      const debounce = DebounceService.initialize();
      $scope.filters = $location.search();
      $scope.warehouses = Warehouse.query();

      $scope.filter = function (filters) {
        if (!filters) {
          filters = $scope.filters || {};
        }

        $location.search(filters);

        $scope.results = null;
        debounce.execute(TransferPool.query(filters)).then(function (data) {
          $scope.results = data;
        });
      };

      $scope.filter();
    },
  ]);
})();

(() => {
  angular.module('app').service('LargeItemWarningService', [
    'ConfirmationService',
    'ConversionService',
    function (ConfirmationService, ConversionService) {
      const MAX_CUFT = 50;
      const MAX_SIDE_INCHES = 72;
      const alreadyWarned = {};

      function shouldWarn(length, width, height) {
        const overCuFt = ConversionService.cubicInchesToFeet(length * width * height) > MAX_CUFT;
        const overMaxSideInches = _.some([length, width, height], function (side) {
          return side > MAX_SIDE_INCHES;
        });
        return overCuFt || overMaxSideInches;
      }

      this.warnIfLargeItem = (id, length, width, height, yesCb) => {
        if (!alreadyWarned[id] && shouldWarn(length, width, height)) {
          alreadyWarned[id] = true;
          ConfirmationService.confirm({
            title: "Whoa! That's big.",
            description: 'Please check with the customer that the dimensions for this item are correct.',
            showDeclineButton: false,
            acceptButton: 'OK',
            dialogOptions: { showClose: false },
          }).then(yesCb);
          return true;
        }
        return false;
      };
    },
  ]);
})();

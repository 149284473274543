(function () {
  const app = angular.module('app');

  app.controller('SelectionsController', [
    '$scope',
    function ($scope) {
      function contains(selections, selection) {
        return !!_.find(selections, function (other) {
          return other.id === selection.id;
        });
      }

      function insert(selections, selection) {
        if (!contains(selections, selection)) {
          selections.push(selection);
        }
      }

      function remove(selections, selection) {
        if (contains(selections, selection)) {
          _.remove(selections, function (other) {
            return other.id === selection.id;
          });
        }
      }

      $scope.toggle = function ($event, selections, selection) {
        const checked = $event.target.checked;
        if (checked) {
          insert(selections, selection);
        } else {
          remove(selections, selection);
        }
      };
    },
  ]);
})();

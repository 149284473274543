import styled from '@emotion/styled';
import React from 'react';

import { Text } from '@shared/components/bootstrap';
import { Spacer } from '@shared/components/helpers';

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SectionHeading: React.FC = ({ children }) => (
  <>
    <FlexContainer>
      <Text tag="h3">{children}</Text>
    </FlexContainer>
    <Spacer height="16px" />
  </>
);

import * as React from 'react';
import { useState } from 'react';

import { AuditOrderEventFragment } from '@admin/schema';

import { Timestamp } from '@admin/components/timestamp';

import { Lightbox } from '@shared/components/helpers/lightbox';

interface IImageSelection {
  imageSrc: string;
  caption: string;
}

export const OrderAuditImages: React.FC<{
  auditOrderEvents: AuditOrderEventFragment[];
  timezone: string;
}> = ({ auditOrderEvents, timezone }) => {
  const [selection, setSelection] = useState<IImageSelection | undefined>(undefined);
  return (
    <div>
      {auditOrderEvents
        .filter((orderEvent) => orderEvent.image)
        .map((orderEvent) => (
          <div key={orderEvent.image!.id} className="col-xs-6 col-md-4 col-lg-2">
            <span> Order </span>
            <a className="underline" href={`/orders/${orderEvent.orderId}`} target="_blank">
              {orderEvent.orderId}
            </a>
            <span>
              : {orderEvent.titleizedEventName} at{' '}
              <Timestamp value={orderEvent.timestamp!} format="medium" timezone={timezone} />
            </span>

            <figure className="brick-square">
              <a
                href="#"
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  setSelection({
                    imageSrc: orderEvent.image!.source,
                    caption: 'Order ' + orderEvent.orderId + ': ' + orderEvent.titleizedEventName,
                  });
                }}
              >
                <img className="location-image" src={orderEvent.image!.size} />
              </a>
            </figure>
          </div>
        ))}
      {selection && (
        <Lightbox imageURL={selection.imageSrc} caption={selection.caption} onClose={() => setSelection(undefined)} />
      )}
    </div>
  );
};

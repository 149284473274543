import { SelectFormGroup } from '@admin/components/fields/select_form_group';
import styled from '@emotion/styled';

export const InlineSelectFormGroup = styled(SelectFormGroup)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;

  & > label {
    flex-shrink: 0;
    margin-bottom: 0;
  }
`;

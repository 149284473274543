angular.module('app').factory('Invoice', [
  '$resource',
  function ($resource) {
    return $resource(
      '/accounts/:account_id/invoices/:id.json',
      {
        id: '@id',
        account_id: '@account_id',
      },
      {
        find: {
          url: '/accounts/:account_id/invoices/find.json',
          method: 'GET',
        },
        pay: {
          url: '/accounts/:account_id/invoices/:id/pay.json',
          method: 'POST',
        },
        refund: {
          url: '/accounts/:account_id/invoices/:id/refund.json',
          method: 'POST',
        },
      },
    );
  },
]);

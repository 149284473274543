import * as React from 'react';
import Collapse from 'react-css-collapse';

import DispatchMetrics from './dispatch_metrics';

import { IDispatchNote } from './types/dispatch_note';
import { IOutboundDispatch } from './types/outbound_dispatch';
import { IVehicle } from './types/vehicle';

import { Note } from './note';
import { SaveButton } from './save_button';
import { VehicleAssignment } from './vehicle_assignment';

interface IInboundVehicleProps {
  dispatchedVehicles: IVehicle[] | null;
  outboundDispatches?: IOutboundDispatch[];
  dispatchesNotes?: IDispatchNote[];
  vehicleType: string;
  warehouseId?: string;
  handleVehicleAssignment(
    serialCode: string | undefined,
    dispatchId: number | undefined,
    warehouseId: string | undefined,
    oldDispatchId: number | undefined,
    notes: string | undefined,
  ): void;
}

interface IInboundVehicleState {
  selectedVehicleToDispatch: { [vehicleId: string]: number };
  submitEnabled: { [vehicleId: string]: boolean };
  collapse: { [vehicleId: string]: boolean };
  notes: { [vehicleId: string]: string };
}

class InboundVehicle extends React.Component<IInboundVehicleProps, IInboundVehicleState> {
  constructor(props: IInboundVehicleProps) {
    super(props);

    this.state = {
      selectedVehicleToDispatch: {},
      collapse: {},
      notes: {},
      submitEnabled: {},
    };
  }

  public render() {
    const {
      dispatchedVehicles,
      outboundDispatches,
      dispatchesNotes,
      handleVehicleAssignment,
      warehouseId,
      vehicleType,
    } = this.props;

    return (
      <div className="active-vehicles__tables">
        <h6>{vehicleType} Vehicle</h6>
        <table className="table dispatch-table">
          <tbody>
            {dispatchedVehicles &&
              dispatchedVehicles.map((v) => (
                <React.Fragment key={v.vehicleId}>
                  <tr className="dispatch-table__row">
                    <td className="dispatch-table__serial-code">
                      <span
                        className="dispatch-table__down-arrow"
                        onClick={() => {
                          this.handleCollapse(v.serialCode);
                        }}
                      >
                        <i
                          className={`fa fa-chevron-right rotate ${this.state.collapse[v.serialCode] ? 'down' : ''}`}
                        />
                      </span>
                    </td>
                    <td className="dispatch-table__vehicle-details">
                      <span className="dispatch-table__vehicle-details--serial-code">{v.serialCode}</span>
                      <span>{v.numSeats} seats</span>
                      <span>{v.cuft} cuft</span>
                    </td>
                    <td className="dispatch-table__details">
                      {v.dispatchIdRegions && v.displayDetails && (
                        <React.Fragment>
                          <span>
                            {v.dispatchIdRegions
                              .map((idRegion) => (
                                <a
                                  href={'/dispatches/' + idRegion[0]}
                                  target="_blank"
                                  className="dispatch-table__link"
                                  key={`${v.vehicleId}-${idRegion[0]}`}
                                >
                                  {`${idRegion[1]} ${idRegion[0]}`}
                                </a>
                              ))
                              .reduce<React.ReactNode[]>(
                                (acc: any[], b: any) => (acc.length === 0 ? [b] : [...acc, ', ', b]),
                                [],
                              )}
                            <span>{v.depotName}</span>
                            <span>{v.arrival}</span>
                          </span>
                        </React.Fragment>
                      )}
                    </td>
                    <td className="dispatch-table__order-details">
                      {v.dispatchIdRegions && v.displayDetails && (
                        <DispatchMetrics
                          itemCount={v.pickupsItemCount}
                          totalCuft={v.totalCuft}
                          cuftPerItemCount={v.cuftPerItemCount}
                          estimatedHours={v.estimatedIngestionHours}
                        />
                      )}
                    </td>
                    <td className="dispatch-table__split-info">
                      {!!v.dispatchVehicleCount && v.displayDetails && (
                        <span>{v.dispatchVehicleCount > 1 ? `1/${v.dispatchVehicleCount}` : ''}</span>
                      )}
                    </td>
                    <td className="active-vehicles__assignment">
                      <VehicleAssignment
                        outboundDispatches={outboundDispatches}
                        serialCode={v.serialCode}
                        submitEnabled={this.state.submitEnabled[v.serialCode] || this.submitEnabled(v.serialCode)}
                        selectedDispatchId={
                          this.state.selectedVehicleToDispatch[v.serialCode] !== undefined
                            ? this.state.selectedVehicleToDispatch[v.serialCode]
                            : this.selectedDispatch(v)
                        }
                        collapseEnabled={this.state.collapse[v.serialCode] || false}
                        handleVehicleAssignment={handleVehicleAssignment}
                        handleSelectedDispatch={this.handleSelectedDispatch}
                        warehouseId={warehouseId}
                      />
                    </td>
                  </tr>
                  <tr className="active-vehicles__collapse--container">
                    <td className="active-vehicles__collapse--row" colSpan={6}>
                      <Collapse isOpen={this.state.collapse[v.serialCode]}>
                        <Note
                          vehicle={v}
                          dispatchesNotes={dispatchesNotes}
                          updatedNote={this.state.notes[v.serialCode]}
                          handleNotes={this.handleNotes}
                          selectedDispatchId={
                            this.state.selectedVehicleToDispatch[v.serialCode] || this.selectedDispatch(v)
                          }
                        />
                        <SaveButton
                          serialCode={v.serialCode}
                          selectedDispatchId={
                            this.state.selectedVehicleToDispatch[v.serialCode] || this.selectedDispatch(v)
                          }
                          warehouseId={warehouseId}
                          outboundDispatches={outboundDispatches}
                          notes={this.state.notes[v.serialCode] || ''}
                          disabled={!(this.state.submitEnabled[v.serialCode] || this.submitEnabled(v.serialCode))}
                          handleVehicleAssignment={handleVehicleAssignment}
                        />
                      </Collapse>
                    </td>
                  </tr>
                </React.Fragment>
              ))}
          </tbody>
        </table>
      </div>
    );
  }

  private handleSelectedDispatch = (selectedDispatchId: number, serialCode: string) => {
    this.setState(({ selectedVehicleToDispatch, submitEnabled }) => ({
      selectedVehicleToDispatch: { ...selectedVehicleToDispatch, [serialCode]: selectedDispatchId },
      submitEnabled: { ...submitEnabled, [serialCode]: true },
    }));
  };

  private selectedDispatch = (vehicle: any) => {
    const { outboundDispatches } = this.props;
    const assignedDispatch =
      outboundDispatches && outboundDispatches.find((d) => d.vehicleCodes.includes(vehicle.serialCode));

    return assignedDispatch && assignedDispatch.dispatchId;
  };

  private handleCollapse = (id: string) => {
    this.setState(({ collapse }) => ({ collapse: { ...collapse, [id]: !collapse[id] } }));
  };

  private submitEnabled = (serialCode: string) => {
    const { outboundDispatches } = this.props;

    const assignedDispatch = outboundDispatches && outboundDispatches.find((d) => d.vehicleCodes.includes(serialCode));
    return !!assignedDispatch;
  };

  private handleNotes = (serialCode: string, note: string) => {
    this.setState(({ notes }) => ({ notes: { ...notes, [serialCode]: note } }));
  };
}

export { InboundVehicle };

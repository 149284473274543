import { client } from '@admin/libraries/apollo';
import * as React from 'react';
import { useWarehousesSelectorQuery } from '@admin/schema';

import { MultiselectFormControl } from '@admin/components/fields';

export const Selector: React.FC<{
  selectedIDs: string[];
  onChange(selectedIDs: string[]): void;
}> = ({ onChange, selectedIDs }) => {
  const { data } = useWarehousesSelectorQuery({ client, variables: { filters: { active: true } } });
  const warehouses = data?.warehouses ?? [];

  return (
    <MultiselectFormControl
      placeholder="Warehouses"
      options={warehouses}
      selectedIDs={selectedIDs}
      onChange={onChange}
    />
  );
};

import React, { useState } from 'react';
import styled from '@emotion/styled';
import { client } from '@admin/libraries/apollo';

import { Status, useSignatureApprovalCreateMutation } from '@admin/schema';
import { Button, HelpBlock } from '@shared/components/bootstrap';

const MarginButton = styled(Button)`
  margin-left: 4px;
`;

export const SimulateSignature: React.FC<{ orderID: string }> = ({ orderID }) => {
  const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);
  const [error, setError] = useState<string | undefined>(undefined);
  const [execute] = useSignatureApprovalCreateMutation({
    client,
  });

  const onSubmit = async () => {
    setError(undefined);
    setSuccessMessage(undefined);

    const result = await execute({
      variables: {
        orderID: orderID,
      },
    });

    const res = result.data?.signatureApprovalCreate;

    if (res?.status === Status.Ok) {
      setSuccessMessage('Signature successfully simulated! Wait for app to progress.');
    } else {
      setError(res?.error || 'Sorry, something went wrong. Please try again or contact Tech Support.');
    }
  };

  return (
    <>
      {error && <HelpBlock className="text-danger">{error}</HelpBlock>}
      {successMessage && <HelpBlock className="text-success">{successMessage}</HelpBlock>}
      <MarginButton kind="primary" onClick={onSubmit}>
        Simulate Customer Signing
      </MarginButton>
    </>
  );
};

import React from 'react';
import { useField } from 'formik';

import Select from 'react-select';

import { FieldFormGroupProps, FieldFormGroup } from '@shared/components/fields/field_form_group';

type SelectFormControlProps<T extends string | number> = React.SelectHTMLAttributes<HTMLSelectElement> &
  FieldFormGroupProps & { name: string; entries: Array<ISelectable<T>> };

interface ISelectable<T> {
  id: T;
  name: string;
}

interface IOption<T> {
  label: string;
  value: T;
}

export const FormikMultiselectFormGroup = <T extends string | number>({
  has,
  help,
  label,
  ...input
}: SelectFormControlProps<T>) => {
  const [{ value }, { error, touched }, { setValue }] = useField<Array<string | number>>(input.name);
  const options: Array<IOption<T>> = input.entries.map((entry) => ({ value: entry.id, label: entry.name }));
  const getValue = () => {
    if (options) {
      return options.filter((option) => value.includes(option.value));
    } else {
      return [];
    }
  };

  return (
    <FieldFormGroup has={touched && error ? 'error' : undefined} help={error ?? help} label={label} id={input.id}>
      <Select
        id={input.id}
        isMulti
        options={options}
        value={getValue()}
        name={input.name}
        onChange={(values) => setValue(values.map((option) => option.value))}
        placeholder={input.placeholder}
        isDisabled={input.disabled}
      />
    </FieldFormGroup>
  );
};

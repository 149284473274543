(function () {
  // this decorator is needed to prevent javascript errors when trying to dynamically
  // update a polyline: https://github.com/angular-ui/angular-google-maps/issues/1562
  angular.module('app').decorator('uiGmapModelKey', ['$delegate', uiGmapModelKeyDecorator]);
  function uiGmapModelKeyDecorator($delegate) {
    const setChildScope = $delegate.prototype.setChildScope;

    $delegate.prototype.setChildScope = function (keys, childScope) {
      // eslint-disable-next-line prefer-rest-params
      const result = setChildScope.apply(this, arguments);

      if (childScope && childScope.hasOwnProperty('model') && !childScope.model) {
        delete childScope.model;
      }

      return result;
    };

    return $delegate;
  }
})();

import { ReorderableList } from '@admin/components/helpers/reorderable_list';
import { client } from '@admin/libraries/apollo';
import { OrderMapDocument, TaskOrder__TaskFragment, useOrderTasksUpdateMutation } from '@admin/schema';
import { mask } from '@admin/utils/phone';
import { Box } from '@clutter/clean';
import { Button, Table, Text } from '@shared/components/bootstrap';
import React, { useState } from 'react';
import { ClientTask, TaskModal } from './task_modal';

const TaskHeaders = () => (
  <thead>
    <tr>
      <td>Number</td>
      <td>Address</td>
      <td>Contact</td>
      <td width="120">Actions</td>
    </tr>
  </thead>
);

const TaskColumns: React.FC<{ task: TaskOrder__TaskFragment; onEdit(): void; onRemove(): void }> = ({
  task,
  onEdit,
  onRemove,
}) => (
  <>
    <td>
      <b>#{task.sequenceNumber}</b>
    </td>
    <td>{task.address.street}</td>
    <td>
      {task.contact?.name && `${task.contact.name} - `}
      {task.contact && mask(task.contact.phone)}
    </td>
    <td>
      <Button.Group>
        <Button onClick={() => onEdit()} kind="default" size="sm">
          Edit
        </Button>
        <Button onClick={() => onRemove()} kind="danger" size="sm">
          Remove
        </Button>
      </Button.Group>
    </td>
  </>
);

export const TasksPanel = ({
  accountID,
  orderID,
  initialTasks,
}: {
  accountID: string;
  orderID: string;
  initialTasks: TaskOrder__TaskFragment[];
}) => {
  const [updateTasks, { loading }] = useOrderTasksUpdateMutation({ client });
  const [error, setError] = useState<Error | null>(null);
  const [changed, setChanged] = useState(false);
  const [editedTaskIndex, setEditedTask] = useState<number | 'new' | undefined>(undefined);
  const [tasks, setTasks] = useState(initialTasks);

  const onRemove = (index: number) => {
    const newItems = Array.from(items);
    newItems.splice(index, 1);
    onSave(newItems.map((item) => item.props.task));
  };

  const onEdit = setEditedTask;

  const taskToItem = (task: TaskOrder__TaskFragment, index: number) => ({
    component: TaskColumns,
    props: { task, onEdit: () => onEdit(index), onRemove: () => onRemove(index) },
    key: task.id,
  });

  const onSave = async (newItems: ClientTask[]) => {
    setError(null);
    try {
      const { data } = await updateTasks({
        variables: {
          input: {
            orderID,
            tasks: newItems.map((task) => {
              const { name, phone } = task.contact || {};
              return {
                id: task.id,
                instructions: task.instructions,
                addressID: task.address!.id,
                contact: name && phone ? { name, phone } : undefined,
              };
            }),
          },
        },
        refetchQueries: [{ query: OrderMapDocument, variables: { orderID } }],
      });
      if (data?.result?.order?.tasks) {
        setTasks(data.result.order.tasks);
        setChanged(false);
      } else {
        setTasks(initialTasks);
      }
    } catch (e) {
      setTasks(initialTasks);
      if (e instanceof Error) setError(e);
      else throw e;
    }
  };

  const onSaveEdit = (task: ClientTask) => {
    const newItems = items.map<ClientTask>((item) => item.props.task);

    if (task.id && typeof editedTaskIndex === 'number') {
      newItems[editedTaskIndex] = task;
    } else {
      newItems.push(task);
    }
    return onSave(newItems);
  };

  const items = tasks.map(taskToItem);

  return (
    <Box.Flex justifyContent="space-between" flexDirection="column" height="100%">
      <Box position="relative" style={loading ? { opacity: 0.3, pointerEvents: 'none', cursor: 'wait' } : undefined}>
        <Table striped>
          <TaskHeaders />
          <tbody>
            <ReorderableList
              items={items}
              setItems={(newItems) => {
                setChanged(true);
                setTasks(newItems.map((item) => item.props.task));
              }}
              wrapperTag="tr"
            />
          </tbody>
        </Table>
      </Box>
      <Box.Flex justifyContent="space-between">
        <Text style="danger">{error?.message}</Text>
        <Button.Group>
          {changed && (
            <Button kind="default" onClick={() => onSave(tasks)} loading={loading}>
              Save sequence
            </Button>
          )}
          <Button kind="primary" onClick={() => setEditedTask('new')}>
            Add Task
          </Button>
        </Button.Group>
      </Box.Flex>
      {editedTaskIndex !== undefined && (
        <TaskModal
          onClose={() => setEditedTask(undefined)}
          orderID={orderID}
          task={typeof editedTaskIndex === 'number' ? tasks[editedTaskIndex] : { id: undefined }}
          accountID={accountID}
          onEdit={onSaveEdit}
        />
      )}
    </Box.Flex>
  );
};

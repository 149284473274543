(function () {
  const app = angular.module('app');

  app.factory('WorkBreak', [
    '$resource',
    function ($resource) {
      return $resource(
        '/shifts/:shift_id/work_breaks.json',
        { shift_id: '@shift_id' },
        {
          create: {
            method: 'POST',
          },
          update: {
            method: 'PATCH',
            url: '/shifts/:shift_id/work_breaks/:uuid.json',
            params: { shift_id: '@shift_id', uuid: '@uuid' },
          },
        },
      );
    },
  ]);
})();

import { Panel } from '@admin/components/helpers/panel';
import { Pagination } from '@admin/components/pagination';
import { Filters } from '@admin/components/rate_adjustment/schedulings/filters';

import { Spinner } from '@admin/components/spinner';

import { client } from '@admin/libraries/apollo';
import { useDebounce } from '@shared/hooks';

import {
  RateAdjustment__Scheduling__FiltersInput,
  RateAdjustment__Upload__Product,
  usePaginatedRateAdjustmentSchedulingsQuery,
} from '@admin/schema';
import * as React from 'react';
import { useState } from 'react';

import { Table } from './table';

export const List: React.FC<{ product: RateAdjustment__Upload__Product }> = ({ product }) => {
  const [filters, setFilters] = useState<RateAdjustment__Scheduling__FiltersInput>({ product });
  const [page, setPage] = useState<number | undefined>();
  const { data, loading } = usePaginatedRateAdjustmentSchedulingsQuery({
    client,
    variables: {
      page,
      filters: {
        ...filters,
        search: useDebounce(filters.search),
      },
    },
  });

  const paginated = data && data.paginated;

  return (
    <>
      <Panel>
        <Panel.Body>
          <Filters
            filters={filters}
            onChange={(changes) => {
              setPage(undefined);
              setFilters(changes);
            }}
          />
        </Panel.Body>
      </Panel>
      <Panel>
        <Panel.Body>
          {loading && <Spinner />}
          {!loading && paginated && (
            <>
              <Table entries={paginated.results} product={RateAdjustment__Upload__Product.SelfStorage} />
              <Pagination pagination={paginated.pagination} onPage={setPage} />
            </>
          )}
        </Panel.Body>
      </Panel>
    </>
  );
};

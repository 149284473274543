(function () {
  function capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  function removeCharacter(str, character) {
    return str.split(character).join(' ');
  }

  angular.module('app').filter('titleize', function () {
    return function (inputString) {
      if (typeof inputString !== 'string') {
        return '';
      }
      let words = inputString;
      words = removeCharacter(words, ':');
      words = removeCharacter(words, '_');
      words = words
        .split(' ')
        .map(function (str) {
          return capitalize(str);
        })
        .join(' ');
      return words;
    };
  });
})();

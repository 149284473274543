import { array } from '../utils';

const SET = 'dispatches/SET';
const CONCAT = 'dispatches/CONCAT';

export const setDispatches = (data) => ({
  type: SET,
  data,
  unique: true,
});

export const concatDispatches = (data) => ({
  type: CONCAT,
  unique: true,
  data,
});

export default array({
  SET,
  CONCAT,
});

import React from 'react';
import { COLORS } from '@clutter/clean';
import styled from '@emotion/styled';
import { FieldArray, useField } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { FieldFormGroupProps } from '@admin/components/fields';
import { FormikFieldFormGroup } from '@shared/components/fields/formik/formik_field_form_group';
import { Availability__LaborAdjustmentsRangeInput } from '@admin/schema';
import { FormikInputFormGroup } from '@shared/components/fields/formik/formik_input_form_group';
import { Button, Col, Row } from '@shared/components/bootstrap';

const AdjustmentRange = styled.div`
  margin: 10px 0;
`;

const RemoveButton = styled.div`
  display: inline-block;
  cursor: pointer;
  color: ${COLORS.toucan};
`;

const AdjustmentRangeCol = styled(Col)`
  display: inline-block;
  float: none;
`;

export const FormikAdjustmentRanges: React.FC<FieldFormGroupProps & { name: string }> = ({
  has,
  help,
  label,
  ...input
}) => {
  const [_, { value }] = useField(input.name);

  return (
    <FormikFieldFormGroup name={input.name} help={help} label={label} id={input.id}>
      <FieldArray
        name={input.name}
        render={({ push, remove }) => (
          <>
            {value.map((adjustment: Availability__LaborAdjustmentsRangeInput, idx: number) => (
              <AdjustmentRange key={`adjustment-${idx}`}>
                <Row>
                  <AdjustmentRangeCol md={3}>
                    <FormikInputFormGroup
                      id={`${input.name}_${idx}_low`}
                      name={`${input.name}[${idx}].lowThresholdPercentage`}
                      label="Low threshold"
                      type="number"
                      min={0}
                      max={100}
                    />
                  </AdjustmentRangeCol>
                  <AdjustmentRangeCol md={3}>
                    <FormikInputFormGroup
                      id={`${input.name}_${idx}_high`}
                      name={`${input.name}[${idx}].highThresholdPercentage`}
                      label="High threshold"
                      type="number"
                      min={0}
                      max={100}
                    />
                  </AdjustmentRangeCol>
                  <AdjustmentRangeCol md={3}>
                    <FormikInputFormGroup
                      id={`${input.name}_${idx}_adjustment`}
                      name={`${input.name}[${idx}].perMoverHourAdjustmentAmount`}
                      label="Per mover hour adjustment"
                      type="number"
                      min={-100}
                      max={100}
                    />
                  </AdjustmentRangeCol>
                  <AdjustmentRangeCol md={3}>
                    <RemoveButton onClick={() => remove(idx)}>
                      <FontAwesomeIcon icon="times" />
                    </RemoveButton>
                  </AdjustmentRangeCol>
                </Row>
              </AdjustmentRange>
            ))}
            <div>
              <Button
                onClick={() =>
                  push({ lowThresholdPercentage: 0, highThresholdPercentage: 0, perMoverHourAdjustmentAmount: 0 })
                }
                kind="primary"
              >
                Add Range
              </Button>
            </div>
          </>
        )}
      />
    </FormikFieldFormGroup>
  );
};

import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { COLORS } from '@clutter/clean';

import { UUID } from '@shared/utils';
import { Button } from '@shared/components/bootstrap';

import { Context } from './context';
import { Mode } from './filters';
import { NightTransportRoute } from './types';

const RouteItem = styled.div`
  border-right: thin solid ${COLORS.grayBorder};
  width: 300px;
  flex-grow: 0;
  flex-shrink: 0;
`;

const AddButton = styled.div`
  width: 100%;
  padding: 38px 15px;
`;

export const AddRoute: React.FC = () => {
  const { mode, geo, loading, onAddRoute } = useContext(Context);

  const onAdd = () => {
    const newRoute: NightTransportRoute = { uuid: UUID(), arrival: '', origin: undefined };
    onAddRoute(newRoute);
  };

  return (
    <RouteItem>
      <AddButton>
        <Button block kind="default" onClick={onAdd} disabled={!geo || mode !== Mode.Editing || loading}>
          Add
        </Button>
      </AddButton>
    </RouteItem>
  );
};

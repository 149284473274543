import styled from '@emotion/styled';
import React, { useState } from 'react';
import { ErrorMessage, FormContext, useForm } from 'react-hook-form';

import { useAdapter, useElement, useTokenize } from '@shared/components/stripe';

import { STRIPE_API_KEY } from '@admin/config/stripe';

import { Panel } from '@admin/components/helpers/panel';
import { Spinner } from '@admin/components/spinner';
import { newSelfStorageRentalURL } from '@admin/config/routes';
import { Alert, AnchorButton, Button, Text } from '@shared/components/bootstrap';
import { Spacer } from '@shared/components/helpers';
import { accountURL } from '@admin/config/routes';
import {
  SelfStorage__RentInput,
  SelfStorage__ReservationFragment,
  SelfStorage__AccountForRentingFragment,
  useSelfStorageAccountForRentingQuery,
  useSelfStorageReservationQuery,
  useRentSelfStorageMutation,
} from '@admin/schema';
import { client } from '@admin/libraries/apollo';
import { mask } from '@admin/utils/phone';
import { AccountBreadcrumbs } from '../components/account_breadcrumbs';
import { LeadBreadcrumbs } from '../components/lead_breadcrumbs';
import { Additional } from './additional';
import { Addresses } from './addresses';
import { Billing } from './billing';
import { Customer } from './customer';
import { Disclaimers } from './disclaimers';
import { FacilitySelector } from './facility_selector';
import { GateCode } from './gate_code';
import { Quote } from './quote';
import { Summary } from './summary';
import { UnitSelector } from './unit_selector';

import { Context } from './form/context';

import { Dialog as SignDialog, Status as SignStatus, useSign } from './sign';

import { FormData } from './form/types';
import { buildRentInput } from './form/utils';

const HR = styled.hr`
  margin: 0px;
`;

const ErrorAlertWrapper = styled.div`
  .alert {
    margin-bottom: 0px;
  }
  a {
    color: #fff;
    text-decoration: underline;
  }
  a:hover {
    color: #ddd;
  }
  a:active {
    color: #ccc;
  }
`;

const AccountAlertWrapper = styled(ErrorAlertWrapper)`
  font-size: 14px;
  line-height: 17px;
  font-weight: 400;
  white-space: normal;
`;

const DEFAULT_COUNTRY = 'US';

const STRIPE_CARD_ELEMENT_OPTIONS: IStripeElementOptions = {
  hidePostalCode: true,
  style: {
    base: {
      color: '#555555',
      fontSize: '12px',
      fontFamily: 'inherit',
    },
  },
};

const Container: React.FC<{
  account?: SelfStorage__AccountForRentingFragment;
  reservation?: SelfStorage__ReservationFragment;
}> = ({ account, reservation }) => {
  const adapter = useAdapter(STRIPE_API_KEY);
  const element = useElement(adapter, 'card', STRIPE_CARD_ELEMENT_OPTIONS);
  const tokenizer = useTokenize(adapter, element, { allow: 'prepaid' });

  const [facilityID, setFacilityID] = useState<string | undefined>();
  const [accountAlertVisible, setAccountAlertVisible] = useState(true);

  const [rent, { loading, data: rental }] = useRentSelfStorageMutation({ client });

  const { sign, signing } = useSign();

  const form = useForm<FormData>({
    defaultValues: account
      ? {
          phones: {
            cell: account.customer.cellPhone ? mask(account.customer.cellPhone) : undefined,
            home: account.customer.homePhone ? mask(account.customer.homePhone) : undefined,
            work: account.customer.workPhone ? mask(account.customer.workPhone) : undefined,
          },
          policy: account.policy
            ? {
                kind: account.policy.kind,
                number: account.policy.number,
                company: account.policy.company,
                amount: String(account.policy.amount),
                from: account.policy.from,
                till: account.policy.till,
              }
            : undefined,
          identification: account.identification
            ? {
                birthday: account.identification.birthday,
                state: account.identification.state,
                number: account.identification.number,
              }
            : undefined,
        }
      : undefined,
  });

  const onSubmit = async (data: FormData) => {
    if (loading || tokenizer.tokenizing) {
      return;
    }

    const address =
      !data.addresses.billing.id || data.addresses.billing.id === 'other'
        ? data.addresses.billing
        : account?.addresses.find(({ id }) => id === data.addresses.billing.id);

    const token =
      data.billing.method === 'tokenizer'
        ? await tokenizer.tokenize({
            name: data.billing?.name,
            address_line1: address?.street,
            address_line2: address?.aptsuite ?? undefined,
            address_city: address?.city,
            address_state: address?.state,
            address_zip: address?.zip,
            address_country: DEFAULT_COUNTRY,
          })
        : undefined;

    if (!account && !token) {
      return;
    }

    const input: SelfStorage__RentInput = buildRentInput({
      data,
      token,
      accountID: account?.id,
      reservationID: reservation?.id,
    });

    const { status, signatureIDs } = await sign(input);
    if (status !== SignStatus.Submit) {
      return;
    }
    input.rental.signatureIDs = signatureIDs;

    const result = await rent({ variables: { input } });
    const rentalError = result && result.data?.rent?.error;
    const rentalAccount = result && result.data?.rent?.account;

    if (!rentalError && rentalAccount) {
      location.replace(accountURL(rentalAccount));
    }
  };

  let cancelURL = 'self_storage/reservations';
  if (reservation) {
    cancelURL = `self_storage/reservations/${reservation.id}`;
  } else if (account) {
    cancelURL = `/accounts/${account.id}`;
  }

  const saving = tokenizer.tokenizing || loading;
  const error = rental?.rent.error;
  const existingAccount = rental?.rent.account;

  return (
    <Context.Provider value={{ account, element }}>
      <Panel>
        <Panel.Title>
          {reservation ? (
            <>
              <LeadBreadcrumbs reservation={reservation} />
              <Text tag="h2">Reservation Details</Text>
            </>
          ) : (
            <>
              {account && <AccountBreadcrumbs account={account} />}
              <Text tag="h2">New Move-In</Text>
            </>
          )}
          {reservation && account && accountAlertVisible && (
            <AccountAlertWrapper>
              <Alert style="info" onClose={() => setAccountAlertVisible(false)}>
                This customer has already booked with Clutter before and this rental will be added to that account. If
                you believe this is in error, please process this rental as a{' '}
                <a href={newSelfStorageRentalURL()}>walk-in</a> instead.
              </Alert>
            </AccountAlertWrapper>
          )}
        </Panel.Title>
        <Panel.Body>
          <FormContext {...form}>
            {signing && <SignDialog {...signing} />}
            <form id="rentalForm" onSubmit={form.handleSubmit(onSubmit)}>
              <HR />
              {reservation ? (
                <Quote reservation={reservation} />
              ) : (
                <FacilitySelector facilityID={facilityID} setFacilityID={setFacilityID} />
              )}
              <HR />
              <UnitSelector reservation={reservation} facilityID={facilityID} />
              <HR />
              {!account && (
                <>
                  <Customer reservation={reservation} />
                  <HR />
                </>
              )}
              <>
                <Billing />
                <HR />
              </>
              <Addresses />
              <Additional />
              <HR />
              <Summary
                accountID={account?.id}
                facilityID={reservation?.facility.id ?? facilityID}
                reservation={reservation}
              />
              <HR />
              <GateCode accountID={account?.id} facilityID={reservation?.facility.id ?? facilityID} />
              <HR />
              <Disclaimers />

              <ErrorMessage name="feeIDs">{({ message }) => <Alert style="danger">{message}</Alert>}</ErrorMessage>

              {(error || tokenizer.error) && (
                <ErrorAlertWrapper>
                  <Alert style="danger">
                    {existingAccount && error ? (
                      <span>
                        This phone or email belongs to an existing Clutter account. Please click{' '}
                        <a href={newSelfStorageRentalURL({ accountID: existingAccount.id })}>here</a> to process a
                        move-in for an existing customer instead. Don't forget to confirm the credit card already on
                        file!
                      </span>
                    ) : (
                      error || tokenizer.error
                    )}
                  </Alert>
                </ErrorAlertWrapper>
              )}
            </form>
          </FormContext>
        </Panel.Body>
        <Panel.Footer>
          <Button loading={saving} kind="primary" size="lg" type="submit" form="rentalForm">
            Confirm Move-In
          </Button>
          <Spacer width="8px" inline />
          <AnchorButton kind="danger" size="lg" href={cancelURL}>
            Cancel
          </AnchorButton>
        </Panel.Footer>
      </Panel>
    </Context.Provider>
  );
};

export const Form: React.FC<{
  accountID?: string;
  reservationID?: string;
}> = ({ reservationID, accountID }) => {
  const reservation = useSelfStorageReservationQuery({
    client,
    variables: { id: reservationID! },
    skip: !reservationID,
  })?.data?.reservation;

  const account = useSelfStorageAccountForRentingQuery({
    client,
    variables: { accountID: accountID! },
    skip: !accountID,
  })?.data?.account;

  if ((reservationID && !reservation) || (accountID && !account)) {
    return <Spinner />;
  } else {
    return <Container account={account} reservation={reservation} />;
  }
};

import axios from 'axios';

import { sortBy } from 'lodash';

import { IDispatchNote } from '../types/dispatch_note';
import { IOutboundDispatch } from '../types/outbound_dispatch';
import { IPendingDispatch } from '../types/pending_dispatch';

interface IDispatchesParams {
  warehouseId: string;
  date: string;
  vehicleTypes: any;
  depotIds?: string;
  hasReturns?: string;
  filteredVehicleTypes?: string;
  filteredAssignmentOptions?: string;
}

interface IOutboundDispatchesResponse {
  pendingDispatches: IPendingDispatch[];
  outboundDispatches: IOutboundDispatch[];
  dispatchesNotes: IDispatchNote[];
}

class OutboundDispatches {
  private params: IDispatchesParams;

  constructor(params: IDispatchesParams) {
    this.params = params;
  }

  public async fetch(): Promise<IOutboundDispatchesResponse | undefined> {
    let params = { date: this.params.date, has_returns: this.params.hasReturns };

    if (this.params.depotIds && this.params.depotIds.length > 0) {
      params = Object.assign(params, { depot_ids: this.params.depotIds });
    }

    if (this.params.filteredVehicleTypes && this.params.filteredVehicleTypes.length > 0) {
      params = Object.assign(params, { vehicle_types: this.params.filteredVehicleTypes });
    }

    if (this.params.filteredAssignmentOptions && this.params.filteredAssignmentOptions.length > 0) {
      params = Object.assign(params, { vehicle_assignment: this.params.filteredAssignmentOptions });
    }

    try {
      const response = await axios.get(
        `/warehouses/${parseInt(this.params.warehouseId, 10)}/vehicle_route_matcher/dispatches.json`,
        { params },
      );

      return this.parseResponse(response.data);
    } catch {
      return undefined;
    }
  }

  private parseResponse = (res: [any]) => {
    const pendingDispatches: IPendingDispatch[] = [];
    const dispatchesNotes: any[] = [];

    const byDepot: any = {};

    res.forEach((dispatch) => {
      let itemCount = 0;
      let dispatchTotalCuft = 0;
      const vehicleCodes: string[] = [];
      const vehiclePreferences: any = [];

      dispatch.orders.forEach((order: any) => {
        if (order.type === 'return') {
          itemCount += order.items_count;
          dispatchTotalCuft += Number(order.items_cuft);
        }
      });

      dispatch.vehicle_preferences.forEach((vp: any) => {
        vehiclePreferences.push(`${vp.quantity} ${this.params.vehicleTypes[vp.vehicle_type_id]} truck`);
      });

      dispatch.vehicles.forEach((vehicle: any) => {
        vehicleCodes.push(vehicle.name);
      });

      const dockItemsPerHour = dispatch.warehouse.outbound_dock_items_per_hour;
      const outboundDockItemPerHour = dockItemsPerHour > 0 ? itemCount / dockItemsPerHour : 0;

      // Expect fewer than 3 vehicles at most so this loop performance is not of concern
      const vehiclePreferencesSatisfied: boolean =
        JSON.stringify(dispatch.vehicles.map((v: any) => v.vehicle_type_id).sort()) ===
        JSON.stringify(
          dispatch.vehicle_preferences
            .map((vp: any) => Array(vp.quantity).fill(vp.vehicle_type_id))
            .flat()
            .sort(),
        );

      let item = {
        dispatchId: dispatch.id,
        requestedVehicles: vehiclePreferences,
        requestedVehicleTypes: undefined,
        vehicleCount: undefined,
        minCuft: 0,
        seatsNeeded: dispatch.assignments_length,
        depotName: dispatch.depot.name,
        itemCount,
        dispatchTotalCuft,
        vehicleAssigned: dispatch.vehicles.length < 1,
        vehicleRequirementsSatisfied: dispatch.vehicle_requirements_satisfied,
        vehiclePreferencesSatisfied,
        vehiclesSerialCode: dispatch.vehicles.map((v: any) => v.name).join(' '),
        arrival: dispatch.arrival_local_time,
        vehicleCodes,
        outboundDockItemPerHour,
        cuftPerItemCount: itemCount > 0 ? dispatchTotalCuft / itemCount : 0,
      };

      if (dispatch.vehicle_recipe) {
        item = {
          ...item,
          requestedVehicleTypes: dispatch.vehicle_recipe.allowed_vehicle_type_ids
            .map((v: any) => this.params.vehicleTypes[v])
            .join(', '),
          vehicleCount: this.numberOfVehiclesRequested(dispatch.vehicle_recipe),
          minCuft: parseInt(dispatch.vehicle_recipe.minimum_cuft, 10),
        };
      }

      if (byDepot[item.depotName]) {
        byDepot[item.depotName].push(item);
      } else {
        byDepot[item.depotName] = [item];
      }
      pendingDispatches.push(item);
      dispatchesNotes.push(...this.vehicleDispatchNotes(dispatch.id, dispatch.vehicles));
    });

    const pendingOrderedList = this.orderDispatchesByDepotAndVehicle(byDepot);

    return {
      pendingDispatches: this.orderDispatchesByDepotAndVehicle(byDepot),
      outboundDispatches: this.outboundDispatches(pendingOrderedList),
      dispatchesNotes,
    };
  };

  private orderDispatchesByDepotAndVehicle = (pendingDispatchesByDepot: any) => {
    const keys = Object.keys(pendingDispatchesByDepot).sort();
    const pendingDispatches: any[] = [];

    keys.forEach((key: string) => {
      pendingDispatches.push(...sortBy(pendingDispatchesByDepot[key], 'requestedVehicles'));
    });

    return pendingDispatches;
  };

  private outboundDispatches = (pendingDispatches: any) => {
    const dispatchList: IOutboundDispatch[] = [];

    pendingDispatches.forEach((d: any) => {
      dispatchList.push({ dispatchId: d.dispatchId, vehicleCodes: d.vehicleCodes });
    });
    return dispatchList;
  };

  private vehicleDispatchNotes = (dispatchId: number, vehicles: any) => {
    const notes: any[] = [];
    vehicles.forEach((v: any) => {
      notes.push({ dispatchId, serialCode: v.name, notes: v.dispatch_vehicle_notes });
    });
    return notes;
  };

  private numberOfVehiclesRequested = (vehicleRecipe: any) => {
    const count =
      vehicleRecipe.max_quantity === vehicleRecipe.min_quantity
        ? vehicleRecipe.min_quantity
        : `${vehicleRecipe.min_quantity} - ${vehicleRecipe.max_quantity}`;
    return count;
  };
}

export { OutboundDispatches };

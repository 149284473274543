import { capitalize } from 'lodash';
import React from 'react';

import { Thumbnails } from '@admin/components/helpers/thumbnails';
import { Claim__ItemSelectionFragment } from '@admin/schema';
import { ListGroup } from '@shared/components/bootstrap';
import { Currency } from '@shared/components/helpers';

import { ItemLink } from '../item_link';
import { IResolutionEdits } from './helpers';
import { Selection } from './selection';

export const ItemSelections: React.FC<{
  selections: Claim__ItemSelectionFragment[];
  accountID: string;
  edits: IResolutionEdits;
  setEdits(edits: IResolutionEdits): void;
}> = ({ selections, accountID, edits, setEdits }) => (
  <>
    <ListGroup.Item>
      <div className="row">
        <div className="col-sm-2">
          <strong>Item</strong>
        </div>
        <div className="col-sm-1">
          <strong>Type</strong>
        </div>
        <div className="col-sm-1">
          <strong>Images</strong>
        </div>
        <div className="col-sm-2">
          <strong>URL</strong>
        </div>
        <div className="col-sm-1">
          <strong>Declared</strong>
        </div>
        <div className="col-sm-2">
          <strong>Classifications</strong>
        </div>
        <div className="col-sm-1">
          <strong>Approved</strong>
        </div>
        <div className="col-sm-2">
          <strong>Offered</strong>
        </div>
      </div>
    </ListGroup.Item>
    {selections.map((selection) => (
      <ListGroup.Item key={selection.id}>
        <div className="row">
          <div className="col-sm-2">
            <ItemLink accountID={accountID} itemID={selection.item?.id} itemName={selection.name ?? undefined} />
          </div>
          <div className="col-sm-1">{capitalize(selection.kind)}</div>
          <div className="col-sm-1">
            <Thumbnails attachments={selection.photos.concat(selection.receipts)} condensed />
          </div>
          <div className="col-sm-2">
            <a className="btn-link text-overflow" href={selection.productURL ?? undefined} target="_blank">
              {selection.productURL}
            </a>
          </div>
          <div className="col-sm-1">
            <Currency value={Number(selection.declaration || 0)} />
          </div>
          <Selection selection={selection} showWeight edits={edits} setEdits={setEdits} />
        </div>
      </ListGroup.Item>
    ))}
  </>
);

export function splitName(name: string): { first: string; middle?: string; last?: string } {
  const names = name.split(/\s+/);
  let lastName;
  if (names.length > 1) {
    lastName = names[names.length - 1];
  }

  let middleName;
  if (names.length > 2) {
    middleName = names.slice(1, names.length - 1).join(' ');
  }

  return { first: names[0], middle: middleName, last: lastName };
}

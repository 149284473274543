import React from 'react';
import { useEffect, useState } from 'react';
import moment from 'moment';
import DayPicker, { DayModifiers } from 'react-day-picker';

export const AvailabilityCalender: React.FC<{
  month: Date;
  selectedDays: Date[];
  disabled?: boolean;
  onDaySelected(dates: Date[]): void;
  onMonthChange(month: Date): void;
}> = ({ month, selectedDays, disabled, onDaySelected, onMonthChange }) => {
  const [mouseDown, setMouseDown] = useState(false);

  useEffect(() => {
    const handler = () => setMouseDown(false);
    addEventListener('mouseup', handler);
    return () => removeEventListener('mouseup', handler);
  }, []);

  const selectDay = (day: Date, modifiers: DayModifiers) => {
    if (disabled) return;

    if (modifiers.selected) {
      onDaySelected(selectedDays.filter((selectedDay) => !moment(selectedDay).isSame(moment(day), 'day')));
    } else {
      onDaySelected(selectedDays.concat(day));
    }
  };

  return (
    <DayPicker
      selectedDays={selectedDays}
      onDayMouseDown={(day: Date, modifiers: DayModifiers) => {
        setMouseDown(true);
        selectDay(day, modifiers);
      }}
      onDayMouseEnter={(day: Date, modifiers: DayModifiers) => {
        if (mouseDown) selectDay(day, modifiers);
      }}
      disabledDays={disabled ? { daysOfWeek: [0, 1, 2, 3, 4, 5, 6] } : { before: new Date() }}
      renderDay={(day: Date) => {
        const date = day.getDate();
        return <div>{date}</div>;
      }}
      month={month}
      onMonthChange={onMonthChange}
    />
  );
};

import angular from 'angular';

const affirmation = `I have estimated the size of this order based upon the
                    information provided by the customer, and I consent to
                    have my electronic signature used on this order's corresponding estimate forms.`;

const template = `<label class="text-left">
    <input
      type="checkbox"
      id="estimator-signature-affirmation"
      ng-model="$ctrl.checked"
      ng-change="$ctrl.onCheck({ value: $ctrl.checked})"
    >
    ${affirmation}
  </label>`;

angular.module('app').component('estimatorSignatureAffirmation', {
  template,
  bindings: {
    onCheck: '&',
  },
  controller: function ctrl() {
    this.$onInit = () => {
      this.checked = false;
    };
  },
});

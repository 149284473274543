import { array } from '../utils';

const SET = 'checkins/SET';
const CONCAT = 'checkins/CONCAT';

export const setCheckins = (data) => ({
  type: SET,
  data,
  unique: true,
});

export const concatCheckins = (data) => ({
  type: CONCAT,
  unique: true,
  data,
});

export default array({
  SET,
  CONCAT,
});

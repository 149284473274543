(function () {
  const app = angular.module('app');

  app.controller('SchedulesShowController', [
    '$scope',
    '$resource',
    'Schedule',
    'AccountService',
    function ($scope, $resource, Schedule, AccountService) {
      $scope.getCustomerNames = AccountService.getCustomerNames;
      $scope.getPrimaryEmail = AccountService.getPrimaryEmail;
      $scope.getPrimaryPhone = AccountService.getPrimaryPhone;
      $scope.schedule = Schedule.get();

      $scope.assigned = function (dispatch) {
        return _.find(dispatch.assignments, function (assignment) {
          return assignment.user.id === $scope.schedule.user.id;
        });
      };

      $scope.accept = function (assignment) {
        const Accept = $resource('/assignments/:assignment_id/accept.json', { assignment_id: '@assignment_id' });
        const accept = new Accept({ assignment_id: assignment.id });
        accept.$save(function () {
          assignment.state = 'accepted';
        });
      };

      $scope.reject = function (assignment) {
        const Reject = $resource('/assignments/:assignment_id/reject.json', { assignment_id: '@assignment_id' });
        const reject = new Reject({ assignment_id: assignment.id });
        reject.$save(function () {
          assignment.state = 'rejected';
        });
      };
    },
  ]);
})();

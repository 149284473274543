import { useField } from 'formik';
import * as React from 'react';
import { forwardRef } from 'react';

import { FieldFormGroupProps, FieldFormGroup } from '@shared/components/fields/field_form_group';

type SelectFormControlType = HTMLSelectElement;
type SelectFormControlProps = React.SelectHTMLAttributes<HTMLSelectElement> & FieldFormGroupProps & { name: string };

export const FormikSelectFormGroup = forwardRef<SelectFormControlType, SelectFormControlProps>(
  ({ has, help, label, ...input }, ref) => {
    const [field, { error, touched }] = useField(input.name);
    return (
      <FieldFormGroup has={touched && error ? 'error' : undefined} help={error ?? help} label={label} id={input.id}>
        <select className="form-control" ref={ref} {...field} {...input} />
      </FieldFormGroup>
    );
  },
);

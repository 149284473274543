(function () {
  const app = angular.module('app');

  app.controller('CustomersEditController', [
    '$scope',
    '$state',
    '$stateParams',
    'Account',
    'Customer',
    'Page',
    'AccountService',
    'ErrorService',
    function ($scope, $state, $stateParams, Account, Customer, Page, AccountService, ErrorService) {
      $scope.id = $stateParams.id;
      $scope.account_id = $stateParams.account_id;

      $scope.account = Account.get({ id: $scope.account_id }, (account) => {
        $scope.primaryCustomer = AccountService.getPrimaryCustomer(account.customers);
        Page.reset({ title: `Acct: ${account.customer.name} - Customer - Edit` });
      });

      $scope.customer = Customer.get({
        id: $scope.id,
        account_id: $scope.account_id,
      });

      function showErrorBanner(error) {
        ErrorService.refreshBanner(error.data);
      }

      function viewAccount() {
        $state.go('viewAccount', { id: $scope.account.id });
      }

      $scope.showErrorBanner = showErrorBanner;
      $scope.viewAccount = viewAccount;
    },
  ]);
})();

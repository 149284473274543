(function () {
  angular.module('app').factory('HistoricalPricing', [
    '$resource',
    function ($resource) {
      return $resource('/accounts/:account_id/historical_pricings.json', {
        account_id: '@account_id',
      });
    },
  ]);
})();

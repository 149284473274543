import React, { useState } from 'react';

import { DateTime } from 'luxon';

import {
  Notification__ProviderEnum,
  NotificationDelivery__State,
  NotificationFragment,
  OrderSubtypeEnum,
  OrderTypeEnum,
  useAccountOrdersTypeAndSubtypeQuery,
  usePaginatedNotificationListQuery,
} from '@admin/schema';

import { Button, Table } from '@shared/components/bootstrap';
import { client } from '@admin/libraries/apollo';

import { Panel } from '@admin/components/helpers/panel';
import { Pagination } from '@admin/components/pagination';
import { Spinner } from '@admin/components/spinner';
import { EmailEventsModal } from './email_events_modal';
import { LocaleString } from './locale_string';
import { ResendIterableModal } from './resend_iterable_modal';
import { ResendModal } from './resend_modal';
import { ViewEmailModal } from './view_email_modal';

function isResendable(notification: NotificationFragment) {
  return notification.provider !== Notification__ProviderEnum.Iterable;
}

function isScheduled(notification: NotificationFragment) {
  return notification.deliveries.some(({ state }) => state === NotificationDelivery__State.Queued);
}

function isIterable(notification: NotificationFragment) {
  return notification.provider === Notification__ProviderEnum.Iterable;
}

const NotificationsPanel: React.FC<{ accountId: string }> = ({ accountId }) => {
  const [page, setPage] = useState(1);
  const [resendId, setResendId] = useState<string | null>(null);
  const [showOnboardingIterableModal, setShowOnboardingIterableModal] = useState(false);
  const [showMovingIterableModal, setShowMovingIterableModal] = useState(false);
  const [viewedEventsNotification, setViewedEventsNotification] = useState<NotificationFragment | null>(null);
  const [viewedIterableNotification, setViewedIterableNotification] = useState<NotificationFragment | null>(null);

  const { data, loading } = usePaginatedNotificationListQuery({
    client,
    variables: { accountId, page },
  });

  const { pagination, results: notifications = [] } = data?.paginated || {};

  const { data: ordersData, loading: ordersLoading } = useAccountOrdersTypeAndSubtypeQuery({
    client,
    variables: { accountID: accountId.toString() },
  });

  const move = (ordersData?.orders || []).find((order) => order.type === OrderTypeEnum.Move);
  const onboarding = (ordersData?.orders || []).find((order) => order.subtype === OrderSubtypeEnum.Onboarding);

  if (loading || ordersLoading) {
    return (
      <div className="panel">
        <Spinner />
      </div>
    );
  }

  return (
    <>
      {resendId !== null && <ResendModal notificationId={resendId} onClose={() => setResendId(null)} />}
      {showOnboardingIterableModal && onboarding && (
        <ResendIterableModal
          accountId={accountId}
          kind="onboarding_confirmation"
          onClose={() => setShowOnboardingIterableModal(false)}
        />
      )}
      {showMovingIterableModal && move && (
        <ResendIterableModal
          accountId={accountId}
          kind="move_reserved"
          onClose={() => setShowMovingIterableModal(false)}
        />
      )}
      {viewedEventsNotification !== null && (
        <EmailEventsModal
          deliveries={viewedEventsNotification.deliveries}
          onClose={() => setViewedEventsNotification(null)}
        />
      )}
      {viewedIterableNotification !== null && (
        <ViewEmailModal notification={viewedIterableNotification} onClose={() => setViewedIterableNotification(null)} />
      )}
      <Panel>
        <Panel.Header>
          <Panel.Title>Notifications</Panel.Title>
        </Panel.Header>
        <Panel.Body>
          <p>
            {onboarding && (
              <Button kind="primary" onClick={() => setShowOnboardingIterableModal(true)}>
                Resend Confirmation Email
              </Button>
            )}
          </p>
          <p>
            {move && (
              <Button kind="primary" onClick={() => setShowMovingIterableModal(true)}>
                Resend Move Reserved Email
              </Button>
            )}
          </p>
          <Table id="notifications-table" className="notifications-table table table-striped">
            <tbody>
              {notifications.map((notification) => (
                <tr key={notification.id}>
                  <td className="text-left">
                    <strong>{notification.kind} </strong>
                    <span>notification</span>
                    {notification.objectName && (
                      <span>
                        <span> for </span>
                        <strong>{notification.objectName}</strong>
                      </span>
                    )}
                    <ul>
                      {notification.deliveries.map((delivery, i, a) => (
                        <li key={delivery.id}>
                          <strong>{delivery.state.toLowerCase()}</strong>
                          {' on '}
                          <LocaleString
                            date={delivery.sent || delivery.scheduled || delivery.updatedAt || delivery.createdAt}
                            format={DateTime.DATETIME_MED_WITH_SECONDS}
                          />{' '}
                          {delivery.triggerer && (
                            <span>
                              ({i === a.length - 1 ? 'triggered by' : 'resent by'} {delivery.triggerer})
                            </span>
                          )}
                        </li>
                      ))}
                    </ul>
                  </td>
                  <td className="text-right">
                    <Button.Group>
                      {isIterable(notification) && (
                        <Button kind="primary" onClick={() => setViewedIterableNotification(notification)}>
                          View Email
                        </Button>
                      )}
                      {isResendable(notification) && (
                        <Button
                          kind="primary"
                          onClick={() => setResendId(notification.id)}
                          disabled={isScheduled(notification)}
                        >
                          {isScheduled(notification) ? 'Scheduled' : 'Resend'}
                        </Button>
                      )}
                      <Button
                        kind="default"
                        data-test-id="notification-event-modal-toggle"
                        onClick={() => setViewedEventsNotification(notification)}
                      >
                        View Events
                      </Button>
                    </Button.Group>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Pagination pagination={pagination!} onPage={setPage} />
        </Panel.Body>
      </Panel>
    </>
  );
};

export { NotificationsPanel };

import React from 'react';

import { client } from '@admin/libraries/apollo';
import { useAccountBookingCancellationDisclaimerQuery } from '@admin/schema';
import { ORDER_SERVICE_TYPE_MAP, ORDER_SUBTYPE_MAP, ORDER_TYPE_MAP } from '@admin/utils/order_type_map';
import { Checkbox } from '@shared/components/bootstrap';

export const Cancellation: React.FC<{
  accountID: string;
  orderType: keyof typeof ORDER_TYPE_MAP;
  orderSubtype?: keyof typeof ORDER_SUBTYPE_MAP;
  serviceType?: keyof typeof ORDER_SERVICE_TYPE_MAP;
  checked?: boolean;
  setDisclaimer(checked: boolean): void;
}> = ({ accountID, orderType, orderSubtype, serviceType, checked = false, setDisclaimer }) => {
  const subtypeInput = orderSubtype ? ORDER_SUBTYPE_MAP[orderSubtype] : ORDER_SUBTYPE_MAP.subsequent;
  const serviceTypeEnum = serviceType ? ORDER_SERVICE_TYPE_MAP[serviceType] : undefined;
  const { data } = useAccountBookingCancellationDisclaimerQuery({
    client,
    variables: {
      accountID,
      orderType: ORDER_TYPE_MAP[orderType],
      orderSubtype: subtypeInput,
      orderServiceType: serviceTypeEnum,
    },
    skip: !orderSubtype,
  });

  const disclaimer = data?.account?.disclaimer;

  return (
    <div>
      {disclaimer && (
        <Checkbox>
          <input
            id="disclaimer"
            type="checkbox"
            checked={checked}
            onChange={(event) => setDisclaimer(event.target.checked)}
          />
          {disclaimer}
        </Checkbox>
      )}
    </div>
  );
};

import React, { useState } from 'react';
import styled from '@emotion/styled';

import { Form, Formik, FormikErrors } from 'formik';
import {
  Status,
  useContractorPasswordResetRequestApproveMutation,
  useContractorPasswordResetRequestQuery,
} from '@admin/schema';
import { client } from '@admin/libraries/apollo';
import { Spacer } from '@shared/components/helpers/spacer';
import { Button, Panel } from '@shared/components/bootstrap';
import { FormikInputFormGroup } from '@shared/components/fields/formik/formik_input_form_group';
import { Spinner } from '../spinner';

const ErrorMessage = styled.p`
  color: red;
  text-align: right;
`;

type FormValues = { verificationCode: string };
const INITIAL_VALUES = {
  verificationCode: '',
};

export const ContractorPasswordResetRequestApproveForm: React.FC<{ mfaRequestID: string }> = ({ mfaRequestID }) => {
  const { data: requestData, loading: requestDataLoading } = useContractorPasswordResetRequestQuery({
    client,
    variables: {
      id: mfaRequestID,
    },
    fetchPolicy: 'network-only',
  });

  const [submit, { loading: approvalSubmissionInProgress }] = useContractorPasswordResetRequestApproveMutation({
    client,
  });
  const [error, setError] = useState<string | undefined>();
  const [approved, setApproved] = useState<boolean>(false);

  const validate = (formData: FormValues) => {
    const errors: FormikErrors<FormValues> = {};

    if (!formData.verificationCode || formData.verificationCode === '') {
      errors.verificationCode = 'Please enter the verification code provided by the contractor';
    }

    return errors;
  };

  const onSubmit = async ({ verificationCode }: FormValues) => {
    const result = (await submit({ variables: { input: { mfaRequestID, verificationCode } } })).data?.result;

    if (result?.status === Status.Ok) {
      setError(undefined);
      setApproved(true);
    } else {
      setApproved(false);
      setError(result?.error ?? 'An unexpected error occurred. Please try again');
    }
  };

  if (requestDataLoading || !requestData) {
    return <Spinner />;
  }

  return approved ? (
    <Panel>
      <Panel.Header>
        <Panel.Title>{requestData?.result?.user.name} Password Reset Request</Panel.Title>
      </Panel.Header>
      <Panel.Body>
        {requestData?.result?.user.name} ({requestData?.result?.user.email}) has had their password reset request
        approved. They can now log in using their new password.
      </Panel.Body>
    </Panel>
  ) : (
    <Formik<FormValues>
      initialValues={INITIAL_VALUES}
      onSubmit={onSubmit}
      validate={validate}
      validateOnChange={false}
      validateOnBlur={false}
    >
      <Form>
        <Panel>
          <Panel.Header>
            <Panel.Title>{requestData?.result?.user.name} Password Reset Request</Panel.Title>
          </Panel.Header>
          <Panel.Body>
            <p>
              {requestData?.result?.user.name} ({requestData?.result?.user.email}) has submitted a password reset
              request. Reach out to them to confirm that they are the one that submitted the request. If yes, enter the
              verification code that they were provided to approve the request.
            </p>
            <Spacer height="16px" />
            <FormikInputFormGroup id="verificationCode" label="Verification Code" name="verificationCode" />
            {error && <ErrorMessage>{error}</ErrorMessage>}
          </Panel.Body>
          <Panel.Footer align="right">
            <Button
              loading={approvalSubmissionInProgress}
              disabled={approvalSubmissionInProgress}
              kind="primary"
              type="submit"
            >
              Approve
            </Button>
          </Panel.Footer>
        </Panel>
      </Form>
    </Formik>
  );
};

import template from '@admin/angular/templates/order_subtype_selector.html';

const app = angular.module('app');

app.component('orderSubtypeSelector', {
  template,
  bindings: {
    order: '<',
    account: '<',
    cancelable: '<', // Proxy if all items are selected.
  },
  controller: [
    'OrderClassifier',
    function orderSubTypeSelectorController(OrderClassifier) {
      const reset = () => {
        this.classification = OrderClassifier.get({
          account_id: this.account.id,
          order_id: this.order.id,
          type: this.order.type,
          subtype: this.order.subtype,
          cancelable: this.cancelable,
        });

        this.classification.$promise.then(() => {
          this.order.cancel_account = this.classification.type === 'return' && this.classification.subtype === 'final';
        });
      };

      this.$onInit = () => {
        if (!this.order.cancel_account && !!this.order.account_cancel_intent) {
          delete this.order.account_cancel_intent.reason;
        }
        reset();
      };

      this.$onChanges = () => {
        if (this.classification) {
          reset();
        }
      };

      this.setAccountCancelIntentReason = (reason) => {
        if (!this.order.account_cancel_intent) {
          this.order.account_cancel_intent = {};
        }
        this.order.account_cancel_intent.reason = reason;
      };
    },
  ],
});

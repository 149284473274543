import React from 'react';

import { PackageSetEntryFragment } from '@admin/schema';
import { FeesTotal } from './fees_total';
import { PackageSetEntry } from './package_set_entry';

export const PackageSetEntries: React.FC<{
  entries: PackageSetEntryFragment[];
}> = ({ entries }) => {
  const totalAmount = entries.map((entry) => entry.amount || 0).reduce((a, b) => a + b, 0);
  return (
    <div className="form-group" hidden={entries.length === 0}>
      {entries.map((entry) => (
        <PackageSetEntry amount={entry.amount ?? 0} packageSetEntry={entry} key={entry.id} />
      ))}
      <FeesTotal totalAmount={totalAmount} />
    </div>
  );
};

(function () {
  angular.module('app').service('SavedService', [
    'ConfirmationService',

    function (ConfirmationService) {
      const TITLE = 'Awesome!';
      const DESCRIPTION = 'Everything worked as expected!.';
      const TEMPLATE = '/partials/dialogs/_default.html';

      function display(title, description) {
        return ConfirmationService.confirm({
          title: title,
          description: description,
          template: TEMPLATE,
        });
      }

      this.handle = function (options) {
        const title = (options ? options.title : null) || TITLE;
        const description = (options ? options.description : null) || DESCRIPTION;
        return display(title, description);
      };
    },
  ]);
})();

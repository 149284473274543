(function () {
  const app = angular.module('app');

  const ATTRIBUTES = ['id', 'quantity', 'selected', 'room_category_id'];

  app.service('RoomSelectionsNestedAttributesService', [
    'NestedAttributesService',
    function (NestedAttributesService) {
      this.convert = function (selections) {
        return NestedAttributesService.convert(selections, function (selection) {
          if ((selection.quantity && selection.quantity > 0) || selection.selected) {
            return _.pick(selection, ATTRIBUTES);
          }
        });
      };
    },
  ]);
})();

import { List } from '@admin/components/rate_adjustment/schedulings/list';
import { usePusher } from '@admin/hooks';
import styled from '@emotion/styled';
import React, { useState } from 'react';

import { Panel } from '@admin/components/helpers/panel';
import { Spinner } from '@admin/components/spinner';
import { Alert, Button } from '@shared/components/bootstrap';
import { Manager } from '@shared/components/files';
import { Spacer } from '@shared/components/helpers';

import { client } from '@admin/libraries/apollo';
import {
  Status,
  useConfirmRateAdjustmentUploadMutation,
  usePreviewRateAdjustmentUploadMutation,
  RateAdjustment__Draft,
  RateAdjustment__Upload__Product,
} from '@admin/schema';
import { Selector } from './selector';

import { Drafts } from './drafts';

import { Context } from './context';

const TableWrapper = styled.div`
  max-height: 374px;
  overflow-y: scroll;
`;

export const RateAdjustmentUploaderForm: React.FC<{ product: RateAdjustment__Upload__Product }> = ({ product }) => {
  const [file, setFile] = useState<File | undefined>(undefined);
  const [confirmSuccess, setConfirmSuccess] = useState<boolean>(false);
  const [previewError, setPreviewError] = useState<string | undefined>(undefined);
  const [confirmError, setConfirmError] = useState<string | undefined>(undefined);
  const [uploadID, setUploadID] = useState<string | undefined>(undefined);
  const [previewRateAdjustments, setPreviewRateAdjustments] = useState<RateAdjustment__Draft[] | undefined>(undefined);
  const [pusherLoading, setPusherLoading] = useState<boolean>(false);

  usePusher<{ error?: string }>(uploadID && `rate-adjustment-upload-${uploadID}`, 'status', ({ error }) => {
    if (error) {
      setConfirmError(`There was a problem saving this: ${error}`);
      setConfirmSuccess(false);
    } else {
      setConfirmError(undefined);
      setConfirmSuccess(true);
    }
    setUploadID(undefined);
    setPusherLoading(false);
  });

  const cancel = () => {
    setFile(undefined);
  };

  const [previewUpload, { loading: previewLoading }] = usePreviewRateAdjustmentUploadMutation({ client });
  const [confirmUpload, { loading: confirmLoading }] = useConfirmRateAdjustmentUploadMutation({
    client,
    onCompleted: cancel,
  });

  const preview = async (previewFile: File, signedID: string) => {
    setPreviewError(undefined);
    setConfirmError(undefined);
    setConfirmSuccess(false);
    setPreviewRateAdjustments(undefined);
    setUploadID(undefined);
    const response = await previewUpload({
      variables: { input: { spreadsheetID: signedID, product } },
    });
    if (response && response.data) {
      if (response.data.previewRateAdjustmentUpload.status === Status.Unprocessable) {
        const errorMessage = response.data.previewRateAdjustmentUpload.error;
        setPreviewError(`There was a problem previewing this: ${errorMessage}`);
        return;
      }
      const data = response.data.previewRateAdjustmentUpload;
      setPreviewRateAdjustments(data.drafts ?? undefined);
      setUploadID(data.upload?.id);
      setFile(previewFile);
    }
  };

  const save = async () => {
    if (!uploadID) {
      return;
    }
    setPreviewRateAdjustments(undefined);
    setPreviewError(undefined);
    await confirmUpload({ variables: { id: uploadID } });
    setPusherLoading(true);
  };

  const uploadContextValue = {
    file,
    loading: previewLoading || confirmLoading || pusherLoading,
  };

  return (
    <Context.Provider value={uploadContextValue}>
      <Panel>
        <Panel.Header>
          <Panel.Title>Upload Rate Adjustments</Panel.Title>
        </Panel.Header>
        <Panel.Body>
          <Manager onSave={preview} children={({ uploads }) => <Selector uploads={uploads} product={product} />} />
          {confirmSuccess && <Alert style="info">Upload successful!</Alert>}
          <TableWrapper>
            {previewRateAdjustments && <Drafts drafts={previewRateAdjustments} product={product} />}
          </TableWrapper>
          {(previewLoading || confirmLoading || pusherLoading) && <Spinner />}
          {(previewError || confirmError) && <Alert style="danger">{previewError || confirmError}</Alert>}
        </Panel.Body>
        <Panel.Footer align="right">
          <Button kind="default" onClick={cancel} disabled={!file || uploadContextValue.loading}>
            Cancel
          </Button>
          <Spacer width="8px" />
          <Button kind="primary" onClick={save} disabled={!file || !uploadID || confirmLoading || pusherLoading}>
            Save
          </Button>
        </Panel.Footer>
      </Panel>
      {/* TODO: Add rate adjustment list/filters for door-to-door */}
      {product === RateAdjustment__Upload__Product.SelfStorage && (
        <Panel>
          <List product={product} />
        </Panel>
      )}
    </Context.Provider>
  );
};

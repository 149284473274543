const app = angular.module('app');

app.controller('AccountsShowController', [
  '$rootScope',
  '$scope',
  '$stateParams',
  '$anchorScroll',
  'Account',
  'Page',
  'Stripe',
  'ConfirmationService',
  'account',
  'AccountService',
  'ScrollService',
  function (
    $rootScope,
    $scope,
    $stateParams,
    $anchorScroll,
    Account,
    Page,
    Stripe,
    ConfirmationService,
    account,
    AccountService,
    ScrollService,
  ) {
    $scope.tab = 'Home';

    const refreshAccount = function () {
      $rootScope.$broadcast('account:refresh');
      $scope.account = Account.get({ id: $scope.id });
      $scope.stripe = Stripe.get({ account_id: $scope.id });
      return true;
    };

    $scope.id = $stateParams.id;
    $scope.stripe = Stripe.get({ account_id: $scope.id });
    $scope.account = account;
    $scope.account.subscriptions = _.sortBy(
      $scope.account.subscriptions,
      (sub) => parseInt(sub.pricing.amount, 10),
      0,
    ).reverse();
    $scope.primaryCustomer = AccountService.getPrimaryCustomer($scope.account.customers);

    $scope.protectionPlans = $scope.account.protectionSubscriptions();
    $scope.storagePlans = $scope.account.storageSubscriptions();

    $scope.inlineForms = {
      chargesNew: {
        hidden: true,
      },
      creditsAdd: {
        hidden: true,
      },
      billingSourceAdd: {
        hidden: true,
      },
    };

    Page.reset({ title: `Acct: ${$scope.account.customer.name}` });

    // eslint-disable-next-line no-shadow
    $scope.unsuspendAccount = function (account) {
      ConfirmationService.confirm({
        title: 'Activate Account?',
        description: `Are you sure you want to activate account ${account.number}?`,
      }).then(() => {
        Account.unsuspend(
          {
            id: account.id,
          },
          () => {
            refreshAccount();
            $rootScope.$broadcast('message:display', {
              type: 'info',
              body: 'Account Successfully Activated!',
            });
          },
          () => {
            $rootScope.$broadcast('message:display', {
              type: 'danger',
              body: 'We were unable to activate this account. Please contact support.',
            });
          },
        );
      });
    };

    $scope.scrollTo = function (anchor) {
      $anchorScroll(anchor);
    };

    $scope.showForm = function (formKey, params) {
      if (angular.isDefined(params)) {
        $scope.inlineForms[formKey].params = params;
      } else {
        $scope.inlineForms[formKey].params = {};
      }
      $scope.inlineForms[formKey].hidden = false;
    };

    $scope.resetForm = function (formKey) {
      $scope.inlineForms[formKey].hidden = true;
    };

    $scope.$on('inlineform:hide', (event, data) => {
      $scope.resetForm(data.form);
    });

    $scope.$on('inlineform:saved', (event, data) => {
      $scope.resetForm(data.form);
      if (data.form === 'chargesNew' || data.form === 'creditsAdd') {
        refreshAccount();
        document.dispatchEvent(new CustomEvent('notes.refresh'));
      }
    });

    $scope.onCanceled = () => {
      refreshAccount();
      $rootScope.$broadcast('message:display', {
        type: 'info',
        body: 'Account Successfully Canceled!',
      });
    };

    $scope.onSuspended = () => {
      refreshAccount();
      $rootScope.$broadcast('message:display', {
        type: 'info',
        body: 'Account Successfully Suspended!',
      });
    };

    $scope.onBanUpdated = (banned) => {
      $rootScope.$broadcast('message:display', {
        type: 'info',
        body: `Account Successfully ${banned ? 'Enabled' : 'Disabled'}!`,
      });
    };

    this.$onInit = () => {
      if ($stateParams.tab) {
        $scope.tab = $stateParams.tab;
      }

      ScrollService.scroll();
    };
  },
]);

import React from 'react';
import styled from '@emotion/styled';

import { client } from '@admin/libraries/apollo';
import { FacilityInput__Kind, useFacilityDetailsQuery } from '@admin/schema';

const Hours = styled.div`
  margin-top: 4px;
`;

export const AddressDetails: React.FC<{
  facilityID: string;
  facilityType: string;
}> = ({ facilityID, facilityType }) => {
  const { data, loading } = useFacilityDetailsQuery({
    client,
    variables: {
      facility: { id: facilityID, type: FacilityInput__Kind[facilityType as keyof typeof FacilityInput__Kind] },
    },
    skip: !(facilityType in FacilityInput__Kind),
  });

  const facility = data?.facility;

  if (loading || !facility || facility.__typename !== 'Warehouse') return null;

  return (
    <div>
      <hr />
      <strong>Facility Access Hours: </strong>
      <div>
        {facility.accessHours.map((hours, index) => (
          <div key={index}>
            <Hours>{hours}</Hours>
          </div>
        ))}
      </div>
    </div>
  );
};

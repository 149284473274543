(function () {
  angular.module('app').filter('resize', function () {
    return function (image, w, h, fit, bg) {
      if (!image || image.state !== 'uploaded') {
        return '/missing.svg';
      }

      if (!bg) {
        bg = 'FFF';
      }
      if (!fit) {
        fit = 'fill';
      }

      const params = { w: w, h: h, fit: fit, bg: bg };

      const query = _.map(params, function (value, key) {
        return key + '=' + value;
      }).join('&');

      return image.source + '?' + query;
    };
  });
})();

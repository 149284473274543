import { DateTime, Interval, Duration } from 'luxon';
import { zip, partition } from 'lodash';

import { Maybe } from '@admin/schema';
import { IOrderEvent } from '@admin/types/order_event';
import { assess, poorAssessmentStyle } from './utils';

const EXTRA_DURATION_LIMIT = 30;
export const DURATION_EVENTS = ['navigated', 'entered', 'parked', 'signed_approval', 'ended', 'exited', 'canceled'];
const TERMINAL_EVENTS = ['exited', 'canceled'];

export type DurationHeader =
  | 'Drive Duration'
  | 'Park Duration'
  | 'Prep Duration'
  | 'Order Duration'
  | 'Depart Duration';

export const DURATION_HEADERS: DurationHeader[] = [
  'Drive Duration',
  'Park Duration',
  'Prep Duration',
  'Order Duration',
  'Depart Duration',
];
export const DURATION_HEADER_EVENTS: { [key in DurationHeader]: string } = {
  'Drive Duration': 'Drive',
  'Park Duration': 'Park',
  'Prep Duration': 'Prep',
  'Order Duration': 'Order',
  'Depart Duration': 'Depart',
};

type Segment = 'Drive' | 'Park' | 'Prep' | 'Order' | 'Depart';
type SegmentInterval = { [key in Segment]?: Interval };
export const ORDERED_SEGMENTS: Segment[] = ['Drive', 'Park', 'Prep', 'Order', 'Depart'];

const eventNameMap: { [key: string]: Segment } = {
  navigated: 'Drive',
  entered: 'Park',
  parked: 'Prep',
  signed_approval: 'Order',
  ended: 'Depart',
};

const isTerminalEvent = (eventName: string) => TERMINAL_EVENTS.includes(eventName);

export const getSegmentIntervals = (events: IOrderEvent[], next?: DateTime) =>
  events.reduce<SegmentInterval>((acc, event, index) => {
    const { eventName, timestamp } = event;
    if (isTerminalEvent(eventName)) {
      return acc;
    }

    const isLastEvent = index === events.length - 1;
    const segmentName = eventNameMap[eventName];

    const start = DateTime.fromISO(timestamp);
    const end = isLastEvent ? next || DateTime.local() : DateTime.fromISO(events[index + 1].timestamp);

    return { ...acc, [segmentName]: Interval.fromDateTimes(start, end) };
  }, {});

export const getIntervals = (events: IOrderEvent[], next?: DateTime) => {
  const segmentIntervals = getSegmentIntervals(events, next);

  return ORDERED_SEGMENTS.map((segmentName) => segmentIntervals[segmentName] || null);
};

export const getBreakIntervals = (events: IOrderEvent[], next?: DateTime) => {
  const [startEvents, endEvents] = partition(events, ({ eventName }) => eventName.includes('start'));
  return zip(startEvents, endEvents).map(([startEvent, endEvent]) => {
    const endTimestamp = endEvent?.timestamp && DateTime.fromISO(endEvent.timestamp);

    return Interval.fromDateTimes(DateTime.fromISO(startEvent!.timestamp), endTimestamp || next || DateTime.local());
  });
};

export const overlap = (rangeA?: Maybe<Interval>, rangeB?: Interval) =>
  (rangeB && rangeA?.intersection(rangeB)?.toDuration()) || Duration.fromObject({ minutes: 0 });

export const assessDuration = assess((value, source) => {
  if (value >= source + EXTRA_DURATION_LIMIT) {
    return poorAssessmentStyle;
  }
  return null;
});

export const durationValueInMinutes = (duration: Maybe<Duration>) => duration?.shiftTo('minute').minutes;

export const durationCopy = (duration: Maybe<Duration>) => duration?.toFormat('h:mm') ?? 'N/A';

export const overlapCopy = (duration: Maybe<Duration>) => duration?.toFormat('h:mm');

type DurationCopy = [string | undefined, string | null | undefined];
export const combine = ([eventDuration, breakOverlap]: DurationCopy) => {
  if (breakOverlap) {
    return `${eventDuration} (${breakOverlap})`;
  }
  return eventDuration;
};

import React, { useContext, useEffect } from 'react';
import styled from '@emotion/styled';
import { COLORS, UnstyledButton } from '@clutter/clean';
import { DateTime } from 'luxon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useDrop } from 'react-dnd';

import { client } from '@admin/libraries/apollo';
import { ClutterGeoEnum, useNightTransportPlannerDriversQuery, UserFragment } from '@admin/schema';
import { Alert } from '@shared/components/bootstrap';

import { Context } from './context';
import { Mode } from './filters';
import { NightTransportRoute, NightTransportTask } from './types';
import { Task } from './task';
import { InlineInputFormGroup } from './inline_input_form_group';
import { InlineSelectFormGroup } from './inline_select_form_group';

const RouteForm = styled.div`
  padding: 15px 15px 0;
  display: flex;
  flex-direction: column;
`;

const RouteItem = styled.div`
  border-right: thin solid ${COLORS.grayBorder};
  width: 300px;
  flex-grow: 0;
  flex-shrink: 0;
`;

const TaskList = styled.div`
  gap: 5px;
  padding: 0 15px 15px;
  display: flex;
  flex-direction: column;
`;

const DeleteButton = styled(UnstyledButton)<{ canDelete: boolean }>`
  display: flex;
  padding: 15px 15px 0 0;
  float: right;
  color: ${({ canDelete }) => (canDelete ? COLORS.toucan : COLORS.grayBorder)};
`;

type NightTransportDriverFragment = UserFragment & { tz: string };

const ROUTE_NIGHT_DRIVER_TITLE = 'Night Driver';

const DeleteRoute: React.FC<{ routeUUID: string; canDelete: boolean }> = ({ routeUUID, canDelete }) => {
  const { mode, onDeleteRoute } = useContext(Context);

  return (
    <DeleteButton
      canDelete={mode === Mode.Editing && canDelete}
      disabled={!canDelete}
      onClick={() => onDeleteRoute(routeUUID)}
    >
      <FontAwesomeIcon icon={faTimes} />
    </DeleteButton>
  );
};

const RouteArrivalFormGroup: React.FC<{
  route: NightTransportRoute;
  disabled: boolean;
  onChangeArrival(_?: string): void;
}> = ({ route, disabled, onChangeArrival }) => {
  const { date, mode, tz } = useContext(Context);
  const arrivalTime = DateTime.fromISO(route.arrival ?? '', { zone: tz }).toFormat('HH:mm');
  const arrivalTimeDisplay = DateTime.fromISO(route.arrival ?? '', { zone: tz }).toFormat('h:mm a ZZZZ');

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const time = event.target.value;
    const changedArrival = DateTime.fromISO(`${date!}T${time}:00`, { zone: tz }).toISO();
    onChangeArrival(changedArrival);
  };

  if (mode === Mode.Viewing) return <p>{arrivalTimeDisplay} Arrival</p>;

  return (
    <InlineInputFormGroup
      disabled={disabled}
      label="Arrival"
      name={`route_${route.uuid}_arrival`}
      value={arrivalTime}
      onChange={onChange}
      type="time"
    />
  );
};

const RouteDriverFormGroup: React.FC<{
  route: NightTransportRoute;
  onChangeDriver(_?: NightTransportDriverFragment): void;
}> = ({ route, onChangeDriver }) => {
  const { mode, geo, loading } = useContext(Context);
  const { data } = useNightTransportPlannerDriversQuery({
    client,
    variables: {
      filters: {
        geo: ClutterGeoEnum[geo! as keyof typeof ClutterGeoEnum],
        jobTitle: ROUTE_NIGHT_DRIVER_TITLE,
      },
    },
    skip: !geo,
  });

  const onChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const driverID = event.target.value;
    const driver = driverID ? data?.drivers?.find(({ id }) => id === driverID) : undefined;

    onChangeDriver(driver);
  };

  if (mode === Mode.Viewing) return <p>Driver: {route.driver?.name}</p>;

  return (
    <InlineSelectFormGroup
      value={route.driver?.id ?? ''}
      label="Driver"
      onChange={onChange}
      name={`route_${route.uuid}_driver_id`}
      disabled={!data || loading}
    >
      <option value="" disabled={!!route.driver}>
        - Driver -
      </option>
      {data?.drivers.map(({ id, name }) => (
        <option value={id} key={id}>
          {name}
        </option>
      ))}
    </InlineSelectFormGroup>
  );
};

function getOrigin(task: NightTransportTask, tasks: NightTransportTask[]) {
  const { action } = task;

  switch (action.__typename) {
    case 'NightTransport__Carpool':
    case 'NightTransport__Resupply':
      return tasks.find(({ action: { uuid } }) => uuid === action.driveUUID)?.action.origin;
    case 'NightTransport__Drive':
      return action.origin;
  }
}

export const Route: React.FC<{
  route: NightTransportRoute;
  tasks: readonly NightTransportTask[];
}> = ({ route, tasks }) => {
  const { tz, errors, onAddTask, onChangeRoute, onChangeTaskRoute, tasks: globalTasks } = useContext(Context);
  const [_, drop] = useDrop(
    () => ({
      accept: 'Task',
      drop: (item: NightTransportTask, monitor) => {
        const onRoute = monitor.isOver({ shallow: true });
        if (onRoute) {
          const task = { ...item, routeUUID: route.uuid, position: tasks.length + 1 };
          if (item.position === 0) {
            onAddTask(task);
          } else {
            const oldRouteUUID = item.routeUUID;
            onChangeTaskRoute(task, oldRouteUUID);
          }
        }
      },
    }),
    [tasks],
  );

  const onChangeDriver = (driver: NightTransportDriverFragment) => {
    onChangeRoute({ ...route, driver });
  };

  const onChangeArrival = (arrival: string) => {
    onChangeRoute({ ...route, arrival });
  };

  useEffect(() => {
    const sortedTasks = [...tasks].sort(({ position: positionA }, { position: positionB }) => positionA - positionB);
    if (!sortedTasks[0]) {
      if (route.origin !== undefined) onChangeRoute({ ...route, origin: undefined });
      return;
    }

    const origin = getOrigin(sortedTasks[0], globalTasks);

    if (origin !== route.origin) {
      onChangeRoute({ ...route, origin: origin });
    }
  }, [tasks, route, onChangeRoute, globalTasks]);

  return (
    <RouteItem ref={drop}>
      <DeleteRoute routeUUID={route.uuid} canDelete={tasks.length === 0} />
      {errors[route.uuid] && <Alert style="danger">{errors[route.uuid]}</Alert>}
      <RouteForm>
        <RouteDriverFormGroup route={route} onChangeDriver={onChangeDriver} />
        <RouteArrivalFormGroup route={route} disabled={!tz} onChangeArrival={onChangeArrival} />
        <p>Starting Facility: {route.origin?.name}</p>
      </RouteForm>
      <TaskList>
        {[...tasks]
          .sort(({ position: positionA }, { position: positionB }) => positionA - positionB)
          .map((task, index) => (
            <Task key={task.uuid} task={task} index={index} />
          ))}
      </TaskList>
    </RouteItem>
  );
};

import * as React from 'react';

import { some } from 'lodash';
import { DateTime } from 'luxon';

import { IDispatchOrder } from '@admin/types';
import styled from '@emotion/styled';
import { OrderGanttRow } from './order_gantt_row';
import { useTimeZone } from '../context';
import { KNOWN_EVENT_NAMES } from './utils';

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
`;

const TERMINAL_EVENTS = ['exited', 'canceled', 'rescheduled'];
const LAST_ORDER_TERMINAL_EVENTS = ['clockout', 'rescheduled'];
const LAST_ORDER_EVENTS = ['depot_arrival', 'clockout'];

export const OrderGanttRows: React.FC<{
  orders: IDispatchOrder[];
  endTime?: DateTime;
}> = ({ orders, endTime }) => {
  const timeZone = useTimeZone();
  const childless = orders.filter(({ parentID }) => !parentID);

  const scheduledOrders = childless.filter(({ orderEventsExcludingNonLeadAssignments }) =>
    some(orderEventsExcludingNonLeadAssignments, (event) => KNOWN_EVENT_NAMES.includes(event.eventName)),
  );
  const lastDispatchOrder = scheduledOrders[scheduledOrders.length - 1];

  const expectingClockout = (isSimulated: boolean, order: IDispatchOrder) =>
    (isSimulated && some(order.simulatedOrderEvents, (event) => LAST_ORDER_EVENTS.includes(event.eventName))) ||
    (!isSimulated && order.id === lastDispatchOrder.id);

  return (
    <Container>
      {childless.map((order, index) => {
        const nextOrder = index + 1 < childless.length && childless[index + 1];
        const nextTimeStamp =
          nextOrder &&
          nextOrder.orderEventsExcludingNonLeadAssignments.length &&
          DateTime.fromISO(nextOrder.orderEventsExcludingNonLeadAssignments[0].timestamp).setZone(timeZone);

        const isTerminalOrderEvent = (isSimulated: boolean) => (eventName: string) => {
          if (expectingClockout(isSimulated, order)) {
            return LAST_ORDER_TERMINAL_EVENTS.includes(eventName);
          }

          return TERMINAL_EVENTS.includes(eventName);
        };

        return (
          <OrderGanttRow
            next={nextTimeStamp || endTime}
            key={order.id}
            order={order}
            isTerminalOrderEvent={isTerminalOrderEvent}
          />
        );
      })}
    </Container>
  );
};

import { createContext, useContext } from 'react';

export const TimeZoneContext = createContext<string | undefined>(undefined);
export const useTimeZone = () => {
  const timeZone = useContext(TimeZoneContext);
  if (timeZone === undefined) {
    throw new Error('useTimeZone must be used within a TimeZoneProvider');
  }
  return timeZone;
};

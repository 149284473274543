import { User__ExternalGroup } from '@admin/schema';

export const USER_EXTERNAL_GROUPS = [
  User__ExternalGroup.IronMountain,
  User__ExternalGroup.FreshPrints,
  User__ExternalGroup.Teleperformance,
  User__ExternalGroup.BlueCrew,
];

export const USER_EXTERNAL_GROUP_TO_NAME: Record<User__ExternalGroup, string> = {
  [User__ExternalGroup.IronMountain]: 'Iron Mountain',
  [User__ExternalGroup.FreshPrints]: 'Fresh Prints',
  [User__ExternalGroup.Teleperformance]: 'Teleperformance',
  [User__ExternalGroup.BlueCrew]: 'Blue Crew',
};

(function () {
  // unit: (mi|km)
  // period: (hour)
  angular.module('app').filter('velocity', function () {
    return function (velocity, unit, period, precision) {
      if (!precision) {
        precision = 0;
      }

      switch (unit) {
        case 'km':
          velocity /= 1000.0;
          break;
        case 'mi':
          velocity /= 1609.34;
          break;
      }

      switch (period) {
        case 'hour':
          velocity *= 3600.0;
          break;
      }

      return _.round(velocity, precision) + ' ' + unit + '/' + period;
    };
  });
})();

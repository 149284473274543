import React, { useEffect, useState } from 'react';

import { SelectFormControl } from '@admin/components/fields';
import { Checkbox, ControlLabel, FormGroup, HelpBlock, Radio } from '@shared/components/bootstrap';

export enum BusinessUnit {
  Moving = 'Moving',
  SmartStorage = 'Smart Storage',
  SelfStorage = 'Self Storage',
  Enterprise = 'Enterprise',
  Marketplace = 'Marketplace',
  ClutterInternal = 'Clutter Internal',
}

export enum Area {
  Billing = 'Billing',
  Field = 'Field',
  Warehouse = 'Warehouse',
  Funnel = 'Funnel',
  AccountPortal = 'Account Portal',
  AdminTeal = 'Admin Teal',
  Dispatch = 'Dispatch',
  UserManagement = 'User Management',
  CustomerCommunications = 'Customer Communications',
  Coupons = 'Coupons',
  Shipping = 'Shipping',
}

const businessUnitOptions = Object.values(BusinessUnit);
const areaOptions = Object.values(Area);

interface ICreateTicketProps {
  priority?: number;
  description?: string;
  explanation?: string;
  notify: boolean;
  error?: Error;
  businessUnit?: string;
  area?: string;
  onPriority(value: number): void;
  onDescription(value: string): void;
  onNotify(value: boolean): void;
  onExplanation(value: string): void;
  onBusinessUnit(value: BusinessUnit): void;
  onArea(value: Area): void;
}

const PRIORITIES: Array<{ value: number; name: string; details: string }> = [
  {
    value: 0,
    name: 'Extremely Urgent (P0)',
    details: 'Addressed within 1 hour',
  },
  {
    value: 1,
    name: 'High Priority (P1)',
    details: 'Addressed by the end of the next day',
  },
  {
    value: 2,
    name: 'Medium Priority (P2)',
    details: 'Addressed in 3 days',
  },
  {
    value: 3,
    name: 'Low Priority (P3)',
    details: 'No specific deadline',
  },
];

const REQUEST_PLACEHOLDER =
  'Please describe what needs to fixed. Please include \
if applicable: account, order, items, pallets, or employee name \
that is experiencing issues.';

const EXPLANATION_PLACEHOLDER =
  'Please provide context for the request. Did the product not work as expected? \
Was there a process breakdown? Is a customer being affected by this issue?';

const EXTREME_PRIORITY_WARNING =
  'I understand this will immediately call an on duty engineer. \
If not extremely urgent, please use High Priority.';

export const TechSupportForm: React.FC<ICreateTicketProps> = ({
  area,
  businessUnit,
  description,
  error,
  explanation,
  notify,
  priority,
  onArea,
  onBusinessUnit,
  onDescription,
  onExplanation,
  onNotify,
  onPriority,
}) => {
  const [validationState, setValidationState] = useState<'error' | undefined>();

  useEffect(() => setValidationState(error ? 'error' : undefined), [error]);

  const isExtremePriority = priority === 0;

  return (
    <React.Fragment>
      <ControlLabel>
        <span>Select Priority: </span>
      </ControlLabel>
      <FormGroup has={validationState}>
        {PRIORITIES.map((priorityOption) => (
          <Radio key={priorityOption.value} has={priorityOption.value === 0 ? 'error' : undefined}>
            <input type="radio" required name="priority" onChange={() => onPriority(priorityOption.value)} />
            {priorityOption.name} - {priorityOption.details}
          </Radio>
        ))}
      </FormGroup>
      <FormGroup has={validationState}>
        <ControlLabel>Request:</ControlLabel>
        <textarea
          required
          placeholder={REQUEST_PLACEHOLDER}
          style={{ resize: 'vertical' }}
          className="form-control"
          onChange={(event) => onDescription(event.target.value)}
          value={description}
        />
      </FormGroup>
      <FormGroup has={validationState}>
        <ControlLabel>Context:</ControlLabel>
        <textarea
          required
          placeholder={EXPLANATION_PLACEHOLDER}
          style={{ resize: 'vertical' }}
          className="form-control"
          onChange={(event) => onExplanation(event.target.value)}
          value={explanation}
        />
      </FormGroup>
      <FormGroup has={validationState}>
        <ControlLabel>Business Unit:</ControlLabel>
        <SelectFormControl
          options={businessUnitOptions}
          onChange={(event) => onBusinessUnit(event as BusinessUnit)}
          value={businessUnit}
          prompt="Select a Business Unit"
          required={true}
        />
      </FormGroup>
      <FormGroup has={validationState}>
        <ControlLabel>Area:</ControlLabel>
        <SelectFormControl
          options={areaOptions}
          onChange={(event) => onArea(event as Area)}
          value={area}
          prompt="Select an Area"
          required={true}
        />
      </FormGroup>
      <FormGroup>
        <ControlLabel>
          <input type="checkbox" onChange={(event) => onNotify(event.target.checked)} checked={notify} /> Notify
          requestor and assignee on updates
        </ControlLabel>
      </FormGroup>
      <FormGroup>
        {isExtremePriority && (
          <Checkbox has="error">
            <input type="checkbox" required />
            {EXTREME_PRIORITY_WARNING}
          </Checkbox>
        )}
      </FormGroup>
      {error && error.message && <HelpBlock className="text-danger">{error.message}</HelpBlock>}
    </React.Fragment>
  );
};

(function () {
  const app = angular.module('app');

  app.controller('PlansIndexController', [
    '$scope',
    'Plan',
    function ($scope, Plan) {
      $scope.plans = Plan.query();
    },
  ]);

  app.controller('PlansShowController', [
    '$scope',
    '$stateParams',
    'Plan',
    'Pricing',
    function ($scope, $stateParams, Plan, Pricing) {
      $scope.plan = Plan.get({
        id: $stateParams.id,
      });
      $scope.pricings = Pricing.query({ plan_id: $stateParams.id });
    },
  ]);

  app.controller('NewPlansController', [
    '$rootScope',
    '$scope',
    '$state',
    'Plan',
    function ($rootScope, $scope, $state, Plan) {
      $scope.plan = new Plan();

      $scope.create = function () {
        $scope.plan.$save(
          function () {
            $state.go('plans');
          },
          function (error) {
            $rootScope.$broadcast('message:group:display', error.data);
          },
        );
      };
    },
  ]);
})();

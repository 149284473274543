import { isEmpty } from 'lodash';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';

import { IInventoryCaptureProps, InventoryCapture } from '@admin/components/orders/inventory_capture';
import { IPlanCalculatorInputProps, PlanCalculator } from '@admin/components/orders/plan_calculator';
import { estimatedMoverCount } from '@admin/utils/estimated_mover_count';
import { client } from '@admin/libraries/apollo';
import { IOrderInventory } from '@shared/types';

import { customPlan, dimensionalPlan } from '@admin/utils/subscribe';
import {
  PlanFragment,
  usePickupMoverCountLazyQuery,
  useStoragePlansQuery,
  useStorageSubscriptionsQuery,
} from '@admin/schema';
import { cuftForOrderInventory } from '@shared/utils/cuft_for_order_inventory';
import { filterInventoryForSave } from '@shared/utils/filter_inventory_for_save';
import { buildPickupInventoryInputCodegen } from '@shared/utils';

type Address = {
  id?: number | string;
  [key: string]: any;
};

const SUBSCRIPTIONS_TO_EXCLUDE = ['Item', 'Mattress', 'Garage', 'Closet', 'Studio', 'Apartment', '10x50'];

export const InventorySection: React.FC<
  IInventoryCaptureProps &
    IPlanCalculatorInputProps & {
      subtype?: 'onboarding' | 'subsequent' | 'final' | 'cancelation';
      address?: Address;
      onCuftCalculation(cuft: number): void;
      onMoverCountUpdate(movers: number): void;
      onPlanSuggestion?(plan: PlanFragment): void;
    }
> = ({
  accountID,
  orderID,
  onChange,
  savedInventory,
  subtype,
  address,
  onCuftCalculation,
  onPlanSuggestion,
  onMoverCountUpdate,
}) => {
  const [inventory, setInventory] = useState<IOrderInventory>({});
  const [cuft, setCuft] = useState<number>(0);
  const [estimatedMovers, setEstimatedMovers] = useState<number>(1);
  const [suggestedPlan, setSuggestedPlan] = useState<PlanFragment | undefined>(undefined);

  const { data: storageData } = useStorageSubscriptionsQuery({ client, variables: { accountID } });
  const inventoryMetrics = storageData?.inventoryMetrics;
  const storageSubscriptions = storageData?.storageSubscriptions || [];
  const utilization = inventoryMetrics?.cuftUsed ?? 0;

  const { data: planData } = useStoragePlansQuery({ client });
  const plans = useMemo(
    () => planData?.storagePlans.filter(({ name }) => !SUBSCRIPTIONS_TO_EXCLUDE.includes(name)) || [],
    [planData],
  );

  const [getPickupMoverCount] = usePickupMoverCountLazyQuery({
    client,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setEstimatedMovers(data.pickupMoverCount);
      onMoverCountUpdate(data.pickupMoverCount);
    },
  });

  useEffect(() => {
    if (!inventory || isEmpty(inventory)) {
      return;
    }
    const orderCuft = cuftForOrderInventory(filterInventoryForSave(inventory));
    setCuft(orderCuft);
    onCuftCalculation(orderCuft);
  }, [inventory]);

  useEffect(() => {
    const totalCuft = cuft + utilization;
    if (!plans || !plans.length || totalCuft === 0) {
      return;
    }
    const plan = dimensionalPlan(plans, totalCuft) || customPlan(plans, totalCuft);
    setSuggestedPlan(plan);

    if (onPlanSuggestion) {
      onPlanSuggestion({ ...plan });
    }
  }, [cuft, utilization, plans, onPlanSuggestion]);

  useEffect(() => {
    if (!plans || !plans.length || cuft === 0) {
      return;
    }
    const planForCuft = dimensionalPlan(plans, cuft) || customPlan(plans, cuft);
    const capitalizedSubtype = subtype && subtype.charAt(0).toUpperCase() + subtype.slice(1);

    const pricing = (storageSubscriptions[0] || {}).pricing || {};
    let plan = planForCuft;

    if (pricing.plan && pricing.plan.cuft > planForCuft.cuft) {
      plan = pricing.plan;
    }

    if (orderID) {
      const formattedInventory = buildPickupInventoryInputCodegen({ inventory });
      getPickupMoverCount({ variables: { orderID, inventory: formattedInventory } });
    } else {
      estimatedMoverCount({ inventory, plan, cuft, subtype: capitalizedSubtype, addressID: address?.id }).then(
        (moverCount: number) => {
          setEstimatedMovers(moverCount);
          onMoverCountUpdate(moverCount);
        },
      );
    }
  }, [cuft, inventory.requestedMovers, plans, address]);

  const handleOnChange = (updatedInventory: IOrderInventory) => {
    setInventory(updatedInventory);
    onChange(updatedInventory);
  };

  return (
    <>
      <InventoryCapture
        orderID={orderID}
        savedInventory={savedInventory}
        hideMoving={subtype === 'subsequent'}
        onChange={handleOnChange}
      />
      <hr />
      <div className="row">
        <div className="col-sm-6 col-sm-push-6">
          <PlanCalculator
            accountID={accountID}
            cuft={cuft}
            inventoryMetrics={inventoryMetrics || undefined}
            estimatedMovers={estimatedMovers}
            subscriptions={storageSubscriptions}
            suggestedPlan={suggestedPlan}
          />
        </div>
      </div>
    </>
  );
};

import React from 'react';
import { capitalize } from 'lodash';

import {
  useConfirmation,
  Dialog as ConfirmationDialog,
  Response as ConfirmationResponse,
} from '@admin/components/confirmation';
import { Label } from '@admin/components/helpers/label';
import { Panel } from '@admin/components/helpers/panel';
import { Roles } from '@admin/components/helpers/roles';
import { Spinner } from '@admin/components/spinner';
import {
  BillingAuthorizationHoldsQuery,
  Billing__Charge__Status,
  Billing__UnattemptedAuthorizationHoldStatus,
  useBillingAuthorizationHoldsQuery,
  useBillingAuthorizationHoldCancelMutation,
  useBillingAuthorizationHoldUncancelMutation,
} from '@admin/schema';
import { UserRole } from '@admin/types';
import { Button, Table } from '@shared/components/bootstrap';
import { Timestamp } from '@shared/components/helpers';
import { client } from '@admin/libraries/apollo';

type AuthorizationHold = {
  __typename?: 'Billing__AuthorizationHold' | undefined;
  id: string;
  charge: Charge;
};

type Charge = {
  __typename?: 'Billing__Charge' | undefined;
  id: string;
  amount: number;
  date: string;
  outcomeReason?: string | null | undefined;
  source: {
    __typename?: 'Billing__Source' | undefined;
    id: string;
    number: string;
  };
  status: Billing__Charge__Status;
};

const statusStyle = (status: Billing__Charge__Status | Billing__UnattemptedAuthorizationHoldStatus) => {
  switch (status) {
    case Billing__Charge__Status.Succeeded:
      return 'success';
    case Billing__Charge__Status.Failed:
      return 'warning';
    case Billing__UnattemptedAuthorizationHoldStatus.Pending:
      return 'info';
    case Billing__UnattemptedAuthorizationHoldStatus.Canceled:
      return 'warning';
  }
};

const statusText = (chargeStatus: Billing__Charge__Status, outcomeReason: string | null | undefined) =>
  chargeStatus === Billing__Charge__Status.Succeeded ? 'Succeeded' : titleize(outcomeReason || 'Failed');

function titleize(str: string) {
  return str
    .replace(/^[\s_]+|[\s_]+$/g, '') // Trim leading/trailing whitespace/underscores
    .replace(/[_\s]+/g, ' ') // Replace underscores with spaces
    .replace(/^[a-z]/, (firstWord: string) => firstWord.toUpperCase()); // Capitalize the first word
}

const Row: React.FC<{
  authorizationHold: AuthorizationHold;
}> = ({ authorizationHold }) => {
  const { id, charge } = authorizationHold;
  const { amount, date, outcomeReason, source, status } = charge;

  return (
    <tr>
      <td className="col-md-2" colSpan={2}>
        {id}
      </td>
      <td className="col-md-2" colSpan={2}>
        <Timestamp value={date} format={Timestamp.Format.DateTime} />
      </td>
      <td className="col-md-2" colSpan={2}>
        {amount}
      </td>
      <td className="col-md-2" colSpan={2}>
        {source.number}
      </td>
      <td className="col-md-2" colSpan={2}>
        <Label kind={statusStyle(status)}>{statusText(status, outcomeReason)}</Label>
      </td>
      <td className="col-md-2" colSpan={2} />
    </tr>
  );
};

const UnattemptedRow: React.FC<{
  unattemptedAuthorizationHold: BillingAuthorizationHoldsQuery['billingUnattemptedAuthorizationHold'];
}> = ({ unattemptedAuthorizationHold }) => {
  const { confirm, confirmation } = useConfirmation();
  const [cancel] = useBillingAuthorizationHoldCancelMutation({ client });
  const [uncancel] = useBillingAuthorizationHoldUncancelMutation({ client });

  const onCancel = async () => {
    const confirmed = await confirm({
      title: 'Cancel authorization check?',
      description: 'This customer will not have a temporary hold placed on their card.',
    });

    if (confirmed !== ConfirmationResponse.Confirm || !unattemptedAuthorizationHold) return;

    cancel({ variables: { orderID: unattemptedAuthorizationHold.orderID } });
  };

  const onUncancel = async () => {
    const confirmed = await confirm({
      title: 'Uncancel authorization check?',
      description: 'This customer will have a temporary hold placed on their card before their appointment.',
    });

    if (confirmed !== ConfirmationResponse.Confirm || !unattemptedAuthorizationHold) return;

    uncancel({ variables: { orderID: unattemptedAuthorizationHold.orderID } });
  };

  if (!unattemptedAuthorizationHold) {
    return null;
  }

  const { amount, date, orderID, source, status } = unattemptedAuthorizationHold;
  return (
    <>
      {confirmation && <ConfirmationDialog confirmation={confirmation} />}
      <tr>
        <td className="col-md-2" colSpan={2}>
          Order ID: {orderID}
        </td>
        <td className="col-md-2" colSpan={2}>
          <Timestamp value={date} format={Timestamp.Format.DateTime} />
        </td>
        <td className="col-md-2" colSpan={2}>
          {amount}
        </td>
        <td className="col-md-2" colSpan={2}>
          {source?.number}
        </td>
        <td className="col-md-2" colSpan={2}>
          <Label kind={statusStyle(status)}>{capitalize(status)}</Label>
        </td>
        <td className="col-md-2" colSpan={2}>
          <Roles show={[UserRole.Admin, UserRole.Teamlead]}>
            {status === Billing__UnattemptedAuthorizationHoldStatus.Pending && (
              <Button kind="danger" onClick={onCancel}>
                Cancel
              </Button>
            )}
            {status === Billing__UnattemptedAuthorizationHoldStatus.Canceled && (
              <Button kind="primary" onClick={onUncancel}>
                Uncancel
              </Button>
            )}
          </Roles>
        </td>
      </tr>
    </>
  );
};

const AuthorizationHoldsTable: React.FC<{
  orderID: string;
}> = ({ orderID }) => {
  const { data, loading } = useBillingAuthorizationHoldsQuery({
    client,
    variables: {
      orderID,
    },
  });

  if (loading || !data) {
    return <Spinner />;
  }

  return (
    <Table responsive striped>
      <thead>
        <tr>
          <th className="col-md-2" colSpan={2}>
            ID
          </th>
          <th className="col-md-2" colSpan={2}>
            Date
          </th>
          <th className="col-md-2" colSpan={2}>
            Amount
          </th>
          <th className="col-md-2" colSpan={2}>
            Card
          </th>
          <th className="col-md-2" colSpan={2}>
            Status
          </th>
          <th className="col-md-2" colSpan={2}>
            Cancel
          </th>
        </tr>
      </thead>
      <tbody>
        {data.billingAuthorizationHolds.map((authorizationHold) => (
          <Row key={authorizationHold.id} authorizationHold={authorizationHold} />
        ))}
        <UnattemptedRow unattemptedAuthorizationHold={data.billingUnattemptedAuthorizationHold} />
      </tbody>
    </Table>
  );
};

export const AuthorizationHoldsPanel: React.FC<{
  orderID: string;
}> = ({ orderID }) => (
  <Panel>
    <Panel.Header>
      <Panel.Title>Authorization Holds</Panel.Title>
    </Panel.Header>
    <Panel.Body>
      <AuthorizationHoldsTable orderID={orderID} />
    </Panel.Body>
  </Panel>
);

import { FormikInputFormGroup } from '@shared/components/fields/formik/formik_input_form_group';
import { FormikNativeDateTimeFormGroup } from '@admin/components/fields/formik/formik_native_date_time_form_group';
import { calculateDuration } from '@admin/components/shifts/utilities';
import { client } from '@admin/libraries/apollo';
import {
  AttestationEventFragment,
  AttestationEventResolveMutationVariables,
  Status,
  useAttestationEventResolveMutation,
  Workforce__Shift__ActivityInput,
} from '@admin/schema';
import { Box } from '@clutter/clean';

import { Alert, Button, Modal, Table, Radio, Text } from '@shared/components/bootstrap';
import { UUID } from '@shared/utils';
import { Workforce__WorkBreak } from '@admin/schema';
import { ErrorMessage, FieldArray, Form, Formik, useFormikContext } from 'formik';
import { keyBy, sortBy, zip } from 'lodash';
import { DateTime } from 'luxon';
import React, { useState } from 'react';

const ACTIONS = [
  { label: 'Approve changes', value: 'approve' },
  { label: 'Reject changes', value: 'reject' },
  { label: 'Edit merged changes', value: 'edit' },
] as const;

type WorkBreakDescriptor = Pick<Workforce__WorkBreak, 'ended' | 'started' | 'uuid'>;

type ClientWorkBreak = { uuid?: string; started: DateTime; ended: DateTime };

type Values = {
  shiftStartTime?: DateTime;
  workBreaks?: ClientWorkBreak[];
  note?: string;
};

/** Merge existing/requested edits with a preference for requested edits with the same UUID */
function mergeBreaks(existing: WorkBreakDescriptor[], adjusted: WorkBreakDescriptor[]) {
  const existingEditsByUUID = keyBy(
    adjusted.filter((b) => b.uuid),
    'uuid',
  );
  return adjusted.concat(existing.filter((e) => !existingEditsByUUID[e.uuid] && e.started && e.ended));
}

function buildInitialValues({
  correctedShiftStart,
  correctedWorkBreaks,
  shift: {
    started,
    user: { tz },
    workBreaks,
  },
}: AttestationEventFragment) {
  const parsedBreaks = mergeBreaks(workBreaks, correctedWorkBreaks ?? []).map((b) => ({
    uuid: b.uuid,
    started: DateTime.fromISO(b.started!, { zone: tz }),
    ended: DateTime.fromISO(b.ended!, { zone: tz }),
  }));
  return {
    shiftStartTime: DateTime.fromISO(correctedShiftStart ?? started.timestamp, { zone: tz }),
    workBreaks: sortBy(parsedBreaks, 'started'),
  };
}

/** Truncates or extends existing activities to match the shift duration after editing */
function buildActivities(
  duration: number,
  activities: AttestationEventFragment['shift']['activities'],
): Workforce__Shift__ActivityInput[] {
  let remainingDuration = duration;
  const output: AttestationEventFragment['shift']['activities'] = [];
  for (const activity of activities) {
    if (remainingDuration <= activity.durationMinutes) {
      output.push({ ...activity, durationMinutes: remainingDuration });
      remainingDuration = 0;
      break;
    } else {
      output.push(activity);
      remainingDuration -= activity.durationMinutes;
    }
  }

  if (remainingDuration > 0 && output.length > 0) {
    const last = output[output.length - 1];
    output[output.length - 1] = { ...last, durationMinutes: last.durationMinutes + remainingDuration };
  }

  return output.map((o) => ({
    id: o.id,
    durationMinutes: o.durationMinutes,
    jobCodeID: o.jobCode.id,
  }));
}

function buildVariables(
  action: string,
  values: Values,
  { shift, id }: AttestationEventFragment,
): AttestationEventResolveMutationVariables {
  if (action === 'reject') return { eventId: id, note: values.note };

  const parse = (value: string) => DateTime.fromISO(value, { zone: shift.user.tz });
  const started = values.shiftStartTime ?? parse(shift.started.timestamp);
  const breaks = values.workBreaks;
  const duration = calculateDuration(
    values.shiftStartTime ?? shift.started.timestamp,
    shift.ended!.timestamp,
    values.workBreaks ?? shift.workBreaks,
  );

  return {
    eventId: id,
    note: values.note,
    shiftInput: {
      started: { id: shift.started.id, timestamp: started.toISO(), facilityID: shift.started.facility.id },
      userID: shift.user.id,
      workBreaks: breaks
        ? breaks.map((b) => ({ uuid: b.uuid, started: b.started.toISO(), ended: b.ended.toISO() }))
        : shift.workBreaks,
      activities: buildActivities(duration, shift.activities),
    },
  };
}

const EditForm = ({
  zone,
  breaksEdited,
  startTimeEdited,
}: {
  zone: string;
  breaksEdited: boolean;
  startTimeEdited: boolean;
}) => {
  const { values } = useFormikContext<Values>();
  return (
    <>
      {startTimeEdited && <FormikNativeDateTimeFormGroup label="Shift Start" name="shiftStartTime" zone={zone} />}
      {breaksEdited && (
        <FieldArray
          name="workBreaks"
          render={(arrayHelpers) => (
            <div>
              {values.workBreaks?.map((friend, index) => (
                <Box.Flex gap="12px" key={friend.uuid} alignItems="center">
                  <FormikNativeDateTimeFormGroup
                    label={<>Break {index + 1} Start</>}
                    name={`workBreaks.${index}.started`}
                    zone={zone}
                  />
                  <FormikNativeDateTimeFormGroup
                    label={`Break ${index + 1} End`}
                    name={`workBreaks.${index}.ended`}
                    zone={zone}
                  />
                  <Button type="button" kind="default" onClick={() => arrayHelpers.remove(index)}>
                    Remove
                  </Button>
                </Box.Flex>
              ))}
              <Button type="button" kind="default" onClick={() => arrayHelpers.push({ uuid: UUID() })}>
                Add break
              </Button>
            </div>
          )}
        />
      )}
    </>
  );
};

export const AttestationEventReviewModal: React.FC<{ event: AttestationEventFragment; onClose(): void }> = ({
  event,
  onClose,
}) => {
  const { shift, correctedShiftStart, correctedWorkBreaks } = event;
  const zone = shift.user.tz;
  const [action, setAction] = useState<typeof ACTIONS[number]['value']>();
  const formatForZone = (timestamp: string) =>
    DateTime.fromISO(timestamp, { zone }).toLocaleString(DateTime.DATETIME_SHORT);

  const combinedBreaks = zip(shift.workBreaks, (correctedWorkBreaks ?? []) as WorkBreakDescriptor[]);
  const [mutate, { loading, error, data }] = useAttestationEventResolveMutation({ client });

  const handleSubmit = (values: Values) => {
    mutate({ variables: buildVariables(action!, values, event) }).then(
      (result) => result.data?.result.status === Status.Ok && window.location.reload(),
    );
  };

  const resolvedError = data?.result.error ?? error?.message;

  return (
    <Modal onClose={onClose}>
      <Formik<Values> initialValues={buildInitialValues(event)} onSubmit={handleSubmit}>
        <Form>
          <Modal.Content>
            <Modal.Header>
              <Modal.Title>Review Shift</Modal.Title>
              <Modal.Close close={onClose} />
            </Modal.Header>
            <Modal.Body>
              <Alert style="info">
                {shift.user.name} requests the following changes to their shift on{' '}
                {DateTime.fromISO(shift.date).toLocaleString(DateTime.DATE_MED)}
              </Alert>
              <Table>
                <thead>
                  <tr>
                    <th>Event</th>
                    <th>Current</th>
                    <th>Requested</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Shift Start</td>
                    <td>{formatForZone(shift.started.timestamp)}</td>
                    <td>{correctedShiftStart && formatForZone(correctedShiftStart)}</td>
                  </tr>
                  {!!combinedBreaks?.length && (
                    <>
                      {combinedBreaks.map(([workBreak, correctedWorkBreak], i) => (
                        <React.Fragment key={i}>
                          <tr>
                            <td>Break {i + 1} Start</td>
                            <td>{workBreak && formatForZone(workBreak.started!)}</td>
                            <td>{correctedWorkBreak && formatForZone(correctedWorkBreak.started!)}</td>
                          </tr>
                          <tr>
                            <td>Break {i + 1} End</td>
                            <td>{workBreak && formatForZone(workBreak.ended!)}</td>
                            <td>{correctedWorkBreak && formatForZone(correctedWorkBreak.ended!)}</td>
                          </tr>
                        </React.Fragment>
                      ))}
                    </>
                  )}
                  <tr>
                    <td>Shift End</td>
                    <td>{formatForZone(shift.ended!.timestamp)}</td>
                    <td></td>
                  </tr>
                </tbody>
              </Table>
              <div className="well well-sm">
                {ACTIONS.map(({ label, value }) => (
                  <Radio key={value}>
                    <input
                      type="radio"
                      value={value}
                      checked={action === value}
                      onChange={(e) => setAction(e.currentTarget.value as typeof action)}
                    />{' '}
                    {label}
                  </Radio>
                ))}
              </div>
              {action === 'edit' && (
                <EditForm
                  zone={zone}
                  breaksEdited={!!event.correctedWorkBreaks}
                  startTimeEdited={!!event.correctedShiftStart}
                />
              )}
              {action !== undefined && <FormikInputFormGroup label="Note" name="note" />}
              <ErrorMessage name="base" />
              {resolvedError && <Text style="danger">{resolvedError}</Text>}
            </Modal.Body>
            <Modal.Footer>
              <Button kind="default" onClick={onClose}>
                Cancel
              </Button>
              <Button kind="primary" type="submit" loading={loading} disabled={action === undefined || loading}>
                Submit
              </Button>
            </Modal.Footer>
          </Modal.Content>
        </Form>
      </Formik>
    </Modal>
  );
};

import React from 'react';

import { client } from '@admin/libraries/apollo';

import { useItemDetailsQuery } from '@admin/schema';
import { TicketTicketableType as TicketableType, UserRole } from '@admin/types';

import { CreateTicket as CreateTechSupportTicket } from '@admin/components/tickets/tech_support/create_ticket';

import { Breadcrumb } from '@shared/components/bootstrap';
import { Panel } from '@admin/components/helpers/panel';
import { Roles } from '@admin/components/helpers/roles';
import { Label } from '@admin/components/helpers/label';
import { PageHeader } from '@admin/components/helpers/page_header';
import { BarcodeValue } from '@admin/components/barcode/barcode_value';

import { ItemActivateButton } from './item_activate_button';
import { ItemDeactivateButton } from './item_deactivate_button';
import { ItemState } from './item_state';
import { ItemManualReturnButton } from './item_manual_return_button';

export const ItemDetails: React.FC<{
  accountID: string;
  id: string;
}> = ({ accountID, id }) => {
  const { data } = useItemDetailsQuery({
    client,
    variables: { accountID, id },
  });
  if (!data) return null;

  const item = data.item;
  const account = item?.account;

  return (
    <>
      <PageHeader>
        <Roles show={[UserRole.Admin, UserRole.Manager, UserRole.L2Agent]}>
          <span style={{ float: 'right' }}>
            <CreateTechSupportTicket
              ticketable={{
                id: id,
                type: TicketableType.Item,
                displayName: `Item #' + ${item?.barcode.value ?? '-'}`,
              }}
            />
          </span>
        </Roles>

        <Breadcrumb>
          <Breadcrumb.Item>
            <a href="/accounts">Accounts</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <a href={`/accounts/${accountID}`}>
              #{account?.number ?? '-'} ({account?.customer.name ?? '-'})
            </a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <a href={`/accounts/${accountID}/inventory`}>Inventory</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item active>
            <span>#{item?.barcode.value ?? '-'}</span>{' '}
            {item && !item.active && (
              <>
                {' '}
                <Label kind="danger">Deleted</Label>{' '}
              </>
            )}
          </Breadcrumb.Item>
        </Breadcrumb>
      </PageHeader>

      <Panel>
        <Panel.Header>
          <Panel.Title>Summary</Panel.Title>
        </Panel.Header>
        <Panel.Body>
          <p>
            <strong>Barcode:</strong> {item ? <BarcodeValue barcode={item.barcode} /> : '-'}
          </p>

          <p>
            <strong>State:</strong> {item ? <ItemState item={item} /> : '-'}
          </p>

          {item?.name && (
            <p>
              <strong>Name:</strong> {item.name}
            </p>
          )}

          {item?.contents && (
            <p>
              <strong>Contents:</strong> {item.contents}
            </p>
          )}
        </Panel.Body>
        <Panel.Footer align="right">
          {item && (
            <>
              <ItemManualReturnButton item={item} />
              <ItemActivateButton item={item} />
              <ItemDeactivateButton item={item} />
            </>
          )}
        </Panel.Footer>
      </Panel>
    </>
  );
};

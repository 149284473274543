import React from 'react';

import { useFormattedAppointmentLaborAgreementQuery } from '@admin/schema';

import { client } from '@admin/libraries/apollo';

export const LaborPolicy: React.FC<{ orderId: string }> = ({ orderId }) => {
  const { data } = useFormattedAppointmentLaborAgreementQuery({ variables: { orderId }, client });

  return <span> {data?.formattedAppointmentLaborAgreement} </span>;
};

import React from 'react';

import { Claim__IssueSelectionFragment } from '@admin/schema';
import { ListGroup } from '@shared/components/bootstrap';

export const IssueSelectionDescription: React.FC<{
  issueSelection: Claim__IssueSelectionFragment;
}> = ({ issueSelection }) => (
  <>
    <ListGroup.Item>
      <div>
        <strong>Customer Description</strong>
      </div>
    </ListGroup.Item>
    <ListGroup.Item>
      <div>{issueSelection.description} </div>
    </ListGroup.Item>
  </>
);

import React from 'react';
import PropTypes from 'prop-types';
import { Marker, InfoWindow } from 'react-google-maps';
import { get } from 'lodash';
import moment from 'moment';
import { isCheckinHoveredOrSelected, isCheckinSelected } from '../utils';
import { layoutEventSystem } from '../propTypes';
import { PATH_SCALE, PATH_SCALE_FOCUS, PATH_ANCHOR } from '../contants';
import { person, truck } from '../../../../paths';

const DashboardCheckinsMarkers = ({
  fillColor,
  strokeOpacity,
  fillOpacity,
  strokeColor,
  checkins,
  dispatch,
  onCheckinHoverOut,
  onCheckinClick,
  onCheckinHover,
  ...eventSystem
}) =>
  checkins.map((checkin) => (
    <Marker
      icon={{
        path: get(checkin, 'resource.type') === 'User' ? person : truck,
        fillColor,
        strokeOpacity,
        strokeWeight: isCheckinHoveredOrSelected(eventSystem, checkin.id) ? 2 : 1,
        scale: isCheckinSelected(eventSystem, checkin.id) ? PATH_SCALE_FOCUS : PATH_SCALE,
        fillOpacity,
        strokeColor,
        anchor: PATH_ANCHOR,
      }}
      onMouseOut={onCheckinHoverOut}
      onClick={() => onCheckinClick(checkin.id)}
      onMouseOver={() => onCheckinHover(checkin.id)}
      options={{
        optimized: false,
        zIndex: 99999999,
      }}
      key={`${dispatch.id}:checkin:${checkin.id}`}
      position={{
        lat: Number(checkin.latitude),
        lng: Number(checkin.longitude),
      }}
    >
      {isCheckinHoveredOrSelected(eventSystem, checkin.id) && (
        <InfoWindow onCloseClick={() => onCheckinClick(null)}>
          <div>
            <h4>{checkin.resource.name}</h4>
            Checked in {moment(checkin.timestamp).toNow(true)} ago
            <br />
            {checkin.resource.phone}
          </div>
        </InfoWindow>
      )}
    </Marker>
  ));

DashboardCheckinsMarkers.propTypes = {
  fillColor: PropTypes.string,
  strokeOpacity: PropTypes.number,
  fillOpacity: PropTypes.number,
  strokeColor: PropTypes.string,
  checkins: PropTypes.array,
  dispatch: PropTypes.object,
  ...layoutEventSystem,
};

export default DashboardCheckinsMarkers;

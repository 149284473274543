import { Panel } from '@admin/components/helpers/panel';
import { Pagination } from '@admin/components/pagination';
import { Spinner } from '@admin/components/spinner';
import { auctionUploadFormURL } from '@admin/config/routes';
import { usePusher } from '@admin/hooks';
import { client } from '@admin/libraries/apollo';
import { Auction__UploadFragment as Upload, Auction__Upload__State, useAuctionListQuery } from '@admin/schema';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnchorButton as Link, Badge, Table, Text, Tooltip } from '@shared/components/bootstrap';
import { Timestamp } from '@shared/components/helpers';
import React, { useState } from 'react';

const AUCTION_UPLOAD_STATE_TO_STYLE: Record<Auction__Upload__State, 'danger' | 'success' | 'info'> = {
  [Auction__Upload__State.Failed]: 'danger',
  [Auction__Upload__State.Processed]: 'success',
  [Auction__Upload__State.Processing]: 'info',
  [Auction__Upload__State.Pending]: 'info',
};

const Status: React.FC<{ upload: Upload }> = ({ upload }) => (
  <Badge style={AUCTION_UPLOAD_STATE_TO_STYLE[upload.state]}>
    {upload.state.toLowerCase()}{' '}
    {upload.state === Auction__Upload__State.Processing && <FontAwesomeIcon spin icon="spinner" />}
  </Badge>
);

const Row: React.FC<{ upload: Upload }> = ({ upload }) => (
  <tr>
    <td>{upload.id}</td>
    <td>{upload.user.name}</td>
    <td>{upload.processingAt && <Timestamp value={upload.processingAt} format={Timestamp.Format.DateTime} />}</td>
    <td>{upload.processedAt && <Timestamp value={upload.processedAt} format={Timestamp.Format.DateTime} />}</td>
    <td>
      <a className="btn-link" href={upload.originalCSV.url} target="_blank">
        <strong>{upload.originalCSV.filename}</strong>
      </a>
      <Text style="muted" tag="div">
        {upload.description}
      </Text>
    </td>
    <td>
      {upload.error ? (
        <Tooltip title={upload.error}>
          <Status upload={upload} />
        </Tooltip>
      ) : (
        <Status upload={upload} />
      )}
    </td>
    <td>
      {upload.failureCSV && (
        <a className="btn-link" href={upload.failureCSV.url} target="_blank">
          <strong>{upload.failureCSV.filename}</strong>
        </a>
      )}
    </td>
  </tr>
);

const ViewPermissionDenied: React.FC = () => (
  <tr>
    <td colSpan={12}>You do not have permission to view auction upload history.</td>
  </tr>
);

export const List: React.FC = () => {
  const [page, setPage] = useState<number | undefined>();
  const { data, loading, refetch } = useAuctionListQuery({ client, variables: { page: page } });
  const paginated = data?.paginated;
  const viewable = data?.permission?.view;
  const createable = data?.permission?.create;

  usePusher<{ id: number }>('auction-upload', 'changed', () => {
    refetch();
  });

  return (
    <Panel>
      <Table responsive striped>
        <thead>
          <tr>
            <th>ID</th>
            <th>Uploader</th>
            <th>Processing at</th>
            <th>Processed at</th>
            <th>File</th>
            <th>State</th>
            <th>Failures</th>
          </tr>
        </thead>
        <tbody>
          {!loading && viewable ? (
            paginated?.results.map((upload) => <Row key={upload.id} upload={upload}></Row>)
          ) : (
            <ViewPermissionDenied />
          )}
        </tbody>
      </Table>
      {loading && <Spinner />}
      {paginated && <Pagination pagination={paginated.pagination} onPage={setPage} />}
      <Panel.Footer>
        <Text alignment="right" tag="div">
          <Link kind="default" href={auctionUploadFormURL()} disabled={!createable || !viewable}>
            New
          </Link>
        </Text>
      </Panel.Footer>
    </Panel>
  );
};

import * as React from 'react';
import { forwardRef } from 'react';

import { FieldFormGroupProps, FieldFormGroup } from '@shared/components/fields/field_form_group';

export type SelectFormControlType = HTMLSelectElement;
export type SelectFormControlProps = React.SelectHTMLAttributes<HTMLSelectElement> & FieldFormGroupProps;

export const SelectFormGroup = forwardRef<SelectFormControlType, SelectFormControlProps>(
  ({ has, help, label, className, ...input }, ref) => (
    <FieldFormGroup has={has} help={help} label={label} className={className} id={input.id}>
      <select className="form-control" ref={ref} {...input} />
    </FieldFormGroup>
  ),
);

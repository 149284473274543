import { head, last } from 'lodash';
import * as React from 'react';

import { HistoryResourcePhoneCallFragment } from '@admin/schema';

import { PhoneCallDirection } from '@admin/types';

import { Download as AudioDownload } from '@admin/components/audio/download';
import { Listen as AudioListen } from '@admin/components/audio/listen';
import { Time } from '@admin/components/helpers/time';

import { Timestamp } from '@admin/components/timestamp';

import { phonecallURL } from '@admin/config/routes';

const resourceForPhoneCall = (resource: HistoryResourcePhoneCallFragment) => {
  const conversation = last(resource.conversations);
  switch (resource.status) {
    case 'missed':
      return 'Call';
    case 'hungup':
      return 'Caller';
    case 'voicemail':
      return 'Caller';
    case 'abandoned':
      return 'Call';
    default:
      return conversation && conversation.user && conversation.user.name;
  }
};

const actionForPhoneCall = (resource: HistoryResourcePhoneCallFragment) => {
  switch (resource.status) {
    case 'complete':
      switch (resource.direction) {
        case PhoneCallDirection.Inbound:
          return 'answered call:';
        case PhoneCallDirection.Outbound:
          return 'called:';
      }
    case 'missed':
      return 'missed:';
    case 'hungup':
      return 'hung up:';
    case 'voicemail':
      return 'left a voicemail:';
    case 'abandoned':
      return 'abandoned:';
  }
};

export const PhoneCall: React.FC<{
  resource: HistoryResourcePhoneCallFragment;
  timestamp: string;
  timezone?: string;
}> = ({ resource, timestamp, timezone }) => {
  const conversation = last(resource.conversations);
  const voicemail = head(resource.voicemails);

  // Use the conversation recording and fallback to the voicemail recording.
  const recording = conversation ? conversation && conversation.recording : voicemail && voicemail.recording;
  const agent = conversation && conversation.user && conversation.user.name;

  return (
    <tr>
      <td className="col-nowrap col-md-2 text-left" colSpan={2}>
        <Timestamp value={timestamp} timezone={timezone} />
      </td>

      <td className="col-nowrap col-md-2 text-left" colSpan={2}>
        <strong>{resourceForPhoneCall(resource)}</strong> <span>{actionForPhoneCall(resource)}</span>
      </td>

      <td className="col-md-6 text-left" colSpan={6}>
        <ul>
          <li>
            <strong>Phone Number: </strong> <span>{resource.phone.number}</span>
          </li>
          {resource.startAt && (
            <li>
              <strong>Call Start: </strong>
              <Timestamp value={resource.startAt} timezone={timezone} />
            </li>
          )}
          {resource.endAt && (
            <li>
              <strong>Call End: </strong>
              <Timestamp value={resource.endAt} timezone={timezone} />
            </li>
          )}
          <li>
            <strong>{resource.status === 'voicemail' ? 'Voicemail' : 'Call'}</strong>{' '}
            <span>
              <strong>Duration:</strong> <Time value={resource.duration} />
            </span>
          </li>
          {!!resource.notes.length && (
            <li>
              <strong>Notes:</strong> <span>{resource.notes.map(({ body }) => body).join(' ')}</span>
            </li>
          )}
          {!!resource.reasons.length && (
            <li>
              <strong>Call reasons:</strong> <span>{resource.reasons.join(', ')}</span>
            </li>
          )}
          <li>
            <a className="underline" href={phonecallURL(resource)}>
              View Call Details
            </a>
          </li>
        </ul>
      </td>

      <td className="col-md-2 text-right" colSpan={2}>
        {recording && recording.playbackURL && (
          <>
            <AudioListen src={recording.playbackURL} date={resource.startAt} agent={agent} pageID={resource.id} />{' '}
            <AudioDownload src={resource.id}>Download</AudioDownload>
          </>
        )}
      </td>
    </tr>
  );
};

import * as React from 'react';

import styled from '@emotion/styled';
import { Button, Modal } from '@shared/components/bootstrap';

const Title = styled.div`
  text-align: center;
`;

export const ConfirmationModal: React.FC<{
  title: string;
  acceptButton: string;
  declineButton: string;
  onClose(): void;
  onConfirm(): void;
}> = ({ title, acceptButton, declineButton, onClose, onConfirm, children }) => (
  <Modal onClose={onClose}>
    <Modal.Content>
      <Modal.Header>
        <Modal.Title>
          <Title>{title}</Title>
        </Modal.Title>
      </Modal.Header>
      {children && <Modal.Body>{children}</Modal.Body>}
      <Modal.Footer>
        <Button kind="default" onClick={onClose}>
          {declineButton}
        </Button>
        <Button kind="primary" onClick={onConfirm}>
          {acceptButton}
        </Button>
      </Modal.Footer>
    </Modal.Content>
  </Modal>
);

import { Spinner } from '@admin/components/spinner';
import { History__Subject, NoteNotableEnum } from '@admin/schema';
import { HistoriesPanel } from '@admin/components/histories/panel';
import { client } from '@admin/libraries/apollo';
import React from 'react';
import { NotesPanel } from '@admin/components/notes/panel';
import { LoadTypeEnum, useLogisticsLoadQuery } from '@admin/schema';
import { LogisticsLoadBasicInfoPanel } from './detail_panels/basic_info_panel';
import { LogisticsLoadCargoDetailsPanel } from './detail_panels/cargo_details_panel';
import { LogisticsLoadInventoryPanel } from './detail_panels/inventory_panel';
import { LogisticsLoadDocumentsPanel } from './detail_panels/documents_panel';
import { LogisticsLoadImagesPanel } from './detail_panels/images_panel';

export const LogisticsDetailedLoad: React.FC<{ id: string }> = ({ id }) => {
  const { data, loading } = useLogisticsLoadQuery({ client, variables: { id } });

  if (loading || !data) {
    return <Spinner />;
  }
  const load = data.load;
  document.title = `Load: #${load.number}`;

  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <LogisticsLoadBasicInfoPanel load={load} />
        </div>
        <div className="col-md-6">
          <LogisticsLoadCargoDetailsPanel load={load} />
          {load.type === LoadTypeEnum.Outbound && <LogisticsLoadDocumentsPanel load={load} />}
        </div>
      </div>
      <LogisticsLoadInventoryPanel load={load} />
      <LogisticsLoadImagesPanel load={load} />
      <NotesPanel type={NoteNotableEnum.LogisticsLoad} id={id} />
      <HistoriesPanel type={History__Subject.LogisticsLoad} id={id} timezone={load.warehouse.tz} />
    </>
  );
};

import { vehicleTypesURL } from '@admin/config/routes';
import { client } from '@admin/libraries/apollo';
import { useVehicleTypeQuery, useVehicleTypeUpdateMutation, VehicleTypeInput } from '@admin/schema';
import * as React from 'react';
import { VehicleTypeForm } from './vehicle_type_form';

export const EditVehicleType: React.FC<{ id: string }> = ({ id }) => {
  const [error, setError] = React.useState<string | undefined>();

  const { data } = useVehicleTypeQuery({ client, variables: { id } });
  const [vehicleTypeUpdate] = useVehicleTypeUpdateMutation({ client });

  if (!data || !data.vehicleType) {
    return null;
  }

  const onSubmit = async (vehicleType: VehicleTypeInput) => {
    const result = await vehicleTypeUpdate({
      variables: {
        input: {
          name: vehicleType.name,
          cuft: vehicleType.cuft,
          numSeats: vehicleType.numSeats,
          weightInPounds: vehicleType.weightInPounds,
          fieldDispatchable: vehicleType.fieldDispatchable,
        },
        id,
      },
    });

    if (result.data?.vehicleTypeUpdate.error) {
      setError(result.data?.vehicleTypeUpdate.error);
    } else {
      window.location.href = vehicleTypesURL();
    }
  };

  return (
    <div>
      {error && <div className="alert alert-danger">{error}</div>}
      <VehicleTypeForm existingVehicleType={data.vehicleType} onSubmit={onSubmit} />
    </div>
  );
};

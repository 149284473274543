import React, { useEffect } from 'react';
import { ErrorMessage, FormContext, useForm } from 'react-hook-form';

import {
  Alert,
  AnchorButton as Link,
  Breadcrumb,
  Button,
  ControlLabel,
  FormGroup,
  HelpBlock,
} from '@shared/components/bootstrap';

import { Panel } from '@admin/components/helpers/panel';
import { Spacer } from '@shared/components/helpers';

import {
  SelfStorage__UnitGroupFragment,
  SelfStorage__ReservationFragment,
  useBookSelfStorageReservationMutation,
} from '@admin/schema';
import { client } from '@admin/libraries/apollo';

import { listSelfStorageReservationsURL as reservationsURL } from '@admin/config/routes';
import { Radios as FacilityRadios } from '@admin/components/self_storage/facilities/radios';
import { Quote } from './quote';

interface IFormData {
  name: string;
  email: string;
  phone: string;
  date: string;
  facilityID: string;
  group: SelfStorage__UnitGroupFragment;
  channel: string;
}

const Breadcrumbs: React.FC = () => (
  <div className="page-header">
    <Breadcrumb>
      <Breadcrumb.Item>
        <a href={reservationsURL()}>Reservations</a>
      </Breadcrumb.Item>
      <Breadcrumb.Item>New</Breadcrumb.Item>
    </Breadcrumb>
  </div>
);

const Container: React.FC<{
  onSave(reservation: SelfStorage__ReservationFragment): void;
}> = ({ onSave }) => {
  const [book, { loading, data }] = useBookSelfStorageReservationMutation({ client });
  const form = useForm<IFormData>();

  useEffect(() => {
    form.register('facilityID');
    return () => {
      form.unregister('facilityID');
    };
  }, []);

  const facilityID = form.watch('facilityID');

  const onSubmit = async (input: IFormData) => {
    const response = await book({
      variables: {
        input: {
          name: input.name,
          facilityID: input.facilityID,
          phone: input.phone,
          email: input.email,
          date: input.date,
          ...(input.group && {
            classificationID: input.group.classification.id,
            priceID: input.group.price.id,
            promotionID: input.group.promotion?.id,
          }),
          marketingData: { channel: input.channel },
        },
      },
    });
    if (response?.data?.result?.reservation) {
      onSave(response.data.result.reservation);
    }
  };

  return (
    <FormContext {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Panel>
          <Panel.Header>
            <Panel.Title>Book Reservation</Panel.Title>
          </Panel.Header>
          <Panel.Body>
            <FormGroup has={form.errors.channel ? 'error' : undefined}>
              <ControlLabel htmlFor="channel">Channel:</ControlLabel>
              <select
                id="channel"
                className="form-control"
                name="channel"
                ref={form.register({ required: 'please select a channel' })}
              >
                <option value="sparefoot">SpareFoot</option>
                <option value="in-store">In-Store</option>
                <option value="other">Other</option>
              </select>
            </FormGroup>

            <FormGroup has={form.errors.name ? 'error' : undefined}>
              <ControlLabel htmlFor="name">Name:</ControlLabel>
              <input
                id="name"
                type="text"
                className="form-control"
                name="name"
                ref={form.register({ required: 'please enter a name' })}
              />
              <ErrorMessage name="name">{({ message }) => <HelpBlock>{message}</HelpBlock>}</ErrorMessage>
            </FormGroup>

            <FormGroup has={form.errors.email ? 'error' : undefined}>
              <ControlLabel htmlFor="email">Email:</ControlLabel>
              <input
                id="email"
                type="email"
                className="form-control"
                name="email"
                ref={form.register({ required: 'please enter an email' })}
              />
              <ErrorMessage name="email">{({ message }) => <HelpBlock>{message}</HelpBlock>}</ErrorMessage>
            </FormGroup>

            <FormGroup has={form.errors.phone ? 'error' : undefined}>
              <ControlLabel htmlFor="phone">Phone:</ControlLabel>
              <input
                id="phone"
                type="phone"
                className="form-control"
                name="phone"
                ref={form.register({ required: 'please enter a phone' })}
              />
              <ErrorMessage name="phone">{({ message }) => <HelpBlock>{message}</HelpBlock>}</ErrorMessage>
            </FormGroup>

            <FormGroup has={form.errors.date ? 'error' : undefined}>
              <ControlLabel htmlFor="reservation_date">Move-In Date:</ControlLabel>
              <input
                id="reservation_date"
                type="date"
                className="form-control"
                name="date"
                ref={form.register({ required: 'please enter a date' })}
              />
              <ErrorMessage name="date">{({ message }) => <HelpBlock>{message}</HelpBlock>}</ErrorMessage>
            </FormGroup>
            <FacilityRadios
              onSelect={(selectedID) => form.setValue('facilityID', selectedID)}
              selectedID={facilityID}
            />
            {facilityID && <Quote facilityID={facilityID} />}
            {data?.result?.error && <Alert style="danger">{data?.result.error}</Alert>}
          </Panel.Body>
          <Panel.Footer align="right">
            <Link kind="default" href={reservationsURL()} disabled={loading}>
              Cancel
            </Link>
            <Spacer width="8px" />
            <Button kind="primary" type="submit" loading={loading}>
              Save
            </Button>
          </Panel.Footer>
        </Panel>
      </form>
    </FormContext>
  );
};

export const New: React.FC<{
  onSave(): void;
}> = ({ onSave }) => (
  <>
    <Breadcrumbs />
    <Container onSave={onSave} />
  </>
);

interface Window {
  clutter: {
    zendesk?: {
      subdomain: string;
    };
  };
}

const zendeskSubdomain: string = (window as any).clutter.zendesk?.subdomain;

export const zendeskTicketURL = ({ id }: { id: number | string }) =>
  `https://${zendeskSubdomain}.zendesk.com/agent/tickets/${id}`;

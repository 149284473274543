import { useField } from 'formik';
import React from 'react';
import { FieldFormGroupProps } from '@shared/components/fields/field_form_group';
import { DateTime } from 'luxon';
import { FormikFieldFormGroup } from '@shared/components/fields/formik/formik_field_form_group';

export function formatDateTimeForTimePickerInput(datetime: DateTime) {
  const hour = datetime.hour >= 10 ? `${datetime.hour}` : `0${datetime.hour}`;
  const minute = datetime.minute >= 10 ? `${datetime.minute}` : `0${datetime.minute}`;
  return `${hour}:${minute}`;
}

// The time should value should be a string in the format of hh:mm (e.g. "15:30")
export const FormikTimePickerFormGroup: React.FC<FieldFormGroupProps & { name: string }> = ({
  help,
  label,
  name,
  id,
}) => {
  const [{ value }, _, { setValue }] = useField(name);

  return (
    <FormikFieldFormGroup name={name} help={help} label={label} id={id}>
      <input
        id={id}
        type="time"
        className="form-control"
        value={value}
        onChange={(event) => setValue(event.target.value)}
      />
    </FormikFieldFormGroup>
  );
};

(function () {
  const app = angular.module('app');

  app.controller('AvailabilityController', [
    '$scope',
    '$location',
    'User',
    'Region',
    'FiltersService',
    function ($scope, $location, User, Region, FiltersService) {
      $scope.filters = FiltersService.parse($location.search(), {
        date: 'date',
        week: 'date',
        region_id: 'number',
        user_id: 'number',
      });

      $scope.users = User.query({ 'roles[]': 'mover' });
      $scope.regions = Region.query();

      $scope.filter = function (filters) {
        filters = FiltersService.cleanup(filters);
        $location.search(filters);

        $scope.$broadcast('filter', filters);
      };

      $scope.filter($scope.filters);
    },
  ]);
})();

import angular from 'angular';
import { concat } from 'lodash';

const app = angular.module('app');

const ORDER_ATTRIBUTES = [
  'id',
  'account_id',
  'region_id',
  'address_id',
  'rescheduler_id',
  'rescheduler_type',
  'scheduled_override',
  'external',
  'scheduled',
  'movers',
  'other',
  'materials',
  'moving',
  'storage',
  'scheduled_override_reason',
  'contact_attributes',
  'tags',
  'movers_overwritten_at',
  'labor_exception',
  'mover_override_reason',
  'labor_rate_id',
  'account_packages_attributes',
  'subscribe_attributes',
  'duration',
  'predecessor_id',
  'drop_off_appointment_attributes',
  'per_mover_hour_adjustment_amount',
];

const PICKUP_ATTRIBUTES = concat(ORDER_ATTRIBUTES, [
  'metadata_attributes',
  'material_selections_attributes',
  'reset_room_selections_attributes',
  'reset_estimated_items_attributes',
  'reset_estimation_selections_attributes',
]);

const RETURN_ATTRIBUTES = concat(ORDER_ATTRIBUTES, [
  'item_ids',
  'cancel_account',
  'reason_id',
  'service_type',
  'account_cancel_intent',
]);

const SELF_STORAGE_SHUTTLE_ATTRIBUTES = concat(ORDER_ATTRIBUTES, ['reservation']);

const MOVE_ATTRIBUTES = ORDER_ATTRIBUTES.concat([
  'moving_operation_attributes',
  'moving_selections_attributes',
  'subtype',
]);
const MOVE_RESCHEDULE_FEE_DESCRIPTION =
  'A rescheduling fee of $100 will be charged for rescheduling within 48 hours of the appointment. ' +
  'Your initial deposit will be applied towards the new order.';

const CANCELATION_FEE_PERIOD_HOURS = 48;

const RETAIL_DELIVERY_ATTRIBUTES = ORDER_ATTRIBUTES;

app.constant('PICKUP_ATTRIBUTES', PICKUP_ATTRIBUTES);
app.constant('RETURN_ATTRIBUTES', RETURN_ATTRIBUTES);
app.constant('SELF_STORAGE_SHUTTLE_ATTRIBUTES', SELF_STORAGE_SHUTTLE_ATTRIBUTES);
app.constant('MOVE_ATTRIBUTES', MOVE_ATTRIBUTES);
app.constant('MOVE_RESCHEDULE_FEE_DESCRIPTION', MOVE_RESCHEDULE_FEE_DESCRIPTION);
app.constant('CANCELATION_FEE_PERIOD_HOURS', CANCELATION_FEE_PERIOD_HOURS);
app.constant('RETAIL_DELIVERY_ATTRIBUTES', RETAIL_DELIVERY_ATTRIBUTES);

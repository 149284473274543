import * as React from 'react';
import { useEffect, useState } from 'react';

import { Panel } from '@admin/components/helpers/panel';
import { Pagination } from '@admin/components/pagination';
import { Spinner } from '@admin/components/spinner';
import { AnchorButton } from '@shared/components/bootstrap';
import { useDebounce } from '@shared/hooks';

import { Selector as FacilitiesSelector } from '@admin/components/self_storage/facilities/selector';
import { SingleSelector as PartnersSelector } from '@admin/components/self_storage/partners/single_selector';
import { Selector as UsersSelector } from '@admin/components/users/selector';

import {
  SelfStorage__Partner__Company,
  SelfStorage__Reservation__FiltersInput,
  usePaginatedSelfStorageReservationsQuery,
  useSelfStorageRentalPolicyQuery,
  useSelfStorageReservationPolicyQuery,
} from '@admin/schema';
import { client } from '@admin/libraries/apollo';

import { newSelfStorageRentalURL, newSelfStorageReservationURL } from '@admin/config/routes';
import { Spacer } from '@shared/components/helpers';
import { Table } from './table';

enum State {
  Pending = 'pending',
  Lost = 'lost',
  Won = 'won',
}

export const List: React.FC<
  SelfStorage__Reservation__FiltersInput & {
    page?: number;
    onFilter(filters: SelfStorage__Reservation__FiltersInput & { page?: number }): void;
  }
> = ({ onFilter, ...defaults }) => {
  const [facilityIDs, setFacilityIDs] = useState(defaults.facilityIDs ?? []);
  const [company, setCompany] = useState<SelfStorage__Partner__Company | undefined>(
    defaults.company ?? SelfStorage__Partner__Company.Clutter,
  );
  const [userIDs, setUserIDs] = useState(defaults.userIDs ?? []);
  const [page, setPage] = useState(defaults.page);
  const [search, setSearch] = useState(defaults.search);
  const [state, setState] = useState(defaults.state);
  const [from, setFrom] = useState(defaults.from);
  const [till, setTill] = useState(defaults.till);
  const { data, loading } = usePaginatedSelfStorageReservationsQuery({
    client,
    variables: {
      page,
      filters: {
        facilityIDs,
        company,
        userIDs,
        state,
        from,
        till,
        search: useDebounce(search),
      },
    },
  });
  const { data: rentalPolicy } = useSelfStorageRentalPolicyQuery({ client });
  const { data: reservationPolicy } = useSelfStorageReservationPolicyQuery({
    client,
    variables: { reservationID: null },
  });

  useEffect(() => {
    onFilter({
      facilityIDs,
      company,
      userIDs,
      search,
      state,
      from,
      till,
      page,
    });
  }, [facilityIDs, company, userIDs, search, from, till, state, page]);

  const paginated = data && data.paginated;

  const onPartnerChange = (selectedCompany?: SelfStorage__Partner__Company) => {
    if (selectedCompany !== company) {
      setCompany(selectedCompany);
      setFacilityIDs([]);
    }
    setPage(undefined);
  };

  return (
    <>
      <Panel>
        <Panel.Body>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label className="control-label" htmlFor="search">
                  Search:
                </label>
                <input
                  id="search"
                  className="form-control"
                  type="search"
                  placeholder="Search"
                  value={search || ''}
                  onChange={(event) => setSearch(event.target.value || undefined)}
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label className="control-label" htmlFor="from">
                  From:
                </label>
                <input
                  id="from"
                  min="0000-01-01"
                  max="9999-12-31"
                  type="date"
                  className="form-control"
                  value={from || ''}
                  onChange={(event) => setFrom(event.target.value || undefined)}
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label className="control-label" htmlFor="till">
                  Till:
                </label>
                <input
                  id="till"
                  min="0000-01-01"
                  max="9999-12-31"
                  type="date"
                  className="form-control"
                  value={till || ''}
                  onChange={(event) => setTill(event.target.value || undefined)}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <div className="form-group">
                <label className="control-label">Partner:</label>
                <PartnersSelector
                  selectedCompany={company}
                  onChange={(selectedCompany) => onPartnerChange(selectedCompany || undefined)}
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label className="control-label">Facility:</label>
                <FacilitiesSelector
                  selectedIDs={facilityIDs}
                  disabled={company !== SelfStorage__Partner__Company.Clutter}
                  onChange={setFacilityIDs}
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label className="control-label">Agent:</label>
                <UsersSelector withSelfStorageReservation selectedIDs={userIDs} onChange={setUserIDs} />
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label className="control-label" htmlFor="state">
                  Status:
                </label>
                <select
                  id="state"
                  className="form-control"
                  value={state || ''}
                  onChange={(event) => setState((event.target.value || undefined) as State)}
                >
                  <option value=""> - All - </option>
                  <option value={State.Pending}>Pending</option>
                  <option value={State.Lost}>Lost</option>
                  <option value={State.Won}>Won</option>
                </select>
              </div>
            </div>
          </div>
        </Panel.Body>
      </Panel>
      <Panel>
        <Panel.Body>
          {loading && <Spinner />}
          {!loading && paginated && (
            <>
              <Table entries={paginated.results} />
              <Pagination pagination={paginated.pagination} onPage={setPage} />
            </>
          )}
        </Panel.Body>
        <Panel.Footer align="right">
          <AnchorButton
            kind="primary"
            href={newSelfStorageReservationURL()}
            disabled={!reservationPolicy?.permissions?.new}
          >
            New Reservation
          </AnchorButton>
          <Spacer width="8px" />
          <AnchorButton kind="primary" href={newSelfStorageRentalURL()} disabled={!rentalPolicy?.permissions?.new}>
            New Walk-In
          </AnchorButton>
        </Panel.Footer>
      </Panel>
    </>
  );
};

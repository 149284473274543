import { normalize, denormalize } from 'normalizr';
import { assign, keys, isObject, merge } from 'lodash';
import { object } from '../utils';

function assignDepth(depth, target, ...sources) {
  if (isObject(target)) {
    sources.forEach((source) => {
      if (isObject(source)) {
        if (depth > 1) {
          keys(source).forEach((key) => {
            if (isObject(source[key])) {
              if (!(key in target)) {
                assign(target, { [key]: source[key] });
              } else {
                // eslint-disable-next-line no-param-reassign
                target[key] = assignDepth(depth - 1, target[key], source[key]);
              }
            } else {
              assign(target, { [key]: source[key] });
            }
          });
        } else if (depth === 1) {
          assign(target, source);
        }
      }
    });
  }
  return target;
}

export const SET_ENTITIES = 'entities/SET';
export const CLEAR_ENTITIES = 'entities/CLEAR';
export const FILTER_ENTITIES = 'entities/FILTER';
export const REPLACE_ENTITIES = 'entities/REPLACE';

export const normalizeFor = (schema) => (data) => normalize(data, schema);
export const denormalizeFor = (schema) => (input, entities) => denormalize(input, schema, entities);

/**
 * Setting entities means merging models one level deep at the model level
 *  - where entities are structured like this:
 *  {
 *    'schema-name': {
 *      'modelId': {
 *        ...model,
 *      }
 *    }
 *  }
 * @param data
 */
export const setEntity = (data) => ({
  type: SET_ENTITIES,
  data: (state) => assignDepth(3, state, data),
});

export const setEntityData = (data, schema) => (dispatch) => {
  if (!schema) {
    throw new Error('schema is required');
  }
  const { result, entities } = normalize(data, schema);
  dispatch(setEntity(entities));
  return result;
};

export const replaceEntity = (data) => ({
  type: SET_ENTITIES,
  data: (state) => assignDepth(2, state, data),
});

export const mergeEntity = (data) => ({
  type: SET_ENTITIES,
  data: (state) => merge({}, state, data),
});

export const clearEntity = () => ({
  type: CLEAR_ENTITIES,
});

export const filterEntity = (data) => ({
  type: FILTER_ENTITIES,
  data,
});

export default object({
  SET: SET_ENTITIES,
  CLEAR: CLEAR_ENTITIES,
  FILTER: FILTER_ENTITIES,
  REPLACE: REPLACE_ENTITIES,
});

export const selectEntities = (state) => state.entities;

export * from './schemas';

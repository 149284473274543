import React from 'react';
import { FieldError } from 'react-hook-form';
import styled from '@emotion/styled';

import { ControlLabel, FormGroup, HelpBlock } from '@shared/components/bootstrap';

const RequiredControlLabel = styled(ControlLabel)`
  ::after {
    content: '*';
    color: red;
    margin-left: 2px;
  }
`;

export const Field: React.FC<{
  id: string;
  label: string;
  error?: FieldError;
  required?: boolean;
}> = ({ id, label, error, required, children }) => (
  <FormGroup has={error ? 'error' : undefined}>
    {required && (
      <RequiredControlLabel htmlFor={id}>
        <strong>{label}</strong>
      </RequiredControlLabel>
    )}
    {!required && (
      <ControlLabel htmlFor={id}>
        <strong>{label}</strong>
      </ControlLabel>
    )}
    {children}
    {error?.message && <HelpBlock>{error.message}</HelpBlock>}
  </FormGroup>
);

import { Account__State } from '@admin/schema';

export const ACCOUNT_STATES = [
  Account__State.Active,
  Account__State.Pending,
  Account__State.Canceled,
  Account__State.Suspended,
];

export const ACCOUNT_STATE_TO_NAME: Record<Account__State, string> = {
  [Account__State.Active]: 'Active',
  [Account__State.Pending]: 'Pending',
  [Account__State.Canceled]: 'Canceled',
  [Account__State.Suspended]: 'Suspended',
};

export const ACCOUNT_STATE_TO_KIND: Record<Account__State, 'success' | 'primary' | 'warning' | 'danger'> = {
  [Account__State.Active]: 'success',
  [Account__State.Pending]: 'primary',
  [Account__State.Canceled]: 'warning',
  [Account__State.Suspended]: 'danger',
};

export type AccountStateParam = 'active' | 'pending' | 'canceled' | 'suspended';

export const ACCOUNT_STATE_TO_PARAM: Record<Account__State, AccountStateParam> = {
  [Account__State.Active]: 'active',
  [Account__State.Pending]: 'pending',
  [Account__State.Canceled]: 'canceled',
  [Account__State.Suspended]: 'suspended',
};

export const ACCOUNT_STATE_FROM_PARAM = {
  active: Account__State.Active,
  pending: Account__State.Pending,
  canceled: Account__State.Canceled,
  suspended: Account__State.Suspended,
};

(() => {
  const app = angular.module('app');

  app.component('messageHistory', {
    templateUrl: 'partials/components/message_history.html',
    bindings: {
      getSeeMoreLink: '&',
      recipientType: '<',
      recipientId: '<',
    },
    controller: [
      'MessageCacheService',
      '$timeout',
      'SmsConstants',
      function (MessageCacheService, $timeout, SmsConstants) {
        const startHideTimer = () => {
          this.promiseToHide = $timeout(() => {
            this.isOpen = false;
          }, 300);
        };

        const cancelHideTimer = () => {
          if (this.promiseToHide) {
            $timeout.cancel(this.promiseToHide);
          }
        };

        const addPosition = function addPositionToMessages(messages) {
          let count = 0;
          let previousKind;
          messages.forEach((message) => {
            if (message.kind === previousKind) {
              count += 1;
            } else {
              count = 0;
            }

            message.position = count;
            previousKind = message.kind;
          });
        };

        this.onEnterIcon = () => {
          if (!this.messagesObj) {
            this.messagesObj = MessageCacheService.getMessages(this.recipientType, this.recipientId);
            this.messagesObj.$promise.then((data) => {
              addPosition(data.results);
            });
          }

          cancelHideTimer();
          this.isOpen = true;
        };

        this.onLeaveIcon = startHideTimer;
        this.onEnterPopover = cancelHideTimer;
        this.onLeavePopover = startHideTimer;
        this.maxDisplayLength = SmsConstants.MAX_TEXT_DISPLAY;

        this.getClasses = function getClasses(message) {
          const parity = message.position % 2 ? 'odd-row' : 'even-row';
          const direction = message.kind === 'inbound' ? 'inbound-row' : 'outbound-row';
          return `${parity} ${direction}`;
        };
      },
    ],
  });
})();

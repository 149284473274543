import React from 'react';

import { User__ExternalGroup } from '@admin/schema';

import { Label } from '@admin/components/helpers/label';

import { USER_EXTERNAL_GROUP_TO_NAME } from '@admin/constants/user_external_group';

export const ExternalGroup: React.FC<{ externalGroup: User__ExternalGroup }> = ({ externalGroup }) => (
  <Label kind="default">{USER_EXTERNAL_GROUP_TO_NAME[externalGroup]}</Label>
);

import * as React from 'react';

import { useResendTodayAppUrlMutation } from '@admin/schema';
import { client } from '@admin/libraries/apollo';
import styled from '@emotion/styled';

const Button = styled.button`
  margin-right: 4px;
`;

const ResendTodayUrlButton: React.FC<{ orderId: any }> = ({ orderId }) => {
  const [execute] = useResendTodayAppUrlMutation({ client, variables: { orderId } });

  return (
    <Button className="btn btn-info" onClick={() => execute()}>
      Resend Today App URL
    </Button>
  );
};

export { ResendTodayUrlButton };

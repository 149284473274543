import PropTypes from 'prop-types';

export const layoutEventSystem = {
  activeDispatchId: PropTypes.number,
  activeOrderId: PropTypes.number,
  activeCheckinId: PropTypes.number,
  hoveredDispatchId: PropTypes.number,
  hoveredOrderId: PropTypes.number,
  hoveredCheckinId: PropTypes.number,
  onOrderHover: PropTypes.func.isRequired,
  onOrderHoverOut: PropTypes.func.isRequired,
  onOrderClick: PropTypes.func.isRequired,
  onDispatchHover: PropTypes.func.isRequired,
  onDispatchHoverOut: PropTypes.func.isRequired,
  onDispatchClick: PropTypes.func.isRequired,
  onCheckinHover: PropTypes.func.isRequired,
  onCheckinHoverOut: PropTypes.func.isRequired,
  onCheckinClick: PropTypes.func.isRequired,
};

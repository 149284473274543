import { OptionsQuery, useLogisticsSkusForAccountLazyQuery } from '@admin/schema';
import { client } from '@admin/libraries/apollo';
import { lowerCase, startCase } from 'lodash';
import React, { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { SingleSelectFormControl } from '@admin/components/fields/single_select_form_control';
import { Field, StyledRow, RemoveCargoDetailsButton } from './field';

type Account = OptionsQuery['logisticsAccounts'][number];
type PurchaseOrder = OptionsQuery['logisticsPurchaseOrders'][number];

export const FulfillmentExpectationFields: React.FC<{
  editMode: boolean;
  accounts: Account[];
  purchaseOrders: PurchaseOrder[];
  item: any;
  index: number;
  removeFulfillmentExpectation(index: number): void;
  initialAccountID: string | undefined;
  initialPurchaseOrderID: string | undefined;
  initialSkuID: string | undefined;
  usePalletLabels: boolean;
  hasItems: boolean;
}> = ({
  editMode,
  accounts,
  purchaseOrders,
  item,
  index,
  removeFulfillmentExpectation,
  initialAccountID,
  initialPurchaseOrderID,
  initialSkuID,
  usePalletLabels,
  hasItems,
}) => {
  const { errors, register, watch } = useFormContext<{
    fulfillmentExpectations?: Array<{
      accountID: string;
      skuID: string;
      quantity: number;
      purchaseOrderID: string;
    }>;
  }>();
  const [accountID, setAccountID] = useState<string>(
    initialAccountID || (watch(`fulfillmentExpectations[${index - 1}].accountID`) as string) || '',
  );
  const [purchaseOrderID, setPurchaseOrderID] = useState<string>(initialPurchaseOrderID || '');
  const [skuID, setSkuID] = useState<string>(initialSkuID || '');

  const [getSkus, { data: skuData, loading }] = useLogisticsSkusForAccountLazyQuery({
    client,
    variables: { accountID },
  });

  useEffect(() => {
    if (accountID !== '') {
      getSkus();
    }
  }, [accountID]);

  const editingDisabled: boolean = editMode && usePalletLabels;

  if (initialAccountID && (loading || !skuData)) {
    return <span>Loading...</span>;
  }

  const accountOptions = accounts.map((a) => ({ value: a.id, label: `${a.customer.name} (${a.id})` }));
  const poOptions = purchaseOrders
    .filter((po) => po.account.id === accountID)
    .map((po) => ({ value: po.id, label: `${po.number} (${po.account.customer.name})` }));
  const skuOptions = skuData?.logisticsSkus.map((sku) => ({
    value: sku.id,
    label: `${sku.value} (${startCase(lowerCase(sku.countingUnit))})`,
  }));

  return (
    <StyledRow className="row">
      <input
        type="hidden"
        id={`fe_id_${index}_hidden`}
        defaultValue={item.fulfillmentExpectationID}
        name={`fulfillmentExpectations[${index}].fulfillmentExpectationID`}
        ref={register()}
        disabled={true}
      />
      <div className="col-md-3">
        <Field error={errors.fulfillmentExpectations} id={`account_id_${index}`} label="3PL Account" required={true}>
          <SingleSelectFormControl
            id={`account_id_${index}`}
            options={accountOptions}
            value={accountID}
            onChange={(id) => {
              if (id) setAccountID(id);
              getSkus();
              setSkuID('');
            }}
            disabled={editingDisabled || hasItems}
          />
          <input
            type="hidden"
            id={`account_id_${index}_hidden`}
            name={`fulfillmentExpectations[${index}].accountID`}
            ref={register()}
            defaultValue={accountID}
          />
        </Field>
      </div>
      <div className="col-md-3">
        <Field
          error={errors.fulfillmentExpectations}
          id={`po_id_${index}_hidden`}
          label="Purchase Order Number"
          required={usePalletLabels}
        >
          <SingleSelectFormControl
            id={`po_id_${index}`}
            value={purchaseOrderID}
            onChange={(id) => {
              if (id) setPurchaseOrderID(id);
            }}
            options={poOptions}
            disabled={editingDisabled || hasItems}
          />
          <input
            type="hidden"
            id={`po_id_${index}_hidden`}
            name={`fulfillmentExpectations[${index}].purchaseOrderID`}
            ref={register()}
            defaultValue={purchaseOrderID}
          />
        </Field>
      </div>
      <div className="col-md-3">
        <Field error={errors.fulfillmentExpectations} id={`sku_id_${index}`} label="SKU Number" required={true}>
          <SingleSelectFormControl
            id={`sku_id_${index}`}
            value={skuID}
            onChange={(id) => {
              if (id) setSkuID(id);
            }}
            options={skuOptions ?? []}
            disabled={!skuData || editingDisabled || hasItems}
          />
          <input
            type="hidden"
            id={`sku_id_${index}_hidden`}
            name={`fulfillmentExpectations[${index}].skuID`}
            ref={register()}
            value={skuID}
          />
        </Field>
      </div>
      <div className="col-md-2">
        <Field error={errors.fulfillmentExpectations} id={`quantity_${index}`} label="Quantity" required={true}>
          <input
            id={`quantity_${index}`}
            type="number"
            className="form-control"
            defaultValue={item.quantity}
            name={`fulfillmentExpectations[${index}].quantity`}
            ref={register()}
            onWheel={(e) => e.currentTarget.blur()}
            disabled={!skuData || editingDisabled}
          />
        </Field>
      </div>
      <div className="col-md-1">
        <RemoveCargoDetailsButton
          kind="danger"
          onClick={() => removeFulfillmentExpectation(index)}
          disabled={editingDisabled || hasItems}
        >
          Delete
        </RemoveCargoDetailsButton>
      </div>
    </StyledRow>
  );
};

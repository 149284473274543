import React from 'react';

import { displayableText } from '@admin/components/payment_plans/helpers';
import { Maybe, PaymentPlan__Offer__Type as OfferType } from '@admin/schema';

const ACTIVE_TYPES = Object.values(OfferType).filter((type) => type !== OfferType.PayToVacate);

export const OfferTypeInput: React.FC<{
  offerType?: Maybe<OfferType>;
  disabled: boolean;
  onChange(offerType: OfferType): void;
}> = ({ offerType, disabled, onChange }) => (
  <div className="form-group">
    <div className="row">
      <div className="col-md-2">
        <select
          disabled={disabled}
          className="form-control"
          value={offerType || ''}
          required
          onChange={(event) => onChange((event.target.value as OfferType) || undefined)}
        >
          <option value=""></option>
          {ACTIVE_TYPES.map((type) => (
            <option key={type} value={type}>
              {displayableText(type)}
            </option>
          ))}
        </select>
      </div>
    </div>
  </div>
);

import { InventorySummary } from '@admin/components/orders/inventory_summary';
import { Formatter } from '@admin/components/phone/formatter';
import { Order } from '@admin/schema';
import { Panel } from '@shared/components/bootstrap';
import React from 'react';

export const InstructionsPanel: React.FC<{ order: Pick<Order, 'id' | 'contact' | 'otherNote'> }> = ({ order }) => (
  <Panel>
    <Panel.Header>
      <Panel.Title>Appointment Details</Panel.Title>
    </Panel.Header>
    <Panel.Body>
      <InventorySummary orderID={order.id} />
      {order.otherNote && (
        <div className="pad-btm">
          <strong>Appointment Notes:</strong> <div className="preformatted">{order.otherNote}</div>
        </div>
      )}
      {order.contact && (
        <div ng-if="order.contact" className="pad-btm">
          <strong>Day-of Contact:</strong> {order.contact?.name} (Phone: <Formatter number={order.contact.phone} />)
        </div>
      )}
    </Panel.Body>
  </Panel>
);

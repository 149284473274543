import { Files } from '@admin/components/notes/files';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Manager, Selector, Uploader } from '@shared/components/files';
import { useLegacyAttachmentUpload } from '@shared/hooks';
import { DateTime } from 'luxon';
import React, { useState } from 'react';

import { DateTimePicker } from '@admin/components/helpers/date_time_picker';
import { Categories } from '@admin/components/tickets/categories';
import { TicketGroupPicker } from '@admin/components/tickets/ticket_group_picker';
import { Button, ControlLabel, Text } from '@shared/components/bootstrap';
import { client } from '@admin/libraries/apollo';

import { Ticket__TicketableInput, TicketsDocument, useBuildTicketMutation } from '@admin/schema';

const ACCEPT = 'image/*';

export const TicketForm: React.FC<{
  ticketable: Ticket__TicketableInput;
  onClose(): void;
}> = ({ ticketable, onClose }) => {
  const [category, setCategory] = useState<string>('');
  const [subCategory, setSubCategory] = useState<string | undefined>(undefined);
  const [description, setDescription] = useState<string>('');
  const [assignedToLead, setAssignedToLead] = useState<boolean>(false);
  const [groupID, setGroupID] = useState<string | undefined>(undefined);
  const [assigneeID, setAssigneeID] = useState<string | undefined>(undefined);
  const [notify, setNotify] = useState<boolean>(true);
  const [liveNow, setLiveNow] = useState<boolean>(true);
  const [goLiveTime, setGoLiveTime] = useState<DateTime | undefined>(undefined);
  const [dueDate, setDueDate] = useState<DateTime | undefined>(undefined);
  const [uploaded, setUploaded] = useState<
    Array<{
      file: File;
      url: string;
    }>
  >([]);

  const [formError, setFormError] = useState<string | undefined>();

  const [buildTicket, { loading }] = useBuildTicketMutation({
    client,
    refetchQueries: [{ query: TicketsDocument, variables: { ticketable } }],
  });

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const response = await buildTicket({
      variables: {
        input: {
          ticketableType: ticketable.type,
          ticketableID: String(ticketable.id),
          groupID: groupID!,
          assigneeID,
          description,
          category,
          subCategory,
          liveNow,
          goLiveTime: goLiveTime?.toISO(),
          dueDate: dueDate!.toISO(),
          notify,
          attachments: uploaded.map(({ url }) => ({ url })),
        },
      },
    });

    if (response && response.data?.buildTicket.error) {
      setFormError(response.data.buildTicket.error);
      return;
    }
    onClose();
  };

  const onCategoryChange = (newCategory: string, newSubCategory: string) => {
    setCategory(newCategory);
    setSubCategory(newSubCategory);
  };

  const onTicketGroupChange = (newGroupID: string, newAssigneeID: string) => {
    setGroupID(newGroupID);
    setAssigneeID(newAssigneeID);
  };

  const onUploaded = (file: File, url: string) => {
    setUploaded((current) => [...current, { file, url }]);
  };

  const onAssignToLeadChange = (newValue: boolean) => {
    setAssignedToLead(newValue);

    if (newValue && ticketable.associatedLeadUserID) {
      setGroupID(undefined);
      setAssigneeID(ticketable.associatedLeadUserID);
    }
  };

  const showAssignToLeadCheckbox = !!ticketable.associatedLeadUserID;
  const showTicketGroupSelector = !assignedToLead;

  return (
    <form onSubmit={onSubmit}>
      <div className="row">
        <Categories
          className="col-md-6"
          selectedCategory={category}
          selectedSubCategory={subCategory}
          onChange={onCategoryChange}
        />
      </div>
      <div className="mar-top">
        <textarea
          required
          id="ticket-description"
          className="form-control"
          value={description}
          placeholder="Describe the issue"
          onChange={(e) => setDescription(e.target.value)}
        />
      </div>
      {showAssignToLeadCheckbox && (
        <div>
          <ControlLabel>
            <input
              id="ticket-assign-to-lead"
              type="checkbox"
              onChange={(event) => onAssignToLeadChange(event.target.checked)}
              checked={assignedToLead}
            />{' '}
            Assign to Lead
          </ControlLabel>
        </div>
      )}
      {showTicketGroupSelector && (
        <div className="row mar-top">
          <TicketGroupPicker
            className="col-md-6"
            selectedGroupID={groupID}
            selectedAssigneeID={assigneeID}
            onChange={onTicketGroupChange}
          />
        </div>
      )}
      <div className="row mar-top">
        <div className="col-md-2">Set Live</div>
        <div className="col-md-2">
          <ControlLabel>
            <input
              id="ticket-now"
              type="radio"
              checked={liveNow}
              onChange={(event) => setLiveNow(event.target.checked)}
            />{' '}
            Now
          </ControlLabel>
        </div>
        <div className="col-md-1 pad-no">
          <ControlLabel>
            <input
              id="ticket-at"
              type="radio"
              checked={!liveNow}
              onChange={(event) => setLiveNow(!event.target.checked)}
            />{' '}
            At
          </ControlLabel>
        </div>
        <div className="col-md-7">
          <DateTimePicker id="ticket-go-live" dt={goLiveTime} disabled={liveNow} onChange={setGoLiveTime} />
        </div>
      </div>
      <div className="row mar-top">
        <div className="col-md-2">Due Date</div>
        <div className="col-md-10">
          <DateTimePicker id="ticket-due" dt={dueDate} onChange={setDueDate} />
        </div>
      </div>
      <div>
        <ControlLabel>
          <input
            id="ticket-notify"
            type="checkbox"
            onChange={(event) => setNotify(event.target.checked)}
            checked={notify}
          />{' '}
          Notify requestor and assignee on updates
        </ControlLabel>
      </div>
      <Manager onSave={onUploaded}>
        {({ uploads }) => (
          <>
            {!!uploaded.length && (
              <div className="form-group">
                <Files>
                  {uploaded.map((entry) => (
                    <Files.Upload key={entry.url}>
                      <span>{entry.file.name}</span>{' '}
                      <a
                        href="#"
                        onClick={(event) => {
                          event.preventDefault();
                          event.stopPropagation();
                          setUploaded(uploaded.filter(({ url }) => url !== entry.url));
                        }}
                      >
                        <FontAwesomeIcon icon="times" />
                      </a>
                    </Files.Upload>
                  ))}
                </Files>
              </div>
            )}
            {uploads.map((entry) => (
              <Uploader uploader={useLegacyAttachmentUpload} key={entry.uuid} {...entry} />
            ))}
            <div className="form-group">
              <div className="clearfix">
                <div className="pull-left">
                  <label className="btn btn-default">
                    <FontAwesomeIcon icon="paperclip" />
                    <Selector accept={ACCEPT} />
                  </label>
                </div>
              </div>
            </div>
          </>
        )}
      </Manager>
      {formError && (
        <Text tag="p" style="danger" alignment="center">
          <strong>{formError}</strong>
        </Text>
      )}
      <Button className="center-block" kind="primary" type="submit" loading={loading}>
        Create
      </Button>
    </form>
  );
};

import React from 'react';
import { Formik, Form as FormikForm } from 'formik';

import { Status, Hrs__LocationInput, useHrsLocationUpsertMutation } from '@admin/schema';

import { client } from '@admin/libraries/apollo';
import { FormikInputFormGroup } from '@shared/components/fields/formik/formik_input_form_group';
import { Alert, Button, Panel, Modal } from '@shared/components/bootstrap';
import { FormikSelectFormGroup } from '@shared/components/fields/formik/formik_select_form_group';

type HRSLocationRegionType = {
  id: string;
  name: string;
};

export type HRSLocationType = {
  id: string;
  code: string;
  region?: HRSLocationRegionType | null;
};

export const UpsertModal: React.FC<{
  location?: HRSLocationType;
  regionOptions: HRSLocationRegionType[];
  closeModal(): void;
}> = ({ location, regionOptions, closeModal }) => {
  const [save, { loading, data }] = useHrsLocationUpsertMutation({
    client,
    onCompleted: ({ result }) => {
      if (result?.status === Status.Ok) closeModal();
    },
  });

  const onSubmit = (input: Hrs__LocationInput) => {
    save({
      variables: {
        id: location?.id,
        input,
      },
    });
  };

  return (
    <Modal onClose={closeModal}>
      <Formik<Hrs__LocationInput>
        initialValues={{
          code: location?.code ?? '',
          regionID: location?.region?.id,
        }}
        onSubmit={onSubmit}
      >
        <FormikForm>
          <Panel>
            <Panel.Body>
              {data?.result?.error && <Alert style="danger">{data.result.error}</Alert>}
              <FormikInputFormGroup label="Code" name="code" />
              <FormikSelectFormGroup label="Region" name="regionID">
                <option value="">None</option>
                {regionOptions.map((region) => (
                  <option key={region.id} value={region.id}>
                    {region.name}
                  </option>
                ))}
              </FormikSelectFormGroup>
            </Panel.Body>
            <Panel.Footer align="right">
              <Button kind="primary" onClick={closeModal}>
                Cancel
              </Button>
              <Button loading={loading} kind="primary" type="submit">
                Save
              </Button>
            </Panel.Footer>
          </Panel>
        </FormikForm>
      </Formik>
    </Modal>
  );
};

import { Box, FontWeight } from '@clutter/clean';
import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import { ErrorMessage, FormContext, useForm } from 'react-hook-form';

import { detailSelfStorageReservationURL as reservationURL } from '@admin/config/routes';
import { client } from '@admin/libraries/apollo';
import {
  SelfStorage__ReservationFragment,
  SelfStorage__Reservation__State,
  Status,
  useModifySelfStorageThirdPartyReservationMutation,
} from '@admin/schema';
import { Alert, AnchorButton as Link, Button, ControlLabel, FormGroup, HelpBlock } from '@shared/components/bootstrap';

import { ReasonSelector } from './reason_selector';
import { StateSelector } from './state_selector';
import { IFormData } from './types';
import { UploadFileButton } from './upload_file_button';

const Heading = styled.div`
  font-size: 16px;
  font-weight: ${FontWeight.Medium};
  margin-bottom: 8px;
`;

const MISSING_RESERVATION_STATE = 'Please select an option.';
const MISSING_CONFIRMATION_ATTEMPT_DATE = 'Please enter a date.';
const EARLY_CONFIRMATION_ATTEMPT_DATE = 'Please select a date after the reservation was created.';
const MISSING_CONFIRMATION_NUMBER = 'Please enter confirmation number.';
const MISSING_LOST_REASON = 'Please select a reason why the reservation was unsuccessful.';
const MISSING_ATTACHMENT = 'Please include screenshot of reservation from partner site.';
const GENERIC_ERROR = 'Changes were not saved. Please refresh and try again.';

export const Form: React.FC<{ reservation: SelfStorage__ReservationFragment; onSave(): void }> = ({
  reservation,
  onSave,
}) => {
  const [formError, setFormError] = useState<string>('');
  const [modify, { loading }] = useModifySelfStorageThirdPartyReservationMutation({ client });

  const form = useForm<IFormData>({
    defaultValues: {
      confirmationAttemptDate: reservation.confirmationAttemptDate ?? undefined,
    },
  });

  const { handleSubmit, register, unregister, setValue, watch, errors } = form;

  const selectedState = watch('state');

  useEffect(() => {
    if (selectedState !== SelfStorage__Reservation__State.Won) {
      return;
    }
    register('fileData', { required: MISSING_ATTACHMENT });
    return () => {
      unregister('fileData');
    };
  }, [register, unregister, selectedState]);

  const onSubmit = async (formData: IFormData) => {
    const { fileData, ...input } = formData;
    const response = await modify({
      variables: {
        id: reservation.id,
        input: { ...input, signedID: fileData?.signedID },
      },
    });

    if (response.data?.modify.status === Status.Ok) {
      setFormError('');
      onSave();
    } else {
      setFormError(response.data?.modify.error || GENERIC_ERROR);
    }
  };

  return (
    <FormContext {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Heading>Reservation Confirmation</Heading>
        <div className="row">
          <div className="col-md-6">
            <FormGroup has={errors.state ? 'error' : undefined}>
              <ControlLabel htmlFor="state">Was the reservation successful:</ControlLabel>
              <StateSelector id="state" name="state" ref={register({ required: MISSING_RESERVATION_STATE })} />
              <ErrorMessage name="state">{({ message }) => <HelpBlock>{message}</HelpBlock>}</ErrorMessage>
            </FormGroup>
          </div>
          <div className="col-md-6">
            {selectedState === SelfStorage__Reservation__State.Won && (
              <>
                <FormGroup has={errors.externalConfirmationNumber ? 'error' : undefined}>
                  <ControlLabel htmlFor="external-confirmation-number">Confirmation Number:</ControlLabel>
                  <input
                    id="external-confirmation-number"
                    type="text"
                    className="form-control"
                    name="externalConfirmationNumber"
                    ref={register({
                      required: MISSING_CONFIRMATION_NUMBER,
                    })}
                  />
                  <ErrorMessage name="externalConfirmationNumber">
                    {({ message }) => <HelpBlock>{message}</HelpBlock>}
                  </ErrorMessage>
                </FormGroup>
                <FormGroup has={errors.fileData ? 'error' : undefined}>
                  <ControlLabel htmlFor="file">Attachment:</ControlLabel>
                  <UploadFileButton
                    id="file"
                    disabled={loading}
                    file={watch('fileData')?.file}
                    onUpload={(file, signedID) => setValue('fileData', { file, signedID })}
                    onRemoveFile={() => setValue('fileData', undefined)}
                  />
                  <ErrorMessage name="fileData">{({ message }) => <HelpBlock>{message}</HelpBlock>}</ErrorMessage>
                </FormGroup>
              </>
            )}
            {selectedState === SelfStorage__Reservation__State.Lost && (
              <FormGroup has={errors.reason ? 'error' : undefined}>
                <ControlLabel htmlFor="reason">Reason:</ControlLabel>
                <ReasonSelector id="reason" name="reason" ref={register({ required: MISSING_LOST_REASON })} />
                <ErrorMessage name="reason">{({ message }) => <HelpBlock>{message}</HelpBlock>}</ErrorMessage>
              </FormGroup>
            )}
            {selectedState === SelfStorage__Reservation__State.Pending && (
              <FormGroup has={errors.confirmationAttemptDate ? 'error' : undefined}>
                <ControlLabel htmlFor="reservation-confirmation-attempt-date">Confirmation Attempt Date:</ControlLabel>
                <input
                  id="reservation-confirmation-attempt-date"
                  name="confirmationAttemptDate"
                  className="form-control"
                  type="date"
                  min={reservation.createdAt}
                  ref={register({
                    required: MISSING_CONFIRMATION_ATTEMPT_DATE,
                    min: {
                      value: reservation.createdAt,
                      message: EARLY_CONFIRMATION_ATTEMPT_DATE,
                    },
                  })}
                />
                <ErrorMessage name="confirmationAttemptDate">
                  {({ message }) => <HelpBlock>{message}</HelpBlock>}
                </ErrorMessage>
              </FormGroup>
            )}
          </div>
        </div>
        {formError && <Alert style="danger">{formError}</Alert>}
        <Box.Flex flexDirection="row" justifyContent="flex-end" textAlign="right">
          <Box margin="0 8px 0 0">
            <Link kind="default" href={reservationURL(reservation)} disabled={loading}>
              Cancel
            </Link>
          </Box>
          <Box>
            <Button kind="primary" type="submit" loading={loading} disabled={loading}>
              Submit
            </Button>
          </Box>
        </Box.Flex>
      </form>
    </FormContext>
  );
};

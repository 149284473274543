import * as React from 'react';

import { IItemGroup } from '@admin/types';

import { Fit, Picture } from '@shared/components/helpers/picture';

export const Hero: React.FC<{
  selection: IItemGroup;
  width: number;
  height: number;
  className?: string;
}> = ({ selection, width, height, className }) => {
  const image = selection.hero_image;
  const source = image && image.state === 'uploaded' && image.source;

  return source ? (
    <Picture className={className} width={width} height={height} source={source} fit={Fit.Fill} background="FFF" />
  ) : (
    <img className={className} src="/missing.svg" />
  );
};

import React, { useState } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import Select from 'react-select';
import { DateTime } from 'luxon';

import { client } from '@admin/libraries/apollo';
import {
  NewShiftOptionsQuery,
  ShiftAssignmentQuery,
  ShiftQuery,
  useShiftAssignmentLazyQuery,
  Workforce__FacilityFragment,
  Workforce__JobCodeFragment,
  Workforce__ShiftInput,
} from '@admin/schema';
import { Button, Alert, Modal } from '@shared/components/bootstrap';

import { ShiftForm } from './form';

type Shift = ShiftQuery['shift'];
type User = NewShiftOptionsQuery['users'][number];
type ShiftAssignment = ShiftAssignmentQuery['shiftAssignment'];

const DEFAULT_SHIFT_INPUT: Workforce__ShiftInput = {
  id: null,
  userID: '',
  notes: null,
  started: {
    timestamp: '',
    facilityID: '',
  },
  activities: [],
  workBreaks: [],
};

const convertShift = (shift?: Shift): Workforce__ShiftInput => {
  if (shift) {
    return {
      id: shift.id,
      userID: shift.user.id,
      notes: shift.notes,
      workBreaks: shift.workBreaks.map((workBreak) => ({
        id: workBreak.id,
        started: workBreak.started,
        ended: workBreak.ended,
      })),
      started: {
        timestamp: shift.started.timestamp,
        facilityID: shift.started.facility.id,
      },
      ended: shift.ended && {
        timestamp: shift.ended.timestamp,
        facilityID: shift.ended.facility.id,
      },
      activities: shift.activities.map((activity) => ({
        id: activity.id,
        durationMinutes: activity.durationMinutes,
        jobCodeID: activity.jobCode.id,
      })),
    };
  } else {
    return DEFAULT_SHIFT_INPUT;
  }
};

export const ShiftModal: React.FC<{
  shift?: Shift;
  jobCodes: Workforce__JobCodeFragment[];
  users?: User[];
  facilities?: Workforce__FacilityFragment[];
  error: string | undefined;
  loading: boolean;
  editMode: boolean;
  onSave(shift: Workforce__ShiftInput): void;
  onDelete(shiftID: string): void;
  onClose(): void;
}> = ({ shift, jobCodes, users, facilities, error, loading, editMode, onSave, onDelete, onClose }) => {
  const [user, setUser] = useState<User | undefined>(shift?.user);
  const [shiftAssignment, setShiftAssignment] = useState<ShiftAssignment | undefined>(shift?.shiftAssignment);
  const [activityDurationValid, setActivityDurationValid] = useState<boolean>(true);
  const [getShiftAssignment, { loading: assignmentLoading }] = useShiftAssignmentLazyQuery({
    client,
    onCompleted: (data) => setShiftAssignment(data?.shiftAssignment || undefined),
  });
  const shiftInput = convertShift(shift);
  const methods = useForm<Workforce__ShiftInput>({ defaultValues: shiftInput });

  const validateShift = (shiftData: Workforce__ShiftInput): boolean => {
    let valid = true;
    if (!shiftData?.ended?.timestamp && !shiftData.activities) return valid;

    const jobCodeIDs = shiftData.activities.map(({ jobCodeID }) => jobCodeID);
    if (jobCodeIDs.length === new Set(jobCodeIDs).size && jobCodeIDs.every((id) => !!id)) {
      methods.clearError('jobCodeID');
    } else if (jobCodeIDs.some((id) => !id)) {
      methods.setError('jobCodeID', 'manual', 'Each split must have a job code');
      valid = false;
    } else {
      methods.setError('jobCodeID', 'manual', 'Each split must have a different job code from the others');
      valid = false;
    }

    if (activityDurationValid) {
      methods.clearError('durationMinutes');
    } else {
      methods.setError('durationMinutes', 'manual', 'Total hours from splits must equal total shift hours');
      valid = false;
    }

    return valid;
  };

  const onSubmit = (shiftData: Workforce__ShiftInput) => {
    if (validateShift(shiftData)) {
      onSave(shiftData);
    }
  };

  return (
    <Modal onClose={onClose}>
      <Modal.Content>
        <FormContext {...methods}>
          <form id="shiftForm" onSubmit={methods.handleSubmit(onSubmit)}>
            <Modal.Header>
              <Modal.Title>{editMode ? 'Edit Shift - ' + shift!.user.name : 'Add Shift'}</Modal.Title>
              {!editMode && users && (
                <Select
                  id="user_id"
                  value={{ value: user?.id, label: user?.name, data: user }}
                  onChange={(el: any) => {
                    setUser(el?.data || undefined);
                    getShiftAssignment({ variables: { userID: el.value, date: DateTime.local().toISODate() } });
                  }}
                  options={users.map((u) => ({ value: u.id, label: u.name, data: u }))}
                />
              )}
              <Modal.Close close={onClose} />
            </Modal.Header>
            <Modal.Body>
              {error && <Alert style="danger">{error}</Alert>}
              {user && !assignmentLoading && (
                <ShiftForm
                  shift={shiftInput}
                  originalJobCode={shiftAssignment?.jobCode}
                  originalFacilityID={shiftAssignment?.facility?.id}
                  userID={user.id}
                  tz={user.tz}
                  jobCodes={jobCodes}
                  facilities={facilities}
                  setActivityDurationValid={setActivityDurationValid}
                />
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={() => onDelete(shift!.id)}
                className="pull-left"
                size="lg"
                kind="danger"
                disabled={loading || !editMode || !!shift!.deletedAt || !!shift!.approvedAt}
              >
                Delete Shift
              </Button>
              <Button onClick={onClose} size="lg" kind="secondary" disabled={loading} outline>
                Cancel
              </Button>
              <Button type="submit" size="lg" kind="primary" disabled={loading || !!shift?.deletedAt}>
                Save Shift
              </Button>
            </Modal.Footer>
          </form>
        </FormContext>
      </Modal.Content>
    </Modal>
  );
};

(function () {
  angular.module('app').factory('TicketGroup', [
    '$resource',
    function ($resource) {
      return $resource(
        '/ticket_groups/:id.json',
        {
          id: '@id',
        },
        {
          update: {
            method: 'PATCH',
          },
          create: {
            method: 'POST',
          },
        },
      );
    },
  ]);
})();

import React from 'react';

import { Availability__FacilityCuftLimitFiltersInput } from '@admin/schema';
import { Col, Row } from '@shared/components/bootstrap';
import { FieldFormGroup, InputFormGroup } from '@admin/components/fields';
import { Selector as WarehouseSelector } from '@admin/components/warehouses/selector';

export const Filters: React.FC<{
  filters: Availability__FacilityCuftLimitFiltersInput;
  onChange(filters: Availability__FacilityCuftLimitFiltersInput): void;
}> = ({ filters, onChange }) => (
  <Row>
    <Col md={3}>
      <InputFormGroup
        id="from_date"
        label="From Date:"
        type="date"
        min="0000-01-01"
        max="9999-12-31"
        value={filters.fromDate || ''}
        onChange={(event) => onChange({ ...filters, fromDate: event.target.value || '' })}
      />
    </Col>
    <Col md={3}>
      <InputFormGroup
        id="till_date"
        label="Till Date:"
        type="date"
        min="0000-01-01"
        max="9999-12-31"
        value={filters.tillDate || ''}
        onChange={(event) => onChange({ ...filters, tillDate: event.target.value || '' })}
      />
    </Col>
    <Col md={6}>
      <FieldFormGroup label="Warehouses:">
        <WarehouseSelector
          selectedIDs={filters.warehouseIDs}
          onChange={(warehouseIDs) => onChange({ ...filters, warehouseIDs })}
        />
      </FieldFormGroup>
    </Col>
  </Row>
);

import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';

import { client } from '@admin/libraries/apollo';
import { useOrderTermContractsQuery, OrderSubtypeEnum } from '@admin/schema';
import { currency } from '@shared/utils/currency';
import { fulfilledTermCommitment } from '@shared/utils/fulfilled_term_commitment';

const TerminationAgreement: React.FC<{
  checked?: boolean;
  contractDuration: number;
  earlyTerminationFee: number;
  onChange(checked: boolean): void;
}> = ({ checked, contractDuration, earlyTerminationFee, onChange }) => (
  <label>
    <input type="checkbox" checked={checked || false} onChange={(event) => onChange(event.target.checked)} /> I
    authorize Clutter to charge me the {currency(earlyTerminationFee)} early account closure fee for closing my account
    in less than {contractDuration} days.
  </label>
);

const TermCommitment: React.FC<{
  checked?: boolean;
  onChange(checked: boolean): void;
}> = ({ checked, onChange }) => (
  <label>
    <input type="checkbox" checked={checked || false} onChange={(event) => onChange(event.target.checked)} /> This final
    return is being booked before the term commitment has been met. Check here to confirm that you have charged the
    remaining fees due in the term. Bookings will be checked for accurate payment.
  </label>
);

export const TermContracts: React.FC<{
  accountID: string;
  orderScheduledDate: Date;
  setIsEarlyTermination(earlyTermination: boolean): void;
  setDisclaimer(agreedDisclaimer: boolean): boolean;
}> = ({ accountID, orderScheduledDate, setIsEarlyTermination, setDisclaimer }) => {
  const [unfulfilledTerminationAgreement, setUnfulfilledTerminationAgreement] = useState<boolean>(false);
  const [unfulfilledTermCommitment, setUnfulfilledTermCommitment] = useState<boolean>(false);
  const [agreedTerminationAgreementDisclaimer, setAgreedTerminationAgreementDisclaimer] = useState<boolean>(false);
  const [agreedTermCommitmentDisclaimer, setAgreedTermCommitmentDisclaimer] = useState<boolean>(false);

  const { data } = useOrderTermContractsQuery({ client, variables: { accountID } });

  useEffect(() => {
    if (!orderScheduledDate) {
      return;
    }
    const parsedScheduledDate = DateTime.fromJSDate(orderScheduledDate);
    if (data?.account?.terminationAgreement) {
      const contractEndDate = data.account.terminationAgreement.contractEndDate;
      const earlyAgreementTermination = contractEndDate
        ? parsedScheduledDate < DateTime.fromISO(contractEndDate)
        : false;
      setUnfulfilledTerminationAgreement(earlyAgreementTermination);
    }
    if (data?.account?.latestTermCommitment) {
      const latestTermCommitment = data.account.latestTermCommitment;
      const fulfilledCommitment = fulfilledTermCommitment(
        parsedScheduledDate,
        OrderSubtypeEnum.Final,
        latestTermCommitment?.contractEndDate ?? undefined,
      );
      setUnfulfilledTermCommitment(!fulfilledCommitment);
    }
  }, [data, orderScheduledDate]);

  useEffect(() => {
    setIsEarlyTermination(unfulfilledTerminationAgreement || unfulfilledTermCommitment);
  }, [unfulfilledTerminationAgreement, unfulfilledTermCommitment]);

  useEffect(() => {
    setDisclaimer(
      unfulfilledTerminationAgreement === agreedTerminationAgreementDisclaimer &&
        unfulfilledTermCommitment === agreedTermCommitmentDisclaimer,
    );
  }, [agreedTerminationAgreementDisclaimer, agreedTermCommitmentDisclaimer]);

  if (!data?.account.terminationAgreement && !data?.account.latestTermCommitment) {
    return null;
  }

  const terminationAgreement = data?.account.terminationAgreement;

  return (
    <div>
      {unfulfilledTerminationAgreement && (
        <TerminationAgreement
          checked={agreedTerminationAgreementDisclaimer}
          contractDuration={terminationAgreement!.contractDuration}
          earlyTerminationFee={terminationAgreement!.earlyTerminationFee}
          onChange={setAgreedTerminationAgreementDisclaimer}
        />
      )}
      {unfulfilledTermCommitment && (
        <TermCommitment checked={agreedTermCommitmentDisclaimer} onChange={setAgreedTermCommitmentDisclaimer} />
      )}
    </div>
  );
};

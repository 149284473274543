import React from 'react';

import { useField } from 'formik';
import { DurationInput } from '@admin/components/fields';
import { FormikFieldFormGroup } from '@shared/components/fields/formik/formik_field_form_group';
import { FieldFormGroupProps } from '@shared/components/fields/field_form_group';

type InputFormGroupProps = Omit<FieldFormGroupProps, 'has'> & { unit: 'seconds' | 'minutes'; name: string };

export const FormikDurationFormGroup: React.FC<InputFormGroupProps> = ({ name, unit, ...props }) => {
  const [{ value }, _1, helpers] = useField(name);

  return (
    <FormikFieldFormGroup name={name} {...props}>
      <DurationInput
        duration={value}
        unit={unit}
        onChange={(newValue) => {
          helpers.setValue(newValue);
        }}
      />
    </FormikFieldFormGroup>
  );
};

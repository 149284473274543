import { take } from 'lodash';
import * as React from 'react';
import { useState } from 'react';

import { NoteFragment } from '@admin/schema';

import { ListGroup } from '@shared/components/bootstrap';

import { Entry } from './entry';

const DEFAULT_LIMIT = 5;

export const List: React.FC<{
  id: string;
  type: string;
  notes?: NoteFragment[];
}> = ({ id, type, notes }) => {
  const [limit, setLimit] = useState<number | undefined>(DEFAULT_LIMIT);
  const more = () => setLimit(undefined);
  const less = () => setLimit(DEFAULT_LIMIT);

  return (
    <>
      {notes && (
        <ListGroup>
          {(limit ? take(notes, limit) : notes).map((note) => (
            <ListGroup.Item key={note.id}>
              <Entry note={note} supplementary={note.notable.id !== String(id) || note.notable.type !== String(type)} />
            </ListGroup.Item>
          ))}
        </ListGroup>
      )}
      {notes && notes.length > DEFAULT_LIMIT && (
        <p className="text-center">
          <button type="button" onClick={limit ? more : less} className="btn btn-default">
            Show {limit ? 'All' : 'Fewer'} Notes
          </button>
        </p>
      )}
    </>
  );
};

import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Checkbox } from '@shared/components/bootstrap';
import { Spacer } from '@shared/components/helpers';

import { DEFAULT_STATES } from '@admin/constants/geography';
import { mask, parse, REGEX } from '@admin/utils/phone';
import { Section } from '../components/section';
import { SectionHeading } from '../components/section_heading';
import { Field } from './field';

import { FormData } from './form/types';

type FieldsData = Pick<FormData, 'military' | 'identification' | 'phones'>;

export const Additional: React.FC = () => {
  const { register, watch } = useFormContext<FieldsData>();

  return (
    <Section>
      <SectionHeading>Additional Details</SectionHeading>
      <div className="row">
        <div className="col-md-6">
          <Field required id="phones-cell" name="phones.cell" label="Cell Phone Number">
            <input
              id="phones-cell"
              name="phones.cell"
              className="form-control"
              type="text"
              ref={register({
                required: !watch('phones.home') && 'Please provide a cell or home phone number',
                pattern: {
                  value: REGEX,
                  message: 'Please enter a valid cell phone number',
                },
              })}
              onChange={(event) => {
                event.target.value = mask(parse(event.target.value));
              }}
            />
          </Field>
        </div>
        <div className="col-md-6">
          <Field required id="phones-home" name="phones.home" label="Home Phone Number">
            <input
              id="phones-home"
              name="phones.home"
              className="form-control"
              type="text"
              ref={register({
                required: !watch('phones.cell') && 'Please provide a cell or home phone number',
                pattern: {
                  value: REGEX,
                  message: 'Please enter a valid home phone number',
                },
              })}
              onChange={(event) => {
                event.target.value = mask(parse(event.target.value));
              }}
            />
          </Field>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <Field required id="identification-number" name="identification.number" label="ID #">
            <input
              id="identification-number"
              name="identification.number"
              className="form-control"
              type="text"
              ref={register({
                required: 'Please provide a ID #',
              })}
            />
          </Field>
        </div>
        <div className="col-md-4">
          <Field required id="identification-state" name="identification.state" label="State">
            <select
              id="identification-state"
              name="identification.state"
              className="form-control"
              ref={register({
                required: 'Please provide a state',
              })}
            >
              <option value=""> - State - </option>
              {DEFAULT_STATES.map((state, key) => (
                <option value={state} key={key}>
                  {state}
                </option>
              ))}
            </select>
          </Field>
        </div>
        <div className="col-md-4">
          <Field required id="identification-birthday" name="identification.birthday" label="Date of Birth">
            <input
              id="identification-birthday"
              name="identification.birthday"
              className="form-control"
              type="date"
              ref={register({
                required: 'Please provide a birthday',
              })}
            />
          </Field>
        </div>
      </div>
      <Checkbox>
        <input type="checkbox" name="military" ref={register} />
        <strong>Military:</strong> Check if tenant or spouse is on active duty military?
      </Checkbox>
      <Spacer height="24px" />
    </Section>
  );
};

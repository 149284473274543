(function () {
  const app = angular.module('app');

  app.directive('affiliateInvoiceTable', [
    function () {
      return {
        restrict: 'E',
        templateUrl: function (elem, attrs) {
          return attrs.templateUrl;
        },
        scope: {
          affiliateInvoices: '=',
          pay: '=',
        },
      };
    },
  ]);
})();

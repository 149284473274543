import styled from '@emotion/styled';
import { DateTime, Duration } from 'luxon';
import * as React from 'react';

import { client } from '@admin/libraries/apollo';

import { Spinner } from '@admin/components/spinner';
import { Spacer } from '@shared/components/helpers';

import { useOrderLaborClocksQuery, LaborClock } from '@admin/schema';
import { Button } from '@shared/components/bootstrap';
import { Row } from './table/row';
import { CreateRow } from './table/create_row';

const SpinnerWrapper = styled.div`
  display: flex;
`;

export const Table: React.FC<{
  orderID: string;
}> = ({ orderID }) => {
  const { data, loading } = useOrderLaborClocksQuery({ client, variables: { orderID }, fetchPolicy: 'network-only' });

  const laborClocks = data && [...data.order.laborClocks];
  const laborClockUserIDs = laborClocks?.map((laborClock) => laborClock.user.id);
  const assignmentsWithoutLaborClocks = data?.order.dispatch?.assignments.filter(
    (assignment) => !laborClockUserIDs?.includes(assignment.user.id),
  );
  const defaultDate = data?.order.scheduled ? DateTime.fromISO(data.order.scheduled) : undefined;
  const tz = data?.order?.region?.tz;

  const [adding, setAdding] = React.useState(false);

  const getTotalDurationInMilliseconds = () =>
    laborClocks
      ?.filter((laborClock: Pick<LaborClock, 'started' | 'ended'>) => laborClock.started && laborClock.ended)
      .map(
        (laborClock: Pick<LaborClock, 'started' | 'ended'>) =>
          DateTime.fromISO(laborClock.ended!).diff(DateTime.fromISO(laborClock.started!)).milliseconds,
      )
      .reduce((a, b) => a + b, 0);

  return (
    <>
      {loading && (
        <>
          <SpinnerWrapper>
            <Spinner />
          </SpinnerWrapper>
          <Spacer height="8px" />
        </>
      )}
      <>
        <div>
          <b>Note:</b> Changing labor clocks will not update labor invoices that have already been created. If you need
          to re-bill for labor, create a tech support ticket
        </div>
        <div className="table-responsive">
          <table className="table table-striped">
            <thead>
              <tr>
                <th className="text-left col-sm-3">Name</th>
                <th className="text-left col-sm-1">Role</th>
                <th className="text-left col-sm-2">Started</th>
                <th className="text-left col-sm-2">Ended</th>
                <th className="text-left col-sm-1">Duration</th>
                <th className="text-left col-sm-3" />
              </tr>
            </thead>
            <tbody>
              {laborClocks &&
                laborClocks
                  .sort((a: Pick<LaborClock, 'id'>, b: Pick<LaborClock, 'id'>) => parseInt(a.id) - parseInt(b.id))
                  .map((laborClock, index) => (
                    <Row key={index} laborClock={laborClock} defaultDate={defaultDate} tz={tz} />
                  ))}
              {adding && (
                <CreateRow
                  orderID={orderID}
                  assignmentsWithoutLaborClocks={assignmentsWithoutLaborClocks}
                  defaultDate={defaultDate}
                  tz={tz}
                  setEditing={setAdding}
                />
              )}
              <tr>
                <td className="text-left col-sm-9" colSpan={4}>
                  Total
                </td>
                <td className="text-left col-sm-1">
                  {getTotalDurationInMilliseconds() &&
                    Duration.fromObject({ milliseconds: getTotalDurationInMilliseconds() }).toFormat('hh:mm:ss')}
                </td>
                <td className="text-right col-sm-3">
                  {!adding && assignmentsWithoutLaborClocks && assignmentsWithoutLaborClocks.length > 0 && (
                    <Button kind="primary" onClick={() => setAdding(true)}>
                      Add
                    </Button>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </>
    </>
  );
};

import * as React from 'react';
import { useMarkMessagesReadMutation, useTicketMessagesQuery } from '@admin/schema';
import { client } from '@admin/libraries/apollo';
import { Spinner } from '@admin/components/spinner';
import { Spacer } from '@shared/components/helpers/spacer';
import { useEffect } from 'react';
import { currentUser } from '@admin/config/current_user';
import { TicketMessagesForm } from './form';
import { List } from './list';
import { Refresh } from '../refresh';

export const Widget: React.FC<{
  id: string;
}> = ({ id }) => {
  const { data, loading, refetch } = useTicketMessagesQuery({
    client,
    variables: {
      ticketID: id,
    },
  });

  const messages = data && data.ticket.messages.slice().sort((a, b) => Number(b.id) - Number(a.id));

  const [markAsRead] = useMarkMessagesReadMutation({ client });
  useEffect(() => {
    const messagesToMarkAsRead =
      messages?.filter(
        (message) =>
          (message.readAt === undefined || message.readAt === null) &&
          (currentUser.id === Number(message.ticket.assignee?.id) ||
            currentUser.id === Number(message.ticket.owner.id)) &&
          currentUser.id !== Number(message.user.id),
      ) ?? [];

    if (messagesToMarkAsRead.length === 0) return;

    markAsRead({
      variables: { ids: messagesToMarkAsRead.map((message) => message.id) },
    });
  }, [markAsRead, messages]);

  return (
    <>
      <Refresh refetch={refetch} intervalSeconds={15} />
      <Spacer height="1rem" />
      <TicketMessagesForm id={id} onSave={refetch} />
      {loading && <Spinner />}
      {messages && <List messages={messages} />}
    </>
  );
};

import { capitalize } from 'lodash';
import { DateTime } from 'luxon';
import React, { useState } from 'react';

import { client } from '@admin/libraries/apollo';
import {
  Account,
  Barcode,
  Item,
  useItemManualReturnMutation,
  ItemStateEnum as Item__State,
  Scalars,
  useOrdersSelectorQuery,
  OrderTypeEnum as Order__Type,
  OrderStateEnum as Order__State,
} from '@admin/schema';
import { UserRole } from '@admin/types';

import { Button, Modal, Radio } from '@shared/components/bootstrap';
import { FieldFormGroup } from '@admin/components/fields';
import { Roles } from '@admin/components/helpers/roles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons';

const FINAL_ITEM_STATES = [Item__State.Auctioned, Item__State.Disposed, Item__State.Returned];

type ItemFragment = Pick<Item, 'id' | 'state'> & {
  account: Pick<Account, 'id'>;
  barcode: Pick<Barcode, 'id' | 'value'>;
};

const Dialog: React.FC<{
  item: ItemFragment;
  onClose(): void;
}> = ({
  item: {
    id: itemID,
    barcode,
    account: { id: accountID },
  },
  onClose,
}) => {
  const [orderID, setOrderID] = useState<Scalars['ID'] | undefined>();
  const [execute, { loading }] = useItemManualReturnMutation({ client });
  const { data } = useOrdersSelectorQuery({ client, variables: { accountID } });
  const orders = data?.orders?.filter(
    ({ type, state }) => type === Order__Type.Return && state === Order__State.Completed,
  );

  const onConfirm = () => {
    if (!orderID) return;
    if (loading) return;

    execute({
      variables: {
        itemID,
        orderID,
      },
    });
  };

  return (
    <Modal onClose={onClose}>
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>
            Are you sure you want to manually return <strong>#{barcode.value}</strong>?
          </Modal.Title>
          <Modal.Close close={onClose} />
        </Modal.Header>
        <Modal.Body>
          <p>
            Marking an item as returned removes it from it's current location and requires attaching it to a completed
            order. Please choose the order this item was returned to the customer on:
          </p>
          <FieldFormGroup>
            {orders?.map((order) => (
              <Radio key={order.id}>
                <input
                  type="radio"
                  value={order.id}
                  checked={orderID === order.id}
                  onChange={(event) => {
                    if (event.target.checked) {
                      setOrderID(order.id);
                    }
                  }}
                />
                <strong>#{order.number}</strong>
                <> - </>
                {DateTime.fromISO(order.scheduled).toLocaleString(DateTime.DATETIME_MED)}
                <> - </>
                {capitalize(order.type)}
                <> - </>
                {capitalize(order.subtype)}
                <> - </>
                <a className="btn-link" href={`/accounts/${accountID}/orders/${order.id}`} target="_blank">
                  <FontAwesomeIcon icon={faLink} />
                </a>
              </Radio>
            ))}
          </FieldFormGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button kind="default" onClick={onClose} disabled={loading}>
            Cancel
          </Button>
          <Button kind="danger" onClick={onConfirm} loading={loading} disabled={!orderID}>
            Mark as Returned
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};

export const ItemManualReturnButton: React.FC<{
  item: ItemFragment;
}> = ({ item }) => {
  const [visible, setVisible] = useState<boolean>(false);

  const onClick = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  if (FINAL_ITEM_STATES.includes(item.state)) return null;

  return (
    <Roles show={[UserRole.Admin, UserRole.DataCorrector]}>
      {visible && <Dialog item={item} onClose={onClose} />}

      <Button kind="warning" onClick={onClick}>
        Mark as Returned
      </Button>
    </Roles>
  );
};

import React from 'react';

import { accountURL, accountsURL } from '@admin/config/routes';
import { Admin__PaymentPlan__AccountFragment as PaymentPlanAccount } from '@admin/schema';
import { Breadcrumb } from '@shared/components/bootstrap';

export const Breadcrumbs: React.FC<{
  account: PaymentPlanAccount;
  newPaymentPlan?: boolean;
}> = ({ account, newPaymentPlan = false }) => (
  <div className="text-overflow pad-top pad-btm page-header">
    <Breadcrumb>
      <Breadcrumb.Item>
        <a href={accountsURL()}>Accounts</a>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        <a href={accountURL(account)}>
          #{account.number} ({account.customer.name})
        </a>
      </Breadcrumb.Item>
      <Breadcrumb.Item active>{newPaymentPlan && 'New'} Payment Plan </Breadcrumb.Item>
    </Breadcrumb>
  </div>
);

import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { capitalize } from 'lodash';
import * as React from 'react';

import { Summary as PriceSummary } from '@admin/components/self_storage/prices/summary';
import { Summary as PromotionSummary } from '@admin/components/self_storage/promotions/summary';

import { COLORS } from '@root/colors';

import { SelfStorage__UnitGroupFragment } from '@admin/schema';

import { Spacer } from '@shared/components/helpers';

const Card = styled.button<{
  selected?: boolean;
}>`
  cursor: pointer;
  width: 160px;
  padding: 12px;
  color: ${({ selected }) => (selected ? COLORS.white : COLORS.black)};
  background-color: ${({ selected }) => (selected ? COLORS.teal : COLORS.white)};
  border-color: ${({ selected }) => (selected ? COLORS.tealDarkest : COLORS.grayLightest)};
  border-radius: 10px;
  border-style: solid;
  border-width: 1px;
  text-align: center;
`;

const CardSize = styled.div`
  font-size: 14px;
`;

const CardPrice = styled.div`
  font-size: 16px;
`;

const CardTerm = styled.div`
  font-size: 10px;
`;

const CardPromotion = styled.div<{ selected?: boolean }>`
  color: ${({ selected }) => (selected ? COLORS.white : COLORS.green)};
  font-size: 12px;
`;

const CardCategory = styled.div`
  font-size: 10px;
`;

const CardSeparator = styled.hr`
  margin: 4px 0;
`;

const CardFeatures = styled.div`
  margin: 4px 0 0;
`;

const CardFeature = styled.div`
  font-size: 10px;
  margin: -4px;
  display: flex;
`;

const CardFeatureIcon = styled.div`
  padding: 4px;
`;

const CardFeatureText = styled.div`
  padding: 4px;
`;

export const Entry: React.FC<{
  group: SelfStorage__UnitGroupFragment;
  selected: boolean;
  onSelect(): void;
}> = ({ group: { classification, price, promotion }, selected, onSelect }) => (
  <Card
    type="button"
    onClick={(event) => {
      event.preventDefault();
      event.stopPropagation();
      onSelect();
    }}
    selected={selected}
  >
    {promotion && (
      <>
        <CardPromotion selected={selected}>
          <PromotionSummary promotion={promotion} />
        </CardPromotion>
        <Spacer height="4px" />
      </>
    )}
    <CardSize>
      {classification.kind.subtype ? (
        capitalize(classification.kind.subtype)
      ) : (
        <>
          {classification.width}′ × {classification.length}′ × {classification.height}
        </>
      )}
    </CardSize>
    <CardSeparator />
    <CardPrice>
      <PriceSummary price={price} />
    </CardPrice>
    <CardTerm>per month</CardTerm>
    <CardSeparator />
    <CardCategory>{classification.kind.name}</CardCategory>
    <CardFeatures>
      <CardFeature>
        <CardFeatureText>{classification.firstFloor ? 'Ground' : 'Elevator'}</CardFeatureText>
      </CardFeature>
      {classification.obstructed && (
        <CardFeature>
          <CardFeatureText>Obstructed</CardFeatureText>
        </CardFeature>
      )}
      <CardFeature>
        <CardFeatureIcon>
          <FontAwesomeIcon icon={classification.indoors ? 'sign-in-alt' : 'sign-out-alt'} />
        </CardFeatureIcon>
        <CardFeatureText>{classification.indoors ? 'Indoors' : 'Outdoors'}</CardFeatureText>
      </CardFeature>
      {classification.climate && (
        <CardFeature>
          <CardFeatureIcon>
            <FontAwesomeIcon icon="thermometer-half" />
          </CardFeatureIcon>
          <CardFeatureText>Climate</CardFeatureText>
        </CardFeature>
      )}
    </CardFeatures>
  </Card>
);

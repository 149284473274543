import * as React from 'react';
import styled from '@emotion/styled';
import { max, min } from 'lodash';
import { DateTime } from 'luxon';
import { scaleLinear, ScaleLinear } from 'd3-scale';
import { SkeletonLoader } from '@clutter/clean';

import { ScaleContext, TimeZoneContext } from './context';
import { Row } from './row';
import { GanttEvent, GanttRow } from './types';
import { TimeAxis } from './time_axis';

const getScale = (timestamps: DateTime[]): ScaleLinear<number, number> => {
  const start = min(timestamps)!.startOf('hour');
  const end = max(timestamps)!.endOf('hour').plus(1); // Round up to hour by adding 1 millisecond

  return scaleLinear().domain([start, end]).range([0, 100]);
};

const Empty = () => <p>No events.</p>;

const Container = styled.div`
  position: relative;

  .tooltip {
    min-width: 250px;
  }
`;

const RowsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
`;

const LoadingContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
`;

const Loader = styled(SkeletonLoader)`
  width: 100%;
  height: 100%;
  background: gray;
  opacity: 0.3;
`;

export const GanttChart: React.FC<{
  rows: GanttRow[];
  tz: string;
  loading?: boolean;
}> = ({ rows, tz, loading = false }) => {
  const timestamps = rows
    .reduce((memo, { events }) => events.concat(memo), [] as GanttEvent[])
    .reduce((memo, { startTimestamp, endTimestamp }) => memo.concat([startTimestamp, endTimestamp]), [] as DateTime[]);

  const scale = getScale(timestamps);

  if (timestamps.length <= 1) {
    return <Empty />;
  }

  return (
    <TimeZoneContext.Provider value={tz}>
      <ScaleContext.Provider value={scale}>
        <Container>
          {loading && (
            <LoadingContainer>
              <Loader />
            </LoadingContainer>
          )}
          <RowsContainer>
            {rows.map((row) => (
              <Row key={row.title} row={row} />
            ))}
          </RowsContainer>
          <TimeAxis />
        </Container>
      </ScaleContext.Provider>
    </TimeZoneContext.Provider>
  );
};

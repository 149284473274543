import { getOrdinalSuffix } from '@shared/utils/get_ordinal_suffix';

import { Maybe, SelfStorage__Unit, SelfStorage__Classification } from '@admin/schema';

export const getSizeForUnit = ({
  classification,
}: {
  classification?: Maybe<Pick<SelfStorage__Classification, 'width' | 'length' | 'height'>>;
}) => {
  const length = classification?.length;
  const width = classification?.width;
  const height = classification?.height;
  return (
    typeof width === 'number' &&
    typeof length === 'number' &&
    typeof height === 'number' &&
    `${width}×${length}×${height}`
  );
};

export const getLocationForUnit = ({
  classification,
  floor,
}: Pick<SelfStorage__Unit, 'floor'> & {
  classification?: Maybe<Pick<SelfStorage__Classification, 'indoors'>>;
}) => {
  const indoors = classification?.indoors;
  const floorLabel =
    typeof floor === 'number' && (floor === 0 ? 'Ground Floor' : `${floor}${getOrdinalSuffix(floor)} Floor`);
  const indoorsLabel = typeof indoors === 'boolean' ? (indoors ? 'Indoors' : 'Outdoors') : undefined;

  return indoorsLabel && `${indoorsLabel}${floorLabel && ` (${floorLabel})`}`;
};

export const getClimateForUnit = ({
  classification,
}: {
  classification?: Maybe<Pick<SelfStorage__Classification, 'climate'>>;
}) => {
  const climate = classification?.climate;
  if (typeof climate !== 'boolean') {
    return 'Unknown';
  }

  return climate ? 'Climate Controlled' : 'None';
};

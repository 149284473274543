import angular from 'angular';

angular.module('app').filter('truncate', [
  '$filter',
  ($filter) => (input, limit) => {
    let text = $filter('limitTo')(input, limit);
    if (input.length > limit) {
      text += '…';
    }
    return text;
  },
]);

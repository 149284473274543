import * as React from 'react';

import styled from '@emotion/styled';

import {
  Changed__Association__Kind,
  Changed__Event,
  HistoryResourceChangedAuditedFragment,
  HistoryResourceChangedAuditFragment,
  HistoryResourceChangedFieldFragment,
  HistoryResourceChangedRelationshipFragment,
} from '@admin/schema';

import { Distance } from '@admin/components/helpers/distance';
import { Titleize } from '@admin/components/helpers/titleize';
import { Timestamp } from '@admin/components/timestamp';
import { palletURL } from '@admin/config/routes';

import { Spacer } from '@shared/components/helpers';

import { Autoformat } from './audit/autoformat';
import { CUFT } from './audit/cuft';
import { Duration, Durations } from './audit/duration';
import { Phone } from './audit/phone';
import { Tags } from './audit/tags';

const Link = styled.a`
  color: #3891d1;
  text-decoration: underline;
`;

const PRIMARY_EMAIL_FIELD_NAME = 'primary_email';
const PRIMARY_PHONE_FIELD_NAME = 'primary';

const Change: React.FC<{
  name: string;
  value: string | number | boolean | string[] | number[] | boolean[] | null;
  timezone?: string;
}> = ({ name, value, timezone }) => {
  switch (name) {
    case 'tags':
      return <Tags value={value as string[]} />;
    case 'phone':
      return <Phone value={value as string} />;
    case 'break':
      return <Duration value={value as number} />;
    case 'estimated_duration':
      return <Duration value={value as number} />;
    case 'estimated_distance':
      return <Distance value={value as number} />;
    case 'estimated_cuft':
      return <CUFT value={value as number} />;
    case 'estimated_mover_durations':
      return <Durations values={value as number[]} />;
    default:
      return <Autoformat value={value} timezone={timezone} />;
  }
};

const Field: React.FC<{
  audit: HistoryResourceChangedAuditFragment;
  field: HistoryResourceChangedFieldFragment;
  timezone?: string;
}> = ({ audit, field, timezone }) => {
  if (audit.audited.__typename === 'Changed__Phone' && field.name === PRIMARY_PHONE_FIELD_NAME) {
    const changed = audit.fields.find(({ name }) => name === 'number');
    if (field.was) {
      const phone = (changed && changed.was) || audit.audited.number;
      return (
        <>
          The <strong>primary phone</strong> for this account was previously <strong>{phone}</strong>
        </>
      );
    }
    if (field.now) {
      const phone = (changed && changed.now) || audit.audited.number;
      return (
        <>
          The <strong>primary phone</strong> for this account is now <strong>{phone}</strong>
        </>
      );
    }
  }

  if (audit.audited.__typename === 'Changed__Customer' && field.name === PRIMARY_EMAIL_FIELD_NAME) {
    const changed = audit.fields.find(({ name }) => name === 'email');

    if (field.was) {
      const email = (changed && changed.was) || audit.audited.email;
      return (
        <>
          The <strong>primary email</strong> for this account was previously <strong>{email}</strong>
        </>
      );
    }
    if (field.now) {
      const email = (changed && changed.now) || audit.audited.email;
      return (
        <>
          The <strong>primary email</strong> for this account is now <strong>{email}</strong>
        </>
      );
    }
  }

  return (
    <>
      <strong>
        {field.name === 'tags' && 'Account'} <Titleize text={field.name} />
      </strong>
      {field.was !== null && (
        <>
          {' '}
          <span>was</span>{' '}
          <strong>
            <Change value={field.was} name={field.name} timezone={timezone} />
          </strong>
        </>
      )}
      {field.now !== null && (
        <>
          {' '}
          <span>now</span>{' '}
          <strong>
            <Change value={field.now} name={field.name} timezone={timezone} />
          </strong>
        </>
      )}
    </>
  );
};

const AssociatedLink: React.FC<{
  relationshipName: string;
  associated: {
    id: string;
    name: string;
  };
}> = ({ relationshipName, associated }) => {
  switch (relationshipName) {
    case 'pallets':
      return <Link href={palletURL(associated.id)}>{associated.name}</Link>;
    default:
      return <strong>{associated.name}</strong>;
  }
};

const Relationship: React.FC<{
  relationship: HistoryResourceChangedRelationshipFragment;
}> = ({ relationship }) => (
  <>
    <strong>
      <Titleize text={relationship.name} />
    </strong>{' '}
    <span>changed</span>{' '}
    {relationship.associations.map((association, subindex) => (
      <span key={subindex}>
        {' '}
        <span>
          <span>
            {association.kind === Changed__Association__Kind.Add && <span className="text-success">(added)</span>}
            {association.kind === Changed__Association__Kind.Remove && <span className="text-danger">(removed)</span>}
          </span>{' '}
          <AssociatedLink relationshipName={relationship.name} associated={association.associated} />
        </span>
      </span>
    ))}
  </>
);

const AuditedType: React.FC<{
  audited: HistoryResourceChangedAuditedFragment;
}> = ({ audited }) => (
  <strong>
    {audited.__typename === 'LaborClock' && `Labor Clock: ${audited.user.name} (${audited.role.toLowerCase()})`}
    <Spacer height="1rem" />
  </strong>
);

export const Audit: React.FC<{
  resource: HistoryResourceChangedAuditFragment;
  timestamp: string;
  timezone?: string;
}> = ({ resource, timestamp, timezone }) => (
  <tr>
    <td className="col-nowrap col-md-2 text-left" colSpan={2}>
      <Timestamp value={timestamp} timezone={timezone} />
    </td>
    <td className="col-nowrap col-md-2 text-left" colSpan={2}>
      <strong>{resource.changer.name}</strong>{' '}
      <span>
        {resource.event === Changed__Event.Create && <>created</>}
        {resource.event === Changed__Event.Update && <>updated</>}
        {resource.event === Changed__Event.Destroy && <>destroyed</>}
      </span>
    </td>
    <td className="col-md-8 text-left" colSpan={8}>
      {(resource.audited.__typename === 'Changed__Record' || resource.audited.__typename === 'LaborClock') && (
        <AuditedType audited={resource.audited} />
      )}
      <ul>
        {resource.fields.map((field, index) => (
          <li key={index}>
            <Field audit={resource} field={field} timezone={timezone} />
          </li>
        ))}
        {resource.relationships.map((relationship, index) => (
          <li key={index}>
            <Relationship relationship={relationship} />
          </li>
        ))}
      </ul>
    </td>
  </tr>
);

import styled from '@emotion/styled';
import { DateTime } from 'luxon';
import React, { useEffect } from 'react';

import { Billing__Source, useAccountSourcesQuery } from '@admin/schema';

import { client } from '@admin/libraries/apollo';

const EXPIRATION_FORMAT = 'yyyy/MM'; // i.e. 2020/12

const VerticalRadio = styled.label`
  display: block;
  padding-left: 50px;
`;

const Details: React.FC<{ source: Pick<Billing__Source, 'number' | 'brand' | 'kind' | 'expiration'> }> = ({
  source,
}) => (
  <>
    <strong>{source.brand || source.kind}</strong> <strong>····{source.number}</strong>{' '}
    {source.expiration && <strong>expires {DateTime.fromISO(source.expiration).toFormat(EXPIRATION_FORMAT)}</strong>}
  </>
);

export const Radios: React.FC<{
  accountID: string;
  selectedID?: string;
  defaultID?: string;
  name: string;
  onSelect(sourceID?: string): void;
}> = ({ accountID, selectedID, defaultID, name, onSelect }) => {
  const { data } = useAccountSourcesQuery({ client, variables: { accountID } });
  const sources = data?.account?.sources;

  const accountDefault = sources?.find((source) => source.default);

  useEffect(() => {
    if (!sources || selectedID !== undefined) {
      return;
    }
    if (defaultID) {
      onSelect(defaultID);
    } else {
      onSelect('');
    }
  }, [sources]);

  return (
    <>
      {sources && (
        <VerticalRadio>
          <input
            type="radio"
            name={name}
            value=""
            checked={selectedID === ''}
            onChange={(event) => {
              if (event.target.checked) {
                onSelect('');
              }
            }}
          />
          Account Default Source (currently {accountDefault ? <Details source={accountDefault} /> : 'no default'})
        </VerticalRadio>
      )}
      {sources &&
        sources.map((source) => (
          <div key={source.id}>
            <VerticalRadio>
              <input
                type="radio"
                name={name}
                value={source.id}
                checked={selectedID === source.id}
                onChange={(event) => {
                  if (event.target.checked) {
                    onSelect(source.id);
                  }
                }}
              />{' '}
              <Details source={source} /> {source.default && <>(default)</>}
            </VerticalRadio>
          </div>
        ))}
    </>
  );
};

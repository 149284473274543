import angular from 'angular';
import { formatDate, parseDate } from 'react-day-picker/moment';

const app = angular.module('app');

app.component('followupFields', {
  templateUrl: 'partials/components/followup_fields.html',
  bindings: {
    errors: '<',
    onInputChange: '&',
    selections: '<',
    timeZones: '<',
    unavailabilities: '<?',
  },
  controller: [
    'BusinessHours',
    'FiltersService',
    'FollowupService',
    function (BusinessHours, FiltersService, FollowupService) {
      const roundUp = function roundTimeUp(time) {
        const minutes = Math.ceil((time.minute() + 1) / 15) * 15;
        return moment(time).minute(minutes).second(0).millisecond(0);
      };

      const windowStartTime = () => {
        const now = moment();
        let start = moment(this.businessHours.open_time);

        if (start.isBefore(now)) {
          start = roundUp(now);
        }

        return start;
      };

      const windowEndTime = (offset) => {
        const endOfDay = moment(this.businessHours.close_time).endOf('day');
        let end = moment(this.businessHours.close_time).add(offset, 'minutes');

        if (end.isAfter(endOfDay)) {
          end = endOfDay;
        }
        return end;
      };

      const updateWindow = () => {
        if (this.businessHours && this.businessHours.$resolved) {
          const start = windowStartTime();
          const updates = {
            offsetMinutes: 0,
          };

          if (this.selections.timeZone) {
            const businessHoursOffset = start.utcOffset();
            const dstOffset = start.isDST() && this.selections.timeZone.dst_flag ? -60 : 0;
            const timeZoneOffset = this.selections.timeZone.time_zone_offset * 60 - dstOffset;
            updates.offsetMinutes = timeZoneOffset - businessHoursOffset;
          }

          this.timeProps.windowStart = start.add(updates.offsetMinutes, 'minutes');
          this.timeProps.windowEnd = windowEndTime(updates.offsetMinutes);
          this.onInputChange({ updates });
        }
      };

      const updateBusinessHours = (date) => {
        const filters = FiltersService.cleanup({
          date,
          dept: FollowupService.constants.SALES_DEPARTMENT,
        });
        this.businessHours = BusinessHours.get(filters);
        return this.businessHours.$promise.then(updateWindow);
      };

      // day could be a moment (when called by dayPickerInput) or a JavaScript date (onInit)
      const selectDate = (selectedDay, options) => {
        if (!options || !options.disabled) {
          const day = moment(selectedDay);
          const updates = {
            year: day.year(),
            month: day.month(),
            date: day.date(),
          };
          this.onInputChange({ updates });
          return updateBusinessHours(day);
        }
      };

      const selectFirstDateWithAvailability = (startingDay) => {
        selectDate(startingDay).then(() => {
          if (!this.timeProps.windowEnd.isAfter(this.timeProps.windowStart)) {
            const nextDay = moment(startingDay).add(1, 'days').toDate();
            this.dateProps.value = nextDay;
            selectFirstDateWithAvailability(nextDay);
          }
        });
      };

      const selectTime = (time) => {
        const updates = {
          hour: null,
          minute: null,
        };

        if (time) {
          updates.hour = time.hour();
          updates.minute = time.minute();
        }

        this.onInputChange({ updates });
      };

      this.selectTimeZone = () => {
        const updates = { timeZone: this.internalTimeZone };
        this.onInputChange({ updates });
      };

      const timeZoneChanged = (changes) => {
        const selections = changes.selections;
        const previousValue = selections && selections.previousValue && selections.previousValue.timeZone;
        const currentValue = selections && selections.currentValue && selections.currentValue.timeZone;
        return previousValue !== currentValue;
      };

      this.$onChanges = (changes) => {
        if (timeZoneChanged(changes)) {
          this.internalTimeZone = _.find(this.timeZones, {
            full_name: changes.selections.currentValue.timeZone.full_name,
          });
          updateWindow();
        }

        if (this.dateProps) {
          this.dateProps.value = moment(this.selections).startOf('day').toDate();
        }
      };

      this.$onInit = () => {
        const today = moment().startOf('day').toDate();
        this.unavailabilities = this.unavailabilities || [];

        this.dateProps = {
          format: 'ddd M/D',
          formatDate: formatDate,
          inputProps: {
            className: 'date-input',
            readOnly: true,
          },
          parseDate: parseDate,
          placeholder: '',
          dayPickerProps: {
            disabledDays: this.unavailabilities.concat({ before: today }),
            fromMonth: moment().startOf('month').toDate(),
            modifiersStyles: {
              today: {
                color: 'inherit',
                fontWeight: 'inherit',
              },
            },
            numberOfMonths: 2,
            onDayClick: selectDate,
          },
        };

        this.timeProps = {
          interval: moment.duration(15, 'minutes'),
          onChange: selectTime,
        };

        if (
          this.selections &&
          _.every([this.selections.year, this.selections.month, this.selections.date], _.isNumber)
        ) {
          selectDate(moment(this.selections));
        } else {
          selectFirstDateWithAvailability(today);
        }
      };
    },
  ],
});

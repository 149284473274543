import * as React from 'react';
import { useState } from 'react';

import { Panel as Container } from '@admin/components/helpers/panel';

import { History__SubjectInput } from '@admin/schema';

import { Table } from './table';

const DEFAULT_COLLAPSED = false;

export const HistoriesPanel: React.FC<
  Partial<History__SubjectInput> & {
    timezone?: string;
  }
> = ({ id, type, timezone }) => {
  const [collapsed, setCollapsed] = useState<boolean>(DEFAULT_COLLAPSED);

  return (
    <Container id="history">
      <Container.Header>
        <Container.Collapse collapsed={collapsed} onChange={setCollapsed} />
        <Container.Title>History</Container.Title>
      </Container.Header>
      {!collapsed && <Container.Body>{id && type && <Table id={id} type={type} timezone={timezone} />}</Container.Body>}
    </Container>
  );
};

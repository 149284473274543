import QS from 'qs';

(function () {
  /*
    Behaves like $location.search, except uses QS for serialization/deserialization of query string so that nesting works properly.
    Instead of using one function for setting/getting as $location does, we have getSearch() and setSearch(params).
  */
  const app = angular.module('app');
  app.service('ClLocation', [
    '$location',
    function ($location) {
      this.setSearch = (params) => {
        const qs = QS.stringify(params, { arrayFormat: 'brackets', addQueryPrefix: true, skipNulls: true });
        $location.url(`${$location.path()}${qs}`);
      };

      this.getSearch = () => {
        // We use the private $location.$$url instead of relying on window.location.search because $location.$$url
        // has the proper URL after a ui-sref link is clicked, for example.
        const url = $location.$$url;
        const qsStartIdx = url.indexOf('?');
        if (qsStartIdx === -1) {
          return {};
        }
        const qs = url.substring(qsStartIdx);
        return QS.parse(qs, { depth: 10, ignoreQueryPrefix: true });
      };
    },
  ]);
})();

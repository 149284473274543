import styled from '@emotion/styled';
import React from 'react';

import { Landing__Location__Content } from '@admin/schema';

import { Panel } from '@admin/components/helpers/panel';
import { Table, AnchorButton as Link } from '@shared/components/bootstrap';

const Links = styled.div`
  display: flex;
  gap: 4px;
`;

export const List: React.FC<{
  location: {
    globalID: string;
    contents: Array<Pick<Landing__Location__Content, 'id' | 'service'>>;
  };
}> = ({ location }) => (
  <Panel>
    <Panel.Header>
      <Panel.Title>Content</Panel.Title>
    </Panel.Header>
    <Panel.Body>
      <Table striped responsive>
        <thead>
          <tr>
            <th className="text-left">ID</th>
            <th className="text-left">Service</th>
            <th className="col-zero" />
          </tr>
        </thead>
        <tbody>
          {location.contents.map((content) => (
            <tr key={content.id}>
              <td className="text-left">{content.id}</td>
              <td className="text-left">{content.service}</td>
              <td className="col-zero">
                <Links>
                  <Link kind="primary" href={`/landing/location/contents/${content.id}`}>
                    View
                  </Link>
                  <Link kind="primary" href={`/landing/location/contents/${content.id}/edit`}>
                    Edit
                  </Link>
                </Links>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Panel.Body>
    <Panel.Footer align="right">
      <Link kind="default" href={`/landing/location/contents/new?location_id=${location.globalID}`}>
        Add
      </Link>
    </Panel.Footer>
  </Panel>
);

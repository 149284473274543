import * as React from 'react';

import { useSelfStoragePartnersQuery } from '@admin/schema';
import { client } from '@admin/libraries/apollo';

import { MultiselectFormControl } from '@admin/components/fields';

export const MultiSelector: React.FC<{
  selectedIDs: string[];
  onChange(selectedIDs: string[]): void;
}> = ({ onChange, selectedIDs }) => {
  const { data } = useSelfStoragePartnersQuery({ client });
  const partners = data?.partners ?? [];

  return (
    <MultiselectFormControl placeholder="Partners" options={partners} selectedIDs={selectedIDs} onChange={onChange} />
  );
};

(function () {
  const app = angular.module('app');

  app.factory('LeadObjectionService', [
    '$q',
    'ObjectionsList',
    'ObjectionReason',
    function ($q, ObjectionsList, ObjectionReason) {
      const service = {
        VISIBILITY_SMS: 'sms',
        VISIBILITY_CHECKOUT: 'checkout',
        VISIBILITY_INCOMING_CALL: 'incoming_call',
        VISIBILITY_OUTGOING_CALL: 'outgoing_call',
        VISIBILITY_SELF_STORAGE_PIVOT: 'self_storage_pivot',
        handleObjectionChange: handleObjectionChange,
        handleFollowupScheduledObjection: handleFollowupScheduledObjection,
        hasObjectionChanged: hasObjectionChanged,
        isFollowupScheduledObjection: isFollowupScheduledObjection,
        isWon: isWon,
        list: list,
        exclude: exclude,
      };

      let objectionReasons = [];

      return service;

      function handleObjectionChange(lead) {
        if (lead && lead.objection_reason) {
          lead.state = getLeadState(lead.objection_reason);
        }
      }

      function handleFollowupScheduledObjection(lead) {
        if (service.isFollowupScheduledObjection(lead.objection_reason)) {
          lead.followup_scheduled_at = null;
        }
      }

      function hasObjectionChanged(lead) {
        return lead && lead.originalObjection !== lead.objection_reason;
      }

      function isFollowupScheduledObjection(objection) {
        return objection === ObjectionsList.FOLLOWUP_SCHEDULED;
      }

      function isWon(lead) {
        return lead.state === 'won';
      }

      function list(visibility) {
        const deferred = $q.defer();

        if (objectionReasons.length > 0) {
          deferred.resolve(filter(visibility));
        } else {
          ObjectionReason.get()
            .$promise.then((data) => {
              objectionReasons = data;
              const result = filter(visibility);
              deferred.resolve(result);
            })
            .catch(deferred.reject);
        }

        return deferred.promise;
      }

      function filter(visibility) {
        return objectionReasons.filter((reason) => reason.visibility.includes(visibility));
      }

      function exclude(reasons, visibility) {
        return reasons.filter((reason) => !reason.visibility.includes(visibility));
      }

      function getLeadState(objectionReasonName) {
        const objectionReason = objectionReasons.find(function (reason) {
          return reason.name === objectionReasonName;
        });

        if (objectionReason) {
          return objectionReason.lead_state;
        } else {
          return null;
        }
      }
    },
  ]);
})();

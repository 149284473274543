import React from 'react';
import { Form as FormikForm, Formik, FormikErrors } from 'formik';

import { CustomerFields, validateCustomerFields } from '@admin/components/accounts/create/customer_fields';
import { FormikCheckboxFormGroup } from '@admin/components/fields/formik/formik_checkbox_form_group';
import { FormikSelectFormGroup } from '@shared/components/fields/formik/formik_select_form_group';
import { Panel } from '@admin/components/helpers/panel';
import { Spinner } from '@admin/components/spinner';
import { Alert, Button } from '@shared/components/bootstrap';
import { Spacer } from '@shared/components/helpers';
import styled from '@emotion/styled';
import { css, jsx } from '@emotion/react';
import { client } from '@admin/libraries/apollo';

import { Logistics__AccountInput, useWarehousesQuery } from '@admin/schema';

const INITIAL_VALUES = {
  name: '',
  email: '',
  phone: '',
  communicationConsent: false,
  warehouseID: '',
};

const inputStyle = css`
  text-align: left;
`;

const Container = styled.div`
  width: 50%;
  margin: auto;
`;

const HR = styled.hr`
  margin: 20px 0px;
`;

const SectionTitle = styled.div`
  font-weight: 600;
  font-size: 22px;
`;

const StyledRow = styled.div`
  display: flex;
  align-items: center;
`;

export const AccountForm: React.FC<{
  error: string | null | undefined;
  onSubmit: (data: Logistics__AccountInput, errors: string | null | undefined) => Promise<void>;
  submitting: boolean;
}> = ({ error, onSubmit, submitting }) => {
  const { data: warehousesData, loading: warehousesLoading } = useWarehousesQuery({ client });

  const validate = (data: Logistics__AccountInput) => {
    const errors: FormikErrors<Logistics__AccountInput> = validateCustomerFields(data);

    if (!data.warehouseID || data.warehouseID === '0') {
      errors.warehouseID = 'Selecting a warehouse is required';
    }
    return errors;
  };

  const onFormSubmit = async (data: Logistics__AccountInput) => {
    const errors = Object.values(validate(data));
    onSubmit(data, errors.length === 0 ? null : errors.join('. '));
  };

  if (warehousesLoading || !warehousesData) {
    return <Spinner />;
  }

  const warehouses = warehousesData.warehouses;

  return (
    <Panel>
      <Formik<Logistics__AccountInput>
        initialValues={INITIAL_VALUES}
        onSubmit={onFormSubmit}
        validate={validate}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ values }) => (
          <FormikForm id="logisticsAccountForm">
            {error && <Alert style="danger">{error}</Alert>}
            <Panel.Body>
              <Container>
                <SectionTitle>Contact Info</SectionTitle>
                <Spacer height="8px" />
                <StyledRow className="row">
                  <CustomerFields />
                </StyledRow>
              </Container>
              <HR />
              <Container>
                <SectionTitle>Warehouse</SectionTitle>
                <Spacer height="8px" />
                <FormikSelectFormGroup
                  name="warehouseID"
                  id="warehouse_id"
                  label="Default Warehouse"
                  required={true}
                  help="You can add additional warehouse addresses later if this customer operates out of more than one
                  warehouse."
                >
                  <option key={0} value="0">
                    None Selected
                  </option>
                  {warehouses.map((warehouse) => (
                    <option key={warehouse.id} value={warehouse.id}>
                      {warehouse.name}
                    </option>
                  ))}
                </FormikSelectFormGroup>
              </Container>
              <HR />
              <Container>
                <SectionTitle>Affirmations</SectionTitle>
                <Spacer height="8px" />
                <StyledRow className="row">
                  <div className="col-sm-2">
                    <FormikCheckboxFormGroup
                      id="communication_consent"
                      label="Communication Consent"
                      name="communicationConsent"
                      required={true}
                      css={inputStyle}
                    />
                  </div>
                  <div className="col-sm-10">
                    <p>
                      The customer agrees to receive calls or text messages from Clutter with critical notices in
                      connection with the Service. These calls or texts may be sent using autodialing technology. The
                      customer can opt-out of texts by replying "STOP" to one of our messages, however, opting out of
                      receiving all texts may impact their use of the Services. Std. text message and data rates may
                      apply. For more details, the Terms of Service can be viewed at clutter.com.
                    </p>
                  </div>
                </StyledRow>
              </Container>
            </Panel.Body>
            <Panel.Footer align="right">
              <Button
                kind="primary"
                type="submit"
                disabled={warehousesLoading || submitting || !values.communicationConsent}
              >
                Save
              </Button>
            </Panel.Footer>
          </FormikForm>
        )}
      </Formik>
    </Panel>
  );
};

(function () {
  angular.module('app').filter('capitalize', function () {
    return function (str) {
      if (typeof str !== 'string') {
        return '';
      }

      return str.charAt(0).toUpperCase() + str.slice(1);
    };
  });
})();

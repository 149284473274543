(function () {
  angular.module('app').filter('initials', function () {
    return function (string) {
      if (string) {
        return _.map(string.split(/\W+/), function (component) {
          return _.head(component);
        }).join('');
      }
    };
  });
})();

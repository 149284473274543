import React from 'react';

import { Pluralize } from '@shared/components/helpers';

const ZipCodePuralize: React.FC<{ count: number }> = ({ count }) => (
  <Pluralize count={count} singular="zip code" plural="zip codes" />
);

export const ZipInput: React.FC<{
  label: string;
  value: string;
  addedZipsCount: number;
  removedZipsCount: number;
  disabled: boolean;
  onChange(event: React.ChangeEvent<HTMLTextAreaElement>): void;
}> = ({ label, value, addedZipsCount, removedZipsCount, disabled, onChange }) => (
  <div className="form-group warehouse">
    <label className="control-label">
      <strong>{label}</strong>
      <textarea className="form-control" rows={10} cols={20} onChange={onChange} disabled={disabled} value={value} />
    </label>
    {addedZipsCount || removedZipsCount ? (
      <div>
        Will add <ZipCodePuralize count={addedZipsCount} /> and remove <ZipCodePuralize count={removedZipsCount} />.
      </div>
    ) : null}
  </div>
);

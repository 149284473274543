(() => {
  const app = angular.module('app');

  app.directive('ifRole', (UserService) => ({
    restrict: 'A',
    scope: false,
    compile: (element, { ifRole = '' }) => {
      const roles = ifRole.split(' ');

      if (!roles.some(UserService.hasRole)) {
        element.children().remove();
        element.remove();
      }
    },
  }));

  app.directive('unlessRole', (UserService) => ({
    restrict: 'A',
    scope: false,
    compile: (element, { unlessRole = '' }) => {
      const roles = unlessRole.split(' ');

      if (roles.some(UserService.hasRole)) {
        element.children().remove();
        element.remove();
      }
    },
  }));
})();

import template from '@admin/angular/templates/order_form.html';

import angular from 'angular';
import { pick, isUndefined } from 'lodash';
import moment from 'moment';

import { STORAGE_UNIT_FIELDS } from '@admin/components/orders/moving/selection_details_summary';

const app = angular.module('app');

const DEFAULT_RESCHEDULE_FEE_TITLE = 'Are you sure you want to reschedule with the $100 reschedule fee?';
const DEFAULT_RESCHEDULE_FEE_DESCRIPTION =
  'This order was previously rescheduled by the customer within ' +
  '48 hours of the job, this will incur a $100 cancellation fee';

const MOVE_RESCHEDULE_FEE_TITLE = 'Are you sure you want to reschedule?';

const SIMULTANEOUS_ORDER_TITLE = 'Are you sure you want to change this order along with the linked order?';
const SIMULTANEOUS_ORDER_DESCRIPTION = 'This order is linked to another order. Changing it will change both orders.';

const WAREHOUSE_ORDER_TITLE = 'Are you sure you don’t want to book this order in the Account Portal?';
const WAREHOUSE_ORDER_DESCRIPTION =
  'Disposals and Facility Pickups should be booked via the Account Portal unless this a special case that does not require customer billing.';

const WAREHOUSE_RETURN_SERVICE_TYPES = ['auction', 'disposal', 'third_party_return'];
const MOVING_SQUARE_FOOTAGE_BUILDING_TYPES = ['Commercial', 'Other'];
const MOVING_CUSTOM_UNIT_BUILDING_TYPES = ['Studio', 'Storage Facility / Warehouse'];
const MOVE_SIZE_BUILDING_TYPES = [...MOVING_SQUARE_FOOTAGE_BUILDING_TYPES, ...MOVING_CUSTOM_UNIT_BUILDING_TYPES];
const STORAGE_UNIT_SIZES = new Set(STORAGE_UNIT_FIELDS);

app.component('orderForm', {
  template,
  bindings: {
    order: '<',
    account: '<',
    attributes: '<',
    resource: '<',
    type: '@',
  },
  controller: [
    '$window',
    '$scope',
    '$state',
    '$stateParams',
    '$http',
    'ErrorService',
    'ConfirmationService',
    'OrderSerializer',
    'UserService',
    'EstimatedReturnMoverCountService',
    'MOVE_RESCHEDULE_FEE_DESCRIPTION',

    function ctrl(
      $window,
      $scope,
      $state,
      $stateParams,
      $http,
      ErrorService,
      ConfirmationService,
      serializer,
      UserService,
      EstimatedReturnMoverCountService,
      MOVE_RESCHEDULE_FEE_DESCRIPTION,
    ) {
      this.serialize = () => pick(serializer(this.order), this.attributes);

      this.warehouseReturn = () =>
        $stateParams.warehouse_return === 'true' || WAREHOUSE_RETURN_SERVICE_TYPES.includes(this.order.service_type);

      const estimatorRequiredAndProvided = () => !!this.order.id ^ !!this.estimatorAffirmation; // eslint-disable-line no-bitwise

      const standardSavables = () => {
        const movingAttributesValid =
          !MOVING_SQUARE_FOOTAGE_BUILDING_TYPES.includes(this.order.moving_operation?.origin_address?.building_type) ||
          this.movingSquareFootage > 0;
        return (
          !this.saving &&
          this.order &&
          (this.disclaimer ||
            this.warehouseReturn() ||
            this.order.type === 'selfstorage-shuttle' ||
            this.order.type === 'retail-delivery') &&
          (!this.order.unpaid_materials_bundle_amount || this.disclaimerForMaterialsBundle) &&
          (!this.order.rescheduling || this.order.rescheduler) &&
          estimatorRequiredAndProvided() &&
          (!this.order.scheduled_override || this.order.scheduled_override_reason) &&
          (!this.order.movers_overwritten_at || this.order.mover_override_reason) &&
          (!this.warehouseReturn() ||
            (this.warehouseReturn() && WAREHOUSE_RETURN_SERVICE_TYPES.includes(this.order.service_type))) &&
          (this.order.type !== 'moving-move' || movingAttributesValid) &&
          (this.order.type !== 'selfstorage-shuttle' ||
            this.order.id ||
            (this.order.reservation && this.order.reservation.id)) &&
          (!this.order.follow_up_job_exception || this.order.follow_up_job_exception_reason)
        );
      };

      this.savable = () => {
        if (this.order.cancel_account) {
          return (
            standardSavables() &&
            (!this.isEarlyTermination || this.isEarlyTermination === !!this.earlyTerminationDisclaimer)
          );
        }
        return standardSavables();
      };

      this.setEstimatorAffirmation = (value) => {
        this.estimatorAffirmation = value;
      };

      this.orderTypeFormatted = () => {
        switch (this.order.type) {
          case 'selfstorage-shuttle':
            return 'Self-Storage Shuttle';
          case 'moving-move':
            return 'Move';
          case 'retail-delivery':
            return 'Retail Delivery';
          default:
            return this.order.type;
        }
      };

      this.setIsEarlyTermination = (value) => {
        this.isEarlyTermination = value;
      };

      this.setEarlyTerminationDisclaimer = (value) => {
        this.earlyTerminationDisclaimer = value;
      };

      this.setCancellationDisclaimer = (value) => {
        this.disclaimer = value;
      };

      const estimateReturnMovers = () => {
        if (this.order.movers_overwritten_at == null) {
          const itemIds = [];
          this.order.items.forEach((item) => itemIds.push(item.id));
          EstimatedReturnMoverCountService.process(itemIds, this.order);
        }
      };

      this.onReservationSelection = (reservationID) => {
        this.order.reservation = { id: reservationID };
      };

      this.onWarehouseReturnServiceTypeSelection = (serviceType) => {
        this.order.service_type = serviceType;
        switch (serviceType) {
          case 'auction':
          case 'disposal':
            this.order.external = false;
            return;
          default:
            this.order.external = true;
        }
      };

      this.confirm = async () => {
        const { order } = this;
        if (!order.cardless && order.rescheduled_at && order.rescheduler && order.rescheduler.type === 'Customer') {
          let title = DEFAULT_RESCHEDULE_FEE_TITLE;
          let description = DEFAULT_RESCHEDULE_FEE_DESCRIPTION;

          if (this.order.type === 'moving-move') {
            title = MOVE_RESCHEDULE_FEE_TITLE;
            description = `I have informed the customer that: "${MOVE_RESCHEDULE_FEE_DESCRIPTION}"`;
          }

          await ConfirmationService.confirm({ title, description });
        }
        if (order.simultaneous) {
          await ConfirmationService.confirm({
            title: SIMULTANEOUS_ORDER_TITLE,
            description: SIMULTANEOUS_ORDER_DESCRIPTION,
          });
        }

        if (['disposal', 'third_party_return'].includes(order.service_type)) {
          await ConfirmationService.confirm({
            title: WAREHOUSE_ORDER_TITLE,
            description: WAREHOUSE_ORDER_DESCRIPTION,
          });
        }
      };

      this.onItems = (selections, requestables) => {
        this.order.items = selections;
        this.order.allItemsSelected = requestables.length === selections.length;
      };

      this.onInventory = (inventory) => {
        Object.assign(this.order, {
          inventory,
          full_move_out: inventory.isFullMoveOut,
          full_pack: inventory.isFullMoveOut || inventory.needsPackingHelp,
          requested_movers: inventory.requestedMovers,
        });
      };

      this.onEstimateCubicFootage = (estimatedCUFT) => {
        this.estimatedCUFT = estimatedCUFT;
      };

      this.onMoverCountUpdate = (movers) => {
        this.estimatedMovers = movers;
        if (this.order.movers_overwritten_at == null) {
          this.order.movers = movers;
        }
      };

      const deleteOrderSubscriptionAndFeesAttributes = () => {
        delete this.order.subscribe_attributes;
        delete this.order.account_packages_attributes;
      };

      this.onSubscribe = ({
        labor,
        laborRate,
        storage,
        protection,
        length,
        width,
        quantity,
        activePricingSet,
        accountPackages,
        appointmentQuote,
      }) => {
        if (!labor || !laborRate || !storage || !protection || !activePricingSet) {
          deleteOrderSubscriptionAndFeesAttributes();
        } else {
          Object.assign(this.order, {
            subscribe_attributes: {
              quote_id: activePricingSet.quoteId,
              materials_bundle_purchase: true,
              pricing_set_id: activePricingSet.id,
              labor_pricing_group_entry_id: labor.id,
              storage_pricing_group_entry_id: storage.id,
              protection_pricing_group_entry_id: protection.id,
              labor_rate_id: laborRate.id,
              appointment_quote_id: appointmentQuote?.id,
              storage_attributes: {
                length,
                width,
                quantity,
              },
            },
            account_packages_attributes: accountPackages,
          });
          if (!this.warehouseReturn()) {
            if (labor.rateGroup && labor.rateGroup.curbside) {
              this.order.service_type = 'curbside_pickup';
            } else {
              this.order.service_type = 'full_service';
            }
          }
        }
      };

      this.isReonboarding = () =>
        !this.order.id &&
        this.order.type === 'pickup' &&
        this.order.subtype === 'onboarding' &&
        $stateParams.external !== 'false';

      this.save = async () => {
        if (UserService.hasRole('l1_agent')) {
          ErrorService.handle({ message: 'You do not have permission to update or schedule orders.' });
          return;
        }

        if (this.isReonboarding() && !this.order.subscribe_attributes) {
          ErrorService.handle({ message: 'Please select a valid storage and protection plan.' });
          return;
        }

        if (this.order.follow_up_job && !this.order.predecessor_id) {
          ErrorService.handle({ message: 'Please select a valid parent order.' });
          return;
        }

        if (!this.order.id && this.order.type === 'pickup' && isUndefined(this.order.full_move_out)) {
          ErrorService.handle({ message: 'Please specify if this is a full move-out before saving the order!' });
          return;
        }

        if (!this.order.address) {
          ErrorService.handle({ message: 'Please select an address before saving the order!' });
          return;
        }

        if (this.managing) {
          ErrorService.handle({ message: 'Please save any address changes before saving the order!' });
          return;
        }

        if (this.order.cancel_account && !this.order.account_cancel_intent?.reason) {
          ErrorService.handle({ message: 'Please select a cancellation reason.' });
          return;
        }

        if (!this.savable()) {
          return;
        }

        try {
          await this.confirm();
        } catch (_error) {
          return;
        }

        this.saving = this.serialize();
        const method = this.order.id ? 'update' : 'create';
        this.resource[method](this.saving)
          .$promise.then((order) => {
            $state.go('viewOrder', { account_id: order.account_id, id: order.id });
          })
          .catch(ErrorService.handle)
          .finally(() => {
            delete this.saving;
          });
      };

      const classifyOrder = async () => {
        if (!this.order.id) {
          this.order.type = this.type;
          const params = `type=${this.type}&cancelable=${this.order.cancel_account}&subtype=${this.order.subtype}`;
          $http.get(`/accounts/${this.account.id}/orders/classify.json?${params}`).then((response) => {
            this.order.subtype = response.data.subtype;
          });
        }
        if (this.order.subtype === 'onboarding') {
          const response = await $http.get(`/accounts/${this.account.id}/onboarded.json`);
          this.order.reonboarding = response.data.onboarded;
        }
      };

      this.isAllowedToAddItemsToEnroute =
        UserService.hasRole('admin') || (UserService.hasRole('manager') && UserService.hasRole('warehouse'));

      this.onRoomsChange = (field, value) => {
        let quantity = value;
        if (typeof value === 'boolean') {
          quantity = value ? 1 : 0;
        }

        // Underlying Rooms component coerces 0/1 quantities into booleans
        this.movingRooms = {
          ...this.movingRooms,
          [field]: quantity,
        };
        const existingSelection = this.order.moving_selections.find(
          ({ moving_category }) => moving_category.name === field,
        );

        if (existingSelection) {
          existingSelection.quantity = quantity;
        } else {
          this.order.moving_selections.push({ quantity, moving_category: { name: field } });
        }
      };

      this.onMovingSquareFootageChange = (movingSquareFootage) => {
        this.movingSquareFootage = movingSquareFootage;
        this.clearMovingSelections();
        this.onRoomsChange('square_footage', movingSquareFootage);
      };

      this.onStorageUnitSizeChange = (storageUnitSize) => {
        this.storageUnitSize = storageUnitSize;
        this.clearMovingSelections();
        this.onRoomsChange(storageUnitSize, 1);
      };

      this.clearMovingSelections = () => {
        this.order.moving_selections = this.order.moving_selections.map((movingSelection) => ({
          ...movingSelection,
          quantity: 0,
        }));

        this.order.moving_selections.forEach((movingSelection) => {
          this.movingRooms[movingSelection.moving_category.name] = movingSelection.quantity;
        });
      };

      this.$onInit = () => {
        this.order.override_movers = this.order.movers_overwritten_at !== null;
        if ($stateParams.onboardingDate) {
          this.order.follow_up_job = true;
          this.onboardingDate = moment($stateParams.onboardingDate).format('dddd, MMMM Do, YYYY');
        }

        if (this.order.type === 'moving-move') {
          const { account_packages: accountPackages = [], moving_selections: movingSelections = [] } = this.order;

          this.order.moving_operation.packing_supplies_required = accountPackages.some(
            (accountPackage) => accountPackage.kind === 'packing_supplies',
          );

          this.order.materials_package_set_entry_id = accountPackages.find(
            (accountPackage) => accountPackage.kind === 'packing_supplies',
          )?.package_set_entry_id;

          this.movingRooms = movingSelections.reduce((movingRooms, { moving_category, quantity }) => {
            movingRooms[moving_category.name] = quantity;
            return movingRooms;
          }, {});

          this.moveHasMoveSize = false;
          this.moveHasSquareFootage = false;
          this.moveHasStorageUnit = false;
          this.movingSquareFootage =
            movingSelections.find(({ moving_category }) => moving_category.name === 'square_footage')?.quantity || 0;
          this.storageUnitSize = movingSelections.find(
            ({ quantity, moving_category }) => STORAGE_UNIT_SIZES.has(moving_category.name) && quantity > 0,
          )?.moving_category?.name;
          this.onboardingDate = moment(this.order.scheduled);
        }

        if (this.warehouseReturn()) {
          const defaultDate = new Date();
          defaultDate.setHours(10);
          defaultDate.setMinutes(0);
          defaultDate.setSeconds(0);
          this.order.scheduled = this.order.scheduled ? this.order.scheduled : defaultDate;
        }

        classifyOrder();
      };

      // clear predecessor_id on the order if follow_up_job becomes non-truthy
      $scope.$watch(
        () => this.order.follow_up_job,
        (nv, ov) => {
          if (nv === ov) {
            return;
          }
          if (nv !== true) {
            this.order.predecessor_id = null;
          }
        },
      );

      $scope.$watch(
        () => this.order.address,
        (nv, ov) => {
          if (nv === ov) {
            return;
          }
          if (this.order.type === 'return' && nv !== null) {
            estimateReturnMovers();
          }
        },
      );

      $scope.$watch(
        () => this.order.override_movers,
        (nv, ov) => {
          if (nv === ov) {
            return;
          }
          if (nv === true) {
            this.order.movers_overwritten_at = moment.utc(new Date()).format('YYYY-MM-DD HH:mm:ss');
          } else {
            this.order.mover_override_reason = null;
            this.order.movers_overwritten_at = null;
            if (this.order.type === 'return') {
              estimateReturnMovers();
            } else {
              this.order.movers = this.estimatedMovers;
            }
          }
        },
      );

      $scope.$watch(
        () => this.order.moving_operation?.origin_address?.building_type,
        (buildingType) => {
          if (this.order.type !== 'moving-move' || !buildingType) {
            return;
          }

          this.moveHasMoveSize = MOVE_SIZE_BUILDING_TYPES.includes(buildingType);
          const moveSizeNotSet = !this.order.moving_operation.move_size;
          if (!this.moveHasMoveSize) {
            delete this.order.moving_operation.move_size;
          } else if (moveSizeNotSet) {
            this.order.moving_operation.move_size = 'EVERYTHING';
          }

          this.moveHasSquareFootage = MOVING_SQUARE_FOOTAGE_BUILDING_TYPES.includes(buildingType);
          const movingSquareFootageSet = this.movingSquareFootage > 0;
          if (!this.moveHasSquareFootage && movingSquareFootageSet) {
            this.onMovingSquareFootageChange(0);
          }

          this.moveHasStorageUnit = buildingType === 'Storage Facility / Warehouse';
          const storageUnitSizeSet = !!this.storageUnitSize;
          if (this.moveHasStorageUnit) {
            this.onStorageUnitSizeChange(this.storageUnitSize || 'locker');
          } else if (storageUnitSizeSet) {
            this.clearMovingSelections();
          }

          if (buildingType === 'Studio') {
            this.clearMovingSelections();
            this.onRoomsChange('studio', 1);
          }
        },
      );

      $scope.$watchCollection(
        () => this.order.items,
        (nv, ov) => {
          if (nv === ov) {
            return;
          }
          if (this.order.type === 'return') {
            classifyOrder();
            estimateReturnMovers();
          }
        },
      );
    },
  ],
});

import { client } from '@admin/libraries/apollo';
import { useLaborSummaryQuery } from '@admin/schema';
import React from 'react';

import { Summary as AgreementSummary } from '@admin/components/account_agreement/summary';

export const LaborSummary: React.FC<{ accountID: number }> = ({ accountID }) => {
  const { data } = useLaborSummaryQuery({ client, variables: { accountID: String(accountID) } });

  if (!data) {
    return null;
  }

  const {
    account: {
      laborSummary: {
        laborRate,
        laborBundleCustomer,
        availableSubsequentAccountLaborCreditsCount,
        subsequentAccountLaborCreditsCount,
        hasOnboardingLaborCredit,
        hasFinalLaborCredit,
      },
    },
  } = data;

  const laborCredits: React.ReactNode[] = [];
  if (hasOnboardingLaborCredit) {
    laborCredits.push('free onboarding');
  }
  if (subsequentAccountLaborCreditsCount > 0) {
    let subjobCreditCopy = `${subsequentAccountLaborCreditsCount} free sub job`;
    if (subsequentAccountLaborCreditsCount !== 1) {
      subjobCreditCopy += 's';
    }
    subjobCreditCopy += ` (${availableSubsequentAccountLaborCreditsCount} remaining)`;
    laborCredits.push(subjobCreditCopy);
  }
  if (hasFinalLaborCredit) {
    laborCredits.push('free final delivery');
  }

  return (
    <>
      <div>
        <strong>Storage Labor Rate:</strong> {laborRate.name}
      </div>
      <strong>Agreement Summary:</strong>
      <AgreementSummary accountID={accountID} />
      {laborBundleCustomer && (
        <>
          <strong>Labor Credits: </strong>
          <>{laborCredits.join(', ')}</>
        </>
      )}
    </>
  );
};

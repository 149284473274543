import { SelectFormControlType } from '@admin/components/fields';
import { SelfStorage__Reservation__State } from '@admin/schema';
import React, { forwardRef } from 'react';

type SelectProps = React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>;

const RESPONSE_OPTIONS = [
  {
    label: 'Yes',
    value: SelfStorage__Reservation__State.Won,
  },
  {
    label: 'No',
    value: SelfStorage__Reservation__State.Lost,
  },
  {
    label: 'Confirm at a future date',
    value: SelfStorage__Reservation__State.Pending,
  },
];

export const StateSelector = forwardRef<SelectFormControlType, SelectProps>((props, ref) => (
  <select className="form-control" {...props} ref={ref}>
    <option value="">-</option>
    {RESPONSE_OPTIONS.map((option) => (
      <option key={option.value} value={option.value}>
        {option.label}
      </option>
    ))}
  </select>
));

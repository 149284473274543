(function () {
  angular.module('app').filter('datetz', [
    '$filter',
    function ($filter) {
      return function (date, timezone, format, ignoreAbbr) {
        let abbr = null;

        if (date && timezone) {
          abbr = moment.tz.zone(timezone).abbr(new Date(date));
        }

        const result = $filter('date')(date, format, abbr);
        return abbr && !ignoreAbbr ? result + ' ' + abbr : result;
      };
    },
  ]);
})();

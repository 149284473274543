import styled from '@emotion/styled';
import React, { useState } from 'react';

import { ActiveStorageAttachment } from '@admin/schema';
import { Fit, Picture, LightboxWithCarousel } from '@shared/components/helpers';
import { COLORS } from '@root/colors';

interface IThumbnailsProps {
  attachments: ActiveStorageAttachment[];
  condensed?: boolean;
  height?: number;
  width?: number;
}

const CondensedContainer = styled.div<{ width: number }>`
  position: relative;
  min-width: 36px;
  width: ${(props) => props.width}px;
  cursor: default;

  :hover {
    border-color: #337ab7;
    cursor: pointer;
  }
`;

const Label = styled.span`
  position: absolute;
  right: 0;
  bottom: 0;
  margin: 2px;
  font-size: 12px;
  text-align: right;
  border: 1px solid ${COLORS.gray};
`;

const MultipleThumbnails: React.FC<{ attachments: ActiveStorageAttachment[] }> = ({ attachments }) => (
  <div className="row no-pad">
    {attachments.map((attachment) => (
      <div key={attachment.filename} className="col-sm-3">
        <a className="thumbnail" href={attachment.imgixURL} target="_blank">
          <Picture source={attachment.imgixURL} height={320} width={320} fit={Fit.Fill} background="FFF" />
        </a>
      </div>
    ))}
  </div>
);

const CondensedThumbnails: React.FC<{
  attachments: ActiveStorageAttachment[];
  height: number;
  width: number;
}> = ({ attachments, height, width }) => {
  const [showLightbox, setShowLightbox] = useState<boolean>(false);

  if (!attachments || !attachments.length) {
    return null;
  }

  const parsedAttachments = attachments.map((attachment) => ({ src: attachment.imgixURL }));

  return (
    <>
      <CondensedContainer width={width} className="thumbnail" onClick={() => setShowLightbox(true)}>
        <Picture source={attachments[0].imgixURL} height={height} width={width} fit={Fit.Fill} background="FFF" />
        <Label className="label label-default">{attachments.length}</Label>
      </CondensedContainer>
      {showLightbox && <LightboxWithCarousel imageInputs={parsedAttachments} onClose={() => setShowLightbox(false)} />}
    </>
  );
};

export const Thumbnails = ({ attachments, condensed = false, height = 100, width = 100 }: IThumbnailsProps) => (
  <>
    {condensed ? (
      <CondensedThumbnails attachments={attachments} height={height} width={width} />
    ) : (
      <MultipleThumbnails attachments={attachments} />
    )}
  </>
);

import * as React from 'react';

import { IPagination as IJBuilderPagination } from '@admin/types';
import { IPagination as IGQLPagination } from '@shared/types';

const INITIAL_PAGE = 1;
const SURROUNDING_PAGES = 2; // i.e. how many pages to display on either side of the current page.

const pages = (current: number, total: number) => {
  const results = [];
  const from = Math.max(INITIAL_PAGE, current - SURROUNDING_PAGES);
  const till = Math.min(total, current + SURROUNDING_PAGES);
  for (let page = from; page <= till; page++) {
    results.push(page);
  }
  return results;
};

const PaginationButton: React.FC<{
  text: string | number;
  page?: number | null;
  current: number;
  onPage(page: number): void;
}> = ({ text, page, current, onPage }) => (
  <button
    className="btn btn-default btn-active-primary"
    onClick={(event) => {
      event.preventDefault();
      event.stopPropagation();
      if (page) {
        onPage(page);
      }
    }}
    disabled={!page || current === page}
  >
    {text}
  </button>
);

interface IPaginationProps {
  pagination: IJBuilderPagination | IGQLPagination;
  onPage(page: number): void;
}

export const Pagination = ({ pagination, onPage }: IPaginationProps) => {
  const current =
    pagination.__typename === 'Pagination'
      ? (pagination as IGQLPagination).currentPage
      : (pagination as IJBuilderPagination).current_page;
  const total =
    pagination.__typename === 'Pagination'
      ? (pagination as IGQLPagination).totalPages
      : (pagination as IJBuilderPagination).total_pages;
  const prev =
    pagination.__typename === 'Pagination'
      ? (pagination as IGQLPagination).prevPage
      : (pagination as IJBuilderPagination).prev_page;
  const next =
    pagination.__typename === 'Pagination'
      ? (pagination as IGQLPagination).nextPage
      : (pagination as IJBuilderPagination).next_page;

  return (
    <>
      {total > INITIAL_PAGE && (
        <div className="text-center">
          <div className="pagination btn-group">
            <PaginationButton text="«" page={INITIAL_PAGE} current={current} onPage={onPage} />
            <PaginationButton text="‹" page={prev} current={current} onPage={onPage} />

            {pages(current, total).map((page) => (
              <PaginationButton key={page} page={page} text={page} current={current} onPage={onPage} />
            ))}

            <PaginationButton text="›" page={next} current={current} onPage={onPage} />
            <PaginationButton text="»" page={total} current={current} onPage={onPage} />
          </div>
        </div>
      )}
    </>
  );
};

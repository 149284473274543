import cn from 'classnames';
import React from 'react';

import { Categories } from '@admin/components/tickets/categories';
import { TicketGroupPicker } from '@admin/components/tickets/ticket_group_picker';
import { TicketFiltersInput } from '@admin/schema';
import { Nav } from '@shared/components/bootstrap';
import { TicketMode, TicketState, TicketClaimedState } from '@admin/types';

type ALLOWED_MODE = TicketMode.MyTickets | TicketMode.AssignedToMyGroups | TicketMode.All;

const TAB_NAMES = {
  [TicketMode.MyTickets]: 'My Tickets',
  [TicketMode.AssignedToMyGroups]: 'Assigned to My Groups',
  [TicketMode.All]: 'All',
};

const TABS: ALLOWED_MODE[] = [TicketMode.MyTickets, TicketMode.AssignedToMyGroups, TicketMode.All];

const Tabs: React.FC<{
  selectedMode?: ALLOWED_MODE;
  setMode(mode: ALLOWED_MODE): void;
}> = ({ selectedMode, setMode }) => (
  <Nav format={Nav.Format.Tabs}>
    {TABS.map((tab) => (
      <Nav.Item key={tab} className={cn({ active: tab === selectedMode })}>
        <Nav.Link onClick={() => setMode(tab)}>{TAB_NAMES[tab]}</Nav.Link>
      </Nav.Item>
    ))}
  </Nav>
);

export const Filters: React.FC<{
  filters: TicketFiltersInput;
  setFilters(filters: TicketFiltersInput): void;
}> = ({ filters, setFilters }) => {
  const { mode, category, subCategory, state, groupID, assigneeID, claimed } = filters;
  const onModeChange = (newMode: ALLOWED_MODE) => {
    setFilters({ ...filters, groupID: '', assigneeID: '', mode: newMode });
  };
  const onStateChange = (newState: TicketState) => {
    setFilters({ ...filters, state: newState });
  };
  const onClaimedStateChange = (newClaimedState: TicketClaimedState) => {
    setFilters({ ...filters, claimed: newClaimedState });
  };
  const onCategoryChange = (newCategory: string, newSubCategory: string) => {
    setFilters({ ...filters, category: newCategory, subCategory: newSubCategory });
  };
  const onTicketGroupChange = (newGroupID: string, newAssigneeID: string) => {
    setFilters({ ...filters, groupID: newGroupID, assigneeID: newAssigneeID });
  };
  return (
    <>
      <Tabs selectedMode={mode as ALLOWED_MODE} setMode={(newMode) => onModeChange(newMode)} />
      <div className="mar-top row">
        <div className="col-md-2">
          <select
            className="form-control text-capitalize"
            value={state || ''}
            onChange={(e) => onStateChange(e.currentTarget.value as TicketState)}
          >
            <option value="">-- State --</option>
            {[TicketState.Open, TicketState.Paused, TicketState.Pending, TicketState.Resolved].map(
              (ticketState, key) => (
                <option key={key} value={ticketState}>
                  {ticketState}
                </option>
              ),
            )}
          </select>
        </div>
        <div className="col-md-2">
          <select
            className="form-control text-capitalize"
            value={claimed || ''}
            onChange={(e) => onClaimedStateChange(e.currentTarget.value as TicketClaimedState)}
          >
            <option value="">-- Select a claimed state --</option>
            {[TicketClaimedState.Claimed, TicketClaimedState.Unclaimed].map((ticketClaimedState, key) => (
              <option key={key} value={ticketClaimedState}>
                {ticketClaimedState}
              </option>
            ))}
          </select>
        </div>
        <Categories
          className="col-md-2"
          selectedCategory={category ?? ''}
          selectedSubCategory={subCategory ?? ''}
          onChange={onCategoryChange}
        />
        {mode !== TicketMode.MyTickets && mode !== TicketMode.AssignedToMyGroups && (
          <TicketGroupPicker
            className="col-md-2"
            selectedGroupID={groupID ?? ''}
            selectedAssigneeID={assigneeID ?? ''}
            onChange={onTicketGroupChange}
          />
        )}
      </div>
    </>
  );
};

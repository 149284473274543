import styled from '@emotion/styled';
import React from 'react';

import { COLORS } from '@root/colors';

export const TD = styled.td<{ highlight?: boolean; className?: string }>`
  ${({ highlight }) => highlight && `background: ${COLORS.orangeLightest}`}
`;

export const Totals = styled.table`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 8px 4px;
  font-weight: 600;

  tr:not(:last-child) {
    color: ${COLORS.grayDark};
  }

  td {
    padding: 0px 4px;
    text-align: right;
  }
`;

import React from 'react';

import styled from '@emotion/styled';

const AgreementBox = styled.div`
  border-style: solid;
  border-color: lightgray;
  border-width: thin;
  border-radius: 4px;
  padding: 1rem;
`;

const numMovers = (cuft: number) => {
  if (cuft <= 500) {
    return '1 mover';
  } else if (cuft <= 1000) {
    return '2 movers';
  } else if (cuft <= 1400) {
    return '3 movers';
  } else {
    return '4 movers';
  }
};

export const LaborAgreementDescriptor: React.FC<{
  agreement: string[];
  cuft: number;
  isCurbside: boolean;
}> = ({ agreement, cuft, isCurbside }) => (
  <AgreementBox>
    <ul>
      {agreement.map((sentence, index) => (
        <li key={index}> {sentence} </li>
      ))}
      {!isCurbside && cuft && <li> {numMovers(cuft)} will be provided. </li>}
    </ul>
  </AgreementBox>
);

import styled from '@emotion/styled';
import { format } from 'date-fns';
import { DateTime } from 'luxon';
import React, { useState } from 'react';

import { Map } from '@admin/components/helpers/map';
import { Panel } from '@admin/components/helpers/panel';
import { HistoriesPanel } from '@admin/components/histories/panel';
import { NotesPanel } from '@admin/components/notes/panel';
import { Spinner } from '@admin/components/spinner';
import { AnchorButton as Link, Button, Modal, Text } from '@shared/components/bootstrap';
import { Spacer } from '@shared/components/helpers';

import { Summary as PromotionSummary } from '@admin/components/self_storage/promotions/summary';

import {
  SelfStorage__Reservation__State,
  useCancelSelfStorageReservationMutation,
  useSelfStorageReservationQuery,
  useSelfStorageCurrentUserTokenQuery,
  useSelfStorageReservationPolicyQuery,
} from '@admin/schema';
import { client } from '@admin/libraries/apollo';
import { formatDate } from '@shared/utils';

import { History__Subject, NoteNotableEnum } from '@admin/schema';
import { Tag } from '@admin/components/helpers/tag';
import { accountURL } from '@admin/config/routes';
import { StateLabel } from './table';
import { LeadBreadcrumbs } from '../components/lead_breadcrumbs';
import { getFeaturesForReservation } from './utils';

const Field = styled.div`
  display: flex;
  padding-bottom: 12px;
`;

const FieldTitle = styled.div`
  width: 120px;
  padding-right: 20px;
  font-weight: bold;
`;

const FeaturesList = styled.ul`
  list-style: none;
  padding-left: 4px;

  & li:before {
    display: inline-block;
    width: 8px;
    content: '-';
  }
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: -4px;
`;

const Action = styled.div`
  display: flex;
  padding: 4px;
`;

const PLACEHOLDER = '-';

const ConfirmTitle = styled.div`
  text-align: center;
`;

const ConfirmButtons = styled.div`
  display: flex;
  justify-content: center;
`;

const Confirm: React.FC<{
  title: string;
  onConfirm(): void;
  onCancel(): void;
}> = ({ onConfirm, onCancel, title }) => (
  <Modal onClose={onCancel}>
    <Modal.Content>
      <Modal.Body>
        <ConfirmTitle>
          <Text tag="h4">{title}</Text>
        </ConfirmTitle>
        <Spacer height="16px" />
        <ConfirmButtons>
          <Button kind="primary" onClick={onConfirm}>
            Yes
          </Button>
          <Spacer width="8px" />
          <Button kind="default" onClick={onCancel}>
            No
          </Button>
        </ConfirmButtons>
      </Modal.Body>
    </Modal.Content>
  </Modal>
);

export const Detail: React.FC<{
  id: string;
}> = ({ id }) => {
  const [cancelling, setCancelling] = useState<boolean>(false);
  const { data } = useSelfStorageReservationQuery({ client, variables: { id } });
  const [cancel, { loading }] = useCancelSelfStorageReservationMutation({ client });
  const user = useSelfStorageCurrentUserTokenQuery({ client }).data?.user;
  const { data: policy } = useSelfStorageReservationPolicyQuery({ client, variables: { reservationID: id } });

  const reservation = data && data.reservation;

  if (!reservation) {
    return (
      <Panel>
        <Panel.Body>
          <Spinner />
        </Panel.Body>
      </Panel>
    );
  }

  const pending = reservation.state === SelfStorage__Reservation__State.Pending;
  const isThirdParty = reservation.facility.partner?.isThirdParty;

  return (
    <>
      <Panel>
        <Panel.Header>
          <Panel.Title>
            <LeadBreadcrumbs reservation={reservation} />
          </Panel.Title>
        </Panel.Header>
        <Panel.Body>
          <div className="col-md-7">
            <Field>
              <FieldTitle>Lead:</FieldTitle>
              <span>{`#${reservation.lead.id}`}</span>
            </Field>
            {reservation.account && (
              <Field>
                <FieldTitle>Account:</FieldTitle>
                <a
                  className="btn-link"
                  href={accountURL({ id: reservation.account.id })}
                >{`#${reservation.account.id}`}</a>
              </Field>
            )}
            <Field>
              <FieldTitle>Name:</FieldTitle>
              <span>{reservation.name}</span>
            </Field>
            <Field>
              <FieldTitle>Phone:</FieldTitle>
              <span>{reservation.phone}</span>
            </Field>
            <Field>
              <FieldTitle>Email:</FieldTitle>
              <span>{reservation.email}</span>
            </Field>
            <Field>
              <FieldTitle>Unit:</FieldTitle>
              <div>
                <div>{`${reservation.width}×${reservation.length} ${reservation.unitType} at $${reservation.quotedPrice}/mo`}</div>
                <FeaturesList>
                  {getFeaturesForReservation(reservation).map((feature, index) => (
                    <li key={index}>{feature}</li>
                  ))}
                </FeaturesList>
              </div>
            </Field>
            {reservation.promotion && (
              <Field>
                <FieldTitle>Promotion:</FieldTitle>
                <PromotionSummary promotion={reservation.promotion} />
              </Field>
            )}
            <Field>
              <FieldTitle>Move-In Date:</FieldTitle>
              <span>{reservation.date && format(reservation.date, ' ddd, MMM Do, YYYY')}</span>
            </Field>
            <Field>
              <FieldTitle>Facility:</FieldTitle>
              <div>
                <div>{reservation.facility.partner?.name}</div>
                <div>{reservation.facility.phoneNumber}</div>
                <div>{reservation.facility.name}</div>
                <div>{reservation.facility.address.street}</div>
                <div>
                  {reservation.facility.address.city}, {reservation.facility.address.state}{' '}
                  {reservation.facility.address.zip}
                </div>
              </div>
            </Field>
            {isThirdParty && (
              <>
                <Field>
                  <FieldTitle>Subscribed to third party SMS:</FieldTitle>
                  <div>{reservation.smsSubscribed ? 'Yes' : 'No'}</div>
                </Field>
                <Field>
                  <FieldTitle>Created At:</FieldTitle>
                  <div>{formatDate(reservation.createdAt, DateTime.DATETIME_FULL, false)}</div>
                </Field>
              </>
            )}
            {reservation.tags.length > 0 && (
              <Field>
                <FieldTitle>Tags:</FieldTitle>
                <div>
                  {reservation.tags.map((t) => (
                    <Tag key={t} tag={t} />
                  ))}
                </div>
              </Field>
            )}
            <Field>
              <FieldTitle>State:</FieldTitle>
              <StateLabel state={reservation.state} />
            </Field>
            {isThirdParty && (
              <>
                <hr />
                <Field>
                  <FieldTitle>Reserver:</FieldTitle>
                  <div>{reservation.reserver?.name || PLACEHOLDER}</div>
                </Field>
                <Field>
                  <FieldTitle>Reserved At:</FieldTitle>
                  <div>
                    {reservation.reservedAt
                      ? formatDate(reservation.reservedAt, DateTime.DATETIME_FULL, false)
                      : PLACEHOLDER}
                  </div>
                </Field>
                {pending && (
                  <Field>
                    <FieldTitle>Confirmation Attempt Date:</FieldTitle>
                    <div>
                      {reservation.confirmationAttemptDate
                        ? formatDate(reservation.confirmationAttemptDate)
                        : PLACEHOLDER}
                    </div>
                  </Field>
                )}
                <Field>
                  <FieldTitle>Third Party Confirmation Number:</FieldTitle>
                  <div>{reservation.externalConfirmationNumber || PLACEHOLDER}</div>
                </Field>
                <Field>
                  <FieldTitle>Booking Link:</FieldTitle>
                  <div>{reservation.facility.bookingLink}</div>
                </Field>
              </>
            )}
          </div>
          <div className="col-md-5">
            <Map center={reservation.facility.address} marker={reservation.facility.address} />
          </div>
        </Panel.Body>
        {pending && policy?.permissions?.modify && (
          <Panel.Footer>
            <Actions>
              {user && reservation.shuttleBookingUrl && (
                <Action>
                  <Link
                    kind="primary"
                    href={`${reservation.shuttleBookingUrl.url}&u=${encodeURIComponent(user.token)}`}
                    target="_blank"
                  >
                    Book Shuttle
                  </Link>
                </Action>
              )}
              {!isThirdParty && (
                <Action>
                  <Button kind="danger" onClick={() => setCancelling(true)} disabled={loading}>
                    Cancel
                  </Button>
                </Action>
              )}
              <Action>
                <Link kind="warning" href={`/self_storage/reservations/${id}/edit`}>
                  Edit
                </Link>
              </Action>
              {!isThirdParty && (
                <Action>
                  <Link
                    kind="primary"
                    href={`/self_storage/reservations/${id}/rentals/new${
                      reservation.lead.accountID ? `?accountID=${reservation.lead.accountID}` : ''
                    }`}
                  >
                    Move-In
                  </Link>
                </Action>
              )}
            </Actions>
          </Panel.Footer>
        )}
      </Panel>
      {cancelling && (
        <Confirm
          title="Are you sure you want to cancel?"
          onConfirm={() => {
            cancel({ variables: { id } });
            setCancelling(false);
          }}
          onCancel={() => {
            setCancelling(false);
          }}
        />
      )}
      <NotesPanel id={id} type={NoteNotableEnum.SelfStorageReservation} />
      <HistoriesPanel id={id} type={History__Subject.SelfStorageReservation} />
    </>
  );
};

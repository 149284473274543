(function () {
  angular.module('app').filter('addressAccessibility', [
    '$filter',
    function ($filter) {
      return function (address) {
        function displayHouseAccessibility() {
          const options = [];

          if (typeof address.stories !== 'undefined' && address.stories !== null) {
            options.push(address.stories + ' ' + $filter('pluralize')(address.stories, 'story', 'stories'));
          }

          if (typeof address.front_door_steps !== 'undefined' && address.front_door_steps !== null) {
            options.push(
              address.front_door_steps +
                ' ' +
                $filter('pluralize')(address.front_door_steps, 'step in front', 'steps in front'),
            );
          }

          if (options.length) {
            return '(' + options.join(', ') + ')';
          }
        }

        function displayApartmentAccessibility() {
          const options = [];

          if (typeof address.floor !== 'undefined' && address.floor !== null) {
            options.push(address.floor + ' ' + $filter('pluralize')(address.floor, 'floor', 'floors'));
          }

          if (address.stairs) {
            options.push('stairs');
          }

          if (address.elevator) {
            options.push('elevator');
          }

          if (address.service_elevator) {
            const index = options.indexOf('elevator');
            if (index >= 0) {
              options.splice(index, 1);
            }
            options.push('service elevator');
          }

          if (options.length) {
            return '(' + options.join(', ') + ')';
          }
        }

        if (address.building_type === 'House') {
          return displayHouseAccessibility();
        } else {
          return displayApartmentAccessibility();
        }
      };
    },
  ]);
})();

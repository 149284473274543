(function () {
  const app = angular.module('app');

  app.directive('collapsiblePanel', [
    function () {
      return {
        restrict: 'A',
        replace: 'false',
        transclude: 'element',
        scope: {
          panelId: '@collapsiblePanel',
        },
        link: function (scope, element, attrs, ctrl, transclude) {
          const oldDom = angular.element(transclude());

          // insert collapsible button
          const panelControlEl = angular.element(
            '<div class="panel-control"><button class="btn btn-default" data-target="#' +
              scope.panelId +
              '" data-toggle="collapse"><i class="fa fa-chevron-down"></i></button></div>',
          );
          angular.element(oldDom.find('.panel-heading')).prepend(panelControlEl);

          const panelBodyEl = angular.element(oldDom.find('.panel-body'));
          const panelFooterEl = angular.element(oldDom.find('.panel-footer'));
          const newBodyEl = angular.element('<div class="collapse in" id="' + scope.panelId + '"></div>');

          // wrap collapsible content in a div to prevent jitter
          newBodyEl.append(panelBodyEl, panelFooterEl);
          oldDom.append(newBodyEl);

          panelControlEl.bind('click', function (e) {
            e.preventDefault();
            angular.element(panelControlEl.find('.fa')).toggleClass('fa-chevron-down fa-chevron-right');
          });

          element.after(oldDom);
        },
      };
    },
  ]);
})();

import styled from '@emotion/styled';
import * as React from 'react';

import { ISelection, Selector, Uploader } from '@shared/components/files';
import { Spacer } from '@shared/components/helpers';

const FlexContainer = styled.div`
  height: 40px;
  display: flex;
  align-items: start;
`;

const UploadBar = styled.div`
  min-width: 144px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const FileName = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const FileUploader: React.FC<{
  disabled: boolean;
  file?: File;
  upload?: ISelection;
}> = ({ disabled, file, upload }) => (
  <FlexContainer>
    <label className={`btn btn-xs btn-rounded btn-default ${disabled && 'disabled'}`}>
      <Selector multiple={false} accept=".csv,.xlsx" disabled={disabled} />
      Choose File
    </label>
    <Spacer width="6px" />
    {!upload && <FileName>{file ? file.name : 'No file chosen'}</FileName>}
    <UploadBar>{upload && <Uploader key={upload.uuid} {...upload} />}</UploadBar>
  </FlexContainer>
);

import React from 'react';

import { Thumbnails } from '@admin/components/helpers/thumbnails';
import { Claim__PropertySelectionFragment } from '@admin/schema';
import { ListGroup } from '@shared/components/bootstrap';
import { Currency } from '@shared/components/helpers';

import { AssessmentSummary } from '../assessment_summary';

export const PropertySelection: React.FC<{
  propertySelection: Claim__PropertySelectionFragment;
}> = ({ propertySelection }) => {
  const { declaration, name, owner, photos, quotes, resolution, assessment } = propertySelection;
  return (
    <>
      <ListGroup.Item>
        <div className="row">
          <div className="col-sm-2">
            <strong>Property</strong>
          </div>
          <div className="col-sm-1">
            <strong>Type</strong>
          </div>
          <div className="col-sm-1">
            <strong>Images</strong>
          </div>
          <div className="col-sm-3">
            <strong>Owner</strong>
          </div>
          <div className="col-sm-1">
            <strong>Declared</strong>
          </div>
          <div className="col-sm-2">
            <strong>Classifications</strong>
          </div>
          <div className="col-sm-1">
            <strong>Approved</strong>
          </div>
          <div className="col-sm-1">
            <strong>Offered</strong>
          </div>
        </div>
      </ListGroup.Item>
      <ListGroup.Item>
        <div className="row">
          <div className="col-sm-2">{name}</div>
          <div className="col-sm-1">Property Damage</div>
          <div className="col-sm-1">
            <Thumbnails attachments={photos.concat(quotes)} condensed />
          </div>
          <div className="col-sm-3">
            {!owner ? (
              'Customer'
            ) : (
              <>
                <div>{owner.name}</div>
                <div>{owner.email}</div>
                <div>{owner.phone}</div>
              </>
            )}
          </div>
          <div className="col-sm-1">
            <Currency value={Number(declaration || 0)} />
          </div>
          <div className="col-sm-2">
            <AssessmentSummary assessment={assessment} kind={resolution?.kind} reason={resolution?.reason} />
          </div>
          <div className="col-sm-1">{!!assessment && <Currency value={Number(assessment?.approvedAmount || 0)} />}</div>
          <div className="col-sm-1">{!!resolution && <Currency value={Number(resolution?.amount || 0)} />}</div>
        </div>
      </ListGroup.Item>
    </>
  );
};

import template from '@admin/angular/templates/order_address_selector.html';

import angular from 'angular';

const app = angular.module('app');

app.component('orderAddressSelector', {
  template,
  bindings: {
    account: '<',
    order: '<',
    showAccountAddresses: '<',
    region: '=',
    managing: '=',
    warehouseReturn: '<',
  },
  controller: [
    function ctrl() {
      this.selected = (address) => {
        this.order.address = address;
      };

      this.deselected = () => {
        delete this.order.address;
      };

      this.geocoded = (geocode) => {
        this.potentially_invalid = geocode.potentially_invalid;
        this.order.region = this.warehouseReturn && this.order.region ? this.order.region : geocode.region;
      };

      this.onWarehouseSelect = (region) => {
        this.order.region = region;
      };
    },
  ],
});

import * as React from 'react';

const UNKNOWN_AREA = '999';
const UNKNOWN_EXCHANGE = '999';
const UNKNOWN_SUBSCRIBER = '9999';

const REGEX = /^(\+1)?\s*[\(]?(\d{0,3})[\)]?[\-\s]*(\d{0,3})[\-\s]*(\d{0,4})$/;

const DEFAULT_COUNTRY = '+1';

export const Phone: React.FC<{ value: string }> = ({ value }) => {
  const match = value.match(REGEX);
  if (!match) {
    return <>{value}</>;
  }
  const [, country, area, exchange, subscriber] = match;
  if (area === UNKNOWN_AREA && exchange === UNKNOWN_EXCHANGE && subscriber === UNKNOWN_SUBSCRIBER) {
    return <>unknown</>;
  }
  return (
    <>
      {country !== DEFAULT_COUNTRY && country} ({area}) {exchange}-{subscriber}
    </>
  );
};

import React, { useEffect, useState } from 'react';

import AddressValidator from '@admin/react/components/AddressValidator';
import { Country, COUNTRIES, STATES, DEFAULT_COUNTRY, DEFAULT_STATES } from '@admin/constants/geography';
import { GeocodeQuery, useGeocodeQuery } from '@admin/schema';
import { ControlLabel, FormGroup, Row, Col } from '@shared/components/bootstrap';
import { InputFormControl } from '@shared/components/fields/input_form_control';
import { client } from '@admin/libraries/apollo';
import { Address } from '@admin/types/address';
import { AutocompleteFormControl } from './autocomplete_form_control';

const DEBOUNCE_DELAY = 500; // ms;

const useDebouncedGeocodeQuery = ({
  fields: { city, state, zip, street },
  onGeocode,
}: {
  fields: Address;
  onGeocode(geocode?: GeocodeQuery['geocode']): void;
}) => {
  const [input, setInput] = useState(() => (city && state && zip ? { city, state, zip, street } : undefined));

  useEffect(() => {
    if (!city || !state || !zip) return;
    const timeout = setTimeout(() => setInput({ city, state, zip, street }), DEBOUNCE_DELAY);
    return () => clearTimeout(timeout);
  }, [city, state, zip, street]);

  return useGeocodeQuery({
    client,
    variables: { input: input! },
    skip: !input,
    onCompleted: ({ geocode }) => {
      onGeocode(geocode ?? undefined);
    },
  });
};

export const Fields: React.FC<{
  disabled?: boolean;
  fields: Address;
  shouldFlagAmbiguousAddress: boolean;
  shouldFlagInvalidAddress: boolean;
  onChange(address: Address): void;
  onGeocode?(geocode?: GeocodeQuery['geocode']): void;
  onError?(message: string | null): void;
}> = ({ disabled, fields, shouldFlagAmbiguousAddress, shouldFlagInvalidAddress, onGeocode, onChange, onError }) => {
  const { data } = useDebouncedGeocodeQuery({
    fields,
    onGeocode: (geocode) => {
      onChange({
        ...fields,
        latitude: geocode?.latitude,
        longitude: geocode?.longitude,
      });
      if (onGeocode) onGeocode(geocode);
    },
  });

  const [autocomplete, setAutocomplete] = useState<{
    query?: string;
    error?: string;
  }>({});

  return (
    <fieldset disabled={disabled}>
      <Row>
        <Col sm={7}>
          <FormGroup>
            <ControlLabel>Address</ControlLabel>
            <AutocompleteFormControl
              placeholder="Street"
              name="address[street]"
              zip={fields.zip ?? undefined}
              query={autocomplete.query ?? fields.street ?? ''}
              onChange={(place) => {
                onChange({
                  ...fields,
                  street: place.street,
                  city: place.city,
                  state: place.state,
                  country: place.country,
                  zip: place.zip,
                });
                setAutocomplete({ query: place.street });
              }}
              onQuery={(query) => {
                fields.street = query;
                setAutocomplete({ ...autocomplete, query });
              }}
              onError={(error) => {
                setAutocomplete({ ...autocomplete, error });
              }}
            />
          </FormGroup>
        </Col>

        <Col sm={5}>
          <FormGroup>
            <ControlLabel>Apt, Suite, etc (optional)</ControlLabel>
            <InputFormControl
              type="text"
              placeholder="Apt, Suite, etc"
              name="address[aptsuite]"
              value={fields.aptsuite ?? ''}
              onChange={(event) => onChange({ ...fields, aptsuite: event.target.value || undefined })}
            />
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col sm={12}>
          <FormGroup>
            <ControlLabel>Business Name (optional)</ControlLabel>
            <InputFormControl
              type="text"
              placeholder="Business Name (optional)"
              name="address[business_name]"
              value={fields.business_name ?? ''}
              onChange={(event) => onChange({ ...fields, business_name: event.target.value || undefined })}
            />
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col sm={3}>
          <FormGroup>
            <ControlLabel>City</ControlLabel>
            <InputFormControl
              required
              type="text"
              placeholder="City"
              name="address[city]"
              value={fields.city ?? ''}
              onChange={(event) => onChange({ ...fields, city: event.target.value || undefined })}
            />
          </FormGroup>
        </Col>

        <Col sm={3}>
          <FormGroup>
            <ControlLabel>State</ControlLabel>
            <select
              required
              className="form-control"
              name="address[state]"
              value={fields.state ?? ''}
              onChange={(event) => onChange({ ...fields, state: event.target.value || undefined })}
            >
              <option value=""> - State - </option>
              {(STATES[fields.country as Country] ?? DEFAULT_STATES).map((state) => (
                <option key={state} value={state}>
                  {state}
                </option>
              ))}
            </select>
          </FormGroup>
        </Col>

        <Col sm={3}>
          <FormGroup>
            <ControlLabel>Country</ControlLabel>
            <select
              required
              className="form-control"
              name="address[country]"
              value={fields.country ?? DEFAULT_COUNTRY}
              onChange={(event) => onChange({ ...fields, country: event.target.value || undefined })}
            >
              {COUNTRIES.map((country) => (
                <option key={country} value={country}>
                  {country}
                </option>
              ))}
            </select>
          </FormGroup>
        </Col>

        <Col sm={3}>
          <FormGroup>
            <ControlLabel>ZIP</ControlLabel>
            <InputFormControl
              required
              type="text"
              placeholder="ZIP"
              name="address[zip]"
              value={fields.zip ?? ''}
              onChange={(event) => onChange({ ...fields, zip: event.target.value || undefined })}
            />
          </FormGroup>
        </Col>
      </Row>

      <AddressValidator
        address={fields}
        geocode={data?.geocode}
        shouldFlagAmbiguousAddress={shouldFlagAmbiguousAddress}
        shouldFlagInvalidAddress={shouldFlagInvalidAddress}
        onAddressChange={onError}
      />
    </fieldset>
  );
};

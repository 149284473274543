import moment from 'moment';
import _ from 'lodash';
import angular from 'angular';

import { ALL_DAY_SLA_END, ALL_DAY_SLA_START, isAllDaySLA } from '@shared/utils/all_day_sla';
import { currency } from '@shared/utils';

const sortDatesAsc = (eventGroup1, eventGroup2) => {
  if (eventGroup1.when > eventGroup2.when) return 1;
  if (eventGroup1.when < eventGroup2.when) return -1;
  return 0;
};

const addEmptyDates = (dates, dateGroups) => {
  dates.forEach((d) => {
    const found = dateGroups.some((g) => moment(d).isSame(g.when, 'day'));
    if (!found) {
      dateGroups.push({
        when: d,
        events: [],
      });
    }
  });

  return dateGroups.sort(sortDatesAsc);
};

angular.module('app').component('schedule', {
  templateUrl: 'partials/components/schedule.html',
  bindings: {
    onScheduleEventClick: '&',
    events: '<',
    perItemFee: '<',
    selectedEvent: '@',
    activeEvent: '@',
    className: '@',
    emptyDayMessage: '@',
    emptyMessage: '@',
    footerMessage: '@',
    timezone: '<',
    untouchedMessage: '@',
    dates: '<',
    unlimited: '<',
    loading: '<',
  },
  controller: [
    function () {
      this.$onChanges = (changes) => {
        const { events = {}, selectedEvent = {}, activeEvent = {} } = changes;
        if (!_.isEmpty(events.currentValue)) {
          this.touched = true;
        }
        if (
          !_.isEqual(events.currentValue, events.previousValue) ||
          selectedEvent.currentValue !== selectedEvent.previousValue ||
          activeEvent.currentValue !== activeEvent.previousValue
        ) {
          delete this.groupedEvents;
        }
      };

      this.$onInit = () => {
        if (this.events) {
          this.touched = true;
        }
      };

      const createUnlimitedGroupedEvents = (d) => ({
        when: d,
        events: moment(d).isSame(new Date(), 'day')
          ? []
          : [
              {
                from_time: moment(d).tz(this.timezone).set({ hour: 7 }).toDate(),
                till_time: moment(d).tz(this.timezone).set({ hour: 10 }).toDate(),
                id: moment(d).tz(this.timezone).set({ hour: 7 }).toISOString(),
              },
              {
                from_time: moment(d).tz(this.timezone).set({ hour: 9 }).toDate(),
                till_time: moment(d).tz(this.timezone).set({ hour: 12 }).toDate(),
                id: moment(d).tz(this.timezone).set({ hour: 9 }).toISOString(),
              },
              {
                from_time: moment(d).tz(this.timezone).set({ hour: 11 }).toDate(),
                till_time: moment(d).tz(this.timezone).set({ hour: 14 }).toDate(),
                id: moment(d).tz(this.timezone).set({ hour: 11 }).toISOString(),
              },
            ],
      });

      this.createGroupedEvents = () => {
        if (this.unlimited) {
          return this.dates.sort((a, b) => a - b).map(createUnlimitedGroupedEvents);
        }

        const dateGroups = _(this.events)
          .sortBy(
            ({ from_time: fromTime }) => fromTime,
            ({ till_time: tillTime }) => tillTime,
          )
          .groupBy(({ from_time: fromTime }) => moment(fromTime).startOf('day').valueOf())
          .toPairs()
          .sortBy(([when]) => Number(when))
          .map(([when, events]) => ({ when: new Date(Number(when)), events }))
          .value();

        return addEmptyDates(this.dates, dateGroups);
      };

      const eventDisabled = (id) => {
        if (this.unlimited) return false;
        const event = _.find(this.events, { id });
        return !event || event.disabled;
      };

      this.eventDescription = (event) => {
        const laborRateAmount = event.laborRate && currency(event.laborRate.amount);
        const laborDescription = event.laborCreditEligible
          ? 'Free Labor Eligible'
          : laborRateAmount && `Labor: ${laborRateAmount} ${event.laborBillingFormat}`;
        const itemFee = this.perItemFee;
        const appointmentFeeDescription =
          event.appointmentFee &&
          itemFee &&
          `$${itemFee.amount}/Item (1 mover) + $${itemFee.largeAmount}/Item (2+ movers) + $${parseFloat(
            event.appointmentFee,
          ).toFixed(2)} Service Charge`;

        return [laborDescription, appointmentFeeDescription].filter(Boolean).join(', ');
      };

      this.getCalendarClass = (id) => {
        if (!id) {
          return '';
        }
        const disabled = eventDisabled(id);

        const classes = [];
        if (this.onScheduleEventClick && !disabled) {
          classes.push('calendar-event-clickable');
        } else if (disabled) {
          classes.push('calendar-disabled');
        }
        const { selectedEvent, activeEvent } = this;
        if (selectedEvent === id) {
          classes.push('selected');
        } else if (activeEvent === id) {
          classes.push('active');
        }
        return classes.join(' ');
      };

      this.getGroupedEvents = () => {
        this.groupedEvents = this.groupedEvents || this.createGroupedEvents();
        return this.groupedEvents;
      };

      this.handleCalendarClick = ({ id, duration, laborRate, perMoverHourAdjustmentAmount }) => {
        if (this.onScheduleEventClick && id && !eventDisabled(id)) {
          this.onScheduleEventClick({
            $id: id,
            $duration: duration,
            $laborRate: laborRate,
            $perMoverHourAdjustmentAmount: perMoverHourAdjustmentAmount,
          });
        }
      };

      this.showUntouchedMessage = () => !this.loading && !this.touched;

      this.allDaySLA = () => {
        const group = this.getGroupedEvents();
        return group.some(({ events }) =>
          events.some((event) => isAllDaySLA(event.from_time.getHours(), event.till_time.getHours())),
        );
      };

      this.allDaySlaEnd = ALL_DAY_SLA_END;
      this.allDaySlaStart = ALL_DAY_SLA_START;
    },
  ],
});

import { capitalize } from 'lodash';
import React from 'react';

import { SelectFormControl } from '@admin/components/fields';
import { Thumbnails } from '@admin/components/helpers/thumbnails';
import { Claim__ItemSelectionFragment, Claim__ItemSelectionKind } from '@admin/schema';
import { ListGroup } from '@shared/components/bootstrap';

import { Resolution } from './resolution';
import { ClaimResolutionEdits, ClaimItemEdits, ClaimKindEdits } from '../types';

const DECLINE_REASONS = [
  'Not covered',
  'Previously compensated',
  'Packed by Owner',
  'Clutter not at fault',
  'Item Found',
];

interface IEditProps {
  itemEdits: ClaimItemEdits;
  kindEdits: ClaimKindEdits;
  resolutionEdits: ClaimResolutionEdits;
  setItemEdits(edits: ClaimItemEdits): void;
  setKindEdits(edits: ClaimKindEdits): void;
  setResolutionEdits(edits: ClaimResolutionEdits): void;
}

const ItemSelection: React.FC<IEditProps & { selection: Claim__ItemSelectionFragment }> = ({
  selection,
  itemEdits,
  kindEdits,
  resolutionEdits,
  setItemEdits,
  setKindEdits,
  setResolutionEdits,
}) => {
  const { photos, productURL, receipts } = selection;
  const itemID = itemEdits[selection.id] ?? selection.item?.id;
  const kind = kindEdits[selection.id] ?? selection.kind;

  return (
    <div className="row">
      <div className="col-sm-2">
        <input
          placeholder="Enter Item ID"
          value={itemID || ''}
          className="form-control"
          onChange={(event) => setItemEdits({ ...itemEdits, [selection.id]: event.target.value })}
        />
      </div>
      <div className="col-sm-1">
        <SelectFormControl
          options={[Claim__ItemSelectionKind.Lost, Claim__ItemSelectionKind.Damaged]}
          value={kind}
          name={capitalize}
          onChange={(option) => setKindEdits({ ...kindEdits, [selection.id]: option as Claim__ItemSelectionKind })}
        />
      </div>
      <div className="col-sm-1">
        <Thumbnails attachments={photos.concat(receipts)} condensed />
      </div>
      <div className="col-sm-3">
        <a className="btn-link text-overflow" href={productURL ?? undefined} target="_blank">
          {productURL}
        </a>
      </div>
      <div className="col-sm-5">
        <Resolution
          disabled={false}
          declineReasons={DECLINE_REASONS}
          selection={selection}
          resolutionEdits={resolutionEdits}
          onResolve={setResolutionEdits}
        />
      </div>
    </div>
  );
};

export const ItemSelections: React.FC<IEditProps & { itemSelections: Claim__ItemSelectionFragment[] }> = ({
  itemSelections,
  itemEdits,
  kindEdits,
  resolutionEdits,
  setItemEdits,
  setKindEdits,
  setResolutionEdits,
}) => (
  <>
    <ListGroup.Item>
      <div className="row">
        <div className="col-sm-2">
          <strong>Item</strong>
        </div>
        <div className="col-sm-1">
          <strong>Type</strong>
        </div>
        <div className="col-sm-1">
          <strong>Images</strong>
        </div>
        <div className="col-sm-3">
          <strong>URL</strong>
        </div>
        <div className="col-sm-2">
          <strong>Valid?</strong>
        </div>
        <div className="col-sm-3" />
      </div>
    </ListGroup.Item>
    {itemSelections.map((selection) => (
      <ListGroup.Item key={selection.id}>
        <ItemSelection
          selection={selection}
          itemEdits={itemEdits}
          kindEdits={kindEdits}
          resolutionEdits={resolutionEdits}
          setItemEdits={setItemEdits}
          setKindEdits={setKindEdits}
          setResolutionEdits={setResolutionEdits}
        />
      </ListGroup.Item>
    ))}
  </>
);

import { ROLES } from '@admin/config/roles';
import styled from '@emotion/styled';
import { intersection } from 'lodash';
import React, { useEffect, useState } from 'react';

import {
  Admin__PaymentPlan__OfferFragment as PaymentPlanOffer,
  Billing__Invoice__Status as InvoiceStatus,
  PaymentPlan__Offer__Type as OfferType,
  useUnforgivenInvoicesQuery,
} from '@admin/schema';

import { Select as SourcesSelect } from '@admin/components/billing/sources/select';
import { Spinner } from '@admin/components/spinner';
import { InvoiceSelections, OfferChanges } from '@admin/types/payment_plan';
import { client } from '@admin/libraries/apollo';
import { BillingMethod, UserRole } from '@admin/types';
import { currency } from '@shared/utils/currency';

import { ExpiryInput } from './expiry_input';
import { InvoiceTable } from './invoice_table';
import { MonthlyFeeInput } from './monthly_fee_input';
import { OfferButtons } from './offer_buttons';
import { OfferTypeInput } from './offer_type_input';
import { OneTimeFeeInput } from './one_time_fee_input';
import { StorageTermInput } from './storage_term_input';

const StepText = styled.p`
  font-size: 16px;
`;

const UNPAID_INVOICE_STATUSES = [InvoiceStatus.Failed, InvoiceStatus.NotAttempted];

export const Edit: React.FC<{
  offer?: PaymentPlanOffer;
  accountID: string;
  offerChanges: OfferChanges;
  lockType?: boolean;
  setOfferChanges(changes: OfferChanges): void;
  finishEditing?(): void;
}> = ({ offer, accountID, offerChanges, lockType, setOfferChanges, finishEditing }) => {
  const [invoiceSelections, setInvoiceSelections] = useState<InvoiceSelections>({});
  const [oneTimeFeeIncluded, setOneTimeFeeIncluded] = useState<boolean>(!!offerChanges.oneTimeFee);
  const [storageTermIncluded, setStorageTermIncluded] = useState<boolean>(!!offerChanges.storageTerm);
  const [monthlyFeeIncluded, setMonthlyFeeIncluded] = useState<boolean>(
    !!offerChanges.monthlyFee && !!offerChanges.monthlyFeeTerm,
  );
  const [source, setSource] = useState<BillingMethod | undefined>();
  const [totalChecked, setTotalChecked] = useState<number>(0);

  const { data, loading } = useUnforgivenInvoicesQuery({
    client,
    variables: {
      accountID,
      statuses: UNPAID_INVOICE_STATUSES,
    },
  });

  useEffect(() => {
    if (!offer || !data || !data.invoices) {
      return;
    }

    const selections: InvoiceSelections = {};
    const savedInvoiceIDs = offer.settledInvoices.map(({ id }) => id);
    const outstandingInvoiceIDs = data.invoices.map(({ id }) => id);
    const selectedInvoiceIDs = intersection(savedInvoiceIDs, outstandingInvoiceIDs);

    selectedInvoiceIDs.reduce((acc, invoiceID) => {
      acc[invoiceID] = true;
      return acc;
    }, selections);

    setInvoiceSelections(selections);
  }, [offer, data]);

  if (loading) {
    return <Spinner />;
  }

  if (!data) {
    return <p>There are no outstanding invoices.</p>;
  }

  const { invoices } = data;

  const totalFees =
    (offerChanges.oneTimeFee || 0) + (offerChanges.monthlyFee || 0) * (offerChanges.monthlyFeeTerm || 0);

  const payImmediately = offerChanges.type === OfferType.LumpSum;

  return (
    <form>
      <StepText>Step 1: Choose Payment Plan Type</StepText>
      <OfferTypeInput
        disabled={!!lockType}
        offerType={offerChanges.type}
        onChange={(offerType) =>
          setOfferChanges({
            ...offerChanges,
            type: offerType,
          })
        }
      />
      <hr />
      <StepText>Step 2: Select Invoices</StepText>
      <InvoiceTable
        invoices={invoices}
        invoiceSelections={invoiceSelections}
        totalChecked={totalChecked}
        setInvoiceSelections={(selections, checkedAmount) => {
          if (payImmediately) {
            setOfferChanges({
              ...offerChanges,
              oneTimeFee: checkedAmount,
            });
            setOneTimeFeeIncluded(true);
          }
          setTotalChecked(checkedAmount);
          setInvoiceSelections(selections);
        }}
      />
      <hr />
      <StepText>Step 3: {payImmediately ? 'Choose Payment Details' : 'Configure Payment Plan'}</StepText>
      {payImmediately ? (
        <SourcesSelect
          source={source}
          setSource={setSource}
          accountID={accountID}
          showOtherSource={intersection(ROLES, [UserRole.Collections, UserRole.SelfStorageAssociate]).length > 0}
        />
      ) : (
        <>
          <OneTimeFeeInput
            included={oneTimeFeeIncluded}
            oneTimeFee={offerChanges.oneTimeFee}
            setIncluded={setOneTimeFeeIncluded}
            onChange={(oneTimeFee) =>
              setOfferChanges({
                ...offerChanges,
                oneTimeFee: oneTimeFee,
              })
            }
          />
          <StorageTermInput
            included={storageTermIncluded}
            storageTerm={offerChanges.storageTerm}
            setIncluded={setStorageTermIncluded}
            onChange={(storageTerm) =>
              setOfferChanges({
                ...offerChanges,
                storageTerm: storageTerm,
              })
            }
          />
          <MonthlyFeeInput
            included={monthlyFeeIncluded}
            monthlyFee={offerChanges.monthlyFee}
            monthlyFeeTerm={offerChanges.monthlyFeeTerm}
            setIncluded={setMonthlyFeeIncluded}
            onChange={(monthlyFee, monthlyFeeTerm) =>
              setOfferChanges({
                ...offerChanges,
                monthlyFee: monthlyFee,
                monthlyFeeTerm: monthlyFeeTerm,
              })
            }
          />
          <div className="row">
            <div className="col-md-1 col-md-offset-9 text-right">
              <strong>Total fees:</strong>
            </div>
            <div className="col-md-2">
              <strong>{currency(totalFees)}</strong>
            </div>
          </div>
        </>
      )}
      {!payImmediately && (
        <>
          <hr />
          <StepText>Step 4: Select Expiration Date</StepText>
          <ExpiryInput
            expiry={offerChanges.expiry}
            onChange={(expiry) =>
              setOfferChanges({
                ...offerChanges,
                expiry: expiry,
              })
            }
          />
        </>
      )}
      <OfferButtons
        accountID={accountID}
        offerID={offer?.id}
        state={offer?.state}
        oneTimeFeeIncluded={oneTimeFeeIncluded}
        storageTermIncluded={storageTermIncluded}
        monthlyFeeIncluded={monthlyFeeIncluded}
        offerChanges={offerChanges}
        setOfferChanges={setOfferChanges}
        invoices={invoices}
        invoiceSelections={invoiceSelections}
        finishEditing={finishEditing}
        payImmediately={payImmediately}
        source={source}
      />
    </form>
  );
};

import Hls from 'hls.js';
import * as React from 'react';

interface IVideoPlayerProps {
  url: string;
}

interface IVideoPlayerState {
  autoplay: boolean;
  controls: boolean;
  playerId: number;
  width: number;
  height: number;
}

export class VideoPlayer extends React.Component<IVideoPlayerProps, IVideoPlayerState> {
  private videoPlayer: React.RefObject<HTMLVideoElement>;

  private hls: any;

  constructor(props: IVideoPlayerProps) {
    super(props);

    this.state = {
      playerId: Date.now(),
      width: 500,
      height: 300,
      controls: true,
      autoplay: true,
    };

    this.videoPlayer = React.createRef();
    this.hls = null;
  }

  public componentDidUpdate() {
    this.initPlayer();
  }

  public componentDidMount() {
    this.initPlayer();
  }

  public componentWillUnmount() {
    const { hls } = this;

    if (hls) {
      hls.destroy();
    }
  }

  public render() {
    const { playerId, controls, width, height } = this.state;

    return (
      <div key={playerId} className="player-area">
        <video
          ref={this.videoPlayer}
          className="video-player"
          id={`video-player-${playerId}`}
          controls={controls}
          width={width}
          height={height}
        />
        <button
          onClick={() => {
            this.videoPlayer.current!.playbackRate = 1;
          }}
        >
          1x
        </button>
        <button
          onClick={() => {
            this.videoPlayer.current!.playbackRate = 2;
          }}
        >
          2x
        </button>
        <button
          onClick={() => {
            this.videoPlayer.current!.playbackRate = 4;
          }}
        >
          4x
        </button>
        <button
          onClick={() => {
            this.videoPlayer.current!.playbackRate = 8;
          }}
        >
          8x
        </button>
      </div>
    );
  }

  private initPlayer() {
    if (this.hls) {
      this.hls.destroy();
    }

    const { url } = this.props;
    const { autoplay } = this.state;
    const { current: videoPlayer } = this.videoPlayer;

    if (!videoPlayer) {
      return;
    }

    const config = {
      maxBufferLength: 120,
      maxMaxBufferLength: 600,
      maxBufferSize: 1200 * 1000 * 1000,
      maxBufferHole: 2,
      lowBufferWatchdogPeriod: 1,
      highBufferWatchdogPeriod: 3,
    };

    const hls = new Hls(config);

    hls.loadSource(url.replace(/\n/g, ''));
    hls.attachMedia(videoPlayer);
    hls.on(Hls.Events.MANIFEST_PARSED, () => {
      if (autoplay) {
        videoPlayer!.play();
      }
    });

    this.hls = hls;
  }
}

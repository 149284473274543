export const USER_AGENT_PRIORITY_OPTIONS = [
  { label: '1 - One (Highest)', value: 1 },
  { label: '2 - Two', value: 2 },
  { label: '3 - Three', value: 3 },
  { label: '4 - Four', value: 4 },
  { label: '5 - Five', value: 5 },
  { label: '6 - Six', value: 6 },
  { label: '7 - Seven', value: 7 },
  { label: '8 - Eight', value: 8 },
  { label: '9 - Nine', value: 9 },
  { label: '10 - Ten', value: 10 },
  { label: '11 - Eleven', value: 11 },
  { label: '12 - Twelve', value: 12 },
  { label: '13 - Thirteen (Lowest)', value: 13 },
];

(function () {
  const app = angular.module('app');

  app.directive('leadForm', [
    function () {
      return {
        restrict: 'E',
        templateUrl: 'partials/checkouts/_lead_form.html',
        controller: 'LeadsFormController',
        scope: {
          afterZipSelectionCallback: '&?',
          afterLoadCallback: '&?',
          afterSaveCallback: '&?',
          followupErrors: '<',
          followupSelections: '<',
          onMovingOperationChange: '&',
          lead: '=',
          serviceAreaClassifier: '<',
          updateFollowupSelections: '&',
          validatedZip: '<?',
          service: '<',
          pins: '<',
          coupons: '<',
        },
      };
    },
  ]);
})();

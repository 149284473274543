(function () {
  angular.module('app').filter('units', function () {
    return function (number, type) {
      switch (type) {
        case 'feet':
          return number + "'";
        case 'inches':
          return number + "''";
      }
    };
  });
})();

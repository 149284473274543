import React from 'react';

import { Claim__AssessmentFragment, Claim__Assessment__Classification } from '@admin/schema';

import { AssessmentEditInput } from './helpers';
import { DISPLAYABLE_CLASSIFICATION } from '../contants';

export const AssessmentInputs: React.FC<{
  assessment?: Claim__AssessmentFragment;
  disableClassification: boolean;
  showWeight?: boolean;
  edits?: AssessmentEditInput;
  setEdits(edit: AssessmentEditInput): void;
}> = ({ assessment, disableClassification, showWeight = true, edits, setEdits }) => {
  const verifiedValue = edits?.verifiedValue ?? assessment?.verifiedValue;
  const weight = edits?.weight ?? assessment?.weight;
  const classification = edits?.classification ?? assessment?.classification;

  return (
    <>
      <div className="col-sm-2">
        <div className="input-group">
          <span className="input-group-addon">$</span>
          <input
            id="verified-value"
            value={verifiedValue || ''}
            className="form-control"
            type="number"
            onChange={(event) => setEdits({ verifiedValue: Number(event.target.value) })}
          />
        </div>
      </div>
      <div className="col-sm-2">
        {showWeight && (
          <div className="input-group">
            <input
              id="weight"
              value={weight || ''}
              className="form-control"
              type="number"
              onChange={(event) => setEdits({ weight: Number(event.target.value) })}
            />
            <span className="input-group-addon">lbs</span>
          </div>
        )}
      </div>
      <div className="col-sm-2">
        <select
          className="form-control"
          value={classification}
          onChange={(event) => setEdits({ classification: event.target.value as Claim__Assessment__Classification })}
          disabled={disableClassification}
        >
          <option value="" />
          {Object.values(Claim__Assessment__Classification).map((entry) => (
            <option value={entry} key={entry}>
              {DISPLAYABLE_CLASSIFICATION[entry]}
            </option>
          ))}
        </select>
      </div>
    </>
  );
};

import template from '@admin/angular/templates/account_materials.html';

import angular from 'angular';

const app = angular.module('app');

app.component('accountMaterials', {
  template,
  bindings: {
    account: '<',
  },
  controller: [
    function ctrl() {
      this.$onChanges = () => {
        if (!this.account) return;

        this.materialsSummary = this.account.materials_summary;
      };
    },
  ],
});

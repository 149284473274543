import React, { useState } from 'react';
import styled from '@emotion/styled';
import { client } from '@admin/libraries/apollo';

import { useOrderSignaturesQuery } from '@admin/schema';
import { Spacer } from '@shared/components/helpers';
import { Panel } from '@admin/components/helpers/panel';
import { Spinner } from '@admin/components/spinner';
import { qaEnvironment } from '@admin/config/qa_environment';
import { Table as SignaturesTable } from './table';
import { SimulateSignature } from './simulate_signature';
import { RegenerateDocuments } from './regenerate_documents';

const SpinnerWrapper = styled.div`
  display: flex;
`;

export const OrderSignaturesPanel: React.FC<{ orderID: string }> = ({ orderID }) => {
  const [collapsed, setCollapsed] = useState(false);

  const { data } = useOrderSignaturesQuery({ client, variables: { orderID } });

  const order = data?.order;

  if (!order) {
    return (
      <>
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
        <Spacer height="8px" />
      </>
    );
  }

  const signatures = order.signatures;
  const tz = order.region?.tz;

  return (
    <Panel>
      <Panel.Header>
        <Panel.Collapse collapsed={collapsed} onChange={setCollapsed} />
        <Panel.Title>Signatures</Panel.Title>
      </Panel.Header>
      {!collapsed && (
        <Panel.Body>
          <SignaturesTable signatures={signatures} tz={tz} />
        </Panel.Body>
      )}
      <Panel.Footer align="right">
        {qaEnvironment && <SimulateSignature orderID={order.id} />}
        <RegenerateDocuments signatureRequests={order.customerSignatureRequests} />
      </Panel.Footer>
    </Panel>
  );
};

import * as React from 'react';

import { Button } from '@shared/components/bootstrap';

export const FilterGroup: React.FC = ({ children }) => <Button.Group children={children} />;

export const FilterOption: React.FC<{
  selected?: boolean;
  onSelect(): void;
}> = ({ children, selected, onSelect }) => (
  <Button kind="info" onClick={onSelect} active={selected} outline={!selected}>
    {children}
  </Button>
);

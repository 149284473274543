import React from 'react';
import { connect } from 'react-redux';
import { getUser } from '../redux';

export const withUser = (Component) =>
  connect()(
    class extends React.Component {
      state = {};

      componentDidMount() {
        const user = this.props.dispatch(getUser());
        this.setState({ user });
      }

      render() {
        return <Component user={this.state.user} {...this.props} />;
      }
    },
  );

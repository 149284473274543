import React from 'react';

import { Barcode } from '@admin/schema';

import { QaQrCodeLink as QAAutomationLink } from '@admin/components/qa_automation/qa_qr_code_link';

export const BarcodeValue: React.FC<{
  barcode: Pick<Barcode, 'value'>;
}> = ({ barcode }) => (
  <>
    #{barcode.value} <QAAutomationLink value={barcode.value} />
  </>
);

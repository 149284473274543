import styled from '@emotion/styled';
import React from 'react';
import { ErrorMessage, useFormContext } from 'react-hook-form';

import { Checkbox, Text } from '@shared/components/bootstrap';
import { SectionHeading } from '../components/section_heading';

const Wrapper = styled.div`
  font-size: 14px;
`;

const Error = styled.div`
  font-size: 12px;
`;

export const Disclaimers: React.FC<{}> = () => {
  const { register, errors } = useFormContext<{ scannedID: boolean }>();

  return (
    <Wrapper>
      <SectionHeading>Scan ID</SectionHeading>
      <Text tag="p">Please make a copy of the customer’s ID.</Text>
      <Checkbox has={errors.scannedID ? 'error' : undefined}>
        <input
          type="checkbox"
          name="scannedID"
          ref={register({ required: 'Please scan a copy of the customer’s ID.' })}
        />
        I’ve scanned a copy of the customer’s ID
        <ErrorMessage name="scannedID">{({ message }) => <Error>{message}</Error>}</ErrorMessage>
      </Checkbox>
    </Wrapper>
  );
};

import { ILabel } from '@admin/types/label';
import * as React from 'react';

import LabelGroup from '../label_group';

export const Tags: React.FC<{
  tags?: Array<string | ILabel>;
}> = ({ tags }) => {
  if (tags && tags.length > 0) {
    const labels = tags.map((tag) => (typeof tag === 'string' ? { name: tag } : tag));
    return <LabelGroup className="label-tag" labels={labels} />;
  } else {
    return null;
  }
};

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';

import { Text } from '@shared/components/bootstrap';

interface ILoaderProps {
  loading: boolean;
}

class Loader extends React.Component<ILoaderProps> {
  public render() {
    if (this.props.loading) {
      return (
        <Text alignment="center" style="muted" tag="div">
          <FontAwesomeIcon spin icon="spinner" className="vehicles-route-matcher__loader" />
        </Text>
      );
    } else {
      return null;
    }
  }
}

export { Loader };

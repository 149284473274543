import { Claim__Assessment__Input } from '@admin/schema';

export type AssessmentEditInput = Pick<Claim__Assessment__Input, 'verifiedValue' | 'weight' | 'classification'>;

export interface AssessmentEdits {
  [selectionID: string]: AssessmentEditInput;
}

export const buildModifyAssessmentsInput = (
  itemEdits: AssessmentEdits,
  propertyID: string | undefined,
  propertyEdit: AssessmentEditInput,
) => {
  let input = {};
  const editedItemIDs = Object.keys(itemEdits);
  if (editedItemIDs.length) {
    const itemAssessments: Claim__Assessment__Input[] = [];
    editedItemIDs.forEach((selectionID) => {
      itemAssessments.push({
        selectionID,
        ...itemEdits[selectionID],
      });
    });
    input = { itemAssessments };
  }
  if (propertyID && Object.keys(propertyEdit).length) {
    const propertyAssessment = {
      selectionID: propertyID,
      ...propertyEdit,
    };
    input = { ...input, propertyAssessment };
  }
  return input;
};

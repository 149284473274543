import React, { useEffect } from 'react';

import {
  AppointmentQuote,
  useFormattedAccountLaborAgreementQuery,
  useFormattedLaborAgreementQuery,
  usePricingSetAppointmentDataQuery,
  LaborRate,
  PricingSetFragment,
} from '@admin/schema';

import { IAccountPackage, OrderSubtypeKind } from '@admin/types';

import { client } from '@admin/libraries/apollo';
import { PackageSetEntries } from '../package_set_entry/package_set_entries';
import { LaborAgreementDescriptor } from '../labor_agreement_descriptor/labor_agreement_descriptor';

export const UpgradeDowngradeAppointmentPricing: React.FC<{
  accountId: string;
  pricingSet?: PricingSetFragment;
  rateGroupId?: string;
  cuft?: number;
  zip?: string;
  isCurbside?: boolean;
  setAppointmentData(
    accountPackages: IAccountPackage[],
    laborRate?: LaborRate,
    appointmentQuote?: AppointmentQuote,
  ): void;
}> = ({ accountId, pricingSet, rateGroupId, cuft, zip, isCurbside, setAppointmentData }) => {
  const appointmentPricingDataQuery = usePricingSetAppointmentDataQuery({
    variables: pricingSet && {
      pricingSetId: pricingSet.id,
      rateGroupId: rateGroupId!,
      cuft: cuft!,
    },
    client,
    fetchPolicy: 'no-cache',
    skip: !pricingSet || !rateGroupId || !cuft,
  });
  const packageSetEntries = appointmentPricingDataQuery.data?.pricingSetAppointmentData?.packageSetEntries || [];
  const onboardingPackageSetEntries = packageSetEntries.filter(
    (entry) => entry.orderSubtype === OrderSubtypeKind.Onboarding,
  );
  const laborRate = appointmentPricingDataQuery.data?.pricingSetAppointmentData?.laborRate;

  useEffect(() => {
    const accountPackages = packageSetEntries.map((entry) => ({
      package_set_entry_id: entry.id,
      amount: entry.amount,
    }));
    setAppointmentData(accountPackages, laborRate);
  }, [appointmentPricingDataQuery.data?.pricingSetAppointmentData]);

  const newLaborAgreementQuery = useFormattedLaborAgreementQuery({
    variables: pricingSet && {
      pricingSetId: pricingSet.id,
      rateGroupId: rateGroupId!,
      cuft: cuft!,
      orderZip: zip!,
      laborRateId: laborRate?.id,
      packageSetEntryIds: packageSetEntries?.map((entry) => entry.id),
    },
    client,
    skip: !pricingSet || !rateGroupId || !cuft || !laborRate || !zip,
  });

  const existingLaborAgreementQuery = useFormattedAccountLaborAgreementQuery({
    variables: { accountId: accountId },
    client,
  });

  return (
    <div className="form-horizontal">
      <PackageSetEntries entries={onboardingPackageSetEntries} />
      {cuft &&
        newLaborAgreementQuery.data?.formattedLaborAgreement &&
        existingLaborAgreementQuery.data?.formattedAccountLaborAgreement && (
          <div className="form-horizontal">
            <div className="form-group">
              <div id="existing-labor-agreement-label" className="col-sm-4">
                <strong>Old Agreement Summary:</strong>
              </div>
              <div id="new-labor-agreement-label" className="col-sm-4">
                <strong>New Agreement Summary:</strong>
              </div>
            </div>
            <div className="form-group">
              <div id="existing-labor-agreement-descriptor" className="col-sm-4">
                <LaborAgreementDescriptor
                  agreement={existingLaborAgreementQuery.data?.formattedAccountLaborAgreement}
                  cuft={cuft}
                  isCurbside={!!isCurbside} // isCurbside is always present when rateGroupId is present
                />
              </div>
              <div id="new-labor-agreement-descriptor" className="col-sm-4">
                <LaborAgreementDescriptor
                  agreement={newLaborAgreementQuery.data?.formattedLaborAgreement}
                  cuft={cuft}
                  isCurbside={!!isCurbside} // isCurbside is always present when rateGroupId is present
                />
              </div>
            </div>
          </div>
        )}
    </div>
  );
};

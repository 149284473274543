/** @jsx jsx */

import * as React from 'react';

import { css, jsx } from '@emotion/react';
import { DateTime } from 'luxon';

import { Tooltip } from '@shared/components/bootstrap/tooltip';
import { format } from './utils';
import { useScale } from './order_gantt';

const checkpointStyle = css`
  z-index: 5;
  position: absolute;
  bottom: 2rem;
  width: 1px;
  height: 100%;
  border-left: solid 4px;
`;

export const Checkpoint: React.FC<{
  timestamp: DateTime;
  label: string;
  className?: string;
}> = ({ timestamp, label, className }) => {
  const title = `${label}: ${format(timestamp)}`;
  const scale = useScale();

  return (
    <div css={checkpointStyle} className={className} style={{ left: `${scale(timestamp)}%` }}>
      <Tooltip key={title} title={title}>
        <div style={{ height: '100%', width: '15px', marginLeft: '-8px' }} />
      </Tooltip>
    </div>
  );
};

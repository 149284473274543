import { client } from '@admin/libraries/apollo';
import { useValidPhoneLazyQuery } from '@admin/schema';

// NOTE: useful for react-hook-form async validation callbacks.
export const usePhoneValidation = () => {
  const [validate] = useValidPhoneLazyQuery({ client });
  return async (phone: string) => {
    const { data } = await validate({ variables: { phone } });
    return data?.valid;
  };
};

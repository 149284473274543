(function () {
  angular.module('app').factory('Order', [
    '$resource',
    'TransformBuilder',
    'OrderSerializer',
    'OrderDeserializer',
    function ($resource, TransformBuilder, serializer, deserializer) {
      return $resource(
        '/accounts/:account_id/orders/:id.json',
        {
          id: '@id',
          account_id: '@account_id',
        },
        {
          get: {
            method: 'GET',
            transformResponse: TransformBuilder.transformResponse(deserializer),
          },
          dispatching: {
            method: 'GET',
            url: '/dispatching/orders.json',
            isArray: true,
          },
          update: {
            method: 'PATCH',
            url: '/accounts/:account_id/orders/:id.json',
            transformRequest: TransformBuilder.transformRequest(serializer),
            transformResponse: TransformBuilder.transformResponse(deserializer),
          },
          search: {
            url: 'orders.json',
            method: 'GET',
            isArray: false,
            paramSerializer: 'NestedParamsSerializer',
          },
        },
      );
    },
  ]);
})();

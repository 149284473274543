import * as React from 'react';

import { HistoryFragment } from '@admin/schema';

import { Audit } from './row/audit';
import { MarketingEvent } from './row/marketing_event';
import { Note } from './row/note';
import { PhoneCall } from './row/phone_call';
import { Subscribe } from './row/subscribe';
import { Usage } from './row/usage';

export const Row: React.FC<{
  history: HistoryFragment;
  timezone?: string;
}> = ({ history, timezone }) => {
  const timestamp = history.timestamp;
  const resource = history.resource;
  switch (resource.__typename) {
    case 'Changed__Audit':
      return <Audit resource={resource} timestamp={timestamp} timezone={timezone} />;
    case 'MarketingEvent':
      return <MarketingEvent resource={resource} timestamp={timestamp} timezone={timezone} />;
    case 'Note':
      return <Note resource={resource} timestamp={timestamp} timezone={timezone} />;
    case 'PhoneCall':
      return <PhoneCall resource={resource} timestamp={timestamp} timezone={timezone} />;
    case 'Subscribe':
      return <Subscribe resource={resource} timestamp={timestamp} timezone={timezone} />;
    case 'Usage':
      return <Usage resource={resource} timestamp={timestamp} timezone={timezone} />;
    default:
      return null;
  }
};

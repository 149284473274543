import { Button, ButtonKind, Modal } from '@shared/components/bootstrap';
import React, { FC } from 'react';

export const ConfirmationModal: FC<{
  confirmKind: ButtonKind;
  confirmText: string;
  message: string;
  title: string;
  onCancel(): void;
  onConfirm(): void | Promise<any>;
}> = ({ confirmText = 'Confirm', confirmKind = 'primary', message, title, onCancel, onConfirm }) => (
  <Modal onClose={onCancel}>
    <Modal.Content>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <Button kind="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          kind={confirmKind}
          onClick={() => {
            const maybePromise = onConfirm();
            if (maybePromise && maybePromise.then) {
              maybePromise.then(() => onCancel());
            } else {
              onCancel();
            }
          }}
        >
          {confirmText}
        </Button>
      </Modal.Footer>
    </Modal.Content>
  </Modal>
);

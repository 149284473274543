import { AddressForm } from '@admin/components/address/address_form';
import { Selector as RegionsSelector } from '@admin/components/regions/selector';
import { DepotInput } from '@admin/schema';
import * as React from 'react';

export const DepotForm: React.FC<{
  depot: DepotInput;
  onChange(depot: DepotInput): void;
}> = ({ onChange, depot }) => {
  const { name, abbreviation, active } = depot;
  return (
    <>
      <div className="form-group depot">
        <label className="control-label" htmlFor="depot__name">
          <strong>Name</strong>
        </label>
        <input
          id="depot__name"
          type="text"
          className="form-control"
          value={name}
          onChange={(event) => {
            onChange({ ...depot, name: event.target.value });
          }}
        />
      </div>
      <div className="form-group depot">
        <label className="control-label" htmlFor="depot__abbr">
          <strong>Abbreviation</strong>
        </label>
        <input
          id="depot__abbr"
          type="text"
          className="form-control"
          value={abbreviation}
          onChange={(event) => {
            onChange({ ...depot, abbreviation: event.target.value });
          }}
        />
      </div>
      <div className="form-group depot">
        <label className="control-label" htmlFor="depot__active">
          <strong>Active</strong>
        </label>
        <input
          id="depot__active"
          type="checkbox"
          className="form-control"
          checked={active}
          onChange={(event) => {
            onChange({ ...depot, active: event.target.checked });
          }}
        />
      </div>
      <AddressForm
        address={depot.address}
        onChange={(address) => {
          onChange({ ...depot, address });
        }}
      />
      <div className="form-group depot">
        <label className="control-label">
          <strong>Regions:</strong>
        </label>
        <RegionsSelector
          selectedIDs={depot.regionIDs}
          onChange={(regionIDs) => {
            onChange({ ...depot, regionIDs });
          }}
        />
      </div>
    </>
  );
};

import React, { useContext } from 'react';

import { client } from '@admin/libraries/apollo';
import { ClutterGeoEnum, VehicleFragment, useNightTransportPlannerVehiclesQuery } from '@admin/schema';

import { Context } from './context';
import { Mode } from './filters';
import { InlineSelectFormGroup } from './inline_select_form_group';

export const VehicleFormGroup: React.FC<{
  name: string;
  label: string;
  disabled?: boolean;
  currentVehicle?: VehicleFragment;
  showForm?: boolean;
  onChangeVehicle(_: VehicleFragment): void;
}> = ({ name: formGroupName, label, disabled, currentVehicle, showForm = false, onChangeVehicle }) => {
  const { mode, geo, loading } = useContext(Context);
  const { data } = useNightTransportPlannerVehiclesQuery({
    client,
    variables: { filters: { geo: ClutterGeoEnum[geo! as keyof typeof ClutterGeoEnum], active: true } },
    skip: !geo,
  });

  const onChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const id = event.target.value;
    const vehicle = data!.vehicles.find((elem) => elem.id === id)!;

    onChangeVehicle(vehicle);
  };

  if (mode === Mode.Viewing && !showForm) {
    return (
      <p>
        {label}: {currentVehicle?.name}
      </p>
    );
  }

  return (
    <InlineSelectFormGroup
      value={currentVehicle?.id ?? ''}
      label={label}
      onChange={onChange}
      name={formGroupName}
      disabled={!data || mode !== Mode.Editing || disabled || loading}
    >
      <option disabled={!!currentVehicle} value="">
        - Vehicle -
      </option>

      {data?.vehicles.map(({ id, name }) => (
        <option value={id} key={id}>
          {name}
        </option>
      ))}
    </InlineSelectFormGroup>
  );
};

(function () {
  const app = angular.module('app');

  app.controller('UsagesWidgetController', [
    '$rootScope',
    '$scope',
    'Usage',
    'AccountMetrics',
    'ItemCategorizationObserverService',
    function ($rootScope, $scope, Usage, AccountMetrics, ItemCategorizationObserverService) {
      $scope.init = function (accountID) {
        $scope.accountID = accountID;
        ItemCategorizationObserverService.registerCallback($scope.refresh);
        const metricsPromise = AccountMetrics.get({ id: $scope.accountID }).$promise;
        $scope.refresh(metricsPromise);
      };

      $scope.refresh = function (metricsPromise) {
        metricsPromise.then(function (metrics) {
          $scope.accountMetrics = {
            unsized_items_count: metrics.unsized_items_count,
            cuft_used: metrics.cuft_used,
          };

          if (metrics.unsized_items_count > 0) {
            Usage.get({ account_id: $scope.accountID }).$promise.then(function (usage) {
              if (usage.legacy) {
                $scope.accountMetrics.legacy = true;
                $scope.accountMetrics.cuft_used = usage.cubic_ft;
                $scope.accountMetrics.updated_at = usage.updated_at;
                $scope.accountMetrics.stale = usage.stale;
              }
            });
          } else {
            $scope.accountMetrics.all_categorized = true;
          }
        });
      };

      $scope.$on('$destroy', function () {
        ItemCategorizationObserverService.removeCallback($scope.refresh);
      });

      $rootScope.$broadcast('account_metrics:item_update', function (metricsPromise) {
        $scope.refresh(metricsPromise);
      });
    },
  ]);
})();

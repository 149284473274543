import React, { useCallback, useState } from 'react';
import styled from '@emotion/styled';
import { COLORS } from '@clutter/clean';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { client } from '@admin/libraries/apollo';
import { ApolloError } from '@apollo/client';
import {
  ClutterGeoEnum,
  Status,
  useGeoTimezoneQuery,
  useNightTransportPlanQuery,
  useNightTransportRouteUpsertMutation,
} from '@admin/schema';
import { Alert } from '@shared/components/bootstrap';
import { useURLSearchParamState } from '@shared/hooks';

import { Timeline } from '@admin/components/night_transport/planner/timeline';
import { createRoutesInput, validateTask } from './util';
import { AddRoute } from './add_route';
import { Context } from './context';
import { Filters, Mode } from './filters';
import { preprocess } from './preprocess';
import { Route } from './route';
import { NightTransportRoute, NightTransportTask, NightTransportRequiredDrive } from './types';
import { UnassignedTasks } from './unassigned_tasks';

const Container = styled.div`
  display: flex;
  margin-bottom: 8px;
`;

const Content = styled.div`
  display: flex;
  flex-grow: 1;
  overflow-x: scroll;
`;

const RouteList = styled.div`
  border-left: thin solid ${COLORS.grayBorder};
  background-color: ${COLORS.cloud};
  display: flex;
  flex-grow: 0;
  min-height: calc(100vh - 335px);
`;

const Sidebar = styled.div`
  width: 300px;
  flex-shrink: 0;
  background: ${COLORS.grayBackground};
`;

export const Planner: React.FC = () => {
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [mode, setMode] = useState(Mode.Viewing);
  const [date, setDate] = useURLSearchParamState('date');
  const [geo, setGeo] = useURLSearchParamState('geo');
  const [routes, setRoutes] = useState<NightTransportRoute[]>([]);
  const [tasks, setTasks] = useState<NightTransportTask[]>([]);
  const [requiredDriveTasks, setRequiredDriveTasks] = useState<Record<string, NightTransportRequiredDrive>>({});

  const { data: timezoneData } = useGeoTimezoneQuery({
    client,
    variables: { geo: ClutterGeoEnum[geo as keyof typeof ClutterGeoEnum] },
    skip: !geo,
  });
  const tz = timezoneData?.geoTimezone;

  const [upsert, { loading }] = useNightTransportRouteUpsertMutation({ client });

  const validateInput = () => {
    const invalidRoutes = routes.reduce<Record<string, string>>((acc, route) => {
      const { origin, arrival } = route;
      if (!arrival || !origin) {
        acc[route.uuid] = `Invalid ${!arrival ? 'arrival' : ''}${!arrival && !origin ? ' and ' : ''}${
          !origin ? 'origin' : ''
        }`;
      }
      return acc;
    }, {});

    const invalidTasks = tasks.reduce<Record<string, string>>((acc, task) => {
      if (!task.routeUUID) return acc;
      const error = validateTask(task);
      if (error) {
        acc[task.uuid] = error;
      }
      return acc;
    }, {});

    return { ...invalidRoutes, ...invalidTasks };
  };

  const onSave = async () => {
    setErrors({});
    const validationErrors = validateInput();

    if (Object.keys(validationErrors).length === 0) {
      const routesInput = createRoutesInput(routes, tasks, requiredDriveTasks);
      try {
        const result = await upsert({
          variables: {
            input: {
              geo: ClutterGeoEnum[geo! as keyof typeof ClutterGeoEnum],
              date: date!,
              routes: routesInput,
            },
          },
        });
        if (result.data?.result?.status === Status.Ok) {
          setErrors({});
          setMode(Mode.Viewing);
        } else {
          setErrors({
            base:
              result.data?.result?.error ||
              'Sorry, an unexpected error occurred. If the problem persists, contact Tech Support.',
          });
        }
      } catch (e) {
        setErrors({
          base:
            (e as ApolloError).message ||
            'Sorry, an unexpected error occurred. If the problem persists, contact Tech Support.',
        });
      } finally {
        window.scrollTo(0, 0);
      }
    } else {
      setErrors(validationErrors);
    }
  };

  const { refetch } = useNightTransportPlanQuery({
    client,
    variables: {
      filters: {
        geo: ClutterGeoEnum[geo! as keyof typeof ClutterGeoEnum],
        date: date!,
      },
      input: {
        geo: ClutterGeoEnum[geo! as keyof typeof ClutterGeoEnum],
        date: date!,
      },
    },
    skip: !date || !geo,
    onCompleted: (data) => {
      const processedData = preprocess(data);
      setRoutes(processedData.routes);
      setTasks(processedData.tasks);
      setRequiredDriveTasks(processedData.requiredDriveTasks);
    },
  });

  const onCancel = async () => {
    const result = await refetch({
      filters: {
        geo: ClutterGeoEnum[geo! as keyof typeof ClutterGeoEnum],
        date: date!,
      },
      input: {
        geo: ClutterGeoEnum[geo! as keyof typeof ClutterGeoEnum],
        date: date!,
      },
    });
    const processedData = preprocess(result.data);
    setRoutes(processedData.routes);
    setTasks(processedData.tasks);
    setRequiredDriveTasks(processedData.requiredDriveTasks);
  };

  const onAddRoute = (add: NightTransportRoute) => {
    setRoutes((current) => [...current, add]);
  };

  const onAddTask = (add: NightTransportTask) => {
    setTasks((current) => [...current, add]);
  };

  const onChangeRoute = useCallback(
    (change: NightTransportRoute) => {
      setRoutes((current) => current.map((route) => (route.uuid === change.uuid ? change : route)));
    },
    [setRoutes],
  );

  const onChangeTask = (change: NightTransportTask) => {
    setTasks((current) => current.map((task) => (task.uuid === change.uuid ? change : task)));
  };

  const onChangeTaskRoute = (changedTask: NightTransportTask, previousRouteUUID?: string) => {
    setTasks((current) => {
      const previousRouteTasks = current
        .filter(({ routeUUID, uuid: taskUUID }) => routeUUID === previousRouteUUID && changedTask.uuid !== taskUUID)
        .sort(({ position: positionA }, { position: positionB }) => positionA - positionB);

      const compressedTasks = previousRouteTasks.reduce((map, task, index) => {
        map.set(task.uuid, { ...task, position: index + 1 });
        return map;
      }, new Map<string, NightTransportTask>());

      return current.map((task) => {
        if (task.uuid === changedTask.uuid) return changedTask;

        const compressedTask = compressedTasks.get(task.uuid);
        return compressedTask || task;
      });
    });
  };

  const onDeleteRoute = (routeUUID: string) => {
    setRoutes((current) => current.filter((route) => route.uuid !== routeUUID));
  };

  const onDeleteTask = (taskUUID: string) => {
    setTasks((current) => current.filter((task) => task.uuid !== taskUUID));
  };

  // For dragging a task onto a different route
  const onMoveTask = (dragTask: NightTransportTask, dragPosition: number, routeUUID?: string) => {
    setTasks((current) => {
      const updatedTasks = current.map((task) => {
        if (task.uuid === dragTask.uuid) {
          return { ...task, position: dragPosition, routeUUID };
        } else if (task.routeUUID === routeUUID && task.position >= dragPosition) {
          return { ...task, position: task.position + 1 };
        }
        return task;
      });
      if (dragTask.position === 0) {
        return [...updatedTasks, { ...dragTask, position: dragPosition, routeUUID }];
      }
      return updatedTasks;
    });
  };

  // For dragging a task within the same route
  const onSwapTasks = (
    dragTask: NightTransportTask,
    dragPosition: number,
    hoverTask: NightTransportTask,
    hoverPosition: number,
  ) => {
    setTasks((current) =>
      current.map((task) => {
        if (task.uuid === dragTask.uuid) {
          return { ...task, position: hoverPosition };
        } else if (task.uuid === hoverTask.uuid) {
          return { ...task, position: dragPosition };
        }
        return task;
      }),
    );
  };

  return (
    <Context.Provider
      value={{
        mode,
        date,
        geo,
        tz,
        tasks,
        requiredDriveTasks,
        onMode: setMode,
        onDate: setDate,
        onGeo: setGeo,
        onAddRoute,
        onAddTask,
        onCancel,
        onChangeRoute,
        onChangeTask,
        onDeleteRoute,
        onDeleteTask,
        onMoveTask,
        onSwapTasks,
        onSave,
        onChangeTaskRoute,
        loading,
        errors,
      }}
    >
      <Filters />
      {errors.base && <Alert style="danger">{errors.base}</Alert>}
      <DndProvider backend={HTML5Backend} options={{}}>
        <Container>
          <Content>
            <RouteList>
              {routes.map((route) => (
                <Route
                  key={route.uuid}
                  route={route}
                  tasks={tasks.filter(({ routeUUID }) => routeUUID === route.uuid)}
                />
              ))}
              <AddRoute />
            </RouteList>
          </Content>
          <Sidebar>
            <UnassignedTasks tasks={tasks.filter(({ routeUUID }) => routeUUID === undefined)} />
          </Sidebar>
        </Container>
      </DndProvider>
      <Timeline routes={routes} tasks={tasks} requiredDriveTasks={requiredDriveTasks} />
    </Context.Provider>
  );
};

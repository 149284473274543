import * as React from 'react';
import { forwardRef } from 'react';

import {
  InputFormControlType,
  InputFormControlProps,
  InputFormControl,
} from '@shared/components/fields/input_form_control';

export const ZipFormControl = forwardRef<InputFormControlType, InputFormControlProps>(({ onChange, ...props }, ref) => (
  <InputFormControl
    ref={ref}
    {...props}
    onChange={(event) => {
      event.target.value = event.target.value.replace(/\D/g, '');
      if (onChange) {
        onChange(event);
      }
    }}
  />
));

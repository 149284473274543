(function () {
  const app = angular.module('app');

  app.component('dropdownUpdate', {
    bindings: {
      filterOptions: '<',
      parent: '<',
      initialSelectionId: '<?',
      getConfirmationModalOptions: '&',
      saveChange: '&',
    },
    templateUrl: 'partials/components/dropdown_update.html',
    controller: [
      'ConfirmationService',
      'ErrorService',
      '$q',
      function (ConfirmationService, ErrorService, $q) {
        const self = this;
        self.handleChange = handleChange;
        self.$onInit = onInit;

        function cacheSavedOption() {
          self.savedOption = self.selectedOption;
        }

        function disableDropdown() {
          self.disabled = true;
        }

        function enableDropdown() {
          self.disabled = false;
        }

        function revertToSavedOption() {
          self.selectedOption = self.savedOption;
        }

        function trySavingChange() {
          return self
            .saveChange({ parent: self.parent, selectionId: self.selectedOption.id })
            .then(cacheSavedOption, (error) => {
              revertToSavedOption();
              return ErrorService.handle(error);
            });
        }

        function handleChange() {
          disableDropdown();
          ConfirmationService.confirm(
            self.getConfirmationModalOptions({ parent: self.parent, selectionId: self.selectedOption.id }),
          )
            .then(trySavingChange, revertToSavedOption)
            .finally(enableDropdown);
        }

        // filterOptions could be either a $resource class object, a raw $q promise or a plain array
        function onInit() {
          $q.when(self.filterOptions.$promise || self.filterOptions).then(initSelect);
          enableDropdown();
        }

        function initSelect(resolvedFilterOptions) {
          self.resolvedFilterOptions = resolvedFilterOptions;

          if (typeof self.initialSelectionId !== 'undefined') {
            self.selectedOption = _.find(resolvedFilterOptions, function (option) {
              return option.id === self.initialSelectionId || option.id === Number(self.initialSelectionId);
            });

            cacheSavedOption();

            // If there is no option matching the initialSelectionId, clear the filter.
            if (!self.selectedOption) {
              self.handleChange();
            }
          }
        }
      },
    ],
  });
})();

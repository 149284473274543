import { DateTime } from 'luxon';
import React from 'react';

import { DateTimePicker } from '@admin/components/helpers/date_time_picker';
import { Maybe } from '@admin/schema';

export const ExpiryInput: React.FC<{
  expiry?: Maybe<string>;
  onChange(expiry?: string): void;
}> = ({ expiry, onChange }) => {
  const parsedExpiry = expiry ? DateTime.fromISO(expiry) : undefined;

  const onInputChange = (dt: DateTime) => {
    const newExpiry = dt ? dt.toISO() : undefined;
    onChange(newExpiry);
  };

  return (
    <div className="form-group">
      <label className="control-label">Expiration Date</label>
      <div className="row">
        <div className="col-md-4">
          <DateTimePicker dt={parsedExpiry} onChange={onInputChange} />
        </div>
      </div>
    </div>
  );
};

import React, { useState } from 'react';

import { Panel } from '@admin/components/helpers/panel';
import { Table } from './table';

const DEFAULT_COLLAPSED = false;

export const OpsAuditResultsPanel: React.FC<{ resourceID: string; resourceType: string }> = ({
  resourceID,
  resourceType,
}) => {
  const [collapsed, setCollapsed] = useState<boolean>(DEFAULT_COLLAPSED);

  return (
    <Panel>
      <Panel.Header>
        <Panel.Collapse collapsed={collapsed} onChange={setCollapsed} />
        <Panel.Title>Audit Results</Panel.Title>
      </Panel.Header>
      <Panel.Body>{!collapsed && <Table resourceID={resourceID} resourceType={resourceType} />}</Panel.Body>
    </Panel>
  );
};

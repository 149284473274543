import angular from 'angular';
import DispatchLiveDashboard from '../../react/components/DispatchLiveDashboard';

const app = angular.module('app');

app.directive('dispatchLiveDashboard', [
  'reactDirective',
  '$stateParams',
  '$ngRedux',
  (reactDirective, $stateParams, $ngRedux) =>
    reactDirective(DispatchLiveDashboard, undefined, undefined, {
      store: $ngRedux,
      $stateParams,
    }),
]);

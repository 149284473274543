import styled from '@emotion/styled';
import React from 'react';

import { NotesPanel } from '@admin/components/notes/panel';
import { Spinner } from '@admin/components/spinner';
import { Spacer } from '@shared/components/helpers';

import { NoteNotableEnum, useAuditQuery } from '@admin/schema';

import { client } from '@admin/libraries/apollo';

import { AuditablesPanel } from '../audit_review/auditables_panel';
import { DisassemblyReview } from '../audit_review/disassembly_audit/disassembly_review';
import { AnswersPanel } from './answers_panel';

const SpinnerWrapper = styled.div`
  display: flex;
`;

export const OpsAuditAudit: React.FC<{
  auditID: string;
}> = ({ auditID }) => {
  const { data, loading } = useAuditQuery({ client, variables: { auditID }, fetchPolicy: 'no-cache' });

  const audit = data && data.audit;

  return (
    <div className="ops-audit-audit-review">
      <Spacer height="8px" />
      {(loading || !audit) && (
        <>
          <SpinnerWrapper>
            <Spinner />
          </SpinnerWrapper>
          <Spacer height="8px" />
        </>
      )}
      {audit && audit.images && (
        <div className="row">
          <div className="col-md-8">
            {audit && audit?.configuration.name !== 'Disassembly Audit' && (
              <AuditablesPanel images={audit.images} resourceID={audit.resourceID} resourceType={audit.resourceType} />
            )}
            {audit?.configuration.name === 'Disassembly Audit' && <DisassemblyReview audit={audit} />}
          </div>
          <div className="col-md-4">
            <AnswersPanel audit={audit} />
            {audit && <NotesPanel type={NoteNotableEnum.OpsAuditAudit} id={audit.id} />}
          </div>
        </div>
      )}
    </div>
  );
};

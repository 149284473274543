import * as React from 'react';

export const SelectFormControl = <T extends string>({
  id,
  prompt,
  options,
  value,
  required,
  disabled,
  name,
  onChange,
}: {
  id?: string;
  prompt?: string;
  options: T[];
  value?: T;
  required?: boolean;
  disabled?: boolean;
  name?(value: T): string;
  onChange(value?: T): void;
}) => (
  <select
    id={id}
    className="form-control"
    value={value}
    onChange={(event) => onChange((event.target.value as T) || undefined)}
    required={required}
    disabled={disabled}
  >
    {prompt && <option value="">{prompt}</option>}
    {options.map((entry, index) => (
      <option value={entry} key={index}>
        {name ? name(entry) : entry}
      </option>
    ))}
  </select>
);

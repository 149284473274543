import * as React from 'react';

import { FlipProp, IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Tooltip, TooltipPlacement } from '@shared/components/bootstrap/tooltip';

import { COLORS } from '@root/colors';

import styled from '@emotion/styled';

export enum Status {
  Off,
  Good,
  Fair,
  Bad,
}

const STATUS_COLORS: { [key in Status]: { backgroundColor: string; color: string } } = {
  [Status.Good]: { backgroundColor: COLORS.tealLightest, color: COLORS.tealDark },
  [Status.Fair]: { backgroundColor: COLORS.orangeLightest, color: COLORS.orange },
  [Status.Bad]: { backgroundColor: COLORS.redLightest, color: COLORS.red },
  [Status.Off]: { backgroundColor: COLORS.grayLightest, color: COLORS.grayDark },
};

interface IChicletProps {
  status: Status;
}

const Chiclet = styled.button<IChicletProps>`
  width: 32px;
  height: 32px;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => STATUS_COLORS[props.status].backgroundColor};
  border: 1px solid ${(props) => STATUS_COLORS[props.status].color};
  color: ${(props) => STATUS_COLORS[props.status].color};
  margin-right: 4px;
`;

const ErrorIcon: React.FC<{ icon: IconProp; status: Status; flip?: FlipProp }> = ({ status, icon, flip }) => (
  <Chiclet status={status}>
    <FontAwesomeIcon icon={icon} flip={flip} />
  </Chiclet>
);

const TooltippedIcon: React.FC<{
  icon: IconProp;
  status: Status;
  errors?: string[];
  flip?: FlipProp;
  placement?: TooltipPlacement;
}> = ({ status, icon, flip, placement, errors }) => {
  const errorIcon = <ErrorIcon status={status} icon={icon} flip={flip} />;
  const shouldTooltip = status !== Status.Good && errors && errors.length;

  return shouldTooltip ? (
    <Tooltip placement={placement} title={errors!.join('<br/><br/>')}>
      {errorIcon}
    </Tooltip>
  ) : (
    errorIcon
  );
};

export { TooltippedIcon as ErrorIcon };

import styled from '@emotion/styled';
import React from 'react';

import { opsAuditAuditURL } from '@admin/config/routes';
import { AuditResultsQuery } from '@admin/schema';
import { COLORS } from '@root/colors';

import { AuditExceptionColumn } from '../audit_exception/audit_exception_column';

type AuditType = AuditResultsQuery['audits'][number];

export const AnswerListItem = styled.li<{ failed: boolean }>(({ failed }) => ({
  color: failed ? COLORS.red : 'initial',
}));

const Failed = styled.span`
  color: ${COLORS.red};
`;

const Passed = styled.span`
  color: ${COLORS.green};
`;

export const Row: React.FC<{
  audit: AuditType;
}> = ({ audit }) => (
  <tr>
    <td className="col-lg-1">{audit.failed ? <Failed>Failed</Failed> : <Passed>Passed</Passed>}</td>
    <td className="col-lg-2"> {audit.configuration.name} </td>
    <td className="col-lg-1"> {audit.auditor?.name} </td>
    <td className="col-lg-1"> {audit.resourceType + ' ' + audit.resourceID} </td>
    <td className="col-lg-1"> {audit.questionGroup} </td>
    <td>
      <ol className="text-left">
        {audit.answers.map((answer) => (
          <AnswerListItem key={answer.question.id} failed={answer.failed}>
            {answer.question.prompt + ': ' + answer.response}
          </AnswerListItem>
        ))}
      </ol>
    </td>
    <td className="col-lg-2">
      <AuditExceptionColumn audit={audit} />
    </td>
    <td className="col-lg-2">
      <a className="btn btn-primary" href={opsAuditAuditURL(audit.parentID || audit.id)}>
        View Audit
      </a>
    </td>
  </tr>
);

import React, { useEffect, useRef } from 'react';

import { FieldFormGroupProps } from '@shared/components/fields/field_form_group';
import { InputFormControlType, InputFormControlProps } from '@shared/components/fields/input_form_control';
import { ControlLabel, FormGroup, HelpBlock } from '@shared/components/bootstrap';

export type Option = {
  __typename: string;
  id: string;
};

type FormGroupProps<T extends string | number | Option> = InputFormControlProps &
  FieldFormGroupProps & {
    options: T[];
    selectedOptions: T[];
    setSelectedOptions(newOptions: T[]): void;
  };

export const IndeterminateCheckboxFormGroup = <T extends string | number | Option>({
  id,
  has,
  help,
  label,
  options,
  selectedOptions,
  setSelectedOptions,
  ...input
}: FormGroupProps<T>) => {
  const ref = useRef<InputFormControlType>(null);
  const checked =
    options.length > 0 &&
    options.every((option) =>
      option instanceof Object
        ? selectedOptions.find(
            (selectedOption) =>
              (selectedOption as Option).id === option.id &&
              (selectedOption as Option).__typename === option.__typename,
          )
        : selectedOptions.includes(option),
    );
  const indeterminate = selectedOptions.length > 0 && options.length !== selectedOptions.length;

  useEffect(() => {
    if (!ref?.current) return;
    ref.current.checked = checked;
    ref.current.indeterminate = indeterminate;
  }, [checked, indeterminate]);

  const toggleSelections = () => {
    if (checked) {
      setSelectedOptions([]);
    } else {
      setSelectedOptions(options);
    }
  };

  return (
    <FormGroup has={has}>
      {label ? (
        <div className="checkbox-inline">
          <ControlLabel htmlFor={id}>
            <input
              ref={ref}
              id={id}
              {...input}
              type="checkbox"
              onChange={(_) => toggleSelections()}
              checked={checked}
            />
            {label}
          </ControlLabel>
        </div>
      ) : (
        <input ref={ref} {...input} type="checkbox" onChange={toggleSelections} checked={checked} />
      )}
      {help && <HelpBlock>{help}</HelpBlock>}
    </FormGroup>
  );
};

import { IInvoiceActionProps, IInvoiceActionFormProps } from '@admin/components/billing/invoices/types';
import { Currency } from '@shared/components/helpers';
import React, { useState } from 'react';

import { Button, Modal, Tooltip } from '@shared/components/bootstrap';
import { Text } from '@clutter/clean';

import { InvoiceRebillForm } from './re_bill/form';

const ReBillModal: React.FC<IInvoiceActionFormProps> = (props) => (
  <Modal centered onClose={props.onCancel}>
    <Modal.Content>
      <Modal.Header>
        <Modal.Title>
          <Text.Headline>Re-Bill Invoice #{props.invoiceID}</Text.Headline>
        </Modal.Title>
        <Text.Body>
          Total: <Currency value={props.amountOwed} />
        </Text.Body>
        <Text.Body>Please DO NOT use this feature to bill for upcoming rent payments.</Text.Body>
        <Modal.Close close={props.onCancel} />
      </Modal.Header>
      <Modal.Body>
        <InvoiceRebillForm {...props} />
      </Modal.Body>
    </Modal.Content>
  </Modal>
);

const ReBillButton: React.FC<{ disabled?: boolean; onClick?(): void }> = ({ disabled, onClick }) => (
  // We set the button className to 'disabled' rather than the html
  // element itself since disabled elements will break the Tooltip display
  <Button kind="default" className={disabled ? 'disabled' : ''} onClick={onClick}>
    Re-Bill
  </Button>
);

export const ReBill: React.FC<IInvoiceActionProps & { disabled: boolean }> = ({
  invoiceID,
  accountID,
  amountOwed,
  onSave,
  disabled,
}) => {
  const [showModal, setShowModal] = useState<boolean>(false);

  return (
    <>
      {disabled ? (
        <Tooltip title="Invoices with both taxes and discounts cannot be re-billed">
          <ReBillButton disabled />
        </Tooltip>
      ) : (
        <ReBillButton onClick={() => setShowModal(true)} />
      )}
      {showModal && (
        <ReBillModal
          onCancel={() => setShowModal(false)}
          onSave={(attemptID) => {
            setShowModal(false);
            onSave(attemptID, 'Invoice successfully re-billed', true);
          }}
          invoiceID={String(invoiceID)}
          accountID={String(accountID)}
          amountOwed={Number(amountOwed)}
        />
      )}
    </>
  );
};

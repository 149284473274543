import { CategoriesSelector } from '@admin/components/categories/categories_selector';
import { PalletTypeInput } from '@admin/schema';
import { PalletLegacySize } from '@admin/types';
import * as React from 'react';
import * as ITEM_CATEGORY_QUERY from '../categories/item_categories.gql';
import * as MATERIAL_CATEGORY_QUERY from '../categories/material_categories.gql';
import * as PART_CATEGORY_QUERY from '../categories/part_categories.gql';

export const PalletTypeForm: React.FC<{
  edit: boolean;
  palletType: PalletTypeInput;
  onChange(palletType: PalletTypeInput): void;
}> = ({ palletType, onChange, edit }) => {
  const { name, capacity, position, active, legacySize, description } = palletType;
  return (
    <>
      <div className="form-group pallet-type">
        <label className="control-label" htmlFor="pallet-type__name">
          <strong>Name</strong>
        </label>
        <input
          id="pallet-type__name"
          readOnly={edit}
          type="text"
          className="form-control"
          value={name}
          onChange={(event) => {
            onChange({ ...palletType, name: event.target.value });
          }}
        />
      </div>
      <div className="form-group pallet-type">
        <label className="control-label" htmlFor="pallet-type__capacity">
          <strong>Capacity</strong>
        </label>
        <input
          id="pallet-type__capacity"
          onKeyPress={(event) => event.charCode >= 48 && event.charCode <= 57}
          type="number"
          className="form-control"
          value={capacity ?? undefined}
          onChange={(event) => {
            onChange({ ...palletType, capacity: parseInt(event.target.value, 10) });
          }}
        />
      </div>
      <div className="form-group pallet-type">
        <label className="control-label" htmlFor="pallet-type__positino">
          <strong>Position</strong>
        </label>
        <input
          id="pallet-type__position"
          onKeyPress={(event) => event.charCode >= 48 && event.charCode <= 57}
          type="number"
          className="form-control"
          value={position}
          onChange={(event) => {
            onChange({ ...palletType, position: parseInt(event.target.value, 10) });
          }}
        />
      </div>
      <div className="form-group pallet-type">
        <label className="control-label" htmlFor="pallet-type__active">
          <strong>iOS Creatable</strong>
        </label>
        <input
          id="pallet-type__active"
          type="checkbox"
          className="form-control"
          checked={active}
          onChange={(event) => {
            onChange({ ...palletType, active: event.target.checked });
          }}
        />
      </div>
      <div className="form-group pallet-type">
        <label className="control-label" htmlFor="pallet-type__legacy-size">
          <strong>Legacy Size</strong>
        </label>
        <select
          id="pallet-type__legacy-size"
          className="form-control"
          value={legacySize}
          onChange={(event: any) => {
            onChange({ ...palletType, legacySize: event.target.value });
          }}
        >
          <option value="" disabled>
            Choose a legacy size
          </option>
          <option value={PalletLegacySize.SINGLE}>Single</option>
          <option value={PalletLegacySize.DOUBLE}>Double</option>
          <option value={PalletLegacySize.OVERSIZED}>Oversized</option>
          <option value={PalletLegacySize.CRATED}>Crated</option>
        </select>
      </div>
      <div className="form-group pallet-type">
        <label className="control-label" htmlFor="pallet-type__description">
          <strong>Description</strong>
        </label>
        <input
          id="pallet-type__description"
          type="text"
          className="form-control"
          value={description ?? undefined}
          onChange={(event) => {
            onChange({ ...palletType, description: event.target.value });
          }}
        />
      </div>
      <div className="form-group pallet-type">
        <label className="control-label">
          <strong>Item Categories:</strong>
        </label>
        <CategoriesSelector
          selectedIDs={palletType.itemCategoryIDs ?? []}
          onChange={(itemCategoryIDs) => {
            onChange({ ...palletType, itemCategoryIDs });
          }}
          query={ITEM_CATEGORY_QUERY}
        />
      </div>
      <div className="form-group pallet-type">
        <label className="control-label">
          <strong>Part Categories:</strong>
        </label>
        <CategoriesSelector
          selectedIDs={palletType.partCategoryIDs ?? []}
          onChange={(partCategoryIDs) => {
            onChange({ ...palletType, partCategoryIDs });
          }}
          query={PART_CATEGORY_QUERY}
        />
      </div>
      <div className="form-group pallet-type">
        <label className="control-label">
          <strong>Material Categories:</strong>
        </label>
        <CategoriesSelector
          selectedIDs={palletType.materialCategoryIDs ?? []}
          onChange={(materialCategoryIDs) => {
            onChange({ ...palletType, materialCategoryIDs });
          }}
          query={MATERIAL_CATEGORY_QUERY}
        />
      </div>
    </>
  );
};

import angular from 'angular';

const app = angular.module('app');

app.component('timeSlotDropdown', {
  templateUrl: 'partials/components/time_slot_dropdown.html',
  bindings: {
    interval: '<', // moment duration
    onChange: '&',
    selectedHour: '<',
    selectedMinute: '<',
    windowStart: '<', // moment object with the correct hour and minute
    windowEnd: '<',
  },
  controller: function () {
    // This component don't care about the year, month, or date, so we'll always
    // use the same values for these fields.
    const YEAR = 1970;
    const MONTH = 0;
    const DATE = 1;

    const getSlots = function getSlotsToShow(windowStart, windowEnd, interval) {
      const slots = [];

      if (windowStart && windowEnd && interval) {
        const start = windowStart.clone().year(YEAR).month(MONTH).date(DATE);
        const end = windowEnd.clone().year(YEAR).month(MONTH).date(DATE);
        let slot = start;
        while (slot < end) {
          slots.push(slot.clone());
          slot = slot.add(interval);
        }
      }

      return slots;
    };

    const getSelectedSlot = function getSelectedSlot(selectedHour, selectedMinute, slots) {
      if (_.isNumber(selectedHour) && _.isNumber(selectedMinute) && slots) {
        return _.find(slots, (slot) => slot.hour() === selectedHour && slot.minute() === selectedMinute);
      }
    };

    this.displayFormat = 'h:mm A';

    this.$onChanges = () => {
      this.slots = getSlots(this.windowStart, this.windowEnd, this.interval);
      if (this.slots.length) {
        this.selectedSlot = getSelectedSlot(this.selectedHour, this.selectedMinute, this.slots);
        this.onChange({ time: this.selectedSlot });
      }
    };
  },
});

import styled from '@emotion/styled';
import React from 'react';
import { startCase } from 'lodash';

import { Panel } from '@admin/components/helpers/panel';
import { AuditQuery } from '@admin/schema';
import { COLORS } from '@root/colors';
import { Spacer } from '@shared/components/helpers';

type ParentAuditType = AuditQuery['audit'];
type ChildAuditType = AuditQuery['audit']['children'][number];
type AuditType = ParentAuditType | ChildAuditType;
type AuditAnswerType = AuditQuery['audit']['answers'][number];
type AuditQuestionType = AuditQuery['audit']['questions'][number];

const Radio = styled.input`
  margin: 4px 0 0;
`;

const Label = styled.label`
  margin: 4px;
`;

const Scrollable = styled.div`
  max-height: 80vh;
  overflow: hidden;
  overflow-y: scroll;
`;

const AnswerListItem = styled.ol<{ failed: boolean }>(({ failed }) => ({
  color: failed ? COLORS.red : 'initial',
}));

const questionCompare = (a: AuditQuestionType, b: AuditQuestionType) => {
  const valueA = a.ordering ? a.ordering : parseInt(a.id, 10);
  const valueB = b.ordering ? b.ordering : parseInt(b.id, 10);
  return valueA - valueB;
};

const childAuditCompare = (a: AuditType, b: AuditType) => {
  if (a.resourceType === b.resourceType) {
    return parseInt(a.resourceID) - parseInt(b.resourceID);
  } else {
    return a.resourceType < b.resourceType ? -1 : 1;
  }
};

const AnswersList: React.FC<{
  audit: AuditType;
}> = ({ audit }) => {
  const answers = new Map<string, AuditAnswerType>();
  audit.answers.forEach((ans) => {
    answers.set(ans.question.id, ans);
  });
  return (
    <ol>
      {audit.questions
        .sort((a: AuditQuestionType, b: AuditQuestionType) => questionCompare(a, b))
        .map((question) => (
          <AnswerListItem key={`${audit.id}_${question.id}`} failed={answers.get(question.id)?.failed || false}>
            {question.prompt}
            <div className="row">
              {question.answerChoices.map((choice) => (
                <div key={choice} className="col-md-4 col-lg-3">
                  <Radio
                    type="radio"
                    value={choice}
                    name={audit.id + '_' + question.id}
                    id={choice + '_' + audit.id}
                    checked={answers.get(question.id)?.response === choice}
                    required
                    disabled
                  />
                  <Label htmlFor={choice + '_' + audit.id}>{choice}</Label>
                </div>
              ))}
            </div>
          </AnswerListItem>
        ))}
    </ol>
  );
};

export const AnswersPanel: React.FC<{
  audit: ParentAuditType;
}> = ({ audit }) => {
  const sanitizedQuestionGroup = audit?.questionGroup ? "('" + audit.questionGroup + "') question group" : '';
  return (
    <form>
      <Panel>
        <Panel.Header>
          <Panel.Title>Questions {sanitizedQuestionGroup}</Panel.Title>
        </Panel.Header>
        <Panel.Body>
          <Scrollable>
            <AnswersList audit={audit} />
            {audit.children &&
              audit.children
                .filter((auditChild) => auditChild != null)
                .sort((a, b) => childAuditCompare(a, b))
                .map((auditChild) => (
                  <div key={auditChild.id}>
                    <Spacer height="8px" />
                    <hr />
                    <h5>
                      Questions for {startCase(auditChild.resourceType)} - {auditChild.resourceID}{' '}
                    </h5>
                    <AnswersList audit={auditChild} />
                  </div>
                ))}
          </Scrollable>
        </Panel.Body>
      </Panel>
    </form>
  );
};

import React, { useContext } from 'react';

import { client } from '@admin/libraries/apollo';
import { ClutterGeoEnum, FacilityFragment, useNightTransportPlannerFacilitiesQuery } from '@admin/schema';

import { Context } from './context';
import { Mode } from './filters';
import { FacilityType } from './types';
import { InlineSelectFormGroup } from './inline_select_form_group';

export const FacilityFormGroup: React.FC<{
  name: string;
  label: string;
  disabled?: boolean;
  currentFacility?: FacilityFragment;
  facilityTypes?: FacilityType[];
  showForm?: boolean;
  onChangeFacility(_: FacilityFragment): void;
}> = ({
  name: formGroupName,
  label,
  disabled,
  currentFacility,
  facilityTypes = [FacilityType.Warehouse, FacilityType.Depot],
  showForm = false,
  onChangeFacility,
}) => {
  const { mode, geo, loading } = useContext(Context);
  const { data } = useNightTransportPlannerFacilitiesQuery({
    client,
    variables: { filters: { geo: ClutterGeoEnum[geo! as keyof typeof ClutterGeoEnum], active: true } },
    skip: !geo,
  });

  const onChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const [type, resourceID] = event.target.value.split('-');
    const facility: FacilityFragment = (() => {
      switch (type) {
        case FacilityType.Warehouse:
          return data?.facilities.find(
            ({ id, __typename }) => id === resourceID && __typename === FacilityType.Warehouse,
          );
        case FacilityType.Depot:
          return data?.facilities.find(({ id, __typename }) => id === resourceID && __typename === FacilityType.Depot);
      }
    })()!;

    onChangeFacility(facility);
  };

  const value = (() => {
    switch (currentFacility?.__typename) {
      case FacilityType.Warehouse:
        return `${FacilityType.Warehouse}-${currentFacility.id}`;
      case FacilityType.Depot:
        return `${FacilityType.Depot}-${currentFacility.id}`;
    }
  })();

  if (mode === Mode.Viewing && !showForm) {
    return (
      <p>
        {label}: {currentFacility?.name}
      </p>
    );
  }

  return (
    <InlineSelectFormGroup
      value={value ?? ''}
      label={label}
      onChange={onChange}
      name={formGroupName}
      disabled={!data || mode !== Mode.Editing || disabled || loading}
    >
      <option disabled={!!currentFacility} value="">
        - Facility -
      </option>

      <optgroup
        label={FacilityType.Warehouse}
        disabled={facilityTypes && !facilityTypes.includes(FacilityType.Warehouse)}
      >
        {data?.facilities
          .filter(({ __typename }) => __typename === FacilityType.Warehouse)
          .map(({ id, name }) => (
            <option value={`${FacilityType.Warehouse}-${id}`} key={id}>
              {name}
            </option>
          ))}
      </optgroup>

      <optgroup label={FacilityType.Depot} disabled={facilityTypes && !facilityTypes.includes(FacilityType.Depot)}>
        {data?.facilities
          .filter(({ __typename }) => __typename === FacilityType.Depot)
          .map(({ id, name }) => (
            <option value={`${FacilityType.Depot}-${id}`} key={id}>
              {name}
            </option>
          ))}
      </optgroup>
    </InlineSelectFormGroup>
  );
};

/* eslint-disable no-use-before-define */

/**
 * Used for ng-repeat's containing many elements, many of which may not be displayed until
 * scrolling takes place. The directive removes the watchers on the these elements and
 * their children when the element in the repeat is not visible
 */

/**
 * Determines whether an element is at least partially visible in the viewport
 * https://stackoverflow.com/questions/123999/how-to-tell-if-a-dom-element-is-visible-in-the-current-viewport
 * @returns {boolean}
 */
const elementInViewport = (element) => {
  const el = element[0];

  const rect = el.getBoundingClientRect();

  // DOMRect { x: 8, y: 8, width: 100, height: 100, top: 8, right: 108, bottom: 108, left: 8 }
  const windowHeight = window.innerHeight || document.documentElement.clientHeight;
  const windowWidth = window.innerWidth || document.documentElement.clientWidth;

  // http://stackoverflow.com/questions/325933/determine-whether-two-date-ranges-overlap
  const vertInView = rect.top <= windowHeight && rect.top + rect.height >= 0;
  const horInView = rect.left <= windowWidth && rect.left + rect.width >= 0;

  return vertInView && horInView;
};

angular.module('app').directive('viewportWatch', [
  '$parse',
  '$timeout',
  ($parse, $timeout) => ({
    restrict: 'A',
    scope: {
      refreshSuspensionOn: '=refreshHideOn',
    },

    link($scope, element) {
      let wasVisible;

      const watchers = {
        suspended: false,
      };

      toggleWatchers();
      element[0].parentElement.addEventListener('scroll', _.debounce(toggleWatchers, 500));
      window.addEventListener('resize', _.debounce(toggleWatchers, 500));

      $scope.$watch(
        'refreshSuspensionOn',
        (newVal, oldVal) => {
          if (newVal !== oldVal) refreshSuspensionFromRoot();
        },
        true,
      );

      function disableWatchers() {
        suspendFromRoot();
      }

      function enableWatchers() {
        resumeFromRoot();
      }

      function toggleWatchers() {
        const inVp = elementInViewport(element);
        if (inVp && !wasVisible) {
          enableWatchers();
        } else if ((!inVp && wasVisible) || wasVisible === undefined) {
          disableWatchers();
        }
        wasVisible = inVp;
      }

      function suspendFromRoot() {
        if (!watchers.suspended) {
          $timeout(() => {
            suspendWatchers();
            watchers.suspended = true;
          });
        }
      }

      function refreshSuspensionFromRoot() {
        if (watchers.suspended) {
          $timeout(() => {
            suspendWatchers();
          });
        }
      }

      function resumeFromRoot() {
        if (watchers.suspended) {
          $timeout(() => {
            resumeWatchers();
            watchers.suspended = false;
          });
        }
      }

      function suspendWatchers() {
        iterateSiblings($scope, suspendScopeWatchers);
        iterateChildren($scope, suspendScopeWatchers);
      }

      function resumeWatchers() {
        iterateSiblings($scope, resumeScopeWatchers);
        iterateChildren($scope, resumeScopeWatchers);
      }

      function mockScopeWatch(scopeId) {
        return (watchExp, listener, objectEquality, prettyPrintExpression) => {
          watchers[scopeId].unshift({
            fn: angular.isFunction(listener) ? listener : angular.noop,
            last: void 0, // eslint-disable-line no-void
            get: $parse(watchExp),
            exp: prettyPrintExpression || watchExp,
            eq: !!objectEquality,
          });
        };
      }

      function suspendScopeWatchers(scope) {
        if (!watchers[scope.$id]) {
          watchers[scope.$id] = scope.$$watchers || [];
          _.set(scope, '$$watchers', []);
          _.set(scope, '$watch', mockScopeWatch(scope.$id));
        }
      }

      function resumeScopeWatchers(scope) {
        if (watchers[scope.$id]) {
          _.set(scope, '$$watchers', watchers[scope.$id]);
          if (_.has(scope, '$watch')) _.unset(scope, '$watch');
          watchers[scope.$id] = false;
        }
      }

      /* eslint-disable no-param-reassign */
      function iterateSiblings(scope, operationOnScope) {
        scope = scope.$$nextSibling;
        while (scope) {
          operationOnScope(scope);
          iterateChildren(scope, operationOnScope);
          scope = scope.$$nextSibling;
        }
      }
      /* eslint-enable no-param-reassign */

      /* eslint-disable no-param-reassign */
      function iterateChildren(scope, operationOnScope) {
        scope = scope.$$childHead;
        while (scope) {
          operationOnScope(scope);
          iterateSiblings(scope, operationOnScope);
          scope = scope.$$childHead;
        }
      }
      /* eslint-enable no-param-reassign */
    },
  }),
]);

import React from 'react';

import { client } from '@admin/libraries/apollo';
import { useAccountAddressNewQuery } from '@admin/schema';

import { Spinner } from '@admin/components/spinner';
import { Breadcrumb } from '@shared/components/bootstrap/breadcrumb';

import { AccountAddressForm } from './form';

export const New: React.FC<{
  accountID: string;
  onSave(address: { id: string }): void;
}> = ({ accountID, onSave }) => {
  const { data } = useAccountAddressNewQuery({ client, variables: { accountID } });
  if (!data) return <Spinner />;

  const account = data.account;

  return (
    <>
      <h1 className="page-header text-overflow">
        <Breadcrumb>
          <Breadcrumb.Item>
            <a href="/accounts">Accounts</a>
          </Breadcrumb.Item>{' '}
          <Breadcrumb.Item>
            <a href={`/accounts/${accountID}`}>
              #{account.number} ({account.customer.name})
            </a>
          </Breadcrumb.Item>{' '}
          <Breadcrumb.Item active>Addresses - New</Breadcrumb.Item>
        </Breadcrumb>
      </h1>

      <AccountAddressForm accountID={accountID} onSave={onSave} />
    </>
  );
};

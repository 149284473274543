(function () {
  const app = angular.module('app');

  app.service('CategorySizingOptionsService', [
    '$filter',
    function ($filter) {
      function generateCategoryOptions(categories) {
        const options = [];

        categories.forEach(function (category) {
          const sizingMetric = category.sizing_metric;

          if (sizingMetric) {
            if (sizingMetric.range) {
              const range = sizingMetric.range.split(',');
              for (let value = range[0]; value <= range[1]; value++) {
                const displayName = $filter('category')(category, value);
                options.push(generateCategorySelectOption(category, displayName, value));
              }
            } else {
              sizingMetric.enumerations.forEach(function (value) {
                const displayName = $filter('category')(category, value);
                options.push(generateCategorySelectOption(category, displayName, value));
              });
            }
          } else {
            options.push(generateCategorySelectOption(category));
          }
        });
        return options;
      }

      function generateCategorySelectOption(category, displayName, value) {
        const sizingMetric = category.sizing_metric;
        if (sizingMetric) {
          return {
            metadata: {
              synonyms: category.synonyms,
            },
            displayName: displayName,
            value: [category.id, sizingMetric.id, value].join('-'),
          };
        } else {
          return {
            metadata: {
              synonyms: category.synonyms,
            },
            displayName: category.name,
            value: category.id,
          };
        }
      }

      this.selectOptions = function (categories) {
        return generateCategoryOptions(categories);
      };
    },
  ]);
})();

/** @jsx jsx */

import * as React from 'react';

import { css, jsx } from '@emotion/react';
import { DateTime } from 'luxon';

import { COLORS } from '@root/colors';

import { IDispatchOrder } from '@admin/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useScale } from './order_gantt';

export const ExpectedStartTimeCaret = () => <FontAwesomeIcon color={COLORS.red} icon="caret-down" size="2x" />;

export const ExpectedStartTime: React.FC<{ order: IDispatchOrder }> = ({ order: { expectedStartTime } }) => {
  const scale = useScale();
  if (!expectedStartTime) {
    return null;
  }

  const time = DateTime.fromISO(expectedStartTime);
  const left = scale(time);

  const position = css`
    position: absolute;
    left: ${left}%;
    bottom: 30%;
    margin-left: -7.5px;
  `;

  return (
    <div css={position}>
      <ExpectedStartTimeCaret />
    </div>
  );
};

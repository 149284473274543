import React, { forwardRef } from 'react';

type SelectProps = React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>;

const LOST_REASON_OPTIONS = [
  {
    label: 'Reasonable price is no longer available',
    value: 'unit_price_unavailable',
  },
  {
    label: 'Unit size is no longer available',
    value: 'unit_size_unavailable',
  },
  {
    label: 'Customer canceled request',
    value: 'customer_canceled',
  },
];

export const ReasonSelector = forwardRef<HTMLSelectElement, SelectProps>((props, ref) => (
  <select className="form-control" {...props} ref={ref}>
    <option value="">-</option>
    {LOST_REASON_OPTIONS.map((option) => (
      <option key={option.value} value={option.value}>
        {option.label}
      </option>
    ))}
  </select>
));

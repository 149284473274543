import * as React from 'react';

export const Duration: React.FC<{
  value: number;
}> = ({ value }) => {
  const hours = Math.floor(value / 3600);
  const minutes = Math.floor(value / 60) % 60;
  return (
    <>
      {hours}:{minutes < 10 && '0'}
      {minutes}
    </>
  );
};

export const Durations: React.FC<{
  values: number[];
}> = ({ values }) => {
  const durations = values.map<React.ReactNode>((value) => <Duration key={value} value={value} />);
  if (values.length) {
    return <>{durations.reduce((memo, duration) => [memo, ', ', duration])}</>;
  }
  return null;
};

import React from 'react';

import { Thumbnails } from '@admin/components/helpers/thumbnails';
import { Claim__PropertySelectionFragment } from '@admin/schema';
import { ListGroup } from '@shared/components/bootstrap';

import { Resolution } from './resolution';
import { ClaimResolutionEdits } from '../types';

const DECLINE_REASONS = ['Not covered', 'Previously compensated', 'Clutter not at fault'];

export const PropertySelection: React.FC<{
  propertySelection: Claim__PropertySelectionFragment;
  resolutionEdits: ClaimResolutionEdits;
  setResolutionEdits(edits: ClaimResolutionEdits): void;
}> = ({ propertySelection, resolutionEdits, setResolutionEdits }) => {
  const { name, owner, photos, quotes } = propertySelection;

  return (
    <>
      <ListGroup.Item>
        <div className="row">
          <div className="col-sm-2">
            <strong>Property</strong>
          </div>
          <div className="col-sm-1">
            <strong>Type</strong>
          </div>
          <div className="col-sm-1">
            <strong>Images</strong>
          </div>
          <div className="col-sm-3">
            <strong>Owner</strong>
          </div>
          <div className="col-sm-2">
            <strong>Valid?</strong>
          </div>
          <div className="col-sm-3" />
        </div>
      </ListGroup.Item>
      <ListGroup.Item>
        <div className="row">
          <div className="col-sm-2">{name}</div>
          <div className="col-sm-1">Property Damage</div>
          <div className="col-sm-1">
            <Thumbnails attachments={photos.concat(quotes)} condensed />
          </div>
          <div className="col-sm-3">
            {!owner ? (
              'Customer'
            ) : (
              <>
                <div>{owner.name}</div>
                <div>{owner.email}</div>
                <div>{owner.phone}</div>
              </>
            )}
          </div>
          <div className="col-sm-5">
            <Resolution
              disabled={false}
              declineReasons={DECLINE_REASONS}
              selection={propertySelection}
              resolutionEdits={resolutionEdits}
              onResolve={setResolutionEdits}
            />
          </div>
        </div>
      </ListGroup.Item>
    </>
  );
};

(function () {
  angular.module('app').service('ConversionService', [
    function () {
      const CUBIC_INCHES_IN_CUBIC_FOOT = 1728;

      this.cubicInchesToFeet = function (cubicInches) {
        if (!cubicInches) {
          return 0;
        }
        return _.round(cubicInches / CUBIC_INCHES_IN_CUBIC_FOOT, 2);
      };

      this.feetToCubicInches = function (cuft) {
        return _.round(cuft * CUBIC_INCHES_IN_CUBIC_FOOT, 2);
      };
    },
  ]);
})();

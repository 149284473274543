import * as React from 'react';

import { Alert, Button } from '@shared/components/bootstrap';

import { client } from '@admin/libraries/apollo';

import { Spinner } from '@admin/components/spinner';

import { useReservableSelfStorageUnitGroupsQuery, SelfStorage__UnitGroupFragment } from '@admin/schema';
import { Filters } from './selector/filters';
import { Entries } from './selector/entries';

export const Selector: React.FC<{
  facilityID: string;
  classificationID?: string;
  priceID?: string;
  onSelect(group: SelfStorage__UnitGroupFragment): void;
}> = ({ classificationID, facilityID, priceID, onSelect }) => {
  const { data, loading, error, refetch } = useReservableSelfStorageUnitGroupsQuery({
    client,
    variables: { facilityID },
  });

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return (
      <Alert style="warning">
        {error.message}
        <Button onClick={() => refetch()}>Retry</Button>
      </Alert>
    );
  }

  const groups = (data && data.groups) || [];

  // The current selection is cleared if the price swaps (i.e. on resuming a draft checkout).
  const selection = groups.find((group) => group.classification.id === classificationID && group.price.id === priceID);

  return (
    <Filters
      groups={groups}
      children={({ filtered }) => <Entries groups={filtered} onSelect={onSelect} selection={selection} />}
    />
  );
};

import React from 'react';

import { DateTime } from 'luxon';

import { PaginatedOrderResult } from '../list';
import { DropOffDisplay } from './drop_off';

export const Scheduled: React.FC<{ order: PaginatedOrderResult }> = ({ order }) => {
  const tz = order.region.tz;
  const scheduled = DateTime.fromISO(order.scheduled).setZone(tz);
  const date = scheduled.toFormat('MMM d, y');

  const OrderSLAWindow = () => (
    <>
      {date}, {order.formattedArrivalWindow}
    </>
  );

  const { dropOffAppointment } = order;

  if (dropOffAppointment) {
    const { expectedAt } = dropOffAppointment;
    const expectedAtDateTime = DateTime.fromISO(expectedAt).setZone(tz);

    return (
      <DropOffDisplay
        orderDate={scheduled}
        dropOffDate={expectedAtDateTime}
        orderComponent={
          <span>
            Transfer: <OrderSLAWindow />
          </span>
        }
        dropOffComponent={<strong>Appointment: {expectedAtDateTime.toLocaleString(DateTime.DATE_MED)}</strong>}
      />
    );
  }

  return <OrderSLAWindow />;
};

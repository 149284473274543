import * as React from 'react';

import { SingleSelector as FacilitySelector } from '@admin/components/self_storage/facilities/single_selector';
import { Selector as OrderSelector } from '@admin/components/orders/selector';
import { Selector as RentalSelector } from '@admin/components/self_storage/account/rentals/selector';

import { FieldFormGroup, SelectFormGroup } from '@admin/components/fields';
import { useSelfStorageRentalsWithUnitsQuery } from '@admin/schema';

import { client } from '@admin/libraries/apollo';
import { OrderTypeEnum } from '@portal/schema';

export enum Product {
  FullService = 'Full-Service',
  SelfStorage = 'Self-Storage',
  ThirdPartyLogistics = '3PL',
  Moving = 'Moving',
}

const PRODUCTS = [Product.FullService, Product.SelfStorage, Product.ThirdPartyLogistics, Product.Moving];

export const SelectProductAndEntity: React.FC<{
  accountID: string;
  product?: Product;
  entityValue?: string;
  onSelectProduct(value: Product): void;
  onSelectEntity(kind?: string, value?: string): void;
}> = ({ accountID, onSelectProduct, onSelectEntity, product, entityValue }) => {
  const { data } = useSelfStorageRentalsWithUnitsQuery({ client, variables: { accountID } });
  const rentals = data?.rentals || [];
  return (
    <>
      <SelectFormGroup
        label="Business Unit:"
        name="product"
        className="form-control"
        value={product || ''}
        onChange={(event) => {
          onSelectProduct(event.target.value as Product);
          onSelectEntity(undefined, undefined);
        }}
      >
        <option disabled={true} value="">
          - Business Unit -
        </option>
        {PRODUCTS.map((entry) => (
          <option key={entry} value={entry}>
            {entry}
          </option>
        ))}
      </SelectFormGroup>
      {product === Product.FullService && (
        <FieldFormGroup label="Order:">
          <OrderSelector
            name="order_id"
            accountID={accountID}
            selectedID={entityValue}
            onSelect={(value) => onSelectEntity('orderID', value!)}
          />
        </FieldFormGroup>
      )}
      {product === Product.SelfStorage &&
        (rentals.length > 0 ? (
          <FieldFormGroup label="Unit">
            <RentalSelector
              name="rental_id"
              accountID={accountID}
              selectedID={entityValue}
              onSelect={(value) => onSelectEntity('rentalID', value!)}
            />
          </FieldFormGroup>
        ) : (
          <FieldFormGroup label="Facility">
            <FacilitySelector
              name="self_storage_facility_id"
              selectedID={entityValue}
              onSelect={(value) => onSelectEntity('selfStorageFacilityID', value!)}
            />
          </FieldFormGroup>
        ))}
      {product === Product.Moving && (
        <FieldFormGroup label="Order:">
          <OrderSelector
            name="order_id"
            accountID={accountID}
            selectedID={entityValue}
            orderType={OrderTypeEnum.Move}
            onSelect={(value) => onSelectEntity('orderID', value!)}
          />
        </FieldFormGroup>
      )}
    </>
  );
};

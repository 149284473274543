const UNKNOWN_NUMBER = '9999999999';

/**
 * Convert a phone number into a human readable format. The method supports
 * formatting while typing. For example:
 *
 * '411': '411'
 * '1234567': '123-4567'
 * '9999999999': 'unknown'
 * '+15555555555': '(555) 555-5555'
 *
 * @param input an optional string to be formatted
 * @returns A number that has been converted into a human readable format
 */
export const formatPhoneForHuman = (input?: string): string => {
  if (!input) {
    return '';
  }

  // strip +1
  if (input[0] === '+' && input[1] === '1') {
    input = input.slice(2);
  }

  if (input === UNKNOWN_NUMBER) {
    return 'unknown';
  }

  if (input.length <= 3) {
    return input;
  } else if (input.length >= 4 && input.length <= 7) {
    return input.slice(0, 3) + '-' + input.slice(3);
  } else if (input.length === 8) {
    return '(' + input.slice(0, 1) + ') ' + input.slice(1, 4) + '-' + input.slice(4);
  } else if (input.length === 9) {
    return '(' + input.slice(0, 2) + ') ' + input.slice(2, 5) + '-' + input.slice(5);
  } else if (input.length === 10) {
    return '(' + input.slice(0, 3) + ') ' + input.slice(3, 6) + '-' + input.slice(6);
  } else {
    return input;
  }
};

import template from '@admin/angular/templates/order_summary.html';

(() => {
  const app = angular.module('app');

  app.component('orderSummary', {
    template,
    bindings: {
      order: '<',
    },
  });
})();

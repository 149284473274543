import * as React from 'react';
import { useMemo } from 'react';

type Option = {
  category: string;
  name: string;
};

export const NestedFormControl: React.FC<
  Omit<React.SelectHTMLAttributes<HTMLSelectElement>, 'onSelect'> & {
    prompt?: string;
    options: Option[];
    selection?: Option;
    onSelect(selection?: Option): void;
  }
> = ({ prompt, options, value, onSelect, ...props }) => {
  const groups = useMemo(() => {
    const memo = new Map<string, string[]>();
    for (const { category, name } of options) {
      const names = memo.get(category) ?? [];
      names.push(name);
      memo.set(category, names);
    }
    return memo;
  }, [options]);

  const onChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const option = options.find(({ name, category }) => `${category}:${name}` === event.target.value);
    onSelect(option);
  };

  return (
    <select {...props} className="form-control" onChange={onChange}>
      {prompt && <option value="">{prompt}</option>}
      {Array.from(groups.keys()).map((category) => (
        <optgroup key={category} label={category}>
          {groups.get(category)?.map((name) => (
            <option key={name} value={`${category}:${name}`}>
              {name}
            </option>
          ))}
        </optgroup>
      ))}
    </select>
  );
};

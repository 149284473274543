import { Titleize } from '@admin/components/helpers/titleize';
import styled from '@emotion/styled';
import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';

import {
  faCcAmex,
  faCcDinersClub,
  faCcDiscover,
  faCcJcb,
  faCcMastercard,
  faCcVisa,
} from '@fortawesome/free-brands-svg-icons';

import { faQuestion } from '@fortawesome/free-solid-svg-icons';

import { Billing__Source } from '@admin/schema';

const faIcon = (brand?: string): IconProp => {
  switch (brand) {
    case 'Visa':
      return faCcVisa;
    case 'American Express':
      return faCcAmex;
    case 'MasterCard':
      return faCcMastercard;
    case 'Discover':
      return faCcDiscover;
    case 'JCB':
      return faCcJcb;
    case 'Diners Club':
      return faCcDinersClub;
    default:
      return faQuestion;
  }
};

const Container = styled.div<{ inline?: boolean }>`
  display: flex;
  flex-direction: ${({ inline }) => (inline ? 'row' : 'column')};
  align-items: center;
  justify-content: center;
  margin: -3px;
`;

const Entry = styled.div`
  padding: 3px;
`;

export const SourceIcon: React.FC<{
  source: Pick<Billing__Source, 'id' | 'brand' | 'default' | 'fundingType' | 'number'>;
  fundingType?: string; // Include for angular usage of this component due to snake casing
  extraDetails?: boolean;
  scale: number;
  inline?: boolean;
}> = ({ source, fundingType, extraDetails, scale, inline }) => (
  <Container inline={inline}>
    <Entry>
      <FontAwesomeIcon
        icon={faIcon(source.brand || undefined)}
        size={`${scale}x` as SizeProp}
        className={source.default ? 'text-warning' : undefined}
      />
    </Entry>
    {extraDetails && (
      <Entry>
        <Titleize text={source.fundingType ?? fundingType} /> {source.number}
      </Entry>
    )}
  </Container>
);

(() => {
  class ClMultiselectCtrl {
    /*
      The goal of the ClMultiselect component is to simplify the creation of dropdowns that can accept multiple selections.
      It has two modes of use: 'flat' and 'nested'.

      'flat' mode means that the options passed in cannot have a nested structure, but the data represented can be nested via
      primary and parent keys.

      ```
      <cl-multiselect
        mode="flat"
        translation="{ nothingSelected: '- Category -' }"
        options="$ctrl.categories"
        selected-options="$ctrl.initialFilters.category_ids"
        label-key="name"
        primary-key="id"
        parent-key="parent_id"
        css-class="category-multiselect"
        on-selection-change="$ctrl.onMultiselectChange('category_ids', selectedOptions)"/>
      ```

      When the selection changes via reset button click or an item is selected/deselected, the onSelectionChange expression
      will be called, selectedOptions will be a list of the currently selected category_ids.


      'nested' mode means that the options passed into the component can have a nested structure.

      ```
      $ctrl.incidentOptions = [
        {
          label: 'Open',
          value: 'open',
          children: [{ label: 'damaged', value: 'damaged' }, { label: 'duplicate', value: 'duplicate' }]
        },
        {
          label: 'Closed',
          value: 'closed',
          children: [{ label: 'lost', value: 'lost' }, { label: 'auction', value: 'auction' }]
        },
      ];

      <cl-multiselect
        mode="nested"
        translation="{ nothingSelected: '- Incident -' }"
        options="$ctrl.incidentOptions"
        selected-options="$ctrl.initialFilters.incidents"
        label-key="label"
        value-key="value"
        children-key="children"
        css-class="incidents-multiselect"
        on-selection-change="$ctrl.onMultiselectChange('incidents', selectedOptions)"/>
      ```

      When the selection changes via reset button click or an item is selected/deselected, the onSelectionChange expression
      will be called, selectedOptions will be a list of the currently selected options. For example, if 'damaged', 'duplicate'
      and 'auction' were selected in the above multiselect, selectedOptions in the onSelectionChange expression would be equal to:


      ```
      [
        {
          'open': ['damaged', 'duplicate'],
          'closed': ['auction']
        }
      ]
      ```

      selectedOptions should be passed to the component in the same format as directly above.
    */

    static get DEFAULT_CHILDREN_KEY() {
      return 'children';
    }

    static get DEFAULT_LABEL_KEY() {
      return 'label';
    }

    static get DEFAULT_VALUE_KEY() {
      return 'value';
    }

    static get DEFAULT_PARENT_KEY() {
      return 'parent_id';
    }

    static get DEFAULT_PRIMARY_KEY() {
      return 'id';
    }

    static get DEFAULT_TRANSLATION() {
      return {
        selectAll: 'Tick all',
        selectNone: 'Tick none',
        reset: 'Reset',
        search: 'Type here to search...',
        nothingSelected: 'None Selected',
      };
    }

    static get FLAT_MODE() {
      return 'flat';
    }

    static get NESTED_MODE() {
      return 'nested';
    }

    $onInit() {
      const validMode = this.isFlatMode() || this.isNestedMode();
      if (!validMode) {
        throw new InvalidMultiselectModeError("mode is required and must be either 'nested' or 'flat'");
      }
      this.translation = _.merge(_.clone(ClMultiselectCtrl.DEFAULT_TRANSLATION), this.translation);
    }

    /*
      Given the options (the options passed into the component) and selectedOptions (passed into the component as selectedOptions),
      generate 'isteven options'; options structured in the way that isteven expects to be passed into its inputModel binding, see:
      http://isteven.github.io/angular-multi-select/#/demo-grouping
    */
    generateIstevenOptions() {
      if (this.isFlatMode()) {
        this.istevenOptions = this.generateIstevenOptionsFromFlat(this.options, this.selectedOptions || []);
      } else if (this.isNestedMode()) {
        this.istevenOptions = this.generateIstevenOptionsFromNested(this.options, [], this.selectedOptions || []);
      }
    }

    $onChanges(changesObj) {
      if (changesObj.options && changesObj.options.currentValue) {
        if (changesObj.options.currentValue.$promise) {
          changesObj.options.currentValue.$promise.then(this.generateIstevenOptions.bind(this));
        } else {
          this.generateIstevenOptions();
        }
      }
    }

    generateIstevenOptionsFromFlat(options, selectedOptions) {
      const mappings = _.groupBy(options, (option) => {
        const parentId = this.parentKey || ClMultiselectCtrl.DEFAULT_PARENT_KEY;
        return option[parentId];
      });
      const generate = (rootOptions, istevenOptions) => {
        for (const option of rootOptions) {
          const primaryId = option[this.primaryKey || ClMultiselectCtrl.DEFAULT_PRIMARY_KEY];
          const label = option[this.labelKey || ClMultiselectCtrl.DEFAULT_LABEL_KEY];
          const childrenOptions = mappings[primaryId];
          if (childrenOptions) {
            istevenOptions.push({ name: label, msGroup: true });
            generate(childrenOptions, istevenOptions);
            istevenOptions.push({ msGroup: false });
          } else {
            const ticked = selectedOptions.includes(String(primaryId));
            istevenOptions.push({ name: label, value: primaryId, ticked });
          }
        }
      };
      // parent of both `undefined` and `null` signify root:
      const rootOptions = (mappings[undefined] || []).concat(mappings.null || []);
      const istevenOptions = [];
      generate(rootOptions, istevenOptions);
      return istevenOptions;
    }

    generateIstevenOptionsFromNested(options, parentValues, selectedOptions) {
      let istevenOptions = [];
      for (const option of options) {
        const childrenOptions = option[this.childrenKey || ClMultiselectCtrl.DEFAULT_CHILDREN_KEY];
        const label = option[this.labelKey || ClMultiselectCtrl.DEFAULT_LABEL_KEY];
        const value = option[this.valueKey || ClMultiselectCtrl.DEFAULT_VALUE_KEY];
        if (childrenOptions) {
          let newSelectedOptions = [];
          for (const selectedOption of selectedOptions) {
            if (selectedOption instanceof Object && selectedOption[value]) {
              // It's possible that there are multiple objects matching the value due to the way
              // query strings are parsed via the qs library, so we combine the arrays.
              // An example of where using the qs.parse function results in this behavior:
              // Stringifying: {"incidents":["cold",{"open":["damaged",{"lost":["red","green"]}]}]}
              // And parsing the result results in:
              // {"incidents":["cold",{"open":["damaged"]},{"open":[{"lost":["red","green"]}]}]}
              // An issue has been made for this bug: https://github.com/ljharb/qs/issues/241.
              newSelectedOptions = newSelectedOptions.concat(selectedOption[value]);
            }
          }
          istevenOptions.push({ name: label, msGroup: true });
          istevenOptions = istevenOptions.concat(
            this.generateIstevenOptionsFromNested(childrenOptions, parentValues.concat(value), newSelectedOptions),
          );
          istevenOptions.push({ msGroup: false });
        } else {
          const ticked = selectedOptions.includes(value);
          istevenOptions.push({ name: label, value, parentValues, ticked });
        }
      }
      return istevenOptions;
    }

    /*
      For nested mode, take the flat list of selected (ticked) options from isteven and convert it to
      a nested structure, i.e.:
      ```
      [
        {
          "open": [
            "damaged",
            "duplicate",
            {
              "lost": [
                "green"
              ]
            },
            {
              "auction": [
                "foo",
                "bar"
              ]
            }
          ],
          "closed": [
            "duplicate"
          ]
        },
        "pending"
      ]

      ```

      For flat mode, take the flat list of selected (ticked) options from isteven and map over the list
      so each element is the primaryKey of the option.

      Then, the transformed structure is passed to the onSelectionChange binding passed into this component in a key called
      `selectedOptions`.
    */
    _onItemClick() {
      let selectedOptions = [];
      if (this.isFlatMode()) {
        selectedOptions = this.getSelectedOptionsForFlat();
      } else if (this.isNestedMode()) {
        selectedOptions = this.getSelectedOptionsForNested();
      }
      this.onSelectionChange({ selectedOptions });
    }

    getSelectedOptionsForFlat() {
      return this.istevenSelectedOptions.map((selectedOption) => selectedOption.value);
    }

    getSelectedOptionsForNested() {
      const topLevelSelectedOptions = [];
      const nestedSelectedOptions = {};
      for (const istevenSelectedOption of this.istevenSelectedOptions) {
        const isTopLevelSelectedOption = !istevenSelectedOption.parentValues.length;
        if (isTopLevelSelectedOption) {
          topLevelSelectedOptions.push(istevenSelectedOption.value);
          continue;
        }
        let cursor = nestedSelectedOptions;
        for (let i = 0; i < istevenSelectedOption.parentValues.length; i++) {
          const parentValue = istevenSelectedOption.parentValues[i];
          const isLastParentValue = i === istevenSelectedOption.parentValues.length - 1;
          if (isLastParentValue) {
            cursor[parentValue] = cursor[parentValue] || [];
            cursor[parentValue].push(istevenSelectedOption.value);
          } else {
            const nextParentValue = istevenSelectedOption.parentValues[i + 1];
            let foundObj = false;
            const els = cursor[parentValue] || [];
            // At this level in the tree, we can have leaves and non-leaf nodes. Non-leaf nodes are represented by an object;
            // we must set the cursor to the correct non-leaf node if it already exists in nestedSelectedOptions.
            // For example, imagine we have the following istevenSelectedOptions:
            // [
            //   { name: 'damaged', value: 'damaged', parentValues: ['open'] },
            //   { name: 'lost', value: 'lost', parentValues: ['open', 'missing'] },
            //   { name: 'trashed', value: 'trashed', parentValues: ['open', 'missing'] }
            // ]
            // After the first two istevenSelectedOption is processed by the outermost for loop, nestedSelectedOptions will be:
            // {
            //   open: [
            //     'damaged',
            //     { missing: ['lost'] }
            //   ]
            // }
            // When processing the third istevenSelectedOption, the below for loop will set the cursor to the object {missing: ['lost']}
            for (const el of els) {
              if (el instanceof Object && el[nextParentValue]) {
                foundObj = true;
                cursor = el;
                break;
              }
            }
            // If the non-leaf node does not yet exist in nestedSelectedOptions, we'll create it and set the cursor to point to it:
            if (!foundObj) {
              const newObj = {};
              newObj[nextParentValue] = [];
              cursor[parentValue] = cursor[parentValue] || [];
              cursor[parentValue].push(newObj);
              cursor = newObj;
            }
          }
        }
      }
      if (Object.keys(nestedSelectedOptions).length) {
        topLevelSelectedOptions.push(nestedSelectedOptions);
      }
      return topLevelSelectedOptions;
    }

    // Untick the items in the UI, call the onReset passed into the component if present,
    // then call the onSelectionChange passed into the component (with selectedOptions set to []).
    _onReset() {
      for (const option of this.istevenOptions) {
        option.ticked = false;
      }
      if (this.onReset) {
        this.onReset();
      }
      this.onSelectionChange({ selectedOptions: [] });
    }

    isFlatMode() {
      return this.mode === ClMultiselectCtrl.FLAT_MODE;
    }

    isNestedMode() {
      return this.mode === ClMultiselectCtrl.NESTED_MODE;
    }
  }

  ClMultiselectCtrl.$inject = [];

  const ClMultiselect = {
    bindings: {
      options: '<',
      labelKey: '@?',
      valueKey: '@?',
      childrenKey: '@?',
      selectedOptions: '<?',
      onSelectionChange: '&',
      onReset: '&?',
      mode: '@',
      maxLabels: '@?',
      primaryKey: '@?',
      parentKey: '@?',
      helperElements: '@?',
      translation: '<?',
      cssClass: '@?',
    },
    controller: ClMultiselectCtrl,
    templateUrl: 'partials/components/cl_multiselect.html',
  };

  class InvalidMultiselectModeError extends Error {}

  angular.module('app').component('clMultiselect', ClMultiselect);
})();

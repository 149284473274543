import { CancelButton } from '@admin/components/rate_adjustment/schedulings/cancel';
import { Size } from '@admin/components/self_storage/classifications/size';
import { accountURL } from '@admin/config/routes';

import {
  RateAdjustment__Scheduling__State,
  RateAdjustment__SchedulingFragment,
  RateAdjustment__Upload__Product,
  SelfStorage__UnitFragment,
} from '@admin/schema';
import { AnchorButton as Link } from '@shared/components/bootstrap';

import { Currency } from '@shared/components/helpers';
import { capitalize } from '@shared/utils/capitalize';
import cn from 'classnames';
import { DateTime } from 'luxon';
import * as React from 'react';

const StateLabel: React.FC<{ state: RateAdjustment__SchedulingFragment['state'] }> = ({ state }) => (
  <span
    className={cn(
      'label',
      state === RateAdjustment__Scheduling__State.Pending && 'label-info',
      state === RateAdjustment__Scheduling__State.Canceled && 'label-default',
      state === RateAdjustment__Scheduling__State.Reversed && 'label-default',
      state === RateAdjustment__Scheduling__State.Active && 'label-success',
    )}
  >
    {state === RateAdjustment__Scheduling__State.Active ? 'Completed' : capitalize(state)}
  </span>
);

const SelfStorageRows: React.FC<{
  unit: Pick<SelfStorage__UnitFragment, 'id' | 'name' | 'classification' | 'facility'>;
}> = ({ unit }) => (
  <>
    <td className="text-left">{unit.facility.name}</td>
    <td className="text-left">{unit.name}</td>
    <td className="text-left">
      <Size classification={unit.classification} /> {unit.classification.kind.name}
    </td>
  </>
);

const SelfStorageHeaders: React.FC = () => (
  <>
    <th className="text-left">Facility</th>
    <th className="text-left">Unit Name</th>
    <th className="text-left">Unit Details</th>
  </>
);

export const Table: React.FC<{
  product: RateAdjustment__Upload__Product;
  entries: RateAdjustment__SchedulingFragment[];
}> = ({ product, entries }) => {
  const isSelfStorage = product === RateAdjustment__Upload__Product.SelfStorage;

  return (
    <div className="table-responsive">
      <table className="table table-striped">
        <thead>
          <tr>
            <th className="text-left">Customer Name</th>
            {isSelfStorage && <SelfStorageHeaders />}
            <th className="text-left">Uploaded On</th>
            <th className="text-left">Scheduled Date</th>
            <th className="text-left">Old Price</th>
            <th className="text-left">New Price</th>
            <th className="text-left">Status</th>
            <th className="text-left">Label</th>
            <th className="text-right" />
          </tr>
        </thead>
        <tbody>
          {entries.map((entry) => (
            <tr key={entry.id}>
              <td className="text-left">{entry.account.customer.name}</td>
              {isSelfStorage && <SelfStorageRows unit={entry.selfStorageRental!.unit} />}
              <td className="text-left">{DateTime.fromISO(entry.createdAt).toLocaleString(DateTime.DATE_FULL)}</td>
              <td className="text-left">
                {DateTime.fromISO(entry.effectiveAt).toUTC().toLocaleString(DateTime.DATE_FULL)}
              </td>
              <td className="text-left">
                <Currency value={entry.oldPrice} />
              </td>
              <td className="text-left">
                <Currency value={entry.newPrice} />
              </td>
              <td className="text-left">
                <StateLabel state={entry.state} />
                {entry.state === RateAdjustment__Scheduling__State.Pending && <CancelButton scheduling={entry} />}
              </td>
              <td className="text-left">{entry.label}</td>
              <td>
                <Link kind="default" href={accountURL(entry.account)}>
                  Account
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

//

(function () {
  const app = angular.module('app');

  app.directive('invoiceDetails', [
    function () {
      return {
        restrict: 'E',
        templateUrl: 'partials/invoices/details_view.html',
        scope: {
          payment: '=?',
          invoice: '=?',
        },
        controller: [
          '$scope',
          function ($scope) {
            $scope.invoice.open = false;

            $scope.transactionHistory = function (invoice) {
              return invoice.charges
                .concat(invoice.offline_payments)
                .concat(invoice.refunds)
                .sort(function (a, b) {
                  return new Date(a.date) < new Date(b.date);
                });
            };

            $scope.refundable = function (transaction) {
              if (
                transaction.type === 'charge' &&
                transaction.status === 'succeeded' &&
                transaction.refunded === false
              ) {
                return true;
              } else {
                return false;
              }
            };
          },
        ],
      };
    },
  ]);
})();

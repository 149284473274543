import { DateTime } from 'luxon';
import * as React from 'react';

import { AnchorButton as Link, Table } from '@shared/components/bootstrap';

import { SelfStorage__RentalFragment } from '@admin/schema';

import { Email } from '@shared/components/helpers/email';
import { Phone } from '@shared/components/helpers/phone';

import { Size } from '@admin/components/self_storage/classifications/size';
import { Status } from '@admin/components/self_storage/rentals/status';

import { Overlock } from '@admin/components/self_storage/rentals/overlock';

import { accountURL } from '@admin/config/routes';

export const Entries: React.FC<{
  rentals: SelfStorage__RentalFragment[];
}> = ({ rentals }) => (
  <Table striped responsive>
    <thead>
      <tr>
        <th className="text-left">ID</th>
        <th className="text-left">Facility</th>
        <th className="text-left">Unit #</th>
        <th className="text-left">Unit Details</th>
        <th className="text-left">Floor</th>
        <th className="text-left">Move-In Date</th>
        <th className="text-left">Move-Out Date</th>
        <th className="text-left">Name</th>
        <th className="text-left">Email</th>
        <th className="text-left">Phone</th>
        <th className="text-left">Status</th>
        <th className="text-right" />
      </tr>
    </thead>
    <tbody>
      {rentals.map((rental) => (
        <tr key={rental.id}>
          <td className="text-left">{rental.id}</td>
          <td className="text-left">{rental.unit.facility.name}</td>
          <td className="text-left">{rental.unit.name}</td>
          <td className="text-left">
            <Size classification={rental.unit.classification} /> {rental.unit.classification.kind.name}
          </td>
          <td className="text-left">{rental.unit.floor}</td>
          <td className="text-left">
            {rental.activatedAt && DateTime.fromISO(rental.activatedAt).toLocaleString(DateTime.DATETIME_MED)}
          </td>
          <td className="text-left">
            {rental.canceledAt && DateTime.fromISO(rental.canceledAt).toLocaleString(DateTime.DATETIME_MED)}
          </td>
          <td className="text-left">{rental.subscription.account.customer.name}</td>
          <td className="text-left">
            <Email value={rental.subscription.account.customer.email} />
          </td>
          <td className="text-left">
            {rental.subscription.account.customer.phone ? (
              <Phone value={rental.subscription.account.customer.phone} />
            ) : (
              'N/A'
            )}
          </td>
          <td className="text-left col-nowrap">
            <Status status={rental.status} /> <Overlock rental={rental} />
          </td>
          <td className="text-right">
            <Link kind="default" href={accountURL(rental.subscription.account)}>
              Account
            </Link>
          </td>
        </tr>
      ))}
    </tbody>
  </Table>
);

import { DateTime } from 'luxon';
import React, { FC, ReactNode } from 'react';

import { Availability } from './types';

import { AvailabilityContext } from './availability_context';

export const AvailabilityContainer: FC<{
  availabilities?: Availability[];
  loading?: boolean;
  zone: string;
  period: DateTime;
  from: DateTime;
  till: DateTime;
  date?: DateTime;
  selection?: Availability;
  onDate(date: DateTime): void;
  onSelect(availability: Availability): void;
  onReset(): void;
  onNavigate(period: DateTime): void;
  children: ReactNode;
}> = ({
  availabilities = [],
  loading,
  zone,
  from,
  till,
  period,
  date,
  selection,
  children,
  onDate,
  onReset,
  onNavigate,
  onSelect,
}) => {
  const onNext = () => {
    onReset();
    onNavigate(period.plus({ months: 1 }));
  };

  const onPrev = () => {
    onReset();
    onNavigate(period.minus({ months: 1 }));
  };

  return (
    <AvailabilityContext.Provider
      value={{
        availabilities,
        loading,
        zone,
        from,
        till,
        date,
        selection,
        onNext,
        onPrev,
        onDate: (value) => {
          onReset();
          onDate(value);
        },
        onSelect,
      }}
      children={children}
    />
  );
};

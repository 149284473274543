import { Address } from '@admin/types/address';
import { GeocodeQuery } from '@admin/schema';
import classNames from 'classnames';
import { some } from 'lodash';
import React from 'react';

const addressFields = ['street', 'aptsuite', 'city', 'state'];

class AddressValidator extends React.Component<
  {
    address: Address;
    geocode?: GeocodeQuery['geocode'];
    shouldFlagAmbiguousAddress?: boolean;
    shouldFlagInvalidAddress?: boolean;
    onAddressChange?(error: string | null): void;
  },
  {
    message: null | string;
    warning: null | string;
  }
> {
  public state = {
    message: null,
    warning: null,
  };

  public componentDidUpdate() {
    let message = null;
    let warning = null;

    const { geocode, shouldFlagAmbiguousAddress, shouldFlagInvalidAddress, onAddressChange } = this.props;

    if (geocode) {
      if (shouldFlagInvalidAddress && (!geocode.latitude || !geocode.longitude)) {
        warning = 'Invalid address';
      } else if (shouldFlagAmbiguousAddress && geocode.potentially_invalid) {
        warning = 'Potentially invalid address. Please double-check the address before continuing.';
      } else {
        // eslint-disable-next-line no-lonely-if
        if (this.isValidationRequired()) {
          message = 'Valid address';
        }
      }
    }

    if (message !== this.state.message || warning !== this.state.warning) {
      this.setState({
        message,
        warning,
      });
    }

    if (onAddressChange) {
      onAddressChange(warning);
    }
  }

  public isValidationRequired = () => this.props.shouldFlagAmbiguousAddress || this.props.shouldFlagInvalidAddress;

  public isAddressPresent = () =>
    this.props.address && some(addressFields, (field) => (this.props.address as any)[field]);

  public isAddressPresentAndGeocoded = () => this.isAddressPresent() && this.props.geocode;

  public getClassNames = () => classNames('text-left', this.state.warning ? 'text-danger' : 'text-success');

  public render() {
    return this.isAddressPresentAndGeocoded() ? (
      <div className={this.getClassNames()}>{this.state.warning || this.state.message}</div>
    ) : null;
  }
}

export default AddressValidator;

import React, { useState } from 'react';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import QRCode from 'qrcode.react';

import { client } from '@admin/libraries/apollo';
import { STRIPE_API_KEY } from '@admin/config/stripe';
import { Element, useAdapter, useElement, useTokenize } from '@shared/components/stripe';
import { Button, Table, Text, Checkbox, Badge, Tooltip } from '@shared/components/bootstrap';
import { Panel } from '@admin/components/helpers/panel';
import { Pagination } from '@admin/components/pagination';
import { Spinner } from '@admin/components/spinner';
import { Timestamp } from '@admin/components/timestamp';
import { accountURL, orderURL } from '@admin/config/routes';
import { Spacer } from '@shared/components/helpers';
import { Input } from '@clutter/clean';
import {
  Automation__TestOrderRequest__State,
  Automation__TestOrderRequest,
  useTestOrderRequestsQuery,
  useBuildTestOrderRequestMutation,
  OrderStateEnum,
} from '@admin/schema';
import { usePusher } from '@admin/hooks';

const SpinnerWrapper = styled.div`
  display: flex;
`;

const TEST_ORDER_REQUEST_STATE_TO_STYLE: Record<Automation__TestOrderRequest__State, 'danger' | 'success' | 'info'> = {
  [Automation__TestOrderRequest__State.Failed]: 'danger',
  [Automation__TestOrderRequest__State.Processed]: 'success',
  [Automation__TestOrderRequest__State.Processing]: 'info',
  [Automation__TestOrderRequest__State.Pending]: 'info',
};

const Status: React.FC<{ state: Automation__TestOrderRequest['state'] }> = ({ state }) => (
  <Badge style={TEST_ORDER_REQUEST_STATE_TO_STYLE[state]}>
    {state.toLowerCase()}{' '}
    {state === Automation__TestOrderRequest__State.Processing && <FontAwesomeIcon spin icon="spinner" />}
  </Badge>
);

export const TestOrders: React.FC = () => {
  const [qrCodes, setQrCodes] = useState<string[] | undefined>(undefined);
  const [cardName, setCardName] = useState<string>('');
  const [completed, setCompleted] = useState<boolean>(false);
  const [customCardEnabled, setCustomCardEnabled] = useState<boolean>(false);
  const [token, setToken] = useState<IStripeToken | undefined>(undefined);
  const [page, setPage] = useState<number | undefined>();
  const { data, loading, refetch } = useTestOrderRequestsQuery({ client, variables: { page } });
  const [executeSubmit, { loading: creatingOrder }] = useBuildTestOrderRequestMutation({ client });
  const paginated = data?.paginated;

  usePusher<{ id: number }>('automation-test-order-request', 'changed', () => {
    refetch();
  });

  const adapter = useAdapter(STRIPE_API_KEY);
  const element = useElement(adapter, 'card');
  const { tokenize, tokenizing } = useTokenize(adapter, element);

  const executeTokenize = (name: string) => tokenize({ name }).then(setToken);
  const invalidSetup = customCardEnabled && !token;
  const creationDisabled = creatingOrder || invalidSetup;

  const generateQRCode = () => {
    const min = Math.ceil(1000000000);
    const max = Math.floor(9999999999);
    const newCodes = [String(Math.floor(Math.random() * (max - min + 1)) + min)];
    if (qrCodes) {
      setQrCodes(qrCodes.concat(newCodes));
    } else {
      setQrCodes(newCodes);
    }
  };

  const submitNewOrder = async (type: string, subtype: string, simultaneousPickup: boolean) => {
    const result = await executeSubmit({
      variables: {
        input: {
          type,
          subtype,
          completed,
          cardToken: token?.id,
          simultaneousPickup,
        },
      },
    });
    if (!result?.data) {
      return;
    }
    setCustomCardEnabled(false);
    setToken(undefined);

    refetch();
  };

  const formatOrderType = (type: string, subtype: string) =>
    type[0].toUpperCase() +
    type.slice(1).toLowerCase() +
    ' - ' +
    subtype[0].toUpperCase() +
    subtype.slice(1).toLowerCase();

  return (
    <>
      <Panel>
        <Panel.Header>
          <Panel.Title>Test Orders</Panel.Title>
        </Panel.Header>
        <Panel.Body>
          <div className="row">
            <div className="col-md-6">
              <label className="control-label" htmlFor="complete">
                Completed:
              </label>
              <select
                id="complete"
                className="form-control"
                defaultValue="No"
                onChange={(event) => setCompleted(event.target.value === 'Yes')}
              >
                <option value="No">No</option>
                <option value="Yes">Yes</option>
              </select>
            </div>
            <div className="col-md-6">
              <Checkbox>
                <input
                  name="invoice"
                  type="checkbox"
                  checked={customCardEnabled}
                  onChange={(event) => {
                    setCustomCardEnabled(event.target.checked);
                    setToken(undefined);
                  }}
                />
                Custom Card
                {token && ` (ending in ${token.card?.last4})`}
              </Checkbox>
              {customCardEnabled && !token && (
                <>
                  <Input
                    className="form-control"
                    placeholder="Name"
                    type="text"
                    onChange={(event) => setCardName(event.target.value || '')}
                    value={cardName}
                  />
                  <Element element={element} />
                  <Button kind="info" disabled={tokenizing} onClick={() => executeTokenize(cardName)}>
                    Save
                  </Button>
                </>
              )}
            </div>
          </div>
          <br />
          <div className="row">
            <Text alignment="left" tag="div">
              <Button
                kind="primary"
                disabled={creationDisabled}
                onClick={() => submitNewOrder('Pickup', 'Onboarding', false)}
              >
                Create Onboarding
              </Button>{' '}
              <Button
                kind="dark"
                disabled={creationDisabled}
                onClick={() => submitNewOrder('Pickup', 'Subsequent', false)}
              >
                Create Subsequent Pickup
              </Button>{' '}
              <Button
                kind="success"
                disabled={creationDisabled}
                onClick={() => submitNewOrder('Return', 'Subsequent', false)}
              >
                Create Subsequent Return
              </Button>{' '}
              <Button
                kind="warning"
                disabled={creationDisabled}
                onClick={() => submitNewOrder('Return', 'Subsequent', true)}
              >
                Create Sub Return w/ Sim Pickup
              </Button>{' '}
              <Button kind="info" disabled={creationDisabled} onClick={() => submitNewOrder('Return', 'Final', false)}>
                Create Final Return
              </Button>{' '}
              <Button
                kind="dark"
                disabled={creationDisabled}
                onClick={() => submitNewOrder('Moving::Move', 'None', false)}
              >
                Create Move
              </Button>{' '}
              <Button
                kind="dark"
                disabled={creationDisabled}
                onClick={() => submitNewOrder('Moving::Move', 'None', true)}
              >
                Create Move w/ Sim OB
              </Button>{' '}
              <Button
                kind="dark"
                disabled={creationDisabled}
                onClick={() => submitNewOrder('Moving::Move', 'Packing', false)}
              >
                Create Long-Distance Move
              </Button>
            </Text>
          </div>
          {loading && (
            <>
              <SpinnerWrapper>
                <Spinner />
              </SpinnerWrapper>
              <Spacer height="8px" />
            </>
          )}
          <Table responsive>
            <thead>
              <tr>
                <th>ID</th>
                <th>State</th>
                <th>Account Link</th>
                <th>Order Link</th>
                <th>Type</th>
                <th>Completed</th>
                <th>Scheduled</th>
              </tr>
            </thead>
            <tbody>
              {paginated?.results.map(({ id, type, state, error, subtype, completed: requestedCompleted, order }) => (
                <tr key={id}>
                  <td>{id}</td>
                  <td>
                    {error ? (
                      <Tooltip title={error}>
                        <Status state={state} />
                      </Tooltip>
                    ) : (
                      <Status state={state} />
                    )}
                  </td>
                  <td>
                    {order?.accountID && (
                      <a className="btn-link" target="_blank" href={accountURL({ id: order.accountID })}>
                        Account {order.accountID}
                      </a>
                    )}
                  </td>
                  <td>
                    {order?.id && (
                      <a className="btn-link" target="_blank" href={orderURL({ id: order.id })}>
                        Order {order.id}
                      </a>
                    )}
                  </td>
                  <td>{formatOrderType(type, subtype)}</td>
                  <td>{order?.state === OrderStateEnum.Completed || requestedCompleted ? 'Yes' : 'No'}</td>
                  <td>{order?.scheduled && <Timestamp value={order.scheduled} format="medium" />}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          {paginated && <Pagination onPage={setPage} pagination={paginated.pagination} />}
        </Panel.Body>
      </Panel>
      <Panel>
        <Panel.Body>
          <div className="row">
            {qrCodes &&
              qrCodes.map((code) => (
                <div key={code}>
                  <div className="col-lg-2 text-center">
                    <div>
                      <strong> {code} </strong>
                    </div>
                    <QRCode value={code} />
                  </div>
                </div>
              ))}
          </div>
        </Panel.Body>
        <Panel.Footer>
          <div className="text-right">
            <Button kind="primary" onClick={generateQRCode}>
              Generate Item QR Code
            </Button>
          </div>
        </Panel.Footer>
      </Panel>
    </>
  );
};

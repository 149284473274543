import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { DateTime, Duration } from 'luxon';

import { Panel } from '@admin/components/helpers/panel';
import {
  Workforce__FacilityFragment,
  Workforce__ShiftInput,
  Workforce__JobCodeFragment,
  Workforce__ClockinInput,
} from '@admin/schema';
import { Box, COLORS } from '@clutter/clean';
import styled from '@emotion/styled';
import { Button, HelpBlock } from '@shared/components/bootstrap';
import { Spacer } from '@shared/components/helpers';
import { UUID } from '@shared/utils/uuid';

import { DateTimePicker } from './form/date_time_picker';
import { Field } from './form/field';
import { WorkBreakFields, IWorkBreakInput } from './form/work_break_fields';
import { ActivityFields, IActivityInput } from './form/activity_fields';
import { FacilityPicker } from './form/facility_picker';
import { calculateDuration } from './utilities';

const Title = styled(Box.Flex)`
  font: 'Open Sans';
  font-size: 17px;
  font-weight: 300;
  line-height: 35px;
  margin: 0 20px;
`;

const TotalHoursText = styled(Box.Flex)`
  font-size: 16px;
  line-height: 24px;
  margin: auto 20px;
  vertical-align: text-bottom;
`;

const WarningBlock = styled(HelpBlock)`
  color: ${COLORS.tiger};
  margin-top: -24px;
`;

const MAX_ACTIVITIES_PER_SHIFT = 5;

const validShiftClockin = ({ timestamp, facilityID }: Workforce__ClockinInput) =>
  [timestamp, facilityID].every(Boolean);

const shiftSplittingDisabled = (started: Workforce__ClockinInput, ended: Workforce__ClockinInput) =>
  ![started, ended].every(validShiftClockin);

export const ShiftForm: React.FC<{
  shift: Workforce__ShiftInput;
  originalJobCode?: Workforce__JobCodeFragment;
  originalFacilityID?: string;
  userID: string;
  tz: string;
  jobCodes: Workforce__JobCodeFragment[];
  facilities?: Workforce__FacilityFragment[];
  setActivityDurationValid(valid: boolean): void;
}> = ({ shift, originalJobCode, originalFacilityID, userID, tz, jobCodes, facilities, setActivityDurationValid }) => {
  const { register, setValue, unregister, watch } = useFormContext<Workforce__ShiftInput>();

  const started = watch('started.timestamp') as string;
  const ended = watch('ended.timestamp') as string;
  const startedFacility = watch('started.facilityID') as string;
  const endedFacility = watch('ended.facilityID') as string;

  const [activityWarning, setActivityWarning] = useState<string>();
  const [durationMinutes, setDurationMinutes] = useState<number>(calculateDuration(started, ended, shift.workBreaks));
  const [activities, setActivities] = useState<IActivityInput[]>(
    shift.activities.map((activity) => ({ ...activity, key: UUID() })),
  );
  const [workBreaks, setWorkBreaks] = useState<IWorkBreakInput[]>(
    shift.workBreaks.map((workBreak) => ({ ...workBreak, key: UUID() })),
  );

  const editMode = !shift.id;

  useEffect(() => {
    const duration = calculateDuration(started, ended, workBreaks);
    setDurationMinutes(duration);
    if (activities.length === 1) {
      updateActivity(0, { ...activities[0], durationMinutes: duration, key: UUID() });
    }
  }, [started, ended, workBreaks]);

  useEffect(() => {
    const activityLength = activities.reduce((minutes, activity) => minutes + activity.durationMinutes, 0);
    setActivityDurationValid(
      Math.ceil(Duration.fromObject({ minutes: durationMinutes }).as('minutes')) === activityLength,
    );
  }, [durationMinutes, activities]);

  useEffect(() => {
    if (DateTime.fromISO(ended || '').isValid && activities.length === 0 && durationMinutes !== 0) {
      setActivities([
        {
          id: null,
          key: UUID(),
          durationMinutes: durationMinutes,
          jobCodeID: originalJobCode?.id || '',
        },
      ]);
    }
  }, [ended, durationMinutes]);

  useEffect(() => {
    if (originalJobCode && !activities.every(({ jobCodeID }) => jobCodeID === originalJobCode.id)) {
      setActivityWarning(`Job codes do not match original shift assignment (${originalJobCode.name})`);
    } else {
      setActivityWarning(undefined);
    }
  }, [activities]);

  useEffect(() => {
    register({ name: 'started.timestamp' });
    register({ name: 'ended.timestamp' });
    register({ name: 'started.facilityID' });
    register({ name: 'ended.facilityID' });

    return () => {
      unregister('started.timestamp');
      unregister('ended.timestamp');
      unregister('started.facilityID');
      unregister('ended.facilityID');
    };
  }, [register]);

  useEffect(() => {
    activities.forEach((activity, index) => {
      setValue(`activities.${index}.id`, activity.id);
      setValue(`activities.${index}.jobCodeID`, activity.jobCodeID);
      setValue(`activities.${index}.durationMinutes`, activity.durationMinutes);
    });
  }, [activities, setValue]);

  useEffect(() => {
    workBreaks.forEach((workBreak, index) => {
      setValue(`workBreaks.${index}.started`, workBreak.started);
      setValue(`workBreaks.${index}.ended`, workBreak.ended);
      setValue(`workBreaks.${index}.id`, workBreak.id);
    });
  }, [workBreaks, setValue]);

  useEffect(() => {
    if (originalFacilityID && !startedFacility && !endedFacility) {
      setValue('started.facilityID', originalFacilityID);
      setValue('ended.facilityID', originalFacilityID);
    }
  }, [originalFacilityID]);

  useEffect(() => {
    if (!endedFacility || !ended) {
      setValue('ended.facilityID', startedFacility);
    }
  }, [startedFacility]);

  const removeActivity = (indexToRemove: number) => {
    const updatedActivities = activities.filter((_, index) => index !== indexToRemove);
    setActivities(updatedActivities);
  };

  const appendActivity = () => {
    if (activities.length === 5) {
      setActivityWarning(`Number of activities cannot exceed ${MAX_ACTIVITIES_PER_SHIFT} per shift`);
      return;
    }
    const updatedActivities = [...activities, { jobCodeID: '', durationMinutes: 0, id: null, key: UUID() }];
    setActivities(updatedActivities);
  };

  const updateActivity = (indexToUpdate: number, updatedActivity: IActivityInput) => {
    const updatedActivities = [...activities];
    updatedActivities[indexToUpdate] = updatedActivity;
    setActivities(updatedActivities);
  };

  const removeWorkBreak = (indexToRemove: number) => {
    const updatedWorkBreaks = workBreaks.filter((_, index) => index !== indexToRemove);
    setWorkBreaks(updatedWorkBreaks);
  };

  const appendWorkBreak = () => {
    const updatedWorkBreaks = [...workBreaks, { started: null, ended: null, id: null, key: UUID() }];
    setWorkBreaks(updatedWorkBreaks);
  };

  const updateWorkBreak = (indexToUpdate: number, updatedWorkBreak: IWorkBreakInput) => {
    const updatedWorkBreaks = [...workBreaks];
    updatedWorkBreaks[indexToUpdate] = updatedWorkBreak;
    setWorkBreaks(updatedWorkBreaks);
  };

  return (
    <Panel>
      <input id="id" type="hidden" name="id" ref={register} disabled={true} />
      <input id="user_id" type="hidden" name="userID" defaultValue={userID} ref={register} disabled={true} />
      <Panel.Header>
        <Box.Flex flexDirection="row" justifyContent="space-between">
          <Title>Clock In & Clock Out</Title>
          <TotalHoursText>
            <strong>Total:&nbsp;</strong>
            {Duration.fromObject({ minutes: durationMinutes }).toFormat('hh:mm')}
          </TotalHoursText>
        </Box.Flex>
      </Panel.Header>
      <Panel.Body>
        <Field id="shift_start" label="Shift Start">
          <DateTimePicker
            dt={DateTime.fromISO(started).setZone(tz)}
            onChange={(newDateTime) => {
              setValue(`started.timestamp`, newDateTime?.toString() || '');
            }}
            tz={tz}
            dateWithTimeRequired
          />
          {editMode && facilities && (
            <FacilityPicker
              id="shift_start_facility"
              facilityID={startedFacility || originalFacilityID}
              facilities={facilities}
              onChange={(facilityID) => {
                setValue(`started.facilityID`, facilityID || '');
              }}
            />
          )}
        </Field>
        <Field id="shift_end" label="Shift End">
          <DateTimePicker
            dt={ended ? DateTime.fromISO(ended).setZone(tz) : undefined}
            onChange={(newDateTime) => {
              setValue(`ended.timestamp`, newDateTime?.toString() || '');
            }}
            tz={tz}
            required={false}
            dateWithTimeRequired
          />
          {editMode && facilities && (
            <FacilityPicker
              id="shift_end_facility"
              facilityID={endedFacility || originalFacilityID}
              facilities={facilities}
              onChange={(facilityID) => {
                setValue(`ended.facilityID`, facilityID || '');
              }}
            />
          )}
        </Field>
      </Panel.Body>
      <Panel.Header>
        <Title>Job Code Allocations</Title>
      </Panel.Header>
      <Panel.Body>
        {activityWarning && <WarningBlock>{activityWarning}</WarningBlock>}
        {activities.map((activity, index) => (
          <ActivityFields
            key={activity.key}
            index={index}
            activityInput={activity}
            jobCodes={jobCodes}
            handleRemove={removeActivity}
            handleUpdate={updateActivity}
          />
        ))}
        <Button
          disabled={shiftSplittingDisabled(
            { timestamp: started, facilityID: startedFacility },
            { timestamp: ended, facilityID: endedFacility },
          )}
          className="pull-right"
          onClick={() => appendActivity()}
          size="sm"
          kind="default"
        >
          Split Shift
        </Button>
      </Panel.Body>
      <Panel.Header>
        <Title>Meal Breaks</Title>
      </Panel.Header>
      <Panel.Body>
        {workBreaks.map((workBreak, index) => (
          <WorkBreakFields
            key={workBreak.key}
            index={index}
            workBreakInput={workBreak}
            tz={tz}
            removeWorkBreak={removeWorkBreak}
            updateWorkBreak={updateWorkBreak}
          />
        ))}
        <Button className="pull-right" onClick={() => appendWorkBreak()} size="sm" kind="default">
          Add Meal Break
        </Button>
        <Spacer height="4rem" />
        <Field id="notes" label="Notes">
          <textarea id="notes" className="form-control" name="notes" ref={register} />
        </Field>
      </Panel.Body>
    </Panel>
  );
};

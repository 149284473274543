import cn from 'classnames';
import * as React from 'react';

import { Claim__ResolutionKind } from '@admin/schema';

interface IToggleProps {
  kind?: Claim__ResolutionKind;
  onClick(kind: Claim__ResolutionKind): void;
}

export const Toggle = ({ kind, onClick }: IToggleProps) => (
  <div className="btn-group">
    <button
      type="button"
      onClick={() => onClick(Claim__ResolutionKind.Compensated)}
      className={cn('btn', { 'active btn-info': kind === Claim__ResolutionKind.Compensated })}
    >
      Valid
    </button>
    <button
      type="button"
      onClick={() => onClick(Claim__ResolutionKind.Denied)}
      className={cn('btn', { 'active btn-danger': kind === Claim__ResolutionKind.Denied })}
    >
      Not Valid
    </button>
  </div>
);

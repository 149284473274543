import * as React from 'react';

import { client } from '@admin/libraries/apollo';

import { Panel } from '@admin/components/helpers/panel';
import { Roles } from '@admin/components/helpers/roles';
import { Pagination } from '@admin/components/pagination';
import { Spinner } from '@admin/components/spinner';
import { Timestamp } from '@admin/components/timestamp';
import { UserRole } from '@admin/types';
import { AnchorButton, Table, Text } from '@shared/components/bootstrap';

import { LogisticsPOsForPanelQuery, useLogisticsPOsForPanelQuery } from '@admin/schema';
import { logisticsNewPurchaseOrderURL, logisticsEditPurchaseOrderURL } from '@admin/config/routes';

const POsTable: React.FC<{ data: LogisticsPOsForPanelQuery; accountID: string }> = ({ data, accountID }) => {
  if (!data.paginated.pagination.totalCount) {
    return <Text tag="p">This account does not have any 3PL Purchase Orders.</Text>;
  }

  return (
    <Table responsive striped>
      <thead>
        <tr>
          <th className="text-left col-md-3">Number</th>
          <th className="text-left col-md-3">Created At</th>
          <th className="text-right col-md-3" />
        </tr>
      </thead>
      <tbody>
        {data.paginated.results.map((po) => (
          <tr key={po.id}>
            <td className="text-left col-md-3">{po.number}</td>
            <td className="text-left col-md-3">
              <Timestamp value={po.createdAt} />
            </td>
            <td className="text-right col-md-3">
              <Roles show={[UserRole.Admin, UserRole.EnterpriseManager, UserRole.Manager]}>
                <a className="btn btn-primary" href={logisticsEditPurchaseOrderURL({ accountID, id: po.id })}>
                  Edit
                </a>
              </Roles>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export const LogisticsPurchaseOrdersPanel: React.FC<{ accountID: string }> = ({ accountID }) => {
  const [page, setPage] = React.useState<number | undefined>();
  const { data, loading } = useLogisticsPOsForPanelQuery({ client, variables: { page, accountID } });

  const pagination = data &&
    data.paginated && {
      __typename: 'Pagination' as const,
      currentPage: data.paginated.pagination.currentPage,
      nextPage: data.paginated.pagination.nextPage || undefined,
      prevPage: data.paginated.pagination.prevPage || undefined,
      totalPages: data.paginated.pagination.totalPages,
      totalCount: data.paginated.pagination.totalCount,
    };

  return (
    <Panel>
      <Panel.Header>
        <Panel.Title>Purchase Orders</Panel.Title>
      </Panel.Header>
      <Panel.Body>
        {loading && <Spinner />}
        {data && <POsTable data={data} accountID={accountID} />}
        {pagination && <Pagination pagination={pagination} onPage={setPage} />}
      </Panel.Body>
      <Roles show={[UserRole.Admin, UserRole.EnterpriseManager, UserRole.Manager]}>
        <Panel.Footer>
          <Text alignment="right" tag="div">
            <AnchorButton kind="primary" href={logisticsNewPurchaseOrderURL({ accountID })}>
              New Purchase Order
            </AnchorButton>
          </Text>
        </Panel.Footer>
      </Roles>
    </Panel>
  );
};

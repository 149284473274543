import styled from '@emotion/styled';
import * as React from 'react';
import { useForm } from 'react-hook-form';

import { Panel } from '@admin/components/helpers/panel';
import { Roles } from '@admin/components/helpers/roles';
import { Spinner } from '@admin/components/spinner';
import {
  Alert,
  AnchorButton as Link,
  Breadcrumb,
  Button,
  Checkbox,
  Radio,
  Table,
  Text,
} from '@shared/components/bootstrap';
import { Spacer } from '@shared/components/helpers';
import { UserRole } from '@admin/types';

import { Size } from '@admin/components/self_storage/classifications/size';
import { Currency } from '@shared/components/helpers';

import { accountURL } from '@admin/config/routes';

import {
  Status,
  SelfStorage__Status as SelfStorage__Rental__Status,
  useAccountWithSelfStorageRentalsQuery,
  useCancelSelfStorageRentalMutation,
} from '@admin/schema';
import { client } from '@admin/libraries/apollo';

import { SectionHeading } from '@admin/components/self_storage/components/section_heading';

interface IFormData {
  rentalID: string;
  kind: Kind;
  empty: boolean;
  unlocked: boolean;
  inactive: boolean;
  delinquency?: Delinquency;
}

const Error = styled.div`
  font-size: 12px;
`;

const HR = styled.hr`
  margin: 0px;
`;

const ErrorAlertWrapper = styled.div`
  margin-top: 24px;
  .alert {
    margin-bottom: 0px;
  }
`;

enum Delinquency {
  Permitted = 'PERMITTED',
  Denied = 'DENIED',
}

enum Kind {
  Transfer = 'TRANSFER',
  Cancel = 'CANCEL',
}

export const Cancel: React.FC<{ accountID: string }> = ({ accountID }) => {
  const [save, { loading, data: canelation }] = useCancelSelfStorageRentalMutation({ client });
  const { data } = useAccountWithSelfStorageRentalsQuery({ client, variables: { accountID } });
  const account = data?.account;
  const rentals = account?.rentals?.filter((rental) => rental.status === SelfStorage__Rental__Status.Active);

  const { errors, register, handleSubmit, setValue } = useForm<IFormData>();

  if (!account) {
    return <Spinner />;
  }

  if (!rentals?.length) {
    return <Alert style="info">This account does not have any self-storage rentals.</Alert>;
  }

  const onSubmit = async (form: IFormData) => {
    const response = await save({
      variables: {
        id: form.rentalID,
        prorate: form.kind === Kind.Transfer,
        skipDelinquentVerification: form.delinquency === Delinquency.Permitted,
      },
    });
    if (response && response.data?.result?.status === Status.Ok) {
      location.replace(accountURL(account));
    }
  };

  return (
    <>
      <div className="page-header">
        <Breadcrumb>
          <Breadcrumb.Item>
            <a href="/accounts">Accounts</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <a href={accountURL(account)}>
              #{account.number} ({account.customer.name})
            </a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Move Out</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Panel>
          <Panel.Title>
            <Text tag="h2">Move-Out</Text>
          </Panel.Title>
          <Panel.Body>
            <HR />
            <SectionHeading>Select a Unit</SectionHeading>
            <p>You can only move-out one unit at a time.</p>
            <Table responsive striped>
              <thead>
                <tr>
                  <th className="text-left col-zero" />
                  <th className="text-left">Facility</th>
                  <th className="text-left">Unit #</th>
                  <th className="text-left">Unit Details</th>
                  <th className="text-left">Floor</th>
                  <th className="text-left">Location</th>
                  <th className="text-right">Monthly Total</th>
                </tr>
              </thead>
              <tbody>
                {rentals?.map((rental) => (
                  <tr key={rental.id} onClick={() => setValue('rentalID', rental.id)}>
                    <td className="text-left col-zero">
                      <input
                        type="radio"
                        name="rentalID"
                        ref={register({ required: 'Please select a rental to cancel.' })}
                        value={rental.id}
                      />
                    </td>
                    <td className="text-left">{rental.unit.facility.name}</td>
                    <td className="text-left">{rental.unit.name}</td>
                    <td className="text-left">
                      <Size classification={rental.unit.classification} /> {rental.unit.classification.kind.name}
                    </td>
                    <td className="text-left">{rental.unit.floor}</td>
                    <td className="text-left">{rental.unit.classification.indoors ? 'Indoors' : 'Outdoors'}</td>
                    <td className="text-right">
                      <Currency value={rental.rate} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {errors.rentalID && <Text style="danger">{errors.rentalID.message}</Text>}
            <HR />
            <SectionHeading>Move-Out Type</SectionHeading>
            <p>
              For billing purposes, we need to know if this is a transfer or a move-out. Is the customer switching to a
              new unit instead or emptying a unit?
            </p>
            <Radio has={errors.kind ? 'error' : undefined}>
              <input
                name="kind"
                type="radio"
                ref={register({ required: 'Please select if this is a move out.' })}
                value={Kind.Cancel}
              />
              This customer is moving out of this unit.
              {errors.kind && <Error>{errors.kind.message}</Error>}
            </Radio>
            <Radio has={errors.kind ? 'error' : undefined}>
              <input
                name="kind"
                type="radio"
                ref={register({ required: 'Please select if this is a transfer to a new unit.' })}
                value={Kind.Transfer}
              />
              This customer is transferring from this unit to a new unit at Clutter.
              {errors.kind && <Error>{errors.kind.message}</Error>}
            </Radio>
            <HR />
            <SectionHeading>Move-Out Checklist</SectionHeading>
            <p>Have all of these been verified?</p>
            <Checkbox has={errors.empty ? 'error' : undefined}>
              <input name="empty" type="checkbox" ref={register({ required: 'Please confirm the unit is empty.' })} />
              Unit is empty
              {errors.empty && <Error>{errors.empty.message}</Error>}
            </Checkbox>
            <Checkbox has={errors.unlocked ? 'error' : undefined}>
              <input
                name="unlocked"
                type="checkbox"
                ref={register({ required: 'Please confirm the unit has no lock.' })}
              />
              Unit has no lock
              {errors.unlocked && <Error>{errors.unlocked.message}</Error>}
            </Checkbox>
            <Checkbox has={errors.inactive ? 'error' : undefined}>
              <input
                name="inactive"
                type="checkbox"
                ref={register({ required: 'Please confirm the gate code is deactivated.' })}
              />
              Customer gate code deactivated
              {errors.inactive && <Error>{errors.inactive.message}</Error>}
            </Checkbox>
            {account.delinquent && (
              <>
                <SectionHeading>
                  <Text style="danger">Delinquency Management</Text>
                </SectionHeading>
                <p>
                  <strong>{account.customer.name}</strong> has a balance of{' '}
                  <Text style="danger" tag="strong">
                    <Currency value={account.balance} />
                  </Text>
                  .
                </p>
                <Roles show={[UserRole.Admin]}>
                  <Radio has={errors.delinquency ? 'error' : undefined}>
                    <input
                      name="delinquency"
                      type="radio"
                      value={Delinquency.Denied}
                      ref={register({ required: 'Please confirm the customer delinquency status.' })}
                    />
                    This customer must pay their balance before moving out.
                    {errors.delinquency && <Error>{errors.delinquency.message}</Error>}
                  </Radio>
                  <Radio has={errors.delinquency ? 'error' : undefined}>
                    <input
                      name="delinquency"
                      type="radio"
                      value={Delinquency.Permitted}
                      ref={register({ required: 'Please confirm the customer delinquency status.' })}
                    />
                    This customer can be moved out now and pay later.
                    {errors.delinquency && <Error>{errors.delinquency.message}</Error>}
                  </Radio>
                </Roles>
              </>
            )}
            {canelation?.result?.error && (
              <ErrorAlertWrapper>
                <Alert style="danger">{canelation?.result?.error}</Alert>
              </ErrorAlertWrapper>
            )}
          </Panel.Body>
          <Panel.Footer>
            <Button loading={loading} kind="danger" type="submit">
              Confirm Move-Out
            </Button>
            <Spacer width="8px" inline />
            <Link disabled={loading} kind="default" href={accountURL(account)}>
              Cancel
            </Link>
          </Panel.Footer>
        </Panel>
      </form>
    </>
  );
};

import * as React from 'react';

import { newAccountMoveFollowUpURL } from '@admin/config/routes';

export const CreateFollowUp: React.FC<{
  accountID: string;
  orderID: string;
}> = ({ accountID, orderID }) => (
  <a href={newAccountMoveFollowUpURL({ accountID, orderID })} className="btn btn-primary">
    Manage Follow-Ups
  </a>
);

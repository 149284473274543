import React from 'react';
import { Formik, Form as FormikForm } from 'formik';

import {
  Status,
  useRegionCreateMutation,
  RegionCreate__Input,
  ClutterGeoEnum,
  IrmTerritoryEnum,
  IrmClusterEnum,
  RegionFulfillerEnum,
  useModifyRegionMutation,
  RegionQuery,
} from '@admin/schema';

import { client } from '@admin/libraries/apollo';
import { FormikInputFormGroup } from '@shared/components/fields/formik/formik_input_form_group';
import { Alert, Button, Panel, Modal } from '@shared/components/bootstrap';
import { TimeZone } from '@admin/constants/time_zone';
import { FormikAutosuggestFormGroup } from '../fields/formik/formik_autosuggest_form_group';
import { displayableText } from './utils';

const GEO_OPTIONS = Object.values(ClutterGeoEnum)
  .sort()
  .map((val) => ({
    label: displayableText(val),
    value: val,
  }));

const IRM_CLUSTER_OPTIONS = Object.values(IrmClusterEnum)
  .sort()
  .map((val) => ({
    label: displayableText(val),
    value: val,
  }));

const IRM_TERRITORY_OPTIONS = Object.values(IrmTerritoryEnum)
  .sort()
  .map((val) => ({
    label: displayableText(val),
    value: val,
  }));

const FULFILLER_OPTIONS = Object.values(RegionFulfillerEnum)
  .sort()
  .map((val) => ({
    label: displayableText(val),
    value: val,
  }));

const TIME_ZONE_OPTIONS = Object.values(TimeZone)
  .sort()
  .map((val) => ({
    label: val,
    value: val,
  }));

export const UpsertModal: React.FC<{
  region?: RegionQuery['region'];
  warehouseOptions: Array<{ id: string; name: string }>;
  onClose(regionID?: string): void;
}> = ({ region, warehouseOptions, onClose }) => {
  const [createRegion, { loading: creatingRegion, data }] = useRegionCreateMutation({
    client,
    onCompleted: ({ result }) => {
      if (result?.status === Status.Ok) onClose(result.region?.id);
    },
  });

  const [modifyRegion, { loading: modifyingRegion }] = useModifyRegionMutation({
    client,
    onCompleted: ({ modifyRegion: result }) => {
      if (result?.region) onClose(result.region?.id);
    },
  });

  const onSubmit = (input: RegionCreate__Input) => {
    if (!region) {
      createRegion({
        variables: {
          input,
        },
      });
    } else {
      modifyRegion({
        variables: {
          id: region.id,
          input: input,
        },
      });
    }
  };

  const WAREHOUSE_OPTIONS = warehouseOptions.map((option) => ({ value: option.id, label: option.name }));

  return (
    <Modal onClose={() => onClose()}>
      <Formik<RegionCreate__Input>
        initialValues={{
          defaultWarehouseID: region?.defaultWarehouse?.id ?? '',
          fulfiller: region?.fulfiller ?? RegionFulfillerEnum.Clutter,
          geo: region?.geo ?? ClutterGeoEnum.Socal,
          hexColor: region?.hexColor ?? '#00a499',
          irmCluster: region?.irmCluster ?? IrmClusterEnum.West,
          irmTerritory: region?.irmTerritory ?? IrmTerritoryEnum.California,
          name: region?.name ?? '',
          netsuiteRegionID: String(region?.netsuiteRegionID) ?? '',
          tz: region?.tz ?? TimeZone.LosAngeles,
        }}
        onSubmit={onSubmit}
      >
        <FormikForm>
          <Panel>
            <Panel.Body>
              {data?.result?.error && <Alert style="danger">{data.result.error}</Alert>}
              <FormikInputFormGroup label="Name" name="name" />
              <FormikAutosuggestFormGroup label="Geo" name="geo" options={GEO_OPTIONS} />
              <FormikAutosuggestFormGroup label="IRM Cluster" name="irmCluster" options={IRM_CLUSTER_OPTIONS} />
              <FormikAutosuggestFormGroup label="IRM Territory" name="irmTerritory" options={IRM_TERRITORY_OPTIONS} />
              <FormikAutosuggestFormGroup label="Fulfiller" name="fulfiller" options={FULFILLER_OPTIONS} />
              <FormikAutosuggestFormGroup label="Time Zone" name="tz" options={TIME_ZONE_OPTIONS} />
              <FormikAutosuggestFormGroup
                label="Default Warehouse"
                name="defaultWarehouseID"
                options={WAREHOUSE_OPTIONS}
              />
              <FormikInputFormGroup label="Netsuite Region ID" name="netsuiteRegionID" type="number" />
              <FormikInputFormGroup label="Hex Color" name="hexColor" />
            </Panel.Body>
            <Panel.Footer align="right">
              <Button kind="primary" onClick={() => onClose()}>
                Cancel
              </Button>
              <Button loading={creatingRegion || modifyingRegion} kind="primary" type="submit">
                {region ? 'Save' : 'Continue to Configuration'}
              </Button>
            </Panel.Footer>
          </Panel>
        </FormikForm>
      </Formik>
    </Modal>
  );
};

(() => {
  const app = angular.module('app');

  app.component('replyButton', {
    templateUrl: 'partials/components/reply_button.html',
    bindings: {
      disabled: '<',
      replyCallback: '&',
      replyToName: '<?',
    },
    controller: [
      '$element',
      'MessageThread',
      '$rootScope',
      'SmsConstants',
      function ($element, MessageThread, $rootScope, SmsConstants) {
        const SHOW_REPLY_MODAL = 'show-reply-modal';

        this.openModal = () => {
          $rootScope.$broadcast('showAside');
          $element.addClass(SHOW_REPLY_MODAL);
        };

        this.closeModal = () => {
          $element.removeClass(SHOW_REPLY_MODAL);
        };

        this.trySending = () => {
          if (this.messageBody) {
            this.closeModal();
            this.replyCallback({ body: this.messageBody });
          } else {
            this.errorVisible = true;
          }
        };

        this.hideErrorMessage = () => {
          this.errorVisible = false;
        };

        this.$onInit = () => {
          this.placeholder = SmsConstants.PLACEHOLDER;
          this.maxLength = SmsConstants.MAX_LENGTH;
        };
      },
    ],
  });
})();

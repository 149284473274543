import styled from '@emotion/styled';
import { currency } from '@shared/utils/currency';
import { capitalize, keyBy, omit, pickBy } from 'lodash';
import * as React from 'react';

import { Table, Text } from '@shared/components/bootstrap';
import { Currency, Spacer } from '@shared/components/helpers';
import { COLORS } from '@root/colors';
import {
  useSelfStorageFeesQuery,
  useSelfStorageCurrentFacilityFeesQuery,
  SelfStorage__Facility,
  SelfStorage__Fee__Kind,
  SelfStorage__FeeFragment,
  SelfStorage__RentalWithBillingFragment,
  SelfStorage__ProtectionFragment,
} from '@admin/schema';
import { client } from '@admin/libraries/apollo';
import { Size } from '../../classifications/size';
import { Selector as ProtectionSelector } from '../../protections/selector';
import { ElectricityCell } from './electricity_cell';
import { TD, Totals } from './helpers';
import { PriceCell } from './price_cell';
import { PromotionCell } from './promotion_cell';
import { UnitDetails } from './unit_details';

import { IElectricityEdits, IFacilityTotals, IPriceEdits, IPromotionEdits, IProtectionEdits } from '../types';

const Title = styled.h3`
  margin: 0px 0px 16px 0px;
`;

const TR = styled.tr`
  height: 68px;
`;

const HR = styled.hr`
  margin: 0px;
`;

const Label: React.FC<{ highlight: boolean }> = styled.label<{ highlight: boolean }>`
  cursor: pointer;
  margin: 0px;
  padding: 4px;
  display: inline-flex;
  align-items: center;
  ${({ highlight }) => highlight && `background-color: ${COLORS.orangeLightest};`}
`;

const feeName = (fee: SelfStorage__FeeFragment): string =>
  `${capitalize(fee.kind.replace('_', ' '))} (${currency(fee.price)}/mo)`;

const Fee: React.FC<{
  legacyFee?: SelfStorage__FeeFragment;
  activeFee: SelfStorage__FeeFragment;
  selectedId?: string;
  highlight: boolean;
  onChange(selectedFee?: SelfStorage__FeeFragment): void;
}> = ({ legacyFee, activeFee, selectedId, highlight, onChange }) => (
  <Label highlight={highlight}>
    <input
      type="checkbox"
      checked={!!selectedId}
      onChange={(e) => {
        onChange(e.target.checked ? legacyFee || activeFee : undefined);
      }}
    />
    <Spacer width="6px" inline />
    {legacyFee ? (
      <select
        className="form-control"
        value={selectedId}
        disabled={!selectedId}
        onChange={(e) => onChange(activeFee.id === e.target.value ? activeFee : legacyFee)}
      >
        <option value={legacyFee.id}>{feeName(legacyFee)} - Legacy</option>
        <option value={activeFee.id}>{feeName(activeFee)}</option>
      </select>
    ) : (
      feeName(activeFee)
    )}
  </Label>
);

export const Facility: React.FC<{
  accountID: string;
  facility: Pick<SelfStorage__Facility, 'id' | 'name'>;
  rentals: SelfStorage__RentalWithBillingFragment[];
  totals: IFacilityTotals;
  priceEdits: IPriceEdits;
  setPriceEdits: (edits: IPriceEdits) => void;
  protectionEdits: IProtectionEdits;
  setProtectionEdits: (edits: IProtectionEdits) => void;
  promotionEdits: IPromotionEdits;
  setPromotionEdits: (edits: IPromotionEdits) => void;
  electricityEdits: IElectricityEdits;
  setElectricityEdits: (edits: IElectricityEdits) => void;
  feeEdits: Map<SelfStorage__Fee__Kind, SelfStorage__FeeFragment | null>;
  setFeeEdits: (edits: Map<SelfStorage__Fee__Kind, SelfStorage__FeeFragment | null>) => void;
  isLastFacility?: boolean;
}> = ({
  accountID,
  facility,
  rentals,
  totals,
  priceEdits,
  setPriceEdits,
  protectionEdits,
  setProtectionEdits,
  promotionEdits,
  setPromotionEdits,
  electricityEdits,
  setElectricityEdits,
  feeEdits,
  setFeeEdits,
  isLastFacility,
}) => {
  const { data: feesData } = useSelfStorageFeesQuery({ client, variables: { facilityID: facility.id } });
  const { data: currentFeesData } = useSelfStorageCurrentFacilityFeesQuery({ client, variables: { accountID } });
  const electricityFee = feesData?.fees.find((fee) => fee.kind === SelfStorage__Fee__Kind.Electricity);
  const facilityFees = feesData?.fees.filter((fee) => fee.recurring && fee.kind !== SelfStorage__Fee__Kind.Electricity);
  const currentFacilityFees = keyBy(
    currentFeesData?.account?.fees.filter((fee) => fee.facilityID === facility.id),
    'kind',
  );
  const legacyFees = keyBy(
    pickBy(currentFacilityFees, (currentFee) => !facilityFees?.find((fee) => fee.id === currentFee.id)),
    'kind',
  );

  return (
    <div>
      <Title>{facility.name}</Title>
      <Table striped responsive>
        <thead>
          <tr>
            <th className="text-left">Unit</th>
            <th className="text-left">Unit Type</th>
            <th className="text-left">Unit Size</th>
            <th className="text-left">Details</th>
            <th className="text-left">Price</th>
            <th className="text-left">Protection</th>
            <th className="text-left">Promotion</th>
            <th className="text-left">Electricity</th>
            <th className="text-right">Total</th>
          </tr>
        </thead>
        <tbody>
          {rentals?.map((rental) => {
            const { price } = totals.rentals[rental.id];
            const protection = protectionEdits.hasOwnProperty(rental.id)
              ? protectionEdits[rental.id]
              : rental.protection;
            const hasElectricityFee = !!rental?.fees.find((fee) => fee.kind === SelfStorage__Fee__Kind.Electricity);

            return (
              <TR key={rental.id}>
                <td className="text-left">{rental.unit.name}</td>
                <td className="text-left">{rental.unit.classification.kind.name}</td>
                <td className="text-left">
                  <Size classification={rental.unit.classification} />
                </td>
                <td className="text-left">
                  <UnitDetails unit={rental.unit} />
                </td>
                <PriceCell
                  value={priceEdits[rental.id] ?? rental.unitRate}
                  onChange={(e) => {
                    const newPriceEdits =
                      e.target.value === String(rental.unitRate)
                        ? omit(priceEdits, rental.id)
                        : { ...priceEdits, [rental.id]: e.target.value };

                    setPriceEdits(newPriceEdits);
                  }}
                  highlight={price !== rental.unitRate}
                />
                <TD className="text-left" highlight={protection?.id !== rental.protection?.id}>
                  <ProtectionSelector
                    currentProtection={rental.protection}
                    facilityID={facility.id}
                    selection={protection}
                    onSelect={(newProtection: SelfStorage__ProtectionFragment) => {
                      const newProtectionEdits =
                        newProtection?.id === rental.protection?.id
                          ? omit(protectionEdits, rental.id)
                          : { ...protectionEdits, [rental.id]: newProtection };

                      setProtectionEdits(newProtectionEdits);
                    }}
                  />
                </TD>
                <PromotionCell
                  promotion={rental.promotion}
                  highlight={promotionEdits[rental.id]}
                  onChange={(e) => {
                    const newPromotionEdits = e.target.checked
                      ? omit(promotionEdits, rental.id)
                      : { ...promotionEdits, [rental.id]: true };

                    setPromotionEdits(newPromotionEdits);
                  }}
                />
                <ElectricityCell
                  fee={rental.unit.classification.electricity ? electricityFee : undefined}
                  checked={
                    !!electricityEdits[rental.id] || (hasElectricityFee && !electricityEdits.hasOwnProperty(rental.id))
                  }
                  highlight={!!electricityEdits.hasOwnProperty(rental.id)}
                  onChange={(e) => {
                    const newElectricityEdits =
                      e.target.checked === hasElectricityFee
                        ? omit(electricityEdits, rental.id)
                        : { ...electricityEdits, [rental.id]: hasElectricityFee ? undefined : electricityFee };

                    setElectricityEdits(newElectricityEdits);
                  }}
                />
                <td className="text-right">
                  <Currency value={totals.rentals[rental.id].total} />
                </td>
              </TR>
            );
          })}
        </tbody>
      </Table>
      <Text tag="h4">Fees</Text>
      {facilityFees?.map((fee) => {
        const highlight = feeEdits.has(fee.kind);
        const onFeeChange = (selection?: SelfStorage__FeeFragment) => {
          const replacementFeeEdits = new Map(feeEdits);
          if (currentFacilityFees[fee.kind]?.id === selection?.id) {
            replacementFeeEdits.delete(fee.kind);
          } else {
            replacementFeeEdits.set(fee.kind, selection || null);
          }
          setFeeEdits(replacementFeeEdits);
        };
        const legacyFee = legacyFees[fee.kind];
        const selectedFee = feeEdits.has(fee.kind) ? feeEdits.get(fee.kind) : currentFacilityFees[fee.kind];
        return (
          <div key={fee.id}>
            <Fee
              activeFee={fee}
              legacyFee={legacyFee}
              onChange={onFeeChange}
              selectedId={selectedFee?.id}
              highlight={highlight}
            />
          </div>
        );
      })}
      <Spacer height="12px" />
      <HR />
      <Totals>
        <tbody>
          {typeof totals.oldTotal === 'number' && (
            <tr>
              <td>Original Subtotal:</td>
              <td>
                <Currency value={totals.oldTotal} />
              </td>
            </tr>
          )}
          <tr>
            <td>Subtotal:</td>
            <td>
              <Currency value={totals.total} />
            </td>
          </tr>
        </tbody>
      </Totals>
      {!isLastFacility && <Spacer height="24px" />}
    </div>
  );
};

import * as React from 'react';

import { SelfStorage__FacilityFragment } from '@admin/schema';

export const Table: React.FC<{
  facilities: SelfStorage__FacilityFragment[];
}> = ({ facilities }) => (
  <div className="table-responsive">
    <table className="table table-striped">
      <thead>
        <tr>
          <th className="text-left">Name</th>
          <th className="text-left">Address</th>
          <th className="text-left">Region</th>
          <th className="text-left">Status</th>
        </tr>
      </thead>
      <tbody>
        {facilities.map((facility) => (
          <tr key={facility.id}>
            <td className="text-left">{facility.name}</td>
            <td className="text-left">
              {facility.address ? (
                <>
                  <div>{facility.address.street}</div>
                  <div>
                    {facility.address.city}, {facility.address.state} {facility.address.zip}
                  </div>
                </>
              ) : (
                '-'
              )}
            </td>
            <td className="text-left">{facility.region ? facility.region.name : '-'}</td>
            <td className="text-left">{facility.active ? 'Active' : 'Inactive'}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

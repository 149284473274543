import * as React from 'react';
import { Button } from '@shared/components/bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { useEffect } from 'react';
import { useLatestCallback } from '@shared/hooks/use_latest';

type RefreshProps = {
  intervalSeconds: number;
  refetch(): Promise<any>;
};

export const Refresh = ({ refetch, intervalSeconds }: RefreshProps) => {
  const refresh = useLatestCallback(async () => {
    await refetch();
  });

  useEffect(() => {
    const refreshInterval = setInterval(() => {
      refresh();
    }, intervalSeconds * 1000);
    return () => clearInterval(refreshInterval);
  }, [intervalSeconds, refresh]);

  return (
    <div className="text-right">
      <p style={{ fontStyle: 'italic' }}>Refreshed every {intervalSeconds} seconds</p>
      <Button kind="default" onClick={refresh}>
        Refresh <FontAwesomeIcon icon={faSync} />
      </Button>
    </div>
  );
};

(function () {
  const app = angular.module('app');

  app.component('messageMetadata', {
    templateUrl: 'partials/messages/message_metadata.html',
    controller: 'MessageMetadataController as message_metadata_controller',
    bindings: {
      message: '<',
    },
  });
})();

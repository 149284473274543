import * as React from 'react';

import { client } from '@admin/libraries/apollo';

import { useBillingInvoiceItemKindsQuery } from '@admin/schema';

export const Selector: React.FC<{
  name: string;
  accountID: string;
  kind?: string;
  onChange(kind?: string): void;
}> = ({ name, accountID, kind, onChange }) => {
  const { data } = useBillingInvoiceItemKindsQuery({ variables: { accountID }, client });
  const options = data?.billing?.options || [];

  return (
    <select
      name={name}
      className="form-control"
      onChange={(event) => onChange(event.target.value || undefined)}
      value={kind || ''}
    >
      <option disabled={!!kind} value="">
        {' '}
        - Kind -{' '}
      </option>
      {options.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
};

import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Spacer } from '@shared/components/helpers';
import { Button } from '@shared/components/bootstrap';
import { Field } from './field';
import { StyledRow } from './question_field';
import {
  updateStringArrayItemByIndex,
  transformBooleanArrayToStringArray,
  toggleBooleanArrayByIndex,
  removeArrayItemByIndex,
} from '../util';

export const AnswerChoiceField: React.FC<{
  questionIndex: number;
  answer: string;
  answerIndex: number;
  answerChoices: string[];
  setAnswerChoices: React.Dispatch<React.SetStateAction<string[]>>;
  defectAnswers: boolean[];
  setDefectAnswers: React.Dispatch<React.SetStateAction<boolean[]>>;
  failedAnswers: boolean[];
  setFailedAnswers: React.Dispatch<React.SetStateAction<boolean[]>>;
}> = ({
  questionIndex,
  answer,
  answerIndex,
  answerChoices,
  setAnswerChoices,
  defectAnswers,
  setDefectAnswers,
  failedAnswers,
  setFailedAnswers,
}) => {
  const { setValue } = useFormContext();

  const onModifyAnswerChoice = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newAnswerChoices = updateStringArrayItemByIndex(answerChoices, event.target.value, answerIndex);
    setAnswerChoices(newAnswerChoices);
    setValue(`questions[${questionIndex}].answerChoices`, newAnswerChoices);
    setValue(
      `questions[${questionIndex}].defectAnswers`,
      transformBooleanArrayToStringArray(newAnswerChoices, defectAnswers),
    );
    setValue(
      `questions[${questionIndex}].failedAnswers`,
      transformBooleanArrayToStringArray(newAnswerChoices, failedAnswers),
    );
  };

  const onModifyDefectAnswer = () => {
    const newDefectAnswers = toggleBooleanArrayByIndex(defectAnswers, answerIndex);
    setDefectAnswers(newDefectAnswers);
    setValue(
      `questions[${questionIndex}].defectAnswers`,
      transformBooleanArrayToStringArray(answerChoices, newDefectAnswers),
    );
  };

  const onModifyFailedAnswer = () => {
    const newFailedAnswers = toggleBooleanArrayByIndex(failedAnswers, answerIndex);
    setFailedAnswers(newFailedAnswers);
    setValue(
      `questions[${questionIndex}].failedAnswers`,
      transformBooleanArrayToStringArray(answerChoices, newFailedAnswers),
    );
  };

  const onDeleteAnswerChoice = () => {
    const newAnswerChoices = removeArrayItemByIndex(answerChoices, answerIndex);
    const newDefectAnswers = removeArrayItemByIndex(defectAnswers, answerIndex);
    const newFailedAnswers = removeArrayItemByIndex(failedAnswers, answerIndex);
    setAnswerChoices(newAnswerChoices);
    setDefectAnswers(newDefectAnswers);
    setFailedAnswers(newFailedAnswers);
    setValue(`questions[${questionIndex}].answerChoices`, newAnswerChoices);
    setValue(
      `questions[${questionIndex}].defectAnswers`,
      transformBooleanArrayToStringArray(newAnswerChoices, newDefectAnswers),
    );
    setValue(
      `questions[${questionIndex}].failedAnswers`,
      transformBooleanArrayToStringArray(newAnswerChoices, newFailedAnswers),
    );
  };

  return (
    <>
      <StyledRow className="row">
        <div className="col-md-9">
          <Field id={`questions[${questionIndex}].answerChoices[${answerIndex}]`} label="">
            <input value={answer} className="form-control" type="string" required onChange={onModifyAnswerChoice} />
          </Field>
        </div>
        <div className="col-md-1">
          <Field id={`questions[${questionIndex}].answerChoices[${answerIndex}].defect`} label="Defect">
            <Spacer width="6px" inline />
            <input type="checkbox" checked={defectAnswers[answerIndex]} onChange={onModifyDefectAnswer} />
          </Field>
        </div>
        <div className="col-md-1">
          <Field id={`questions[${questionIndex}].answerChoices[${answerIndex}].failed`} label="Failed">
            <Spacer width="6px" inline />
            <input type="checkbox" checked={failedAnswers[answerIndex]} onChange={onModifyFailedAnswer} />
          </Field>
        </div>
        <div className="col-md-1">
          <Button kind="danger" onClick={onDeleteAnswerChoice}>
            Delete
          </Button>
        </div>
      </StyledRow>
    </>
  );
};

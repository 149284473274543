import { client } from '@admin/libraries/apollo';
import { Maybe, useAddressesLazyQuery } from '@admin/schema';
import { useAddressAutocompleteService } from '@shared/hooks/use_address_autocomplete_service';
import * as React from 'react';
import AsyncSelect from 'react-select/async';
import { matchSorter } from 'match-sorter';
import { parseGooglePlaceResult } from '@admin/utils/google_maps';

export type InputFormControlType = HTMLInputElement;
export type InputFormControlProps = React.InputHTMLAttributes<HTMLInputElement>;

export type ClientAddress = {
  id: string | null;
  street: string;
  city: string;
  zip: string;
  state: string;
  latitude?: Maybe<number>;
  longitude?: Maybe<number>;
};

type Option = {
  value: string | null;
  label: string;
  address?: ClientAddress;
  suggestion?: google.maps.places.AutocompletePrediction;
};

function formatAddress(address: ClientAddress) {
  return `${address.street} ${address.city}, ${address.state} ${address.zip}`;
}

function useAddressLoader(accountID: string) {
  const [getAccountAddresses] = useAddressesLazyQuery({ client });
  const service = useAddressAutocompleteService();

  const loadAccountAddresses = async (inputValue: string) => {
    const { data } = await getAccountAddresses({ variables: { accountID } });
    if (!data) throw new Error('Unable to fetch addresses for account ' + accountID);
    return matchSorter(
      data.addresses.map((v) => ({ value: v.id, label: formatAddress(v), address: v })),
      inputValue,
      { keys: ['label'] },
    );
  };

  const loadAutocompleteSuggestions = async (inputValue: string) =>
    new Promise<Option[]>((resolve, reject) => {
      if (!inputValue) {
        resolve([]);
      } else {
        service.getSuggestions(
          inputValue,
          (suggestions) => {
            if (!suggestions) reject();
            else {
              resolve(
                suggestions.map((s) => ({
                  value: s.id,
                  label: s.description,
                  suggestion: s,
                })),
              );
            }
          },
          { componentRestrictions: undefined },
        );
      }
    });

  return async (inputValue: string) => {
    const [accountAddresses, newAddresses] = await Promise.all([
      loadAccountAddresses(inputValue),
      loadAutocompleteSuggestions(inputValue),
    ]);

    return [
      { label: 'Existing Addresses', options: accountAddresses },
      { label: 'New ClientAddress Suggestions', options: newAddresses },
    ];
  };
}

/** Find an existing address for an account or return required details to create a new address */
export const AddressFinderFormControl: React.FC<{
  name?: string;
  id?: string;
  accountID: string;
  value: ClientAddress | undefined;
  onChange: (value: ClientAddress | undefined) => void;
  onInvalidPlace?: (value: google.maps.places.PlaceResult) => void;
}> = ({ accountID, value, onChange, onInvalidPlace, name, id }) => {
  const [placesService] = React.useState(() => new google.maps.places.PlacesService(document.createElement('div')));
  const loadOptions = useAddressLoader(accountID);
  const handleSelect = (option: Option | null) => {
    if (option?.address) {
      onChange(option.address);
    } else if (option?.suggestion) {
      placesService.getDetails({ placeId: option.suggestion.place_id }, (result) => {
        const { street, latitude, longitude, state, city, zip } = parseGooglePlaceResult(result) || {};
        if (street && city && state && zip) {
          onChange({ id: null, street, city, state, zip, latitude, longitude });
        } else {
          onInvalidPlace?.(result);
        }
      });
    }
  };

  return (
    <AsyncSelect<Option>
      classNamePrefix="Select"
      name={name}
      id={id}
      defaultOptions
      isClearable
      value={value ? { value: value.id, label: value.street, address: value } : undefined}
      onChange={handleSelect}
      loadOptions={loadOptions}
    />
  );
};

import angular from 'angular';
import { head } from 'lodash';

const app = angular.module('app');

const FORMAT_TYPE_ZIP = 'zip';
const FORMAT_TYPE_NUMBER = 'number';
const FORMAT_TYPE_BANK_ACCOUNT_NUMBER = 'bank-account-number';
const FORMAT_TYPE_BANK_ROUTING_NUMBER = 'bank-routing-number';
const FORMAT_TYPE_TITLE_CASE = 'title-case';
const FORMAT_TYPE_SENTENCE_CASE = 'sentence-case';
const FORMAT_TYPE_DAY_MONTH = 'day-or-month';

const MAX_LENGTHS = {
  [FORMAT_TYPE_ZIP]: 5,
  [FORMAT_TYPE_BANK_ACCOUNT_NUMBER]: 17,
  [FORMAT_TYPE_BANK_ROUTING_NUMBER]: 9,
  [FORMAT_TYPE_DAY_MONTH]: 2,
};

const SPECIAL_NUMERIC_KEYS = [
  69, // "e"
  107, // "+"
  109, // "."
  110, // ","
  187, // "="
  188, // ","
  189, // "-"
  190, // "."
];

const toDigit = (text) => text.replace(/\D/g, '');
const toTitleCase = (text) => text.replace(/\w\S*/g, (str) => str.charAt(0).toUpperCase() + str.substr(1));
const upperLast = (text) => text.replace(/.$/, (endChar) => endChar.toUpperCase());
// Makes the first letter OR first letter after ?, !, ., or \n uppercase, and then replaces
// patterns that we don't want auto-capitalized (such as urls).
const toSentenceCase = (text) =>
  text
    .replace(/(^\s{0,}\w|(\.\s{1,}|\?\s{1,}|!\s{1,}|\n\s{0,})\w)/g, (str) => upperLast(str))
    .replace(/(http|www)/gi, (str) => str.toLowerCase());

app.directive('inputFormat', [
  () => ({
    restrict: 'A',
    link: (scope, element, attrs) => {
      let maxLength = MAX_LENGTHS[attrs.formatType];
      if (element.get(0).type === FORMAT_TYPE_NUMBER) {
        element.on('keydown', (e) => {
          // prevent special numeric key strokes
          if (SPECIAL_NUMERIC_KEYS.includes(e.keyCode)) {
            e.preventDefault();
          }
        });
        maxLength = attrs.maxlength;
      } else if (maxLength) {
        element.attr('maxlength', String(maxLength));
      }

      const formatInput = (formatType) => {
        let text = element.val();

        if (formatType) {
          const { selectionStart, selectionEnd } = head(element);
          switch (formatType) {
            case FORMAT_TYPE_ZIP:
            case FORMAT_TYPE_NUMBER:
            case FORMAT_TYPE_DAY_MONTH:
            case FORMAT_TYPE_BANK_ACCOUNT_NUMBER:
            case FORMAT_TYPE_BANK_ROUTING_NUMBER:
              text = toDigit(text);
              break;
            case FORMAT_TYPE_TITLE_CASE:
              text = toTitleCase(text);
              break;
            case FORMAT_TYPE_SENTENCE_CASE:
              text = toSentenceCase(text);
              break;
            default:
              break;
          }
          element.val(text);
          head(element).setSelectionRange(selectionStart, selectionEnd);
        } else if (maxLength) {
          element.val(text.substr(0, maxLength));
        }
      };

      element.on('input', () => {
        formatInput(attrs.formatType);
      });
    },
  }),
]);

import { logisticsDetailedLoadURL } from '@admin/config/routes';
import { Roles } from '@admin/components/helpers/roles';
import { UserRole } from '@admin/types';
import React from 'react';
import { LogisticsLoadForm } from './form/form';

export const LogisticsNewLoad: React.FC<{}> = () => {
  const onSubmit = async (id: string) => {
    window.location.href = logisticsDetailedLoadURL({ id });
  };

  return (
    <Roles show={[UserRole.Admin, UserRole.EnterpriseManager, UserRole.Manager]}>
      <LogisticsLoadForm load={undefined} editMode={false} onSave={onSubmit} />
    </Roles>
  );
};

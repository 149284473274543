import template from '@admin/angular/templates/estimated_item_list.html';

(() => {
  const app = angular.module('app');

  app.component('estimatedItemList', {
    template,
    bindings: {
      entries: '<',
    },
    controller: [
      function () {
        this.text = (entry) => {
          let text = `${entry.quantity} × ${entry.category_display_name}`;
          if (entry.dimensions_overwritten) {
            text += ` (${entry.length}" x ${entry.width}" x ${entry.height}")`;
          }
          if (entry.notes && entry.notes.length) {
            text += `: ${entry.notes}`;
          }
          return text;
        };

        this.totalCuft = () => _.sumBy(this.entries, (entry) => parseFloat(entry.cuft) * entry.quantity || 0);
      },
    ],
  });
})();

import * as React from 'react';

import { lowerCase, startCase } from 'lodash';
import { DateTime } from 'luxon';
import { Roles } from '@admin/components/helpers/roles';
import { TeachInWarning } from '@admin/components/logistics/skus/teach_in_warning';
import { Timestamp } from '@admin/components/timestamp';
import { UserRole } from '@admin/types';
import { logisticsDetailedLoadURL, logisticsEditLoadURL } from '@admin/config/routes';
import { PaginatedLoadQuery } from '@admin/schema';
import { Table } from '@shared/components/bootstrap';

const buttonPadding = { margin: 1 };

type LoadType = PaginatedLoadQuery['paginated']['results'][number];

const formatDate = (date: string) => DateTime.fromISO(date).toLocaleString(DateTime.DATE_MED);

const ExpectationsSummary: React.FC<{ load: LoadType }> = ({ load }) => {
  const customerExpectations = new Map<
    string,
    { customerName: string; totalQuantities: number; skuValues: string[]; requiresTeachIn: boolean }
  >();
  load.fulfillmentExpectations.forEach((fe) => {
    const customerName = fe.sku.account.customer.name;
    const accountID = fe.sku.account.id;
    const customerAggregate = customerExpectations.get(accountID);
    if (customerAggregate) {
      customerAggregate.totalQuantities += fe.quantity;
      customerAggregate.skuValues.push(fe.sku.value);
    } else {
      customerExpectations.set(accountID, {
        customerName: customerName,
        totalQuantities: fe.quantity,
        skuValues: [fe.sku.value],
        requiresTeachIn: load.requiresTeachIn,
      });
    }
  });

  return (
    <>
      {Array.from(customerExpectations.keys()).map((accountID) => (
        <span key={accountID}>
          <strong>
            {customerExpectations.get(accountID)?.customerName}: {customerExpectations.get(accountID)?.totalQuantities}{' '}
            items | {customerExpectations.get(accountID)?.skuValues.length} SKUs
            {customerExpectations.get(accountID)?.requiresTeachIn && (
              <>
                {' '}
                <TeachInWarning />
              </>
            )}
          </strong>
          <br />
          <small>{customerExpectations.get(accountID)?.skuValues.join(', ')}</small>
          <br />
        </span>
      ))}
    </>
  );
};

export const LoadsTable: React.FC<{
  loads: LoadType[];
  dateHeader: string;
}> = ({ loads, dateHeader }) => (
  <Table striped responsive>
    <thead>
      <tr className="load-list__row">
        <th className="col-sm-1 text-left">Type</th>
        <th className="col-sm-1 text-left">Load Number</th>
        <th className="col-sm-1 text-left">State</th>
        <th className="col-sm-1 text-left">Warehouse</th>
        <th className="col-sm-2 text-left">{dateHeader}</th>
        <th className="col-sm-3 text-left">Expectations</th>
        <th className="col-sm-1 text-left">Carrier Name</th>
        <th className="col-sm-2 text-left" />
      </tr>
    </thead>
    <tbody>
      <>
        {loads.map((load) => (
          <tr className="load-list__row" key={load.id}>
            <td className="col-sm-1 text-left">{load.type}</td>
            <td className="col-sm-1 text-left">{load.number}</td>
            <td className="col-sm-1 text-left" style={{ textTransform: 'capitalize' }}>
              {load.state}
            </td>
            <td className="col-sm-1 text-left">{load.warehouse.name}</td>
            <td className="col-sm-2 text-left">
              {load.appointmentDate ? (
                <Timestamp value={load.appointmentDate} timezone={load.warehouse.tz} />
              ) : (
                <span>
                  <strong>Request:</strong> {formatDate(load.requestedAppointmentDate)} -{' '}
                  {startCase(lowerCase(load.requestedAppointmentWindow))}
                </span>
              )}
            </td>
            <td className="col-sm-3 text-left" style={{ whiteSpace: 'pre-wrap' }}>
              <ExpectationsSummary load={load} />
            </td>
            <td className="col-sm-1 text-left">{load.carrierDetails?.businessName}</td>
            <td className="col-sm-2 text-right">
              <a style={buttonPadding} className="btn btn-primary" href={logisticsDetailedLoadURL({ id: load.id })}>
                View
              </a>
              <Roles show={[UserRole.Admin, UserRole.EnterpriseManager, UserRole.Manager]}>
                <a style={buttonPadding} className="btn btn-primary" href={logisticsEditLoadURL({ id: load.id })}>
                  Edit
                </a>
              </Roles>
            </td>
          </tr>
        ))}
      </>
    </tbody>
  </Table>
);

import * as React from 'react';

interface IProps {
  onClick: () => void;
  disabled?: boolean;
}

export const CheckAvailabilityButton: React.FunctionComponent<IProps> = ({ onClick, disabled }) => (
  <button
    className="btn btn-success"
    onClick={(e) => {
      onClick();
      e.preventDefault();
    }}
    style={{ width: '170px', height: '40px', margin: '0 auto' }}
    disabled={disabled}
  >
    Check Availability
  </button>
);

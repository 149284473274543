(function () {
  angular.module('app').filter('addressOther', function () {
    return function (address) {
      const options = [];

      if (address.steep) {
        options.push('Steep Driveway or Road');
      }

      if (address.service_entrance) {
        options.push('Service entrance');
      }

      if (address.gated) {
        options.push('Gated Community');
      }

      if (address.loading_dock) {
        options.push('Loading Dock');
      }

      if (address.code) {
        let str = 'Code';
        if (address.code_value) {
          str += ': ' + address.code_value;
        }
        options.push(str);
      }

      return options;
    };
  });
})();

(() => {
  const app = angular.module('app');

  app.controller('ItemsCategorizablesIndexController', [
    '$scope',
    '$stateParams',
    '$state',
    'Account',
    'Item',
    'ItemsCategorizablesModalService',
    function ($scope, $stateParams, $state, Account, Item, ItemsCategorizablesModalService) {
      $scope.selectedItems = [];
      $scope.account_id = $stateParams.account_id;
      $scope.account = Account.get({ id: $scope.account_id });
      $scope.items = Item.groupables({ account_id: $scope.account_id });

      $scope.confirm = () => {
        ItemsCategorizablesModalService.present($scope.selectedItems, () =>
          $state.go('inventory', { account_id: $scope.account_id }),
        );
      };
    },
  ]);
})();

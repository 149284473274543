import angular from 'angular';

const app = angular.module('app');

app.constant('PusherConstants', {
  PUSHER_CONNECT: 'pusher_connect',

  PUSHER_SUBSCRIPTION_SUCCEEDED: 'pusher:subscription_succeeded',
  PUSHER_SUBSCRIPTION_ERROR: 'pusher:subscription_error',

  SMS_INFO_CHANNEL_NAME: 'sms-info',
  SMS_PENDING_COUNT: 'sms-info-pending-count',
  BROADCAST_SMS_PENDING_COUNT_EVENT: 'pusher:pending:count',
});

(function () {
  angular
    .module('app')
    .controller('CallGroupListController', [
      '$scope',
      '$state',
      'ngDialog',
      'CallGroup',
      function ($scope, $state, ngDialog, CallGroup) {
        $scope.callGroups = CallGroup.query();

        $scope.openDeleteModal = function (callGroup) {
          return ngDialog.openConfirm({
            template: 'partials/pop_ups/confirm_box.html',
            className: 'ngdialog-theme-default',
            controller: [
              '$scope',
              function ($scope) {
                $scope.message = 'Are you sure you want to delete ' + callGroup.name + '?';
              },
            ],
          });
        };

        $scope.deleteCallGroup = function (callGroup) {
          $scope.openDeleteModal(callGroup).then(function () {
            callGroup.$delete(function () {
              $state.go(
                'callGroups',
                {},
                {
                  reload: true,
                },
              );
            });
          });
        };
      },
    ])
    .controller('CallGroupViewController', [
      '$scope',
      '$stateParams',
      'CallGroup',
      function ($scope, $stateParams, CallGroup) {
        $scope.callGroup = CallGroup.get({
          id: $stateParams.id,
        });
      },
    ])
    .controller('CallGroupCreateController', [
      '$rootScope',
      '$scope',
      '$state',
      'CallGroup',
      function ($rootScope, $scope, $state, CallGroup) {
        $scope.callGroup = new CallGroup();

        $scope.addCallGroup = function () {
          $scope.callGroup.$save(
            function () {
              $state.go('callGroups');
            },
            function (error) {
              $rootScope.$broadcast('message:group:display', error.data);
            },
          );
        };
      },
    ])
    .controller('CallGroupEditController', [
      '$rootScope',
      '$scope',
      '$state',
      '$stateParams',
      'CallGroup',
      function ($rootScope, $scope, $state, $stateParams, CallGroup) {
        $scope.updateCallGroup = function () {
          $scope.callGroup.$update(
            function () {
              $state.go('callGroups');
            },
            function (error) {
              $rootScope.$broadcast('message:group:display', error.data);
            },
          );
        };

        $scope.loadCallGroup = function () {
          $scope.callGroup = CallGroup.get({
            id: $stateParams.id,
          });
        };

        $scope.loadCallGroup();
      },
    ]);
})();

import * as React from 'react';

import { client } from '@admin/libraries/apollo';
import { usePalletGradesQuery } from '@admin/schema';

import { Panel } from '@admin/components/helpers/panel';

import { PalletGradeImages } from './pallet_grade_images';

export const PalletImagesPanel: React.FC<{ palletID: string; timezone: string }> = ({ palletID, timezone }) => {
  const { data } = usePalletGradesQuery({
    client,
    variables: { palletID },
  });

  return (
    <Panel>
      <Panel.Header>
        <Panel.Title>Pallet Grade Images</Panel.Title>
      </Panel.Header>
      <Panel.Body>
        {data?.palletGrades && <PalletGradeImages palletGrades={data.palletGrades} timezone={timezone} />}
      </Panel.Body>
    </Panel>
  );
};

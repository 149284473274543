(function () {
  angular.module('app').filter('unique', [
    function () {
      return function (values, field) {
        return _.uniq(
          _.map(values, function (value) {
            return value[field];
          }),
        );
      };
    },
  ]);
})();

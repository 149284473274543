import React from 'react';
import { Formik, FormikErrors, Form as FormikForm } from 'formik';

import {
  Status,
  AttendancePolicyViolation__Kind,
  useAttendancePolicyViolationCreateMutation,
  AttendancePolicyViolation__Create__Input,
} from '@admin/schema';

import { client } from '@admin/libraries/apollo';
import { Alert, Button, Panel, Modal } from '@shared/components/bootstrap';
import { FormikSelectFormGroup } from '@shared/components/fields/formik/formik_select_form_group';
import { FormikDateTimePicker } from '../fields/formik/formik_date_time_picker';
import { FormikTextAreaFormGroup } from '../fields/formik/formik_text_area_form_group';
import { FormikDatePickerFormGroup } from '../fields/formik/formik_date_picker_form_group';

export const CreateEndedEarlyViolationModal: React.FC<{
  userOptions: Array<{ id: string; name: string }>;
  closeModal(): void;
}> = ({ userOptions, closeModal }) => {
  const [save, { loading, data }] = useAttendancePolicyViolationCreateMutation({
    client,
    onCompleted: ({ result }) => {
      if (result?.status === Status.Ok) closeModal();
    },
  });

  const validate = (formValues: AttendancePolicyViolation__Create__Input) => {
    const formErrors: FormikErrors<AttendancePolicyViolation__Create__Input> = {};

    if (!formValues.userID) {
      formErrors.userID = 'Please select a user';
    }

    if (!formValues.shiftDate) {
      formErrors.shiftDate = 'Please select a shift date';
    }

    if (!formValues.expectedTime) {
      formErrors.expectedTime = 'Please select a time';
    }

    if (!formValues.actualTime) {
      formErrors.actualTime = 'Please select a time';
    }

    if (!formValues.note) {
      formErrors.note = 'Please add a note';
    }

    return formErrors;
  };

  const onSubmit = (input: AttendancePolicyViolation__Create__Input) => {
    save({
      variables: {
        input,
      },
    });
  };

  return (
    <Modal onClose={closeModal}>
      <Formik<AttendancePolicyViolation__Create__Input>
        initialValues={{
          actualTime: '',
          confirmedNoCall: false,
          expectedTime: '',
          kind: AttendancePolicyViolation__Kind.EndedEarly,
          note: undefined,
          shiftDate: '',
          userID: '',
        }}
        onSubmit={onSubmit}
        validate={validate}
      >
        {({ isValid }) => (
          <FormikForm>
            <Panel>
              <Panel.Header>
                <Panel.Title>Add Shift Ended Early Violation</Panel.Title>
              </Panel.Header>
              <Panel.Body>
                {data?.result?.error && <Alert style="danger">{data.result.error}</Alert>}
                <FormikSelectFormGroup id="userID" name="userID" label="User">
                  <option value="">- None Selected -</option>
                  {userOptions.map((user) => (
                    <option key={user.id} value={`${user.id} - ${user.name}`}>
                      {user.name}
                    </option>
                  ))}
                </FormikSelectFormGroup>
                <FormikDatePickerFormGroup id="shiftDate" name="shiftDate" label="Shift Start Date" />
                <FormikDateTimePicker id="expectedTime" name="expectedTime" label="Expected Shift End Time" />
                <FormikDateTimePicker id="actualTime" name="actualTime" label="Actual Shift End Time" />
                <FormikTextAreaFormGroup id="note" name="note" label="Note" />
              </Panel.Body>
              <Panel.Footer align="right">
                <Button kind="primary" onClick={closeModal}>
                  Cancel
                </Button>
                <Button loading={loading} kind="primary" type="submit" disabled={!isValid}>
                  Add Violation
                </Button>
              </Panel.Footer>
            </Panel>
          </FormikForm>
        )}
      </Formik>
    </Modal>
  );
};

import { client } from '@admin/libraries/apollo';
import * as React from 'react';

import { UserFiltersInput, useUsersSelectorQuery } from '@admin/schema';

import { MultiselectFormControl } from '@admin/components/fields';

export const Selector: React.FC<
  {
    selectedIDs: string[];
    onChange(selectedIDs: string[]): void;
  } & UserFiltersInput
> = ({ onChange, selectedIDs, ...filters }) => {
  const { data } = useUsersSelectorQuery({ client, variables: { filters } });
  const users = (data && data.users) || [];

  return <MultiselectFormControl placeholder="Users" options={users} selectedIDs={selectedIDs} onChange={onChange} />;
};

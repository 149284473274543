import React from 'react';
import { ControlLabel, FormGroup, Col } from '@shared/components/bootstrap';
import { OrderSubtypeEnum } from '@admin/schema';
import { ORDER_SUBTYPE_MAP, orderSubtypeMapKeyForValue } from '@admin/utils/order_type_map';
import { OPTIONS } from '../fields/formik/formik_moving_subtype_form_group';

export enum Styling {
  EditOrderForm,
  CreateFollowUpForm,
  EditFollowUpForm,
}

export const MovingSubtypeFormGroup: React.FC<{
  selection: keyof typeof ORDER_SUBTYPE_MAP;
  onSelect(selection: keyof typeof ORDER_SUBTYPE_MAP): void;
  styling?: Styling;
}> = ({ selection, onSelect, styling = Styling.EditOrderForm }) => {
  const onChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = orderSubtypeMapKeyForValue(event.target.value as OrderSubtypeEnum) ?? selection;
    onSelect(selectedValue);
  };

  const selectionEnum = ORDER_SUBTYPE_MAP[selection];

  return (
    <FormGroup>
      <ControlLabel className={styling === Styling.EditOrderForm ? 'col-sm-2' : undefined} htmlFor="subtype_select">
        Moving Subtype
      </ControlLabel>
      <Col sm={styling === Styling.EditOrderForm ? 4 : undefined}>
        <select id="subtype_select" className="form-control" value={selectionEnum} onChange={onChange}>
          {OPTIONS.map((option) => (
            <option value={option.subtype} key={option.subtype}>
              {option.displayName}
            </option>
          ))}
        </select>
      </Col>
    </FormGroup>
  );
};

import { get } from 'lodash';
import * as React from 'react';
import { ErrorMessage, useFormContext } from 'react-hook-form';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ControlLabel, FormGroup, HelpBlock, Text } from '@shared/components/bootstrap';

export const ValidationIcon: React.FC<{ icon: IconProp }> = ({ icon }) => (
  <span className="form-control-feedback" aria-hidden="true">
    <FontAwesomeIcon icon={icon} />
  </span>
);

export const Field: React.FC<{
  id: string; // i.e. 'billing-name'
  name: string; // i.e. 'billing.name'
  label?: string;
  required?: boolean;
}> = ({ id, name, label, required, children }) => {
  const { errors, formState } = useFormContext();
  const error = get(errors, name);
  const submitted = formState.isSubmitted;

  return (
    <FormGroup has={error ? 'error' : submitted ? 'success' : undefined} hasFeedback={submitted}>
      {label !== undefined && (
        <ControlLabel htmlFor={id}>
          {label} {required && <Text style="danger">*</Text>}
        </ControlLabel>
      )}
      {children}
      {submitted && <ValidationIcon icon={error ? faTimes : faCheck} />}
      <ErrorMessage name={name}>{({ message }) => <HelpBlock>{message}</HelpBlock>}</ErrorMessage>
    </FormGroup>
  );
};

import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from '@emotion/styled';
import React, { useState } from 'react';

import { Button, HelpBlock, Text, Progress } from '@shared/components/bootstrap';
import { Picture } from '@shared/components/helpers';

import { useLegacyAttachmentUpload } from '@shared/hooks';

import { client } from '@admin/libraries/apollo';
import { Image, Maybe, useAvatarDeleteMutation, useAvatarUploadMutation, User } from '@admin/schema';

import { Roles } from '@admin/components/helpers/roles';

import { UserRole } from '@admin/types';

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 4px;
`;

const Preview = styled(Picture)`
  width: 100%;
  height: auto;
`;

const Placeholder = styled.img`
  width: 100%;
  height: auto;
  background: #f9f9f9;
`;

const Buttons = styled.fieldset`
  justify-content: center;
  display: flex;
  gap: 4px;
`;

const Input = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;

const Upload = styled.div`
  width: 100%;
`;

const Uploader: React.FC<{
  file: File;
  onError(error: string | Error): void;
  onUpload(signedID: string): void;
}> = ({ onError, onUpload, file }) => {
  const { loaded, total } = useLegacyAttachmentUpload({
    file,
    onError,
    onUpload,
  });

  return (
    <Upload>
      <Progress>
        {loaded !== undefined && total !== undefined && <Progress.Bar bg="info" loaded={loaded} total={total} />}
      </Progress>
    </Upload>
  );
};

export const Avatar: React.FC<{
  user: Pick<User, 'id'> & { image?: Maybe<Pick<Image, 'id' | 'source'>> };
  actions?: boolean;
}> = ({ actions, user }) => {
  const [error, setError] = useState<Error | string | undefined>();
  const [file, setFile] = useState<File | undefined>();
  const [remove, { loading: removing }] = useAvatarDeleteMutation({ client });
  const [upload, { loading: uploading }] = useAvatarUploadMutation({ client });

  const processing = !!file || uploading || removing;

  const onUpload = async (url: string) => {
    if (uploading || removing) return;
    await upload({ variables: { userID: user.id, url } });
    setFile(undefined);
    setError(undefined);
  };

  const onRemove = async () => {
    if (uploading || removing) return;
    await remove({ variables: { userID: user.id } });
    setError(undefined);
    setFile(undefined);
  };

  const onError = (value: Error | string) => {
    setError(value);
    setFile(undefined);
  };

  const onSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (processing) return;
    const files = event.target.files;
    if (files) {
      const [value] = Array.from(files);
      setError(undefined);
      setFile(value);
    }
  };

  return (
    <Container>
      {user.image ? (
        <Preview source={user.image.source} width={480} height={480} />
      ) : (
        <Placeholder src="/missing.svg" />
      )}

      {actions && (
        <Roles show={[UserRole.Admin]}>
          <Buttons disabled={processing}>
            <label className="btn btn-primary">
              <Input onChange={onSelect} type="file" accept="image/*" hidden />
              {uploading && <FontAwesomeIcon icon={faSpinner} spin />} Choose a file
            </label>

            <Button disabled={!user.image} kind="danger" onClick={onRemove}>
              {removing && <FontAwesomeIcon icon={faSpinner} spin />} Remove Image
            </Button>
          </Buttons>

          <HelpBlock>Note: Pictures should be a square (i.e. 640 × 640)</HelpBlock>

          {error && (
            <Text tag="span" style="danger">
              {typeof error === 'string' ? error : error.message}
            </Text>
          )}

          {file && <Uploader file={file} onError={onError} onUpload={onUpload} />}
        </Roles>
      )}
    </Container>
  );
};

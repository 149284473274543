const app = angular.module('app');

app.controller('AccountsEditController', [
  '$rootScope',
  '$scope',
  '$state',
  'account',
  'coupons',
  'laborRates',
  'rateGroups',
  'storageTerms',
  'materialsPlans',
  'pricingSets',
  function (
    $rootScope,
    $scope,
    $state,
    account,
    coupons,
    laborRates,
    rateGroups,
    storageTerms,
    materialsPlans,
    pricingSets,
  ) {
    $scope.account = account;
    $scope.labor_rates = laborRates;
    $scope.rate_groups = rateGroups;
    $scope.storage_terms = storageTerms;
    $scope.materials_plans = materialsPlans;
    $scope.pricing_sets = [{ id: account.pricing_set_id, name: `Assigned:  ${account.pricing_set_id || 'none'}` }];

    pricingSets.forEach((pricingSet) => {
      $scope.pricing_sets.push({
        id: pricingSet.id,
        name: `Available:  ${pricingSet.id}  (${pricingSet.market_pricing_variant_label || 'default'})`,
      });
    });

    const currCoupon = _.find(coupons, (c) => c.id === account.coupon_id);
    const currCouponName = _.get(currCoupon, 'name');
    $scope.currDiscount = currCouponName ? { name: currCouponName } : null;
    $scope.onDiscountSelected = (discount) => {
      $scope.account.coupon_id = _.get(discount, 'coupon_id', '');
      $scope.account.promo_code = _.get(discount, 'promo_code', '');
    };

    $scope.updateAccount = function () {
      $scope.account.$update(
        () => {
          $state.go('viewAccount', { id: $scope.account.id });
        },
        (error) => {
          $rootScope.$broadcast('message:group:display', error.data);
        },
      );
    };

    document.querySelector('form').onkeypress = (e) => (e.keyCode || e.which || e.charCode || 0) !== 13;
  },
]);

import angular from 'angular';
import { map } from 'lodash';
import moment from 'moment';

const boundBetween = (max, data, min) => Math.min(min, Math.max(data, max));

const MIN_WIDTH = 0.03;

class ShiftEntryController {
  static interpolateForShift(when, tz, dayOffset, startHour = 5, endHour = 29) {
    const { hours, minutes } = moment.tz(when, tz).toObject();
    const hourFloat = hours + minutes / 60 + dayOffset * 24;
    const boundedHourFloat = boundBetween(startHour, hourFloat, endHour);
    const p = (boundedHourFloat - startHour) / (endHour - startHour);
    return boundBetween(0, p, 1);
  }

  constructor($filter) {
    this.percentageFilter = $filter('percentage');
  }

  style(started, ended, tz, basisDate = started) {
    // Note: normalize to 5:00 AM to 9:00 PM schedule
    const startDayOffset = moment
      .tz(started, tz)
      .startOf('date')
      .diff(moment.tz(basisDate, tz).startOf('date'), 'days');
    const endDayOffset = ended
      ? moment.tz(ended, tz).startOf('date').diff(moment.tz(basisDate, tz).startOf('date'), 'days')
      : 0;
    const startBounds = started ? ShiftEntryController.interpolateForShift(started, tz, startDayOffset) : 0;
    const endBounds = ended ? ShiftEntryController.interpolateForShift(ended, tz, endDayOffset) : 1;
    let left = startBounds;
    const width = Math.max(endBounds - startBounds, MIN_WIDTH);
    let right = endBounds;

    if (right > 0.5) {
      left = 'auto';
      right = this.percentageFilter(1 - right);
    } else {
      right = 'auto';
      left = this.percentageFilter(left);
    }

    const stylings = {
      left,
      width: this.percentageFilter(width),
      right,
    };
    return map(stylings, (value, key) => `${key}:${value};`).join(' ');
  }
}

angular.module('app').controller('ShiftEntryController', ['$filter', ($filter) => new ShiftEntryController($filter)]);

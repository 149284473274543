import * as React from 'react';

import { IInventorySection, IItem, InventorySectionMode } from '@admin/types';

import { SelectableBrick as ItemGroupSelectableBrick } from '@admin/components/item_groups/selectable_brick';
import { SelectableBrick as ItemSelectableBrick } from '@admin/components/items/selectable_brick';
import { Pallet } from './section/pallet';
import { Warehouse } from './section/warehouse';

const DEFAULT_NAME = 'Other';

const SectionHero: React.FC<{
  section: IInventorySection;
  selections: IItem[];
  onSelect(selections: IItem[]): void;
}> = ({ section, ...props }) => {
  switch (section.mode) {
    case InventorySectionMode.Warehouses:
      return section.warehouse ? <Warehouse {...props} warehouse={section.warehouse} /> : <>{DEFAULT_NAME}</>;
    case InventorySectionMode.Pallets:
      return section.pallet ? <Pallet {...props} pallet={section.pallet} /> : <>{DEFAULT_NAME}</>;
  }
};

export const Section: React.FC<{
  section: IInventorySection;
  selections: IItem[];
  onSelect(selections: IItem[]): void;
}> = ({ section, ...props }) => {
  const filter = (item: IItem) => {
    switch (section.mode) {
      case InventorySectionMode.Warehouses:
        return section.warehouse ? item.warehouse_id === section.warehouse.id : !item.warehouse_id;
      case InventorySectionMode.Pallets:
        return section.pallet ? item.pallet_id === section.pallet.id : !item.pallet_id;
    }
  };

  return (
    <>
      <SectionHero {...props} section={section} />

      <div className="grid">
        <div className="row">
          {section.resources.map((resource, key) => (
            <div key={key} className="entry col-xs-6 col-sm-4 col-md-3 col-lg-2">
              {resource.kind === 'item' && <ItemSelectableBrick {...props} selection={resource.object} />}
              {resource.kind === 'item-group' && (
                <ItemGroupSelectableBrick
                  {...props}
                  selection={resource.object}
                  children={(() => {
                    const items = resource.object.items.filter(filter);
                    return (
                      <>
                        ({items.length} / {resource.object.items_count} items)
                      </>
                    );
                  })()}
                />
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

import { Tags } from '@admin/components/helpers/tags';
import { client } from '@admin/libraries/apollo';
import { useOrderTagsQuery } from '@admin/schema';
import { COLORS } from '@root/colors';
import React from 'react';

const TAG_TO_COLOR: Record<string, string> = {
  'Customer Requested': COLORS.blue,
  'Customer Requested Decrease': COLORS.red,
  'Customer Requested Increase': COLORS.green,
};

export const OrderTags = ({ id }: { id?: string }) => {
  const { data } = useOrderTagsQuery({ client, variables: { id: id! }, skip: !id });

  if (!data) return null;

  return <Tags tags={data.order.tags.map((t) => ({ name: t, color: TAG_TO_COLOR[t] }))} />;
};

(function () {
  angular.module('app').factory('Caller', [
    '$resource',
    function ($resource) {
      return $resource(
        '/caller.json',
        {
          phone: '@phone',
        },
        {
          phone_call: {
            url: '/caller/phone_call.json',
            method: 'GET',
          },
        },
      );
    },
  ]);
})();

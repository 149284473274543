import * as React from 'react';

import { PricingGroupPricingEntryFragment } from '@admin/schema';

import { ProtectionRadios } from './protection_radios';
import { ProtectionTotal } from './protection_total';

export const ProtectionFormGroup: React.FC<{
  protection?: PricingGroupPricingEntryFragment;
  onSelect(protection?: PricingGroupPricingEntryFragment): void;
}> = ({ protection, onSelect }) => (
  <div className="form-group">
    <label className="control-label col-sm-2">Protection:</label>
    <div className="col-sm-5">
      <ProtectionRadios protection={protection} onSelect={onSelect} />
    </div>
    <div className="col-sm-5 form-total">
      <ProtectionTotal protection={protection} />
    </div>
  </div>
);

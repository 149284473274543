import * as React from 'react';

import { client } from '@admin/libraries/apollo';
import { useModifyRegionMutation, useRegionQuery } from '@admin/schema';

import { AnchorButton } from '@shared/components/bootstrap';
import { Spacer, Timestamp } from '@shared/components/helpers';

import { HistoriesPanel } from '@admin/components/histories/panel';
import { LabelledAttribute } from '@admin/components/helpers/labelled_attribute';
import { Map } from '@admin/components/helpers/map';
import { PageHeader } from '@admin/components/helpers/page_header';
import { Panel } from '@admin/components/helpers/panel';
import { jobAvailabilityListURL, regionEditURL, regionURL } from '@admin/config/routes';
import { History__Subject } from '@admin/schema';

import { ROLES } from '@admin/config/roles';
import { UserRole } from '@admin/types';
import { Box } from '@clutter/clean';
import { Breadcrumbs } from './breadcrumbs';

import { OperatingSchedule } from '../operating_schedule';
import { FeatureFlagsList } from '../feature_flags/list';
import { DepotsList } from '../depot/depot_list';
import { UpsertModal } from './upsert_modal';
import { displayableText } from './utils';

const timestamp = (time: string) => (time ? <Timestamp value={time} format={Timestamp.Format.Date} /> : 'N/A');

export const Details: React.FC<{
  id: string;
}> = ({ id }) => {
  const [showUpsertModal, setShowUpsertModal] = React.useState<boolean>(false);

  const { data } = useRegionQuery({ client, variables: { regionID: String(id) } });
  const region = data?.region;

  const [modifyRegion, { loading: modifyingRegion }] = useModifyRegionMutation({
    client,
  });

  if (!region) return null;

  const editAccess = ROLES.includes(UserRole.Admin);

  return (
    <>
      <PageHeader>
        <Breadcrumbs name={region.name} />
      </PageHeader>
      <h3>Operating Schedule</h3>
      <OperatingSchedule
        operatingScheduleTemplates={region.operatingScheduleTemplates}
        manageHref={jobAvailabilityListURL()}
      />
      <Spacer height="8px" />
      <h3>Feature Flags</h3>
      <FeatureFlagsList resourceName="Region" resourceID={region.id} editAccess={editAccess} />
      <Spacer height="8px" />
      <h3>Depots</h3>
      <DepotsList
        regionDetailsProperties={{
          regionID: region.id,
          defaultDepotID: region.defaultDepot?.id,
          modifyingRegion: modifyingRegion,
          onClickMakeDefault: (newDefaultDepotID) => {
            modifyRegion({
              variables: {
                id,
                input: {
                  defaultDepotID: newDefaultDepotID,
                },
              },
            });
          },
        }}
        showPanelHeading={false}
      />
      <Spacer height="8px" />
      <h3>Other Details</h3>
      <Panel>
        <Panel.Header>
          {editAccess && (
            <Box.Flex padding="16px" gap={'4px'} justifyContent={'right'}>
              <AnchorButton
                kind="danger"
                onClick={() => {
                  modifyRegion({
                    variables: {
                      id,
                      input: {
                        active: !region.active,
                      },
                    },
                  });
                }}
                disabled={modifyingRegion}
              >
                {region.active ? 'Deactivate Region' : 'Activate Region'}{' '}
              </AnchorButton>
              <AnchorButton kind="primary" onClick={() => setShowUpsertModal(true)}>
                Edit Details
              </AnchorButton>
              <AnchorButton kind="primary" href={regionEditURL({ id })}>
                Edit Zip Codes
              </AnchorButton>
            </Box.Flex>
          )}
        </Panel.Header>
        <Panel.Body>
          {region.parent && (
            <>
              <a href={regionURL({ id: region.parent.id })}>
                <LabelledAttribute label="Parent" attribute={region.parent.name} />
              </a>
              <Spacer height="8px" />
            </>
          )}
          <LabelledAttribute label="Active" attribute={region.active ? 'True' : 'False'} />
          <Spacer height="8px" />
          <LabelledAttribute label="IRM Cluster" attribute={displayableText(region.irmCluster)} />
          <Spacer height="8px" />
          <LabelledAttribute label="IRM Territory" attribute={displayableText(region.irmTerritory)} />
          <Spacer height="8px" />
          <LabelledAttribute label="Clutter Geo" attribute={displayableText(region.geo)} />
          <Spacer height="8px" />
          <LabelledAttribute label="Fulfiller" attribute={displayableText(region.fulfiller)} />
          <Spacer height="8px" />
          <LabelledAttribute label="Timezone" attribute={region.tz} />
          <Spacer height="8px" />
          <LabelledAttribute label="Default Warehouse" attribute={region.defaultWarehouse?.name} />
          <Spacer height="8px" />
          <LabelledAttribute label="Netsuite Region ID" attribute={`${region.netsuiteRegionID}`} />
          <Spacer height="8px" />
          <LabelledAttribute label="Hex Color" attribute={region.hexColor} />
          <Spacer height="8px" />
          <LabelledAttribute label="Created" attribute={timestamp(region.createdAt)} />
          <Spacer height="8px" />
          <LabelledAttribute label="Last Updated" attribute={timestamp(region.updatedAt)} />
          <Spacer height="32px" />
          <LabelledAttribute label="In Service Area Zip Codes" attribute={region.zipCodes.join(', ')} />
          <Spacer height="32px" />
          <LabelledAttribute
            label="Storage Extended Service Area Zip Codes"
            attribute={region.storageExtendedServiceAreaPostalCodes.join(', ')}
          />
          <Spacer height="32px" />
          <LabelledAttribute
            label="Moving Extended Service Area Zip Codes"
            attribute={region.movingExtendedServiceAreaPostalCodes.join(', ')}
          />
        </Panel.Body>
        {showUpsertModal && (
          <UpsertModal
            region={data.region}
            warehouseOptions={data.warehouses}
            onClose={() => {
              setShowUpsertModal(false);
            }}
          />
        )}
      </Panel>
      <Panel>
        <Panel.Header>
          <Panel.Title>Map</Panel.Title>
        </Panel.Header>
        <Panel.Body>
          <Map center={region.center} marker={region.center} />
        </Panel.Body>
      </Panel>
      <HistoriesPanel type={History__Subject.Region} id={id} />
    </>
  );
};

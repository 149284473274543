(function () {
  const app = angular.module('app');

  app.controller('PaymentsTableController', [
    '$scope',
    'Payments',
    'PaginationService',
    function ($scope, Payments, PaginationService) {
      const self = this;
      self.account_id = $scope.id;
      self.payments = [];
      self.sort = 'date';

      self.pagination = PaginationService.initialize();

      self.account_id = $scope.id;

      self.resort = function (sortColumn) {
        self.sort = sortColumn;
        self.getPayments();
      };

      self.getPayments = function () {
        Payments.get(
          {
            account_id: $scope.id,
            page: self.pagination.page,
            sort: self.sort,
          },
          function (response) {
            self.pagination.prepare(response.pagination);
            self.payments = response.results;
          },
        );
      };

      self.getPayments();
    },
  ]);
})();

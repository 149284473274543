import { client } from '@admin/libraries/apollo';
import { useTaskOrderContextQuery } from '@admin/schema';
import React, { useContext, useMemo } from 'react';

export const TaskOrderContext = React.createContext<
  undefined | { accountID: string; data: ReturnType<typeof useTaskOrderContextQuery>['data'] }
>(undefined);

export const useTaskOrderContext = () => {
  const value = useContext(TaskOrderContext);
  if (value === undefined) throw new Error('Missing TaskOrderContext provider');
  return value;
};

export const TaskOrderContextProvider = ({ accountID, children }: { accountID: string; children: React.ReactNode }) => {
  const { data } = useTaskOrderContextQuery({ client, variables: { accountID: accountID } });
  const value = useMemo(() => ({ data, accountID }), [data, accountID]);
  return <TaskOrderContext.Provider value={value}>{children}</TaskOrderContext.Provider>;
};

import { Panel } from '@admin/components/helpers/panel';
import { Roles } from '@admin/components/helpers/roles';
import { CreateTicket as CreateTechSupportTicket } from '@admin/components/tickets/tech_support/create_ticket';
import { Timestamp } from '@admin/components/timestamp';
import { TicketTicketableType, UserRole } from '@admin/types';
import {
  LoadTypeEnum,
  LogisticsLoadQuery,
  Maybe,
  Status,
  useCancelLogisticsLoadMutation,
  useReopenLogisticsLoadMutation,
  Logistics__PalletHeightPreferenceEnum,
  LogisticsLoadDocument,
  PaginatedLoadDocument,
} from '@admin/schema';
import { logisticsEditLoadURL } from '@admin/config/routes';
import styled from '@emotion/styled';
import { Alert, Button } from '@shared/components/bootstrap';
import { ConfirmationModal } from '@shared/components/modals/confirmation_modal';
import { Spacer } from '@shared/components/helpers';
import { capitalize, lowerCase, startCase } from 'lodash';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { client } from '@admin/libraries/apollo';
import { AddressInfo } from './address_info';

type LogisticsLoad = LogisticsLoadQuery['load'];

const BiggerText = styled.div`
  font-size: 16px;
`;

const displayPalletHeightPreference = (preference: Maybe<Logistics__PalletHeightPreferenceEnum> | undefined) => {
  if (preference === Logistics__PalletHeightPreferenceEnum.DoNotExceed) {
    return 'Do Not Exceed';
  } else if (preference === Logistics__PalletHeightPreferenceEnum.CloseAsPossible) {
    return 'Close as Possible';
  }
  return 'None';
};

export const LogisticsLoadBasicInfoPanel: React.FC<{ load: LogisticsLoad }> = ({ load }) => {
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [error, setError] = useState<string | null | undefined>(undefined);
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  const [executeCancel, { loading: loadingCancel }] = useCancelLogisticsLoadMutation({
    client,
    awaitRefetchQueries: true,
    refetchQueries: [{ query: LogisticsLoadDocument, variables: { id: load.id } }, { query: PaginatedLoadDocument }],
  });

  const [executeReopen, { loading: loadingReopen }] = useReopenLogisticsLoadMutation({
    client,
    awaitRefetchQueries: true,
    refetchQueries: [{ query: LogisticsLoadDocument, variables: { id: load.id } }, { query: PaginatedLoadDocument }],
  });

  const cancelLoad = async () => {
    const result = await executeCancel({
      variables: {
        input: {
          id: load.id,
        },
      },
    });

    if (
      result.data?.cancelLogisticsLoad.status === Status.Unprocessable ||
      result.data?.cancelLogisticsLoad.status === Status.Unauthorized
    ) {
      setError(result.data.cancelLogisticsLoad.error);
    } else {
      setError(undefined);
    }
  };

  const reOpenLoad = async () => {
    const result = await executeReopen({
      variables: {
        id: load.id,
      },
    });

    if (
      result.data?.reopenLogisticsLoad.status === Status.Unprocessable ||
      result.data?.reopenLogisticsLoad.status === Status.Unauthorized
    ) {
      setError(result.data.reopenLogisticsLoad.error);
    } else {
      setError(undefined);
    }
  };

  return (
    <Panel>
      {error && <Alert style="danger">{error}</Alert>}
      <Panel.Header>
        <Panel.Collapse collapsed={collapsed} onChange={setCollapsed} />
        <Panel.Title>
          Load {load.number} Details
          <Roles show={[UserRole.Admin, UserRole.Manager, UserRole.EnterpriseManager]}>
            <span style={{ float: 'right' }}>
              <CreateTechSupportTicket
                ticketable={{
                  id: load.id,
                  type: TicketTicketableType.LogisticsLoad,
                  displayName: 'Load #' + load.number,
                }}
              />
            </span>
          </Roles>
        </Panel.Title>
      </Panel.Header>
      <Panel.Body>
        {!collapsed && (
          <>
            <div className="row">
              <div className="col-md-6">
                <h3>Basic Info</h3>
                <ul className="list-unstyled">
                  <li>
                    <strong>Number:</strong>
                    {` ${load.number}`}
                  </li>
                  <li>
                    <strong>Type:</strong>
                    {` ${load.type}`}
                  </li>
                  <li>
                    <strong>State:</strong>
                    {` ${capitalize(load.state)}`}
                  </li>
                  <li>
                    <strong>Source:</strong>
                    {` ${startCase(lowerCase(load.source))}`}
                  </li>
                  <li>
                    <strong>Warehouse:</strong>
                    {` ${load.warehouse.name}`}
                  </li>
                  <li>
                    <strong>Bill of Lading Number:</strong>
                    {` ${load.billOfLadingNumber || ''}`}
                  </li>
                  <li>
                    <strong>Handling Method:</strong>
                    {` ${startCase(load.handlingMethod || '')}`}
                  </li>
                  <li>
                    <strong>Expected Orders:</strong>
                    {` ${load.expectedOrders}`}
                  </li>
                  <li>
                    <strong>Expected Items:</strong>
                    {` ${load.expectedItems}`}
                  </li>
                  <li>
                    <strong>Expected Pallets:</strong>
                    {` ${load.expectedPallets}`}
                  </li>
                  <li>
                    <strong>Target Pallet Height:</strong>
                    {(load.targetPalletHeight && ` ${load.targetPalletHeight} inches`) || ' None'}
                  </li>
                  <li>
                    <strong>Pallet Height Preference:</strong>
                    {` ${displayPalletHeightPreference(load.palletHeightPreference)}`}
                  </li>
                  <li>
                    <strong>Special Instructions:</strong>
                    {` ${load.specialInstructions || ''}`}
                  </li>
                </ul>
              </div>
              <div className="col-md-6">
                <h3>Carrier Info</h3>
                <ul className="list-unstyled">
                  <li>
                    <strong>Mode:</strong>
                    {` ${load.mode}`}
                  </li>
                  <li>
                    <strong>Trailer Number:</strong>
                    {` ${load.trailerNumber || ''}`}
                  </li>
                  <li>
                    <strong>Freight Charge Terms:</strong>
                    {` ${startCase(load.freightChargeTerms)}`}
                  </li>
                  <li>
                    <strong>Drop Trailer/Container?</strong> {load.drop ? 'Yes' : 'No'}
                  </li>
                  <li>
                    <strong>Carrier Name:</strong>
                    {` ${load.carrierDetails?.businessName || ''}`}
                  </li>
                  {load.carrierDetails && (
                    <>
                      {load.carrierDetails.transportationArrangedBy && (
                        <li>
                          <strong>Transportation Arranged By:</strong>
                          {` ${startCase(lowerCase(load.carrierDetails.transportationArrangedBy))}`}
                        </li>
                      )}
                      {load.carrierDetails.contactName && (
                        <li>
                          <strong>Contact Name:</strong>
                          {` ${load.carrierDetails.contactName}`}
                        </li>
                      )}
                      {load.carrierDetails.contactEmail && (
                        <li>
                          <strong>Contact Email:</strong>
                          {` ${load.carrierDetails.contactEmail}`}
                        </li>
                      )}
                      {load.carrierDetails.contactPhone && (
                        <li>
                          <strong>Contact Phone:</strong>
                          {` ${load.carrierDetails.contactPhone}`}
                        </li>
                      )}
                      {load.carrierDetails.scac && (
                        <li>
                          <strong>SCAC:</strong>
                          {` ${load.carrierDetails.scac}`}
                        </li>
                      )}
                    </>
                  )}
                </ul>
              </div>
            </div>
            <div className="row">
              {load.appointmentDate ? (
                <div className="col-md-6">
                  <h3>Appointment Date</h3>
                  <BiggerText>
                    <Timestamp value={load.appointmentDate} timezone={load.warehouse.tz} />
                  </BiggerText>
                </div>
              ) : (
                <div className="col-md-6">
                  <h3>Requested Appointment Date</h3>
                  <BiggerText>
                    {DateTime.fromISO(load.requestedAppointmentDate).toLocaleString(DateTime.DATE_MED)} -{' '}
                    {startCase(lowerCase(load.requestedAppointmentWindow))}
                  </BiggerText>
                </div>
              )}
              <div className="col-md-6">
                <h3>Reference Info</h3>
                <ul className="list-unstyled">
                  <li>
                    <strong>Release Number:</strong>
                    {` ${load.releaseNumber || ''}`}
                  </li>
                  {load.reference1 && (
                    <li>
                      <strong>Reference 1:</strong>
                      {` ${load.reference1}`}
                    </li>
                  )}
                  {load.reference2 && (
                    <li>
                      <strong>Reference 2:</strong>
                      {` ${load.reference2}`}
                    </li>
                  )}
                  {load.reference3 && (
                    <li>
                      <strong>Reference 3:</strong>
                      {` ${load.reference3}`}
                    </li>
                  )}
                  {load.reference4 && (
                    <li>
                      <strong>Reference 4:</strong>
                      {` ${load.reference4}`}
                    </li>
                  )}
                  {load.reference5 && (
                    <li>
                      <strong>Reference 5:</strong>
                      {` ${load.reference5}`}
                    </li>
                  )}
                </ul>
              </div>
            </div>
            {load.type === LoadTypeEnum.Outbound && (
              <div className="row">
                {load.shipTo && (
                  <div className="col-md-6">
                    <AddressInfo kind="Ship To" address={load.shipTo} />
                  </div>
                )}
                {load.billTo && (
                  <div className="col-md-6">
                    <AddressInfo kind="Bill To" address={load.billTo} />
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </Panel.Body>
      <Panel.Footer align="right">
        <Roles show={[UserRole.Admin, UserRole.EnterpriseManager]}>
          {load.state === 'completed' && load.type === LoadTypeEnum.Inbound && (
            <Button kind="primary" type="submit" onClick={reOpenLoad} loading={loadingReopen}>
              Reopen
            </Button>
          )}
          <Spacer width="8px" />
          <Button
            kind="primary"
            type="submit"
            onClick={() => setModalVisible(true)}
            loading={loadingCancel}
            disabled={load.state === 'canceled'}
          >
            Cancel
          </Button>
          <Spacer width="8px" />
        </Roles>
        <Roles show={[UserRole.Admin, UserRole.EnterpriseManager, UserRole.Manager]}>
          <Button
            kind="primary"
            type="submit"
            onClick={() => (window.location.href = logisticsEditLoadURL({ id: load.id }))}
          >
            Edit
          </Button>
        </Roles>
      </Panel.Footer>
      {modalVisible && (
        <ConfirmationModal
          title="Are you sure you want to cancel the load?"
          acceptButton="Yes, cancel this load"
          declineButton="No, do not cancel this load"
          onConfirm={() => {
            cancelLoad();
            setModalVisible(false);
          }}
          onClose={() => setModalVisible(false)}
        />
      )}
    </Panel>
  );
};

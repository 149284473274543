import angular from 'angular';

const app = angular.module('app');

app.directive('qa', () => {
  const qaDirective = {
    compile: (element) => {
      const qaMetaTag = document.querySelector('meta[name="automate-qa"]');
      const availableForAutomation = qaMetaTag.getAttribute('content');
      if (availableForAutomation === 'false') {
        element.css('display', 'none');
      }
    },
  };
  return qaDirective;
});

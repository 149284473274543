(function () {
  angular.module('app').factory('Dispatch', [
    '$resource',
    'TransformBuilder',
    'DispatchSerializer',
    'DispatchDeserializer',
    function ($resource, TransformBuilder, serializer, deserializer) {
      return $resource(
        '/dispatches/:id.json',
        {
          id: '@id',
        },
        {
          get: {
            method: 'GET',
            transformResponse: TransformBuilder.transformResponse(deserializer),
          },
          create: {
            method: 'POST',
            transformRequest: TransformBuilder.transformRequest(serializer),
            transformResponse: TransformBuilder.transformResponse(deserializer),
          },
          update: {
            method: 'PATCH',
            transformRequest: TransformBuilder.transformRequest(serializer),
            transformResponse: TransformBuilder.transformResponse(deserializer),
          },
        },
      );
    },
  ]);
})();

import * as React from 'react';
import { accountURL, logisticsDetailedLoadURL } from '@admin/config/routes';
import { Spinner } from '@admin/components/spinner';
import { Timestamp } from '@admin/components/timestamp';
import { client } from '@admin/libraries/apollo';
import { Panel } from '@admin/components/helpers/panel';
import { useLogisticsPalletInfoQuery, LogisticsPalletInfoQuery } from '@admin/schema';
import styled from '@emotion/styled';
import { COLORS } from '@root/colors';
import moment from 'moment-timezone';

const Link = styled.a`
  color: ${COLORS.blueLight};
`;

type PalletInventoryLoadsType = LogisticsPalletInfoQuery['pallet']['loads'];
type PalletInventoriesType = LogisticsPalletInfoQuery['pallet']['palletInventories'];

const LogisticsPalletLoadsPanel: React.FC<{ loads: PalletInventoryLoadsType }> = ({ loads }) => (
  <Panel>
    <Panel.Header>
      <Panel.Title>Associated 3PL Loads</Panel.Title>
    </Panel.Header>
    <Panel.Body>
      <div>
        {loads &&
          loads
            .sort((a, b) => moment(b.appointmentDate!).diff(moment(a.appointmentDate!)))
            .map((load) => (
              <div key={load.id} className="col-xs-8 col-md-6 col-lg-4">
                <ul>
                  <li>
                    <strong>{load.type} Load Number:</strong>
                    <Link href={logisticsDetailedLoadURL({ id: load.id })} target="_blank">
                      {' '}
                      {load.number}
                    </Link>
                  </li>
                  <li>
                    <strong>Appointment Date:</strong>{' '}
                    {load.appointmentDate ? (
                      <Timestamp value={load.appointmentDate} timezone={load.warehouse.tz} />
                    ) : (
                      'Not set'
                    )}
                  </li>
                </ul>
              </div>
            ))}
      </div>
    </Panel.Body>
  </Panel>
);

const LogisticsPalletInventoryPanel: React.FC<{ palletInventories: PalletInventoriesType }> = ({
  palletInventories,
}) => (
  <Panel>
    <Panel.Header>
      <Panel.Title>3PL Inventory</Panel.Title>
    </Panel.Header>
    <Panel.Body>
      <div>
        {palletInventories
          .filter((palletInventory) => palletInventory.quantity > 0)
          .map((palletInventory) => (
            <div key={palletInventory.sku.id} className="col-xs-6 col-md-4 col-lg-2">
              <ul>
                <li>
                  <strong>Account:</strong>
                  <Link href={accountURL({ id: palletInventory.sku.account.id })}>
                    {' '}
                    {palletInventory.sku.account.customer.name}
                  </Link>
                </li>
                <li>
                  <strong>SKU:</strong> {palletInventory.sku.value}
                </li>
                <li>
                  <strong>SKU Description:</strong> {palletInventory.sku.description}
                </li>
                <li>
                  <strong>Quantity:</strong> {palletInventory.quantity}
                </li>
              </ul>
            </div>
          ))}
      </div>
    </Panel.Body>
  </Panel>
);

export const LogisticsPalletInfoPanels: React.FC<{ id: string }> = ({ id }) => {
  const { data, loading } = useLogisticsPalletInfoQuery({ client, variables: { id } });

  if (loading || !data) {
    return <Spinner />;
  }

  const palletInventories = data.pallet.palletInventories;
  const loads = data.pallet.loads;

  return (
    <>
      <LogisticsPalletInventoryPanel palletInventories={palletInventories} />
      <LogisticsPalletLoadsPanel loads={loads} />
    </>
  );
};

import React, { useState } from 'react';

import {
  Coupon,
  Coupon__Subject,
  Maybe,
  Order,
  OrderServiceTypeEnum,
  OrderStatusEnum,
  useCouponsLazyQuery,
  useOrderCouponUpdateMutation,
} from '@admin/schema';

import { Roles } from '@admin/components/helpers/roles';
import { Button, Modal, Spacing, Text } from '@shared/components/bootstrap';
import { UserRole } from '@admin/types';
import { client } from '@admin/libraries/apollo';

import AsyncSelect from 'react-select/async';
import { parseGQLErrorUnion } from '@shared/utils/gql_errors';

function couponToOption(coupon: Pick<Coupon, 'id' | 'name' | 'code'>) {
  return {
    value: coupon.id,
    label: `${coupon.name} (${coupon.code})`,
  };
}

export const CouponToggle: React.FC<{
  order: Pick<Order, 'id' | 'status' | 'serviceType'> & {
    coupon?: Maybe<Pick<Coupon, 'id' | 'code' | 'discountDescription' | 'name'>>;
  };
}> = ({ order: { coupon, id, status, serviceType } }) => {
  const [editing, setEditing] = useState(false);
  const [updateCoupon, { data, loading, error, reset }] = useOrderCouponUpdateMutation({ client });
  const [fetchCoupons] = useCouponsLazyQuery({ client });
  const [newCoupon, setNewCoupon] = useState(coupon ? couponToOption(coupon) : null);

  const onClose = () => setEditing(false);

  const loadOptions = async (inputValue: string) => {
    const { data: couponData } = await fetchCoupons({
      variables: { filters: { query: inputValue, active: true, subject: Coupon__Subject.Order } },
    });
    return couponData?.result.map((c) => ({ value: c.id, label: `${c.name} (${c.code})` })) || [];
  };

  if (error) throw error;

  const onSave = async () => {
    const result = await updateCoupon({
      variables: { couponID: newCoupon?.value, orderID: id },
    });
    if (result.data?.orderCouponUpdate?.result.__typename === 'Order') {
      onClose();
    }
  };

  const [_, mutationResultError] = parseGQLErrorUnion(data?.orderCouponUpdate?.result);

  const canEditCoupon =
    status !== OrderStatusEnum.Completed &&
    status !== OrderStatusEnum.Canceled &&
    serviceType !== OrderServiceTypeEnum.Shipment;

  return (
    <>
      <span>
        {coupon ? (
          <>
            {coupon.name} ({coupon.code}, {coupon.discountDescription})
          </>
        ) : (
          'None'
        )}
        {canEditCoupon && (
          <Roles show={[UserRole.Admin, UserRole.BillingAdmin, UserRole.Billing, UserRole.L1Agent, UserRole.L2Agent]}>
            {' '}
            <Button
              kind="primary"
              onClick={() => {
                setEditing(true);
              }}
            >
              Edit
            </Button>
          </Roles>
        )}
      </span>
      {editing && (
        <Modal onClose={onClose}>
          <Modal.Content>
            <Modal.Header>
              <Modal.Title>Edit Coupon</Modal.Title>
              <Modal.Close close={onClose} />
            </Modal.Header>
            <Modal.Body>
              <AsyncSelect
                defaultOptions
                isClearable
                value={newCoupon}
                onChange={(value) => {
                  reset();
                  setNewCoupon(value);
                }}
                loadOptions={loadOptions}
              />
              {mutationResultError && (
                <Spacing mt={4}>
                  <Text tag="p" style="danger">
                    {mutationResultError.errorMessage}
                  </Text>
                </Spacing>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button kind="default" onClick={onClose}>
                Cancel
              </Button>
              <Button kind="primary" onClick={onSave} loading={loading} disabled={!!mutationResultError}>
                Save
              </Button>
            </Modal.Footer>
          </Modal.Content>
        </Modal>
      )}
    </>
  );
};

import { NO_SLA_WINDOW_SHORT } from '@shared/utils/all_day_sla';

const app = angular.module('app');

app.controller('OrdersShowController', [
  '$state',
  '$scope',
  '$stateParams',
  'Order',
  'Account',
  'Page',
  'Dispatch',
  'AccountService',
  function ($state, $scope, $stateParams, Order, Account, Page, Dispatch, AccountService) {
    $scope.id = $stateParams.id;
    $scope.account_id = $stateParams.account_id;
    $scope.changeAgentActive = false;
    $scope.getCustomerNames = AccountService.getCustomerNames;
    $scope.getPrimaryCustomer = AccountService.getPrimaryCustomer;
    $scope.getPrimaryPhone = AccountService.getPrimaryPhone;

    $scope.goToOrder = function (order) {
      $state.go('viewOrder', { account_id: order.accountID, id: order.id });
    };

    $scope.updateUser = function (user_id) {
      Order.update({
        account_id: $scope.account_id,
        id: $scope.id,
        user_id,
      }).$promise.then($scope.refreshOrder);
    };

    function reset() {
      const { account, order } = $scope;

      if (!account.id || !order.id) {
        return;
      }

      const orderType = order.type.charAt(0).toUpperCase() + order.type.slice(1);
      Page.reset({ title: `${orderType}: ${account.customer.name}` });
    }

    $scope.account = Account.get({ id: $scope.account_id }, reset);

    $scope.refreshOrder = function () {
      $scope.order = Order.get(
        {
          id: $scope.id,
          account_id: $scope.account_id,
        },
        (order) => {
          reset();
          if (order.dispatch_id) {
            $scope.dispatch = Dispatch.get({ id: $scope.order.dispatch_id });
          }
        },
      );
    };

    $scope.refreshOrder();

    $scope.placeholder = NO_SLA_WINDOW_SHORT;
  },
]);

import styled from '@emotion/styled';
import React from 'react';

import { Maybe, SelfStorage__PromotionFragment } from '@admin/schema';

import { Spacer } from '@shared/components/helpers';
import { TD } from './helpers';

const Label = styled.label`
  cursor: pointer;
`;

export const PromotionCell: React.FC<{
  promotion?: Maybe<SelfStorage__PromotionFragment>;
  highlight: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}> = ({ promotion, highlight, onChange }) => (
  <TD className="text-left" highlight={highlight}>
    {promotion && (
      <Label>
        <input type="checkbox" checked={!highlight} onChange={onChange} />
        <Spacer width="6px" inline />
        {promotion.name}
      </Label>
    )}
  </TD>
);

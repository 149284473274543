import React from 'react';

import { OrderStateEnum as Order__State } from '@admin/schema';

import { Label } from '@admin/components/helpers/label';

import { ORDER_STATE_TO_NAME, ORDER_STATE_TO_KIND } from '@admin/constants/order_state';

export const State: React.FC<{ state: Order__State }> = ({ state }) => (
  <Label kind={ORDER_STATE_TO_KIND[state]}>{ORDER_STATE_TO_NAME[state]}</Label>
);

(function () {
  const app = angular.module('app');

  app.factory('SmsPendingCountService', [
    'UserService',
    function (UserService) {
      const service = {
        getPendingMessageCount: getPendingMessageCount,
      };

      return service;

      function getPendingMessageCount(pendingCountsUpdate) {
        let count = null;

        if (UserService.in_sales_groups) {
          count = pendingCountsUpdate.sales;
        } else if (UserService.in_care_groups) {
          count = pendingCountsUpdate.care;
        } else {
          count = pendingCountsUpdate.sales + pendingCountsUpdate.care;
        }
        return count;
      }
    },
  ]);
})();

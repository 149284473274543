import angular from 'angular';

angular.module('app').component('billingChargeForm', {
  template: `
    <react-component
      name="BillingChargeForm"
      props="{
        accountID: $ctrl.id,
        initialKind: $ctrl.initialKind,
        onSave: $ctrl.onSave,
        onCancel: $ctrl.onCancel,
      }"
    />
  `,
  bindings: {
    id: '<',
    initialKind: '<',
    onClose: '&',
  },
  controller: [
    '$rootScope',
    function ($rootScope) {
      this.onCancel = () => {
        this.onClose();
      };

      this.onSave = (summary) => {
        $rootScope.$broadcast('inlineform:saved', {
          form: 'chargesNew',
        });
        $rootScope.$broadcast('message:display', {
          type: 'info',
          body: `Charge of ${summary} successful!`,
        });
        this.onClose();
      };
    },
  ],
});

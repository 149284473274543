(function () {
  const app = angular.module('app');

  app.constant('ServiceAreaConstants', {
    STATUSES: {
      OUT_OF_SERVICE_AREA: 'Out of service area',
      FULL_SERVICE_ONLY: 'Full service only',
      SELF_STORAGE_ONLY: 'Self storage only',
      BOTH: 'Full service + self storage available',
      INVALID_ZIP: 'Invalid zip code',
    },
    AVAILABILITY: {
      AVAILABLE: 'Available',
      UNAVAILABLE: 'Unavailable',
    },
  });
})();

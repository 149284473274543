const app = angular.module('app');

app.component('checkoutPriceMatch', {
  templateUrl: 'partials/components/checkout_price_match.html',
  bindings: {
    priceMatchKind: '<',
    onChange: '&',
    showLabel: '<',
  },
  controller: [
    function () {
      const self = this;

      self.$onDestroy = function () {
        self.onChange({ priceMatchKind: undefined });
      };

      self.updatePriceMatchKind = function () {
        self.onChange({ priceMatchKind: self.priceMatchKind });
      };
    },
  ],
});

import * as React from 'react';

import { NoteNotableInput, useNotesQuery } from '@admin/schema';

import { client } from '@admin/libraries/apollo';

import { Spinner } from '@admin/components/spinner';

import { Events } from './events';
import { NotesForm } from './form';
import { List } from './list';

export const Widget: React.FC<NoteNotableInput> = ({ id, type }) => {
  const { data, refetch, loading } = useNotesQuery({
    client,
    variables: {
      notable: {
        id,
        type,
      },
    },
  });
  const notes = data && data.notes;

  return (
    <Events refetch={refetch}>
      <NotesForm id={id} type={type} onSave={refetch} />
      {loading && <Spinner />}
      {notes && <List id={id} type={type} notes={notes} />}
    </Events>
  );
};

import React from 'react';
import { client } from '@admin/libraries/apollo';
import { useRegionsSelectorQuery, Region } from '@admin/schema';
import { ControlLabel, FormGroup, Col } from '@shared/components/bootstrap';

export type RegionFragment = Pick<Region, 'id' | 'name' | 'tz'>;

export const RegionFormGroup: React.FC<{
  selection?: RegionFragment;
  onSelect(selection?: RegionFragment): void;
}> = ({ selection, onSelect }) => {
  const { data } = useRegionsSelectorQuery({ client });
  const regions = data?.regions;

  const onChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const regionID = event.target.value || undefined;
    const region = regionID ? regions?.find(({ id }) => id === regionID) : undefined;
    onSelect(region);
  };

  return (
    <FormGroup>
      <ControlLabel htmlFor="region_select">Region</ControlLabel>
      <Col>
        <select id="region_select" className="form-control" value={selection?.id ?? ''} onChange={onChange}>
          {!selection && <option value=""> - Region - </option>}
          {regions?.map((entry) => (
            <option value={entry.id} key={entry.id}>
              {entry.name}
            </option>
          ))}
        </select>
      </Col>
    </FormGroup>
  );
};

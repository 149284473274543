import template from '@admin/angular/templates/incidents_dialog.html';

import angular from 'angular';
import { map, pick } from 'lodash';

const app = angular.module('app');

const GENERIC_MESSAGE = 'Something went wrong. Please try again.';
const SAVABLE_MESSAGE = 'Please review the form prior to saving.';
const UPLOADS_MESSAGE = 'Please upload an image prior to saving.';

app.component('incidentsDialog', {
  template,
  bindings: {
    incident: '<',
    resource: '<',
    onCancel: '<',
    onSave: '<',
  },
  controller: [
    'UploaderService',
    'ErrorService',
    'Incident',
    function (UploaderService, ErrorService, Incident) {
      this.uploads = [];
      this.upload = {};

      this.$onInit = () => {
        this.incident_kind_options = this.incident.id ? [this.incident.kind] : ['damaged', 'missing'];
        this.confirmations = { state: this.resource.state === 'flagged' };
      };

      this.attach = (file, reader) => {
        this.uploads = [...this.uploads, { file, reader, state: 'pending' }];
      };

      this.unattach = (upload) => {
        this.uploads = this.uploads.filter((current) => current !== upload);
      };

      this.savable = () =>
        this.incident.kind &&
        this.incident.explanation &&
        this.incident.explanation.trim().length > 0 &&
        (this.incident.id || this.confirmations.state);

      this.onNewDuplicateItemLink = (item) => {
        this.duplicate_of = item;
      };

      const parameterize = () => {
        const params = pick(this.incident, ['explanation', 'kind', 'parent_id', 'parent_type', 'id']);
        if (!this.incident.id) {
          params.images_attributes = map(this.uploads, (upload) => ({ url: upload.url, state: 'uploaded' }));
        }
        if (this.incident.kind === 'duplicate') {
          params.duplicate_of_id = this.duplicate_of ? this.duplicate_of.id : null;
        }
        return params;
      };

      const updateResource = () => {
        if (this.incident.kind === 'duplicate') {
          Object.assign(this.resource, { duplicate_of_id: this.duplicate_of && this.duplicate_of.id });
        }
      };

      this.save = () => {
        if (this.saving) {
          return;
        }
        if (!this.savable()) {
          ErrorService.handle({ message: SAVABLE_MESSAGE });
          return;
        }
        if (!this.incident.id && !this.uploads.length && this.incident.kind === 'damaged') {
          ErrorService.handle({ message: UPLOADS_MESSAGE });
          return;
        }

        this.saving = this.incident;

        UploaderService.uploadFiles(this.uploads).then(
          () => {
            const method = this.incident.id ? 'update' : 'create';

            Incident[method](parameterize()).$promise.then(
              () => {
                updateResource();
                this.onSave();
              },
              (error) => {
                delete this.saving;
                ErrorService.handle(error);
              },
            );
          },
          () => {
            ErrorService.handle({ message: GENERIC_MESSAGE });
            delete this.saving;
          },
        );
      };

      this.cancel = () => {
        this.onCancel();
      };
    },
  ],
});

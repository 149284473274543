(function () {
  angular.module('app').filter('highlight', [
    function () {
      return function (text, pattern) {
        if (pattern && pattern.length) {
          const regex = new RegExp('(' + pattern + ')', 'gi');
          text = text.replace(regex, '<strong>$&</strong>');
        }
        return text;
      };
    },
  ]);
})();

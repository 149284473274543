const app = angular.module('app');

app.factory('DispatchMap', [
  '$resource',
  function ($resource) {
    return $resource('/dispatches/:dispatch_id/map.json', {
      dispatch_id: '@dispatch_id',
    });
  },
]);

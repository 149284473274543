(function () {
  const app = angular.module('app');

  app.directive('itemCategoryEditor', [
    'Item',
    'ItemGroup',
    'ItemCategoryService',
    'ItemCategorizationObserverService',
    'EventConstants',
    'LargeItemWarningService',
    function (
      Item,
      ItemGroup,
      ItemCategoryService,
      ItemCategorizationObserverService,
      EventConstants,
      LargeItemWarningService,
    ) {
      return {
        restrict: 'E',
        templateUrl: 'partials/items/_category_editor.html',
        scope: {
          item: '=',
          selectedCategory: '=',
        },
        link: function (scope) {
          scope.editingMode = false;
          scope.previousSelectedCategory = scope.selectedCategory;

          ItemCategoryService.options.$promise.then(function (options) {
            scope.categoryOptions = options;
          });

          scope.$on('edit_category_mode:disabled', function () {
            if (scope.selectedCategory === scope.previousSelectedCategory) {
              scope.editingMode = false;
            }
          });
          scope.$on('edit_category_mode:enabled', function () {
            if (!scope.selectedCategory) {
              scope.previousSelectedCategory = scope.selectedCategory;
              scope.editingMode = true;
            }
          });

          scope.resetItem = function () {
            scope.item.dimensions_overwritten = false;
          };

          scope.setDefaultDimensions = function () {
            scope.clearDimensions();
            scope.resetToDefault(scope.item.category.defaults, scope.item.sizing_selection);
          };

          scope.resetToDefault = function (defaults, sizingSelection) {
            let attributes = {};
            if (defaults) {
              attributes = sizingSelection ? defaults[sizingSelection] : defaults.default;
            }

            scope.item.length = attributes.length;
            scope.item.width = attributes.width;
            scope.item.height = attributes.height;
            scope.item.num_movers = attributes.num_movers;
          };

          scope.clearDimensions = function () {
            delete scope.item.length;
            delete scope.item.width;
            delete scope.item.height;
            delete scope.item.num_movers;
          };

          scope.restoreDimensions = function () {
            scope.editDimensionsMode = false;
            scope.dimensionTabIndex = -1;
            scope.setDefaultDimensions();
            scope.item.dimensions_overwritten = false;
          };

          scope.editDimensions = function () {
            scope.editDimensionsMode = true;
            scope.item.dimensions_overwritten = true;
            scope.dimensionTabIndex = 0;
          };

          scope.onDimensionBlur = function (event) {
            if (scope.editDimensionsMode && (!event.relatedTarget || event.relatedTarget.textContent !== 'cancel')) {
              scope.largeItemWarning = LargeItemWarningService.warnIfLargeItem(
                scope.$id,
                scope.item.length,
                scope.item.width,
                scope.item.height,
                function () {
                  scope.largeItemWarning = false;
                  if (event.relatedTarget && event.relatedTarget.textContent === 'save') {
                    scope.save();
                  }
                },
              );
            }
          };

          scope.handleSubmit = function (event) {
            scope.save();
            scope.$emit(EventConstants.ITEM_CATEGORY_EDITOR_SAVED, { event: event });
          };

          scope.onSelect = function () {
            scope.resetItem();
            scope.setDefaultDimensions();
          };

          scope.cancel = function () {
            scope.editingMode = false;
            scope.selectedCategory = scope.previousSelectedCategory;
            Object.assign(scope.item, scope.itemCopy);
          };

          scope.enabledEditing = function () {
            scope.itemCopy = angular.copy(scope.item);
            scope.previousSelectedCategory = scope.selectedCategory;
            scope.editingMode = true;
          };

          scope.dimensionsPresent = function () {
            return (
              scope.item.length !== null &&
              scope.item.length !== undefined &&
              scope.item.width !== null &&
              scope.item.width !== undefined &&
              scope.item.height !== null &&
              scope.item.height !== undefined
            );
          };

          scope.save = function () {
            if (scope.largeItemWarning) {
              return;
            }
            let request = null;

            const sizing = {
              category_id: scope.item.category ? scope.item.category.id : null,
              sizing_selection: scope.item.sizing_selection,
              custom_category_name: scope.item.custom_category_name,
              length: scope.item.length,
              width: scope.item.width,
              height: scope.item.height,
              num_movers: scope.item.num_movers,
              dimensions_overwritten: scope.item.dimensions_overwritten,
            };

            if (scope.item.group_id) {
              request = ItemGroup.update(
                {
                  id: scope.item.group_id,
                  account_id: scope.item.account_id,
                },
                sizing,
              );
            } else {
              request = Item.update(
                {
                  id: scope.item.id,
                  account_id: scope.item.account_id,
                },
                sizing,
              );
            }

            request.$promise.then(
              function () {
                if (sizing.category_id) {
                  scope.item.category = ItemCategoryService.findItemCategory(sizing.category_id);
                }
                if (sizing.sizing_selection) {
                  scope.item.sizing_selection = sizing.sizing_selection;
                }
                scope.previousSelectedCategory = scope.selectedCategory;
                scope.editingMode = false;
                scope.editDimensionsMode = false;
                ItemCategorizationObserverService.notify(scope.item.account_id);
              },
              function (error) {
                scope.$root.$broadcast('message:display', {
                  type: 'danger',
                  body: 'Unable to update Item: ' + error.data.message,
                });
              },
            );
          };
        },
      };
    },
  ]);
})();

import * as React from 'react';
import { useOrderAttachmentsQuery } from '@admin/schema';
import { Spinner } from '@admin/components/spinner';
import { Panel as Container } from '@admin/components/helpers/panel';
import { client } from '@admin/libraries/apollo';
import { accountPortalURL } from '@admin/config/routes';
import { AnchorButton as Link, Table } from '@shared/components/bootstrap';

export const OrderAttachments: React.FC<{ orderID: string }> = ({ orderID }) => {
  const { data, loading } = useOrderAttachmentsQuery({ client, variables: { orderID } });
  const ingestedDocuments = data?.order?.ingestedDocuments;

  if (loading) {
    return <Spinner />;
  }

  if (!ingestedDocuments?.length) {
    return null;
  }

  return (
    <Container>
      <Container.Header>
        <Container.Title>Attachments</Container.Title>
      </Container.Header>
      <Container.Body>
        <h4>Ingested Documents</h4>
        <Table responsive striped>
          <tbody>
            {ingestedDocuments?.map(({ id, filename, url }) => (
              <tr key={id}>
                <td className="text-left">
                  <div>{filename}</div>
                </td>
                <td className="text-right">
                  <Link kind="primary" target="_blank" href={`${accountPortalURL}${url}`}>
                    Download
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Container.Body>
    </Container>
  );
};

import * as React from 'react';
import { useContext, useEffect } from 'react';

import { PricingGroupPricingEntryFragment } from '@admin/schema';

import { PricingSetContext } from './pricing_set_context';

export const ProtectionRadios: React.FC<{
  protection?: PricingGroupPricingEntryFragment;
  onSelect(protection?: PricingGroupPricingEntryFragment): void;
}> = ({ protection, onSelect }) => {
  const { protectionPricingGroupEntries: entries = [] } = useContext(PricingSetContext);
  const selection = entries.find((entry) => !!protection && entry.pricing.plan.id === protection.pricing.plan.id);

  useEffect(() => {
    if (selection !== protection) {
      onSelect(protection);
    }
  }, [selection]);

  if (!entries.length) {
    return <>-</>;
  }

  return (
    <>
      {entries.map((entry) => (
        <div className="radio" key={entry.id}>
          <label>
            <input type="radio" checked={entry === selection} onChange={() => onSelect(entry)} />
            <span>{entry.pricing.plan.name}</span>
          </label>
        </div>
      ))}
    </>
  );
};

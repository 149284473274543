import styled from '@emotion/styled';
import React from 'react';

import { Button, Modal } from '@shared/components/bootstrap';

const Title = styled.div`
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  text-align: center;
  white-space: normal;
`;

const Description = styled.div`
  font-size: 18px;
  font-weight: 200;
  line-height: 24px;
  text-align: center;
  white-space: normal;
`;

export const Dialog: React.FC<{
  onClose(): void;
  title: string;
  description: string;
}> = ({ onClose, title, description }) => (
  <Modal onClose={onClose}>
    <Modal.Content>
      <Modal.Header>
        <Modal.Title>
          <Title>{title}</Title>
        </Modal.Title>
        <Modal.Close close={onClose} />
      </Modal.Header>
      <Modal.Body>
        <Description>{description}</Description>
      </Modal.Body>
      <Modal.Footer>
        <Button kind="default" size="lg" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal.Content>
  </Modal>
);

import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';

import { Panel } from '@admin/components/helpers/panel';
import { Button } from '@shared/components/bootstrap';

import { phonecallURL } from '@admin/config/routes';

import {
  IAudioPlayerLoadEventDetails,
  useAudioPlayerLoadEvent,
  useAudioPlayerPauseEvent,
  useAudioPlayerPlayEvent,
} from './events';

const Container = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 8px 0px;

  .panel {
    margin: 0;
  }

  a {
    text-decoration: underline;
  }
`;

export const Player: React.FC = () => {
  const ref = useRef<HTMLAudioElement | null>(null);
  const [recording, setRecording] = useState<IAudioPlayerLoadEventDetails | undefined>(undefined);
  const [minimized, setMinimized] = useState(false);

  useAudioPlayerLoadEvent((detail) => {
    setRecording(detail);
  });
  useAudioPlayerPauseEvent(() => ref.current && ref.current.pause());
  useAudioPlayerPlayEvent(() => ref.current && ref.current.play());

  useEffect(() => {
    if (ref.current && recording) {
      ref.current.pause();
      ref.current.load();
      ref.current.play();
    }
  }, [ref.current, recording]);

  if (!recording) {
    return null;
  }

  return (
    <Container>
      <Panel>
        <Panel.Header>
          <Panel.Control>
            <Button kind="default" onClick={() => setMinimized(!minimized)}>
              <FontAwesomeIcon icon={minimized ? 'plus' : 'minus'} />
            </Button>
            <Button kind="default" onClick={() => setRecording(undefined)}>
              <FontAwesomeIcon icon="times" />
            </Button>
          </Panel.Control>
          <Panel.Title>Audio</Panel.Title>
        </Panel.Header>
        <Panel.Body>
          {recording.agent && <p>Agent: {recording.agent}</p>}
          {recording.date && <p>Date: {DateTime.fromISO(recording.date).toLocaleString(DateTime.DATE_MED)}</p>}

          {recording.pageID && (
            <p>
              <a href={phonecallURL({ id: recording.pageID })}>View Phone Call</a>
            </p>
          )}

          <audio controls controlsList="nodownload" ref={ref}>
            <source src={recording.src} type="audio/wav" />
          </audio>
        </Panel.Body>
      </Panel>
    </Container>
  );
};

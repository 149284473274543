import { useLatestCallback } from '@shared/hooks/use_latest';
import Pusher from 'pusher-js';
import { useEffect } from 'react';

interface IConfig {
  pusher: {
    key: string;
    cluster: string;
  };
}

interface IClutter {
  config: IConfig;
}

declare const clutter: IClutter;
const config = clutter.config;

export const usePusher = <T>(name: string | undefined, event: string, callback: (data: T) => void) => {
  const latestCallback = useLatestCallback(callback);
  useEffect(() => {
    if (!name) {
      return;
    }
    const pusher = new Pusher(config.pusher.key, { cluster: config.pusher.cluster });
    const channel = pusher.subscribe(name);
    const boundCallback = (data: T) => {
      latestCallback(data);
    };
    channel.bind(event, boundCallback);

    return () => {
      channel.unbind(event, boundCallback);
      pusher.unsubscribe(name);
    };
  }, [name, event, latestCallback]);
};

import template from '@admin/angular/templates/return_form.html';

(() => {
  const app = angular.module('app');

  app.component('returnForm', {
    template,
    bindings: {
      account: '<account',
      order: '<order',
    },
    controller: [
      'Return',
      'RETURN_ATTRIBUTES',
      function (Return, RETURN_ATTRIBUTES) {
        this.attributes = RETURN_ATTRIBUTES;
        this.resource = Return;
      },
    ],
  });
})();

(function () {
  angular.module('app').factory('Plan', [
    '$resource',
    function ($resource) {
      return $resource('/plans/:id.json', {
        id: '@id',
      });
    },
  ]);
})();

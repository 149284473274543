import * as React from 'react';
import { useState } from 'react';

import { Panel } from '@admin/components/helpers/panel';
import { NotesPanel } from '@admin/components/notes/panel';
import { Spinner } from '@admin/components/spinner';
import { TicketsPanel as TaskTicketsPanel } from '@admin/components/tickets/panel';
import { CustomerTicketFragment, NoteNotableEnum, Ticket__TicketableEnum } from '@admin/schema';
import styled from '@emotion/styled';
import { Button } from '@shared/components/bootstrap';
import { client } from '@admin/libraries/apollo';

import { useCustomerTicketQuery, CustomerInteractionFragment, LeadInteractionFragment } from '@admin/schema';
import { accountURL, editLeadURL } from '@admin/config/routes';
import { Attributes } from './attributes';
import { Edit } from './edit';
import { Interactions } from './interactions';
import { ReopenButton } from './reopen_button';
import { ResolveButton } from './resolve_button';
import { ZendeskTicketsPanel } from './zendesk_tickets_panel/index';

const ButtonContainer = styled.div`
  button {
    margin-left: 4px;
  }
`;

const customerLink = (
  customerTicketable: CustomerTicketFragment['customerTicketable'],
  customerTicketableType: string,
) => {
  switch (customerTicketableType) {
    case 'Account':
      return accountURL(customerTicketable);
    case 'Lead':
      return editLeadURL(customerTicketable);
  }
};

export const Details: React.FC<{ id: string }> = ({ id }) => {
  const { data, loading } = useCustomerTicketQuery({ client, variables: { id } });

  const [editing, setEditing] = useState<boolean>(false);

  if (loading) {
    return <Spinner />;
  }

  if (!data) {
    return <></>;
  }

  const { customerTicket } = data;

  const { customerTicketable, customerTicketableType } = customerTicket;

  let interactions: Array<CustomerInteractionFragment | LeadInteractionFragment> = [];

  if (customerTicket.customerTicketableType === 'Account') {
    interactions = customerTicket.interactions;
  }

  if (customerTicket.customerTicketableType === 'Lead') {
    interactions = customerTicket.leadInteractions;
  }

  return (
    <>
      <Panel>
        <Panel.Header>
          <Panel.Title>Customer Ticket #{id}</Panel.Title>
        </Panel.Header>
        <Panel.Body>
          {!editing && (
            <Attributes
              customerTicket={customerTicket}
              customerTicketableLink={customerLink(customerTicketable, customerTicketableType)!}
            />
          )}
          {editing && (
            <Edit
              customerTicket={customerTicket}
              customerTicketableLink={customerLink(customerTicketable, customerTicketableType)!}
              finishEditing={() => setEditing(false)}
            />
          )}
        </Panel.Body>
        <Panel.Footer align="right">
          <ButtonContainer>
            {!editing && customerTicket.state !== 'resolved' && (
              <>
                <Button kind="primary" onClick={() => setEditing(true)}>
                  Edit
                </Button>
                <ResolveButton customerTicket={customerTicket} />
              </>
            )}
            {customerTicket.state === 'resolved' && <ReopenButton id={id} />}
          </ButtonContainer>
        </Panel.Footer>
      </Panel>
      <ZendeskTicketsPanel
        customerTicketID={customerTicket.id}
        ticketAssociations={customerTicket.zendeskTicketAssociations}
      />
      <TaskTicketsPanel ticketable={{ id: data.customerTicket.id, type: Ticket__TicketableEnum.CustomerTicket }} />
      <Interactions interactions={interactions} />
      <NotesPanel type={NoteNotableEnum.CustomerTicket} id={id} />
    </>
  );
};

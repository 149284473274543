import React, { useState } from 'react';

import { client } from '@admin/libraries/apollo';
import { useOrderLeadTimesQuery } from '@admin/schema';

import { Panel } from '@admin/components/helpers/panel';
import { Table, AnchorButton as Link } from '@shared/components/bootstrap';
import { Spinner } from '@admin/components/spinner';
import { DateTime } from 'luxon';
import { ORDER_SUBTYPE_TO_NAME } from '@admin/constants/order_subtype';
import { ORDER_TYPE_TO_NAME } from '@admin/constants/order_type';
import { OrderLeadTimeEditingType, UpsertModal } from './upsert_modal';

export const OrderLeadTimeList: React.FC<{ warehouseID: string; editAccess: boolean }> = ({
  warehouseID,
  editAccess,
}) => {
  const [showUpsertModal, setShowUpsertModal] = useState<boolean>(false);
  const [editingOrderLeadTime, setEditingOrderLeadTime] = useState<OrderLeadTimeEditingType | undefined>(undefined);

  const { data, loading, refetch } = useOrderLeadTimesQuery({
    client,
    variables: { warehouseID: warehouseID },
  });

  return (
    <>
      <Panel>
        <Panel.Body>
          <Table responsive striped>
            <thead>
              <tr>
                <th className="col-sm-2">Order Type</th>
                <th className="col-sm-2">Order Subtype</th>
                <th className="col-sm-2">Booking Lead Days Required</th>
                <th className="col-sm-2">Booking Lead Day Type</th>
                <th className="col-sm-2">Booking Lead Day Cutoff Time</th>
                {editAccess && (
                  <>
                    <th className="col-sm-2">Last Updated</th>
                    <th className="col-sm-1 text-right">
                      <a
                        className="btn btn-mint btn-labeled fa fa-plus"
                        onClick={() => {
                          setEditingOrderLeadTime(undefined);
                          setShowUpsertModal(true);
                        }}
                      >
                        Add
                      </a>
                    </th>
                  </>
                )}
              </tr>
            </thead>

            <tbody>
              {data?.orderLeadTimes.map((orderLeadTime) => (
                <tr key={orderLeadTime.id}>
                  <td className="col-sm-2">{ORDER_TYPE_TO_NAME[orderLeadTime.orderType]}</td>
                  <td className="col-sm-2">{ORDER_SUBTYPE_TO_NAME[orderLeadTime.orderSubtype]}</td>
                  <td className="col-sm-2">{orderLeadTime.processingDaysRequired}</td>
                  <td className="col-sm-2">
                    {orderLeadTime.ignoreNonOperatingDays ? 'Operating Days' : 'Calendar Days'}
                  </td>
                  <td className="col-sm-2">
                    {DateTime.fromISO(orderLeadTime.processingDayCutoffTime, { zone: 'UTC' }).toLocaleString(
                      DateTime.TIME_SIMPLE,
                    )}
                  </td>
                  {editAccess && (
                    <>
                      <td className="col-sm-2">
                        {DateTime.fromISO(orderLeadTime.updatedAt).toLocaleString(DateTime.DATETIME_MED)}
                      </td>
                      <td className="col-sm-1 text-right">
                        <Link
                          kind="primary"
                          onClick={() => {
                            setEditingOrderLeadTime({
                              id: orderLeadTime.id,
                              ignoreNonOperatingDays: orderLeadTime.ignoreNonOperatingDays,
                              orderSubtype: orderLeadTime.orderSubtype,
                              orderType: orderLeadTime.orderType,
                              processingDayCutoffTime: orderLeadTime.processingDayCutoffTime,
                              processingDaysRequired: orderLeadTime.processingDaysRequired,
                            });
                            setShowUpsertModal(true);
                          }}
                        >
                          Edit
                        </Link>
                      </td>
                    </>
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
          {loading && <Spinner />}
        </Panel.Body>
      </Panel>
      {showUpsertModal && (
        <UpsertModal
          orderLeadTime={editingOrderLeadTime}
          existingOrderLeadTimeKinds={
            data?.orderLeadTimes.map((orderLeadTime) => ({
              orderSubtype: orderLeadTime.orderSubtype,
              orderType: orderLeadTime.orderType,
            })) ?? []
          }
          warehouseID={warehouseID}
          closeModal={() => {
            setEditingOrderLeadTime(undefined);
            setShowUpsertModal(false);
            refetch();
          }}
        />
      )}
    </>
  );
};

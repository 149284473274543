import { capitalize } from 'lodash';
import * as React from 'react';

export const titleize = (input: string) =>
  input
    .split(/[\s|\:|\_]+/)
    .map(capitalize)
    .join(' ');

export const Titleize: React.FC<{
  text: string;
}> = ({ text }: { text: string }) => <>{titleize(text)}</>;

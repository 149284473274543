import { DateTime } from 'luxon';
import * as React from 'react';
import { FC, useContext } from 'react';

import { Calendar } from '@shared/components/calendar/calendar';

import { AvailabilityContext } from './availability_context';
import { AvailabilityDate } from './availability_date';
import { AvailabilityPrompt } from './availability_prompt';

const FROM_ISO_OPTIONS = { setZone: true };

type Status = {
  available: boolean;
  laborRate?: {
    id: string;
    amount: number;
  } | null;
};

const key = (value: DateTime): string => value.toISODate();

export const AvailabilityCalendar: FC = () => {
  const { loading, from, till, onNext, onPrev, date, onDate, availabilities } = useContext(AvailabilityContext);

  const statuses = new Map<string, Status>();

  for (const availability of availabilities) {
    const { datetime, ...status } = availability;
    statuses.set(key(DateTime.fromISO(datetime, FROM_ISO_OPTIONS)), status);
  }

  const labor = availabilities.some(
    ({ laborRate }) => laborRate?.amount !== 0 && typeof laborRate?.amount === 'number',
  );

  return (
    <div>
      <AvailabilityPrompt>Please select a date.</AvailabilityPrompt>
      <Calendar
        loading={loading}
        from={from}
        till={till}
        disabled={(value: DateTime): boolean => {
          const status = statuses.get(key(value));
          return !status || !status.available || value < DateTime.local();
        }}
        selected={(value: DateTime): boolean =>
          !!date && date.year === value.year && date.month === value.month && date.day === value.day
        }
        booked={(value: DateTime): boolean => {
          const status = statuses.get(key(value));
          return !!status && !status.available;
        }}
        onSelect={(value) => {
          onDate(value);
        }}
        children={(props) => {
          const status = statuses.get(key(props.date));
          return <AvailabilityDate labor={labor} {...props} {...status} />;
        }}
        onNext={onNext}
        onPrevious={onPrev}
      />
    </div>
  );
};

(function () {
  const app = angular.module('app');

  app.directive('multiselect', [
    function () {
      return {
        restrict: 'E',
        scope: {
          selected: '=',
          value: '=',
          comparator: '=?',
          changedCallback: '&',
        },
        controller: [
          '$scope',
          function ($scope) {
            $scope.active = active;
            $scope.toggle = toggle;

            function insert(value) {
              if (!exists(value)) {
                $scope.selected.push(value);
              }
            }

            function compare(a, b) {
              return ($scope.comparator || angular.equals)(a, b);
            }

            function remove(value) {
              if (exists(value)) {
                _.remove($scope.selected, function (object) {
                  return compare(value, object);
                });
              }
            }

            function toggle($event) {
              const target = $event.target;
              const checked = target.checked;

              if ($scope.value instanceof Array) {
                _.each($scope.value, function (value) {
                  (checked ? insert : remove)(value);
                });
              } else {
                (checked ? insert : remove)($scope.value);
              }
              if ($scope.changedCallback) {
                $scope.changedCallback();
              }
            }

            function active() {
              if ($scope.value instanceof Array) {
                return _.every($scope.value, exists);
              } else {
                return exists($scope.value);
              }
            }

            function exists(value) {
              return _.some($scope.selected, function (object) {
                return compare(object, value);
              });
            }
          },
        ],

        template: '<input type="checkbox" ng-checked="active()" ng-click="toggle($event)" />',
      };
    },
  ]);
})();

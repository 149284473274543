import { DateTime, Duration } from 'luxon';

import {
  Address__BuildingRestrictionsAnswerEnum,
  AddressDetailEnvironmentEnum,
  AvailabilitiesDocument,
  AvailabilitiesQuery,
  AvailabilitiesQueryVariables,
  Maybe,
  OrderInput,
  OrderTypeEnum,
  AvailabilitiesInputKind,
} from '@admin/schema';

import { AddressDetailField, IOrderInventory } from '@shared/types';
import { IAvailabilitiesForBookingInput, IAvailabilitiesForEditingInput, IAvailabilitiesInput } from '@admin/types';

import { client } from '@admin/libraries/apollo';
import { buildPickupInventoryInput } from '@shared/utils/build_pickup_inventory_input';
import { buildReturnInventoryInput } from '@shared/utils/build_return_inventory_input';
import { ORDER_TYPE_MAP } from './order_type_map';

const FROM_ISO_OPTIONS = { setZone: true };

interface IAvailabilitiesParams {
  inventory?: IOrderInventory;
  accountID?: number;
  orderID?: number;
  predecessorID?: Maybe<string>;
  regionID?: number;
  address?: {
    id?: number;
    aptsuite?: string;
    street?: string;
    city?: string;
    state?: string;
    country?: string;
    zip?: string;
    building_type?: string;
    building_subtype?: string;
    floor: number | null;
    stories: number | null;
    front_door_steps: number | null;
    squareFeet: number | null;
    stairs?: boolean;
    elevator?: boolean;
    gated?: boolean;
    steep?: boolean;
    service_elevator?: boolean;
    service_entrance?: boolean;
    loading_dock?: boolean;
    parking_street?: boolean;
    parking_driveway?: boolean;
    parking_lot?: boolean;
    parking_alley?: boolean;
    parking_permit?: boolean;
    parking_in_front?: boolean;
    parking_on_specific_street: string | null;
    parking_instructions: string | null;
    max_truck_height: number | null;
    max_truck_height_unknown?: boolean;
    paperwork?: boolean;
    access_unknown?: boolean;
    paperwork_unknown?: boolean;
    environment: string | null;
    parking_unknown?: boolean;
    additional_notes: string | null;
    building_opening_hour: number | null;
    building_closing_hour: number | null;
    building_restrictions_answer: string | null;
    code?: boolean;
    code_value: string | null;
  };
  subscriptions?: Array<{
    quantity: number;
    plan_id: number;
    custom_plan: boolean;
    custom_plan_dimension_length?: string;
    custom_plan_dimension_width?: string;
  }>;
  items?: Array<{ id: number | string }>;
  type?: keyof typeof ORDER_TYPE_MAP;
  from: string; // 2019-12-15
  till: string; // 2019-12-31
}

const DEFAULT_STAIRS = false;
const DEFAULT_ELEVATOR = false;
const DEFAULT_GATED = false;
const DEFAULT_STEEP = false;
const DEFAULT_SERVICE_ELEVATOR = false;
const DEFAULT_SERVICE_ENTRANCE = false;
const DEFAULT_LOADING_DOCK = false;
const DEFAULT_CODE = false;
const DEFAULT_PARKING_STREET = false;
const DEFAULT_PARKING_DRIVEWAY = false;
const DEFAULT_PARKING_LOT = false;
const DEFAULT_PARKING_ALLEY = false;
const DEFAULT_PARKING_PERMIT = false;
const DEFAULT_PARKING_IN_FRONT = false;
const DEFAULT_ACCESS_UNKNOWN = false;
const DEFAULT_PAPERWORK_UNKNOWN = false;
const DEFAULT_PARKING_UNKNOWN = false;
const DEFAULT_MAX_TRUCK_HEIGHT_UNKNOWN = false;

const buildAvailabilityInputForBooking = ({
  from,
  till,
  order,
}: {
  from: string;
  till: string;
  order: OrderInput;
}): IAvailabilitiesForBookingInput => ({
  from,
  till,
  kind: AvailabilitiesInputKind.Booking,
  order,
});

const buildAvailabilityInputForEditing = ({
  from,
  till,
  orderID,
  order,
}: {
  from: string;
  till: string;
  orderID: number;
  order: OrderInput;
}): IAvailabilitiesForEditingInput => ({
  from,
  till,
  kind: AvailabilitiesInputKind.Editing,
  orderID: String(orderID),
  order,
});

const formatAddressEnvironment = (environment: Maybe<string>) => {
  switch (environment) {
    case 'indoor':
      return AddressDetailEnvironmentEnum.Indoor;
    case 'outdoor':
      return AddressDetailEnvironmentEnum.Outdoor;
    default:
      return undefined;
  }
};

const formatAddressBuildingRestrictionsAnswer = (buildingRestrictionsAnswer: Maybe<string>) => {
  switch (buildingRestrictionsAnswer) {
    case 'yes':
      return Address__BuildingRestrictionsAnswerEnum.Yes;
    case 'no':
      return Address__BuildingRestrictionsAnswerEnum.No;
    case 'unknown':
      return Address__BuildingRestrictionsAnswerEnum.Unknown;
    default:
      return undefined;
  }
};

export const availabilities = async ({
  accountID,
  orderID,
  predecessorID = null,
  regionID,
  address,
  type,
  inventory = {},
  items = [],
  subscriptions,
  from,
  till,
}: IAvailabilitiesParams) => {
  if (!address) {
    return { error: 'An address is required to check availability.' };
  }

  const { id: addressID, aptsuite, street, city, state, country, zip } = address;

  if (!addressID && !zip) {
    return { error: `An address must have a ZIP specified to check availability.` };
  }

  if (!regionID) {
    return { error: 'A region is required to check availability.' };
  }

  const order: OrderInput = {
    type: (() => {
      if (type && ORDER_TYPE_MAP.hasOwnProperty(type)) {
        return ORDER_TYPE_MAP[type];
      }
      throw new Error(`invalid order type provided: "${type}"`);
    })(),
    addressID: addressID ? String(addressID) : undefined,
    regionID: String(regionID),
    predecessorID: predecessorID,
    address: !addressID
      ? {
          aptsuite,
          street: street!,
          city: city!,
          state: state!,
          country,
          zip: address.zip!,
          details: {
            [AddressDetailField.BuildingType]: address.building_type,
            [AddressDetailField.Floor]: address.floor,
            [AddressDetailField.Stories]: address.stories,
            [AddressDetailField.FrontDoorSteps]: address.front_door_steps,
            [AddressDetailField.Stairs]: address.stairs || DEFAULT_STAIRS,
            [AddressDetailField.SquareFeet]: address.squareFeet || null,
            [AddressDetailField.Elevator]: address.elevator || DEFAULT_ELEVATOR,
            [AddressDetailField.Gated]: address.gated || DEFAULT_GATED,
            [AddressDetailField.Steep]: address.steep || DEFAULT_STEEP,
            [AddressDetailField.ServiceElevator]: address.service_elevator || DEFAULT_SERVICE_ELEVATOR,
            [AddressDetailField.ServiceEntrance]: address.service_entrance || DEFAULT_SERVICE_ENTRANCE,
            [AddressDetailField.LoadingDock]: address.loading_dock || DEFAULT_LOADING_DOCK,
            [AddressDetailField.Code]: address.code || DEFAULT_CODE,
            [AddressDetailField.CodeValue]: address.code_value || null,
            [AddressDetailField.ParkingStreet]: address.parking_street || DEFAULT_PARKING_STREET,
            [AddressDetailField.ParkingDriveway]: address.parking_driveway || DEFAULT_PARKING_DRIVEWAY,
            [AddressDetailField.ParkingLot]: address.parking_lot || DEFAULT_PARKING_LOT,
            [AddressDetailField.ParkingAlley]: address.parking_alley || DEFAULT_PARKING_ALLEY,
            [AddressDetailField.ParkingPermit]: address.parking_permit || DEFAULT_PARKING_PERMIT,
            [AddressDetailField.ParkingInFront]: address.parking_in_front || DEFAULT_PARKING_IN_FRONT,
            [AddressDetailField.ParkingOnSpecificStreet]: address.parking_on_specific_street,
            [AddressDetailField.ParkingInstructions]: address.parking_instructions,
            [AddressDetailField.Paperwork]: !!address.paperwork,
            [AddressDetailField.AccessUnknown]: address.access_unknown || DEFAULT_ACCESS_UNKNOWN,
            [AddressDetailField.PaperworkUnknown]: address.paperwork_unknown || DEFAULT_PAPERWORK_UNKNOWN,
            [AddressDetailField.ParkingUnknown]: address.parking_unknown || DEFAULT_PARKING_UNKNOWN,
            [AddressDetailField.MaxTruckHeight]: null,
            [AddressDetailField.MaxTruckHeightUnknown]: DEFAULT_MAX_TRUCK_HEIGHT_UNKNOWN,
            [AddressDetailField.Environment]: formatAddressEnvironment(address.environment) || null,
            [AddressDetailField.AdditionalNotes]: address.additional_notes,
            [AddressDetailField.BuildingOpeningHour]: address.building_opening_hour,
            [AddressDetailField.BuildingClosingHour]: address.building_closing_hour,
            [AddressDetailField.BuildingRestrictionsAnswer]: formatAddressBuildingRestrictionsAnswer(
              address.building_restrictions_answer,
            ),
          },
        }
      : undefined,
    ...buildPickupInventoryInput({ inventory }),
    ...buildReturnInventoryInput({ items }),
  };

  if (order.type === OrderTypeEnum.Return) {
    if (!order.itemIDs?.length) {
      return { error: 'Unable to compute availability without inventory.' };
    }
  }

  const input: IAvailabilitiesInput = {
    ...(orderID === undefined
      ? buildAvailabilityInputForBooking({ from, till, order })
      : buildAvailabilityInputForEditing({ from, till, orderID, order })),
    subscriptions:
      subscriptions &&
      subscriptions.map((subscription) => {
        if (subscription.custom_plan) {
          return {
            planID: String(subscription.plan_id),
            quantity: Math.round(subscription.quantity),
            customDimension: {
              length: Number(subscription.custom_plan_dimension_length),
              width: Number(subscription.custom_plan_dimension_width),
            },
          };
        }
        return {
          planID: String(subscription.plan_id),
          quantity: Math.round(subscription.quantity),
        };
      }),
  };

  const variables = {
    accountID: accountID ? String(accountID) : undefined,
    input,
  };

  const { data, loading } = await client.query<AvailabilitiesQuery, AvailabilitiesQueryVariables>({
    fetchPolicy: 'no-cache',
    query: AvailabilitiesDocument,
    variables,
  });

  if (!data || loading) {
    return [];
  }

  return {
    data: data.availabilities.map(
      ({
        available,
        datetime,
        duration,
        appointmentFee,
        laborRate,
        perMoverHourAdjustmentAmount,
        laborBillingFormat,
      }) => ({
        available,
        datetime: DateTime.fromISO(datetime, FROM_ISO_OPTIONS),
        duration: duration ? Duration.fromISO(duration) : undefined,
        appointmentFee,
        laborRate,
        perMoverHourAdjustmentAmount,
        laborBillingFormat: laborBillingFormat ? laborBillingFormat?.replace(/_/g, ' ') : undefined,
      }),
    ),
  };
};

import { DateTime } from 'luxon';
import * as React from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';

import { Omit } from '@admin/utils/omit';

export const DateFormControl: React.FC<
  {
    value?: string; // value: 2020-12-31 (ISO formatted)
    onChange(value?: string): void; //  value: 2020-12-31 (ISO formatted)
  } & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'value' | 'onChange'>
> = ({ value, onChange, ...props }) => (
  <DayPickerInput
    inputProps={{ ...props, className: 'form-control' }}
    format="YYYY-M-D"
    value={value}
    onDayChange={(date) => {
      onChange(DateTime.fromJSDate(date).toISODate() || undefined);
    }}
  />
);

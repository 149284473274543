import axios, { AxiosResponse } from 'axios';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';

import { Label } from '@admin/components/helpers/label';
import { Panel } from '@admin/components/helpers/panel';
import { Spinner } from '@admin/components/spinner';
import { Table } from '@shared/components/bootstrap';
import { useAxiosRequest } from '@admin/hooks';

type ActivityEvent = {
  id: string;
  timestamp: string;
  kind: 'arrive' | 'depart';
  object: {
    name: string;
    address: {
      map: string;
    };
  };
};

type ActivityCheckin = {
  id: string;
  timestamp: string;
  latitude: string;
  longitude: string;
  speed?: number;
  appstate: string;
  device?: {
    name: string;
  };
};

type ActivityGroup = {
  date: string;
  events: ActivityEvent[];
  checkins: ActivityCheckin[];
};

type ActivityData = {
  tz: string;
  groups: ActivityGroup[];
};

const METERS_PER_SECOND_TO_MILES_PER_HOUR = 3600 / 1609.34;

const EVENT_KIND_TO_HUMAN = {
  arrive: 'Arrived',
  depart: 'Departed',
};

const mapURL = ({ latitude, longitude }: { latitude: string; longitude: string }) =>
  `https://www.google.com/maps?q=loc:${latitude},${longitude}`;

export const Activity: React.FC<{ id: string }> = ({ id }) => {
  const [request, setRequest] = useState<Promise<AxiosResponse<ActivityData>> | undefined>();
  useEffect(() => {
    setRequest(axios.get<ActivityData>(`/users/${id}/reports/navigation.json`));
  }, [id]);
  const { loading, response } = useAxiosRequest(request);
  const data = response?.data;

  return (
    <Panel>
      <Panel.Header>
        <Panel.Title>Activity</Panel.Title>
      </Panel.Header>
      <Panel.Body>
        <Table striped responsive>
          <thead>
            <tr>
              <th className="col-sm-2 text-left">Date</th>
              <th className="col-sm-5 text-left">Routes</th>
              <th className="col-sm-5 text-left">Events</th>
            </tr>
          </thead>
          <tbody>
            {data?.groups.map((group) => (
              <tr key={group.date}>
                <td className="col-sm-2 text-left">{DateTime.fromISO(group.date).toFormat('EEEE, MMMM d, yyyy')}</td>
                <td className="col-sm-5 text-left">
                  <ul>
                    {group.checkins.map((checkin) => (
                      <li key={checkin.id}>
                        <Label kind="default">
                          {DateTime.fromISO(checkin.timestamp).setZone(data.tz).toFormat('hh:mm a')}
                        </Label>{' '}
                        <span>-</span>{' '}
                        <a href={mapURL(checkin)} target="_blank">
                          {checkin.latitude},{checkin.longitude}
                        </a>{' '}
                        <span>-</span>{' '}
                        {checkin.speed && checkin.speed > 0 && (
                          <span>{Math.round(checkin.speed * METERS_PER_SECOND_TO_MILES_PER_HOUR)}mi/hour</span>
                        )}{' '}
                        <span>on</span> <strong>{checkin.device?.name || '-'}</strong> <span>appstate</span>{' '}
                        <strong>{checkin.appstate}</strong>
                      </li>
                    ))}
                  </ul>
                </td>
                <td className="col-sm-5 text-left">
                  <ul>
                    {group.events.map((event) => (
                      <li key={event.id}>
                        <Label kind="default">
                          {DateTime.fromISO(event.timestamp).setZone(data.tz).toFormat('hh:mm a')}
                        </Label>{' '}
                        <span>-</span> <Label kind="primary">{EVENT_KIND_TO_HUMAN[event.kind]}</Label> <span>at</span>{' '}
                        <a href={event.object.address.map} target="_blank">
                          {event.object.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {loading && <Spinner />}
      </Panel.Body>
    </Panel>
  );
};

import * as React from 'react';

interface IDispatchMetrics {
  itemCount: number | null;
  totalCuft: number | null;
  cuftPerItemCount: number | null;
  estimatedHours: number;
}

const DispatchMetrics = ({ itemCount, totalCuft, cuftPerItemCount, estimatedHours }: IDispatchMetrics) => (
  <React.Fragment>
    <span>{`${itemCount} items`}</span>
    <span>{`${totalCuft ? totalCuft.toFixed(2) : 0} cuft`}</span>
    <span>
      {`${cuftPerItemCount ? cuftPerItemCount.toFixed(1) : 0},
         ${Math.floor(estimatedHours)}:${Math.round((estimatedHours * 60) % 60)
        .toString()
        .padStart(2, '0')}`}
    </span>
  </React.Fragment>
);

export default DispatchMetrics;

(function () {
  angular.module('app').service('SmsLiveService', [
    '$rootScope',
    'UserService',
    'PusherService',
    'PusherConstants',
    function ($rootScope, UserService, PusherService, PusherConstants) {
      this.subscribe_to_pusher = () => {
        if (this.channel) {
          return;
        }
        this.channel = PusherService.client.subscribe(PusherConstants.SMS_INFO_CHANNEL_NAME);
        this.channel.bind(PusherConstants.PUSHER_SUBSCRIPTION_SUCCEEDED, this.subscription_succeeded);
        this.channel.bind(PusherConstants.PUSHER_SUBSCRIPTION_ERROR, this.subscription_error);
        this.channel.bind(PusherConstants.SMS_PENDING_COUNT, this.received_pending);
      };

      this.subscription_succeeded = () => {
        // eslint-disable-next-line no-console
        console.log({
          message: 'SMS_' + UserService.name + ': SUBSCRIPTION_SUCCEDDED for ' + PusherConstants.SMS_INFO_CHANNEL_NAME,
        });
      };

      this.subscription_errors = (error) => {
        // eslint-disable-next-line no-console
        console.log({
          message:
            'SMS_' +
            UserService.name +
            ': SUBSCRIPTION_ERROR ' +
            error +
            ' for ' +
            PusherConstants.SMS_INFO_CHANNEL_NAME,
        });
      };

      this.received_pending = (data) => {
        $rootScope.$broadcast(PusherConstants.BROADCAST_SMS_PENDING_COUNT_EVENT, data);
      };
    },
  ]);
})();

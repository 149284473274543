import angular from 'angular';

angular.module('app').factory('Referral', [
  '$resource',
  function ($resource) {
    return $resource(
      '/marketing/referrals/:id.json',
      {
        id: '@id',
      },
      {
        index: {
          method: 'GET',
          isArray: true,
        },
        update: {
          method: 'PUT',
        },
      },
    );
  },
]);

export enum TimeZone {
  Chicago = 'America/Chicago',
  Denver = 'America/Denver',
  Detroit = 'America/Detroit',
  LosAngeles = 'America/Los_Angeles',
  NewYork = 'America/New_York',
  Phoenix = 'America/Phoenix',
  Toronto = 'America/Toronto',
  Manila = 'Asia/Manila',
  Kolkata = 'Asia/Kolkata',
}

export const TZS = [
  TimeZone.Chicago,
  TimeZone.Denver,
  TimeZone.Detroit,
  TimeZone.LosAngeles,
  TimeZone.NewYork,
  TimeZone.Phoenix,
  TimeZone.Toronto,
  TimeZone.Manila,
  TimeZone.Kolkata,
];

import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';

const DATE_FORMAT = 'yyyy-MM-dd';
const TIME_FORMAT = 'HH:mm';

export const DateTimePicker: React.FC<{
  dt?: DateTime;
  disabled?: boolean;
  onChange(dt: DateTime | undefined): void;
  tz: string;
  required?: boolean;
  dateWithTimeRequired?: boolean;
}> = ({ dt, disabled, onChange, tz, required = true, dateWithTimeRequired = false }) => {
  const [date, setDate] = useState<string>(dt ? dt.toFormat(DATE_FORMAT) : '');
  const [time, setTime] = useState<string>(dt ? dt.toFormat(TIME_FORMAT) : '');

  useEffect(() => {
    if (!date || !time) {
      onChange(undefined);
      return;
    }

    const newDt = DateTime.fromFormat(`${date} ${time}`, `${DATE_FORMAT} ${TIME_FORMAT}`, { zone: tz });
    onChange(newDt);
  }, [date, time]);

  return (
    <div className="form-group">
      <div className="row">
        <div className="col-md-6">
          <input
            type="date"
            className="form-control"
            value={date}
            onChange={(event) => setDate(event.currentTarget.value)}
            disabled={disabled}
            required={required || (Boolean(time) && dateWithTimeRequired)}
          />
        </div>
        <div className="col-md-6">
          <input
            type="time"
            className="form-control"
            value={time}
            onChange={(event) => setTime(event.currentTarget.value)}
            disabled={disabled}
            required={required || (Boolean(date) && dateWithTimeRequired)}
          />
        </div>
      </div>
    </div>
  );
};

import React from 'react';

import { LandingLocationCityQuery, useLandingLocationCityQuery } from '@admin/schema';

import { client } from '@admin/libraries/apollo';

import { Breadcrumb, AnchorButton as Link } from '@shared/components/bootstrap';
import { Panel } from '@admin/components/helpers/panel';
import { PageHeader } from '@admin/components/helpers/page_header';
import { Spinner } from '@admin/components/spinner';

import { List as ContentPanel } from '@admin/components/landing/location/content/list';

const Content: React.FC<{
  city: LandingLocationCityQuery['city'];
}> = ({ city }) => (
  <>
    <Panel>
      <Panel.Body>
        <div>
          State: <strong>{city.state.name}</strong>
        </div>
        <div>
          Region: <strong>{city.region.name}</strong>
        </div>
        <div>
          Slug: <strong>{city.slug}</strong>
        </div>
        <div>
          Name: <strong>{city.name}</strong>
        </div>
        <div>
          Latitude: <strong>{city.latitude}</strong>
        </div>
        <div>
          Longitude: <strong>{city.longitude}</strong>
        </div>
      </Panel.Body>
      <Panel.Footer align="right">
        <Link kind="primary" href={`/landing/location/cities/${city.id}/edit`}>
          Edit
        </Link>
      </Panel.Footer>
    </Panel>
    <ContentPanel location={city} />
  </>
);

export const Details: React.FC<{
  id: string;
}> = ({ id }) => {
  const { data } = useLandingLocationCityQuery({
    client,
    fetchPolicy: 'cache-and-network',
    variables: { id },
  });
  const city = data?.city;

  return (
    <>
      <PageHeader>
        <Breadcrumb>
          <Breadcrumb.Item>Landing</Breadcrumb.Item>
          <Breadcrumb.Item>Location</Breadcrumb.Item>
          <Breadcrumb.Item>
            <a href="/landing/location/cities">Cities</a>
          </Breadcrumb.Item>
          {id && <Breadcrumb.Item active>{city?.name ?? '-'}</Breadcrumb.Item>}
        </Breadcrumb>
      </PageHeader>
      {city ? <Content city={city} /> : <Spinner />}
    </>
  );
};
